import { Paper, Stack, Typography, Container, Box } from "@mui/material"
import { useState, useEffect } from "react";
import { useProductLineParams } from "../../../contexts/ProductLineParams";
import InfoLogo from '../../../assets/images/info.svg'
import greenDrop from '../../../assets/images/green_drop.svg'

import { useDriftParams } from "../../../contexts/DriftParams";
import CalculationMode from './CalculationMode';
import { useConnTypes } from "../../../contexts/conTypes";
import { DriftParamEnum, ProductLineEnum, ErrorEnum, NoticeEnum, ShuntParamsEnum, SessionParamsEnum, AdjustmentsEnum, CalcParamsEnum } from "../../../enums";
import { useAdjustParams, useCalcParams, useDevbarParams, useErrorContext, useShuntParams } from "../../../contexts";
import { useNoticeContext, useSessionParams } from "../../../contexts";
import { NoticeMsg } from "../../../constants/noticeMessages";
import { PRODUCTLINE_GREEN, PRODUCTPRINCIP_PR2, PRODUCTPRINCIP_PR3, PRODUCTPRINCIP_SR2, PRODUCTPRINCIP_SR2FLEX, PRODUCTPRINCIP_SR2SMART, PRODUCTPRINCIP_SR3, PRODUCTPRINCIP_VA1 } from "../../../constants/productlineConstants";
import { useDatabase } from "reactfire";
import { DimSelectField } from "./components";
import { WizardParamEnum } from "../../../enums/wizardParamsEnum";
import { useWizardParams } from "../../../contexts/wizardParams";
import { SystemFluidPicker } from "../systemFluidPicker/systemFluidPicker";
import { SwitchFlowCalcMode } from "./components/switchFlowCalcMode";
import { ErrorMsg } from "../../../constants/errorMessages";
import { HOVER_TEXT_DEFAULT } from "../../../constants/generalText/hoverTexts";
import { DriftParamsBody_SR2 } from "./driftParamsBodyPrinciples/SR/driftParamsBodySR2";
import { DriftParamsBody_SR3 } from "./driftParamsBodyPrinciples/SR/driftParamsBodySR3";
import { DriftParamsBody_SR2Flex } from "./driftParamsBodyPrinciples/SR/driftParamsBodySR2Flex";
import { DriftParamsBody_PR2 } from "./driftParamsBodyPrinciples/PR/driftParamsBodyPR2";
import { DriftParamsBody_PR3 } from "./driftParamsBodyPrinciples/PR/driftParamsBodyPR3";
import { DriftParamsBody_VA1 } from "./driftParamsBodyPrinciples/VA/driftParamsBodyVA1";
import { resetMaxStepFunc } from "../../../functions/HelperFunctions/resetMaxStep";


export const DriftParam = () => {
    const database = useDatabase();

    const Errorlist = new ErrorEnum();
    const Noticelist = new NoticeEnum();
    const driftParams = useDriftParams();
    const shuntParams = useShuntParams();
    const productLine = useProductLineParams();
    const calcParams = useCalcParams();
    const connTypes = useConnTypes();
    const errorContext = useErrorContext();
    const noticeContext = useNoticeContext();
    const sessionParams = useSessionParams();
    const devParams = useDevbarParams();
    const adjustParams = useAdjustParams();
    const wizardParams = useWizardParams();

    const getIsSaboParam = () => {
        if (ShuntParamsEnum.IsSabo.getContextValue(shuntParams) != null) {
            return ShuntParamsEnum.IsSabo.getContextValue(shuntParams);
        }
        else {
            return '0';
        }
    }

    const getImageRef = () => {
        var result = connTypes.find(obj => {
            return obj.id === ProductLineEnum.ProductPrincip.getContextValue(productLine);
        })

        if (result != null && result.paramImageRef != null) {
            return result.paramImageRef;
        }
        else {
            return '';
        }
    }

    const imageRef = getImageRef();

    const [isSabo, setIsSabo] = useState(getIsSaboParam())
    const [currentGreenLvl, setCurrentGreenLvl] = useState(-1)
    const [greenLvlChanged, setGreenLevelChanged] = useState(false);

    useEffect(() => {
        if (Number(WizardParamEnum.SecondaryFlow.getContextValue(wizardParams)) === -1) {
            if (WizardParamEnum.SecondaryFlowSpecVal.getContextValue(wizardParams) != null && DriftParamEnum.SecFlow.getContextValue(driftParams) == null) {
                DriftParamEnum.SecFlow.setContextValue(driftParams, WizardParamEnum.SecondaryFlowSpecVal.getContextValue(wizardParams))
            }
        }
    }, [WizardParamEnum.SecondaryFlow.getContextValue(wizardParams), WizardParamEnum.SecondaryFlowSpecVal.getContextValue(wizardParams)])

    useEffect(() => {
        if (ProductLineEnum.ProductGreenLevel.getContextValue(productLine) != null && ProductLineEnum.ProductGreenLevel.getContextValue(productLine) !== "") {
            if (currentGreenLvl != null && currentGreenLvl !== ProductLineEnum.ProductGreenLevel.getContextValue(productLine)) {
                setGreenLevelChanged(true);
            }
            else if (currentGreenLvl == null && ProductLineEnum.ProductGreenLevel.getContextValue(productLine) === 2) {
                setGreenLevelChanged(true);
            }
            else {
                setGreenLevelChanged(false);
            }

            setCurrentGreenLvl(ProductLineEnum.ProductGreenLevel.getContextValue(productLine))
        }

    }, [SessionParamsEnum.UpdateValues.getContextValue(sessionParams)])

    const setInitialValue = (type) => {
        if (type === 'backValve') {
            if (DriftParamEnum.HasBackVent.getContextValue(driftParams) != null && DriftParamEnum.HasBackVent.getContextValue(driftParams) !== '') {
                return DriftParamEnum.HasBackVent.getContextValue(driftParams);
            }
            else {
                return '1';
            }
        }
        else if (type === 'mainPump') {
            if (DriftParamEnum.HasMainPump.getContextValue(driftParams) != null && DriftParamEnum.HasMainPump.getContextValue(driftParams) !== '') {
                return DriftParamEnum.HasMainPump.getContextValue(driftParams);
            }
            else {
                return '1';
            }
        }
    }

    const [backValve, setBackValve] = useState(setInitialValue('backValve'));
    const [mainPump, setMainPump] = useState(setInitialValue('mainPump'));

    const handleCheckBackValve = (event) => {
        resetMaxStepFunc(sessionParams, database);

        CalcParamsEnum.clearData(calcParams);
        AdjustmentsEnum.clearData(adjustParams);

        DriftParamEnum.HasBackVent.setContextValue(driftParams, event.target.value);

        if (event.target.value === "0") {
            Noticelist.addNotice(noticeContext, NoticeMsg.manual_noBackVent.key, NoticeMsg.manual_noBackVent.msg);
        }
        else {
            Noticelist.clearNotice(noticeContext, NoticeMsg.manual_noBackVent.key)
        }
        setBackValve(event.target.value);
    };

    /*
    const handleCheckSaboVersion = (event) => {
        resetMaxStep();

        CalcParamsEnum.clearData(calcParams);
        AdjustmentsEnum.clearData(adjustParams);

        ShuntParamsEnum.IsSabo.setContextValue(shuntParams, event.target.value);
        setIsSabo(event.target.value);
    };

    const handleCheckMainPump = (event) => {
        resetMaxStep();

        CalcParamsEnum.clearData(calcParams);
        AdjustmentsEnum.clearData(adjustParams);

        DriftParamEnum.HasMainPump.setContextValue(driftParams, event.target.value);
        setMainPump(event.target.value);
    };

    const resetMaxStep = () => {
        if (SessionParamsEnum.MaxStep.getContextValue(sessionParams) !== SessionParamsEnum.CurrentStep.getContextValue(sessionParams)) {
            SessionParamsEnum.MaxStep.setContextValue(sessionParams, SessionParamsEnum.CurrentStep.getContextValue(sessionParams), database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
        }
    }*/

    const isDisabled = () => {
        if (SessionParamsEnum.Editable.getContextValue(sessionParams) === false && devParams.state.val.devMode !== true) {
            return true;
        }
        else {
            return false;
        }
    }

    const [infoText, setInfoText] = useState(HOVER_TEXT_DEFAULT);
    const [infoText2, setInfoText2] = useState("");

    const handleHover = (event, message, mess) => {
        setInfoText(message);
        setInfoText2(mess);
    };

    const handleExit = (event) => {
        setInfoText(HOVER_TEXT_DEFAULT);
        setInfoText2('');
    };

    const checkCalcMode = () => {
        if (DriftParamEnum.CalcMode.getContextValue(driftParams) === "0" || DriftParamEnum.CalcMode.getContextValue(driftParams) === 0) {
            return false;
        }
        else {
            return true;
        }
    }

    const displayDriftParamsBody = () => {
        switch (ProductLineEnum.ProductPrincip.getContextValue(productLine)) {
            case PRODUCTPRINCIP_SR2:
                return (
                    <DriftParamsBody_SR2
                        handleHover={handleHover}
                        handleExit={handleExit}
                        handleCheckBackValve={handleCheckBackValve}
                        isDisabled={isDisabled}
                        imageRef={imageRef}
                        backValve={backValve}
                        checkCalcMode={checkCalcMode}
                    />
                );
            case PRODUCTPRINCIP_SR2FLEX: case PRODUCTPRINCIP_SR2SMART:
                return (
                    <DriftParamsBody_SR2Flex
                        handleHover={handleHover}
                        handleExit={handleExit}
                        isDisabled={isDisabled}
                        imageRef={imageRef}
                    />
                );

            case PRODUCTPRINCIP_SR3:
                return (
                    <DriftParamsBody_SR3
                        handleHover={handleHover}
                        handleExit={handleExit}
                        handleCheckBackValve={handleCheckBackValve}
                        isDisabled={isDisabled}
                        imageRef={imageRef}
                        backValve={backValve}
                        checkCalcMode={checkCalcMode}
                    />
                );
            case PRODUCTPRINCIP_PR2:
                return (
                    <DriftParamsBody_PR2
                        handleHover={handleHover}
                        handleExit={handleExit}
                        isDisabled={isDisabled}
                        imageRef={imageRef}
                    />
                );
            case PRODUCTPRINCIP_PR3:
                return (
                    <DriftParamsBody_PR3
                        handleHover={handleHover}
                        handleExit={handleExit}
                        isDisabled={isDisabled}
                        imageRef={imageRef}
                    />
                );
            case PRODUCTPRINCIP_VA1:
                return (
                    <DriftParamsBody_VA1
                        handleHover={handleHover}
                        handleExit={handleExit}
                        isDisabled={isDisabled}
                        imageRef={imageRef}
                    />
                );
            default:
                if (SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) === false) {
                    console.error("Null or not a recognized principle!", ProductLineEnum.ProductPrincip.getContextValue(productLine))
                }
                break;
        }
    }

    return (
        <>
            <Stack>
                <div style={{ display: 'block', }}>
                    <Stack direction={'row'}>
                        <Paper elevation={0} sx={{ width: '700px', justifyContent: 'center', padding: '15px 10px 5px 10px', borderRadius: 0, boxShadow: "2px 2px 10px #0055a030", }}>
                            <CalculationMode isDisabled={isDisabled()} />
                        </Paper>
                    </Stack>
                </div>


                <div style={{ marginTop: '15px', }}>
                    <Paper elevation={0} sx={{ width: '700px', padding: '10px', height: 'fit-content', borderRadius: 0, boxShadow: "2px 2px 10px #0055a030", }}>
                        <Typography variant='h3' fontWeight='bold'>
                            Driftparametrar
                        </Typography>
                        <Box>
                            <SystemFluidPicker isInDriftCalc isDisabled={isDisabled()} />
                        </Box>

                        {displayDriftParamsBody()}

                        <Container style={{ marginLeft: '75px', marginTop: '15px' }}>
                            <Stack direction={'row'}>
                                <Container>
                                    <Stack direction={'column'}>
                                        <div style={{ width: '150px' }}>
                                            <span style={{ marginLeft: '9px' }}>Primär dim</span>
                                            <DimSelectField isPrim={true} />
                                        </div>

                                    </Stack>
                                </Container>
                                <Container>
                                    <Stack direction={'column'}>
                                        <div style={{ width: '150px' }}>
                                            <span style={{ marginLeft: '9px' }}>Sekundär dim</span>
                                            <DimSelectField isPrim={false} />
                                        </div>
                                    </Stack>
                                </Container>
                            </Stack>
                        </Container>

                        <Box>
                            <SwitchFlowCalcMode isDisabled={isDisabled()} />
                        </Box>
                    </Paper>
                </div >

                {Errorlist.hasErrors(errorContext) ?
                    <>
                        <div style={{ display: 'block', marginTop: '15px', }}>
                            <Paper elevation={0} className="error-box">
                                <Stack direction={'row'}>
                                    <Typography style={{ paddingLeft: '57px', paddingTop: '15px', paddingBottom: '15px', maxWidth: '500px' }}>
                                        {Object.keys(Errorlist.getErrorList(errorContext)).map((errorKey) => {
                                            if (ErrorMsg[errorKey] != null) {
                                                if (ErrorMsg[errorKey].component != null) {
                                                    return <div key={errorKey}>{ErrorMsg[errorKey].component}</div>
                                                }
                                                else if (typeof ErrorMsg[errorKey] === 'function') {
                                                    if (Errorlist.getErrorList(errorContext)[errorKey]?.val != null) {
                                                        if (Errorlist.getErrorList(errorContext)[errorKey]?.val2 != null) {
                                                            return <div key={errorKey}>{ErrorMsg[errorKey](Errorlist.getErrorList(errorContext)[errorKey]?.val, Errorlist.getErrorList(errorContext)[errorKey]?.val2)?.component}</div>
                                                        }
                                                        else {
                                                            return <div key={errorKey}>{ErrorMsg[errorKey](Errorlist.getErrorList(errorContext)[errorKey]?.val)?.component}</div>
                                                        }

                                                    }
                                                    else {
                                                        return <div key={errorKey}>{ErrorMsg[errorKey]()?.component}</div>
                                                    }
                                                }
                                                else if (typeof Errorlist.getErrorList(errorContext)[errorKey] === 'string') {
                                                    return <div key={errorKey}>{Errorlist.getErrorList(errorContext)[errorKey]}</div>
                                                }
                                                else if (Errorlist.getErrorList(errorContext)[errorKey]?.msg != null) {
                                                    return <div key={errorKey}>{Errorlist.getErrorList(errorContext)[errorKey]?.msg}</div>
                                                }
                                            }
                                        })}
                                    </Typography>
                                </Stack>
                            </Paper>
                        </div>
                    </> : <></>
                }

                {Noticelist.hasNotices(noticeContext) ?
                    <>
                        <div style={{ display: 'block', marginTop: '15px', }}>
                            <Paper elevation={0} className="notice-box">
                                <Stack direction={'row'}>
                                    <Typography style={{ paddingLeft: '57px', paddingTop: '15px', paddingBottom: '15px', maxWidth: '500px' }}>
                                        {Object.keys(Noticelist.getNoticeList(noticeContext)).map((noticeKey) => {
                                            return <><span key={noticeKey}>{Noticelist.getNoticeList(noticeContext)[noticeKey]}</span><br /></>
                                        })}
                                    </Typography>
                                </Stack>
                            </Paper>
                        </div>
                    </> : <></>
                }

                {ProductLineEnum.ProductLine.getContextValue(productLine) === PRODUCTLINE_GREEN && greenLvlChanged && currentGreenLvl !== -1 ?
                    <>
                        <div style={{ display: 'block', marginTop: '15px', }}>
                            <Paper elevation={0} className="green-level-box">
                                {currentGreenLvl === 2 &&
                                    <img src={greenDrop} alt="green drop" style={{ width: '15px', float: 'right', marginTop: '3px', marginRight: '5px' }} />
                                }
                                <img src={greenDrop} alt="green drop" style={{ width: '15px', float: 'right', marginTop: '3px', marginRight: '5px' }} />
                                <Stack direction={'row'}>
                                    {currentGreenLvl === 2 ? (
                                        <Typography style={{ paddingLeft: '57px', paddingTop: '15px', paddingBottom: '15px', maxWidth: '550px' }}>
                                            Den dimensionerade shuntgruppen uppfyller kraven för miljödeklaration enligt ISO 14025 och <br />
                                            EN 15804:2012+A2:2019 och ingår därmed i vår
                                            produktlinje <strong>Premablock<sup>®</sup> green II</strong>.
                                        </Typography>
                                    ) : (
                                        <Typography style={{ paddingLeft: '57px', paddingTop: '15px', paddingBottom: '15px', maxWidth: '550px' }}>
                                            Den dimensionerade shuntgruppen ingår i produktlinjen <strong>Premablock<sup>®</sup> green I</strong>. <br />
                                            Produktlinjen <strong>Premablock<sup>®</sup> green II</strong>, det ännu grönare valet, omfattar för närvarande<br />
                                            shuntgrupper i dimension DN20-DN125, med IMI STAD ZERO/IMI STAF som vald injusteringsventil och utan <strong>Connected™</strong> tillbehör.

                                        </Typography>
                                    )}

                                </Stack>
                            </Paper>
                        </div>
                    </> : <></>
                }

                <div style={{ display: 'block', marginTop: '15px', }}>
                    <Paper elevation={0} style={{ width: '700px', justifyContent: 'center', padding: '10px', height: 'fit-content', borderRadius: 0, boxShadow: "2px 2px 10px #0055a030", }}>
                        <Stack direction={'row'}>
                            <img src={InfoLogo} alt='Info logo' />
                            <Typography style={{ paddingLeft: '10px', paddingTop: '10px', paddingBottom: '10px' }}>
                                {infoText}
                                <br></br>
                                {infoText2}
                            </Typography>
                        </Stack>
                    </Paper></div>

            </Stack >

        </>
    );
}