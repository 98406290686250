import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useDatabase } from 'reactfire';
import { SESSION_PATH } from '../../../../constants/firebasePaths';
import { SessionParamsEnum } from '../../../../enums/sessionParamsEnum';
import { useSessionParams } from '../../../../contexts';
import { get, ref, set } from 'firebase/database';

const filter = createFilterOptions();

export default function TermsInput({ optionType, labelText }) {
    const database = useDatabase();
    const sessionParams = useSessionParams();

    let baseUrl = SESSION_PATH + SessionParamsEnum.SessionID.getContextValue(sessionParams) + "/CustomData/" + optionType;
    const dbRef = ref(database, baseUrl);

    const [value, setValue] = useState(null);

    useEffect(() => {
        get(dbRef).then((res) => {
            //console.log("Value: ", res.val())
            if (res.val() != null) {
                //console.log("Set Value: ", res.val())
                setValue({
                    title: res.val(),
                })
            }
        })
    }, [])

    const changeValue = (event, newValue) => {
        //console.log("Value:", newValue, optionType)
        if (typeof newValue === 'string') {
            setValue({
                title: newValue,
            });
        } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({
                title: newValue.inputValue,
            });
        } else {
            setValue(newValue);
        }
        if (newValue != null && newValue !== "") {
            saveToFirebase(newValue.title);
        }
        else {
            saveToFirebase(null)
        }

    }

    const saveToFirebase = (value) => {
        set(dbRef, value).then(() => {
            //console.log("Saved!");
        })
    }

    return (
        <Autocomplete
            value={value}
            onChange={changeValue}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.title);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        title: inputValue,
                    });
                }

                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="terms-input"
            options={(optionType === "freightOptions" && freightOptions) || (optionType === "deliveryOptions" && deliveryOptions) || (optionType === "paymentOptions" && paymentOptions) || (optionType === "warantyOptions" && warantyOptions) || (optionType === "otherOptions" && otherOptions)}
            size="small"
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.title;
            }}
            renderOption={(props, option) => <li {...props}>{option.title}</li>}
            sx={{ width: 500, marginBottom: '20px' }}
            freeSolo
            renderInput={(params) => (
                <TextField {...params} label={labelText} />
            )}
        />
    );
}


const freightOptions = [
    { title: 'Leverans EXW, fritt fabrik Kalmar. Kostnad för emballage och frakt till kommer.' },
];

const deliveryOptions = [
    { title: 'Enligt senare överenskommelse' },
    { title: '3-6 veckor beronde på aktuell beläggning och ledtid för ingående komponenter' },
];

const paymentOptions = [
    { title: '30 dagar netto efter leverans och godkänd kreditprövning' },
];

const warantyOptions = [
    { title: '5 år från och med leveransdatum' },
];

const otherOptions = [
    { title: 'Enligt AA VVS 09' },
];
