import { Paper, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Checkbox } from "@mui/material"
import { useProductLineParams } from "../../../../../contexts";
import { AdjustmentsEnum, ProductLineEnum } from "../../../../../enums"
import { AdjustSelectfield } from "../adjustSelectfield";

import { CABINET_INSULATED, CABINET_UNINSULATED, MIXINGVESSEL_1000L, MIXINGVESSEL_100L, MIXINGVESSEL_200L, MIXINGVESSEL_300L, MIXINGVESSEL_500L, MIXINGVESSEL_60L, NO_CABINET, NO_MIXINGVESSEL, STAND_FLOOR, STAND_WALL } from "../../../../../constants/adjustmentsOptions"
import { PRODUCTLINE_GREEN, PRODUCTLINE_SMART, PRODUCTFAMILY_VA } from "../../../../../constants/productlineConstants";

export const AdjustCardOther = ({ standValue, cabinetValue, dripPlateValue, actuatorsValue, setAdjustParams, getMinTempValue, isDisabled }) => {
    const productLineParams = useProductLineParams();
    
    const productLine = ProductLineEnum.ProductLine;
    const productFamily = ProductLineEnum.ProductFamily;
    const productGreenLvl = ProductLineEnum.ProductGreenLevel;
    
    return (
        <div>
            <Paper elevation={0} className="adjust-card">
                <FormControl>
                    <FormLabel id="wall-stand-radio-buttons-group">Övrigt</FormLabel>
                    <RadioGroup
                        aria-labelledby="wall-stand-radio-buttons-group"
                        name="wall-stand"
                        value={standValue}
                        onChange={(event) => { setAdjustParams(event.target.value, AdjustmentsEnum.StandValue) }}
                    >
                        <FormControlLabel value={STAND_FLOOR} control={<Radio />} label="Golvstativ" disabled={isDisabled()} />
                        <FormControlLabel value={STAND_WALL} control={<Radio />} label="Väggstativ" disabled={isDisabled()} />

                    </RadioGroup>
                </FormControl>
                {(productLine.getContextValue(productLineParams) !== PRODUCTLINE_GREEN) && (getMinTempValue() <= 18) &&
                    <>
                        <br></br>
                        <br></br>
                        <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
                            <RadioGroup

                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="cabinet"
                                value={cabinetValue}
                                onChange={(event) => { setAdjustParams(event.target.value, AdjustmentsEnum.CabinetValue) }}
                            >
                                {getMinTempValue() <= 20 ? (
                                    <FormControlLabel value={CABINET_UNINSULATED} control={<Radio />} label="Med skåp" disabled={isDisabled()} />
                                ) : (
                                    <FormControlLabel value={CABINET_INSULATED} control={<Radio />} label="Med skåp" disabled={isDisabled()} />
                                )}

                                <FormControlLabel value={NO_CABINET} control={<Radio />} label="Utan skåp" disabled={isDisabled()} />
                            </RadioGroup>
                        </FormControl></>
                }
                <br></br>
                {getMinTempValue() > 18 && (productLine.getContextValue(productLineParams) === PRODUCTLINE_GREEN && productGreenLvl.getContextValue(productLineParams) !== 2) &&
                    <>
                        <br></br>
                        <FormControlLabel control={<Checkbox checked={dripPlateValue} disabled={isDisabled()}
                            onChange={(event) => { setAdjustParams(event.target.checked, AdjustmentsEnum.DripPlateValue) }} />} label="Dropplåt EN 1.4436" />
                    </>
                }
                {(productLine.getContextValue(productLineParams) !== PRODUCTLINE_GREEN) && (productLine.getContextValue(productLineParams) !== PRODUCTLINE_SMART) &&
                    <>
                        <br></br>
                        <FormControlLabel control={<Checkbox checked={actuatorsValue} disabled={isDisabled()}
                            onChange={(event) => { setAdjustParams(event.target.checked, AdjustmentsEnum.ActuatorsValue) }} />} label="Ställdon 24V/0-10V" />
                    </>}

                {productFamily.getContextValue(productLineParams) === PRODUCTFAMILY_VA &&
                    <>
                        <br></br>
                        <AdjustSelectfield id="mixingVessels" className="select-label-top" labelText={"Blandningskärl"} options={[NO_MIXINGVESSEL, MIXINGVESSEL_60L, MIXINGVESSEL_100L, MIXINGVESSEL_200L, MIXINGVESSEL_300L, MIXINGVESSEL_500L, MIXINGVESSEL_1000L,]} isDisabled={isDisabled()} />
                    </>
                }
            </Paper>
        </div>
    )
}