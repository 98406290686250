import { equalTo, get, onDisconnect, onValue, orderByChild, query, ref, serverTimestamp, set, update } from "firebase/database";
import { useEffect, useState } from "react"
import { useDatabase } from "reactfire";
import { SESSION_PATH, SESSION_SHUNT_PATH, SHUNT_COMMENT_PATH } from "../constants/firebasePaths";
import { useSessionParams } from "../contexts";
import { SessionParamsEnum } from "../enums";


export const useLastConnected = () => {
    const sessionParams = useSessionParams();
    const sessionID = SessionParamsEnum.SessionID.getContextValue(sessionParams);
    const database = useDatabase();


    useEffect(() => {
        if (sessionID != null) {
            const sessionLastConnectedRef = ref(database, SESSION_PATH + sessionID + "/lastClosed");

            window.addEventListener("beforeunload", (ev) => {
                console.log("Unload!", sessionID, serverTimestamp());

                set(sessionLastConnectedRef, serverTimestamp());
            });

            
            return () => {
                window.removeEventListener("beforeunload", (ev) => {
                    console.log("Removed!")
                });
            }
        }

    }, [sessionID])

    return;
}