import React, { createContext, useReducer, useContext } from "react";


const DevParams = createContext(undefined);

function devParamsReducer(state, action) {
    switch (action.type) {

        case 'setFamily': {
            let data = {};
            if (sessionStorage.getItem("devParams") != null) {
                data = JSON.parse(sessionStorage.getItem("devParams"));
            }

            data['family'] = action.payload;
            sessionStorage.setItem('devParams', JSON.stringify(data));
            return { val: data }
        }
        case 'setHotCold': {
            let data = {};
            if (sessionStorage.getItem("devParams") != null) {
                data = JSON.parse(sessionStorage.getItem("devParams"));
            }

            data['hotCold'] = action.payload;
            sessionStorage.setItem('devParams', JSON.stringify(data));
            return { val: data }
        }

        case 'setCheckedFlex': {
            let data = {};
            if (sessionStorage.getItem("devParams") != null) {
                data = JSON.parse(sessionStorage.getItem("devParams"));
            }

            data['checkedFlex'] = action.payload;
            sessionStorage.setItem('devParams', JSON.stringify(data));
            return { val: data }
        }
        case 'setCheckedGreen': {
            let data = {};
            if (sessionStorage.getItem("devParams") != null) {
                data = JSON.parse(sessionStorage.getItem("devParams"));
            }

            data['checkedGreen'] = action.payload;
            sessionStorage.setItem('devParams', JSON.stringify(data));
            return { val: data }
        }
        case 'setCheckedExtreme': {
            let data = {};
            if (sessionStorage.getItem("devParams") != null) {
                data = JSON.parse(sessionStorage.getItem("devParams"));
            }

            data['checkedExtreme'] = action.payload;
            sessionStorage.setItem('devParams', JSON.stringify(data));
            return { val: data }
        }
        case 'setAdjustValue': {
            let data = {};
            if (sessionStorage.getItem("devParams") != null) {
                data = JSON.parse(sessionStorage.getItem("devParams"));
            }

            data['adjustValue'] = action.payload;
            sessionStorage.setItem('devParams', JSON.stringify(data));
            return { val: data }
        }

        case 'setDevMode': {
            let data = {};
            if (sessionStorage.getItem("devParams") != null) {
                data = JSON.parse(sessionStorage.getItem("devParams"));
            }

            data['devMode'] = action.payload;
            sessionStorage.setItem('devParams', JSON.stringify(data));
            return { val: data }
        }

        case 'setError': {
            let data = {};
            if (sessionStorage.getItem("devParams") != null) {
                data = JSON.parse(sessionStorage.getItem("devParams"));
            }

            data['error'] = action.payload;
            sessionStorage.setItem('devParams', JSON.stringify(data));
            return { val: data }
        }
        case 'clearError': {
            let data = {};
            if (sessionStorage.getItem("devParams") != null) {
                data = JSON.parse(sessionStorage.getItem("devParams"));
            }

            delete data.error;
            sessionStorage.setItem('devParams', JSON.stringify(data));
            return { val: data }
        }
        case 'clear': {
            sessionStorage.removeItem('devParams');
            return { val: {} }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function DevBarProvider({ children }) {
    let obj = {};
    if (sessionStorage.getItem('devParams') != null) {
        obj = JSON.parse(sessionStorage.getItem('devParams'));
    }
    else {
        obj = { devMode: false }
        sessionStorage.setItem('devParams', JSON.stringify(obj));
    }

    const [state, dispatch] = useReducer(devParamsReducer, { val: obj })
    const value = { state, dispatch }

    return (
        <DevParams.Provider value={value}>
            {children}
        </DevParams.Provider>
    );
}

function useDevbarParams() {
    const context = useContext(DevParams)
    if (context === undefined) {
        throw new Error('useDriftParams must be used within a DriftParams')
    }
    return context
}


export { DevBarProvider, useDevbarParams };