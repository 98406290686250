import { Button, Fab } from "@mui/material"
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import './sparkleBtnEffect.css'

export const SparkleWizardButton = ({ handleOpen }) => {

    return (
        <Fab sx={{ position: 'absolute', marginLeft: '1020px', marginTop: '-25px' }} onClick={handleOpen} size="small" color="primary" >
            <AutoFixHighIcon />
            <div className="star-fade">
                <div className="star-1">
                    <svg
                        viewBox="0 0 784.11 815.53"
                        version="1.1"
                    >
                        <g>
                            <path d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z" className="fil0"></path>
                        </g>
                    </svg>
                </div>

                <div className="star-2">
                    <svg
                        viewBox="0 0 784.11 815.53"
                        version="1.1"
                    >
                        <g>
                            <path d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z" className="fil1"></path>
                        </g>
                    </svg>
                </div>

                <div className="star-3">
                    <svg
                        viewBox="0 0 784.11 815.53"
                        version="1.1"
                    >
                        <g>
                            <path d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z" className="fil1"></path>
                        </g>
                    </svg>
                </div>

                <div className="star-4">
                    <svg
                        viewBox="0 0 784.11 815.53"
                        version="1.1"
                    >
                        <g>
                            <path d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z" className="fil0"></path>
                        </g>
                    </svg>
                </div>

                <div className="star-5">
                    <svg
                        viewBox="0 0 784.11 815.53"
                        version="1.1"
                    >
                        <g>
                            <path d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z" className="fil1"></path>
                        </g>
                    </svg>
                </div>

                <div className="star-6">
                    <svg
                        viewBox="0 0 784.11 815.53"
                        version="1.1"
                    >
                        <g>
                            <path d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z" className="fil0"></path>
                        </g>
                    </svg>
                </div>
            </div>
        </Fab>
    )
}