import { httpsCallable } from "firebase/functions"

const sendRequestOfferMail = function sendRequestOfferMail(functions, sessionID, message, baseUrl, senderMail, senderPhone, senderComp, senderRef, senderOrgNr, senderAdress, senderPostalNr, senderPostalCity, facility, projNr, reciverEmail = null) {
    functions.region = 'europe-west1';
    const sendMail = httpsCallable(functions, "sendOfferRequestMail");

    return sendMail({ 
        sessionID: sessionID,
        message: message, 
        baseUrl: baseUrl, 
        senderMail: senderMail,
        senderPhone: senderPhone,
        senderComp: senderComp,
        senderRef: senderRef,
        senderOrgNr: senderOrgNr,
        senderAdress: senderAdress,
        senderPostalNr: senderPostalNr,
        senderPostalCity: senderPostalCity,
        devEmail: reciverEmail,
        facility: facility,
        projNr: projNr,
    });
}

export { sendRequestOfferMail }