import { useState } from "react";
import { SupportModule } from "../../components/modules/support/supportModal";

export const News = () => {
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => { setOpenModal(true) }
    const handleCloseModal = () => { setOpenModal(false) }

    const btnStyle = {
        'background': 'none',
        'border': 'none',
        'padding': '0',
        'color': '#003eaa',
        'fontWeight': 'bold',
        'textDecoration': 'underline',
        'cursor': 'pointer',
    }

    return (
        <>
            <div>
                <h2 className="intro-title" style={{ marginBlockStart: '0px' }}>Nyheter i ShuntLogik<sup>®</sup> 1.5</h2>

                <p>ShuntLogik<sup>®</sup> 1.5 är resultatet av ett långt och gediget arbete med att framtidssäkra och vidareutveckla vårt uppskattade beräkningsverktyg, och för med sig en stor mängd nyheter, stort som smått.</p>

                <p>
                    Som med allt annat kommer det säkert kännas lite ovant och främmande i början, men vi har haft våra kunders och andra användares intressen som huvudfokus i utvecklingsarbetet, och när du väl bekantat dig med det uppdaterade användargränssnittet och tillkomna verktygen så är vi övertygade om att du kommer ha en förenklad och förbättrad upplevelse av dimensionering med ShuntLogik®. Fram till dess är du givetvis välkommen att kontakta oss på PREMA för råd och vägledning, eller läsa mer i respektive avsnitt i Introduktionsanvisningen.
                </p>

                <p>De största nyheterna i ShuntLogik<sup>®</sup> 1.5 inkluderar</p>
                
                <ul>
                    <li>Sessionshantering: Möjlighet att granska, arbeta vidare med eller dela körningar, som inte längre försvinner per automatik när webbläsaren stängs</li>
                    <li>Ingen tidsgräns för hur länge en körning kan vara öppen i webbläsaren innan den nollställs</li>
                    <li>Introduktion av Översikts-fönstret, som kan nås när som helst i en körning, för att få en överblick över positioner i körningen, byta mellan dem, lägga till nya positioner, med mera. Positionerna måste inte längre göras klara en och en, utan det går att ha flera parallella pågående konfigureringar samtidigt.</li>
                    <li>Snabbväxling mellan positioner i körningen</li>
                    <li>Stöd för dimensionering av den nya produktlinjen Premablock<sup>®</sup> smart</li>
                    <li>Stöd för dimensionering av den nya produktlinjen Premablock<sup>®</sup> green II</li>
                    <li>Snabbredigering av vissa systemparametrar med omberäkning av flera positioner samtidigt</li>
                </ul>

                <p>Bland den stora mängd övriga nyheter och förbättringar i och med ShuntLogik<sup>®</sup> 1.5 ingår bland annat</p>

                <ul>
                    <li>Tydligare uppdelning mellan kritiska felmeddelanden och icke-kritiska notismeddelanden i beräkningssteget</li>
                    <li>Stöd för att enklare identifiera konfigureringsval som är mer miljövänliga än standard</li>
                    <li>Större frihet för expertanvändare att göra val kring placering av backventiler, så som exempelvis möjligheten att välja SABO-utförande för PR-2.
                    </li>
                    <li>Pedagogisk översyn av fel- och notismeddelanden i beräkningssteget, för bättre stöd till mindre erfarna användare</li>
                    <li>Möjlighet att välja fabrikat på injusteringsventiler även i green I-utföranden</li>
                    <li>Uppdaterade sortiment från leverantörer vars komponenter är valbara i ShuntLogik<sup>®</sup></li>
                </ul>

                <p>Inte minst så innebär ShuntLogik<sup>®</sup> 1.5 att grunden är lagd för en snabbare och flexiblare vidareutveckling, så se till att hålla ögonen öppna efter versionsuppdateringar, och ta en titt vilka nyheter och förbättringar som tillkommit!</p>

                <p>Vi tar tacksamt emot <button style={btnStyle} onClick={handleOpenModal}>tips och synpunkter</button>, så kanske just dina önskemål kommer finnas med i förteckningen över framtida versionsnyheter!</p>
            </div>
            
            <SupportModule openModal={openModal} handleModalClose={handleCloseModal} />
        </>
    )
}