export const KV_KEY = 'kv';
export const KVS_KEY = 'kvs';
export const BALANCING_KV_KEY = 'balancingKv';
export const AUTHORITY_KEY = 'authority';
export const SV_PRESSURE_DROP_KEY = 'svPressureDrop';
export const PRIM_INTERNAL_PRESSURE_DROP_KEY = 'primInternalPressureDrop';
export const SEC_INTERNAL_PRESSURE_DROP_KEY = 'secInternalPressureDrop';
export const SHUNT_TYPE_KEY = 'shuntType';
export const TOTAL_PRESSURE_KEY = 'totalPressure';
export const PRIM_DIM_KEY = 'primDim';
export const SEC_DIM_KEY = 'sekDim';
export const PRIM_DIM_ROW_KEY = 'primDimRow';
export const SEC_DIM_ROW_KEY = 'sekDimRow';
export const ADJ_VALVE_KEY = 'adjValve';
export const CONTROL_VALVE_KEY = 'controlValve';
export const SV_DIM_KEY = 'svDim';
export const SV_DIM_OBJ_KEY = 'svDimObj';
export const SV_MAX_KEY = 'svMax';
export const SV_SETTING_KEY = 'svSetting';
export const MIN_PRESSURE_KEY = 'minPressure';
export const PRIM_INTERNAL_DP_KEY = 'primInternalDP';
export const MEASUREMENTDATA_KEY = 'measurementData'