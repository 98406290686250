import { ref, set } from "firebase/database";
import { SESSION_SHUNT_PATH } from "../../constants/firebasePaths";
import { ErrorEnum, NoticeEnum, SessionParamsEnum } from "../../enums";

const saveNoticesAndErrors = function saveNoticesAndErrors(database, sessionParams, noticeContext, errorContext) {
    const ErrorList = new ErrorEnum();
    const NoticeList = new NoticeEnum();
    
    const shuntID = SessionParamsEnum.CurrentShunt.getContextValue(sessionParams);
    const driftParamsRef = ref(database, SESSION_SHUNT_PATH + "/" + shuntID + "/MsgLists");

    let snapData = {
        notices: NoticeList.getNoticeList(noticeContext),
        errors: ErrorList.getErrorList(errorContext),
    }

    const promises = [];

    if (SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true){
        promises.push(set(driftParamsRef, snapData));
    }
    

    return Promise.all(promises);
}

const saveNoticesAndErrorsLocal = function saveNoticesAndErrorsLocal(database, shuntID, errorList, noticeList) {
    const driftParamsRef = ref(database, SESSION_SHUNT_PATH + "/" + shuntID + "/MsgLists");

    let snapData = {
        notices: noticeList,
        errors: errorList,
    }

    const promises = [];

    promises.push(set(driftParamsRef, snapData));

    return Promise.all(promises);
}

export { saveNoticesAndErrors, saveNoticesAndErrorsLocal } 