import { ref, update } from "firebase/database";
import { SessionParamsEnum, ShuntParamsEnum } from "../../enums";
import { SESSION_SHUNT_PATH, SHUNT_WIZARDDATA } from "../../constants/firebasePaths";
import { WizardParamEnum } from "../../enums/wizardParamsEnum";
import { WIZARD_APPLICATION_INTENT_KEY, WIZARD_APPLICATION_KEY, WIZARD_APPLICATION_SIZE_KEY, WIZARD_AVAIL_DRIVING_PRESSURE_KEY, WIZARD_CERTICIFACTIONS_KEY, WIZARD_DYNAMIC_CONTROL_VALVE_KEY, WIZARD_ENERGY_MEASUREMENT_KEY, WIZARD_HAS_MAIN_PUMP_KEY, WIZARD_LIMITED_SPACE_KEY, WIZARD_ONLY_DIMENSIONABLE_KEY, WIZARD_PRIORITIES_KEY, WIZARD_SECONDARY_FLOW_KEY, WIZARD_SECONDARY_FLOW_SPEC_VAL_KEY, WIZARD_SOURCE_FLOW_KEY, WIZARD_SOURCE_KEY, WIZARD_SPECIFIC_AVAIL_DRIVING_PRESSURE_KEY, WIZARD_STAINLESS_KEY, WIZARD_SYSTEM_FLUID_MAIN_KEY, WIZARD_SYSTEM_FLUID_PRIMARY_KEY, WIZARD_SYSTEM_FLUID_PRIMARY_MIX_RATIO_KEY, WIZARD_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_KEY, WIZARD_SYSTEM_FLUID_SECONDARY_KEY, WIZARD_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY, WIZARD_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_KEY, WIZARD_VARIABLE_AVAIL_DRIVING_PRESSURE_KEY } from "../../constants/keys/wizardKeys";


export const saveWizardData = (database, sessionParams, wizardParams, shuntID = null) => {
    let localShuntID;
    if (shuntID == null || shuntID === ""){
        localShuntID = SessionParamsEnum.CurrentShunt.getContextValue(sessionParams);
    }
    else{
        localShuntID = shuntID;
    }

    const wizardParamsRef = ref(database, SESSION_SHUNT_PATH + localShuntID + SHUNT_WIZARDDATA);

    const snapData = {
        [WIZARD_APPLICATION_KEY]: WizardParamEnum.Application.getContextValue(wizardParams) || null,
        [WIZARD_APPLICATION_INTENT_KEY]: WizardParamEnum.ApplicationIntent.getContextValue(wizardParams) || null,
        [WIZARD_APPLICATION_SIZE_KEY]: WizardParamEnum.ApplicationSize.getContextValue(wizardParams) || null,
        [WIZARD_AVAIL_DRIVING_PRESSURE_KEY]: WizardParamEnum.AvailDrivingPressure.getContextValue(wizardParams) || null,
        [WIZARD_CERTICIFACTIONS_KEY]: WizardParamEnum.Certifications.getContextValue(wizardParams) || null,
        [WIZARD_DYNAMIC_CONTROL_VALVE_KEY]: WizardParamEnum.DynamicControlValve.getContextValue(wizardParams) || null,
        [WIZARD_ENERGY_MEASUREMENT_KEY]: WizardParamEnum.EnergyMeasurement.getContextValue(wizardParams) || null,
        [WIZARD_HAS_MAIN_PUMP_KEY]: WizardParamEnum.HasMainPump.getContextValue(wizardParams) || null,
        [WIZARD_LIMITED_SPACE_KEY]: WizardParamEnum.LimitedSpace.getContextValue(wizardParams) || null,
        [WIZARD_ONLY_DIMENSIONABLE_KEY]: WizardParamEnum.OnlyDimensionable.getContextValue(wizardParams) || null,
        [WIZARD_PRIORITIES_KEY]: WizardParamEnum.Priorities.getContextValue(wizardParams) || null,
        [WIZARD_SECONDARY_FLOW_KEY]: WizardParamEnum.SecondaryFlow.getContextValue(wizardParams) || null,
        [WIZARD_SECONDARY_FLOW_SPEC_VAL_KEY]: WizardParamEnum.SecondaryFlowSpecVal.getContextValue(wizardParams) || null,
        [WIZARD_SOURCE_KEY]: WizardParamEnum.Source.getContextValue(wizardParams) || null,
        [WIZARD_SOURCE_FLOW_KEY]: WizardParamEnum.SourceFlow.getContextValue(wizardParams) || null,
        [WIZARD_SPECIFIC_AVAIL_DRIVING_PRESSURE_KEY]: WizardParamEnum.SpecificAvailDrivingPressure.getContextValue(wizardParams) || null,
        [WIZARD_VARIABLE_AVAIL_DRIVING_PRESSURE_KEY]: WizardParamEnum.VariableAvailDrivingPressure.getContextValue(wizardParams) || null,
        [WIZARD_STAINLESS_KEY]: WizardParamEnum.Stainless.getContextValue(wizardParams) || null,
        [WIZARD_SYSTEM_FLUID_MAIN_KEY]: WizardParamEnum.SystemFluidMain.getContextValue(wizardParams) || null,
        [WIZARD_SYSTEM_FLUID_PRIMARY_KEY]: WizardParamEnum.SystemFluidPrimary.getContextValue(wizardParams) || null,
        [WIZARD_SYSTEM_FLUID_PRIMARY_MIX_RATIO_KEY]: WizardParamEnum.SystemFluidPrimaryMixRatio.getContextValue(wizardParams) || null,
        [WIZARD_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_KEY]: WizardParamEnum.SystemFluidPrimaryMixRatioUnknown.getContextValue(wizardParams) || null,
        [WIZARD_SYSTEM_FLUID_SECONDARY_KEY]: WizardParamEnum.SystemFluidSecondary.getContextValue(wizardParams) || null,
        [WIZARD_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY]: WizardParamEnum.SystemFluidSecondaryMixRatio.getContextValue(wizardParams) || null,
        [WIZARD_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_KEY]: WizardParamEnum.SystemFluidSecondaryMixRatioUnknown.getContextValue(wizardParams) || null,
    }

    const promises = [];

    if (Object.keys(snapData).length !== 0 && SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true) {
        promises.push(update(wizardParamsRef, snapData));
    }


    return Promise.all(promises);
}
