import { doc, getDoc } from "firebase/firestore";
import { PRODUCTFAMILY_PR, PRODUCTFAMILY_SR, PRODUCTFAMILY_VA } from "../../../constants/productlineConstants";

const getPriceGreenValue = async function getPriceGreenValue(firestore, prodFamily, dim) {
    let greenDoc;

    if (prodFamily === PRODUCTFAMILY_SR || prodFamily === PRODUCTFAMILY_PR) {
        greenDoc = doc(firestore, "/PriceValues/greenAddon/options/SR_PR");
    }
    else if (prodFamily === PRODUCTFAMILY_VA) {
        greenDoc = doc(firestore, "/PriceValues/greenAddon/options/VA");
    }
    else {
        console.error("Not accepted prodfamily: ", prodFamily);
        return;
    }

    const res = await getDoc(greenDoc);
    
    //console.log("Res:", dim, res.data().basePrice[dim], res.data().enumFactor);
    return Math.round(Number(res.data().basePrice[dim] * Number(res.data().enumFactor)));
}

export { getPriceGreenValue }