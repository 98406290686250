
import { ConnectionTypeListInterface } from './types'
import { connectionTypeImageDataList } from './connOptionsImageData'
import { connectionTypeInputDataList } from './connOptionsInputData';
import { connectionTypeInfoDataList } from './connOptionsInfoData';
import { connectionTypeFilterDataList } from './connOptionsFilterData';
import { connectionTypeCustomOptionsList } from './connOptionsCustomData';
import { connectionTypeSpecificationDataList } from './connOptionsSpecificationData';

export const connectionTypeList: ConnectionTypeListInterface = {
    "SR-2": {
        id: "SR-2",
        InfoData: connectionTypeInfoDataList['SR-2'],
        ImageData: connectionTypeImageDataList['SR-2'],
        FilterData: connectionTypeFilterDataList['SR-2'],
        InputData: connectionTypeInputDataList['SR-2'],
        SpecData: connectionTypeSpecificationDataList['SR-2'],
        CustomOptions: connectionTypeCustomOptionsList['SR-2'],
    },
    "SR-2 flex Appshunt": {
        id: "SR-2 flex Appshunt",
        InfoData: connectionTypeInfoDataList['SR-2 flex Appshunt'],
        ImageData: connectionTypeImageDataList['SR-2 flex Appshunt'],
        FilterData: connectionTypeFilterDataList['SR-2 flex Appshunt'],
        InputData: connectionTypeInputDataList['SR-2 flex Appshunt'],
        SpecData: connectionTypeSpecificationDataList['SR-2 flex Appshunt'],
        CustomOptions: connectionTypeCustomOptionsList['SR-2 flex Appshunt'],
    },
    "SR-3": {
        id: "SR-3",
        InfoData: connectionTypeInfoDataList['SR-3'],
        ImageData: connectionTypeImageDataList['SR-3'],
        FilterData: connectionTypeFilterDataList['SR-3'],
        InputData: connectionTypeInputDataList['SR-3'],
        SpecData: connectionTypeSpecificationDataList['SR-3'],
        CustomOptions: connectionTypeCustomOptionsList['SR-3'],
    },
    "PR-2": {
        id: "PR-2",
        InfoData: connectionTypeInfoDataList['PR-2'],
        ImageData: connectionTypeImageDataList['PR-2'],
        FilterData: connectionTypeFilterDataList['PR-2'],
        InputData: connectionTypeInputDataList['PR-2'],
        SpecData: connectionTypeSpecificationDataList['PR-2'],
        CustomOptions: connectionTypeCustomOptionsList['PR-2'],
    },
    "PR-2 Appshunt": {
        id: "PR-2 Appshunt",
        InfoData: connectionTypeInfoDataList['PR-2 Appshunt'],
        ImageData: connectionTypeImageDataList['PR-2 Appshunt'],
        FilterData: connectionTypeFilterDataList['PR-2 Appshunt'],
        InputData: connectionTypeInputDataList['PR-2 Appshunt'],
        SpecData: connectionTypeSpecificationDataList['PR-2 Appshunt'],
        CustomOptions: connectionTypeCustomOptionsList['PR-2 Appshunt'],
    },
    "PR-3": {
        id: "PR-3",
        InfoData: connectionTypeInfoDataList['PR-3'],
        ImageData: connectionTypeImageDataList['PR-3'],
        FilterData: connectionTypeFilterDataList['PR-3'],
        InputData: connectionTypeInputDataList['PR-3'],
        SpecData: connectionTypeSpecificationDataList['PR-3'],
        CustomOptions: connectionTypeCustomOptionsList['PR-3'],
    },
    "VA-1": {
        id: "VA-1",
        InfoData: connectionTypeInfoDataList['VA-1'],
        ImageData: connectionTypeImageDataList['VA-1'],
        FilterData: connectionTypeFilterDataList['VA-1'],
        InputData: connectionTypeInputDataList['VA-1'],
        SpecData: connectionTypeSpecificationDataList['VA-1'],
        CustomOptions: connectionTypeCustomOptionsList['VA-1'],
    },
    "FBU-5K": {
        id: "FBU-5K",
        InfoData: connectionTypeInfoDataList['FBU-5K'],
        ImageData: connectionTypeImageDataList['FBU-5K'],
        FilterData: connectionTypeFilterDataList['FBU-5K'],
        InputData: connectionTypeInputDataList['FBU-5K'],
        SpecData: connectionTypeSpecificationDataList['FBU-5K'],
        CustomOptions: connectionTypeCustomOptionsList['FBU-5K'],
    },
    "FBU-5V": {
        id: "FBU-5V",
        InfoData: connectionTypeInfoDataList['FBU-5V'],
        ImageData: connectionTypeImageDataList['FBU-5V'],
        FilterData: connectionTypeFilterDataList['FBU-5V'],
        InputData: connectionTypeInputDataList['FBU-5V'],
        SpecData: connectionTypeSpecificationDataList['FBU-5V'],
        CustomOptions: connectionTypeCustomOptionsList['FBU-5V'],
    },
    "FKU-5K": {
        id: "FKU-5K",
        InfoData: connectionTypeInfoDataList['FKU-5K'],
        ImageData: connectionTypeImageDataList['FKU-5K'],
        FilterData: connectionTypeFilterDataList['FKU-5K'],
        InputData: connectionTypeInputDataList['FKU-5K'],
        SpecData: connectionTypeSpecificationDataList['FKU-5K'],
        CustomOptions: connectionTypeCustomOptionsList['FKU-5K'],
    },
    "SRX-42": {
        id: "SRX-42",
        InfoData: connectionTypeInfoDataList['SRX-42'],
        ImageData: connectionTypeImageDataList['SRX-42'],
        FilterData: connectionTypeFilterDataList['SRX-42'],
        InputData: connectionTypeInputDataList['SRX-42'],
        SpecData: connectionTypeSpecificationDataList['SRX-42'],
        CustomOptions: connectionTypeCustomOptionsList['SRX-42'],
    },
    "SRX-43": {
        id: "SRX-43",
        InfoData: connectionTypeInfoDataList['SRX-43'],
        ImageData: connectionTypeImageDataList['SRX-43'],
        FilterData: connectionTypeFilterDataList['SRX-43'],
        InputData: connectionTypeInputDataList['SRX-43'],
        SpecData: connectionTypeSpecificationDataList['SRX-43'],
        CustomOptions: connectionTypeCustomOptionsList['SRX-43'],
    },
    "PRX-43": {
        id: "PRX-43",
        InfoData: connectionTypeInfoDataList['PRX-43'],
        ImageData: connectionTypeImageDataList['PRX-43'],
        FilterData: connectionTypeFilterDataList['PRX-43'],
        InputData: connectionTypeInputDataList['PRX-43'],
        SpecData: connectionTypeSpecificationDataList['PRX-43'],
        CustomOptions: connectionTypeCustomOptionsList['PRX-43'],
    },
    "PRUX-43FK": {
        id: "PRUX-43FK",
        InfoData: connectionTypeInfoDataList['PRUX-43FK'],
        ImageData: connectionTypeImageDataList['PRUX-43FK'],
        FilterData: connectionTypeFilterDataList['PRUX-43FK'],
        InputData: connectionTypeInputDataList['PRUX-43FK'],
        SpecData: connectionTypeSpecificationDataList['PRUX-43FK'],
        CustomOptions: connectionTypeCustomOptionsList['PRUX-43FK'],
    },
    "SRBX-62VK": {
        id: "SRBX-62VK",
        InfoData: connectionTypeInfoDataList['SRBX-62VK'],
        ImageData: connectionTypeImageDataList['SRBX-62VK'],
        FilterData: connectionTypeFilterDataList['SRBX-62VK'],
        InputData: connectionTypeInputDataList['SRBX-62VK'],
        SpecData: connectionTypeSpecificationDataList['SRBX-62VK'],
        CustomOptions: connectionTypeCustomOptionsList['SRBX-62VK'],
    },
    "SRBX-63VK": {
        id: "SRBX-63VK",
        InfoData: connectionTypeInfoDataList['SRBX-63VK'],
        ImageData: connectionTypeImageDataList['SRBX-63VK'],
        FilterData: connectionTypeFilterDataList['SRBX-63VK'],
        InputData: connectionTypeInputDataList['SRBX-63VK'],
        SpecData: connectionTypeSpecificationDataList['SRBX-63VK'],
        CustomOptions: connectionTypeCustomOptionsList['SRBX-63VK'],
    },
    "PRBX-6VK": {
        id: "PRBX-6VK",
        InfoData: connectionTypeInfoDataList['PRBX-6VK'],
        ImageData: connectionTypeImageDataList['PRBX-6VK'],
        FilterData: connectionTypeFilterDataList['PRBX-6VK'],
        InputData: connectionTypeInputDataList['PRBX-6VK'],
        SpecData: connectionTypeSpecificationDataList['PRBX-6VK'],
        CustomOptions: connectionTypeCustomOptionsList['PRBX-6VK'],
    },
    "SRBX-6ECO": {
        id: "SRBX-6ECO",
        InfoData: connectionTypeInfoDataList['SRBX-6ECO'],
        ImageData: connectionTypeImageDataList['SRBX-6ECO'],
        FilterData: connectionTypeFilterDataList['SRBX-6ECO'],
        InputData: connectionTypeInputDataList['SRBX-6ECO'],
        SpecData: connectionTypeSpecificationDataList['SRBX-6ECO'],
        CustomOptions: connectionTypeCustomOptionsList['SRBX-6ECO'],
    },
    "SRBX-6VAV": {
        id: "SRBX-6VAV",
        InfoData: connectionTypeInfoDataList['SRBX-6VAV'],
        ImageData: connectionTypeImageDataList['SRBX-6VAV'],
        FilterData: connectionTypeFilterDataList['SRBX-6VAV'],
        InputData: connectionTypeInputDataList['SRBX-6VAV'],
        SpecData: connectionTypeSpecificationDataList['SRBX-6VAV'],
        CustomOptions: connectionTypeCustomOptionsList['SRBX-6VAV'],
    },
    "SRBX-6SPETS": {
        id: "SRBX-6SPETS",
        InfoData: connectionTypeInfoDataList['SRBX-6SPETS'],
        ImageData: connectionTypeImageDataList['SRBX-6SPETS'],
        FilterData: connectionTypeFilterDataList['SRBX-6SPETS'],
        InputData: connectionTypeInputDataList['SRBX-6SPETS'],
        SpecData: connectionTypeSpecificationDataList['SRBX-6SPETS'],
        CustomOptions: connectionTypeCustomOptionsList['SRBX-6SPETS'],
    },
    "SRBX-6NOD": {
        id: "SRBX-6NOD",
        InfoData: connectionTypeInfoDataList['SRBX-6NOD'],
        ImageData: connectionTypeImageDataList['SRBX-6NOD'],
        FilterData: connectionTypeFilterDataList['SRBX-6NOD'],
        InputData: connectionTypeInputDataList['SRBX-6NOD'],
        SpecData: connectionTypeSpecificationDataList['SRBX-6NOD'],
        CustomOptions: connectionTypeCustomOptionsList['SRBX-6NOD'],
    },
    "SRBX-6VAK": {
        id: "SRBX-6VAK",
        InfoData: connectionTypeInfoDataList['SRBX-6VAK'],
        ImageData: connectionTypeImageDataList['SRBX-6VAK'],
        FilterData: connectionTypeFilterDataList['SRBX-6VAK'],
        InputData: connectionTypeInputDataList['SRBX-6VAK'],
        SpecData: connectionTypeSpecificationDataList['SRBX-6VAK'],
        CustomOptions: connectionTypeCustomOptionsList['SRBX-6VAK'],
    },
    "SRBX-6VAK-Forbi": {
        id: "SRBX-6VAK-Forbi",
        InfoData: connectionTypeInfoDataList['SRBX-6VAK-Forbi'],
        ImageData: connectionTypeImageDataList['SRBX-6VAK-Forbi'],
        FilterData: connectionTypeFilterDataList['SRBX-6VAK-Forbi'],
        InputData: connectionTypeInputDataList['SRBX-6VAK-Forbi'],
        SpecData: connectionTypeSpecificationDataList['SRBX-6VAK-Forbi'],
        CustomOptions: connectionTypeCustomOptionsList['SRBX-6VAK-Forbi'],
    },
    "SRBX-8VAKK": {
        id: "SRBX-8VAKK",
        InfoData: connectionTypeInfoDataList['SRBX-8VAKK'],
        ImageData: connectionTypeImageDataList['SRBX-8VAKK'],
        FilterData: connectionTypeFilterDataList['SRBX-8VAKK'],
        InputData: connectionTypeInputDataList['SRBX-8VAKK'],
        SpecData: connectionTypeSpecificationDataList['SRBX-8VAKK'],
        CustomOptions: connectionTypeCustomOptionsList['SRBX-8VAKK'],
    },
    "SRBX-8VAKK-Forbi": {
        id: "SRBX-8VAKK-Forbi",
        InfoData: connectionTypeInfoDataList['SRBX-8VAKK-Forbi'],
        ImageData: connectionTypeImageDataList['SRBX-8VAKK-Forbi'],
        FilterData: connectionTypeFilterDataList['SRBX-8VAKK-Forbi'],
        InputData: connectionTypeInputDataList['SRBX-8VAKK-Forbi'],
        SpecData: connectionTypeSpecificationDataList['SRBX-8VAKK-Forbi'],
        CustomOptions: connectionTypeCustomOptionsList['SRBX-8VAKK-Forbi'],
    },
    "PBCS": {
        id: "PBCS",
        InfoData: connectionTypeInfoDataList['PBCS'],
        ImageData: connectionTypeImageDataList['PBCS'],
        FilterData: connectionTypeFilterDataList['PBCS'],
        InputData: connectionTypeInputDataList['PBCS'],
        SpecData: connectionTypeSpecificationDataList['PBCS'],
        CustomOptions: connectionTypeCustomOptionsList['PBCS'],
    },
};

