import { CalcParamsEnum } from "../enums/calcParamsEnum";
import { DriftParamEnum } from "../enums/driftParamsEnum";
import { ShuntParamsEnum } from "../enums/shuntParamsEnum";
import { calcDeltaPressure } from "./ShuntCalculations/baseFormulas/calcDeltaPressure";
import { calcAuthority } from "./ShuntCalculations/calculateAuthority";
import { flex_calcControlValveDimensions } from "./ShuntCalculations/flexCalculations/calculateControlValveDimension";
import { calcKvs } from "./ShuntCalculations/calculateKvs";
import { calcPrimPressureDrop, calcSecondaryPressureDrop } from "./ShuntCalculations/calculatePressureDrop";
import { calcPrimFlow } from "./ShuntCalculations/calculatePrimFlow";
import { ErrorMsg } from "../constants/errorMessages";
import { NoticeMsg } from "../constants/noticeMessages";

import { findCorrespondingDim, getDimRowFromName } from "./ShuntCalculations/helpFunctions/findCorrespondingDim";
import { getNextDimKvs, getNextLowerDimKvs, getNextSmartDimKvs } from "./ShuntCalculations/helpFunctions/getNextDimKvs";
import { setShuntType } from "./ShuntCalculations/setShuntType";
import { calcTotalPressure } from "./ShuntCalculations/calculateTotalPressure";
import { roundNumber, roundToDecimals } from "./ShuntCalculations/helpFunctions/roundTo4Decimals";
import { checkForFlowErrors, checkForPressureErrors, checkForShuntErrorsTemperature } from "./ShuntCalculations/errorChecks/checkForShuntErrors";
import { checkNumOfAdjustmentValves, checkNumOfCheckValves } from "./ShuntCalculations/calculateNumOfValves";
import { checkDriftParamsInput } from "./ShuntCalculations/errorChecks/checkDriftParamsInput";
import { SessionParamsEnum } from "../enums/sessionParamsEnum";
import { get, ref, set } from "firebase/database";
import { CONTROL_VALVES_DIMS_FLEX_DEFAULT_PATH, CONTROL_VALVES_DIMS_SMART_DEFAULT_PATH, CONTROL_VALVES_PATH, SESSION_SHUNT_PATH, SHUNT_CONNECTED_ENERGY_MODEL_PATH, SHUNT_CONTROLVALVE_PATH, SHUNT_CV_KVS_PATH, SHUNT_DRIFTPARAMS_FLOW_CALC_MODE, SHUNT_DRIFTPARAMS_PRIMDIM, SHUNT_DRIFTPARAMS_SECONDARY_EFFECT, SHUNT_DRIFTPARAMS_SEKDIM, SHUNT_DRIFTPARAMS_USE_CONNECTED_ENERGY_PATH, SHUNT_FLEXDIM_ID_PATH, SHUNT_FLEXMODEL_PATH, SHUNT_SMART_SV_PATH, SHUNT_WIZARDDATA_SYSTEM_FLUID_MAIN_PATH, SHUNT_WIZARDDATA_SYSTEM_FLUID_PRIMARY_MIX_RATIO_PATH, SHUNT_WIZARDDATA_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_PATH } from "../constants/firebasePaths";
import { collection, getDocs } from "firebase/firestore";
import { saveNoticesAndErrorsLocal } from "../firebase/functions/saveNoticesAndErrors";
import { PRODUCTFAMILY_PR, PRODUCTFAMILY_SR, PRODUCTFAMILY_VA, PRODUCTLINE_FLEX, PRODUCTLINE_GREEN, PRODUCTLINE_SMART, PRODUCTPRINCIP_PR2, PRODUCTPRINCIP_PR3, PRODUCTPRINCIP_SR2, PRODUCTPRINCIP_SR2FLEX, PRODUCTPRINCIP_VA1 } from "../constants/productlineConstants";
import { CV_IMI, CV_MODEL_TASMART, CV_MODEL_KVS, CV_SOE, CV_MODEL_TACOMPACT_P, COOL_SHUNT } from "../constants/shuntOptions";
import { calculateBalancingKV } from "./ShuntCalculations/calculateBalancingKV";
import { calcInternalPrimDP } from "./ShuntCalculations/calcInternalPrimDP";
import { isGreenLevel2 } from "./ShuntCalculations/isGreenLevel2";
import { ProductLineEnum } from "../enums";
import { getSpecialFlexDim } from "../firebase/functions/getSpecialFlexDim";
import { SYSTEM_FLUID } from "../constants/wizardConstants";
import { calcEffectFromFlow, calcFlowFromEffect } from "./ShuntCalculations/calcFlowAndEffect";

/**
 * 
 * @param {*} dimensionData The DimensionsData about the Dims
 * @returns 
 */
const calculateShuntParamsFromFB = async function calculateShuntParamsFromFB(shuntID, dimensionData, database, firestore, sessionParams, coolingEnergyMeasurementList, heatEnergyMeasurementList, systemFluidOptions) {
    const localErrorList = {};
    const localNoticeList = {};

    const shuntRef = ref(database, SESSION_SHUNT_PATH + shuntID);
    const shuntInfo = await get(shuntRef);

    //Productline Params
    const plPrincipVal = shuntInfo.child("PLData/princip").val();
    const plProdFamilyVal = String(shuntInfo.child("PLData/princip").val()).substring(0, 2);
    const plProdLine = shuntInfo.child("PLData/prodLine").val();
    let plGreenLevel = shuntInfo.child("PLData/greenLevel").val();

    //Shunt Params
    const hasLatch = shuntInfo.child("ShuntData/hasLatch").val();
    const IsSabo = shuntInfo.child("ShuntData/isSabo").val();
    const isCustom = shuntInfo.child("Baseinfo/isCustom").val();
    //const designation = shuntInfo.child("Baseinfo/designation").val();
    //const cvModel = shuntInfo.child("ShuntData/cvModel").val();
    const cvIsInterval = shuntInfo.child("ShuntData/cvIsInterval").val();
    const adjustValve = shuntInfo.child("ShuntData/adjValve").val();

    //Drift Params
    let calcMode = 1;
    if (shuntInfo.child("DriftParams/calcMode").exists()) {
        calcMode = Number(shuntInfo.child("DriftParams/calcMode").val());
    }

    let primTempOut = shuntInfo.child("DriftParams/primTempOut").val();
    let primTempIn = shuntInfo.child("DriftParams/primTempIn").val();
    let secTempOut = shuntInfo.child("DriftParams/secTempOut").val();
    let secTempIn = shuntInfo.child("DriftParams/secTempIn").val();
    let secondaryFlow = shuntInfo.child("DriftParams/secFlow").val();
    let primDrivingPressure = shuntInfo.child("DriftParams/primDrivingPressure").val();
    let primExternalPressureDrop = shuntInfo.child("DriftParams/primExternalPressureDrop").val();
    let secExternalPressureDrop = shuntInfo.child("DriftParams/secExternalPressureDrop").val();
    const ivMod = shuntInfo.child("DriftParams/ivMod").val();
    const hasMainPumpVal = shuntInfo.child("DriftParams/hasMainPump").val();
    const pEfk = shuntInfo.child("DriftParams/pEfk").val();

    const numBackVents = shuntInfo.child("ShuntData/numBV").val();
    let controlValve = shuntInfo.child("ShuntData/controlValve").val();
    let cvModel = shuntInfo.child("ShuntData/cvModel").val();

    const systemFluid = shuntInfo.child(SHUNT_WIZARDDATA_SYSTEM_FLUID_MAIN_PATH).val();
    const systemFluidMixRatio = shuntInfo.child(SHUNT_WIZARDDATA_SYSTEM_FLUID_PRIMARY_MIX_RATIO_PATH).val();
    const systemFluidMixRatioUnknown = shuntInfo.child(SHUNT_WIZARDDATA_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_PATH).val();

    const systemFluidObj = systemFluidOptions.find((obj) => obj.value === systemFluid);

    const useConnectedEnergy = shuntInfo.child(SHUNT_DRIFTPARAMS_USE_CONNECTED_ENERGY_PATH).val();
    let connectedEnergyModel = shuntInfo.child(SHUNT_CONNECTED_ENERGY_MODEL_PATH).val();

    let flowCalcMode = shuntInfo.child(SHUNT_DRIFTPARAMS_FLOW_CALC_MODE).val();
    let secondaryEffect = shuntInfo.child(SHUNT_DRIFTPARAMS_SECONDARY_EFFECT).val();

    // eslint-disable-next-line eqeqeq
    if (calcMode == undefined || isNaN(calcMode)) {
        calcMode = 1
    }

    let hasMainPump = true;
    if (hasMainPumpVal === "0") {
        hasMainPump = false;
    }

    let dimRow;
    let dimSekRow;
    let kvs = 0;
    let primInternalPressureDrop;
    let flexSettings = {};
    let minPressure;
    let maxPressure = 90;

    let smartDims = [];
    let smartDim;
    let primDimOrg;
    let smartDimOrg;
    let primPressureDropOrg;
    let kvsOrg;
    let hasIncreased = false;
    let smartMax = false;

    if (isCustom === true || isCustom === 'true') {
        return;
    }

    //Safechecking for empty fields
    let fieldsSet = checkDriftParamsInput(null, plPrincipVal, hasMainPump, secTempOut, secTempIn, secondaryFlow, secondaryEffect, primDrivingPressure, primExternalPressureDrop, secExternalPressureDrop, flowCalcMode, false, flowCalcMode);

    //Returns if one or more fields has not been set
    if (fieldsSet === false) {
        //Save ErrorList
        if (Object.keys(localErrorList).length > 0) {
            const dbCurStepRef = ref(database, SESSION_SHUNT_PATH + shuntID + "/Baseinfo/curStep");
            const dbMaxStepRef = ref(database, SESSION_SHUNT_PATH + shuntID + "/Baseinfo/maxStep");
            await set(dbCurStepRef, 2);
            await set(dbMaxStepRef, 2);
        }
        return;
    }

    //Decides if the Check Valve is on the primary or/and secondary side
    let primarySideBV = false;
    let secondarySideBV = false;
    if (plProdFamilyVal === PRODUCTFAMILY_SR || Number(IsSabo) === 1) {
        secondarySideBV = true;
    }
    else if (plProdFamilyVal === PRODUCTFAMILY_PR) {
        primarySideBV = true;
    }

    //Check if the calculation should use an extra BV on the primary side.
    let extraBV = false;
    if (hasLatch === 1 && plProdFamilyVal === PRODUCTFAMILY_SR) {
        extraBV = true;
    }

    //Check the number of Check valves and Adjustmentvalves
    let backVents = checkNumOfCheckValves(plPrincipVal, numBackVents);
    let numTotalIV = checkNumOfAdjustmentValves(plPrincipVal, ivMod);

    // eslint-disable-next-line eqeqeq
    if (primTempOut == undefined || isNaN(primTempOut)) {
        primTempOut = secTempOut;
    }

    // eslint-disable-next-line eqeqeq
    if (primTempIn == undefined || isNaN(primTempIn)) {
        primTempIn = secTempIn;
    }

    if (flowCalcMode === 'kw') {
        if (systemFluid !== SYSTEM_FLUID.other.value && systemFluid !== SYSTEM_FLUID.unknown.value && systemFluid !== SYSTEM_FLUID.different.value) {
            if (systemFluid === SYSTEM_FLUID.water.value || (systemFluidMixRatioUnknown !== true && systemFluidMixRatio != null && systemFluidMixRatio !== "")) {
                if (systemFluid === SYSTEM_FLUID.water.value) {
                    secondaryFlow = Math.abs(calcFlowFromEffect(Number(secondaryEffect), systemFluidObj, 100, Number(secTempOut), Number(secTempIn)));
                }
                else {
                    secondaryFlow = Math.abs(calcFlowFromEffect(Number(secondaryEffect), systemFluidObj, systemFluidMixRatio, Number(secTempOut), Number(secTempIn)));
                }

                DriftParamEnum.SecFlow.setToFirebase(database, shuntID, secondaryFlow);
            }
            else {
                DriftParamEnum.SecFlow.setToFirebase(database, shuntID, secondaryFlow);
            }
        }
        else {
            DriftParamEnum.SecFlow.setToFirebase(database, shuntID, secondaryFlow);
        }
    }
    else {
        if (systemFluid !== SYSTEM_FLUID.other.value && systemFluid !== SYSTEM_FLUID.unknown.value && systemFluid !== SYSTEM_FLUID.different.value) {
            if (systemFluid === SYSTEM_FLUID.water.value || (systemFluidMixRatioUnknown !== true && systemFluidMixRatio != null && systemFluidMixRatio !== "")) {
                if (systemFluid === SYSTEM_FLUID.water.value) {
                    secondaryEffect = Math.abs(calcEffectFromFlow(secondaryFlow, systemFluidObj, 100, Number(secTempOut), Number(secTempIn)));
                }
                else {
                    secondaryEffect = Math.abs(calcEffectFromFlow(secondaryFlow, systemFluidObj, systemFluidMixRatio, Number(secTempOut), Number(secTempIn)));
                }

                DriftParamEnum.SecFlowEffect.setToFirebase(database, shuntID, secondaryEffect);
            }
            else {
                DriftParamEnum.SecFlowEffect.setToFirebase(database, shuntID, secondaryEffect);
            }
        }
        else {
            DriftParamEnum.SecFlowEffect.setToFirebase(database, shuntID, secondaryEffect);
        }
    }

    const primFlow = calcPrimFlow(primTempIn, primTempOut, secTempIn, secTempOut, secondaryFlow);

    if (primFlow <= 0) {
        DriftParamEnum.PrimFlow.setToFirebase(database, shuntID, primFlow);

        //console.error(ErrorMsg.flow_nonexistingPrimFlow.msg);
        localErrorList[ErrorMsg.flow_nonexistingPrimFlow.key] = ErrorMsg.flow_nonexistingPrimFlow.msg;
        return;
    }
    else if (primFlow > 50) {
        DriftParamEnum.PrimFlow.setToFirebase(database, shuntID, primFlow);

        //console.error(ErrorMsg.flow_toHighPrimFlow.msg);
        localErrorList[ErrorMsg.flow_toHighPrimFlow.key] = ErrorMsg.flow_toHighPrimFlow.msg;
        return;
    }

    if (plProdLine === PRODUCTLINE_FLEX) {
        let model = shuntInfo.child(SHUNT_FLEXMODEL_PATH).val();
        let flexID = shuntInfo.child(SHUNT_FLEXDIM_ID_PATH).val();
        let controlValve = shuntInfo.child(SHUNT_CONTROLVALVE_PATH).val();

        let fsUrl = "";

        // eslint-disable-next-line eqeqeq
        if (controlValve != undefined && controlValve != "" && model != undefined && model != "" && calcMode === 0) {
            fsUrl = CONTROL_VALVES_PATH + controlValve + '/Models/' + model + "/Dims";
        }
        else {
            fsUrl = CONTROL_VALVES_DIMS_FLEX_DEFAULT_PATH;
        }


        const dimsDoc = collection(firestore, fsUrl);

        const firestoreDimRes = await getDocs(dimsDoc);

        const localModels = [];
        const localSpecialModels = await getSpecialFlexDim(firestore);

        if (firestoreDimRes.empty === false) {
            firestoreDimRes.docs.forEach(model => {
                localModels.push({ id: model.id, data: model.data() })
            });
        }

        //console.log("Here? ", localSpecialModels)
        flexSettings = flex_calcControlValveDimensions(primFlow, primDrivingPressure, localModels, model, flexID, null, null, calcMode, localSpecialModels, false, localErrorList, localNoticeList);
        if (flexSettings != null) {
            minPressure = flexSettings?.flexObj?.data?.minPressure;
            maxPressure = flexSettings?.flexObj?.data?.maxPressure;

            CalcParamsEnum.FlexSVDim.setToFirebase(database, shuntID, flexSettings.flexObj.data.display);
            CalcParamsEnum.FlexSVDimObj.setToFirebase(database, shuntID, flexSettings.flexObj.data);
            CalcParamsEnum.FlexSVMax.setToFirebase(database, shuntID, roundToDecimals(flexSettings.svOfMax, 2));
            CalcParamsEnum.FlexSVSetting.setToFirebase(database, shuntID, flexSettings.svSet);
            CalcParamsEnum.MinPressure.setToFirebase(database, shuntID, minPressure);

            if (flexSettings.isSpecial === true) {
                ShuntParamsEnum.FlexModel.setToFirebase(database, shuntID, CV_MODEL_TACOMPACT_P);
            }
        }

    }
    else if (plProdLine === PRODUCTLINE_SMART) {
        let fsUrlSmart = CONTROL_VALVES_DIMS_SMART_DEFAULT_PATH;

        const dimsDocSmart = collection(firestore, fsUrlSmart);
        const firestoreDimResSmart = await getDocs(dimsDocSmart);

        const localModels = [];
        if (firestoreDimResSmart.empty === false) {
            //console.log("SmartModels:", firestoreDimResSmart.docs)
            firestoreDimResSmart.docs.forEach(model => {
                localModels.push({ id: model.id, data: model.data() })
            });
        }
        smartDims = localModels;

        if (primFlow < 0.044) {
            DriftParamEnum.PrimFlow.setToFirebase(database, shuntID, primFlow);

            //console.error(ErrorMsg.smart_flowToLow.msg);
            localErrorList[ErrorMsg.smart_flowToLow.key] = ErrorMsg.smart_flowToLow.msg;
            return;
        }
        else if (primFlow > 31.1111) {
            DriftParamEnum.PrimFlow.setToFirebase(database, shuntID, primFlow);

            //console.error(ErrorMsg.smart_flowToHigh.msg);
            localErrorList[ErrorMsg.smart_flowToHigh.key] = ErrorMsg.smart_flowToHigh.msg;
            return;
        }
    }

    let shuntData = setShuntType(secTempIn, secTempOut);

    //Calc Connected Energy
    if (useConnectedEnergy === true) {

        if (calcMode === 1 || connectedEnergyModel == null || connectedEnergyModel === "") {
            let measurementList = [];
            let found = false;
            let foundObj;

            if (shuntData === COOL_SHUNT) {
                if (coolingEnergyMeasurementList != null) {
                    measurementList = coolingEnergyMeasurementList.sort((a, b) => a?.minFlow - b?.minFlow);
                }
            }
            else {
                if (heatEnergyMeasurementList != null) {
                    measurementList = heatEnergyMeasurementList.sort((a, b) => a?.minFlow - b?.minFlow);
                }
            }

            for (let index = 0; index < measurementList.length; index++) {
                const element = measurementList[index];
                if (found === false) {
                    if (primFlow > element?.minFlow && primFlow <= element?.maxFlow) {
                        found = true;
                        foundObj = element;
                    }
                    else if (primFlow > element?.maxFlow && index === measurementList.length - 1) {
                        if (primFlow <= element?.absMaxFlow) {
                            found = true;
                            foundObj = element;
                        }
                        else {
                            const primFlowHighError = ErrorMsg.connected_PrimFlowToHigh(element?.maxFlow);
                            localErrorList[primFlowHighError.key] = { msg: primFlowHighError.msg, val: element?.maxFlow };
                        }
                    }
                }
            }

            if (found === true) {
                connectedEnergyModel = foundObj;

                /*
                let extraDP = roundNumber(calcDeltaPressure(primFlow, Number(connectedEnergyModel?.kv)));

                if (extraDP> primDrivingPressure) {
                    const primFlowHighError = ErrorMsg.connected_PressureDropToHigh(extraDP);
                    localErrorList[primFlowHighError.key] = {msg: primFlowHighError.msg, val: extraDP};
                    return;
                }*/
            }
        }
        else if (calcMode === 0 && connectedEnergyModel != null && connectedEnergyModel !== "") {
            if (primFlow < Number(connectedEnergyModel?.absMinFlow)) {
                localErrorList[ErrorMsg.connected_ManualToLowForAbsFlow.key] = ErrorMsg.connected_ManualToLowForAbsFlow.msg;
            }
            else if (primFlow > Number(connectedEnergyModel?.absMaxFlow)) {
                localErrorList[ErrorMsg.connected_ManualToHighForAbsFlow.key] = ErrorMsg.connected_ManualToHighForAbsFlow.msg;
            }
        }
    }

    //Automatic mode
    if (calcMode === 1) {
        //Find corresponding Dimensions based on the given parameters for Primary and Secondary
        dimRow = findCorrespondingDim(dimensionData, primFlow, primDrivingPressure, kvs, false, primarySideBV, extraBV, hasMainPump, minPressure, cvIsInterval, plPrincipVal, plProdFamilyVal, plProdLine, calcMode, adjustValve, null, connectedEnergyModel, false, shuntID, database);
        dimSekRow = findCorrespondingDim(dimensionData, secondaryFlow, primDrivingPressure, kvs, true, primarySideBV, extraBV, hasMainPump, minPressure, cvIsInterval, plPrincipVal, plProdFamilyVal, plProdLine, calcMode, adjustValve, null, connectedEnergyModel, false, shuntID, database);

        // eslint-disable-next-line eqeqeq
        if (dimRow == undefined || dimSekRow == undefined) {
            //console.error(ErrorMsg.dimNotFound.msg);
            localErrorList[ErrorMsg.dimNotFound.key] = ErrorMsg.dimNotFound.msg;
            return;
        }

        if (plProdLine === PRODUCTLINE_SMART) {
            smartDim = smartDims.find(obj => obj.id === dimRow.smartSV);
            // eslint-disable-next-line eqeqeq
            if (smartDim != undefined) {
                if (smartDim.id === "DN125") {
                    smartMax = true;
                }
                kvs = smartDim.data.kvs;
            }
        }
        else {
            //Calculate the KVS value
            kvs = calcKvs(primFlow, secondaryFlow, primDrivingPressure, secExternalPressureDrop, plPrincipVal, plProdLine, dimRow, dimSekRow, dimensionData, cvIsInterval, extraBV);
        }

        //Reselect the primary dimension after the new KVS-value has been calculated
        dimRow = findCorrespondingDim(dimensionData, primFlow, primDrivingPressure, kvs, false, primarySideBV, extraBV, hasMainPump, minPressure, cvIsInterval, plPrincipVal, plProdFamilyVal, plProdLine, calcMode, adjustValve, null, connectedEnergyModel, false, shuntID, database);

        if (plProdLine === PRODUCTLINE_SMART) {
            smartDim = smartDims.find(obj => obj.id === dimRow.smartSV);
            // eslint-disable-next-line eqeqeq
            if (smartDim != undefined) {
                if (smartDim.id === "DN125") {
                    smartMax = true;
                }
                kvs = smartDim.data.kvs;
            }
        }

        //Calculate the primary drop value
        primInternalPressureDrop = calcPrimPressureDrop(primFlow, primDrivingPressure, dimRow, kvs, minPressure, primarySideBV, extraBV, cvIsInterval, plProdFamilyVal, plProdLine, calcMode, adjustValve, null, null, false, shuntID, database);

        /**
         * (SR Only) If the primary drop value is higher than the primary driving pressure. 
         * Increase the kvs one step higher and recalculate the Dim and primary drop again. 
         */
        if ((plProdFamilyVal === PRODUCTFAMILY_SR && plPrincipVal !== PRODUCTPRINCIP_SR2FLEX) && primInternalPressureDrop > Number(primDrivingPressure) && extraBV === false) {
            hasIncreased = true;
            primDimOrg = dimRow;
            smartDimOrg = smartDim;
            primPressureDropOrg = primInternalPressureDrop;
            kvsOrg = kvs;
            let count = 0;

            while (primInternalPressureDrop > Number(primDrivingPressure) && count < 5 && smartMax === false) {
                count++;
                let newKvs;
                if (plProdLine === PRODUCTLINE_SMART) {
                    newKvs = getNextSmartDimKvs(dimensionData, smartDims, smartDim.id)
                }
                else {
                    newKvs = getNextDimKvs(dimensionData, kvs);
                }


                if (newKvs !== null) {
                    kvs = newKvs;
                }
                else {
                    dimRow = primDimOrg;
                    smartDim = smartDimOrg;
                    primInternalPressureDrop = primPressureDropOrg;
                    kvs = kvsOrg;
                    hasIncreased = false;
                    break;
                }

                if (plProdLine === PRODUCTLINE_SMART) {
                    // eslint-disable-next-line no-loop-func
                    smartDim = smartDims.find(obj => obj.data.kvs === kvs);
                    // eslint-disable-next-line eqeqeq
                    if (smartDim != undefined) {
                        if (smartDim.id === "DN125") { //Currently max
                            smartMax = true;
                        }
                        if (primFlow < smartDim.data.qMin && primFlow > smartDim.data.qMax) {
                            //console.error("PrimFlow not matching")
                            break;
                        }
                    }
                }
                else {
                    dimRow = findCorrespondingDim(dimensionData, primFlow, primDrivingPressure, kvs, false, primarySideBV, extraBV, hasMainPump, minPressure, cvIsInterval, plPrincipVal, plProdFamilyVal, plProdLine, calcMode, adjustValve, null, connectedEnergyModel, false, shuntID, database);

                    // eslint-disable-next-line eqeqeq
                    if (dimRow == undefined) {
                        dimRow = primDimOrg;
                        smartDim = smartDimOrg;
                        primInternalPressureDrop = primPressureDropOrg;
                        kvs = kvsOrg;
                        hasIncreased = false;
                        break;
                    }
                }

                primInternalPressureDrop = calcPrimPressureDrop(primFlow, primDrivingPressure, dimRow, kvs, minPressure, primarySideBV, extraBV, cvIsInterval, plProdFamilyVal, plProdLine, calcMode, adjustValve, null, null, false, shuntID, database);
                //console.log("To High After: ", primInternalPressureDrop, '>', primDrivingPressure, primInternalPressureDrop > Number(primDrivingPressure), smartDim.id)
            }
        }

        // (SR Only) If the Primary dim is larger than the secondary. Increase the secondary to match the primary dim.
        if (plProdFamilyVal === PRODUCTFAMILY_SR) {
            if (dimRow.dim > dimSekRow.dim) {
                dimSekRow = dimRow;
            }
        }

        // SR-2-Smart Check
        if (plProdLine === PRODUCTLINE_SMART) {
            //Check if primary driving pressure is above the max of 400kPa
            if (primDrivingPressure > 400) {
                localErrorList[ErrorMsg.smart_highPressure.key] = ErrorMsg.smart_highPressure.msg;
            }
            else if (primFlow < smartDim.data.qMin) {
                if (hasIncreased === true) {
                    smartDim = smartDimOrg;
                    dimRow = primDimOrg;
                    primInternalPressureDrop = primPressureDropOrg;
                }

                const primPressureError = ErrorMsg.smart_PrimPressureToLow(primInternalPressureDrop);

                //console.error(primPressureError.msg);
                localErrorList[primPressureError.key] = primPressureError.msg;
            }
            /*
            else if (primFlow > smartDim.data.qMax) {
                if (hasIncreased === true) {
                    smartDim = smartDimOrg;
                    dimRow = primDimOrg;
                }

                console.error(ErrorMsg.smart_PrimPressureToHigh.msg);
                localErrorList[ErrorMsg.smart_PrimPressureToHigh.key] = ErrorMsg.smart_PrimPressureToHigh.msg;
            }*/
            else if (primInternalPressureDrop > Number(primDrivingPressure)) {
                const primPressureError = ErrorMsg.smart_PrimPressureToLow(primInternalPressureDrop);

                //console.error(primPressureError.msg);
                localErrorList[primPressureError.key] = primPressureError.msg;
            }
            else {
                if (primFlow <= (Number(smartDim.data.qMin) * 1.10)) {
                    //console.log(NoticeMsg.smart_flowCloseLow.msg);
                    localNoticeList[NoticeMsg.smart_flowCloseLow.key] = NoticeMsg.smart_flowCloseLow.msg;
                }
                else if (primFlow >= (Number(smartDim.data.qMax) * 0.9)) {
                    //console.log(NoticeMsg.smart_flowCloseHigh.msg);
                    localNoticeList[NoticeMsg.smart_flowCloseHigh.key] = NoticeMsg.smart_flowCloseHigh.msg;
                }
            }
        }


        if (plProdLine === PRODUCTLINE_FLEX) {
            if (primDrivingPressure < primInternalPressureDrop) {
                localErrorList[ErrorMsg.flex_lowPressure.key] = ErrorMsg.flex_lowPressure.msg;
            }

            ShuntParamsEnum.ControlValve.setToFirebase(database, shuntID, 'IMI');
        }
        else {
            if (plProdLine === PRODUCTLINE_SMART) {
                ShuntParamsEnum.CV_Model.setToFirebase(database, shuntID, CV_IMI);
                ShuntParamsEnum.ControlValve.setToFirebase(database, shuntID, CV_MODEL_TASMART);
                ShuntParamsEnum.SmartDimID.setToFirebase(database, shuntID, smartDim.id)
            }
            else {
                if (controlValve == null || controlValve === '') {
                    ShuntParamsEnum.ControlValve.setToFirebase(database, shuntID, CV_SOE);
                }
                if (cvModel == null || cvModel === '') {
                    ShuntParamsEnum.CV_Model.setToFirebase(database, shuntID, CV_MODEL_KVS);
                }
                ShuntParamsEnum.CV_Kvs.setToFirebase(database, shuntID, kvs);
            }
        }
    }
    //Manual mode
    else if (calcMode === 0) {
        const primDimName = shuntInfo.child(SHUNT_DRIFTPARAMS_PRIMDIM).val();
        const sekDimName = shuntInfo.child(SHUNT_DRIFTPARAMS_SEKDIM).val();

        // eslint-disable-next-line eqeqeq
        if (primDimName == undefined || primDimName == "" || sekDimName == undefined || sekDimName == "") {
            //console.error(ErrorMsg.manual_noDimSelected.msg);
            localErrorList[ErrorMsg.manual_noDimSelected.key] = ErrorMsg.manual_noDimSelected.msg;
            return;
        }

        dimRow = getDimRowFromName(primDimName, dimensionData);
        dimSekRow = getDimRowFromName(sekDimName, dimensionData);

        if (cvIsInterval === true) {
            //Calculate the KVS value
            kvs = calcKvs(primFlow, secondaryFlow, primDrivingPressure, secExternalPressureDrop, plPrincipVal, plProdLine, dimRow, dimSekRow, dimensionData, cvIsInterval, extraBV);

            //Check if the kvs-value is within the selected control-valve's range.
            const kvVals = shuntInfo.child(SHUNT_CV_KVS_PATH).val();
            let min = Number(kvVals.split(' - ')[0]);
            let max = Number(kvVals.split(' - ')[1]);
            if (kvs < min) {
                //console.error(ErrorMsg.manual_flowToLowForCV.msg);
                localErrorList[ErrorMsg.manual_flowToLowForCV.key] = ErrorMsg.manual_flowToLowForCV.msg;
            }
            else if (kvs > max) {
                //console.error(ErrorMsg.manual_flowToHighForCV.msg);
                localErrorList[ErrorMsg.manual_flowToHighForCV.key] = ErrorMsg.manual_flowToHighForCV.msg;
            }
        }
        else if (plProdLine === PRODUCTLINE_SMART) {
            let smartSVid = shuntInfo.child(SHUNT_SMART_SV_PATH).val();;
            let smartDim = smartDims.find(obj => obj.id === smartSVid);

            if (primFlow < smartDim.data.qMin) {
                const smart_PrimPressureToLow = ErrorMsg.smart_PrimPressureToLow(primInternalPressureDrop);
                //console.error(smart_PrimPressureToLow.msg);
                localErrorList[smart_PrimPressureToLow.key] = smart_PrimPressureToLow.msg;
            }
            else if (primFlow > smartDim.data.qMax) {
                //console.error(ErrorMsg.smart_flowToHigh.msg);
                localErrorList[ErrorMsg.smart_flowToHigh.key] = ErrorMsg.smart_flowToHigh.msg;
            }
            else {
                if (primFlow <= (Number(smartDim.data.qMin) * 1.10)) {
                    //console.log(NoticeMsg.smart_flowCloseLow.msg);
                    localNoticeList[NoticeMsg.smart_flowCloseLow.key] = NoticeMsg.smart_flowCloseLow.msg;
                }
                else if (primFlow >= (Number(smartDim.data.qMax) * 0.9)) {
                    //console.log(NoticeMsg.smart_flowCloseHigh.msg);
                    localNoticeList[NoticeMsg.smart_flowCloseHigh.key] = NoticeMsg.smart_flowCloseHigh.msg;
                }
            }
        }
        else {
            kvs = shuntInfo.child(SHUNT_CV_KVS_PATH).val();

            // eslint-disable-next-line eqeqeq
            if (kvs == undefined) {
                //console.error(ErrorMsg.manual_cvNotSet.msg);
                localErrorList[ErrorMsg.manual_cvNotSet.key] = ErrorMsg.manual_cvNotSet.msg;
                return;
            }
        }

        primInternalPressureDrop = calcPrimPressureDrop(primFlow, primDrivingPressure, dimRow, kvs, minPressure, primarySideBV, extraBV, cvIsInterval, plProdFamilyVal, plProdLine, calcMode, adjustValve, null, null, false, shuntID, database);
    }
    else {
        //console.error(ErrorMsg.internalError.key + " - No ProductType could be found!");
        localErrorList[ErrorMsg.internalError.key] = ErrorMsg.internalError.msg + '- No ProductType could be found!';
        return;
    }


    if (useConnectedEnergy === true && connectedEnergyModel != null && connectedEnergyModel?.kv != null) {
        let extraDP = roundNumber(calcDeltaPressure(primFlow, Number(connectedEnergyModel?.kv)));

        if (plPrincipVal === PRODUCTPRINCIP_VA1 || (plPrincipVal === PRODUCTPRINCIP_PR2 && hasMainPump === false)) {
            primInternalPressureDrop = roundNumber(primInternalPressureDrop + extraDP);
        }
        else {
            if (extraDP + primInternalPressureDrop > primDrivingPressure) {
                const primFlowHighError = ErrorMsg.connected_PressureDropToHigh(extraDP, extraDP + primInternalPressureDrop);
                localErrorList[primFlowHighError.key] = { msg: primFlowHighError.msg, val: extraDP, val2: roundNumber(extraDP + primInternalPressureDrop) };
            }

            primInternalPressureDrop = roundNumber(primInternalPressureDrop + extraDP);
        }
    }


    let secInternalPressureDrop = calcSecondaryPressureDrop(secondaryFlow, primFlow, kvs, dimSekRow, secondarySideBV, null, cvIsInterval, plProdFamilyVal, plProdLine, calcMode, adjustValve, backVents, false, shuntID, database)
    let SVpressureLoss;

    if (plProdLine === PRODUCTLINE_FLEX) {
        SVpressureLoss = minPressure;
    }
    else if (plProdFamilyVal === PRODUCTFAMILY_PR) {
        SVpressureLoss = calcDeltaPressure(secondaryFlow, kvs);
    }
    else {
        SVpressureLoss = calcDeltaPressure(primFlow, kvs);
    }

    let authority;
    if (plPrincipVal === PRODUCTPRINCIP_PR3) {
        authority = calcAuthority(kvs, primFlow, secondaryFlow, primInternalPressureDrop, dimSekRow.rorAndBend, plPrincipVal, hasMainPump, Number(pEfk), primDrivingPressure, primExternalPressureDrop, SVpressureLoss, cvIsInterval, calcMode)
    }
    else {
        authority = calcAuthority(kvs, primFlow, secondaryFlow, primInternalPressureDrop, dimRow.rorAndBend, plPrincipVal, hasMainPump, Number(pEfk), primDrivingPressure, primExternalPressureDrop, SVpressureLoss, cvIsInterval, calcMode);
    }

    if (plProdFamilyVal === PRODUCTFAMILY_VA && calcMode === 1) {
        let isBelow = false;

        //Decrease kvs until authority is above 20%
        while (authority < 20) {
            isBelow = true;

            //console.log("Too Low: ", kvs, authority);
            let newKvs = getNextLowerDimKvs(dimensionData, kvs);
            if (newKvs != null) {
                let newAuth = calcAuthority(newKvs, primFlow, secondaryFlow, primInternalPressureDrop, dimRow.rorAndBend, plPrincipVal, hasMainPump, Number(pEfk), primDrivingPressure, primExternalPressureDrop, SVpressureLoss, cvIsInterval, calcMode);

                if (newAuth >= 20) {
                    SVpressureLoss = calcDeltaPressure(primFlow, newKvs);
                    secInternalPressureDrop = calcSecondaryPressureDrop(secondaryFlow, primFlow, newKvs, dimSekRow, secondarySideBV, null, cvIsInterval, plProdFamilyVal, plProdLine, calcMode, adjustValve, backVents, false, shuntID, database)
                    kvs = newKvs;
                    authority = newAuth;
                    ShuntParamsEnum.CV_Kvs.setToFirebase(database, shuntID, kvs);
                    break;
                }
            }
            else {
                console.error("Can't go lower!");
                break;
            }
        }

        if (isBelow === false) {
            let nextAuthVal = authority;
            let nextKvs = kvs;

            //Increase kvs until authority is above 20% (unless it has prev. been decreased to reach threshold)
            while (nextAuthVal >= 20) {
                let newKvs = getNextDimKvs(dimensionData, nextKvs);

                if (newKvs != null) {
                    let newAuth = calcAuthority(newKvs, primFlow, secondaryFlow, primInternalPressureDrop, dimSekRow.rorAndBend, plPrincipVal, hasMainPump, Number(pEfk), primDrivingPressure, primExternalPressureDrop, SVpressureLoss, cvIsInterval, calcMode);

                    if (newAuth < 20) {
                        //console.log("Can't go up another step! Auth:", newAuth, "kvs: ", newKvs);
                        break;
                    }
                    else {
                        //console.log("New Auth: ", newAuth);
                        nextAuthVal = newAuth;
                        nextKvs = newKvs;
                    }
                }
                else {
                    //console.error("Can't go higher!");
                    break;
                }
            }



            if (nextAuthVal !== authority) {
                SVpressureLoss = calcDeltaPressure(primFlow, nextKvs);
                secInternalPressureDrop = calcSecondaryPressureDrop(secondaryFlow, primFlow, nextKvs, dimSekRow, secondarySideBV, null, cvIsInterval, plProdFamilyVal, plProdLine, calcMode, adjustValve, backVents, false, shuntID, database)
                kvs = nextKvs;
                authority = nextAuthVal;
                ShuntParamsEnum.CV_Kvs.setToFirebase(database, shuntID, kvs);
            }
        }
    }

    if (authority !== 0 && authority < 20) {
        localNoticeList[NoticeMsg.calc_authToLow.key] = NoticeMsg.calc_authToLow.msg;
    }

    if (plProdLine === PRODUCTLINE_GREEN && plPrincipVal !== PRODUCTPRINCIP_SR2 && kvs < 1.6) {
        localNoticeList[NoticeMsg.green_toLowKvs.key] = NoticeMsg.green_toLowKvs.msg;
    }

    const totalPressure = calcTotalPressure(plPrincipVal, hasMainPump, secExternalPressureDrop, primDrivingPressure, Number(pEfk), secInternalPressureDrop, SVpressureLoss, primInternalPressureDrop, primExternalPressureDrop);
    //console.log("Total P: ", totalPressure);
    //console.log("Auth final: ", authority);
    //console.log("Prim Dim (final):", dimRow);
    //console.log("Sek Dim (final):", dimSekRow);

    const balanceKV = calculateBalancingKV(plProdFamilyVal, plProdLine, cvIsInterval, primDrivingPressure, hasMainPump, extraBV, primFlow, dimRow, calcMode, adjustValve, primInternalPressureDrop);
    let primInternalDrivPressure;
    if (plPrincipVal === PRODUCTPRINCIP_VA1 || (plPrincipVal === PRODUCTPRINCIP_PR2 && hasMainPump === false)) {
        primInternalDrivPressure = calcInternalPrimDP(plProdLine, plProdFamilyVal, primExternalPressureDrop, SVpressureLoss, primFlow, balanceKV);
    }
    else {
        primInternalDrivPressure = calcInternalPrimDP(plProdLine, plProdFamilyVal, primDrivingPressure, SVpressureLoss, primFlow, balanceKV);
    }

    //checkForFlowErrors(secondaryFlow, primDrivingPressure, null, false, localErrorList)
    checkForShuntErrorsTemperature(plPrincipVal, primTempIn, primTempOut, secTempIn, secTempOut, null, null, false, localErrorList, localNoticeList);
    if (plProdLine !== PRODUCTLINE_SMART) {
        checkForPressureErrors(primDrivingPressure, primInternalPressureDrop, secInternalPressureDrop, SVpressureLoss, plProdLine, plPrincipVal, maxPressure, hasMainPump, primFlow, dimRow?.maxFlow, calcMode, null, null, false, localErrorList, localNoticeList)
    }

    checkForFlowErrors(primDrivingPressure, primFlow, secondaryFlow, plProdLine, plPrincipVal, null, null, false, localErrorList, localNoticeList);

    if ((isNaN(authority) === true || authority === 0) && calcMode === 0) {
        if (Number(SVpressureLoss) < Number(primDrivingPressure) * 0.20) {
            localNoticeList[NoticeMsg.manual_cvPressureDropLowUnknownAuthority.key] = NoticeMsg.manual_cvPressureDropLowUnknownAuthority.msg;
        }
    }

    if (plProdLine === PRODUCTLINE_GREEN) {
        let isGreenLvl2 = isGreenLevel2(plPrincipVal, shuntData, adjustValve, dimRow?.dim, dimSekRow?.dim, primTempIn, primTempOut, secTempIn, secTempOut, kvs, useConnectedEnergy);
        console.log("SetLevel - ", isGreenLvl2, shuntID, plGreenLevel)
        if (plGreenLevel == null || plGreenLevel === '') {
            if (isGreenLvl2 === true) {
                ProductLineEnum.ProductGreenLevel.setToFirebase(database, shuntID, 2);
            }
            else {
                ProductLineEnum.ProductGreenLevel.setToFirebase(database, shuntID, 1);
            }
        }
    }



    ShuntParamsEnum.NumBV.setToFirebase(database, shuntID, backVents);
    ShuntParamsEnum.NumIV.setToFirebase(database, shuntID, numTotalIV);

    ShuntParamsEnum.ConnectedEnergyModel.setToFirebase(database, shuntID, connectedEnergyModel);

    DriftParamEnum.PrimFlow.setToFirebase(database, shuntID, primFlow);

    CalcParamsEnum.Kvs.setToFirebase(database, shuntID, kvs);
    CalcParamsEnum.BalancingKv.setToFirebase(database, shuntID, balanceKV);
    DriftParamEnum.PrimaryDim.setToFirebase(database, shuntID, dimRow.display);
    CalcParamsEnum.PrimDimRow.setToFirebase(database, shuntID, dimRow);
    DriftParamEnum.SecondaryDim.setToFirebase(database, shuntID, dimSekRow.display);
    CalcParamsEnum.SekDimRow.setToFirebase(database, shuntID, dimSekRow);
    if (isNaN(authority) === false) {
        CalcParamsEnum.Authority.setToFirebase(database, shuntID, authority);
    }

    CalcParamsEnum.SVPressureLoss.setToFirebase(database, shuntID, SVpressureLoss);
    CalcParamsEnum.PrimInternalPressureDrop.setToFirebase(database, shuntID, primInternalPressureDrop);
    CalcParamsEnum.SecInternalPressureDrop.setToFirebase(database, shuntID, secInternalPressureDrop);
    CalcParamsEnum.ShuntType.setToFirebase(database, shuntID, shuntData);
    CalcParamsEnum.TotalPressure.setToFirebase(database, shuntID, roundToDecimals(totalPressure, 1));
    CalcParamsEnum.PrimInternalDP.setToFirebase(database, shuntID, primInternalDrivPressure);
    console.log("Local Errors: ", localErrorList, localNoticeList)
    if (Object.keys(localErrorList).length > 0) {
        if (SessionParamsEnum.CurrentShunt.getContextValue(sessionParams) === shuntID) {
            SessionParamsEnum.CurrentStep.setContextValue(sessionParams, 1);
            SessionParamsEnum.MaxStep.setContextValue(sessionParams, 1);
        }
        const dbCurStepRef = ref(database, SESSION_SHUNT_PATH + shuntID + "/Baseinfo/curStep");
        const dbMaxStepRef = ref(database, SESSION_SHUNT_PATH + shuntID + "/Baseinfo/maxStep");
        await set(dbCurStepRef, 1);
        await set(dbMaxStepRef, 1);
    }
    await saveNoticesAndErrorsLocal(database, shuntID, localErrorList, localNoticeList);
    //SessionParamsEnum.UpdateValues.setContextValue(sessionParams);
    return;
}

export { calculateShuntParamsFromFB }