import { Menu, MenuItem, Box, Checkbox, FormControl, FormControlLabel, FormLabel, InputLabel, Select, Stack, TextField } from "@mui/material"
import { SystemFluidMixRatioInputfield } from "./systemFluidMixRatioInputField";
import { useGetSystemFluidOptions } from "../../../hooks/useGetSystemFluidOptions";

import { QUESTION_SYSTEM_FLUID_KEY, QUESTION_SYSTEM_FLUID_MIX_RATIO_KEY, QUESTION_SYSTEM_FLUID_MIX_RATIO_UNKNOWN_KEY, SYSTEM_FLUID } from "../../../constants/wizardConstants";


export const SystemFluidMenu = ({ id, anchorEl, open, handleClose, systemFluid, systemFluidMixRatio, systemFluidMixRatioUnknown, answerQuestion }) => {
    const systemFluidOptions = useGetSystemFluidOptions();

    const getSystemFluidSelected = (value) => {
        if (value != null) {
            return systemFluidOptions.find((obj) => obj.value === value);
        }
        return null;
    }

    const handleChangeFluidMix = (value, key) => {
        const fluidOption = getSystemFluidSelected(systemFluid);
        if (fluidOption?.maxMixRatio != null) {
            if (Number(value) > fluidOption?.maxMixRatio) {
                answerQuestion(key, fluidOption?.maxMixRatio);
            }
            else if (Number(value) < 0) {
                answerQuestion(key, 0);
            }
            else {
                answerQuestion(key, value);
            }
        }
        else {
            if (Number(value) > 100) {
                answerQuestion(key, 100);
            }
            else if (Number(value) < 0) {
                answerQuestion(key, 0);
            }
            else {
                answerQuestion(key, value);
            }
        }
    }

    return (
        <Menu
            id={id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            disableScrollLock
            slotProps={{
                paper: {
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        background: 'white',
                        border: '1px solid #8b8c8d',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 3,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            color: 'white',
                            top: -1,
                            right: 10,
                            width: 5,
                            height: 5,
                            bgcolor: 'white',
                            borderTop: '1px solid #8b8c8d',
                            borderLeft: '1px solid #8b8c8d',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <Box padding={'2px 8px'}>
                <Stack>
                    <Stack sx={{ marginTop: '10px' }} direction={'row'} spacing={2} alignItems={'flex-start'}>
                        <Box sx={{ paddingTop: (systemFluid !== SYSTEM_FLUID.water.value && systemFluid !== SYSTEM_FLUID.unknown.value && systemFluid !== SYSTEM_FLUID.different.value  && systemFluid !== SYSTEM_FLUID.other.value) ? '10px' : '0px', }}>
                            <FormControl size="small">
                                <InputLabel>Systemvätska</InputLabel>
                                <Select
                                    sx={{ width: '150px', height: '30px', background: 'white' }}
                                    size="small"
                                    value={systemFluid}
                                    label={'Systemvätska'}
                                    onChange={(e) => {
                                        if (getSystemFluidSelected(e.target.value)?.defaultMixRatio != null) {
                                            answerQuestion(QUESTION_SYSTEM_FLUID_MIX_RATIO_KEY, getSystemFluidSelected(e.target.value)?.defaultMixRatio);
                                        }
                                        answerQuestion(QUESTION_SYSTEM_FLUID_KEY, e.target.value)
                                    }}
                                >
                                    {systemFluidOptions.map((item) => {
                                        if (item?.pumpOptionOnly !== true && item?.wizardOptionOnly !== true) {
                                            return (
                                                <MenuItem key={item?.value} value={item?.value}>{item?.label}</MenuItem>
                                            );
                                        }
                                        else{
                                            return "";
                                        }
                                    })}
                                </Select>
                            </FormControl>
                        </Box>


                        {getSystemFluidSelected(systemFluid)?.defaultMixRatio != null ? (
                            <Box paddingBottom={'10px'}>
                                <Stack direction={'row'} alignItems={'flex-end'} marginTop={'-15px'}>
                                    <Stack>
                                        <FormLabel>Blandningsförhållande: (%)</FormLabel>
                                        <Stack direction={'row'} spacing={1} marginTop={'8px'}>
                                            <SystemFluidMixRatioInputfield
                                                questionKey={QUESTION_SYSTEM_FLUID_MIX_RATIO_KEY}
                                                value={systemFluidMixRatio}
                                                systemFluid={systemFluid}
                                                handleChangeFluidMix={handleChangeFluidMix}
                                                disabled={systemFluidMixRatioUnknown}
                                            />

                                            <TextField
                                                sx={{ width: '60px', background: 'white' }}
                                                size="small"
                                                type="number"
                                                label="Vatten"
                                                value={Math.round((100 - systemFluidMixRatio) * 100) / 100}
                                                disabled
                                                InputProps={{
                                                    inputProps: {
                                                        step: 10,
                                                        max: 100,
                                                        min: 0
                                                    }
                                                }}
                                            />
                                        </Stack>
                                    </Stack>
                                    <FormControlLabel
                                        sx={{ marginLeft: '-10px', height: '28px' }}
                                        control={
                                            <Checkbox checked={systemFluidMixRatioUnknown} onChange={() => answerQuestion(QUESTION_SYSTEM_FLUID_MIX_RATIO_UNKNOWN_KEY, !systemFluidMixRatioUnknown)} />
                                        }
                                        label={SYSTEM_FLUID.unknown.label}
                                    />
                                </Stack>
                            </Box>
                        ) : ("")}
                    </Stack>
                </Stack>
            </Box>
        </Menu>
    )
}