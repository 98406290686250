import { get, push, ref, set, update } from "firebase/database";
import { ContextEnum } from "./contextEnum";
import { nanoid } from 'nanoid';
import { SESSION_MODIFIED_SHUNTS_PATH, SESSION_PATH, SESSION_SHUNT_PATH, SHUNT_ADDONS_PATH, SHUNT_DESIGNATION_PATH, SHUNT_POSITION_PATH, SHUNT_SHUNTDATA } from "../constants/firebasePaths";
import { getCurrentHighPosition, getCurrentHighPositionSession, getCurrentNextPosition } from "../firebase/functions/getCurrentHighPosition";
import { ShuntParamsEnum } from "./shuntParamsEnum";
import { updatePositionList } from "../firebase/functions/updatePositionLIst";
import { IS_ADDON_KEY } from "../constants/keys/shuntKeys";

export class SessionParamsEnum extends ContextEnum {
    static ParentID = new SessionParamsEnum("parentID", "setParentID");
    static IsApiSession = new SessionParamsEnum("isApiSession", "setIsApiSession");
    static ApiMethod = new SessionParamsEnum("apiMethod", "setApiMethod");
    static ApiIsBulk = new SessionParamsEnum("apiIsBulk", "setApiIsBulk");
    static SessionID = new SessionParamsEnum("sessionID", "setSessionID");
    static Facility = new SessionParamsEnum("facility", "setFacility");
    static ProjNr = new SessionParamsEnum("projNr", "setProjNr");

    //Dashboard-Fields
    static LinkedProject = new SessionParamsEnum("linkedProject", "setLinkedProject");
    static Status = new SessionParamsEnum("status", "setStatus");

    static CurrentShunt = new SessionParamsEnum("currentShunt", "setCurrentShunt")
    static SessionShunts = new SessionParamsEnum("shunts", "setSessionShunt");
    static SessionAddons = new SessionParamsEnum("addons", "setSessionAddons");
    static Editable = new SessionParamsEnum("editable", "setEditable");
    static CurrentStep = new SessionParamsEnum("curStep", "setCurrentStep");
    static MaxStep = new SessionParamsEnum("maxStep", "setMaxStep");
    static SearchID = new SessionParamsEnum("searchSessionID", "setSearchSessionID");
    static OpenOverview = new SessionParamsEnum("openOverview", "setOpenOverview");
    static OverviewPanel = new SessionParamsEnum("overviewPanel", "setOverviewPanel");

    static OpeningSessionModified = new SessionParamsEnum("openingSessionModified", "setOpeningSessionModified");
    static OpeningSessionModifiedError = new SessionParamsEnum("openingSessionModifiedError", "setOpeningSessionModifiedError");

    static SwitchingShunt = new SessionParamsEnum('switching', 'setSwitching');
    static UpdateValues = new SessionParamsEnum("update", "setUpdateValues");


    static createSessionID = async function createSessionID(context, parentID = null, database = null) {
        const currentDate = new Date(Date.now());
        let sessionID = currentDate.getFullYear() + "-" + String(Number(currentDate.getMonth() + 1)).padStart(2, '0') + "-" + String(currentDate.getDate()).padStart(2, '0') + "-" + nanoid(4);
        //console.log("SessionID: ", sessionID)
        context.dispatch({ type: "setSessionID", payload: sessionID });
        context.dispatch({ type: "setEditable", payload: true });

        if (parentID != null && database != null) {
            context.dispatch({ type: "setParentID", payload: parentID, database: database });
        }

        return sessionID;
    }

    static addSessionShunt = async function addSessionShunt(context, database, isCurrent, shuntContext, pos = null, sessionID = null) {
        //console.log("Here Add!", sessionID, pos)
        const sessionRef = ref(database, SESSION_SHUNT_PATH);
        const res = await push(sessionRef)
        let position = -1;

        if (pos != null) {
            position = pos;
        }
        /*
        else{
            if (sessionID != null && sessionID !== ""){
                position = await getCurrentHighPositionSession(database, sessionID);
            }
            else{
                position = await getCurrentHighPosition(database, context);
            }
        }*/

        //console.log("Here Add!", position)
        context.dispatch({ type: "addSessionShunt", payload: res.key, database: database, shuntPos: position });

        if (isCurrent === true) {
            context.dispatch({ type: "setCurrentShunt", payload: res.key, database: database });
            ShuntParamsEnum.ShuntPosition.setContextValue(shuntContext, position);
        }

        return res.key;
    }

    static createSessionShunt = async function createSessionShunt(context, database, shuntID, isCurrent, shuntContext, position = null) {
        //console.log("Here Create!")
        if (position == null) {
            //position = await getCurrentHighPosition(database, context);
            //position += 1;
            position = -1;
        }

        context.dispatch({ type: "addSessionShunt", payload: shuntID, database: database, shuntPos: position });
        ShuntParamsEnum.ShuntPosition.setContextValue(shuntContext, position);

        if (isCurrent === true) {
            context.dispatch({ type: "setCurrentShunt", payload: shuntID, database: database });
        }

        return shuntID;
    }

    static copySessionShunt = async function copySessionShunt(context, database, shuntID, isCurrent, shuntContext, newSessionID) {
        const oldShuntRef = ref(database, SESSION_SHUNT_PATH + shuntID);
        const resOldShunt = await get(oldShuntRef);

        if (resOldShunt.child(SHUNT_SHUNTDATA).child(IS_ADDON_KEY).exists() === false || resOldShunt.child(SHUNT_SHUNTDATA).child(IS_ADDON_KEY).val() !== true) {
            const sessionRef = ref(database, SESSION_SHUNT_PATH);
            const res = await push(sessionRef, resOldShunt.val());

            if (resOldShunt.child(SHUNT_ADDONS_PATH).exists()) {
                let addonRef;
                let addonPos;
                let addonDesig;

                resOldShunt.child(SHUNT_ADDONS_PATH).forEach((addon) => {
                    addonRef = ref(database, SESSION_SHUNT_PATH + addon.val());

                    get(addonRef).then((addonSnap) => {
                        if (addonSnap.exists()) {
                            let newAddonData = addonSnap.val();

                            if (newAddonData['ShuntData'] == null) {
                                newAddonData['ShuntData'] = {};
                            }

                            newAddonData['ShuntData']['shuntID'] = res.key;

                            return push(sessionRef, newAddonData).then((addonKey) => {
                                const newAddonIDRef = ref(database, SESSION_SHUNT_PATH + res.key + SHUNT_ADDONS_PATH + addon.key);

                                addonPos = -1;
                                addonDesig = null;

                                if (addonSnap.child(SHUNT_POSITION_PATH).exists()) {
                                    addonPos = addonSnap.child(SHUNT_POSITION_PATH).val()
                                }

                                if (addonSnap.child(SHUNT_DESIGNATION_PATH).exists()) {
                                    addonDesig = addonSnap.child(SHUNT_DESIGNATION_PATH).val()
                                }

                                set(newAddonIDRef, addonKey.key);

                                context.dispatch({ type: "addSessionShunt", payload: addonKey.key, database: database, shuntPos: addonPos, shuntDesig: addonDesig, isAddon: true, addonType: addon.key });
                            });
                        }
                    });
                });
            }

            let pos = 0;
            if (resOldShunt.child(SHUNT_POSITION_PATH).exists()) {
                pos = resOldShunt.child(SHUNT_POSITION_PATH).val()
            }

            let designation = null;
            if (resOldShunt.child(SHUNT_DESIGNATION_PATH).exists()) {
                designation = resOldShunt.child(SHUNT_DESIGNATION_PATH).val()
            }

            console.log("SpecData: ", resOldShunt.child('SpecData').val())
            if (resOldShunt.child('SpecData').exists() && newSessionID != null) {
                if (resOldShunt.child('SpecData').child('specList').exists() || resOldShunt.child('SpecData').child('connList').exists() || resOldShunt.child('SpecData').child('measurementList').exists() || resOldShunt.child('SpecData').child('techList').exists()) {
                    const modifiedRef = ref(database, SESSION_PATH + newSessionID + SESSION_MODIFIED_SHUNTS_PATH + res.key)
                    if (resOldShunt.child('SpecData').child('isModified').exists() && resOldShunt.child('SpecData').child('isModified').val() === true) {
                        console.log("SETDATA 2: ", modifiedRef.toString())
                        await set(modifiedRef, 2);
                    }
                    else {
                        console.log("SETDATA 1: ", modifiedRef.toString())
                        await set(modifiedRef, 1);
                    }
                }
            }

            //console.log("Create copy: ", designation)
            context.dispatch({ type: "addSessionShunt", payload: res.key, database: database, shuntPos: pos, shuntDesig: designation });

            if (isCurrent === true) {
                //console.log("copy Set Current: ", shuntID, res.key)
                context.dispatch({ type: "setCurrentShunt", payload: res.key, database: database });
                ShuntParamsEnum.ShuntPosition.setContextValue(shuntContext, pos);
            }

            return res.key;
        }
        else {
            return "";
        }
    }

    /**
     * 
     * @param {*} context 
     * @param {*} database 
     * @param {string} shuntID 
     * @param {'mixingVessel'} addonType 
     * @param {*} shuntContext 
     * @param {string} prodLine
     * @param {number} position 
     * @returns 
     */
    static createShuntAddon = async function createShuntAddon(context, database, addonType, shuntContext, position, prodLine, shuntList, shuntID = null, addonData = null, isManual = false) {
        //console.log("Here Create addon!")
        const sessionRef = ref(database, SESSION_SHUNT_PATH);
        const res = await push(sessionRef)

        let nextPos = Number(position) + 1;

        await updatePositionList(database, SessionParamsEnum.SessionID.getContextValue(context), nextPos, shuntList)

        //console.log("PRODLINE: ", prodLine)
        context.dispatch({ type: "addShuntAddon", payload: res.key, database: database, shuntID: shuntID, shuntPos: nextPos, addonType: addonType, addonData: addonData, prodLine: prodLine, isManual: isManual });

        if (shuntID != null && shuntID === SessionParamsEnum.CurrentShunt.getContextValue(context)) {
            let addons = {};
            if (ShuntParamsEnum.Addons.getContextValue(shuntContext) != null) {
                addons = ShuntParamsEnum.Addons.getContextValue(shuntContext);
            }

            addons[addonType] = res.key;

            ShuntParamsEnum.Addons.setContextValue(shuntContext, addons);
        }

        SessionParamsEnum.UpdateValues.setContextValue(context, !SessionParamsEnum.UpdateValues.getContextValue(context))
        return res.key;
    }

    static updateShuntAddonPosition = function updateShuntAddonPosition(context, database, addonID, shuntPosition, shuntList) {
        let nextPos = Number(shuntPosition) + 1;

        updatePositionList(database, SessionParamsEnum.SessionID.getContextValue(context), nextPos, shuntList, addonID)

        const sessionRef = ref(database, SESSION_PATH + SessionParamsEnum.SessionID.getContextValue(context) + SESSION_SHUNT_PATH + addonID);
        const shuntRef = ref(database, SESSION_SHUNT_PATH + addonID + SHUNT_POSITION_PATH);

        const promiseSession = set(sessionRef, Number(nextPos));
        const promiseShunt = set(shuntRef, Number(nextPos));

        return Promise.all([promiseSession, promiseShunt])
    }


    /**
     * 
     * @param {*} context 
     * @param {*} database 
     * @param {*} shuntContext 
     * @param {string} addonID 
     * @param {'mixingVessel'} addonType 
     * @param {string} [shuntID] 
     */
    static deleteShuntAddon = function deleteShuntAddon(context, database, shuntContext, addonID, addonType, shuntID = null, isCurrent = true) {
        //console.log("Delete here?")
        context.dispatch({ type: "removeShuntAddon", payload: addonID, database: database, shuntID: shuntID, addonType: addonType });

        if (isCurrent) {
            let addonsShunt = {};
            if (ShuntParamsEnum.Addons.getContextValue(shuntContext) != null) {
                addonsShunt = ShuntParamsEnum.Addons.getContextValue(shuntContext);
            }

            if (addonsShunt[addonType] != null) {
                delete addonsShunt[addonType];
            }

            ShuntParamsEnum.Addons.setContextValue(shuntContext, addonsShunt);
        }
    }
}