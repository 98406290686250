import { roundNumber } from "./helpFunctions/roundTo4Decimals";
// eslint-disable-next-line no-unused-vars
import { getDimKvs, getDimKvs_Custom } from "./helpFunctions/getNextDimKvs";
import { PRODUCTPRINCIP_PR2, PRODUCTPRINCIP_PR3, PRODUCTPRINCIP_SR2, PRODUCTPRINCIP_SR2FLEX, PRODUCTPRINCIP_SR3, PRODUCTPRINCIP_VA1 } from "../../constants/productlineConstants";
import { calcExtraBVInterval } from "./calculatePressureDrop";

/**
 * @param {number} primflow The primary flow in the system (l/s) (***Used for SR shunts***)
 * @param {number} secFlow The secondary flow in the system (l/s) (***Used for PR and VA shunts***)
 * @param {number} primDrivingPressure The available Driving Pressure in the primary system (kPa) (***Used in all but VA-1***)
 * @param {number} secExternalPressureDrop The secondary pressure drop (kPa) (***Used in VA-1***)
 * @param {string} prodType The type of shunt 
 * @param {import("./helpFunctions/findCorrespondingDim").DimRow} primDimRow 
 * @param {import("./helpFunctions/findCorrespondingDim").DimRow} secDimRow 
 * @returns {number} The calculated kvs-value
 */
function calcKvs(primflow, secFlow, primDrivingPressure, secExternalPressureDrop, prodType, prodLine, primDimRow, secDimRow, dimensionData, isInterval, extraBV, isCustom = false) {
    
    if (prodType === PRODUCTPRINCIP_SR2 || prodType === PRODUCTPRINCIP_SR3 || prodType === PRODUCTPRINCIP_SR2FLEX) {
        if (isInterval === true) {
            let ror = primDimRow.kvRor;
            let kv = primDrivingPressure - (100 * Math.pow((primflow * 3.6) / ror, 2));
            if (extraBV === true){
                kv -= calcExtraBVInterval(primflow, primDimRow, prodLine);
            }
            let kvs = (primflow * 3.6) / Math.sqrt(kv / 100);
            
            return roundNumber(kvs);
        }
        else {
            let result = (primflow * 3.6) / Math.pow(primDrivingPressure / 100, 0.5);
            if (isCustom === true){
                return getDimKvs_Custom(dimensionData, result);
            }
            else{
                return getDimKvs(dimensionData, result, prodType);
            }
            
        }
    }
    else if (prodType === PRODUCTPRINCIP_PR2 || prodType === PRODUCTPRINCIP_PR3) {
        if (isInterval === true) {
            if (prodType === PRODUCTPRINCIP_PR2) {
                return roundNumber((secFlow * 3.6) / Math.sqrt(12 * 0.01))
            }
            else if (prodType === PRODUCTPRINCIP_PR3) {
                return roundNumber((secFlow * 3.6) / Math.sqrt(9 * 0.01))
            }
        }
        else {
            if (prodType === PRODUCTPRINCIP_PR2) {
                return secDimRow.kvsPr2;
            }
            else {
                return secDimRow.kvsPr3;
            }
        }
    }
    else if (prodType === PRODUCTPRINCIP_VA1) {
        let kv = (secFlow * 3.6) / Math.sqrt(secExternalPressureDrop * 0.01);
        return getDimKvs(dimensionData, kv, prodType);
    }
    else {
        if (isCustom === true){
            return getDimKvs_Custom(dimensionData, secDimRow);
        }
        else{
            return getDimKvs(dimensionData, secDimRow, prodType);
        }
       
    }
}

export { calcKvs }