import { Box } from "@mui/material"
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { svSE } from '@mui/x-data-grid/locales';

import { StarredField } from "./ColumnCells/StarredField";
import { OpenSessionField } from "./ColumnCells/OpenSessionField";



export const AllSessionsComponent = ({ sessions, notInSession }) => {
    const columns = [
        {
            field: 'starred',
            headerName: 'Stjärnmärkt',
            sortable: false,
            width: 30,
            renderCell: StarredField,
            renderHeader: () => (
                <div></div>
            ),
        },
        {
            field: 'id',
            headerName: 'Sessions ID',
            width: 150,
        },
        {
            field: 'anläggning',
            headerName: 'Anläggning',
            width: 150,
        },
        {
            field: 'projNr',
            headerName: 'Projekt Nr',
            width: 110,
        },
        {
            field: 'numShunts',
            headerName: 'Antal positioner',
            sortable: false,
            width: 160,
            valueGetter: (value, row) => `${Object.keys(row?.SessionShunts || {}).length}`,
        },
        {
            field: 'options',
            headerName: '',
            sortable: false,
            filterable: false,
            width: 80,
            renderCell: ({ value, row }) => OpenSessionField({value, row, notInSession})
        },
    ];

    return (
        <Box>
            <DataGrid
                sx={{ minHeight: '200px' }}
                density="compact"
                rows={sessions}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 15,
                        },
                    },
                    sorting: {
                        sortModel: [{ field: 'id', sort: 'desc' }]
                    },
                }}
                slots={{ toolbar: GridToolbar }}
                disableColumnSelector
                disableDensitySelector
                slotProps={{
                    toolbar: {
                        csvOptions: { disableToolbarButton: true },
                        printOptions: { disableToolbarButton: true },
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 250 },
                    },
                    footerRowCount: {

                    }
                }}
                pageSizeOptions={[10, 15, 25, 50]}
                disableRowSelectionOnClick
                localeText={svSE.components.MuiDataGrid.defaultProps.localeText}
            />
        </Box>
    )
}