import { CV_MODEL_AB_QM, CV_MODEL_TAMODULATOR } from "../../../constants/shuntOptions";
import { roundNumber } from "../helpFunctions/roundTo4Decimals";

const flex_calcControlValveSetting = function flex_calcControlValveSetting(flow, flexDim, model) {
    if (model === CV_MODEL_AB_QM) {
        let maxQmax = flexDim.data.qMax[flexDim.data.qMax.length - 1];
        return roundNumber(flow / maxQmax * 100);
    }
    else {
        for (let index = 0; index < flexDim.data.qMax.length; index++) {
            const curQMaxValue = flexDim.data.qMax[index];
            
            if (flow <= curQMaxValue) {
                if (index === 0) {
                    return flexDim.data.qMaxPosStart;
                }
                else {
                    let prevQMaxValue = flexDim.data.qMax[index - 1];
                    let curSetting = flexDim.data.qMaxPosStart + index * flexDim.data.qMaxPosStep;
                    let prevSetting = flexDim.data.qMaxPosStart + (index - 1) * flexDim.data.qMaxPosStep;
                    let inclination = (curSetting - prevSetting) / (curQMaxValue - prevQMaxValue);

                    if (model === CV_MODEL_TAMODULATOR && flexDim.data.dim <= 32){
                        return roundNumber(Math.round(2 * (inclination * flow + (curSetting - inclination * curQMaxValue))) / 2);
                    }
                    else{
                        return roundNumber(inclination * flow + (curSetting - inclination * curQMaxValue));
                    }

                }
            }
        }

        return 10; //??
    }
}

export { flex_calcControlValveSetting }