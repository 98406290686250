import { Fragment, useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { getShuntListName } from '../../../firebase/functions/getShuntList';
import { useDatabase } from 'reactfire';
import { useSessionParams } from '../../../contexts';
import { PriceCalcShunt } from './priceCalcShunt';
import { MobileStepper } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { PriceCalcOverall } from './priceCalcOverall';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export default function PriceStepper() {
    const database = useDatabase();
    const sessionParams = useSessionParams();

    const [steps, setSteps] = useState([]);
    const [stepsIDs, setStepsIDs] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});

    useEffect(() => {
        const getNames = async () => {
            const nameList = await getShuntListName(database, sessionParams);
            nameList.sort((a, b) => Number(a.pos) - Number(b.pos));
            const localSteps = [];
            const localIDs = [];
            for (let index = 0; index < nameList.length; index++) {
                const element = nameList[index];
                localSteps.push(element.fullName);
                localIDs.push(element.id);
            }
            localSteps.push('Övergripande')
            setStepsIDs(localIDs);
            setSteps(localSteps);
        }

        getNames();
    }, [])

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const getShuntID = () => {
        if (stepsIDs[activeStep] != null) {
            return stepsIDs[activeStep];
        }
        else {
            return "";
        }
    }

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    return (
        <Box sx={{ marginLeft: '15px', marginTop: '2px', width: '98%' }}>
            <MobileStepper
                style={{ width: 'fit-content', fontSize: 16, marginLeft: '330px' }}
                variant="text"
                steps={steps.length}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        style={{ height: '25px' }}
                        size="medium"
                        onClick={handleNext}
                        disabled={activeStep === steps.length - 1}
                    >
                        <ArrowForwardIosIcon fontSize='medium' />
                    </Button>
                }
                backButton={
                    <Button
                        style={{ height: '15px' }}
                        size="medium"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                    >
                        <ArrowBackIosNewIcon fontSize='small' />
                    </Button>
                }
            />
            <div>
                <Fragment>
                    {activeStep < stepsIDs.length ? (
                        <Fragment>
                            <PriceCalcShunt shuntID={getShuntID()} handleComplete={handleComplete} />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <PriceCalcOverall shuntList={stepsIDs} handleComplete={handleComplete} />
                        </Fragment>
                    )}
                </Fragment>
            </div>
        </Box>
    );
}
