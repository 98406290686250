import { useAdjustParams, useCalcParams, useDevbarParams, useSessionParams, useShuntParams } from '../../../../contexts';
import { useState } from 'react';
import { FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { ShuntParamsEnum } from '../../../../enums/shuntParamsEnum';
import { ADJUST_VALVE_PURMOEVO, ADJUST_VALVE_STADF } from '../../../../constants/shuntOptions';
import { AdjustmentsEnum, CalcParamsEnum, SessionParamsEnum } from '../../../../enums';


export const AdjustValveGreenSelect = () => {
    const shuntParams = useShuntParams();
    const calcParams = useCalcParams();
    const sessionParams = useSessionParams();
    const adjustParams = useAdjustParams();
    const devParams = useDevbarParams();

    const setDefaultValue = () => {
        if (ShuntParamsEnum.AdjustmentValve.getContextValue(shuntParams) != null && ShuntParamsEnum.AdjustmentValve.getContextValue(shuntParams) !== "") {
            return ShuntParamsEnum.AdjustmentValve.getContextValue(shuntParams);
        }
        else {
            ShuntParamsEnum.AdjustmentValve.setContextValue(shuntParams, ADJUST_VALVE_STADF);
            return ADJUST_VALVE_STADF;
        }
    }
    const [modelSelect, setModelSelect] = useState(setDefaultValue());


    const setValue = (value) => {
        //console.log("Index:", value)
        ShuntParamsEnum.AdjustmentValve.setContextValue(shuntParams, value);
        CalcParamsEnum.clearData(calcParams);
        AdjustmentsEnum.clearData(adjustParams);
        setModelSelect(value);
    };


    const isDisabled = () => {
        if (SessionParamsEnum.Editable.getContextValue(sessionParams) === false && devParams.state.val.devMode !== true) {
            return true;
        }
        else {
            return false;
        }
    }

    return (
        <Stack>
            <span style={{ marginTop: '12px' }}>Injusteringsventil:</span>
            <RadioGroup
                style={{ paddingTop: "5px", paddingLeft: "10px" }}
                value={modelSelect}
                name="radio-buttons-group"
                onChange={(event) => { setValue(event.target.value) }}
            >
                <FormControlLabel key={"STAD"} value={ADJUST_VALVE_STADF} control={<Radio />} disabled={isDisabled()} label="IMI STAD ZERO/IMI STAF" />
                <FormControlLabel key={"Purmo"} value={ADJUST_VALVE_PURMOEVO} control={<Radio />} disabled={isDisabled()} label="Purmo Evobalance/Purmo STV" />
            </RadioGroup>
        </Stack>
    );
};