
export class NoticeEnum {
    constructor() {
        this.name = "noticeList";
        this.addNoticeMethod = "addNotice";
        this.removeNoticeMethod = "clearNotice";
        this.clearMethod = "clearNoticeList";
    }

    hasNotices(contextParam) {
        if (contextParam.state.val != null) {
            return Object.keys(contextParam.state.val).length > 0;
        }
        else {
            return false;
        }

    }

    getNoticeList(contextParam) {
        return contextParam.state.val;
    }

    addNotice(contextParam, key, notice) {
        contextParam.dispatch({ type: this.addNoticeMethod, payload: { key: key, msg: notice } })
    }

    clearNotice(contextParam, key) {
        contextParam.dispatch({ type: this.removeNoticeMethod, payload: key })
    }

    clearAllNotices(contextParam) {
        contextParam.dispatch({ type: this.clearMethod })
    }
}