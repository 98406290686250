
import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSessionParams } from "../../../../contexts"
import { SessionParamsEnum } from "../../../../enums";
import { MIXING_VESSEL_TEXT } from "../../../../constants/componentsTexts";
import { PRODUCTLINE_GREEN } from "../../../../constants/productlineConstants";

/**
 * 
 * @param {Object} param0 
 * @param {Object} param0.objData
 * @param {'mixingVessel' | 'pump' | 'connHose' | 'hole'} param0.component
 * @returns 
 */
export const InspectPageMixingVesselRow = ({ objData, component }) => {
    const sessionParams = useSessionParams();
    const [specifyAmount, setSpecifyAmount] = useState(true);

    const [textRows, setTextRows] = useState([]);

    useEffect(() => {
        if (SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true) {
            let textRes = getMixingDataRow();
            setTextRows(textRes);
        }
    }, [SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams), SessionParamsEnum.CurrentShunt.getContextValue(sessionParams), objData, component]);


    const getMixingDataRow = () => {
        let text = [];
        switch (component) {
            case 'mixingVessel':
                let mixingValue = String(objData?.ShuntData?.AddonData?.size).split(' ')[0];
                let isGreen = objData?.PLData?.prodLine === PRODUCTLINE_GREEN;
                text.push({ num: 1, text: MIXING_VESSEL_TEXT(mixingValue, isGreen) });
                setSpecifyAmount(true);
                break;
            case 'pump':
                text.push({ num: 1, text: "Pump Wilo WJ203, 1x240V med sladd och stickpropp" });
                setSpecifyAmount(true);
                break;
            case 'connHose':
                text.push({ num: 1, text: "Flexibel anslutningsslang" });
                setSpecifyAmount(true);
                break;
            case 'hole':
                text.push({ num: 1, text: "Hål med propp för returledning" });
                setSpecifyAmount(true);
                break;
            default:
                break;
        }

        return text;
    }

    return (
        <>
            {textRows.map((row, index) => {
                if (specifyAmount === true) {
                    return (
                        <Stack key={index} direction={'row'} spacing={1}>
                            {row.num != null ? (
                                <Typography style={{ width: '30px' }}>
                                    {String(row.num)} st.
                                </Typography>
                            ) : (
                                <Typography style={{ width: '30px' }}>

                                </Typography>
                            )}

                            <Typography style={{ width: '520px' }}>
                                {row.text}
                            </Typography>
                        </Stack>
                    )
                }
                else {
                    return (
                        <Stack key={index} direction={'row'} spacing={1}>
                            <Typography style={{ width: '520px' }}>
                                {row.text}
                            </Typography>
                        </Stack>
                    )
                }
            })
            }
        </>
    )
}