import { Modal, Backdrop } from "@mui/material"
import { useEffect, useState } from "react";
import { useSessionParams } from "../../../contexts";
import { SessionParamsEnum } from "../../../enums";
import { ShuntOverview } from "./overview"

export const OverviewModal = () => {
    const sessionParams = useSessionParams();

    const setInitOpen = () => {
        if (SessionParamsEnum.OpenOverview.getContextValue(sessionParams) == null) {
            return false
        }
        else {
            return SessionParamsEnum.OpenOverview.getContextValue(sessionParams);
        }
    }

    const [openLocal, setOpenLocal] = useState(setInitOpen());

    let isInEdit = false;
    const setEdit = (val) => {
        isInEdit = val;
    }

    const handleClose = () => {
        if (isInEdit === false) {
            SessionParamsEnum.OpenOverview.setContextValue(sessionParams, false);
        }
    }

    useEffect(() => {
        //console.log("Open:", SessionParamsEnum.OpenOverview.getContextValue(sessionParams))
        if (SessionParamsEnum.OpenOverview.getContextValue(sessionParams) == null) {
            setOpenLocal(false)
        }
        else {
            setOpenLocal(SessionParamsEnum.OpenOverview.getContextValue(sessionParams));
        }
    }, [SessionParamsEnum.OpenOverview.getContextValue(sessionParams)]);

    const style = {
        position: 'fixed',
        top: '470px',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1148,
        height: '630px',
        maxHeight: 'auto',
        bgcolor: 'rgba(255,255,255,0.85)',
        border: '2px solid #E1E2E4',
        borderRadius: 2,
        boxShadow: 24,
        padding: "20px",
        overflow: 'hidden',
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openLocal}
            onClose={handleClose}
            closeAfterTransition
            slotProps={{ backdrop: { timeout: 500 } }}
        >
            <div>
                <ShuntOverview style={style} open={openLocal} handleClose={handleClose} setIsInEdit={setEdit} />
            </div>
        </Modal>
    )
}