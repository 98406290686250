export const API_AdjustValveLables = {
    Armatec: "Armatec1310",
    MMA: "PurmoEvo",
    Oventrop: "Oventrop",
    'STAD/F': "StadF",
}

export const API_DimSizes = {
    'DN020.1': "DN020/IV010",
    'DN020.2': "DN020/IV010",
    'DN020.3': "DN020/IV015",
    'DN020.4': "DN020/IV015",
    'DN020.5': "DN020/IV015",
    'DN020.6': "DN020/IV020",
    'DN020.7': "DN020/IV020",
}