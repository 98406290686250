import { Button, DialogActions, DialogContent, styled, TableCell, TableRow, Typography, Tooltip, IconButton, Stack, Divider } from "@mui/material";
import { ref } from "firebase/database";
import { useEffect, useState } from "react";
import { useDatabase, useDatabaseObjectData } from "reactfire";
import { SESSION_SHUNT_PATH } from "../../../../constants/firebasePaths";
import { useSessionParams } from "../../../../contexts/SessionParams";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CommentIcon from '@mui/icons-material/Comment';
import ReportIcon from '@mui/icons-material/Report';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageSharpIcon from '@mui/icons-material/ImageSharp';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { LoadNewShuntComponent } from "../../../utils/loadNewShuntComponent";
import { SessionParamsEnum } from "../../../../enums/sessionParamsEnum";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../utils/bootstrapDialog";
import { deleteShunt } from "../../../../firebase/functions/deleteShunt";
import { PRODUCTPRINCIP_PR2, PRODUCTPRINCIP_VA1 } from "../../../../constants/productlineConstants";

import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import ListIcon from '@mui/icons-material/List';
import { AddShuntRowModal } from "../../customShunt/components/shuntRow";
import { MagicloudCadDownloadButtons } from "../../magicloud/cadDownloadButtons";
import { getProdID } from "../../../../functions/MagicadFunctions/getProdCode";
import { useContextAuth } from "../../../../authContext/AuthHook";
import { HOVER_TEXT_OVERVIEW_ICON_CHANGED_IMAGE, HOVER_TEXT_OVERVIEW_ICON_COMMENT_EXISTS, HOVER_TEXT_OVERVIEW_ICON_PRICE_SET, HOVER_TEXT_OVERVIEW_ICON_SPEC_MODIFIED, HOVER_TEXT_OVERVIEW_ICON_SPEC_MODIFIED_CHANGED } from "../../../../constants/generalText/hoverTexts";

export const FirebaseShuntRow = ({ shuntID, editMode, openEditValue, handleClose, magicloudIDs, loading }) => {
    const database = useDatabase();
    const sessionParams = useSessionParams();
    const { user } = useContextAuth();

    const [productID, setProductID] = useState("");

    //const [loadShuntID, setLoadShuntID] = useState(shuntID);
    const [openModal, setOpenModal] = useState(false);
    const [openModalCustom, setOpenModalCustom] = useState(false);
    const [isCustom, setIsCustom] = useState(false);
    const [loadingShunt, setLoadingShunt] = useState(false);
    const handleModalCustomOpen = () => { setOpenModalCustom(true) }
    const handleModalCustomClose = () => { setOpenModalCustom(false) }

    const shuntRef = ref(database, SESSION_SHUNT_PATH + shuntID);

    const response = useDatabaseObjectData(shuntRef);
    const { status, data } = response;


    const [openShunt, setOpenShunt] = useState(false);

    useEffect(() => {
        if (SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) === false) {
            setLoadingShunt(false);
        }
    }, [SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams)])

    useEffect(() => {
        if (status === 'success') {
            setIsCustom(getIsCustom());
        }
    }, [status, data]);


    useEffect(() => {
        if (loading === false) {
            let prodID = getProdID(false, null, null, null, null, data);
            let magicadUID = magicloudIDs[prodID];
            //console.log("Row: ", prodID, " => ", magicadUID)
            setProductID(magicadUID);
        }
    }, [magicloudIDs, loading, status, data])

    const isDisabled = () => {
        if (SessionParamsEnum.Editable.getContextValue(sessionParams) === false) {
            return true;
        }
        else {
            return false;
        }
    }

    const removeShuntApprove = async () => {
        await deleteShunt(database, shuntID, SessionParamsEnum.SessionID.getContextValue(sessionParams), sessionParams);
        setOpenModal(false);
    }

    const removeShuntDecline = () => {
        setOpenModal(false);
    }

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.action.hover,
        // },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));


    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const getMaxStep = () => {
        if (data?.MsgLists?.errors != null) {
            return <ReportIcon fontSize="small" color='error' />
        }
        else if (data?.Baseinfo?.maxStep != null) {
            if (Number(data.Baseinfo.maxStep) === 4) {
                return <CheckCircleIcon fontSize="small" color="success" />
            }
            else if (Number(data.Baseinfo.maxStep) === 3) {
                return <svg className="numberIcon " focusable="false" aria-hidden="true" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12"></circle><text className="numberIcon-text" x="12" y="16" textAnchor="middle" style={{ height: '1.2em', width: '1.2em' }}>4</text></svg>
            }
            else if (Number(data.Baseinfo.maxStep) === 2) {
                return <svg className="numberIcon " focusable="false" aria-hidden="true" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12"></circle><text className="numberIcon-text" x="12" y="16" textAnchor="middle" style={{ height: '1.2em', width: '1.2em' }}>3</text></svg>
            }
            else if (Number(data.Baseinfo.maxStep) === 1) {
                return <svg className="numberIcon " focusable="false" aria-hidden="true" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12"></circle><text className="numberIcon-text" x="12" y="16" textAnchor="middle" style={{ height: '1em', width: '1em' }}>2</text></svg>
            }
            else if (Number(data.Baseinfo.maxStep) === 0) {
                return <svg className="numberIcon " focusable="false" aria-hidden="true" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12"></circle><text className="numberIcon-text" x="12" y="16" textAnchor="middle" style={{ height: '1.2em', width: '1.2em' }}>1</text></svg>
            }
            return "";
        }
        else {
            return "";
        }
    }

    const getIsCustom = () => {
        if (data?.Baseinfo?.isCustom != null) {
            return data?.Baseinfo?.isCustom;
        }
        else {
            return false;
        }
    }

    const getPos = () => {
        if (data?.Baseinfo?.position != null) {
            return "Pos. " + String(data.Baseinfo.position);
        }
        else {
            return "";
        }
    }

    const getBeteckning = () => {
        if (data?.Baseinfo?.designation != null) {
            return String(data.Baseinfo.designation);
        }
        else {
            return "";
        }
    }

    const getModel = () => {
        if (data?.ShuntData?.plModel != null) {
            return String(data.ShuntData.plModel);
        }
        else if (data?.PLData?.princip != null) {
            return String(data.PLData.princip);
        }
        else {
            return "";
        }
    }

    const getProdLine = () => {
        if (data?.PLData?.prodLine != null) {
            if (data?.PLData?.greenLevel != null) {
                if (data?.PLData?.greenLevel === 1) {
                    return String(data.PLData.prodLine) + " I";
                }
                else if (data?.PLData?.greenLevel === 2) {
                    return String(data.PLData.prodLine) + " II";
                }
            }
            return String(data.PLData.prodLine);
        }
        else {
            return "";
        }
    }

    const getShuntType = () => {
        if (data?.CalcParams?.shuntType != null) {
            return capitalizeFirstLetter(String(data.CalcParams.shuntType));
        }
        else {
            return "";
        }
    }

    const getDrivPressure = () => {
        if (data?.PLData?.princip === PRODUCTPRINCIP_VA1) {
            return "-";
        }
        else if (data?.PLData?.princip === PRODUCTPRINCIP_PR2 && data?.DriftParams?.hasMainPump === "0") {
            return "-";
        }
        else if (data?.DriftParams?.primDrivingPressure != null) {
            return data.DriftParams.primDrivingPressure + " kPa";
        }
        else {
            return "";
        }
    }

    const getDrivPressureNumber = () => {
        if (data?.DriftParams?.primDrivingPressure != null) {
            return Number(data.DriftParams.primDrivingPressure);
        }
        else {
            return "-";
        }
    }

    const getPrimTempIn = () => {
        if (data?.PLData?.princip === PRODUCTPRINCIP_VA1) {
            return "-";
        }
        else if (data?.DriftParams?.primTempIn != null) {
            return data.DriftParams.primTempIn + " °C";
        }
        else {
            return "-";
        }
    }

    const getPrimTempInNumber = () => {
        if (data?.DriftParams?.primTempIn != null) {
            return Number(data.DriftParams.primTempIn);
        }
        else {
            return "";
        }
    }

    const selectShunt = (event) => {
        if (event?.target?.cellIndex != null) {
            if (editMode !== true && SessionParamsEnum.CurrentShunt.getContextValue(sessionParams) !== shuntID) {
                setLoadingShunt(true);
                setOpenShunt(true);
            }
        }
    }

    const selectDeleteShunt = () => {
        setOpenModal(true);
    }

    const getMinMaxTemp = () => {
        if (data != null && data.DriftParams != null) {
            let primTempIn = null;
            let primTempOut = null;
            let secTempIn = null;
            let secTempOut = null;

            let anyValues = false;
            if (data.DriftParams.primTempIn != null) {
                anyValues = true;
                primTempIn = Number(data.DriftParams.primTempIn);
            }
            if (data.DriftParams.primTempOut != null) {
                anyValues = true;
                primTempOut = Number(data.DriftParams.primTempOut);
            }
            if (data.DriftParams.secTempIn != null) {
                anyValues = true;
                secTempIn = Number(data.DriftParams.secTempIn);
            }
            if (data.DriftParams.secTempOut != null) {
                anyValues = true;
                secTempOut = Number(data.DriftParams.secTempOut);
            }

            if (anyValues === true) {
                let min = Math.min(primTempIn || Number.MAX_SAFE_INTEGER, primTempOut || Number.MAX_SAFE_INTEGER, secTempIn || Number.MAX_SAFE_INTEGER, secTempOut || Number.MAX_SAFE_INTEGER);
                let max = Math.max(primTempIn || Number.MIN_SAFE_INTEGER, primTempOut || Number.MIN_SAFE_INTEGER, secTempIn || Number.MIN_SAFE_INTEGER, secTempOut || Number.MIN_SAFE_INTEGER);

                return min + "/" + max + " °C";
            }
            else {
                return "";
            }
        }
        else {
            return "";
        }
    }

    const notEditableDrivPressure = () => {
        if (data?.PLData?.princip === PRODUCTPRINCIP_VA1) {
            return false;
        }
        else if (data?.PLData?.princip === PRODUCTPRINCIP_PR2 && data?.DriftParams?.hasMainPump === "0") {
            return false;
        }
        return true;
    }

    const notEditableTemp = () => {
        if (data?.PLData?.princip === PRODUCTPRINCIP_VA1) {
            return false;
        }
        return true;
    }

    const loadShunt = () => {
        if (openShunt === true) {
            //setOpen(false);
            return (
                <LoadNewShuntComponent shuntID={shuntID} setOpen={setOpenShunt} showLoad={false} />
            );
        }
        else {
            return (
                <></>
            )
        }
    }

    const isModifiedSpecList = () => {
        if (data?.SpecData?.specList != null || data?.SpecData?.connList || data?.SpecData?.measurementList != null || data?.SpecData?.techList) {
            return true;
        }

        return false;
    }

    const isModifiedSpecListChanged = () => {
        if (data?.SpecData?.specList != null || data?.SpecData?.connList || data?.SpecData?.measurementList != null || data?.SpecData?.techList) {
            if (data?.SpecData?.isModified != null && data?.SpecData?.isModified === true) {
                return true;
            }
        }

        return false;
    }

    const setLoadingAnimation = () => {
        if (loadingShunt === true && shuntID === SessionParamsEnum.CurrentShunt.getContextValue(sessionParams)) {
            return true;
        }
        else {
            return false;
        }
    }

    return (
        <>
            {getProdLine() !== "" ? (
                <>
                    {isCustom ? (
                        <>
                            <StyledTableRow key={shuntID} onClick={selectShunt} onDoubleClick={() => { selectShunt(); handleClose(); }} selected={shuntID === SessionParamsEnum.CurrentShunt.getContextValue(sessionParams)} >
                                <TableCell className="table-cell"><PlaylistAddCheckCircleIcon fontSize="small" color="success" /> </TableCell>
                                <TableCell className={"table-cell"}>{getPos()}</TableCell>
                                <TableCell className={editMode ? "table-cell" : "table-cell pointer"}>
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                        {getBeteckning()}
                                        {editMode && <IconButton size="small" onClick={() => { openEditValue('Beteckning', getBeteckning(), shuntID) }}><EditIcon style={{ height: '13px', width: '13px', color: "#f7c03f" }} /></IconButton>}
                                    </Stack>
                                </TableCell>
                                <TableCell className={"table-cell"}>{getModel()}</TableCell>
                                <TableCell className={"table-cell"}>{getProdLine()}</TableCell>
                                <TableCell className={"table-cell"}>{getShuntType()}</TableCell>
                                <TableCell className={"table-cell"}>{getMinMaxTemp()}</TableCell>
                                <TableCell className={"table-cell"}>{getDrivPressure()}</TableCell>
                                <TableCell className={"table-cell"}>{getPrimTempIn()}</TableCell>
                                {user?.isAnonymous !== true && user?.isInternal === true ? (
                                    <TableCell className="table-cell-admin">
                                        <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>

                                            <span style={{ height: '24px' }}><Divider sx={{ marginRight: '2px' }} orientation="vertical" /></span>
                                            {data?.CustomData?.imageName != null &&
                                                <Tooltip arrow title={HOVER_TEXT_OVERVIEW_ICON_CHANGED_IMAGE}>
                                                    <span>
                                                        <ImageSharpIcon sx={{ color: "#888888", marginTop: "0px", marginRight: '2px' }} />
                                                    </span>
                                                </Tooltip>
                                            }

                                            {data?.CustomData?.comment != null &&
                                                <Tooltip arrow title={HOVER_TEXT_OVERVIEW_ICON_COMMENT_EXISTS}>
                                                    <span>
                                                        <CommentIcon sx={{ color: "#888888", marginTop: "0px", marginRight: '2px' }} />
                                                    </span>
                                                </Tooltip>
                                            }

                                            {data?.CustomData?.shuntPrice != null && data?.CustomData?.priceCompleted === true &&
                                                <Tooltip arrow title={HOVER_TEXT_OVERVIEW_ICON_PRICE_SET}>
                                                    <span>
                                                        <AttachMoneyIcon sx={{ color: "#888888", marginTop: "0px", marginRight: '2px' }} />
                                                    </span>
                                                </Tooltip>
                                            }

                                            {isModifiedSpecList() ? (
                                                <>
                                                    {isModifiedSpecListChanged() ? (
                                                        <Tooltip arrow title={HOVER_TEXT_OVERVIEW_ICON_SPEC_MODIFIED_CHANGED}>
                                                            <span>
                                                                <ListIcon sx={{ color: "#da3e3e", marginTop: "0px", marginRight: '2px' }} />
                                                            </span>
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip arrow title={HOVER_TEXT_OVERVIEW_ICON_SPEC_MODIFIED}>
                                                            <span>
                                                                <ListIcon sx={{ color: "#888888", marginTop: "0px", marginRight: '2px' }} />
                                                            </span>
                                                        </Tooltip>
                                                    )}
                                                </>
                                            ) : ("")}

                                        </Stack>
                                    </TableCell>
                                ) : (
                                    <TableCell className={editMode ? "table-cell" : "table-cell pointer"}></TableCell>
                                )}

                                {SessionParamsEnum.IsApiSession.getContextValue(sessionParams) !== true ? (
                                    <TableCell sx={{ padding: '0px', minWidth: '10px' }}>
                                        <MagicloudCadDownloadButtons shuntData={data} productId={productID} smallButtons />
                                    </TableCell>
                                ) : ("")}

                                {!isDisabled() &&
                                    <TableCell sx={{ padding: '0px' }}>
                                        <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                                            <span style={{ height: '24px' }}><Divider orientation="vertical" /></span>
                                            <Button onClick={selectDeleteShunt} className="delete-btn" disabled={shuntID === SessionParamsEnum.CurrentShunt.getContextValue(sessionParams) || editMode === true}><DeleteIcon fontSize="small" /></Button>
                                            {user?.isAnonymous !== true && user?.isInternal === true ? (
                                                <>
                                                    <span style={{ height: '24px' }}><Divider orientation="vertical" /></span>
                                                    <IconButton onClick={handleModalCustomOpen} size="small">
                                                        <EditIcon fontSize="small" style={{ height: '17px', width: '17px', color: "#f7c03f" }} />
                                                    </IconButton>
                                                </>
                                            ) : ("")}
                                        </Stack>
                                    </TableCell>
                                }
                                {loadShunt()}
                            </StyledTableRow>
                        </>
                    ) : (
                        <>
                            <StyledTableRow key={shuntID} onClick={selectShunt} onDoubleClick={() => { selectShunt(); handleClose(); }} selected={shuntID === SessionParamsEnum.CurrentShunt.getContextValue(sessionParams)} hover={editMode ? false : true}>
                                <TableCell className="table-cell">{getMaxStep()}</TableCell>
                                <TableCell className={editMode ? "table-cell" : "table-cell pointer"}>{getPos()}</TableCell>
                                <TableCell className={editMode ? "table-cell" : "table-cell pointer"}>
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                        {getBeteckning()}
                                        {editMode && <IconButton size="small" onClick={() => { openEditValue('Beteckning', getBeteckning(), shuntID) }}><EditIcon style={{ height: '13px', width: '13px', color: "#f7c03f" }} /></IconButton>}
                                    </Stack>
                                </TableCell>
                                <TableCell className={editMode ? "table-cell" : "table-cell pointer"}>{getModel()}</TableCell>
                                <TableCell className={editMode ? "table-cell" : "table-cell pointer"}>{getProdLine()}</TableCell>
                                <TableCell className={editMode ? "table-cell" : "table-cell pointer"}>{getShuntType()}</TableCell>
                                <TableCell className={editMode ? "table-cell wide" : "table-cell wide pointer"}>{getMinMaxTemp()}</TableCell>
                                <TableCell className={editMode ? "table-cell wide" : "table-cell wide pointer"}>
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={0.5}>
                                        <span>{getDrivPressure()}</span>
                                        {editMode && notEditableDrivPressure() && <IconButton size="small" onClick={() => { openEditValue('Drivtryck', getDrivPressureNumber(), shuntID) }}><EditIcon style={{ height: '13px', width: '13px', color: "#f7c03f" }} /></IconButton>}
                                    </Stack>
                                </TableCell>
                                <TableCell className={editMode ? "table-cell" : "table-cell pointer"}>
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={0.5}>
                                        <span>{getPrimTempIn()}</span>
                                        {editMode && notEditableTemp() && <IconButton size="small" onClick={() => { openEditValue('Prim Temp In', getPrimTempInNumber(), shuntID) }}><EditIcon style={{ height: '13px', width: '13px', color: "#f7c03f" }} /></IconButton>}
                                    </Stack>
                                </TableCell>
                                {user?.isAnonymous !== true && user?.isInternal === true ? (
                                    <TableCell className="table-cell-admin">
                                        <Stack direction={'row'}>
                                            <span><Divider sx={{ marginRight: '2px' }} orientation="vertical" /></span>
                                            {data?.CustomData?.imageName != null &&
                                                <Tooltip arrow title={HOVER_TEXT_OVERVIEW_ICON_CHANGED_IMAGE}>
                                                    <span>
                                                        <ImageSharpIcon sx={{ color: "#888888", marginTop: "0px", marginRight: '2px' }} />
                                                    </span>
                                                </Tooltip>
                                            }
                                            {data?.CustomData?.comment != null &&
                                                <Tooltip arrow title={HOVER_TEXT_OVERVIEW_ICON_COMMENT_EXISTS}>
                                                    <span>
                                                        <CommentIcon sx={{ color: "#888888", marginTop: "1px", marginRight: '2px' }} />
                                                    </span>
                                                </Tooltip>
                                            }
                                            {data?.CustomData?.shuntPrice != null && data?.CustomData?.priceCompleted === true &&
                                                <Tooltip arrow title={HOVER_TEXT_OVERVIEW_ICON_PRICE_SET}>
                                                    <span>
                                                        <AttachMoneyIcon sx={{ color: "#888888", marginTop: "0px", marginRight: '2px' }} />
                                                    </span>
                                                </Tooltip>
                                            }


                                            {isModifiedSpecList() ? (
                                                <>
                                                    {isModifiedSpecListChanged() ? (
                                                        <Tooltip arrow title={HOVER_TEXT_OVERVIEW_ICON_SPEC_MODIFIED_CHANGED}>
                                                            <span>
                                                                <WarningAmberRoundedIcon sx={{ color: "#da3e3e", marginTop: "0px", marginRight: '2px' }} />
                                                            </span>
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip arrow title={HOVER_TEXT_OVERVIEW_ICON_SPEC_MODIFIED}>
                                                            <span>
                                                                <ListIcon sx={{ color: "#888888", marginTop: "0px", marginRight: '2px' }} />
                                                            </span>
                                                        </Tooltip>
                                                    )}
                                                </>
                                            ) : ("")}
                                        </Stack>
                                    </TableCell>
                                ) : (
                                    <TableCell className={editMode ? "table-cell" : "table-cell pointer"}></TableCell>
                                )}

                                {SessionParamsEnum.IsApiSession.getContextValue(sessionParams) !== true ? (
                                    <TableCell sx={{ padding: '0px' }}>
                                        <Stack direction={'row'} spacing={0.5}>
                                            <span><Divider orientation="vertical" /></span>
                                            <MagicloudCadDownloadButtons shuntData={data} productId={productID} smallButtons />
                                            <span><Divider orientation="vertical" /></span>
                                        </Stack>

                                    </TableCell>
                                ) : ("")}

                                {SessionParamsEnum.IsApiSession.getContextValue(sessionParams) === true ? (
                                    <TableCell sx={{ padding: '0px' }}>
                                    </TableCell>
                                ) : (
                                    <>
                                        {!isDisabled() &&
                                            <TableCell sx={{ padding: '0px' }}>
                                                <Button onClick={selectDeleteShunt} className="delete-btn" disabled={shuntID === SessionParamsEnum.CurrentShunt.getContextValue(sessionParams) || editMode === true}>
                                                    <DeleteIcon fontSize="small" />
                                                </Button>
                                            </TableCell>
                                        }
                                    </>
                                )}


                                {loadShunt()}
                            </StyledTableRow>
                        </>
                    )}
                </>
            ) : ("")}


            <BootstrapDialog
                onClose={removeShuntDecline}
                aria-labelledby="customized-dialog-title"
                open={openModal}
            >
                <BootstrapDialogTitle onClose={removeShuntDecline}>
                    Ta bort {getPos() != null ? (<>{getPos()} - {getBeteckning() != null ? (getBeteckning()) : ("")}</>) : ("")}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom style={{ width: '400px', display: 'flex', justifyContent: 'center' }}>
                        <strong>Är du säker på att du vill ta bort shuntgruppen?</strong>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={removeShuntApprove}>
                        Ja
                    </Button>
                    <Button onClick={removeShuntDecline}>
                        Nej
                    </Button>
                </DialogActions>
            </BootstrapDialog>

            <BootstrapDialog
                onClose={handleModalCustomClose}
                aria-labelledby="customized-dialog-title"
                open={openModalCustom}
            >
                <AddShuntRowModal handleModalClose={handleModalCustomClose} isEdit={true} shuntObj={data} />
            </BootstrapDialog>
        </>
    );
}