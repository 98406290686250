import { Paper, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Tooltip } from "@mui/material"
import { AdjustmentsEnum } from "../../../../../enums"
import { CUTOFF_190S_115TE, CUTOFF_3601_2301, CUTOFF_3601_2310, CUTOFF_3701_2301, CUTOFF_BA107S_BA115TE, CUTOFF_GENERIC, CUTOFF_GENERIC_LOW_LEAD, CUTOFF_VM4205_3001 } from "../../../../../constants/adjustmentsOptions"

import greenDrop from '../../../../../assets/images/green_drop.svg'

export const AdjustCardCutOffValve = ({ cutOffValue, setAdjustParams, isDisabled }) => {

    return (
        <div>
            <Paper elevation={0} className="adjust-card">
                <FormControl>
                    <FormLabel id="cut-off-valve-radio-buttons-group">Avstängningsventil</FormLabel>
                    <RadioGroup
                        aria-labelledby="cut-off-valve-radio-buttons-group"
                        name="cut-off-valve"
                        value={cutOffValue}
                        onChange={(event) => { setAdjustParams(event.target.value, AdjustmentsEnum.CutOffValue) }}
                    >
                        <FormControlLabel value={CUTOFF_3601_2301} control={<Radio />} label="Avstängningsventil Armatec 3601/2301" disabled={isDisabled()} />
                        <FormControlLabel
                            value={CUTOFF_3701_2301}
                            control={<Radio />}
                            label={
                                <>
                                    Avstängningsventil Armatec 3701/2301
                                    <Tooltip title="Miljövänligare val">
                                        <img src={greenDrop} alt="green drop" style={{ width: '7px', marginLeft: '5px' }} />
                                    </Tooltip>
                                </>
                            }
                            disabled={isDisabled()}
                        />
                        <FormControlLabel value={CUTOFF_3601_2310} control={<Radio />} label="Avstängningsventil Armatec 3601/2310" disabled={isDisabled()} />
                        <FormControlLabel value={CUTOFF_BA107S_BA115TE} control={<Radio />} label="Avstängningsventil Beulco BA107S/BA115TE" disabled={isDisabled()} />
                        <FormControlLabel value={CUTOFF_VM4205_3001} control={<Radio />} label="Avstängningsventil Ventim VM4205/3001" disabled={isDisabled()} />
                        <FormControlLabel value={CUTOFF_190S_115TE} control={<Radio />} label="Avstängningsventil Impel 190S/115TE" disabled={isDisabled()} />

                    </RadioGroup>
                </FormControl>
            </Paper>
        </div>
    )
}