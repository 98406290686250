import { httpsCallable } from "firebase/functions"

const addprojectIDFunc = async function addprojectID(functions, sessionID, projectID) {
    functions.region = 'europe-west1';
    const addprojectID = httpsCallable(functions, "addProjectID");

    return addprojectID({
        sessionID: sessionID,
        projectID: projectID,
    });
}

export { addprojectIDFunc }