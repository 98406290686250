import { Box, Button, IconButton, InputAdornment, InputLabel, LinearProgress, Link, OutlinedInput, Paper, Skeleton, Stack, TextField, Tooltip, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom"
import { useAuth, useDatabase, useFunctions } from "reactfire";
import { getInviteData } from "../firebase/functions/getInviteData";
import PasswordChecklist from "react-password-checklist"

import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { createSLUser } from "../firebase/functions/cloudFunctions/createSLUser";
import { signInWithCustomToken } from "firebase/auth";
import { NOT_CHANGABLE_TEXT } from "../constants/generalText/accountInfo";

const Invite = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const database = useDatabase();
    const functions = useFunctions();

    const [created, setCreated] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    const [validPass, setValidPass] = useState(false);
    const [savingUser, setSavingUser] = useState(false);
    const [loading, setLoading] = useState(true);
    const [alreadyUsed, setAlreadyUsed] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const [URLSearchParams] = useSearchParams();
    const code = URLSearchParams.get('code');

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [password, setPassword] = useState('')
    const [passwordAgain, setPasswordAgain] = useState('')

    const handleClickShowPassword = () => {
        setShowPassword(true)
    };

    const handleClickShowPasswordRelease = () => {
        setShowPassword(false)
    };

    const handleClickShowPasswordConfirm = () => {
        setShowPasswordConfirm(true)
    };

    const handleClickShowPasswordConfirmRelease = () => {
        setShowPasswordConfirm(false)
    };


    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        console.log("Search: ", code)
        setLoading(true);
        if (code == null || code === "") {
            navigate("/", { replace: true });
        }
        else {
            getInviteData(database, code).then((res) => {
                if (res != null) {
                    console.log("Result: ", res)
                    if (res.used !== 0) {
                        if (res.disabled === true) {
                            setIsDisabled(true);
                        }
                        setAlreadyUsed(true);
                    }
                    else if (res.disabled === true) {
                        setIsDisabled(true);
                    }

                    setEmail(res?.email);
                    setName(res?.name);
                    setCompany(res?.company);
                    setLoading(false);
                }
                else {
                    navigate("/", { replace: true });
                }
            }).catch((error) => {
                console.error("FB Error: ", error);
                setLoading(false);
            });
        }
    }, [code]);


    const handleSubmit = (event) => {
        event.preventDefault();

        setSavingUser(true);
        createSLUser(functions, code, email, password, name, company).then((res) => {
            // @ts-ignore
            signInWithCustomToken(auth, res.data).then(() => {
                setCreated(true);
            });
        }).catch((error) => {
            console.error("Error: ", error);
        }).finally(() => {
            setSavingUser(false);
        })
    }

    if (alreadyUsed) {
        return (
            <>
                <br />
                <div style={{
                    margin: "0px 0px 0px 0px",
                    width: "96.3%",
                    borderRadius: "4px",
                    padding: "20px",
                    justifyContent: 'center',
                    backgroundColor: '#E1E2E4',
                }}>
                    <Box>
                        <Typography variant="h2" fontWeight="bold" className="header-title" sx={{ mb: '10px', color: "#183851" }}>
                            Skapa konto på PREMA ShuntLogik<sup>®</sup>
                        </Typography>
                        <Paper sx={{
                            width: '97%',
                            padding: '15px'
                        }}>
                            {isDisabled ? (
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                                    <Typography variant="h2" fontWeight="bold" sx={{ mb: '10px', color: "#183851" }}>
                                        Länk har inaktiverats. Kontakta PREMA för mer information.
                                    </Typography>
                                    <Box fontSize={'1rem'} paddingTop={'20px'} paddingBottom={'20px'}>
                                        <Link href="/" underline="hover">
                                            <strong>Återgå till startsidan</strong>
                                        </Link>
                                    </Box>

                                </Box>
                            ) : (
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                                    <Typography variant="h2" fontWeight="bold" sx={{ mb: '10px', color: "#183851" }}>
                                        Länk har redan används!
                                    </Typography>
                                    <Box fontSize={'1rem'} paddingTop={'20px'} paddingBottom={'20px'}>
                                        <Link href="/" underline="hover">
                                            <strong>Återgå till startsidan</strong>
                                        </Link>
                                    </Box>

                                </Box>
                            )}

                        </Paper>
                    </Box>
                </div>
            </>
        )

    }

    return (
        <>
            <br />
            <div style={{
                margin: "0px 0px 0px 0px",
                width: "96.3%",
                borderRadius: "4px",
                padding: "20px",
                justifyContent: 'center',
                backgroundColor: '#E1E2E4',
            }}>
                <Box>
                    <Typography variant="h2" fontWeight="bold" className="header-title" sx={{ mb: '10px', color: "#183851" }}>
                        Skapa konto på PREMA ShuntLogik<sup>®</sup>
                    </Typography>
                    <Paper sx={{
                        width: '97%',
                        padding: '15px'
                    }}>
                        {loading ? (
                            <Stack>
                                <Skeleton />
                            </Stack>
                        ) : (
                            <>
                                {created ? (
                                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                                        <Typography variant="h2" fontWeight="bold" sx={{ mb: '10px', color: "#183851" }}>
                                            Konto skapat!
                                        </Typography>
                                        <Box fontSize={'1rem'} paddingTop={'20px'} paddingBottom={'20px'}>
                                            <Link href="/" underline="hover">
                                                <strong>Återgå till startsidan</strong>
                                            </Link>
                                        </Box>

                                    </Box>
                                ) : (
                                    <form onSubmit={handleSubmit}>
                                        <Stack spacing={1.5}>
                                            <Stack spacing={0.5}>
                                                <InputLabel>
                                                    Email
                                                </InputLabel>

                                                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                                    <Typography fontSize={'1rem'} width={'250px'}>
                                                        {email}
                                                    </Typography>
                                                    <Tooltip arrow placement="right" title={NOT_CHANGABLE_TEXT}>
                                                        <InfoOutlinedIcon sx={{ height: '17px !important' }} />
                                                    </Tooltip>
                                                </Stack>

                                            </Stack>
                                            <Stack spacing={0.5}>
                                                <InputLabel>
                                                    Namn
                                                </InputLabel>
                                                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                                    <Typography fontSize={'1rem'} width={'250px'}>
                                                        {name}
                                                    </Typography>
                                                    <Tooltip arrow placement="right" title={NOT_CHANGABLE_TEXT}>
                                                        <InfoOutlinedIcon sx={{ height: '17px !important' }} />
                                                    </Tooltip>
                                                </Stack>
                                            </Stack>
                                            <Stack spacing={0.5}>
                                                <InputLabel>
                                                    Företag
                                                </InputLabel>
                                                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                                    <Typography fontSize={'1rem'} width={'250px'}>
                                                        {company}
                                                    </Typography>
                                                    <Tooltip arrow placement="right" title={NOT_CHANGABLE_TEXT}>
                                                        <InfoOutlinedIcon sx={{ height: '17px !important' }} />
                                                    </Tooltip>
                                                </Stack>
                                            </Stack>

                                            <input
                                                style={{ display: 'none' }}
                                                autoComplete="username"
                                                type="email"
                                                value={email}
                                            />
                                            <Stack spacing={0.5}>
                                                <InputLabel>
                                                    Lösenord
                                                </InputLabel>
                                                <OutlinedInput
                                                    id="password"
                                                    sx={{ width: "300px", backgroundColor: 'white' }}
                                                    type={showPassword ? "text" : "password"}
                                                    size="small"
                                                    required
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onMouseDown={handleClickShowPassword}
                                                                onMouseUp={handleClickShowPasswordRelease}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </Stack>
                                            <Stack spacing={0.5}>
                                                <InputLabel>
                                                    Repetera lösenord
                                                </InputLabel>
                                                <OutlinedInput
                                                    id="passwordConfirm"
                                                    sx={{ width: "300px", backgroundColor: 'white' }}
                                                    type={showPasswordConfirm ? "text" : "password"}
                                                    size="small"
                                                    value={passwordAgain}
                                                    onChange={(e) => setPasswordAgain(e.target.value)}
                                                    required
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onMouseDown={handleClickShowPasswordConfirm}
                                                                onMouseUp={handleClickShowPasswordConfirmRelease}
                                                                edge="end"
                                                            >
                                                                {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </Stack>

                                            <Box>
                                                {password.length > 0 ? (
                                                    <PasswordChecklist
                                                        rules={["minLength", "number", "capital", "match"]}
                                                        minLength={8}
                                                        iconSize={14}
                                                        value={password}
                                                        valueAgain={passwordAgain}
                                                        onChange={(isValid) => { setValidPass(isValid) }}
                                                        messages={{
                                                            minLength: "Minst 8 tecken.",
                                                            number: "Minst en siffra",
                                                            capital: "Minst en stor bokstav",
                                                            match: "Lösenorden matchar",
                                                        }}
                                                    />
                                                ) : ("")}

                                            </Box>

                                            <Box sx={{ marginTop: '20px', width: '300px' }}>
                                                {savingUser ? (
                                                    <LinearProgress />
                                                ) : ("")}

                                                <Button disabled={!validPass || savingUser} fullWidth type="submit" variant="contained" >
                                                    Skapa konto
                                                </Button>
                                            </Box>

                                        </Stack>
                                    </form>
                                )}
                            </>
                        )}
                    </Paper>
                </Box>
            </div >
        </>
    )
}

export default Invite