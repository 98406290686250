import { ref, set, update } from "firebase/database";
import { SESSION_PATH, SHUNT_CUSTOMDATA } from "../../../constants/firebasePaths";

const saveOverallPriceInfo = function saveOverallPriceInfo(database, sessionID, shippingOption) {
    //console.log("ShuntID:", shuntID);
    const totalPriceRef = ref(database, SESSION_PATH + sessionID + SHUNT_CUSTOMDATA);

    let snapData = {
        offerLocked: true,
        shipOption: shippingOption,
    };

    const promises = [];

    //console.log("Saving Data");
    promises.push(update(totalPriceRef, snapData));

    return Promise.all(promises);
}

const unlockSessionOffer = function unlockSessionOffer(database, sessionID, isLocked) {
    const totalPriceRef = ref(database, SESSION_PATH + sessionID + SHUNT_CUSTOMDATA);

    let snapData = {
        offerLocked: isLocked,
    };

    const promises = [];

    //console.log("Saving Data");
    promises.push(update(totalPriceRef, snapData));

    return Promise.all(promises);
}

export { saveOverallPriceInfo, unlockSessionOffer } 