import { collection, getDocs } from "firebase/firestore";
import { SHUNT_DIMS_PATH } from "../constants/firebasePaths";
import { ContextEnum } from "./contextEnum";

export class FSDataEnum extends ContextEnum {
    static Dims = new FSDataEnum("dims", "setDims")
    static FlexDims = new FSDataEnum("flexDims", "setFlexDims");
    static SmartDims = new FSDataEnum("smartDims", "setSmartDims");

    async getContextValue(contextParam, firestore) {
        if (contextParam.state?.val != null && contextParam.state?.val[this.name] != null){
            return contextParam.state?.val[this.name];
        }
        else{
            if (this.name === 'dims'){
                const conTypeDoc = collection(firestore, SHUNT_DIMS_PATH);
                const res = await getDocs(conTypeDoc)
                const list = [];
    
                res.forEach((doc) => {
                    list.push(doc.data())
                });
                
                return list;
            }
            else{
                return '';
            }
        }
    }

}