import { useState, useEffect } from 'react';
import { ConnectionCard } from './connectionCard';
import { useNavigate } from 'react-router-dom';
import { useAdjustParams, useDriftParams, useErrorContext, useNoticeContext, useProductLineParams, usePumpParams, useShuntParams, useCalcParams, useConnTypes } from "../../../../contexts"
import { clearShuntData } from '../../../../firebase/functions/saveShuntData';
import { clearDriftData } from '../../../../firebase/functions/saveDriftParams';
import { clearCalcData, saveProductLineData } from '../../../../firebase/functions';
import { useDatabase } from 'reactfire';
import { useSessionParams } from '../../../../contexts/SessionParams';
import { SessionParamsEnum } from '../../../../enums/sessionParamsEnum';
import { AdjustmentsEnum, CalcParamsEnum, DriftParamEnum, ProductLineEnum, ShuntParamsEnum } from '../../../../enums';
import { PumpParamEnum } from '../../../../enums/pumpParamsEnum';
import { PRODUCTLINE_FLEX, PRODUCTLINE_GREEN, PRODUCTLINE_SMART, PRODUCTLINE_STANDARD } from '../../../../constants/productlineConstants';
import { getCurrentHighPosition } from '../../../../firebase/functions/getCurrentHighPosition';
import { useWizardParams } from '../../../../contexts/wizardParams';
import { WizardParamEnum } from '../../../../enums/wizardParamsEnum';
import { ConfirmChangedPrinciple } from './confirmChangedPrinciple';


export const ConnectionList = ({ prodLine, isDisabled, selectedPLPrinciple, prevPLLine, prevPLPriciple, setSelectedPLPrinciple, handleDoubleClick }) => {
    const database = useDatabase();
    const sessionParams = useSessionParams();
    const productLine = useProductLineParams();
    const connTypes = useConnTypes();
    const navigate = useNavigate();
    const adjustParams = useAdjustParams();
    const calcParams = useCalcParams();
    const driftParams = useDriftParams();
    const errorContext = useErrorContext();
    const noticeContext = useNoticeContext();
    const pumpParams = usePumpParams();
    const shuntParams = useShuntParams()
    const wizardParams = useWizardParams();

    const [currentlyNewPick, setCurrentlyNewPick] = useState('');
    const [openConfirmChange, setOpenConfirmChange] = useState(false);

    const handleOpenConfirmChangeModal = () => { setOpenConfirmChange(true) }
    const handleCloseConfirmChangeModal = () => {
        setOpenConfirmChange(false)
        setCurrentlyNewPick('');
    }


    const clearContexts = () => {
        //console.log("Clear ConnectionList")
        if (SessionParamsEnum.IsApiSession.getContextValue(sessionParams) !== true) {
            DriftParamEnum.clearData(driftParams);
        }
        ProductLineEnum.ProductGreenLevel.clearData(productLine);
        AdjustmentsEnum.clearData(adjustParams);
        CalcParamsEnum.clearData(calcParams);

        PumpParamEnum.clearData(pumpParams);
        ShuntParamsEnum.clearData(shuntParams);

        errorContext.dispatch({ type: 'clearErrorList' })
        noticeContext.dispatch({ type: 'clearNoticeList' })
    }

    const getSelected = () => {
        if (ProductLineEnum.ProductPrincip.getContextValue(productLine) != null) {
            return ProductLineEnum.ProductPrincip.getContextValue(productLine);
        }
        else {
            return '';
        }
    }

    //const [selected, setSelected] = useState(getSelected());

    //console.log("Product", productLine.state.productLine.princip)

    const setCurrent = (choosen) => {
        setSelectedPLPrinciple(choosen)
        /*
        if (SessionParamsEnum.MaxStep.getContextValue(sessionParams) >= 1) {
            console.log("Things will be wiped...(Card)")
        }
        else {
            ProductLineEnum.ProductPrincip.setContextValue(productLine, choosen);

            setSelected(choosen);
            clearContexts();

            clearShuntData(database, sessionParams).then(() => {
                //console.log("Done");
            });
            if (SessionParamsEnum.IsApiSession.getContextValue(sessionParams) !== true) {
                clearDriftData(database, sessionParams).then(() => {
                    //console.log("Done Drift");
                });
            }
            clearCalcData(database, sessionParams).then(() => {
                //console.log("Done");
            });

            SessionParamsEnum.MaxStep.setContextValue(sessionParams, 0, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
        }*/
    }

    const isSelected = (document) => {
        return document === selectedPLPrinciple;
    }

    const isPrevious = (document) => {
        if (prevPLLine === prodLine) {
            return document === prevPLPriciple;
        }
        else{
            return false;
        }
    }

    /*
    useEffect(() => {
        if (ProductLineEnum.ProductPrincip.getContextValue(productLine) != null) {
            setSelected(ProductLineEnum.ProductPrincip.getContextValue(productLine))
        }
        else {
            setSelected('')
        }
    }, [productLine])*/

    // const firestore = useFirestore();
    // const typesref = collection(firestore, firestoreRef);
    // const result = useFirestoreCollectionData(typesref);

    // useEffect(() => {
    //     if (result.data != null && result.data.length > 0) {
    //         setConnectionTypes(result.data);
    //     }

    // }, [result])

    // if (result.status === 'loading') {
    //     return <span>loading...</span>;
    // }

    function filterOptions(obj) {
        if (prodLine === PRODUCTLINE_STANDARD || prodLine === PRODUCTLINE_GREEN) {
            return obj.type !== PRODUCTLINE_FLEX && obj.type !== PRODUCTLINE_SMART;
        }
        else if (prodLine === PRODUCTLINE_FLEX) {
            return obj.type === PRODUCTLINE_FLEX;
        }
        else if (prodLine === PRODUCTLINE_SMART) {
            return obj.type === PRODUCTLINE_SMART;
        }
        else {
            return false;
        }
    }

    const doubleClickType = async (document) => {
        setCurrent(document?.id);
        if (SessionParamsEnum.CurrentStep.getContextValue(sessionParams) === 0 || SessionParamsEnum.CurrentStep.getContextValue(sessionParams) == null) {
            SessionParamsEnum.CurrentStep.setContextValue(sessionParams, 1, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
        }

        if (ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) == null || ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) === -1) {
            let pos = await getCurrentHighPosition(database, sessionParams);
            ShuntParamsEnum.ShuntPosition.setContextValue(shuntParams, pos + 1, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams), SessionParamsEnum.SessionID.getContextValue(sessionParams));
        }

        saveProductLineData(database, sessionParams, productLine);
        WizardParamEnum.clearData(wizardParams);
        navigate("/stepper#step2");
    }

    return (
        <>
            <div>
                {connTypes.filter(filterOptions).map((document) => {
                    return (
                        <div key={'div-' + document.id}>
                            {(isDisabled === false) ? (
                                <div style={{ cursor: "pointer" }} key={document.id} onClick={() => { setCurrent(document.id) }} onDoubleClick={() => { handleDoubleClick(prodLine, document.id) }}>
                                    <ConnectionCard connectionObj={document} isSelected={isSelected(document.id)} isPrevious={isPrevious(document.id)} />
                                </div>
                            ) : (
                                <div style={{ backgroundColor: 'white' }} key={document.id} >
                                    <ConnectionCard connectionObj={document} isSelected={isSelected(document.id)} isPrevious={isPrevious(document.id)} />
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>
        </>

    );
}