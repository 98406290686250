import { equalTo, get, onValue, orderByChild, query, ref } from "firebase/database";
import { useEffect, useState } from "react"
import { useDatabase } from "reactfire";
import { SESSION_PATH, SESSION_SHUNT_PATH, SHUNT_COMMENT_PATH } from "../constants/firebasePaths";


export const useGetMySessions = (userID) => {
    const database = useDatabase();
    const [sessions, setSessions] = useState([]);


    useEffect(() => {
        if (userID != null && userID !== "") {
            const sessionsRef = query(ref(database, SESSION_PATH), orderByChild('owner'), equalTo(userID));

            const valueRef = onValue(sessionsRef, (snap) => {
                let localList = [];
                if (snap.exists()) {
                    snap.forEach((child) => {
                        localList.push({ id: child.key, ...child.val() })
                    });
                    setSessions(localList);
                }
            });

            return () => {
                valueRef();
            }
        }
    }, [userID])

    return { sessions };
}