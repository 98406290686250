import { PRODUCTFAMILY_SR, PRODUCTFAMILY_VA } from "../../constants/productlineConstants";
import { getAdjustmentValveModelWithoutContext } from "./helpFunctions/getKvIVModel";
import { roundToDecimals } from "./helpFunctions/roundTo4Decimals";

function calculateBalancingKV(prodFamily, prodLine, cvIsInterval, primDrivingPressure, hasMainPump, extraBV, primFlow, dimRow, calcMode, adjustmentValve, primInternalPressureDrop) {
    if (prodFamily === PRODUCTFAMILY_VA) {
        return -1;
    }

    if (prodFamily === PRODUCTFAMILY_SR) {
        if (cvIsInterval === true) {
            return 10;
        }
    }

    if (hasMainPump === false || primDrivingPressure <= 0) {
        return 0;
    }

    let kvIV = dimRow[getAdjustmentValveModelWithoutContext(calcMode, prodLine, adjustmentValve, dimRow.dim)];
    let openIVValue = Math.pow(36 * primFlow, 2) / Math.pow(kvIV, 2);
    //console.log("DP IV: ", openIVValue);
    let balancingPressureDrop = primDrivingPressure - (primInternalPressureDrop - openIVValue);
    //console.log("DP: ", balancingPressureDrop);

    if (balancingPressureDrop <= 0) {
        return 0;
    }

    let res = 36 * primFlow / Math.sqrt(balancingPressureDrop);
    //console.log("DP Res: ", res);
    return roundToDecimals(res, 5);
}

export { calculateBalancingKV }