import { doc, getDoc } from "firebase/firestore";

const getPriceInsulationValue = async function getPriceInsulationValue(firestore, insulation, dim) {
    let insulationDoc =  doc(firestore, '/PriceValues/Insulation/options/' + insulation);

    const res = await getDoc(insulationDoc)
    
    //console.log("Res:", dim, res.data().basePrice[dim], res.data().enumFactor);
    return Math.round(Number(res.data().basePrice[dim] * Number(res.data().enumFactor)));
}

export { getPriceInsulationValue }