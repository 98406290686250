import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Collapse, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { collection } from "firebase/firestore";
import { Fragment, useEffect, useState } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { numberWithSpaceSeparator } from "../../../functions/numberFunctions";

const dimText = "Dimension";
const dimsText = "Dimensioner";
const prodFamTest = "Produktfamiljer";
const productListText = "Produktlista";
const variantText = "Varianter";
const altText = "Alternativ";
const basePriceText = "Baspris";
const curPriceText = "Aktuellt pris";
const enumFactorText = "Uppräkningsfaktor";

const Row = ({ priceCat }) => {
    const firestore = useFirestore();

    const [open, setOpen] = useState(false);
    const [openDim, setOpenDim] = useState({});
    const [update, setUpdate] = useState(false);

    const optionsDoc = collection(firestore, '/PriceValues/' + priceCat.NO_ID_FIELD + '/options/');
    const firestoreRes = useFirestoreCollectionData(optionsDoc);

    const [optionsData, setOptionsData] = useState([]);

    const openDimVal = (val) => {
        if (openDim[val] == null) {
            openDim[val] = true;
        }
        else {
            openDim[val] = !openDim[val];
        }
        setUpdate(!update);
        //console.log(openDim)
    }

    useEffect(() => {
        if (firestoreRes.data != null) {
            //console.log("Data: ", firestoreRes.data)
            setOptionsData(firestoreRes.data);
        }

    }, [firestoreRes])

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.action.hover,
        // },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    /**
     * 
     * @param {Number} level 
     * @returns 
     */
    const displayDropdownText = (level) => {
        if (level === 0) {
            if (priceCat?.basePrice == null) {
                return altText;
            }
            else if (priceCat?.dimBased === false){
                return productListText;
            }
        }
        else {
            if (priceCat?.NO_ID_FIELD === 'PipeCoupling') {
                return prodFamTest;
            }
        }

        return dimsText;
    }

    const displaySubCategory = () => {
        if (priceCat?.basePrice != null) {
            return (
                <Table size="small">
                    <TableHead>
                        <StyledTableRow>
                            <TableCell sx={{ width: "120px" }}>{dimText}</TableCell>
                            <TableCell>{basePriceText}</TableCell>
                            <TableCell>{curPriceText}</TableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(priceCat?.basePrice).sort((a, b) => a.localeCompare(b)).map((key) => {
                            return (
                                <StyledTableRow>
                                    <TableCell sx={{ width: "120px" }}>{key}</TableCell>
                                    <TableCell sx={{ width: "120px" }}>{numberWithSpaceSeparator(priceCat?.basePrice[key])} {isNaN(priceCat?.basePrice[key]) === false && "kr"}</TableCell>
                                    <TableCell>{isNaN(priceCat?.basePrice[key]) === false ? (numberWithSpaceSeparator(Math.round(Number(priceCat?.basePrice[key]) * Number(priceCat?.enumFactor))) + " kr") : ( " - ")} </TableCell>
                                </StyledTableRow>
                            )
                        })}
                        <Typography sx={{ paddingTop: '10px', paddingBottom: '10px', marginLeft: '15px' }}>
                            <strong>{enumFactorText}</strong>: {priceCat?.enumFactor}
                        </Typography>
                    </TableBody>
                </Table>
            )
        }
        else if (optionsData != null && optionsData.length > 0) {
            return (
                <Table size="small">
                    <TableBody>
                        {optionsData.map((row) => {
                            return (
                                <Fragment>
                                    <StyledTableRow>
                                        <TableCell sx={{ width: "150px" }}>{row?.name}</TableCell>
                                        <TableCell sx={{ width: "300px" }}></TableCell>
                                        <TableCell align="left" >
                                            <Button
                                                aria-label="expand row"
                                                size="small"
                                                className="models-btn"
                                                onClick={() => openDimVal(row.NO_ID_FIELD)}
                                            >
                                                {openDim[row.NO_ID_FIELD] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                {displayDropdownText(1)}
                                            </Button>
                                        </TableCell>
                                    </StyledTableRow>
                                    <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                            <Collapse in={openDim[row.NO_ID_FIELD]} timeout="auto" unmountOnExit>
                                                <Box sx={{ marginLeft: '20px' }}>
                                                    <OptionRow priceCat={priceCat} rowData={row} />
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            )
        }
        else {
            return (
                <Table size="small">
                    <TableHead>
                        <StyledTableRow>
                            <TableCell sx={{ width: "80px" }}>{variantText}</TableCell>
                            <TableCell></TableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>

                    </TableBody>
                </Table>
            )
        }
    }

    return (
        <>
            <StyledTableRow style={{ height: "15px" }}>
                <TableCell sx={{ width: "120px" }}>{priceCat.name}</TableCell>
                <TableCell sx={{ width: "300px" }}></TableCell>
                <TableCell align="left">
                    <Button
                        aria-label="expand row"
                        size="small"
                        className="models-btn"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        {displayDropdownText(0)}
                    </Button>
                </TableCell>
            </StyledTableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            {displaySubCategory()}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

const OptionRow = ({ priceCat, rowData }) => {
    const firestore = useFirestore();

    //console.log("RowData:", rowData)
    const optionsDoc = collection(firestore, '/PriceValues/' + priceCat.NO_ID_FIELD + '/options/' + rowData.NO_ID_FIELD + '/prodFamily/');
    const firestoreRes = useFirestoreCollectionData(optionsDoc);

    const [optionsData, setOptionsData] = useState([]);

    const [openDim, setOpenDim] = useState({});
    const [update, setUpdate] = useState(false);

    const openDimVal = (val) => {
        if (openDim[val] == null) {
            openDim[val] = true;
        }
        else {
            openDim[val] = !openDim[val];
        }
        setUpdate(!update);
    }

    useEffect(() => {
        if (firestoreRes.data != null) {
            //console.log("Data (fam): ", firestoreRes.data)
            setOptionsData(firestoreRes.data);
        }

    }, [firestoreRes])


    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.action.hover,
        // },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    if (rowData?.basePrice != null) {
        return (
            <Table size="small">
                <TableHead>
                    <StyledTableRow>
                        <TableCell sx={{ width: "120px" }}>{dimText}</TableCell>
                        <TableCell>{basePriceText}</TableCell>
                        <TableCell>{curPriceText}</TableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(rowData?.basePrice).sort((a, b) => a.localeCompare(b)).map((key) => {
                        return (
                            <StyledTableRow>
                                <TableCell sx={{ width: "120px" }}>{key}</TableCell>
                                <TableCell sx={{ width: "120px" }}>{numberWithSpaceSeparator(rowData?.basePrice[key])} kr</TableCell>
                                <TableCell>{numberWithSpaceSeparator(Math.round(Number(rowData?.basePrice[key]) * Number(rowData?.enumFactor)))} kr</TableCell>
                            </StyledTableRow>
                        )
                    })}
                    <Typography sx={{ paddingTop: '10px', paddingBottom: '10px', marginLeft: '15px' }}>
                        <strong>{enumFactorText}</strong>: {rowData?.enumFactor}
                    </Typography>
                </TableBody>
            </Table>
        )
    }
    else {
        return (
            <Table size="small">
                <TableHead>
                </TableHead>
                <TableBody>
                    {optionsData.map((family) => {
                        return (
                            <Fragment>
                                <StyledTableRow>
                                    <TableCell sx={{ width: "140px" }}>{family?.name}</TableCell>
                                    <TableCell sx={{ width: "300px" }}></TableCell>
                                    <TableCell align="left">
                                        <Button
                                            aria-label="expand row"
                                            size="small"
                                            className="models-btn"
                                            onClick={() => openDimVal(family?.NO_ID_FIELD)}
                                        >
                                            {openDim[family?.NO_ID_FIELD] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            {dimsText}
                                        </Button>
                                    </TableCell>
                                </StyledTableRow>
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                        <Collapse in={openDim[family?.NO_ID_FIELD]} timeout="auto" unmountOnExit>
                                            <Table size="small">
                                                <TableHead>
                                                    <StyledTableRow>
                                                        <TableCell sx={{ width: "120px" }}>{dimText}</TableCell>
                                                        <TableCell sx={{ width: "120px" }}>{basePriceText}</TableCell>
                                                        <TableCell>{curPriceText}</TableCell>
                                                    </StyledTableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {Object.keys(family?.basePrice).sort((a, b) => a.localeCompare(b)).map((key) => {
                                                        return (
                                                            <StyledTableRow>
                                                                <TableCell sx={{ width: "120px" }}>{key}</TableCell>
                                                                <TableCell sx={{ width: "120px" }}>{numberWithSpaceSeparator(family?.basePrice[key])} kr</TableCell>
                                                                <TableCell>{numberWithSpaceSeparator(Math.round(Number(family?.basePrice[key]) * Number(family?.enumFactor)))} kr</TableCell>
                                                            </StyledTableRow>
                                                        )
                                                    })}
                                                    <Typography sx={{ paddingTop: '10px', paddingBottom: '10px', marginLeft: '15px' }}>
                                                        <strong>{enumFactorText}</strong>: {family?.enumFactor}
                                                    </Typography>
                                                </TableBody>
                                            </Table>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>


                            </Fragment>

                        )
                    })}
                </TableBody>
            </Table>
        )
    }

}

export const SystemSpecPricing = () => {
    const firestore = useFirestore();

    const pricingDoc = collection(firestore, '/PriceValues/');
    const firestoreRes = useFirestoreCollectionData(pricingDoc);

    const [pricingData, setPricingData] = useState([]);

    useEffect(() => {
        if (firestoreRes.data != null) {
            //console.log("Data: ", firestoreRes.data)
            setPricingData(firestoreRes.data);
        }

    }, [firestoreRes])

    return (
        <Box sx={{ padding: "20px" }}>
            <Typography component={'h3'}>
                Dimensionsbaserade:
            </Typography>
            <TableContainer component={Paper} sx={{ width: '800px' }}>
                <Table sx={{ paddingRight: '20px' }} size="small" aria-label="customized table">
                    <TableBody>
                        {pricingData.map((priceRow) => {
                            if (priceRow?.dimBased === true && priceRow?.used !== false) {
                                return (
                                    <Row priceCat={priceRow} />
                                )
                            }
                            else {
                                return null;
                            }

                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography component={'h3'} sx={{ marginTop: '10px' }}>
                Styckbaserade:
            </Typography>
            <TableContainer component={Paper} sx={{ width: '800px' }}>
                <Table sx={{ paddingRight: '20px' }} size="small" aria-label="customized table">
                    <TableBody>
                        {pricingData.map((priceRow) => {
                            if (priceRow?.dimBased === false && priceRow?.used !== false) {
                                return (
                                    <Row priceCat={priceRow} />
                                )
                            }
                            else {
                                return null;
                            }

                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}