import { Paper } from "@mui/material"
import { useEffect } from "react";
import { MyProfileMainComponent } from "../myProfile/myProfileMainComponent";

export const MyProfilePanel = () => {
    return (
        <Paper className='overview-main-panel'>
            <div className="overview-content-size">
                <MyProfileMainComponent />
            </div >
        </Paper >
    )
}