import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react"
import { useFirestore } from "reactfire";
import { ENERGYMEASUREMENT_SIEMENS_UH50_A_DIMS_PATH, ENERGYMEASUREMENT_SIEMENS_UH50_C_DIMS_PATH } from "../constants/firebasePaths";

export const useGetEnergyMeasurementList = () => {
    const firestore = useFirestore();
    const [heatEnergyMeasurementList, setHeatEnergyMeasurementList] = useState([]);
    const [coolingEnergyMeasurementList, setCoolingEnergyMeasurementList] = useState([]);


    useEffect(() => {
        const uh50CRef = collection(firestore, ENERGYMEASUREMENT_SIEMENS_UH50_C_DIMS_PATH);
        const uh50ARef = collection(firestore, ENERGYMEASUREMENT_SIEMENS_UH50_A_DIMS_PATH);

        getDocs(uh50CRef).then((result) => {
            let localList = [];
            result.forEach((child) => {
                localList.push({id: child.id, ...child.data()});
            });

            setCoolingEnergyMeasurementList(localList);
        });

        getDocs(uh50ARef).then((result) => {
            let localList = [];
            result.forEach((child) => {
                localList.push({id: child.id, ...child.data()});
            });

            setHeatEnergyMeasurementList(localList);
        });
    }, []);

    return { heatEnergyMeasurementList, coolingEnergyMeasurementList };
}