import { httpsCallable } from "firebase/functions"

const setSessionStatusFunc = async function setSessionStatus(functions, sessionID, status) {
    functions.region = 'europe-west1';
    const setSessionStatus = httpsCallable(functions, "setSessionStatus");

    return setSessionStatus({
        sessionID: sessionID,
        status: status,
    });
}

export { setSessionStatusFunc }