import { logEvent } from 'firebase/analytics';
import { useEffect } from 'react';
import { useAnalytics } from 'reactfire';
import { InspectPage } from '../modules/inspectPage/inspectPage';


export const StepRes = () => {
    const analytics = useAnalytics();

    useEffect(() => {
        logEvent(analytics, 'page_view', { page_location: window.location.href, page_title: "Step 5" });
    }, [window.location.href]);

    return (
        <InspectPage />
    );
};