import { FormLabel, FormControl, FormControlLabel, FormGroup, Checkbox } from "@mui/material"
import { ENVIROMENT_CERTIFICATIONS, QUESTION_CERTIFICATION_KEY } from "../../../../constants/wizardConstants"


export const EnviromentCertQuestion = ({ enviromentCert, answerQuestion }) => {

    return (
        <FormControl margin={'dense'} variant="standard">
            <FormLabel>Bedömningar & certifieringar</FormLabel>
            <FormGroup>
                <FormControlLabel
                    sx={{ height: '28px', width: '100px' }}
                    control={
                        <Checkbox checked={enviromentCert[ENVIROMENT_CERTIFICATIONS.sundaHus.value]} onChange={() => answerQuestion(QUESTION_CERTIFICATION_KEY, ENVIROMENT_CERTIFICATIONS.sundaHus.value)} />
                    }
                    label={ENVIROMENT_CERTIFICATIONS.sundaHus.label}
                />
                <FormControlLabel
                    sx={{ height: '28px', width: '175px' }}
                    control={
                        <Checkbox checked={enviromentCert[ENVIROMENT_CERTIFICATIONS.byggVaru.value]} onChange={() => answerQuestion(QUESTION_CERTIFICATION_KEY, ENVIROMENT_CERTIFICATIONS.byggVaru.value)} />
                    }
                    label={ENVIROMENT_CERTIFICATIONS.byggVaru.label}
                />
                <FormControlLabel
                    sx={{ height: '28px', width: '305px' }}
                    control={
                        <Checkbox checked={enviromentCert[ENVIROMENT_CERTIFICATIONS.EPD.value]} onChange={() => answerQuestion(QUESTION_CERTIFICATION_KEY, ENVIROMENT_CERTIFICATIONS.EPD.value)} />
                    }
                    label={ENVIROMENT_CERTIFICATIONS.EPD.label}
                />
            </FormGroup>
        </FormControl>
    )
}