export const ADJUSTMENT_PARAMS = 'adjustParams';
export const CALC_PARAMS = 'calcParams';
export const DRIFT_PARAMS = 'driftParams';
export const ERRORLIST_PARAMS = 'errorList';
export const NOTICELIST_PARAMS = 'noticeList';
export const FSDATA_PARAMS = 'FSData';
export const MODIFYSPEC_PARAMS = 'modifySpec';
export const PRODUCTLINE_PARAMS = 'productLine';
export const PUMPDATA_PARAMS = 'pumpParams';
export const SESSION_PARAMS = 'sessionParams';
export const SHUNT_PARAMS = 'shuntParams';
export const WIZARD_PARAMS = 'wizardParams';