import { Box, Button, Grid, Modal, Paper, Typography } from "@mui/material"
import { SessionInputField } from "../landing/components/sessionInputField"
import { SessionParamsEnum } from "../../../enums"
import { useSessionParams, useShuntParams } from "../../../contexts"
import { saveSessionData } from "../../../firebase/functions"
import { useAnalytics, useAuth, useDatabase } from "reactfire"
import { getSessionCurrentShunt } from "../../../firebase/functions/getSessionData"
import { useNavigate } from "react-router-dom"
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { containsIlligalCharacters } from "../../../functions/HelperFunctions/containsIlligalChars"
import { logEvent } from "firebase/analytics"
import { useState } from "react"
import { FindSessionOnFB } from "../../utils/findSessionOnFB"


export const NewSessionPanel = () => {
    const sessionParams = useSessionParams();
    const shuntParams = useShuntParams();
    const analytics = useAnalytics();
    const database = useDatabase();
    const auth = useAuth()
    const navigate = useNavigate();

    const [searchID, setSearchID] = useState('');
    const [isEdit, setIsEdit] = useState(false);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [openError, setOpenError] = useState(false);
    const handleOpenError = () => setOpenError(true);
    const handleCloseError = () => setOpenError(false);

    const isApiSession = SessionParamsEnum.IsApiSession.getContextValue(sessionParams);

    const createSession = async () => {
        let sessionID;
        let shuntID;
        if (isApiSession !== true) {
            sessionID = await SessionParamsEnum.createSessionID(sessionParams);
            saveSessionData(sessionID, database, sessionParams, auth)
            shuntID = await SessionParamsEnum.addSessionShunt(sessionParams, database, true, shuntParams);
        }
        else if (SessionParamsEnum.SessionID.getContextValue(sessionParams) != null && SessionParamsEnum.SessionID.getContextValue(sessionParams) !== "") {
            sessionID = SessionParamsEnum.SessionID.getContextValue(sessionParams);
            saveSessionData(sessionID, database, sessionParams, auth)
            let current = await getSessionCurrentShunt(database, sessionParams);
            if (current == null) {
                shuntID = await SessionParamsEnum.addSessionShunt(sessionParams, database, true, shuntParams);
            }
            else {
                shuntID = current;
                SessionParamsEnum.CurrentShunt.setContextValue(sessionParams, current);
                SessionParamsEnum.MaxStep.setContextValue(sessionParams, 0, database, current);
                SessionParamsEnum.CurrentStep.setContextValue(sessionParams, 0, database, current);
            }
        }
        else {
            console.error("Is Api but no sessionID is set");
            return;
        }

        //console.log("Shunt: ", shuntID);
        navigate("/stepper");
    }

    const editSession = () => {
        if (containsIlligalCharacters(SessionParamsEnum.SearchID.getContextValue(sessionParams)) === false) {
            logEvent(analytics, 'search_edit', { type: "editSession", search_term: SessionParamsEnum.SearchID.getContextValue(sessionParams) });

            setSearchID(String(SessionParamsEnum.SearchID.getContextValue(sessionParams)).trim());
            setIsEdit(true);
            setOpen(true);
        }
        else {
            handleOpenError();
        }
    }

    const viewSession = () => {
        if (containsIlligalCharacters(SessionParamsEnum.SearchID.getContextValue(sessionParams)) === false) {
            logEvent(analytics, 'search_view', { type: "viewSession", search_term: SessionParamsEnum.SearchID.getContextValue(sessionParams) });
            setSearchID(String(SessionParamsEnum.SearchID.getContextValue(sessionParams)).trim());
            setIsEdit(false);
            setOpen(true);
        }
        else {
            handleOpenError();
        }
    }

    return (
        <>
            <Paper style={{ display: 'flex', flexDirection: 'column', width: "870px" }}>
                <div className="overview-content-size">
                    <Box padding={'15px'} paddingLeft={'23px'}>
                        <Grid container spacing={5}>
                            <Grid item>
                                <Box width={'350px'}>
                                    <Typography variant='h3' fontWeight="bold" sx={{ mb: '10px', color: "#183851" }}>Ny utformning:
                                    </Typography>
                                    <Typography variant='h4' fontWeight="bold" sx={{ color: "#183851" }}>Ange:
                                    </Typography>
                                    <SessionInputField
                                        type={SessionParamsEnum.Facility}
                                        context={sessionParams}
                                        label={"Anläggning"}
                                        location={"anläggning"}
                                    />

                                    <br />
                                    <SessionInputField
                                        type={SessionParamsEnum.ProjNr}
                                        context={sessionParams}
                                        label={"Projektnummer"}
                                        location={"projNr"}
                                    />

                                    <Button onClick={createSession} size='small' variant="contained" color="primary" sx={{ mt: '10px' }}>
                                        Skapa <ArrowCircleRightIcon style={{ marginLeft: '10px' }} />
                                    </Button>
                                </Box>
                            </Grid>

                            <Grid item>
                                <Box width={'350px'}>
                                    {isApiSession !== true ? (
                                        <>
                                            <Typography variant='h3' fontWeight="bold" sx={{ mt: '25px', color: "#183851" }}>Tidigare session:
                                            </Typography>

                                            <SessionInputField
                                                type={SessionParamsEnum.SearchID}
                                                context={sessionParams}
                                                label={"Sessions-iD"}
                                                location={"sessionsID"} />

                                            <Button onClick={viewSession} size='small' variant="contained" disabled={SessionParamsEnum.SearchID.getContextValue(sessionParams) == undefined || SessionParamsEnum.SearchID.getContextValue(sessionParams)?.length <= 14} color="primary" sx={{ mr: '20px', mt: '10px' }}>Granska <ArrowCircleRightIcon style={{ marginLeft: '10px' }} /></Button>
                                            <Button onClick={editSession} disabled={SessionParamsEnum.SearchID.getContextValue(sessionParams) == null || SessionParamsEnum.SearchID.getContextValue(sessionParams)?.length <= 14} size='small' variant="contained" color="primary" sx={{ mt: '10px' }}>Redigera (ny session) <ArrowCircleRightIcon style={{ marginLeft: '10px' }} /></Button>
                                        </>
                                    ) : (
                                        <Box style={{ marginBottom: '141px', marginTop: '30px' }}>

                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </div >
            </Paper >

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div>
                    <FindSessionOnFB sessionID={searchID} edit={isEdit} />
                </div>
            </Modal>

            <Modal
                open={openError}
                onClose={handleCloseError}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '300px',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    borderRadius: '5px',
                    boxShadow: 24,
                    p: 4,
                }}>
                    Det angivna Sessions-ID:t innehåller ogiltiga tecken.
                </Box>
            </Modal>
        </>

    )
}