import { Fragment, useEffect } from 'react'
import { useAuth, useUser } from 'reactfire'
import { useNavigate } from 'react-router-dom'
import { signInAnonymously } from 'firebase/auth';
import { Start } from './Start';

const Landing = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const user = useUser();

    useEffect(() => {
        user.firstValuePromise.then(async () => {
            if (auth.currentUser == null) {
                await signInAnonymously(auth);
                navigate("/home", { replace: true });
            }
            else {
                navigate("/home", { replace: true });
            }
        });
    }, [user.firstValuePromise]);

    return (
        <>
            <br />
            <div style={{
                margin: "0px 0px 0px 0px",
                width: "96.3%",
                borderRadius: "4px",
                padding: "20px",
                justifyContent: 'center',
                backgroundColor: '#E1E2E4',
            }}>
                <Start />
            </div>
        </>
    )
}

export default Landing