import { ref, set } from "firebase/database";
import { ContextEnum } from "./contextEnum";

import { SESSION_SHUNT_PATH } from "../constants/firebasePaths";
import { PRIM_DIM_KEY, SEC_DIM_KEY, PRIM_TEMP_IN_KEY, SEC_TEMP_IN_KEY, PRIM_TEMP_OUT_KEY, SEC_TEMP_OUT_KEY, PRIM_FLOW_KEY, SEC_FLOW_KEY, PRIM_DRIVING_PRESSURE_KEY, SEC_EXTERNAL_PRESSURE_DROP_KEY, PRIM_EXTERNAL_PRESSURE_DROP_KEY, CALCMODE_KEY, NUM_BACKVENTS_KEY, HAS_MAINPUMP_KEY, HAS_BACKVENTS_KEY, PEFK_KEY, IV_MOD_KEY, LOCKED_PRIM_DRIVING_PRESSURE_KEY, LOCKED_PRIM_EXTERNAL_PRESSURE_DROP_KEY, LOCKED_PRIM_FLOW_KEY, LOCKED_PRIM_TEMP_IN_KEY, LOCKED_PRIM_TEMP_OUT_KEY, LOCKED_SEC_EXTERNAL_PRESSURE_DROP_KEY, LOCKED_SEC_FLOW_KEY, LOCKED_SEC_TEMP_IN_KEY, LOCKED_SEC_TEMP_OUT_KEY, FLOW_CALC_MODE_KEY, SEC_FLOW_EFFECT_KEY, USE_CONNECTED_ENERGY_KEY } from "../constants/keys/driftParamsKeys";
import { WIZARD_CLEAR_SYSTEM_FLUID_MAIN } from "../constants/keys/wizardKeys";

export class DriftParamEnum extends ContextEnum {
    static PrimaryDim = new DriftParamEnum(PRIM_DIM_KEY, "setPrimDim");
    static SecondaryDim = new DriftParamEnum(SEC_DIM_KEY, "setSekDim");
    static PrimTempIn = new DriftParamEnum(PRIM_TEMP_IN_KEY, "setPrimTempIn");
    static SecTempIn = new DriftParamEnum(SEC_TEMP_IN_KEY, "setSecTempIn");
    static PrimTempOut = new DriftParamEnum(PRIM_TEMP_OUT_KEY, "setPrimTempOut");
    static SecTempOut = new DriftParamEnum(SEC_TEMP_OUT_KEY, "setSecTempOut");
    static PrimFlow = new DriftParamEnum(PRIM_FLOW_KEY, "setPrimFlow");
    static SecFlow = new DriftParamEnum(SEC_FLOW_KEY, "setSecFlow");
    static SecFlowEffect = new DriftParamEnum(SEC_FLOW_EFFECT_KEY, "setSecFlowEffect");
    static PrimDrivingPressure = new DriftParamEnum(PRIM_DRIVING_PRESSURE_KEY, "setPrimDrivingPressure");
    static SecExternalPressureDrop = new DriftParamEnum(SEC_EXTERNAL_PRESSURE_DROP_KEY, "setSecExternalPressureDrop");
    static PrimExternalPressureDrop = new DriftParamEnum(PRIM_EXTERNAL_PRESSURE_DROP_KEY, "setPrimExternalPressureDrop");
    static CalcMode = new DriftParamEnum(CALCMODE_KEY, "setCalcMode")
    static NumBackVents = new DriftParamEnum(NUM_BACKVENTS_KEY, "setNumBackVents");
    static HasMainPump = new DriftParamEnum(HAS_MAINPUMP_KEY, "setHasMainPump");
    static HasBackVent = new DriftParamEnum(HAS_BACKVENTS_KEY, "setHasBackVent");
    static PEfk = new DriftParamEnum(PEFK_KEY, "setPEfk");
    static IVModification = new DriftParamEnum(IV_MOD_KEY, "setIVMod");
    static UseConnectedEnergy = new DriftParamEnum(USE_CONNECTED_ENERGY_KEY, "setUseConnectedEnergy");
    //static UpdateValues = new DriftParamEnum("updateValues", "setUpdateValues");

    static FlowCalcMode = new DriftParamEnum(FLOW_CALC_MODE_KEY, "setFlowCalcMode");

    static LockedPrimTempIn = new DriftParamEnum(LOCKED_PRIM_TEMP_IN_KEY, "setLockedPrimTempIn");
    static LockedSecTempIn = new DriftParamEnum(LOCKED_SEC_TEMP_IN_KEY, "setLockedSecTempIn");
    static LockedPrimTempOut = new DriftParamEnum(LOCKED_PRIM_TEMP_OUT_KEY, "setLockedPrimTempOut");
    static LockedSecTempOut = new DriftParamEnum(LOCKED_SEC_TEMP_OUT_KEY, "setLockedSecTempOut");
    static LockedPrimFlow = new DriftParamEnum(LOCKED_PRIM_FLOW_KEY, "setLockedPrimFlow");
    static LockedSecFlow = new DriftParamEnum(LOCKED_SEC_FLOW_KEY, "setLockedSecFlow");
    static LockedPrimDrivingPressure = new DriftParamEnum(LOCKED_PRIM_DRIVING_PRESSURE_KEY, "setLockedPrimDrivingPressure");
    static LockedSecExternalPressureDrop = new DriftParamEnum(LOCKED_SEC_EXTERNAL_PRESSURE_DROP_KEY, "setLockedSecExternalPressureDrop");
    static LockedPrimExternalPressureDrop = new DriftParamEnum(LOCKED_PRIM_EXTERNAL_PRESSURE_DROP_KEY, "setLockedPrimExternalPressureDrop");

    setToFirebase(database, shuntID, value) {
        const dbRef = ref(database, SESSION_SHUNT_PATH + shuntID + "/DriftParams/" + this.name);
        return set(dbRef, value);
    }

    /**
     * 
     * @param {*} context 
     * @param {*} database
     * @param {('SR-2' | 'SR-2-flex' | 'SR-2-smart' | 'SR-3' | 'PR-2' | 'PR-3' | 'VA-1' )} productPrincip 
     * @param {('SR-2' | 'SR-2-flex' | 'SR-2-smart' | 'SR-3' | 'PR-2' | 'PR-3' | 'VA-1' | null )} oldProductPrincip 
     * @param {string} shuntID
     * @param {*} wizardContext 
     */
    static clearDataForNewShunt = function clearDataForNewShunt(context, database, productPrincip, oldProductPrincip, shuntID, wizardContext) {
        if (oldProductPrincip === 'VA-1') {
            if (productPrincip !== 'VA-1') {
                context.dispatch({ type: "clearFB", database: database, shuntID: shuntID });
                wizardContext.dispatch({ type: WIZARD_CLEAR_SYSTEM_FLUID_MAIN, database: database, shuntID: shuntID });
            }
        }
        else {
            if (productPrincip === 'SR-2' || productPrincip === 'SR-2-flex' || productPrincip === 'SR-2-smart' || productPrincip === 'PR-2' || productPrincip === 'VA-1') {
                context.dispatch({ type: "clearPrimTempOut", database: database, shuntID: shuntID });

                if (productPrincip === 'VA-1') {
                    context.dispatch({ type: "clearFB", database: database, shuntID: shuntID });
                    wizardContext.dispatch({ type: WIZARD_CLEAR_SYSTEM_FLUID_MAIN, database: database, shuntID: shuntID });
                }

                if (productPrincip === 'SR-2-smart') {
                    context.dispatch({ type: "clearUseConnectedEnergy", database: database, shuntID: shuntID });
                }
            }
        }

    }
}