import { CV_MOUNTED_PRIM, CV_MOUNTED_SECONDARY } from "../wizardConstants";
import { ConnectionTypeInputDataListInterface } from "./types";

export const connectionTypeInputDataList: ConnectionTypeInputDataListInterface = {
    "SR-2": {
        id: "SR-2",
        cvValues: [
            { nWay: 2, mounted: CV_MOUNTED_PRIM }
        ],
    },
    "SR-2 flex Appshunt": {
        id: "SR-2 flex Appshunt",
        cvValues: [
            { nWay: 2, mounted: CV_MOUNTED_PRIM }
        ],
        dimensionInput: [
            [{ key: 'primDP', label: 'Drivtryck (Primärt)', unit: 'kPa' }],
            [{ key: 'tempPrimIn', label: 'Prim Temp till', unit: '°C' }, { key: 'tempPrimReturn', label: 'Prim Temp retur', unit: '°C' }],
            [{ key: 'secPrimIn', label: 'Sek Temp till', unit: '°C' }, { key: 'secPrimReturn', label: 'Sek Temp retur', unit: '°C' }],
            [{ key: 'secExternalDP', label: 'Tryckfall (Sekundärt)', unit: 'kPa' }],
            [{ key: 'secFlow', label: 'Flöde (Sekundärt)', unit: 'l/s' }],
        ],
    },
    "SR-3": {
        id: "SR-3",
        cvValues: [
            { nWay: 3, mounted: CV_MOUNTED_PRIM }
        ],
    },
    "PR-2": {
        id: "PR-2",
        cvValues: [
            { nWay: 3, mounted: CV_MOUNTED_SECONDARY }
        ],
    },
    "PR-2 Appshunt": {
        id: "PR-2 Appshunt",
        cvValues: [
            { nWay: 3, mounted: CV_MOUNTED_SECONDARY }
        ],
        dimensionInput: [
            [{ key: 'primDP', label: 'Drivtryck (Primärt)', unit: 'kPa' }],
            [{ key: 'tempPrimIn', label: 'Prim Temp till', unit: '°C' }, { key: 'tempPrimReturn', label: 'Prim Temp retur', unit: '°C' }],
            [{ key: 'secPrimIn', label: 'Sek Temp till', unit: '°C' }, { key: 'secPrimReturn', label: 'Sek Temp retur', unit: '°C' }],
            [{ key: 'secExternalDP', label: 'Tryckfall (Sekundärt)', unit: 'kPa' }],
            [{ key: 'secFlow', label: 'Flöde (Sekundärt)', unit: 'l/s' }],
        ],
    },
    "PR-3": {
        id: "PR-3",
        cvValues: [
            { nWay: 3, mounted: CV_MOUNTED_SECONDARY }
        ],
    },
    "VA-1": {
        id: "VA-1",
        cvValues: [
            { nWay: 3, mounted: CV_MOUNTED_SECONDARY }
        ],
    },
    "FBU-5K": {
        id: "FBU-5K",
        cvValues: [
            { nWay: 3, mounted: CV_MOUNTED_SECONDARY },
            { nWay: 2, mounted: null, use: "spetskyla" }
        ],
        dimensionInput: [
            [{ key: 'primDP', label: 'Drivtryck (Primärt, Spets)', unit: 'kPa' }],
            [{ key: 'tempPrimIn', label: 'Prim Temp till', unit: '°C' }, { key: 'tempPrimReturn', label: 'Prim Temp retur', unit: '°C' }],
            [{ key: 'secPrimIn', label: 'Sek Temp till', unit: '°C' }, { key: 'secPrimReturn', label: 'Sek Temp retur', unit: '°C' }],
            [{ key: 'secExternalDP', label: 'Tryckfall (Sekundärt)', unit: 'kPa' }],
            [{ key: 'secFlow', label: 'Flöde (Sekundärt)', unit: 'l/s' }],
        ],
    },
    "FBU-5V": {
        id: "FBU-5V",
        cvValues: [
            { nWay: 3, mounted: CV_MOUNTED_SECONDARY },
            { nWay: 2, mounted: null, use: "spetsvärme" }
        ],
        dimensionInput: [
            [{ key: 'primDP', label: 'Drivtryck (Primärt, Spets)', unit: 'kPa' }],
            [{ key: 'tempPrimIn', label: 'Prim Temp till', unit: '°C' }, { key: 'tempPrimReturn', label: 'Prim Temp retur', unit: '°C' }],
            [{ key: 'secPrimIn', label: 'Sek Temp till', unit: '°C' }, { key: 'secPrimReturn', label: 'Sek Temp retur', unit: '°C' }],
            [{ key: 'secExternalDP', label: 'Tryckfall (Sekundärt)', unit: 'kPa' }],
            [{ key: 'secFlow', label: 'Flöde (Sekundärt)', unit: 'l/s' }],
        ],
    },
    "FKU-5K": {
        id: "FKU-5K",
        cvValues: [
            { nWay: 2, mounted: CV_MOUNTED_PRIM },
        ],
        dimensionInput: [
            [{ key: 'primDP', label: 'Drivtryck (Primärt, Spets)', unit: 'kPa' }],
            [{ key: 'tempPrimIn', label: 'Prim Temp till', unit: '°C' }, { key: 'tempPrimReturn', label: 'Prim Temp retur', unit: '°C' }],
            [{ key: 'secPrimIn', label: 'Sek Temp till', unit: '°C' }, { key: 'secPrimReturn', label: 'Sek Temp retur', unit: '°C' }],
            [{ key: 'secExternalDP', label: 'Tryckfall (Sekundärt)', unit: 'kPa' }],
            [{ key: 'secFlow', label: 'Flöde (Sekundärt)', unit: 'l/s' }],
        ],
    },
    "SRX-42": {
        id: "SRX-42",
        cvValues: [
            { nWay: 2, mounted: CV_MOUNTED_PRIM },
        ],
        dimensionInput: [
            [{ key: 'systemFluid', label: 'Systemvätska' }],
            [{ key: 'primDP', label: 'Drivtryck (Primärt)', unit: 'kPa' }],
            [{ key: 'tempPrimIn', label: 'Prim Temp till', unit: '°C' }, { key: 'tempPrimReturn', label: 'Prim Temp retur', unit: '°C' }],
            [{ key: 'secPrimIn', label: 'Sek Temp till', unit: '°C' }, { key: 'secPrimReturn', label: 'Sek Temp retur', unit: '°C' }],
            [{ key: 'secExternalDP', label: 'Tryckfall (Sekundärt)', unit: 'kPa' }],
            [{ key: 'secFlow', label: 'Flöde (Sekundärt)', unit: 'l/s' }],
        ],
    },
    "SRX-43": {
        id: "SRX-43",
        cvValues: [
            { nWay: 3, mounted: CV_MOUNTED_PRIM },
        ],
        dimensionInput: [
            [{ key: 'systemFluid', label: 'Systemvätska' }],
            [{ key: 'primDP', label: 'Drivtryck (Primärt)', unit: 'kPa' }],
            [{ key: 'tempPrimIn', label: 'Prim Temp till', unit: '°C' }, { key: 'tempPrimReturn', label: 'Prim Temp retur', unit: '°C' }],
            [{ key: 'secPrimIn', label: 'Sek Temp till', unit: '°C' }, { key: 'secPrimReturn', label: 'Sek Temp retur', unit: '°C' }],
            [{ key: 'secExternalDP', label: 'Tryckfall (Sekundärt)', unit: 'kPa' }],
            [{ key: 'secFlow', label: 'Flöde (Sekundärt)', unit: 'l/s' }],
        ],
    },
    "PRX-43": {
        id: "PRX-43",
        cvValues: [
            { nWay: 3, mounted: CV_MOUNTED_SECONDARY },
        ],
        dimensionInput: [
            [{ key: 'systemFluid', label: 'Systemvätska' }],
            [{ key: 'primDP', label: 'Drivtryck (Primärt)', unit: 'kPa' }],
            [{ key: 'tempPrimIn', label: 'Prim Temp till', unit: '°C' }, { key: 'tempPrimReturn', label: 'Prim Temp retur', unit: '°C' }],
            [{ key: 'secPrimIn', label: 'Sek Temp till', unit: '°C' }, { key: 'secPrimReturn', label: 'Sek Temp retur', unit: '°C' }],
            [{ key: 'secExternalDP', label: 'Tryckfall (Sekundärt)', unit: 'kPa' }],
            [{ key: 'secFlow', label: 'Flöde (Sekundärt)', unit: 'l/s' }],
        ],
    },
    "PRUX-43FK": {
        id: "PRUX-43FK",
        cvValues: [
            { nWay: 3, mounted: CV_MOUNTED_PRIM },
        ],
        dimensionInput: [
            [{ key: 'systemFluid', label: 'Systemvätska' }],
            [{ key: 'primDP', label: 'Drivtryck (Primärt)', unit: 'kPa' }],
            [{ key: 'tempPrimIn', label: 'Prim Temp till', unit: '°C' }, { key: 'tempPrimReturn', label: 'Prim Temp retur', unit: '°C' }],
            [{ key: 'secPrimIn', label: 'Sek Temp till', unit: '°C' }, { key: 'secPrimReturn', label: 'Sek Temp retur', unit: '°C' }],
            [{ key: 'secExternalDP', label: 'Tryckfall (Sekundärt)', unit: 'kPa' }],
            [{ key: 'secFlow', label: 'Flöde (Sekundärt)', unit: 'l/s' }],
        ],
    },
    "SRBX-62VK": {
        id: "SRBX-62VK",
        cvValues: [
            { nWay: 2, mounted: CV_MOUNTED_PRIM },
            { nWay: '2 eller 3', mounted: CV_MOUNTED_SECONDARY },
        ],
        dimensionInput: [
            [{ key: 'primDP', label: 'Drivtryck', unit: 'kPa' }],
            [{ key: 'flow', label: 'Batterikörningar', toolTip: "Batterikörningar värme och kyla", unit: 'l/s' }],
            [{ key: 'tempPrimIn', label: 'Prim Temp värme', unit: '°C' }, { key: 'tempPrimReturn', label: 'Prim Temp kyla', unit: '°C' }],
        ],
    },
    "SRBX-63VK": {
        id: "SRBX-63VK",
        cvValues: [
            { nWay: 3, mounted: CV_MOUNTED_PRIM },
            { nWay: '2 eller 3', mounted: CV_MOUNTED_SECONDARY },
        ],
        dimensionInput: [
            [{ key: 'primDP', label: 'Drivtryck', unit: 'kPa' }],
            [{ key: 'flow', label: 'Batterikörningar', toolTip: "Batterikörningar värme och kyla", unit: 'l/s' }],
            [{ key: 'tempPrimIn', label: 'Prim Temp värme', unit: '°C' }, { key: 'tempPrimReturn', label: 'Prim Temp kyla', unit: '°C' }],
        ],
    },
    "PRBX-6VK": {
        id: "PRBX-6VK",
        cvValues: [
            { nWay: 3, mounted: CV_MOUNTED_PRIM },
            { nWay: '2 eller 3', mounted: CV_MOUNTED_SECONDARY },
        ],
        dimensionInput: [
            [{ key: 'primDP', label: 'Drivtryck', unit: 'kPa' }],
            [{ key: 'flow', label: 'Batterikörningar', toolTip: "Batterikörningar värme och kyla", unit: 'l/s' }],
            [{ key: 'tempPrimIn', label: 'Prim Temp värme', unit: '°C' }, { key: 'tempPrimReturn', label: 'Prim Temp kyla', unit: '°C' }],
        ],
    },
    "SRBX-6ECO": {
        id: "SRBX-6ECO",
        cvValues: [
            { nWay: '2 eller 3', mounted: CV_MOUNTED_PRIM },
        ],
        dimensionInput: [
            [{ key: 'primDP', label: 'Drivtryck (Primärt)', unit: 'kPa' }],
            [{ key: 'tempPrimIn', label: 'Prim Temp KB', unit: '°C' }, { key: 'tempPrimReturn', label: 'Prim Temp VP', unit: '°C' }],
            [{ key: 'secPrimIn', label: 'Sek Temp till', unit: '°C' }, { key: 'secPrimReturn', label: 'Sek Temp retur', unit: '°C' }],
            [{ key: 'secExternalDP', label: 'Tryckfall (Sekundärt)', unit: 'kPa' }],
            [{ key: 'secFlow', label: 'Flöde (Sekundärt)', unit: 'l/s' }],
        ],
    },
    "SRBX-6VAV": {
        id: "SRBX-6VAV",
        cvValues: [
            { nWay: 3, mounted: CV_MOUNTED_PRIM },
        ],
        dimensionInput: [
            [{ key: 'primDP', label: 'Drivtryck (Primärt)', unit: 'kPa' }],
            [{ key: 'tempPrimIn', label: 'Prim Temp TL', unit: '°C' }, { key: 'tempPrimReturn', label: 'Prim Temp VP', unit: '°C' }],
            [{ key: 'secPrimIn', label: 'Sek Temp till', unit: '°C' }, { key: 'secPrimReturn', label: 'Sek Temp retur', unit: '°C' }],
            [{ key: 'secExternalDP', label: 'Tryckfall (Sekundärt)', unit: 'kPa' }],
            [{ key: 'secFlow', label: 'Flöde (Sekundärt)', unit: 'l/s' }],
        ],
    },
    "SRBX-6SPETS": {
        id: "SRBX-6SPETS",
        cvValues: [
            { nWay: '2 eller 3', mounted: CV_MOUNTED_PRIM },
            { nWay: '2 eller 3', mounted: CV_MOUNTED_SECONDARY },
        ],
        dimensionInput: [
            [{ key: 'primDP', label: 'Drivtryck (Primärt)', unit: 'kPa' }],
            [{ key: 'tempPrimIn', label: 'Prim Temp ÅV', unit: '°C' }, { key: 'tempPrimReturn', label: 'Prim Temp SPETS', unit: '°C' }],
            [{ key: 'secPrimIn', label: 'Sek Temp till', unit: '°C' }, { key: 'secPrimReturn', label: 'Sek Temp retur', unit: '°C' }],
            [{ key: 'secExternalDP', label: 'Tryckfall (Sekundärt)', unit: 'kPa' }],
            [{ key: 'secFlow', label: 'Flöde (Sekundärt)', unit: 'l/s' }],
        ],
    },
    "SRBX-6NOD": {
        id: "SRBX-6NOD",
        cvValues: [
            { nWay: '2 eller 3', mounted: CV_MOUNTED_SECONDARY },
        ],
        dimensionInput: [
            [{ key: 'primDP', label: 'Drivtryck (Primärt)', unit: 'kPa' }],
            [{ key: 'tempPrimIn', label: 'Prim Temp KB', unit: '°C' }, { key: 'tempPrimReturn', label: 'Prim Temp TAPP', unit: '°C' }],
            [{ key: 'secPrimIn', label: 'Sek Temp till', unit: '°C' }, { key: 'secPrimReturn', label: 'Sek Temp retur', unit: '°C' }],
            [{ key: 'secExternalDP', label: 'Tryckfall (Sekundärt)', unit: 'kPa' }],
            [{ key: 'secFlow', label: 'Flöde (Sekundärt)', unit: 'l/s' }],
        ],
    },
    "SRBX-6VAK": {
        id: "SRBX-6VAK",
        dimensionInput: [
            [{ key: 'flow', label: 'Batterikörningar', toolTip: "Batterikörningar värmeåtervinning och kylfall", unit: 'l/s' }],
            [{ key: 'primDP', label: 'Drivtryck (Primärt)', unit: 'kPa' }],
            [{ key: 'tempPrimIn', label: 'Prim Temp till', unit: '°C' }, { key: 'tempPrimReturn', label: 'Prim Temp retur', unit: '°C' }],
            [{ key: 'secExternalDP', label: 'Tryckfall (Rör)', toolTip: "Mellan batterier och shuntgrupp", unit: 'kPa' }],
            [{ key: 'secFlow', label: 'Flöde (Sekundärt)', unit: 'l/s' }],
            [{ key: 'cvNways', label: 'Styrventil för kylan' }],
        ],
    },
    "SRBX-6VAK-Forbi": {
        id: "SRBX-6VAK-Forbi",
        dimensionInput: [
            [{ key: 'flow', label: 'Batterikörningar', toolTip: "Batterikörningar värmeåtervinning och kylfall", unit: 'l/s' }],
            [{ key: 'primDP', label: 'Drivtryck (Primärt)', unit: 'kPa' }],
            [{ key: 'tempPrimIn', label: 'Prim Temp till', unit: '°C' }, { key: 'tempPrimReturn', label: 'Prim Temp retur', unit: '°C' }],
            [{ key: 'secExternalDP', label: 'Tryckfall (Rör)', toolTip: "Mellan batterier och shuntgrupp", unit: 'kPa' }],
            [{ key: 'secFlow', label: 'Flöde (Sekundärt)', unit: 'l/s' }],
            [{ key: 'cvNways', label: 'Styrventil för kylan' }],
        ],
    },
    "SRBX-8VAKK": {
        id: "SRBX-8VAKK",
        dimensionInput: [
            [{ key: 'flow', label: 'Batterikörningar', toolTip: "Batterikörningar värmeåtervinning och kylfall", unit: 'l/s' }],
            [{ key: 'primDP', label: 'Drivtryck (Primärt)', unit: 'kPa' }],
            [{ key: 'tempPrimIn', label: 'Prim Temp till', unit: '°C' }, { key: 'tempPrimReturn', label: 'Prim Temp retur', unit: '°C' }],
            [{ key: 'secExternalDP', label: 'Tryckfall (Rör)', toolTip: "Mellan batterier och shuntgrupp", unit: 'kPa' }],
            [{ key: 'secFlow', label: 'Flöde (Sekundärt)', unit: 'l/s' }],
            [{ key: 'cvNways', label: 'Styrventil för kylan' }],
        ],
    },
    "SRBX-8VAKK-Forbi": {
        id: "SRBX-8VAKK-Forbi",
        dimensionInput: [
            [{ key: 'flow', label: 'Batterikörningar', toolTip: "Batterikörningar värmeåtervinning och kylfall", unit: 'l/s' }],
            [{ key: 'primDP', label: 'Drivtryck (Primärt)', unit: 'kPa' }],
            [{ key: 'tempPrimIn', label: 'Prim Temp till', unit: '°C' }, { key: 'tempPrimReturn', label: 'Prim Temp retur', unit: '°C' }],
            [{ key: 'secExternalDP', label: 'Tryckfall (Rör)', toolTip: "Mellan batterier och shuntgrupp", unit: 'kPa' }],
            [{ key: 'secFlow', label: 'Flöde (Sekundärt)', unit: 'l/s' }],
            [{ key: 'cvNways', label: 'Styrventil för kylan' }],
        ],
    },
    "PBCS": {
        id: "PBCS",
    },
};