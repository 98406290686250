import { httpsCallable } from "firebase/functions"

const updateCustomShuntFunc = async function updateCustomShunt(functions, sessionID, shuntID, position, designation, princip, prodLine, plModel, dimVals, shuntType, connDesign, shuntData, pumpOptions, flexDim, exchangeVal = null, cv2Nways = null, adjustmentsOptions = null, isSpecial = null, ivMod = null, specList = null, techList = null) {
    functions.region = 'europe-west1';
    const updateCustomShunt = httpsCallable(functions, "updateCustomShunt");

    return updateCustomShunt({
        sessionID: sessionID,
        shuntID: shuntID,
        position: position,
        designation: designation,
        princip: princip,
        prodLine: prodLine,
        plModel: plModel,
        connDesign: connDesign,
        shuntType: shuntType,
        dimValues: dimVals,
        exchangeValue: exchangeVal,
        cv2NWays: cv2Nways,
        adjustOptions: adjustmentsOptions,
        isSpecial: isSpecial,
        shuntData: shuntData,
        pumpOptions: pumpOptions,
        flexDim: flexDim,
        ivMod: ivMod,
        specList: specList,
        techList: techList,
    });
}

export { updateCustomShuntFunc }