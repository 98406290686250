import { get, ref } from "firebase/database";
import { SESSION_SHUNT_PATH } from "../../../constants/firebasePaths";

const getShuntPriceList = async function getShuntPriceList(database, shuntList) {
    const shuntPrices = {};

    for (let index = 0; index < shuntList.length; index++) {
        const shuntID = shuntList[index];
        let shuntRef = ref(database, SESSION_SHUNT_PATH + shuntID)

        let data = await get(shuntRef);

        shuntPrices[shuntID] = data.val();
    }

    return shuntPrices;
}

export { getShuntPriceList }