import { SHOW_ID, CHECKED_TERMS, CHECKED_COVER, NEW_PAGE_PER_POSITION, DISPLAY_OPTION, INCLUDE_MAGICLOUD_LINK, OFFERT_MODE } from "../constants/keys/modifySpecKeys";
import { ContextEnum } from "./contextEnum";

export class ModifySpecEnum extends ContextEnum {
    static ShowID = new ModifySpecEnum(SHOW_ID, "setShowID");
    static CheckedTerms = new ModifySpecEnum(CHECKED_TERMS, "setCheckedTerms");
    static CheckedCover = new ModifySpecEnum(CHECKED_COVER, "setCheckedCover");
    static OffertMode = new ModifySpecEnum(OFFERT_MODE, "setOffertMode");
    static NewPagePerPosition = new ModifySpecEnum(NEW_PAGE_PER_POSITION, "setNewPagePerPosition");
    static DisplayOption = new ModifySpecEnum(DISPLAY_OPTION, "setDisplayOption");
    static IncludeMagicloudLink = new ModifySpecEnum(INCLUDE_MAGICLOUD_LINK, "setIncludeMagicloudLink");
}