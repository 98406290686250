/**
 * 
 * @param {number} value 
 * @returns 
 */
const roundTo4Decimals = function roundTo4Decimals(value) {
    return Math.round((value + Number.EPSILON) * 10000) / 10000;
}

/**
 * 
 * @param {number} value 
 * @returns 
 */
const roundTo1Decimals = function roundTo1Decimals(value) {
    return Math.round((value + Number.EPSILON) * 10) / 10;
}

/**
 * 
 * @param {number} value 
 * @returns 
 */
const roundToDecimals = function roundToDecimals(value, numOfDec) {
    const decNum = Number('1'.padEnd(numOfDec+1, '0'));
    return Math.round((value + Number.EPSILON) * decNum) / decNum;
}

/**
 * 
 * @param {number} value 
 */
const roundNumber = function roundNumber(value) {
    let decimals = 4;
    const absValue = Math.abs(value);

    if (absValue >= 10) {
        decimals = 1;
    } else if (absValue >= 1) {
        decimals = 2;
    } else if (absValue >= 0.1) {
        decimals = 2;
    } else if (absValue >= 0.01) {
        decimals = 3;
    } else if (absValue >= 0.001) {
        decimals = 3;
    } else if (absValue === 0.000) {
        decimals = 0;
    }

    const multi = Number("1".padEnd(decimals + 1, '0'));

    return Math.round((value + Number.EPSILON) * multi) / multi;
}

export { roundTo4Decimals, roundTo1Decimals, roundNumber, roundToDecimals }