import { ErrorMsg } from "../../../constants/errorMessages";
import { PRODUCTPRINCIP_VA1 } from "../../../constants/productlineConstants";
import { ErrorEnum } from "../../../enums/errorEnum";

const checkDriftParamsInput = function checkDriftParamsInput(errorContext, plPrincip, hasMainPump, secTempOut, secTempIn, secondaryFlow, secondaryEffect, primDrivingPressure, primExternalPressureDrop, secExternalPressureDrop, flowCalcMode, usingContext = true, localErrorlist = null) {
    const ErrorList = new ErrorEnum();
    let fieldsSet = true;

    //Safechecking for empty fields
    if (secTempOut == null || secTempOut === "") {
        //console.error(ErrorMsg.params_noSecTempOut.msg);
        if (usingContext === true) {
            ErrorList.addError(errorContext, ErrorMsg.params_notSet.key, ErrorMsg.params_notSet.msg);
        }
        else {
            localErrorlist[ErrorMsg.params_notSet.key] = ErrorMsg.params_notSet.msg;
        }

        fieldsSet = false;
    }

    if (secTempIn == null || secTempIn === "") {
        //console.error(ErrorMsg.params_noTempIn.msg);
        if (usingContext === true) {
            ErrorList.addError(errorContext, ErrorMsg.params_notSet.key, ErrorMsg.params_notSet.msg);
        }
        else {
            localErrorlist[ErrorMsg.params_notSet.key] = ErrorMsg.params_notSet.msg;
        }

        fieldsSet = false;
    }

    if (flowCalcMode !== 'kw') {
        if (secondaryFlow == null || secondaryFlow === "") {
            //console.error(ErrorMsg.params_noSecFlow.msg);
            if (usingContext === true) {
                ErrorList.addError(errorContext, ErrorMsg.params_notSet.key, ErrorMsg.params_notSet.msg);
            }
            else {
                localErrorlist[ErrorMsg.params_notSet.key] = ErrorMsg.params_notSet.msg;
            }

            fieldsSet = false;
        }
        else if (Number(secondaryFlow) <= 0) {
            if (usingContext === true) {
                ErrorList.addError(errorContext, ErrorMsg.flow_nonexistingSecFlow.key, ErrorMsg.flow_nonexistingSecFlow.msg);
            }
            else {
                localErrorlist[ErrorMsg.flow_nonexistingSecFlow.key] = ErrorMsg.flow_nonexistingSecFlow.msg;
            }

            fieldsSet = false;
        }
        else if (Number(secondaryFlow) > 50) {
            if (usingContext === true) {
                ErrorList.addError(errorContext, ErrorMsg.flow_toHighSecFlow.key, ErrorMsg.flow_toHighSecFlow.msg);
            }
            else {
                localErrorlist[ErrorMsg.flow_toHighSecFlow.key] = ErrorMsg.flow_toHighSecFlow.msg;
            }

            fieldsSet = false;
        }
    }
    else {
        if (secondaryEffect == null || secondaryEffect === "") {
            if (usingContext === true) {
                ErrorList.addError(errorContext, ErrorMsg.params_notSet.key, ErrorMsg.params_notSet.msg);
            }
            else {
                localErrorlist[ErrorMsg.params_notSet.key] = ErrorMsg.params_notSet.msg;
            }

            fieldsSet = false;
        }
    }

    if (plPrincip !== PRODUCTPRINCIP_VA1 && hasMainPump === true) {
        if (primDrivingPressure == null || primDrivingPressure === "") {
            //console.error(ErrorMsg.params_noPrimDrivPressure.msg);
            if (usingContext === true) {
                ErrorList.addError(errorContext, ErrorMsg.params_notSet.key, ErrorMsg.params_notSet.msg);
            }
            else {
                localErrorlist[ErrorMsg.params_notSet.key] = ErrorMsg.params_notSet.msg;
            }

            fieldsSet = false;
        }
    }
    else {
        if (primExternalPressureDrop == null || primExternalPressureDrop === "") {
            //console.error(ErrorMsg.params_noprimExternalPressureDrop.msg);
            if (usingContext === true) {
                ErrorList.addError(errorContext, ErrorMsg.params_notSet.key, ErrorMsg.params_notSet.msg);
            }
            else {
                localErrorlist[ErrorMsg.params_notSet.key] = ErrorMsg.params_notSet.msg;
            }

            fieldsSet = false;
        }
    }


    if (secExternalPressureDrop == null || isNaN(secExternalPressureDrop)) {
        //console.error(ErrorMsg.params_noSecPressureLoss.msg);
        if (usingContext === true) {
            ErrorList.addError(errorContext, ErrorMsg.params_notSet.key, ErrorMsg.params_notSet.msg);
        }
        else {
            localErrorlist[ErrorMsg.params_notSet.key] = ErrorMsg.params_notSet.msg;
        }

        fieldsSet = false;
    }

    return fieldsSet;
}

export { checkDriftParamsInput }