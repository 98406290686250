import { Database, get, ref } from "firebase/database";
import { SESSION_SHUNT_PATH, SHUNT_PLDATA, SHUNT_SHUNTDATA } from "../../constants/firebasePaths";
import { PL_MODEL_KEY } from "../../constants/keys/shuntKeys";

/**
 * 
 * @param {Database} database 
 * @param {string} shuntID 
 */
const getShuntProdType = async function getShuntProdType(database, shuntID) {
    const infoRef = ref(database, SESSION_SHUNT_PATH + shuntID + SHUNT_PLDATA);
    const plModelRef = ref(database, SESSION_SHUNT_PATH + shuntID + SHUNT_SHUNTDATA + '/' + PL_MODEL_KEY)

    const result = await get(infoRef);
    const plModel = await get(plModelRef);

    return { ...result.val(), model: plModel.val() };
}

export { getShuntProdType }