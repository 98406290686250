import { Button, DialogActions, DialogContent, Typography } from "@mui/material"
import { BootstrapDialog, BootstrapDialogTitle } from "../../../utils/bootstrapDialog"


export const ConfirmChangedPrinciple = ({ openModal, handleModalClose, handleContinue }) => {


    return (
        <BootstrapDialog
            onClose={handleModalClose}
            open={openModal}
        >
            <BootstrapDialogTitle onClose={handleModalClose}>
                Byta kopplingsprincip
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center' }}>
                    Om du byter kopplingsprincip eller produktlinje behöver konfigureringen göras om.
                </Typography>
                <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center', paddingTop: "10px" }}>
                    <b>Vill du fortsätta?</b>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleModalClose}>
                    Ångra
                </Button>
                <Button variant='outlined' onClick={handleContinue}>
                    Fortsätt
                </Button>
            </DialogActions>
        </BootstrapDialog>
    )
}