import { Box, styled, TableRow, Typography } from "@mui/material";
import { ref } from "firebase/database";
import { useEffect, useState } from "react";
import { useDatabase, useDatabaseObjectData } from "reactfire";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import ReportIcon from '@mui/icons-material/Report';
import { SESSION_SHUNT_PATH } from "../../../constants/firebasePaths";
import { FirebaseShuntDisplay } from "../inspectPage/components/firebaseShuntDisplay";

export const SpecOverviewShuntDev = ({ shuntID }) => {
    const database = useDatabase();

    const shuntRef = ref(database, SESSION_SHUNT_PATH + shuntID);

    const response = useDatabaseObjectData(shuntRef);
    const { status, data } = response;

    const [fieldValue, setFieldValue] = useState({})

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    useEffect(() => {
        if (data != null) {
            setFieldValue(data);
        }
    }, [data]);

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const getMaxStep = () => {
        if (data?.MsgLists?.errors != null) {
            return <ReportIcon fontSize="small" color='error' />
        }
        else if (data?.Baseinfo?.maxStep != null) {
            if (Number(data.Baseinfo.maxStep) === 4) {
                return <CheckCircleIcon fontSize="small" color="success" />
            }
            else if (Number(data.Baseinfo.maxStep) === 3) {
                return <Looks4Icon fontSize="small" color="info" />
            }
            else if (Number(data.Baseinfo.maxStep) === 2) {
                return <Looks3Icon fontSize="small" color="info" />
            }
            else if (Number(data.Baseinfo.maxStep) === 1) {
                return <LooksTwoIcon fontSize="small" color="info" />
            }
            else if (Number(data.Baseinfo.maxStep) === 0) {
                return <LooksOneIcon fontSize="small" color="info" />
            }
            return "";
        }
        else {
            return "";
        }
    }

    const getPos = () => {
        if (data?.Baseinfo?.position != null) {
            return "Pos. " + String(data.Baseinfo.position);
        }
        else {
            return "";
        }
    }

    const getBeteckning = () => {
        if (data?.Baseinfo?.designation != null) {
            return String(data.Baseinfo.designation);
        }
        else {
            return "";
        }
    }

    const getModel = () => {
        if (data?.ShuntData?.plModel != null) {
            return String(data.ShuntData.plModel);
        }
        else if (data?.PLData?.princip != null) {
            return String(data.PLData.princip);
        }
        else {
            return "";
        }
    }

    const getProdLine = () => {
        if (data?.PLData?.prodLine != null) {
            return capitalizeFirstLetter(String(data.PLData.prodLine));
        }
        else {
            return "";
        }
    }

    const getShuntType = () => {
        if (data?.CalcParams?.shuntType != null) {
            return capitalizeFirstLetter(String(data.CalcParams.shuntType));
        }
        else {
            return "";
        }
    }

    const getMinMaxTemp = () => {
        if (data?.DriftParams != null) {
            let primTempIn = null;
            let primTempOut = null;
            let secTempIn = null;
            let secTempOut = null;

            let anyValues = false;
            if (data.DriftParams.primTempIn != null) {
                anyValues = true;
                primTempIn = Number(data.DriftParams.primTempIn);
            }
            if (data.DriftParams.primTempOut != null) {
                anyValues = true;
                primTempOut = Number(data.DriftParams.primTempOut);
            }
            if (data.DriftParams.secTempIn != null) {
                anyValues = true;
                secTempIn = Number(data.DriftParams.secTempIn);
            }
            if (data.DriftParams.secTempOut != null) {
                anyValues = true;
                secTempOut = Number(data.DriftParams.secTempOut);
            }

            if (anyValues === true) {
                let min = Math.min(primTempIn || Number.MAX_SAFE_INTEGER, primTempOut || Number.MAX_SAFE_INTEGER, secTempIn || Number.MAX_SAFE_INTEGER, secTempOut || Number.MAX_SAFE_INTEGER);
                let max = Math.max(primTempIn || Number.MIN_SAFE_INTEGER, primTempOut || Number.MIN_SAFE_INTEGER, secTempIn || Number.MIN_SAFE_INTEGER, secTempOut || Number.MIN_SAFE_INTEGER);

                return min + "/" + max + " °C";
            }
            else {
                return "";
            }
        }
        else {
            return "";
        }

    }

    return (
        <Box style={{ marginTop: "10px" }}>
            <div>
                <Typography component={'h2'} >{getPos()} - {getBeteckning()}</Typography>
                <FirebaseShuntDisplay shuntID={shuntID} shortDesc={false} />
            </div>
        </Box>
    );
}