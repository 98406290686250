import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material"
import Logo from '../assets/images/logos/logo.svg';
import '../index.css';
import { SL_VERSION } from "../constants/generalText/generalInfo";

const CacheLoading = () => {
    const curDate = new Date();

    return (
        <>
            <Box sx={{ flexGrow: 1, marginTop: '-7px' }}>
                <img src={Logo}
                    alt="Logo"
                    aria-label="logo"
                    className="logo"
                />

                <div style={{ marginTop: "2px", paddingLeft: "5px" }}>
                    <Typography variant="h2" fontWeight="bold" sx={{ color: "#183851" }}>
                        ShuntLogik<sup>®</sup>
                    </Typography>
                    <Typography variant="h3" fontWeight="bold" sx={{ color: "#183851" }}>
                        Ver {SL_VERSION}
                    </Typography>
                </div>
            </Box>
            <br />
            <div style={{
                margin: "0px 0px 0px 0px",
                width: "96.3%",
                borderRadius: "4px",
                padding: "20px",
                justifyContent: 'center',
                backgroundColor: '#E1E2E4',
            }}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Box>
                        <Skeleton width={"400px"} height={"220px"} animation='wave' />
                        <Skeleton width={"400px"} height={"140px"} animation='wave' />
                    </Box>
                    <Box>
                        <Skeleton width={"600px"} height={"100px"} animation='wave' />
                        <Skeleton width={"600px"} height={"280px"} animation='wave' />
                    </Box>
                </Stack>

            </div>

            <>
                <div>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <ul style={{ listStyleType: "none" }}>
                                    <li><h3 className="footer-h3">PREMA<sup>®</sup> Huvudkontor</h3></li>
                                    <li>Franska vägen 17, 393 56 Kalmar</li>
                                    <li>Tel: +46 480 560 90</li>
                                    <li><a href="mailto: kundservice@prema.se" target="hidden-iframe" style={{ textDecoration: "none", color: '#06559e' }}><strong>Skicka e-post</strong></a></li>

                                </ul>
                            </Grid>
                            <Grid item xs={3}>
                                <ul style={{ listStyleType: "none" }}>
                                    <li><h3 className="footer-h3">PREMA<sup>®</sup> Stockholm</h3></li>
                                    <li>Industrigatan 4 B, 112 46 Stockholm</li>
                                    <li>Tel: +46 8 704 28 82</li>
                                    <li><a href="mailto: winge@prema.se" target="hidden-iframe" style={{ textDecoration: "none", color: '#06559e' }}><strong>Skicka e-post</strong></a></li>

                                </ul>
                            </Grid>
                            <Grid item xs={3}>
                                <ul style={{ listStyleType: "none", color: "#183851" }}>
                                    <li><h3 className="footer-h3">PREMA<sup>®</sup> Göteborg</h3></li>
                                    <li>Lilla Bommen 2, 411 04 Göteborg</li>
                                    <li>Tel: +46 31 724 43 85</li>
                                    <li><a href="mailto: goteborg@prema.se" target="hidden-iframe" style={{ textDecoration: "none", color: '#06559e' }}><strong>Skicka e-post</strong></a></li>

                                </ul>
                            </Grid>
                            <Grid item xs={3}>
                                <ul style={{ listStyleType: "none" }}>
                                    <li><h3 className="footer-h3">PREMA<sup>®</sup> Malmö</h3></li>
                                    <li>Stadiongatan 65, 217 62 Malmö</li>
                                    <li>Tel: +46 40 666 76 05</li>
                                    <li><a href="mailto: magnus.persson@prema.se" target="hidden-iframe" style={{ textDecoration: "none", color: '#06559e', }}><strong>Skicka e-post</strong></a></li>

                                </ul>
                            </Grid>
                        </Grid>
                    </Box>
                    <iframe title="mail-link" name="hidden-iframe" style={{ visibility: 'hidden', position: 'absolute' }}></iframe>
                    <div style={{ margin: 'auto', justifyContent: 'center', padding: '5px 5px 0px 5px', }}>
                        <Typography variant="body2" align="center" fontWeight="bold" color="#b2b1a7" style={{ paddingBottom: "8px", marginTop: "-8px" }}>
                            © Copyright {curDate.getFullYear()} PREMA<sup>®</sup> AB
                        </Typography>
                    </div>
                </div>
            </>
        </>

    )
}

export default CacheLoading;