import { useState } from "react";
import { BootstrapDialog, BootstrapDialogTitle } from "../../utils/bootstrapDialog";
import { Box, Button, CircularProgress, DialogActions, DialogContent, Stack, TextField, Typography } from "@mui/material";

import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import { requestSLUser } from "../../../firebase/functions/cloudFunctions/requestSLUser";
import { useFunctions } from "reactfire";

export const RequestUserModal = ({ openModal, handleCloseModal }) => {
    const functions = useFunctions();
    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const handleCloseModalLocal = () => {
        handleCloseModal();
        setIsSent(false);
    }

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [companyName, setCompanyName] = useState('');


    const sendRequest = (event) => {
        event.preventDefault();
        setIsLoading(true);
        requestSLUser(functions, email, name, companyName).then((res) => {
            setIsSent(true);

            setEmail('');
            setName('');
            setCompanyName('');
        }).catch((error) => {
            console.error("Error: ", error);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    return (
        <>
            <BootstrapDialog
                onClose={handleCloseModalLocal}
                aria-labelledby="customized-dialog-title"
                open={openModal}
            >
                <div style={{ minWidth: '400px' }}>
                    <BootstrapDialogTitle component="h3" sx={{ paddingLeft: '20px', paddingTop: '0px', paddingBottom: '0px' }} onClose={handleCloseModalLocal}>
                        <h3 className="header-title">Ansök om ShuntLogik<sup>®</sup>-konto</h3>
                    </BootstrapDialogTitle>
                    {isLoading === false ? (
                        <>
                            {isSent === false ? (
                                <>
                                    <form onSubmit={sendRequest}>
                                        <DialogContent style={{marginLeft: '8px'}}>
                                            <Stack spacing={2}>
                                                <TextField
                                                    required
                                                    sx={{ width: '350px' }}
                                                    label="Email"
                                                    value={email}
                                                    type="email"
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />

                                                <TextField
                                                    required
                                                    sx={{ width: '350px' }}
                                                    label="Namn"
                                                    value={name}
                                                    type="text"
                                                    onChange={(e) => setName(e.target.value)}
                                                />


                                                <TextField
                                                    required
                                                    sx={{ width: '350px' }}
                                                    label="Företag"
                                                    value={companyName}
                                                    type="text"
                                                    onChange={(e) => setCompanyName(e.target.value)}
                                                />
                                            </Stack>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button type="submit" disabled={email == null || email === "" || name == null || name === "" || companyName == null || companyName === ""}>
                                                Skicka
                                            </Button>
                                        </DialogActions>
                                    </form>
                                </>
                            ) : (
                                <>
                                    <DialogContent>
                                        <Typography component={'h2'} style={{ display: 'flex', justifyContent: 'center', width: '400px', marginTop: 20, marginBottom: 20 }}>
                                            Ansökan skickad! <br />
                                        </Typography>

                                        <Typography style={{ display: 'flex', fontSize: '0.95rem', justifyContent: 'center', textAlign: 'center', width: '400px', marginTop: 20, marginBottom: 20 }}>
                                            Du kommer få en inbjudan att färdigställa ditt konto via epost när ansökan behandlats
                                        </Typography>
                                    </DialogContent>
                                </>
                            )}

                        </>

                    ) : (
                        <>
                            <DialogContent>
                                <Typography component={'h2'} gutterBottom style={{ display: 'flex', justifyContent: 'center', width: '400px', marginTop: 30, minHeight: 120, }}>
                                    <Stack>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress size={25} /></div>
                                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>Skickar...</div>
                                    </Stack>
                                </Typography>

                            </DialogContent>
                        </>
                    )}
                </div>
            </BootstrapDialog >
        </>
    )
}