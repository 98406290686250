import { Box, Button, Checkbox, DialogActions, DialogContent, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField, Tooltip } from "@mui/material"
import { BootstrapDialog, BootstrapDialogTitle } from "../../../utils/bootstrapDialog"
import { MIXINGVESSEL_1000L, MIXINGVESSEL_100L, MIXINGVESSEL_200L, MIXINGVESSEL_300L, MIXINGVESSEL_500L, MIXINGVESSEL_60L } from "../../../../constants/adjustmentsOptions";
import { useEffect, useState } from "react";
import greenLine1 from '../../../../assets/images/logos/Green_I_Logo.svg'
import { PRODUCTLINE_GREEN, PRODUCTLINE_STANDARD } from "../../../../constants/productlineConstants";
import { updateMixingVesselData } from "../../../../firebase/functions/updateMixingVesselData";
import { useSessionParams } from "../../../../contexts";
import { useDatabase } from "reactfire";
import { SessionParamsEnum } from "../../../../enums";
import { checkIfPosExistsCustom } from "../../../../functions/ShuntCalculations/helpFunctions/checkIfPosAndDesignationExists";


export const EditAddonModal = ({ openModal, handleModalClose, shuntData }) => {
    const sessionParams = useSessionParams();
    const database = useDatabase();

    const [isSaving, setIsSaving] = useState(false);
    const [position, setPosition] = useState(0);
    const [existsPos, setExistsPos] = useState(false);
    const [parentID, setParentID] = useState('');
    const [volume, setVolume] = useState("");
    const [greenVersion, setGreenVersion] = useState(false);

    useEffect(() => {
        if (shuntData != null) {
            checkIfPosExistsCustom(position, database, sessionParams, false, shuntData?.NO_ID_FIELD).then((res) => {
                setExistsPos(res);
            });
        }
    }, [position]);

    useEffect(() => {
        if (shuntData != null) {
            if (shuntData?.PLData?.prodLine === PRODUCTLINE_GREEN) {
                setGreenVersion(true);
            }
            else {
                setGreenVersion(false);
            }

            if (shuntData?.ShuntData?.AddonData?.size != null) {
                setVolume(shuntData?.ShuntData?.AddonData?.size);
            }

            if (shuntData?.ShuntData?.shuntID != null && shuntData?.ShuntData?.shuntID !== "") {
                setParentID(shuntData?.ShuntData?.shuntID);
            }
            else {
                setParentID('')
            }

            if (shuntData?.Baseinfo?.position != null) {
                setPosition(Number(shuntData?.Baseinfo?.position));
            }
        }
    }, [shuntData]);


    const mixingVesselsOptions = [
        MIXINGVESSEL_60L,
        MIXINGVESSEL_100L,
        MIXINGVESSEL_200L,
        MIXINGVESSEL_300L,
        MIXINGVESSEL_500L,
        MIXINGVESSEL_1000L
    ];

    const handleChangeVolume = (event) => {
        setVolume(event.target.value);
    }

    const handleChangePostition = (event) => {
        if (isNaN(Number(event.target.value)) === false) {
            if (Number(event.target.value) < 0) {
                setPosition(0);
            }
            else {
                setPosition(Number(event.target.value));
            }
        }
    }

    const saveUpdatedData = async () => {
        setIsSaving(true);
        let newProdline = PRODUCTLINE_STANDARD;

        if (greenVersion === true) {
            newProdline = PRODUCTLINE_GREEN;
        }

        try {
            if (parentID != null && parentID !== "") {
                await updateMixingVesselData(database, SessionParamsEnum.SessionID.getContextValue(sessionParams), shuntData?.NO_ID_FIELD, newProdline, volume, null, parentID)
            }
            else {
                await updateMixingVesselData(database, SessionParamsEnum.SessionID.getContextValue(sessionParams), shuntData?.NO_ID_FIELD, newProdline, volume, position);
            }

            handleModalClose();

        } catch (error) {
            console.error("FB Error: ", error);
        } finally {
            setIsSaving(false);
        }


    }

    return (
        <>
            <BootstrapDialog
                onClose={handleModalClose}
                aria-labelledby="edit-addon-modal"
                open={openModal}
            >
                <BootstrapDialogTitle onClose={handleModalClose}>
                    Redigera tillbehör
                </BootstrapDialogTitle>

                <DialogContent sx={{ width: '400px' }} dividers>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1}>
                        <Tooltip arrow title={parentID != null && parentID !== "" ? "Blandningskärlet är kopplat till en shuntgrupp" : ""}>
                            <TextField
                                sx={{ width: '80px' }}
                                disabled={parentID != null && parentID !== ""}
                                inputMode="numeric"
                                type="number"
                                label="Pos."
                                value={position}
                                onChange={handleChangePostition}
                            />
                        </Tooltip>

                        <FormControl>
                            <InputLabel>
                                Volym
                            </InputLabel>

                            <Select
                                sx={{ width: '120px' }}
                                label={'Volym'}
                                value={volume}
                                onChange={handleChangeVolume}
                            >
                                <MenuItem sx={{ height: '22px' }} value={''}></MenuItem>
                                {mixingVesselsOptions.map((volumeItem) => {
                                    return (
                                        <MenuItem key={volumeItem} value={volumeItem}>
                                            {volumeItem}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>

                        <Box display={'flex'} justifyContent={'center'} sx={{ width: '150px' }}>
                            <FormControlLabel control={<Checkbox checked={greenVersion} onChange={() => setGreenVersion(!greenVersion)} />} label={<span><img src={greenLine1} alt='Green I Line' style={{ height: '14px', paddingTop: '6px', userSelect: 'none' }} /></span>} />
                        </Box>
                    </Stack>
                    {existsPos === true ? (
                        <Box sx={{ marginTop: '2px', color: '#e12427' }}>
                            Denna position existerar redan!
                        </Box>
                    ) : (
                        <Box sx={{ marginTop: '2px', height: '15px' }}>

                        </Box>
                    )}
                </DialogContent>

                <DialogActions>
                    <Button onClick={saveUpdatedData} variant="outlined" disabled={isSaving || existsPos}>
                        Spara
                    </Button>
                    <Button disabled={isSaving} variant="outlined" onClick={handleModalClose}>Avbryt</Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    )
}