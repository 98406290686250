import { Paper, Stack } from "@mui/material"
import { useEffect } from "react";
import { useSessionParams } from "../../../contexts/SessionParams"
import { SessionParamsEnum } from "../../../enums/sessionParamsEnum";
import { WordExport } from "../wordExport/wordExport";
import { SpecOverviewShuntDev } from "./specOverviewShuntDev";

export const SpecOverviewDev = ({ shuntList, setHeaderText }) => {
    const sessionParams = useSessionParams();

    useEffect(() => {
        setHeaderText("Specifikation Dev")
    }, []);

    return (
        <Paper style={{ display: 'flex', flexDirection: 'column', width: "870px" }}>
            <div className="overview-content-size">
                <Stack spacing={1} style={{ width: "800px", paddingTop: "10px", paddingLeft: "20px" }}>
                    <div>
                        <b>Anläggning:</b> {SessionParamsEnum.Facility.getContextValue(sessionParams)}
                    </div>
                    <div>
                        <b>Projektnummer:</b> {SessionParamsEnum.ProjNr.getContextValue(sessionParams)}
                    </div>

                    {/* <Divider orientation="horizontal" flexItem /> */}

                    <div>
                        {shuntList.map((shuntID) => {
                            return (
                                <>
                                    <SpecOverviewShuntDev shuntID={shuntID} />
                                    {/* <Divider orientation="horizontal" flexItem /> */}
                                </>
                            )
                        })}
                    </div>

                    <div style={{ marginTop: '50px' }}>
                        <WordExport />
                    </div>

                </Stack >
            </div >
        </Paper >
    )
}