import { Box, Stack, Divider } from "@mui/material"
import { PrioritiesQuestion } from "./questions/priorityQuestion"


export const WizardTab2Questions = ({
    answerQuestion,
    priorities,
    resetVal,
}) => {


    return (
        <Box sx={{ padding: '5px' }}>
            <Stack spacing={1}>
                <PrioritiesQuestion priorities={priorities} answerQuestion={answerQuestion} />
                <Divider />
            </Stack>

        </Box >
    )
}