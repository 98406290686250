import { Alert, Button, Snackbar, styled, TableCell, TableRow } from "@mui/material";
import { ref } from "firebase/database";
import { useState } from "react";
import { useDatabase, useDatabaseObjectData, useFunctions } from "reactfire";
import { SESSION_SHUNT_PATH } from "../../../../constants/firebasePaths";
import { useSessionParams } from "../../../../contexts/SessionParams";
import { SessionParamsEnum } from "../../../../enums/sessionParamsEnum";
import { uploadImage } from "../../../../firebase/functions/cloudFunctions/uploadImage";
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteImage } from "../../../../firebase/functions/cloudFunctions/deleteImage";
import { MAX_FILE_SIZE } from "../../../../constants/generalConstants";

const Input = styled('input')({
    display: 'none',
});

export const FirebaseIllustrationRow = ({ shuntID }) => {
    const database = useDatabase();
    const functions = useFunctions();
    const shuntRef = ref(database, SESSION_SHUNT_PATH + shuntID);
    const sessionParams = useSessionParams();
    const [modified, setModified] = useState(false);
    const [loading, setLoading] = useState(false)

    const [openAlert, setOpenAlert] = useState(false);
    const handleOpenAlert = () => { setOpenAlert(true) }
    const handleCloseAlert = () => { setOpenAlert(false) }

    const response = useDatabaseObjectData(shuntRef);
    const { data } = response;

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const getPos = () => {
        if (data != null && data.Baseinfo != null && data.Baseinfo.position != null) {
            return "Pos. " + String(data.Baseinfo.position);
        }
        else {
            return "";
        }
    }

    const getBeteckning = () => {
        if (data != null && data.Baseinfo != null && data.Baseinfo.designation != null) {
            return String(data.Baseinfo.designation);
        }
        else {
            return "";
        }
    }

    const getIllustration = () => {
        if (data?.CustomData?.imageName != null) {
            if (modified !== true) {
                setModified(true)
            }
            return String(data.CustomData.imageName);
        }
        else if (data?.ShuntData?.shuntString != null) {
            if (modified !== false) {
                setModified(false)
            }
            return String(data.ShuntData.shuntString);
        }
        else {
            if (modified !== false) {
                setModified(false)
            }
            return "";
        }
    }

    const getLastModified = () => {
        if (data?.CustomData?.lastModified != null) {
            const date = new Date(data.CustomData.lastModified)
            return date.toLocaleString('se-SV');
        }
        else {
            return "-";
        }
    }

    const uploadPicture = (event) => {
        const file = event.target.files[0];
        //console.log("File: ", file)

        if (file != null) {
            if (file.size > MAX_FILE_SIZE) {
                console.log("File to large!");
                handleOpenAlert();
            }
            else {
                setLoading(true);
                uploadImage(functions, SessionParamsEnum.SessionID.getContextValue(sessionParams), shuntID, file, file.name, file.type).then(() => {
                    //console.log("File uploaded!");
                }).finally(() => {
                    setLoading(false);
                });
            }
        }
    }

    const deletePicture = () => {
        setLoading(true);
        deleteImage(functions, SessionParamsEnum.SessionID.getContextValue(sessionParams), shuntID).then(() => {
            //console.log("File deleted!");
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <>
            <StyledTableRow key={shuntID}>
                <TableCell>{getPos()} ({getBeteckning()})</TableCell>
                <TableCell>{getIllustration()}</TableCell>
                <TableCell>{getLastModified()}</TableCell>
                <TableCell>
                    <label htmlFor={"contained-button-" + shuntID}>
                        <Input accept="image/*" id={"contained-button-" + shuntID} type="file" onChange={uploadPicture} />
                        <Button sx={{ padding: '2px 2px' }} component="span" disabled={loading}>
                            Bläddra
                        </Button>
                    </label>
                </TableCell>
                {modified ? (
                    <TableCell>
                        <Button onClick={deletePicture} disabled={loading} className="delete-btn"><DeleteIcon fontSize="small" /></Button>

                        {/* <IconButton onClick={deletePicture} disabled={loading}>
                            <DeleteIcon />
                        </IconButton> */}
                    </TableCell>) : (<TableCell></TableCell>)
                }

            </StyledTableRow>

            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
                    Kan ej ladda upp fil. <br/>Filen är för stor.
                </Alert>
            </Snackbar>
        </>
    );
}