import { Box } from '@mui/material';
import { logEvent } from 'firebase/analytics';
import { useEffect } from 'react';
import { useAnalytics } from 'reactfire';
import IntroductionAccordion from '../components/modules/introductionModal/accordionIntroductionModal';

const Intro = () => {
    const analytics = useAnalytics();

    useEffect(() => {
        logEvent(analytics, 'page_view', { page_location: window.location.href, page_title: "Introduction" });
    }, [window.location.href]);

    return (
        <>
            <br />
            <div style={{
                margin: "0px 0px 0px 0px",
                width: "96.3%",
                borderRadius: "4px",
                padding: "20px",
                justifyContent: 'center',
                backgroundColor: '#E1E2E4',
            }}>
                <Box>
                    <h3 className="header-title" style={{ color: '#183851' }}>ShuntLogik - Introduktionsanvisning</h3>
                    <IntroductionAccordion />
                </Box>
            </div>
        </>
    )
}

export default Intro