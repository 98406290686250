import { Stack, TextField } from "@mui/material"
import { useEffect, useState } from "react";
import { useDatabase } from "reactfire";
import { useDriftParams, useAdjustParams, useErrorContext, useCalcParams, useSessionParams } from "../../../../contexts";
import { SessionParamsEnum, CalcParamsEnum, AdjustmentsEnum, DriftParamEnum } from "../../../../enums";
import { clearCalcData } from "../../../../firebase/functions";
import { resetMaxStepFunc } from "../../../../functions/HelperFunctions/resetMaxStep";


export const DriftParamsTextField = ({ driftEnum, hoverText, hoverTextRow2 = '', handleHover, handleExit, isDisabled = false, style = null, end = null }) => {
    const database = useDatabase();

    const driftParams = useDriftParams();
    const adjustParams = useAdjustParams();
    const errorContext = useErrorContext();
    const calcParams = useCalcParams();
    const sessionParams = useSessionParams();

    if (style == null) {
        style = { width: "60px", height: "10px" }
    }

    const getInitValue = () => {

        if (driftEnum.getContextValue(driftParams) != null && driftEnum.getContextValue(driftParams) !== "") {
            return driftEnum.getContextValue(driftParams);
        }
        else {
            return '';
        }
    }

    const [value, setValue] = useState(getInitValue());

    useEffect(() => {
        if (driftEnum.getContextValue(driftParams) != null || driftEnum.getContextValue(driftParams) !== "") {
            setValue(driftEnum.getContextValue(driftParams));
        }
    }, [driftEnum.getContextValue(driftParams)])

    /*
    const resetMaxStep = () => {
        if (SessionParamsEnum.MaxStep.getContextValue(sessionParams) !== SessionParamsEnum.CurrentStep.getContextValue(sessionParams) && SessionParamsEnum.CurrentStep.getContextValue(sessionParams) != null) {
            SessionParamsEnum.MaxStep.setContextValue(sessionParams, SessionParamsEnum.CurrentStep.getContextValue(sessionParams), database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
        }
    }*/

    const setDriftParams = (value) => {
        resetMaxStepFunc(sessionParams, database);

        CalcParamsEnum.clearData(calcParams);
        AdjustmentsEnum.clearData(adjustParams);
        clearCalcData(database, sessionParams);
        if (driftEnum === DriftParamEnum.SecFlowEffect && Number(value) < 0){
            driftEnum.setContextValue(driftParams, "0");
            setValue("0");
        }
        else{
            driftEnum.setContextValue(driftParams, value);
            setValue(value);
        }

    }


    return (
        <>
            {end != null ? (
                <Stack direction={'row'} style={{ marginTop: "0px" }}>
                    <TextField
                        style={style}
                        size={"small"}
                        type={"number"}
                        onKeyDown={(evt) => (/^[a-öA-Ö+?']?$/).test(evt.key) && evt.preventDefault()}
                        value={value || ''}
                        margin="normal"
                        disabled={isDisabled}
                        fullWidth
                        inputProps={{
                            step: 'any'
                        }}
                        className="drift-input"
                        error={(driftEnum.getContextValue(driftParams) === undefined || driftEnum.getContextValue(driftParams) === "") && errorContext.state.val.params_notSet != null}
                        onChange={(event) => { setDriftParams(event.target.value) }}
                        onMouseOver={(event) => handleHover(event, hoverText, hoverTextRow2)}
                        onMouseLeave={handleExit}
                    />
                    <div style={{ marginLeft: '10px', marginTop: "24px" }}>
                        {end}
                    </div>
                </Stack>
            ) : (
                <TextField
                    style={style}
                    size={"small"}
                    type={"number"}
                    onKeyDown={(evt) => (/^[a-öA-Ö+?']?$/).test(evt.key) && evt.preventDefault()}
                    value={value || ''}
                    margin="normal"
                    disabled={isDisabled}
                    fullWidth
                    inputProps={{
                        step: 'any'
                    }}
                    className="drift-input"
                    error={(driftEnum.getContextValue(driftParams) === undefined || driftEnum.getContextValue(driftParams) === "") && errorContext.state.val.params_notSet != null}
                    onChange={(event) => { setDriftParams(event.target.value) }}
                    onMouseOver={(event) => handleHover(event, hoverText, hoverTextRow2)}
                    onMouseLeave={handleExit}
                />
            )}
        </>

    );
}