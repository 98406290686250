import { ref, set } from "firebase/database";
import { SESSION_SHUNT_PATH, SHUNT_PUMPDATA } from "../constants/firebasePaths";
import { PUMP_ALARM, PUMP_CARD, PUMP_EXTERNAL_STOP, PUMP_GENERAL_REQUEST, PUMP_PRESSURE, PUMP_REQUEST_TYPE, PUMP_SPECIFIC_REQUEST, PUMP_STEERING, PUMP_SYSTEMFLUID } from "../constants/keys/pumpKeys";
import { ContextEnum } from "./contextEnum";

export class PumpParamEnum extends ContextEnum {
    static PumpRequestType = new PumpParamEnum(PUMP_REQUEST_TYPE, "setPumpRequestType");
    static GeneralPumpRequest = new PumpParamEnum(PUMP_GENERAL_REQUEST, "setGeneralPumpRequest");
    static SystemFluid = new PumpParamEnum(PUMP_SYSTEMFLUID, "setSystemfluid");
    static SpecificPumpRequest = new PumpParamEnum(PUMP_SPECIFIC_REQUEST, "setSpecificPumpRequest");
    static Steering = new PumpParamEnum(PUMP_STEERING, "setSteering");
    static Alarm = new PumpParamEnum(PUMP_ALARM, "setAlarm");
    static ExternalStop = new PumpParamEnum(PUMP_EXTERNAL_STOP, "setExternalStop");
    static Pressure = new PumpParamEnum(PUMP_PRESSURE, "setPressure");
    static Card = new PumpParamEnum(PUMP_CARD, "setCard");

    setToFirebase(database, shuntID, value){
        const dbRef = ref(database, SESSION_SHUNT_PATH + shuntID + SHUNT_PUMPDATA + this.name);
        return set(dbRef, value);
    }
}