import { ref, serverTimestamp, set } from "firebase/database";
import { SESSION_PATH } from "../../constants/firebasePaths";
import { SessionParamsEnum } from "../../enums/sessionParamsEnum";
import { SL_VERSION } from "../../constants/generalText/generalInfo";

const saveSessionData = function saveSessionData(sessionID, database, sessionParams, auth) {
    const lastOpenedRef = ref(database, SESSION_PATH + sessionID + "/lastOpened");
    const facilityRef = ref(database, SESSION_PATH + sessionID + "/anläggning");
    const ProjNrRef = ref(database, SESSION_PATH + sessionID + "/projNr");
    const ownerRef = ref(database, SESSION_PATH + sessionID + "/owner");
    const slVersionRef = ref(database, SESSION_PATH + sessionID + "/versionSL");

    const promises = [];
    if (SessionParamsEnum.Facility.getContextValue(sessionParams) != null && SessionParamsEnum.Facility.getContextValue(sessionParams) !== "") {
        //console.log("Saving facility: ", SessionParamsEnum.Facility.getContextValue(sessionParams));
        promises.push(set(facilityRef, SessionParamsEnum.Facility.getContextValue(sessionParams)));
    }

    if (SessionParamsEnum.ProjNr.getContextValue(sessionParams) != null && SessionParamsEnum.ProjNr.getContextValue(sessionParams) !== "") {
        //console.log("Saving ProjNr: ", SessionParamsEnum.ProjNr.getContextValue(sessionParams));

        promises.push(set(ProjNrRef, SessionParamsEnum.ProjNr.getContextValue(sessionParams)));
    }

    if (SL_VERSION != null) {
        promises.push(set(slVersionRef, SL_VERSION));
    }

    if (auth.currentUser != null) {
        if (auth.currentUser.isAnonymous === true) {
            promises.push(set(ownerRef, "Anonymous"));
        }
        else {
            promises.push(set(ownerRef, auth.currentUser.uid));
        }
    }

    promises.push(set(lastOpenedRef, serverTimestamp()))

    return Promise.all(promises);
}

export { saveSessionData } 