import { doc, getDoc } from "firebase/firestore";

const getPriceDripPlate = async function getPriceDripPlate(firestore, dim) {
    let dripPlateDoc = doc(firestore, "/PriceValues/dripPlate");

    const res = await getDoc(dripPlateDoc);
    
    //console.log("Res:", dim, res.data().basePrice[dim], res.data().enumFactor);
    return Math.round(Number(res.data().basePrice[dim] * Number(res.data().enumFactor)));
}

export { getPriceDripPlate }