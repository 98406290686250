import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { Adjustments, Devbar } from '../modules/adjustments';
import { useAnalytics } from 'reactfire';
import { logEvent } from 'firebase/analytics';

export const Step3 = () => {
    const analytics = useAnalytics();

    useEffect(() => {
        logEvent(analytics, 'page_view', { page_location: window.location.href, page_title: "Step 3" });
    }, [window.location.href]);

    return (
        <Box style={{ marginTop: '15px' }}>
            <Stack direction="column" spacing={2}>
                <Devbar />
                <Adjustments />

            </Stack>
        </Box>
    );
};