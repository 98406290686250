import { ref, onValue } from "firebase/database";
import { useEffect, useState } from "react";
import { useDatabase } from "reactfire";
import { MAGICLOUD_ID_PATH } from "../constants/firebasePaths";


export const useGetMagicloudIDs = () => {
    const database = useDatabase();
    const [magicloudIDs, setMagicloudIDs] = useState({})
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const magicloudRef = ref(database, MAGICLOUD_ID_PATH);

        const valueRef = onValue(magicloudRef, (snapshot) => {
            if (snapshot.exists()) {
                setMagicloudIDs(snapshot.val());
            }
            setLoading(false);
        });

        return () => {
            valueRef();
        }
    }, [])

    return { magicloudIDs, loading };
}

