import { Select, MenuItem, Stack } from '@mui/material';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import { collection } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { ADJUSTMENT_VALVES_PATH, CONTROL_VALVES_PATH } from '../../../../constants/firebasePaths';
import { PRODUCTLINE_GREEN, PRODUCTLINE_FLEX, PRODUCTLINE_SMART } from '../../../../constants/productlineConstants';
import { CV_IMI, CV_SOE } from '../../../../constants/shuntOptions';
import { useDriftParams, useShuntParams, useProductLineParams, useSessionParams, useDevbarParams, useCalcParams } from '../../../../contexts';
import { ProductLineEnum, DriftParamEnum, SessionParamsEnum, ShuntParamsEnum, CalcParamsEnum } from '../../../../enums';

export const ValveSelectField = ({ labelText, isDisabled, isAdjustmentValve }) => {
    const driftParams = useDriftParams();
    const shuntParams = useShuntParams();
    const calcParams = useCalcParams();
    const productLineParams = useProductLineParams();
    const sessionParams = useSessionParams();
    const devParams = useDevbarParams();

    const firestore = useFirestore();

    const pLine = ProductLineEnum.ProductLine.getContextValue(productLineParams);

    const [optData, setOptData] = useState([]);
    const [value, setValue] = useState('');
    const [defaultVal, setDefaultVal] = useState('');
    const [update, setUpdate] = useState(false);
    let fsUrl = "";

    if (isAdjustmentValve) {
        fsUrl = ADJUSTMENT_VALVES_PATH;
    }
    else {
        fsUrl = CONTROL_VALVES_PATH;
    }

    const optionsDoc = collection(firestore, fsUrl);
    const firestoreRes = useFirestoreCollection(optionsDoc);

    const checkCalcMode = () => {
        if (DriftParamEnum.CalcMode.getContextValue(driftParams) === "0" || DriftParamEnum.CalcMode.getContextValue(driftParams) === 0) {
            return false;
        }
        else {
            return true;
        }
    }

    const isDisabledMethod = () => {
        if ((SessionParamsEnum.Editable.getContextValue(sessionParams) === false && devParams.state.val.devMode !== true) ||
            (DriftParamEnum.CalcMode.getContextValue(driftParams) !== "0" && DriftParamEnum.CalcMode.getContextValue(driftParams) !== 0)) {
            return true;
        }
        else {
            if (pLine !== PRODUCTLINE_GREEN){
                return false;
            }
            else{
                return true;
            }
            
        }
    }

    const setModelOption = (event) => {
        CalcParamsEnum.clearData(calcParams);
        if (isAdjustmentValve) {
            ShuntParamsEnum.AdjustmentValve.setContextValue(shuntParams, event.target.value);
        }
        else {
            if (event.target.value !== ShuntParamsEnum.ControlValve.getContextValue(driftParams)) {
                //ShuntParamsEnum.CV_Kvs.setContextValue(shuntParams, null);
                ShuntParamsEnum.CV_Model.setContextValue(shuntParams, null);
            }
            ShuntParamsEnum.ControlValve.setContextValue(shuntParams, event.target.value);
        }
        setValue(event.target.value);
    }

    useEffect(() => {
        if (firestoreRes.data != null) {
            const adjValve = ShuntParamsEnum.AdjustmentValve.getContextValue(shuntParams);
            const controlValve = ShuntParamsEnum.ControlValve.getContextValue(shuntParams);
            const productLine = ProductLineEnum.ProductLine.getContextValue(productLineParams);

            const options = [];
            firestoreRes.data.forEach(model => {
                //console.log("Data:", model.data());
                if (isAdjustmentValve) {
                    options.push({ id: model.id, name: model.data().name });
                }
                else {
                    if (model.data().usedIn === "all" || model.data().usedIn.includes(ProductLineEnum.ProductPrincip.getContextValue(productLineParams))) {
                        options.push({ id: model.id, name: model.data().name });
                    }
                }

                if (model.data().default === true) {
                    setDefaultVal(model.id);
                }

                if (isAdjustmentValve && (adjValve == null || adjValve === "")) {
                    if (productLine === PRODUCTLINE_GREEN) {
                        if (model.data().default_green === true) {
                            setValue(model.id);
                            setUpdate(!update);
                        }
                    }
                    else {
                        if (model.data().default === true) {
                            setValue(model.id);
                            setUpdate(!update);
                        }
                    }

                }
                else if (!isAdjustmentValve && (controlValve == null || controlValve === "")) {
                    if (productLine === PRODUCTLINE_FLEX) {
                        if (model.data().default_flex === true) {
                            setValue(model.id);
                            setUpdate(!update);
                        }
                    }
                    else if (productLine === PRODUCTLINE_SMART) {
                        if (model.data().default_smart === true) {
                            setValue(model.id);
                            setUpdate(!update);
                        }
                    }
                    else {
                        if (model.data().default === true) {
                            setValue(model.id);
                            setUpdate(!update);
                        }
                    }

                }
            });

            setOptData(options);
        }

    }, [firestoreRes, ProductLineEnum.ProductLine.getContextValue(productLineParams), ProductLineEnum.ProductPrincip.getContextValue(productLineParams)])

    useEffect(() => {
        if (isAdjustmentValve) {
            if ((ShuntParamsEnum.AdjustmentValve.getContextValue(shuntParams) != null && ShuntParamsEnum.AdjustmentValve.getContextValue(shuntParams) !== "")) {
                setValue(ShuntParamsEnum.AdjustmentValve.getContextValue(shuntParams));
                setUpdate(!update);
            }

        }
        else {
            if ((ShuntParamsEnum.ControlValve.getContextValue(shuntParams) != null && ShuntParamsEnum.ControlValve.getContextValue(shuntParams) !== "")) {
                setValue(ShuntParamsEnum.ControlValve.getContextValue(shuntParams));
                setUpdate(!update);
            }
        }
    }, [shuntParams.state.val]);

    useEffect(() => {
        if (SessionParamsEnum.IsApiSession.getContextValue(sessionParams) !== true) {
            if (DriftParamEnum.CalcMode.getContextValue(driftParams) === "1" && !isAdjustmentValve) {
                if (pLine === PRODUCTLINE_FLEX || pLine === PRODUCTLINE_SMART) {
                    ShuntParamsEnum.ControlValve.setContextValue(shuntParams, CV_IMI);
                    setValue(CV_IMI);
                }
                else {
                    ShuntParamsEnum.ControlValve.setContextValue(shuntParams, CV_SOE);
                    setValue(defaultVal);
                }
            }
        }

    }, [DriftParamEnum.CalcMode.getContextValue(driftParams)])


    return (
        <Stack direction={"row"}>
            <div style={{ marginTop: "14px" }}>
                {labelText}:
            </div>
            <Select
                style={{ marginLeft: "auto", width: "50%", marginTop: "7px" }}
                size={"small"}
                value={value || ''}
                margin="dense"
                onChange={setModelOption}
                disabled={isDisabledMethod()}
            >
                {optData.map((option, index) => {
                    return (<MenuItem key={index} value={option.id}>{option.name}</MenuItem>)
                })}
            </Select>
        </Stack>
    );
};