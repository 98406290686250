import { INSULATION_LABELS } from "./adjustmentsLables"
import { INSULATION_19NH_SMART } from "./adjustmentsOptions"
import { PRODUCTFAMILY_PR, PRODUCTFAMILY_SR, PRODUCTFAMILY_VA, PRODUCTFOLDER_PR2, PRODUCTFOLDER_PR3, PRODUCTFOLDER_PRBX, PRODUCTFOLDER_PRX, PRODUCTFOLDER_SR2, PRODUCTFOLDER_SR2_FLEX, PRODUCTFOLDER_SR2_SMART, PRODUCTFOLDER_SR3, PRODUCTFOLDER_SRBX, PRODUCTFOLDER_SRX, PRODUCTFOLDER_VA1, PRODUCTPRINCIP_SR2 } from "./productlineConstants"

export const CUSTOM_SHUNT_OPTIONS = [
    "FB-5V",
    "FB-5K",
    "FK-5K",
    "PR-2 Appshunt",
    "SR-2 flex Appshunt",
    "SRX-42",
    "SRX-43",
    "PRX-43",
    "PRX-43FK",
    "PBCS",
    "SRBX-6VK",
    "PRBX-6VK",
    "SRBX-6ECO",
    "SRBX-6VÅV",
    "SRBX-6SPETS",
    "SRBX-6NÖD",
    "SRBX-6VÅK",
    "SRBX-8VÅKK",
]

export const CUSTOM_SHUNT_PRODLINES = {
    "FB-5V": ['standard', 'green I', 'green II', 'flex'],
    "FB-5K": ['standard', 'green I', 'green II', 'flex'],
    "FK-5K": ['standard', 'green I', 'green II', 'flex'],
    "SR-2 flex Appshunt": ['flex'],
    "PR-2 Appshunt": ['standard'],
    "SRX-42": ['standard', 'green I', 'green II', 'flex', 'smart'],
    "SRX-43": ['standard', 'green I', 'green II'],
    "PRX-43": ['standard', 'green I', 'green II'],
    "PRX-43FK": ['standard', 'green I'],
    "PBCS": ['standard', 'green I'],
    "SRBX-6VK": ['standard', 'green I', 'green II', 'flex', 'smart'],
    "SRBX-62VK": ['standard', 'green I', 'green II', 'flex', 'smart'],
    "SRBX-63VK": ['standard', 'green I', 'green II', 'flex', 'smart'],
    "PRBX-6VK": ['standard', 'green I', 'green II', 'flex', 'smart'],
    "SRBX-6ECO": ['standard', 'green I', 'flex', 'smart'],
    "SRBX-6VÅV": ['standard', 'green I', 'flex', 'smart'],
    "SRBX-6SPETS": ['standard', 'green I', 'flex', 'smart'],
    "SRBX-6NÖD": ['standard', 'green I', 'flex', 'smart'],
    "SRBX-6VÅK": ['standard', 'green I', 'flex', 'smart'],
    "SRBX-8VÅKK": ['standard', 'green I', 'flex', 'smart'],
}

export const CUSTOM_SHUNT_CONN_OPTIONS = {
    "FB-5V": ['U'],
    "FB-5K": ['U'],
    "FK-5K": ['U'],
    "PR-2 Appshunt": ['H'],
    "SR-2 flex Appshunt": ['H'],
    "SRX-42": ['U', 'L'],
    "SRX-43": ['U', 'L'],
    "PRX-43": ['U', 'L'],
    "PRX-43FK": ['U'],
    "PBCS": ['Custom'],
}

export const CUSTOM_SHUNT_TEMP_VERSION = {
    "FB-5V": ['Värme'],
    "FB-5K": ['Kyla'],
    "FK-5K": ['Kyla'],
    "SR-2 flex Appshunt": ['Värme', 'Kyla'],
    "PR-2 Appshunt": ['Värme', 'Kyla'],
    "SRX-42": ['Värme', 'Kyla'],
    "SRX-43": ['Värme', 'Kyla'],
    "PRX-43": ['Värme', 'Kyla'],
    "PRX-43FK": ['Kyla'],
    "SRBX-6VK": ['Värme + kyla'],
    "SRBX-62VK": ['Värme + kyla'],
    "SRBX-63VK": ['Värme + kyla'],
    "PRBX-6VK": ['Värme + kyla'],
    "SRBX-6ECO": ['Värme + kyla'],
    "SRBX-6VÅV": ['VÅ + kyla'],
    "SRBX-6SPETS": ['Värme'],
    "SRBX-6NÖD": ['Kyla'],
    "SRBX-6VÅK": ['VÅ + kyla'],
    "SRBX-8VÅKK": ['Massa saker'],
}

export const CUSTOM_SHUNT_ADVANCED_MODE_ENABLED = {

}

export const CUSTOM_SHUNT_DIM_LABELS = {
    "SR-2": { 'prim': 'Prim', 'sek': 'Sek' },
    "SR-2-flex": { 'prim': 'Prim', 'sek': 'Sek' },
    "SR-2-smart": { 'prim': 'Prim', 'sek': 'Sek' },
    "SR-3": { 'prim': 'Prim', 'sek': 'Sek' },
    "PR-2": { 'prim': 'Prim', 'sek': 'Sek' },
    "PR-3": { 'prim': 'Prim', 'sek': 'Sek' },
    "VA-1": { 'prim': 'TL', 'sek': 'FL' },
    "FB-5V": { 'prim': 'VP', 'prim2': 'VR', 'sek': 'Sek' },
    "FB-5K": { 'prim': 'KB', 'prim2': 'KS', 'sek': 'BF' },
    "FK-5K": { 'none': 'ㄟ( ▔, ▔ )ㄏ' },
    "SR-2 flex Appshunt": { 'prim': 'Prim', 'sek': 'Sek' },
    "PR-2 Appshunt": { 'prim': 'Prim', 'sek': 'Sek' },
    "SRX-42": { 'prim': 'Prim', 'sek': 'Sek' },
    "SRX-43": { 'prim': 'Prim', 'sek': 'Sek' },
    "PRX-43": { 'prim': 'Prim', 'sek': 'Sek' },
    "PRX-43FK": { 'prim': 'KB', 'sek': 'FK' },
    "SRBX-6VK": { 'prim': 'KB', 'prim2': 'VP', 'sek': 'Sek' },
    "SRBX-6VK-KB": { 'prim': 'VP', 'prim2': 'KB', 'sek': 'Sek' },
    "SRBX-62VK": { 'prim': 'KB', 'prim2': 'VP', 'sek': 'Sek' },
    "SRBX-63VK": { 'prim': 'KB', 'prim2': 'VP', 'sek': 'Sek' },
    "PRBX-6VK": { 'prim': 'KB', 'prim2': 'VP', 'sek': 'Sek' },
    "SRBX-6ECO": { 'prim': 'KB', 'sek': 'Sek', 'prim2': 'VP' },
    "SRBX-6VÅV": { 'prim': 'TL', 'prim2': 'VP', 'sek': 'FL' },
    "SRBX-6SPETS": { 'prim': 'ÅV', 'prim2': 'VP', 'sek': 'Sek' },
    "SRBX-6NÖD": { 'prim': 'KB', 'prim2': 'TAPP', 'sek': 'Sek' },
    "SRBX-6VÅK": { 'prim': 'TL', 'prim2': 'KB', 'sek': 'FL' },
    "SRBX-8VÅKK": { 'prim': 'TL', 'prim2': 'VP', 'sek': 'KB', 'sek2': 'FL' },
}

export const CUSTOM_SHUNT_ADJUST_VALVE_DIM_LABELS = {
    "SR-2": { 'prim': 'Prim', 'sek': 'Sek' },
    "SR-2-flex": { 'sek': 'Sek' },
    "SR-2-smart": { 'prim': 'Prim', 'sek': 'Sek' },
    "SR-3": { 'prim': 'Prim', 'sek': 'Sek' },
    "PR-2": { 'prim': 'Prim', 'sek': 'Sek' },
    "PR-3": { 'prim': 'Prim', 'sek': 'Sek' },
    "VA-1": { 'prim': 'TL', 'sek': 'FL' },
    "FB-5V": { 'prim': 'VP', 'prim2': 'VR', 'sek': 'Sek' },
    "FB-5K": { 'prim': 'KB', 'prim2': 'KS', 'sek': 'BF' },
    "FK-5K": { 'none': 'ㄟ( ▔, ▔ )ㄏ' },
    "SR-2 flex Appshunt": { 'prim': 'Prim', 'sek': 'Sek' },
    "PR-2 Appshunt": { 'prim': 'Prim', 'sek': 'Sek' },
    "SRX-42": { 'prim': 'Prim', 'sek': 'Sek' },
    "SRX-43": { 'prim': 'Prim', 'sek': 'Sek' },
    "PRX-43": { 'prim': 'Prim', 'sek': 'Sek' },
    "PRX-43FK": { 'prim': 'KB', 'sek': 'FK' },
    "SRBX-6VK": { 'prim': 'KB', 'prim2': 'VP', 'sek': 'Sek' },
    "SRBX-6VK-KB": { 'prim': 'VP', 'prim2': 'KB', 'sek': 'Sek' },
    "SRBX-62VK": { 'prim': 'KB', 'prim2': 'VP', 'sek': 'Sek' },
    "SRBX-63VK": { 'prim': 'KB', 'prim2': 'VP', 'sek': 'Sek' },
    "PRBX-6VK": { 'prim': 'KB', 'prim2': 'VP', 'sek': 'Sek' },
    "SRBX-6ECO": { 'prim': 'KB', 'sek': 'Sek', 'prim2': 'VP' },
    "SRBX-6VÅV": { 'prim': 'TL', 'prim2': 'VP', 'sek': 'FL' },
    "SRBX-6SPETS": { 'prim': 'ÅV', 'prim2': 'VP', 'sek': 'Sek' },
    "SRBX-6NÖD": { 'prim': 'KB', 'prim2': 'TAPP', 'sek': 'Sek' },
    "SRBX-6VÅK": { 'prim': 'TL', 'prim2': 'KB', 'sek': 'FL' },
    "SRBX-8VÅKK": { 'prim': 'TL', 'prim2': 'VP', 'sek': 'KB', 'sek2': 'FL' },
}

export const CUSTOM_SHUNT_FLOW_LABELS = {
    "SR-2": { 'prim': 'Prim', 'sec': 'Sek' },
    "SR-2-flex": { 'prim': 'Prim', 'sec': 'Sek' },
    "SR-2-smart": { 'prim': 'Prim', 'sec': 'Sek' },
    "SR-3": { 'prim': 'Prim', 'sec': 'Sek' },
    "PR-2": { 'prim': 'Prim', 'sec': 'Sek' },
    "PR-3": { 'prim': 'Prim', 'sec': 'Sek' },
    "VA-1": { 'prim': 'TL', 'sec': 'FL' },
    "FB-5V": { 'prim': 'VP', 'prim2': 'VR', 'sec': 'Sek' },
    "FB-5K": { 'prim': 'KB', 'prim2': 'KS', 'sec': 'BF' },
    "FK-5K": { 'none': 'ㄟ( ▔, ▔ )ㄏ' },
    "SR-2 flex Appshunt": { 'prim': 'Prim', 'sec': 'Sek' },
    "PR-2 Appshunt": { 'prim': 'Prim', 'sec': 'Sek' },
    "SRX-42": { 'prim': 'Prim', 'sec': 'Sek' },
    "SRX-43": { 'prim': 'Prim', 'sec': 'Sek' },
    "PRX-43": { 'prim': 'Prim', 'sec': 'Sek' },
    "PRX-43FK": { 'prim': 'KB', 'sec': 'FK' },
    "SRBX-6VK": { 'prim': 'KB', 'prim2': 'VP', 'sec': 'Sek' },
    "SRBX-6VK-KB": { 'prim': 'VP', 'prim2': 'KB', 'sec': 'Sek' },
    "SRBX-62VK": { 'prim': 'KB', 'prim2': 'VP', 'sec': 'Sek' },
    "SRBX-63VK": { 'prim': 'KB', 'prim2': 'VP', 'sec': 'Sek' },
    "PRBX-6VK": { 'prim': 'KB', 'prim2': 'VP', 'sec': 'Sek' },
    "SRBX-6ECO": { 'prim': 'KB', 'sec': 'Sek', 'prim2': 'VP' },
    "SRBX-6VÅV": { 'prim': 'TL', 'prim2': 'VP', 'sec': 'FL' },
    "SRBX-6SPETS": { 'prim': 'ÅV', 'prim2': 'VP', 'sec': 'Sek' },
    "SRBX-6NÖD": { 'prim': 'KB', 'prim2': 'TAPP', 'sec': 'Sek' },
    "SRBX-6VÅK": { 'prim': 'TL', 'prim2': 'KB', 'sec': 'FL' },
    "SRBX-8VÅKK": { 'prim': 'TL', 'prim2': 'VP', 'sec': 'KB', 'sec2': 'FL' },
}

export const SHUNT_FAMILY = {
    "SR-2": PRODUCTFAMILY_SR,
    "SR-2-flex": PRODUCTFAMILY_SR,
    "SR-2-smart": PRODUCTFAMILY_SR,
    "SR-3": PRODUCTFAMILY_SR,
    "PR-2": PRODUCTFAMILY_PR,
    "PR-3": PRODUCTFAMILY_PR,
    "VA-1": PRODUCTFAMILY_VA,
    "FB-5V": 'others',
    "FB-5K": 'others',
    "FK-5K": 'others',
    "SR-2 flex Appshunt": PRODUCTFAMILY_SR,
    "PR-2 Appshunt": PRODUCTFAMILY_PR,
    "SRX-42": PRODUCTFAMILY_SR,
    "SRX-43": PRODUCTFAMILY_SR,
    "PRX-43": PRODUCTFAMILY_PR,
    "PRX-43FK": PRODUCTFAMILY_PR,
    "SRBX-6VK": PRODUCTFAMILY_SR,
    "SRBX-6VK-KB": PRODUCTFAMILY_SR,
    "SRBX-62VK": PRODUCTFAMILY_SR,
    "SRBX-63VK": PRODUCTFAMILY_SR,
    "PRBX-6VK": PRODUCTFAMILY_PR,
    "SRBX-6ECO": PRODUCTFAMILY_SR,
    "SRBX-6VÅV": PRODUCTFAMILY_SR,
    "SRBX-6SPETS": PRODUCTFAMILY_SR,
    "SRBX-6NÖD": PRODUCTFAMILY_SR,
    "SRBX-6VÅK": PRODUCTFAMILY_SR,
    "SRBX-8VÅKK": PRODUCTFAMILY_SR,
}

export const SHUNT_FAMILY_FOLDER = {
    "SR-2": PRODUCTFOLDER_SR2,
    "SR-2-flex": PRODUCTFOLDER_SR2_FLEX,
    "SR-2-smart": PRODUCTFOLDER_SR2_SMART,
    "SR-3": PRODUCTFOLDER_SR3,
    "PR-2": PRODUCTFOLDER_PR2,
    "PR-3": PRODUCTFOLDER_PR3,
    "VA-1": PRODUCTFOLDER_VA1,
    "FB-5V": 'others',
    "FB-5K": 'others',
    "FK-5K": 'others',
    "SR-2 flex Appshunt": PRODUCTFOLDER_SR2_FLEX,
    "PR-2 Appshunt": PRODUCTFOLDER_PR2,
    "SRX-42": PRODUCTFOLDER_SRX,
    "SRX-43": PRODUCTFOLDER_SRX,
    "PRX-43": PRODUCTFOLDER_PRX,
    "PRX-43FK": PRODUCTFOLDER_PRX,
    "SRBX-6VK": PRODUCTFOLDER_SRBX,
    "SRBX-6VK-KB": PRODUCTFOLDER_SRBX,
    "SRBX-62VK": PRODUCTFOLDER_SRBX,
    "SRBX-63VK": PRODUCTFOLDER_SRBX,
    "PRBX-6VK": PRODUCTFOLDER_PRBX,
    "SRBX-6ECO": PRODUCTFOLDER_SRBX,
    "SRBX-6VÅV": PRODUCTFOLDER_SRBX,
    "SRBX-6SPETS": PRODUCTFOLDER_SRBX,
    "SRBX-6NÖD": PRODUCTFOLDER_SRBX,
    "SRBX-6VÅK": PRODUCTFOLDER_SRBX,
    "SRBX-8VÅKK": PRODUCTFOLDER_SRBX,
}

export const SHUNT_NWAYS = {
    "SR-2": 2,
    "SR-2-flex": 2,
    "SR-2-smart": 2,
    "SR-3": 3,
    "PR-2": 2,
    "PR-3": 3,
    "VA-1": 1,
}

export const CUSTOM_SHUNT_BYPASS_OPTION = {
    "SRBX-6VÅK": "Förbigång",
    "SRBX-8VÅKK": "Förbigång",
}

export const CUSTOM_SHUNT_EXCHANGE_LABELS = {
    "SRBX-6VK": [{ 'label': 'VP', 'val': 'VP' }, { 'label': 'KB', 'val': 'KB' }],
    "SRBX-62VK": [{ 'label': 'KB', 'val': 'prim' }, { 'label': 'VP', 'val': 'prim2' }],
    "SRBX-63VK": [{ 'label': 'KB', 'val': 'prim' }, { 'label': 'VP', 'val': 'prim2' }],
}

export const CUSTOM_SHUNT_EXCHANGE_VALUES = {
    "SRX-42": "Prim",
    "SRX-43": "Prim",
    "PRX-43": "Sek",
    "PRX-43FK": "Sek",
}

export const CUSTOM_SHUNT_SEC_CONTROLVALVE_OPTIONS = {
    "SRBX-6VK": [{ value: 'kyla', cv: 'controlValve' }, { value: 'värme', cv: 'controlValve2' }],
    "SRBX-6VK-KB": [{ value: 'värme', cv: 'controlValve' }, { value: 'kyla', cv: 'controlValve2' }],
}

export const CUSTOM_SHUNT_SEC_CONTROLVALVE_LABELS = {
    "SRBX-6VK": [{ 'label': '2-vägs', 'nWays': 2 }, { 'label': '3-vägs', 'nWays': 3 }],
    "SRBX-62VK": [{ 'label': '2-vägs', 'nWays': 2 }, { 'label': '3-vägs', 'nWays': 3 }],
    "SRBX-63VK": [{ 'label': '2-vägs', 'nWays': 2 }, { 'label': '3-vägs', 'nWays': 3 }],
}

export const DEFAULT_SHUNT_DATA = {
    'adjValve': 'StadF', 'controlValve': 'SoE', 'cvModel': 'Kvs', 'numConn': 4, 'numIV': 2
}

export const CUSTOM_SHUNT_SHUNT_DATA = {
    "SR-2 flex Appshunt": {
        'adjValve': 'StadF',
        'controlValve': 'IMI',
        'flexModel': 'TAModulator',
        'numIV': 1,
        'numBV': 1,
        'shuntNomenklaturString': 'SRH-2flex-App'
    },
    "PR-2 Appshunt": {
        'adjValve': 'StadF',
        'controlValve': 'Belimo',
        'cvModel': 'R3015',
        'numIV': 2,
        'shuntNomenklaturString': 'PRH-2-App'
    },
    "SRBX-6VK": {
        'adjValve': 'StadF',
        'controlValve': 'SoE',
        'cvModel': 'Kvs',
        'numBV': 1,
        'numIV': 3,
    },
}

export const CUSTOM_SHUNT_NUM_CONNECTIONS = {
    "SR-2": 4,
    "SR-2-flex": 4,
    "SR-2-smart": 4,
    "SR-3": 4,
    "PR-2": 4,
    "PR-3": 4,
    "VA-1": 4,
    "FB-5V": 5,
    "FB-5K": 5,
    "FK-5K": 5,
    "SR-2 flex Appshunt": 4,
    "PR-2 Appshunt": 4,
    "SRX-42": 4,
    "SRX-43": 4,
    "PRX-43": 4,
    "PRX-43FK": 4,
    "SRBX-6VK": 6,
    "SRBX-6VK-KB": 6,
    "SRBX-62VK": 6,
    "SRBX-63VK": 6,
    "PRBX-6VK": 6,
    "SRBX-6ECO": 6,
    "SRBX-6VÅV": 6,
    "SRBX-6SPETS": 6,
    "SRBX-6NÖD": 6,
    "SRBX-6VÅK": 6,
    "SRBX-8VÅKK": 8,
}

export const CUSTOM_SHUNT_DIM_LIMITS = {
    "SR-2 flex Appshunt": ['DN020-6', 'DN025'],
    "PR-2 Appshunt": ['DN020-6'],
}

export const CUSTOM_SHUNT_PL_MODEL = {
    "SR-2 flex Appshunt": 'SRH-2 flex App',
    "PR-2 Appshunt": 'PRH-2App',
}

export const CUSTOM_SHUNT_PRESSURE_CLASS = {
    /*"SR-2 flex Appshunt": 'PN6',
    "PR-2 Appshunt": 'PN6',*/
}

export const CUSTOM_SHUNT_NUM_THERMO = {
    "SR-2 flex Appshunt": 3,
    "PR-2 Appshunt": 3,
    "SRBX-6VK": { 'cold': 2, 'warm': 4 },
    "SRBX-6VK-KB": { 'cold': 2, 'warm': 4 },
}

export const CUSTOM_SHUNT_PUMP_OPTIONS = {
    "SR-2 flex Appshunt": { 'pumpRequestType': 'customerSpecified', 'specificPumpRequest': 'Wilo PARA 25-130/7, 1x230V/0,44Amp' },
    "PR-2 Appshunt": { 'pumpRequestType': 'customerSpecified', 'specificPumpRequest': 'Wilo PARA 25-130/7, 1x230V/0,44Amp' },
}

export const CUSTOM_SHUNT_CONTROLVALVE_VALUES = {
    "SR-2 flex Appshunt": { 'DN020': ' DN020, 0,055-0,27 l/s', 'DN025': ' DN020, 0,094-0,49 l/s' },
}

export const CUSTOM_SHUNT_INSULATION_TEXT = {
    "SR-2 flex Appshunt": INSULATION_LABELS[INSULATION_19NH_SMART] + " (hellimmad)",
    "PR-2 Appshunt": INSULATION_LABELS[INSULATION_19NH_SMART] + " (hellimmad)",
}

export const CUSTOM_SHUNT_SAFETY_VALVE = {
    "SRBX-6VK": true,
    "SRX-42": true,
    "SRX-43": true,
    "PRX-43": true,
    "PRX-43FK": true,
}

export const CUSTOM_SHUNT_MUFF = {
    "SRX-42": true,
    "SRX-43": true,
    "PRX-43": true,
    "PRX-43FK": true,
}


export const CUSTOM_SHUNT_MEASUREMENT_ROWS = {
    "SR-2 flex Appshunt": {
        'DN020': [
            'L = 670 mm',
            'B = 250 mm',
            'L = 190 mm',
            'c/c = 160 mm'
        ],
        'DN025': [
            'L = 750 mm',
            'B = 300 mm',
            'L = 220 mm',
            'c/c = 180 mm'
        ]
    },
    "PR-2 Appshunt": {
        'DN020': [
            'L = 780 mm',
            'B = 270 mm',
            'L = 170 mm',
            'c/c = 160 mm'
        ]
    },
}