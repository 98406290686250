import { useState, } from 'react';
import { Box, Stack, Stepper, Step, StepLabel } from '@mui/material';

import { ClearContext } from '../../utils/clearContext';

const steps = ['Kopplingsprincip', 'Parametrar', 'Utförande', 'Pumpval', 'Granska'];

export default function HorizontalLinearStepper() {
    const getStep = () => {
        let hash = window.location.hash;

        switch (hash) {
            case "#step1":
                return 0;
            case "#step2":
                return 1;
            case "#step3":
                return 2;
            case "#step4":
                return 3;
            case "#step5":
                return 4;
            default:
                return 0;
        }
    }

    const [activeStep, setActiveStep] = useState(getStep());

    const getStepAfterInit = () => {
        let hash = window.location.hash;
        switch (hash) {
            case "#step1":
                if (activeStep !== 0) {
                    setActiveStep(0)
                }
                break;
            case "#step2":
                if (activeStep !== 1) {
                    setActiveStep(1)
                }
                break;
            case "#step3":
                if (activeStep !== 2) {
                    setActiveStep(2)
                }
                break;
            case "#step4":
                if (activeStep !== 3) {
                    setActiveStep(3)
                }
                break;
            case "#step5":
                if (activeStep !== 4) {
                    setActiveStep(4)
                }
                break;
            default:
                if (activeStep !== 0) {
                    setActiveStep(0)
                }
                break;
        }
    }

    getStepAfterInit();

    return (
        <>
            <ClearContext clearSession={true} />
            <div style={{
                margin: "0px 0px 0px 0px",
                width: "96.3%",
                borderRadius: "4px",
                paddingLeft: "20px",
                paddingRight: "20px",
                justifyContent: 'center',
                backgroundColor: '#E1E2E4',
            }}>

                <Box>
                    <Stack direction={"row"}>
                        <div style={{ marginRight: '20px', paddingLeft: '8px', paddingRight: '8px' }}>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ paddingRight: '8px', paddingTop: '3px' }}>
                                    <svg style={{ color: '#1976d2', height: '1.5em', width: '1.5em' }} focusable="false" aria-hidden="true" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12" style={{ fill: '#06559e' }}>
                                    </circle>
                                        <text style={{ fill: '#fff', fontFamily: 'roboto', color: '#06559e' }} x="12" y="16" textAnchor="middle">
                                            0
                                        </text>
                                    </svg>
                                </span>
                                <span className="MuiStepLabel-labelContainer">
                                    <span className="MuiStepLabel-label css-18k7o0v-MuiStepLabel-label">
                                        Start
                                    </span>
                                </span>
                            </span>
                        </div>


                        <Stepper activeStep={-1} style={{ width: '95%' }}>

                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps} >{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Stack>
                </Box>

            </div>
        </>
    );
}