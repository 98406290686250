import { useState } from "react"
import { Box, Button } from "@mui/material"

import { BootstrapDialog } from "../../utils/bootstrapDialog";
import { AddShuntRowModal } from "./components/shuntRow";
import { useContextAuth } from "../../../authContext/AuthHook";

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export const CustomShuntModal = ({ disabled }) => {
    const { user } = useContextAuth();
    const [openModal, setOpenModal] = useState(false);

    const handleModalOpen = () => { setOpenModal(true) }
    const handleModalClose = () => { setOpenModal(false) }

    return (
        <>
            {user?.isAnonymous !== true && user?.isInternal === true ? (
                <Box>
                    <Button sx={{width: '270px', justifyContent: 'start'}} size="small" variant="contained" disabled={disabled} onClick={handleModalOpen}>
                        <AddCircleOutlineIcon style={{ marginRight: '8px' }} /> Lägg till manuell shuntgrupp
                    </Button>
                </Box>
            ) : ("")}


            <BootstrapDialog
                onClose={handleModalClose}
                aria-labelledby="customized-dialog-title"
                open={openModal}
            >
                <AddShuntRowModal handleModalClose={handleModalClose} />
            </BootstrapDialog>
        </>
    )
}