import { ref, set } from "firebase/database";
import { SESSION_SHUNT_PATH, SHUNT_PLDATA } from "../constants/firebasePaths";
import { PROD_PRODLINE, PROD_PRODFAMILY, PROD_PRINCIP, PROD_N_WAYS_VALVE, PROD_GREEN_LEVEL, PROD_SELECTED_PRODLINE, PROD_SELECTED_PRINCIP } from "../constants/keys/productLineKeys";
import { ContextEnum } from "./contextEnum";

export class ProductLineEnum extends ContextEnum {
    static ProductLine = new ProductLineEnum(PROD_PRODLINE, "setProductLine", "clearProductLine");
    static ProductFamily = new ProductLineEnum(PROD_PRODFAMILY, "setProdFamily", "clearProdFamily");
    static ProductPrincip = new ProductLineEnum(PROD_PRINCIP, "setPrincip", "clearPrincip");
    static ProductGreenLevel = new ProductLineEnum(PROD_GREEN_LEVEL, "setGreenLevel", "clearGreenLevel");
    static ProductNWaysValue = new ProductLineEnum(PROD_N_WAYS_VALVE, "setNWaysValve", "clearNWaysValve");

    static SelectedProductLine = new ProductLineEnum(PROD_SELECTED_PRODLINE, "setSelectedProductLine", "clearSelectedProductLine");
    static SelectedProductPrincip = new ProductLineEnum(PROD_SELECTED_PRINCIP, "setSelectedPrincip", "clearSelectedPrincip");


    /**
     * @param {string} name 
     * @param {string} dispatchMethod 
     * @param {string} clearMethod 
     */
    constructor(name, dispatchMethod, clearMethod) {
        super(name, dispatchMethod);
        this.clearMethod = clearMethod;
    }

    getContextValue(contextParam) {
        return contextParam.state.productLine[this.name];
    }

    clearData(contextParam) {
        //console.log("Here Prod", this.clearMethod)
        contextParam.dispatch({ type: this.clearMethod })
    }

    setToFirebase(database, shuntID, value){
        const dbRef = ref(database, SESSION_SHUNT_PATH + shuntID + SHUNT_PLDATA + this.name);
        return set(dbRef, value);
    }
} 