import './index.css';

import Logo from '../../../assets/images/logos/logo.svg';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/Logout';

import { useState, useCallback, useEffect } from 'react';
import { Box, Typography, FormControlLabel, Checkbox, Stack, Button, Modal, } from "@mui/material"
import { useAuth } from 'reactfire';
import { useLocation } from 'react-router-dom';
import { signInAnonymously } from 'firebase/auth';
import { useDevbarParams, useSessionParams } from '../../../contexts';
import { SessionParamsEnum } from '../../../enums';
import { isDevUser } from '../../../firebase/functions/isDevUser';
import DashboardLink from '../dashboardLink/dashboardLink';
import Infobox from '../infobox/infobox'
import { LoginModule } from '../login/loginModule';
import { SL_VERSION } from '../../../constants/generalText/generalInfo';
import { useContextAuth } from '../../../authContext/AuthHook';
import { RequestSLUserButton } from '../requestSLUser/requestSLUserButton';


export default function HeaderBar() {
    // const navigate = useNavigate();
    const { handleLogout } = useContextAuth();
    const { pathname } = useLocation();
    const { user } = useContextAuth();
    const auth = useAuth();
    const devParams = useDevbarParams();

    const [openLogin, setOpenLogin] = useState(false);
    const handleLoginClose = () => {
        setOpenLogin(false);
    }

    const getDevParams = (type) => {
        if (devParams.state.val != null) {
            switch (type) {
                case "devMode":
                    if (devParams.state.val.devMode != null) {
                        return devParams.state.val.devMode;
                    }
                    else {
                        return "";
                    }

                default:
                    return "";
            }
        }
        else {
            return '';
        }
    }

    const sessionParams = useSessionParams();
    const [devMode, setDevMode] = useState(getDevParams('devMode') || false);
    const [dashBoard, setDashboard] = useState(false);
    const [apiSession, setApiSession] = useState(SessionParamsEnum.IsApiSession.getContextValue(sessionParams) || false);

    useEffect(() => {
        auth.onAuthStateChanged(async (fbAuth) => {
            if (fbAuth == null) {
                console.log("Anon: ", auth.currentUser)
                await signInAnonymously(auth);
            }
            else {
                if (isDevUser(fbAuth?.uid) === false && (devParams.state.val.devMode === true || devMode === true)) {
                    devParams.dispatch({ type: 'setDevMode', payload: false })
                    setDevMode(false);
                }
            }
        })
    }, [auth.currentUser]);

    useEffect(() => {
        if (auth.currentUser != null) {
            if (isDevUser(auth.currentUser?.uid) && pathname === "/stepper") {
                setDashboard(true);
            }
            else {
                setDashboard(false);
            }

        }
    }, [auth.currentUser])


    const setIsApiSession = (event) => {
        setApiSession(event.target.checked)
        if (event.target.checked === true) {
            SessionParamsEnum.IsApiSession.setContextValue(sessionParams, true);
        }
        else {
            SessionParamsEnum.IsApiSession.setContextValue(sessionParams, false);
        }
    }

    const setDevParams = (value, type) => {
        switch (type) {

            case "devMode":
                devParams.dispatch({ type: 'setDevMode', payload: value })
                setDevMode(value);
                break;

            default:
                //console.log("No matching param")
                break;
        }
    }

    const isDisabled = () => {
        if (SessionParamsEnum.Editable.getContextValue(sessionParams) === true) {
            return true;
        }
        else {
            return false;
        }
    }

    const onPageLeave = useCallback((event) => {
        //console.log('run' + pathname);
        event.preventDefault();
        event.returnValue = '';

    }, [])

    if (pathname !== "/home" && isDisabled()) {
        //console.log('added' + pathname);
        window.removeEventListener('beforeunload', onPageLeave)
        window.addEventListener('beforeunload', onPageLeave)
    }
    else {
        window.removeEventListener('beforeunload', onPageLeave)
    }

    const handleLogoutFunc = (event) => {
        event.preventDefault();
        handleLogout();
    }

    return (
        <Box sx={{ flexGrow: 1, marginTop: '-7px' }}>
            <img src={Logo}
                alt="Logo"
                aria-label="logo"
                className="logo"
            // onClick={() => { navigate("/"); }}
            />

            {pathname === "/stepper" ? (
                <>
                    <Infobox />

                    <div>
                        {user?.isAnonymous === true ? (
                            <div style={{ position: "absolute", marginLeft: "815px", marginTop: "-45px" }}>
                                <Stack>
                                    <Button onClick={() => { setOpenLogin(true) }} sx={{ width: '105px', height: '24px' }}>
                                        <AdminPanelSettingsIcon style={{ marginRight: '5px' }} /> Logga in
                                    </Button>

                                    <RequestSLUserButton />
                                </Stack>
                            </div>
                        ) : (
                            <>
                                {user?.isInternal === true ? (
                                    <>
                                        <DashboardLink />

                                        <div style={{ position: "absolute", marginLeft: "815px", marginTop: "12px" }}>
                                            <Button onClick={handleLogoutFunc} sx={{ width: '105px' }}>
                                                <LogoutIcon style={{ marginRight: '5px' }} /> Logga ut
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div style={{ position: "absolute", marginLeft: "815px", marginTop: '-50px' }}>
                                            <Typography sx={{ marginLeft: '10px', marginBottom: '-4px' }} fontStyle={'italic'} color={'GrayText'}>
                                                Inloggad som {user?.name}
                                            </Typography>
                                            <Button size='small' onClick={handleLogoutFunc} sx={{ width: '105px' }}>
                                                <LogoutIcon style={{ marginRight: '5px' }} /> Logga ut
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </>
            ) : ("")}


            {user != null ? (
                <>
                    {devMode === true ? (
                        <>
                            <div style={{ position: "absolute", marginLeft: "1135px", marginTop: "0px" }}>
                                <span style={{ fontSize: "10px", color: "#183851" }}>{user?.isAnonymous ? ("Anonymous (" + user?.uid + ")") : ("Email: " + user?.email)}</span>
                            </div>

                            <div style={{ position: "absolute", marginLeft: "1135px", marginTop: "17px" }}>
                                <span style={{ fontSize: "10px", color: "#183851" }}><i>{(sessionParams.state.val != null && SessionParamsEnum.CurrentShunt.getContextValue(sessionParams) != null ? "(" + (SessionParamsEnum.CurrentShunt.getContextValue(sessionParams)) + ")" : ("-"))}</i></span>
                            </div>
                            <div style={{ position: "absolute", marginLeft: "1135px", marginTop: "28px" }}>
                                <FormControlLabel control={<Checkbox checked={apiSession} size="small" style={{ fontSize: "10px", color: "#183851" }}
                                    onChange={setIsApiSession} />} label="Api Session" />
                            </div>
                        </>
                    ) : ("")}

                    {user?.isDev === true ? (
                        <Stack>
                            <div style={{ position: "absolute", marginLeft: "1134px", marginTop: "-43px" }}>
                                <FormControlLabel control={<Checkbox checked={devMode} style={{ color: 'lightgray' }}
                                    onChange={(event) => { setDevParams(event.target.checked, "devMode") }} />} label="" />
                            </div>

                        </Stack>
                    ) : ("")}
                </>
            ) : ("")}


            <div style={{ marginTop: "2px", paddingLeft: "5px" }}>
                <Typography variant="h2" fontWeight="bold" sx={{ color: "#183851" }}>
                    ShuntLogik<sup>®</sup>
                </Typography>
                <Typography variant="h3" fontWeight="bold" sx={{ color: "#183851" }}>
                    Ver {SL_VERSION}
                </Typography>
            </div>

            <Modal
                open={openLogin}
                onClose={handleLoginClose}
            >
                <div>
                    <LoginModule handleClose={handleLoginClose} />
                </div>
            </Modal>
        </Box >
    );
}