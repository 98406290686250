import { PRODUCTLINE_GREEN } from "../../../constants/productlineConstants";
import { ADJUST_VALVE_ARMATEC1310, ADJUST_VALVE_PURMOEVO, ADJUST_VALVE_OVENTROP, ADJUST_VALVE_STADF } from "../../../constants/shuntOptions";
import { ShuntParamsEnum } from "../../../enums/shuntParamsEnum";


const getAdjustmentValveModel = function getAdjustmentValveModel(calcMode, prodLine, adjustmentValve, shuntParams, dim, useContext = true, shuntID = null, database = null) {
    const adjValve = ShuntParamsEnum.AdjustmentValve;
    if (adjustmentValve != null) {
        switch (adjustmentValve) {
            case ADJUST_VALVE_ARMATEC1310:
                return "kvIV_At";
            case ADJUST_VALVE_PURMOEVO:
                return "kvIV_Stv";
            case ADJUST_VALVE_OVENTROP:
                return "kvIV_Oven";
            case ADJUST_VALVE_STADF:
                return "kvIV_Stad";
            default:
                if (prodLine === PRODUCTLINE_GREEN && dim <= 50) {
                    if (useContext === true){
                        adjValve.setContextValue(shuntParams, ADJUST_VALVE_PURMOEVO);
                    }
                    else{
                        adjValve.setToFirebase(database, shuntID, ADJUST_VALVE_PURMOEVO);
                    }
                    
                    return "kvIV_Stv";
                }
                else {
                    if (useContext === true){
                        adjValve.setContextValue(shuntParams, ADJUST_VALVE_PURMOEVO);
                    }
                    else{
                        adjValve.setToFirebase(database, shuntID, ADJUST_VALVE_PURMOEVO);
                    }
                    return "kvIV_Stad";
                }
        }
    }
    else {
        if (prodLine === PRODUCTLINE_GREEN) {
            if (adjustmentValve === ADJUST_VALVE_STADF) {
                return "kvIV_Stad";
            }
            else {
                if (dim <= 50) {
                    return "kvIV_Stv";
                }
                else {
                    return "kvIV_Stv";
                }
            }
        }
        else {
            if (useContext === true){
                adjValve.setContextValue(shuntParams, ADJUST_VALVE_STADF);
            }
            else{
                adjValve.setToFirebase(database, shuntID, ADJUST_VALVE_STADF);
            }
            return "kvIV_Stad";
        }
    }
}

const getAdjustmentValveModelWithoutContext = function getAdjustmentValveModelWithoutContext(calcMode, prodLine, adjustmentValve, dim) {
    if (adjustmentValve != null) {
        switch (adjustmentValve) {
            case ADJUST_VALVE_ARMATEC1310:
                return "kvIV_At";
            case ADJUST_VALVE_PURMOEVO:
                return "kvIV_Stv";
            case ADJUST_VALVE_OVENTROP:
                return "kvIV_Oven";
            case ADJUST_VALVE_STADF:
                return "kvIV_Stad";
            default:
                if (prodLine === PRODUCTLINE_GREEN && dim <= 50) {               
                    return "kvIV_Stv";
                }
                else {
                    return "kvIV_Stad";
                }
        }
    }
    else {
        if (prodLine === PRODUCTLINE_GREEN) {
            if (adjustmentValve === ADJUST_VALVE_STADF) {
                return "kvIV_Stad";
            }
            else {
                if (dim <= 50) {
                    return "kvIV_Stv";
                }
                else {
                    return "kvIV_Stv";
                }
            }
        }
        else {
            return "kvIV_Stad";
        }
    }
}

export { getAdjustmentValveModel, getAdjustmentValveModelWithoutContext }