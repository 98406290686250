import { ref, remove, set, update } from 'firebase/database';
import { createContext, useReducer, useContext } from 'react'
import { SESSION_PATH, SESSION_SHUNT_ADDONS_PATH, SESSION_SHUNT_DESIGNATIONS_PATH, SESSION_SHUNT_PATH, SHUNT_ADDONS_PATH, SHUNT_CONNECTED_ENERGY_COMM_MODAL_PATH, SHUNT_CONNECTED_ENERGY_MODEL_PATH, SHUNT_DESIGNATION_PATH, SHUNT_HAS_LATCH_PATH, SHUNT_IS_MINI_PATH, SHUNT_MAGICLOUD_LINK_PATH, SHUNT_MINI_INCOMPATIBLE_PATH, SHUNT_PLMODEL_PATH, SHUNT_POSITION_PATH, SHUNT_SHUNTDATA, SHUNT_SHUNTSTRING_PATH } from '../constants/firebasePaths';
import { ADDON_DATA_KEY, ADDON_TYPE_KEY, ADDONS_KEY, ADJVALVE_KEY, CONNECTED_ENERGY_COMM_MODAL_KEY, CONNECTED_ENERGY_MODEL_KEY, CONTROLVALVE_KEY, CV_IS_INTERVAL_KEY, CV_KVS_KEY, CV_MODEL_KEY, CV_NAME_KEY, DESIGNATION_KEY, EXCHANGER_KEY, FLEX_BRAND_KEY, FLEX_DIM_ID_KEY, FLEX_MODEL_KEY, HAS_LATCH_KEY, HAS_PREV_LATCH_KEY, IS_ADDON_KEY, IS_CUSTOM_KEY, IS_MINI_KEY, IS_SABO_KEY, MAGICLOUD_LINK_KEY, MC_FUNCTION_KEY, MINI_INCOMPATIBLE_KEY, NUM_BV_KEY, NUM_CONN_KEY, NUM_IV_KEY, PL_MODEL_KEY, POSITION_KEY, SHUNT_STRING_KEY, SMART_DIM_ID_KEY, SMART_DIM_KEY, SMART_MODEL_KEY } from '../constants/keys/shuntKeys';
import { SHUNT_PARAMS } from '../constants/sessionStoragePaths';

const ShuntParams = createContext(undefined);

function shuntParamsReducer(state, action) {
    switch (action.type) {
        case 'setDesignation': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            if (action.database != null && action.shuntID != null && action.sessionID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_DESIGNATION_PATH);
                const sessionShuntRef = ref(action.database, SESSION_PATH + action.sessionID + SESSION_SHUNT_DESIGNATIONS_PATH + action.shuntID);

                set(fieldRef, action.payload).then(() => {
                    set(sessionShuntRef, action.payload).then(() => {
                        //console.log("Added to fb!");
                    });
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[DESIGNATION_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setPosition': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            if (action.database != null && action.shuntID != null && action.sessionID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_POSITION_PATH);
                const sessionShuntRef = ref(action.database, SESSION_PATH + action.sessionID + SESSION_SHUNT_PATH + action.shuntID);

                set(fieldRef, Number(action.payload)).then(() => {
                    set(sessionShuntRef, Number(action.payload)).then(() => {
                        //console.log("Added to fb!");
                    });
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[POSITION_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setControlValve': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            data[CONTROLVALVE_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setCVName': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            data[CV_NAME_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setCVModel': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            data[CV_MODEL_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setCVKvs': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            data[CV_KVS_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setCVIsInterval': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            data[CV_IS_INTERVAL_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setAdjValve': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            data[ADJVALVE_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setShuntTypeData': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            data['shuntTypeData'] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setFlexBrand': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            data[FLEX_BRAND_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setFlexModel': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            data[FLEX_MODEL_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setFlexDimID': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            data[FLEX_DIM_ID_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setSmartModel': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            data[SMART_MODEL_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setSmartDim': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            data[SMART_DIM_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setSmartDimID': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            data[SMART_DIM_ID_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setNumBV': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            data[NUM_BV_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setNumIV': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            data[NUM_IV_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setHasLatch': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_HAS_LATCH_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }


            data[HAS_LATCH_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setPrevHasLatch': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            data[HAS_PREV_LATCH_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setIsSabo': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            data[IS_SABO_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setMagicloudLink': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_MAGICLOUD_LINK_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[MAGICLOUD_LINK_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setMCFunction': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            data[MC_FUNCTION_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setIsCustom': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            data[IS_CUSTOM_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setExchanger': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            data[EXCHANGER_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setNumConn': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            data[NUM_CONN_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setAddons': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            data[ADDONS_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setIsAddon': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            data[IS_ADDON_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setAddonData': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            data[ADDON_DATA_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setAddonType': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            data[ADDON_TYPE_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setIsMini': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_IS_MINI_PATH);

                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[IS_MINI_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setShuntNomenklaturString': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_SHUNTSTRING_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }
            data[SHUNT_STRING_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setPLModel': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_PLMODEL_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }
            data[PL_MODEL_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setMiniIncompatible': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_MINI_INCOMPATIBLE_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }
            data[MINI_INCOMPATIBLE_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setConnectedEnergyModel': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_CONNECTED_ENERGY_MODEL_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }
            data[CONNECTED_ENERGY_MODEL_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setConnectedEnergyCommModal': {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_CONNECTED_ENERGY_COMM_MODAL_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }
            data[CONNECTED_ENERGY_COMM_MODAL_KEY] = action.payload;
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'updateShuntAddon' : {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }

            const positiondRef = ref(action.database, SESSION_PATH + action.sessionID + SESSION_SHUNT_PATH + action.payload);
            const addonDataRef = ref(action.database, SESSION_SHUNT_PATH + action.payload + SHUNT_SHUNTDATA);
            
            if (action.position != null){
                set(positiondRef, action.position);
            }

            if (action.addonData != null) {
                update(addonDataRef, {
                    isAddon: true,
                    AddonData: action.addonData,
                });
            }
            
            return { val: data }
        }
        case 'removeShuntAddon' : {
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }
            
            if (action?.removeFromDB === true && action.payload != null && data.sessionID != null && action.shuntID != null && action.addonType){
                const promises = [];
                const sessionRef = ref(action.database, SESSION_PATH + data.sessionID + SESSION_SHUNT_PATH + action.payload);
                const addonRef = ref(action.database, SESSION_PATH + data.sessionID + SESSION_SHUNT_ADDONS_PATH + action.payload);
                const shuntAddonRef = ref(action.database, SESSION_SHUNT_PATH + action.payload);
                const shuntRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_ADDONS_PATH + action.addonType);

                promises.push(remove(sessionRef));
                promises.push(remove(addonRef));
                promises.push(remove(shuntAddonRef));
                promises.push(remove(shuntRef));

                Promise.all(promises).then(() => {
                    console.log("Updated!");
                });
            }

            //console.log("AddonData: ", data['Addons'])
            if (data['Addons'] != null) {
                const addons = data['Addons'];

                if (addons[action.addonType] != null){
                    delete addons[action.addonType];
                }

                sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            }
            
            return { val: data }
        }
        case 'setDataFromSnapShot': {
            let data = {};

            data[ADJVALVE_KEY] = action.payload.child(ADJVALVE_KEY).val() || undefined;
            data[CONTROLVALVE_KEY] = action.payload.child(CONTROLVALVE_KEY).val() || undefined;
            if (action.payload.child(NUM_BV_KEY).exists()){
                data[NUM_BV_KEY] = action.payload.child(NUM_BV_KEY).val();
            }
            if (action.payload.child(NUM_IV_KEY).exists()){
                data[NUM_IV_KEY] = action.payload.child(NUM_IV_KEY).val();
            }
            if (action.payload.child(HAS_LATCH_KEY).exists()){
                data[HAS_LATCH_KEY] = action.payload.child(HAS_LATCH_KEY).val();
            }
            if (action.payload.child(HAS_PREV_LATCH_KEY).exists()){
                data[HAS_PREV_LATCH_KEY] = action.payload.child(HAS_PREV_LATCH_KEY).val();
            }
            if (action.payload.child(IS_SABO_KEY).exists()){
                data[IS_SABO_KEY] = action.payload.child(IS_SABO_KEY).val();
            }
            if (action.payload.child(MINI_INCOMPATIBLE_KEY).exists()){
                data[MINI_INCOMPATIBLE_KEY] = action.payload.child(MINI_INCOMPATIBLE_KEY).val();
            }
            if (action.payload.child(MAGICLOUD_LINK_KEY).exists()){
                data[MAGICLOUD_LINK_KEY] = action.payload.child(MAGICLOUD_LINK_KEY).val();
            }
            
            
            data[CV_NAME_KEY] = action.payload.child(CV_NAME_KEY).val() || undefined;
            data[CV_MODEL_KEY] = action.payload.child(CV_MODEL_KEY).val() || undefined;
            data[CV_KVS_KEY] = action.payload.child(CV_KVS_KEY).val() || undefined;
            data[CV_IS_INTERVAL_KEY] = action.payload.child(CV_IS_INTERVAL_KEY).val() || undefined;
            data[FLEX_BRAND_KEY] = action.payload.child(FLEX_BRAND_KEY).val() || undefined;
            data[FLEX_MODEL_KEY] = action.payload.child(FLEX_MODEL_KEY).val() || undefined;
            data[FLEX_DIM_ID_KEY] = action.payload.child(FLEX_DIM_ID_KEY).val() || undefined;
            data[SMART_MODEL_KEY] = action.payload.child(SMART_MODEL_KEY).val() || undefined;
            data[SMART_DIM_ID_KEY] = action.payload.child(SMART_DIM_ID_KEY).val() || undefined;
            data[SMART_DIM_KEY] = action.payload.child(SMART_DIM_KEY).val() || undefined;
            data[SHUNT_STRING_KEY] = action.payload.child(SHUNT_STRING_KEY).val() || undefined;
            data[PL_MODEL_KEY] = action.payload.child(PL_MODEL_KEY).val() || undefined;
            data[IS_MINI_KEY] = action.payload.child(IS_MINI_KEY).val() || undefined;
            data[MC_FUNCTION_KEY] = action.payload.child(MC_FUNCTION_KEY).val() || undefined;
            data[IS_CUSTOM_KEY] = action.payload.child(IS_CUSTOM_KEY).val() || false;
            data[EXCHANGER_KEY] = action.payload.child(EXCHANGER_KEY).val() || undefined;
            data[NUM_CONN_KEY] = action.payload.child(NUM_CONN_KEY).val() || 4;
            data[ADDONS_KEY] = action.payload.child(ADDONS_KEY).val() || undefined;

            data[CONNECTED_ENERGY_MODEL_KEY] = action.payload.child(CONNECTED_ENERGY_MODEL_KEY).val() || undefined;
            data[CONNECTED_ENERGY_COMM_MODAL_KEY] = action.payload.child(CONNECTED_ENERGY_COMM_MODAL_KEY).val() || undefined;

            data[IS_ADDON_KEY] = action.payload.child(IS_ADDON_KEY).val() || undefined;
            data[ADDON_TYPE_KEY] = action.payload.child(ADDON_TYPE_KEY).val() || undefined;
            data[ADDON_DATA_KEY]= action.payload.child(ADDON_DATA_KEY).val() || undefined;
            //console.log("Loading Data:", data);
            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'clear': {
            //console.log("Clear")
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }
            sessionStorage.removeItem(SHUNT_PARAMS);

            //Keep Position and Designation
            let newData = {}
            newData[POSITION_KEY] = data.position;
            newData[DESIGNATION_KEY] = data.designation;

            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(newData));
            return { val: newData }
        }
        case 'clearAPI': {
            //console.log("Clear")
            let data = {};
            if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
            }
            sessionStorage.removeItem(SHUNT_PARAMS);

            //Keep Position and Designation
            let newData = {}
            newData[POSITION_KEY] = data.position;

            sessionStorage.setItem(SHUNT_PARAMS, JSON.stringify(newData));
            return { val: newData }
        }
        case 'fullClear': {
            //console.log("Full Clear")
            sessionStorage.removeItem(SHUNT_PARAMS);

            return { val: {} }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function ShuntParamsProvider({ children }) {
    let obj = {};
    if (sessionStorage.getItem(SHUNT_PARAMS) != null) {
        obj = JSON.parse(sessionStorage.getItem(SHUNT_PARAMS));
    }

    const [state, dispatch] = useReducer(shuntParamsReducer, { val: obj })
    const value = { state, dispatch }
    return (
        <ShuntParams.Provider value={value}>
            {children}
        </ShuntParams.Provider>);
}

function useShuntParams() {
    const context = useContext(ShuntParams)
    if (context === undefined) {
        throw new Error('useShuntParams must be used within a ShuntParams')
    }
    return context
}


export { ShuntParamsProvider, useShuntParams }