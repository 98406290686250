import { useState } from 'react';
import Logo from '../../../assets/images/logos/logo.png'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { IconButton, Modal, Box } from '@mui/material';
import IntroductionAccordion from './accordionIntroductionModal'


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '700px',
    width: '50%',
    bgcolor: "#E1E2E4",
    border: '4px solid #fff',
    boxShadow: 24,
    borderRadius: 4,
    p: 4,
    overflow: 'auto',
};

export default function IntroductionModal() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    return (
        <>
            <span onClick={handleOpen} style={{ fontWeight: 'bold', cursor: 'pointer', textDecoration: 'underline' }}>introduktionsanvisning</span>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <img src={Logo} alt="logo" id="logo"></img>
                    <IconButton style={{ float: 'right', cursor: 'pointer', marginTop: '-20px', marginRight: '-25px', marginBottom: '5px', }} onClick={handleClose}> <CloseRoundedIcon /></IconButton>
                    <br></br>
                    <h3 className="header-title" style={{ color: '#183851' }}>ShuntLogik - Introduktionsanvisning</h3>
                    <IntroductionAccordion />
                </Box>
            </Modal>
        </>

    );
}