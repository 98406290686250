import { useState } from "react"
import { ShareSession } from "../overview/shareSession";
import { roundNumber } from "../../../functions/ShuntCalculations/helpFunctions/roundTo4Decimals";

/**
 * Makes it so that the error message starts with an clickable link to send a message to PREMA.
 * Starts the message with the text "Kontakta PREMA" as default
 * @param {Object} param0 
 * @param {String} param0.reasonText
 * @returns 
 */
export const ContactPremaModal = ({ reasonText }) => {
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    }

    return (
        <div>
            <span onClick={handleOpenModal} style={{ cursor: 'pointer', fontWeight: 700, textDecoration: 'underline', marginRight: '3px' }}>Kontakta PREMA</span>
            <span> {reasonText}</span>

            <ShareSession open={openModal} handleOnDecline={handleCloseModal} premaOnly />
        </div>
    )
}

export const ContactPremaModalConnectedPrimFlowToHigh = ({ value }) => {
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    }

    return (
        <div>
            <span onClick={handleOpenModal} style={{ cursor: 'pointer', fontWeight: 700, textDecoration: 'underline', marginRight: '3px' }}>Kontakta PREMA</span>
            <span> vid energimätning i shuntgrupper med primärt flöde över {roundNumber(value)} l/s, eller gör om beräkningen utan <strong>Connected™ Energi</strong> som tillval</span>

            <ShareSession open={openModal} handleOnDecline={handleCloseModal} premaOnly />
        </div>
    )
}