import { Stack, TextField } from "@mui/material"
import { Fragment, useEffect, useState } from "react";
import { useDatabase } from "reactfire";
import { ErrorMsg } from "../../../../constants/errorMessages";
import { NoticeMsg } from "../../../../constants/noticeMessages";
import { useErrorContext, useNoticeContext } from "../../../../contexts";
import { useSessionParams } from "../../../../contexts/SessionParams";
import { useShuntParams } from "../../../../contexts/ShuntParams";
import { ErrorEnum } from "../../../../enums/errorEnum";
import { NoticeEnum } from "../../../../enums/noticeEnum";
import { SessionParamsEnum } from "../../../../enums/sessionParamsEnum";
import { ShuntParamsEnum } from "../../../../enums/shuntParamsEnum";
import { checkIfDesignationExists, checkIfPosExists } from "../../../../functions/ShuntCalculations/helpFunctions/checkIfPosAndDesignationExists";
import { resetMaxStepFunc } from "../../../../functions/HelperFunctions/resetMaxStep";

export const ContextTextfield = ({ enumValue, context, labelText, isDisabled = false, end = null, className = "", isNumber = false }) => {
    const ErrorList = new ErrorEnum();
    const NoticeList = new NoticeEnum();
    const errorContext = useErrorContext();
    const noticeContext = useNoticeContext();
    const database = useDatabase();
    const sessionParams = useSessionParams();
    const shuntID = SessionParamsEnum.CurrentShunt.getContextValue(sessionParams);
    const sessionID = SessionParamsEnum.SessionID.getContextValue(sessionParams);
    const shuntParams = useShuntParams();

    const [exists, setExists] = useState(false);

    const setInitialValue = () => {
        if (enumValue === ShuntParamsEnum.ShuntPosition && enumValue.getContextValue(shuntParams) == null) {
            //ShuntParamsEnum.ShuntPosition.setContextValue(context, '1', database, shuntID, sessionID);
            return '1';
        }
        else {
            enumValue.getContextValue(context);
        }
    }

    const [value, setValue] = useState(setInitialValue);
    const [firstUpdate, setFirstUpdate] = useState(true);

    useEffect(() => {
        setFirstUpdate(true);
    }, [shuntID]);

    useEffect(() => {
        if (enumValue.getContextValue(context) != null && firstUpdate === true) {
            setValue(enumValue.getContextValue(context));
            setFirstUpdate(false);
        }

        if (ShuntParamsEnum.ShuntDesignation.getContextValue(shuntParams) != null && ShuntParamsEnum.ShuntDesignation.getContextValue(shuntParams) !== "") {
            if (ErrorList.hasErrors(errorContext)) {
                if (ErrorList.getErrorList(errorContext)[ErrorMsg.params_noShuntDesignation.key] != null) {
                    ErrorList.clearError(errorContext, ErrorMsg.params_noShuntDesignation.key);
                }
            }
        }

    }, [enumValue.getContextValue(context)])

    useEffect(() => {
        if (enumValue === ShuntParamsEnum.ShuntPosition && ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) != null) {

            checkIfPosExists(ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams), database, sessionParams).then((res) => {
                setExists(res);

                if (res === true) {
                    console.error(ErrorMsg.params_shuntPosAlreadyExists.msg);
                    ErrorList.addError(errorContext, ErrorMsg.params_shuntPosAlreadyExists.key, ErrorMsg.params_shuntPosAlreadyExists.msg);
                }
                else {
                    ErrorList.clearError(errorContext, ErrorMsg.params_shuntPosAlreadyExists.key);
                }
            })
        }
    }, [ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams)])

    useEffect(() => {
        if (enumValue === ShuntParamsEnum.ShuntDesignation && ShuntParamsEnum.ShuntDesignation.getContextValue(shuntParams) != null) {
            checkIfDesignationExists(ShuntParamsEnum.ShuntDesignation.getContextValue(shuntParams), database, sessionParams).then((res) => {
                if (res === true) {
                    //console.log(NoticeMsg.param_designationExists.msg);
                    NoticeList.addNotice(noticeContext, NoticeMsg.param_designationExists.key, NoticeMsg.param_designationExists.msg);
                }
                else {
                    NoticeList.clearNotice(noticeContext, NoticeMsg.param_designationExists.key);
                }
            })
        }
    }, [ShuntParamsEnum.ShuntDesignation.getContextValue(shuntParams)])

    const setValueToContext = (value) => {
        //console.log("Val: ", value)
        resetMaxStepFunc(sessionParams, database);
        /*
        if (SessionParamsEnum.MaxStep.getContextValue(sessionParams) !== SessionParamsEnum.CurrentStep.getContextValue(sessionParams)) {
            SessionParamsEnum.MaxStep.setContextValue(sessionParams, SessionParamsEnum.CurrentStep.getContextValue(sessionParams));
        }*/
        setValue(value);
        enumValue.setContextValue(context, value, database, shuntID, sessionID);
    }

    const style = {
        'input': {
            '&::placeholder': {

                color: 'red'
            },
        }
    };


    return (
        <Stack direction={"row"} justifyContent="space-evenly">
            <div style={{ marginTop: "19px" }}>
                {labelText}:
            </div>

            {end != null ? (
                <>
                    <TextField
                        style={{ marginLeft: "auto", width: "37%", height: "10px" }}
                        size={"small"}
                        value={value || ''}
                        type={isNumber ? ("number") : ("text")}
                        margin="normal"
                        fullWidth
                        onChange={(event) => { setValueToContext(event.target.value) }}
                        onBlur={(event) => { setValueToContext(event.target.value) }}
                        error={exists}
                        disabled={isDisabled}
                    />

                    <div className={className} style={{ marginTop: "24px", marginLeft: '5%', float: "right" }}>
                        <Stack>{end}</Stack>
                    </div></>

            ) : labelText === "Beteckning" ? (
                <TextField
                    style={{ marginLeft: "auto", width: "50%", height: "10px", marginBottom: "0px" }}
                    size={"small"}
                    value={value || ''}
                    type={isNumber ? ("number") : ("text")}
                    margin="normal"
                    fullWidth
                    placeholder={"Ange beteckning.."}
                    onChange={(event) => { setValueToContext(event.target.value) }}
                    onBlur={(event) => { setValueToContext(event.target.value) }}
                    disabled={isDisabled}
                    error={exists}
                    sx={style}
                />
            ) : (
                <TextField
                    className="textfieldClass"
                    style={{ marginLeft: "auto", width: "50%", height: "10px", marginBottom: "18px" }}
                    size={"small"}
                    value={value || ''}
                    type={isNumber ? ("number") : ("text")}
                    margin="normal"
                    fullWidth
                    onKeyDown={(evt) => (/^[-a-ö,.A-Ö]?$/).test(evt.key) && evt.preventDefault()}
                    onChange={(event) =>
                        Number(event.target.value) < 0
                            ? (setValueToContext('0'))
                            : setValueToContext(event.target.value)
                    }
                    onBlur={(event) => { setValueToContext(event.target.value) }}
                    error={exists}
                    disabled={isDisabled}
                />)}
        </Stack>
    );
}