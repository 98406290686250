import { Start } from './Start'

const Home = () => {
    return (
        <>
            <br />
            <div style={{
                margin: "0px 0px 0px 0px",
                width: "96.3%",
                borderRadius: "0px",
                boxShadow: "inset 1px 1px 10px #91919140 ",
                padding: "20px",
                justifyContent: 'center',
                backgroundColor: '#E1E2E4',
            }}>
                <Start />
            </div>
        </>
    )
}

export default Home