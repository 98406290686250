import { Autocomplete, Stack, TextField } from "@mui/material"
import { useState } from "react";
import { PUMP_GREEN_II_DEFAULT } from "../../../../constants/pumpOptions";
import { usePumpParams } from "../../../../contexts/PumpParams";
import { useSessionParams } from "../../../../contexts/SessionParams";
import { PumpParamEnum } from "../../../../enums/pumpParamsEnum";
import { SessionParamsEnum } from "../../../../enums/sessionParamsEnum";
import { resetMaxStepFunc } from "../../../../functions/HelperFunctions/resetMaxStep";
import { useDatabase } from "reactfire";

export const PumpTextfieldGreen = ({ isDisabled = false }) => {
    const database = useDatabase();
    const pumpParams = usePumpParams();
    const sessionParams = useSessionParams();

    /**
     * 
     * @returns {Array} data
     */
    const getSavedOptions = () => {
        let data = [];
        if (localStorage.getItem('pumpSearchesGreen') != null) {
            data = JSON.parse(localStorage.getItem('pumpSearchesGreen'));
        }

        return data;
    }

    const [options, setOptions] = useState(getSavedOptions());

    const getPumpParams = () => {
        if (PumpParamEnum.SpecificPumpRequest.getContextValue(pumpParams) != null) {
            const pumpVal = PumpParamEnum.SpecificPumpRequest.getContextValue(pumpParams);

            let optionVal = String(pumpVal).replace(PUMP_GREEN_II_DEFAULT, '').trim();

            if (optionVal !== "") {
                if (options.findIndex(obj => obj === optionVal) === -1) {
                    let local = options;
                    local.push(optionVal);
                    localStorage.setItem("pumpSearchesGreen", JSON.stringify(local));
                }
                return optionVal;
            }
            else{
                return '';
            }

        }
        else {
            return '';
        }
    }

    const [pumpvalue, setValue] = useState(getPumpParams());


    const setPumpParams = (value, setContext) => {
        resetMaxStepFunc(sessionParams, database);
        /*
        if (SessionParamsEnum.MaxStep.getContextValue(sessionParams) !== SessionParamsEnum.CurrentStep.getContextValue(sessionParams)) {
            SessionParamsEnum.MaxStep.setContextValue(sessionParams, SessionParamsEnum.CurrentStep.getContextValue(sessionParams));
        }*/
        //console.log("Text:", value)

        if (setContext === true) {
            if (value != null && value !== "" && options.findIndex(obj => obj === value) === -1) {
                let local = options;
                local.push(value);
                localStorage.setItem("pumpSearchesGreen", JSON.stringify(local));
                setOptions(local);
            }

            PumpParamEnum.SpecificPumpRequest.setContextValue(pumpParams, PUMP_GREEN_II_DEFAULT + ' ' + value);
        }

        setValue(value)
    }

    return (
        <Stack direction={"column"} justifyContent="space-evenly" spacing={1} sx={{marginTop: '5px', marginBottom: '30px'}}>
            {/* <div style={{ marginTop: "5px", }}>
                {labelText}:
            </div> */}
            <>
                <Autocomplete
                    freeSolo
                    options={options}
                    disabled={isDisabled}
                    defaultValue={options.find(obj => obj === pumpvalue)}
                    onChange={(event, value) => { setPumpParams(value, true) }}

                    renderInput={(params) => <TextField
                        {...params}
                        style={{ width: "95%", height: "10px", marginTop: '5px' }}
                        size={"small"}
                        value={pumpvalue}
                        margin="normal"
                        onBlur={(event) => { setPumpParams(event.target.value, true) }}
                        fullWidth
                        variant="outlined"
                        disabled={isDisabled}
                        label={"Detaljer:"}
                    />}
                />
            </>
        </Stack>
    );
}