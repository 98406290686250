export function isDevUser(userID) {
    if (userID === "ewuXsS55AjPjWv7ar3Rs07cVJCg1" ||
        userID === "5hd90Qn4SobLtdVSdKyFpv3Gs9b2" ||
        userID === "Tvs13ZDXSFTr4G7lii3njUIkK1A3") {
        return true;
    }
    else{
        return false;
    }
}

export function isDevF2User(userID) {
    if (userID === "ewuXsS55AjPjWv7ar3Rs07cVJCg1") {
        return true;
    }
    else{
        return false;
    }
}