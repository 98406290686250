//import { useState } from 'react';
import { Card, CardMedia, CardContent, Typography, Grid, List, ListItem, ListItemText, ListItemIcon, Tooltip, Divider } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { useProductLineParams, } from "../../../../contexts"
import greenDrop from '../../../../assets/images/green_drop.svg'
import divider from '../../../../assets/images/divider.svg'
import { PRODUCTLINE_FLEX, PRODUCTLINE_GREEN, PRODUCTLINE_SMART, PRODUCTLINE_STANDARD, PRODUCTPRINCIP_VA1 } from '../../../../constants/productlineConstants';
import { ProductLineEnum } from '../../../../enums';

export const ConnectionCard = ({ connectionObj, isSelected, isPrevious }) => {
    const imageRef = connectionObj.imageRef;
    const productLine = useProductLineParams();
    const prodLine = ProductLineEnum.ProductLine.getContextValue(productLine);

    const setStyle = () => {
        if (isSelected) {
            return {
                marginTop: "8px",
                marginBottom: "8px",
                border: "2px solid #0055a0",
                backgroundColor: "white",
                boxShadow: "2px 2px 10px #0055a030",
                borderRadius: 0,
                //padding: '10px',
            }
        }
        else if (isPrevious) {
            return {
                marginTop: "8px",
                marginBottom: "8px",
                border: "2px dotted #439539",
                backgroundColor: "white",
                boxShadow: "2px 2px 10px #0055a030",
                borderRadius: 0,
                //padding: '10px',
            }
        }
        else {
            return {
                marginTop: "8px",
                marginBottom: "8px",
                border: "2px solid transparent",
                backgroundColor: "white",
                boxShadow: "2px 2px 10px #0055a030",
                borderRadius: 0,
            }
        }
    }

    return (
        <Card sx={setStyle} >
            <Grid container spacing={8} >
                <Grid item xs={3}>
                    <CardMedia
                        style={{ width: '309px', height: 'auto', marginLeft: '-20px', borderRadius: 0 }}
                        component="img"
                        image={imageRef}
                        alt={connectionObj.name}
                    />
                </Grid>

                <Grid item xs={9}>
                    <CardContent sx={{ backgroundColor: '#FFF', height: 'auto', padding: '10px 0px 10px 10px', width: 'maxWidth' }}>
                        <Typography variant="h3">
                            <span style={{ color: '#0053A1' }}><strong>{connectionObj.name}</strong></span> | {connectionObj.header.toUpperCase()}
                            {prodLine === PRODUCTLINE_GREEN &&
                                <>
                                    {connectionObj.id !== PRODUCTPRINCIP_VA1 ? (
                                        <span style={{ float: 'right', marginRight: '10px' }}>
                                            <Tooltip title={'Premablock® green I'}>
                                                <img src={greenDrop} alt="green drop" style={{ width: '10px' }} />
                                            </Tooltip>
                                            <img src={divider} alt="divider" style={{ height: '17px', width: '4px', marginLeft: '2px', marginRight: '2px', marginBottom: '-1px' }} />
                                            <Tooltip title={'Premablock® green II'}>
                                                <span>
                                                    <img src={greenDrop} alt="green drop" style={{ width: '10px' }} />
                                                    <img src={greenDrop} alt="green drop" style={{ width: '10px', marginLeft: '2px' }} />
                                                </span>
                                            </Tooltip>
                                        </span>
                                    ) : (
                                        <span style={{ float: 'right', marginRight: '10px' }}>
                                            <Tooltip title={'Premablock® green I'}>
                                                <img src={greenDrop} alt="green drop" style={{ width: '10px' }} />
                                            </Tooltip>
                                        </span>
                                    )}
                                </>
                            }
                        </Typography>
                        <Typography variant="body1" component="p" >
                            {connectionObj.primText}
                        </Typography>


                        <Grid container rowSpacing={1} spacing={1}>
                            <Grid item xs={5}>
                                <List className="con-type-list" dense={true}>
                                    {connectionObj.firstListText != null &&
                                        <ListItem disablePadding={true}>
                                            <ListItemIcon>
                                                <CheckRoundedIcon className="check-icon" />
                                            </ListItemIcon>
                                            <ListItemText
                                                className="con-type-list-item"
                                                secondary={connectionObj.firstListText}
                                            />
                                        </ListItem>
                                    }
                                    {connectionObj.secondListText != null &&
                                        <ListItem disablePadding={true}>
                                            <ListItemIcon>
                                                <CheckRoundedIcon className="check-icon" />
                                            </ListItemIcon>
                                            <ListItemText
                                                className="con-type-list-item"
                                                secondary={connectionObj.secondListText}
                                            />
                                        </ListItem>
                                    }
                                    {connectionObj.thirdListText != null &&
                                        <ListItem disablePadding={true}>
                                            <ListItemIcon>
                                                <CheckRoundedIcon className="check-icon" />
                                            </ListItemIcon>
                                            <ListItemText
                                                className="con-type-list-item"
                                                secondary={connectionObj.thirdListText}
                                            />
                                        </ListItem>
                                    }

                                </List>
                                {/* <CheckRoundedIcon className="check-icon" /> {connectionObj.firstListText}
                                <br></br>
                                <CheckRoundedIcon className="check-icon" /> {connectionObj.secondListText} */}

                            </Grid>

                            <Grid item xs={7}>
                                <List className="con-type-list" dense={true}>
                                    {connectionObj.fifthListText != null &&
                                        <ListItem disablePadding={true}>
                                            <ListItemIcon>
                                                <CheckRoundedIcon className="check-icon" />
                                            </ListItemIcon>
                                            <ListItemText
                                                className="con-type-list-item"
                                                secondary={connectionObj.fourthListText}
                                            />
                                        </ListItem>
                                    }
                                    {connectionObj.fifthListText != null &&
                                        <ListItem disablePadding={true}>
                                            <ListItemIcon>

                                                <CheckRoundedIcon className="check-icon" />
                                            </ListItemIcon>
                                            <ListItemText
                                                className="con-type-list-item"
                                                secondary={connectionObj.fifthListText}
                                            />
                                        </ListItem>
                                    }
                                    {(connectionObj.sixthListText != null) && (prodLine !== PRODUCTLINE_GREEN) &&
                                        <ListItem disablePadding={true}>
                                            <ListItemIcon>
                                                <CheckRoundedIcon className={(prodLine === PRODUCTLINE_FLEX && "check-icon-flex") || (prodLine === PRODUCTLINE_SMART && "check-icon-flex") || (prodLine === PRODUCTLINE_STANDARD && "check-icon")} />
                                            </ListItemIcon>
                                            <ListItemText
                                                className="con-type-list-item"
                                                secondary={connectionObj.sixthListText}
                                            />
                                        </ListItem>
                                    }

                                    {connectionObj.seventhListText != null &&
                                        <ListItem disablePadding={true}>
                                            <ListItemIcon>
                                                <CheckRoundedIcon className={(prodLine === PRODUCTLINE_GREEN && "check-icon-green") || (prodLine === PRODUCTLINE_FLEX && "check-icon-flex") || (prodLine === PRODUCTLINE_SMART && "check-icon-flex") || (prodLine === PRODUCTLINE_STANDARD && "check-icon")} />
                                            </ListItemIcon>
                                            <ListItemText
                                                className="con-type-list-item"
                                                secondary={connectionObj.seventhListText}
                                            />
                                        </ListItem>
                                    }
                                    {connectionObj.eightListText != null &&
                                        <ListItem disablePadding={true}>
                                            <ListItemIcon>
                                                <CheckRoundedIcon className={(prodLine === PRODUCTLINE_GREEN && "check-icon-green") || (prodLine === PRODUCTLINE_FLEX && "check-icon-flex") || (prodLine === PRODUCTLINE_SMART && "check-icon-flex") || (prodLine === PRODUCTLINE_STANDARD && "check-icon")} />
                                            </ListItemIcon>
                                            <ListItemText
                                                className="con-type-list-item"
                                                secondary={connectionObj.eightListText}
                                            />
                                        </ListItem>
                                    }
                                </List>
                            </Grid>

                        </Grid>
                    </CardContent>
                </Grid>
            </Grid >
        </Card >
    );
}