import { Paper, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Tooltip } from "@mui/material"
import { ADJUSTVALUE_STAD, ADJUSTVALUE_STAD_ZERO } from "../../../../../constants/adjustmentsOptions"
import { AdjustmentsEnum } from "../../../../../enums"

import greenDrop from '../../../../../assets/images/green_drop.svg'

export const AdjustCardAdjustValve = ({ adjustValue, setAdjustParams, isDisabled }) => {

    return (
        <div>
            <Paper elevation={0} className="adjust-card">
                <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group">Injusteringsventil</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="adjustValve"
                        value={adjustValue}
                        onChange={(event) => { setAdjustParams(event.target.value, AdjustmentsEnum.AdjustValue) }}
                    >
                        <FormControlLabel value={ADJUSTVALUE_STAD} control={<Radio />} label="STAD" disabled={isDisabled()} />
                        <FormControlLabel
                            value={ADJUSTVALUE_STAD_ZERO}
                            control={<Radio />}
                            label={
                                <>
                                    STAD ZERO
                                    <Tooltip title="Miljövänligare val">
                                        <img src={greenDrop} alt="green drop" style={{ width: '7px', marginLeft: '5px' }} /></Tooltip>
                                </>
                            }
                            disabled={isDisabled()}
                        />

                    </RadioGroup>
                </FormControl>
            </Paper>
        </div>
    )
}