import { Database, get, ref } from "firebase/database";
import { SESSION_SHUNT_PATH, SHUNT_SPECLISTDATA } from "../../constants/firebasePaths";

/**
 * 
 * @param {Database} database 
 * @param {string} shuntID 
 */
const getShuntSpecList = async function getShuntSpecList(database, shuntID) {
    const specRef = ref(database, SESSION_SHUNT_PATH + shuntID + SHUNT_SPECLISTDATA);


    const result = await get(specRef);

    return result.val();
}

export { getShuntSpecList }