import { createContext, useReducer, useContext } from "react";
import { CHECKED_COVER, CHECKED_TERMS, DISPLAY_OPTION, INCLUDE_MAGICLOUD_LINK, NEW_PAGE_PER_POSITION, OFFERT_MODE, SHOW_ID } from "../constants/keys/modifySpecKeys";
import { MODIFYSPEC_PARAMS } from "../constants/sessionStoragePaths";


const ModifySpecContext = createContext(undefined);

function modifySpecReducer(state, action) {
    switch (action.type) {

        case 'setShowID': {
            let data = {};
            if (sessionStorage.getItem(MODIFYSPEC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(MODIFYSPEC_PARAMS));
            }

            data[SHOW_ID] = action.payload;
            sessionStorage.setItem(MODIFYSPEC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setCheckedTerms': {
            let data = {};
            if (sessionStorage.getItem(MODIFYSPEC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(MODIFYSPEC_PARAMS));
            }

            data[CHECKED_TERMS] = action.payload;
            sessionStorage.setItem(MODIFYSPEC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setCheckedCover': {
            let data = {};
            if (sessionStorage.getItem(MODIFYSPEC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(MODIFYSPEC_PARAMS));
            }

            data[CHECKED_COVER] = action.payload;
            sessionStorage.setItem(MODIFYSPEC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setOffertMode': {
            let data = {};
            if (sessionStorage.getItem(MODIFYSPEC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(MODIFYSPEC_PARAMS));
            }

            data[OFFERT_MODE] = action.payload;
            sessionStorage.setItem(MODIFYSPEC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setNewPagePerPosition': {
            let data = {};
            if (sessionStorage.getItem(MODIFYSPEC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(MODIFYSPEC_PARAMS));
            }

            data[NEW_PAGE_PER_POSITION] = action.payload;
            sessionStorage.setItem(MODIFYSPEC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setDisplayOption': {
            let data = {};
            if (sessionStorage.getItem(MODIFYSPEC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(MODIFYSPEC_PARAMS));
            }

            data[DISPLAY_OPTION] = action.payload;
            sessionStorage.setItem(MODIFYSPEC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setIncludeMagicloudLink': {
            let data = {};
            if (sessionStorage.getItem(MODIFYSPEC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(MODIFYSPEC_PARAMS));
            }

            data[INCLUDE_MAGICLOUD_LINK] = action.payload;
            sessionStorage.setItem(MODIFYSPEC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setSessionDataFromSnapshot': {
            let data = {};

            data[SHOW_ID] = action.payload[SHOW_ID] || undefined;
            data[CHECKED_TERMS] = action.payload[CHECKED_TERMS] || undefined;
            data[CHECKED_COVER] = action.payload[CHECKED_COVER] || undefined;
            data[OFFERT_MODE] = action.payload[OFFERT_MODE] || undefined;
            data[NEW_PAGE_PER_POSITION] = action.payload[NEW_PAGE_PER_POSITION] || undefined;
            data[DISPLAY_OPTION] = action.payload[DISPLAY_OPTION] || undefined;
            data[INCLUDE_MAGICLOUD_LINK] = action.payload[INCLUDE_MAGICLOUD_LINK] || undefined;

            sessionStorage.setItem(MODIFYSPEC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        // case 'setDataFromSnapShot': {
        //     let data = {};

        //     data['pumpRequestType'] = action.payload.child("pumpRequestType").val() || undefined;
        //     data['generalPumpRequest'] = action.payload.child("generalPumpRequest").val() || undefined;
        //     data['systemfluid'] = action.payload.child("systemfluid").val() || undefined;
        //     data['pspecificPumpRequest'] = action.payload.child("specificPumpRequest").val() || undefined;
        //     data['steering'] = action.payload.child("steering").val() || undefined;
        //     data['alarm'] = action.payload.child("alarm").val() || undefined;
        //     data['externalStop'] = action.payload.child("externalStop").val() || undefined;
        //     data['pressure'] = action.payload.child("pressure").val() || undefined;
        //     data['card'] = action.payload.child("card").val() || undefined;

        //     //console.log("Loading Data:", data);
        //     sessionStorage.setItem(MODIFYSPEC_PARAMS, JSON.stringify(data));
        //     return { val: data }
        // }
        case 'clear': {
            sessionStorage.removeItem(MODIFYSPEC_PARAMS);
            return { val: {} }
        }
        // case 'clear': {
        //     sessionStorage.removeItem(MODIFYSPEC_PARAMS);
        //     let obj = { pumpRequestType: "generalRequest", generalPumpRequest: "premasChoice" }
        //     sessionStorage.setItem(MODIFYSPEC_PARAMS, JSON.stringify(obj));

        //     return { val: obj }
        // }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function ModifyProvider({ children }) {
    let obj = {};
    if (sessionStorage.getItem(MODIFYSPEC_PARAMS) != null) {
        obj = JSON.parse(sessionStorage.getItem(MODIFYSPEC_PARAMS));
    }
    else {
        obj = { displayOption: "perShunt", }
        sessionStorage.setItem(MODIFYSPEC_PARAMS, JSON.stringify(obj));
    }



    const [state, dispatch] = useReducer(modifySpecReducer, { val: obj })
    const value = { state, dispatch }

    return (
        <ModifySpecContext.Provider value={value}>
            {children}
        </ModifySpecContext.Provider>
    );
}

function useModifySpecContext() {
    const context = useContext(ModifySpecContext)
    if (context === undefined) {
        throw new Error('useModifySpec must be used within a modifySpec')
    }
    return context
}


export { ModifyProvider, useModifySpecContext };