import { FormControl, Checkbox, Select, OutlinedInput, MenuItem, ListItemText, InputLabel, Stack, Radio, RadioGroup, FormControlLabel } from "@mui/material"
import { APP_SOURCE, QUESTION_SOURCE_FLOW_KEY, QUESTION_SOURCE_KEY } from "../../../../constants/wizardConstants"
import { useEffect, useState } from "react"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
            width: 310,
        },
    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
};

export const AppSourceValQuestion = ({ appSource, application, sourceFlow, answerQuestion, resetVal }) => {
    const [loaded, setLoaded] = useState(false);
    const [values, setValues] = useState([]);
    const [valueNames, setValueNames] = useState([]);


    useEffect(() => {
        if (resetVal === true) {
            console.log("Loaded: ", resetVal)
            setLoaded(false);
        }
    }, [resetVal]);

    useEffect(() => {
        if (loaded === false) {
            setLoaded(true);
            let localVal = [];
            let localValNames = [];

            Object.keys(appSource).forEach((key) => {
                let val = appSource[key];
                //console.log("Key: ", key, "Val: ", val)
                if (val === true) {
                    localVal.push(key);
                    localValNames.push(APP_SOURCE[key].label);
                }
            });

            setValues(localVal);
            setValueNames(localValNames);
        }
    }, [appSource, loaded]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;

        let localValuesNames = [];
        let valueIDs = [];
        if (value === 'string') {
            valueIDs = value.split(',');
        }
        else {
            //@ts-ignore
            valueIDs = value;
        }

        for (let index = 0; index < valueIDs.length; index++) {
            const sourceID = valueIDs[index];

            if (APP_SOURCE[sourceID] != null) {
                localValuesNames.push(APP_SOURCE[sourceID].label);
            }
        }


        setValues(
            typeof value === 'string' ? value.split(',') : value,
        );

        setValueNames(localValuesNames);
    };

    const handleChangeFlow = (event) => {
        answerQuestion(QUESTION_SOURCE_FLOW_KEY, event.target.value);
    }

    useEffect(() => {
        Object.keys(appSource).forEach((key) => {
            let val = appSource[key];
            if (values.indexOf(key) > -1) {
                if (val !== true) {
                    answerQuestion(QUESTION_SOURCE_KEY, key);
                }
            }
            else {
                if (val === true) {
                    answerQuestion(QUESTION_SOURCE_KEY, key);
                }
            }
        });
    }, [values])

    /**
     * 
     * @param {string[]} sourceNonCompatible 
     * @param {string[]} appNonCompatible 
     */
    const checkIfDisabled = (sourceNonCompatible, appNonCompatible) => {
        let disabled = false;

        if (appNonCompatible != null) {
            for (let index = 0; index < appNonCompatible.length; index++) {
                const appVal = appNonCompatible[index];

                if (application === appVal) {
                    disabled = true;
                    break;
                }
            }
        }


        if (disabled) return disabled;

        if (sourceNonCompatible != null) {
            for (let index = 0; index < sourceNonCompatible.length; index++) {
                const sourceVal = sourceNonCompatible[index];

                if (appSource[sourceVal] === true) {
                    disabled = true;
                    break;
                }
            }
        }

        return disabled;
    }

    const setListText = (source) => {
        if (values.length === 0 || checkIfDisabled(APP_SOURCE[source]?.nonCompatible, APP_SOURCE[source]?.appNonCompatible) || values.indexOf(source) > -1) {
            return APP_SOURCE[source]?.label;
        }
        else {
            return '+ ' + APP_SOURCE[source]?.label;
        }
    }

    const displayFlowOptions = () => {
        let selected = [];
        for (const key in appSource) {
            if (Object.hasOwnProperty.call(appSource, key)) {
                const element = appSource[key];

                if (element === true) {
                    selected.push(key);
                }
            }
        }

        if (selected.length === 1) {
            if (APP_SOURCE[selected[0]]?.specifyFlow === true) {
                return true;
            }
        }

        return false;
    }

    return (
        <>
            <Stack direction={'row'} alignItems={'center'} spacing={3}>
                <FormControl>
                    <InputLabel>Källa</InputLabel>
                    <Select
                        sx={{ width: '180px', height: '30px', background: 'white' }}
                        size="small"
                        multiple
                        value={values}
                        onChange={handleChange}
                        input={<OutlinedInput label={'Källa'} />}
                        renderValue={() => valueNames.join(', ')}
                        MenuProps={MenuProps}
                    >
                        {Object.keys(APP_SOURCE).map((source) => (
                            <MenuItem disabled={checkIfDisabled(APP_SOURCE[source]?.nonCompatible, APP_SOURCE[source]?.appNonCompatible)} key={APP_SOURCE[source].value} sx={{ height: '32px' }} value={APP_SOURCE[source].value}>
                                <Checkbox checked={values.indexOf(source) > -1} />
                                <ListItemText primary={setListText(source)} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {displayFlowOptions() ? (
                    <FormControl>
                        <InputLabel shrink>Flöde primärt</InputLabel>
                        <RadioGroup
                            row
                            value={sourceFlow}
                            onChange={handleChangeFlow}
                        >
                            <FormControlLabel value="variable" control={<Radio />} label="Variabelt" />
                            <FormControlLabel value="constant" control={<Radio />} label="Konstant" />
                        </RadioGroup>
                    </FormControl>
                ) : ("")}

            </Stack>

        </>
    )
}