import { FormControlLabel, Checkbox } from "@mui/material"
import { QUESTION_DIMENSIONABLE_KEY } from "../../../../constants/wizardConstants"

export const DimensionableQuestion = ({ dimensionable, answerQuestion }) => {

    return (
        <>
            <FormControlLabel
                control={
                    <Checkbox
                        
                        checked={dimensionable}
                        onChange={() => answerQuestion(QUESTION_DIMENSIONABLE_KEY, !dimensionable)} />
                }
                label="Dimensionerbar i ShuntLogik"
            />
        </>
    );
}