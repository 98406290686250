import { useEffect, useState } from "react";
import { Alert, CircularProgress, Snackbar, styled } from "@mui/material";
import { getDownloadURL, ref } from "firebase/storage";
import { useFunctions, useStorage, useUser } from "reactfire";
import { SessionParamsEnum } from "../../../enums";
import { useSessionParams } from "../../../contexts";
import { uploadImage } from "../../../firebase/functions/cloudFunctions/uploadImage";

import { MAX_FILE_SIZE } from "../../../constants/generalConstants";

import imgPlaceholder from '../../../assets/images/placeholder.png';

const Input = styled('input')({
    display: 'none',
});

export const StorageRefSingleImage = ({ imageRef, altText, clearImgCache = false, setClearImgCache = null, updateImg = false, shuntID }) => {
    //console.log("Update inner: ", updateImg, imageRef, clearImgCache)
    const storageRef = useStorage();
    const user = useUser();
    const functions = useFunctions();
    const sessionParams = useSessionParams();

    const [openAlert, setOpenAlert] = useState(false);
    const handleOpenAlert = () => { setOpenAlert(true) }
    const handleCloseAlert = () => { setOpenAlert(false) }

    const [imageRefVal, setImageRefVal] = useState('');
    const [found, setFound] = useState(false);
    const [loading, setLoading] = useState(true);
    const [imageUrl, setImageUrl] = useState('');
    const [update, setUpdate] = useState(false);
    const [urlAddresses] = useState({});

    const [askingFB, setAskingFB] = useState(false);
    const [isInQueue, setIsInQueue] = useState(false);


    useEffect(() => {
        //console.log("Update: (Img)", imageRefVal, imageRef);

        if (clearImgCache === true && urlAddresses[imageRef] != null) {
            console.log("Clear cache")
            setClearImgCache(false);

            if (urlAddresses[imageRef] != null) {
                urlAddresses[imageRef] = null;
            }

            setImageRefVal(imageRef);
            setUpdate(!update);
        }
        else {
            setImageRefVal(imageRef);
            setUpdate(!update);
        }
    }, [imageRef, clearImgCache, updateImg])

    useEffect(() => {
        if (imageRefVal != null && imageRefVal !== '') {
            setFound(false);
            setLoading(true);
            //console.log("Start FB: ", imageRefVal)
            if (urlAddresses[imageRef] == null) {
                //console.log("Ask FB: ", imageRefVal)
                if (askingFB === false) {
                    setAskingFB(true);
                    getDownloadURL(ref(storageRef, imageRefVal)).then((imgUrl) => {
                        urlAddresses[imageRefVal] = imgUrl;
                        setImageUrl(imgUrl);
                        setFound(true);
                    }).catch((error) => {
                        setFound(false);
                        if (error.code === 'storage/object-not-found') {
                            console.log("Not found:", imageRefVal)
                        } else {
                            console.log("Error:", error)
                        }
                    }).finally(() => {
                        setLoading(false);
                        setAskingFB(false);
                    });
                }
                else {
                    console.log("Already Asking...")
                    setIsInQueue(true);
                }

            }
            else {
                //console.log("Cache: ", imageRefVal)
                setImageUrl(urlAddresses[imageRef]);
                setFound(true);
                setLoading(false);
            }
        }
    }, [imageRefVal, update]);

    useEffect(() => {
        if (askingFB === false && isInQueue === true) {
            setIsInQueue(false);
            setUpdate(!update);
        }
    }, [askingFB])

    const uploadPicture = (event) => {
        const file = event.target.files[0];
        //console.log("File: ", file)

        if (file != null) {
            console.log("Filedata: ", file.type)

            if (file.size > MAX_FILE_SIZE) {
                console.log("File to large!");
                handleOpenAlert();
            }
            else {
                setLoading(true);
                uploadImage(functions, SessionParamsEnum.SessionID.getContextValue(sessionParams), shuntID, file, file.name, file.type).then(() => {
                    //console.log("File uploaded!");
                })
                    .catch((e) => {
                        console.error("Could not upload file:", e);
                    }).finally(() => {
                        setLoading(false);
                    });
            }
        }
    }

    return (
        <>
            {loading === false ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {found === true ? (
                        <>
                            <img src={imageUrl} alt={altText} style={{ marginTop: '10px', maxWidth: '250px', maxHeight: '230px' }} />
                        </>
                    ) : (
                        <>
                            <img src={imgPlaceholder} alt={altText} style={{ width: '130px', height: '140px', border: 'solid 1px #06559e', borderRadius: '3px', marginTop: '10px' }} />
                        </>
                    )}
                </div>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </div>
            )}

            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
                    Kan ej ladda upp fil. <br />Filen är för stor.
                </Alert>
            </Snackbar>
        </>
    )
}