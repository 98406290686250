import { Box, Tab, Tabs } from "@mui/material";
import { useContextAuth } from "../../../authContext/AuthHook"
import { FavoriteSessionsComponent } from "./FavoriteSessionsComp";
import { AllSessionsComponent } from "./AllSessionsComp";
import { useEffect, useState } from "react";
import { useGetMySessions } from "../../../hooks/useGetMySessions";


export const MySessionHistoryMainComponent = ({ notInSession }) => {
    const { user } = useContextAuth();
    const [value, setValue] = useState(0)

    const { sessions } = useGetMySessions(user?.id);

    const handleChange = (event) => {
        //console.log("Value:", event.target.tabIndex);
        setValue(event.target.tabIndex)
    }

    const tabPanel = () => {
        switch (value) {
            case 0:
                return (
                    <FavoriteSessionsComponent sessions={sessions.filter((obj) => obj.starred === true)} notInSession={notInSession} />
                );
            case 1:
                return (
                    <AllSessionsComponent sessions={sessions} notInSession={notInSession} />
                );
            default:
                break;
        }
    }

    return (
        <Box>
            <Tabs value={value} onChange={handleChange}>
                <Tab label="Stjärnmärkta Sessioner" tabIndex={0} />
                <Tab label="Alla Sessioner" tabIndex={1} />
            </Tabs>

            <div>
                {tabPanel()}
            </div >
        </Box>
    )
}