import { useEffect, useState } from "react";
import { CircularProgress, Stack } from "@mui/material";
import { getDownloadURL, ref } from "firebase/storage";
import { useStorage } from "reactfire";
import { useAdjustParams, useProductLineParams, useSessionParams, useShuntParams } from "../../../contexts";
import { createShuntNomenklaturString_fromFBData, createShuntNomenklaturString_manual } from "../../../functions/ShuntCalculations/helpFunctions/createShuntNomenklaturString";
import { AdjustmentsEnum, ProductLineEnum, SessionParamsEnum, ShuntParamsEnum } from "../../../enums";

import { CONN_VALUE_L, CONN_VALUE_R } from "../../../constants/adjustmentsOptions";
import { PRODUCTPRINCIP_SR2, PRODUCTPRINCIP_SR2FLEX, PRODUCTPRINCIP_SR2SMART, PRODUCTPRINCIP_SR3, PRODUCTPRINCIP_PR2, PRODUCTPRINCIP_PR3, PRODUCTPRINCIP_VA1 } from "../../../constants/productlineConstants";
import { SHUNT_FAMILY_FOLDER } from "../../../constants/customShunts";

import imgPlaceholder from '../../../assets/images/placeholder.png';

export const StorageRefDoubleImage = ({ useCurrent = true, shuntData = null }) => {
    const storageRef = useStorage();
    const adjustParams = useAdjustParams();
    const productLineParams = useProductLineParams();
    const sessionParams = useSessionParams();
    const shuntParams = useShuntParams();

    const [leftUrlImage, setLeftUrlImage] = useState('');
    const [rightUrlImage, setRightUrlImage] = useState('');
    const [leftAltText, setLeftAltText] = useState('');
    const [rightAltText, setRightAltText] = useState('');
    const [foundLeft, setFoundLeft] = useState(false);
    const [foundRight, setFoundRight] = useState(false);
    const [loadingLeft, setLoadingLeft] = useState(true);
    const [loadingRight, setLoadingRight] = useState(true);
    const [imageUrlLeft, setImageUrlLeft] = useState('');
    const [imageUrlRight, setImageUrlRight] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [urlAddresses, setUrlAddresses] = useState({});

    const setFolderNameInit = () => {
        let plPrincip;
        if (useCurrent === true){
            plPrincip = ProductLineEnum.ProductPrincip.getContextValue(productLineParams);
        }
        else{
            plPrincip = shuntData?.PLData?.princip;
        }
        
        let plFamily;
        if (SHUNT_FAMILY_FOLDER[plPrincip] != null) {
            plFamily = String(SHUNT_FAMILY_FOLDER[plPrincip]).toLocaleLowerCase();
        }

        if (plFamily != null) {
            return String(plFamily).toLowerCase();
        }
        else {
            return null;
        }

    }

    useEffect(() => {
        if (SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true) {
            let shuntNomStringLeft = "";
            let shuntNomStringRight = "";
            if (useCurrent === true) {
                //console.log("Current")
                shuntNomStringLeft = createShuntNomenklaturString_manual(CONN_VALUE_L, productLineParams, adjustParams, shuntParams);
                shuntNomStringRight = createShuntNomenklaturString_manual(CONN_VALUE_R, productLineParams, adjustParams, shuntParams);
            }
            else {
                //console.log("Fb")
                let plPrincip = shuntData?.PLData?.princip;
                let plLine = shuntData?.PLData?.prodLine;
                let connDesign = shuntData?.AdjustData?.connectionDesign;
                let numBV = shuntData?.ShuntData?.numBV;
                let numIV = shuntData?.ShuntData?.numIV;
                let hasLatch = shuntData?.ShuntData?.hasLatch;
                let isSabo = shuntData?.ShuntData?.isSabo;
                let plFamily;
                let plNWay;

                if (plPrincip === PRODUCTPRINCIP_SR2 || plPrincip === PRODUCTPRINCIP_SR2FLEX || plPrincip === PRODUCTPRINCIP_SR2SMART) {
                    plFamily = 'SR'
                    plNWay = 2;
                }
                else if (plPrincip === PRODUCTPRINCIP_SR3) {
                    plFamily = 'SR'
                    plNWay = 3;
                }
                else if (plPrincip === PRODUCTPRINCIP_PR2) {
                    plFamily = 'PR'
                    plNWay = 2;
                }
                else if (plPrincip === PRODUCTPRINCIP_PR3) {
                    plFamily = 'PR'
                    plNWay = 3;
                }
                else if (plPrincip === PRODUCTPRINCIP_VA1) {
                    plFamily = 'VA'
                    plNWay = 1;
                }

                shuntNomStringLeft = createShuntNomenklaturString_fromFBData(CONN_VALUE_L, plFamily, plLine, plNWay, connDesign, numBV, numIV, hasLatch, isSabo);
                shuntNomStringRight = createShuntNomenklaturString_fromFBData(CONN_VALUE_R, plFamily, plLine, plNWay, connDesign, numBV, numIV, hasLatch, isSabo)
            }

            let folderName = setFolderNameInit();

            if (folderName != null && folderName !== "" && shuntNomStringLeft != null && shuntNomStringLeft !== "" && shuntNomStringRight != null && shuntNomStringRight !== "") {
                setLeftAltText(shuntNomStringLeft);
                setRightAltText(shuntNomStringRight);
                setLeftUrlImage("images/" + folderName + "/kopplingsbilder/" + shuntNomStringLeft + ".jpg");
                setRightUrlImage("images/" + folderName + "/kopplingsbilder/" + shuntNomStringRight + ".jpg");
            }
        }
    }, [SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams), ShuntParamsEnum.ShuntNomenklaturString.getContextValue(shuntParams), AdjustmentsEnum.ConnectionDesign.getContextValue(adjustParams)]);


    useEffect(() => {
        if (leftUrlImage != null && leftUrlImage !== '' && rightUrlImage != null && rightUrlImage !== '') {
            if (urlAddresses[leftUrlImage] == null) {
                setLoadingLeft(true);
                setFoundLeft(false);
                getDownloadURL(ref(storageRef, leftUrlImage)).then((imgUrl) => {
                    urlAddresses[leftUrlImage] = imgUrl;
                    setImageUrlLeft(imgUrl);
                    setFoundLeft(true);
                }).catch((error) => {
                    setFoundLeft(false);
                    if (error.code === 'storage/object-not-found') {
                        console.log("Not found (left):", leftUrlImage)
                    } else {
                        console.log("Error:", error)
                    }
                }).finally(() => {
                    setLoadingLeft(false);
                });
            }
            else {
                setImageUrlLeft(urlAddresses[leftUrlImage]);
                setFoundLeft(true);
                setLoadingLeft(false);
            }

            if (urlAddresses[rightUrlImage] == null) {
                setLoadingRight(true);
                setFoundRight(false);
                getDownloadURL(ref(storageRef, rightUrlImage)).then((imgUrl) => {
                    urlAddresses[rightUrlImage] = imgUrl;
                    setImageUrlRight(imgUrl);
                    setFoundRight(true);
                }).catch((error) => {
                    setFoundRight(false);
                    if (error.code === 'storage/object-not-found') {
                        console.log("Not found (right):", rightUrlImage)
                    } else {
                        console.log("Error:", error)
                    }
                }).finally(() => {
                    setLoadingRight(false);
                });
            }
            else {
                setImageUrlRight(urlAddresses[rightUrlImage]);
                setFoundRight(true);
                setLoadingRight(false);
            }
        }
    }, [leftUrlImage, rightUrlImage])


    return (
        <>
            {loadingLeft === false && loadingRight === false ? (
                <>
                    {foundLeft === false && foundRight === false ? (
                        <Stack direction={'column'} justifyContent="center" alignItems="center">
                            <img src={imgPlaceholder} alt={'Not found'} style={{ width: '100px', height: '100px' }} />
                        </Stack>
                    ) : (
                        <Stack direction={'column'} justifyContent="center" alignItems="center" spacing={1}>
                            {foundLeft === true ? (
                                <img src={imageUrlLeft} alt={leftAltText} style={{ maxWidth: '140px', maxHeight: '150px' }} />
                            ) : (
                                <img src={imgPlaceholder} alt={leftAltText} style={{ width: '100px', height: '100px' }} />
                            )}

                            {foundRight === true ? (
                                <img src={imageUrlRight} alt={rightAltText} style={{ maxWidth: '140px', maxHeight: '150px' }} />
                            ) : (
                                <img src={imgPlaceholder} alt={rightAltText} style={{ width: '100px', height: '100px' }} />
                            )}
                        </Stack>
                    )}
                </>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </div>
            )}
        </>
    )
}