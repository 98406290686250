import React, { createContext, useReducer, useContext } from "react";
import { ERRORLIST_PARAMS } from "../constants/sessionStoragePaths";

const ErrorContext = createContext(undefined);

function errorContextReducer(state, action) {
    switch (action.type) {
        case 'addError': {
            let data = {};
            if (sessionStorage.getItem(ERRORLIST_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(ERRORLIST_PARAMS));
            }
            const key = action.payload.key;
            if (action.payload.value != null) {
                if (action.payload.value2 != null) {
                    data[key] = { msg: action.payload.msg, val: action.payload.value, val2: action.payload.value2 };
                }
                else{
                    data[key] = { msg: action.payload.msg, val: action.payload.value };
                }
                
            }
            else {
                data[key] = action.payload.msg;
            }

            sessionStorage.setItem(ERRORLIST_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'clearError': {
            let data = {};
            if (sessionStorage.getItem(ERRORLIST_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(ERRORLIST_PARAMS));
            }

            if (data[action.payload] != null) {
                delete data[action.payload];
                sessionStorage.setItem(ERRORLIST_PARAMS, JSON.stringify(data));
            }

            return { val: data }
        }
        case 'clearErrorList': {
            sessionStorage.removeItem(ERRORLIST_PARAMS);
            return { val: {} }
        }
        case 'clear': {
            sessionStorage.removeItem(ERRORLIST_PARAMS);
            return { val: {} }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function ErrorContextProvider({ children }) {
    let obj = {};
    if (sessionStorage.getItem(ERRORLIST_PARAMS) != null) {
        obj = JSON.parse(sessionStorage.getItem(ERRORLIST_PARAMS));
    }

    const [state, dispatch] = useReducer(errorContextReducer, { val: obj })
    const value = { state, dispatch }

    return (
        <ErrorContext.Provider value={value}>
            {children}
        </ErrorContext.Provider>
    );
}

function useErrorContext() {
    const context = useContext(ErrorContext)
    if (context === undefined) {
        throw new Error('useErrorContext must be used within a ErrorContext')
    }
    return context
}


export { ErrorContextProvider, useErrorContext };