//Label-Text
export const CONN_VALUES_LABELS = {
    _: "Ej specificerat",
    r: "Högerutförande",
    l: "Vänsterutförande",
    vrd: "Lodrätt höger, prim nedåt",
    vld: "Lodrätt vänster, prim nedåt",
    vru: "Lodrätt höger, prim uppåt",
    vlu: "Lodrätt vänster, prim uppåt",
    hr: "Vågrätt höger, prim åt höger",
    hl: "Vågrätt vänster, prim åt vänster",
    ur: "Höger, prim uppåt, sek åt höger",
    ul: "Vänster, prim uppåt, sek åt vänster",
    dr: "Höger, prim nedåt, sek åt höger",
    dl: "Vänster, prim nedåt, sek åt vänster",
    ru: "Höger, prim åt höger, sek uppåt",
    lu: "Vänster, prim åt vänster, sek uppåt",
    rd: "Höger, prim åt höger, sek nedåt",
    ld: "Vänster, prim åt vänster, sek nedåt",
}

export const CONN_LATCH_HEAT_LABEL= ", med värmespärr";
export const CONN_LATCH_COOL_LABEL= ", med kylspärr";

export const CUTOFF_LABELS = {
    '3601/2301': "Avstängningsventiler Armatec 3601/2301",
    '3701/2301': "Avstängningsventiler Armatec 3701/2301",
    '3601/2310': "Avstängningsventiler Armatec 3601/2310",
    'genericCutOff': "Avstängningsventiler standard",
    'genericLowLeadCutOff': "Avstängningsventiler lågblyiga",
    'BA107S/BA115TE': "Avstängningsventiler Beulco BA107S/BA115TE",
    'VM4205/3001': "Avstängningsventiler Ventim VM4205/3001",
    '190S/115TE': "Avstängningsventiler Impel 190S/115TE",
}

export const CUTOFF_LABELS_UNDER_DN65 = {
    '3601/2301': "Armatec 3601",
    '3701/2301': "Armatec 3701",
    '3601/2310': "Armatec 3601",
    'genericCutOff': "standard",
    'genericLowLeadCutOff': "lågblyiga",
    'BA107S/BA115TE': "Beulco BA107S",
    'VM4205/3001': "Ventim VM4205",
    '190S/115TE': "Impel 190S",
}

export const CUTOFF_LABELS_OVER_DN65 = {
    '3601/2301': "Armatec 2301",
    '3701/2301': "Armatec 2301",
    '3601/2310': "Armatec 2310",
    'genericCutOff': "standard",
    'genericLowLeadCutOff': "lågblyiga",
    'BA107S/BA115TE': "Beulco BA115TE",
    'VM4205/3001': "Ventim 3001",
    '190S/115TE': "Impel 115TE",
}

export const THERMO_MULTIPLE_LABELS = {
    'bimetallic': "Termometrar (bimetall) med vätskeberörda dykrör",
    'sprit110mm': "Sprittermometrar 110mm",
    'qvintus': "Sprittermometrar Qvintus",
}

export const THERMO_LABELS = {
    'bimetallic': "Termometrar (bimetall) med vätskeberörda dykrör",
    'sprit110mm': "Sprittermometer 110mm",
    'qvintus': "Sprittermometer Qvintus",
}

export const THERMO_MULTIPLE_LABELS_GREEN = {
    'bimetallic': "Termometrar (bimetall) med dykrör i rostfritt stål",
    'sprit110mm': "Sprittermometrar 110mm",
    'qvintus': "Sprittermometrar Qvintus",
}

export const THERMO_LABELS_GREEN = {
    'bimetallic': "Termometer (bimetall) med dykrör i rostfritt stål",
    'sprit110mm': "Sprittermometer 110mm",
    'qvintus': "Sprittermometer Qvintus",
}

export const INSULATION_LABELS = {
    'armaflex13': "Armaflex AF 13 mm",
    'armaflex19': "Armaflex AF 19 mm",
    'ultima13': "AF Ultima 13 mm",
    'ultima19': "AF Ultima 19 mm",
    'nh_smart13': "NH Smart 13 mm",
    'nh_smart19': "NH Smart 19 mm",
}