import { FormLabel, FormControl, FormControlLabel, FormGroup, Checkbox, FormHelperText } from "@mui/material"
import { PRIORITY_OPTIONS, QUESTION_PRIORITIES } from "../../../../constants/wizardConstants"


export const PrioritiesQuestion = ({ priorities, answerQuestion }) => {
    const handleAnswer = (item) => {
        if (item !== 'quality'){
            let numAlt = 0;
            for (const key in priorities) {
                if (Object.hasOwnProperty.call(priorities, key)) {
                    const element = priorities[key];
                    if (element === true){
                        numAlt++;
                    }
                }
            }

            if (numAlt < 3 || priorities[item] === true){
                answerQuestion(QUESTION_PRIORITIES, PRIORITY_OPTIONS[item].value);
            }
        }
    }

    return (
        <FormControl margin={'dense'} variant="standard">
            <FormLabel>Prioriteringar</FormLabel>
            <FormGroup>
                {Object.keys(PRIORITY_OPTIONS).map((item) => {
                    return (
                        <FormControlLabel
                            sx={{ height: '27px', width: '300px' }}
                            control={
                                <Checkbox checked={priorities[PRIORITY_OPTIONS[item].value]} onChange={() => handleAnswer(item)} />
                            }
                            label={PRIORITY_OPTIONS[item].label}
                        />);
                })}

            </FormGroup>
            <FormHelperText>Välj max 3 val</FormHelperText>
        </FormControl>
    )
}