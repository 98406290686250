import { ref, remove, set } from "firebase/database";
import { SESSION_SHUNT_PATH, SHUNT_CALCPARAMS } from "../constants/firebasePaths";
import { KV_KEY, KVS_KEY, SV_PRESSURE_DROP_KEY, PRIM_INTERNAL_PRESSURE_DROP_KEY, SEC_INTERNAL_PRESSURE_DROP_KEY, AUTHORITY_KEY, ADJ_VALVE_KEY, CONTROL_VALVE_KEY, PRIM_DIM_ROW_KEY, SEC_DIM_ROW_KEY, SHUNT_TYPE_KEY, TOTAL_PRESSURE_KEY, MEASUREMENTDATA_KEY, SV_DIM_KEY, SV_DIM_OBJ_KEY, SV_MAX_KEY, SV_SETTING_KEY, MIN_PRESSURE_KEY, BALANCING_KV_KEY, PRIM_INTERNAL_DP_KEY } from "../constants/keys/calcParamsKeys";
import { PRIM_DIM_KEY, SEC_DIM_KEY } from "../constants/keys/driftParamsKeys";
import { ContextEnum } from "./contextEnum";

export class CalcParamsEnum extends ContextEnum {
    static Kv = new CalcParamsEnum(KV_KEY, "setKv");
    static Kvs = new CalcParamsEnum(KVS_KEY, "setKvs");
    static BalancingKv = new CalcParamsEnum(BALANCING_KV_KEY, "setBalancingKv");
    static SVPressureLoss = new CalcParamsEnum(SV_PRESSURE_DROP_KEY, "setSVPressureLoss");
    static PrimInternalPressureDrop = new CalcParamsEnum(PRIM_INTERNAL_PRESSURE_DROP_KEY, "setPrimInternalPressureDrop");
    static SecInternalPressureDrop = new CalcParamsEnum(SEC_INTERNAL_PRESSURE_DROP_KEY, "setSecInternalPressureDrop");
    static Authority = new CalcParamsEnum(AUTHORITY_KEY, "setAuthority");
    static PrimDim = new CalcParamsEnum(PRIM_DIM_KEY, "setPrimDim");
    static SekDim = new CalcParamsEnum(SEC_DIM_KEY, "setSekDim");
    static AdjValve = new CalcParamsEnum(ADJ_VALVE_KEY, "setAdjValve");
    static ControlValve = new CalcParamsEnum(CONTROL_VALVE_KEY, "setControlValve");
    static PrimDimRow = new CalcParamsEnum(PRIM_DIM_ROW_KEY, "setPrimDimRow");
    static SekDimRow = new CalcParamsEnum(SEC_DIM_ROW_KEY, "setSecDimRow");
    static ShuntType = new CalcParamsEnum(SHUNT_TYPE_KEY, "setShuntType");
    static TotalPressure = new CalcParamsEnum(TOTAL_PRESSURE_KEY, "setTotalPressure");
    static PrimInternalDP = new CalcParamsEnum(PRIM_INTERNAL_DP_KEY, "setPrimInternalDP");

    static MeasurementData = new CalcParamsEnum(MEASUREMENTDATA_KEY, "setMeasurementData")

    //Flex-specific
    static FlexSVDim = new CalcParamsEnum(SV_DIM_KEY, "setSvDim");
    static FlexSVDimObj = new CalcParamsEnum(SV_DIM_OBJ_KEY, "setSvDimObj");
    static FlexSVMax = new CalcParamsEnum(SV_MAX_KEY, "setSvMax");
    static FlexSVSetting = new CalcParamsEnum(SV_SETTING_KEY, "setSvSetting");
    static MinPressure = new CalcParamsEnum(MIN_PRESSURE_KEY, "setMinPressure")

    //static UpdateValues = new CalcParamsEnum("updateValues", "setUpdateValues");

    setToFirebase(database, shuntID, value){
        const dbRef = ref(database, SESSION_SHUNT_PATH + shuntID + SHUNT_CALCPARAMS + this.name);
        return set(dbRef, value);
    }

    static clearDataFromFB = function clearDataFromFB(context, database, shuntID){
        const fbRef = ref(database, SESSION_SHUNT_PATH + shuntID + SHUNT_CALCPARAMS);
        remove(fbRef);
        context.dispatch({ type: "clear"});
    }
}