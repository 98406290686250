import { ConnectionTypeImageDataListInterface } from "./types";

import pr2 from '../../assets/images/connectionTypes/small/pr2.jpeg'
import pr3 from '../../assets/images/connectionTypes/small/pr3a.jpeg'
import sr2 from '../../assets/images/connectionTypes/small/sr2.jpeg'
//import sr2Flex from '../../assets/images/connectionTypes/small/sr2_flex.png'
//import sr2Smart from '../../assets/images/connectionTypes/small/sr2_smart.png'
import sr3 from '../../assets/images/connectionTypes/small/sr3.jpeg'
import vaa from '../../assets/images/connectionTypes/small/va1.jpeg'
import pr2L from '../../assets/images/connectionTypes/pr2_2_noBV.png'
import pr3L from '../../assets/images/connectionTypes/pr3a_2.png'
import sr2L from '../../assets/images/connectionTypes/sr2_2.png'
//import sr2FlexL from '../../assets/images/connectionTypes/sr2_flex_2.png'
//import sr2SmartL from '../../assets/images/connectionTypes/sr2_smart_2.png'

import sr3L from '../../assets/images/connectionTypes/sr3_2.png'
import vaaL from '../../assets/images/connectionTypes/vaa_2.png'

import sr2Auto from '../../assets/images/connectionTypes/auto/SR2.png'
import sr3Auto from '../../assets/images/connectionTypes/auto/SR3.png'
import pr2Auto from '../../assets/images/connectionTypes/auto/PR2.png'
import pr3Auto from '../../assets/images/connectionTypes/auto/PR3.png'
import va1Auto from '../../assets/images/connectionTypes/auto/VA1.png'
import sr2AppAuto from '../../assets/images/connectionTypes/auto/sr2-app.png'
import pr2AppAuto from '../../assets/images/connectionTypes/auto/PRH2-app.jpg'
import fbu5kAuto from '../../assets/images/connectionTypes/auto/FBU-5K.png'
import fbu5vAuto from '../../assets/images/connectionTypes/auto/FBU-5V.png'
import fku5kAuto from '../../assets/images/connectionTypes/auto/FBU-5K.png'
import srx42Auto from '../../assets/images/connectionTypes/auto/SRX-42.png'
import srx43Auto from '../../assets/images/connectionTypes/auto/SRX-43.png'
import prx43Auto from '../../assets/images/connectionTypes/auto/PRX-43.png'
import prux43fkAuto from '../../assets/images/connectionTypes/auto/PRUX-43FK.png'
import srbx62vkAuto from '../../assets/images/connectionTypes/auto/SRBX-6VK.png'
import srbx63vkAuto from '../../assets/images/connectionTypes/auto/SRBX-63VK.jpg'
import prbx6vkAuto from '../../assets/images/connectionTypes/auto/PRBX-6VK.jpg'
import srbx6VAVAuto from '../../assets/images/connectionTypes/auto/SRBX-6VAV.png'
import srbx6SpetsAuto from '../../assets/images/connectionTypes/auto/SRBX-6SPETS.png'
import srbx6NodAuto from '../../assets/images/connectionTypes/auto/SRBX-6NOD.png'
import srbx6EcoAuto from '../../assets/images/connectionTypes/auto/SRBX-6ECO.png'
import srbx6VakAuto from '../../assets/images/connectionTypes/auto/SRBX-6VAK.png'
import srbx6VakForbiAuto from '../../assets/images/connectionTypes/auto/SRBX-6VAK-Forbigang.png'
import srbx8VakkAuto from '../../assets/images/connectionTypes/auto/SRBX-8VAKK.png'
import srbx8VakkForbiAuto from '../../assets/images/connectionTypes/auto/SRBX-8VAKK-Forbigang.png'
import pbcsAuto from '../../assets/images/connectionTypes/auto/PBCS-exempel.png'
import { PRODUCTFOLDER_PR2, PRODUCTFOLDER_PR3, PRODUCTFOLDER_PRBX, PRODUCTFOLDER_PRX, PRODUCTFOLDER_SR2, PRODUCTFOLDER_SR2_FLEX, PRODUCTFOLDER_SR3, PRODUCTFOLDER_SRBX, PRODUCTFOLDER_SRX, PRODUCTFOLDER_VA1 } from "../productlineConstants";


export const connectionTypeImageDataList: ConnectionTypeImageDataListInterface = {
    "SR-2": {
        id: "SR-2",
        imageRef: sr2,
        paramImageRef: sr2L,
        autoImg: sr2Auto,
        prodFamilyImageFolder: PRODUCTFOLDER_SR2,
    },
    "SR-2 flex Appshunt": {
        id: "SR-2 flex Appshunt",
        imageRef: sr2AppAuto,
        paramImageRef: sr2AppAuto,
        autoImg: sr2AppAuto,
        prodFamilyImageFolder: PRODUCTFOLDER_SR2_FLEX,
    },
    "SR-3": {
        id: "SR-3",
        imageRef: sr3,
        paramImageRef: sr3L,
        autoImg: sr3Auto,
        prodFamilyImageFolder: PRODUCTFOLDER_SR3,
    },
    "PR-2": {
        id: "PR-2",
        imageRef: pr2,
        paramImageRef: pr2L,
        autoImg: pr2Auto,
        prodFamilyImageFolder: PRODUCTFOLDER_PR2,
    },
    "PR-2 Appshunt": {
        id: "PR-2 Appshunt",
        imageRef: pr2AppAuto,
        paramImageRef: pr2AppAuto,
        autoImg: pr2AppAuto,
        prodFamilyImageFolder: PRODUCTFOLDER_PR2,
    },
    "PR-3": {
        id: "PR-3",
        imageRef: pr3,
        paramImageRef: pr3L,
        autoImg: pr3Auto,
        prodFamilyImageFolder: PRODUCTFOLDER_PR3,
    },
    "VA-1": {
        id: "VA-1",
        imageRef: vaa,
        paramImageRef: vaaL,
        autoImg: va1Auto,
        prodFamilyImageFolder: PRODUCTFOLDER_VA1,
    },
    "FBU-5K": {
        id: "FBU-5K",
        imageRef: fbu5kAuto,
        paramImageRef: fbu5kAuto,
        autoImg: fbu5kAuto,
        prodFamilyImageFolder: 'others',
    },
    "FBU-5V": {
        id: "FBU-5V",
        imageRef: fbu5vAuto,
        paramImageRef: fbu5vAuto,
        autoImg: fbu5vAuto,
        prodFamilyImageFolder: 'others',
    },
    "FKU-5K": {
        id: "FKU-5K",
        imageRef: fku5kAuto,
        paramImageRef: fku5kAuto,
        autoImg: fku5kAuto,
        prodFamilyImageFolder: 'others',
    },
    "SRX-42": {
        id: "SRX-42",
        imageRef: srx42Auto,
        paramImageRef: srx42Auto,
        autoImg: srx42Auto,
        prodFamilyImageFolder: PRODUCTFOLDER_SRX,
    },
    "SRX-43": {
        id: "SRX-43",
        imageRef: srx43Auto,
        paramImageRef: srx43Auto,
        autoImg: srx43Auto,
        prodFamilyImageFolder: PRODUCTFOLDER_SRX,
    },
    "PRX-43": {
        id: "PRX-43",
        imageRef: prx43Auto,
        paramImageRef: prx43Auto,
        autoImg: prx43Auto,
        prodFamilyImageFolder: PRODUCTFOLDER_PRX,
    },
    "PRUX-43FK": {
        id: "PRUX-43FK",
        imageRef: prux43fkAuto,
        paramImageRef: prux43fkAuto,
        autoImg: prux43fkAuto,
        prodFamilyImageFolder: PRODUCTFOLDER_PRX,
    },
    "SRBX-62VK": {
        id: "SRBX-62VK",
        imageRef: srbx62vkAuto,
        paramImageRef: srbx62vkAuto,
        autoImg: srbx62vkAuto,
        prodFamilyImageFolder: PRODUCTFOLDER_SRBX,
    },
    "SRBX-63VK": {
        id: "SRBX-63VK",
        imageRef: srbx63vkAuto,
        paramImageRef: srbx63vkAuto,
        autoImg: srbx63vkAuto,
        prodFamilyImageFolder: PRODUCTFOLDER_SRBX,
    },
    "PRBX-6VK": {
        id: "PRBX-6VK",
        imageRef: prbx6vkAuto,
        paramImageRef: prbx6vkAuto,
        autoImg: prbx6vkAuto,
        prodFamilyImageFolder: PRODUCTFOLDER_PRBX,
    },
    "SRBX-6ECO": {
        id: "SRBX-6ECO",
        imageRef: srbx6EcoAuto,
        paramImageRef: srbx6EcoAuto,
        autoImg: srbx6EcoAuto,
        prodFamilyImageFolder: PRODUCTFOLDER_SRBX,
    },
    "SRBX-6VAV": {
        id: "SRBX-6VAV",
        imageRef: srbx6VAVAuto,
        paramImageRef: srbx6VAVAuto,
        autoImg: srbx6VAVAuto,
        prodFamilyImageFolder: PRODUCTFOLDER_SRBX,
    },
    "SRBX-6SPETS": {
        id: "SRBX-6SPETS",
        imageRef: srbx6SpetsAuto,
        paramImageRef: srbx6SpetsAuto,
        autoImg: srbx6SpetsAuto,
        prodFamilyImageFolder: PRODUCTFOLDER_SRBX,
    },
    "SRBX-6NOD": {
        id: "SRBX-6NOD",
        imageRef: srbx6NodAuto,
        paramImageRef: srbx6NodAuto,
        autoImg: srbx6NodAuto,
        prodFamilyImageFolder: PRODUCTFOLDER_SRBX,
    },
    "SRBX-6VAK": {
        id: "SRBX-6VAK",
        imageRef: srbx6VakAuto,
        paramImageRef: srbx6VakAuto,
        autoImg: srbx6VakAuto,
        prodFamilyImageFolder: PRODUCTFOLDER_SRBX,
    },
    "SRBX-6VAK-Forbi": {
        id: "SRBX-6VAK-Forbi",
        imageRef: srbx6VakForbiAuto,
        paramImageRef: srbx6VakForbiAuto,
        autoImg: srbx6VakForbiAuto,
        prodFamilyImageFolder: PRODUCTFOLDER_SRBX,
    },
    "SRBX-8VAKK": {
        id: "SRBX-8VAKK",
        imageRef: srbx8VakkAuto,
        paramImageRef: srbx8VakkAuto,
        autoImg: srbx8VakkAuto,
        prodFamilyImageFolder: PRODUCTFOLDER_SRBX,
    },
    "SRBX-8VAKK-Forbi": {
        id: "SRBX-8VAKK-Forbi",
        imageRef: srbx8VakkForbiAuto,
        paramImageRef: srbx8VakkForbiAuto,
        autoImg: srbx8VakkForbiAuto,
        prodFamilyImageFolder: PRODUCTFOLDER_SRBX,
    },
    "PBCS": {
        id: "PBCS",
        imageRef: pbcsAuto,
        paramImageRef: pbcsAuto,
        autoImg: pbcsAuto,
        prodFamilyImageFolder: 'others',
    },
};