/**
 * @typedef {Object} DimRow
 * @property {string} NO_ID_FIELD - The ID of the dim
 * @property {string} name - The name of the dim
 * @property {string} display - The displayed name of the dim
 * @property {string} styrVent
 * @property {number} dim
 * @property {number} svDim
 * @property {number} kvBV
 * @property {number} kvBV_Green
 * @property {number} kvIV_Stad
 * @property {number} kvIV_Stv
 * @property {number} kvIV_Oven
 * @property {number} kvRor
 * @property {number} kvs
 * @property {number} kvsPr2
 * @property {number} kvsPr3
 * @property {number} minFlow
 * @property {number} maxFlow
 * @property {number} rorAndBend
 */

import { PRODUCTPRINCIP_PR2, PRODUCTPRINCIP_PR3, PRODUCTPRINCIP_SR2SMART, PRODUCTPRINCIP_VA1 } from "../../../constants/productlineConstants";
import { calcPrimPressureDrop } from "../calculatePressureDrop";

/**
 * 
 * @param {object} dimensionData 
 * @param {number} flow 
 * @param {number} drivingPressure 
 * @param {number} curKvs 
 * @param {boolean} isSecondary 
 * @param {boolean} primarySideBV
 * @param {boolean} extraBV
 * @param {boolean} hasMainPump 
 * @param {number} minPressure
 * @param {*} shuntParams 
 * @returns {DimRow} The matching row
 */
const findCorrespondingDim = function findCorrespondingDim(dimensionData, flow, drivingPressure, curKvs, isSecondary, primarySideBV, extraBV, hasMainPump, minPressure, cvIsInterval, plPrincip, plType, plLine, calcMode, adjustValve, shuntParams, connectedEnergyModel, useContext = true, shuntID = null, database = null) {
    let found = false;
    let selectedDim;
    let selectedKvs;

    for (const dim of dimensionData) {
        let kvs = dim.kvs;
        if (plPrincip === PRODUCTPRINCIP_PR2) {
            kvs = dim.kvsPr2;
        }
        else if (plPrincip === PRODUCTPRINCIP_PR3) {
            kvs = dim.kvsPr3;
        }

        if (found) {
            selectedKvs = kvs;
            selectedDim = dim;
            break;
        }
        if ((dim.minFlow < flow && dim.maxFlow >= flow) || (dim.minFlow === 0 && Number(flow) === 0)) {
            if (isSecondary === true) {
                selectedDim = dim;
                selectedKvs = kvs;
                break;
            }
            else {
                found = true;
                const primInternalPressureDrop = calcPrimPressureDrop(flow, drivingPressure, dim, curKvs, minPressure, primarySideBV, false, cvIsInterval, plType, plLine, calcMode, adjustValve, shuntParams, connectedEnergyModel, useContext, shuntID, database);
                //console.log("MinKpa: ", primInternalPressureDrop, dim);

                if (plPrincip === PRODUCTPRINCIP_PR2) {
                    if (hasMainPump && (primInternalPressureDrop <= drivingPressure || dim.display === "DN200")) {
                        selectedDim = dim;
                        selectedKvs = kvs;
                        break;
                    }
                    else if (!hasMainPump) {
                        selectedDim = dim;
                        selectedKvs = kvs;
                        break;
                    }
                }
                else if (plPrincip === PRODUCTPRINCIP_VA1) {
                    selectedDim = dim;
                    selectedKvs = kvs;
                    break;
                }
                else if (plPrincip === PRODUCTPRINCIP_SR2SMART){
                    selectedDim = dim;
                    selectedKvs = kvs;
                    break;
                }
                else if (primInternalPressureDrop <= drivingPressure || dim.display === "DN200") {
                    selectedDim = dim;
                    selectedKvs = kvs;
                    break;
                }
            }

        }
    }

    return selectedDim;
}

/**
 * 
 * @param {string} dimName 
 * @param {Array} dimensionData 
 */
const getDimRowFromName = function getDimRowFromName(dimName, dimensionData) {
    const selected = dimensionData.find((obj) => { return obj.display === dimName });
    if (selected == null){
        const selectedName = dimensionData.find((obj) => { return obj.name === dimName });
        return selectedName
    }
    return selected;
}

export { findCorrespondingDim, getDimRowFromName }