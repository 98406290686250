import { useEffect, useState } from "react";
import { logEvent } from 'firebase/analytics';
import { useAnalytics, useDatabase } from 'reactfire';
import { Typography } from "@mui/material";
import { reportError } from "../../../firebase/functions/reportError";

import { SESSION_PARAMS } from "../../../constants/sessionStoragePaths";

import ErrorIcon from '@mui/icons-material/Error';

export const ErrorPage = ({ error }) => {
    const analytics = useAnalytics();
    const database = useDatabase();
    const [sessionID, setSessionID] = useState("Unknown")

    useEffect(() => {
        console.log("Sending Analytics data...")
        logEvent(analytics, 'exception', { description: error.message, fatal: true });
        let localSessionID = "Unknown";
        if (sessionStorage.getItem(SESSION_PARAMS) != null) {
            const data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            if (data['sessionID'] != null) {
                localSessionID = data['sessionID'];
                setSessionID(localSessionID);
            }
        }

        if (window.location.hostname !== 'localhost') {
            reportError(database, localSessionID, error?.message, String(error?.stack).substring(0, 400)).then(() => {
                console.log("Report sent")
            })
        }
        else{
            console.log("Running in localhost, no report sent!")
        }

    }, []);

    return (
        <div style={{
            margin: "30px 0px 0px 0px",
            width: "96.3%",
            borderRadius: "4px",
            padding: "20px",
            justifyContent: 'center',
            backgroundColor: '#E1E2E4',
        }}>

            <Typography component={'h1'} style={{ display: 'flex', justifyContent: 'center', margin: 'auto', marginBottom: '20px' }}><ErrorIcon color="error"></ErrorIcon></Typography>

            <Typography component={'h1'} style={{ display: 'flex', justifyContent: 'center', margin: 'auto' }}>Oops...</Typography>

            <Typography component={'h2'} style={{ display: 'flex', justifyContent: 'center', margin: 'auto' }}>Nånting gick fel...</Typography>
            <Typography component={'h3'} style={{ display: 'flex', justifyContent: 'center', margin: 'auto', marginTop: '10px', marginBottom: '20px' }}>En felrapport har skapats och skickats!</Typography>
            <div>
                Session: {sessionID}
            </div>
        </div>
    )
}

