import { useEffect, useRef, useState } from 'react';
import { Stack, Typography, DialogContent, DialogActions, Button } from "@mui/material"
import { collection } from 'firebase/firestore';
import { useDatabase, useFirestore, useFirestoreCollection } from 'reactfire';
import { useNavigate } from 'react-router-dom';
import { SidebarImage } from './imageDisplay';
import { useSessionParams, useDevbarParams, useAdjustParams, useProductLineParams, useCalcParams, useDriftParams, useShuntParams, useErrorContext, useNoticeContext } from '../../../contexts';
import { useFSData } from '../../../contexts/fsDataContext';
import { useWizardParams } from '../../../contexts/wizardParams';
import { ProductLineEnum, AdjustmentsEnum, ShuntParamsEnum, DriftParamEnum, FSDataEnum, SessionParamsEnum, CalcParamsEnum } from '../../../enums';
import { saveAdjustData, saveShuntData } from '../../../firebase/functions';
import { getSpecialFlexDim } from '../../../firebase/functions/getSpecialFlexDim';
import { calcMeasurements } from '../../../functions/calculateMeasurements';
import { calculateShuntParams } from '../../../functions/calculateShuntParams';
import { calcPrimPressureDrop } from '../../../functions/ShuntCalculations/calculatePressureDrop';
import { createShuntNomenklaturString } from '../../../functions/ShuntCalculations/helpFunctions/createShuntNomenklaturString';
import { getLargestDim } from '../../../functions/ShuntCalculations/helpFunctions/getLargestDim';
import { resetMaxStepFunc } from '../../../functions/HelperFunctions/resetMaxStep';
import { AdjustCardConnDesign, AdjustCardConnValue, AdjustCardShuntSize, AdjustCardMaterial, AdjustCardThermo, AdjustCardInsulation, AdjustCardAdjustValve, AdjustCardCutOffValve, AdjustCardManometer, AdjustCardOther } from './components/optionCards';
import { AdjustCardConnectedTemp } from './components/optionCards/adjustCardConnectedTemp';
import { AdjustCardConnectedDiff } from './components/optionCards/adjustCardConnectedDiff';
import { useGetSystemFluidOptions } from '../../../hooks/useGetSystemFluidOptions';
import { useGetEnergyMeasurementList } from '../../../hooks/useGetEnergyMeasurementList';
import { BootstrapDialog, BootstrapDialogTitle } from '../../utils/bootstrapDialog';

import { AdjustmentDefault } from '../../../constants/defaultValues/adjustmentDefault';
import { CONN_DESIGN_U, CONN_VALUE_NOT_SPECIFIED, CONN_DESIGN_H, CONN_VALUE_VRD, CONN_DESIGN_L, CONN_VALUE_UR, CONN_LATCH, NO_MANOMETER, INSULATION_19ARMAFLEX, ADJUSTVALUE_STAD, CONN_VALUE_R, CONN_VALUE_VRU, CONN_VALUE_DR, INSULATION_19NH_SMART, INSULATION_13NH_SMART } from '../../../constants/adjustmentsOptions';
import { MEASUREMENTDATA_PATH } from '../../../constants/firebasePaths';
import { PRODUCTPRINCIP_SR2, PRODUCTPRINCIP_SR3, PRODUCTLINE_GREEN, PRODUCTFAMILY_SR, PRODUCTLINE_FLEX, PRODUCTLINE_SMART, PRODUCTFAMILY_PR } from '../../../constants/productlineConstants';
import { ADJUST_VALVE_STADF, COOL_SHUNT } from '../../../constants/shuntOptions';

export const Adjustments = () => {
    const navigate = useNavigate();
    const database = useDatabase();
    const sessionParams = useSessionParams();
    const containerRef = useRef();
    const firestore = useFirestore();
    const devParams = useDevbarParams();
    const adjustParams = useAdjustParams();
    const productLineParams = useProductLineParams();
    const calcParams = useCalcParams();
    const driftParams = useDriftParams();
    const shuntParams = useShuntParams();
    const wizardParams = useWizardParams();
    const errorProvider = useErrorContext();
    const noticeProvider = useNoticeContext();
    const fsDataParams = useFSData();

    const systemFluidOptions = useGetSystemFluidOptions();

    const productLine = ProductLineEnum.ProductLine;
    const productFamily = ProductLineEnum.ProductFamily;
    const productGreenLevel = ProductLineEnum.ProductGreenLevel;

    const currentShunt = SessionParamsEnum.CurrentShunt.getContextValue(sessionParams);

    /**
      * 
      * @param {AdjustmentsEnum} type 
      * @returns 
      */
    const getAdjustParams = (type) => {
        // eslint-disable-next-line eqeqeq
        if (adjustParams.state != undefined && adjustParams.state.val != undefined && Object.keys(adjustParams.state.val).length !== 0) {
            // eslint-disable-next-line eqeqeq
            if (type.getContextValue(adjustParams) != undefined) {
                return type.getContextValue(adjustParams);
            }
            else {
                return '';
            }
        }
        else {
            return '';
        }
    }

    /**
  * 
  * @param {ShuntParamsEnum} type 
  * @returns 
  */
    const getShuntParams = (type) => {
        // eslint-disable-next-line eqeqeq
        if (shuntParams.state != undefined && shuntParams.state.val != undefined && Object.keys(shuntParams.state.val).length !== 0) {
            // eslint-disable-next-line eqeqeq
            if (type.getContextValue(shuntParams) != undefined) {
                return type.getContextValue(shuntParams);
            }
            else {
                return '';
            }
        }
        else {
            return '';
        }
    }

    const { heatEnergyMeasurementList, coolingEnergyMeasurementList } = useGetEnergyMeasurementList();

    const [connectionDesign, setConnectionDesign] = useState(getAdjustParams(AdjustmentsEnum.ConnectionDesign));
    const [connectionValue, setConnectionValue] = useState(getAdjustParams(AdjustmentsEnum.ConnectionValue));
    const [materialValue, setMaterialValue] = useState(getAdjustParams(AdjustmentsEnum.MaterialValue));
    const [thermoValue, setThermoValue] = useState(getAdjustParams(AdjustmentsEnum.ThermoValue));
    const [isolationValue, setIsolationValue] = useState(getAdjustParams(AdjustmentsEnum.IsolationValue));
    const [cutOffValue, setCutOffValue] = useState(getAdjustParams(AdjustmentsEnum.CutOffValue));
    const [manometerValue, setManometerValue] = useState(getAdjustParams(AdjustmentsEnum.ManometerValue));
    const [standValue, setStandValue] = useState(getAdjustParams(AdjustmentsEnum.StandValue));
    const [cabinetValue, setCabinetValue] = useState(getAdjustParams(AdjustmentsEnum.CabinetValue));
    const [actuatorsValue, setActuatorsValue] = useState(getAdjustParams(AdjustmentsEnum.ActuatorsValue));
    const [dripPlateValue, setDripPlateValue] = useState(getAdjustParams(AdjustmentsEnum.DripPlateValue));
    const [adjustValue, setAdjustValue] = useState(getAdjustParams(AdjustmentsEnum.AdjustValue));
    const [shuntSize, setShuntSize] = useState(getShuntParams(ShuntParamsEnum.IsMini));
    const [connTemp, setConnTemp] = useState(getAdjustParams(AdjustmentsEnum.ConnectedTemp));
    const [connDiff, setConnDiff] = useState(getAdjustParams(AdjustmentsEnum.ConnectedDiff));

    const [manometerTemp, setManometerTemp] = useState(manometerValue)
    const [energyMeasurement, setEnergyMeasurement] = useState(getAdjustParams(AdjustmentsEnum.EnergyMeasurement));
    const [oldConnValue, setOldConnValue] = useState(connectionValue);
    const [oldConnDesign, setOldConnDesign] = useState(connectionDesign);

    const [saveShuntDataBool, setSaveShuntData] = useState(false);
    const [update, setUpdate] = useState(false);
    const [measurementData, setMeasuremenData] = useState([]);
    const [openModalHighPrimDrop, setOpenModalHighPrimDrop] = useState(false);
    const [openModalInterval, setOpenModalInterval] = useState(false);
    const [newPrimVal, setNewPrimVal] = useState(0);
    const [disableExtraBV, setDisableExtraBV] = useState(false);

    const measurementDataCol = collection(firestore, MEASUREMENTDATA_PATH);
    const firestoreMesurementRes = useFirestoreCollection(measurementDataCol);

    useEffect(() => {
        if (saveShuntDataBool === true) {
            setSaveShuntData(false);
            saveShuntData(database, sessionParams, shuntParams);
        }
    }, [saveShuntDataBool]);

    const handleModalHighPrimDropCloseApprove = async () => {
        setOpenModalHighPrimDrop(false);
        DriftParamEnum.PrimDrivingPressure.setContextValue(driftParams, newPrimVal);
        let dims = await FSDataEnum.Dims.getContextValue(fsDataParams);
        let flexDims = await FSDataEnum.FlexDims.getContextValue(fsDataParams);
        let specialDim = await getSpecialFlexDim(firestore);
        calculateShuntParams(productLineParams, driftParams, calcParams, shuntParams, errorProvider, noticeProvider, dims, database, sessionParams, wizardParams, systemFluidOptions, flexDims, null, null, specialDim, heatEnergyMeasurementList, coolingEnergyMeasurementList);
        setSaveShuntData(true);
        var url_ob = new URL(window.location.href);
        var new_url = url_ob.pathname + '#step2';
        document.location.href = new_url;
        //console.log("Here: ", new_url);
        navigate(new_url);
    }

    const handleModalHighPrimDropCloseDecline = () => {
        setDisableExtraBV(true);

        AdjustmentsEnum.ConnectionValue.setContextValue(adjustParams, '_');
        ShuntParamsEnum.HasLatch.setContextValue(shuntParams, 0);
        ShuntParamsEnum.HasPrevLatch.setContextValue(shuntParams, 0);
        setConnectionValue('_');

        setOpenModalHighPrimDrop(false);
    }

    const handleModalIntervalCloseApprove = async () => {
        setOpenModalInterval(false);
        DriftParamEnum.PrimDrivingPressure.setContextValue(driftParams, newPrimVal);
        let dims = await FSDataEnum.Dims.getContextValue(fsDataParams);
        let flexDims = await FSDataEnum.FlexDims.getContextValue(fsDataParams);
        let specialDim = await getSpecialFlexDim(firestore);
        calculateShuntParams(productLineParams, driftParams, calcParams, shuntParams, errorProvider, noticeProvider, dims, database, sessionParams, wizardParams, systemFluidOptions, flexDims, null, null, specialDim, heatEnergyMeasurementList, coolingEnergyMeasurementList);

        setSaveShuntData(true);
        var url_ob = new URL(window.location.href);
        var new_url = url_ob.pathname + '#step2';
        document.location.href = new_url;
        //console.log("Here: ", new_url);
        navigate(new_url);
    }

    const handleModalIntervalCloseDecline = () => {
        if (ShuntParamsEnum.HasLatch.getContextValue(shuntParams) === 1) {
            setDisableExtraBV(true);
            //console.log("Decline", ShuntParamsEnum.HasPrevLatch.getContextValue(shuntParams), ShuntParamsEnum.HasLatch.getContextValue(shuntParams))
            AdjustmentsEnum.ConnectionValue.setContextValue(adjustParams, CONN_VALUE_NOT_SPECIFIED);
            ShuntParamsEnum.HasLatch.setContextValue(shuntParams, 0);
            ShuntParamsEnum.HasPrevLatch.setContextValue(shuntParams, 0);
            setConnectionValue(CONN_VALUE_NOT_SPECIFIED);
        }
        else {
            //console.log("Decline", oldConnDesign, oldConnValue)
            AdjustmentsEnum.ConnectionDesign.setContextValue(adjustParams, oldConnDesign);
            AdjustmentsEnum.ConnectionValue.setContextValue(adjustParams, oldConnValue);
            ShuntParamsEnum.HasLatch.setContextValue(shuntParams, 1);
            ShuntParamsEnum.HasPrevLatch.setContextValue(shuntParams, 1);
            setConnectionValue(oldConnValue);
            setConnectionDesign(oldConnDesign);
        }

        setOpenModalInterval(false);
    }

    /**
    * @param {*} value 
    * @param {AdjustmentsEnum} type 
    */
    const setAdjustParams = (value, type, id = null) => {
        resetMaxStepFunc(sessionParams, database);
        /*
        if (SessionParamsEnum.MaxStep.getContextValue(sessionParams) !== SessionParamsEnum.CurrentStep.getContextValue(sessionParams)) {
            SessionParamsEnum.MaxStep.setContextValue(sessionParams, SessionParamsEnum.CurrentStep.getContextValue(sessionParams));
        }*/
        if (type !== AdjustmentsEnum.ConnectionValue && type !== ShuntParamsEnum.IsMini) {
            type.setContextValue(adjustParams, value);
        }

        switch (type) {
            case AdjustmentsEnum.ConnectionDesign:

                setOldConnDesign(connectionDesign);
                if (value === CONN_DESIGN_U) {
                    if (ShuntParamsEnum.HasLatch.getContextValue(shuntParams) === 1) {
                        AdjustmentsEnum.ConnectionValue.setContextValue(adjustParams, CONN_VALUE_NOT_SPECIFIED);
                        setConnectionValue(CONN_VALUE_NOT_SPECIFIED);
                        ShuntParamsEnum.HasLatch.setContextValue(shuntParams, 0);
                    }
                    else {
                        setOldConnValue(CONN_VALUE_NOT_SPECIFIED);
                        AdjustmentsEnum.ConnectionValue.setContextValue(adjustParams, CONN_VALUE_NOT_SPECIFIED);
                        setConnectionValue(CONN_VALUE_NOT_SPECIFIED);
                        ShuntParamsEnum.HasLatch.setContextValue(shuntParams, 0);
                    }

                }
                else if (value === CONN_DESIGN_H) {
                    if (ShuntParamsEnum.HasLatch.getContextValue(shuntParams) !== 1 && productFamily.getContextValue(productLineParams) === PRODUCTFAMILY_SR) {
                        if (CalcParamsEnum.ShuntType.getContextValue(calcParams) === COOL_SHUNT) {
                            setOldConnValue(CONN_VALUE_VRD);
                            AdjustmentsEnum.ConnectionValue.setContextValue(adjustParams, CONN_VALUE_VRD);
                            setConnectionValue(CONN_VALUE_VRD);
                        }
                        else {
                            setOldConnValue(CONN_VALUE_VRU);
                            AdjustmentsEnum.ConnectionValue.setContextValue(adjustParams, CONN_VALUE_VRU);
                            setConnectionValue(CONN_VALUE_VRU);
                        }
                    }
                    else {
                        if (CalcParamsEnum.ShuntType.getContextValue(calcParams) === COOL_SHUNT && productFamily.getContextValue(productLineParams) === PRODUCTFAMILY_SR) {
                            setOldConnValue(CONN_VALUE_VRU);
                            AdjustmentsEnum.ConnectionValue.setContextValue(adjustParams, CONN_VALUE_VRU);
                            setConnectionValue(CONN_VALUE_VRU);
                        }
                        else {
                            setOldConnValue(CONN_VALUE_VRD);
                            AdjustmentsEnum.ConnectionValue.setContextValue(adjustParams, CONN_VALUE_VRD);
                            setConnectionValue(CONN_VALUE_VRD);
                        }
                    }
                }
                else if (value === CONN_DESIGN_L) {
                    if (ShuntParamsEnum.HasLatch.getContextValue(shuntParams) !== 1) {
                        if (CalcParamsEnum.ShuntType.getContextValue(calcParams) === COOL_SHUNT && productFamily.getContextValue(productLineParams) === PRODUCTFAMILY_SR) {
                            setOldConnValue(CONN_VALUE_DR);
                            AdjustmentsEnum.ConnectionValue.setContextValue(adjustParams, CONN_VALUE_DR);
                            setConnectionValue(CONN_VALUE_DR);
                        }
                        else {
                            setOldConnValue(CONN_VALUE_UR);
                            AdjustmentsEnum.ConnectionValue.setContextValue(adjustParams, CONN_VALUE_UR);
                            setConnectionValue(CONN_VALUE_UR);
                        }
                    }
                    else {
                        if (CalcParamsEnum.ShuntType.getContextValue(calcParams) === COOL_SHUNT && productFamily.getContextValue(productLineParams) === PRODUCTFAMILY_SR) {
                            setOldConnValue(CONN_VALUE_UR);
                            AdjustmentsEnum.ConnectionValue.setContextValue(adjustParams, CONN_VALUE_UR);
                            setConnectionValue(CONN_VALUE_UR);
                        }
                        else {
                            setOldConnValue(CONN_VALUE_DR);
                            AdjustmentsEnum.ConnectionValue.setContextValue(adjustParams, CONN_VALUE_DR);
                            setConnectionValue(CONN_VALUE_DR);
                        }
                    }
                }

                setConnectionDesign(value);
                break;
            case AdjustmentsEnum.ConnectionValue:
                setOldConnValue(connectionValue);
                setOldConnDesign(connectionDesign);
                setConnectionValue(value);

                if (id === CONN_LATCH) {
                    ShuntParamsEnum.HasLatch.setContextValue(shuntParams, 1);
                }
                else {
                    ShuntParamsEnum.HasLatch.setContextValue(shuntParams, 0);
                }

                type.setContextValue(adjustParams, value);
                break;
            case AdjustmentsEnum.MaterialValue:
                setMaterialValue(value);
                break;
            case AdjustmentsEnum.ThermoValue:
                setThermoValue(value);
                break;
            case AdjustmentsEnum.IsolationValue:
                setIsolationValue(value);
                break;
            case AdjustmentsEnum.CutOffValue:
                setCutOffValue(value);
                break;
            case AdjustmentsEnum.ManometerValue:
                setManometerValue(value);
                break;
            case AdjustmentsEnum.StandValue:
                setStandValue(value);
                break;
            case AdjustmentsEnum.CabinetValue:

                setCabinetValue(value);
                break;
            case AdjustmentsEnum.ActuatorsValue:
                setActuatorsValue(value);
                break;
            case AdjustmentsEnum.DripPlateValue:
                setDripPlateValue(value);
                break;
            case AdjustmentsEnum.AdjustValue:
                setAdjustValue(value);
                break;
            case AdjustmentsEnum.EnergyMeasurement:
                setEnergyMeasurement(value);
                break;
            case AdjustmentsEnum.ConnectedTemp:
                setConnTemp(value);
                break;
            case AdjustmentsEnum.ConnectedDiff:
                setConnDiff(value);
                break;
            case ShuntParamsEnum.IsMini:
                if ((value === true || value === 'true') && (manometerValue !== NO_MANOMETER || AdjustmentsEnum.ManometerValue.getContextValue(adjustParams) !== NO_MANOMETER)) {
                    AdjustmentsEnum.ManometerValue.setContextValue(adjustParams, NO_MANOMETER);
                    setManometerTemp(manometerValue);
                    setManometerValue(NO_MANOMETER);
                }
                else if ((value === false || value === 'false') && manometerTemp != null && manometerTemp !== "") {
                    setManometerValue(manometerTemp);
                    AdjustmentsEnum.ManometerValue.setContextValue(adjustParams, manometerTemp);
                }
                if (value === 'true') {
                    type.setContextValue(shuntParams, true, database, currentShunt);
                    setShuntSize(true);
                }
                else if (value === 'false') {
                    type.setContextValue(shuntParams, false, database, currentShunt);
                    setShuntSize(false);
                }
                else {
                    type.setContextValue(shuntParams, value);
                    setShuntSize(value);
                }
                setUpdate(!update)

                break;
            default:
                //console.log("No matching param")
                break;
        }

    }

    const updateAdjustSettings = () => {
        if (AdjustmentsEnum.ConnectionDesign.getContextValue(adjustParams) != null && AdjustmentsEnum.ConnectionDesign.getContextValue(adjustParams) !== "") {
            setConnectionDesign(AdjustmentsEnum.ConnectionDesign.getContextValue(adjustParams));
        }
        if (AdjustmentsEnum.ConnectionValue.getContextValue(adjustParams) != null && AdjustmentsEnum.ConnectionValue.getContextValue(adjustParams) !== "") {
            setConnectionValue(AdjustmentsEnum.ConnectionValue.getContextValue(adjustParams));
        }
        if (AdjustmentsEnum.MaterialValue.getContextValue(adjustParams) != null && AdjustmentsEnum.MaterialValue.getContextValue(adjustParams) !== "") {
            setMaterialValue(AdjustmentsEnum.MaterialValue.getContextValue(adjustParams));
        }
        if (AdjustmentsEnum.ThermoValue.getContextValue(adjustParams) != null && AdjustmentsEnum.ThermoValue.getContextValue(adjustParams) !== "") {
            setThermoValue(AdjustmentsEnum.ThermoValue.getContextValue(adjustParams));
        }
        if (AdjustmentsEnum.IsolationValue.getContextValue(adjustParams) != null && AdjustmentsEnum.IsolationValue.getContextValue(adjustParams) !== "") {
            setIsolationValue(AdjustmentsEnum.IsolationValue.getContextValue(adjustParams));
        }
        if (AdjustmentsEnum.CutOffValue.getContextValue(adjustParams) != null && AdjustmentsEnum.CutOffValue.getContextValue(adjustParams) !== "") {
            setCutOffValue(AdjustmentsEnum.CutOffValue.getContextValue(adjustParams));
        }
        if (AdjustmentsEnum.ManometerValue.getContextValue(adjustParams) != null && AdjustmentsEnum.ManometerValue.getContextValue(adjustParams) !== "") {
            setManometerValue(AdjustmentsEnum.ManometerValue.getContextValue(adjustParams));
        }
        if (AdjustmentsEnum.StandValue.getContextValue(adjustParams) != null && AdjustmentsEnum.StandValue.getContextValue(adjustParams) !== "") {
            setStandValue(AdjustmentsEnum.StandValue.getContextValue(adjustParams));
        }
        if (AdjustmentsEnum.CabinetValue.getContextValue(adjustParams) != null && AdjustmentsEnum.CabinetValue.getContextValue(adjustParams) !== "") {
            setCabinetValue(AdjustmentsEnum.CabinetValue.getContextValue(adjustParams));
        }
        if (AdjustmentsEnum.ActuatorsValue.getContextValue(adjustParams) != null && AdjustmentsEnum.ActuatorsValue.getContextValue(adjustParams) !== "") {
            setActuatorsValue(AdjustmentsEnum.ActuatorsValue.getContextValue(adjustParams));
        }
        if (AdjustmentsEnum.AdjustValue.getContextValue(adjustParams) != null && AdjustmentsEnum.AdjustValue.getContextValue(adjustParams) !== "") {
            setAdjustValue(AdjustmentsEnum.AdjustValue.getContextValue(adjustParams));
        }
        if (AdjustmentsEnum.ConnectedTemp.getContextValue(adjustParams) != null && AdjustmentsEnum.ConnectedTemp.getContextValue(adjustParams) !== "") {
            setConnTemp(AdjustmentsEnum.ConnectedTemp.getContextValue(adjustParams));
        }
        if (AdjustmentsEnum.ConnectedDiff.getContextValue(adjustParams) != null && AdjustmentsEnum.ConnectedDiff.getContextValue(adjustParams) !== "") {
            setConnDiff(AdjustmentsEnum.ConnectedDiff.getContextValue(adjustParams));
        }
        if (ShuntParamsEnum.IsMini.getContextValue(shuntParams) != null && ShuntParamsEnum.IsMini.getContextValue(shuntParams) !== "") {
            setShuntSize(ShuntParamsEnum.IsMini.getContextValue(shuntParams));
        }

        setUpdate(!update)
    }

    useEffect(() => {
        if (firestoreMesurementRes.data != null) {
            const localModels = [];

            firestoreMesurementRes.data.forEach(model => {
                localModels.push({ id: model.id, ...model.data() })
            });
            setMeasuremenData(localModels);
        }
    }, [firestoreMesurementRes])

    useEffect(() => {
        //console.log("Extra BV")
        const prodPrincip = ProductLineEnum.ProductPrincip.getContextValue(productLineParams);
        if ((prodPrincip === PRODUCTPRINCIP_SR2 || prodPrincip === PRODUCTPRINCIP_SR3) && ShuntParamsEnum.HasLatch.getContextValue(shuntParams) != null) {
            if (ShuntParamsEnum.HasLatch.getContextValue(shuntParams) !== ShuntParamsEnum.HasPrevLatch.getContextValue(shuntParams)) {
                ShuntParamsEnum.HasPrevLatch.setContextValue(shuntParams, ShuntParamsEnum.HasLatch.getContextValue(shuntParams));
                const primFlow = DriftParamEnum.PrimFlow.getContextValue(driftParams);
                const primDrivPressure = DriftParamEnum.PrimDrivingPressure.getContextValue(driftParams);
                const dimRow = CalcParamsEnum.PrimDimRow.getContextValue(calcParams);
                const kvs = CalcParamsEnum.Kvs.getContextValue(calcParams);
                const minPressure = CalcParamsEnum.MinPressure.getContextValue(calcParams);

                let extraBV = false;

                if (ShuntParamsEnum.HasLatch.getContextValue(shuntParams) === 1) {
                    extraBV = true;
                }

                //console.log("Pre1 SetPrimDrop:", primFlow, primDrivPressure, dimRow, kvs, minPressure, extraBV)
                //const cvModel = ShuntParamsEnum.CV_Model.getContextValue(shuntParams);
                const cvIsInterval = ShuntParamsEnum.CV_IsInterval.getContextValue(shuntParams);
                const plProdFamilyVal = ProductLineEnum.ProductFamily.getContextValue(productLineParams);
                const plProdLine = ProductLineEnum.ProductLine.getContextValue(productLineParams);
                const adjustValve = ShuntParamsEnum.AdjustmentValve.getContextValue(shuntParams);
                let calcMode = Number(DriftParamEnum.CalcMode.getContextValue(driftParams));
                if (calcMode == null || isNaN(calcMode)) {
                    calcMode = 1
                }

                const primVal = calcPrimPressureDrop(primFlow, primDrivPressure, dimRow, kvs, minPressure, false, extraBV, cvIsInterval, plProdFamilyVal, plProdLine, calcMode, adjustValve, shuntParams);
                setNewPrimVal(primVal);

                if (cvIsInterval === true) {
                    setOpenModalInterval(true);
                }
                else {
                    if (primVal > primDrivPressure) {
                        setOpenModalHighPrimDrop(true);
                    }
                    else if (isNaN(primVal) === false && primVal !== 0) {
                        //console.log("Pre2 SetPrimDrop:", primVal)
                        CalcParamsEnum.PrimInternalPressureDrop.setContextValue(calcParams, primVal, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
                        setSaveShuntData(true);
                        setOpenModalHighPrimDrop(false);
                    }
                }


            }
            //CalcParamsEnum.PrimInternalPressureDrop.setContextValue(calcParams, primVal);
        }
    }, [ShuntParamsEnum.HasLatch.getContextValue(shuntParams)]);

    useEffect(() => {
        //console.log("Mesasure:", measurementData)
        calcMeasurements(measurementData, adjustParams, productLineParams, calcParams, shuntParams, sessionParams, database, currentShunt);
        createShuntNomenklaturString(productLineParams, adjustParams, sessionParams, shuntParams, database, currentShunt);
        //saveMeasurementData(database, sessionParams, calcParams);
    }, [adjustParams.state.val, measurementData, driftParams.state.val.sekDim, ShuntParamsEnum.IsMini.getContextValue(shuntParams)])


    useEffect(() => {

        if (adjustParams.state.val != null && Object.keys(adjustParams.state.val).length !== 0) {
            updateAdjustSettings();
            saveAdjustData(database, sessionParams, adjustParams);
        }
        else {
            fillDefaultValues();
        }
    }, [adjustParams.state]);

    useEffect(() => {
        if (devParams.state.val.devMode === true) {
            const prodLine = ProductLineEnum.ProductLine.getContextValue(productLineParams);

            if ((prodLine !== PRODUCTLINE_GREEN) && (getMinTempValue() <= 2)) {
                setAdjustParams(INSULATION_19ARMAFLEX, AdjustmentsEnum.IsolationValue)
            }
            else if ((prodLine === PRODUCTLINE_GREEN) && (getMinTempValue() <= 2)) {
                setAdjustParams(INSULATION_19NH_SMART, AdjustmentsEnum.IsolationValue)
            }
            else if ((getMinTempValue() <= 18) && (getMinTempValue() > 2) && (prodLine === PRODUCTLINE_GREEN)) {
                setAdjustParams(INSULATION_13NH_SMART, AdjustmentsEnum.IsolationValue)
            }

            if (getMinTempValue() > 18) {
                setAdjustParams(false, AdjustmentsEnum.DripPlateValue)
            }
            else if (getMinTempValue() <= 18) {
                setAdjustParams(true, AdjustmentsEnum.DripPlateValue)
            }

            if ((productLine.getContextValue(productLineParams) !== PRODUCTLINE_GREEN) && (ShuntParamsEnum.AdjustmentValve.getContextValue(shuntParams) === ADJUST_VALVE_STADF) && (CalcParamsEnum.SekDimRow.getContextValue(calcParams) != null && CalcParamsEnum.SekDimRow.getContextValue(calcParams).dim <= 50)) {
                setAdjustParams(ADJUSTVALUE_STAD, AdjustmentsEnum.AdjustValue)
            }
        }

    }, []);

    /*
    useEffect(() => {
        const maxDim = getLargestDim(calcParams);
        if (isNaN(maxDim) === false) {
            if (getLargestDim(calcParams) <= 20) {
                ShuntParamsEnum.IsMini.setContextValue(shuntParams, true, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
            }
            else {
                ShuntParamsEnum.IsMini.setContextValue(shuntParams, false, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
            }
        }

    }, [])*/

    const getMinTempValue = () => {
        let primTempIn = Number(DriftParamEnum.PrimTempIn.getContextValue(driftParams));
        let primTempOut = Number(DriftParamEnum.PrimTempOut.getContextValue(driftParams));
        let secTempIn = Number(DriftParamEnum.SecTempIn.getContextValue(driftParams));
        let secTempOut = Number(DriftParamEnum.SecTempOut.getContextValue(driftParams));

        //console.log("Vals:", primTempIn, primTempOut, secTempIn, secTempOut)
        if (isNaN(primTempIn) || DriftParamEnum.PrimTempIn.getContextValue(driftParams) == null) {
            primTempIn = Number.MAX_SAFE_INTEGER;
        }
        if (isNaN(primTempOut) || DriftParamEnum.PrimTempOut.getContextValue(driftParams) == null) {
            primTempOut = Number.MAX_SAFE_INTEGER;
        }
        if (isNaN(secTempIn) || DriftParamEnum.SecTempIn.getContextValue(driftParams) == null) {
            secTempIn = Number.MAX_SAFE_INTEGER;
        }
        if (isNaN(secTempOut) || DriftParamEnum.SecTempOut.getContextValue(driftParams) == null) {
            secTempOut = Number.MAX_SAFE_INTEGER;
        }

        return Math.min(primTempIn, primTempOut, secTempIn, secTempOut);
    }

    const fillDefaultValues = () => {
        const prodFamily = ProductLineEnum.ProductFamily.getContextValue(productLineParams);
        const prodLine = ProductLineEnum.ProductLine.getContextValue(productLineParams);
        const greenLevel = Number(ProductLineEnum.ProductGreenLevel.getContextValue(productLineParams));

        let defaultValues = {};

        if (prodFamily === PRODUCTFAMILY_SR) {
            if (prodLine === PRODUCTLINE_FLEX) {
                defaultValues = AdjustmentDefault.SRFlexDefault;
            }
            else if (prodLine === PRODUCTLINE_GREEN) {
                defaultValues = AdjustmentDefault.SRGreenDefault;
            }
            else if (prodLine === PRODUCTLINE_SMART) {
                //console.log("Here?")
                defaultValues = AdjustmentDefault.SRSmartDefault;
            }
            else {
                defaultValues = AdjustmentDefault.SRDefault;
            }
        }
        else if (prodFamily === PRODUCTFAMILY_PR) {
            if (prodLine === PRODUCTLINE_GREEN) {
                defaultValues = AdjustmentDefault.PRGreenDefault;
            }
            else {
                defaultValues = AdjustmentDefault.PRDefault;
            }
        }
        else {
            if (prodLine === PRODUCTLINE_GREEN) {
                defaultValues = AdjustmentDefault.VAGreenDefault;
            }
            else {
                defaultValues = AdjustmentDefault.VADefault;
            }
        }

        const minTemp = getMinTempValue();
        if (minTemp <= 2) {
            if (prodLine === PRODUCTLINE_GREEN) {
                if (greenLevel === 2) {
                    defaultValues = { ...defaultValues, ...AdjustmentDefault.IsolationGreenII_Below2Degrees }
                }
                else {
                    defaultValues = { ...defaultValues, ...AdjustmentDefault.IsolationGreen_Below2Degrees }
                }

            }
            else {
                defaultValues = { ...defaultValues, ...AdjustmentDefault.Isolation_Below2Degrees }
            }
        }
        else if (minTemp < 18) {
            if (prodLine === PRODUCTLINE_GREEN) {
                if (greenLevel === 2) {
                    defaultValues = { ...defaultValues, ...AdjustmentDefault.IsolationGreenII_Below18Degrees }
                }
                else {
                    defaultValues = { ...defaultValues, ...AdjustmentDefault.IsolationGreen_Below18Degrees }
                }

            }
            else {
                defaultValues = { ...defaultValues, ...AdjustmentDefault.Isolation_Below18Degrees }
            }
        }

        if (prodLine !== PRODUCTLINE_GREEN && ShuntParamsEnum.AdjustmentValve.getContextValue(shuntParams) === ADJUST_VALVE_STADF && CalcParamsEnum.SekDimRow.getContextValue(calcParams) != null && CalcParamsEnum.SekDimRow.getContextValue(calcParams).dim <= 50) {
            defaultValues = { ...defaultValues, adjustValue: ADJUSTVALUE_STAD }
        }

        if (SessionParamsEnum.IsApiSession.getContextValue(sessionParams) === true) {
            defaultValues['connectionValue'] = CONN_VALUE_R;
        }

        /*
        if (getLargestDim(calcParams) <= 20 && ShuntParamsEnum.IsMiniIncompatible.getContextValue(shuntParams) !== true) {
            console.log("Here, SET_MINI")
            ShuntParamsEnum.IsMini.setContextValue(shuntParams, true);
        }
        else {
            ShuntParamsEnum.IsMini.setContextValue(shuntParams, false);
        }*/

        //console.log("Default: ", defaultValues);
        AdjustmentsEnum.setDefaultValues(adjustParams, defaultValues);
    }


    const isDisabled = () => {
        if (SessionParamsEnum.Editable.getContextValue(sessionParams) === false && devParams.state.val.devMode !== true) {
            return true;
        }
        else {
            return false;
        }
    }

    return (
        <>
            <Stack direction={'row'} spacing={5} ref={containerRef}>
                <div>
                    <div>
                        <Typography variant='h3' fontWeight='bold'>
                            Anpassningar
                        </Typography>
                        <AdjustCardConnDesign connectionDesign={connectionDesign} setAdjustParams={setAdjustParams} isDisabled={isDisabled} />
                    </div>

                    <AdjustCardConnValue connectionValue={connectionValue} connectionDesign={connectionDesign} setAdjustParams={setAdjustParams} isDisabled={isDisabled} disableExtraBV={disableExtraBV} />

                    {(getLargestDim(calcParams) < 25) && (ShuntParamsEnum.IsMiniIncompatible.getContextValue(shuntParams) !== true) && ProductLineEnum.ProductGreenLevel.getContextValue(productLineParams) !== 2 &&
                        <AdjustCardShuntSize shuntSize={shuntSize} setAdjustParams={setAdjustParams} isDisabled={isDisabled} />
                    }

                    <AdjustCardMaterial materialValue={materialValue} setAdjustParams={setAdjustParams} isDisabled={isDisabled} />

                    {(productLine.getContextValue(productLineParams) !== PRODUCTLINE_GREEN) &&
                        <AdjustCardThermo thermoValue={thermoValue} setAdjustParams={setAdjustParams} isDisabled={isDisabled} />
                    }

                    {getMinTempValue() < 18 &&
                        <AdjustCardInsulation insulationValue={isolationValue} setAdjustParams={setAdjustParams} isDisabled={isDisabled} />
                    }

                    {(productLine.getContextValue(productLineParams) !== PRODUCTLINE_GREEN) && (ShuntParamsEnum.AdjustmentValve.getContextValue(shuntParams) === ADJUST_VALVE_STADF) && (getLargestDim(calcParams) <= 50) &&
                        <AdjustCardAdjustValve adjustValue={adjustValue} setAdjustParams={setAdjustParams} isDisabled={isDisabled} />
                    }
                    {(productLine.getContextValue(productLineParams) !== PRODUCTLINE_GREEN) &&
                        <AdjustCardCutOffValve cutOffValue={cutOffValue} setAdjustParams={setAdjustParams} isDisabled={isDisabled} />
                    }
                    {(productLine.getContextValue(productLineParams) !== PRODUCTLINE_GREEN) && (shuntSize === false) &&
                        <AdjustCardManometer manometerValue={manometerValue} setAdjustParams={setAdjustParams} isDisabled={isDisabled} />
                    }

                    {(productLine.getContextValue(productLineParams) !== PRODUCTLINE_GREEN || (productLine.getContextValue(productLineParams) === PRODUCTLINE_GREEN && productGreenLevel.getContextValue(productLineParams) !== 2)) ? (
                        <>
                            <AdjustCardConnectedTemp connectedTemp={connTemp} setAdjustParams={setAdjustParams} isDisabled={isDisabled} />
                            <AdjustCardConnectedDiff connectedDiff={connDiff} setAdjustParams={setAdjustParams} isDisabled={isDisabled} />
                        </>
                    ) : ("")}

                    <AdjustCardOther standValue={standValue} cabinetValue={cabinetValue} dripPlateValue={dripPlateValue} actuatorsValue={actuatorsValue} getMinTempValue={getMinTempValue} setAdjustParams={setAdjustParams} isDisabled={isDisabled} />

                </div>

                <SidebarImage />
            </Stack>

            <BootstrapDialog
                onClose={handleModalHighPrimDropCloseDecline}
                aria-labelledby="customized-dialog-title"
                open={openModalHighPrimDrop}
            >
                <BootstrapDialogTitle onClose={handleModalHighPrimDropCloseDecline}>
                    För högt tryckfall
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center' }}>
                        På grund av extra backventil kräver detta anslutningsutförande ett högre tillgängligt drivtryck.
                    </Typography>
                    <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center' }}>
                        <strong>Kan ett primärtryck på {newPrimVal}kPa accepteras?</strong>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalHighPrimDropCloseApprove}>
                        Ja
                    </Button>
                    <Button onClick={handleModalHighPrimDropCloseDecline}>
                        Nej
                    </Button>
                </DialogActions>
            </BootstrapDialog>

            <BootstrapDialog
                onClose={handleModalIntervalCloseDecline}
                aria-labelledby="customized-dialog-title"
                open={openModalInterval}
            >
                <BootstrapDialogTitle onClose={handleModalIntervalCloseDecline}>
                    Omräkning krävs
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center' }}>
                        På grund av extra backventil kräver detta anslutningsutförande ett högre tillgängligt drivtryck.
                    </Typography>
                    <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center' }}>
                        <strong>Vill du gå vidare med omräkning för det valda inkopplingsalternativet?</strong>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalIntervalCloseApprove}>
                        Ja
                    </Button>
                    <Button onClick={handleModalIntervalCloseDecline}>
                        Nej
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    )
}
