import { PRODUCTPRINCIP_PR2, PRODUCTPRINCIP_VA1 } from "../../constants/productlineConstants";

const calcTotalPressure = function calcTotalPressure(plPrincip, hasMainPump, secExternalPressureDrop, primDrivingPressure, pEfk, secInternalPressureDrop, SVpressureDrop, primInternalPressureDrop, primExternalPressureDrop) {
    if (pEfk == null || pEfk === "" || isNaN(pEfk) === true) {
        pEfk = 0;
    }

    let totalPressure = Number(secExternalPressureDrop) + Number(secInternalPressureDrop);

    if (plPrincip === PRODUCTPRINCIP_VA1) {
        totalPressure += Number(pEfk) + Number(primExternalPressureDrop) + Number(primInternalPressureDrop);
    }
    else if (plPrincip === PRODUCTPRINCIP_PR2 && hasMainPump === false) {
        totalPressure += Number(pEfk) + Number(primExternalPressureDrop) + Number(primInternalPressureDrop);       
    }

    return totalPressure;
}

export { calcTotalPressure }