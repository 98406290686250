// @ts-nocheck
import { getBlob, ref } from "firebase/storage";
import * as exif from '@ginpei/exif-orientation';

export const getShuntImage = async function getShuntImage(storage, folderUrl) {
    let storageRef = ref(storage, folderUrl)
    let imageBlob = await getBlob(storageRef)
        .catch(error => {
            if (error.code === 'storage/object-not-found') {
                //console.log("Not found:", folderUrl)
            } else {
                //console.log("Error:", error)
            }
        });

    let size = { width: 0, height: 0 }

    if (imageBlob != null) {
        size = await getBlobSize(imageBlob);
        //console.log("Size: ", size.width, size.height);
    }

    return { blob: imageBlob, size: size };
}

/**
 * 
 * @param {Blob} imageBlob 
 * @returns 
 */
async function getBlobSize(imageBlob) {
    let size = { width: 0, height: 0 }
    let url = URL.createObjectURL(imageBlob);
    let image = new Image()
    image.src = url;

    await new Promise(resolve => {
        image.onload = async function () {
            //console.log("Size Res: Width:", this.width, "Height: ", this.height)
            let buffer = await imageBlob.arrayBuffer();
            let rotation = await exif.getOrientation(buffer).then((res) => {
                //console.log("Orientation: ", res)
                return res?.rotation;
            });

            if (this.width > 190 || this.height > 190) {
                if (this.width > this.height) {
                    let aspectRatio = this.height / this.width;
                    this.width = 190;
                    this.height = 190 * aspectRatio;
                }
                else {
                    let aspectRatio = this.width / this.height;
                    this.width = 190 * aspectRatio;
                    this.height = 190;
                }
            }

            if (rotation === 90 || rotation === 180){
                size = { width: this.height, height: this.width, rotation: rotation }
            }
            else{
                size = { width: this.width, height: this.height, rotation: rotation }
            }

            
            resolve('resolved');
        }
    })

    return size;
}