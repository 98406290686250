import { FormLabel, FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material"
import { QUESTION_HAS_MAIN_PUMP_KEY } from "../../../../constants/wizardConstants"


export const HasMainPumpQuestion = ({ hasMainPump, answerQuestion }) => {

    const handleAnswer = (event) => {
        if (event.target.value === 'true' || event.target.value === true){
            answerQuestion(QUESTION_HAS_MAIN_PUMP_KEY, true);
        }
        else if (event.target.value === 'false' || event.target.value === false){
            answerQuestion(QUESTION_HAS_MAIN_PUMP_KEY, false);
        }
    }


    return (
        <>
            <FormControl size="small">
                <FormLabel>Huvudpump finns</FormLabel>
                <RadioGroup
                    sx={{ marginLeft: '10px' }}
                    row
                    value={hasMainPump}
                    onChange={handleAnswer}
                >
                    <FormControlLabel value={true} control={<Radio />} label="Ja" />
                    <FormControlLabel value={false} control={<Radio />} label="Nej" />
                </RadioGroup>
            </FormControl>
        </>
    )
}