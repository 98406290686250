//Keys
export const WIZARD_SOURCE_KEY = "source";
export const WIZARD_SOURCE_FLOW_KEY = "sourceFlow";
export const WIZARD_APPLICATION_KEY = "application";
export const WIZARD_APPLICATION_INTENT_KEY = "applicationIntent";
export const WIZARD_APPLICATION_SIZE_KEY = "applicationSize";
export const WIZARD_ONLY_DIMENSIONABLE_KEY = "onlyDimensionable";
export const WIZARD_SECONDARY_FLOW_KEY = "secondaryFlow";
export const WIZARD_SECONDARY_FLOW_SPEC_VAL_KEY = "secondaryFlowSpecVal";
export const WIZARD_AVAIL_DRIVING_PRESSURE_KEY = "availDrivingPressure";
export const WIZARD_VARIABLE_AVAIL_DRIVING_PRESSURE_KEY = "variableAvailDrivingPressure";
export const WIZARD_SPECIFIC_AVAIL_DRIVING_PRESSURE_KEY = "specificAvailDrivingPressure";
export const WIZARD_HAS_MAIN_PUMP_KEY = "hasMainPump";
export const WIZARD_CERTICIFACTIONS_KEY = "certifications";
export const WIZARD_SYSTEM_FLUID_MAIN_KEY = "systemFluidMain";
export const WIZARD_SYSTEM_FLUID_PRIMARY_KEY = "systemFluidPrimary";
export const WIZARD_SYSTEM_FLUID_PRIMARY_MIX_RATIO_KEY = "systemFluidPrimaryMixRatio";
export const WIZARD_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_KEY = "systemFluidPrimaryMixRatioUnknown";
export const WIZARD_SYSTEM_FLUID_SECONDARY_KEY = "systemFluidSecondary";
export const WIZARD_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY = "systemFluidSecondaryMixRatio";
export const WIZARD_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_KEY = "systemFluidSecondaryMixRatioUnknown";
export const WIZARD_ENERGY_MEASUREMENT_KEY = "energyMeasurement";
export const WIZARD_STAINLESS_KEY = "stainless";
export const WIZARD_DYNAMIC_CONTROL_VALVE_KEY = "dynamicControlValve";
export const WIZARD_LIMITED_SPACE_KEY = "limitedSpace";
export const WIZARD_PRIORITIES_KEY = "priorities";


//Set-functions
export const WIZARD_SET_SOURCE = "setSource";
export const WIZARD_SET_SOURCE_FLOW = "setSourceFlow";
export const WIZARD_SET_APPLICATION = "setApplication";
export const WIZARD_SET_APPLICATION_INTENT = "setApplicationIntent";
export const WIZARD_SET_APPLICATION_SIZE = "setApplicationSize";
export const WIZARD_SET_ONLY_DIMENSIONABLE = "setOnlyDimensionable";
export const WIZARD_SET_SECONDARY_FLOW = "setSecondaryFlow"
export const WIZARD_SET_SECONDARY_FLOW_SPEC_VAL = "setSecondaryFlowSpecVal";
export const WIZARD_SET_AVAIL_DRIVING_PRESSURE = "setAvailDrivingPressure";
export const WIZARD_SET_VARIABLE_AVAIL_DRIVING_PRESSURE = "setVariableAvailDrivingPressure"
export const WIZARD_SET_SPECIFIC_AVAIL_DRIVING_PRESSURE = "setSpecificAvailDrivingPressure"
export const WIZARD_SET_HAS_MAIN_PUMP = "setHasMainPump";
export const WIZARD_SET_CERTICIFACTIONS = "setCertifications";
export const WIZARD_SET_SYSTEM_FLUID_MAIN = "setSystemFluidMain";
export const WIZARD_CLEAR_SYSTEM_FLUID_MAIN = "clearSystemFluidMain";
export const WIZARD_SET_SYSTEM_FLUID_PRIMARY = "setSystemFluidPrimary";
export const WIZARD_SET_SYSTEM_FLUID_PRIMARY_MIX_RATIO = "setSystemFluidPrimaryMixRatio";
export const WIZARD_SET_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN = "setSystemFluidPrimaryMixRatioUnknown";
export const WIZARD_SET_SYSTEM_FLUID_SECONDARY = "setSystemFluidSecondary";
export const WIZARD_SET_SYSTEM_FLUID_SECONDARY_MIX_RATIO = "setSystemFluidSecondaryMixRatio";
export const WIZARD_SET_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN = "setSystemFluidSecondaryMixRatioUnknown";
export const WIZARD_SET_ENERGY_MEASUREMENT = "setEnergyMeasurement";
export const WIZARD_SET_STAINLESS = "setStainless";
export const WIZARD_SET_DYNAMIC_CONTROL_VALVE = "setDynamicControlValve";
export const WIZARD_SET_LIMITED_SPACE = "setLimitedSpace";
export const WIZARD_SET_PRIORITIES = "setPriorities";