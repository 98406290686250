import { Box, ToggleButton as MuiToggleButton, styled, ToggleButtonGroup, Tooltip } from "@mui/material"
import { useWizardParams } from "../../../../contexts/wizardParams"
import { WizardParamEnum } from "../../../../enums/wizardParamsEnum";
import { SYSTEM_FLUID } from "../../../../constants/wizardConstants";
import { useEffect, useState } from "react";
import { useDriftParams } from "../../../../contexts";
import { DriftParamEnum } from "../../../../enums";

const ToggleButton = styled(MuiToggleButton)({
    "&.Mui-disabled": {
        "&.Mui-selected": {
            color: "black",
            backgroundColor: '#f0f8ff !important',
            borderRadius: '4px 0px 0px 4px'
        },
        color: "grey",
        backgroundColor: '#00000061 !important',
        pointerEvents: "auto",
        borderRadius: '0px 4px 4px 0px'
    },
    "&.Mui-selected": {
        color: "white",
        backgroundColor: '#0155a0 !important',
        borderRadius: '4px',
    },
});


export const SwitchFlowCalcMode = ({ isDisabled }) => {
    const wizardParams = useWizardParams();
    const driftParams = useDriftParams();

    const setInitValue = () => {
        if (DriftParamEnum.FlowCalcMode.getContextValue(driftParams) != null) {
            return DriftParamEnum.FlowCalcMode.getContextValue(driftParams);
        }
        else {
            return 'ls'
        }
    }

    const [selectedVal, setSelectedVal] = useState(setInitValue())

    useEffect(() => {
        if (selectedVal === 'kw') {
            if (kwIsDisabled()) {
                setSelectedVal('ls');
                DriftParamEnum.FlowCalcMode.setContextValue(driftParams, 'ls');
            }
        }
    }, [selectedVal, WizardParamEnum.SystemFluidMain.getContextValue(wizardParams), WizardParamEnum.SystemFluidPrimaryMixRatioUnknown.getContextValue(wizardParams)])

    const handleChange = (event) => {
        DriftParamEnum.FlowCalcMode.setContextValue(driftParams, event.target.value);
        setSelectedVal(event.target.value);
    }

    const kwIsDisabled = () => {
        if (WizardParamEnum.SystemFluidMain.getContextValue(wizardParams) == null) {
            return true;
        }
        else if (WizardParamEnum.SystemFluidMain.getContextValue(wizardParams) === SYSTEM_FLUID.unknown.value || WizardParamEnum.SystemFluidMain.getContextValue(wizardParams) === SYSTEM_FLUID.other.value) {
            return true;
        }
        else if (WizardParamEnum.SystemFluidMain.getContextValue(wizardParams) !== SYSTEM_FLUID.water.value && WizardParamEnum.SystemFluidPrimaryMixRatioUnknown.getContextValue(wizardParams) === true) {
            return true;
        }

        return false;
    }

    return (
        <Box display={'flex'} flexDirection={'row'} justifyContent={'right'} marginTop={'-30px'} marginRight={'0px'}>
            <ToggleButtonGroup
                size="small"
                sx={{
                    height: '26px',
                }}
                onChange={handleChange}
                value={selectedVal}
                exclusive
            >
                <ToggleButton sx={{ textTransform: 'none' }} disabled={kwIsDisabled() || isDisabled} value={'ls'}>
                    l/s
                </ToggleButton>
                <Tooltip arrow title={kwIsDisabled() ? "Systemvätska samt blandningsförhållande behöver vara känd för att kunna beräkna med kW" : ""}>
                    <ToggleButton sx={{ textTransform: 'none' }} disabled={kwIsDisabled() || isDisabled} value={'kw'}>
                        kW
                    </ToggleButton>
                </Tooltip>

            </ToggleButtonGroup>
        </Box>
    )
}