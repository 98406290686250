import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { firebaseConfig, sendToGoogleAnalytics } from './firebase'
import { FirebaseAppProvider } from 'reactfire';
import { App } from './App';
import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import theme from './components/modules/theme/theme';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <StyledEngineProvider injectFirst>

      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </FirebaseAppProvider>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendToGoogleAnalytics);
