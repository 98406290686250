import { Box } from "@mui/system";
import { signInAnonymously } from "firebase/auth";
import { Fragment, useEffect, useState } from "react";
import { useAuth } from "reactfire";
import { FindSessionOnFB } from "../../utils/findSessionOnFB";

export const OpenLinkModule = () => {
    const url = new URL(window.location.href);
    const sessionID = url.searchParams.get('session');
    const [loggedIn, setLoggedIn] = useState(false);
    const auth = useAuth();
    
    useEffect(() => {
        setLoggedIn(false);
        const nav = async () => {
            auth.onAuthStateChanged(async (fbAuth) => {
                if (fbAuth == null) {
                    await signInAnonymously(auth);
                    setLoggedIn(true);
                    //console.log("Anon: ", res)
                }
                else {
                    setLoggedIn(true);
                }
            });
        }

        nav();
    }, []);

    return (
        <Box>
            {url.searchParams.has('session') === true ? (
                <div style={{ padding: "20px" }}>
                    Öppnar sessionen {sessionID}...
                    {loggedIn === true &&
                        <Fragment>
                            {url.searchParams.has('session') === true && <FindSessionOnFB sessionID={sessionID} edit={false} />}
                        </Fragment>}
                </div>
            ) : (
                <div style={{ padding: "20px" }}>
                    Inte giltig sessions ID.
                </div>
            )}

        </Box>
    );
}