import { useState } from "react";
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Paper } from "@mui/material"
import { AdjustmentsEnum } from "../../../../../enums"

export const AdjustCardConnectedTemp = ({ connectedTemp, setAdjustParams, isDisabled }) => {
    const [options, setOptions] = useState({
        primConnTemp: connectedTemp['primConnTemp'] || false,
        sekConnTemp: connectedTemp['sekConnTemp'] || false,
    });

    const { primConnTemp, sekConnTemp } = options;

    const handleChange = (event) => {
        setOptions({
            ...options,
            [event.target.name]: event.target.checked,
        })
        setAdjustParams({
            ...connectedTemp,
            [event.target.name]: event.target.checked,
        }, AdjustmentsEnum.ConnectedTemp)
    };

    return (
        <div>
            <Paper elevation={0} className="adjust-card">
                <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group">Connected™ Temperatur</FormLabel>
                    <FormGroup
                        aria-labelledby="checkbox-buttons-group-conn-temp"
                    >
                        <FormControlLabel sx={{ marginBottom: '-10px' }} control={<Checkbox name="primConnTemp" checked={primConnTemp} onChange={handleChange} />} label={<span><strong>Connected™ Temp Prim</strong> (Belimo O1DT dykrörstyp PT1000)</span>} disabled={isDisabled()} />
                        <FormControlLabel control={<Checkbox name="sekConnTemp" checked={sekConnTemp} onChange={handleChange} />} label={<span><strong>Connected™ Temp Sek</strong> (Belimo O1DT dykrörstyp PT1000)</span>} disabled={isDisabled()} />
                    </FormGroup>

                </FormControl>
            </Paper>
        </div>
    )
}