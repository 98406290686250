import { Box, IconButton } from "@mui/material";
import { SYSTEM_FLUID, QUESTION_SYSTEM_FLUID_MIX_RATIO_KEY, QUESTION_SYSTEM_FLUID_KEY, QUESTION_SYSTEM_FLUID_MIX_RATIO_UNKNOWN_KEY } from "../../../constants/wizardConstants";
import { useWizardParams } from "../../../contexts/wizardParams";
import { useEffect, useRef, useState } from "react";
import { WizardParamEnum } from "../../../enums/wizardParamsEnum";
import { SystemFluidMenu } from "./systemFluidMenu";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { useDatabase } from "reactfire";
import { useAdjustParams, useCalcParams, useProductLineParams, useSessionParams } from "../../../contexts";
import { AdjustmentsEnum, CalcParamsEnum, ProductLineEnum, SessionParamsEnum } from "../../../enums";
import { PRODUCTPRINCIP_VA1 } from "../../../constants/productlineConstants";
import { useGetSystemFluidOptions } from "../../../hooks/useGetSystemFluidOptions";

export const SystemFluidPicker = ({ isDisabled, isInDriftCalc = false }) => {
    const systemFluidOptions = useGetSystemFluidOptions();
    const sessionParams = useSessionParams();
    const productLineParams = useProductLineParams();
    const calcParams = useCalcParams();
    const adjustParams = useAdjustParams();
    const wizardParams = useWizardParams();
    const database = useDatabase();

    const buttonRef = useRef(null);
    const [systemFluid, setSystemFluid] = useState(SYSTEM_FLUID.water.value);
    const [systemFluidMixRatio, setSystemFluidMixRatio] = useState(100);
    const [systemFluidMixRatioUnknown, setSystemFluidMixRatioUnknown] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const getSystemFluidSelected = (value) => {
        if (value != null) {
            return systemFluidOptions.find((obj) => obj.value === value);
        }
        return null;
    }


    const handleOpenMenu = () => {
        if (isDisabled === false) {
            setAnchorEl(buttonRef.current);
        }
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const findSystemFluid = (obj) => {
        if (obj != null && obj?.defaultFor != null){
            return obj?.defaultFor?.includes(ProductLineEnum.ProductPrincip.getContextValue(productLineParams));
        }

        return false;
    }

    useEffect(() => {
        if (WizardParamEnum.SystemFluidMain.getContextValue(wizardParams) != null) {
            setSystemFluid(WizardParamEnum.SystemFluidMain.getContextValue(wizardParams))
        }
        else {
            if (systemFluidOptions != null && systemFluidOptions.length > 0) {
                const fluidOption = systemFluidOptions.find(findSystemFluid);
                if (fluidOption != null) {
                    WizardParamEnum.SystemFluidMain.setContextValue(wizardParams, fluidOption.value, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams), SessionParamsEnum.SessionID.getContextValue(sessionParams));
                    setSystemFluid(fluidOption.value)
                }
                else {
                    WizardParamEnum.SystemFluidMain.setContextValue(wizardParams, SYSTEM_FLUID.water.value, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams), SessionParamsEnum.SessionID.getContextValue(sessionParams));
                    setSystemFluid(SYSTEM_FLUID.water.value)
                }
            }
        }
    }, [WizardParamEnum.SystemFluidMain.getContextValue(wizardParams), systemFluidOptions])

    useEffect(() => {
        if (WizardParamEnum.SystemFluidPrimaryMixRatio.getContextValue(wizardParams) != null) {
            setSystemFluidMixRatio(WizardParamEnum.SystemFluidPrimaryMixRatio.getContextValue(wizardParams))
        }
        else {
            if (systemFluidOptions != null && systemFluidOptions.length > 0) {
                const fluidOption = systemFluidOptions.find(findSystemFluid);
                if (fluidOption != null && fluidOption?.defaultMixRatio != null) {
                    WizardParamEnum.SystemFluidPrimaryMixRatio.setContextValue(wizardParams, fluidOption?.defaultMixRatio, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams), SessionParamsEnum.SessionID.getContextValue(sessionParams));
                    setSystemFluidMixRatio(fluidOption?.defaultMixRatio);
                }
            }
        }
    }, [WizardParamEnum.SystemFluidPrimaryMixRatio.getContextValue(wizardParams), systemFluidOptions])

    useEffect(() => {
        if (WizardParamEnum.SystemFluidPrimaryMixRatioUnknown.getContextValue(wizardParams) != null) {
            setSystemFluidMixRatioUnknown(WizardParamEnum.SystemFluidPrimaryMixRatioUnknown.getContextValue(wizardParams))
        }
    }, [WizardParamEnum.SystemFluidPrimaryMixRatioUnknown.getContextValue(wizardParams)])

    const answerQuestion = (key, value) => {
        if (isInDriftCalc) {
            if (SessionParamsEnum.MaxStep.getContextValue(sessionParams) !== SessionParamsEnum.CurrentStep.getContextValue(sessionParams)) {
                SessionParamsEnum.MaxStep.setContextValue(sessionParams, SessionParamsEnum.CurrentStep.getContextValue(sessionParams), database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
            }

            CalcParamsEnum.clearData(calcParams);
            AdjustmentsEnum.clearData(adjustParams);
        }

        switch (key) {
            case QUESTION_SYSTEM_FLUID_KEY:
                WizardParamEnum.SystemFluidMain.setContextValue(wizardParams, value, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams), SessionParamsEnum.SessionID.getContextValue(sessionParams));
                break;
            case QUESTION_SYSTEM_FLUID_MIX_RATIO_KEY:
                WizardParamEnum.SystemFluidPrimaryMixRatio.setContextValue(wizardParams, value, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams), SessionParamsEnum.SessionID.getContextValue(sessionParams));
                break;
            case QUESTION_SYSTEM_FLUID_MIX_RATIO_UNKNOWN_KEY:
                WizardParamEnum.SystemFluidPrimaryMixRatioUnknown.setContextValue(wizardParams, value, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams), SessionParamsEnum.SessionID.getContextValue(sessionParams));
                break;
            default:
                break;
        }
    }



    return (
        <>
            <Box zIndex={99} sx={{}} minWidth={'200px'} display={'flex'} flexDirection={'row'} justifyContent={'right'} marginTop={'-22px'} marginRight={'-5px'}>
                <Box sx={{ cursor: isDisabled ? 'default' : 'pointer', border: '1px solid #c4c4c4', borderRadius: '4px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Box onClick={handleOpenMenu} height={'21px'} paddingTop={'4px'} fontWeight={700} paddingLeft={'6px'} paddingRight={'5px'}>
                        Systemvätska:
                    </Box>
                    <Box onClick={handleOpenMenu} height={'21px'} paddingTop={'4px'}>
                        {getSystemFluidSelected(systemFluid) != null ? (
                            getSystemFluidSelected(systemFluid).paramLabel
                        ) : ("")}


                        {getSystemFluidSelected(systemFluid)?.defaultMixRatio != null && systemFluidMixRatioUnknown !== true ? (
                            " " + systemFluidMixRatio + "%"
                        ) : ("")}
                    </Box>
                    <Box height={'22px'} paddingTop={'-10px'} paddingBottom={'4px'}>
                        <IconButton sx={{ marginTop: '-2px' }} ref={buttonRef} onClick={handleOpenMenu} size="small" disabled={isDisabled}>
                            {openMenu ? (
                                <KeyboardArrowUpRoundedIcon />
                            ) : (
                                <KeyboardArrowDownRoundedIcon />
                            )}
                        </IconButton>
                    </Box>
                </Box>

            </Box>

            <SystemFluidMenu
                id={'systemFluidMenu'}
                anchorEl={anchorEl}
                open={openMenu}
                handleClose={handleCloseMenu}
                systemFluid={systemFluid}
                systemFluidMixRatio={systemFluidMixRatio}
                systemFluidMixRatioUnknown={systemFluidMixRatioUnknown}
                answerQuestion={answerQuestion}
            />
        </>
    )
}

