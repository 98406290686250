export const ADDON_LABELS = {
    'mixingVessel': 'Glykolblandningskärl'
}

export const ADDON_CONNECTION_IMAGES = {
    'mixingVessel': 'PRE-BW-a'
}

export const ADDON_MEASUREMENT_DATA = {
    'mixingVessel': {
        'PRE-BW-60': {
            'Effektiv volym': '60 liter',
            'Vikt': '15kg',
            'D': "450mm",
            'H': '500mm',
            'H2*': '880mm',
        },
        'PRE-BW-100': {
            'Effektiv volym': '100 liter',
            'Vikt': '18kg',
            'D': "520mm",
            'H': '650mm',
            'H2*': '1030mm',
        },
        'PRE-BW-200': {
            'Effektiv volym': '200 liter',
            'Vikt': '22kg',
            'D': "520mm",
            'H': '1190mm',
            'H2*': '1570mm',
        },
        'PRE-BW-300': {
            'Effektiv volym': '300 liter',
            'Vikt': '31kg',
            'D': "650mm",
            'H': '1135mm',
            'H2*': '1515mm',
        },
        'PRE-BW-500': {
            'Effektiv volym': '500 liter',
            'Vikt': '40kg',
            'D': "750mm",
            'H': '1385mm',
            'H2*': '1765mm',
        },
        'PRE-BW-1000': {
            'Effektiv volym': '1000 liter',
            'Vikt': '60kg',
            'D': "1023mm",
            'H': '1380mm',
            'H2*': '1760mm',
        },
        'PRE-BW-60 green': {
            'Effektiv volym': '60 liter',
            'Vikt': '15kg',
            'D': "450mm",
            'H': '500mm',
            'H2*': '980mm',
        },
        'PRE-BW-100 green': {
            'Effektiv volym': '100 liter',
            'Vikt': '18kg',
            'D': "520mm",
            'H': '650mm',
            'H2*': '1130mm',
        },
        'PRE-BW-200 green': {
            'Effektiv volym': '200 liter',
            'Vikt': '22kg',
            'D': "520mm",
            'H': '1190mm',
            'H2*': '1670mm',
        },
        'PRE-BW-300 green': {
            'Effektiv volym': '300 liter',
            'Vikt': '31kg',
            'D': "650mm",
            'H': '1135mm',
            'H2*': '1615mm',
        },
        'PRE-BW-500 green': {
            'Effektiv volym': '500 liter',
            'Vikt': '40kg',
            'D': "750mm",
            'H': '1385mm',
            'H2*': '1865mm',
        },
        'PRE-BW-1000 green': {
            'Effektiv volym': '1000 liter',
            'Vikt': '60kg',
            'D': "1023mm",
            'H': '1380mm',
            'H2*': '1860mm',
        },
    }

}