import { useState } from "react";
import { SupportModule } from "../../components/modules/support/supportModal";

export const NewsVersion_1_6 = () => {
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => { setOpenModal(true) }
    const handleCloseModal = () => { setOpenModal(false) }

    const btnStyle = {
        'background': 'none',
        'border': 'none',
        'padding': '0',
        'color': '#003eaa',
        'fontWeight': 'bold',
        'textDecoration': 'underline',
        'cursor': 'pointer',
    }

    return (
        <>
            <div>
                <h2 className="intro-title" style={{ marginBlockStart: '0px' }}>Nyheter i ShuntLogik<sup>®</sup> 1.6</h2>

                <p>
                    Utvecklingen av ShuntLogik<sup>®</sup> 1.6  har haft sin utgångspunkt i våra kunders prioriterade önskemål och innebär ett stort lyft
                    vad gäller möjligheter till ännu snabbare support och rådgivning från PREMA. Hanteringen av beräkningarna i projekten
                    har också effektiviserats genom smartare sessionshantering. För användare som arbetar med miljöutföranden i sina
                    projekt finns nu också integrerad rådgivning för val av rätt ’grön’ produktlinje.
                </p>

                <p>De viktigaste nyheterna i ShuntLogik<sup>®</sup> 1.6 är</p>

                <ul>
                    <li>Användarkonton: Möjligheter för externa användare att ansöka om konto för att spara beräkningar och återuppta eller dela tidigare körningar</li>
                    <li>Sessionsdelning: Förenklad hantering av sessionsdelning, inklusive interagerbara felmeddelanden för att direkt kunna dela körningar med PREMA och få snabb support</li>
                    <li>
                        <strong>Connected™</strong> tillbehör: Möjligheter att lägga till uppkopplingsbara tillbehör, som exempelvis energimätare, och få med dessa i shunt-beräkningarna:
                        <ul>
                            <li><strong>Connected™</strong> Energi</li>
                            <li><strong>Connected™</strong> Temperatur</li>
                            <li><strong>Connected™</strong> Differenstryck</li>
                        </ul>
                    </li>
                    <li>Hantering av systemvätska: Ytterligare vägledning vid dimensionering med alternativ systemvätska, samt stöd för dimensionering baserat på kW</li>
                    <li>Byte av produktlinje och/eller kopplingsprincip: Förenklad hantering vid byte av produktlinje eller kopplingsprincip efter påbörjad dimensionering</li>
                </ul>

                <p>Vi tar tacksamt emot <button style={btnStyle} onClick={handleOpenModal}>tips och synpunkter</button>, så kanske just dina önskemål kommer finnas med i förteckningen över framtida versionsnyheter!</p>
            </div>

            <SupportModule openModal={openModal} handleModalClose={handleCloseModal} />
        </>
    )
}