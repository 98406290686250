import { get, onChildChanged, onValue, ref } from "firebase/database";
import { SESSION_PATH, SESSION_SHUNT_DESIGNATIONS_PATH, SESSION_SHUNT_PATH } from "../../constants/firebasePaths";
import { SessionParamsEnum } from "../../enums/sessionParamsEnum"
import { useEffect, useState } from "react";
import { useSessionParams } from "../../contexts";
import { useDatabase } from "reactfire";
import { ADDON_LABELS } from "../../constants/addonLabels";
import { PRODUCTLINE_GREEN, PRODUCTLINE_STANDARD } from "../../constants/productlineConstants";

/**
 * 
 * @returns {Array}
 */
const useGetShuntList = function useGetShuntList() {
    const database = useDatabase();
    const sessionParams = useSessionParams();
    const [shuntList, setShuntList] = useState([]);
    const sessionID = SessionParamsEnum.SessionID.getContextValue(sessionParams);

    const shuntsRef = ref(database, SESSION_PATH + sessionID + SESSION_SHUNT_PATH);

    useEffect(() => {
        onValue(shuntsRef, (snap) => {
            const shuntIDs = [];

            snap.forEach((child) => {
                shuntIDs.push(child.key);
                //console.log("RES: ", child.key);
            });

            setShuntList(shuntIDs);
        });
    }, [])

    return shuntList;
}

const getShuntListName = async function getShuntListName(database, sessionParams) {
    const sessionID = SessionParamsEnum.SessionID.getContextValue(sessionParams);

    const shuntsRef = ref(database, SESSION_PATH + sessionID + SESSION_SHUNT_PATH);

    const result = await get(shuntsRef);

    const shuntIDs = [];
    const shuntPos = {};
    const shuntNames = [];

    result.forEach((child) => {
        shuntIDs.push(child.key);
        shuntPos[child.key] = child.val();
        //console.log("RES: ", child.key);
    });

    let shuntSpecRef;
    let shuntDataSpecRef;
    let shuntMsgRef;
    let shuntPLSpecRef;
    let shuntResult;
    let shuntDataSpecResult;
    let shuntMsgResult;
    let shuntPLResult;

    for (let index = 0; index < shuntIDs.length; index++) {
        //console.log("Index: ", index)
        const shuntID = shuntIDs[index];
        shuntSpecRef = ref(database, SESSION_SHUNT_PATH + shuntID);
        shuntResult = await get(shuntSpecRef);
        //shuntPLResult = await get(shuntPLSpecRef);

        let pos = "";
        let desig = "";
        let maxStep = "";
        let model = "";
        let fullModel = "";
        let addonType = "";
        let isCustom = false;
        let isStarted = false;
        let hasMixingVessel = false;
        let isAddon = false;
        let isSpecEdit = false;
        let isSpecEditModified = false;
        let hasErrors = false;
        let isFound = false;

        if (shuntResult.exists()) {
            isFound = true;
            if (shuntResult.hasChild('Baseinfo')) {
                if (shuntResult.child('Baseinfo').hasChild('position')) {
                    pos = shuntResult.child('Baseinfo').child('position').val();
                }

                if (shuntResult.child('Baseinfo').hasChild('designation')) {
                    desig = shuntResult.child('Baseinfo').child('designation').val();
                }

                if (shuntResult.child('Baseinfo').hasChild('maxStep')) {
                    maxStep = shuntResult.child('Baseinfo').child('maxStep').val();
                }

                if (shuntResult.child('Baseinfo').hasChild('isCustom')) {
                    isCustom = shuntResult.child('Baseinfo').child('isCustom').val();
                }

                //console.log("Here? Shuntlist", shuntID, shuntResult.child('ShuntData').val())
            }

            if (shuntResult.child('ShuntData').exists()) {
                if (shuntResult.child('ShuntData').child('isAddon').exists() && shuntResult.child('ShuntData').child('isAddon').val() === true) {
                    isAddon = true;

                    if (shuntResult.child('ShuntData').child('addonType').exists()) {
                        addonType = shuntResult.child('ShuntData').child('addonType').val();
                    }
                }

                if (shuntResult.child('ShuntData').child('Addons').exists() && shuntResult.child('ShuntData').child('Addons').child('mixingVessel').exists()) {
                    if (shuntResult.child('ShuntData').child('Addons').child('mixingVessel').val() !== "") {
                        hasMixingVessel = true;
                    }
                }
            }

            if (shuntResult.child('SpecData').exists()) {
                if (shuntResult.child('SpecData').child('specList').exists() || shuntResult.child('SpecData').child('connList').exists() || shuntResult.child('SpecData').child('measurementList').exists() || shuntResult.child('SpecData').child('techList').exists()) {
                    isSpecEdit = true;

                    if (shuntResult.child('SpecData').child('isModified').exists() && shuntResult.child('SpecData').child('isModified').val() === true) {
                        isSpecEditModified = true;
                    }
                }
            }

            if (shuntResult.hasChild('MsgLists') && shuntResult.child('MsgLists').hasChild('errors')) {
                hasErrors = true;
            }

            if (shuntResult.hasChild('PLData') && shuntResult.child('PLData').child('princip').exists() && shuntResult.child('PLData').child('princip').val() !== '') {
                isStarted = true;
            }

            if (shuntResult.hasChild('ShuntData') && shuntResult.child('ShuntData').child('plModel').exists()) {
                model = shuntResult.child('ShuntData').child('plModel').val()

                if (shuntResult.hasChild('PLData') && shuntResult.child('PLData').child('prodLine').exists()) {
                    if (shuntResult.child('PLData').child('prodLine').val() !== PRODUCTLINE_STANDARD) {
                        fullModel = model + " " + shuntResult.child('PLData').child('prodLine').val();

                        if (shuntResult.child('PLData').child('prodLine').val() === PRODUCTLINE_GREEN && shuntResult.child('PLData').child('greenLevel').exists()) {
                            if (shuntResult.child('PLData').child('greenLevel').val() === 2 || shuntResult.child('PLData').child('greenLevel').val() === '2') {
                                fullModel += " II"
                            }
                            else {
                                fullModel += " I"
                            }
                        }
                    }
                    else {
                        fullModel = model;
                    }
                }
            }
        }

        let name;

        if (isAddon === true) {
            if (shuntPos[shuntID] != null && Number(shuntPos[shuntID]) !== -1) {
                name = '(Pos.' + shuntPos[shuntID] + ') - ' + ADDON_LABELS[addonType];
            }
            else {
                name = '(Pos. Ej satt) - ' + ADDON_LABELS[addonType];
            }

            shuntNames.push({
                id: shuntID,
                pos: shuntPos[shuntID],
                desig: desig,
                maxStep: maxStep,
                isCustom: isCustom,
                isStarted: isStarted,
                isFound: isFound,
                isAddon: isAddon,
                isSpecEdit: isSpecEdit,
                isSpecEditModified: isSpecEditModified,
                addonType: addonType,
                hasErrors: hasErrors,
                plModel: model,
                fullPlModel: fullModel,
                fullName: name
            });
        }
        else {
            if (shuntPos[shuntID] != null && Number(shuntPos[shuntID]) !== -1) {
                name = '(Pos.' + pos + ') - ' + desig;
            }
            else {
                name = '(Pos. Ej satt) - ' + desig;
            }

            shuntNames.push({
                id: shuntID,
                pos: pos,
                desig: desig,
                maxStep: maxStep,
                isCustom: isCustom,
                isStarted: isStarted,
                isFound: isFound,
                isSpecEdit: isSpecEdit,
                isSpecEditModified: isSpecEditModified,
                hasErrors: hasErrors,
                plModel: model,
                fullPlModel: fullModel,
                fullName: name,
                hasMixingVessel: hasMixingVessel
            });
        }

    }

    //console.log("LIST: ", shuntNames)
    return shuntNames;
}


const useGetShuntListName = () => {
    const [shuntList, setShuntList] = useState([]);
    const [loading, setLoading] = useState(true);

    const sessionParams = useSessionParams();
    const database = useDatabase();

    useEffect(() => {
        if (SessionParamsEnum.SessionID.getContextValue(sessionParams) != null && SessionParamsEnum.SessionID.getContextValue(sessionParams) !== "") {
            const sessionID = SessionParamsEnum.SessionID.getContextValue(sessionParams);
            const shuntsRef = ref(database, SESSION_PATH + sessionID + SESSION_SHUNT_PATH);

            const listenRef = onValue(shuntsRef, (async (snapshot) => {
                setLoading(true);
                const shuntIDs = [];
                const shuntPos = {};
                const shuntNames = [];

                snapshot.forEach((child) => {
                    shuntIDs.push(child.key);
                    //console.log("Val: ", child.key, child.val())
                    shuntPos[child.key] = child.val();
                });

                let shuntSpecRef;
                let shuntPLSpecRef;
                let shuntResult;
                let shuntPLResult;

                for (let index = 0; index < shuntIDs.length; index++) {
                    //console.log("Index: ", index)
                    const shuntID = shuntIDs[index];
                    shuntSpecRef = ref(database, SESSION_SHUNT_PATH + shuntID);
                    shuntResult = await get(shuntSpecRef);
                    //shuntPLResult = await get(shuntPLSpecRef);

                    let pos = "";
                    let desig = "";
                    let maxStep = "";
                    let model = "";
                    let fullModel = "";
                    let addonType = "";
                    let isCustom = false;
                    let isStarted = false;
                    let hasMixingVessel = false;
                    let isAddon = false;
                    let isSpecEdit = false;
                    let isSpecEditModified = false;
                    let hasErrors = false;
                    let isFound = false;

                    if (shuntResult.exists()) {
                        isFound = true;
                        if (shuntResult.hasChild('Baseinfo')) {
                            if (shuntResult.child('Baseinfo').hasChild('position')) {
                                pos = shuntResult.child('Baseinfo').child('position').val();
                            }

                            if (shuntResult.child('Baseinfo').hasChild('designation')) {
                                desig = shuntResult.child('Baseinfo').child('designation').val();
                            }

                            if (shuntResult.child('Baseinfo').hasChild('maxStep')) {
                                maxStep = shuntResult.child('Baseinfo').child('maxStep').val();
                            }

                            if (shuntResult.child('Baseinfo').hasChild('isCustom')) {
                                isCustom = shuntResult.child('Baseinfo').child('isCustom').val();
                            }

                            //console.log("Here? Shuntlist", shuntID, shuntResult.child('ShuntData').val())
                        }

                        if (shuntResult.child('ShuntData').exists()) {
                            if (shuntResult.child('ShuntData').child('isAddon').exists() && shuntResult.child('ShuntData').child('isAddon').val() === true) {
                                isAddon = true;

                                if (shuntResult.child('ShuntData').child('addonType').exists()) {
                                    addonType = shuntResult.child('ShuntData').child('addonType').val();
                                }
                            }

                            if (shuntResult.child('ShuntData').child('Addons').exists() && shuntResult.child('ShuntData').child('Addons').child('mixingVessel').exists()) {
                                if (shuntResult.child('ShuntData').child('Addons').child('mixingVessel').val() !== "") {
                                    hasMixingVessel = true;
                                }
                            }
                        }

                        if (shuntResult.child('SpecData').exists()) {
                            if (shuntResult.child('SpecData').child('specList').exists() || shuntResult.child('SpecData').child('connList').exists() || shuntResult.child('SpecData').child('measurementList').exists() || shuntResult.child('SpecData').child('techList').exists()) {
                                isSpecEdit = true;

                                if (shuntResult.child('SpecData').child('isModified').exists() && shuntResult.child('SpecData').child('isModified').val() === true) {
                                    isSpecEditModified = true;
                                }
                            }
                        }

                        if (shuntResult.hasChild('MsgLists') && shuntResult.child('MsgLists').hasChild('errors')) {
                            hasErrors = true;
                        }

                        if (shuntResult.hasChild('PLData') && shuntResult.child('PLData').child('princip').exists() && shuntResult.child('PLData').child('princip').val() !== '') {
                            isStarted = true;
                        }

                        if (shuntResult.hasChild('ShuntData') && shuntResult.child('ShuntData').child('plModel').exists()) {
                            model = shuntResult.child('ShuntData').child('plModel').val()

                            if (shuntResult.hasChild('PLData') && shuntResult.child('PLData').child('prodLine').exists()) {
                                if (shuntResult.child('PLData').child('prodLine').val() !== PRODUCTLINE_STANDARD) {
                                    fullModel = model + " " + shuntResult.child('PLData').child('prodLine').val();

                                    if (shuntResult.child('PLData').child('prodLine').val() === PRODUCTLINE_GREEN && shuntResult.child('PLData').child('greenLevel').exists()) {
                                        if (shuntResult.child('PLData').child('greenLevel').val() === 2 || shuntResult.child('PLData').child('greenLevel').val() === '2') {
                                            fullModel += " II"
                                        }
                                        else {
                                            fullModel += " I"
                                        }
                                    }
                                }
                                else {
                                    fullModel = model;
                                }
                            }
                        }
                    }

                    let name;

                    if (isAddon === true) {
                        if (shuntPos[shuntID] != null && Number(shuntPos[shuntID]) !== -1) {
                            name = '(Pos.' + shuntPos[shuntID] + ') - ' + ADDON_LABELS[addonType];
                        }
                        else {
                            name = '(Pos. Ej satt) - ' + ADDON_LABELS[addonType];
                        }

                        shuntNames.push({
                            id: shuntID,
                            pos: shuntPos[shuntID],
                            desig: desig,
                            maxStep: maxStep,
                            isCustom: isCustom,
                            isStarted: isStarted,
                            isFound: isFound,
                            isAddon: isAddon,
                            isSpecEdit: isSpecEdit,
                            isSpecEditModified: isSpecEditModified,
                            addonType: addonType,
                            hasErrors: hasErrors,
                            plModel: model,
                            fullPlModel: fullModel,
                            fullName: name
                        });
                    }
                    else {
                        if (shuntPos[shuntID] != null && Number(shuntPos[shuntID]) !== -1) {
                            name = '(Pos.' + pos + ') - ' + desig;
                        }
                        else {
                            name = '(Pos. Ej satt) - ' + desig;
                        }

                        shuntNames.push({
                            id: shuntID,
                            pos: pos,
                            desig: desig,
                            maxStep: maxStep,
                            isCustom: isCustom,
                            isStarted: isStarted,
                            isFound: isFound,
                            isSpecEdit: isSpecEdit,
                            isSpecEditModified: isSpecEditModified,
                            hasErrors: hasErrors,
                            plModel: model,
                            fullPlModel: fullModel,
                            fullName: name,
                            hasMixingVessel: hasMixingVessel
                        });
                    }

                }

                setShuntList(shuntNames);
                setLoading(false);
            }));

            return () => {
                listenRef();
            }
        }
    }, [SessionParamsEnum.SessionID.getContextValue(sessionParams), SessionParamsEnum.UpdateValues.getContextValue(sessionParams)]);

    return { shuntList, loading };
}


export { useGetShuntList, getShuntListName, useGetShuntListName }