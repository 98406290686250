import { CABINET_INSULATED, CABINET_UNINSULATED, CONN_DESIGN_U, CONN_VALUE_NOT_SPECIFIED, CUTOFF_3601_2301, CUTOFF_3701_2301, CUTOFF_GENERIC, CUTOFF_GENERIC_LOW_LEAD, INSULATION_13ARMAFLEX, INSULATION_13NH_SMART, INSULATION_13ULTIMA, INSULATION_19ARMAFLEX, INSULATION_19NH_SMART, INSULATION_19ULTIMA, MATERIAL_PAINTED, NO_MANOMETER, NO_MIXINGVESSEL, STAND_FLOOR, THERMO_BIMETALLIC } from "../adjustmentsOptions"

export class AdjustmentDefault {
    static SRDefault = {
        connectionDesign: CONN_DESIGN_U,
        connectionValue: CONN_VALUE_NOT_SPECIFIED,
        material: MATERIAL_PAINTED,
        thermo: THERMO_BIMETALLIC,
        cutOff: CUTOFF_3601_2301,
        manometer: NO_MANOMETER,
        stand: STAND_FLOOR,
        cabinet: CABINET_INSULATED,
    }

    static SRFlexDefault = {
        connectionDesign: CONN_DESIGN_U,
        connectionValue: CONN_VALUE_NOT_SPECIFIED,
        material: MATERIAL_PAINTED,
        thermo: THERMO_BIMETALLIC,
        cutOff: CUTOFF_3601_2301,
        manometer: NO_MANOMETER,
        stand: STAND_FLOOR,
        cabinet: CABINET_INSULATED,
    }

    static SRGreenDefault = {
        connectionDesign: CONN_DESIGN_U,
        connectionValue: CONN_VALUE_NOT_SPECIFIED,
        material: MATERIAL_PAINTED,
        thermo: THERMO_BIMETALLIC,
        cutOff: CUTOFF_3701_2301,
        manometer: NO_MANOMETER,
        stand: STAND_FLOOR,
        cabinet: CABINET_INSULATED,
    }

    static SRSmartDefault = {
        connectionDesign: CONN_DESIGN_U,
        connectionValue: CONN_VALUE_NOT_SPECIFIED,
        material: MATERIAL_PAINTED,
        thermo: THERMO_BIMETALLIC,
        cutOff: CUTOFF_3601_2301,
        manometer: NO_MANOMETER,
        stand: STAND_FLOOR,
        cabinet: CABINET_INSULATED,
        actuators: false,
    }

    static PRDefault = {
        connectionDesign: CONN_DESIGN_U,
        connectionValue: CONN_VALUE_NOT_SPECIFIED,
        material: MATERIAL_PAINTED,
        thermo: THERMO_BIMETALLIC,
        cutOff: CUTOFF_3601_2301,
        manometer: NO_MANOMETER,
        stand: STAND_FLOOR,
        cabinet: CABINET_INSULATED,
    }

    static PRGreenDefault = {
        connectionDesign: CONN_DESIGN_U,
        connectionValue: CONN_VALUE_NOT_SPECIFIED,
        material: MATERIAL_PAINTED,
        thermo: THERMO_BIMETALLIC,
        cutOff: CUTOFF_3701_2301,
        manometer: NO_MANOMETER,
        stand: STAND_FLOOR,
        cabinet: CABINET_INSULATED,
    }

    static VADefault = {
        connectionDesign: CONN_DESIGN_U,
        connectionValue: CONN_VALUE_NOT_SPECIFIED,
        material: MATERIAL_PAINTED,
        thermo: THERMO_BIMETALLIC,
        cutOff: CUTOFF_3601_2301,
        manometer: NO_MANOMETER,
        stand: STAND_FLOOR,
        cabinet: CABINET_INSULATED,
    }

    static VAGreenDefault = {
        connectionDesign: CONN_DESIGN_U,
        connectionValue: CONN_VALUE_NOT_SPECIFIED,
        material: MATERIAL_PAINTED,
        thermo: THERMO_BIMETALLIC,
        cutOff: CUTOFF_3701_2301,
        manometer: NO_MANOMETER,
        stand: STAND_FLOOR,
        cabinet: CABINET_INSULATED,
    }

    static CustomDefault = {
        connectionValue: CONN_VALUE_NOT_SPECIFIED,
        material: MATERIAL_PAINTED,
        thermo: THERMO_BIMETALLIC,
        cutOff: CUTOFF_3601_2301,
        manometer: NO_MANOMETER,
        stand: STAND_FLOOR,
        cabinet: CABINET_INSULATED,
    }

    static Isolation_Below2Degrees = {
        isolation: INSULATION_19ARMAFLEX,
        dripPlate: true,
        cabinet: CABINET_UNINSULATED,
    }

    static IsolationGreen_Below2Degrees = {
        isolation: INSULATION_19NH_SMART,
        dripPlate: true,
        cabinet: CABINET_UNINSULATED,
    }

    static IsolationGreenII_Below2Degrees = {
        isolation: INSULATION_19NH_SMART,
        dripPlate: true,
        cabinet: CABINET_UNINSULATED,
    }

    static Isolation_Below18Degrees = {
        isolation: INSULATION_13ARMAFLEX,
        dripPlate: true,
        cabinet: CABINET_UNINSULATED,
    }

    static IsolationGreen_Below18Degrees = {
        isolation: INSULATION_13NH_SMART,
        dripPlate: true,
        cabinet: CABINET_UNINSULATED,
    }

    static IsolationGreenII_Below18Degrees = {
        isolation: INSULATION_13NH_SMART,
        dripPlate: true,
        cabinet: CABINET_UNINSULATED,
    }
}