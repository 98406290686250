import { MenuItem, Select, Stack } from '@mui/material';
import { useDatabase, useFirestore, useFirestoreCollection } from 'reactfire';
import { collection, getDocs } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { CONTROL_VALVES_PATH, CONTROL_VALVES_MODELS_IMI_PATH } from '../../../../constants/firebasePaths';
import { useProductLineParams, useShuntParams, useDriftParams, useCalcParams, useAdjustParams, useSessionParams, useDevbarParams } from '../../../../contexts';
import { DriftParamEnum, SessionParamsEnum, ShuntParamsEnum, CalcParamsEnum, AdjustmentsEnum, ProductLineEnum } from '../../../../enums';
import { FlexFlowSelectField } from './flexFlowField';
import { resetMaxStepFunc } from '../../../../functions/HelperFunctions/resetMaxStep';


export const FlexSelectField = () => {
    const productParams = useProductLineParams();
    const shuntParams = useShuntParams();
    const driftParams = useDriftParams();
    const calcParams = useCalcParams();
    const adjustParams = useAdjustParams();
    const firestore = useFirestore();
    const sessionParams = useSessionParams();
    const devParams = useDevbarParams();
    const database = useDatabase();

    const [models, setModels] = useState([]);
    const [update, setUpdate] = useState(false);
    const [modelSelect, setModelSelect] = useState('');

    const checkCalcMode = () => {
        if (DriftParamEnum.CalcMode.getContextValue(driftParams) === "0" || DriftParamEnum.CalcMode.getContextValue(driftParams) === 0) {
            return false;
        }
        else {
            return true;
        }
    }

    const isDisabled = () => {
        if ((SessionParamsEnum.Editable.getContextValue(sessionParams) === false && devParams.state.val.devMode !== true) ||
            (DriftParamEnum.CalcMode.getContextValue(driftParams) !== "0" && DriftParamEnum.CalcMode.getContextValue(driftParams) !== 0)) {
            return true;
        }
        else {
            return false;
        }
    }

    const setOrgUrl = () => {
        if (ShuntParamsEnum.ControlValve.getContextValue(shuntParams) != null && ShuntParamsEnum.ControlValve.getContextValue(shuntParams) !== "" && !checkCalcMode()) {
            return CONTROL_VALVES_PATH + ShuntParamsEnum.ControlValve.getContextValue(shuntParams) + '/Models';
        }
        else {
            return CONTROL_VALVES_MODELS_IMI_PATH;
        }
    }

    const [fsUrl, setFSUrl] = useState(setOrgUrl())

    /*
    if (ShuntParamsEnum.ControlValve.getContextValue(shuntParams) != null && ShuntParamsEnum.ControlValve.getContextValue(shuntParams) !== "" && !checkCalcMode()) {
        fsUrl = CONTROL_VALVES_PATH + ShuntParamsEnum.ControlValve.getContextValue(shuntParams) + '/Models';
    }
    else {
        fsUrl = CONTROL_VALVES_MODELS_IMI_PATH;
    }

    const optionsDoc = collection(firestore, fsUrl);
    const firestoreRes = useFirestoreCollection(optionsDoc);*/

    const setValue = (index) => {
        console.log("Index:", index, "model: ", models[index].id)

        if (ShuntParamsEnum.FlexModel.getContextValue(shuntParams) !== models[index].id) {
            resetMaxStepFunc(sessionParams, database);

            CalcParamsEnum.clearData(calcParams);
            AdjustmentsEnum.clearData(adjustParams);
            ShuntParamsEnum.FlexDimID.setContextValue(shuntParams, null);

            ShuntParamsEnum.FlexModel.setContextValue(shuntParams, models[index].id);
            DriftParamEnum.IVModification.setContextValue(driftParams, models[index].ivMod);

            if (models[index].miniIncompatible === true) {
                ShuntParamsEnum.IsMiniIncompatible.setContextValue(shuntParams, true);
            }
            else {
                ShuntParamsEnum.IsMiniIncompatible.setContextValue(shuntParams, false);
            }

        }


        setModelSelect(String(index));
    };

    /*
    const resetMaxStep = () => {
        if (SessionParamsEnum.MaxStep.getContextValue(sessionParams) !== SessionParamsEnum.CurrentStep.getContextValue(sessionParams)) {
            SessionParamsEnum.MaxStep.setContextValue(sessionParams, SessionParamsEnum.CurrentStep.getContextValue(sessionParams), database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
        }
    }*/

    useEffect(() => {
        if (ShuntParamsEnum.ControlValve.getContextValue(shuntParams) != null && ShuntParamsEnum.ControlValve.getContextValue(shuntParams) !== "" && !checkCalcMode()) {
            setFSUrl(CONTROL_VALVES_PATH + ShuntParamsEnum.ControlValve.getContextValue(shuntParams) + '/Models');
        }
        else {
            setFSUrl(CONTROL_VALVES_MODELS_IMI_PATH);
        }
    }, [ShuntParamsEnum.ControlValve.getContextValue(shuntParams)])

    useEffect(() => {
        if (fsUrl != null && fsUrl !== '') {
            const optionsDoc = collection(firestore, fsUrl);

            getDocs(optionsDoc).then((data) => {
                if (ShuntParamsEnum.ControlValve.getContextValue(shuntParams) == null || ShuntParamsEnum.ControlValve.getContextValue(shuntParams) === '') {
                    ShuntParamsEnum.ControlValve.setContextValue(shuntParams, 'IMI')
                }
                const localModels = [];

                data.forEach((model) => {
                    if (model.data().usedIn != null && (model.data().usedIn.includes(ProductLineEnum.ProductPrincip.getContextValue(productParams)) || model.data().usedIn.includes(ProductLineEnum.ProductPrincip.getContextValue(productParams) + "-v2"))) {
                        let localObject = {};
                        if (model.data().ivMod != null) {
                            localObject = { id: model.id, name: model.data().name, ivMod: model.data().ivMod, default_flex: model.data().default_flex };
                        }
                        else {
                            localObject = { id: model.id, name: model.data().name, ivMod: 0, default_flex: model.data().default_flex };
                        }

                        if (model.data().miniIncompatible != null) {
                            localObject['miniIncompatible'] = model.data().miniIncompatible;
                        }

                        localModels.push(localObject);
                    }
                });

                setModels(localModels);

                if (ShuntParamsEnum.FlexModel.getContextValue(shuntParams) != null && localModels.findIndex(obj => obj.id === ShuntParamsEnum.FlexModel.getContextValue(shuntParams)) !== -1) {
                    let index = localModels.findIndex(obj => obj.id === ShuntParamsEnum.FlexModel.getContextValue(shuntParams))
                    if (index !== -1) {
                        setModelSelect(String(index));
                        setUpdate(!update)
                    }
                }
                else if (localModels.length === 1) {
                    ShuntParamsEnum.FlexModel.setContextValue(shuntParams, localModels[0].id);
                    DriftParamEnum.IVModification.setContextValue(driftParams, localModels[0].ivMod);

                    if (localModels[0].miniIncompatible === true) {
                        ShuntParamsEnum.IsMiniIncompatible.setContextValue(shuntParams, true);
                    }
                    else {
                        ShuntParamsEnum.IsMiniIncompatible.setContextValue(shuntParams, false);
                    }


                    resetMaxStepFunc(sessionParams, database);
                    CalcParamsEnum.clearData(calcParams);

                    setModelSelect('0');
                    setUpdate(!update)
                }
                else if (localModels.length > 1) {
                    let defaultIndex = localModels.findIndex(obj => obj.default_flex === true);

                    if (defaultIndex !== -1) {
                        let defaultModel = localModels[defaultIndex];
                        console.log("Default: ", defaultModel)
                        ShuntParamsEnum.FlexModel.setContextValue(shuntParams, localModels[defaultIndex].id);
                        DriftParamEnum.IVModification.setContextValue(driftParams, localModels[defaultIndex].ivMod);

                        if (localModels[defaultIndex].miniIncompatible === true) {
                            ShuntParamsEnum.IsMiniIncompatible.setContextValue(shuntParams, true);
                        }
                        else {
                            ShuntParamsEnum.IsMiniIncompatible.setContextValue(shuntParams, false);
                        }


                        resetMaxStepFunc(sessionParams, database);
                        CalcParamsEnum.clearData(calcParams);

                        setModelSelect(String(defaultIndex));
                    }
                    else {
                        ShuntParamsEnum.FlexModel.setContextValue(shuntParams, null);
                        resetMaxStepFunc(sessionParams, database);
                        CalcParamsEnum.clearData(calcParams);

                        setModelSelect('-');
                    }

                    setUpdate(!update)
                }
            });
        }
    }, [fsUrl])

    useEffect(() => {
        if (modelSelect !== ShuntParamsEnum.FlexModel.getContextValue(shuntParams)){
            let defaultIndex = models.findIndex(obj => obj.id === ShuntParamsEnum.FlexModel.getContextValue(shuntParams));

            console.log("Set: ", ShuntParamsEnum.FlexModel.getContextValue(shuntParams), defaultIndex)
            setModelSelect(String(defaultIndex));
        }
    }, [ShuntParamsEnum.FlexModel.getContextValue(shuntParams)]);
    
    /*
    useEffect(() => {
        if (firestoreRes.data != null) {
            if (ShuntParamsEnum.ControlValve.getContextValue(shuntParams) == null || ShuntParamsEnum.ControlValve.getContextValue(shuntParams) === ''){
                ShuntParamsEnum.ControlValve.setContextValue(shuntParams, 'IMI')
            }
            const localModels = [];

            firestoreRes.data.forEach(model => {
                if (model.data().usedIn != null && model.data().usedIn.includes(ProductLineEnum.ProductPrincip.getContextValue(productParams))) {
                    let localObject = {};
                    if (model.data().ivMod != null) {
                        localObject = { id: model.id, name: model.data().name, ivMod: model.data().ivMod };
                    }
                    else {
                        localObject = { id: model.id, name: model.data().name, ivMod: 0 };
                    }

                    if (model.data().miniIncompatible != null) {
                        localObject['miniIncompatible'] = model.data().miniIncompatible;
                    }

                    localModels.push(localObject);
                }
            });

            setModels(localModels);
            if (ShuntParamsEnum.FlexModel.getContextValue(shuntParams) != null && localModels.findIndex(obj => obj.id === ShuntParamsEnum.FlexModel.getContextValue(shuntParams)) !== -1) {
                let index = localModels.findIndex(obj => obj.id === ShuntParamsEnum.FlexModel.getContextValue(shuntParams))
                if (index !== -1){
                    setModelSelect(String(index));
                    setUpdate(!update)
                }
            }
            else if (localModels.length === 1){
                ShuntParamsEnum.FlexModel.setContextValue(shuntParams, localModels[0].id);
                DriftParamEnum.IVModification.setContextValue(driftParams, localModels[0].ivMod);

                if (localModels[0].miniIncompatible === true) {
                    ShuntParamsEnum.IsMiniIncompatible.setContextValue(shuntParams, true);
                }
                else {
                    ShuntParamsEnum.IsMiniIncompatible.setContextValue(shuntParams, false);
                }

                
                resetMaxStep();
                CalcParamsEnum.clearData(calcParams);

                setModelSelect('0');
                setUpdate(!update)
            }
            else if (localModels.length > 1) {        
                ShuntParamsEnum.FlexModel.setContextValue(shuntParams, null);
                resetMaxStep();
                CalcParamsEnum.clearData(calcParams);

                setModelSelect('-');
                setUpdate(!update)
            }
        }

    }, [firestoreRes]);*/

    return (
        <>
            <Stack direction={"row"}>
                <div style={{ marginTop: "14px" }}>
                    Modell:
                </div>
                <Select
                    style={{ marginLeft: "auto", width: "50%", marginTop: "7px" }}
                    size={"small"}
                    value={modelSelect}
                    margin="dense"
                    onChange={(event) => { setValue(event.target.value) }}
                    disabled={isDisabled()}
                >
                    {models.map((model, index) => {
                        return (<MenuItem key={index} value={index}>{model.name}</MenuItem>)
                    })}

                </Select>
            </Stack>

            <FlexFlowSelectField />
        </>
    );
};