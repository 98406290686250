import { Box, Stack, Divider } from "@mui/material"
import { AppSourceValQuestion } from "./questions/sourceValQuestion"
import { ApplicationQuestion } from "./questions/applicationQuestion"
import { DrivingPressureQuestion } from "./questions/drivingPressureQuestion"
import { DimensionableQuestion } from "./questions/dimensionableQuestion"
import { HasMainPumpQuestion } from "./questions/hasMainPumpQuestion"
import { EnviromentCertQuestion } from "./questions/enviromentCertQuestion"
import { SystemFluidQuestion } from "./questions/systemFluidQuestion"
import { SecondaryFlowQuestion } from "./questions/secondaryFlowQuestion"
import { EnergyMeasureQuestion } from "./questions/energyMeasureQuestion"
import { SpecialCircumstancesQuestion } from "./questions/specialCircumstances"


export const WizardTab1Questions = ({
    answerQuestion,
    source,
    sourceFlow,
    application,
    applicationIntent,
    applicationSize,
    dimensionable,
    secondaryFlow,
    secondarySpecFlow,
    drivingPressure,
    dpIsVariable,
    dpSpecValue,
    hasMainPump,
    enviromentCert,
    systemFluid,
    systemFluidMixRatio,
    systemFluidMixRatioUnknown,
    systemFluidPrimary,
    systemFluidPrimaryMixRatio,
    systemFluidPrimaryMixRatioUnknown,
    systemFluidSecondary,
    systemFluidSecondaryMixRatio,
    systemFluidSecondaryMixRatioUnknown,
    stainless,
    limitedSpace,
    dynamicControlValve,
    energyMeasurment,
    resetVal,
}) => {


    return (
        <Box sx={{ padding: '5px' }}>
            <Stack spacing={1}>
                <AppSourceValQuestion appSource={source} application={application} sourceFlow={sourceFlow} answerQuestion={answerQuestion} resetVal={resetVal} />
                <ApplicationQuestion application={application} applicationIntent={applicationIntent} applicationSize={applicationSize} source={source} answerQuestion={answerQuestion} />
                <Divider />
                <DimensionableQuestion dimensionable={dimensionable} answerQuestion={answerQuestion} />
                <Divider />
                <DrivingPressureQuestion drivingPressure={drivingPressure} dpIsVariable={dpIsVariable} dpSpecValue={dpSpecValue} hasMainPump={hasMainPump} answerQuestion={answerQuestion} />
                <SecondaryFlowQuestion secondaryFlow={secondaryFlow} secondarySpecFlow={secondarySpecFlow} answerQuestion={answerQuestion} />
                <Divider />
                <HasMainPumpQuestion hasMainPump={hasMainPump} answerQuestion={answerQuestion} />
                <Divider />
                <EnviromentCertQuestion enviromentCert={enviromentCert} answerQuestion={answerQuestion} />
                <Divider />
                <SystemFluidQuestion
                    systemFluid={systemFluid}
                    systemFluidMixRatio={systemFluidMixRatio}
                    systemFluidMixRatioUnknown={systemFluidMixRatioUnknown}
                    systemFluidPrimary={systemFluidPrimary}
                    systemFluidPrimaryMixRatio={systemFluidPrimaryMixRatio}
                    systemFluidPrimaryMixRatioUnknown={systemFluidPrimaryMixRatioUnknown}
                    systemFluidSecondary={systemFluidSecondary}
                    systemFluidSecondaryMixRatio={systemFluidSecondaryMixRatio}
                    systemFluidSecondaryMixRatioUnknown={systemFluidSecondaryMixRatioUnknown}
                    answerQuestion={answerQuestion}
                />
                <Divider />
                <EnergyMeasureQuestion energyMeasurment={energyMeasurment} answerQuestion={answerQuestion} />
                <Divider />
                <SpecialCircumstancesQuestion
                    stainless={stainless}
                    limitedSpace={limitedSpace}
                    dynamicControlValve={dynamicControlValve}
                    answerQuestion={answerQuestion}
                />
            </Stack>

        </Box >
    )
}