import { get, ref, remove, set, update } from "firebase/database";
import { SESSION_PATH, SESSION_SHUNT_PATH } from "../../constants/firebasePaths";

/**
 * 
 * @param {*} database 
 * @param {string} shuntID 
 * @param {string} sessionID 
 * @param {boolean} isModified 
 * @returns 
 */
const setModifiedShuntRows = (database, shuntID, sessionID, isModified) => {
    if (shuntID != null && shuntID !== "" && sessionID != null && sessionID !== "") {
        const modifiedShuntRef = ref(database, SESSION_PATH + "/" + sessionID + "/ModifiedShunts/" + shuntID);

        if (isModified === true) {
            return set(modifiedShuntRef, 2);
        }
        else {
            return set(modifiedShuntRef, 1);
        }
    }

}

/**
 * 
 * @param {*} database 
 * @param {string} shuntID 
 * @param {string} sessionID 
 * @returns 
 */
const removeModifiedShuntRows = (database, shuntID, sessionID) => {
    if (shuntID != null && shuntID !== "" && sessionID != null && sessionID !== "") {
        const modifiedShuntRef = ref(database, SESSION_PATH + "/" + sessionID + "/ModifiedShunts/" + shuntID);

        return remove(modifiedShuntRef);
    }
}


const saveHeaderText = function saveHeaderText(database, shuntID, sessionID, headerText) {
    const specDataRef = ref(database, SESSION_SHUNT_PATH + "/" + shuntID + "/SpecData");

    let snapData = {
        headerText: headerText,
    };

    const promises = [];

    promises.push(update(specDataRef, snapData));
    promises.push(setModifiedShuntRows(database, shuntID, sessionID, false));

    return Promise.all(promises);
}

const saveTechRows = function saveTechRows(database, shuntID, sessionID, techList) {
    const specDataRef = ref(database, SESSION_SHUNT_PATH + "/" + shuntID + "/SpecData");

    let snapData = {
        techList: techList,
        isModified: false,
    };

    const promises = [];

    promises.push(update(specDataRef, snapData));
    promises.push(setModifiedShuntRows(database, shuntID, sessionID, false));

    return Promise.all(promises);
}

const saveSpecRows = function saveSpecRows(database, shuntID, sessionID, specList) {
    const specDataRef = ref(database, SESSION_SHUNT_PATH + "/" + shuntID + "/SpecData");

    let snapData = {
        specList: specList,
        isModified: false,
    };

    const promises = [];

    promises.push(update(specDataRef, snapData));
    promises.push(setModifiedShuntRows(database, shuntID, sessionID, false));

    return Promise.all(promises);
}

const removeSpecRows = async function removeSpecRows(database, shuntID, sessionID) {
    const specDataRef = ref(database, SESSION_SHUNT_PATH + "/" + shuntID + "/SpecData/specList");

    const promises = [];

    const snap = await get(ref(database, SESSION_SHUNT_PATH + "/" + shuntID + "/SpecData/"));

    if (snap.exists() === false || (snap.hasChild('connList') === false && snap.hasChild('measurementList') === false && snap.hasChild('techList') === false)) {
        promises.push(removeModifiedShuntRows(database, shuntID, sessionID));
    }

    promises.push(remove(specDataRef));

    return Promise.all(promises);
}

const saveConnRows = function saveConnRows(database, shuntID, connList, sessionID) {
    const specDataRef = ref(database, SESSION_SHUNT_PATH + "/" + shuntID + "/SpecData");

    let snapData = {
        connList: connList,
        isModified: false,
    };

    const promises = [];

    promises.push(update(specDataRef, snapData));
    promises.push(setModifiedShuntRows(database, shuntID, sessionID, false));

    return Promise.all(promises);
}

const removeConnRows = async function removeConnRows(database, shuntID, sessionID) {
    const specDataRef = ref(database, SESSION_SHUNT_PATH + "/" + shuntID + "/SpecData/connList");

    const promises = [];

    const snap = await get(ref(database, SESSION_SHUNT_PATH + "/" + shuntID + "/SpecData/"));

    if (snap.exists() === false || (snap.hasChild('specList') === false && snap.hasChild('measurementList') === false && snap.hasChild('techList') === false)) {
        promises.push(removeModifiedShuntRows(database, shuntID, sessionID));
    }

    promises.push(remove(specDataRef));

    return Promise.all(promises);
}


const saveMeasurementRows = function saveMeasurementRows(database, shuntID, sessionID, measurementList) {
    const measurementDataRef = ref(database, SESSION_SHUNT_PATH + "/" + shuntID + "/SpecData");

    let snapData = {
        measurementList: measurementList,
        isModified: false,
    };

    const promises = [];

    promises.push(update(measurementDataRef, snapData));
    promises.push(setModifiedShuntRows(database, shuntID, sessionID, false));

    return Promise.all(promises);
}

export { saveTechRows, saveSpecRows, saveMeasurementRows, saveHeaderText, saveConnRows, removeSpecRows, removeConnRows }