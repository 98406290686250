import { calculateShuntParamsFromFB } from "../../calculateShuntParamsFromFB";

/**
 * Recalculates the shunts with an list with baseInfo about the shunt
 * @param {Array} shuntList A list of shunt-Objects. **Unlike *recalculateShuntsArray* that takes an list of IDs**
 * @param {*} dimData 
 * @param {*} database 
 * @param {*} firestore 
 * @param {*} sessionParams 
 * @returns 
 */
const recalculateShunts = async function recalculateShunts(shuntList, dimData, database, firestore, sessionParams, coolingEnergyMeasurementList, heatEnergyMeasurementList, systemFluidOptions) {
    const promises = [];
    for (let index = 0; index < shuntList.length; index++) {
        const shunt = shuntList[index];
        //console.log("Shunt: ", shunt)
        if (shunt?.isAddon !== true) {
            promises.push(calculateShuntParamsFromFB(shunt.id, dimData, database, firestore, sessionParams, coolingEnergyMeasurementList, heatEnergyMeasurementList, systemFluidOptions));
        }
    }

    return Promise.all(promises);
}

/**
 * Recalculates the shunts with an list of shuntID's
 * @param {Array} shuntList A list with only the IDs. **Unlike *recalculateShunts* that takes an list of shunt-Objects**
 * @param {*} dimData 
 * @param {*} database 
 * @param {*} firestore 
 * @param {*} sessionParams 
 * @returns 
 */
const recalculateShuntsArray = async function recalculateShuntsArray(shuntList, dimData, database, firestore, sessionParams, coolingEnergyMeasurementList, heatEnergyMeasurementList, systemFluidOptions) {
    const promises = [];
    for (let index = 0; index < shuntList.length; index++) {
        const shuntID = shuntList[index];
        console.log("ShuntID: ", shuntID)
        promises.push(calculateShuntParamsFromFB(shuntID, dimData, database, firestore, sessionParams, coolingEnergyMeasurementList, heatEnergyMeasurementList, systemFluidOptions));
    }

    return Promise.all(promises);
}

export { recalculateShunts, recalculateShuntsArray }