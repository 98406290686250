import { ref, set, update } from "firebase/database";
import { SESSION_SHUNT_PATH } from "../../constants/firebasePaths";
import { BALANCING_KV_KEY, PRIM_INTERNAL_DP_KEY, PRIM_INTERNAL_PRESSURE_DROP_KEY, SEC_INTERNAL_PRESSURE_DROP_KEY, SV_PRESSURE_DROP_KEY, TOTAL_PRESSURE_KEY } from "../../constants/keys/calcParamsKeys";
import { SessionParamsEnum, CalcParamsEnum } from "../../enums";

const saveCalcData = function saveCalcData(database, sessionParams, calcParams) {
    const shuntID = SessionParamsEnum.CurrentShunt.getContextValue(sessionParams);
    const driftParamsRef = ref(database, SESSION_SHUNT_PATH + "/" + shuntID + "/CalcParams");

    
    const snapData = {
        kvs: CalcParamsEnum.Kvs.getContextValue(calcParams) || null,
        authority: CalcParamsEnum.Authority.getContextValue(calcParams) || null,
        shuntType: CalcParamsEnum.ShuntType.getContextValue(calcParams) || null,
        primDimRow: CalcParamsEnum.PrimDimRow.getContextValue(calcParams) || null,
        sekDimRow: CalcParamsEnum.SekDimRow.getContextValue(calcParams) || null,

        adjValve: CalcParamsEnum.AdjValve.getContextValue(calcParams) || null,
        controlValve: CalcParamsEnum.ControlValve.getContextValue(calcParams) || null,
        svDim: CalcParamsEnum.FlexSVDim.getContextValue(calcParams) || null,
        svDimObj: CalcParamsEnum.FlexSVDimObj.getContextValue(calcParams) || null,
        svMax: CalcParamsEnum.FlexSVMax.getContextValue(calcParams) || null,
        svSetting: CalcParamsEnum.FlexSVSetting.getContextValue(calcParams) || null,
        minPressure: CalcParamsEnum.MinPressure.getContextValue(calcParams) || null,
    }

    if (CalcParamsEnum.BalancingKv.getContextValue(calcParams) != null && CalcParamsEnum.BalancingKv.getContextValue(calcParams) !== ""){
        snapData[BALANCING_KV_KEY] = CalcParamsEnum.BalancingKv.getContextValue(calcParams);
    }

    if (CalcParamsEnum.SVPressureLoss.getContextValue(calcParams) != null && CalcParamsEnum.SVPressureLoss.getContextValue(calcParams) !== ""){
        snapData[SV_PRESSURE_DROP_KEY] = CalcParamsEnum.SVPressureLoss.getContextValue(calcParams);
    }

    if (CalcParamsEnum.PrimInternalPressureDrop.getContextValue(calcParams) != null && CalcParamsEnum.PrimInternalPressureDrop.getContextValue(calcParams) !== ""){
        snapData[PRIM_INTERNAL_PRESSURE_DROP_KEY] = CalcParamsEnum.PrimInternalPressureDrop.getContextValue(calcParams);
    }

    if (CalcParamsEnum.SecInternalPressureDrop.getContextValue(calcParams) != null && CalcParamsEnum.SecInternalPressureDrop.getContextValue(calcParams) !== ""){
        snapData[SEC_INTERNAL_PRESSURE_DROP_KEY] = CalcParamsEnum.SecInternalPressureDrop.getContextValue(calcParams);
    }

    if (CalcParamsEnum.TotalPressure.getContextValue(calcParams) != null && CalcParamsEnum.TotalPressure.getContextValue(calcParams) !== ""){
        snapData[TOTAL_PRESSURE_KEY] = CalcParamsEnum.TotalPressure.getContextValue(calcParams);
    }

    if (CalcParamsEnum.PrimInternalDP.getContextValue(calcParams) != null && CalcParamsEnum.PrimInternalDP.getContextValue(calcParams) !== ""){
        snapData[PRIM_INTERNAL_DP_KEY] = CalcParamsEnum.PrimInternalDP.getContextValue(calcParams);
    }

    let sendData = false;

    for (let index = 0; index < Object.keys(snapData).length; index++) {
        const element = Object.keys(snapData)[index];
        const value = snapData[element];
        if (value != null) {
            sendData = true;
        };
    }

    const promises = [];

    if (Object.keys(snapData).length !== 0 && sendData === true && SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true) {
        //console.log("CalcData:", snapData);
        promises.push(update(driftParamsRef, snapData));
    }

    return Promise.all(promises);
}

const saveMeasurementData = function saveMeasurementData(database, sessionParams, calcParams) {
    const shuntID = SessionParamsEnum.CurrentShunt.getContextValue(sessionParams);
    //console.log("ShuntID:", shuntID);
    const driftParamsRef = ref(database, SESSION_SHUNT_PATH + "/" + shuntID + "/CalcParams");

    const promises = [];

    if (CalcParamsEnum.MeasurementData.getContextValue(calcParams) != null) {
        const snapData = {
            measurementData: CalcParamsEnum.MeasurementData.getContextValue(calcParams),
        }

        if (SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true){
            promises.push(update(driftParamsRef, snapData));
        }
        
        //console.log("SaveMeasurements! ", shuntID, CalcParamsEnum.MeasurementData.getContextValue(calcParams));
    }

    return Promise.all(promises);
}

const clearCalcData = function clearCalcData(database, sessionParams) {
    const shuntID = SessionParamsEnum.CurrentShunt.getContextValue(sessionParams);

    const driftParamsRef = ref(database, SESSION_SHUNT_PATH + "/" + shuntID + "/CalcParams");

    const promises = [];

    if (SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true){
        promises.push(set(driftParamsRef, null));
    }

    return Promise.all(promises);
}

export { saveCalcData, clearCalcData, saveMeasurementData } 