export const PRIM_DIM_KEY = 'primDim';
export const SEC_DIM_KEY = 'sekDim';
export const PRIM_TEMP_IN_KEY = 'primTempIn';
export const SEC_TEMP_IN_KEY = 'secTempIn';
export const PRIM_TEMP_OUT_KEY = 'primTempOut';
export const SEC_TEMP_OUT_KEY = 'secTempOut';
export const PRIM_FLOW_KEY = 'primFlow';
export const SEC_FLOW_KEY = 'secFlow';
export const SEC_FLOW_EFFECT_KEY = 'secFlowEffect';
export const PRIM_DRIVING_PRESSURE_KEY = 'primDrivingPressure';
export const SEC_EXTERNAL_PRESSURE_DROP_KEY = 'secExternalPressureDrop';
export const PRIM_EXTERNAL_PRESSURE_DROP_KEY = 'primExternalPressureDrop';
export const CALCMODE_KEY = 'calcMode';
export const NUM_BACKVENTS_KEY = 'numBackVents';
export const HAS_BACKVENTS_KEY = 'hasBackVent';
export const HAS_MAINPUMP_KEY = 'hasMainPump';
export const PEFK_KEY = 'pEfk';
export const IV_MOD_KEY = 'ivMod';
export const USE_CONNECTED_ENERGY_KEY = "useConnectedEnergy";
export const FLOW_CALC_MODE_KEY = 'flowCalcMode';

export const LOCKED_PRIM_TEMP_IN_KEY = 'lockedPrimTempIn';
export const LOCKED_SEC_TEMP_IN_KEY = 'lockedSecTempIn';
export const LOCKED_PRIM_TEMP_OUT_KEY = 'lockedPrimTempOut';
export const LOCKED_SEC_TEMP_OUT_KEY = 'lockedSecTempOut';
export const LOCKED_PRIM_FLOW_KEY = 'lockedPrimFlow';
export const LOCKED_SEC_FLOW_KEY = 'lockedSecFlow';
export const LOCKED_PRIM_DRIVING_PRESSURE_KEY = 'lockedPrimDrivingPressure';
export const LOCKED_SEC_EXTERNAL_PRESSURE_DROP_KEY = 'lockedSecExternalPressureDrop';
export const LOCKED_PRIM_EXTERNAL_PRESSURE_DROP_KEY = 'lockedPrimExternalPressureDrop';