import { Box, Backdrop, CircularProgress, Stack, Typography, Divider, Button, styled, Paper, Select, MenuItem, FormControlLabel, Checkbox } from "@mui/material"
import { useEffect } from "react"
import { useState } from "react"
import { Fragment } from "react"
import { useDatabase } from "reactfire"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { PRODUCTLINE_STANDARD } from "../../../constants/productlineConstants"
import { useSessionParams } from "../../../contexts"
import { SessionParamsEnum } from "../../../enums"
import { getShuntPriceList } from "../../../firebase/functions/priceFunctions/getShuntPriceList"
import { saveOverallPriceInfo, unlockSessionOffer } from "../../../firebase/functions/priceFunctions/saveOverallPrice"
import { numberWithSpaceSeparator, roundNearest100 } from "../../../functions/numberFunctions"
import { saveShuntShippingCost } from "../../../firebase/functions/priceFunctions/saveShuntPrice"
import { getSessionPriceData } from "../../../firebase/functions/priceFunctions/getSessionPriceData"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        borderTopWidth: '5px',
        borderTopColor: '#06559e',
        borderBottomWidth: '5px',
        borderBottomColor: '#06559e',
        color: '#06559e',
        fontSize: 14,
        padding: "10px 16px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "5px 16px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const PriceCalcOverall = ({ shuntList, handleComplete }) => {
    const sessionParams = useSessionParams();
    const database = useDatabase();
    

    const sessionID = SessionParamsEnum.SessionID.getContextValue(sessionParams);
    //const sessionRef = ref(database, SESSION_PATH + sessionID);
    //const overallPriceRef = ref(database, SESSION_PRICES_PATH + sessionID);

    //const sessionData = get(sessionRef);
    //const overallPriceData = get(overallPriceRef);

    const [shuntPrices, setShuntPrices] = useState({});
    const [shippingPrices, setShippingPrices] = useState({});
    const [totalPrice, setTotalPrice] = useState(0);
    const [isFullyFinished, setIsFullyFinished] = useState(false);
    const [isLocked, setIsLocked] = useState(false);
    const [isCompleted, setIsCompleted] = useState(1);
    const [shipping, setShipping] = useState('exklShipping')

    const shippingOptions = {
        exklShipping: "Exkl. frakt & emballage",
        shippingNoContract: "Frakt & emballage, ej avtal",
        assemblin: "Avtalskund: Assemblin",
        instalco: "Avtalskund: Instalco",
    }

    useEffect(() => {
        const readData = async () => {
            const dataList = await getShuntPriceList(database, shuntList);
            const sessionInfoData = await getSessionPriceData(database, sessionID);

            if (sessionInfoData?.offerLocked != null) {
                if (sessionInfoData?.offerLocked === true || sessionInfoData?.offerLocked === 'true') {
                    setIsLocked(true);
                }
                else if (sessionInfoData?.offerLocked === false || sessionInfoData?.offerLocked === 'false') {
                    setIsLocked(false);
                }
            }

            if (sessionInfoData?.shipOption != null) {
                setShipping(sessionInfoData?.shipOption)
            }

            setShuntPrices(dataList);
        }

        readData();
    }, [])


    useEffect(() => {
        let finished = true;
        let total = 0;
        let localShipCost = {};

        Object.keys(shuntPrices).forEach((shuntID) => {
            if (shuntPrices[shuntID]?.CustomData?.shuntPrice == null || shuntPrices[shuntID]?.CustomData?.priceCompleted !== true) {
                finished = false;
            }
            else {
                total += roundNearest100(shuntPrices[shuntID]?.CustomData?.shuntPrice);
            }

            if (shuntPrices[shuntID]?.CustomData?.shipCost != null){
                localShipCost[shuntID] = Number(shuntPrices[shuntID]?.CustomData?.shipCost);
            }
        });
        setTotalPrice(total);
        setIsFullyFinished(finished);
        setShippingPrices(localShipCost);
    }, [shuntPrices])

    const handleChangeShipping = (event) => {
        const shippingOption = event.target.value;
        setShipping(shippingOption);

        let localShipping = {};

        if (shippingOption !== "exklShipping") {
            if ((shippingOption === "assemblin" && totalPrice > 30000) || (shippingOption === "instalco" && totalPrice > 50000)) {
                Object.keys(shuntPrices).forEach((shuntID) => {
                    if (shuntPrices[shuntID]?.CustomData?.shuntPrice != null && shuntPrices[shuntID]?.CustomData?.priceCompleted === true) {
                        localShipping[shuntID] = 0;
                    }
                });
            }
            else {
                if (Object.keys(shuntPrices).length === 1) {
                    Object.keys(shuntPrices).forEach((shuntID) => {
                        if (shuntPrices[shuntID]?.CustomData?.shuntPrice != null && shuntPrices[shuntID]?.CustomData?.priceCompleted === true) {
                            localShipping[shuntID] = 1450;
                        }
                    });
                }
                else if (Object.keys(shuntPrices).length > 1) {
                    Object.keys(shuntPrices).forEach((shuntID) => {
                        if (shuntPrices[shuntID]?.CustomData?.shuntPrice != null && shuntPrices[shuntID]?.CustomData?.priceCompleted === true) {
                            let primDim = shuntPrices[shuntID]?.CalcParams?.primDimRow?.dim;
                            let sekDim = shuntPrices[shuntID]?.CalcParams?.sekDimRow?.dim;

                            let largestDim = Math.max(Number(primDim), Number(sekDim));
                            if (largestDim <= 40) {
                                localShipping[shuntID] = 725;
                            }
                            else if (largestDim > 40) {
                                localShipping[shuntID] = 1450;
                            }
                        }
                    });
                }

            }
        }

        setShippingPrices(localShipping);
    };

    const handleChangeLocked = (event) => {
        if (event.target.checked === true){
            saveSessionPrice();
        }
        else{
            setIsLocked(event.target.checked);
            unlockSessionOffer(database, sessionID, event.target.checked);
        }
        
    };

    const disableButton = () => {
        if (isLocked === true || isFullyFinished === false) {
            return true;
        }
        else {
            return false;
        }
    }

    const saveSessionPrice = () => {
        setIsLocked(true);

        if (shipping !== "exklShipping") {
            Object.keys(shippingPrices).forEach((shuntID) => {
                if (shippingPrices[shuntID] != null) {
                    saveShuntShippingCost(database, shuntID, shippingPrices[shuntID]);
                }
            });
        }
        else {
            Object.keys(shuntPrices).forEach((shuntID) => {
                //console.log("None", shuntID)
                saveShuntShippingCost(database, shuntID, null);
            });
        }

        saveOverallPriceInfo(database, sessionID, shipping);
    }

    return (
        <Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isCompleted === -1}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Typography component={'h2'} style={{ paddingBottom: '10px' }}>
                <strong>Sammanställning utpriser</strong>
            </Typography>
            <TableContainer component={Paper} sx={{ width: '820px' }}>
                <Divider sx={{ borderBottomWidth: '5px', borderBottomColor: '#06559e' }} />
                <Table >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell style={{ width: '50px' }}>Pos.</StyledTableCell>
                            <StyledTableCell align="left">Benämning</StyledTableCell>
                            <StyledTableCell align="left">Typ</StyledTableCell>
                            <StyledTableCell align="left">Summa delposter</StyledTableCell>
                            {shipping !== "exklShipping" &&
                                <StyledTableCell align="left">+ frakt</StyledTableCell>
                            }
                            <StyledTableCell align="left">Utpris</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(shuntPrices).map((row) => {
                            //console.log("Row: ", shuntPrices[row])
                            let priceText = ' - ';
                            let priceTextRounded = 'Saknas';
                            if (shuntPrices[row]?.CustomData?.shuntPrice != null) {
                                priceText = numberWithSpaceSeparator(shuntPrices[row]?.CustomData?.shuntPrice) + " kr";
                                if (shuntPrices[row]?.CustomData?.priceCompleted === true) {
                                    priceTextRounded = numberWithSpaceSeparator(roundNearest100(shuntPrices[row]?.CustomData?.shuntPrice)) + " kr";
                                }
                            }

                            let shuntType = shuntPrices[row]?.ShuntData?.plModel || shuntPrices[row]?.PLData?.princip;

                            if (shuntPrices[row]?.PLData?.prodLine !== PRODUCTLINE_STANDARD && shuntPrices[row]?.ShuntData?.plModel != null) {
                                shuntType += ' ' + shuntPrices[row]?.PLData?.prodLine;
                            }

                            let localPrimDimName = shuntPrices[row]?.CalcParams?.primDimRow?.name || ' - ';
                            let localSekDimName = shuntPrices[row]?.CalcParams?.sekDimRow?.name || ' - ';

                            shuntType += " (" + localPrimDimName + "/" + localSekDimName + ")"
                            
                            return (
                                <StyledTableRow key={row} sx={{ height: '5px' }}>
                                    <StyledTableCell >
                                        {shuntPrices[row]?.Baseinfo?.position}
                                    </StyledTableCell>
                                    <StyledTableCell>{shuntPrices[row]?.Baseinfo?.designation}</StyledTableCell>
                                    <StyledTableCell>{shuntType}</StyledTableCell>
                                    <StyledTableCell>{priceText}</StyledTableCell>
                                    {shipping !== "exklShipping" &&
                                        <Fragment>
                                            {shippingPrices[row] != null ? (
                                                <StyledTableCell>{shippingPrices[row] + " kr"}</StyledTableCell>
                                            ) : (
                                                <StyledTableCell> - </StyledTableCell>
                                            )}
                                        </Fragment>

                                    }
                                    <StyledTableCell>{priceTextRounded}</StyledTableCell>
                                </StyledTableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                <Divider sx={{ borderBottomWidth: '5px', borderBottomColor: '#06559e' }} />
            </TableContainer>
            <Stack direction={'row'} style={{ marginLeft: '650px' }} spacing={1}>
                <div style={{ paddingTop: '10px', fontSize: 15 }}>
                    Summa:
                </div>
                <div style={{ paddingTop: '10px', fontSize: 15 }}>
                    {isFullyFinished === true ? (<>{numberWithSpaceSeparator(totalPrice)} kr</>) : (<>-</>)}
                </div>
            </Stack>

            <Stack>
                <Box style={{ padding: '10px', marginTop: '5px', marginBottom: '5px' }}>
                    <Stack direction={'row'} spacing={5}>
                        <Stack style={{ width: '500px' }} spacing={2}>

                            <Typography component={'h4'}>
                                <strong><u>Frakter & kundavtal:</u></strong>
                            </Typography>

                            <Stack direction={'row'}>
                                <Select
                                    style={{ width: "200px" }}
                                    value={shipping}
                                    onChange={handleChangeShipping}
                                    disabled={isLocked}>
                                    {Object.keys(shippingOptions).map((option) => {
                                        return (
                                            <MenuItem value={option}>{shippingOptions[option]}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </Stack>
                            <Divider />

                            <Stack direction={'row'} spacing={2}>
                                <Button style={{ width: 170 }} variant='outlined' disabled={disableButton()} onClick={saveSessionPrice}>
                                    Godkänd & Spara
                                </Button>
                                <FormControlLabel control={<Checkbox checked={isLocked} onChange={handleChangeLocked} disabled={isFullyFinished === false} />} label="Offert låst" />
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}