import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import introImg1 from '../../assets/images/introduction_1.png'
import introImg2 from '../../assets/images/introduction_2.png'




export const IntroHandling = () => {

    return (
        <div
        // style={{
        //     margin: "30px 0px 0px 0px",
        //     width: "96.3%",
        //     borderRadius: "4px",
        //     padding: "20px",
        //     justifyContent: 'center',
        //     backgroundColor: '#E1E2E4',
        // }}
        >

            <h2 className="intro-title" style={{ marginBlockStart: '0px' }}>Hantering av körningar/sessioner</h2>

            <p>Arbetsflödet från tidigare versioner av ShuntLogik<sup>®</sup>, där beräkningsverktyget öppnas, anläggning och projektnummer anges, positionerna dimensioneras och konfigureras, specifikationen skrivs ut eller sparas i Word-filformat, och körningen stängs ner i webbläsaren, är fortfarande fullt möjligt och troligtvis det som de flesta användare kommer hålla sig till. För dessa användare kommer uppdateringarna i och med ShuntLogik<sup>®</sup> 1.5 främst yttra sig i det faktum att det inte längre finns en tidsgräns för hur länge en körning kan vara öppen i webbläsaren innan den automatiskt avslutas.</p>

            <p>
                Till skillnad från tidigare versioner av ShuntLogik<sup>®</sup> så försvinner dock nu inte en körning per automatik när webbläsaren stängs ner, utan det är möjligt att granska, arbeta vidare med eller dela en tidigare körning. Hur man väljer att använda dessa nya möjligheter är upp till varje enskild användare, och är inte nödvändigt för att kunna använda kärnfunktionaliteten i dimensioneringsverktyget, och det går precis som tidigare bra att spara ner specifikationen från körningen och skicka till PREMA via exempelvis mail, istället för att dela körningen direkt inifrån ShuntLogik®.
            </p>

            <p>
                För att kunna öppna en tidigare körning i ShuntLogik<sup>®</sup> behöver du antingen få tillgång till en delningslänk, registrerat ett konto och genomfört körningen som inloggad (se mer i avsnitt <strong><i>Konto och Mina sidor</i></strong>), eller manuellt ange ett Sessions-ID på startsidan.
            </p>

            <h3 className="intro-subheaders">Åtkomst via delningslänk</h3>
            <p>
                Om någon angivit din epostadress i ShuntLogiks<sup>®</sup> nya verktyg för Dela session, så kommer du få ett automatiskt genererat epostmeddelande med avsändare PREMA ShuntLogik<sup>®</sup>. I epostmeddelandet finns en länk som öppnar ShuntLogik<sup>®</sup> i din webbläsare, och laddar in den aktuella körningen i Granskningsläge (<i>se mer om Granskningsläge kontra Redigera längre ner</i>). Du kan där navigera runt mellan körningens positioner, granska, skriva ut eller spara ner Specifikationen, eller gå över i Redigeringsläge för att genomföra ändringar eller tillägg.
            </p>

            <p>När du själv delar en körning från ShuntLogik<sup>®</sup> så är verktyget för det förinställt att dela med PREMA, och du behöver då inte manuellt ange mottagare av delningen. Men du har också möjlighet att ange en annan epostadress som mottagare, exempelvis till en kollega. Det är såklart även möjligt att ange din egen epostadress, om du föredrar att ge dig själv framtida åtkomst till din körning via en delningslänk framför att manuellt anteckna och senare ange Sessions-ID.</p>

            <h3 className="intro-subheaders">Manuell inmatning av Sessions-ID</h3>
            <p>
                Sessions-ID för den körning som du är inne i är alltid synligt i rutan med översiktsinformation längst upp i ShuntLogiks<sup>®</sup> användargränssnitt (<i>se avsnitt Översikt och navigering mellan positioner för mer information</i>). Det skapas upp automatiskt för alla nya körningar, och är baserat på datumet det skapades följt av ett antal slumpmässiga tecken. Kopiera eller anteckna detta Sessions-ID om du vill ha möjlighet att få åtkomst till körningen vid ett senare tillfälle.
            </p>

            <p>
                <i>Tips: Klicka på sessions-IDt i din pågående session för att kopiera till Urklipp</i>
            </p>

            <p>På ShuntLogiks<sup>®</sup> startsida kan du, utöver att skapa en helt ny körning, även skriva in ett Sessions-ID från en tidigare körning. När du skrivit in ett giltigt Sessions-ID kan du välja att gå vidare i Granskningsläge eller Redigeringsläge (<i>se mer nedan</i>).</p>

            <h3 className="intro-subheaders">Granskningsläge</h3>

            <p>
                Om du efter manuell inmatning av Sessions-ID klickar på knappen Granska, eller om du klickar på en delningslänk, så kommer du komma in i den tidigare körningen i Granskningsläge. Bredvid körningens Sessions-ID visas symbolen <RemoveRedEyeRoundedIcon className="intro-preview-icon" /> för att indikera detta.
            </p>

            <p>Du kan navigera runt i körningen, och kontrollera alla parametrar, dimensioneringar och val för alla positioner. Du kan även skriva ut eller spara ner specifikationen för körningen, samt använda funktionerna Dela session och Begär offert. Däremot kan du inte uppdatera några värden eller val, utan behöver då gå över i Redigeringsläge.</p>

            <p><strong><i>Observera att det är möjligt att gå från Granskningsläge till Redigeringsläge när som helst genom att klicka på   <EditRoundedIcon className="edit-icon" /> bredvid Sessions-ID, och det är därför lämpligt att börja med att återuppta en tidigare körning i Granskningsläge om man inte är säker på att några justeringar är nödvändiga.</i></strong></p>

            <h3 className="intro-subheaders">Redigera (ny session)</h3>
            <p>
                För att kunna göra ändringar eller tillägg i en tidigare körning behöver du gå in i Redigeringsläge. Det gör du genom att klicka på knappen Redigera (ny session) efter att du skrivit in Sessions-ID på startsidan, alternativt gått över till Redigeringsläge inifrån en tidigare körning i Granskningsläge.
            </p>

            <p>Till skillnad från Granskningsläge, som bara läser in den ursprungliga körningen, så kommer Redigeringsläget leda till en ”avknoppning”, som ges ett eget Sessions-ID. För att indikera att en körning skapats genom redigering av en tidigare så visas symbolen  <ContentCopyIcon className="intro-session-icon" /> bredvid dess Sessions-ID. Genom att hålla muspekaren över denna symbol kan du se vilket ursprungligt Sessions-ID det är som nu redigeras.</p>

            <p>Den ursprungliga körningen kommer inte att förändras eller skrivas över när du arbetar vidare i Redigeringsläge, som nu givits ett nytt Sessions-ID, vilket därmed ger en form av versionshantering. Både den ursprungliga körningen och dess ”avknoppning” kan nås via deras respektive Sessions-ID.</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={introImg1} style={{ width: '231px', marginTop: '10px', marginBottom: '10px' }} alt="Illustration" />
            </div>

            <p>
                Det medför bland annat att om du delar en körning med någon annan, så behöver du inte oroa dig för att de gör förändringar som du inte känner till, för det Sessions-ID du delat kommer alltid leda tillbaka till den version av körningen som du ursprungligen delade.
                <br></br>
                <strong><i>Observera att det också innebär att om du delar en körning just i syfte att få den kompletterad eller korrigerad (genom delning med PREMA, eller annan part) så behöver du få det nya Sessions-ID eller delningslänk som den förändrade körningen automatiskt har givits.</i></strong>
            </p>

            <p>Ytterligare en aspekt av hur Sessions-ID fungerar, är att det är enkelt att skapa en ”grundkörning”, och därefter, baserat på den, ta fram ett antal alternativa varianter, om behov av det skulle finnas.</p>

            <p>Det finns ingen begränsning i hur många gånger en specifik körning redigeras, eller hur många ”generationer” en ursprunglig körning leder till.</p>

            <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={introImg2} style={{ width: '545px', marginTop: '10px', marginBottom: '5px' }} alt="Illustration" />
            </div>

            <h3 className="intro-subheaders">Stänga aktiv körning</h3>
            <p>
                Varje situation som kommer att leda till att den aktiva körningen i ShuntLogik<sup>®</sup> stängs ner, så som att gå till Startsidan (”Steg 0”), lämna ShuntLogik<sup>®</sup> via adressfältet i webbläsaren, eller att stänga ner webbläsaren helt, kommer att ge ett popup-meddelande som påpekar detta, och som du aktivt måste klicka ”Ja” på för att du ska lämna/stänga körningen.
                <br></br>
                <strong>
                    <i>
                        Observera att om du ännu inte antecknat Sessions-ID, eller skickat delningslänk, så kommer dina möjligheter att vid ett senare tillfälle
                        få åtkomst till körningen att gå förlorade. Detta gäller dock inte för dig som ansökt om och beviljats konto i ShuntLogik<sup>®</sup> där du hittar alla dina
                        tidigare körningar i din Sessionshistorik, givet att du varit inloggad när körningen skapades (se mer i avsnitt Konto och Mina sidor).
                    </i>
                </strong>
            </p>
        </div>
    )
}