import { Paper, Stack } from "@mui/material"
import { useEffect } from "react";
import { FirebaseShuntDisplay } from "../inspectPage/components/firebaseShuntDisplay";

export const DevPanelOverview = ({ shuntList, handleClose }) => {
    return (
        <Paper >
            <Stack spacing={1}>
                {shuntList.map((shuntID) => {
                    return (
                        <div key={shuntID} style={{ width: "820px", padding: '10px' }}>
                            <FirebaseShuntDisplay shuntID={shuntID} shortDesc={true} handleClose={handleClose} />
                        </div>
                    )
                })}
            </Stack>
        </Paper>
    )
}