import { CONN_DESIGN_H, CONN_DESIGN_L, CONN_VALUE_DL, CONN_VALUE_DR, CONN_VALUE_HL, CONN_VALUE_HR, CONN_VALUE_LD, CONN_VALUE_LU, CONN_VALUE_RD, CONN_VALUE_RU, CONN_VALUE_UL, CONN_VALUE_UR, CONN_VALUE_VLD, CONN_VALUE_VLU, CONN_VALUE_VRD, CONN_VALUE_VRU } from "../constants/adjustmentsOptions";
import { PRODUCTFAMILY_SR, PRODUCTLINE_GREEN } from "../constants/productlineConstants";
import { SessionParamsEnum, ShuntParamsEnum } from "../enums";
import { AdjustmentsEnum } from "../enums/adjustmentsEnum";
import { CalcParamsEnum } from "../enums/calcParamsEnum";
import { ProductLineEnum } from "../enums/productLineEnum";


/**
 * @typedef {Object} MeasurementRes
 * @property {number} diameter
 * @property {number} Y
 * @property {number} X
 * @property {number} h
 * @property {number} hh
 * @property {number} I
 * @property {number} weight
 * @property {number} depth
 * @property {number} cadH
 * @property {number} cadW
 * @property {boolean} useIVal
 * @property {boolean} useITag
 * @property {boolean} useHHTag
 */


/**
 * @param {Array} measurementData 
 * @param {*} adjustmentParams 
 * @param {*} productLineParams 
 * @param {*} calcParams
 * @param {*} shuntParams
 */
const calcMeasurements = function calcMeasurements(measurementData, adjustmentParams, productLineParams, calcParams, shuntParams, sessionParams, database, shuntID) {
    //console.log("MesurementData:", measurementData);
    const primdimRow = CalcParamsEnum.PrimDimRow.getContextValue(calcParams);
    const sekdimRow = CalcParamsEnum.SekDimRow.getContextValue(calcParams);
    const prodFamily = ProductLineEnum.ProductFamily.getContextValue(productLineParams);
    const prodLine = ProductLineEnum.ProductLine.getContextValue(productLineParams);
    const connectionDesign = AdjustmentsEnum.ConnectionDesign.getContextValue(adjustmentParams);
    const connectionValue = AdjustmentsEnum.ConnectionValue.getContextValue(adjustmentParams);

    const isMini = ShuntParamsEnum.IsMini.getContextValue(shuntParams);

    let dimRow = sekdimRow;
    if (primdimRow != null && sekdimRow != null){
        if (primdimRow.dim > sekdimRow.dim) {
            dimRow = primdimRow;
        }
        else {
            dimRow = sekdimRow;
        }
    }
    else if (primdimRow != null){
        dimRow = primdimRow;
    }
    
    
    //console.log("DimRow:", dimRow);
    if (dimRow != null) {
        //console.log("DIM: ", dimRow.name, ", IsMini: ", isMini, dimRow, measurementData);
        let measurementDim;
        if (isMini === true){
            measurementDim = measurementData.find(obj => obj.id === dimRow.name + 'M');

            if (measurementDim == null){
                measurementDim = measurementData.find(obj => obj.id === dimRow.name);
            }
        }
        else{
            measurementDim = measurementData.find(obj => obj.id === dimRow.name);
        }
        
        //console.log("Selected: ", measurementDim);

        if (measurementDim != null) {
            let modifiedWeight = measurementDim.vikt;

            if (prodLine === PRODUCTLINE_GREEN && measurementDim.vikt_green != null){
                modifiedWeight = measurementDim.vikt_green;
            }

            if (dimRow.ivName != null && dimRow.name !== dimRow.ivName){
                if (dimRow.ivName === "DN015"){
                    if (prodLine === PRODUCTLINE_GREEN && measurementDim.vikt_IV015_green != null){
                        modifiedWeight = measurementDim.vikt_IV015_green;
                    }
                    else if (measurementDim.vikt_IV015 != null){
                        modifiedWeight = measurementDim.vikt_IV015;
                    }
                    //modifiedWeight -= 7;
                }
                else if (dimRow.ivName === "DN010"){
                    if (prodLine === PRODUCTLINE_GREEN && measurementDim.vikt_IV010_green != null){
                        modifiedWeight = measurementDim.vikt_IV010_green;
                    }
                    else if (measurementDim.vikt_IV010 != null){
                        modifiedWeight = measurementDim.vikt_IV010;
                    }
                    //modifiedWeight -= 13;
                }
            }

            let measurementRes = {
                diameter: measurementDim.CC,
                Y: measurementDim.Y,
                X: measurementDim.X,
                h: measurementDim.h,
                I: measurementDim.I,
                weight: modifiedWeight,
                depth: measurementDim.djup,
                cadH: 0,
                cadW: 0,
                useIVal: false,
                useITag: false,
                useHHTag: false,
            };

            let cadH = measurementRes.X;
            let cadW = measurementRes.Y;

            if (connectionDesign === CONN_DESIGN_H) {
                if (measurementDim.Y_H != null) {
                    measurementRes.Y = measurementDim.Y_H;
                }

                if (measurementDim.CC_H != null){
                    measurementRes.diameter = measurementDim.CC_H;
                }

                measurementRes.useIVal = true;

                if (!isHorizontal(connectionValue)) {
                    cadW = measurementRes.I;
                    cadH = measurementRes.Y;
                }
                else {
                    cadH = measurementRes.I;
                    cadW = measurementRes.Y;
                }
            }
            else if (connectionDesign === CONN_DESIGN_L) {
                if (prodFamily === PRODUCTFAMILY_SR) {
                    if (isPrimUpOrDown(connectionValue)) {
                        measurementRes.useITag = true;

                        //Swaps the two values
                        [cadW, cadH] = [cadH, cadW];
                    }
                }
                else {
                    measurementRes.useITag = true;

                    if (isPrimLeftOrRight(connectionValue)) {
                        //Swaps the two values
                        [cadW, cadH] = [cadH, cadW];
                    }
                }
            }

            measurementRes.cadW = cadW;
            measurementRes.cadH = cadH;

            if (isPrimOrSekDown(connectionValue)) {
                measurementRes.h = measurementDim.hh;
                measurementRes.useHHTag = true;
            }

            //console.log("Res Dim:", measurementRes);
            if (SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true){
                CalcParamsEnum.MeasurementData.setContextValue(calcParams, measurementRes, database, shuntID);
            }
            
        }
    }
}

function isPrimUpOrDown(connectionValue) {
    if (connectionValue === CONN_VALUE_UR || connectionValue === CONN_VALUE_UL || connectionValue === CONN_VALUE_DR || connectionValue === CONN_VALUE_DL) {
        return true;
    }
    else {
        return false;
    }
}

function isPrimLeftOrRight(connectionValue) {
    if (connectionValue === CONN_VALUE_RU || connectionValue === CONN_VALUE_LU || connectionValue === CONN_VALUE_RD || connectionValue === CONN_VALUE_LD) {
        return true;
    }
    else {
        return false;
    }
}

function isHorizontal(connectionValue) {
    if (connectionValue === CONN_VALUE_HR || connectionValue === CONN_VALUE_HL) {
        return true;
    }
    else {
        return false;
    }
}

function isPrimOrSekDown(connectionValue) {
    if (connectionValue === CONN_VALUE_VRD || connectionValue === CONN_VALUE_VLD || connectionValue === CONN_VALUE_VRU || connectionValue === CONN_VALUE_VLU || 
        connectionValue === CONN_VALUE_DR || connectionValue === CONN_VALUE_DL || connectionValue === CONN_VALUE_RD || connectionValue === CONN_VALUE_LD) {
        return true;
    }
    else {
        return false;
    }
}

export { calcMeasurements }