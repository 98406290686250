import { Typography } from "@mui/material";
import { logEvent } from "firebase/analytics";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAnalytics } from "reactfire";


export const NotFound = () => {
    const analytics = useAnalytics();

    useEffect(() => {
        //console.log("Sending Analytics data...")
        logEvent(analytics, 'exception', { description: "Page not found", fatal: false });
    }, []);

    return (
        <div style={{
            margin: "30px 0px 0px 0px",
            width: "96.3%",
            borderRadius: "4px",
            padding: "20px",
            justifyContent: 'center',
            backgroundColor: '#E1E2E4',
        }}>

            <Typography component={'h1'} style={{ display: 'flex', justifyContent: 'center', margin: 'auto' }}>404 - Not Found</Typography>

            <Typography component={'h2'} style={{ display: 'flex', justifyContent: 'center', margin: 'auto' }}>Sidan du försökte hitta finns inte!</Typography>
            <Typography component={'h2'} style={{ display: 'flex', justifyContent: 'center', margin: 'auto' }}><Link to="/">Gå tillbaka till start</Link></Typography>
        </div>
    )
}