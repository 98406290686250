import { Fragment, useEffect, useState } from "react"
import { Box, Button, CircularProgress, DialogActions, DialogContent, Divider, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material"
import { useDatabase, useFirestore, useFunctions } from "reactfire";
import { useSessionParams, useShuntParams } from "../../../../contexts";
import { FSDataEnum, SessionParamsEnum, ShuntParamsEnum } from "../../../../enums";
import { updateCustomShuntFunc } from "../../../../firebase/functions/cloudFunctions/updateCustomShunt";
import { checkIfDesignationExists, checkIfPosExistsCustom } from "../../../../functions/ShuntCalculations/helpFunctions/checkIfPosAndDesignationExists";
import { BootstrapDialogTitle } from "../../../utils/bootstrapDialog";
import { getCurrentHighPosition } from "../../../../firebase/functions/getCurrentHighPosition";
import { CustomShuntAddDescription } from "./customShuntAddDescription";
import { addCustomShuntFunc } from "../../../../firebase/functions/cloudFunctions/addCustomShunt";
import { AdjustmentDefault } from "../../../../constants/defaultValues/adjustmentDefault";
import { useFSData } from "../../../../contexts/fsDataContext";
import { useSwitchShunt } from "../../../../hooks/useSwitchShunt";

import { connectionTypeList } from "../../../../constants/fullConnOptions/connOptionData";
import { DEFAULT_SHUNT_DATA } from "../../../../constants/customShunts";
import { PRODUCTLINE_FLEX, PRODUCTLINE_GREEN, PRODUCTLINE_SMART, PRODUCTPRINCIP_PR2_APPSHUNT, PRODUCTPRINCIP_SR2_FLEX_APPSHUNT } from "../../../../constants/productlineConstants";

export const AddShuntRowModal = ({ handleModalClose, isEdit = false, shuntObj = null, specExpanded = false, techExpanded = false }) => {
    const sessionParams = useSessionParams();
    const shuntParams = useShuntParams();
    const database = useDatabase();
    const firestore = useFirestore();
    const functions = useFunctions();
    const fsParams = useFSData();

    const [shuntConnection, setShuntConnection] = useState(Object);

    const [shuntID] = useState(shuntObj?.NO_ID_FIELD || null)
    const [pos, setPos] = useState(shuntObj?.Baseinfo?.position || '');
    const [desig, setDesig] = useState(shuntObj?.Baseinfo?.designation || '');
    const [princip, setPrincip] = useState(shuntObj?.PLData?.princip || '');
    const [connOptions, setConnOptions] = useState([]);
    const [connDesign, setConnDesign] = useState(shuntObj?.AdjustData?.connectionDesign || '');
    const [prodOptions, setProdOptions] = useState([]);
    const [prodVal, setProdVal] = useState(shuntObj?.PLData?.prodLine || '');
    const [plModel, setPlModel] = useState(shuntObj?.ShuntData?.plModel || '');

    const [isSpecial, setIsSpecial] = useState(false);
    const [isBypass, setIsBypass] = useState(false);
    const [tempVersion, setTempVersion] = useState('');
    const [cv2Val, setCV2Val] = useState({});
    const [dimVals, setDimVals] = useState({});
    const [exchangeVal, setExchangeVal] = useState('');
    const [exchangeVals, setExchangeVals] = useState({});
    const [prevDimVals, setPrevDimVals] = useState({});
    const [prevSwitchedVals, setPrevSwitchedVals] = useState({});

    const [shuntNomString, setShuntNomString] = useState('');
    const [numIV, setNumIV] = useState(-1)

    const [switchShunt, setSwitchShunt] = useState('');

    useSwitchShunt(switchShunt);
    /*
    const setInitSpecList = () => {
        if (shuntObj?.SpecData?.specList != null) {
            let local = shuntObj?.SpecData?.specList;
            return local;
        }
        else {
            return [{ num: '1', text: 'Premablock® ' + plModel, indent: true, showAmount: true }];
        }
    }*/

    //const [compList, setCompList] = useState(setInitSpecList());
    //const [compListTech, setCompListTech] = useState(shuntObj?.SpecData?.techList || [{ text: '' }]);
    const [update, setUpdate] = useState(false);

    const triggerUpdate = () => setUpdate(!update);

    const [existsPos, setExistsPos] = useState(false);
    const [existsDesc, setExistsDesc] = useState(false);

    const [saving, setSaving] = useState(false);

    useEffect(() => {
        getCurrentHighPosition(database, sessionParams).then((res) => {
            if (shuntObj == null) {
                setPos(res + 1);
            }
        })
    }, [])

    useEffect(() => {
        const shuntConn = connectionTypeList[shuntObj?.PLData?.princip];

        if (shuntConn != null){
            setShuntConnection(shuntConn);

            if (shuntConn?.CustomOptions?.shuntDimLabels != null && shuntObj?.CalcParams != null){
                let localDimVals = {};
                Object.keys(shuntConn?.CustomOptions?.shuntDimLabels).forEach((key) => {
    
                    if (shuntObj?.CalcParams[key + 'DimRow'] != null) {
                        localDimVals[key] = shuntObj?.CalcParams[key + 'DimRow'];
                    }
                })
    
                setPrevDimVals(localDimVals);
            }

            if (shuntConn?.CustomOptions?.shuntDimLabels != null) {
                if (shuntObj?.ShuntData?.exchanger != null){
                    setExchangeVal(shuntObj?.ShuntData?.exchanger);
                }
                
                if (shuntObj?.ShuntData?.cv2NWays != null) {
                    setCV2Val(shuntObj?.ShuntData?.cv2NWays);
                }
            }
        }

        if (shuntObj?.CalcParams?.shuntType != null) {
            if (shuntObj?.CalcParams?.shuntType === 'Kylshunt') {
                setTempVersion('Kyla');
            }
            else if (shuntObj?.CalcParams?.shuntType === 'Värmeshunt') {
                setTempVersion('Värme');
            }
            else {
                setTempVersion(shuntObj?.CalcParams?.shuntType);
            }
        }

        /*
        if (CUSTOM_SHUNT_DIM_LABELS[shuntObj?.PLData?.princip] != null && shuntObj?.CalcParams != null) {
            let localDimVals = {};
            Object.keys(CUSTOM_SHUNT_DIM_LABELS[shuntObj?.PLData?.princip]).forEach((key) => {

                if (shuntObj?.CalcParams[key + 'DimRow'] != null) {
                    localDimVals[key] = shuntObj?.CalcParams[key + 'DimRow'];
                }
            })

            setPrevDimVals(localDimVals);
        }

        if (CUSTOM_SHUNT_SEC_CONTROLVALVE_LABELS[shuntObj?.PLData?.princip] != null && shuntObj?.ShuntData?.exchanger != null) {
            setExchangeVal(shuntObj?.ShuntData?.exchanger);
        }

        if (CUSTOM_SHUNT_SEC_CONTROLVALVE_LABELS[shuntObj?.PLData?.princip] != null && shuntObj?.ShuntData?.cv2NWays != null) {
            setCV2Val(shuntObj?.ShuntData?.cv2NWays);
        }*/
       
        triggerUpdate();

    }, [shuntObj])

    useEffect(() => {
        if (isEdit === true) {
            checkIfPosExistsCustom(pos, database, sessionParams, false, shuntObj.NO_ID_FIELD).then((res) => {
                setExistsPos(res);
            })
        }
        else {
            checkIfPosExistsCustom(pos, database, sessionParams, true).then((res) => {
                setExistsPos(res);
            })
        }

    }, [pos]);

    /*
    useEffect(() => {
        //console.log("Start", isSpecial)
        if (princip != null && princip !== "") {
            if (CUSTOM_SHUNT_PL_MODEL[princip] != null) {
                let plName = CUSTOM_SHUNT_PL_MODEL[princip];
                if (isBypass === true) {
                    plName += "/Förbigång"
                }
                if (isSpecial === true) {
                    //console.log("Set", isSpecial)
                    plName += "Spec"
                }
                setPlModel(plName);
            }
            else {
                if (connDesign != null && connDesign !== "" && connDesign !== "-" && connDesign !== "Custom") {
                    let combined = princip.substring(0, 2) + connDesign.toLocaleUpperCase() + princip.substring(2);
                    if (isBypass === true) {
                        combined += "/Förbigång"
                    }

                    if (isSpecial === true) {
                        //console.log("Set", isSpecial)
                        combined += "Spec"
                    }
                    setPlModel(combined);
                }
                else {
                    let plName = princip;
                    if (isBypass === true) {
                        plName += "/Förbigång"
                    }

                    if (isSpecial === true) {
                        //console.log("Set", isSpecial)
                        plName += "Spec"
                    }
                    setPlModel(plName);
                }
            }
        }
    }, [princip, connDesign, isBypass, isSpecial])*/

    useEffect(() => {
        //console.log("ProdVal: ", plModel, prodVal, exchangeVal, cv2Val)


        if (exchangeVal != null && exchangeVal !== '' && cv2Val != null && Object.keys(cv2Val).length > 0) {
            let prodString = "";
            let localNumIV = 3;
            if (prodVal === PRODUCTLINE_FLEX || prodVal === PRODUCTLINE_SMART) {
                prodString = prodVal;
                localNumIV -= 1;

                let isBoth2Ways = true;
                Object.keys(cv2Val).forEach((cv) => {
                    if (cv2Val[cv] === 3) {
                        isBoth2Ways = false;
                    }
                });

                if (isBoth2Ways === true) {
                    localNumIV -= 1;
                }
            }

            setNumIV(localNumIV)

            let valString = "-";
            if (exchangeVal === 'VP') {
                if (cv2Val['controlValve2'] != null) {
                    valString += String(cv2Val['controlValve2']) + prodString + "-KB-VP-" + String(localNumIV) + "iv";
                }
            }
            else if (exchangeVal === 'KB') {
                if (cv2Val['controlValve'] != null) {
                    valString += String(cv2Val['controlValve']) + prodString + "-VP-KB-" + String(localNumIV) + "iv";
                }
            }

            //console.log("Value: ", plModel + valString)
            setShuntNomString(plModel + valString);
        }
        else {
            setNumIV(-1);
            setShuntNomString('');
        }
    }, [plModel, prodVal, exchangeVal, cv2Val, update])
    /*
    useEffect(() => {
        if (prodVal !== '' && prodVal !== 'standard') {
            compList[0] = { num: '1', text: 'Premablock® ' + plModel + " " + prodVal, indent: true, showAmount: true };
        }
        else {
            compList[0] = { num: '1', text: 'Premablock® ' + plModel, indent: true, showAmount: true };
        }

        setUpdate(!update)
    }, [plModel, prodVal])*/

    const handleDescOnBlur = () => {
        checkIfDesignationExists(desig, database, sessionParams, true).then((res) => {
            setExistsDesc(res)
        })
    }

    const handleChangePrincip = (event) => {
        //console.log("Hello pick: ", event.target.value)
        setPrincip(event.target.value);
        if (connectionTypeList[event.target.value] != null) {
            const shuntConn = connectionTypeList[event.target.value];
            //console.log("Hello pick found: ", shuntConn)
            setShuntConnection(shuntConn)

            if (shuntConn?.CustomOptions?.connOptions != null && shuntConn?.CustomOptions?.connOptions.length >= 1) {
                setConnDesign(shuntConn?.CustomOptions?.connOptions[0])

                if (shuntConn?.InfoData?.plModName != null) {
                    let plModelName = createPLModelName(shuntConn?.InfoData?.plModName, shuntConn?.CustomOptions?.connOptions[0])
                    setPlModel(plModelName);
                }
            }
            else {
                setConnDesign('')
                setPlModel(shuntConn?.InfoData?.name);
            }

            //console.log('ProdLines: ', shuntConn?.InfoData?.prodlines)
            if (shuntConn?.InfoData?.prodlines != null && shuntConn?.InfoData?.prodlines.length >= 1) {
                setProdVal(shuntConn?.InfoData?.prodlines[0])
            }
            else {
                setProdVal('')
            }


        }
        else {
            setShuntConnection({});
        }
    };


    /**
     * 
     * @param {string} plModName 
     * @param {string} connOption 
     */
    const createPLModelName = (plModName, connOption) => {
        let newName = plModName.replace('{x}', connOption)

        return newName;
    }

    const handleChangePos = (value) => {
        setPos(value);
    }


    const handleChange = (event) => {
        switch (event.target.name) {
            case 'pos':
                setPos(event.target.value);
                break;
            case 'desc':
                setDesig(event.target.value);
                break;
            case 'conn':
                setConnDesign(event.target.value);
                if (shuntConnection?.InfoData?.plModName != null) {
                    setPlModel(createPLModelName(shuntConnection?.InfoData?.plModName, event.target.value))
                }
                break;
            case 'prod':
                setProdVal(event.target.value);
                break;
            default:
                console.error("Not an option:", event.target.name)
                break;
        }
    };

    const isValid = () => {
        if (pos != null && existsPos === false &&
            desig != null &&
            princip != null && princip !== "" &&
            prodVal != null && prodVal !== "" &&
            plModel != null && plModel !== "") {
            return true;
        }
        else {
            return false;
        }
    }

    const saveShuntFunc = async () => {
        setSaving(true);
        let shuntType = null;
        let shuntData = null;
        let pumpOptions = null;
        let flexDim = null;
        let ivMod = null;
        let localExh = exchangeVal;

        if (tempVersion != null) {
            if (tempVersion === 'Kyla') {
                shuntType = "Kylshunt";
            }
            else if (tempVersion === 'Värme') {
                shuntType = "Värmeshunt";
            }
            else {
                shuntType = tempVersion;
            }
        }

        /*
        console.log("Values: ", shuntType, "Dims: ", dimVals);
        console.log("Exchange: ", exchangeVal);
        console.log("CV 2: ", cv2Val);
        console.log("ShuntNom: ", shuntNomString);
        console.log("ProdVal: ", prodVal)*/
        let adjustOptions = AdjustmentDefault.CustomDefault;

        if (tempVersion.includes('kyla') || tempVersion.includes('Kyla')) {
            if (prodVal === PRODUCTLINE_GREEN) {
                adjustOptions = { ...adjustOptions, ...AdjustmentDefault.IsolationGreen_Below18Degrees }
            }
            else {
                adjustOptions = { ...adjustOptions, ...AdjustmentDefault.Isolation_Below18Degrees }
            }
        }

        if (princip === PRODUCTPRINCIP_PR2_APPSHUNT || princip === PRODUCTPRINCIP_SR2_FLEX_APPSHUNT) {
            adjustOptions['cabinet'] = 'no_cabinet';
            adjustOptions['dripPlate'] = false;
        }

        if (shuntConnection?.SpecData?.specialShuntData != null) {
            shuntData = shuntConnection?.SpecData?.specialShuntData;
        }
        else {
            shuntData = DEFAULT_SHUNT_DATA;
        }

        if (numIV != null && numIV >= 0) {
            shuntData = { ...shuntData, numIV: numIV }
        }

        //console.log("NUM: ", CUSTOM_SHUNT_NUM_CONNECTIONS[princip])
        if (shuntConnection?.SpecData?.numOfConnections != null) {
            shuntData['numConn'] = shuntConnection?.SpecData?.numOfConnections;
        }

        if (shuntNomString != null && shuntNomString !== '' && shuntData['shuntNomenklaturString'] == null) {
            shuntData['shuntNomenklaturString'] = shuntNomString
        }

        if (shuntConnection?.SpecData?.specialExhangeValue != null && (localExh == null || localExh === "")) {
            localExh = shuntConnection?.SpecData?.specialExhangeValue
        }

        if (isBypass === true) {
            shuntData = { ...shuntData, bypass: isBypass }
        }

        //console.log("ShuntData: ", shuntData);
        if (shuntConnection?.SpecData?.specialPumpOptions != null) {
            pumpOptions = shuntConnection?.SpecData?.specialPumpOptions;
        }

        if (princip === PRODUCTPRINCIP_SR2_FLEX_APPSHUNT) {
            const res = await FSDataEnum.FlexDims.getContextValue(fsParams, firestore);

            if (dimVals['prim'] != null) {
                let result = res.find(obj => obj.id === dimVals['prim'].name);
                if (result != null) {
                    flexDim = res.find(obj => obj.id === dimVals['prim'].name)
                    //console.log("Res: ", flexDim)
                }

            }
        }

        if (prodVal === PRODUCTLINE_FLEX || prodVal === PRODUCTLINE_SMART) {
            ivMod = -1;
        }

        addCustomShuntFunc(
            functions,
            SessionParamsEnum.SessionID.getContextValue(sessionParams),
            pos,
            desig,
            princip,
            prodVal,
            plModel,
            dimVals,
            shuntType || null,
            connDesign || null,
            shuntData,
            pumpOptions,
            flexDim,
            localExh || null,
            cv2Val || null,
            adjustOptions,
            isSpecial,
            ivMod
        ).then((res) => {
            //console.log("Res: ", res?.data);
            if (res?.data != null && res?.data !== '') {
                setSwitchShunt(String(res?.data));
            }
            SessionParamsEnum.UpdateValues.setContextValue(sessionParams, !SessionParamsEnum.UpdateValues.getContextValue(sessionParams));
            handleModalClose();
        }).catch((e) => {
            console.error(e);
        }).finally(() => {
            setSaving(false);
        });
    }

    const updateShuntFunc = async () => {
        setSaving(true);

        let shuntType = null;
        let shuntData = null;
        let pumpOptions = null;
        let flexDim = null;
        let ivMod = null;
        let localExh = exchangeVal;

        if (tempVersion != null) {
            if (tempVersion === 'Kyla') {
                shuntType = "Kylshunt";
            }
            else if (tempVersion === 'Värme') {
                shuntType = "Värmeshunt";
            }
            else {
                shuntType = tempVersion;
            }
        }

        let adjustOptions = AdjustmentDefault.CustomDefault;

        if (tempVersion.includes('kyla') || tempVersion.includes('Kyla')) {
            if (prodVal === PRODUCTLINE_GREEN) {
                adjustOptions = { ...adjustOptions, ...AdjustmentDefault.IsolationGreen_Below2Degrees }
            }
            else {
                adjustOptions = { ...adjustOptions, ...AdjustmentDefault.Isolation_Below2Degrees }
            }
        }

        if (princip === PRODUCTPRINCIP_PR2_APPSHUNT || princip === PRODUCTPRINCIP_SR2_FLEX_APPSHUNT) {
            adjustOptions['cabinet'] = 'no_cabinet';
            adjustOptions['dripPlate'] = false;
        }

        if (shuntConnection?.SpecData?.specialShuntData != null) {
            shuntData = shuntConnection?.SpecData?.specialShuntData;
        }
        else {
            shuntData = DEFAULT_SHUNT_DATA;
        }

        if (numIV != null && numIV >= 0) {
            shuntData = { ...shuntData, numIV: numIV }
        }

        if (shuntNomString != null && shuntNomString !== '' && shuntData['shuntNomenklaturString'] == null) {
            shuntData['shuntNomenklaturString'] = shuntNomString
        }

        if (shuntConnection?.SpecData?.numOfConnections != null) {
            shuntData['numConn'] = shuntConnection?.SpecData?.numOfConnections;
        }

        if (shuntConnection?.SpecData?.specialExhangeValue != null && (localExh == null || localExh === "")) {
            localExh = shuntConnection?.SpecData?.specialExhangeValue
        }

        if (isBypass === true) {
            shuntData = { ...shuntData, bypass: isBypass }
        }

        if (shuntConnection?.SpecData?.specialPumpOptions != null) {
            pumpOptions = shuntConnection?.SpecData?.specialPumpOptions;
        }

        if (princip === PRODUCTPRINCIP_SR2_FLEX_APPSHUNT) {
            const res = await FSDataEnum.FlexDims.getContextValue(fsParams, firestore);

            if (dimVals['prim'] != null) {
                let result = res.find(obj => obj.id === dimVals['prim'].name);
                if (result != null) {
                    flexDim = res.find(obj => obj.id === dimVals['prim'].name)
                    //console.log("Res: ", flexDim)
                }

            }
        }

        if (prodVal === PRODUCTLINE_FLEX || prodVal === PRODUCTLINE_SMART) {
            ivMod = -1;
        }


        updateCustomShuntFunc(
            functions,
            SessionParamsEnum.SessionID.getContextValue(sessionParams),
            shuntID,
            pos,
            desig,
            princip,
            prodVal,
            plModel,
            dimVals,
            shuntType || null,
            connDesign || null,
            shuntData,
            pumpOptions,
            flexDim,
            localExh || null,
            cv2Val || null,
            adjustOptions,
            isSpecial,
            ivMod,
        ).then(() => {
            if (SessionParamsEnum.CurrentShunt.getContextValue(sessionParams) === shuntID){
                ShuntParamsEnum.ShuntPosition.setContextValue(shuntParams, pos);
                ShuntParamsEnum.ShuntDesignation.setContextValue(shuntParams, desig);
            }
            SessionParamsEnum.UpdateValues.setContextValue(sessionParams, !SessionParamsEnum.UpdateValues.getContextValue(sessionParams));
            handleModalClose();
        }).catch((e) => {
            console.error(e);
        }).finally(() => {
            setSaving(false);
        })
    }


    return (
        <Fragment>
            <BootstrapDialogTitle onClose={handleModalClose}>
                {isEdit ? ("Uppdatera manuell shuntgrupp") : ("Lägg till manuell shuntgrupp")}
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center' }}>
                    {isEdit ? ("Uppdatera") : ("Lägg till")} en shuntgrupp som inte är dimensionerad via ShuntLogik. Begränsad funktionalitet.
                </Typography>

                <Paper style={{ padding: '5px', marginTop: '15px' }}>
                    <Box sx={{ padding: '0px 10px 10px' }}>
                        <Typography gutterBottom style={{ display: 'flex', justifyContent: 'left', paddingTop: "10px" }}>
                            <b>Parametrar:</b>
                        </Typography>
                        <Stack sx={{ marginLeft: '3px', marginBottom: '2px' }} direction={'row'} spacing={1}>
                            <Typography sx={{ width: '50px' }} component={'span'}>Pos:</Typography>
                            <Typography sx={{ width: '100px' }} component={'span'}>Beteckning:</Typography>
                            <Typography sx={{ width: '130px' }} component={'span'}>Princip:</Typography>
                            <Typography sx={{ width: '65px' }} component={'span'}>Utförande:</Typography>
                            <Typography sx={{ width: '105px' }} component={'span'}>Prod.Linje:</Typography>
                        </Stack>
                        <Stack direction={'row'} spacing={1}>
                            <TextField
                                sx={{ width: '50px' }}
                                name="pos"
                                value={pos}
                                onKeyDown={(evt) => (/^[-a-ö,.A-Ö]?$/).test(evt.key) && evt.preventDefault()}
                                onChange={(event) =>
                                    Number(event.target.value) < 0
                                        ? (handleChangePos('0'))
                                        : handleChangePos(event.target.value)
                                }
                                size="small"
                                type={'number'}
                                variant="outlined"
                            />
                            <TextField
                                sx={{ width: '100px' }}
                                name="desc"
                                value={desig}
                                onChange={handleChange}
                                onBlur={handleDescOnBlur}
                                size="small"
                                variant="outlined"
                            />
                            <Select
                                sx={{ width: '130px' }}
                                value={princip}
                                onChange={handleChangePrincip}
                            >
                                {Object.keys(connectionTypeList).map((principOptKey) => {
                                    return (
                                        <MenuItem key={principOptKey} value={principOptKey}>
                                            {connectionTypeList[principOptKey].InfoData?.name}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                            <Select
                                sx={{ width: '65px' }}
                                value={connDesign}
                                name="conn"
                                onChange={handleChange}
                                disabled={shuntConnection?.connOptions?.length < 1}
                            >
                                {shuntConnection?.CustomOptions?.connOptions?.map((option) => {
                                    return (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                            <Select
                                sx={{ width: '105px' }}
                                name="prod"
                                value={prodVal}
                                onChange={handleChange}
                                disabled={shuntConnection?.prodlines?.length < 1}
                            >
                                {shuntConnection?.InfoData?.prodlines?.map((option) => {
                                    return (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </Stack>

                        <Box sx={{ marginTop: '5px', minHeight: '15px' }}>
                            {plModel != null && plModel !== "" ? (
                                <Box>
                                    PL Modell: {plModel}
                                </Box>
                            ) : ("")}

                            {existsPos === true ? (
                                <Box sx={{ marginTop: '5px', color: '#e12427' }}>
                                    Denna position existerar redan!
                                </Box>
                            ) : (
                                <Box sx={{ marginTop: '5px', height: '15px' }}>

                                </Box>
                            )}

                            {existsDesc === true ? (
                                <Box sx={{ marginTop: '5px', color: 'orange' }}>
                                    Denna Beteckning existerar redan!
                                </Box>
                            ) : ("")}
                        </Box>
                    </Box>


                    {plModel != null && plModel !== "" ? (
                        <Fragment>
                            <Divider />
                            <Box>
                                <CustomShuntAddDescription
                                    shuntConnOption={shuntConnection}
                                    plModel={plModel}
                                    setPlModel={setPlModel}
                                    plPrincip={princip}
                                    prodLine={prodVal}
                                    dimVals={dimVals}
                                    setDimVals={setDimVals}
                                    tempVersion={tempVersion}
                                    setTempVersion={setTempVersion}
                                    prevChoice={prevDimVals}
                                    exchangeVals={exchangeVals}
                                    prevChoiceExchange={prevSwitchedVals}
                                    setExchangeVals={setExchangeVals}
                                    cv2Val={cv2Val}
                                    setCV2Val={setCV2Val}
                                    exchangeVal={exchangeVal}
                                    setExchangeVal={setExchangeVal}
                                    isSpecial={isSpecial}
                                    setIsSpecial={setIsSpecial}
                                    isBypass={isBypass}
                                    setIsBypass={setIsBypass}
                                    triggerUpdate={triggerUpdate}
                                />
                            </Box>
                        </Fragment>

                    ) : ("")}
                </Paper>
            </DialogContent>
            <DialogActions>
                {isEdit ? (
                    <Button onClick={updateShuntFunc} disabled={saving === true || isValid() === false}>
                        {saving === true ? (
                            <CircularProgress size={18} />
                        ) : (
                            "Uppdatera"
                        )}
                    </Button>
                ) : (
                    <Button onClick={saveShuntFunc} disabled={saving === true || isValid() === false}>
                        {saving === true ? (
                            <CircularProgress size={18} />
                        ) : (
                            "Lägg till"
                        )}
                    </Button>
                )}

                <Button onClick={handleModalClose}>Avbryt</Button>
            </DialogActions>
        </Fragment>
    )
}