//Product Lines
export const PRODLINE_STANDARD_LINK = "https://prema.se/premablock/";
export const PRODLINE_GREEN1_LINK = "https://prema.se/premablock-green/";
export const PRODLINE_GREEN2_LINK = "https://prema.se/premablock-green2/";
export const PRODLINE_FLEX_LINK = "https://prema.se/premablock-flex/";
export const PRODLINE_SMART_LINK = "https://prema.se/premablock-smart/";

//Shunts
export const SHUNT_SR2_LINK = "https://prema.se/product/sru-2-srh-2-srl-2/";
export const SHUNT_SR3_LINK = "https://prema.se/product/sru-3-srh-3-srl-3/";
export const SHUNT_PR2_LINK = "https://prema.se/product/pru-2-prh-2-prl-2/";
export const SHUNT_PR3_LINK = "https://prema.se/product/pru-3-prh-3-prl-3/";
export const SHUNT_VA1_LINK = "https://prema.se/product/vau-val-vah/";
export const SHUNT_FBU_5V_LINK = "https://prema.se/product/fbu-5v/";
export const SHUNT_FBU_5K_LINK = "https://prema.se/product/fbu-5k/";
export const SHUNT_FKU_5K_LINK = "https://prema.se/product/fku-5k/";
export const SHUNT_SR2_FLEX_APP_LINK = "https://prema.se/product/srh-2-flex-appshunt/";
export const SHUNT_PR2_APP_LINK = "https://prema.se/product/prh-2-appshunt/";
export const SHUNT_SRX_42_LINK = "https://prema.se/product/srux-42-srlx-42/";
export const SHUNT_SRX_43_LINK = "https://prema.se/product/srux-43-srlx-43/";
export const SHUNT_PRX_43_LINK = "https://prema.se/product/prux-43-prlx-43/";
export const SHUNT_PRX_43FK_LINK = "https://prema.se/product/prux-43fk/";
export const SHUNT_SRBX_62VK_LINK = "https://prema.se/product/srbx-62vk/";
export const SHUNT_SRBX_63VK_LINK = "https://prema.se/product/srbx-63vk/";
export const SHUNT_PRBX_6VK_LINK = "https://prema.se/product/prbx-6vk/";
export const SHUNT_SRBX_6ECO_LINK = "https://prema.se/product/srbx-6eco/";
export const SHUNT_SRBX_6VAV_LINK = "https://prema.se/product/srbx-6vav/";
export const SHUNT_SRBX_6SPETS_LINK = "https://prema.se/product/srbx-6spets/";
export const SHUNT_SRBX_6NOD_LINK = "https://prema.se/product/srbx-6nod/";
export const SHUNT_SRBX_6VAK_LINK = "https://prema.se/product/srbx-6vak/";
export const SHUNT_SRBX_6VAK_FORBI_LINK = "https://prema.se/product/srbx-6vakforbigang/";
export const SHUNT_SRBX_8VAKK_LINK = "https://prema.se/product/srbx-8vakk/";
export const SHUNT_SRBX_8VAKK_FORBI_LINK = "https://prema.se/product/srbx-8vakkforbigang/";
export const SHUNT_PBCS_LINK = "https://prema.se/product/pbcs/";