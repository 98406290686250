import { httpsCallable } from "firebase/functions"

/**
 * 
 * @param {*} functions 
 * @param {String} sessionID 
 * @param {String} shuntID 
 * @param {File} image 
 * @param {String} fileName 
 * @param {String} fileType
 * @returns 
 */
const uploadImage = async function uploadImage(functions, sessionID, shuntID, image, fileName, fileType) {
    functions.region = 'europe-west1';
    const uploadImage = httpsCallable(functions, "uploadImage");
    
    const buffer = await image.arrayBuffer()
    const b64String = _arrayBufferToBase64(buffer)
  
    return uploadImage({ 
        sessionID: sessionID, 
        shuntID: shuntID,
        image: b64String, 
        fileName: fileName, 
        fileType: fileType,
    });
}

function _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}

export { uploadImage }