import { Paper } from "@mui/material"
import { useEffect } from "react";
import { MySessionHistoryMainComponent } from "../mySessionsPanel/mySessionsMainComponent";

export const MySessionHistoryPanel = ({ notInSession = false }) => {
    return (
        <Paper className='overview-main-panel'>
            <div className="overview-content-size">
                <MySessionHistoryMainComponent notInSession={notInSession} />
            </div >
        </Paper >
    )
}