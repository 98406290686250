import { ref, update } from "firebase/database";
import { SESSION_SHUNT_PATH } from "../../constants/firebasePaths";
import { SessionParamsEnum } from "../../enums/sessionParamsEnum";
import { PumpParamEnum } from "../../enums/pumpParamsEnum"

const savePumpData = function savePumpData(database, sessionParams, pumpParams, data = null) {
    const shuntID = SessionParamsEnum.CurrentShunt.getContextValue(sessionParams);

    const driftParamsRef = ref(database, SESSION_SHUNT_PATH + "/" + shuntID + "/PumpData");
    let snapData = {};

    if (data != null) {
        snapData = data;
    }
    else {
        snapData = {
            pumpRequestType: PumpParamEnum.PumpRequestType.getContextValue(pumpParams) || null,
            generalPumpRequest: PumpParamEnum.GeneralPumpRequest.getContextValue(pumpParams) || null,
            systemfluid: PumpParamEnum.SystemFluid.getContextValue(pumpParams) || null,
            specificPumpRequest: PumpParamEnum.SpecificPumpRequest.getContextValue(pumpParams) || null,
            steering: PumpParamEnum.Steering.getContextValue(pumpParams) || null,
            alarm: PumpParamEnum.Alarm.getContextValue(pumpParams) || null,
            externalStop: PumpParamEnum.ExternalStop.getContextValue(pumpParams) || null,
            pressure: PumpParamEnum.Pressure.getContextValue(pumpParams) || null,
            card: PumpParamEnum.Card.getContextValue(pumpParams) || null,
        }
    }

    const promises = [];

    if (SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true){
        promises.push(update(driftParamsRef, snapData));
    }
    
    return Promise.all(promises);
}

export { savePumpData } 