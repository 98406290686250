import { CalcParamsEnum } from "../../../enums/calcParamsEnum"

const getLargestDim = function getLargestDim(calcParams) {
    let primDim = CalcParamsEnum.PrimDimRow.getContextValue(calcParams)?.dim;
    let sekDim = CalcParamsEnum.SekDimRow.getContextValue(calcParams)?.dim;

    if (primDim != null && sekDim != null) {
        return Math.max(Number(primDim), Number(sekDim));
    }
    else if (primDim != null) {
        return Number(primDim);
    }
    else if (sekDim != null) {
        return Number(sekDim);
    }
    else {
        return NaN;
    }
}

const getLargestDimValues = function getLargestDim(primDim, sekDim) {
    if (primDim != null && sekDim != null) {
        return Math.max(Number(primDim), Number(sekDim));
    }
    else if (primDim != null) {
        return Number(primDim);
    }
    else if (sekDim != null) {
        return Number(sekDim);
    }
    else {
        return NaN;
    }
}

export { getLargestDim, getLargestDimValues }