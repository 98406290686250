"use client"

import { useRef, useState, useEffect } from "react";
import { get, onValue, ref } from "firebase/database";
//import cookieCutter from 'cookie-cutter'
import { EmailAuthProvider, onIdTokenChanged, sendPasswordResetEmail, sendEmailVerification, signOut, signInAnonymously } from "firebase/auth";
import { AuthContext } from "./AuthContext";
import { useAuth, useDatabase, useFunctions } from "reactfire";
import { inactivateUser } from "../firebase/functions/cloudFunctions/inactivateAccount";

const mapFirebaseResponseToTenant = (
    result,
    user,
    database
) => {
    const providerData = user.providerData && user.providerData[0];
    //console.log("Start!", user.uid, user.isAnonymous, providerData)
    if (user.isAnonymous){
        return {
            id: user.uid,
            name: user.displayName || providerData?.displayName || user.email || null,
            email: user.email || null,
            emailVerified: user.emailVerified || false,
            photoUrl: user.photoURL || null,
            customClaims: {},
            isAnonymous: user.isAnonymous,
            idToken: result.token,
        };
    }
    else{
        return get(ref(database, 'SLUsers/' + user.uid)).then((data) => {
            //console.log("GetData", user.uid, data.exists());
            if (!user.isAnonymous && providerData && data.exists()) {
                //console.log("Data:", data.val())
                return {
                    id: user.uid,
                    name: providerData.displayName || user.displayName || user.email || null,
                    email: providerData.email || null,
                    emailVerified: user.emailVerified || false,
                    photoUrl: providerData.photoURL || null,
                    customClaims: {},
                    isAnonymous: user.isAnonymous,
                    idToken: result.token,
                    ...data.val()
                };
            }
            else {
                return {
                    id: user.uid,
                    name: user.displayName || providerData?.displayName || user.email || null,
                    email: user.email || null,
                    emailVerified: user.emailVerified || false,
                    photoUrl: user.photoURL || null,
                    customClaims: {},
                    isAnonymous: user.isAnonymous,
                    idToken: result.token,
                };
            }
        })
    }
    
};

export const AuthContextProvider = ({ children, }) => {
    const firstLoadRef = useRef(true);
    const auth = useAuth();
    const database = useDatabase();
    const functions = useFunctions();

    const [listenerAttached, setListenerAttached] = useState('');
    const [listener, setListener] = useState(null);
    const [user, setUser] = useState(null);
    const [loggingOutUser, setLoggingOutUser] = useState(false);
    const [loadingUser, setLoadingUser] = useState(false);

    useEffect(() => {
        setLoadingUser(true);
        try {
            if (user != null && user !== "" && listenerAttached != null && listenerAttached !== "") {
                if (user.id !== listenerAttached) {
                    if (listener != null && listener !== "") {
                        listener();
                    }

                    setListenerAttached(user.id)
                    const listenerVal = onValue(ref(database, 'SLUsers/' + user.id), (snapshot) => {
                        if (snapshot.exists()) {
                            setUser({ ...user, ...snapshot.val() });
                        }

                        setLoadingUser(false);
                    });
                    //setListener(listenerVal);
                }
                else {
                    setLoadingUser(false);
                }
            }
            else if (user != null && user !== "") {
                setListenerAttached(user.id)
                const listenerVal = onValue(ref(database, 'SLUsers/' + user.id), (snapshot) => {
                    if (snapshot.exists()) {
                        setUser({ ...user, ...snapshot.val() });
                    }

                    setLoadingUser(false);
                });
            }
            else {
                setLoadingUser(false);
            }
        } catch (error) {
            setLoadingUser(false);
        }


    }, [user]);

    // Call logout any time
    const handleLogout = async () => {
        setLoggingOutUser(true);

        return signInAnonymously(auth).then(() => {
            setLoggingOutUser(true);
        }).catch((error) => {
            console.error("Error logging out: ", error);
        })
    };

    const handleChangePassword = async (oldPassword, newPassword) => {
        const { updatePassword, reauthenticateWithCredential } = await import("firebase/auth");
        if (auth?.currentUser?.email != null) {
            let credential = EmailAuthProvider.credential(auth.currentUser.email, oldPassword);
            return reauthenticateWithCredential(auth.currentUser, credential).then((credentials) => {

                return updatePassword(auth.currentUser, newPassword).then(async () => {
                    const tokenResult = await credentials.user.getIdTokenResult();

                    setUser(await mapFirebaseResponseToTenant(tokenResult, credentials.user, database));;
                })
            }).catch((error) => {
                throw error;
            });
        }
    }

    const handleVerifyEmail = () => {
        if (auth.currentUser != null) {
            return sendEmailVerification(auth.currentUser)
        }
    }

    const handleResetPassword = (email) => {
        return sendPasswordResetEmail(auth, email)
    }

    const handleInactivateAccount = () => {
        return inactivateUser(functions, user.id).then(() => {
            return handleLogout();
        })
    }

    const handleIdTokenChanged = async (firebaseUser) => {

        if (firebaseUser && user && firebaseUser.uid === user.id) {
            firstLoadRef.current = false;
            return;
        }

        if (!firebaseUser) {
            firstLoadRef.current = false;
            setUser(null);
            return;
        }

        firstLoadRef.current = false;
        const tokenResult = await firebaseUser.getIdTokenResult();

        setUser(await mapFirebaseResponseToTenant(tokenResult, firebaseUser, database));
    };

    const registerChangeListener = async () => {
        return onIdTokenChanged(auth, handleIdTokenChanged);
    };

    useEffect(() => {
        const unsubscribePromise = registerChangeListener();

        return () => {
            unsubscribePromise.then((unsubscribe) => unsubscribe());
        };
    }, []);

    return (
        <AuthContext.Provider
            value={{
                user,
                loadingUser,
                loggingOutUser,
                firstLoadRef,
                handleLogout,
                handleChangePassword,
                handleResetPassword,
                handleVerifyEmail,
                handleInactivateAccount,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
