import { ErrorEnum } from "../../../enums/errorEnum";
import { NoticeEnum } from "../../../enums/noticeEnum";
import { ErrorMsg } from "../../../constants/errorMessages";
import { NoticeMsg } from "../../../constants/noticeMessages";
import { PRODUCTLINE_FLEX, PRODUCTLINE_SMART, PRODUCTPRINCIP_PR2, PRODUCTPRINCIP_PR3, PRODUCTPRINCIP_SR2, PRODUCTPRINCIP_SR2SMART, PRODUCTPRINCIP_SR3, PRODUCTPRINCIP_VA1 } from "../../../constants/productlineConstants";


const checkForShuntErrorsTemperature = function checkForShuntErrorsTemperature(plProdPrincip, primTempIn, primTempOut, secTempIn, secTempOut, errorContext, noticeContext, useContext = true, localErrorList = null, localNoticeList = null) {
    let hasErrors = false;
    const ErrorList = new ErrorEnum();
    const NoticeList = new NoticeEnum();

    //Errors
    if (Number(primTempIn) > 90 || Number(primTempOut) > 90 || Number(secTempIn) > 90 || Number(secTempOut) > 90) {
        hasErrors = true;
        if (useContext === true) {
            ErrorList.addError(errorContext, ErrorMsg.temp_tempToHigh.key, ErrorMsg.temp_tempToHigh.msg);
        }
        else {
            localErrorList[ErrorMsg.temp_tempToHigh.key] = ErrorMsg.temp_tempToHigh.msg;
        }
    }

    if (plProdPrincip === PRODUCTPRINCIP_VA1 && (Number(primTempIn) < -10 || Number(primTempOut) < -10 || Number(secTempIn) < -10 || Number(secTempOut) < -10)) {
        hasErrors = true;
        if (useContext === true) {
            ErrorList.addError(errorContext, ErrorMsg.temp_tempToLowVA.key, ErrorMsg.temp_tempToLowVA.msg);
        }
        else {
            localErrorList[ErrorMsg.temp_tempToLowVA.key] = ErrorMsg.temp_tempToLowVA.msg;
        }
    }
    else if (plProdPrincip !== PRODUCTPRINCIP_VA1 && (Number(primTempIn) < -5 || Number(primTempOut) < -5 || Number(secTempIn) < -5 || Number(secTempOut) < -5)) {
        hasErrors = true;
        if (useContext === true) {
            ErrorList.addError(errorContext, ErrorMsg.temp_tempToLow.key, ErrorMsg.temp_tempToLow.msg);
        }
        else {
            localErrorList[ErrorMsg.temp_tempToLow.key] = ErrorMsg.temp_tempToLow.msg;
        }
    }

    let minVal = Math.min(Number(primTempIn), Number(secTempOut));
    let maxVal = Math.max(Number(primTempIn), Number(secTempOut));

    if (primTempOut < minVal || primTempOut > maxVal) {
        hasErrors = true;
        if (useContext === true) {
            ErrorList.addError(errorContext, ErrorMsg.temp_inconsistentTempPrimOut.key, ErrorMsg.temp_inconsistentTempPrimOut.msg);
        }
        else {
            localErrorList[ErrorMsg.temp_inconsistentTempPrimOut.key] = ErrorMsg.temp_inconsistentTempPrimOut.msg;
        }
    }

    if (secTempIn < minVal || secTempIn > maxVal) {
        hasErrors = true;
        if (useContext === true) {
            ErrorList.addError(errorContext, ErrorMsg.temp_inconsistentTempSecIn.key, ErrorMsg.temp_inconsistentTempSecIn.msg);
        }
        else {
            localErrorList[ErrorMsg.temp_inconsistentTempSecIn.key] = ErrorMsg.temp_inconsistentTempSecIn.msg;
        }
    }

    /*
    if (secTempIn < primTempIn && secTempIn < secTempOut) {
        hasErrors = true;
        if (useContext === true) {
            ErrorList.addError(errorContext, ErrorMsg.temp_inconsistentTempSecInLow.key, ErrorMsg.temp_inconsistentTempSecInLow.msg);
        }
        else {
            localErrorList[ErrorMsg.temp_inconsistentTempSecInLow.key] = ErrorMsg.temp_inconsistentTempSecInLow.msg;
        }
    }

    if (secTempIn > primTempIn && secTempIn > secTempOut) {
        hasErrors = true;
        if (useContext === true) {
            ErrorList.addError(errorContext, ErrorMsg.temp_inconsistentTempSecInHigh.key, ErrorMsg.temp_inconsistentTempSecInHigh.msg);
        }
        else {
            localErrorList[ErrorMsg.temp_inconsistentTempSecInHigh.key] = ErrorMsg.temp_inconsistentTempSecInHigh.msg;
        }
    }

    if (secTempOut < primTempIn && primTempOut < secTempOut) {
        hasErrors = true;
        if (useContext === true) {
            ErrorList.addError(errorContext, ErrorMsg.temp_inconsistentTempSecOutLow.key, ErrorMsg.temp_inconsistentTempSecOutLow.msg);
        }
        else {
            localErrorList[ErrorMsg.temp_inconsistentTempSecOutLow.key] = ErrorMsg.temp_inconsistentTempSecOutLow.msg;
        }
    }

    if (secTempOut > primTempIn && primTempOut > secTempOut) {
        hasErrors = true;
        if (useContext === true) {
            ErrorList.addError(errorContext, ErrorMsg.temp_inconsistentTempSecOutHigh.key, ErrorMsg.temp_inconsistentTempSecOutHigh.msg);
        }
        else {
            localErrorList[ErrorMsg.temp_inconsistentTempSecOutHigh.key] = ErrorMsg.temp_inconsistentTempSecOutHigh.msg;
        }
    }*/

    //Notices
    if (Number(secTempIn) < Number(secTempOut) && Number(primTempIn) >= 18 && Number(primTempOut) >= 18 && Number(secTempIn) >= 18 && Number(secTempOut) >= 18) {
        if (useContext === true) {
            NoticeList.addNotice(noticeContext, NoticeMsg.temp_tempIncrease.key, NoticeMsg.temp_tempIncrease.msg);
        }
        else {
            localNoticeList[NoticeMsg.temp_tempIncrease.key] = NoticeMsg.temp_tempIncrease.msg;
        }
    }

    if (Number(secTempIn) > Number(secTempOut) && (Number(primTempIn) < 18 || Number(primTempOut) < 18 || Number(secTempIn) < 18 || Number(secTempOut) < 18)) {
        if (useContext === true) {
            NoticeList.addNotice(noticeContext, NoticeMsg.temp_tempDecrease.key, NoticeMsg.temp_tempDecrease.msg);
        }
        else {
            localNoticeList[NoticeMsg.temp_tempDecrease.key] = NoticeMsg.temp_tempDecrease.msg;
        }
    }

    if (primTempOut == null || isNaN(primTempOut)) {
        primTempOut = secTempOut;
    }

    if (primTempIn == null || isNaN(primTempIn)) {
        primTempIn = secTempIn;
    }

    if (secTempOut === primTempOut && secTempOut === primTempIn && primTempOut === primTempIn && primTempIn != null) {
        if (useContext === true) {
            NoticeList.addNotice(noticeContext, NoticeMsg.temp_equal.key, NoticeMsg.temp_equal.msg);
        }
        else {
            localNoticeList[NoticeMsg.temp_equal.key] = NoticeMsg.temp_equal.msg;
        }
    }

    return hasErrors;
}

const checkForPressureErrors = function checkForPressureErrors(primDrivingPressure, primInternalPressureDrop, secInternalPressureDrop, SvPressureDrop, plProdLine, plPrincipVal, maxPressure, hasMainPump, primFLow, dimMaxFlow, calcMode, errorContext, noticeContext, useContext = true, localErrorList = null, localNoticeList = null) {
    const ErrorList = new ErrorEnum();
    const NoticeList = new NoticeEnum();
    //console.log("Pressure:", primDrivingPressure)
    if (primDrivingPressure != null) {
        if (Number(primDrivingPressure) < 7) {
            if (plPrincipVal === PRODUCTPRINCIP_SR2 || plPrincipVal === PRODUCTPRINCIP_SR2SMART) {
                //console.error(ErrorMsg.pressure_toLowPrimDrivingPressure_SR2.msg);
                if (useContext === true) {
                    ErrorList.clearAllErrors(errorContext);
                    ErrorList.addError(errorContext, ErrorMsg.pressure_toLowPrimDrivingPressure_SR2.key, ErrorMsg.pressure_toLowPrimDrivingPressure_SR2.msg);
                }
                else {
                    localErrorList = {};
                    localErrorList[ErrorMsg.pressure_toLowPrimDrivingPressure_SR2.key] = ErrorMsg.pressure_toLowPrimDrivingPressure_SR2.msg;
                }
                return;
            }
            else if (plPrincipVal === PRODUCTPRINCIP_SR3) {
                if (useContext === true) {
                    NoticeList.addNotice(noticeContext, NoticeMsg.pressure_toLowPrimDrivingPressure_SR3.key, NoticeMsg.pressure_toLowPrimDrivingPressure_SR3.msg);
                }
                else {
                    localNoticeList[NoticeMsg.pressure_toLowPrimDrivingPressure_SR3.key] = NoticeMsg.pressure_toLowPrimDrivingPressure_SR3.msg;
                }
            }
        }

        //console.log("Pressure Check: ", calcMode, primInternalPressureDrop)
        if (calcMode === 0) {
            if (Number(primInternalPressureDrop) > 20) {
                //console.log("Pressure Check 2: ", calcMode, plPrincipVal, hasMainPump)
                if ((plPrincipVal === PRODUCTPRINCIP_PR2 && hasMainPump === false) || plPrincipVal === PRODUCTPRINCIP_VA1) {
                    if (useContext === true) {
                        NoticeList.addNotice(noticeContext, NoticeMsg.pressure_primInternalPressureDropToHighNoDp.key, NoticeMsg.pressure_primInternalPressureDropToHighNoDp.msg);
                    }
                    else {
                        localNoticeList[NoticeMsg.pressure_primInternalPressureDropToHighNoDp.key] = NoticeMsg.pressure_primInternalPressureDropToHighNoDp.msg;
                    }
                }
            }

            if (plPrincipVal === PRODUCTPRINCIP_SR3 || plPrincipVal === PRODUCTPRINCIP_SR2){
                if (Number(secInternalPressureDrop) > 20){
                    if (useContext === true) {
                        NoticeList.addNotice(noticeContext, NoticeMsg.pressure_secInternalPressureDropToHigh_SR.key, NoticeMsg.pressure_secInternalPressureDropToHigh_SR.msg);
                    }
                    else {
                        localNoticeList[NoticeMsg.pressure_secInternalPressureDropToHigh_SR.key] = NoticeMsg.pressure_secInternalPressureDropToHigh_SR.msg;
                    }
                }
            }
            else if (plPrincipVal === PRODUCTPRINCIP_PR2 || plPrincipVal === PRODUCTPRINCIP_PR3 || plPrincipVal === PRODUCTPRINCIP_VA1){
                if (Number(secInternalPressureDrop) - Number(SvPressureDrop) > 20){
                    if (useContext === true) {
                        NoticeList.addNotice(noticeContext, NoticeMsg.pressure_secMountedCvInternalPressureDropToHigh_dim.key, NoticeMsg.pressure_secMountedCvInternalPressureDropToHigh_dim.msg);
                    }
                    else {
                        localNoticeList[NoticeMsg.pressure_secMountedCvInternalPressureDropToHigh_dim.key] = NoticeMsg.pressure_secMountedCvInternalPressureDropToHigh_dim.msg;
                    }
                }
                else if (Number(SvPressureDrop) > 35 && plPrincipVal !== PRODUCTPRINCIP_VA1){
                    if (useContext === true) {
                        NoticeList.addNotice(noticeContext, NoticeMsg.pressure_secMountedCvInternalPressureDropToHigh_cv.key, NoticeMsg.pressure_secMountedCvInternalPressureDropToHigh_cv.msg);
                    }
                    else {
                        localNoticeList[NoticeMsg.pressure_secMountedCvInternalPressureDropToHigh_cv.key] = NoticeMsg.pressure_secMountedCvInternalPressureDropToHigh_cv.msg;
                    }
                }
            }
        }

        if ((plPrincipVal === PRODUCTPRINCIP_SR3 || plPrincipVal === PRODUCTPRINCIP_SR2) && calcMode === 0){
            if (Number(primDrivingPressure) < Number(SvPressureDrop)){
                if (useContext === true) {
                    ErrorList.addError(errorContext, ErrorMsg.pressure_primInternalPressureDropToHigh_SR_cv.key, ErrorMsg.pressure_primInternalPressureDropToHigh_SR_cv.msg);
                }
                else {
                    localErrorList[ErrorMsg.pressure_primInternalPressureDropToHigh_SR_cv.key] = ErrorMsg.pressure_primInternalPressureDropToHigh_SR_cv.msg;
                }
            }
            else if (Number(primDrivingPressure) < Number(primInternalPressureDrop) && primFLow > dimMaxFlow){
                if (useContext === true) {
                    ErrorList.addError(errorContext, ErrorMsg.pressure_primInternalPressureDropToHigh_SR_dim.key, ErrorMsg.pressure_primInternalPressureDropToHigh_SR_dim.msg);
                }
                else {
                    localErrorList[ErrorMsg.pressure_primInternalPressureDropToHigh_SR_dim.key] = ErrorMsg.pressure_primInternalPressureDropToHigh_SR_dim.msg;
                }
            }
            else if (Number(primDrivingPressure) < Number(primInternalPressureDrop)){
                if (useContext === true) {
                    ErrorList.addError(errorContext, ErrorMsg.pressure_primInternalPressureDropToHigh_SR.key, ErrorMsg.pressure_primInternalPressureDropToHigh_SR.msg);
                }
                else {
                    localErrorList[ErrorMsg.pressure_primInternalPressureDropToHigh_SR.key] = ErrorMsg.pressure_primInternalPressureDropToHigh_SR.msg;
                }
            }
        }

        if (Number(primDrivingPressure) < Number(primInternalPressureDrop)) {
            if (plPrincipVal === PRODUCTPRINCIP_PR2 && hasMainPump === true) {
                if (useContext === true) {
                    ErrorList.addError(errorContext, ErrorMsg.pressure_primInternalPressureDropToHigh_PR2.key, ErrorMsg.pressure_primInternalPressureDropToHigh_PR2.msg);
                }
                else {
                    localErrorList[ErrorMsg.pressure_primInternalPressureDropToHigh_PR2.key] = ErrorMsg.pressure_primInternalPressureDropToHigh_PR2.msg;
                }
            }
            else if (plPrincipVal === PRODUCTPRINCIP_PR3) {
                if (useContext === true) {
                    ErrorList.addError(errorContext, ErrorMsg.pressure_primInternalPressureDropToHigh_PR3.key, ErrorMsg.pressure_primInternalPressureDropToHigh_PR3.msg);
                }
                else {
                    localErrorList[ErrorMsg.pressure_primInternalPressureDropToHigh_PR3.key] = ErrorMsg.pressure_primInternalPressureDropToHigh_PR3.msg;
                }
            }
        }

        if (plProdLine !== PRODUCTLINE_FLEX && plProdLine !== PRODUCTLINE_SMART) {
            if (Number(primDrivingPressure) > Number(maxPressure)) {
                //console.error(ErrorMsg.pressure_toHighPrimDrivingPressure.msg);
                if (useContext === true) {
                    if (Number(maxPressure) === 90) {
                        ErrorList.addError(errorContext, ErrorMsg.pressure_toHighPrimDrivingPressure.key, ErrorMsg.pressure_toHighPrimDrivingPressure.msg);
                    }
                    else {
                        ErrorList.addError(errorContext, ErrorMsg.pressure_toHighPrimDrivingPressureManualPressure.key, ErrorMsg.pressure_toHighPrimDrivingPressureManualPressure.msg + maxPressure + " kPa");
                    }

                }
                else {
                    if (Number(maxPressure) === 90) {
                        localErrorList[ErrorMsg.pressure_toHighPrimDrivingPressure.key] = ErrorMsg.pressure_toHighPrimDrivingPressure.msg;
                    }
                    else {
                        localErrorList[ErrorMsg.pressure_toHighPrimDrivingPressure.key] = ErrorMsg.pressure_toHighPrimDrivingPressure.msg + maxPressure + " kPa";
                    }
                }
            }
        }

    }
}

const checkForFlowErrors = function checkForFlowErrors(primDrivingPressure, primFlow, sekFlow, plProdLine, plPrincipVal, errorContext, noticeContext, useContext = true, localErrorList = null, localNoticeList = null) {
    //const ErrorList = new ErrorEnum();
    const NoticeList = new NoticeEnum();
    //console.log("Below Pressure:", primDrivingPressure)

    if (primFlow < sekFlow) {
        if (plPrincipVal === PRODUCTPRINCIP_PR2) {
            if (Number(primDrivingPressure) >= 7) {
                if (useContext === true) {
                    NoticeList.addNotice(noticeContext, NoticeMsg.flow_primFlowLowerThanSecAbove7kPa_PR2.key, NoticeMsg.flow_primFlowLowerThanSecAbove7kPa_PR2.msg);
                }
                else {
                    localNoticeList[NoticeMsg.flow_primFlowLowerThanSecAbove7kPa_PR2.key] = NoticeMsg.flow_primFlowLowerThanSecAbove7kPa_PR2.msg;
                }
            }
            else if (Number(primDrivingPressure) < 7) {
                if (useContext === true) {
                    NoticeList.addNotice(noticeContext, NoticeMsg.flow_primFlowLowerThanSecBelow7kPa_PR2.key, NoticeMsg.flow_primFlowLowerThanSecBelow7kPa_PR2.msg);
                }
                else {
                    localNoticeList[NoticeMsg.flow_primFlowLowerThanSecBelow7kPa_PR2.key] = NoticeMsg.flow_primFlowLowerThanSecBelow7kPa_PR2.msg;
                }
            }
        }
        else if (plPrincipVal === PRODUCTPRINCIP_PR3) {
            if (useContext === true) {
                NoticeList.addNotice(noticeContext, NoticeMsg.flow_primFlowLowerThanSec_PR3.key, NoticeMsg.flow_primFlowLowerThanSec_PR3.msg);
            }
            else {
                localNoticeList[NoticeMsg.flow_primFlowLowerThanSec_PR3.key] = NoticeMsg.flow_primFlowLowerThanSec_PR3.msg;
            }
        }
    }
    else if (primFlow > sekFlow && plPrincipVal === PRODUCTPRINCIP_SR3) {
        if (useContext === true) {
            NoticeList.addNotice(noticeContext, NoticeMsg.flow_primFlowHigherThanSec_SR3.key, NoticeMsg.flow_primFlowHigherThanSec_SR3.msg);
        }
        else {
            localNoticeList[NoticeMsg.flow_primFlowHigherThanSec_SR3.key] = NoticeMsg.flow_primFlowHigherThanSec_SR3.msg;
        }
    }

}

export { checkForShuntErrorsTemperature, checkForPressureErrors, checkForFlowErrors }