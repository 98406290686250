import { Stack, TextField } from "@mui/material"
import { useEffect, useState } from "react";
import { DriftParamEnum } from "../../../../enums";
import Tooltip from '@mui/material/Tooltip';

export const ShuntParamTextfield = ({ paramsEnum = null, context = null, labelText, isDisabled = false, end = null, className = "", isNumber = true }) => {
    const getValueFromContext = () => {

        if (paramsEnum?.getContextValue(context) != null) {
            return paramsEnum.getContextValue(context);
        }
        else {
            return '';
        }
    }
    const [value, setValue] = useState(getValueFromContext());

    const setEditableValue = (event) => {
        if (paramsEnum === DriftParamEnum.PEfk) {
            paramsEnum.setContextValue(context, event.target.value);
        }
        setValue(event.target.value);
    }

    const setValueFromContext = () => {
        if (paramsEnum?.getContextValue(context) != null) {
            setValue(paramsEnum.getContextValue(context));
        }
        else {
            setValue('');
        }
    }

    useEffect(() => {
        if (paramsEnum != null) {
            setValueFromContext();
        }
    }, [paramsEnum, context?.state])

    return (
        <Stack direction={"row"} justifyContent="space-evenly">
            <div style={{ marginTop: "18px" }}>
                {labelText}:
            </div>

            {end != null ? (
                <>
                    {labelText === "Efk" ? (
                        <Tooltip title="Ange tryckfallet i den externa flödesvariabla kretsen (primära) i kPa för att kunna räkna ut styrventilens auktoritet" placement="top">
                            <TextField
                                style={{ marginLeft: "auto", width: "37%", height: "10px" }}
                                size={"small"}
                                value={value || ''}
                                margin="dense"
                                fullWidth
                                onChange={setEditableValue}
                                disabled={isDisabled}
                            />
                        </Tooltip>
                    ) : (
                        <TextField
                            style={{ marginLeft: "auto", width: "38%", height: "10px" }}
                            size={"small"}
                            value={value || ''}
                            margin="dense"
                            fullWidth
                            onChange={setEditableValue}
                            disabled={isDisabled}
                        />
                    )}
                    <div className={className} style={{ marginTop: "15px", marginLeft: '5%', float: "right" }}>
                        <Stack>{end}</Stack>
                    </div></>

            ) : (<>
                {isNumber ? (
                    <TextField
                        style={{ marginLeft: "auto", width: "50%", height: "10px" }}
                        size={"small"}
                        type={'number'}
                        value={value || ''}
                        margin="dense"
                        fullWidth
                        onChange={setEditableValue}
                        disabled={isDisabled}
                    />
                ) : (
                    <TextField
                        style={{ marginLeft: "auto", width: "50%", height: "10px" }}
                        size={"small"}
                        value={value || ''}
                        margin="dense"
                        fullWidth
                        onChange={setEditableValue}
                        disabled={isDisabled}
                    />
                )}

            </>)}
        </Stack>
    );
}