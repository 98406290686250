import overallImg1 from '../../assets/images/SL-Overall-Info-1c.png'
import overallImg2 from '../../assets/images/SL-Overall-Info-1d.png'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportIcon from '@mui/icons-material/Report';
import HelpIcon from '@mui/icons-material/Help';

export const IntroOverviewAndNavigation = () => {
    return (
        <div>

            <h2 className="intro-title" style={{ marginBlockStart: '0px' }}>Översikt och navigering mellan positioner</h2>

            <p>Från och med ShuntLogik<sup>®</sup> 1.5 är det enklare att få en överblick över alla positioner i en pågående körning, samt att navigera mellan dem. Där finns även en större flexibilitet i arbetsflödet med dimensioneringen, och det är till exempel möjligt att påbörja dimensionering och konfigurering av ytterligare positioner innan körningens första position är färdigkonfigurerad.</p>

            <p> Arbetsflödet med dimensionering och konfigurering av en specifik position är oförändrat från tidigare versioner av ShuntLogik<sup>®</sup>, där konfiguratorn vägleder användaren genom stegen Kopplingsprincip &rarr; Parametrar &rarr; Utförande &rarr; Pumpval.
            </p>

            <p>Nytt från och med ShuntLogik<sup>®</sup> 1.5 är däremot den information och navigeringsmöjligheter som visas överst i verktyget, utanför den stegvisa konfigureringen per position.</p>

            <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={overallImg1} style={{ width: '95%' }} alt="Skärmavbild som visar informations och navigationsverktyget " />
            </div>

            <p>Till vänster, under rubriken <strong>Shuntgrupp</strong> visas produktlinje och kopplingsprincip för den position som för närvarande är vald och öppen i den stegvisa konfigureringen. Dess positionsnummer samt beteckning visas även i den meny där det är möjligt att snabbt och enkelt byta till en annan position i körningen, för att granska eller arbeta vidare med dess konfigurering.</p>

            <p>Till höger, under rubriken <strong>Session</strong> framgår körningens Sessions-ID <i>(för mer information om sessioner, se avsnitt <strong>Hantering av körningar/sessioner</strong>)</i> samt vilken anläggningen körningen avser.</p>

            <p>Under detta finns en knapp för att öppna Översikt-fönstret (inom parentes framgår hur många påbörjade positioner körningen för närvarande innehåller). Denna översikt är tillgänglig när som under en körning, oberoende av vilken position man för närvarande konfigurerar.</p>

            <h3 className="header-title" style={{ marginBlockStart: '0px', marginBottom: '0px' }}>Funktionalitet via Översikt</h3>
            <p>Informationen nedan, om användningen av verktygen tillgängliga via Översikt-fönstret, kan även nås via dess <i>Hjälpavsnitt</i> <HelpIcon className="help_icon_small" /></p>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={overallImg2} style={{ width: '95%' }} alt="Skärmavbild som visar informations och navigationsverktyget " />
            </div>

            <p>Här på fliken Hantera shuntgrupper i Översiktsfönstret ser du en tabell över alla shuntgrupper i den aktuella körningen. I den här tabellen kan du bland annat ta bort shuntgrupper som du inte önskar behålla. Det går inte att ta bort den shuntgrupp som för närvarande är vald som aktiv. Du väljer vilken shuntgrupp som ska vara aktiv genom att klicka på dess rad i tabellen. När du stänger ner Översiktsfönstret så kommer du då befinna dig på den valda shuntgruppens konfigurering. Du kan även dubbelklicka på en rad i tabellen för att automatiskt stänga Översiktsfönstret och komma direkt in i konfigureringen.</p>

            <p>Längst till vänster på varje rad i tabellen kan du se aktuell status för respektive position:</p>

            <p><CheckCircleIcon className="check-icon-green-help" /> indikerar att positionen är färdigkonfigurerad och inte har några felmeddelanden.</p>

            <span style={{ paddingRight: '4px', paddingTop: '3px' }}><svg style={{ color: '#1976d2', height: '1.2em', width: '1.2em' }} focusable="false" aria-hidden="true" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12" style={{ fill: '#06559e' }}></circle><text style={{ fill: '#fff', fontFamily: 'roboto', color: '#06559e' }} x="12" y="16" textAnchor="middle">2</text></svg></span>
            <span>indikerar att positionen ännu inte är färdigkonfigurerad/granskad. Siffran indikerar vilket steg som konfigureringen för närvarande befinner sig, och du kommer automatiskt att tas hit om du väljer shuntgruppen som aktiv.</span>

            <p><ReportIcon fontSize="small" color='error' /> indikerar att positionen har ett kritiskt felmeddelande som måste åtgärdas innan den kan konfigureras färdigt. Positionen kan ha denna felstatus antingen för att du lämnade dess konfigurering när den hade ett aktivt felmeddelande, eller som ett resultat av en omberäkning via Snabbredigerings-funktionen (se längre ner).</p>

            <h3 className="intro-subheaders"><i>Lägg till shuntgrupp</i></h3>
            <p>
                Via knappen Lägg till shuntgrupp kan du välja att skapa en ny position helt från grunden, eller basera den nya shuntgruppen på en befintlig position. Du kan styra vilken position som den nya shuntgruppen baseras på genom att sätta den som aktiv i tabellen. En nyskapad position som baserats på en tidigare har systemparametrar och konfigureringsval förifyllda, men du måste fortfarande gå igenom konfigurationens alla steg för att granska och godkänna alla dimensioneringar och val. Det är helt fritt att ändra parametrar och val för den nya shuntgruppen, även om den baserats på en annan position.
            </p>

            <h3 className="intro-subheaders"><i>Snabbredigering</i></h3>
            <p>
                Det är möjligt att ändra vissa systemparametrar för en eller flera positioner och genomföra omberäkning/dimensionering direkt från Hantera shuntgrupper-tabellen. När du klickar på knappen Snabbredigering så kan du bland annat redigera Anläggning och Projektnummer för körningen, samt ändra positionernas Beteckning. Det är även möjligt att ändra systemparametervärdena för tillgängligt drivtryck samt primär vätsketemperatur till shuntgruppen. När du sedan klickar på Uppdatera shuntgrupper så kommer alla ändringar sparas, och dimensioneringsberäkningarna kommer göras om för alla positioner med nya systemparametervärden. Om beräkningarna baserade på de nya värdena har gett upphov till ett felmeddelande så kommer detta framgå i tabellen, och du behöver gå till den aktuella positionens konfigurering för att granska felmeddelandet och vidta lämpliga åtgärder.
            </p>

            <h3 className="intro-subheaders"><i>Specifikation</i></h3>

            <p>
                I menyn till höger hittar du knappen Specifikation. Via den kan du se en sammanställning över alla dina färdigkonfigurerade shuntgrupper i den aktuella körningen. Du kan välja att skriva ut sammanställningen direkt, eller spara ner specifikationen i Word-format.
            </p>

            <h3 className="intro-subheaders"><i>Dela session</i></h3>
            <p>
                I menyn till höger finns även knappen Dela session. Du kan när som helst under en körning använda dig av denna funktion för att skicka ett automatiskt epostmeddelande till PREMA, med en länk till din aktuella körning. Du behöver ange dina kontaktuppgifter, och kan även skriva ett meddelande som bifogas länken, exempelvis med en fråga eller önskemål.
            </p>

            <p>
                Det finns även stöd för att manuellt ange en annan mottagare än PREMA, om du exempelvis vill dela en körning med en kollega eller liknande.
            </p>

            <h3 className="intro-subheaders"><i>Begär offert</i></h3>
            <p>
                Förutsatt att alla positioner i körningen är färdigkonfigurerade så finns det möjlighet att begära en offert för shuntgrupperna direkt via Begär offert-knappen. Du behöver ange dina företags- och kontaktuppgifter, och du kommer få ett epostmeddelande med offert från en av PREMAs säljare efter att de granskat körningen som du begärt offert för. Det går såklart även bra att be om offert genom Dela session-funktionaliteten, eller genom att spara ner specifikationen i Word-format och bifoga i ett eget epostmeddelande, eller på annat vis skicka till någon av PREMAs säljare.
            </p>

            <h3 className="header-title" style={{ marginBlockStart: '0px', marginBottom: '0px' }}>Granska/Specifikation</h3>

            <p>
                I tidigare versioner av ShuntLogik<sup>®</sup> har sista steget (Dokumentation) i konfigureringen av en specifik position visat specifikationen för körningens alla positioner. Summeringen av körningens alla positioner och skapandet av specifikationerna är från och med ShuntLogik<sup>®</sup> 1.5 flyttat till <strong>Översikt</strong>-fönstret, men möjligheterna till direktutskrift respektive Word-filsexport av alla positioners specifikationer finns kvar även efter denna flytt.
            </p>

            <p>
                Den positions-specifika konfigureringens sista steg har i och med detta bytt namn till Granska, och visar en sammanfattning av positionens alla systemparametrar, dimensioneringar och val. Här finns även snabbknappar för att skapa ytterligare shuntgrupper (baserad på föregående, eller helt ny) eller navigering till Översikt, där en körningsövergripande specifikation enkelt kan granskas, skrivas ut eller sparas i Word-filformat.
            </p>

        </div>
    )
}