import { Paper, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Tooltip, Stack } from "@mui/material"
import { useProductLineParams } from "../../../../../contexts"
import { AdjustmentsEnum, ProductLineEnum } from "../../../../../enums"
import { INSULATION_13ARMAFLEX, INSULATION_13NH_SMART, INSULATION_13ULTIMA, INSULATION_19ARMAFLEX, INSULATION_19NH_SMART, INSULATION_19ULTIMA } from "../../../../../constants/adjustmentsOptions"
import { PRODUCTLINE_GREEN } from "../../../../../constants/productlineConstants"

import ErrorIcon from '@mui/icons-material/Error';

export const AdjustCardInsulation = ({ insulationValue, setAdjustParams, isDisabled }) => {
    const productLineParams = useProductLineParams();
    const productLine = ProductLineEnum.ProductLine;
    const greenLevel = ProductLineEnum.ProductGreenLevel;

    return (
        <div>
            <Paper elevation={0} className="adjust-card">
                <FormControl>
                    <FormLabel id="isolation-radio-buttons-group">Isolering</FormLabel>
                    <RadioGroup
                        aria-labelledby="isolation-radio-buttons-group"
                        name="isolation"
                        value={insulationValue}
                        onChange={(event) => { setAdjustParams(event.target.value, AdjustmentsEnum.IsolationValue) }}
                    >
                        {(productLine.getContextValue(productLineParams) !== PRODUCTLINE_GREEN) &&
                            <FormControlLabel value={INSULATION_13ARMAFLEX} control={<Radio />} label="13mm Armaflex (hellimmad)" disabled={isDisabled()} />
                        }

                        <FormControlLabel
                            value={INSULATION_13NH_SMART}
                            control={<Radio />}
                            label={
                                <>
                                    13mm NH Smart (hellimmad)
                                    {/*(productLine.getContextValue(productLineParams) !== PRODUCTLINE_GREEN) && <Tooltip title="Miljövänligare val"><img src={greenDrop} alt="green drop" style={{ width: '7px', marginLeft: '5px' }} /></Tooltip>*/}
                                </>
                            }
                            disabled={isDisabled()}
                        />

                        {Number(greenLevel.getContextValue(productLineParams)) !== 2 ? (
                            <FormControlLabel
                                value={INSULATION_13ULTIMA}
                                control={<Radio />}
                                label={
                                    <Stack direction={'row'} alignItems={'center'}>
                                        13mm Ultima (hellimmad)
                                        {(productLine.getContextValue(productLineParams) === PRODUCTLINE_GREEN && insulationValue === INSULATION_13ULTIMA) && <Tooltip title="Ultima uppfyller inte bedömningskraven för A eller B hos SundaHus men accepteras hos Byggvarubedömningen"><ErrorIcon sx={{fontSize: '11px', width: '7px', marginLeft: '5px'}} htmlColor="#f1c969" /></Tooltip>}
                                        {/*(productLine.getContextValue(productLineParams) !== PRODUCTLINE_GREEN) && <Tooltip title="Miljövänligare val"><img src={greenDrop} alt="green drop" style={{ width: '7px', marginLeft: '5px' }} /></Tooltip>*/}
                                    </Stack>
                                }
                                disabled={isDisabled()}
                            />
                        ) : ("")}


                        {(productLine.getContextValue(productLineParams) !== PRODUCTLINE_GREEN) &&
                            <FormControlLabel value={INSULATION_19ARMAFLEX} control={<Radio />} label="19mm Armaflex (hellimmad)" disabled={isDisabled()} />
                        }
                        <FormControlLabel
                            value={INSULATION_19NH_SMART}
                            control={<Radio />}
                            label={
                                <>
                                    19mm NH Smart (hellimmad)
                                    {/*(productLine.getContextValue(productLineParams) !== PRODUCTLINE_GREEN) && <Tooltip title="Miljövänligare val"><img src={greenDrop} alt="green drop" style={{ width: '7px', marginLeft: '5px' }} /></Tooltip>*/}
                                </>
                            }
                            disabled={isDisabled()} />

                        {Number(greenLevel.getContextValue(productLineParams)) !== 2 ? (
                            <FormControlLabel
                                value={INSULATION_19ULTIMA}
                                control={<Radio />}
                                label={
                                    <Stack direction={'row'} alignItems={'center'}>
                                        19mm Ultima (hellimmad)
                                        {(productLine.getContextValue(productLineParams) === PRODUCTLINE_GREEN && insulationValue === INSULATION_19ULTIMA) && <Tooltip title="Ultima uppfyller inte bedömningskraven för A eller B hos SundaHus men accepteras hos Byggvarubedömningen"><ErrorIcon sx={{fontSize: '11px', width: '7px', marginLeft: '5px'}} htmlColor="#f1c969" /></Tooltip>}
                                        {/*(productLine.getContextValue(productLineParams) !== PRODUCTLINE_GREEN) && <Tooltip title="Miljövänligare val"><img src={greenDrop} alt="green drop" style={{ width: '7px', marginLeft: '5px' }} /></Tooltip>*/}
                                    </Stack>
                                }
                                disabled={isDisabled()}
                            />
                        ) : ("")}
                    </RadioGroup>
                </FormControl>
            </Paper>
        </div>
    )
}