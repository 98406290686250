import { Stack, styled, TextField, Tooltip, tooltipClasses, Typography } from "@mui/material"
import { Fragment, useState } from "react";
import greenDrop from '../../../../assets/images/green_drop.svg'
import { useProductLineParams } from "../../../../contexts";
import { ProductLineEnum } from "../../../../enums";

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#545454',
        color: 'rgba(255, 255, 255, 0.8)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

export const ShuntInfoGreenLevel = () => {
    const productLineParams = useProductLineParams();

    return (
        <Stack sx={{ marginTop: '10px' }} direction={"row"} justifyContent="flex-start" alignItems="center">
            <div>
                green-nivå:
            </div>
            <div>
                {ProductLineEnum.ProductGreenLevel.getContextValue(productLineParams) === 2 ? (
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <Typography color={"inherit"}><strong>Premablock<sup>®</sup> green II</strong></Typography>
                                <Typography color={"inherit"}>Miljövarudeklarerade enligt ISO 14025</Typography>
                                <Typography color={"inherit"}>och EN 15804:2012+A2:2019</Typography>
                            </Fragment>
                        }
                    >
                        <span>
                            <img src={greenDrop} alt="green drop" style={{ width: '11px', marginLeft: '15px' }} />
                            <img src={greenDrop} alt="green drop" style={{ width: '11px', marginLeft: '5px' }} />
                        </span>
                    </HtmlTooltip>
                ) : (
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <Typography color={"inherit"}><strong>Premablock<sup>®</sup> green I</strong></Typography>
                                <Typography color={"inherit"}>Miljöbedömda av SundaHus och</Typography>
                                <Typography color={"inherit"}>Byggvarubedömningen</Typography>
                            </Fragment>
                        }
                    >
                        <span>
                            <img src={greenDrop} alt="green drop" style={{ width: '11px', marginLeft: '15px' }} />
                        </span>
                    </HtmlTooltip>

                )}
            </div>
        </Stack>
    );
}