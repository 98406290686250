import { Stack, TextField, Tooltip } from "@mui/material"
import { Fragment, useEffect, useState } from "react";


export const APriceTextField = ({ title, option, required = false, amount, price, markupVal, changePriceFunc, changeMarkup, category, standardMarkup, modMarkup = false }) => {
    const [localVal, setLocalVal] = useState(price);
    const [localMarkup, setLocalMarkup] = useState(markupVal)

    const displayTotalPrice = () => {
        if (price != null && markupVal != null) {
            return Math.round((Number(Number(price) * Number(amount)) / 100) * (Number(markupVal) + 100));
        }
        else {
            return 0;
        }
    }

    useEffect(() => {
        if (markupVal == null) {
            changeMarkup(standardMarkup, category);
        }
    }, []);

    useEffect(() => {
        if (localVal != null && isNaN(localVal) === false && Number(localVal) !== Number(price)) {
            changePriceFunc(localVal, category);
        }
    }, [localVal]);

    useEffect(() => {
        if (localMarkup != null && isNaN(localMarkup) === false && Number(localMarkup) !== Number(markupVal)) {
            changeMarkup(localMarkup, category)
        }

    }, [localMarkup]);

    const setPrice = (value) => {
        setLocalVal(value);
    }

    const setMarkup = (value) => {
        setLocalMarkup(value);
    }

    const showToolTip = () => {
        if (category.includes('adjustValve') || category.includes('cutOffValve')){
            return true;
        }
        else{
            return false;
        }
    }

    return (
        <Stack>
            <div style={{ color: "GrayText" }}>
                {required === true ? (
                    <b>{title}</b>
                ) : (
                    String(title)
                )}
            </div>
            <Stack direction={'row'} alignItems="center">
                <TextField
                    style={{ width: "75px" }}
                    type={'number'}
                    InputProps={{
                        endAdornment: 'st',
                    }}
                    value={amount}
                />
                <Tooltip title={showToolTip() === true ? <Fragment>Ange <u>mellanskillnaden</u> mellan standardventil och specifikt ventilval</Fragment> : ""} arrow>
                    <TextField
                        style={{ width: "130px", marginLeft: "2px" }}
                        margin={'none'}
                        type={'number'}
                        InputProps={{
                            inputProps: {
                                min: 0
                            },
                            startAdornment: 'à',
                            endAdornment: 'kr',
                        }}
                        value={price}
                        onChange={(e) => { setPrice(e.target.value) }}
                    />
                </Tooltip>
                <div style={{ marginLeft: '5px', width: "347px" }}>
                    ({option})
                </div>
                {modMarkup === true ? (
                    <TextField
                        style={{ width: "80px", }}
                        margin={'none'}
                        label={'Påslag'}
                        type={'number'}
                        InputProps={{
                            endAdornment: '%',
                            inputProps: {
                                max: 999, min: 0
                            },
                        }}
                        InputLabelProps={{ shrink: true }}
                        value={markupVal}
                        onChange={(e) => { setMarkup(e.target.value) }}
                    />
                ) : (
                    <>
                        <div style={{ paddingLeft: "45px", width: "35px", }}>
                            {markupVal} %
                        </div>
                        {/* 
                    <TextField
                        style={{ width: "80px", }}
                        disabled
                        label={'Påslag'}
                        type={'number'}
                        InputProps={{
                            endAdornment: '%',
                            inputProps: {
                                max: 999, min: 0
                            },
                        }}
                        InputLabelProps={{ shrink: true }}
                        value={markupVal}
                        onChange={(e) => { setMarkup(e.target.value) }}
                    />*/}
                    </>
                )}

                <div style={{ marginLeft: '3px', width: "100px", textAlign: 'right' }}>
                    {String(displayTotalPrice())} kr
                </div>
            </Stack>

        </Stack>
    )
}