
import { ref, update } from "firebase/database";
import { SESSION_PATH } from "../../constants/firebasePaths";
import { SessionParamsEnum } from "../../enums/sessionParamsEnum";
import { ModifySpecEnum } from "../../enums/modifySpecEnum"
import { OFFERT_MODE } from "../../constants/keys/modifySpecKeys";

const saveModifiedData = function saveModifiedData(database, sessionParams, modifySpec, data = null) {
    const sessionID = SessionParamsEnum.SessionID.getContextValue(sessionParams);
    const driftParamsRef = ref(database, SESSION_PATH + "/" + sessionID + "/CustomData");
    let snapData = {};

    if (data != null) {
        snapData = data;
    }
    else {
        // @ts-ignore
        snapData = {
            showID: ModifySpecEnum.ShowID.getContextValue(modifySpec) || null,
            checkedTerms: ModifySpecEnum.CheckedTerms.getContextValue(modifySpec) || null,
            checkedCover: ModifySpecEnum.CheckedCover.getContextValue(modifySpec) || null,
            newPagePerPosition: ModifySpecEnum.NewPagePerPosition.getContextValue(modifySpec) || null,
            displayOption: ModifySpecEnum.DisplayOption.getContextValue(modifySpec) || null,
        }

        if (ModifySpecEnum.OffertMode.getContextValue(modifySpec) != null && ModifySpecEnum.OffertMode.getContextValue(modifySpec) !== ""){
            snapData[OFFERT_MODE] = ModifySpecEnum.OffertMode.getContextValue(modifySpec);
        }
        if (ModifySpecEnum.IncludeMagicloudLink.getContextValue(modifySpec) != null && ModifySpecEnum.IncludeMagicloudLink.getContextValue(modifySpec) !== ""){
            snapData['includeMagicloudLink'] = ModifySpecEnum.IncludeMagicloudLink.getContextValue(modifySpec);
        }
    }

    const promises = [];

    if (SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true){
        promises.push(update(driftParamsRef, snapData));
    }
    

    return Promise.all(promises);
}

export { saveModifiedData }


