import { ref, set } from "firebase/database";
import { SessionParamsEnum } from "../../enums";
import { getShuntSpecList } from "../../firebase/functions/getShuntSpecList";
import { SESSION_MODIFIED_SHUNTS_PATH, SESSION_PATH, SESSION_SHUNT_PATH, SHUNT_SPECLISTDATA } from "../../constants/firebasePaths";


export function resetMaxStepFunc(sessionParams, database) {
    if (SessionParamsEnum.MaxStep.getContextValue(sessionParams) !== SessionParamsEnum.CurrentStep.getContextValue(sessionParams)) {
        getShuntSpecList(database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams)).then((res) => {
            if (res != null && (res?.specList != null || res?.connList || res?.measurementList != null || res?.techList != null)){
               const modifiedRef = ref(database, SESSION_SHUNT_PATH + SessionParamsEnum.CurrentShunt.getContextValue(sessionParams) + SHUNT_SPECLISTDATA + "isModified");
               const modifiedSessionRef = ref(database, SESSION_PATH + SessionParamsEnum.SessionID.getContextValue(sessionParams) + SESSION_MODIFIED_SHUNTS_PATH + SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
               
               const promises = [];
               promises.push(set(modifiedRef, true));
               promises.push(set(modifiedSessionRef, 2));
               
               return Promise.all(promises);
            }
        })
        SessionParamsEnum.MaxStep.setContextValue(sessionParams, SessionParamsEnum.CurrentStep.getContextValue(sessionParams), database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
    }
}