import { Database, ref, set, update } from "firebase/database"
import { SESSION_PATH, SESSION_SHUNT_PATH, SHUNT_ADJUSTDATA_MIXINGVESSEL_PATH, SHUNT_POSITION_PATH, SHUNT_PRODLINE_PATH, SHUNT_SHUNTDATA } from "../../constants/firebasePaths"

/**
 * 
 * @param {Database} database 
 * @param {string} sessionID 
 * @param {string} addonID 
 * @param {string} prodLine 
 * @param {string} size 
 * @param {number} [position] 
 * @param {string} [shuntID]
 * @returns 
 */
export const updateMixingVesselData = (database, sessionID, addonID, prodLine, size, position = null, shuntID = null) => {
    const mixingVesselPLDataRef = ref(database, SESSION_SHUNT_PATH + addonID + SHUNT_PRODLINE_PATH);
    const mixingVesselShuntDataRef = ref(database, SESSION_SHUNT_PATH + addonID + SHUNT_SHUNTDATA);
    
    const promises = [];

    const shuntData = {
        AddonData: {
            size: size,
        }
    }

    const promisePL = set(mixingVesselPLDataRef, prodLine);
    const promiseShuntData = update(mixingVesselShuntDataRef, shuntData);

    promises.push(promisePL, promiseShuntData);

    if (position != null){
        const mixingVesselPositionRef = ref(database, SESSION_SHUNT_PATH + addonID + SHUNT_POSITION_PATH);
        const sessionPositionRef = ref(database, SESSION_PATH + sessionID + SESSION_SHUNT_PATH + addonID);

        const promiseShuntPos = set(mixingVesselPositionRef, position);
        const promiseSessionPos = set(sessionPositionRef, position);

        promises.push(promiseShuntPos, promiseSessionPos);
    }

    if (shuntID != null){
        const shuntMixingVesselData = ref(database, SESSION_SHUNT_PATH + shuntID + SHUNT_ADJUSTDATA_MIXINGVESSEL_PATH);

        const promiseShunt = set(shuntMixingVesselData, size);
        promises.push(promiseShunt);
    }

    return Promise.all(promises);
}