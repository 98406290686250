import { useState } from "react"
import { DialogContent, Typography, DialogActions, Button, Tooltip } from "@mui/material"
import { BootstrapDialog, BootstrapDialogTitle } from "../../utils/bootstrapDialog"
import { useNavigate } from "react-router-dom";
import { useDatabase } from "reactfire";
import { useSessionParams, useAdjustParams, useCalcParams, useDriftParams, useErrorContext, useNoticeContext, useProductLineParams, usePumpParams, useShuntParams } from "../../../contexts";
import { SessionParamsEnum, ShuntParamsEnum, AdjustmentsEnum, CalcParamsEnum, DriftParamEnum, ProductLineEnum } from "../../../enums";
import { PumpParamEnum } from "../../../enums/pumpParamsEnum";
import { saveProductLineData } from "../../../firebase/functions";
import { isCurrentShuntStarted } from "../../../functions/HelperFunctions/isCurrentShuntStarted";
import { getCurrentHighPosition } from "../../../firebase/functions/getCurrentHighPosition";
import { WizardParamEnum } from "../../../enums/wizardParamsEnum";
import { useWizardParams } from "../../../contexts/wizardParams";

import { PUMP_GENERAL_REQ, PUMP_PREMA_CHOICE } from "../../../constants/pumpOptions";
import { CONN_DESIGN_U, CONN_VALUE_NOT_SPECIFIED, NO_MIXINGVESSEL, STAND_FLOOR } from "../../../constants/adjustmentsOptions";

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { saveWizardData } from "../../../firebase/functions/saveWizardData";

export const AddShuntModule = ({ style = {}, fullWidth = false, handleClose = null }) => {
    const database = useDatabase();
    const sessionParams = useSessionParams();

    const adjustParams = useAdjustParams();
    const calcParams = useCalcParams();
    const driftParams = useDriftParams();
    const errorContext = useErrorContext();
    const noticeContext = useNoticeContext();
    const productLine = useProductLineParams();
    const pumpParams = usePumpParams();
    const shuntParams = useShuntParams();
    const wizardParams = useWizardParams();

    const navigate = useNavigate();

    const [openModal, setOpenModal] = useState(false);

    const handleModalClose = () => setOpenModal(false);
    const handleModalOpen = () => setOpenModal(true);

    const isDisabled = () => {
        if (SessionParamsEnum.Editable.getContextValue(sessionParams) === false) {
            return true;
        }
        else {
            return false;
        }
    }

    const clearContexts = (full) => {
        //console.log("Clear!");
        sessionParams.dispatch({ type: 'clearStepData' })
        errorContext.dispatch({ type: 'clearErrorList' })
        noticeContext.dispatch({ type: 'clearNoticeList' })

        if (full === true) {
            //console.log("Full clear")
            AdjustmentsEnum.clearData(adjustParams);
            CalcParamsEnum.clearData(calcParams);
            DriftParamEnum.clearData(driftParams);
            ProductLineEnum.clearData(productLine);
            PumpParamEnum.clearData(pumpParams);
            WizardParamEnum.clearData(wizardParams);
            ShuntParamsEnum.fullClearData(shuntParams);
        }
    }

    const createNew = async () => {
        clearContexts(true);
        const shuntID = await SessionParamsEnum.addSessionShunt(sessionParams, database, true, shuntParams);

        if (handleClose != null) {
            handleClose();
        }

        SessionParamsEnum.CurrentStep.setContextValue(sessionParams, 0, database, shuntID);
        navigate("/stepper#step1");
    }

    const createNewCopy = async () => {
        clearContexts(false);
        ShuntParamsEnum.ShuntDesignation.setContextValue(shuntParams, "");
        //Set connection-options + stand-option back to default.
        AdjustmentsEnum.ConnectionDesign.setContextValue(adjustParams, CONN_DESIGN_U);
        AdjustmentsEnum.ConnectionValue.setContextValue(adjustParams, CONN_VALUE_NOT_SPECIFIED);
        AdjustmentsEnum.StandValue.setContextValue(adjustParams, STAND_FLOOR);
        AdjustmentsEnum.MixingVessels.setContextValue(adjustParams, NO_MIXINGVESSEL)
        PumpParamEnum.PumpRequestType.setContextValue(pumpParams, PUMP_GENERAL_REQ);
        PumpParamEnum.GeneralPumpRequest.setContextValue(pumpParams, PUMP_PREMA_CHOICE);
        PumpParamEnum.SpecificPumpRequest.setContextValue(pumpParams, null);

        const sessionID = SessionParamsEnum.SessionID.getContextValue(sessionParams);
        const newPos = await getCurrentHighPosition(database, sessionParams);
        const shuntID = await SessionParamsEnum.addSessionShunt(sessionParams, database, true, shuntParams, newPos + 1);

        //console.log("New Pos: ", newPos);
        ShuntParamsEnum.ShuntPosition.setContextValue(shuntParams, newPos + 1);
        ShuntParamsEnum.Addons.setContextValue(shuntParams, {});
        await saveProductLineData(database, sessionParams, productLine, shuntID);
        await saveWizardData(database, sessionParams, wizardParams, shuntID);

        if (handleClose != null) {
            handleClose();
        }

        SessionParamsEnum.CurrentStep.setContextValue(sessionParams, 1, database, shuntID);
        navigate("/stepper#step2");
        SessionParamsEnum.OpenOverview.setContextValue(sessionParams, false);
        SessionParamsEnum.UpdateValues.setContextValue(sessionParams, !SessionParamsEnum.UpdateValues.getContextValue(sessionParams));
    }

    return (
        <>
            {isDisabled() !== true ? (
                <Button variant="contained" size="small" onClick={handleModalOpen} fullWidth={fullWidth} style={style}>
                    <AddCircleOutlineIcon style={{ marginRight: '5px' }} />Lägg till shuntgrupp
                </Button>
            ) : ("")}

            <BootstrapDialog
                onClose={handleModalClose}
                aria-labelledby="customized-dialog-title"
                open={openModal}
            >
                <BootstrapDialogTitle onClose={handleModalClose}>
                    Lägg till shuntgrupp
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center' }}>
                        Du kan välja mellan att basera den nya shuntgruppen på den aktuella shuntgruppen (behålla alla värden), eller göra ny shuntgrupp av annan shunttyp.
                    </Typography>
                    <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center', paddingTop: "10px" }}>
                        <b>Vad vill du göra?</b>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    {isCurrentShuntStarted(productLine) === true ? (
                        <>
                            {ShuntParamsEnum.IsCustomShunt.getContextValue(shuntParams) !== true ? (
                                <Button variant='outlined' onClick={createNewCopy}>
                                    Baserat på föregående
                                </Button>
                            ) : (
                                <Tooltip title={'Kan inte basera ny på en manuell shuntgrupp'} placement="top" arrow>
                                    <span style={{ marginRight: '8px' }}>
                                        <Button variant='outlined' disabled>
                                            Baserat på föregående
                                        </Button>
                                    </span>
                                </Tooltip>
                            )}
                        </>
                    ) : (
                        <>

                        </>
                    )}

                    <Button variant='outlined' onClick={createNew}>
                        Ny shunttyp
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    )
}