import { DialogContent, Typography, Button, Divider } from "@mui/material"
import { PRODUCTPRINCIP_SR2 } from "../../../../constants/productlineConstants";
import { useCalcParams, useDriftParams, useErrorContext, useNoticeContext, useProductLineParams, useSessionParams, useShuntParams } from "../../../../contexts"
import { DriftParamEnum, ErrorEnum, FSDataEnum, NoticeEnum, ProductLineEnum, SessionParamsEnum, ShuntParamsEnum } from "../../../../enums";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../utils/bootstrapDialog"
import greenLine1 from '../../../../assets/images/logos/Premablock_Green_I_Logo.svg'
import greenLine2 from '../../../../assets/images/logos/Premablock_Green_II_Logo.svg'
import { useDatabase, useFirestore, useFirestoreCollection, useFirestoreCollectionData, useFirestoreDoc } from "reactfire";
import { useFSData } from "../../../../contexts/fsDataContext";
import { calculateShuntParams } from "../../../../functions/calculateShuntParams";
import { collection, doc } from "firebase/firestore";
import { useState, useEffect } from "react";
import { SHUNT_DIMS_PATH, CONTROL_VALVES_PATH, CONTROL_VALVES_DIMS_FLEX_DEFAULT_PATH, CONTROL_VALVES_DIMS_SMART_DEFAULT_PATH } from "../../../../constants/firebasePaths";
import { CV_MODEL_KVS, CV_MODEL_VVF53, CV_MODEL_VXF53, CV_SIEMENS, CV_SOE, DEFAULT_GREEN_II_CV, DEFAULT_GREEN_II_CV_SR2 } from "../../../../constants/shuntOptions";
import { useGetSpecialFlexDim } from "../../../../firebase/functions/getSpecialFlexDim";
import { useWizardParams } from "../../../../contexts/wizardParams";
import { useGetEnergyMeasurementList } from "../../../../hooks/useGetEnergyMeasurementList";
import { useGetSystemFluidOptions } from "../../../../hooks/useGetSystemFluidOptions";

export const GreenLvlModal = ({ handleClose, openModal, continueStep }) => {
    const productLineParams = useProductLineParams();
    const shuntParams = useShuntParams();
    const errorlist = new ErrorEnum();
    const noticelist = new NoticeEnum();

    const database = useDatabase();
    const sessionParams = useSessionParams();
    const driftParams = useDriftParams();
    const calcParams = useCalcParams();
    const errorProvider = useErrorContext();
    const noticeProvider = useNoticeContext();
    const fsDataParams = useFSData();
    const firestore = useFirestore();
    const wizardParams = useWizardParams();

    const systemFluidOptions = useGetSystemFluidOptions();

    const [runUpdate, setRunUpdate] = useState(false);


    const conTypeDoc = collection(firestore, SHUNT_DIMS_PATH);
    const firestoreRes = useFirestoreCollectionData(conTypeDoc);

    const [dimensionData, setDimensionData] = useState([]);
    const [flexDims, setFlexDims] = useState([]);
    const [smartDims, setSmartDims] = useState([]);
    const [customKvs, setCustomKvs] = useState([]);
    const [prevMessages, setPrevMessages] = useState([]);
    const specialDim = useGetSpecialFlexDim();

    const plPrincip = ProductLineEnum.ProductPrincip.getContextValue(productLineParams);
    const { heatEnergyMeasurementList, coolingEnergyMeasurementList } = useGetEnergyMeasurementList();

    useEffect(() => {
        if (firestoreRes.data != null) {
            //console.log("Data: ", firestoreRes.data)
            setDimensionData(firestoreRes.data);
            FSDataEnum.Dims.setContextValue(fsDataParams, firestoreRes.data);
        }

    }, [firestoreRes])


    const checkCalcMode = () => {
        if (DriftParamEnum.CalcMode.getContextValue(driftParams) === "0" || DriftParamEnum.CalcMode.getContextValue(driftParams) === 0) {
            return false;
        }
        else {
            return true;
        }
    }

    let fsUrl = "";

    if (ShuntParamsEnum.ControlValve.getContextValue(shuntParams) != null && ShuntParamsEnum.ControlValve.getContextValue(shuntParams) !== "" &&
        ShuntParamsEnum.FlexModel.getContextValue(shuntParams) != null && ShuntParamsEnum.FlexModel.getContextValue(shuntParams) !== "" && !checkCalcMode()) {
        fsUrl = CONTROL_VALVES_PATH + ShuntParamsEnum.ControlValve.getContextValue(shuntParams) + '/Models/' + ShuntParamsEnum.FlexModel.getContextValue(shuntParams) + "/Dims";
    }
    else {
        fsUrl = CONTROL_VALVES_DIMS_FLEX_DEFAULT_PATH;
    }

    const dimsDoc = collection(firestore, fsUrl);
    const firestoreDimRes = useFirestoreCollection(dimsDoc);

    useEffect(() => {
        if (firestoreDimRes.data != null) {
            const localModels = [];

            firestoreDimRes.data.forEach(model => {
                localModels.push({ id: model.id, data: model.data() })
            });

            setFlexDims(localModels);
            FSDataEnum.FlexDims.setContextValue(fsDataParams, localModels);
        }

    }, [firestoreDimRes])

    const smartDimUrl = CONTROL_VALVES_DIMS_SMART_DEFAULT_PATH
    const smartDimsDoc = collection(firestore, smartDimUrl);
    const firestoreSmartDimRes = useFirestoreCollection(smartDimsDoc);

    useEffect(() => {
        if (firestoreSmartDimRes.data != null) {
            const localModels = [];

            firestoreSmartDimRes.data.forEach(model => {
                localModels.push({ id: model.id, data: model.data() })
            });

            setSmartDims(localModels);
            FSDataEnum.SmartDims.setContextValue(fsDataParams, localModels);
        }

    }, [firestoreSmartDimRes])

    let fsCVUrl = "";

    if (plPrincip === PRODUCTPRINCIP_SR2) {
        fsCVUrl = CONTROL_VALVES_PATH + CV_SIEMENS + '/Models/' + CV_MODEL_VVF53;
    }
    else {
        fsCVUrl = CONTROL_VALVES_PATH + CV_SIEMENS + '/Models/' + CV_MODEL_VXF53;
    }

    const CVDimsDoc = doc(firestore, fsCVUrl);
    const firestoreCVDimRes = useFirestoreDoc(CVDimsDoc);

    useEffect(() => {
        if (firestoreCVDimRes.data != null) {
            //console.log("CV Data", firestoreCVDimRes.data?.data()?.kvs)

            setCustomKvs(firestoreCVDimRes.data?.data()?.kvs);
        }
    }, [firestoreCVDimRes])




    const handleModalApprove = () => {
        ShuntParamsEnum.ControlValve.setContextValue(shuntParams, CV_SIEMENS);
        if (plPrincip === PRODUCTPRINCIP_SR2) {
            ShuntParamsEnum.CV_Model.setContextValue(shuntParams, CV_MODEL_VVF53);
        }
        else {
            ShuntParamsEnum.CV_Model.setContextValue(shuntParams, CV_MODEL_VXF53);
        }

        console.log("Notices: ", noticelist.getNoticeList(noticeProvider))

        if (noticelist.getNoticeList(noticeProvider) != null) {
            setPrevMessages(Object.keys(noticelist.getNoticeList(noticeProvider)));
        }

        errorlist.clearAllErrors(errorProvider);
        noticelist.clearAllNotices(noticeProvider);

        setRunUpdate(true);
        calculateShuntParams(
            productLineParams,
            driftParams,
            calcParams,
            shuntParams,
            errorProvider,
            noticeProvider,
            dimensionData,
            database,
            sessionParams,
            wizardParams,
            systemFluidOptions,
            flexDims,
            smartDims,
            customKvs,
            specialDim,
            heatEnergyMeasurementList,
            coolingEnergyMeasurementList
        );
    }

    const handleModalDecline = () => {
        ShuntParamsEnum.ControlValve.setContextValue(shuntParams, CV_SOE);
        ShuntParamsEnum.CV_Model.setContextValue(shuntParams, CV_MODEL_KVS);
        ProductLineEnum.ProductGreenLevel.setContextValue(productLineParams, 1, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
        continueStep();
        handleClose();
    }

    useEffect(() => {
        if (runUpdate === true) {
            setRunUpdate(false);
            //console.log("Updated!")
            if (errorlist.hasErrors(errorProvider) || noticelist.hasNotices(noticeProvider) || ProductLineEnum.ProductGreenLevel.getContextValue(productLineParams) === 1) {
                if (noticelist.getNoticeList(noticeProvider) != null) {
                    let newNotices = Object.keys(noticelist.getNoticeList(noticeProvider));
                    console.log("Notices: ", newNotices, prevMessages)
                    let sameNotices = true;
                    if (newNotices.length <= prevMessages.length) {
                        for (let index = 0; index < newNotices.length; index++) {
                            const element = newNotices[index];

                            if (prevMessages.includes(element) === false) {
                                sameNotices = false;
                            }
                        }
                    }

                    if (sameNotices === true) {
                        continueStep();
                    }

                }

                handleClose();
            }
            else {
                continueStep();
                handleClose();
            }
        }

    }, [SessionParamsEnum.UpdateValues.getContextValue(sessionParams)])



    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={openModal}
        >
            <BootstrapDialogTitle component="h3" sx={{ paddingLeft: '20px', paddingTop: '0px', paddingBottom: '0px' }} onClose={handleClose}>
                <h3 className="header-title">Bekräfta produktlinje</h3>
            </BootstrapDialogTitle>
            <DialogContent>
                <Typography gutterBottom style={{ display: 'flex', justifyContent: 'left', marginBottom: '10px' }}>
                    För att uppfylla miljödeklarationen för &nbsp;<strong>Premablock® green II</strong>&nbsp; krävs följande styrventil:
                </Typography>
                <Typography gutterBottom style={{ display: 'flex', justifyContent: 'left', marginLeft: '10px', marginBottom: '10px' }}>
                    {plPrincip === PRODUCTPRINCIP_SR2 ? (<strong><i>{DEFAULT_GREEN_II_CV_SR2}</i></strong>) : (<strong><i>{DEFAULT_GREEN_II_CV}</i></strong>)}
                </Typography>
                <Typography gutterBottom style={{ display: 'flex', justifyContent: 'left', paddingBottom: '10px' }}>
                    Accepteras val av styrventil?
                </Typography>
                <Divider style={{ marginBottom: '10px' }} />

                <Button variant="outlined" size="small" onClick={handleModalApprove} sx={{ marginBottom: '10px', width: '550px', marginLeft: '7px' }}>
                    <strong>Ja</strong>, välj styrventilen och gå vidare som <img src={greenLine2} alt='green Line II' style={{ height: '20px', marginTop: '5px', alignSelf: 'right', marginLeft: 'auto' }} />
                </Button>
                <Button variant="outlined" size="small" onClick={handleModalDecline} sx={{ width: '550px', marginLeft: '7px' }}>
                    <strong>Nej</strong>, välj inte styrventil och gå vidare som <img src={greenLine1} alt='green Line I' style={{ height: '20px', marginTop: '5px', alignSelf: 'right', marginLeft: 'auto', marginRight: '9px' }} />
                </Button>
            </DialogContent>
        </BootstrapDialog>
    )
}