import { Paper, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material"
import { MATERIAL_PAINTED, MATERIAL_STAINLESS } from "../../../../../constants/adjustmentsOptions"
import { PRODUCTLINE_GREEN } from "../../../../../constants/productlineConstants"
import { useProductLineParams } from "../../../../../contexts"
import { AdjustmentsEnum, ProductLineEnum } from "../../../../../enums"

export const AdjustCardMaterial = ({ materialValue, setAdjustParams, isDisabled }) => {
    const productLineParams = useProductLineParams();

    const productLine = ProductLineEnum.ProductLine;
    const productGreenLvl = ProductLineEnum.ProductGreenLevel;

    const displayStainless = () => {
        if ((productLine.getContextValue(productLineParams) === PRODUCTLINE_GREEN && productGreenLvl.getContextValue(productLineParams) !== 2) || productLine.getContextValue(productLineParams) !== PRODUCTLINE_GREEN) {
            return true;
        }
        else {
            return false;
        }
    }

    return (
        <div>
            <Paper elevation={0} className="adjust-card">
                <FormControl>
                    <FormLabel id="material-radio-buttons-group">Material</FormLabel>
                    <RadioGroup
                        aria-labelledby="material-radio-buttons-group"
                        name="material"
                        value={materialValue}
                        onChange={(event) => { setAdjustParams(event.target.value, AdjustmentsEnum.MaterialValue) }}
                    >

                        <FormControlLabel value={MATERIAL_PAINTED} control={<Radio />} label="Rörkoppel i rostskyddsmålat, TIG svetsat tryckkärlsstål P235GH" disabled={isDisabled()} />
                        {displayStainless() &&
                            <FormControlLabel value={MATERIAL_STAINLESS} control={<Radio />} label="Rörkoppel i TIG svetsat rostfritt stål EN 1.4436" disabled={isDisabled()} />
                        }

                    </RadioGroup>
                </FormControl>
            </Paper>
        </div>
    )
}