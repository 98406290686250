import { TextField } from '@mui/material';
import { useState } from 'react';


export const SessionInputField = ({ type, context, label, location, }) => {
    const setValue = event => {
        type.setContextValue(context, event.target.value)
        setFieldValue(event.target.value);
    };

    const [fieldValue, setFieldValue] = useState('')

    return (
        <TextField
            className="step_1_input"
            size="small"
            placeholder={label}
            value={fieldValue || ''}
            margin="normal"
            fullWidth
            onChange={setValue}
        />
    );
};