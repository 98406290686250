import { Box } from '@mui/system';
import { logEvent } from 'firebase/analytics';
import { useEffect } from 'react';
import { useAnalytics } from 'reactfire';
import Stepper from '../components/modules/stepper/stepperStart'
import { ClearContext } from '../components/utils/clearContext';
import { StartLanding } from '../components/modules/landing/landing';

export const Start = ({isApi = false, apiMethod = null}) => {
    const analytics = useAnalytics();

    useEffect(() => {
        logEvent(analytics, 'page_view', { page_location: window.location.href, page_title: "StartPage" });
    }, [window.location.href]);

    return (
        <Box>
            {isApi === true && <ClearContext />}
            <Stepper />
            <StartLanding />
        </Box>
    );
};