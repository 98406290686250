import { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import { useAdjustParams, useCalcParams, useProductLineParams, useSessionParams, useShuntParams } from "../../../contexts";
import { AdjustmentsEnum } from "../../../enums/adjustmentsEnum";
import { CalcParamsEnum } from "../../../enums/calcParamsEnum";
import { ProductLineEnum } from "../../../enums/productLineEnum";
import { ShuntParamsEnum } from "../../../enums/shuntParamsEnum";
import { useDevbarParams } from "../../../contexts/devBar";
import { ShuntMeasurementCard } from "../shuntSpecification/shuntMeasurementCard";
import { StorageRefSingleImage } from "../imageDisplay/StorageRefImage";
import { StorageRefDoubleImage } from "../imageDisplay/StorageRefDoubleImage";
import { ModelDisplay } from "../magicloud/modelDisplay";
import { getProdID } from "../../../functions/MagicadFunctions/getProdCode";
import { useGetShuntData } from "../../../hooks/useGetShuntData";
import { SessionParamsEnum } from "../../../enums";
import { useGetMagicloudIDs } from "../../../hooks/useGetMagicloudIDs";

import { CONN_VALUE_NOT_SPECIFIED } from "../../../constants/adjustmentsOptions";
import { PRODUCTLINE_FLEX, PRODUCTLINE_SMART } from "../../../constants/productlineConstants";

export const SidebarImage = () => {
    const adjustParams = useAdjustParams();
    const productLineParams = useProductLineParams();
    const calcParams = useCalcParams();
    const shuntParams = useShuntParams();
    const devParams = useDevbarParams();
    const sessionParams = useSessionParams();
    const { magicloudIDs, loading } = useGetMagicloudIDs();
    const { shuntData } = useGetShuntData(SessionParamsEnum.CurrentShunt.getContextValue(sessionParams))

    const setFolderNameInit = () => {
        if (ProductLineEnum.ProductLine.getContextValue(productLineParams) === PRODUCTLINE_FLEX) {
            return String(ProductLineEnum.ProductFamily.getContextValue(productLineParams) + ProductLineEnum.ProductNWaysValue.getContextValue(productLineParams)).toLowerCase() + "-" + ProductLineEnum.ProductLine.getContextValue(productLineParams);
        }
        else if (ProductLineEnum.ProductLine.getContextValue(productLineParams) === PRODUCTLINE_SMART) {
            return String(ProductLineEnum.ProductFamily.getContextValue(productLineParams) + ProductLineEnum.ProductNWaysValue.getContextValue(productLineParams)).toLowerCase() + "-" + ProductLineEnum.ProductLine.getContextValue(productLineParams);
        }
        else {
            return String(ProductLineEnum.ProductFamily.getContextValue(productLineParams) + ProductLineEnum.ProductNWaysValue.getContextValue(productLineParams)).toLowerCase();
        }
    }

    const [folderName] = useState(setFolderNameInit());
    const [imageUrl, setImageUrl] = useState("");
    const [productID, setProductID] = useState("");
    const shuntNomenklaturString = ShuntParamsEnum.ShuntNomenklaturString;

    const [localMeasurement, setLocalMeasurement] = useState(CalcParamsEnum.MeasurementData.getContextValue(calcParams) || {});

    useEffect(() => {
        //console.log("Measurement Start", CalcParamsEnum.MeasurementData.getContextValue(calcParams));
        if (CalcParamsEnum.MeasurementData.getContextValue(calcParams) != null && CalcParamsEnum.MeasurementData.getContextValue(calcParams) !== "") {
            setLocalMeasurement(CalcParamsEnum.MeasurementData.getContextValue(calcParams));
        }

    }, [CalcParamsEnum.MeasurementData.getContextValue(calcParams)]);

    useEffect(() => {

        if (AdjustmentsEnum.ConnectionValue.getContextValue(adjustParams) !== CONN_VALUE_NOT_SPECIFIED) {
            //console.log("Update: String: ", folderName, shuntNomenklaturString.getContextValue(shuntParams), AdjustmentsEnum.ConnectionValue.getContextValue(adjustParams))
            const imgUrl = "images/" + folderName + "/kopplingsbilder/" + shuntNomenklaturString.getContextValue(shuntParams) + ".jpg";
            //console.log("Update: String: ", imgUrl);
            setImageUrl(imgUrl);
        }
        else {
            setImageUrl('');
        }
    }, [shuntNomenklaturString.getContextValue(shuntParams)])

    useEffect(() => {
        //console.log("Start: ", magicloudIDs, loading)
        if (loading === false) {
            //console.log("List: ", magicloudIDs)
            let prodID = getProdID(true, calcParams, shuntParams, adjustParams, productLineParams, shuntData);
            let magicadUID = magicloudIDs[prodID];
            //console.log("Res: ", prodID, magicadUID);
            setProductID(magicadUID);
        }
    }, [shuntNomenklaturString.getContextValue(shuntParams), AdjustmentsEnum.StandValue.getContextValue(adjustParams), ShuntParamsEnum.IsMini.getContextValue(shuntParams), magicloudIDs, loading])

    return (
        <div>
            <Paper elevation={0} style={{ width: '250px', justifyContent: 'center', padding: '20px', height: 'fit-content', marginTop: '27px', borderRadius: 0, boxShadow: "2px 2px 10px #0055a030", }}>
                <div style={{ marginBottom: '30px' }}>
                    {shuntNomenklaturString.getContextValue(shuntParams) !== "" ? (
                        <>
                            {devParams.state.val.devMode === true && <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '15px' }}>({shuntNomenklaturString.getContextValue(shuntParams)})</div>}

                            {imageUrl != null && imageUrl !== '' && AdjustmentsEnum.ConnectionValue.getContextValue(adjustParams) !== CONN_VALUE_NOT_SPECIFIED ? (
                                <>
                                    <StorageRefSingleImage
                                        imageRef={imageUrl}
                                        altText={shuntNomenklaturString.getContextValue(shuntParams)}
                                        clearImgCache={false}
                                        shuntID={SessionParamsEnum.CurrentShunt.getContextValue(sessionParams)}
                                    />
                                </>
                            ) : (
                                <>
                                    <StorageRefDoubleImage />
                                </>
                            )}

                        </>

                    ) : (
                        <></>
                    )}
                </div>

                <ShuntMeasurementCard shuntID={SessionParamsEnum.CurrentShunt.getContextValue(sessionParams)} measurement={localMeasurement} />

                <div style={{maxWidth: '240px'}}>
                    <ModelDisplay productId={productID} />
                </div>
                
            </Paper>
        </div>
    )
}