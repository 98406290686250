import { useRef, useState } from "react"
import { Paper, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Popover } from "@mui/material"
import { useProductLineParams } from "../../../../../contexts"
import { AdjustmentsEnum, ProductLineEnum } from "../../../../../enums"

import { CONN_DESIGN_U, CONN_DESIGN_H, CONN_DESIGN_L } from "../../../../../constants/adjustmentsOptions"
import { PRODUCTFAMILY_SR, PRODUCTFAMILY_PR, PRODUCTFAMILY_VA } from "../../../../../constants/productlineConstants"

import sru from '../../../../../assets/images/adjustments/sru.png'
import srh from '../../../../../assets/images/adjustments/srh.png'
import srl from '../../../../../assets/images/adjustments/srl.png'
import pru from '../../../../../assets/images/adjustments/pru.png'
import prh from '../../../../../assets/images/adjustments/prh.png'
import prl from '../../../../../assets/images/adjustments/prl.png'
import våu from '../../../../../assets/images/adjustments/våu.png'
import vål from '../../../../../assets/images/adjustments/vål.png'
import våh from '../../../../../assets/images/adjustments/våh.png'

export const AdjustCardConnDesign = ({connectionDesign, setAdjustParams, isDisabled }) => {
    const productLineParams = useProductLineParams();
    const containerRef = useRef();
    
    const [anchorEl, setAnchorEl] = useState(null);
    const [hoverSrl, setHoverSrl] = useState(null);
    const [hoverSrh, setHoverSrh] = useState(null);

    const open = Boolean(anchorEl);
    const openSlr = Boolean(hoverSrl);
    const openSrh = Boolean(hoverSrh);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };


    const handleSlrOpen = (event) => {
        setHoverSrl(event.currentTarget);
    };

    const handleSlrClose = () => {
        setHoverSrl(null);
    };


    const handleSrhOpen = (event) => {
        setHoverSrh(event.currentTarget);
    };

    const handleSrhClose = () => {
        setHoverSrh(null);
    };
    
    const getLabel = (variant) => {
        if (ProductLineEnum.ProductFamily.getContextValue(productLineParams) != null) {
            return String(ProductLineEnum.ProductFamily.getContextValue(productLineParams) + variant).toLocaleUpperCase();
        }
        else {
            return String(variant).toLocaleUpperCase();
        }
    }

    return (
        <Paper elevation={0} className="adjust-card">
            <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">Anslutningsutförande</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="connection-design"
                    value={connectionDesign}
                    onChange={(event) => { setAdjustParams(event.target.value, AdjustmentsEnum.ConnectionDesign) }}
                >
                    <>
                    <FormControlLabel value={CONN_DESIGN_U} control={<Radio />} label={getLabel(CONN_DESIGN_U)}
                        aria-owns={open ? 'mouse-over-sru' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                        disabled={isDisabled()}
                    />
                        <Popover
                            id="mouse-over-sru"
                            sx={{
                                pointerEvents: 'none',
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            container={containerRef.current}
                            onClose={handlePopoverClose}

                        >
                            {(ProductLineEnum.ProductFamily.getContextValue(productLineParams) === PRODUCTFAMILY_SR) && <img src={sru} alt="SRU" />}

                            {(ProductLineEnum.ProductFamily.getContextValue(productLineParams) === PRODUCTFAMILY_PR) && <img src={pru} alt="PRU" />}

                            {(ProductLineEnum.ProductFamily.getContextValue(productLineParams) === PRODUCTFAMILY_VA) && <img src={våu} alt="VÅU" />}
                        </Popover></>

                    <FormControlLabel value={CONN_DESIGN_H} control={<Radio />} label={getLabel(CONN_DESIGN_H)} aria-owns={open ? 'mouse-over-srh' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handleSrhOpen}
                        onMouseLeave={handleSrhClose}
                        disabled={isDisabled()}
                    />
                    <Popover
                        id="mouse-over-srh"
                        sx={{
                            pointerEvents: 'none',
                        }}
                        open={openSrh}
                        anchorEl={hoverSrh}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={handleSrhClose}
                        container={containerRef.current}
                        disableRestoreFocus
                    >
                        {(ProductLineEnum.ProductFamily.getContextValue(productLineParams) === PRODUCTFAMILY_SR) && <img src={srh} alt="SRH" />}

                        {(ProductLineEnum.ProductFamily.getContextValue(productLineParams) === PRODUCTFAMILY_PR) && <img src={prh} alt="PRH" />}

                        {(ProductLineEnum.ProductFamily.getContextValue(productLineParams) === PRODUCTFAMILY_VA) && <img src={våh} alt="VÅH" />}
                    </Popover>
                    <FormControlLabel value={CONN_DESIGN_L} control={<Radio />} label={getLabel(CONN_DESIGN_L)} aria-owns={open ? 'mouse-over-slr' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handleSlrOpen}
                        onMouseLeave={handleSlrClose}
                        disabled={isDisabled()}
                    />
                    <Popover
                        id="mouse-over-srl"
                        sx={{
                            pointerEvents: 'none',
                        }}
                        open={openSlr}
                        anchorEl={hoverSrl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={handleSlrClose}
                        container={containerRef.current}
                        disableRestoreFocus

                    >
                        {(ProductLineEnum.ProductFamily.getContextValue(productLineParams) === PRODUCTFAMILY_SR) && <img src={srl} alt="SRL" />}

                        {(ProductLineEnum.ProductFamily.getContextValue(productLineParams) === PRODUCTFAMILY_PR) && <img src={prl} alt="PRL" />}

                        {(ProductLineEnum.ProductFamily.getContextValue(productLineParams) === PRODUCTFAMILY_VA) && <img src={vål} alt="VÅL" />}
                    </Popover>
                </RadioGroup>
            </FormControl>
        </Paper>
    )
}