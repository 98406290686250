export function isNativeEventTyping(inputType) {
    switch (inputType) {
        case "deleteContentBackward":
        case "insertFromPaste":
        case "insertText":
        case "formatBold":
        case "deleteByCut":
            return true;
        default:
            return false;
    }
}

export const countDecimalPlaces = (value) => {
    if (!isNaN(value)) {
        const decimalPlaces = String(value)
            .match(/\d*\.\d*/)?.[0]
            ?.split(".")[1];
        if (decimalPlaces) {
            return decimalPlaces.length;
        }
    }

    return 0;
};

export function sanitizeIncrementedValue(value, numDecimals) {
    if (value != null && isNaN(value) === false){
        return parseFloat(value.toFixed(numDecimals));
    }
    else{
        return 0;
    }
}