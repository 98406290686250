import { MenuItem, Select, Radio, Stack } from '@mui/material';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import { collection } from 'firebase/firestore';
import { useProductLineParams, useShuntParams, useDriftParams, useSessionParams, useDevbarParams, useCalcParams } from '../../../../contexts';
import { useState, useEffect } from 'react';
import { CONTROL_VALVES_PATH, CONTROL_VALVES_MODELS_DEFAULT_PATH } from '../../../../constants/firebasePaths';
import { DriftParamEnum, SessionParamsEnum, ShuntParamsEnum, CalcParamsEnum, ProductLineEnum } from '../../../../enums';
import { PRODUCTLINE_GREEN } from '../../../../constants/productlineConstants';

export const ModelSelectField = () => {
    const productParams = useProductLineParams();
    const shuntParams = useShuntParams();
    const driftParams = useDriftParams();
    const calcParams = useCalcParams();
    const firestore = useFirestore();
    const sessionParams = useSessionParams();
    const devParams = useDevbarParams();

    const [models, setModels] = useState([]);
    const [selected, setSelected] = useState({});
    const [update, setUpdate] = useState(false);
    const [modelSelect, setModelSelect] = useState('');

    let fsUrl = "";

    const checkCalcMode = () => {
        if ((DriftParamEnum.CalcMode.getContextValue(driftParams) === "0" || DriftParamEnum.CalcMode.getContextValue(driftParams) === 0) && ProductLineEnum.ProductLine.getContextValue(productParams) !== PRODUCTLINE_GREEN) {
            return false;
        }
        else {
            return true;
        }
    }

    const isDisabled = () => {
        if ((SessionParamsEnum.Editable.getContextValue(sessionParams) === false && devParams.state.val.devMode !== true) ||
            (DriftParamEnum.CalcMode.getContextValue(driftParams) !== "0" && DriftParamEnum.CalcMode.getContextValue(driftParams) !== 0)) {
            return true;
        }
        else {
            return false;
        }
    }

    if (ShuntParamsEnum.ControlValve.getContextValue(shuntParams) != null && ShuntParamsEnum.ControlValve.getContextValue(shuntParams) !== "" && !checkCalcMode()) {
        fsUrl = CONTROL_VALVES_PATH + ShuntParamsEnum.ControlValve.getContextValue(shuntParams) + '/Models';
    }
    else {
        fsUrl = CONTROL_VALVES_MODELS_DEFAULT_PATH;
    }

    const optionsDoc = collection(firestore, fsUrl);
    const firestoreRes = useFirestoreCollection(optionsDoc);


    const setValue = (key, value, ivMod, miniIncompatible, interval) => {
        //console.log("Here Val")
        selected[key] = value;
        ShuntParamsEnum.CV_Model.setContextValue(shuntParams, key);
        ShuntParamsEnum.CV_Kvs.setContextValue(shuntParams, value);
        DriftParamEnum.IVModification.setContextValue(driftParams, ivMod);

        if (miniIncompatible != null) {
            ShuntParamsEnum.IsMiniIncompatible.setContextValue(shuntParams, miniIncompatible);
        }
        else {
            ShuntParamsEnum.IsMiniIncompatible.setContextValue(shuntParams, false);
        }

        if (interval != null) {
            ShuntParamsEnum.CV_IsInterval.setContextValue(shuntParams, interval);
        }
        else {
            ShuntParamsEnum.IsMiniIncompatible.setContextValue(shuntParams, false);
        }

        CalcParamsEnum.clearData(calcParams);
        setModelSelect(key);
        setUpdate(!update);
    };

    useEffect(() => {
        if (firestoreRes.data != null) {
            const localModels = [];
            const localSelected = {};

            firestoreRes.data.forEach(model => {
                if (model.data() != null && model.data().usedIn != null) {
                    if (model.data().usedIn === "all" || model.data().usedIn.includes(ProductLineEnum.ProductPrincip.getContextValue(productParams))) {
                        let localObject = {};
                        if (model.data().interval === true) {
                            if (model.data().ivMod != null) {
                                localObject = { name: model.data().name, kvs: model.data().kvsInterval, interval: true, ivMod: model.data().ivMod };
                                //localModels.push({ name: model.data().name, kvs: model.data().kvsInterval, interval: true, ivMod: model.data().ivMod });
                            }
                            else {
                                localObject = { name: model.data().name, kvs: model.data().kvsInterval, interval: true, ivMod: 0 };
                                //localModels.push({ name: model.data().name, kvs: model.data().kvsInterval, interval: true, ivMod: 0 });
                            }

                            if (ShuntParamsEnum.CV_Model.getContextValue(shuntParams) === model.data().name) {
                                if (ShuntParamsEnum.CV_Kvs.getContextValue(shuntParams) != null || ShuntParamsEnum.CV_Kvs.getContextValue(shuntParams) !== "") {
                                    localSelected[model.data().name] = ShuntParamsEnum.CV_Kvs.getContextValue(shuntParams);
                                }
                                else {
                                    localSelected[model.data().name] = '';
                                }
                            }
                            else {
                                localSelected[model.data().name] = '';
                            }

                            //localSelected[model.data().name] = '';
                        }
                        else {
                            if (model.data().ivMod != null) {
                                localObject = { name: model.data().name, kvs: model.data().kvs, interval: false, ivMod: model.data().ivMod };
                                //localModels.push({ name: model.data().name, kvs: model.data().kvs, interval: false, ivMod: model.data().ivMod });
                            }
                            else {
                                localObject = { name: model.data().name, kvs: model.data().kvs, interval: false, ivMod: 0 };
                                //localModels.push({ name: model.data().name, kvs: model.data().kvs, interval: false, ivMod: 0 });
                            }


                            if (ShuntParamsEnum.CV_Model.getContextValue(shuntParams) === model.data().name) {
                                if (ShuntParamsEnum.CV_Kvs.getContextValue(shuntParams) != null || ShuntParamsEnum.CV_Kvs.getContextValue(shuntParams) !== "") {
                                    localSelected[model.data().name] = ShuntParamsEnum.CV_Kvs.getContextValue(shuntParams);
                                }
                                else {
                                    localSelected[model.data().name] = '';
                                }
                            }
                            else {
                                localSelected[model.data().name] = '';
                            }
                        }

                        if (model.data().miniIncompatible != null) {
                            localObject['miniIncompatible'] = model.data().miniIncompatible;
                        }

                        localModels.push(localObject);
                    }
                }

            });
            setModels(localModels);
            setSelected(localSelected);
        }

    }, [firestoreRes])


    useEffect(() => {
        if (ShuntParamsEnum.CV_Model.getContextValue(shuntParams) != null && ShuntParamsEnum.CV_Model.getContextValue(shuntParams) !== "") {
            if (ShuntParamsEnum.CV_Kvs.getContextValue(shuntParams) != null && ShuntParamsEnum.CV_Kvs.getContextValue(shuntParams) !== "") {
                //console.log("Set val", ShuntParamsEnum.CV_Model.getContextValue(shuntParams), ShuntParamsEnum.CV_Kvs.getContextValue(shuntParams))
                selected[ShuntParamsEnum.CV_Model.getContextValue(shuntParams)] = ShuntParamsEnum.CV_Kvs.getContextValue(shuntParams);
                setModelSelect(ShuntParamsEnum.CV_Model.getContextValue(shuntParams));
                setUpdate(!update);
            }
            else {
                setModelSelect('');
            }
        }
    }, [DriftParamEnum.CalcMode.getContextValue(driftParams)])

    const handleRadioChange = (event) => {
        const model = models.find((obj) => { return obj.name === event.target.value });

        if (model != null) {
            if (selected[event.target.value] != null && selected[event.target.value] !== "") {
                ShuntParamsEnum.CV_Kvs.setContextValue(shuntParams, selected[event.target.value]);
            }
            else {
                if (ShuntParamsEnum.CV_Kvs.getContextValue(shuntParams) != null && ShuntParamsEnum.CV_Kvs.getContextValue(shuntParams) !== "" && isNaN(ShuntParamsEnum.CV_Kvs.getContextValue(shuntParams)) === false) {
                    let value = getPreSelectedValue(ShuntParamsEnum.CV_Kvs.getContextValue(shuntParams), model.kvs, model.interval || false);
                    ShuntParamsEnum.CV_Kvs.setContextValue(shuntParams, value);
                    selected[model.name] = value;
                }
                else if (model.kvs != null) {
                    ShuntParamsEnum.CV_Kvs.setContextValue(shuntParams, model.kvs[0]);
                    selected[model.name] = model.kvs[0];
                }
            }

            DriftParamEnum.IVModification.setContextValue(driftParams, model.ivMod);

            if (model.miniIncompatible != null) {
                ShuntParamsEnum.IsMiniIncompatible.setContextValue(shuntParams, model.miniIncompatible);
            }
            else {
                ShuntParamsEnum.IsMiniIncompatible.setContextValue(shuntParams, false);
            }

            if (model.interval != null) {
                ShuntParamsEnum.CV_IsInterval.setContextValue(shuntParams, model.interval);
            }
            else {
                ShuntParamsEnum.IsMiniIncompatible.setContextValue(shuntParams, false);
            }
        }

        CalcParamsEnum.clearData(calcParams);
        ShuntParamsEnum.CV_Model.setContextValue(shuntParams, event.target.value);
        setModelSelect(event.target.value);
    }

    /**
     * 
     * @param {Number} value 
     * @param {Array} kvsList 
     * @param {Boolean} isInterval 
     * @returns 
     */
    const getPreSelectedValue = (value, kvsList, isInterval) => {
        if (isInterval === true) {
            let orderedKeys = Object.keys(kvsList).sort((a, b) => a.localeCompare(b));

            for (let index = 0; index < orderedKeys.length; index++) {
                const key = orderedKeys[index];

                const maxValue = kvsList[key].split(' - ')[1];
                if (Number(value) <= Number(maxValue)) {
                    return kvsList[key];
                }
            }
            let lastKey = orderedKeys[orderedKeys.length - 1]
            return kvsList[lastKey];
        }
        else if (kvsList != null) {
            for (let index = 0; index < kvsList.length; index++) {
                const element = kvsList[index];

                if (Number(value) <= element) {
                    return element;
                }
            }

            return kvsList[kvsList.length - 1];
        }
    }

    return (
        <>
            {models.map((model) => {
                return (
                    <div key={model.name}>
                        {!checkCalcMode() ? (
                            <Stack key={model.name} direction={"row"}>
                                <div style={{ paddingLeft: "15px", marginTop: "8px", }}>
                                    {model.name}:
                                </div>
                                {model.kvs != null ? (
                                    <>
                                        {model.interval === true ? (
                                            <Select
                                                style={{ marginLeft: "auto", width: "42%", marginTop: "5px", fontSize: "11px", height: '22px' }}
                                                size={"small"}
                                                value={selected[model.name]}
                                                margin="dense"
                                                onChange={(event) => { setValue(model.name, event.target.value, model.ivMod, model.miniIncompatible, model.interval) }}
                                                disabled={isDisabled()}
                                            >
                                                {Object.keys(model.kvs).sort((a, b) => a.localeCompare(b)).map((key, index) => {
                                                    return (<MenuItem key={index} value={model.kvs[key]}>{model.kvs[key]}</MenuItem>)
                                                })}

                                            </Select>
                                        ) : (
                                            <Select
                                                style={{ marginLeft: "auto", width: "42%", marginTop: "5px", fontSize: "11px", height: '22px' }}
                                                size={"small"}
                                                value={selected[model.name]}
                                                margin="dense"
                                                onChange={(event) => { setValue(model.name, event.target.value, model.ivMod, model.miniIncompatible, model.interval) }}
                                                disabled={isDisabled()}
                                            >
                                                {model.kvs.map((option, index) => {
                                                    return (<MenuItem key={index} value={option}>{option}</MenuItem>)
                                                })}

                                            </Select>
                                        )}
                                    </>

                                ) : (
                                    <Select
                                        style={{ marginLeft: "auto", width: "42%", marginTop: "5px", fontSize: "11px", height: '22px' }}
                                        size={"small"}
                                        value={''}
                                        margin="dense"
                                        onChange={(event) => { setValue(model.name, event.target.value, model.ivMod, model.miniIncompatible, model.interval) }}
                                        disabled={isDisabled()}
                                    >

                                    </Select>
                                )}
                                <Radio
                                    checked={modelSelect === model.name}
                                    onChange={handleRadioChange}
                                    disabled={isDisabled()}
                                    value={model.name}
                                    sx={{ fontSize: '10px', padding: '3px', borderRadius: '50%', marginTop: '5px' }}
                                />
                            </Stack>
                        ) : (
                            <></>
                        )}
                    </div>
                );
            })}

            {/*
        <Stack direction={"row"}>
            <div style={{ marginTop: "24px" }}>
                {databaseObj.name}:
            </div>
            <Select
                style={{ marginLeft: "auto", width: "50%", marginTop: "15px" }}
                size={"small"}
                value={''}
                margin="dense"
                onChange={(event) => { setValue(event.target.value) }}
                disabled={checkCalcMode()}
            >
                {databaseObj.kvs.map((option, index) => {
                    return (<MenuItem key={index} value={option}>{option}</MenuItem>)
                })}
            </Select>
            </Stack>*/}
        </>
    );
};