import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from 'react';

import { get, ref, serverTimestamp, set } from 'firebase/database';
import { useDatabase, useDatabaseObjectData } from 'reactfire';
import { useSessionParams } from '../../contexts/SessionParams';
import { PROJECT_PATH, PROJECT_PROJECT_ID_PATH, SESSION_PATH } from "../../constants/firebasePaths";
import { LoadNewShuntComponent } from "./loadNewShuntComponent";
import { SessionParamsEnum } from "../../enums";
import { useModifySpecContext, useShuntParams } from "../../contexts";
import { FACILITY_KEY, PROJNR_KEY, SESSION_SHUNTS_KEY, CURRENT_SHUNT, LINKED_PROJECT_KEY, SESSION_MODIFIED_SHUNTS_KEY } from "../../constants/keys/sessionParamsKeys";
import { saveOwnerAndVersion } from "../../firebase/functions/saveOwnerAndVersion";
import { useContextAuth } from "../../authContext/AuthHook";


export const FindSessionOnFB = ({ sessionID, edit, viaInfoBox = false, setIsOpen = null }) => {
    const { user } = useContextAuth();
    const sessionParams = useSessionParams();
    const shuntParams = useShuntParams();
    const modifySpecParams = useModifySpecContext();
    const database = useDatabase();
    const fieldRef = ref(database, SESSION_PATH + sessionID);

    const storedRes = useDatabaseObjectData(fieldRef);

    const [fieldValue, setFieldValue] = useState('')
    const [loadOnce, setLoadOnce] = useState(false)

    const [openProject, setOpenProject] = useState(false);
    const [shuntID, setShuntID] = useState('');

    useEffect(() => {
        if (storedRes.data != null) {
            //console.log("Respone:", storedRes.data);
            setFieldValue(storedRes.data);
        }
    }, [storedRes.data])

    useEffect(() => {
        const readData = async () => {
            if (loadOnce === false) {

                if (edit === true) {
                    //console.log("Edit copy")

                    if (fieldValue["NO_ID_FIELD"] != null) {
                        setLoadOnce(true);
                        const newSession = await SessionParamsEnum.createSessionID(sessionParams, sessionID, database);
                        SessionParamsEnum.SessionID.setContextValue(sessionParams, newSession);

                        if (fieldValue[FACILITY_KEY] != null) {
                            SessionParamsEnum.Facility.setContextValue(sessionParams, fieldValue[FACILITY_KEY], database)
                        }
                        if (fieldValue[PROJNR_KEY] != null) {
                            SessionParamsEnum.ProjNr.setContextValue(sessionParams, fieldValue[PROJNR_KEY], database)
                        }
                        if (fieldValue[LINKED_PROJECT_KEY] != null) {
                            const projectSnap = await get(ref(database, PROJECT_PATH + fieldValue[LINKED_PROJECT_KEY] + PROJECT_PROJECT_ID_PATH));

                            if (projectSnap.exists()) {
                                SessionParamsEnum.LinkedProject.setContextValue(sessionParams, projectSnap.val())
                            }
                        }
                        
                        if (fieldValue[SESSION_MODIFIED_SHUNTS_KEY] != null && viaInfoBox === false){
                            let level = 0;
                            Object.keys(fieldValue[SESSION_MODIFIED_SHUNTS_KEY]).forEach((key) => {
                                if (Number(fieldValue[SESSION_MODIFIED_SHUNTS_KEY][key]) > level){
                                    level = Number(fieldValue[SESSION_MODIFIED_SHUNTS_KEY][key]);
                                }
                            });

                            if (level === 2){
                                SessionParamsEnum.OpeningSessionModifiedError.setContextValue(sessionParams, true);
                            }
                            else if (level === 1){
                                SessionParamsEnum.OpeningSessionModified.setContextValue(sessionParams, true);
                            }
                        }

                        if (fieldValue[SESSION_SHUNTS_KEY] == null) {
                            const shuntIDString = await SessionParamsEnum.addSessionShunt(sessionParams, database, true, shuntParams);
                            setShuntID(shuntIDString);
                        }
                        else {
                            const shunts = [];
                            let first = true;
                            let currentIsSet = false;

                            for (let index = 0; index < Object.keys(fieldValue[SESSION_SHUNTS_KEY]).length; index++) {
                                const shuntID = Object.keys(fieldValue[SESSION_SHUNTS_KEY])[index];
                                //console.log("START: ", shuntID, fieldValue[CURRENT_SHUNT])
                                if (SessionParamsEnum.CurrentShunt.getContextValue(sessionParams) != null) {
                                    if (SessionParamsEnum.CurrentShunt.getContextValue(sessionParams) === shuntID) {
                                        const shuntIDString = await SessionParamsEnum.copySessionShunt(sessionParams, database, shuntID, true, shuntParams, newSession)
                                        setShuntID(shuntIDString);
                                        currentIsSet = true;
                                        shunts.push(shuntIDString);
                                    }
                                    else {
                                        shunts.push(await SessionParamsEnum.copySessionShunt(sessionParams, database, shuntID, false, shuntParams, newSession));
                                    }
                                }
                                else {
                                    if (first === true) {
                                        //console.log("copy Start: (3)", shuntID)
                                        const shuntIDString = await SessionParamsEnum.copySessionShunt(sessionParams, database, shuntID, true, shuntParams, newSession)
                                        setShuntID(shuntIDString);
                                        currentIsSet = true;
                                        shunts.push(shuntIDString);
                                        first = false;
                                    }
                                    else {
                                        //console.log("copy Start: (4)", shuntID)
                                        shunts.push(await SessionParamsEnum.copySessionShunt(sessionParams, database, shuntID, false, shuntParams, newSession));
                                    }
                                }
                            }

                            if (currentIsSet === false) {
                                setShuntID(shunts[0]);
                            }

                            SessionParamsEnum.SessionShunts.setContextValue(sessionParams, shunts);
                        }
                        if (fieldValue['CustomData'] != null) {
                            // @ts-ignore
                            modifySpecParams.dispatch({ type: 'setSessionDataFromSnapshot', payload: fieldValue['CustomData'] })
                        }
                        console.log("Open here 1")
                        const sessionRef = ref(database, SESSION_PATH + newSession + "/lastOpened")
                        set(sessionRef, serverTimestamp());
            
                        await saveOwnerAndVersion(newSession, database, user)

                        setOpenProject(true);

                    }
                }
                else {
                    if (fieldValue["NO_ID_FIELD"] != null) {
                        setLoadOnce(true);
                        SessionParamsEnum.SessionID.setContextValue(sessionParams, sessionID);
                        SessionParamsEnum.Editable.setContextValue(sessionParams, false);
                        if (fieldValue[FACILITY_KEY] != null) {
                            SessionParamsEnum.Facility.setContextValue(sessionParams, fieldValue[FACILITY_KEY])
                        }
                        else {
                            SessionParamsEnum.Facility.setContextValue(sessionParams, '')
                        }
                        if (fieldValue[PROJNR_KEY] != null) {
                            SessionParamsEnum.ProjNr.setContextValue(sessionParams, fieldValue[PROJNR_KEY])
                        }
                        else {
                            SessionParamsEnum.ProjNr.setContextValue(sessionParams, '')
                        }

                        if (fieldValue[LINKED_PROJECT_KEY] != null) {
                            const projectSnap = await get(ref(database, PROJECT_PATH + fieldValue[LINKED_PROJECT_KEY] + PROJECT_PROJECT_ID_PATH));

                            if (projectSnap.exists()) {
                                //console.log("Value?", projectSnap.val())
                                SessionParamsEnum.LinkedProject.setContextValue(sessionParams, projectSnap.val())
                            }
                        }

                        if (fieldValue[SESSION_MODIFIED_SHUNTS_KEY] != null && viaInfoBox === false){

                            let level = 0;
                            Object.keys(fieldValue[SESSION_MODIFIED_SHUNTS_KEY]).forEach((key) => {
                                if (Number(fieldValue[SESSION_MODIFIED_SHUNTS_KEY][key]) > level){
                                    level = Number(fieldValue[SESSION_MODIFIED_SHUNTS_KEY][key]);
                                }
                            });

                            if (level === 2){
                                SessionParamsEnum.OpeningSessionModifiedError.setContextValue(sessionParams, true);
                            }
                            else if (level === 1){
                                SessionParamsEnum.OpeningSessionModified.setContextValue(sessionParams, true);
                            }
                        }

                        if (fieldValue[SESSION_SHUNTS_KEY] == null) {
                            const shuntIDString = await SessionParamsEnum.addSessionShunt(sessionParams, database, true, shuntParams);
                            setShuntID(shuntIDString);
                        }
                        else {
                            const shunts = [];
                            Object.keys(fieldValue[SESSION_SHUNTS_KEY]).forEach((shuntID) => {
                                shunts.push(shuntID);
                            });
                            SessionParamsEnum.SessionShunts.setContextValue(sessionParams, shunts);

                            if (fieldValue[CURRENT_SHUNT] != null) {
                                SessionParamsEnum.CurrentShunt.setContextValue(sessionParams, fieldValue[CURRENT_SHUNT]);
                                setShuntID(fieldValue[CURRENT_SHUNT]);
                            }
                            else {
                                //console.log("Key: ", Object.keys(fieldValue[SESSION_SHUNTS_KEY]).at(0))
                                SessionParamsEnum.CurrentShunt.setContextValue(sessionParams, Object.keys(fieldValue[SESSION_SHUNTS_KEY]).at(0), database);
                                setShuntID(Object.keys(fieldValue[SESSION_SHUNTS_KEY]).at(0))
                            }
                        }

                        //console.log("FieldValues: ", fieldValue['CustomData'])
                        if (fieldValue['CustomData'] != null) {
                            // @ts-ignore
                            modifySpecParams.dispatch({ type: 'setSessionDataFromSnapshot', payload: fieldValue['CustomData'] })
                        }

                        //const sessionRef = ref(database, SESSION_PATH + sessionID + "/lastOpened")
                        //set(sessionRef, serverTimestamp());

                        //console.log("Open here 2")
                        setOpenProject(true);
                    }
                }
            }
        }

        readData();

    }, [fieldValue])


    if (storedRes.status === 'loading') {
        return (
            <Box sx={{
                position: 'absolute',
                top: '300px',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                borderRadius: '5px',
                boxShadow: 24,
                p: 4,
            }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
            </Box>)

    }

    return (
        <Box sx={{
            position: 'absolute',
            top: '300px',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: '5px',
            boxShadow: 24,
            p: 4,
        }}>
            {fieldValue != null && fieldValue !== "" ? (
                <div>
                    {openProject && <LoadNewShuntComponent shuntID={shuntID} setOpen={setOpenProject} newSession={true} />}
                </div>
            ) : (
                <div>Tyvärr hittades ingen session för det angivna IDt</div>
            )}

        </Box>
    );
};