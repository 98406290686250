
export class ErrorEnum {
    constructor() {
        this.name = "errorList";
        this.addErrorMethod = "addError";
        this.removeErrorMethod = "clearError";
        this.clearMethod = "clearErrorList";
    }

    hasErrors(contextParam) {
        if (contextParam.state.val != null) {
            return Object.keys(contextParam.state.val).length > 0;
        }
        else {
            return false;
        }

    }

    getErrorList(contextParam) {
        return contextParam.state.val;
    }

    addError(contextParam, key, error, value = null, value2 = null) {
        if (value != null) {
            if (value2 != null) {
                contextParam.dispatch({ type: this.addErrorMethod, payload: { key: key, msg: error, value: value, value2: value2 } });
            }
            else {
                contextParam.dispatch({ type: this.addErrorMethod, payload: { key: key, msg: error, value: value } });
            }

        }
        else {
            contextParam.dispatch({ type: this.addErrorMethod, payload: { key: key, msg: error } });
        }

    }

    clearError(contextParam, key) {
        contextParam.dispatch({ type: this.removeErrorMethod, payload: key })
    }

    clearAllErrors(contextParam) {
        contextParam.dispatch({ type: this.clearMethod })
    }
}