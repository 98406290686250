import { useEffect, useState } from 'react'
import { Radio, Paper, Stack, Typography, RadioGroup, FormControlLabel, FormControl, Link, Checkbox, Grid, InputLabel, Divider, TextField, Box } from "@mui/material"
import InfoLogo from '../../../assets/images/info.svg'
import { useProductLineParams } from "../../../contexts/ProductLineParams";
import { PumpTextfield } from "./components/pumpTextfield";
import { useDriftParams, usePumpParams, useCalcParams, useShuntParams, useDevbarParams, useSessionParams } from "../../../contexts";
import { useDatabase } from 'reactfire';
import { savePumpData } from '../../../firebase/functions';
import { PUMP_SPECIFIC_REQUEST, PUMP_SYSTEMFLUID } from '../../../constants/keys/pumpKeys';
import { PUMP_GENERAL_REQ, PUMP_GREEN_II_DEFAULT, PUMP_GRUNDFOS, PUMP_KSB, PUMP_PREMA_CHOICE, PUMP_SPECIFIC_REQ, PUMP_WILO, PUMP_XYLEM } from '../../../constants/pumpOptions';
import { CalcParamsEnum, DriftParamEnum, SessionParamsEnum, ProductLineEnum, ShuntParamsEnum } from '../../../enums';
import { PumpParamEnum } from '../../../enums/pumpParamsEnum';
import { PRODUCTLINE_GREEN } from '../../../constants/productlineConstants';
import { PumpTextfieldGreen } from './components/pumpTextFieldGreen';
import { SystemFluidPickerPumpTab } from '../systemFluidPicker/systemFluidPickerPumpTab';
import { useContextAuth } from '../../../authContext/AuthHook';
import { WizardParamEnum } from '../../../enums/wizardParamsEnum';
import { useWizardParams } from '../../../contexts/wizardParams';
import { SYSTEM_FLUID } from '../../../constants/wizardConstants';
import { resetMaxStepFunc } from '../../../functions/HelperFunctions/resetMaxStep';

export const PumpSelection = () => {
    const { user } = useContextAuth();
    const database = useDatabase();
    const productLine = useProductLineParams();
    const pumpParams = usePumpParams();
    const shuntParams = useShuntParams();
    const calcParams = useCalcParams();
    const driftParams = useDriftParams();
    const sessionParams = useSessionParams();
    const wizardParams = useWizardParams();
    const devParams = useDevbarParams();

    const totalPressure = CalcParamsEnum.TotalPressure.getContextValue(calcParams);
    const secFlow = DriftParamEnum.SecFlow.getContextValue(driftParams);

    /**
      * 
      * @param {PumpParamEnum} type 
      * @returns 
      */
    const getPumpParams = (type) => {
        // eslint-disable-next-line eqeqeq
        if (pumpParams.state != undefined && pumpParams.state.val != undefined) {
            // eslint-disable-next-line eqeqeq
            if (type.getContextValue(pumpParams) != undefined) {
                return type.getContextValue(pumpParams);
            }
            else {
                return '';
            }
        }
        else {
            return '';
        }
    }

    const setPumpRequestTypeDefault = () => {
        if (ProductLineEnum.ProductLine.getContextValue(productLine) === PRODUCTLINE_GREEN && ProductLineEnum.ProductGreenLevel.getContextValue(productLine) === 2) {
            return PUMP_SPECIFIC_REQ;
        }
        else {
            return getPumpParams(PumpParamEnum.PumpRequestType);
        }
    }



    const [pressure, setPressure] = useState(getPumpParams(PumpParamEnum.Pressure));
    const [alarm, setAlarm] = useState(getPumpParams(PumpParamEnum.Alarm));
    const [card, setCard] = useState(getPumpParams(PumpParamEnum.Card));
    const [steering, setSteering] = useState(getPumpParams(PumpParamEnum.Steering));
    const [externalStop, setExternalStop] = useState(getPumpParams(PumpParamEnum.ExternalStop));
    const [generalPumpRequest, setGeneralPumpRequest] = useState(getPumpParams(PumpParamEnum.GeneralPumpRequest));
    const [pumpRequestType, setPumpRequestType] = useState(setPumpRequestTypeDefault());

    const [systemFluidManual, setSystemFluidManual] = useState(getPumpParams(PumpParamEnum.SystemFluid))
    // eslint-disable-next-line no-unused-vars
    const [specificPumpRequest, setSpecificPumpRequest] = useState(getPumpParams(PumpParamEnum.SpecificPumpRequest));

    const setManualChoice = (value, type) => {
        resetMaxStepFunc(sessionParams, database);
        //SessionParamsEnum.MaxStep.setContextValue(sessionParams, SessionParamsEnum.CurrentStep.getContextValue(sessionParams));
        if (value === PUMP_SPECIFIC_REQ) {
            setPumpParams(value, type)
            setPumpParams(null, PumpParamEnum.GeneralPumpRequest)

        } else if (value === PUMP_GENERAL_REQ) {
            setPumpParams(PUMP_PREMA_CHOICE, PumpParamEnum.GeneralPumpRequest);
            setPumpParams(value, type)
            setPumpParams(null, PumpParamEnum.SpecificPumpRequest)
        }
        else {
            return '';
        }
    }
    /**
  * 
  * @param {*} value 
  * @param {PumpParamEnum} type 
  */
    const setPumpParams = (value, type) => {
        resetMaxStepFunc(sessionParams, database);
        /*
        if (SessionParamsEnum.MaxStep.getContextValue(sessionParams) !== SessionParamsEnum.CurrentStep.getContextValue(sessionParams)) {
            SessionParamsEnum.MaxStep.setContextValue(sessionParams, SessionParamsEnum.CurrentStep.getContextValue(sessionParams));
        }*/

        type.setContextValue(pumpParams, value);
        switch (type) {
            case PumpParamEnum.PumpRequestType:
                setPumpRequestType(value);
                break;
            case PumpParamEnum.GeneralPumpRequest:
                setGeneralPumpRequest(value);
                break;
            case PumpParamEnum.Pressure:
                setPressure(value);
                break;
            case PumpParamEnum.SpecificPumpRequest:
                setSpecificPumpRequest(value);
                break;
            case PumpParamEnum.Alarm:
                setAlarm(value);
                break;
            case PumpParamEnum.Card:
                setCard(value);
                break;
            case PumpParamEnum.Steering:
                setSteering(value);
                break;
            case PumpParamEnum.ExternalStop:
                setExternalStop(value);
                break;
            default:
                //console.log("No matching param")
                break;
        }
    }

    useEffect(() => {
        if (ProductLineEnum.ProductLine.getContextValue(productLine) === PRODUCTLINE_GREEN && ProductLineEnum.ProductGreenLevel.getContextValue(productLine) === 2 && (getPumpParams(PumpParamEnum.SpecificPumpRequest) == null || getPumpParams(PumpParamEnum.SpecificPumpRequest) === "")) {
            PumpParamEnum.PumpRequestType.setContextValue(pumpParams, PUMP_SPECIFIC_REQ);
            PumpParamEnum.GeneralPumpRequest.setContextValue(pumpParams, null);
            setGeneralPumpRequest(null)
            PumpParamEnum.SpecificPumpRequest.setContextValue(pumpParams, PUMP_GREEN_II_DEFAULT);
        }
    }, [])

    useEffect(() => {
        if (pumpParams.state.val != null) {
            savePumpData(database, sessionParams, pumpParams)
        }
    }, [pumpParams.state.val])

    const getMinTempValue = () => {
        let primTempIn = Number(DriftParamEnum.PrimTempIn.getContextValue(driftParams));
        let primTempOut = Number(DriftParamEnum.PrimTempOut.getContextValue(driftParams));
        let secTempIn = Number(DriftParamEnum.SecTempIn.getContextValue(driftParams));
        let secTempOut = Number(DriftParamEnum.SecTempOut.getContextValue(driftParams));

        if (isNaN(primTempIn) || DriftParamEnum.PrimTempIn.getContextValue(driftParams) == null) {
            primTempIn = Number.MAX_SAFE_INTEGER;
        }
        if (isNaN(primTempOut) || DriftParamEnum.PrimTempOut.getContextValue(driftParams) == null) {
            primTempOut = Number.MAX_SAFE_INTEGER;
        }
        if (isNaN(secTempIn) || DriftParamEnum.SecTempIn.getContextValue(driftParams) == null) {
            secTempIn = Number.MAX_SAFE_INTEGER;
        }
        if (isNaN(secTempOut) || DriftParamEnum.SecTempOut.getContextValue(driftParams) == null) {
            secTempOut = Number.MAX_SAFE_INTEGER;
        }

        return Math.min(primTempIn, primTempOut, secTempIn, secTempOut);
    }

    const getMaxTempValue = () => {
        let primTempIn = Number(DriftParamEnum.PrimTempIn.getContextValue(driftParams));
        let primTempOut = Number(DriftParamEnum.PrimTempOut.getContextValue(driftParams));
        let secTempIn = Number(DriftParamEnum.SecTempIn.getContextValue(driftParams));
        let secTempOut = Number(DriftParamEnum.SecTempOut.getContextValue(driftParams));

        if (isNaN(primTempIn) || DriftParamEnum.PrimTempIn.getContextValue(driftParams) == null) {
            primTempIn = Number.MIN_SAFE_INTEGER;
        }
        if (isNaN(primTempOut) || DriftParamEnum.PrimTempOut.getContextValue(driftParams) == null) {
            primTempOut = Number.MIN_SAFE_INTEGER;
        }
        if (isNaN(secTempIn) || DriftParamEnum.SecTempIn.getContextValue(driftParams) == null) {
            secTempIn = Number.MIN_SAFE_INTEGER;
        }
        if (isNaN(secTempOut) || DriftParamEnum.SecTempOut.getContextValue(driftParams) == null) {
            secTempOut = Number.MIN_SAFE_INTEGER;
        }

        return Math.max(primTempIn, primTempOut, secTempIn, secTempOut);
    }

    // function capitalizeFirstLetter(string) {
    //     return string.charAt(0).toUpperCase() + string.slice(1);
    // }

    const isDisabled = () => {
        if (SessionParamsEnum.Editable.getContextValue(sessionParams) === false && devParams.state.val.devMode !== true) {
            return true;
        }
        else {
            return false;
        }
    }

    const isDisabledGreen = () => {
        if (ProductLineEnum.ProductLine.getContextValue(productLine) === PRODUCTLINE_GREEN && ProductLineEnum.ProductGreenLevel.getContextValue(productLine) === 2) {
            return true;
        }
        else {
            return false;
        }
    }

    const handleSetManualSystemFluid = (event) => {
        setSystemFluidManual(event.target.value);
        PumpParamEnum.SystemFluid.setContextValue(pumpParams, event.target.value);
    }


    const prodLineTest = () => {
        if (ProductLineEnum.ProductLine.getContextValue(productLine) != null) {
            if (ProductLineEnum.ProductLine.getContextValue(productLine) === PRODUCTLINE_GREEN) {
                if (ProductLineEnum.ProductGreenLevel.getContextValue(productLine) != null) {
                    if (ProductLineEnum.ProductGreenLevel.getContextValue(productLine) === 2) {
                        return ProductLineEnum.ProductLine.getContextValue(productLine) + " II";
                    }
                    else {
                        return ProductLineEnum.ProductLine.getContextValue(productLine) + " I";
                    }
                }
            }

            return ProductLineEnum.ProductLine.getContextValue(productLine);
        }

        return " - ";
    }

    return (
        <>

            <Stack direction={'row'} spacing={5}>
                <div>
                    <div>
                        <Typography variant='h3' fontWeight='bold'>
                            Pumpval
                        </Typography>
                        <Paper elevation={0} style={{ width: '500px', justifyContent: 'center', padding: '20px 20px', marginTop: '10px', height: 'fit-content', borderRadius: 0, boxShadow: "2px 2px 10px #0055a030", }}>
                            <div className="info-pump-selection">
                                <Stack direction={'row'}>
                                    <img src={InfoLogo} alt='Info logo' />
                                    <Typography style={{ paddingLeft: '10px', paddingTop: '10px', paddingBottom: '10px' }}>
                                        Ange valfri pump som ger {secFlow} l/s vid {totalPressure} kPa. {(ProductLineEnum.ProductLine.getContextValue(productLine) === "flex") ? ("Beakta typ av systemvätska och eventuella behov av framtida flödesökningar vid val av pump. Justera vid behov även shuntgruppens dimension sekundärt.") : ("Beakta typ av systemvätska.")}
                                    </Typography>
                                </Stack>
                            </div>

                            {ProductLineEnum.ProductLine.getContextValue(productLine) === PRODUCTLINE_GREEN && ProductLineEnum.ProductGreenLevel.getContextValue(productLine) === 2 ? (
                                <>
                                    <div style={{ marginTop: '10px', marginBottom: '15px' }}>
                                        <Radio
                                            checked={pumpRequestType === 'customerSpecified'}
                                            onChange={(event) => { setManualChoice(event.target.value, PumpParamEnum.PumpRequestType) }}
                                            size="small"
                                            value={PUMP_SPECIFIC_REQ}
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'customerSpecified' }}
                                            disabled={isDisabled()}
                                        />

                                        <span>{PUMP_GREEN_II_DEFAULT}</span>

                                        <div style={{ marginLeft: '27px', }} onClick={(event) => { setManualChoice(PUMP_SPECIFIC_REQ, PumpParamEnum.PumpRequestType) }}>
                                            <PumpTextfieldGreen isDisabled={isDisabled() || pumpRequestType === 'generalRequest'} />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div style={{ marginTop: '10px', marginBottom: '15px' }}>
                                        <Radio
                                            checked={pumpRequestType === 'customerSpecified'}
                                            onChange={(event) => { setManualChoice(event.target.value, PumpParamEnum.PumpRequestType) }}
                                            size="small"
                                            value={PUMP_SPECIFIC_REQ}
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'customerSpecified' }}
                                            disabled={isDisabled()}
                                        />
                                        <span>Mitt val till specifikationen</span>

                                        <div style={{ marginLeft: '27px', }} onClick={(event) => { setManualChoice(PUMP_SPECIFIC_REQ, PumpParamEnum.PumpRequestType) }}>

                                            <PumpTextfield id={PUMP_SPECIFIC_REQUEST} labelText={"Skriv in pumpvalet här"} isDisabled={isDisabled() || pumpRequestType === 'generalRequest'} />
                                        </div>
                                    </div>

                                    <div>
                                        <Radio
                                            checked={pumpRequestType === 'generalRequest'}
                                            size="small"
                                            onChange={(event) => { setManualChoice(event.target.value, PumpParamEnum.PumpRequestType) }}
                                            value={PUMP_GENERAL_REQ}
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'generalRequest' }}
                                            disabled={isDisabled() || isDisabledGreen()}
                                        />

                                        <span>Leverantören får dimensionera</span>
                                    </div>
                                    <div style={{ marginLeft: '27px', }}>
                                        <FormControl>

                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="general-pump-request"
                                                value={generalPumpRequest}

                                                onChange={(event) => { setPumpParams(event.target.value, PumpParamEnum.GeneralPumpRequest) }}
                                            >

                                                <FormControlLabel value={PUMP_PREMA_CHOICE} control={<Radio />} label="Valfri" disabled={pumpRequestType === 'customerSpecified' || isDisabled() || isDisabledGreen()} />
                                                <FormControlLabel value={PUMP_GRUNDFOS} control={<Radio />} label="Grundfos" disabled={pumpRequestType === 'customerSpecified' || isDisabled() || isDisabledGreen()} />
                                                <FormControlLabel value={PUMP_KSB} control={<Radio />} label="KSB" disabled={pumpRequestType === 'customerSpecified' || isDisabled() || isDisabledGreen()} />
                                                <FormControlLabel value={PUMP_WILO} control={<Radio />} label="Wilo" disabled={pumpRequestType === 'customerSpecified' || isDisabled() || isDisabledGreen()} />
                                                <FormControlLabel value={PUMP_XYLEM} control={<Radio />} label="Xylem" disabled={pumpRequestType === 'customerSpecified' || isDisabled() || isDisabledGreen()} />


                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </>
                            )}

                            <Divider sx={{ marginTop: '10px', color: 'gray' }} textAlign='left'>Typ av systemvätska</Divider>
                            <div style={{ marginBottom: '15px', marginTop: '10px' }}>
                                <Stack spacing={1}>
                                    <SystemFluidPickerPumpTab />

                                    {user?.isAnonymous === false && WizardParamEnum.SystemFluidMain.getContextValue(wizardParams) === SYSTEM_FLUID.other.value ? (
                                        <Box sx={{ paddingLeft: '8px', paddingTop: '2px', width: '400px' }}>
                                            <TextField
                                                fullWidth
                                                value={systemFluidManual}
                                                onChange={handleSetManualSystemFluid}
                                                disabled={isDisabled()}
                                                label="Systemvätska (fritext)"
                                            />
                                        </Box>

                                    ) : ("")}
                                </Stack>

                                {/*<PumpTextfield id={PUMP_SYSTEMFLUID} labelText={"Typ av systemvätska"} isDisabled={isDisabled()} /> */}
                            </div>
                            <Divider />
                            <Grid container spacing={0}>
                                <Grid item xs={4}>
                                    <FormControlLabel control={<Checkbox checked={pressure} disabled={isDisabled()}
                                        onChange={(event) => { setPumpParams(event.target.checked, PumpParamEnum.Pressure) }} />} label="Tryckstyrd" />

                                    <br></br>
                                    <FormControlLabel control={<Checkbox checked={alarm} disabled={isDisabled()}
                                        onChange={(event) => { setPumpParams(event.target.checked, PumpParamEnum.Alarm) }} />} label="Larmutgång" />
                                    <br></br>
                                    <FormControlLabel control={<Checkbox checked={steering} disabled={isDisabled()}
                                        onChange={(event) => { setPumpParams(event.target.checked, PumpParamEnum.Steering) }} />} label="0-10 V styrning" />
                                </Grid>
                                <Grid item xs={7}>
                                    <FormControlLabel control={<Checkbox checked={externalStop} disabled={isDisabled()}
                                        onChange={(event) => { setPumpParams(event.target.checked, PumpParamEnum.ExternalStop) }} />} label="Extern start/stopp" />
                                    <br></br>
                                    <FormControlLabel control={<Checkbox checked={card} disabled={isDisabled()}
                                        onChange={(event) => { setPumpParams(event.target.checked, PumpParamEnum.Card) }} />} label="Kommunikationskort pump (Modbus)" />
                                </Grid>
                            </Grid>
                        </Paper>
                    </div>

                </div>
                <Stack direction={'column'} spacing={2}>
                    <div>
                        <Typography variant='h3' fontWeight='bold'>
                            Summering
                        </Typography>
                        <Paper elevation={0} style={{ width: '320px', justifyContent: 'center', padding: '20px', marginTop: '10px', height: 'auto', borderRadius: 0, boxShadow: "2px 2px 10px #0055a030", }}>
                            <Typography component="div">
                                <span>Anläggning: <strong>{SessionParamsEnum.Facility.getContextValue(sessionParams) != null ? (SessionParamsEnum.Facility.getContextValue(sessionParams)) : ("-")}</strong></span>
                                <br></br>
                                <span>Projektnummer:  <strong>{SessionParamsEnum.ProjNr.getContextValue(sessionParams) != null ? (SessionParamsEnum.ProjNr.getContextValue(sessionParams)) : ("-")}</strong></span>
                                <br></br>
                                <span>Position: <strong>{ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) != null ? (ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams)) : ("-")}</strong></span>
                                <br></br>
                                <span>Beteckning:  <strong>{ShuntParamsEnum.ShuntDesignation.getContextValue(shuntParams) != null ? (ShuntParamsEnum.ShuntDesignation.getContextValue(shuntParams)) : ("-")}</strong></span>
                                <br></br>

                                <span>Kopplingsprincip:  <strong>{ProductLineEnum.ProductPrincip.getContextValue(productLine) != null ? (ProductLineEnum.ProductPrincip.getContextValue(productLine)) : ("-")}</strong></span>
                                <br></br>
                                <span>Produktlinje:  <strong>{prodLineTest()}</strong></span>
                                <br></br>
                                <span>Shunttyp:  <strong>{CalcParamsEnum.ShuntType.getContextValue(calcParams) != null ? (CalcParamsEnum.ShuntType.getContextValue(calcParams)) : ("-")}</strong></span>
                                <br></br>
                                <span>Lägsta temperatur i shuntgrupp:  <strong>{getMinTempValue()}°C</strong></span>
                                <br></br>
                                <span>Högst temperatur i shuntgrupp:  <strong>{getMaxTempValue()}°C</strong></span>
                                <br></br>
                            </Typography>
                        </Paper>
                    </div>

                    <div>
                        <Typography variant='h3' fontWeight='bold'>
                            Länkar
                        </Typography>
                        <Paper elevation={0} style={{ width: '320px', justifyContent: 'center', padding: '20px', marginTop: '10px', height: '110px', borderRadius: 0, boxShadow: "2px 2px 10px #0055a030", }}>
                            <Link rel="noopener noreferrer" href="http://net.grundfos.com/Appl/WebCAPS/custom?userid=GSV" underline="none" target="_blank">
                                {'Grundfos'}
                            </Link>
                            <br></br>
                            <br></br>
                            <Link rel="noopener noreferrer" href="https://www.ksb.com/sv-se/produkter/produktkatalog" underline="none" target="_blank">
                                {'KSB'}</Link>
                            <br></br>
                            <br></br>
                            <Link rel="noopener noreferrer" href="https://www.wilo-select.com/ApplRange.aspx" underline="none" target="_blank">
                                {'Wilo'}
                            </Link>


                            <br></br>
                            <br></br>
                            <Link rel="noopener noreferrer" href="http://xylect.com/bin/Xylect.dll?IS__NEXTPAGE=startup&IS__NEXTPAGE=BDYHOME&IS__AREA=SWEDEN&IS__COUNTRY=SWEDEN&IS__BROWSER=%23%231.5%231920%23862" underline="none" target="_blank">
                                {'Xylem'}
                            </Link>


                        </Paper></div>
                </Stack>
            </Stack>
        </>
    )
}
