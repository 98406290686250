import { PRODLINE_FLEX_LINK, PRODLINE_GREEN1_LINK, PRODLINE_GREEN2_LINK, PRODLINE_SMART_LINK, PRODLINE_STANDARD_LINK } from "./generalText/shuntLinks";
import { PRIM_FLOW_CONSTANT, PRIM_FLOW_VARIABLE, PRODUCTLINE_FLEX, PRODUCTLINE_GREEN_I, PRODUCTLINE_GREEN_II, PRODUCTLINE_SMART, PRODUCTLINE_STANDARD } from "./productlineConstants";

export const QUESTION_SOURCE_KEY = "source";
export const QUESTION_SOURCE_FLOW_KEY = "sourceFlow";
export const QUESTION_APPLICATION_KEY = "application";
export const QUESTION_APPLICATION_INTENT_KEY = "applicationIntent"
export const QUESTION_APPLICATION_SIZE_KEY = "applicationSize"
export const QUESTION_DIMENSIONABLE_KEY = "dimensionable";
export const QUESTION_SECONDARY_FLOW_KEY = "secondaryFlow";
export const QUESTION_SECONDARY_FLOW_SPEC_VALUE_KEY = "secondaryFlowSpecVal";
export const QUESTION_DRIVING_PRESSURE_KEY = "drivingPressure";
export const QUESTION_DRIVING_PRESSURE_IS_VARIABLE_KEY = "dpIsVariable";
export const QUESTION_DRIVING_PRESSURE_SPEC_VALUE_KEY = "dpSpecValue";
export const QUESTION_HAS_MAIN_PUMP_KEY = "hasMainPump";
export const QUESTION_CERTIFICATION_KEY = "enviromentCert";
export const QUESTION_SYSTEM_FLUID_KEY = "systemFluid";
export const QUESTION_SYSTEM_FLUID_MIX_RATIO_KEY = "systemFluidMixRatio";
export const QUESTION_SYSTEM_FLUID_MIX_RATIO_UNKNOWN_KEY = "systemFluidMixRatioUnknown";
export const QUESTION_SYSTEM_FLUID_PRIMARY_KEY = "systemFluidPrimary";
export const QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_KEY = "systemFluidPrimaryMixRatio";
export const QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_KEY = "systemFluidPrimaryMixRatioUnknown";
export const QUESTION_SYSTEM_FLUID_SECONDARY_KEY = "systemFluidSecondary";
export const QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY = "systemFluidSecondaryMixRatio";
export const QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_KEY = "systemFluidSecondaryMixRatioUnknown";
export const QUESTION_ENERGY_MEASUREMENT_KEY = "energyMeasurement";
export const QUESTION_STAINLESS_KEY = "stainless";
export const QUESTION_LIMITED_SPACE_KEY = "limitedSpace";
export const QUESTION_DYNAMIC_CONTROL_VALVE_KEY = "dynamicControlValve";

export const QUESTION_PRIORITIES = "priorities";

export const APP_SOURCE_FLOW = {
    constant: { value: 'constant', label: 'Konstant flöde' },
    variable: { value: 'variable', label: 'Variabelt flöde' },
}

export const APP_SOURCE = {
    districtHeating: { value: 'districtHeating', label: 'Fjärrvärme', nonCompatible: ['boiler', 'heatPump', 'freeCooling'], appNonCompatible: [], specifyFlow: false },
    districtCooling: { value: 'districtCooling', label: 'Fjärrkyla', nonCompatible: ['coolingMachine', 'freeCooling'], appNonCompatible: [], specifyFlow: false },
    heatPump: { value: 'heatPump', label: 'Värmepump', nonCompatible: ['boiler', 'districtHeating', 'freeCooling'], appNonCompatible: [], specifyFlow: true },
    boiler: { value: 'boiler', label: 'Värmepanna', nonCompatible: ['districtHeating', 'heatPump', 'freeCooling'], appNonCompatible: ['coolingBaffles'], specifyFlow: false },
    coolingMachine: { value: 'coolingMachine', label: 'Kylmaskin', nonCompatible: ['districtCooling', 'freeCooling'], appNonCompatible: [], specifyFlow: false },
    recoveryBattery: { value: 'recoveryBattery', label: 'Återvinning (vätskekopplade batterier)', nonCompatible: ['freeCooling'], appNonCompatible: [], specifyFlow: false },
    freeCooling: { value: 'freeCooling', label: 'Borrhål (frikyla)', nonCompatible: ['districtHeating', 'districtCooling', 'heatPump', 'boiler', 'coolingMachine', 'recoveryBattery', 'recovery'], appNonCompatible: [], specifyFlow: false },
    recovery: { value: 'recovery', label: 'Återvinning (överskott/retur annan process)', nonCompatible: ['freeCooling'], appNonCompatible: [], specifyFlow: false },
    other: { value: 'other', label: 'Övrigt', sourceNonCompatible: [], specifyFlow: true },
    unknown: { value: 'unknown', label: 'Vet ej/inte fastställt', sourceNonCompatible: [], specifyFlow: false },
}

export const APPLICATION = {
    ventilation: { value: 'ventilation', label: 'Ventilation', sourceNonCompatible: [] },
    radiatorsBafflar: { value: 'radiatorsBafflar', label: 'Radiatorer/Bafflar', sourceNonCompatible: [] },
    floorHeating: { value: 'floorHeating', label: 'Golvslingor', sourceNonCompatible: [] },
    tempRegulation: { value: 'peakHeating', label: 'Tempreglering maskin/process', sourceNonCompatible: [] },
}

export const APPLICATION_INTENT = {
    heat: { value: 'heat', label: 'Värme', sourceNonCompatible: [] },
    cooling: { value: 'cooling', label: 'Kyla', sourceNonCompatible: [] },
    heatAndCooling: { value: 'heatAndCooling', label: 'Värme+kyla', sourceNonCompatible: [] },
    heatAndPeak: { value: 'heatAndPeak', label: 'Värme+spetsvärme', sourceNonCompatible: [] },
    coolingAndEmergencyCooling: { value: 'coolingAndEmergencyCooling', label: 'Kyla+nödkyla', sourceNonCompatible: [] },
    shunting: { value: 'shunting', label: 'Förshuntning', sourceNonCompatible: [] },
    centralShuntGroup: { value: 'centralShuntGroup', label: 'Centralshuntgrupp', sourceNonCompatible: [] },
}

export const APPLICATION_SIZE = {
    small: { value: 'small', label: 'Litet' },
    large: { value: 'large', label: 'Stort' },
}

export const AVAILABLE_DRIVE_PRESSURE = {
    normal: { value: 'normal', label: 'Normalt/Högt', level: 2 },
    low: { value: 'low', label: 'Lågt', level: 1 },
    nonExistent: { value: 'nonExistent', label: 'Obefintligt', level: 0 },
    unknown: { value: 'unknown', label: 'Vet ej/inte fastställt' },
    specify: { value: 'specify', label: 'Specifikt värde' },
}

export const ENERGY_MEASUREMENT_OPTIONS = {
    controlValve: { value: 'controlValve', label: 'Via styrventil' },
    pump: { value: 'pump', label: 'Via pump' },
    dedicated: { value: 'dedicated', label: 'Dedikerad energimätare' },
    unknown: { value: 'unknown', label: 'Vet ej/inte fastställt' },
}


export const SECONDARY_FLOW_OPTIONS = [
    { label: '<0,05', level: 1 },
    { label: '0,05-0,5', level: 2 },
    { label: '0,5-5', level: 3 },
    { label: '5-15', level: 4 },
    { label: '15-30', level: 5 },
    { label: '30-50', level: 6 },
    { label: 'Specifikt värde', level: -1 },
    { label: 'Vet ej/inte fastställt', level: -2 },
]

export const ENVIROMENT_CERTIFICATIONS = {
    sundaHus: { value: 'sundaHus', label: 'SundaHus' },
    byggVaru: { value: 'byggVaru', label: 'Byggvarubedömningen' },
    EPD: { value: 'EPD', label: 'EPD (ISO 14025 och EN 15804:2012+A2:2019)' },
}

export const SYSTEM_FLUID = {
    water: { value: 'water', label: 'Vatten', paramLabel: 'Vatten' },
    glycol: { value: 'glycol', label: 'Glykol', paramLabel: 'Glykol' },
    ethanol: { value: 'ethanol', label: 'Etanol' , paramLabel: 'Etanol'},
    other: { value: 'other', label: 'Annan' , paramLabel: 'Annan'},
    different: { value: 'different', label: 'Olika primärt/sekundärt', paramLabel: 'Olika primärt/sekundärt' },
    unknown: { value: 'unknown', label: 'Vet ej/inte fastställt', paramLabel: ' - ' },
}

export const PRIORITY_OPTIONS = {
    quality: { value: 'quality', label: 'Kvalitet och funktionsoptimering' },
    price: { value: 'price', label: 'Hålla nere inköpspris' },
    delivery: { value: 'delivery', label: 'Leveranstid' },
    opCost: { value: 'opCost', label: 'Minimera driftskostnader' },
    enviroment: { value: 'enviroment', label: 'Miljöhänsyn/-krav' },
    automation: { value: 'automation', label: 'Automatisering/övervakningsbarhet' },
    reliability: { value: 'reliability', label: 'Driftsäkerhet' },
}

export const PRIM_FLOW_LABELS = {
    [PRIM_FLOW_VARIABLE]: "Variabelt",
    [PRIM_FLOW_CONSTANT]: "Konstant"
}

export const CV_MOUNTED_PRIM = "primärt";
export const CV_MOUNTED_SECONDARY = "sekundärt";

export const LOW_DP_FLEX_THREASHOLD = 15;


export const PRODUCT_LINE_DATA = {
    [PRODUCTLINE_STANDARD]: {
        id: PRODUCTLINE_STANDARD,
        link: PRODLINE_STANDARD_LINK,
        cert: {
            [ENVIROMENT_CERTIFICATIONS.sundaHus.value]: true,
            [ENVIROMENT_CERTIFICATIONS.byggVaru.value]: true,
            [ENVIROMENT_CERTIFICATIONS.EPD.value]: false,
        },
        needDP: false,
        minFlow: null,
        maxFlow: null,
        minFlowLevel: null,
        maxFlowLevel: null,
    },
    [PRODUCTLINE_FLEX]: {
        id: PRODUCTLINE_FLEX,
        link: PRODLINE_FLEX_LINK,
        cert: {
            [ENVIROMENT_CERTIFICATIONS.sundaHus.value]: true,
            [ENVIROMENT_CERTIFICATIONS.byggVaru.value]: true,
            [ENVIROMENT_CERTIFICATIONS.EPD.value]: false,
        },
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.normal,
        lowDP: 15,
        highDP: 400,
        needDP: 15,
        minFlow: 0.006,
        maxFlow: 52.8,
        minFlowLevel: null,
        maxFlowLevel: null,
    },
    [PRODUCTLINE_SMART]: {
        id: PRODUCTLINE_SMART,
        link: PRODLINE_SMART_LINK,
        cert: {
            [ENVIROMENT_CERTIFICATIONS.sundaHus.value]: false,
            [ENVIROMENT_CERTIFICATIONS.byggVaru.value]: false,
            [ENVIROMENT_CERTIFICATIONS.EPD.value]: false,
        },
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.normal,
        lowDP: 7,
        highDP: 400,
        needDP: false,
        minFlow: 0.044,
        maxFlow: 31.11,
        minFlowLevel: 3,
        maxFlowLevel: 5,
    },
    [PRODUCTLINE_GREEN_I]: {
        id: PRODUCTLINE_GREEN_I,
        link: PRODLINE_GREEN1_LINK,
        cert: {
            [ENVIROMENT_CERTIFICATIONS.sundaHus.value]: true,
            [ENVIROMENT_CERTIFICATIONS.byggVaru.value]: true,
            [ENVIROMENT_CERTIFICATIONS.EPD.value]: false,
        },
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.nonExistent,
        lowDP: 0,
        needDP: false,
        minFlow: 0,
        maxFlow: null,
        minFlowLevel: null,
        maxFlowLevel: null,
    },
    [PRODUCTLINE_GREEN_II]: {
        id: PRODUCTLINE_GREEN_II,
        link: PRODLINE_GREEN2_LINK,
        cert: {
            [ENVIROMENT_CERTIFICATIONS.sundaHus.value]: true,
            [ENVIROMENT_CERTIFICATIONS.byggVaru.value]: true,
            [ENVIROMENT_CERTIFICATIONS.EPD.value]: true,
        },
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.nonExistent,
        lowDP: 0,
        needDP: false,
        minFlow: 0,
        maxFlow: 20,
        maxFlowExchange: 13,
        minFlowLevel: null,
        maxFlowLevel: 5,
        maxFlowLevelExchange: 4,
    },
}


