import { Paper, Stack, FormGroup, FormControlLabel, Switch, TableContainer, TableHead, TableBody, Button, FormControl, RadioGroup, Radio, Table, TableRow, TableCell, styled, Divider } from "@mui/material";
import { ref } from "firebase/database";
import { useEffect, useState } from "react";
import { useDatabase, useDatabaseObjectData } from "reactfire";
import { SESSION_PATH } from "../../../constants/firebasePaths";
import { useSessionParams, useModifySpecContext } from "../../../contexts";
import { ModifySpecEnum, SessionParamsEnum } from "../../../enums";
import { saveModifiedData } from "../../../firebase/functions";
import TermsInput from "./components/termsInputField";
import { CommentDialog } from "./commentDialog";
import CommentIcon from '@mui/icons-material/Comment';
import { FirebaseIllustrationRow } from "./components/firebaseIllustrationRow";
import { FirebaseRemarksRow } from "./components/firebaseRemarksRow";

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

//const filter = createFilterOptions();
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const ModifySpec = ({ shuntList }) => {
    const sessionParams = useSessionParams();
    const database = useDatabase();
    const modifySpec = useModifySpecContext();

    /**
      * 
      * @param {ModifySpecEnum} type 
      * @returns 
      */
    const getModifySpecContext = (type) => {
        // eslint-disable-next-line eqeqeq
        if (modifySpec.state != undefined && modifySpec.state.val != undefined) {
            // eslint-disable-next-line eqeqeq
            if (type.getContextValue(modifySpec) != undefined) {
                //console.log("Type: ", type.getContextValue(modifySpec))
                return type.getContextValue(modifySpec);
            }
            else {
                if (type === ModifySpecEnum.DisplayOption) {
                    return 'perShunt'
                }
                else if (type === ModifySpecEnum.IncludeMagicloudLink) {
                    return true;
                }
                else {
                    return '';
                }

            }
        }
        else {
            if (type === ModifySpecEnum.DisplayOption) {
                return 'perShunt'
            }
            else if (type === ModifySpecEnum.IncludeMagicloudLink) {
                return true;
            }
            else {
                return '';
            }
        }
    }


    const [showID, setShowID] = useState(getModifySpecContext(ModifySpecEnum.ShowID));
    const [offertMode, setOffertMode] = useState(getModifySpecContext(ModifySpecEnum.OffertMode));
    const [checkedTerms, setCheckedTerms] = useState(getModifySpecContext(ModifySpecEnum.CheckedTerms));
    const [checkedCover, setCheckedCover] = useState(getModifySpecContext(ModifySpecEnum.CheckedCover));
    const [newPagePerPosition, setNewPagePerPosition] = useState(getModifySpecContext(ModifySpecEnum.NewPagePerPosition));
    const [includeMagicloudLink, setIncludeMagicloudLink] = useState(getModifySpecContext(ModifySpecEnum.IncludeMagicloudLink))
    // const handleChange = (event) => {
    //     setCheckedTerms(event.target.checked);
    // };

    const [displayOption, setDisplayOption] = useState(getModifySpecContext(ModifySpecEnum.DisplayOption));
    // const handleChangeShow = (event) => {
    //     setShowValue(event.target.value);
    // };

    // const handleCoverPage = (event) => {
    //     setCheckedCover(event.target.checked);
    // };
    /**
    *
    * @param {*} value 
    * @param {ModifySpecEnum} type 
    */
    const setModifySpec = (value, type) => {

        type.setContextValue(modifySpec, value);
        switch (type) {
            case ModifySpecEnum.ShowID:
                setShowID(value);
                break;
            case ModifySpecEnum.CheckedTerms:
                setCheckedTerms(value);
                break;
            case ModifySpecEnum.CheckedCover:
                if (newPagePerPosition === false || newPagePerPosition === '') {
                    ModifySpecEnum.NewPagePerPosition.setContextValue(modifySpec, value);
                    setNewPagePerPosition(value);
                }
                if (value === false && offertMode === true){
                    ModifySpecEnum.OffertMode.setContextValue(modifySpec, value);
                    setOffertMode(value);
                }
                setCheckedCover(value);

                break;
            case ModifySpecEnum.NewPagePerPosition:
                if (value === false) {
                    if (checkedCover === true) {
                        ModifySpecEnum.CheckedCover.setContextValue(modifySpec, value);
                        setCheckedCover(value);
                    }

                    if (offertMode === true) {
                        ModifySpecEnum.OffertMode.setContextValue(modifySpec, value);
                        setOffertMode(value);
                    }
                }
                setNewPagePerPosition(value);
                break;
            case ModifySpecEnum.DisplayOption:
                setDisplayOption(value);
                break;
            case ModifySpecEnum.IncludeMagicloudLink:
                setIncludeMagicloudLink(value);
                break;
            case ModifySpecEnum.OffertMode:
                if (value === true) {
                    if (checkedCover === false || checkedCover === '') {
                        ModifySpecEnum.CheckedCover.setContextValue(modifySpec, value);
                        setCheckedCover(value);
                    }
                    if (newPagePerPosition === false || newPagePerPosition === '') {
                        ModifySpecEnum.NewPagePerPosition.setContextValue(modifySpec, value);
                        setNewPagePerPosition(value);
                    }
                }

                setOffertMode(value);
                break;
            default:
                console.log("No matching param")
                break;
        }
    }

    /*
    useEffect(() => {
        if (ModifySpecEnum.DisplayOption.getContextValue(displayOption) === "") {
            console.log("Set Type: Collected ", ModifySpecEnum.DisplayOption.getContextValue(displayOption))
            setDisplayOption("collected", ModifySpecEnum.DisplayOption);
        }
    }, []);*/

    useEffect(() => {
        if (modifySpec.state.val != null) {
            saveModifiedData(database, sessionParams, modifySpec)
        }
    }, [modifySpec.state.val]);

    const [openComment, setOpenComment] = useState(false);

    const handleOpenComment = () => {
        setOpenComment(true);
    }

    const handleCloseComment = () => {
        setOpenComment(false);
    }

    const sessionRef = ref(database, SESSION_PATH + SessionParamsEnum.SessionID.getContextValue(sessionParams));

    const response = useDatabaseObjectData(sessionRef);
    // eslint-disable-next-line no-unused-vars
    const { status, data } = response;

    const hasComments = () => {
        return data?.CustomData?.lastComment != null;
    }

    const getLastModified = () => {
        if (hasComments()) {
            const date = new Date(data.CustomData.lastComment)
            return date.toLocaleString('se-SV', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit', hour: '2-digit', minute: '2-digit'
            });
        }
        else {
            return "-";
        }
    }

    return (
        <Paper className='overview-main-panel'>
            <div className="overview-content-size">
                <Stack spacing={1} style={{ width: "800px", paddingTop: "10px", paddingLeft: "20px", overflowY: 'hidden' }}>
                    <div>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showID}
                                        onChange={(event) => { setModifySpec(event.target.checked, ModifySpecEnum.ShowID) }}
                                        value={showID}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="Visa sessions-ID"
                            />

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={checkedCover}
                                        onChange={(event) => { setModifySpec(event.target.checked, ModifySpecEnum.CheckedCover) }}
                                        value={checkedCover}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="Försättsblad"
                            />

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={offertMode}
                                        onChange={(event) => { setModifySpec(event.target.checked, ModifySpecEnum.OffertMode) }}
                                        value={offertMode}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="Offert-läge" />

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={newPagePerPosition}
                                        onChange={(event) => { setModifySpec(event.target.checked, ModifySpecEnum.NewPagePerPosition) }}
                                        value={newPagePerPosition}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="Ny sida per position"
                            />

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={includeMagicloudLink}
                                        onChange={(event) => { setModifySpec(event.target.checked, ModifySpecEnum.IncludeMagicloudLink) }}
                                        value={includeMagicloudLink}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="Inkludera MagiCloud-länk"
                            />

                            {/*<FormControlLabel control={<Switch checked={checkedTerms}
                                onChange={(event) => { setModifySpec(event.target.checked, ModifySpecEnum.CheckedTerms) }}
                                value={checkedTerms}
                                inputProps={{ 'aria-label': 'controlled' }} />} label="Inkludera priser och villkor" />*/}
                        </FormGroup>
                    </div>
                    {checkedTerms === true &&
                        <div>
                            <p style={{ fontStyle: "italic", marginBottom: '15px' }}>Villkor</p>
                            <TermsInput optionType={"freightOptions"} labelText={"Frakter"} />

                            <TermsInput optionType={"deliveryOptions"} labelText={"Leveranstid"} />

                            <TermsInput optionType={"paymentOptions"} labelText={"Betalningsvillkor"} />

                            <TermsInput optionType={"warantyOptions"} labelText={"Garantitid"} />

                            <TermsInput optionType={"otherOptions"} labelText={"Övriga villkor"} />
                        </div>
                    }

                    <div style={{ marginTop: '0px' }}>
                        <Divider />
                        <p style={{ fontStyle: "italic", marginBottom: '10px' }}>Illustrationer</p>
                        <TableContainer component={Paper} sx={{ width: 'fit-content' }}>
                            <Table sx={{ minWidth: 420 }} size="small" aria-label="illustration table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ minWidth: 50, maxWidth: 55 }}>Shuntgrupp</TableCell>
                                        <TableCell>Illustration</TableCell>
                                        <TableCell>Uppladdad</TableCell>
                                        <TableCell>Ladda upp</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {shuntList.map((shuntID) => (
                                        <FirebaseIllustrationRow shuntID={shuntID} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                    <div style={{ marginTop: '10px', marginBottom: '25px' }}>
                        <Divider />
                        <p style={{ fontStyle: "italic", marginBottom: '10px' }}>Anmärkningar & förtydliganden</p>
                        <TableContainer component={Paper} sx={{ width: 'fit-content' }}>
                            <Table sx={{ minWidth: 300 }} size="small" aria-label="remarks table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ minWidth: 50, maxWidth: 55 }}>Shuntgrupp</TableCell>
                                        <TableCell>Uppdaterad</TableCell>
                                        <TableCell>Kommentar</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {shuntList.map((shuntID) => (
                                        <FirebaseRemarksRow shuntID={shuntID} editMode={false} />
                                    ))}
                                    <StyledTableRow>
                                        <TableCell style={{ fontStyle: "italic" }}>Specifikationsövergripande</TableCell>
                                        <TableCell>{getLastModified()}</TableCell>
                                        <TableCell>
                                            <Button className="comment-btn" onClick={handleOpenComment}>
                                                {hasComments() ? (
                                                    <EditIcon fontSize="small" />
                                                ) : (
                                                    <AddIcon fontSize="small" />
                                                )}
                                            </Button>
                                        </TableCell>
                                    </StyledTableRow>


                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                    <div style={{ marginTop: '0px', marginBottom: '20px' }}>
                        <FormControl>

                            <RadioGroup
                                row
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={displayOption}
                                onChange={(event) => { setModifySpec(event.target.value, ModifySpecEnum.DisplayOption) }}

                            >
                                <FormControlLabel style={{ marginRight: '50px', marginLeft: '1px' }} value="collected" control={<Radio />} label="Visa samlat" />
                                <FormControlLabel value="perShunt" control={<Radio />} label="Visa per shuntgrupp" />
                            </RadioGroup>
                        </FormControl>
                    </div>


                </Stack>
            </div>
            <CommentDialog handleOnDecline={handleCloseComment} type="overall" open={openComment} shuntID={null} />
        </Paper>

    )
}