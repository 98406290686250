import { Grid, Paper, Skeleton, Divider, Box } from "@mui/material"
import { Fragment } from "react"


export const LoadingShuntSpecSkeleton = () => {

    return (
        <Fragment>
            <Grid item xs={7.6}>
                <Paper elevation={0} style={{ width: '765px', justifyContent: 'center', padding: '8px 18px', marginTop: '10px', height: 'fit-content' }}>
                    <Skeleton
                        animation="wave"
                        height={35}
                        width="50%"
                        style={{ marginBottom: 2 }}
                    />
                    <Divider style={{ width: '60%' }} />
                    <Skeleton
                        animation="wave"
                        height={20}
                        width="40%"
                        style={{ marginTop: 2, marginBottom: 2 }}
                    />
                    <Skeleton
                        animation="wave"
                        height={20}
                        width="40%"
                        style={{ marginBottom: 2 }}
                    />
                    <Skeleton
                        animation="wave"
                        height={20}
                        width="40%"
                        style={{ marginBottom: 2 }}
                    />
                    <Skeleton
                        animation="wave"
                        height={20}
                        width="40%"
                        style={{ marginBottom: 5 }}
                    />
                    <Divider style={{ width: '60%' }} />
                    <Skeleton
                        animation="wave"
                        height={22}
                        width="20%"
                        style={{ marginTop: 15, marginBottom: 2 }}
                    />
                    <Skeleton
                        animation="wave"
                        height={20}
                        width="40%"
                        style={{ marginBottom: 2 }}
                    />
                    <Skeleton
                        animation="wave"
                        height={20}
                        width="40%"
                        style={{ marginBottom: 2 }}
                    />
                </Paper>
            </Grid>
            <Grid item xs={4}>
                <Box style={{ marginLeft: '10px', padding: '10px' }}>
                    <Skeleton
                        animation="wave"
                        height={60}
                        width="100%"
                        style={{ marginBottom: '-30px' }}
                    />
                    <Skeleton
                        animation="wave"
                        height={200}
                        width="100%"
                        style={{ marginBottom: '-30px' }}
                    />
                    <Skeleton
                        animation="wave"
                        height={110}
                        width="100%"
                        style={{ marginBottom: 2 }}
                    />
                </Box>

            </Grid>
        </Fragment>
    )
}