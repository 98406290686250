import { MenuItem, Select, Stack } from '@mui/material';
import { useFirestore, useFirestoreCollection, useFirestoreDoc } from 'reactfire';
import { collection, doc } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { CONTROL_VALVES_MODELS_IMI_PATH, CONTROL_VALVES_DIMS_SMART_DEFAULT_PATH } from '../../../../constants/firebasePaths';
import { useShuntParams, useDriftParams, useSessionParams, useDevbarParams, useCalcParams } from '../../../../contexts';
import { CalcParamsEnum, DriftParamEnum, SessionParamsEnum, ShuntParamsEnum } from '../../../../enums';

export const SmartSelectField = () => {
    const shuntParams = useShuntParams();
    const driftParams = useDriftParams();
    const firestore = useFirestore();
    const sessionParams = useSessionParams();
    const calcParams = useCalcParams();
    const devParams = useDevbarParams();

    const [models, setModels] = useState([]);
    const [update, setUpdate] = useState(false);
    const [modelSelect, setModelSelect] = useState('');

    let modelUrl = CONTROL_VALVES_MODELS_IMI_PATH + 'TASmart';
    let fsUrl = CONTROL_VALVES_DIMS_SMART_DEFAULT_PATH;


    const checkCalcMode = () => {
        if (DriftParamEnum.CalcMode.getContextValue(driftParams) === "0" || DriftParamEnum.CalcMode.getContextValue(driftParams) === 0) {
            return false;
        }
        else {
            return true;
        }
    }

    const isDisabled = () => {
        if ((SessionParamsEnum.Editable.getContextValue(sessionParams) === false && devParams.state.val.devMode !== true) ||
            (DriftParamEnum.CalcMode.getContextValue(driftParams) !== "0" && DriftParamEnum.CalcMode.getContextValue(driftParams) !== 0)) {
            return true;
        }
        else {
            return false;
        }
    }

    const modelDoc = doc(firestore, modelUrl);
    const firestoreModelRes = useFirestoreDoc(modelDoc);

    const optionsDoc = collection(firestore, fsUrl);
    const firestoreRes = useFirestoreCollection(optionsDoc);

    const setValue = (value) => {
        ShuntParamsEnum.SmartDimID.setContextValue(shuntParams, value);
        CalcParamsEnum.clearData(calcParams);
        setModelSelect(value);
    };

    useEffect(() => {
        if (firestoreModelRes.data != null) {
            //console.log("MODEL: ", firestoreModelRes.data.id)
            ShuntParamsEnum.SmartModel.setContextValue(shuntParams, firestoreModelRes.data.id);
            DriftParamEnum.IVModification.setContextValue(driftParams, firestoreModelRes.data.data().ivMod);
            if (firestoreModelRes.data.data().miniIncompatible != null){
                ShuntParamsEnum.IsMiniIncompatible.setContextValue(shuntParams, firestoreModelRes.data.data().miniIncompatible);
            }
        }
    }, [firestoreModelRes])

    useEffect(() => {
        if (firestoreRes.data != null) {
            const localModels = [];

            firestoreRes.data.forEach(model => {
                
                localModels.push({ id: model.id, data: model.data() });
            });
            setModels(localModels);
            if (localModels.length > 0 && ShuntParamsEnum.SmartDimID.getContextValue(shuntParams) == null) {
                ShuntParamsEnum.SmartDimID.setContextValue(shuntParams, localModels[0].id);
                setModelSelect(localModels[0].id);
                setUpdate(!update)
            }
            else {
                setModelSelect(ShuntParamsEnum.SmartDimID.getContextValue(shuntParams));
                setUpdate(!update);
            }
        }

    }, [firestoreRes]);

    useEffect(() => {
        if (ShuntParamsEnum.SmartDimID.getContextValue(shuntParams) != null && ShuntParamsEnum.SmartDimID.getContextValue(shuntParams) !== "") {
            setModelSelect(ShuntParamsEnum.SmartDimID.getContextValue(shuntParams));
            setUpdate(!update);
        }
    }, [ShuntParamsEnum.SmartDimID.getContextValue(shuntParams)])

    return (
        <>
            {!checkCalcMode() ? (
                <>
                    <Stack direction={"row"}>
                        <div style={{ paddingLeft: "15px", marginTop: "8px", }}>
                            Dimension:
                        </div>
                        <Select
                            style={{ marginLeft: "auto", width: "42%", marginTop: "5px", fontSize: "11px", height: '22px' }}
                            size={"small"}
                            value={modelSelect}
                            margin="dense"
                            onChange={(event) => { setValue(event.target.value) }}
                            disabled={isDisabled()}
                        >
                            {models.map((model, index) => {
                                return (<MenuItem key={model.id} value={model.id}>{model.id} ({model?.data?.qMin} - {model?.data?.qMax})</MenuItem>)
                            })}

                        </Select>
                    </Stack>
                </>
            ) : (
                <></>
            )}
        </>
    );
};