import { ref, set } from 'firebase/database';
import { createContext, useReducer, useContext } from 'react'
import { SESSION_SHUNT_PATH, SHUNT_CALCPARAMS_MEASUREMENTDATA_PATH, SHUNT_CALCPARAMS_PRIMINTERNALPRESSUREPROP_PATH } from '../constants/firebasePaths';
import { ADJ_VALVE_KEY, AUTHORITY_KEY, BALANCING_KV_KEY, CONTROL_VALVE_KEY, KVS_KEY, KV_KEY, MEASUREMENTDATA_KEY, MIN_PRESSURE_KEY, PRIM_DIM_KEY, PRIM_DIM_ROW_KEY, PRIM_INTERNAL_DP_KEY, PRIM_INTERNAL_PRESSURE_DROP_KEY, SEC_DIM_KEY, SEC_DIM_ROW_KEY, SEC_INTERNAL_PRESSURE_DROP_KEY, SHUNT_TYPE_KEY, SV_DIM_KEY, SV_DIM_OBJ_KEY, SV_MAX_KEY, SV_PRESSURE_DROP_KEY, SV_SETTING_KEY, TOTAL_PRESSURE_KEY } from '../constants/keys/calcParamsKeys';
import { CALC_PARAMS } from '../constants/sessionStoragePaths';

const CalcParams = createContext(undefined);

function calcParamsReducer(state, action) {
    switch (action.type) {
        case 'setKv': {
            let data = {};
            if (sessionStorage.getItem(CALC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(CALC_PARAMS));
            }

            data[KV_KEY] = action.payload;
            sessionStorage.setItem(CALC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setKvs': {
            let data = {};
            if (sessionStorage.getItem(CALC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(CALC_PARAMS));
            }

            data[KVS_KEY] = action.payload;
            sessionStorage.setItem(CALC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setSVPressureLoss': {
            let data = {};
            if (sessionStorage.getItem(CALC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(CALC_PARAMS));
            }

            data[SV_PRESSURE_DROP_KEY] = action.payload;
            sessionStorage.setItem(CALC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setPrimInternalPressureDrop': {
            let data = {};
            if (sessionStorage.getItem(CALC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(CALC_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_CALCPARAMS_PRIMINTERNALPRESSUREPROP_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }


            data[PRIM_INTERNAL_PRESSURE_DROP_KEY] = action.payload;
            sessionStorage.setItem(CALC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setSecInternalPressureDrop': {
            let data = {};
            if (sessionStorage.getItem(CALC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(CALC_PARAMS));
            }

            data[SEC_INTERNAL_PRESSURE_DROP_KEY] = action.payload;
            sessionStorage.setItem(CALC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setAuthority': {
            let data = {};
            if (sessionStorage.getItem(CALC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(CALC_PARAMS));
            }

            data[AUTHORITY_KEY] = action.payload;
            sessionStorage.setItem(CALC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setPrimDim': {
            let data = {};
            if (sessionStorage.getItem(CALC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(CALC_PARAMS));
            }

            data[PRIM_DIM_KEY] = action.payload;
            sessionStorage.setItem(CALC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setSekDim': {
            let data = {};
            if (sessionStorage.getItem(CALC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(CALC_PARAMS));
            }

            data[SEC_DIM_KEY] = action.payload;
            sessionStorage.setItem(CALC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setAdjValve': {
            let data = {};
            if (sessionStorage.getItem(CALC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(CALC_PARAMS));
            }

            if (data[ADJ_VALVE_KEY] !== action.payload) {
                delete data.controlValve;
            }
            data[ADJ_VALVE_KEY] = action.payload;
            sessionStorage.setItem(CALC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setControlValve': {
            let data = {};
            if (sessionStorage.getItem(CALC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(CALC_PARAMS));
            }

            data[CONTROL_VALVE_KEY] = action.payload;
            sessionStorage.setItem(CALC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setPrimDimRow': {
            let data = {};
            if (sessionStorage.getItem(CALC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(CALC_PARAMS));
            }

            data[PRIM_DIM_ROW_KEY] = action.payload;
            sessionStorage.setItem(CALC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setSecDimRow': {
            let data = {};
            if (sessionStorage.getItem(CALC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(CALC_PARAMS));
            }

            data[SEC_DIM_ROW_KEY] = action.payload;
            sessionStorage.setItem(CALC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case "setShuntType": {
            let data = {};
            if (sessionStorage.getItem(CALC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(CALC_PARAMS));
            }

            data[SHUNT_TYPE_KEY] = action.payload;
            sessionStorage.setItem(CALC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case "setTotalPressure": {
            let data = {};
            if (sessionStorage.getItem(CALC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(CALC_PARAMS));
            }

            data[TOTAL_PRESSURE_KEY] = action.payload;
            sessionStorage.setItem(CALC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case "setPrimInternalDP": {
            let data = {};
            if (sessionStorage.getItem(CALC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(CALC_PARAMS));
            }

            data[PRIM_INTERNAL_DP_KEY] = action.payload;
            sessionStorage.setItem(CALC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case "setSvDim": {
            let data = {};
            if (sessionStorage.getItem(CALC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(CALC_PARAMS));
            }

            data[SV_DIM_KEY] = action.payload;
            sessionStorage.setItem(CALC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case "setSvDimObj": {
            let data = {};
            if (sessionStorage.getItem(CALC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(CALC_PARAMS));
            }

            data[SV_DIM_OBJ_KEY] = action.payload;
            sessionStorage.setItem(CALC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case "setSvMax": {
            let data = {};
            if (sessionStorage.getItem(CALC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(CALC_PARAMS));
            }

            data[SV_MAX_KEY] = action.payload;
            sessionStorage.setItem(CALC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case "setSvSetting": {
            let data = {};
            if (sessionStorage.getItem(CALC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(CALC_PARAMS));
            }

            data[SV_SETTING_KEY] = action.payload;
            sessionStorage.setItem(CALC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case "setMeasurementData": {
            let data = {};
            if (sessionStorage.getItem(CALC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(CALC_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_CALCPARAMS_MEASUREMENTDATA_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added measurementData to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[MEASUREMENTDATA_KEY] = action.payload;
            sessionStorage.setItem(CALC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case "setMinPressure": {
            let data = {};
            if (sessionStorage.getItem(CALC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(CALC_PARAMS));
            }

            data[MIN_PRESSURE_KEY] = action.payload;
            sessionStorage.setItem(CALC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case "setBalancingKv": {
            let data = {};
            if (sessionStorage.getItem(CALC_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(CALC_PARAMS));
            }

            data[BALANCING_KV_KEY] = action.payload;
            sessionStorage.setItem(CALC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setDataFromSnapShot': {
            let data = {};

            data[KVS_KEY] = action.payload.child(KVS_KEY).val() || undefined;
            if (action.payload.child(BALANCING_KV_KEY).exists()){
                data[BALANCING_KV_KEY] = action.payload.child(BALANCING_KV_KEY).val();
            }
            if (action.payload.child(AUTHORITY_KEY).exists()){
                data[AUTHORITY_KEY] = action.payload.child(AUTHORITY_KEY).val();
            }
            if (action.payload.child(SV_PRESSURE_DROP_KEY).exists()){
                data[SV_PRESSURE_DROP_KEY] = action.payload.child(SV_PRESSURE_DROP_KEY).val();
            }
            if (action.payload.child(PRIM_INTERNAL_PRESSURE_DROP_KEY).exists()){
                data[PRIM_INTERNAL_PRESSURE_DROP_KEY] = action.payload.child(PRIM_INTERNAL_PRESSURE_DROP_KEY).val();
            }
            if (action.payload.child(SEC_INTERNAL_PRESSURE_DROP_KEY).exists()){
                data[SEC_INTERNAL_PRESSURE_DROP_KEY] = action.payload.child(SEC_INTERNAL_PRESSURE_DROP_KEY).val();
            }
            if (action.payload.child(TOTAL_PRESSURE_KEY).exists()){
                data[TOTAL_PRESSURE_KEY] = action.payload.child(TOTAL_PRESSURE_KEY).val();
            }
            if (action.payload.child(PRIM_INTERNAL_DP_KEY).exists()){
                data[PRIM_INTERNAL_DP_KEY] = action.payload.child(PRIM_INTERNAL_DP_KEY).val();
            }
            data[SHUNT_TYPE_KEY] = action.payload.child(SHUNT_TYPE_KEY).val() || undefined;
            data[PRIM_DIM_ROW_KEY] = action.payload.child(PRIM_DIM_ROW_KEY).val() || undefined;
            data[SEC_DIM_ROW_KEY] = action.payload.child(SEC_DIM_ROW_KEY).val() || undefined;
            data[ADJ_VALVE_KEY] = action.payload.child(ADJ_VALVE_KEY).val() || undefined;
            data[CONTROL_VALVE_KEY] = action.payload.child(CONTROL_VALVE_KEY).val() || undefined;
            data[SV_DIM_KEY] = action.payload.child(SV_DIM_KEY).val() || undefined;
            data[SV_DIM_OBJ_KEY] = action.payload.child(SV_DIM_OBJ_KEY).val() || undefined;
            data[SV_MAX_KEY] = action.payload.child(SV_MAX_KEY).val() || undefined;
            data[SV_SETTING_KEY] = action.payload.child(SV_SETTING_KEY).val() || undefined;
            data[MIN_PRESSURE_KEY] = action.payload.child(MIN_PRESSURE_KEY).val() || undefined;
            data[MEASUREMENTDATA_KEY] = action.payload.child(MEASUREMENTDATA_KEY).val() || undefined;

            //console.log("Loading Data:", data);
            sessionStorage.setItem(CALC_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'clear': {
            sessionStorage.removeItem(CALC_PARAMS);
            return { val: {} }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function CalcParamsProvider({ children }) {
    let obj = {};
    if (sessionStorage.getItem(CALC_PARAMS) != null) {
        obj = JSON.parse(sessionStorage.getItem(CALC_PARAMS));
    }

    const [state, dispatch] = useReducer(calcParamsReducer, { val: obj })
    const value = { state, dispatch }
    return (
        <CalcParams.Provider value={value}>
            {children}
        </CalcParams.Provider>);
}

function useCalcParams() {
    const context = useContext(CalcParams)
    if (context === undefined) {
        throw new Error('useDriftParams must be used within a CalcParams')
    }
    return context
}


export { CalcParamsProvider, useCalcParams }