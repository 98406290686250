import { useState } from "react"
import { Box, Divider, IconButton, Modal, Paper, Stack, Tooltip, Typography, } from "@mui/material"

import { useAdjustParams, useCalcParams, useConnTypes, useDriftParams, useErrorContext, useNoticeContext, useProductLineParams, usePumpParams, useSessionParams, useShuntParams } from "../../../contexts";
import { AdjustmentsEnum, CalcParamsEnum, DriftParamEnum, ErrorEnum, NoticeEnum, ProductLineEnum, SessionParamsEnum, ShuntParamsEnum } from "../../../enums";
import { PumpParamEnum } from "../../../enums/pumpParamsEnum";
import { useNavigate } from "react-router-dom";
import { useDatabase } from "reactfire";
import { CompactConnectionCard } from "./compactConnectionCard";

import { PRODUCTLINE_FLEX, PRODUCTLINE_GREEN, PRODUCTLINE_GREEN_I, PRODUCTLINE_GREEN_II, PRODUCTLINE_SMART } from "../../../constants/productlineConstants";
import { SelectProdLinePopup } from "./selectProdLinePopup";
import { clearCalcData } from "../../../firebase/functions";
import { clearDriftData } from "../../../firebase/functions/saveDriftParams";
import { clearShuntData } from "../../../firebase/functions/saveShuntData";
import { getCurrentHighPosition } from "../../../firebase/functions/getCurrentHighPosition";
import { QUESTION_SOURCE_KEY, QUESTION_APPLICATION_KEY, QUESTION_DIMENSIONABLE_KEY, APP_SOURCE, QUESTION_DRIVING_PRESSURE_KEY, QUESTION_DRIVING_PRESSURE_IS_VARIABLE_KEY, QUESTION_DRIVING_PRESSURE_SPEC_VALUE_KEY, QUESTION_HAS_MAIN_PUMP_KEY, QUESTION_CERTIFICATION_KEY, ENVIROMENT_CERTIFICATIONS, QUESTION_SYSTEM_FLUID_KEY, QUESTION_SYSTEM_FLUID_MIX_RATIO_KEY, QUESTION_SYSTEM_FLUID_MIX_RATIO_UNKNOWN_KEY, QUESTION_SYSTEM_FLUID_PRIMARY_KEY, QUESTION_SYSTEM_FLUID_SECONDARY_KEY, QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_KEY, QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_KEY, QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY, QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_KEY, AVAILABLE_DRIVE_PRESSURE, QUESTION_SECONDARY_FLOW_KEY, QUESTION_ENERGY_MEASUREMENT_KEY, QUESTION_STAINLESS_KEY, QUESTION_LIMITED_SPACE_KEY, ENERGY_MEASUREMENT_OPTIONS, SYSTEM_FLUID, QUESTION_APPLICATION_INTENT_KEY, PRODUCT_LINE_DATA, QUESTION_PRIORITIES, PRIORITY_OPTIONS, QUESTION_SECONDARY_FLOW_SPEC_VALUE_KEY, QUESTION_SOURCE_FLOW_KEY, QUESTION_APPLICATION_SIZE_KEY, QUESTION_DYNAMIC_CONTROL_VALVE_KEY } from "../../../constants/wizardConstants";
import { WizardTab1Questions } from "./autoPickerTab1";
import { useWizardParams } from "../../../contexts/wizardParams";
import { WizardParamEnum } from "../../../enums/wizardParamsEnum";


import { INITIAL_WIZARD_INFO_TEXT } from "../../../constants/generalText/wizardText";
import { WizardTab2Questions } from "./autoPickerTab2";
import { SparkleWizardButton } from "../../utils/SparkleButton";
import { saveWizardData } from "../../../firebase/functions/saveWizardData";
import { connectionTypeList } from "../../../constants/fullConnOptions/connOptionData";
import { saveProductLineDataInputs } from "../../../firebase/functions/saveProductLineData";
import { ConfirmChangedPrinciple } from "../principlePicker/components/confirmChangedPrinciple";
import { resetMaxStepFunc } from "../../../functions/HelperFunctions/resetMaxStep";

import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CloseIcon from '@mui/icons-material/Close';
import { useContextAuth } from "../../../authContext/AuthHook";

const style = {
    position: 'absolute',
    left: '50%',
    top: '5%',
    translate: '-50%',
    width: { xs: '94vw', md: '90vw', xl: '85vw', xxl: '50vw' },
    height: '82vh',
    bgcolor: 'rgba(255,255,255,0.85)',
    border: '2px solid #E1E2E4',
    borderRadius: '1px',
    boxShadow: 24,
    padding: "20px",
    overflow: 'hidden',
};

export const AutoPickerDialog = () => {
    const { user } = useContextAuth();
    const connTypes = useConnTypes();
    const database = useDatabase();
    const sessionParams = useSessionParams();
    const productLine = useProductLineParams();
    const adjustParams = useAdjustParams();
    const calcParams = useCalcParams();
    const driftParams = useDriftParams();
    const errorContext = useErrorContext();
    const noticeContext = useNoticeContext();
    const pumpParams = usePumpParams();
    const shuntParams = useShuntParams();
    const wizardParams = useWizardParams();

    const Errorlist = new ErrorEnum();
    const Noticelist = new NoticeEnum();

    const navigate = useNavigate();

    const currentShuntID = SessionParamsEnum.CurrentShunt.getContextValue(sessionParams);

    const [currentTab, setCurrentTab] = useState(1);
    const [doubleClickBlock, setDoubleClickBlock] = useState(false);
    const [modified, setModified] = useState(false);
    const [resetVal, setResetVal] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => {
        saveWizardData(database, sessionParams, wizardParams).then(() => {
            console.log("Saved selection");
        }).catch((err) => {
            console.error("Error saving data: ", err);
        });
        setOpenModal(false);
    }

    const [update, setUpdate] = useState(false)
    //SelectModal
    const [openSelectModal, setOpenSelectModal] = useState(false);

    const handleSelectOpen = () => setOpenSelectModal(true);
    const handleSelectClose = () => {
        setCurrentSelected({})
        setOpenSelectModal(false);
    }

    const [selectedPLLine, setSelectedPLLine] = useState("");
    const [selectedPLPrinciple, setSelectedPLPrinciple] = useState("");
    const [openConfirmChange, setOpenConfirmChange] = useState(false);

    const handleOpenConfirmChangeModal = () => { setOpenConfirmChange(true) }
    const handleCloseConfirmChangeModal = () => {
        setOpenConfirmChange(false);
    }

    const getContextValues = (type, useBaseValue = false) => {
        switch (type) {
            case QUESTION_SOURCE_KEY:
                if (WizardParamEnum.Source.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.Source.getContextValue(wizardParams);
                }
                else {
                    return {
                        [APP_SOURCE.districtHeating.value]: false,
                        [APP_SOURCE.districtCooling.value]: false,
                        [APP_SOURCE.heatPump.value]: false,
                        [APP_SOURCE.boiler.value]: false,
                        [APP_SOURCE.coolingMachine.value]: false,
                        [APP_SOURCE.recoveryBattery.value]: false,
                        [APP_SOURCE.freeCooling.value]: false,
                        [APP_SOURCE.recovery.value]: false,
                        [APP_SOURCE.other.value]: false,
                        [APP_SOURCE.unknown.value]: false,
                    };
                }
            case QUESTION_SOURCE_FLOW_KEY:
                if (WizardParamEnum.SourceFlow.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.SourceFlow.getContextValue(wizardParams);
                }
                else {
                    return 'variable';
                }
            case QUESTION_APPLICATION_KEY:
                if (WizardParamEnum.Application.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.Application.getContextValue(wizardParams);
                }
                else {
                    return '';
                }
            case QUESTION_APPLICATION_INTENT_KEY:
                if (WizardParamEnum.ApplicationIntent.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.ApplicationIntent.getContextValue(wizardParams);
                }
                else {
                    return '';
                }
            case QUESTION_APPLICATION_SIZE_KEY:
                if (WizardParamEnum.ApplicationSize.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.ApplicationSize.getContextValue(wizardParams);
                }
                else {
                    return '';
                }
            case QUESTION_DIMENSIONABLE_KEY:
                if (WizardParamEnum.OnlyDimensionable.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.OnlyDimensionable.getContextValue(wizardParams);
                }
                else {
                    return false;
                }
            case QUESTION_DRIVING_PRESSURE_KEY:
                if (WizardParamEnum.AvailDrivingPressure.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.AvailDrivingPressure.getContextValue(wizardParams);
                }
                else {
                    return '';
                }
            case QUESTION_DRIVING_PRESSURE_IS_VARIABLE_KEY:
                if (WizardParamEnum.VariableAvailDrivingPressure.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.VariableAvailDrivingPressure.getContextValue(wizardParams);
                }
                else {
                    return false;
                }
            case QUESTION_DRIVING_PRESSURE_SPEC_VALUE_KEY:
                if (WizardParamEnum.SpecificAvailDrivingPressure.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.SpecificAvailDrivingPressure.getContextValue(wizardParams);
                }
                else {
                    return 0;
                }
            case QUESTION_SECONDARY_FLOW_KEY:
                if (WizardParamEnum.SecondaryFlow.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.SecondaryFlow.getContextValue(wizardParams);
                }
                else {
                    return '';
                }
            case QUESTION_SECONDARY_FLOW_SPEC_VALUE_KEY:
                if (WizardParamEnum.SecondaryFlowSpecVal.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.SecondaryFlowSpecVal.getContextValue(wizardParams);
                }
                else {
                    return '';
                }
            case QUESTION_HAS_MAIN_PUMP_KEY:
                if (WizardParamEnum.HasMainPump.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.HasMainPump.getContextValue(wizardParams);
                }
                else {
                    return true;
                }
            case QUESTION_CERTIFICATION_KEY:
                if (WizardParamEnum.Certifications.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.Certifications.getContextValue(wizardParams);
                }
                else {
                    return {
                        [ENVIROMENT_CERTIFICATIONS.sundaHus.value]: false,
                        [ENVIROMENT_CERTIFICATIONS.byggVaru.value]: false,
                        [ENVIROMENT_CERTIFICATIONS.EPD.value]: false,
                    };
                }
            case QUESTION_SYSTEM_FLUID_KEY:
                if (WizardParamEnum.SystemFluidMain.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.SystemFluidMain.getContextValue(wizardParams);
                }
                else {
                    return '';
                }
            case QUESTION_SYSTEM_FLUID_MIX_RATIO_KEY:
                if (WizardParamEnum.SystemFluidPrimaryMixRatio.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.SystemFluidPrimaryMixRatio.getContextValue(wizardParams);
                }
                else {
                    return 0;
                }
            case QUESTION_SYSTEM_FLUID_MIX_RATIO_UNKNOWN_KEY:
                if (WizardParamEnum.SystemFluidPrimaryMixRatioUnknown.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.SystemFluidPrimaryMixRatioUnknown.getContextValue(wizardParams);
                }
                else {
                    return false;
                }
            case QUESTION_SYSTEM_FLUID_PRIMARY_KEY:
                if (WizardParamEnum.SystemFluidPrimary.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.SystemFluidPrimary.getContextValue(wizardParams);
                }
                else {
                    return '';
                }
            case QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_KEY:
                if (WizardParamEnum.SystemFluidPrimaryMixRatio.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.SystemFluidPrimaryMixRatio.getContextValue(wizardParams);
                }
                else {
                    return 0;
                }
            case QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_KEY:
                if (WizardParamEnum.SystemFluidPrimaryMixRatioUnknown.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.SystemFluidPrimaryMixRatioUnknown.getContextValue(wizardParams);
                }
                else {
                    return false;
                }
            case QUESTION_SYSTEM_FLUID_SECONDARY_KEY:
                if (WizardParamEnum.SystemFluidSecondary.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.SystemFluidSecondary.getContextValue(wizardParams);
                }
                else {
                    return '';
                }
            case QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY:
                if (WizardParamEnum.SystemFluidSecondaryMixRatio.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.SystemFluidSecondaryMixRatio.getContextValue(wizardParams);
                }
                else {
                    return 0;
                }
            case QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_KEY:
                if (WizardParamEnum.SystemFluidSecondaryMixRatioUnknown.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.SystemFluidSecondaryMixRatioUnknown.getContextValue(wizardParams);
                }
                else {
                    return false;
                }
            case QUESTION_ENERGY_MEASUREMENT_KEY:
                if (WizardParamEnum.EnergyMeasurement.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.EnergyMeasurement.getContextValue(wizardParams);
                }
                else {
                    return '';
                }
            case QUESTION_LIMITED_SPACE_KEY:
                if (WizardParamEnum.LimitedSpace.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.LimitedSpace.getContextValue(wizardParams);
                }
                else {
                    return false;
                }
            case QUESTION_DYNAMIC_CONTROL_VALVE_KEY:
                if (WizardParamEnum.DynamicControlValve.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.DynamicControlValve.getContextValue(wizardParams);
                }
                else {
                    return false;
                }
            case QUESTION_STAINLESS_KEY:
                if (WizardParamEnum.Stainless.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.Stainless.getContextValue(wizardParams);
                }
                else {
                    return false;
                }
            case QUESTION_PRIORITIES:
                if (WizardParamEnum.Priorities.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.Priorities.getContextValue(wizardParams);
                }
                else {
                    return {
                        [PRIORITY_OPTIONS.quality.value]: true,
                        [PRIORITY_OPTIONS.price.value]: false,
                        [PRIORITY_OPTIONS.delivery.value]: false,
                        [PRIORITY_OPTIONS.opCost.value]: false,
                        [PRIORITY_OPTIONS.enviroment.value]: false,
                        [PRIORITY_OPTIONS.automation.value]: false,
                        [PRIORITY_OPTIONS.reliability.value]: false,
                    };
                }
            default:
                return "";
        }
    }

    //Org-answers
    const [orgAppSource] = useState(getContextValues(QUESTION_SOURCE_KEY));
    const [orgSourceFlow] = useState(getContextValues(QUESTION_SOURCE_FLOW_KEY));
    const [orgApplication] = useState(getContextValues(QUESTION_APPLICATION_KEY));
    const [orgApplicationIntent] = useState(getContextValues(QUESTION_APPLICATION_INTENT_KEY));
    const [orgApplicationSize] = useState(getContextValues(QUESTION_APPLICATION_SIZE_KEY));
    const [orgDimensionable] = useState(getContextValues(QUESTION_DIMENSIONABLE_KEY));
    const [orgSecondaryFlow] = useState(getContextValues(QUESTION_SECONDARY_FLOW_KEY));
    const [orgSecondarySpecFlow] = useState(getContextValues(QUESTION_SECONDARY_FLOW_SPEC_VALUE_KEY));
    const [orgDrivingPressure] = useState(getContextValues(QUESTION_DRIVING_PRESSURE_KEY));
    const [orgDpIsVariable] = useState(getContextValues(QUESTION_DRIVING_PRESSURE_IS_VARIABLE_KEY));
    const [orgdpSpecValue] = useState(getContextValues(QUESTION_DRIVING_PRESSURE_SPEC_VALUE_KEY));
    const [orgHasMainPump] = useState(getContextValues(QUESTION_HAS_MAIN_PUMP_KEY))
    const [orgEnviromentCert] = useState(getContextValues(QUESTION_CERTIFICATION_KEY));
    const [orgSystemFluid] = useState(getContextValues(QUESTION_SYSTEM_FLUID_KEY));
    const [orgSystemFluidMixRatio] = useState(getContextValues(QUESTION_SYSTEM_FLUID_MIX_RATIO_KEY));
    const [orgSystemFluidMixRatioUnknown] = useState(getContextValues(QUESTION_SYSTEM_FLUID_MIX_RATIO_UNKNOWN_KEY));
    const [orgSystemFluidPrimary] = useState(getContextValues(QUESTION_SYSTEM_FLUID_PRIMARY_KEY));
    const [orgSystemFluidPrimaryMixRatio] = useState(getContextValues(QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_KEY));
    const [orgSystemFluidPrimaryMixRatioUnknown] = useState(getContextValues(QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_KEY));
    const [orgSystemFluidSecondary] = useState(getContextValues(QUESTION_SYSTEM_FLUID_SECONDARY_KEY));
    const [orgSystemFluidSecondaryMixRatio] = useState(getContextValues(QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY));
    const [orgSystemFluidSecondaryMixRatioUnknown] = useState(getContextValues(QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_KEY));
    const [orgEnergyMeasurement] = useState(getContextValues(QUESTION_ENERGY_MEASUREMENT_KEY));
    const [orgStainless] = useState(getContextValues(QUESTION_STAINLESS_KEY));
    const [orgLimitedSpace] = useState(getContextValues(QUESTION_LIMITED_SPACE_KEY));
    const [orgDynamicControlValve] = useState(getContextValues(QUESTION_DYNAMIC_CONTROL_VALVE_KEY));
    const [orgPriorities] = useState(getContextValues(QUESTION_PRIORITIES));

    //Tab 1 Questions
    const [appSource, setAppSource] = useState(getContextValues(QUESTION_SOURCE_KEY));
    const [sourceFlow, setSourceFlow] = useState(getContextValues(QUESTION_SOURCE_FLOW_KEY));
    const [application, setApplication] = useState(getContextValues(QUESTION_APPLICATION_KEY));
    const [applicationIntent, setApplicationIntent] = useState(getContextValues(QUESTION_APPLICATION_INTENT_KEY));
    const [applicationSize, setApplicationSize] = useState(getContextValues(QUESTION_APPLICATION_SIZE_KEY));
    const [dimensionable, setDimensionable] = useState(getContextValues(QUESTION_DIMENSIONABLE_KEY));
    const [secondaryFlow, setSecondaryFlow] = useState(getContextValues(QUESTION_SECONDARY_FLOW_KEY));
    const [secondarySpecFlow, setSecondarySpecFlow] = useState(getContextValues(QUESTION_SECONDARY_FLOW_SPEC_VALUE_KEY));
    const [drivingPressure, setDrivingPressure] = useState(getContextValues(QUESTION_DRIVING_PRESSURE_KEY));
    const [dpIsVariable, setDPIsVariable] = useState(getContextValues(QUESTION_DRIVING_PRESSURE_IS_VARIABLE_KEY));
    const [dpSpecValue, setDPSpecValue] = useState(getContextValues(QUESTION_DRIVING_PRESSURE_SPEC_VALUE_KEY));
    const [hasMainPump, setHasMainPump] = useState(getContextValues(QUESTION_HAS_MAIN_PUMP_KEY))
    const [enviromentCert, setEnviromentCert] = useState(getContextValues(QUESTION_CERTIFICATION_KEY));
    const [systemFluid, setSystemFluid] = useState(getContextValues(QUESTION_SYSTEM_FLUID_KEY));
    const [systemFluidMixRatio, setSystemFluidMixRatio] = useState(getContextValues(QUESTION_SYSTEM_FLUID_MIX_RATIO_KEY));
    const [systemFluidMixRatioUnknown, setSystemFluidMixRatioUnknown] = useState(getContextValues(QUESTION_SYSTEM_FLUID_MIX_RATIO_UNKNOWN_KEY));
    const [systemFluidPrimary, setSystemFluidPrimary] = useState(getContextValues(QUESTION_SYSTEM_FLUID_PRIMARY_KEY));
    const [systemFluidPrimaryMixRatio, setSystemFluidPrimaryMixRatio] = useState(getContextValues(QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_KEY));
    const [systemFluidPrimaryMixRatioUnknown, setSystemFluidPrimaryMixRatioUnknown] = useState(getContextValues(QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_KEY));
    const [systemFluidSecondary, setSystemFluidSecondary] = useState(getContextValues(QUESTION_SYSTEM_FLUID_SECONDARY_KEY));
    const [systemFluidSecondaryMixRatio, setSystemFluidSecondaryMixRatio] = useState(getContextValues(QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY));
    const [systemFluidSecondaryMixRatioUnknown, setSystemFluidSecondaryMixRatioUnknown] = useState(getContextValues(QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_KEY));
    const [energyMeasurement, setEnergyMeasurement] = useState(getContextValues(QUESTION_ENERGY_MEASUREMENT_KEY));
    const [stainless, setStainless] = useState(getContextValues(QUESTION_STAINLESS_KEY));
    const [limitedSpace, setLimitedSpace] = useState(getContextValues(QUESTION_LIMITED_SPACE_KEY));
    const [dynamicControlValve, setDynamicControlValve] = useState(getContextValues(QUESTION_DYNAMIC_CONTROL_VALVE_KEY));

    //Tab 2 Questions
    const [priorities, setPriorities] = useState(getContextValues(QUESTION_PRIORITIES));


    const getSelected = () => {
        if (ProductLineEnum.ProductPrincip.getContextValue(productLine) != null) {
            return ProductLineEnum.ProductPrincip.getContextValue(productLine);
        }
        else {
            return '';
        }
    }

    const [selected, setSelected] = useState([]);
    const [currentSelected, setCurrentSelected] = useState(Object());

    const isSelected = (documentID) => {
        return selected.findIndex(item => item.id === documentID) !== -1;
    }


    const answerQuestion = (question, value) => {
        setModified(true)
        setResetVal(false);
        switch (question) {
            case QUESTION_SOURCE_KEY:
                if (appSource[value] != null) {
                    WizardParamEnum.Source.setContextValue(wizardParams, { ...appSource, [value]: !appSource[value] });
                    setAppSource({
                        ...appSource,
                        [value]: !appSource[value],
                    });
                }
                else {
                    WizardParamEnum.Source.setContextValue(wizardParams, { ...appSource, [value]: true });
                    setAppSource({
                        ...appSource,
                        [value]: true,
                    });
                }
                break;
            case QUESTION_SOURCE_FLOW_KEY:
                WizardParamEnum.SourceFlow.setContextValue(wizardParams, value);
                setSourceFlow(value);
                break;
            case QUESTION_APPLICATION_KEY:
                WizardParamEnum.Application.setContextValue(wizardParams, value);
                setApplication(value);
                break;
            case QUESTION_APPLICATION_INTENT_KEY:
                WizardParamEnum.ApplicationIntent.setContextValue(wizardParams, value);
                setApplicationIntent(value);
                break;
            case QUESTION_APPLICATION_SIZE_KEY:
                WizardParamEnum.ApplicationSize.setContextValue(wizardParams, value);
                setApplicationSize(value);
                break;
            case QUESTION_DIMENSIONABLE_KEY:
                WizardParamEnum.OnlyDimensionable.setContextValue(wizardParams, value);
                setDimensionable(value);
                break;
            case QUESTION_SECONDARY_FLOW_KEY:
                WizardParamEnum.SecondaryFlow.setContextValue(wizardParams, value);
                setSecondaryFlow(value);
                break;
            case QUESTION_SECONDARY_FLOW_SPEC_VALUE_KEY:
                WizardParamEnum.SecondaryFlowSpecVal.setContextValue(wizardParams, value);
                setSecondarySpecFlow(value);
                break;
            case QUESTION_DRIVING_PRESSURE_KEY:
                WizardParamEnum.AvailDrivingPressure.setContextValue(wizardParams, value);
                setDrivingPressure(value);
                break;
            case QUESTION_DRIVING_PRESSURE_IS_VARIABLE_KEY:
                WizardParamEnum.VariableAvailDrivingPressure.setContextValue(wizardParams, value);
                setDPIsVariable(value);
                break;
            case QUESTION_DRIVING_PRESSURE_SPEC_VALUE_KEY:
                WizardParamEnum.SpecificAvailDrivingPressure.setContextValue(wizardParams, value);
                setDPSpecValue(value);
                break;
            case QUESTION_HAS_MAIN_PUMP_KEY:
                WizardParamEnum.HasMainPump.setContextValue(wizardParams, value);
                setHasMainPump(value);
                break;
            case QUESTION_CERTIFICATION_KEY:
                if (enviromentCert[value] != null) {
                    WizardParamEnum.Certifications.setContextValue(wizardParams, {
                        ...enviromentCert,
                        [value]: !enviromentCert[value],
                    });
                    setEnviromentCert({
                        ...enviromentCert,
                        [value]: !enviromentCert[value],
                    });
                }
                else {
                    WizardParamEnum.Certifications.setContextValue(wizardParams, {
                        ...enviromentCert,
                        [value]: true,
                    });
                    setEnviromentCert({
                        ...enviromentCert,
                        [value]: true,
                    });
                }
                break;
            case QUESTION_SYSTEM_FLUID_KEY:
                WizardParamEnum.SystemFluidMain.setContextValue(wizardParams, value);
                setSystemFluid(value);
                break;
            case QUESTION_SYSTEM_FLUID_MIX_RATIO_KEY:
                WizardParamEnum.SystemFluidPrimaryMixRatio.setContextValue(wizardParams, value);
                setSystemFluidMixRatio(value);
                break;
            case QUESTION_SYSTEM_FLUID_MIX_RATIO_UNKNOWN_KEY:
                WizardParamEnum.SystemFluidPrimaryMixRatioUnknown.setContextValue(wizardParams, value);
                setSystemFluidMixRatioUnknown(value);
                break;
            case QUESTION_SYSTEM_FLUID_PRIMARY_KEY:
                WizardParamEnum.SystemFluidPrimary.setContextValue(wizardParams, value);
                setSystemFluidPrimary(value);
                break;
            case QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_KEY:
                WizardParamEnum.SystemFluidPrimaryMixRatio.setContextValue(wizardParams, value);
                setSystemFluidPrimaryMixRatio(value);
                break;
            case QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_KEY:
                WizardParamEnum.SystemFluidPrimaryMixRatioUnknown.setContextValue(wizardParams, value);
                setSystemFluidPrimaryMixRatioUnknown(value);
                break;
            case QUESTION_SYSTEM_FLUID_SECONDARY_KEY:
                WizardParamEnum.SystemFluidSecondary.setContextValue(wizardParams, value);
                setSystemFluidSecondary(value);
                break;
            case QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY:
                WizardParamEnum.SystemFluidSecondaryMixRatio.setContextValue(wizardParams, value);
                setSystemFluidSecondaryMixRatio(value);
                break;
            case QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_KEY:
                WizardParamEnum.SystemFluidSecondaryMixRatioUnknown.setContextValue(wizardParams, value);
                setSystemFluidSecondaryMixRatioUnknown(value);
                break;
            case QUESTION_ENERGY_MEASUREMENT_KEY:
                WizardParamEnum.EnergyMeasurement.setContextValue(wizardParams, value);
                setEnergyMeasurement(value);
                break;
            case QUESTION_STAINLESS_KEY:
                WizardParamEnum.Stainless.setContextValue(wizardParams, value);
                setStainless(value);
                break;
            case QUESTION_LIMITED_SPACE_KEY:
                WizardParamEnum.LimitedSpace.setContextValue(wizardParams, value);
                setLimitedSpace(value);
                break;
            case QUESTION_DYNAMIC_CONTROL_VALVE_KEY:
                WizardParamEnum.DynamicControlValve.setContextValue(wizardParams, value);
                setDynamicControlValve(value);
                break;
            case QUESTION_PRIORITIES:
                if (priorities[value] != null) {
                    WizardParamEnum.Priorities.setContextValue(wizardParams, { ...priorities, [value]: !priorities[value] });
                    setPriorities({
                        ...priorities,
                        [value]: !priorities[value],
                    });
                }
                else {
                    WizardParamEnum.Priorities.setContextValue(wizardParams, { ...priorities, [value]: true });
                    setPriorities({
                        ...priorities,
                        [value]: true,
                    });
                }
                break;
            default:
                break;
        }
    }

    const handleResetValue = () => {
        setSelected([]);
        if (SessionParamsEnum.MaxStep.getContextValue(sessionParams) > 0) {
            resetValuesSaved();
        }
        else {
            resetValues();
        }
    }

    const resetValues = () => {
        setAppSource(getContextValues(QUESTION_SOURCE_KEY, true));
        WizardParamEnum.Source.setContextValue(wizardParams, getContextValues(QUESTION_SOURCE_KEY, true));
        setSourceFlow(getContextValues(QUESTION_SOURCE_FLOW_KEY, true));
        WizardParamEnum.SourceFlow.setContextValue(wizardParams, getContextValues(QUESTION_SOURCE_FLOW_KEY, true));
        setApplication(getContextValues(QUESTION_APPLICATION_KEY, true));
        WizardParamEnum.Application.setContextValue(wizardParams, getContextValues(QUESTION_APPLICATION_KEY, true));
        setApplicationIntent(getContextValues(QUESTION_APPLICATION_INTENT_KEY, true));
        WizardParamEnum.ApplicationIntent.setContextValue(wizardParams, getContextValues(QUESTION_APPLICATION_INTENT_KEY, true));
        setApplicationSize(getContextValues(QUESTION_APPLICATION_SIZE_KEY, true));
        WizardParamEnum.ApplicationSize.setContextValue(wizardParams, getContextValues(QUESTION_APPLICATION_SIZE_KEY, true));
        setDimensionable(getContextValues(QUESTION_DIMENSIONABLE_KEY, true));
        WizardParamEnum.OnlyDimensionable.setContextValue(wizardParams, getContextValues(QUESTION_DIMENSIONABLE_KEY, true));
        setSecondaryFlow(getContextValues(QUESTION_SECONDARY_FLOW_KEY, true));
        WizardParamEnum.SecondaryFlow.setContextValue(wizardParams, getContextValues(QUESTION_SECONDARY_FLOW_KEY, true));
        setSecondarySpecFlow(getContextValues(QUESTION_SECONDARY_FLOW_SPEC_VALUE_KEY, true));
        WizardParamEnum.SecondaryFlowSpecVal.setContextValue(wizardParams, getContextValues(QUESTION_SECONDARY_FLOW_SPEC_VALUE_KEY, true));
        setDrivingPressure(getContextValues(QUESTION_DRIVING_PRESSURE_KEY, true));
        WizardParamEnum.AvailDrivingPressure.setContextValue(wizardParams, getContextValues(QUESTION_DRIVING_PRESSURE_KEY, true));
        setDPIsVariable(getContextValues(QUESTION_DRIVING_PRESSURE_IS_VARIABLE_KEY, true));
        WizardParamEnum.VariableAvailDrivingPressure.setContextValue(wizardParams, getContextValues(QUESTION_DRIVING_PRESSURE_IS_VARIABLE_KEY, true));
        setDPSpecValue(getContextValues(QUESTION_DRIVING_PRESSURE_SPEC_VALUE_KEY, true));
        WizardParamEnum.SpecificAvailDrivingPressure.setContextValue(wizardParams, getContextValues(QUESTION_DRIVING_PRESSURE_SPEC_VALUE_KEY, true));
        setHasMainPump(getContextValues(QUESTION_HAS_MAIN_PUMP_KEY, true));
        WizardParamEnum.HasMainPump.setContextValue(wizardParams, getContextValues(QUESTION_HAS_MAIN_PUMP_KEY, true));
        setEnviromentCert(getContextValues(QUESTION_CERTIFICATION_KEY, true));
        WizardParamEnum.Certifications.setContextValue(wizardParams, getContextValues(QUESTION_CERTIFICATION_KEY, true));
        setSystemFluid(getContextValues(QUESTION_SYSTEM_FLUID_KEY, true));
        WizardParamEnum.SystemFluidMain.setContextValue(wizardParams, getContextValues(QUESTION_SYSTEM_FLUID_KEY, true));
        setSystemFluidMixRatio(getContextValues(QUESTION_SYSTEM_FLUID_MIX_RATIO_KEY, true));
        setSystemFluidMixRatioUnknown(getContextValues(QUESTION_SYSTEM_FLUID_MIX_RATIO_UNKNOWN_KEY, true));
        setSystemFluidPrimary(getContextValues(QUESTION_SYSTEM_FLUID_PRIMARY_KEY, true));
        WizardParamEnum.SystemFluidPrimary.setContextValue(wizardParams, getContextValues(QUESTION_SYSTEM_FLUID_PRIMARY_KEY, true));
        setSystemFluidPrimaryMixRatio(getContextValues(QUESTION_SYSTEM_FLUID_MIX_RATIO_KEY, true));
        WizardParamEnum.SystemFluidPrimaryMixRatio.setContextValue(wizardParams, getContextValues(QUESTION_SYSTEM_FLUID_MIX_RATIO_KEY, true));
        setSystemFluidPrimaryMixRatioUnknown(getContextValues(QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_KEY, true));
        WizardParamEnum.SystemFluidPrimaryMixRatioUnknown.setContextValue(wizardParams, getContextValues(QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_KEY, true));
        setSystemFluidSecondary(getContextValues(QUESTION_SYSTEM_FLUID_SECONDARY_KEY, true));
        WizardParamEnum.SystemFluidSecondary.setContextValue(wizardParams, getContextValues(QUESTION_SYSTEM_FLUID_SECONDARY_KEY, true));
        setSystemFluidSecondaryMixRatio(getContextValues(QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY, true));
        WizardParamEnum.SystemFluidSecondaryMixRatio.setContextValue(wizardParams, getContextValues(QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY, true));
        setSystemFluidSecondaryMixRatioUnknown(getContextValues(QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_KEY, true));
        WizardParamEnum.SystemFluidSecondaryMixRatioUnknown.setContextValue(wizardParams, getContextValues(QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_KEY, true));
        setEnergyMeasurement(getContextValues(QUESTION_ENERGY_MEASUREMENT_KEY, true));
        WizardParamEnum.EnergyMeasurement.setContextValue(wizardParams, getContextValues(QUESTION_ENERGY_MEASUREMENT_KEY, true));
        setStainless(getContextValues(QUESTION_STAINLESS_KEY, true));
        WizardParamEnum.Stainless.setContextValue(wizardParams, getContextValues(QUESTION_STAINLESS_KEY, true));
        setLimitedSpace(getContextValues(QUESTION_LIMITED_SPACE_KEY, true));
        WizardParamEnum.Stainless.setContextValue(wizardParams, getContextValues(QUESTION_STAINLESS_KEY, true));
        setDynamicControlValve(getContextValues(QUESTION_DYNAMIC_CONTROL_VALVE_KEY, true));
        WizardParamEnum.DynamicControlValve.setContextValue(wizardParams, getContextValues(QUESTION_DYNAMIC_CONTROL_VALVE_KEY, true));
        setPriorities(getContextValues(QUESTION_PRIORITIES, true));
        WizardParamEnum.Priorities.setContextValue(wizardParams, getContextValues(QUESTION_PRIORITIES, true));
        setModified(false)

        setUpdate(!update)
        setResetVal(true);
    }


    const resetValuesSaved = () => {
        setAppSource(orgAppSource);
        WizardParamEnum.Source.setContextValue(wizardParams, orgAppSource);
        setSourceFlow(orgSourceFlow);
        WizardParamEnum.SourceFlow.setContextValue(wizardParams, orgSourceFlow);
        setApplication(orgApplication);
        WizardParamEnum.Application.setContextValue(wizardParams, orgApplication);
        setApplicationIntent(orgApplicationIntent);
        WizardParamEnum.ApplicationIntent.setContextValue(wizardParams, orgApplication);
        setApplicationSize(orgApplicationSize);
        WizardParamEnum.ApplicationSize.setContextValue(wizardParams, orgApplicationSize);
        setDimensionable(orgDimensionable);
        WizardParamEnum.OnlyDimensionable.setContextValue(wizardParams, orgDimensionable);
        setSecondaryFlow(orgSecondaryFlow);
        WizardParamEnum.SecondaryFlow.setContextValue(wizardParams, orgSecondaryFlow);
        setSecondarySpecFlow(orgSecondarySpecFlow);
        WizardParamEnum.SecondaryFlowSpecVal.setContextValue(wizardParams, orgSecondarySpecFlow);
        setDrivingPressure(orgDrivingPressure);
        WizardParamEnum.AvailDrivingPressure.setContextValue(wizardParams, orgDrivingPressure);
        setDPIsVariable(orgDpIsVariable);
        WizardParamEnum.VariableAvailDrivingPressure.setContextValue(wizardParams, orgDpIsVariable);
        setDPSpecValue(orgdpSpecValue);
        WizardParamEnum.SpecificAvailDrivingPressure.setContextValue(wizardParams, orgdpSpecValue);
        setHasMainPump(orgHasMainPump);
        WizardParamEnum.HasMainPump.setContextValue(wizardParams, orgHasMainPump);
        setEnviromentCert(orgEnviromentCert);
        WizardParamEnum.Certifications.setContextValue(wizardParams, orgEnviromentCert);
        setSystemFluid(orgSystemFluid);
        WizardParamEnum.SystemFluidMain.setContextValue(wizardParams, orgSystemFluid);
        setSystemFluidMixRatio(orgSystemFluidMixRatio);
        setSystemFluidMixRatioUnknown(orgSystemFluidMixRatioUnknown);
        setSystemFluidPrimary(orgSystemFluidPrimary);
        WizardParamEnum.SystemFluidPrimary.setContextValue(wizardParams, orgSystemFluidPrimary);
        setSystemFluidPrimaryMixRatio(orgSystemFluidPrimaryMixRatio);
        WizardParamEnum.SystemFluidPrimaryMixRatio.setContextValue(wizardParams, orgSystemFluidPrimaryMixRatio);
        setSystemFluidPrimaryMixRatioUnknown(orgSystemFluidPrimaryMixRatioUnknown);
        WizardParamEnum.SystemFluidPrimaryMixRatioUnknown.setContextValue(wizardParams, orgSystemFluidPrimaryMixRatioUnknown);
        setSystemFluidSecondary(orgSystemFluidSecondary);
        WizardParamEnum.SystemFluidSecondary.setContextValue(wizardParams, orgSystemFluidSecondary);
        setSystemFluidSecondaryMixRatio(orgSystemFluidSecondaryMixRatio);
        WizardParamEnum.SystemFluidSecondaryMixRatio.setContextValue(wizardParams, orgSystemFluidSecondaryMixRatio);
        setSystemFluidSecondaryMixRatioUnknown(orgSystemFluidSecondaryMixRatioUnknown);
        WizardParamEnum.SystemFluidSecondaryMixRatioUnknown.setContextValue(wizardParams, orgSystemFluidSecondaryMixRatioUnknown);
        setEnergyMeasurement(orgEnergyMeasurement);
        WizardParamEnum.EnergyMeasurement.setContextValue(wizardParams, orgEnergyMeasurement);
        setStainless(orgStainless);
        WizardParamEnum.Stainless.setContextValue(wizardParams, orgStainless);
        setStainless(orgDynamicControlValve);
        WizardParamEnum.DynamicControlValve.setContextValue(wizardParams, orgDynamicControlValve);
        setLimitedSpace(orgLimitedSpace);
        WizardParamEnum.LimitedSpace.setContextValue(wizardParams, orgLimitedSpace);
        setPriorities(orgPriorities);
        WizardParamEnum.Priorities.setContextValue(wizardParams, orgPriorities);
        setModified(false)

        setUpdate(!update)
    }

    const clearContexts = () => {
        //console.log("Clear ConnectionList")
        if (SessionParamsEnum.IsApiSession.getContextValue(sessionParams) !== true) {
            DriftParamEnum.clearData(driftParams);
        }
        ProductLineEnum.ProductGreenLevel.clearData(productLine);
        AdjustmentsEnum.clearData(adjustParams);
        CalcParamsEnum.clearData(calcParams);

        PumpParamEnum.clearData(pumpParams);
        ShuntParamsEnum.clearData(shuntParams);

        errorContext.dispatch({ type: 'clearErrorList' })
        noticeContext.dispatch({ type: 'clearNoticeList' })
    }

    const setCurrent = (choosen) => {
        if (doubleClickBlock === false) {
            let index = selected.findIndex(item => item.id === choosen.id);
            if (index !== -1) {
                let newList = selected;
                newList.splice(index, 1);
                setSelected(newList);
            }
            else {
                setSelected((prev) => [...prev, choosen]);
            }

            /*
            ProductLineEnum.ProductPrincip.setContextValue(productLine, choosen.id);
    
            if (choosen?.type != null) {
                ProductLineEnum.ProductLine.setContextValue(productLine, choosen.type);
            }
            else {
                ProductLineEnum.ProductLine.setContextValue(productLine, 'standard');
            }
            console.log("Choosen: ", choosen)*/

            /*
            clearShuntData(database, sessionParams).then(() => {
                //console.log("Done");
            });
            if (SessionParamsEnum.IsApiSession.getContextValue(sessionParams) !== true){
                clearDriftData(database, sessionParams).then(() => {
                    //console.log("Done Drift");
                });
            }
            clearCalcData(database, sessionParams).then(() => {
                //console.log("Done");
            });*/

            //SessionParamsEnum.MaxStep.setContextValue(sessionParams, 0, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));

            setUpdate(!update)
        }



    }

    /**
     * 
     * @param {import("../../../constants/fullConnOptions/types").ShuntConnectionInterface} document 
     */
    const doubleClickType = (document) => {
        //console.log("Doubleclick: ", document)
        let prodLines = document.InfoData.prodlines.filter((item) => filterLines(document, item));

        if (document?.FilterData?.dimensionable === true && prodLines.length === 1) {
            setCurrentSelected(document);
            clickAndContinue(document, prodLines[0])
        }
        else {
            if (prodLines.length > 0) {
                setCurrentSelected(document);
                handleSelectOpen();
            }
        }

    }

    const handleConfirmChange = async () => {
        let oldProdPrinciple = ProductLineEnum.ProductPrincip.getContextValue(productLine);
        clearContexts();
        let prodPrinciple = selectedPLPrinciple
        if (selectedPLLine === PRODUCTLINE_FLEX || selectedPLLine === PRODUCTLINE_SMART) {
            prodPrinciple += "-" + selectedPLLine;
        }

        resetMaxStepFunc(sessionParams, database);
        //SessionParamsEnum.MaxStep.setContextValue(sessionParams, 1, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
        ProductLineEnum.ProductPrincip.setContextValue(productLine, prodPrinciple);

        if (selectedPLLine !== PRODUCTLINE_GREEN_I && selectedPLLine !== PRODUCTLINE_GREEN_II) {
            ProductLineEnum.ProductLine.setContextValue(productLine, selectedPLLine);
        }
        else {
            ProductLineEnum.ProductLine.setContextValue(productLine, PRODUCTLINE_GREEN);
        }

        clearShuntData(database, sessionParams).then(() => {
            //console.log("Done shuntdata");
        });
        if (SessionParamsEnum.IsApiSession.getContextValue(sessionParams) !== true) {
            clearDriftData(database, sessionParams).then(() => {
                //console.log("Done Drift");
            });
        }
        clearCalcData(database, sessionParams).then(() => {
            //console.log("Done calcData");
        });

        // @ts-ignore
        DriftParamEnum.clearDataForNewShunt(driftParams, database, prodPrinciple, oldProdPrinciple, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams), wizardParams);

        if (SessionParamsEnum.CurrentStep.getContextValue(sessionParams) === 0 || SessionParamsEnum.CurrentStep.getContextValue(sessionParams) == null) {
            SessionParamsEnum.CurrentStep.setContextValue(sessionParams, 1, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
        }

        if (ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) == null || ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) === -1) {
            let pos = await getCurrentHighPosition(database, sessionParams);
            ShuntParamsEnum.ShuntPosition.setContextValue(shuntParams, pos + 1, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams), SessionParamsEnum.SessionID.getContextValue(sessionParams));
        }

        if (hasMainPump === false) {
            DriftParamEnum.HasMainPump.setContextValue(driftParams, '0')
        }
        else if (hasMainPump === true && DriftParamEnum.HasMainPump.getContextValue(driftParams) === '0') {
            DriftParamEnum.HasMainPump.setContextValue(driftParams, '1')
        }

        if (selectedPLLine !== PRODUCTLINE_GREEN_I && selectedPLLine !== PRODUCTLINE_GREEN_II) {
            saveProductLineDataInputs(database, sessionParams, selectedPLLine, prodPrinciple);
        }
        else {
            saveProductLineDataInputs(database, sessionParams, PRODUCTLINE_GREEN, prodPrinciple);
        }
        handleCloseConfirmChangeModal();
        handleClose();
        navigate("/stepper#step2");
    }

    /**
     * 
     * @param {import("../../../constants/fullConnOptions/types").ShuntConnectionInterface} item 
     * @param {import("../../../constants/fullConnOptions/types").ProdLinesType} prodLine 
     */
    const clickAndContinue = async (item, prodLine) => {
        if (item?.FilterData?.dimensionable === true) {
            if (SessionParamsEnum.MaxStep.getContextValue(sessionParams) >= 1 && (prodLine !== ProductLineEnum.ProductLine.getContextValue(productLine) || item.id !== ProductLineEnum.ProductPrincip.getContextValue(productLine))) {
                setSelectedPLLine(prodLine);
                setSelectedPLPrinciple(item?.id);
                handleOpenConfirmChangeModal();
            }
            else {
                clearContexts();
                let prodPrinciple = item.id;
                if (prodLine === PRODUCTLINE_FLEX || prodLine === PRODUCTLINE_SMART) {
                    prodPrinciple += "-" + prodLine;
                }

                ProductLineEnum.ProductPrincip.setContextValue(productLine, prodPrinciple);

                if (prodLine !== PRODUCTLINE_GREEN_I && prodLine !== PRODUCTLINE_GREEN_II) {
                    ProductLineEnum.ProductLine.setContextValue(productLine, prodLine);
                }
                else {
                    ProductLineEnum.ProductLine.setContextValue(productLine, PRODUCTLINE_GREEN);
                }


                clearShuntData(database, sessionParams).then(() => {
                    //console.log("Done shuntdata");
                });
                if (SessionParamsEnum.IsApiSession.getContextValue(sessionParams) !== true) {
                    clearDriftData(database, sessionParams).then(() => {
                        //console.log("Done Drift");
                    });
                }
                clearCalcData(database, sessionParams).then(() => {
                    //console.log("Done calcData");
                });

                if (SessionParamsEnum.CurrentStep.getContextValue(sessionParams) === 0 || SessionParamsEnum.CurrentStep.getContextValue(sessionParams) == null) {
                    SessionParamsEnum.CurrentStep.setContextValue(sessionParams, 1, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
                }

                if (ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) == null || ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) === -1) {
                    let pos = await getCurrentHighPosition(database, sessionParams);
                    ShuntParamsEnum.ShuntPosition.setContextValue(shuntParams, pos + 1, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams), SessionParamsEnum.SessionID.getContextValue(sessionParams));
                }

                if (hasMainPump === false) {
                    DriftParamEnum.HasMainPump.setContextValue(driftParams, '0')
                }
                else if (hasMainPump === true && DriftParamEnum.HasMainPump.getContextValue(driftParams) === '0') {
                    DriftParamEnum.HasMainPump.setContextValue(driftParams, '1')
                }

                if (prodLine !== PRODUCTLINE_GREEN_I && prodLine !== PRODUCTLINE_GREEN_II) {
                    saveProductLineDataInputs(database, sessionParams, prodLine, prodPrinciple);
                }
                else {
                    saveProductLineDataInputs(database, sessionParams, PRODUCTLINE_GREEN, prodPrinciple);
                }

                handleClose();
                navigate("/stepper#step2");
            }
        }
        else {
            setCurrentSelected(item);
            handleSelectOpen();
        }
    }

    /**
     * 
     * @param {import("../../../constants/fullConnOptions/types").ShuntType} itemID 
     * @returns 
     */
    const filterList = (itemID) => {
        let displayItem = true;
        let item = connectionTypeList[itemID];
        let selectedIndex = selected.findIndex((obj) => obj.id === itemID);


        let enabledSources = [];
        for (const key in appSource) {
            if (Object.hasOwnProperty.call(appSource, key)) {
                const isSet = appSource[key];
                if (isSet) {
                    enabledSources.push(key);
                }
            }
        }

        if (enabledSources.length === 1 && APP_SOURCE[enabledSources[0]]?.specifyFlow === true) {
            if (item?.FilterData?.sourceFlow != null) {
                if (item?.FilterData?.sourceFlow.includes(sourceFlow) === false) {
                    displayItem = false;
                }
            }
        }

        if (enabledSources.length <= item?.FilterData?.numOfSources) {
            if (item?.FilterData?.sourceOptions != null) {
                let requiredFound = false;
                if (item?.FilterData?.sourceOptionRequired != null && ((enabledSources.length > 1 && item?.FilterData?.combination === true) || item?.FilterData?.combination !== true)) {
                    for (let index = 0; index < enabledSources.length; index++) {
                        const element = enabledSources[index];
                        // @ts-ignore
                        if (item?.FilterData?.sourceOptionRequired?.includes(element) === true) {
                            requiredFound = true;
                        }
                    }
                }
                else {
                    requiredFound = true
                }

                if (requiredFound === true) {
                    for (let index = 0; index < enabledSources.length; index++) {
                        const element = enabledSources[index];
                        // @ts-ignore
                        if (item?.FilterData?.sourceOptions?.includes(element) === false) {
                            displayItem = false;
                        }
                    }
                }
                else {
                    displayItem = false;
                }

            }
        }
        else {
            displayItem = false;
        }

        if (displayItem === true) {
            if (limitedSpace === false && item.FilterData?.limitedSpace === true) {
                displayItem = false;
            }
        }

        if (displayItem === true) {
            if (application != null && application !== "" && item.FilterData?.appSource != null) {
                /*
                if (item.appSource.includes(application) === false) {
                    displayItem = false;
                }*/
            }

            if (applicationIntent != null && applicationIntent !== "" && item.FilterData?.appSource != null) {
                if (item.FilterData?.appSource.includes(applicationIntent) === false) {
                    displayItem = false;
                }
            }
            else if (item.id === 'PBCS') {
                displayItem = false;
            }
        }


        if (displayItem === true) {
            if (dimensionable === true && item.FilterData?.dimensionable === false) {
                displayItem = false;
            }
        }

        if (displayItem === true) {
            if (drivingPressure != null && drivingPressure !== "" && drivingPressure !== 'unknown') {
                if (drivingPressure === 'specify' && dpSpecValue != null) {
                    if (Number(item.FilterData?.lowestDPValue) > Number(dpSpecValue)) {
                        displayItem = false;
                    }
                }
                else if (AVAILABLE_DRIVE_PRESSURE[drivingPressure]?.level < item.FilterData?.lowestDPClass?.level) {
                    displayItem = false;
                }
            }
        }

        if (displayItem === true) {
            if (hasMainPump === false || hasMainPump === 'false') {
                if (item.FilterData?.needMainPump === true) {
                    displayItem = false;
                }
                else if (item.FilterData?.lowestDPClass?.level > 0) {
                    displayItem = false;
                }
            }
        }

        if (displayItem === true) {
            if (currentTab === 2) {
                if (isSelected(item.id) === false) {
                    displayItem = false;
                }
            }
        }

        if (displayItem === true) {
            // @ts-ignore
            let prodLines = item?.InfoData.prodlines.filter((item) => filterLines(document, item));
            if (prodLines.length < 1) {
                displayItem = false;
            }
        }

        if (displayItem === true) {
            if (systemFluid === SYSTEM_FLUID.different.value) {
                if (item?.FilterData?.exchange !== true) {
                    displayItem = false;
                }
            }

        }

        if (displayItem === true) {
            if (secondaryFlow != null && secondaryFlow !== '') {
                if (Number(secondaryFlow) > 0) {
                    if (item?.FilterData?.minFlowLevel != null) {
                        if (secondaryFlow < Number(item?.FilterData?.minFlowLevel)) {
                            displayItem = false;
                        }
                    }

                    if (item?.FilterData?.maxFlowLevel != null) {
                        if (secondaryFlow > Number(item?.FilterData?.maxFlowLevel)) {
                            displayItem = false;
                        }
                    }
                }
                else if (Number(secondaryFlow) === -1) {
                    if (secondarySpecFlow != null && secondarySpecFlow !== "" && isNaN(secondarySpecFlow) === false) {
                        let value = Number(secondarySpecFlow);

                        if (item.FilterData?.minFlow != null) {
                            if (value != null && value < Number(item?.FilterData?.minFlow)) {
                                displayItem = false;
                            }
                        }
                        if (item.FilterData?.maxFlow != null) {
                            if (value != null && value > Number(item?.FilterData?.maxFlow)) {
                                displayItem = false;
                            }
                        }
                    }
                }
                else if (Number(secondaryFlow) === -2) {
                    //console.log("Unknown Flow!")
                }
                else {
                    //console.log("Wrong value: ", secondaryFlow)
                }

                /* 
                let low;
                let high;

                if (secondaryFlow.startsWith('<')) {
                    low = 0;
                    high = Number(secondaryFlow.replace(',', '.').substring(1, secondaryFlow.length));
                    //console.log("Low: - High: ", high);

                }
                else {
                    let vals = secondaryFlow.split('-');
                    if (vals.length === 2) {
                        low = Number(vals[0].replace(',', '.'))
                        high = Number(vals[1].replace(',', '.'));

                        //console.log("Low: ", low, "High: ", high);
                    }
                }

                if (low != null && low > Number(item?.maxFlow)) {
                    displayItem = false;
                }*/
            }
        }

        if (displayItem === false && selectedIndex >= 0) {
            let newList = selected;
            newList.splice(selectedIndex, 1);
            setSelected(newList);
        }

        return displayItem;
    }

    /**
     * 
     * @param {import("../../../constants/fullConnOptions/types").ShuntConnectionInterface} connType 
     * @param {import("../../../constants/fullConnOptions/types").ProdLinesType} item 
     * @returns 
     */
    const filterLines = (connType, item) => {
        let isAvailable = true;
        let prodData = PRODUCT_LINE_DATA[item];

        if (prodData != null) {
            if (enviromentCert[ENVIROMENT_CERTIFICATIONS.sundaHus.value] === true && prodData.cert[ENVIROMENT_CERTIFICATIONS.sundaHus.value] !== true) {
                isAvailable = false;
            }
            if (enviromentCert[ENVIROMENT_CERTIFICATIONS.byggVaru.value] === true && prodData.cert[ENVIROMENT_CERTIFICATIONS.byggVaru.value] !== true) {
                isAvailable = false;
            }
            if (enviromentCert[ENVIROMENT_CERTIFICATIONS.EPD.value] === true && prodData.cert[ENVIROMENT_CERTIFICATIONS.EPD.value] !== true) {
                isAvailable = false;
            }

            if (isAvailable === false) return isAvailable;

            if (secondaryFlow != null && secondaryFlow !== '') {

                if (Number(secondaryFlow) > 0) {
                    if (prodData?.minFlowLevel != null) {
                        if (secondaryFlow < Number(prodData?.minFlowLevel)) {
                            isAvailable = false;
                        }
                        if (isAvailable === false) return isAvailable;
                    }

                    if (prodData?.maxFlowLevel != null) {
                        if (secondaryFlow > Number(prodData?.maxFlowLevel)) {
                            isAvailable = false;
                        }
                        if (isAvailable === false) return isAvailable;
                    }

                    if (connType?.FilterData?.exchange === true && prodData?.maxFlowLevelExchange != null) {
                        if (secondaryFlow > Number(prodData?.maxFlowLevelExchange)) {
                            isAvailable = false;
                        }
                        if (isAvailable === false) return isAvailable;
                    }
                }
                else if (Number(secondaryFlow) === -1) {
                    if (secondarySpecFlow != null && secondarySpecFlow !== "" && isNaN(secondarySpecFlow) === false) {
                        let value = Number(secondarySpecFlow);

                        if (prodData.minFlow != null) {
                            if (value != null && value < Number(prodData?.minFlow)) {
                                isAvailable = false;
                            }
                        }
                        if (isAvailable === false) return isAvailable;
                        if (prodData.maxFlow != null) {
                            if (value != null && value > Number(prodData?.maxFlow)) {
                                isAvailable = false;
                            }
                        }
                        if (isAvailable === false) return isAvailable;
                        if (connType?.FilterData?.exchange === true && prodData?.maxFlowExchange != null) {
                            if (value > Number(prodData?.maxFlowExchange)) {
                                isAvailable = false;
                            }
                        }

                        if (isAvailable === false) return isAvailable;
                    }
                }
                else if (Number(secondaryFlow) === -2) {
                    //console.log("(PLine) Unknown Flow!", "Conn: ", connType?.exchange)
                }
                else {
                    console.log("(PLine) Wrong value: ", secondaryFlow)
                }
                /*
                let low;
                let high;

                if (secondaryFlow === 'Specifikt värde') {
                    if (secondarySpecFlow != null && secondarySpecFlow !== "" && isNaN(secondarySpecFlow) === false) {
                        low = Number(secondarySpecFlow);
                        high = Number(secondarySpecFlow);
                    }
                }
                else if (secondaryFlow.startsWith('<')) {
                    low = 0;
                    high = Number(secondaryFlow.replace(',', '.').substring(1, secondaryFlow.length));
                    //console.log("Low: - High: ", high);
                }
                else {
                    let vals = secondaryFlow.split('-');
                    if (vals.length === 2) {
                        low = Number(vals[0].replace(',', '.'))
                        high = Number(vals[1].replace(',', '.'));

                        //console.log("Low: ", low, "High: ", high);
                    }
                }

                if (prodData.minFlow != null) {
                    if (high != null && high < Number(prodData?.minFlow)) {
                        isAvailable = false;
                    }
                }
                if (isAvailable === false) return isAvailable;
                if (prodData.maxFlow != null) {
                    if (low != null && low > Number(prodData?.maxFlow)) {
                        isAvailable = false;
                    }
                }*/
            }
        }

        if (drivingPressure === AVAILABLE_DRIVE_PRESSURE.specify.value && dpSpecValue != null) {
            if (prodData?.lowDP != null && dpSpecValue < prodData?.lowDP) {
                isAvailable = false;
            }
        }
        else if (drivingPressure != null && prodData?.lowestDPClass != null) {
            let dpVal = AVAILABLE_DRIVE_PRESSURE[drivingPressure];
            if (dpVal != null && dpVal.level != null) {
                if (dpVal.level < prodData?.lowestDPClass.level) {
                    isAvailable = false;
                }
            }
        }

        if (isAvailable === false) return isAvailable;

        if ((hasMainPump === false || hasMainPump === 'false') && (item === PRODUCTLINE_FLEX || item === PRODUCTLINE_SMART)) {
            isAvailable = false;
        }

        if (isAvailable === false) return isAvailable;

        if (dynamicControlValve === true && item !== PRODUCTLINE_FLEX) {
            isAvailable = false;
        }

        if (isAvailable === false) return isAvailable;

        if (energyMeasurement === ENERGY_MEASUREMENT_OPTIONS.controlValve.value) {
            if (item !== PRODUCTLINE_SMART) {
                isAvailable = false;
            }
        }
        else if (energyMeasurement === ENERGY_MEASUREMENT_OPTIONS.pump.value || energyMeasurement === ENERGY_MEASUREMENT_OPTIONS.dedicated.value) {
            if (item === PRODUCTLINE_SMART) {
                isAvailable = false;
            }
        }

        return isAvailable;
    }

    const displayInfoText = () => {
        if (currentTab === 1) {
            return INITIAL_WIZARD_INFO_TEXT;
            /*
            if (selected.length > 0) {
                return WIZARD_TAB1_SELECTED_TEXT;
            }
            else {
                return INITIAL_WIZARD_INFO_TEXT;
            }*/
        }
        else {
            return "This is also some really important text!"
        }

    }

    const handleNext = () => {
        setCurrentTab(2);
    }

    const handleBack = () => {
        setCurrentTab(1);
    }

    const isDisabled = () => {
        return selected.length < 1;
    }

    return (
        <>
            {user?.isAnonymous !== true && user?.isInternal === true ? (
                <SparkleWizardButton handleOpen={handleOpen} />
            ) : ("")}

            <Modal
                aria-labelledby="auto-product-wizard"
                aria-describedby="wizard-to-select-product"
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                slotProps={{ backdrop: { timeout: 500 } }}
            >
                <Box sx={style}>
                    <Box sx={{ width: '100%', height: '100%', borderRadius: '1px' }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'start'}>
                            <Typography id="transition-modal-title" component="h2" variant='h2' fontWeight="bold" className="header-title" sx={{ color: "#183851" }}>
                                Produktvägledning
                            </Typography>
                            <IconButton onClick={handleClose} size="small" sx={{ height: '30px', marginTop: '-18px', marginRight: '-18px' }}>
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} height={'100%'}>
                            <Paper sx={{ paddingTop: '20px', paddingLeft: '15px', width: '43%', height: '94%', borderRadius: '1px' }}>
                                <Stack justifyContent={'space-between'} height={'100%'} >
                                    <Box sx={{ overflowY: 'auto' }}>
                                        {currentTab === 1 ? (
                                            <WizardTab1Questions
                                                answerQuestion={answerQuestion}
                                                source={appSource}
                                                sourceFlow={sourceFlow}
                                                application={application}
                                                applicationIntent={applicationIntent}
                                                applicationSize={applicationSize}
                                                secondaryFlow={secondaryFlow}
                                                secondarySpecFlow={secondarySpecFlow}
                                                dimensionable={dimensionable}
                                                drivingPressure={drivingPressure}
                                                dpIsVariable={dpIsVariable}
                                                dpSpecValue={dpSpecValue}
                                                hasMainPump={hasMainPump}
                                                enviromentCert={enviromentCert}
                                                systemFluid={systemFluid}
                                                systemFluidMixRatio={systemFluidMixRatio}
                                                systemFluidMixRatioUnknown={systemFluidMixRatioUnknown}
                                                systemFluidPrimary={systemFluidPrimary}
                                                systemFluidPrimaryMixRatio={systemFluidPrimaryMixRatio}
                                                systemFluidPrimaryMixRatioUnknown={systemFluidPrimaryMixRatioUnknown}
                                                systemFluidSecondary={systemFluidSecondary}
                                                systemFluidSecondaryMixRatio={systemFluidSecondaryMixRatio}
                                                systemFluidSecondaryMixRatioUnknown={systemFluidSecondaryMixRatioUnknown}
                                                limitedSpace={limitedSpace}
                                                energyMeasurment={energyMeasurement}
                                                stainless={stainless}
                                                dynamicControlValve={dynamicControlValve}
                                                resetVal={resetVal}
                                            />
                                        ) : (
                                            <WizardTab2Questions
                                                answerQuestion={answerQuestion}
                                                priorities={priorities}
                                                resetVal={resetVal}
                                            />
                                        )}
                                    </Box>



                                    <Stack padding={'5px'} direction={'row'} alignItems={'flex-end'} justifyContent={'space-between'}>


                                        {currentTab === 2 ? (
                                            <Box>
                                                <IconButton size="small" onClick={handleBack} disabled={isDisabled()} sx={{ fontSize: '25px', color: '#0053A1' }}>
                                                    <ArrowCircleLeftIcon />
                                                </IconButton>
                                            </Box>
                                        ) : (
                                            <Tooltip arrow title={'Återställ val'}>
                                                <span>
                                                    <IconButton disabled={modified === false} onClick={handleResetValue}>
                                                        <RestartAltIcon />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        )}
                                        <Box width={'100%'} paddingRight={'8px'} display={'flex'} justifyContent={'center'} >
                                            <Typography textAlign={'center'} fontSize={'.75rem'} color={'#183851'}>
                                                {displayInfoText()}
                                            </Typography>
                                        </Box>
                                        {/*(currentTab === 1 && isDisabled() === false) ? (
                                            <Box>
                                                <IconButton size="small" onClick={handleNext} disabled={isDisabled()} sx={{ fontSize: '25px', color: '#0053A1' }}>
                                                    <ArrowCircleRightIcon />
                                                </IconButton>
                                            </Box>
                                        ) : (
                                            <Box width={'77px'}></Box>
                                        )*/}
                                        <Box width={'77px'}></Box>
                                    </Stack>
                                </Stack>

                            </Paper>
                            <Box sx={{ paddingLeft: '20px', width: '70%', height: '95%' }}>
                                <Box sx={{ backgroundColor: 'white', width: '100%', height: '100%', border: '2px solid #06559e', marginTop: '3px', borderRadius: '3px', overflowY: 'auto' }}>
                                    {Object.keys(connectionTypeList).filter(filterList).length > 0 ? (
                                        <>
                                            {Object.keys(connectionTypeList).filter(filterList).map((documentID, index) => {
                                                return (
                                                    <div key={documentID}>
                                                        <CompactConnectionCard
                                                            index={index}
                                                            connectionObj={connectionTypeList[documentID]}
                                                            isSelected={isSelected(documentID)}
                                                            currentSelected={documentID === currentSelected?.id}
                                                            filterLines={filterLines} onClick={() => { setCurrent(connectionTypeList[documentID]) }}
                                                            onDoubleClick={() => { doubleClickType(connectionTypeList[documentID]) }}
                                                            clickAndContinue={clickAndContinue}
                                                            selectedList={selected}
                                                        />
                                                        <Divider />
                                                    </div>

                                                )
                                            })}
                                        </>
                                    ) : (
                                        <Box height={'100%'} fontSize={'20px'} color={'gray'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                            <i>Inga matchande alternativ finns!</i>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Stack>
                    </Box>
                </Box >
            </Modal >

            <SelectProdLinePopup openModal={openSelectModal} handleClose={handleSelectClose} selected={currentSelected} filterLines={filterLines} clickAndContinue={clickAndContinue} />

            <ConfirmChangedPrinciple openModal={openConfirmChange} handleModalClose={handleCloseConfirmChangeModal} handleContinue={handleConfirmChange} />
        </>
    )
}
