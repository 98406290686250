import { ref, set } from "firebase/database";
import { SESSION_SHUNT_PATH, SHUNT_ADJUSTDATA } from "../constants/firebasePaths";
import { ACTUATORS_KEY, ADJUSTVALUE_KEY, CABINET_KEY, CONNECTED_DIFF_KEY, CONNECTED_TEMP_KEY, CONNECTION_DESIGN_KEY, CONNECTION_VALUE_KEY, CUTOFF_KEY, DRIPPLATE_KEY, ENERGYMEASUREMENT_KEY, INSULATION_KEY, MANOMETER_KEY, MATERIAL_KEY, MIXINGVESSELS_KEY, STAND_KEY, THERMO_KEY } from "../constants/keys/adjustParamsKeys";
import { ContextEnum } from "./contextEnum";

export class AdjustmentsEnum extends ContextEnum {
    static ConnectionDesign = new AdjustmentsEnum(CONNECTION_DESIGN_KEY, "setConnectionDesign");
    static ConnectionValue = new AdjustmentsEnum(CONNECTION_VALUE_KEY, "setConnectionValue");
    static MaterialValue = new AdjustmentsEnum(MATERIAL_KEY, "setMaterialValue");
    static ThermoValue = new AdjustmentsEnum(THERMO_KEY, "setThermoValue");
    static IsolationValue = new AdjustmentsEnum(INSULATION_KEY, "setIsolationValue");
    static CutOffValue = new AdjustmentsEnum(CUTOFF_KEY, "setCutOffValue");
    static ManometerValue = new AdjustmentsEnum(MANOMETER_KEY, "setManometerValue");
    static StandValue = new AdjustmentsEnum(STAND_KEY, "setStandValue");
    static CabinetValue = new AdjustmentsEnum(CABINET_KEY, "setCabinetValue");
    static ActuatorsValue = new AdjustmentsEnum(ACTUATORS_KEY, "setActuatorsValue");
    static DripPlateValue = new AdjustmentsEnum(DRIPPLATE_KEY, "setDripPlateValue");
    static AdjustValue = new AdjustmentsEnum(ADJUSTVALUE_KEY, "setAdjustValue");
    static MixingVessels = new AdjustmentsEnum(MIXINGVESSELS_KEY, "setMixingVessels");
    static EnergyMeasurement = new AdjustmentsEnum(ENERGYMEASUREMENT_KEY, "setEnergyMeasurement");

    static ConnectedTemp = new AdjustmentsEnum(CONNECTED_TEMP_KEY, "setConnTemp");
    static ConnectedDiff = new AdjustmentsEnum(CONNECTED_DIFF_KEY, "setConnDiff");

    static setDefaultValues = function setDefaultValues(context, defaultObject) {
        context.dispatch({ type: "setDefaultValues", payload: defaultObject})
    }

    static clearConnectedAddons = function clearConnectedAddons(context) {
        context.dispatch({ type: "clearConnectedAddons" })
    }

    setToFirebase(database, shuntID, value){
        const dbRef = ref(database, SESSION_SHUNT_PATH + shuntID + SHUNT_ADJUSTDATA + this.name);
        return set(dbRef, value);
    }
}