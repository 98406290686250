import { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IntroDimension } from '../../../constants/introductionTexts/introDimension';
import { IntroHandling } from '../../../constants/introductionTexts/introHandling';
import { News } from '../../../constants/introductionTexts/introNews';
import { IntroOverviewAndNavigation } from '../../../constants/introductionTexts/introOverview';
import { NewsVersion_1_6 } from '../../../constants/introductionTexts/introNews_1_6';
import { IntroAccount } from '../../../constants/introductionTexts/introAccount';

export default function IntroductionAccordion() {
    const [expanded, setExpanded] = useState('');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : '');
    };

    return (
        <div>
            <Accordion expanded={expanded === 'news-1.6'} onChange={handleChange('news-1.6')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={{ width: '53%', flexShrink: 0, fontWeight: 'bold', color: 'rgb(24, 56, 81)' }}>
                        Nyheter i ShuntLogik<sup>®</sup> 1.6
                    </Typography>

                </AccordionSummary>
                <AccordionDetails>
                    <Typography component={'div'}>
                        <NewsVersion_1_6 />
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'news-1.5'} onChange={handleChange('news-1.5')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={{ width: '53%', flexShrink: 0, fontWeight: 'bold', color: 'rgb(24, 56, 81)' }}>
                        Nyheter i ShuntLogik<sup>®</sup> 1.5
                    </Typography>

                </AccordionSummary>
                <AccordionDetails>
                    <Typography component={'div'}>
                        <News />
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography sx={{ width: '53%', flexShrink: 0, fontWeight: 'bold', color: 'rgb(24, 56, 81)' }}>Dimensionera shuntgrupper med ShuntLogik<sup>®</sup></Typography>

                </AccordionSummary>
                <AccordionDetails>
                    <Typography component={'div'}>
                        <IntroDimension />
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                >
                    <Typography sx={{ width: '53%', flexShrink: 0, fontWeight: 'bold', color: 'rgb(24, 56, 81)' }}>
                        Översikt och navigering mellan positioner
                    </Typography>

                </AccordionSummary>
                <AccordionDetails>
                    <Typography component={'div'}>
                        <IntroOverviewAndNavigation />
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Typography sx={{ width: '53%', flexShrink: 0, fontWeight: 'bold', color: 'rgb(24, 56, 81)' }}>Hantering av körningar/sessioner</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography component={'div'}>
                        <IntroHandling />
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'account'} onChange={handleChange('account')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Typography sx={{ width: '53%', flexShrink: 0, fontWeight: 'bold', color: 'rgb(24, 56, 81)' }}>Konto och Mina sidor</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography component={'div'}>
                        <IntroAccount />
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}