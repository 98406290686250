import { Paper, Tabs, Tab } from "@mui/material"
import { useEffect, useState } from "react";
import { SystemSpecPricing } from "./systemSpecPricing";
import { SystemSpecAdjustValve } from "./systemSpecAdjustValve";
import { SystemSpecControlValve } from "./systemSpecControlValve";
import { SystemSpecMeasurementData } from "./systemSpecMeasurement";

export const SystemSpecOverview = () => {
    const [value, setValue] = useState(0)

    const handleChange = (event) => {
        //console.log("Value:", event.target.tabIndex);
        setValue(event.target.tabIndex)
    }

    const tabPanel = () => {
        switch (value) {
            case 0:
                return (
                    <SystemSpecAdjustValve />
                );
            case 1:
                return (
                    <SystemSpecControlValve />
                );
            case 2:
                return (
                    <SystemSpecMeasurementData />
                );
            case 3:
                return (
                    <SystemSpecPricing />
                );
            default:
                break;
        }
    }

    return (
        <Paper className='overview-main-panel'>
            <div className="overview-content-size">
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Injusteringsventiler" tabIndex={0} />
                    <Tab label="Styrventiler" tabIndex={1} />
                    <Tab label="Mått" tabIndex={2} />
                    {/*<Tab label="Priser" tabIndex={3} /> */}
                </Tabs>
                <div>
                    {tabPanel()}
                </div >
            </div>
        </Paper >
    );
}