import { FormControl, Select, MenuItem, Stack, Checkbox, FormControlLabel, TextField, InputLabel } from "@mui/material"
import { AVAILABLE_DRIVE_PRESSURE, QUESTION_DRIVING_PRESSURE_IS_VARIABLE_KEY, QUESTION_DRIVING_PRESSURE_KEY, QUESTION_DRIVING_PRESSURE_SPEC_VALUE_KEY, QUESTION_DYNAMIC_CONTROL_VALVE_KEY, QUESTION_HAS_MAIN_PUMP_KEY } from "../../../../constants/wizardConstants"

export const DrivingPressureQuestion = ({ drivingPressure, dpIsVariable, dpSpecValue, hasMainPump, answerQuestion }) => {

    const setAnswerQuestion = (event) => {
        answerQuestion(QUESTION_DRIVING_PRESSURE_KEY, event.target.value)

        if (event.target.value === AVAILABLE_DRIVE_PRESSURE.nonExistent.value){
            answerQuestion(QUESTION_HAS_MAIN_PUMP_KEY, false);
        }
    }

    return (
        <>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <FormControl size="small">
                    <InputLabel>Tillgängligt drivtryck</InputLabel>
                    {hasMainPump === false ? (
                        <Select
                            sx={{ width: '180px', height: '30px', background: 'white' }}
                            size="small"
                            value={'not'}
                            disabled
                            label={'Tillgängligt drivtryck'}
                        >
                            <MenuItem value={'not'}>Obefintligt</MenuItem>
                        </Select>
                    ) : (
                        <Select
                            sx={{ width: '180px', height: '30px', background: 'white' }}
                            size="small"
                            value={drivingPressure}
                            label={'Tillgängligt drivtryck'}
                            onChange={setAnswerQuestion}
                        >
                            <MenuItem value={AVAILABLE_DRIVE_PRESSURE.normal.value}>{AVAILABLE_DRIVE_PRESSURE.normal.label}</MenuItem>
                            <MenuItem value={AVAILABLE_DRIVE_PRESSURE.low.value}>{AVAILABLE_DRIVE_PRESSURE.low.label}</MenuItem>
                            <MenuItem value={AVAILABLE_DRIVE_PRESSURE.nonExistent.value}>{AVAILABLE_DRIVE_PRESSURE.nonExistent.label}</MenuItem>
                            <MenuItem value={AVAILABLE_DRIVE_PRESSURE.unknown.value}>{AVAILABLE_DRIVE_PRESSURE.unknown.label}</MenuItem>
                            <MenuItem value={AVAILABLE_DRIVE_PRESSURE.specify.value}>{AVAILABLE_DRIVE_PRESSURE.specify.label}</MenuItem>
                        </Select>
                    )}

                </FormControl>
                {drivingPressure === AVAILABLE_DRIVE_PRESSURE.normal.value && hasMainPump !== false ? (
                    <>
                        <FormControlLabel
                            sx={{ height: '28px' }}
                            control={
                                <Checkbox checked={dpIsVariable} onChange={() => {
                                    if (dpIsVariable === false){
                                        answerQuestion(QUESTION_DYNAMIC_CONTROL_VALVE_KEY, true);
                                    }
                                    answerQuestion(QUESTION_DRIVING_PRESSURE_IS_VARIABLE_KEY, !dpIsVariable)
                                }} />
                            }
                            label={"Varierande/föränderligt"}
                        />
                    </>
                ) : drivingPressure === AVAILABLE_DRIVE_PRESSURE.specify.value ? (
                    <>
                        <TextField
                            size="medium"
                            sx={{ width: '150px' }}
                            inputProps={{
                                style: {
                                    height: '34px',
                                    padding: '0 14px',
                                    background: 'white'
                                },
                            }}
                            label="kPa"
                            type="number"
                            value={dpSpecValue}
                            onChange={(e) => answerQuestion(QUESTION_DRIVING_PRESSURE_SPEC_VALUE_KEY, e.target.value)}
                        />
                    </>
                ) : ("")}
            </Stack>
        </>
    );
}