
import { Stack, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useAdjustParams, useCalcParams, useDriftParams, useProductLineParams, usePumpParams, useSessionParams, useShuntParams } from "../../../../contexts"
import { SessionParamsEnum, ShuntParamsEnum } from "../../../../enums";
import { getComponentText } from "../../../../functions/DisplayFunctions/getComponentText";
import { useWizardParams } from "../../../../contexts/wizardParams";
import { useGetSystemFluidOptions } from "../../../../hooks/useGetSystemFluidOptions";

export const InspectPageComponentRow = ({ component, specifyAmount = true, useCurrent = true, shuntData = null }) => {
    const systemFluidOptions = useGetSystemFluidOptions();
    const productLineParams = useProductLineParams();
    const shuntParams = useShuntParams();
    const calcParams = useCalcParams();
    const adjustParams = useAdjustParams();
    const pumpParams = usePumpParams();
    const sessionParams = useSessionParams();
    const driftParams = useDriftParams();
    const wizardParams = useWizardParams();

    const [textRows, setTextRows] = useState([]);


    useEffect(() => {
        if (SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true) {
            let textRes = getComponentText(component, useCurrent, shuntData, systemFluidOptions, calcParams, productLineParams, shuntParams, adjustParams, pumpParams, driftParams, wizardParams);
            setTextRows(textRes);
        }
    }, [SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams), SessionParamsEnum.CurrentShunt.getContextValue(sessionParams), ShuntParamsEnum.ShuntDesignation.getContextValue(shuntParams), ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams), shuntData, systemFluidOptions]);

    return (
        <>
            {textRows.map((row, index) => {
                if (specifyAmount === true) {
                    return (
                        <Stack key={index} direction={'row'} spacing={1}>
                            {row.num != null ? (
                                <Typography style={{ width: '30px' }}>
                                    {String(row.num)} st.
                                </Typography>
                            ) : (
                                <Typography style={{ width: '30px' }}>

                                </Typography>
                            )}

                            <Typography style={{ width: '520px' }}>
                                {row.text}
                            </Typography>
                        </Stack>
                    )
                }
                else {
                    return (
                        <Stack key={index} direction={'row'} spacing={1}>
                            <Typography style={{ width: '520px' }}>
                                {row.text}
                            </Typography>
                        </Stack>
                    )
                }
            })
            }
        </>
    )
}