import { ContactPremaModal, ContactPremaModalConnectedPrimFlowToHigh } from "../components/modules/contactPremaModal/contactPremaModal";

export class ErrorMsg {
    //Drift-Params Not Set
    static params_notSet = new ErrorMsg("params_notSet", "Fyll i alla systemparametrar för att genomföra dimensioneringsberäkning");
    static params_shuntPosAlreadyExists = new ErrorMsg("params_shuntPosAlreadyExists", "Sessionen innehåller redan en shuntgrupp med denna position");
    static params_noShuntDesignation = new ErrorMsg("params_noShuntDesignation", "Ingen beteckning har angivits");

    //Gamla separata input-checkar
    static params_noSecTempOut = new ErrorMsg("params_noSecTempOut", "Ingen temperatur sekundär retur angivet!");
    static params_noTempIn = new ErrorMsg("params_noTempIn", "Ingen temperatur primärt angivet!");
    static params_noSecFlow = new ErrorMsg("params_noSecFlow", "Inget flöde i sekundärsystemet angivet!");
    static params_noPrimDrivPressure = new ErrorMsg("params_noPrimDrivPressure", "Inget tillgängligt drivtryck i primärkretsen angivet!");
    static params_noprimExternalPressureDrop = new ErrorMsg("params_primExternalPressureDrop", "Inget tryckfall i primärkretsen angivet!");
    static params_noSecPressureLoss = new ErrorMsg("params_noSecPressureLoss", "Inget tryckfall i sekundärsystemet angivet!");

    //Temperature Errors
    static temp_tempToHigh = new ErrorMsg("temp_tempToHigh", "Kontakta PREMA för dimensionering vid temperaturer över 90 grader", <ContactPremaModal reasonText={'för dimensionering vid temperaturer över 90 grader'} />); //Kontakta Prema
    static temp_tempToLow = new ErrorMsg("temp_tempToLow", "Kontakta PREMA för dimensionering vid temperaturer under -5 grader", <ContactPremaModal reasonText={'för dimensionering vid temperaturer under -5 grader'} />); //Kontakta Prema
    static temp_tempToLowVA = new ErrorMsg("temp_tempToLowVA", "Kontakta PREMA för dimensionering vid temperaturer under -10 grader", <ContactPremaModal reasonText={'för dimensionering vid temperaturer under -10 grader'} />); //Kontakta Prema
    //static temp_inconsistentTempSecInLow = new ErrorMsg("temp_inconsistentTempSecInLow", "Inkonsekventa temperaturvärden (temperaturen sekundärt in kan inte vara lägre än både primärt in och sekundärt ut)");
    //static temp_inconsistentTempSecInHigh = new ErrorMsg("temp_inconsistentTempSecInHigh", "Inkonsekventa temperaturvärden (temperaturen sekundärt in kan inte vara högre än både primärt in och sekundärt ut)");
    //static temp_inconsistentTempSecOutLow = new ErrorMsg("temp_inconsistentTempSecOutLow", "Inkonsekventa temperaturvärden (temperaturen sekundärt ut kan inte samtidigt vara lägre än primärt in och högre än primärt ut)");
    //static temp_inconsistentTempSecOutHigh = new ErrorMsg("temp_inconsistentTempSecOutHigh", "Inkonsekventa temperaturvärden (temperaturen sekundärt ut kan inte samtidigt vara högre än primärt in och lägre än primärt ut)");

    static temp_inconsistentTempPrimOut = new ErrorMsg("temp_inconsistentTempPrimOut", "Inkonsekventa temperaturvärden (temperatur för primär retur kan inte ligga utanför intervallet för primärt till shuntgruppen och sekundär retur)");
    static temp_inconsistentTempSecIn = new ErrorMsg("temp_inconsistentTempSecIn", "Inkonsekventa temperaturvärden (temperaturen för sekundärt tillopp kan inte ligga utanför intervallet för primärt till shuntgruppen och sekundär retur)");

    //Flow Error
    static flow_nonexistingPrimFlow = new ErrorMsg("flow_nonexistingPrimFlow", "Negativt eller obefintligt flöde. Kontrollera temperaturvärden");
    static flow_nonexistingSecFlow = new ErrorMsg("flow_nonexistingSecFlow", "Ange ett positivt sekundärflöde");
    static flow_toHighPrimFlow = new ErrorMsg("flow_toHighPrimFlow", "Kontakta PREMA vid flöden över 50 l/s", <ContactPremaModal reasonText={'vid flöden över 50 l/s'} />); //Kontakta Prema
    static flow_toHighSecFlow = new ErrorMsg("flow_toHighSecFlow", "Kontakta PREMA vid flöden över 50 l/s", <ContactPremaModal reasonText={'vid flöden över 50 l/s'} />); //Kontakta Prema

    //Pressure Errors
    //static pressure_primInternalPressureDropToHigh = new ErrorMsg("pressure_primInternalPressureDropToHigh", "Tillgängligt drivtryck för lågt för vald dimension") //Ersatt av nya mer specifika

    static pressure_primInternalPressureDropToHigh_PR2 = new ErrorMsg("pressure_primInternalPressureDropToHigh_PR2", "Det interna tryckfallet primärt är för högt i förhållande till tillgängligt drivtryck. Öka om möjligt det tillgängliga drivtrycket, alternativt välj utförande Utan huvudpump.")
    static pressure_primInternalPressureDropToHigh_PR3 = new ErrorMsg("pressure_primInternalPressureDropToHigh_PR3", "Det interna tryckfallet primärt är för högt i förhållande till tillgängligt drivtryck. Högre tillgängligt drivtryck krävs.")
    static pressure_primInternalPressureDropToHigh_Man = new ErrorMsg("pressure_primInternalPressureDropToHigh_Man", "Det interna tryckfallet primärt är för högt i förhållande till tillgängligt drivtryck. Testa att öka rördimensionen primärt, eller öka tillgängligt drivtryck.")

    static pressure_primInternalPressureDropToHigh_SR_cv = new ErrorMsg("pressure_primInternalPressureDropToHigh_SR_cv", "Tryckfallet över styrventilen är för högt i förhållande till tillgängligt drivtryck. Öka tillgängligt drivtryck om möjligt, annars välj högre kvs-värde för styrventil.");
    static pressure_primInternalPressureDropToHigh_SR_dim = new ErrorMsg("pressure_primInternalPressureDropToHigh_SR_dim", "Vald rördimension primärt leder till att det interna tryckfallet primärt är för högt i förhållande till tillgängligt drivtryck, vid aktuella flöden.");
    static pressure_primInternalPressureDropToHigh_SR = new ErrorMsg("pressure_primInternalPressureDropToHigh_SR", "Det interna tryckfallet primärt är för högt i förhållande till tillgängligt drivtryck. Säkerställ att primär rördimension är lämplig för det aktuella flödet, eller öka tillgängligt drivtryck om möjligt, annars välj högre kvs-värde för styrventil.");


    static pressure_toHighPrimDrivingPressure = new ErrorMsg("pressure_toHighPrimDrivingPressure", "Kontakta PREMA vid drivtryck över 90 kPa", <ContactPremaModal reasonText={'vid drivtryck över 90 kPa'} />); //Kontakta Prema
    static pressure_toHighPrimDrivingPressureManualPressure = new ErrorMsg("pressure_toHighPrimDrivingPressureManualPressure", "Det tillgängliga drivtrycket är för högt för den aktuella styrventilen, kontakta PREMA vid drivtryck över "); //Redundant tills vidare (Flexarna har egen, och ej behov på övriga för tillfället)
    static pressure_toLowPrimDrivingPressure_SR2 = new ErrorMsg("pressure_toLowPrimDrivingPressure_SR2", "Angivna tillgängligt drivtryck är lågt varför shunttyp PR-2 ska väljas, alternativt högre tillgängligt drivtryck primärt anges.");

    //Manual mode Errors
    static manual_noDimSelected = new ErrorMsg("manual_noDimSelected", "Välj rördimensioner primärt och sekundärt för att kunna genomföra beräkning");
    static manual_flowToHighForCV = new ErrorMsg('manual_flowToHighForCV', 'Flödet är för högt för den valda styrventilen. Välj ett högre flödesområde eller en annan styrventil'); //Gäller enbart vissa styrventiler (med flödesintervall)
    static manual_flowToLowForCV = new ErrorMsg('manual_flowToLowForCV', 'Flödet är för lågt för den valda styrventilen. Välj ett lägre flödesområde eller en annan styrventil'); //Gäller enbart vissa styrventiler (med flödesintervall) 
    static manual_cvNotSet = new ErrorMsg('manual_cvNotSet', 'Välj styrventil och kvs-värde för att kunna genomföra beräkning');

    static manual_flex_modelNotSelected = new ErrorMsg('manual_flex_modelNotSelected', 'Välj styrventil och modell för att kunna genomföra beräkning');

    //Flex-specific Errors
    static flex_valveToBig = new ErrorMsg('flex_valveToBig', 'Det valda flödesområdet är för högt, välj ett lägre flödesområde');
    static flex_valveToSmall = new ErrorMsg('flex_valveToSmall', 'Det valda flödesområdet är för lågt, välj ett högre flödesområde');
    static flex_lowPressure = new ErrorMsg('flex_lowPressure', 'Det tillgängliga drivtrycket primärt är för lågt för styrventil med differenstrycksregulator. Ange ett högre tillgängligt drivtryck alternativ välj shunttyp (SR-2) med styrventil som har fast kvs-värde.');
    static flex_highPressure = new ErrorMsg('flex_highPressure', 'Det tillgängliga drivtrycket primärt är för högt för styrventilen. Kontakta PREMA för hjälp');
    static flex_noAcceptableValve = new ErrorMsg('flex_noAcceptableValve', 'Ingen möjlig styrventil hittad för de angivna värdena. Kontakta PREMA för hjälp');
    static flex_safetyMargin = new ErrorMsg('flex_safetyMargin', 'Styrventilen måste vara mer än 86% öppen och får för liten marginal');


    //Smart-specific Errors
    static smart_flowToLow = new ErrorMsg('smart_flowToLow', 'Premablock smart tillåter i dagsläget inte så låga flöden');
    static smart_flowToHigh = new ErrorMsg('smart_flowToHigh', 'Premablock smart tillåter i dagsläget inte så höga flöden');
    static smart_PrimPressureToLow = (val) => { return new ErrorMsg('smart_PrimPressureToLow', 'Tillgängligt drivtryck är för lågt i förhållande till tillgängliga styrventilers flödesområden (kräver minst ' + val + ' kPa). Justera tillgängligt drivtryck för att kunna använda styrventil i smart-utförande med det aktuella flödet.'); }
    //static smart_PrimPressureToHigh = new ErrorMsg('smart_PrimPressureToHigh', 'Tillgängligt drivtryck är för högt. ');
    static smart_highPressure = new ErrorMsg('flex_highPressure', 'Det tillgängliga drivtrycket primärt är för högt för styrventilen. Kontakta PREMA för hjälp');
    static smart_PrimPressureToLow_man = new ErrorMsg('smart_PrimPressureToLow_man', 'Tillgängligt drivtryck är för lågt för den valda styrventilen');
    static smart_PrimFlowToHigh_man = new ErrorMsg('smart_PrimFlowToHigh_man', 'Primära flödet är för högt för den valda styrventilen');
    static smart_PrimFlowToLow_man = new ErrorMsg('smart_PrimFlowToLow_man', 'Primära flödet är för lågt för den valda styrventilen');

    //Connected Energy Errors
    static connected_PressureDropToHigh = (val, val2) => {
        return new ErrorMsg(
            'connected_PressureDropToHigh',
            `Energimätaren i det aktuella systemet medför ett ytterligare tryckfall (${val} kPa) och det totala tryckfallet primärt överskrider det tillgängliga drivtrycket. Ange ett högre drivtryck (>${val2} kPa), eller gör om beräkningen utan Connected™ Energi som tillval`,
            <div>
                Energimätaren i det aktuella systemet medför ett ytterligare tryckfall ({val} kPa) och det totala tryckfallet primärt överskrider det tillgängliga drivtrycket. Ange ett högre drivtryck ({">"}{val2} kPa), eller gör om beräkningen utan <strong>Connected™ Energi</strong> som tillval
            </div>
        );
    }

    static connected_PrimFlowToHigh = (val) => {
        return new ErrorMsg(
            'connected_PrimFlowToHigh',
            `Kontakta PREMA vid energimätning i shuntgrupper med primärt flöde över ${val}l/s, eller gör om beräkningen utan Connected™ Energi som tillval`,
            <ContactPremaModalConnectedPrimFlowToHigh value={val} />
        );
    }

    static connected_ManualToLowForAbsFlow = new ErrorMsg('connected_ManualToLowForAbsFlow', 'Vald energimätare är för stor och shuntgruppens flöde ligger utanför mätarens absoluta gränsvärden');
    static connected_ManualToHighForAbsFlow = new ErrorMsg('connected_ManualToHighForAbsFlow', 'Vald energimätare är för liten och shuntgruppens flöde ligger utanför mätarens absoluta gränsvärden');

    //Other unspecific Errors
    static contactPrema = new ErrorMsg('contactPrema', "Kontakta PREMA för hjälp", <ContactPremaModal reasonText={'för hjälp'} />);
    static dimNotFound = new ErrorMsg('dimNotFound', 'Kunde inte hitta någon passande dim!');
    static internalError = new ErrorMsg('internalError', 'Något gick fel, kontakta F2... (Dev mode)');

    /**
     * 
     * @param {string} key 
     * @param {string} msg 
     * @param {import("@emotion/react/types/jsx-namespace").ReactJSXElement} [component] 
     */
    constructor(key, msg, component) {
        this.key = key;
        this.msg = msg;
        this.component = component;
    }
}
