import { Button, Divider, IconButton, Stack, Typography } from "@mui/material"
import { useContextAuth } from "../../../../authContext/AuthHook";

import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { CommentDialog } from "../../overview/commentDialog";
import { useState } from "react";
import { SessionParamsEnum } from "../../../../enums";
import { useSessionParams } from "../../../../contexts";

export const InspectPageViewComments = ({ shuntData }) => {
    const { user } = useContextAuth();
    const sessionParams = useSessionParams();
    const [openComment, setOpenComment] = useState(false);

    const handleOpenComment = () => {
        setOpenComment(true);
    }

    const handleCloseComment = () => {
        setOpenComment(false);
    }

    const hasComments = () => {
        return shuntData?.CustomData?.lastComment != null;
    }

    return (
        <>
            {hasComments() ? (
                <>
                    <Divider sx={{ marginTop: '10px' }} />
                    <Stack spacing={0.1} style={{ paddingTop: "12px", paddingBottom: "15px" }}>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>

                            <div style={{ paddingBottom: "3px" }}><strong>Anmärkningar & förtydliganden:</strong></div>
                            {user?.isAnonymous === false && user?.isInternal === true ? (
                                <IconButton onClick={handleOpenComment} component="span" size="small">
                                    <EditIcon sx={{ "&:hover": { color: "#9e9e9e" }, fontSize: '12px' }} htmlColor={"#d9d9d9"} />
                                </IconButton>
                            ) : ("")}
                        </Stack>
                        <Typography style={{ width: '550px', whiteSpace: 'pre-wrap' }}>
                            {shuntData?.CustomData?.comment}
                        </Typography>
                    </Stack>
                </>
            ) : (
                <>
                    {user?.isAnonymous === false && user?.isInternal === true && SessionParamsEnum.Editable.getContextValue(sessionParams) === true ? (
                        <>
                            <Divider sx={{ marginTop: '10px' }} />
                            <IconButton onClick={handleOpenComment} sx={{ marginTop: '10px' }} component="span" size="small">
                                <Stack sx={{ "&:hover": { color: "#9e9e9e" }, fontSize: '12px' }} color={"#d9d9d9"} direction={'row'} alignItems={'center'} spacing={1}>
                                    <AddIcon />

                                    <Typography>
                                        Lägg till Anmärkningar/Förtydliganden
                                    </Typography>
                                </Stack>
                            </IconButton>
                        </>
                    ) : ("")}
                </>
            )}

            <CommentDialog handleOnDecline={handleCloseComment} shuntID={shuntData?.NO_ID_FIELD} open={openComment} type={null} />
        </>
    )
}