const { PRODUCTLINE_FLEX, PRODUCTFAMILY_SR, PRODUCTFAMILY_VA, PRODUCTLINE_SMART } = require("../../constants/productlineConstants");
const { calcDeltaPressure } = require("./baseFormulas/calcDeltaPressure");

const calcInternalPrimDP = (prodLine, plFamily, drivingPressure, svPressureDrop, primFlow, balanceKV) => {
    let localDP = drivingPressure;

    if (prodLine === PRODUCTLINE_FLEX || prodLine === PRODUCTLINE_SMART) {
        return 0;
    }
    let balanceValveDP = 0;
    if (balanceKV > 0) {
        balanceValveDP = calcDeltaPressure(primFlow, balanceKV, false);
    }

    if (localDP == null || localDP === "") {
        localDP = 0;
    }

    //console.log(localDP, svPressureDrop, balanceValveDP)
    if (plFamily === PRODUCTFAMILY_SR || plFamily === PRODUCTFAMILY_VA) {
        return Number(Number(localDP) - Number(svPressureDrop || 0) - Number(balanceValveDP || 0));
    }
    else {
        return Number(Number(localDP) - Number(balanceValveDP || 0));
    }
}

export { calcInternalPrimDP }