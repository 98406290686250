import { Stack, Container, Paper, Box } from "@mui/material"
import { DriftParamEnum, SessionParamsEnum } from "../../../../../enums"
import { DriftParamsTextField } from "../../components"
import { LocalImage } from "../../localImage"
import { useDriftParams, useSessionParams } from "../../../../../contexts"

import { HOVER_TEXT_PRIM_TEMP_IN, HOVER_TEXT_PRIM_DRIVING_PRESSURE, HOVER_TEXT_PRIM_FLOW, HOVER_TEXT_SEC_TEMP_OUT, HOVER_TEXT_SEC_EXTERNAL_PRESSURE_DROP, HOVER_TEXT_SEC_FLOW, HOVER_TEXT_SEC_TEMP_IN, HOVER_TEXT_SEC_FLOW_EFFECT } from "../../../../../constants/generalText/hoverTexts"

export const DriftParamsBody_SR2Flex = ({ handleHover, handleExit, isDisabled, imageRef }) => {
    const driftParams = useDriftParams();
    const sessionParams = useSessionParams();

    /**
     * 
     * @param {Boolean} lockedField 
     * @param {Boolean} lockOnAPIUpdate 
     */
    const localIsDisabled = (lockedField, lockOnAPIUpdate) => {
        if ((lockOnAPIUpdate && SessionParamsEnum.ApiMethod.getContextValue(sessionParams) === "update") || lockedField || isDisabled()) {
            return true;
        }
        else {
            return false;
        }
    }

    return (
        <>
            <Stack direction={'row'} spacing={0}>
                <Container style={{ padding: '0px', marginLeft: '0px', }}>
                    {/* Left Side */}
                    {/* Row 1 */}
                    <Box style={{
                        marginTop: "51px"
                    }} />

                    {/* Row 2 */}
                    <DriftParamsTextField
                        driftEnum={DriftParamEnum.PrimDrivingPressure}
                        end={"kPa"}
                        hoverText={HOVER_TEXT_PRIM_DRIVING_PRESSURE}
                        handleHover={handleHover}
                        handleExit={handleExit}
                        isDisabled={localIsDisabled(DriftParamEnum.LockedPrimDrivingPressure.getContextValue(driftParams), true)}
                    />

                    {/* Row 3 */}
                    <DriftParamsTextField
                        driftEnum={DriftParamEnum.PrimFlow}
                        end={"l/s"}
                        isDisabled={true}
                        hoverText={HOVER_TEXT_PRIM_FLOW}
                        handleHover={handleHover}
                        handleExit={handleExit}
                    />

                    {/* Row 4 */}
                    <DriftParamsTextField
                        driftEnum={DriftParamEnum.PrimTempIn}
                        hoverText={HOVER_TEXT_PRIM_TEMP_IN}
                        handleHover={handleHover}
                        handleExit={handleExit}
                        end={'°C'}
                        isDisabled={localIsDisabled(DriftParamEnum.LockedPrimTempIn.getContextValue(driftParams), true)}
                    />

                </Container>

                {/* Middle */}
                <Stack spacing={2}>
                    <Paper elevation={0} id="principle_image" >
                        {imageRef != null ? (
                            <>
                                <LocalImage imageReference={imageRef} />
                            </>
                        ) : (
                            <div style={{ width: '437px', height: '100px', textAlign: 'center', marginTop: '88px' }}>
                                Could not load image!
                            </div>
                        )}
                    </Paper>
                </Stack>

                {/* Right Side */}
                <Container style={{ marginRight: '0px', paddingTop: '14px' }}>
                    {/* Row 1 */}
                    <DriftParamsTextField
                        driftEnum={DriftParamEnum.SecTempOut}
                        hoverText={HOVER_TEXT_SEC_TEMP_OUT}
                        end={'°C'}
                        handleHover={handleHover}
                        handleExit={handleExit}
                        isDisabled={localIsDisabled(DriftParamEnum.LockedSecTempOut.getContextValue(driftParams), true)}
                    />

                    {/* Row 2 */}
                    <DriftParamsTextField
                        driftEnum={DriftParamEnum.SecExternalPressureDrop}
                        end={"kPa"}
                        hoverText={HOVER_TEXT_SEC_EXTERNAL_PRESSURE_DROP}
                        handleHover={handleHover}
                        handleExit={handleExit}
                        isDisabled={localIsDisabled(DriftParamEnum.LockedSecExternalPressureDrop.getContextValue(driftParams), true)}
                    />

                    {/* Row 3 */}
                    <DriftParamsTextField
                        driftEnum={DriftParamEnum.SecFlow}
                        end={"l/s"}
                        hoverText={HOVER_TEXT_SEC_FLOW}
                        handleHover={handleHover}
                        handleExit={handleExit}
                        isDisabled={localIsDisabled(DriftParamEnum.LockedSecTempOut.getContextValue(driftParams), true) || DriftParamEnum.FlowCalcMode.getContextValue(driftParams) === "kw"}
                    />

                    {/* Row 4 */}
                    <DriftParamsTextField
                        driftEnum={DriftParamEnum.SecTempIn}
                        hoverText={HOVER_TEXT_SEC_TEMP_IN}
                        handleHover={handleHover}
                        handleExit={handleExit}
                        end={'°C'}
                        isDisabled={localIsDisabled(DriftParamEnum.LockedSecTempIn.getContextValue(driftParams), true)}
                    />

                    {/* Row 5 */}
                    <Box>
                        <DriftParamsTextField
                            driftEnum={DriftParamEnum.SecFlowEffect}
                            end={"kW"}
                            isDisabled={isDisabled() || DriftParamEnum.FlowCalcMode.getContextValue(driftParams) !== "kw"}
                            hoverText={HOVER_TEXT_SEC_FLOW_EFFECT}
                            handleHover={handleHover}
                            handleExit={handleExit}
                        />
                    </Box>

                </Container>
            </Stack>
        </>
    )
}