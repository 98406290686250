import { httpsCallable } from "firebase/functions";

/**
 * 
 * @param {import("firebase/functions").Functions} functions 
 * @param {String} sessionID 
 * @param {boolean} isStarred 
 */
const setIsStarred = function setIsStarred(functions, sessionID, isStarred) {
    functions.region = 'europe-west1';
    const setSessionIsStarredFunc = httpsCallable(functions, "setSessionIsStarred");

    return setSessionIsStarredFunc({ 
        sessionID: sessionID,
        isStarred: isStarred,
    });
}

export { setIsStarred } 