import { Typography, Paper, TableContainer, Table, TableHead, styled, TableRow, TableCell, TableBody, Button, Collapse } from "@mui/material";
import { Box } from "@mui/system";
import { collection } from "firebase/firestore";
import { Fragment, useEffect, useState } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function Row({ valve }) {
    const [open, setOpen] = useState(false);
    const [openDim, setOpenDim] = useState({});
    const [update, setUpdate] = useState(false);

    const firestore = useFirestore();

    const conTypeDoc = collection(firestore, '/ControlValves/' + valve.NO_ID_FIELD + "/Models");
    const firestoreRes = useFirestoreCollectionData(conTypeDoc);

    const [cvModels, setCVModels] = useState([]);

    const openDimVal = (val) => {
        if (openDim[val] == null) {
            openDim[val] = true;
        }
        else {
            openDim[val] = !openDim[val];
        }
        setUpdate(!update);
        //console.log(openDim)
    }

    useEffect(() => {
        if (firestoreRes.data != null) {
            //console.log("Data: ", firestoreRes.data)
            setCVModels(firestoreRes.data);
        }

    }, [firestoreRes])

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.action.hover,
        // },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    return (
        <>
            <StyledTableRow style={{ height: "35px" }}>
                <TableCell>{valve.name}</TableCell>
                {valve.default === true ? (
                    <TableCell align="center"><CheckCircleIcon fontSize='small' color="success" /></TableCell>
                ) : (
                    <TableCell></TableCell>
                )}
                {valve.default === true ? (
                    <TableCell align="center"><CheckCircleIcon fontSize='small' color="success" /></TableCell>
                ) : (
                    <TableCell></TableCell>
                )}
                {valve.default_flex === true ? (
                    <TableCell align="center"><CheckCircleIcon fontSize='small' color="success" /></TableCell>
                ) : (
                    <TableCell></TableCell>
                )}
                {valve.default_smart === true ? (
                    <TableCell align="center"><CheckCircleIcon fontSize='small' color="success" /></TableCell>
                ) : (
                    <TableCell></TableCell>
                )}
                <TableCell align="center">
                    <Button
                        aria-label="expand row"
                        size="small"
                        className="models-btn"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        Modeller
                    </Button>
                </TableCell>
            </StyledTableRow>
            <StyledTableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <Typography variant="h6" gutterBottom component="div">
                                Modeller
                            </Typography>
                            <Table size="small">
                                <TableHead>
                                    <StyledTableRow>
                                        <TableCell>Namn</TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell></TableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {cvModels.map((models) => (
                                        <>
                                            <StyledTableRow key={models.name}>
                                                <TableCell component="th" scope="row">
                                                    {models.name}
                                                </TableCell>
                                                {models.default === true ? (
                                                    <TableCell align="center"><CheckCircleIcon fontSize='small' color="success" /></TableCell>
                                                ) : (
                                                    <TableCell align="center"></TableCell>
                                                )}
                                                {models.default_green === true ? (
                                                    <TableCell align="center"><CheckCircleIcon fontSize='small' color="success" /></TableCell>
                                                ) : (
                                                    <TableCell align="center"></TableCell>
                                                )}
                                                {models.default_flex === true ? (
                                                    <TableCell align="center"><CheckCircleIcon fontSize='small' color="success" /></TableCell>
                                                ) : (
                                                    <TableCell align="center"></TableCell>
                                                )}
                                                {models.default_smart === true ? (
                                                    <TableCell align="center"><CheckCircleIcon fontSize='small' color="success" /></TableCell>
                                                ) : (
                                                    <TableCell align="center"></TableCell>
                                                )}
                                                <TableCell sx={{ width: '96px' }} align="center">
                                                    <Button
                                                        aria-label="expand row"
                                                        size="small"
                                                        className="models-btn"
                                                        onClick={() => openDimVal(models.name)}
                                                    >
                                                        {openDim[models.name] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        Info
                                                    </Button>
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                    <Collapse in={openDim[models.name]} timeout="auto" unmountOnExit>
                                                        <Box sx={{ marginLeft: '20px' }}>
                                                            <ModelRow valve={valve} model={models} />
                                                        </Box>
                                                    </Collapse>
                                                </TableCell>
                                            </StyledTableRow>
                                        </>

                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </StyledTableRow>
        </>

    )
}

function ModelRow({ valve, model }) {
    const firestore = useFirestore();
    //console.log("Val: ", model)
    const useKvsCheck = () => {
        if (model.kvs != null && model.kvs.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    const useKvsIntervalCheck = () => {
        if (model.kvsInterval != null) {
            return true;
        }
        else {
            return false;
        }
    }

    const [useKvs, setUseKvs] = useState(useKvsCheck());
    const [useKvsInterval, setUseKvsInterval] = useState(useKvsIntervalCheck());
    const [dimData, setDimData] = useState([]);

    const conTypeDoc = collection(firestore, '/ControlValves/' + valve.NO_ID_FIELD + "/Models/" + model.NO_ID_FIELD + "/Dims");
    const firestoreRes = useFirestoreCollectionData(conTypeDoc);

    useEffect(() => {
        if (firestoreRes.data != null) {
            //console.log("Data (Model): ", firestoreRes.data)
            if (firestoreRes.data != null) {
                setDimData(firestoreRes.data);
            }
        }

    }, [firestoreRes])

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.action.hover,
        // },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const showDimInfo = (dim) => {
        let header = dim?.NO_ID_FIELD;
        let infoRows = [];

        if (dim?.display != null) {
            header = dim?.display;
        }

        if (dim?.minPressure != null) {
            infoRows.push({ title: "Min tryck", val: dim?.minPressure });
        }

        if (dim?.maxPressure != null) {
            infoRows.push({ title: "Max tryck", val: dim?.maxPressure });
        }

        if (dim?.qMaxPosStart != null) {
            infoRows.push({ title: "qMax Start", val: dim?.qMaxPosStart });
        }

        if (dim?.qMaxPosStep != null) {
            infoRows.push({ title: "qMax Step", val: dim?.qMaxPosStep });
        }

        if (dim?.kvs != null) {
            infoRows.push({ title: "Kvs", val: dim?.kvs });
        }

        if (dim?.qMin != null) {
            infoRows.push({ title: "qMin", val: dim?.qMin });
        }

        if (dim?.qMax != null) {
            if (Array.isArray(dim?.qMax)) {
                let textList = "";
                for (let index = 0; index < dim?.qMax.length; index++) {
                    const val = dim?.qMax[index];

                    if (index < dim?.qMax.length - 1) {
                        textList += val + ", ";
                    }
                    else {
                        textList += val;
                    }
                }

                infoRows.push({ title: "qMax", val: textList });
            }
            else {
                infoRows.push({ title: "qMax", val: dim?.qMax });
            }

        }

        return (
            <Box sx={{ paddingBottom: '5px', maxWidth: '700px' }}>
                <Typography style={{ paddingLeft: '10px' }}>
                    <strong>{header}</strong>
                </Typography>
                {infoRows.map((row) => {
                    return (
                        <Typography style={{ paddingLeft: '15px' }}>
                            <strong>{row.title}</strong> : {row.val}
                        </Typography>
                    )
                })}
            </Box>
        )
    }

    const displayInfo = () => {
        if (useKvs === true) {
            return (
                <>
                    <Typography variant="h6" gutterBottom component="div">
                        Kvs-värden
                    </Typography>
                    <Typography style={{ paddingLeft: '10px' }}>
                        {model.kvs.map((kvs, index) => {
                            if (index < model.kvs.length - 1) {
                                return (
                                    kvs + ", "
                                );
                            }
                            else {
                                return (
                                    kvs
                                );
                            }
                        })}
                    </Typography>
                </>

            )
        }
        else if (useKvsInterval === true) {
            return (
                <>
                    <Typography variant="h6" gutterBottom component="div">
                        Kvs-värden (Variabla flöden)
                    </Typography>
                    <Typography>
                        {Object.keys(model.kvsInterval).sort((a, b) => a.localeCompare(b)).map((key, index) => {
                            return (
                                <Typography style={{ paddingLeft: '10px' }}>
                                    <strong>{key}</strong>{" : " + model.kvsInterval[key]}
                                </Typography>
                            );
                        })}
                    </Typography>
                </>
            )
        }
        else if (dimData != null) {
            return (
                <>
                    <Typography variant="h6" gutterBottom component="div">
                        Dimensioner
                    </Typography>
                    <Typography>
                        {dimData.map((dim) => {
                            return showDimInfo(dim);
                        })}
                    </Typography>
                </>
            );
        }
    }

    return (
        <Box sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
            {displayInfo()}
        </Box>
    )

    /*
    return (
        <Table size="small">
            <TableHead>
                <StyledTableRow>
                    <TableCell>{useKvs ? ("Kvs-värden") : ("Dimensioner")}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </StyledTableRow>
            </TableHead>
            <TableBody>
                {useKvs ? (
                    <StyledTableRow>
                        <TableCell></TableCell>
                        <TableCell>{model.kvs.map((kvs) => {
                            return (
                                kvs + ", "
                            );
                        })}</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </StyledTableRow>

                ) : ("Dimensioner")}
            </TableBody>
        </Table>
    )*/
}

export const SystemSpecControlValve = () => {
    const firestore = useFirestore();

    const conTypeDoc = collection(firestore, '/ControlValves/');
    const firestoreRes = useFirestoreCollectionData(conTypeDoc);

    const [adjustValveData, setAdjustValveData] = useState([]);


    useEffect(() => {
        if (firestoreRes.data != null) {
            //console.log("Data: ", firestoreRes.data)
            setAdjustValveData(firestoreRes.data);
        }

    }, [firestoreRes])

    return (
        <Box sx={{ padding: "20px" }}>
            <TableContainer component={Paper} sx={{ width: '800px' }}>
                <Table sx={{ paddingRight: '20px' }} size="small" aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Märke</TableCell>

                            <TableCell>Förvalt standard</TableCell>
                            <TableCell>Förvalt green</TableCell>
                            <TableCell>Förvalt flex</TableCell>
                            <TableCell>Förvalt smart</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {adjustValveData.map((valve) => {
                            return (
                                <Row valve={valve} />
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}