import { MenuItem, FormControl, Select } from '@mui/material'
import { useFirestore, useFirestoreCollection } from 'reactfire';
import { collection } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { SHUNT_DIMS_PATH } from '../../../../constants/firebasePaths';
import { useCalcParams, useDriftParams, useSessionParams, useDevbarParams } from '../../../../contexts';
import { DriftParamEnum, CalcParamsEnum, SessionParamsEnum } from '../../../../enums';

export const DimSelectField = ({ isPrim }) => {
    const calcParams = useCalcParams();
    const driftParams = useDriftParams();
    const firestore = useFirestore();
    const sessionParams = useSessionParams();
    const devParams = useDevbarParams();

    const [optData, setOptData] = useState([]);

    const primaryDim = DriftParamEnum.PrimaryDim;
    const secondaryDim = DriftParamEnum.SecondaryDim;

    const optionsDoc = collection(firestore, SHUNT_DIMS_PATH);
    const firestoreRes = useFirestoreCollection(optionsDoc);

    const [fieldValue, setFieldValue] = useState('not set')

    const setValue = event => {
        if (isPrim) {
            primaryDim.setContextValue(driftParams, event.target.value);
        }
        else {
            secondaryDim.setContextValue(driftParams, event.target.value);
        }
        CalcParamsEnum.clearData(calcParams);
        setFieldValue(event.target.value);
    };

    useEffect(() => {
        if (firestoreRes.data != null) {
            //console.log("Options: ", firestoreRes.data);
            const options = [
                '',
            ];

            firestoreRes.data.forEach(dim => {
                if (isPrim === true) {
                    if (options.find(obj => { return obj === dim.data().display }) == null) {
                        options.push(dim.data().display);
                    }
                }
                else {
                    if (options.find(obj => { return obj === dim.data().display }) == null) {
                        options.push(dim.data().display);
                    }
                }

            });

            setOptData(options);
        }

    }, [firestoreRes])



    useEffect(() => {
        if (isPrim && primaryDim.getContextValue(driftParams) != null && primaryDim.getContextValue(driftParams) !== "") {
            //Potentsiell check för att ignorera iv-taggen på dimensionerna DN020, just nu gör den samma sak ändå.
            if (String(primaryDim.getContextValue(driftParams)).includes("DN020")) {
                //setFieldValue("DN020");
                setFieldValue(primaryDim.getContextValue(driftParams));
            }
            else {
                setFieldValue(primaryDim.getContextValue(driftParams));
            }

        }
        else if (secondaryDim.getContextValue(driftParams) != null && secondaryDim.getContextValue(driftParams) !== "") {
            setFieldValue(secondaryDim.getContextValue(driftParams));
        }
    }, [primaryDim.getContextValue(driftParams), secondaryDim.getContextValue(driftParams)]);

    const checkCalcMode = () => {
        if (DriftParamEnum.CalcMode.getContextValue(driftParams) === "0" || DriftParamEnum.CalcMode.getContextValue(driftParams) === 0) {
            return false;
        }
        else {
            return true;
        }
    }

    const isDisabled = () => {
        if ((SessionParamsEnum.Editable.getContextValue(sessionParams) === false && devParams.state.val.devMode !== true) ||
            (DriftParamEnum.CalcMode.getContextValue(driftParams) !== "0" && DriftParamEnum.CalcMode.getContextValue(driftParams) !== 0)) {
            return true;
        }
        else {
            return false;
        }
    }


    return (
        <FormControl sx={{ m: 1, minWidth: 100 }}>
            <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={fieldValue}
                onChange={setValue}
                autoWidth
                disabled={isDisabled()}
            >
                {optData.map((optionObject, index) => {
                    return (
                        <MenuItem key={index} value={optionObject}>{optionObject}</MenuItem>
                    )
                })}

            </Select>
        </FormControl>
    );
};