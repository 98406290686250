import { useDatabase, useFirestore, useFirestoreCollection } from 'reactfire';
import { collection, getDocs, query } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { Stack, MenuItem, Select } from '@mui/material';
import { CONTROL_VALVES_PATH, CONTROL_VALVES_DIMS_FLEX_DEFAULT_PATH } from '../../../../constants/firebasePaths';
import { useShuntParams, useDriftParams, useCalcParams, useSessionParams, useDevbarParams } from '../../../../contexts';
import { ShuntParamsEnum, DriftParamEnum, SessionParamsEnum, CalcParamsEnum } from '../../../../enums';
import { roundToDecimals } from '../../../../functions/ShuntCalculations/helpFunctions/roundTo4Decimals';
import { resetMaxStepFunc } from '../../../../functions/HelperFunctions/resetMaxStep';

export const FlexFlowSelectField = () => {
    const shuntParams = useShuntParams();
    const driftParams = useDriftParams();
    const calcParams = useCalcParams();
    const firestore = useFirestore();
    const sessionParams = useSessionParams();
    const devParams = useDevbarParams();
    const database = useDatabase();


    const setDimID = () => {
        if (ShuntParamsEnum.FlexDimID.getContextValue(shuntParams) != null) {
            return ShuntParamsEnum.FlexDimID.getContextValue(shuntParams);
        }
        else {
            return "";
        }
    }

    const checkCalcMode = () => {
        if (DriftParamEnum.CalcMode.getContextValue(driftParams) === "0" || DriftParamEnum.CalcMode.getContextValue(driftParams) === 0) {
            return false;
        }
        else {
            return true;
        }
    }

    const setOrgUrl = () => {
        if (ShuntParamsEnum.ControlValve.getContextValue(shuntParams) != null && ShuntParamsEnum.ControlValve.getContextValue(shuntParams) !== "" &&
            ShuntParamsEnum.FlexModel.getContextValue(shuntParams) != null && ShuntParamsEnum.FlexModel.getContextValue(shuntParams) !== "" && !checkCalcMode()) {
            return CONTROL_VALVES_PATH + ShuntParamsEnum.ControlValve.getContextValue(shuntParams) + '/Models/' + ShuntParamsEnum.FlexModel.getContextValue(shuntParams) + "/Dims";
        }
        else {
            return CONTROL_VALVES_DIMS_FLEX_DEFAULT_PATH;
        }
    }

    const [fsUrl, setFSUrl] = useState(setOrgUrl())
    const [models, setModels] = useState([]);
    const [selectedFlow, setSelectedFlow] = useState(setDimID());



    const isDisabled = () => {
        if ((SessionParamsEnum.Editable.getContextValue(sessionParams) === false && devParams.state.val.devMode !== true) ||
            (DriftParamEnum.CalcMode.getContextValue(driftParams) !== "0" && DriftParamEnum.CalcMode.getContextValue(driftParams) !== 0)) {
            return true;
        }
        else {
            return false;
        }
    }
    /*
    const resetMaxStep = () => {
        if (SessionParamsEnum.MaxStep.getContextValue(sessionParams) !== SessionParamsEnum.CurrentStep.getContextValue(sessionParams)) {
            SessionParamsEnum.MaxStep.setContextValue(sessionParams, SessionParamsEnum.CurrentStep.getContextValue(sessionParams), database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
        }
    }*/

    const setValue = (value) => {
        resetMaxStepFunc(sessionParams, database);
        CalcParamsEnum.clearData(calcParams);
        ShuntParamsEnum.FlexDimID.setContextValue(shuntParams, value);
        setSelectedFlow(value);
    };

    useEffect(() => {
        if (CalcParamsEnum.FlexSVDim.getContextValue(calcParams) != null && CalcParamsEnum.FlexSVDim.getContextValue(calcParams) !== "") {
            ShuntParamsEnum.FlexDimID.setContextValue(shuntParams, CalcParamsEnum.FlexSVDim.getContextValue(calcParams));
            setSelectedFlow(CalcParamsEnum.FlexSVDim.getContextValue(calcParams));
        }

    }, [CalcParamsEnum.FlexSVDim.getContextValue(calcParams)])

    useEffect(() => {
        if (SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true){
            if (ShuntParamsEnum.ControlValve.getContextValue(shuntParams) != null && ShuntParamsEnum.ControlValve.getContextValue(shuntParams) !== "" &&
                ShuntParamsEnum.FlexModel.getContextValue(shuntParams) != null && ShuntParamsEnum.FlexModel.getContextValue(shuntParams) !== "") {
                setFSUrl(CONTROL_VALVES_PATH + ShuntParamsEnum.ControlValve.getContextValue(shuntParams) + '/Models/' + ShuntParamsEnum.FlexModel.getContextValue(shuntParams) + "/Dims");
            }
            else {
                setFSUrl(CONTROL_VALVES_DIMS_FLEX_DEFAULT_PATH);
            }
        }
       
    }, [ShuntParamsEnum.ControlValve.getContextValue(shuntParams), ShuntParamsEnum.FlexModel.getContextValue(shuntParams), SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams)])

    useEffect(() => {
        if (fsUrl != null && fsUrl !== '') {
            const optionsDoc = collection(firestore, fsUrl);

            getDocs(optionsDoc).then((data) => {
                const localModels = [];
                localModels.push('');

                data.forEach((model) => {
                    localModels.push({ id: model.id, name: model.data().display, flow: roundToDecimals(model.data().qMax[0], 3) + " - " + roundToDecimals(model.data().qMax[model.data().qMax.length - 1], 3) })
                });

                if (localModels.length > 0 && ShuntParamsEnum.FlexModel.getContextValue(shuntParams) != null && ShuntParamsEnum.FlexModel.getContextValue(shuntParams) !== "") {
                    setModels(localModels);
                    if (selectedFlow == null || selectedFlow === "") {
                        // @ts-ignore
                        setSelectedFlow(localModels[0].id);
    
                        // @ts-ignore
                        ShuntParamsEnum.FlexDimID.setContextValue(shuntParams, localModels[0].id);
                    }
    
                }
                else {
                    setModels(['']);
    
                    setSelectedFlow('');
                    ShuntParamsEnum.FlexDimID.setContextValue(shuntParams, null);
                }
            });
        }
    }, [fsUrl])

    /*
    useEffect(() => {

        if (firestoreRes.data != null) {
            const localModels = [];
            localModels.push('');
            firestoreRes.data.forEach(model => {
                console.log("DATAROW. ", model.id)
                localModels.push({ id: model.id, name: model.data().display, flow: roundToDecimals(model.data().qMax[0], 3) + " - " + roundToDecimals(model.data().qMax[model.data().qMax.length - 1], 3) })
            });

            if (localModels.length > 0 && ShuntParamsEnum.FlexModel.getContextValue(shuntParams) != null && ShuntParamsEnum.FlexModel.getContextValue(shuntParams) !== "") {
                setModels(localModels);
                if (selectedFlow == null || selectedFlow === "") {
                    // @ts-ignore
                    setSelectedFlow(localModels[0].id);

                    // @ts-ignore
                    ShuntParamsEnum.FlexDimID.setContextValue(shuntParams, localModels[0].id);
                }

            }
            else {
                setModels(['']);

                setSelectedFlow('');
                ShuntParamsEnum.FlexDimID.setContextValue(shuntParams, null);
            }

        }

    }, [firestoreRes])*/



    return (
        <>
            {!checkCalcMode() ? (
                <>
                    <Stack direction={"row"}>
                        <div style={{ marginTop: "14px" }}>
                            Flödesområde:
                        </div>
                        <Select
                            style={{ marginLeft: "auto", width: "50%", marginTop: "7px" }}
                            size={"small"}
                            value={selectedFlow || ""}
                            margin="dense"
                            onChange={(event) => { setValue(event.target.value) }}
                            disabled={isDisabled()}
                        >
                            {models.map((model, index) => {
                                if (model != null && model !== "") {
                                    return (<MenuItem key={index} value={model?.id}>{model?.flow} ({model?.name})</MenuItem>)
                                }
                                else {
                                    return (<MenuItem key={index} value={model?.id}>{model?.flow}</MenuItem>)
                                }
                            })}

                        </Select>
                    </Stack>
                </>
            ) : (
                <></>
            )}
        </>
    );
};