import { PRODUCTPRINCIP_PR3, PRODUCTPRINCIP_SR2, PRODUCTPRINCIP_SR3, PRODUCTPRINCIP_VA1 } from "../../constants/productlineConstants";

const checkNumOfCheckValves = function checkNumOfCheckValves(plPrincipVal, numBackVents) {
    let backVents = 1;
    if (plPrincipVal === PRODUCTPRINCIP_PR3) {
        backVents += 1;
    }
    else if (plPrincipVal === PRODUCTPRINCIP_VA1) {
        backVents -= 1;
    }
    else if ((plPrincipVal === PRODUCTPRINCIP_SR3 || plPrincipVal === PRODUCTPRINCIP_SR2) && numBackVents === "0") {
        backVents -= 1;
    }
    
    return backVents;
}

const checkNumOfAdjustmentValves = function checkNumOfAdjustmentValves(plPrincipVal, ivMod) {
    let numTotalIV = 2;
    if (plPrincipVal === PRODUCTPRINCIP_VA1) {
        numTotalIV -= 1;
    }
    if (ivMod != null){
        numTotalIV += Number(ivMod);
    }

    return numTotalIV;
}

export { checkNumOfCheckValves, checkNumOfAdjustmentValves }