import { get, ref, set } from "firebase/database";
import { CABINET_INSULATED, CABINET_UNINSULATED, CUTOFF_190S_115TE, CUTOFF_3601_2301, CUTOFF_3601_2310, CUTOFF_3701_2301, CUTOFF_BA107S_BA115TE, CUTOFF_GENERIC, CUTOFF_GENERIC_LOW_LEAD, CUTOFF_VM4205_3001, MANOMETER_10_BAR, MANOMETER_2_5_BAR, MANOMETER_4_BAR, MANOMETER_6_BAR, MATERIAL_PAINTED, MATERIAL_STAINLESS, NO_MANOMETER, STAND_FLOOR, STAND_WALL, THERMO_BIMETALLIC, THERMO_QVINTUS, THERMO_SPRIT110MM } from "../../constants/adjustmentsOptions";
import { API_AdjustValveLables, API_DimSizes } from "../../constants/apiLabels";
import { SESSION_PATH, SESSION_SHUNT_PATH } from "../../constants/firebasePaths";
import { PRODUCTLINE_FLEX, PRODUCTLINE_GREEN, PRODUCTLINE_STANDARD, PRODUCTPRINCIP_PR2, PRODUCTPRINCIP_PR3, PRODUCTPRINCIP_SR2, PRODUCTPRINCIP_SR3, PRODUCTPRINCIP_VA1 } from "../../constants/productlineConstants";
import { PUMP_GENERAL_REQ, PUMP_GRUNDFOS, PUMP_KSB, PUMP_PREMA_CHOICE, PUMP_SPECIFIC_REQ, PUMP_WILO, PUMP_XYLEM } from "../../constants/pumpOptions";
import { AdjustmentsEnum, CalcParamsEnum, DriftParamEnum, FSDataEnum, ProductLineEnum, SessionParamsEnum, ShuntParamsEnum } from "../../enums";
import { PumpParamEnum } from "../../enums/pumpParamsEnum";
import { saveSessionData } from "../../firebase/functions";
import { loadShuntFromFirebase } from "../../firebase/functions/loadShuntFromFirebase";
import { getXmlDataFromFB } from "../../firebase/getXmlDataFromFB";
import { recalculateShunts } from "../ShuntCalculations/helpFunctions/recalculateList";
import { getSpecialFlexDim } from "../../firebase/functions/getSpecialFlexDim";
import { savePluginData } from "../../firebase/functions/savePluginData";

export const parseXmlFile = async (database, firestore, sessionID, method, driftParams, shuntParams, calcParams, adjustParams, pumpParams, productLineParams, errorProvider, noticeProvider, sessionParams, fsDataParams, wizardParams, coolingEnergyMeasurementList, heatEnergyMeasurementList, systemFluidOptions) => {
    const data = await getXmlDataFromFB(database, sessionID);
    const sessionRef = ref(database, SESSION_PATH + sessionID);


    //console.log("Xml: ", data);
    let pluginVersion = getObjectValue(data?.mc_data?.plugin_ver);
    let fbMethod;
    let plugin;

    if (method === 'INS' || method === 'UPD') {
        plugin = 'Revit';
    }
    else {
        plugin = 'AutoCad';
    }


    DriftParamEnum.clearData(driftParams);

    if (method === 'create' || method === 'INS') {
        fbMethod = 'create';
        try {
            await SessionParamsEnum.addSessionShunt(sessionParams, database, true, shuntParams, null, sessionID);
            await parseAutoCadXML(method, sessionID, data?.mc_data, driftParams, productLineParams, shuntParams, sessionParams, adjustParams, pumpParams);
        } catch (error) {
            console.error("Could not parse data! - ", error);
            return;
        }

        console.log("Plugin-values (Create):", plugin, pluginVersion, fbMethod);
        savePluginData(database, sessionID, plugin, pluginVersion, fbMethod);
    }
    else if (method === 'update' || method === 'UPD') {
        let sessionData = await get(sessionRef);

        if (sessionData.exists() === false) {
            saveSessionData(sessionID, database, sessionParams)
        }

        console.log("Shunt: ", data?.mc_data?.project?.shunt?.length);
        if (data?.mc_data?.project?.shunt?.length != null) {
            if (data?.mc_data?.project?.shunt?.length > 1) {
                console.log("Bulk Update!");
                fbMethod = 'bulkUpdate';
                SessionParamsEnum.ApiIsBulk.setContextValue(sessionParams, true);
                const shuntList = []
                for (let index = 0; index < data?.mc_data?.project?.shunt.length; index++) {
                    const element = data?.mc_data?.project?.shunt[index];
                    let shuntID = getObjectValue(element?.shunt_id);
                    let position = Number(getObjectValue(element?.position));
                    console.log("Position: ", position)

                    if (shuntID != null) {
                        const shuntRef = ref(database, SESSION_SHUNT_PATH + shuntID);
                        let shuntData = await get(shuntRef);
                        if (shuntData.exists()) {
                            CalcParamsEnum.clearDataFromFB(calcParams, database, shuntID);
                        }

                        if (position != null && isNaN(position) === false) {
                            await SessionParamsEnum.createSessionShunt(sessionParams, database, shuntID, true, shuntParams, position);
                        }
                        else {
                            await SessionParamsEnum.createSessionShunt(sessionParams, database, shuntID, true, shuntParams);
                        }
                        /*
                        const shuntRef = ref(database, SESSION_SHUNT_PATH + shuntID);
                        let shuntData = await get(shuntRef);
                        if (shuntData.exists()) {
                            if (position != null && isNaN(position) === false){
                                await loadShuntFromFirebase(shuntID, database, sessionParams, driftParams, shuntParams, calcParams, adjustParams, pumpParams, productLineParams, errorProvider, noticeProvider, position);
                            }
                            else{
                                await loadShuntFromFirebase(shuntID, database, sessionParams, driftParams, shuntParams, calcParams, adjustParams, pumpParams, productLineParams, errorProvider, noticeProvider);
                            }
                            //CalcParamsEnum.clearDataFromFB(calcParams, database, shuntID);
                        }
                        else {
                            if (position != null && isNaN(position) === false) {
                                await SessionParamsEnum.createSessionShunt(sessionParams, database, shuntID, true, shuntParams, position);
                            }
                            else {
                                await SessionParamsEnum.createSessionShunt(sessionParams, database, shuntID, true, shuntParams);
                            }
                        }*/
                    }
                    else {
                        shuntID = await SessionParamsEnum.addSessionShunt(sessionParams, database, true, shuntParams);
                    }
                    shuntList.push(shuntID);

                    try {
                        await parseAutoCadXML(method, sessionID, element, driftParams, productLineParams, shuntParams, sessionParams, adjustParams, pumpParams, true, database, shuntID);
                    } catch (error) {
                        console.error("Could not parse data! - ", error);
                        return;
                    }
                }

                await calculateShunts(shuntList, database, firestore, sessionParams, fsDataParams, productLineParams, driftParams, calcParams, shuntParams, errorProvider, noticeProvider, coolingEnergyMeasurementList, heatEnergyMeasurementList, systemFluidOptions)
                await loadShuntFromFirebase(shuntList[0], database, sessionParams, driftParams, shuntParams, calcParams, adjustParams, pumpParams, productLineParams, wizardParams, errorProvider, noticeProvider);
                SessionParamsEnum.OpenOverview.setContextValue(sessionParams, true);
            }
            else if (data?.mc_data?.project?.shunt?.length === 1) {
                console.log("Single Update! (loop)");
                SessionParamsEnum.ApiIsBulk.setContextValue(sessionParams, false);
                fbMethod = 'update';
                for (let index = 0; index < data?.mc_data?.project?.shunt.length; index++) {
                    const element = data?.mc_data?.project?.shunt[index];
                    let shuntID = getObjectValue(element?.shunt_id);
                    let position = Number(getObjectValue(element?.position));
                    if (shuntID != null) {
                        const shuntRef = ref(database, SESSION_SHUNT_PATH + shuntID);
                        let shuntData = await get(shuntRef);
                        if (shuntData.exists()) {
                            CalcParamsEnum.clearDataFromFB(calcParams, database, shuntID);
                            if (position != null && isNaN(position) === false) {
                                await SessionParamsEnum.createSessionShunt(sessionParams, database, shuntID, true, shuntParams, position);
                            }
                            else {
                                await SessionParamsEnum.createSessionShunt(sessionParams, database, shuntID, true, shuntParams);
                            }
                            //await loadShuntFromFirebase(shuntID, database, sessionParams, driftParams, shuntParams, calcParams, adjustParams, pumpParams, productLineParams, errorProvider, noticeProvider);

                        }
                        else {
                            if (position != null && isNaN(position) === false) {
                                await SessionParamsEnum.createSessionShunt(sessionParams, database, shuntID, true, shuntParams, position);
                            }
                            else {
                                await SessionParamsEnum.createSessionShunt(sessionParams, database, shuntID, true, shuntParams);
                            }
                        }
                    }
                    else {
                        shuntID = await SessionParamsEnum.addSessionShunt(sessionParams, database, true, shuntParams);
                    }

                    try {
                        parseAutoCadXML(method, sessionID, element, driftParams, productLineParams, shuntParams, sessionParams, adjustParams, pumpParams);
                    } catch (error) {
                        console.error("Could not parse data! - ", error);
                        return;
                    }
                }
            }
        }
        else if (data?.mc_data?.project?.shunt != null) {
            console.log("Single Update!", data?.mc_data?.project);
            SessionParamsEnum.ApiIsBulk.setContextValue(sessionParams, false);
            fbMethod = 'update';
            
            let shuntID = getObjectValue(data?.mc_data?.project?.shunt?.shunt_id);
            let position = Number(getObjectValue(data?.mc_data?.project?.shunt?.position));
            console.log(shuntID, position);
            if (shuntID != null) {
                const shuntRef = ref(database, SESSION_SHUNT_PATH + shuntID);
                let shuntData = await get(shuntRef);
                if (shuntData.exists()) {
                    CalcParamsEnum.clearDataFromFB(calcParams, database, shuntID);
                    if (position != null && isNaN(position) === false) {
                        await SessionParamsEnum.createSessionShunt(sessionParams, database, shuntID, true, shuntParams, position);
                    }
                    else {
                        await SessionParamsEnum.createSessionShunt(sessionParams, database, shuntID, true, shuntParams);
                    }
                }
                else {
                    if (position != null && isNaN(position) === false) {
                        await SessionParamsEnum.createSessionShunt(sessionParams, database, shuntID, true, shuntParams, position);
                    }
                    else {
                        await SessionParamsEnum.createSessionShunt(sessionParams, database, shuntID, true, shuntParams);
                    }
                }
            }
            else {
                await SessionParamsEnum.addSessionShunt(sessionParams, database, true, shuntParams);
            }

            try {
                parseAutoCadXML(method, sessionID, data?.mc_data?.project?.shunt, driftParams, productLineParams, shuntParams, sessionParams, adjustParams, pumpParams);
            } catch (error) {
                console.error("Could not parse data! - ", error);
                return;
            }
        }

        console.log("Plugin-values (Update): ", plugin, pluginVersion, fbMethod);
        savePluginData(database, sessionID, plugin, pluginVersion, fbMethod);
    }


}

// @ts-ignore
const parseRevitXML = (method, data, driftParams, productLineParams) => {
    if (method === 'INS') {
        console.error("Not supported at the moment!");
        if (data?.prim_temp_sup_c != null) {
            DriftParamEnum.PrimTempIn.setContextValue(driftParams, String(data?.prim_temp_sup_c));
            DriftParamEnum.LockedPrimTempIn.setContextValue(driftParams, true);
        }
        if (data?.prim_temp_ret_c != null) {
            DriftParamEnum.PrimTempOut.setContextValue(driftParams, String(data?.prim_temp_ret_c));
            DriftParamEnum.LockedPrimTempOut.setContextValue(driftParams, true);
        }
        if (data?.sec_flow_ls != null) {
            DriftParamEnum.SecFlow.setContextValue(driftParams, String(data?.sec_flow_ls));
            DriftParamEnum.LockedSecFlow.setContextValue(driftParams, true);
        }
        if (data?.sec_dp_pa != null) {
            DriftParamEnum.SecExternalPressureDrop.setContextValue(driftParams, String(Number(data?.sec_dp_pa) / 1000));
            DriftParamEnum.LockedSecExternalPressureDrop.setContextValue(driftParams, true);
        }
        if (data?.primary_dp_pa != null) {
            DriftParamEnum.PrimDrivingPressure.setContextValue(driftParams, String(Number(data?.primary_dp_pa) / 1000));
            DriftParamEnum.LockedPrimDrivingPressure.setContextValue(driftParams, true);
        }
        if (data?.sec_temp_sup_c != null) {
            DriftParamEnum.SecTempIn.setContextValue(driftParams, String(data?.sec_temp_sup_c));
            DriftParamEnum.LockedSecTempIn.setContextValue(driftParams, true);
        }
        if (data?.sec_temp_ret_c != null) {
            DriftParamEnum.SecTempOut.setContextValue(driftParams, String(data?.sec_temp_ret_c));
            DriftParamEnum.LockedSecTempOut.setContextValue(driftParams, true);
        }
    }
    else if (method === 'UPD') {
        //console.log("Shunt: ", Array(data?.shunt).length);
        console.error("Not supported at the moment!");
    }
}

const parseAutoCadXML = async (method, sessionID, data, driftParams, productLineParams, shuntParams, sessionParams, adjustParams, pumpParams, isBulk = false, database = null, shuntID = null) => {
    if (data?.proj_num != null) {
        SessionParamsEnum.ProjNr.setContextValue(sessionParams, data?.proj_num.text);
    }
    if (data?.primary_temp_supply_c != null) {
        if (isBulk === true) {
            await DriftParamEnum.PrimTempIn.setToFirebase(database, shuntID, Number(getObjectValue(data?.primary_temp_supply_c)));
        }
        DriftParamEnum.PrimTempIn.setContextValue(driftParams, Number(getObjectValue(data?.primary_temp_supply_c)));
        DriftParamEnum.LockedPrimTempIn.setContextValue(driftParams, true);
    }
    if (data?.primary_temp_return_c != null) {
        if (isBulk === true) {
            await DriftParamEnum.PrimTempOut.setToFirebase(database, shuntID, Number(getObjectValue(data?.primary_temp_return_c)));
        }
        DriftParamEnum.PrimTempOut.setContextValue(driftParams, Number(getObjectValue(data?.primary_temp_return_c)));
        DriftParamEnum.LockedPrimTempOut.setContextValue(driftParams, true);
    }
    if (data?.primary_flow_ls != null) {
        if (isBulk === true) {
            await DriftParamEnum.PrimFlow.setToFirebase(database, shuntID, Number(getObjectValue(data?.primary_flow_ls)));
        }
        DriftParamEnum.PrimFlow.setContextValue(driftParams, Number(getObjectValue(data?.primary_flow_ls)));
        DriftParamEnum.LockedPrimFlow.setContextValue(driftParams, true);
    }
    if (data?.secondary_flow_ls != null) {
        if (isBulk === true) {
            await DriftParamEnum.SecFlow.setToFirebase(database, shuntID, Number(getObjectValue(data?.secondary_flow_ls)));
        }
        DriftParamEnum.SecFlow.setContextValue(driftParams, Number(getObjectValue(data?.secondary_flow_ls)));
        DriftParamEnum.LockedSecFlow.setContextValue(driftParams, true);
    }
    if (data?.secondary_dp_pa != null) {
        if (isBulk === true) {
            await DriftParamEnum.SecExternalPressureDrop.setToFirebase(database, shuntID, Number(Number(getObjectValue(data?.secondary_dp_pa)) / 1000).toFixed(2));
        }
        DriftParamEnum.SecExternalPressureDrop.setContextValue(driftParams, Number(Number(getObjectValue(data?.secondary_dp_pa)) / 1000).toFixed(2));
        DriftParamEnum.LockedSecExternalPressureDrop.setContextValue(driftParams, true);
    }
    if (data?.primary_dp_pa != null) {
        if (isBulk === true) {
            await DriftParamEnum.PrimDrivingPressure.setToFirebase(database, shuntID, Number(Number(getObjectValue(data?.primary_dp_pa)) / 1000).toFixed(2));
        }
        DriftParamEnum.PrimDrivingPressure.setContextValue(driftParams, Number(Number(getObjectValue(data?.primary_dp_pa)) / 1000).toFixed(2));
        DriftParamEnum.LockedPrimDrivingPressure.setContextValue(driftParams, true);
    }
    if (data?.secondary_temp_supply_c != null) {
        if (isBulk === true) {
            await DriftParamEnum.SecTempIn.setToFirebase(database, shuntID, Number(getObjectValue(data?.secondary_temp_supply_c)));
        }
        DriftParamEnum.SecTempIn.setContextValue(driftParams, Number(getObjectValue(data?.secondary_temp_supply_c)));
        DriftParamEnum.LockedSecTempIn.setContextValue(driftParams, true);
    }
    if (data?.secondary_temp_return_c != null) {
        if (isBulk === true) {
            await DriftParamEnum.SecTempOut.setToFirebase(database, shuntID, Number(getObjectValue(data?.secondary_temp_return_c)));
        }
        DriftParamEnum.SecTempOut.setContextValue(driftParams, Number(getObjectValue(data?.secondary_temp_return_c)));
        DriftParamEnum.LockedSecTempOut.setContextValue(driftParams, true);
    }
    if (data?.dim_automatic != null) {
        DriftParamEnum.CalcMode.setContextValue(driftParams, String(getObjectValue(data?.dim_automatic)));
    }

    if (method === 'update' || method === 'UPD') {
        if (isBulk === true) {
            await set(ref(database, SESSION_SHUNT_PATH + shuntID + "/Baseinfo/curStep"), 1);
            await set(ref(database, SESSION_SHUNT_PATH + shuntID + "/Baseinfo/maxStep"), 1);
        }
        SessionParamsEnum.CurrentStep.setContextValue(sessionParams, 1);
        SessionParamsEnum.MaxStep.setContextValue(sessionParams, 1);

        let prodLine;
        if (data?.valve_configuration != null && Number(getObjectValue(data?.valve_configuration)) === 1) {
            prodLine = PRODUCTLINE_FLEX;
        }
        else {
            if (data?.qmodel_params != null) {
                console.log(data?.qmodel_params, getObjectValue(data?.qmodel_params))
                let typeArr = String(getObjectValue(data?.qmodel_params)).match(/LOGO=([0-9]*)/);
                let type = "";
                if (typeArr != null) {
                    type = typeArr[0];
                }
                console.log("Match:", type);
                if (type === "LOGO=2") {
                    prodLine = PRODUCTLINE_GREEN;
                }
                else {
                    prodLine = PRODUCTLINE_STANDARD;
                }
            }
            else {
                prodLine = PRODUCTLINE_STANDARD;
            }
        }

        if (isBulk === true) {
            console.log("Set to FB: ", prodLine, "Shunt:", shuntID);
            await ProductLineEnum.ProductLine.setToFirebase(database, shuntID, prodLine);
        }
        ProductLineEnum.ProductLine.setContextValue(productLineParams, prodLine);

        if (data?.product_id != null) {
            let productPrincip;

            let type = String(getObjectValue(data.product_id)).split('-')[0].substring(0, 2);
            let design = String(getObjectValue(data.product_id)).split('-')[0].substring(2, 3);
            let value = String(getObjectValue(data.product_id)).split('-')[2];
            let num = Number(String(getObjectValue(data.product_id)).split('-')[1]);
            console.log("Type: ", type, num, design)
            if (type === 'SR') {
                if (num === 2) {
                    productPrincip = PRODUCTPRINCIP_SR2;
                }
                else if (num === 3) {
                    productPrincip = PRODUCTPRINCIP_SR3;
                }
            }
            else if (type === 'PR') {
                if (num === 2) {
                    productPrincip = PRODUCTPRINCIP_PR2;
                }
                else if (num === 3) {
                    productPrincip = PRODUCTPRINCIP_PR3;
                }
            }
            else if (type === 'VA') {
                productPrincip = PRODUCTPRINCIP_VA1;
            }

            if (isBulk === true) {
                console.log("Set to FB: ", productPrincip, "", shuntID);

                await ProductLineEnum.ProductPrincip.setToFirebase(database, shuntID, productPrincip);

                if (design != null && design !== "") {
                    await AdjustmentsEnum.ConnectionDesign.setToFirebase(database, shuntID, design.toLocaleLowerCase());
                }

                if (value != null && isNaN(Number(value)) === true) {
                    await AdjustmentsEnum.ConnectionValue.setToFirebase(database, shuntID, value.toLocaleLowerCase());
                }
                else {
                    await AdjustmentsEnum.ConnectionValue.setToFirebase(database, shuntID, '_');
                }
            }

            ProductLineEnum.ProductPrincip.setContextValue(productLineParams, productPrincip);

            if (design != null && design !== "") {
                AdjustmentsEnum.ConnectionDesign.setContextValue(adjustParams, design.toLocaleLowerCase());
            }

            if (value != null && isNaN(Number(value)) === true) {
                AdjustmentsEnum.ConnectionValue.setContextValue(adjustParams, value.toLocaleLowerCase());
            }
            else {
                AdjustmentsEnum.ConnectionValue.setContextValue(adjustParams, '_');
            }
        }

        if (data?.user_code != null) {
            if (isBulk === true) {
                await set(ref(database, SESSION_SHUNT_PATH + shuntID + "/Baseinfo/designation"), getObjectValue(data?.user_code));
                await set(ref(database, SESSION_PATH + sessionID + "/shuntDesignations/" + shuntID), getObjectValue(data?.user_code));
            }
            ShuntParamsEnum.ShuntDesignation.setContextValue(shuntParams, getObjectValue(data?.user_code));
        }

        if (data?.BOM_data != null) {
            let rows = getBOMData(data?.BOM_data)
            console.log("Rows", rows)

            if (method === 'update' || method === 'UPD') {
                let primDim = rows.find(element => element.id === "RegValvePrim");
                let secDim = rows.find(element => element.id === "RegValveSec");

                if (prodLine === PRODUCTLINE_FLEX) {
                    primDim = rows.find(element => element.id === "CutOffValvePrim");
                    secDim = rows.find(element => element.id === "CutOffValveSec");
                }

                let shuntGroup = rows.find(element => element.id === "ShuntGroup");

                let fields = String(shuntGroup?.text).split(' - ');
                let dimsInfo = fields.find(element => element.includes("DN"));
                let dims = dimsInfo.split(' ')[0];

                let primDimVal = dims.split('/')[0];
                let secDimVal = dims.split('/')[1];

                console.log("Data: ", primDimVal, secDimVal)

                if (primDim != null && secDim != null) {
                    let cvName = primDim.text.split(' ')[0];

                    if (isBulk === true) {
                        if (API_AdjustValveLables[cvName] != null) {
                            await ShuntParamsEnum.AdjustmentValve.setToFirebase(database, shuntID, API_AdjustValveLables[cvName]);
                        }
                        else {
                            console.log("Name", cvName);
                            await ShuntParamsEnum.AdjustmentValve.setToFirebase(database, shuntID, "StadF");
                        }
                    }
                    if (API_AdjustValveLables[cvName] != null) {
                        ShuntParamsEnum.AdjustmentValve.setContextValue(shuntParams, API_AdjustValveLables[cvName]);
                    }
                    else {
                        console.log("Name", cvName);
                        ShuntParamsEnum.AdjustmentValve.setContextValue(shuntParams, "StadF");
                    }

                    if (cvName === "STAD/F" || cvName == null || cvName === "") {
                        if (isBulk === true) {
                            if (String(primDim.text).toLocaleUpperCase().includes('ZERO')) {
                                await AdjustmentsEnum.AdjustValue.setToFirebase(database, shuntID, "STAD ZERO");
                            }
                            else {
                                await AdjustmentsEnum.AdjustValue.setToFirebase(database, shuntID, "STAD");
                            }
                        }

                        if (String(primDim.text).toLocaleUpperCase().includes('ZERO')) {
                            AdjustmentsEnum.AdjustValue.setContextValue(adjustParams, "STAD ZERO");
                        }
                        else {
                            AdjustmentsEnum.AdjustValue.setContextValue(adjustParams, "STAD");
                        }
                    }

                    if (primDimVal != null) {
                        let dimValue;

                        if (String(primDimVal).includes('DN020.') === true) {
                            dimValue = API_DimSizes[primDimVal];
                        }
                        else {
                            dimValue = primDimVal;
                        }

                        if (isBulk === true) {
                            await DriftParamEnum.PrimaryDim.setToFirebase(database, shuntID, dimValue);
                        }

                        DriftParamEnum.PrimaryDim.setContextValue(driftParams, dimValue);

                    }

                    if (secDimVal != null) {
                        let dimValue;

                        if (String(secDimVal).includes('DN020.') === true) {
                            dimValue = API_DimSizes[secDimVal];
                        }
                        else {
                            dimValue = secDimVal
                        }

                        if (isBulk === true) {
                            await DriftParamEnum.SecondaryDim.setToFirebase(database, shuntID, dimValue);
                        }

                        DriftParamEnum.SecondaryDim.setContextValue(driftParams, dimValue);
                    }
                }

                let controlValve = rows.find(element => element.id === "ControlValve");
                console.log("CV: ", controlValve?.text)
                await getShuntControlValve(controlValve?.text, prodLine, shuntParams, isBulk, database, shuntID);

                let extraLatch = rows.find(element => element.id === "BackValveExtra");
                await hasExtraLatch(extraLatch?.text, shuntParams, isBulk, database, shuntID);

                //Adjustment Params
                let material = rows.find(element => element.id === "Material");
                getAdjustmentMaterial(material?.text, adjustParams, isBulk, database, shuntID);

                let thermo = rows.find(element => element.id === "Thermometers");
                getAdjustmentThermo(thermo?.text, adjustParams, isBulk, database, shuntID);

                let cutOffPrim = rows.find(element => element.id === "CutOffValveSec");
                let cutOffSec = rows.find(element => element.id === "CutOffValveSec");
                getAdjustmentCutOffValve(cutOffPrim?.text, cutOffSec?.text, adjustParams, isBulk, database, shuntID);

                let manometer = rows.find(element => element.id === "ManometerSet");
                getAdjustmentManometer(manometer?.text, adjustParams, isBulk, database, shuntID);

                let stand = rows.find(element => element.id === "Stand");
                getAdjustmentStand(stand?.text, adjustParams, isBulk, database, shuntID);

                let actuator = rows.find(element => element.id === "Actuator");
                getAdjustmentActuator(actuator?.text, adjustParams, isBulk, database, shuntID);

                let cabinet = rows.find(element => element.id === "IsolationBox");
                getAdjustmentCabinet(cabinet?.text, adjustParams, isBulk, database, shuntID);

                let dripPlate = rows.find(element => element.id === "DripPlate");
                getAdjustmentDripPlate(dripPlate?.text, adjustParams, isBulk, database, shuntID);

                let pump = rows.find(element => element.id === "Pump");
                getPumpOptions(pump?.text, pumpParams, isBulk, database, shuntID);

            }
            /*
            else if (method === 'UPD'){
                let shuntgroup = rows.find(element => element.text.includes('Premablock'));
                if (shuntgroup != null){
                    let fields = String(shuntgroup.text).split(' - ');
                    let dimsInfo = fields.find(element => element.includes("DN"));
                    let dims = dimsInfo.split(' ')[0];

                    let primDim = dims.split('/')[0];
                    let secDim = dims.split('/')[1];
                    console.log("Prim: ", primDim, "Sek: ", secDim)

                    if (primDim != null) {
                        if (String(primDim).includes('DN020.') === true) {
                            DriftParamEnum.PrimaryDim.setContextValue(driftParams, API_DimSizes[primDim]);
                        }
                        else {
                            DriftParamEnum.PrimaryDim.setContextValue(driftParams, primDim);
                        }
                    }

                    if (secDim != null) {
                        if (String(secDim).includes('DN020.') === true) {
                            DriftParamEnum.SecondaryDim.setContextValue(driftParams, API_DimSizes[secDim]);
                        }
                        else {
                            DriftParamEnum.SecondaryDim.setContextValue(driftParams, secDim);
                        }

                    }
                }

                let cvRow = rows.find(element => element.text.includes('Styrventil'));
                if (cvRow != null){
                    let cvFields = String(cvRow.text).split(', ');
                    console.log("CV: ", cvRow.text);

                    let name = cvFields[0].replace('Styrventil ', '');
                    let kvsVal = cvFields.find(element => element.includes('kvs'));
                    if (kvsVal != null){
                        kvsVal = kvsVal.split(' ')[1];
                    }

                    console.log("Val: ", name, kvsVal);

                    let cvModel;
                    if (String(name).includes('SIEMENS') === true) {
                        ShuntParamsEnum.ControlValve.setContextValue(shuntParams, 'SIEMENS');

                        //cvModel = getCVModel(name, 'SIEMENS');
                    }
                    else if (name.includes('Schneider') === true) {
                        console.log("Schneider")
                        ShuntParamsEnum.ControlValve.setContextValue(shuntParams, 'Schneider');

                        //cvModel = getCVModel(textArray, 'Schneider');
                    }
                    else if (name.includes('Honeywell') === true) {
                        ShuntParamsEnum.ControlValve.setContextValue(shuntParams, 'Honeywell');

                        //cvModel = getCVModel(textArray, 'Honeywell');
                    }
                    else if (name.includes('Sauter') === true) {
                        ShuntParamsEnum.ControlValve.setContextValue(shuntParams, 'Sauter');

                        //cvModel = getCVModel(textArray, 'Sauter');
                    }
                    else if (name.includes('Belimo') === true) {
                        ShuntParamsEnum.ControlValve.setContextValue(shuntParams, 'Belimo');

                        //cvModel = getCVModel(textArray, 'Belimo');
                    }
                    else if (name.includes('IMI') === true) {
                        ShuntParamsEnum.ControlValve.setContextValue(shuntParams, 'IMI');

                        //cvModel = getCVModel(textArray, 'IMI');
                    }
                    else {
                        ShuntParamsEnum.ControlValve.setContextValue(shuntParams, 'SoE');
                        ShuntParamsEnum.CV_Model.setContextValue(shuntParams, 'Kvs');
                    }

                    if (prodLine === PRODUCTLINE_FLEX) {
                        ShuntParamsEnum.FlexModel.setContextValue(shuntParams, cvModel);
                    }
                    else {
                        ShuntParamsEnum.CV_Kvs.setContextValue(shuntParams, Number(kvsVal));
                    }
                }

                let material = rows.find(element => element.text.includes('Material'));
                
            }*/

        }

        if (data?.function != null) {
            ShuntParamsEnum.MCFunction.setContextValue(shuntParams, getObjectValue(data?.function))
        }

    }
}

const getObjectValue = (inData) => {
    if (inData != null) {
        if (inData['_text'] != null) {
            return inData['_text'];
        }
    }

}

const getBOMData = (inData) => {
    let newRows = [];
    if (inData?.row != null) {
        Object.keys(inData?.row).forEach((row, index) => {
            newRows.push({ index: index, id: inData?.row[row]?._attributes?.id, count: inData?.row[row]?._attributes?.count, choice: inData?.row[row]?._attributes?.choice, text: inData?.row[row]?._text })
        })
    }
    return newRows;
}

/**
 * 
 * @param {Array} stringArray 
 * @param {string} brandName 
 */
const getCVModel = (stringArray, brandName) => {
    let arrIndex = stringArray.indexOf(brandName);
    if (arrIndex !== -1) {
        let model = stringArray[arrIndex + 1];
        model = model.substring(0, model.length - 1)
        console.log("Model: ", model);
        return model;
    }

    return null;
}

const getKvsValue = (stringArray) => {
    let arrIndex = stringArray.indexOf('kvs');
    if (arrIndex !== -1) {
        let kvs = stringArray[arrIndex + 1];
        console.log("Kvs: ", kvs);
        return kvs;
    }

    return null;
}

const getShuntControlValve = async (controlValve, prodLine, shuntParams, isBulk, database = null, shuntID = null) => {
    if (controlValve != null) {
        let cvModel;
        let textArray = String(controlValve).split(' ');
        let cvBrand;

        if (String(controlValve).includes('SIEMENS') === true) {
            cvBrand = 'SIEMENS';

            cvModel = getCVModel(textArray, 'SIEMENS');
        }
        else if (controlValve.includes('Schneider') === true) {
            cvBrand = 'Schneider';

            cvModel = getCVModel(textArray, 'Schneider');
        }
        else if (controlValve.includes('Honeywell') === true) {
            cvBrand = 'Honeywell';

            cvModel = getCVModel(textArray, 'Honeywell');
        }
        else if (controlValve.includes('Sauter') === true) {
            cvBrand = 'Sauter';

            cvModel = getCVModel(textArray, 'Sauter');
        }
        else if (controlValve.includes('Belimo') === true) {
            cvBrand = 'Belimo';

            cvModel = getCVModel(textArray, 'Belimo');
        }
        else if (controlValve.includes('IMI') === true) {
            cvBrand = 'IMI';

            cvModel = getCVModel(textArray, 'IMI');
        }
        else {
            cvBrand = 'SoE';
            cvModel = "Kvs";
        }

        let kvs = getKvsValue(textArray);

        if (isBulk === true) {
            await ShuntParamsEnum.ControlValve.setToFirebase(database, shuntID, cvBrand);

            if (prodLine === PRODUCTLINE_FLEX) {
                await ShuntParamsEnum.FlexModel.setToFirebase(database, shuntID, cvModel);
            }
            else {
                await ShuntParamsEnum.CV_Model.setToFirebase(database, shuntID, cvModel);
                if (isNaN(kvs) === false) {
                    await ShuntParamsEnum.CV_Kvs.setToFirebase(database, shuntID, Number(kvs));
                }
                else {
                    await ShuntParamsEnum.CV_Kvs.setToFirebase(database, shuntID, kvs);
                }

            }
        }

        ShuntParamsEnum.ControlValve.setContextValue(shuntParams, cvBrand);

        if (prodLine === PRODUCTLINE_FLEX) {
            ShuntParamsEnum.FlexModel.setContextValue(shuntParams, cvModel);
        }
        else {
            ShuntParamsEnum.CV_Model.setContextValue(shuntParams, cvModel);
            if (isNaN(kvs) === false) {
                ShuntParamsEnum.CV_Kvs.setContextValue(shuntParams, Number(kvs));
            }
            else {
                ShuntParamsEnum.CV_Kvs.setContextValue(shuntParams, kvs);
            }

        }

    }
}

const getAdjustmentCutOffValve = (cutOffPrim, cutOffSec, adjustParams, isBulk, database = null, shuntID = null) => {
    if (cutOffSec != null && cutOffPrim != null) {
        let cutOffValue;

        if (cutOffPrim.includes('3601')) {
            if (cutOffSec.includes('2301')) {
                cutOffValue = CUTOFF_3601_2301;
            }
            else {
                cutOffValue = CUTOFF_3601_2310;
            }
        }
        if (cutOffPrim.includes('3701')) {
            cutOffValue = CUTOFF_3701_2301;
        }
        else if (cutOffPrim.includes('BA107S')) {
            cutOffValue = CUTOFF_BA107S_BA115TE;
        }
        else if (cutOffPrim.includes('VM4205')) {
            cutOffValue = CUTOFF_VM4205_3001;
        }
        else if (cutOffPrim.includes('190S')) {
            cutOffValue = CUTOFF_190S_115TE;
        }
        else if (cutOffPrim.includes('standard')) {
            cutOffValue = CUTOFF_GENERIC;
        }
        else if (cutOffPrim.includes('lågblyig')) {
            cutOffValue = CUTOFF_GENERIC_LOW_LEAD;
        }
        else {
            cutOffValue = CUTOFF_3601_2301;
        }

        if (isBulk === true) {
            AdjustmentsEnum.CutOffValue.setToFirebase(database, shuntID, cutOffValue);
        }

        AdjustmentsEnum.CutOffValue.setContextValue(adjustParams, cutOffValue);
    }
}

const getAdjustmentThermo = (thermoVal, adjustParams, isBulk, database = null, shuntID = null) => {
    if (thermoVal != null) {
        console.log("Termometer:", thermoVal);
        let thermoValue;
        if (thermoVal.includes('110mm')) {
            thermoValue = THERMO_SPRIT110MM;
        }
        else if (thermoVal.includes('Qvintus')) {
            thermoValue = THERMO_QVINTUS;
        }
        else {
            thermoValue = THERMO_BIMETALLIC;
        }

        if (isBulk === true) {
            AdjustmentsEnum.ThermoValue.setToFirebase(database, shuntID, thermoValue);
        }

        AdjustmentsEnum.ThermoValue.setContextValue(adjustParams, thermoValue);

    }
}

const getAdjustmentMaterial = (material, adjustParams, isBulk, database = null, shuntID = null) => {
    if (material != null) {
        console.log("Material:", material)
        let matValue;

        if (material.includes('1.4436')) {
            matValue = MATERIAL_STAINLESS;
        }
        else {
            matValue = MATERIAL_PAINTED;
        }

        if (isBulk === true) {
            AdjustmentsEnum.MaterialValue.setToFirebase(database, shuntID, matValue);
        }

        AdjustmentsEnum.MaterialValue.setContextValue(adjustParams, matValue);


    }
}

const getAdjustmentManometer = (manometer, adjustParams, isBulk, database = null, shuntID = null) => {
    let manoValue;

    if (manometer != null) {
        console.log("Manometer:", manometer)

        if (manometer.includes('0-2')) {
            manoValue = MANOMETER_2_5_BAR;
        }
        else if (manometer.includes('0-4')) {
            manoValue = MANOMETER_4_BAR;
        }
        else if (manometer.includes('0-6')) {
            manoValue = MANOMETER_6_BAR;
        }
        else if (manometer.includes('0-10')) {
            manoValue = MANOMETER_10_BAR;
        }
        else {
            manoValue = NO_MANOMETER;
        }
    }
    else {
        manoValue = NO_MANOMETER;
    }

    if (isBulk === true) {
        AdjustmentsEnum.ManometerValue.setToFirebase(database, shuntID, manoValue);
    }

    AdjustmentsEnum.ManometerValue.setContextValue(adjustParams, manoValue);
}

const getAdjustmentStand = (stand, adjustParams, isBulk, database = null, shuntID = null) => {
    if (stand != null) {
        console.log("Stand:", stand)
        let standValue;

        if (String(stand).toLocaleLowerCase().includes('golv') === false && String(stand).toLocaleLowerCase().includes('floor') === false) {
            standValue = STAND_WALL;
        }
        else {
            standValue = STAND_FLOOR;
        }

        if (isBulk === true) {
            AdjustmentsEnum.StandValue.setToFirebase(database, shuntID, standValue);
        }

        AdjustmentsEnum.StandValue.setContextValue(adjustParams, standValue);

    }
}

const getAdjustmentActuator = (actuator, adjustParams, isBulk, database = null, shuntID = null) => {
    if (actuator != null) {
        console.log("Actuator:", actuator)
        let actuatorsValue;
        if (String(actuator).toLocaleLowerCase().includes('ställdon') || String(actuator).toLocaleLowerCase().includes('stlldon')) {
            actuatorsValue = true;
        }
        else {
            actuatorsValue = false;
        }

        if (isBulk === true) {
            AdjustmentsEnum.ActuatorsValue.setToFirebase(database, shuntID, actuatorsValue);
        }

        AdjustmentsEnum.ActuatorsValue.setContextValue(adjustParams, actuatorsValue);

    }
}

const getAdjustmentCabinet = (cabinet, adjustParams, isBulk, database = null, shuntID = null) => {
    let cabValue;

    if (cabinet != null) {
        console.log("Cabinet:", cabinet)

        if (String(cabinet).toLocaleLowerCase().includes('isolation') || String(cabinet).toLocaleLowerCase().includes('isolerskåp') || String(cabinet).toLocaleLowerCase().includes('isolerskp')) {
            cabValue = CABINET_INSULATED;
        }
        else {
            cabValue = CABINET_UNINSULATED;
        }
    }
    else {
        cabValue = CABINET_UNINSULATED;
    }

    if (isBulk === true) {
        AdjustmentsEnum.CabinetValue.setToFirebase(database, shuntID, cabValue);
    }
    AdjustmentsEnum.CabinetValue.setContextValue(adjustParams, cabValue);
}

const getAdjustmentDripPlate = (dripPlate, adjustParams, isBulk, database = null, shuntID = null) => {
    let dripPlateValue;

    if (dripPlate != null) {
        console.log("DripPlate:", dripPlate)

        if (dripPlate !== "") {
            dripPlateValue = true;
        }
        else {
            dripPlateValue = false;
        }
    }
    else {
        dripPlateValue = false;
    }

    if (isBulk === true) {
        AdjustmentsEnum.DripPlateValue.setToFirebase(database, shuntID, dripPlateValue);
    }

    AdjustmentsEnum.DripPlateValue.setContextValue(adjustParams, dripPlateValue);

}

const hasExtraLatch = async (extraLatch, shuntParams, isBulk, database = null, shuntID = null) => {
    if (extraLatch != null && extraLatch !== "") {
        if (isBulk === true) {
            await ShuntParamsEnum.HasLatch.setToFirebase(database, shuntID, 1);
        }

        ShuntParamsEnum.HasLatch.setContextValue(shuntParams, 1);

    }
}

const getPumpOptions = (pump, pumpParams, isBulk, database = null, shuntID = null) => {
    if (pump != null) {
        console.log("Pump:", pump, "IsBulk: ", isBulk)
        let pumpChoice = String(pump).split(' ')[1];

        if (pump.toLocaleLowerCase().includes('dimensioneras av prema')) {
            let genReqPump;

            if (pumpChoice === PUMP_GRUNDFOS) {
                genReqPump = PUMP_GRUNDFOS;
            }
            else if (pumpChoice === PUMP_KSB) {
                genReqPump = PUMP_KSB;
            }
            else if (pumpChoice === PUMP_WILO) {
                genReqPump = PUMP_WILO;
            }
            else if (pumpChoice === PUMP_XYLEM) {
                genReqPump = PUMP_XYLEM;
            }
            else {
                genReqPump = PUMP_PREMA_CHOICE;
            }

            if (isBulk === true) {
                PumpParamEnum.PumpRequestType.setToFirebase(database, shuntID, PUMP_GENERAL_REQ);
                PumpParamEnum.GeneralPumpRequest.setToFirebase(database, shuntID, genReqPump);
            }

            PumpParamEnum.PumpRequestType.setContextValue(pumpParams, PUMP_GENERAL_REQ);
            PumpParamEnum.GeneralPumpRequest.setContextValue(pumpParams, genReqPump);

        }
        else {
            if (isBulk === true) {
                PumpParamEnum.PumpRequestType.setToFirebase(database, shuntID, PUMP_SPECIFIC_REQ);

                if (pumpChoice != null && pumpChoice !== "") {
                    PumpParamEnum.SpecificPumpRequest.setToFirebase(database, shuntID, pumpChoice);
                }
            }

            PumpParamEnum.PumpRequestType.setContextValue(pumpParams, PUMP_SPECIFIC_REQ);

            if (pumpChoice != null && pumpChoice !== "") {
                PumpParamEnum.SpecificPumpRequest.setContextValue(pumpParams, pumpChoice);
            }
        }

        let pumpText = pump.toLocaleLowerCase();
        if (isBulk === true) {
            if (pumpText.includes('0-10 v styrning')) {
                PumpParamEnum.Steering.setToFirebase(database, shuntID, true);
            }
            if (pumpText.includes('larmutgång')) {
                PumpParamEnum.Alarm.setToFirebase(database, shuntID, true);
            }
            if (pumpText.includes('tryckstyrd')) {
                PumpParamEnum.Pressure.setToFirebase(database, shuntID, true);
            }
            if (pumpText.includes('extern')) {
                PumpParamEnum.ExternalStop.setToFirebase(database, shuntID, true);
            }
            if (pumpText.includes('kommunikationskort')) {
                PumpParamEnum.Card.setToFirebase(database, shuntID, true);
            }
        }

        if (pumpText.includes('0-10 v styrning')) {
            PumpParamEnum.Steering.setContextValue(pumpParams, true);
        }
        if (pumpText.includes('larmutgång')) {
            PumpParamEnum.Alarm.setContextValue(pumpParams, true);
        }
        if (pumpText.includes('tryckstyrd')) {
            PumpParamEnum.Pressure.setContextValue(pumpParams, true);
        }
        if (pumpText.includes('extern')) {
            PumpParamEnum.ExternalStop.setContextValue(pumpParams, true);
        }
        if (pumpText.includes('kommunikationskort')) {
            PumpParamEnum.Card.setContextValue(pumpParams, true);
        }
    }
}

const calculateShunts = async (shuntList, database, firestore, sessionParams, fsDataParams, productLine, driftParams, calcParams, shuntParams, errorContext, noticeContext, coolingEnergyMeasurementList, heatEnergyMeasurementList, systemFluidOptions) => {
    const dimData = await FSDataEnum.Dims.getContextValue(fsDataParams, firestore);
    const flexDimData = await FSDataEnum.FlexDims.getContextValue(fsDataParams);
    const smartDims = await FSDataEnum.SmartDims.getContextValue(fsDataParams);
    let specialDim = await getSpecialFlexDim(firestore);

    /*
    const errorList = new ErrorEnum();
    const noticeList = new NoticeEnum();

    errorList.clearAllErrors(errorContext);
    noticeList.clearAllNotices(noticeContext);
    calculateShuntParams(productLine, driftParams, calcParams, shuntParams, errorContext, noticeContext, dimData, database, sessionParams, flexDimData, smartDims);
    */

    await recalculateShunts(shuntList, dimData, database, firestore, sessionParams, coolingEnergyMeasurementList, heatEnergyMeasurementList, systemFluidOptions);
}