import { httpsCallable } from "firebase/functions"

/**
 * 
 * @param {import("firebase/functions").Functions} functions 
 * @param {string} email 
 * @param {string} name 
 * @param {string} companyName 
 * @returns 
 */
const requestSLUser = function requestSLUser(functions, email, name, companyName) {
    functions.region = 'europe-west1';
    const requestSLUserFunc = httpsCallable(functions, "requestSLUser");

    return requestSLUserFunc({ 
        inviteEmail: email,
        inviteName: name,
        inviteCompany: companyName,
    });
}

export { requestSLUser }