

/**
 * 
 * @param {string} searchString 
 */
export const containsIlligalCharacters = (searchString) => {
    const regTest = /#|\$|\*|\(|\)|\+|\[|\{|\]|\}|\||\\|<|,|\.|\/|;/;

    console.log(regTest.test(searchString));
    return regTest.test(searchString);
}