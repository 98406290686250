import { PRODUCTPRINCIP_PR2, PRODUCTPRINCIP_PR3, PRODUCTPRINCIP_SR2, PRODUCTPRINCIP_SR2FLEX, PRODUCTPRINCIP_SR2SMART, PRODUCTPRINCIP_SR3, PRODUCTPRINCIP_VA1 } from "../../constants/productlineConstants";
import { calcAuthBase } from "./baseFormulas/calcAuthBaseFormula";
import { calcDeltaPressure } from "./baseFormulas/calcDeltaPressure"
import { roundNumber } from "./helpFunctions/roundTo4Decimals";

/**
 * Checking the control-valves authority. If it is to low (below 20%) the function of the control-valve can be compromized.
 * @param {number} pressureLossSV The Pressureloss SV for the system (kPa)
 * @param {number} pressureLossSek The Pressureloss in secondary system (kPa)
 * @param {number} kvRor The kvRor value from the table.
 * @param {number} kvBer The previosly calculated valveSetting kv-value
 * @param {number} flow The Primary flow for the system.
 * @returns The authority of the system (%)
 */
const calcAuthority_old = function calcAuthority_old(pressureLossSV, pressureLossSek, kvRor, kvBer, flow) {
    const pressRor = roundNumber(calcDeltaPressure(flow, kvRor));
    const pressBer = roundNumber(calcDeltaPressure(flow, kvBer));

    const authority = pressureLossSV / (pressureLossSV + kvBer + pressRor + pressBer);

    return roundNumber(authority * 100);
}

/**
 * 
 * @param {number} kvs 
 * @param {number} primFlow 
 * @param {number} secFlow 
 * @param {number} primInternalPressureDrop 
 * @param {number} rorAndBend 
 * @param {string} plPricip 
 * @param {boolean} hasMainPump 
 * @param {number} pEfk 
 * @param {number} primDrivPressure 
 * @param {number} primExternalPressureDrop 
 * @param {boolean} cvIsInterval 
 * @param {number} calcMode 
 * @returns 
 */
const calcAuthority = function calcAuthority(kvs, primFlow, secFlow, primInternalPressureDrop, rorAndBend, plPricip, hasMainPump, pEfk, primDrivPressure, primExternalPressureDrop, dPressure, cvIsInterval, calcMode) {
    let auth = 0;

    if (primFlow === 0 || kvs === 0) {
        return 0;
    }

    let rorAndBendPressure;

    //console.log("Pefk:", pEfk, Number(primExternalPressureDrop), plPricip);
    switch (plPricip) {
        case PRODUCTPRINCIP_SR2:
            
            if (pEfk != null && Number(pEfk) !== 0 && Number(calcMode) === 0) {
                if (cvIsInterval === true) {
                    auth = calcAuthBase(dPressure, (primDrivPressure + pEfk))
                }
                else {
                    //console.log("PEFK: AuthBase", pEfk, dPressure, primInternalPressureDrop)
                    auth = calcAuthBase(dPressure, (primInternalPressureDrop + pEfk))
                    //console.log("PEFK: AuthBase", auth)
                }
            }
            break;
        case PRODUCTPRINCIP_SR2SMART:
            if (pEfk != null && Number(pEfk) !== 0 && Number(calcMode) === 0) {
                auth = calcAuthBase(dPressure, (primInternalPressureDrop + pEfk))
            }
            break;
        case PRODUCTPRINCIP_SR2FLEX:
            auth = 0
            break;
        case PRODUCTPRINCIP_PR2:
            if (hasMainPump === false) {
                console.log("Data: ", dPressure, primInternalPressureDrop, primExternalPressureDrop, (primInternalPressureDrop + Number(primExternalPressureDrop) + dPressure))
                auth = calcAuthBase(dPressure, (primInternalPressureDrop + Number(primExternalPressureDrop) + dPressure))
            }
            else {
                if (pEfk != null && pEfk !== 0 && Number(calcMode) === 0) {
                    auth = calcAuthBase(dPressure, (primInternalPressureDrop + pEfk + dPressure))
                }
            }

            break;

        case PRODUCTPRINCIP_SR3:
            rorAndBendPressure = calcDeltaPressure(primFlow, rorAndBend, false);
            auth = calcAuthBase(dPressure, (dPressure + rorAndBendPressure));

            break;
        case PRODUCTPRINCIP_PR3:
            rorAndBendPressure = calcDeltaPressure(secFlow, rorAndBend, false);
            auth = calcAuthBase(dPressure, (dPressure + rorAndBendPressure));

            break;
        case PRODUCTPRINCIP_VA1:
            auth = calcAuthBase(dPressure, (dPressure + Number(primExternalPressureDrop) + primInternalPressureDrop));
            break;
        default:
            auth = calcAuthBase(dPressure, (dPressure + primDrivPressure + primInternalPressureDrop));
            break;
    }

    //console.log("Auth (not rounded):", auth);
    return roundNumber(auth);
}

export { calcAuthority }