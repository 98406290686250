import { get, ref } from "firebase/database";
import { SESSION_SHUNT_PATH } from "../../constants/firebasePaths";
import { AdjustmentsEnum } from "../../enums/adjustmentsEnum";
import { CalcParamsEnum } from "../../enums/calcParamsEnum";
import { DriftParamEnum } from "../../enums/driftParamsEnum";
import { ErrorEnum } from "../../enums/errorEnum";
import { NoticeEnum } from "../../enums/noticeEnum";
import { ProductLineEnum } from "../../enums/productLineEnum";
import { PumpParamEnum } from "../../enums/pumpParamsEnum";
import { SessionParamsEnum } from "../../enums/sessionParamsEnum"
import { ShuntParamsEnum } from "../../enums/shuntParamsEnum";
import { WizardParamEnum } from "../../enums";

const loadShuntFromFirebase = async function loadShuntFromFirebase(shuntID, database, sessionParams, driftParams, shuntParams, calcParams, adjustParams, pumpParams, plLineParams, wizardParams, errorProvider, noticeProvider, position = null) {
    if (SessionParamsEnum.Editable.getContextValue(sessionParams) === false){
        SessionParamsEnum.CurrentShunt.setContextValue(sessionParams, shuntID);
    }
    else{
        SessionParamsEnum.CurrentShunt.setContextValue(sessionParams, shuntID, database);
    }
    

    const errorlist = new ErrorEnum();
    const noticelist = new NoticeEnum();
    
    const shuntRef = ref(database, SESSION_SHUNT_PATH + shuntID);
    //console.time("loading")
    const result = await get(shuntRef);
    //console.timeLog("loading");
    let selectedStep = 0;

    sessionParams.dispatch({ type: 'clearStepData' })
    AdjustmentsEnum.clearData(adjustParams);
    CalcParamsEnum.clearData(calcParams);
    DriftParamEnum.clearData(driftParams);
    ProductLineEnum.clearData(plLineParams);
    PumpParamEnum.clearData(pumpParams);
    ShuntParamsEnum.clearData(shuntParams);
    WizardParamEnum.clearData(wizardParams);
    //console.timeLog("loading", "ClearData");

   

    if (result.child("PLData").exists()) {
        const plData = result.child("PLData");

        plLineParams.dispatch({ type: 'setDataFromSnapshot', payload: plData })
    }

    //console.timeLog("loading", "Load PLData");

    if (result.child("DriftParams").exists()) {
        const drift = result.child("DriftParams");
        driftParams.dispatch({ type: 'setDataFromSnapShot', payload: drift })
    }

    //console.timeLog("loading", "Load DriftParams");

    if (result.child("CalcParams").exists()) {
        const calc = result.child("CalcParams");

        calcParams.dispatch({ type: 'setDataFromSnapShot', payload: calc })
    }

    //console.timeLog("loading", "Load CalcParams");

    if (result.hasChild("ShuntData")) {
        const shuntData = result.child("ShuntData");
        
        shuntParams.dispatch({ type: 'setDataFromSnapShot', payload: shuntData })
    }

    //console.timeLog("loading", "Load ShuntData");

    if (result.hasChild("AdjustData")) {
        const adjustData = result.child("AdjustData");

        adjustParams.dispatch({ type: 'setDataFromSnapShot', payload: adjustData })
    }

    //console.timeLog("loading", "Load AdjustData");

    if (result.hasChild("PumpData")) {
        const pumpData = result.child("PumpData");

        pumpParams.dispatch({ type: 'setDataFromSnapShot', payload: pumpData })
    }

    if (result.hasChild("WizardData")) {
        const wizardData = result.child("WizardData");

        wizardParams.dispatch({ type: 'setDataFromSnapShot', payload: wizardData })
    }

    //console.timeLog("loading", "Load PumpData");

    if (result.child("Baseinfo").exists()) {
        const baseinfo = result.child("Baseinfo");

        ShuntParamsEnum.ShuntDesignation.setContextValue(shuntParams, baseinfo.child("designation").val() || "")
        ShuntParamsEnum.IsCustomShunt.setContextValue(shuntParams, baseinfo.child("isCustom").val() || false)
        
        if (position != null && position !== 0){
            ShuntParamsEnum.ShuntPosition.setContextValue(shuntParams, position)
        }
        else{
            ShuntParamsEnum.ShuntPosition.setContextValue(shuntParams, baseinfo.child("position").val() || "")
        }
        
        SessionParamsEnum.CurrentStep.setContextValue(sessionParams, baseinfo.child("curStep").val())
        SessionParamsEnum.MaxStep.setContextValue(sessionParams, baseinfo.child("maxStep").val())
        
        if (baseinfo.child("maxStep").exists()) {
            selectedStep = Number(baseinfo.child("maxStep").val());
        }
        else if (baseinfo.child("curStep").exists()) {
            selectedStep = Number(baseinfo.child("curStep").val());
        }

    }

    //console.timeLog("loading", "Load BaseInfo");

    if (result.hasChild("MsgLists")){
        const msgList = result.child("MsgLists");

        if (msgList.hasChild("errors")){
            msgList.child("errors").forEach((error) => {
                errorlist.addError(errorProvider, error.key, error.val());
            });
        }

        if (msgList.hasChild("notices")){
            msgList.child("notices").forEach((notice) => {
                noticelist.addNotice(noticeProvider, notice.key, notice.val());
            });
        }
    }

    //console.timeEnd("loading")
    return selectedStep;
}

export { loadShuntFromFirebase } 