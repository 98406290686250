import { ref, remove, set, update } from 'firebase/database';
import { createContext, useContext, useReducer } from 'react'
import { SESSION_CHILD_SESSIONS_PATH, SESSION_CURRENT_SHUNT_PATH, SESSION_CUSTOM_ADDED_SHUNTS_PATH, SESSION_FACILITY_PATH, SESSION_LINKED_PROJECT_PATH, SESSION_PARENT_ID_PATH, SESSION_PATH, SESSION_PROJ_NR_PATH, SESSION_SHUNT_ADDONS_PATH, SESSION_SHUNT_DESIGNATIONS_PATH, SESSION_SHUNT_PATH, SHUNT_ADDONS_PATH, SHUNT_BASEINFO, SHUNT_CURRENTSTEP_PATH, SHUNT_MAXSTEP_PATH, SHUNT_PARENTSESSION_PATH, SHUNT_POSITION_PATH, SHUNT_PRODLINE_PATH, SHUNT_SHUNTDATA } from '../constants/firebasePaths';
import { SESSION_PARAMS } from '../constants/sessionStoragePaths';

const SessionParams = createContext(undefined);

function sessionParamsReducer(state, action) {
    switch (action.type) {
        case 'setOpenOverview': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            data['openOverview'] = action.payload;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setOverviewPanel': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            data['overviewPanel'] = action.payload;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setSessionID': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            data['sessionID'] = action.payload;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setIsApiSession': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            data['isApiSession'] = action.payload;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setApiMethod': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            data['apiMethod'] = action.payload;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setApiIsBulk': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            data['apiIsBulk'] = action.payload;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setParentID': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            if (action.database != null) {
                const fieldRef = ref(action.database, SESSION_PATH + action.payload + SESSION_CHILD_SESSIONS_PATH + data.sessionID);
                const childRef = ref(action.database, SESSION_PATH + data.sessionID + SESSION_PARENT_ID_PATH);
                set(fieldRef, 1).then(() => {
                    set(childRef, action.payload).then(() => {
                        //console.log("Added to fb!");
                    });
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data['parentID'] = action.payload;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setFacility': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            if (action.database != null) {
                const fieldRef = ref(action.database, SESSION_PATH + data.sessionID + SESSION_FACILITY_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }


            data['facility'] = action.payload;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setProjNr': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            if (action.database != null) {
                const fieldRef = ref(action.database, SESSION_PATH + data.sessionID + SESSION_PROJ_NR_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data['projNr'] = action.payload;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setLinkedProject': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            if (action.database != null) {
                const fieldRef = ref(action.database, SESSION_PATH + data.sessionID + SESSION_LINKED_PROJECT_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data['linkedProject'] = action.payload;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setStatus': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            if (action.database != null) {
                const fieldRef = ref(action.database, SESSION_PATH + data.sessionID + SESSION_LINKED_PROJECT_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data['status'] = action.payload;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setEditable': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            data['editable'] = action.payload;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setSwitching': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            data['switching'] = action.payload;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setCurrentShunt': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            if (action.database != null) {
                const fieldRef = ref(action.database, SESSION_PATH + data.sessionID + SESSION_CURRENT_SHUNT_PATH);

                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data['currentShunt'] = action.payload;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setCurrentStep': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }
            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_CURRENTSTEP_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });

                if (Number(action.payload) > Number(data.maxStep) || data.maxStep == null) {
                    const fieldMaxRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_MAXSTEP_PATH);
                    set(fieldMaxRef, action.payload).then(() => {
                        //console.log("Added to fb!");
                    }).catch((error) => {
                        console.error("Error writing to FB: ", error);
                    });
                    data['maxStep'] = action.payload;
                }
            }

            data['curStep'] = action.payload;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setMaxStep': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_MAXSTEP_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }


            data['maxStep'] = action.payload;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'clearStepData': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            delete data.maxStep;
            delete data.curStep;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setSearchSessionID': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            data['searchSessionID'] = action.payload;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setOpeningSessionModified': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            data['openingSessionModified'] = action.payload;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setOpeningSessionModifiedError': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            data['openingSessionModifiedError'] = action.payload;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'addSessionShunt': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            const fieldRef = ref(action.database, SESSION_PATH + data.sessionID + SESSION_SHUNT_PATH + action.payload);
            const fieldDesigRef = ref(action.database, SESSION_PATH + data.sessionID + SESSION_SHUNT_DESIGNATIONS_PATH + action.payload);
            const childRef = ref(action.database, SESSION_SHUNT_PATH + action.payload + SHUNT_PARENTSESSION_PATH);
            const childPosRef = ref(action.database, SESSION_SHUNT_PATH + action.payload + SHUNT_POSITION_PATH);

            set(fieldRef, action.shuntPos).then(() => {
                set(childRef, data.sessionID).then(() => {
                    set(childPosRef, action.shuntPos).then(() => {
                        if (action.shuntDesig != null) {
                            set(fieldDesigRef, action.shuntDesig).then(() => {
                                //console.log("Added to fb! ", action.shuntDesig);
                            });
                        }
                        else {
                            //console.log("Added to fb!");
                        }

                        if (action.isAddon != null && action.isAddon === true) {
                            const fieldAddonRef = ref(action.database, SESSION_PATH + data.sessionID + SESSION_SHUNT_ADDONS_PATH + action.payload);

                            set(fieldAddonRef, action.addonType);

                            let addons = [];
                            if (data['addons'] != null) {
                                addons = data['addons'];
                            }
                            console.log("Add Addon: ", addons, action.payload)
                            addons.push(action.payload);

                            data['addons'] = addons;
                        }
                    });
                });
            }).catch((error) => {
                console.error("Error writing to FB: ", error);
            });

            let shunts = [];
            if (data['shunts'] != null) {
                shunts = data['shunts'];
            }

            shunts.push(action.payload);

            data['shunts'] = shunts;

            if (data["update"] == null) {
                data["update"] = true;
            }
            else {
                data["update"] = !data["update"];
            }

            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'addShuntAddon': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            const fieldRef = ref(action.database, SESSION_PATH + data.sessionID + SESSION_SHUNT_PATH + action.payload);
            const fieldDesigRef = ref(action.database, SESSION_PATH + data.sessionID + SESSION_SHUNT_DESIGNATIONS_PATH + action.payload);
            const fieldAddonRef = ref(action.database, SESSION_PATH + data.sessionID + SESSION_SHUNT_ADDONS_PATH + action.payload);
            const fieldCustomAddonRef = ref(action.database, SESSION_PATH + data.sessionID + SESSION_CUSTOM_ADDED_SHUNTS_PATH + action.payload);
            
            const childRef = ref(action.database, SESSION_SHUNT_PATH + action.payload + SHUNT_PARENTSESSION_PATH);
            const basePathRef = ref(action.database, SESSION_SHUNT_PATH + action.payload + SHUNT_BASEINFO);
            const prodLineRef = ref(action.database, SESSION_SHUNT_PATH + action.payload + SHUNT_PRODLINE_PATH);

            const addonDataRef = ref(action.database, SESSION_SHUNT_PATH + action.payload + SHUNT_SHUNTDATA);

            let localAddonData = {
                isAddon: true,
                addonType: action.addonType,
            }

            if (action.addonData != null) {
                localAddonData['AddonData'] = action.addonData;
            }

            if (action.shuntID != null) {
                localAddonData['shuntID'] = action.shuntID;
            }

            update(addonDataRef, localAddonData);

            set(fieldRef, action.shuntPos).then(() => {
                set(childRef, data.sessionID).then(() => {
                    set(prodLineRef, action.prodLine)

                    set(fieldAddonRef, action.addonType).then(() => {
                        //console.log("Added to fb! ", action.shuntDesig);
                    });

                    if (action.isManual === true){
                        set(fieldCustomAddonRef, action.shuntPos).then(() => {
                            //console.log("Added to fb! ", action.shuntDesig);
                        });
                    }

                    set(basePathRef, {
                        position: action.shuntPos,
                        curStep: 4,
                        maxStep: 4,
                    }).then(() => {
                        if (action.shuntDesig != null) {
                            set(fieldDesigRef, action.shuntDesig).then(() => {
                                //console.log("Added to fb! ", action.shuntDesig);
                            });
                        }
                        else {
                            //console.log("Added to fb!");
                        }

                    });
                });

                if (action.shuntID != null) {
                    const shuntParentRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_ADDONS_PATH + action.addonType);

                    set(shuntParentRef, action.payload);
                }



            }).catch((error) => {
                console.error("Error writing to FB: ", error);
            });

            let addons = [];
            if (data['addons'] != null) {
                addons = data['addons'];
            }


            let shunts = [];
            if (data['shunts'] != null) {
                shunts = data['shunts'];
            }

            shunts.push(action.payload);
            addons.push(action.payload);

            data['shunts'] = shunts;
            data['addons'] = addons;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'removeShuntAddon': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            if (action.payload != null && data.sessionID != null) {
                const promises = [];

                const sessionRef = ref(action.database, SESSION_PATH + data.sessionID + SESSION_SHUNT_PATH + action.payload);
                const addonRef = ref(action.database, SESSION_PATH + data.sessionID + SESSION_SHUNT_ADDONS_PATH + action.payload);
                const shuntAddonRef = ref(action.database, SESSION_SHUNT_PATH + action.payload);

                promises.push(remove(sessionRef));
                promises.push(remove(addonRef));
                promises.push(remove(shuntAddonRef));

                if (action.shuntID != null && action.shuntID !== "" && action.addonType != null && action.addonType !== "") {
                    const shuntRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_ADDONS_PATH + action.addonType);

                    remove(shuntRef);
                }

                let shunts = [];
                let addons = [];
                if (data['shunts'] != null) {
                    shunts = data['shunts'];
                }

                if (data['addons'] != null) {
                    addons = data['addons'];
                }

                const index = shunts.indexOf(action.payload);
                if (index > -1) {
                    shunts.splice(index, 1);
                }

                const indexAddon = shunts.indexOf(action.payload);
                if (indexAddon > -1) {
                    addons.splice(indexAddon, 1);
                }
                console.log("Addons: ", addons)
                data['shunts'] = shunts;
                data['addons'] = addons;

                sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            }

            return { val: data }
        }
        case 'removeSessionShunt': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            let shunts = [];
            if (data['shunts'] != null) {
                shunts = data['shunts'];
            }

            const index = shunts.indexOf(action.payload);
            if (index > -1) {
                shunts.splice(index, 1);
            }

            data['shunts'] = shunts;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setSessionShunt': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            data['shunts'] = action.payload;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setSessionAddons': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            data['addons'] = action.payload;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'clearFacility': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            delete data.facility;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'clearProjNr': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            delete data.projNr;
            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'clear': {
            sessionStorage.removeItem(SESSION_PARAMS);
            return { val: {} }
        }
        case 'setUpdateValues': {
            let data = {};
            if (sessionStorage.getItem(SESSION_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
            }

            if (data["update"] == null) {
                data["update"] = true;
            }
            else {
                data["update"] = !data["update"];
            }

            sessionStorage.setItem(SESSION_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function SessionParamsProvider({ children }) {
    let obj = {};
    if (sessionStorage.getItem(SESSION_PARAMS) != null) {
        obj = JSON.parse(sessionStorage.getItem(SESSION_PARAMS));
    }

    obj['openOverview'] = false;

    const [state, dispatch] = useReducer(sessionParamsReducer, { val: obj })
    const value = { state, dispatch }
    return (
        <SessionParams.Provider value={value}>
            {children}
        </SessionParams.Provider>);
}

function useSessionParams() {
    const context = useContext(SessionParams)
    if (context === undefined) {
        throw new Error('useSessionParams must be used within a SessionParams')
    }
    return context;
}


export { SessionParamsProvider, useSessionParams }