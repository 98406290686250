import { Box, Checkbox, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, Tooltip, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useAdjustParams, useCalcParams, useDriftParams, useProductLineParams, useSessionParams, useShuntParams } from "../../../../contexts";
import { AdjustmentsEnum, CalcParamsEnum, DriftParamEnum, ProductLineEnum, SessionParamsEnum, ShuntParamsEnum } from "../../../../enums";
import { PRODUCTLINE_GREEN, PRODUCTLINE_SMART } from "../../../../constants/productlineConstants";
import { useGetEnergyMeasurementList } from "../../../../hooks/useGetEnergyMeasurementList";
import { COOL_SHUNT, HEAT_SHUNT } from "../../../../constants/shuntOptions";
import { ENERGYMEASUREMENT_COMMUNICATION_MODULE } from "../../../../constants/connectedConstants";
import { WizardParamEnum } from "../../../../enums/wizardParamsEnum";
import { useWizardParams } from "../../../../contexts/wizardParams";
import { SYSTEM_FLUID } from "../../../../constants/wizardConstants";
import { useDatabase } from "reactfire";
import { resetMaxStepFunc } from "../../../../functions/HelperFunctions/resetMaxStep";


/**
 * 
 * @param {Object} param0 
 * @param {Array} param0.heatEnergyMeasurementList
 * @param {Array} param0.coolingEnergyMeasurementList
 * @returns 
 */
export const ConnectedEnergyMeasurement = ({ heatEnergyMeasurementList, coolingEnergyMeasurementList }) => {
    const database = useDatabase();
    const productLineParams = useProductLineParams();
    const calcParams = useCalcParams();
    const sessionParams = useSessionParams();
    const driftParams = useDriftParams();
    const shuntParams = useShuntParams();
    const wizardParams = useWizardParams();
    const adjustParams = useAdjustParams();

    const [measurementList, setMeasurementList] = useState([]);

    useEffect(() => {
        if (CalcParamsEnum.ShuntType.getContextValue(calcParams) != null) {
            if (CalcParamsEnum.ShuntType.getContextValue(calcParams) === COOL_SHUNT) {
                setMeasurementList(coolingEnergyMeasurementList.sort((a, b) => a?.minFlow - b?.minFlow));
            }
            else if (CalcParamsEnum.ShuntType.getContextValue(calcParams) === HEAT_SHUNT) {
                setMeasurementList(heatEnergyMeasurementList.sort((a, b) => a?.minFlow - b?.minFlow));
            }
        }
    }, [CalcParamsEnum.ShuntType.getContextValue(calcParams), coolingEnergyMeasurementList, heatEnergyMeasurementList]);


    useEffect(() => {
        if (WizardParamEnum.SystemFluidMain.getContextValue(wizardParams) !== SYSTEM_FLUID.water.value) {
            DriftParamEnum.UseConnectedEnergy.setContextValue(driftParams, false);
            ShuntParamsEnum.ConnectedEnergyModel.setContextValue(shuntParams, null);
        }
    }, [WizardParamEnum.SystemFluidMain.getContextValue(wizardParams)])

    const handleChange = () => {
        CalcParamsEnum.clearData(calcParams);
        AdjustmentsEnum.clearData(adjustParams);
        if (DriftParamEnum.UseConnectedEnergy.getContextValue(driftParams) == null || DriftParamEnum.UseConnectedEnergy.getContextValue(driftParams) === "") {
            DriftParamEnum.UseConnectedEnergy.setContextValue(driftParams, true);
        }
        else {
            DriftParamEnum.UseConnectedEnergy.setContextValue(driftParams, !DriftParamEnum.UseConnectedEnergy.getContextValue(driftParams));
        }

        resetMaxStepFunc(sessionParams, database);
    }

    const handleChangeValue = (event) => {
        CalcParamsEnum.clearData(calcParams);
        AdjustmentsEnum.clearData(adjustParams);
        resetMaxStepFunc(sessionParams, database);

        let selectedObj = measurementList.find((obj) => obj.id === event.target.value);

        if (selectedObj != null) {
            ShuntParamsEnum.ConnectedEnergyModel.setContextValue(shuntParams, selectedObj);
        }
    }

    const handleChangeCommModuleValue = (event) => {
        ShuntParamsEnum.ConnectedEnergyCommModal.setContextValue(shuntParams, event.target.value, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
    }

    const displayEnergyMeasurementList = () => {
        if (CalcParamsEnum.ShuntType.getContextValue(calcParams) === COOL_SHUNT) {
            return coolingEnergyMeasurementList.sort((a, b) => a?.minFlow - b?.minFlow);
        }
        else if (CalcParamsEnum.ShuntType.getContextValue(calcParams) === HEAT_SHUNT) {
            return heatEnergyMeasurementList.sort((a, b) => a?.minFlow - b?.minFlow);
        }
        else {
            return [];
        }
    }

    const checkIfDisabled = () => {
        if ((SessionParamsEnum.Editable.getContextValue(sessionParams) === false) ||
            (DriftParamEnum.CalcMode.getContextValue(driftParams) !== "0" && DriftParamEnum.CalcMode.getContextValue(driftParams) !== 0)) {
            return true;
        }

        return false;
    }

    const isCheckboxDisabled = () => {
        if (SessionParamsEnum.Editable.getContextValue(sessionParams) === false) {
            return true;
        }
        else {
            if (WizardParamEnum.SystemFluidMain.getContextValue(wizardParams) !== SYSTEM_FLUID.water.value) {
                return true;
            }
        }

        return false;
    }

    const showTooltip = () => {
        if (checkIfDisabled() && (ShuntParamsEnum.ConnectedEnergyModel.getContextValue(shuntParams) == null || ShuntParamsEnum.ConnectedEnergyModel.getContextValue(shuntParams) === "")){
            return true;
        }

        return false;
    }

    return (
        <Box marginTop={'10px'}>
            <Stack spacing={0}>
                <Divider textAlign="left">
                    <Typography variant='h4'>
                        Energimätning
                    </Typography>
                </Divider>

                <Stack direction={"row"} justifyContent="space-between" alignItems={'flex-start'}>
                    <>
                        {ProductLineEnum.ProductLine.getContextValue(productLineParams) === PRODUCTLINE_SMART ? (
                            <div style={{ color: '#7e7e7e', fontStyle: 'italic', height: '20px', marginTop: '5px' }}>
                                Via styrventil
                            </div>
                        ) : (
                            <>
                                <div style={{ width: "50%" }}>
                                    <Tooltip arrow title={isCheckboxDisabled() ? <span><strong>Connected™ Energi</strong> är enbart tillgängligt vid vatten som systemvätska</span> : ""} placement="right">
                                        <span>
                                            <FormControlLabel disabled={isCheckboxDisabled()} control={<Checkbox size="small" checked={DriftParamEnum.UseConnectedEnergy.getContextValue(driftParams) || false} onChange={handleChange} />} label={<span style={{ fontSize: '0.72rem', marginLeft: '-6px' }}><strong>Connected™ Energi</strong></span>} />
                                        </span>
                                    </Tooltip>
                                </div>
                                {DriftParamEnum.UseConnectedEnergy.getContextValue(driftParams) === true ? (
                                    <Stack spacing={0} style={{ marginLeft: "auto", width: "49%", marginTop: '5px' }}>
                                        <Tooltip arrow placement="top" title={showTooltip() ? "Lämplig energimätare sätts automatiskt efter genomförd beräkning" : ""}>
                                            <FormControl >
                                                <InputLabel>Mätare</InputLabel>
                                                <Select
                                                    label="Mätare"
                                                    value={ShuntParamsEnum.ConnectedEnergyModel.getContextValue(shuntParams)?.id || ''}
                                                    disabled={checkIfDisabled()}
                                                    onChange={handleChangeValue}
                                                    size={"small"}
                                                    margin="dense"
                                                >
                                                    {measurementList.map((item) => {
                                                        return (
                                                            <MenuItem key={item.id} value={item.id} sx={{ height: '30px' }}>{item.name}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Tooltip>


                                        <FormControl sx={{ marginTop: '7px' }}>
                                            <InputLabel>Kommunikationsmodul</InputLabel>
                                            <Select
                                                label="Kommunikationsmodul"
                                                value={ShuntParamsEnum.ConnectedEnergyCommModal.getContextValue(shuntParams)}
                                                disabled={SessionParamsEnum.Editable.getContextValue(sessionParams) === false}
                                                onChange={handleChangeCommModuleValue}
                                                size={"small"}
                                                margin="dense"
                                            >
                                                <MenuItem value={''} sx={{ height: '18px' }}></MenuItem>
                                                {ENERGYMEASUREMENT_COMMUNICATION_MODULE.map((item) => {
                                                    return (
                                                        <MenuItem key={item} value={item} sx={{ height: '30px' }}>{item}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Stack>

                                ) : (
                                    <div></div>
                                )}
                            </>
                        )}
                    </>


                </Stack>

                <Divider sx={{ marginTop: '6px' }} />
            </Stack>
        </Box>
    )
}