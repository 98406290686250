import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { ENERGY_MEASUREMENT_OPTIONS, QUESTION_ENERGY_MEASUREMENT_KEY } from "../../../../constants/wizardConstants";

export const EnergyMeasureQuestion = ({ energyMeasurment, answerQuestion }) => {

    return (
        <FormControl size="small">
            <InputLabel>Energimätning</InputLabel>
            <Select
                sx={{ width: '180px', height: '30px', background: 'white' }}
                size="small"
                value={energyMeasurment}
                label={'Energimätning'}
                onChange={(e) => answerQuestion(QUESTION_ENERGY_MEASUREMENT_KEY, e.target.value)}
            >
                <MenuItem value={ENERGY_MEASUREMENT_OPTIONS.controlValve.value}>{ENERGY_MEASUREMENT_OPTIONS.controlValve.label}</MenuItem>
                <MenuItem value={ENERGY_MEASUREMENT_OPTIONS.pump.value}>{ENERGY_MEASUREMENT_OPTIONS.pump.label}</MenuItem>
                <MenuItem value={ENERGY_MEASUREMENT_OPTIONS.dedicated.value}>{ENERGY_MEASUREMENT_OPTIONS.dedicated.label}</MenuItem>
                <MenuItem value={ENERGY_MEASUREMENT_OPTIONS.unknown.value}>{ENERGY_MEASUREMENT_OPTIONS.unknown.label}</MenuItem>
            </Select>
        </FormControl>
    );
}