import { Paper, Stack, Typography } from '@mui/material';
import { useRef, useState } from 'react'

import { FirebaseShuntDisplay } from './components/firebaseShuntDisplay';
import { InspectSidePanel } from './inspectSidePanel';
import { useAdjustParams, useCalcParams, useDevbarParams, useDriftParams, useProductLineParams, usePumpParams, useSessionParams, useShuntParams } from "../../../contexts"
import { ProductLineEnum, SessionParamsEnum } from '../../../enums';
import { PRODUCTLINE_FLEX, PRODUCTLINE_GREEN, PRODUCTLINE_GREEN_I, PRODUCTLINE_GREEN_II, PRODUCTLINE_SMART, PRODUCTLINE_STANDARD } from '../../../constants/productlineConstants';
import { DevSaveXml } from '../navbar/devSaveXml';
import { isDevF2User } from '../../../firebase/functions/isDevUser';
import { useDatabase } from 'reactfire';
import { useEffect } from 'react';
import { saveXmlFile } from '../../../functions/MagicadFunctions/saveXmlFile';
import { InspectSidePanelAPISession } from './inspectSidePanelApi';
import { ShuntSpecPage } from '../shuntSpecification/shuntSpecPage';
import { getShuntListName } from '../../../firebase/functions/getShuntList';
import { setPluginSessionCompleted } from '../../../firebase/functions/savePluginData';
import { useWizardParams } from '../../../contexts/wizardParams';
import { useContextAuth } from '../../../authContext/AuthHook';

import standardLine from '../../../assets/images/logos/prodline_standard1.svg'
import greenLine1 from '../../../assets/images/logos/Premablock_Green_I_Logo.svg'
import greenLine2 from '../../../assets/images/logos/Premablock_Green_II_Logo.svg'
import flexLine from '../../../assets/images/logos/prodline_flex1.svg'
import smartLine from '../../../assets/images/logos/prodline_smart.svg'
import { useGetSystemFluidOptions } from '../../../hooks/useGetSystemFluidOptions';

export const InspectPage = () => {
    const { user } = useContextAuth();
    const systemFluidOptions = useGetSystemFluidOptions();
    const sessionParams = useSessionParams();
    const devParams = useDevbarParams();
    const productLine = useProductLineParams();
    const containerRef = useRef();

    const database = useDatabase();
    const shuntParams = useShuntParams();
    const calcParams = useCalcParams();
    const adjustParams = useAdjustParams();
    const pumpParams = usePumpParams();
    const driftParams = useDriftParams();
    const wizardParams = useWizardParams();

    const [isCompleted, setIsCompleted] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const isApiSession = SessionParamsEnum.IsApiSession.getContextValue(sessionParams);
    const sessionID = SessionParamsEnum.SessionID.getContextValue(sessionParams);

    useEffect(() => {
        getShuntListName(database, sessionParams).then((result) => {
            let localCompleted = true;
            result.forEach((shunt) => {
                // @ts-ignore
                if (shunt.maxStep !== '4' && shunt.maxStep !== 4) {
                    localCompleted = false
                }
            });

            setIsCompleted(localCompleted);
        })
    }, []);

    useEffect(() => {
        if (isApiSession === true && isSent === false) {
            if (SessionParamsEnum.ApiMethod.getContextValue(sessionParams) === "update" && SessionParamsEnum.ApiIsBulk.getContextValue(sessionParams) === true) {
                if (isCompleted === true) {
                    console.log("Send Info!");
                    saveXmlFile(database, sessionParams, calcParams, productLine, shuntParams, adjustParams, pumpParams, driftParams, wizardParams, systemFluidOptions).then(() => {
                        setPluginSessionCompleted(database, sessionID, true);
                    });
                    setIsSent(true);
                }
            }
            else {
                console.log("Send Info!");
                saveXmlFile(database, sessionParams, calcParams, productLine, shuntParams, adjustParams, pumpParams, driftParams, wizardParams, systemFluidOptions).then(() => {
                    setPluginSessionCompleted(database, sessionID, true);
                });
                setIsSent(true);
            }
        }
    }, [isCompleted]);

    const displayLogo = () => {
        if (ProductLineEnum.ProductLine.getContextValue(productLine) === PRODUCTLINE_STANDARD) {
            return (
                <img src={standardLine} alt='Standard Line' style={{ height: '20px', }} />
            );
        }
        else if (ProductLineEnum.ProductLine.getContextValue(productLine) === PRODUCTLINE_GREEN) {
            if (ProductLineEnum.ProductGreenLevel.getContextValue(productLine) != null && ProductLineEnum.ProductGreenLevel.getContextValue(productLine) === 2) {
                return (
                    <img src={greenLine2} alt='green Line II' style={{ height: '25px', marginTop: '5px' }} />
                );
            }
            else {
                return (
                    <img src={greenLine1} alt='green Line I' style={{ height: '25px', marginTop: '5px' }} />
                );
            }
        }
        else if (ProductLineEnum.ProductLine.getContextValue(productLine) === PRODUCTLINE_GREEN_I) {
            return (
                <img src={greenLine1} alt='green Line I' style={{ height: '25px', marginTop: '5px' }} />
            );
        }
        else if (ProductLineEnum.ProductLine.getContextValue(productLine) === PRODUCTLINE_GREEN_II) {
            return (
                <img src={greenLine2} alt='green Line II' style={{ height: '25px', marginTop: '5px' }} />
            );
        }
        else if (ProductLineEnum.ProductLine.getContextValue(productLine) === PRODUCTLINE_FLEX) {
            return (
                <img src={flexLine} alt='flex Line' style={{ height: '20px' }} />
            );
        }
        else if (ProductLineEnum.ProductLine.getContextValue(productLine) === PRODUCTLINE_SMART) {
            return (
                <img src={smartLine} alt='smart Line' style={{ height: '20px' }} />
            );
        }
        else if (ProductLineEnum.ProductLine.getContextValue(productLine) != null && ProductLineEnum.ProductLine.getContextValue(productLine) !== '') {
            return ProductLineEnum.ProductLine.getContextValue(productLine);
        }
        else {
            return "No Logo";
        }
    }

    return (
        <div>
            {(devParams.state.val.devMode === false || devParams.state.val.devMode === undefined) ? (
                <>
                    {isDevF2User(user?.uid) &&
                        <div style={{ position: "absolute", marginLeft: "1115px", marginTop: "-90px" }}>
                            <DevSaveXml />
                        </div>
                    }

                    <Stack direction={'row'} spacing={2} style={{ marginTop: 15 }} ref={containerRef}>
                        <ShuntSpecPage shuntID={SessionParamsEnum.CurrentShunt.getContextValue(sessionParams)} />

                        {isApiSession !== true ? (
                            <InspectSidePanel />
                        ) : (
                            <InspectSidePanelAPISession />
                        )}
                    </Stack>
                </>
            ) : (
                <Stack direction={'row'} spacing={2} style={{ marginTop: 15 }}>
                    <Paper elevation={0}>
                        <div style={{ padding: 20, width: "770px" }}>
                            <Typography component={'h2'}><strong>Shunt-information:</strong></Typography>
                            <FirebaseShuntDisplay shuntID={SessionParamsEnum.CurrentShunt.getContextValue(sessionParams)} shortDesc={false} />
                        </div>
                    </Paper>

                    <InspectSidePanel />
                </Stack>
            )}

        </div>

    );
};