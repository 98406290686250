export const DESIGNATION_KEY = 'designation';
export const POSITION_KEY = 'position';
export const ADJVALVE_KEY = 'adjValve';
export const CONTROLVALVE_KEY = 'controlValve';

export const NUM_BV_KEY = 'numBV';
export const NUM_IV_KEY = 'numIV';
export const HAS_LATCH_KEY = 'hasLatch';
export const HAS_PREV_LATCH_KEY = 'hasPrevLatch';
export const IS_SABO_KEY = 'isSabo';
export const CV_NAME_KEY = 'cvName';
export const CV_MODEL_KEY = 'cvModel';
export const CV_KVS_KEY = 'cvKvs';
export const CV_IS_INTERVAL_KEY = 'cvIsInterval';
export const FLEX_BRAND_KEY = 'flexBrand';
export const FLEX_MODEL_KEY = 'flexModel';
export const FLEX_DIM_ID_KEY = 'flexDimID';
export const SMART_MODEL_KEY = 'smartModel';
export const SMART_DIM_ID_KEY = 'smartDimID';
export const SMART_DIM_KEY = 'smartDim';
export const SHUNT_STRING_KEY = 'shuntNomenklaturString';
export const PL_MODEL_KEY = 'plModel';
export const IS_MINI_KEY = 'isMini';
export const IS_CUSTOM_KEY = 'isCustom';
export const NUM_CONN_KEY = 'numConn';
export const EXCHANGER_KEY = 'exchanger';

export const CONNECTED_ENERGY_MODEL_KEY = 'connectedEnergyModel'
export const CONNECTED_ENERGY_COMM_MODAL_KEY = 'connectedEnergyCommModal'

export const MAGICLOUD_LINK_KEY = 'magicloudLink';

export const MINI_INCOMPATIBLE_KEY = 'miniIncompatible'
export const MC_FUNCTION_KEY = 'mcFunction';

export const IS_ADDON_KEY = "isAddon";
export const ADDON_TYPE_KEY = "addonType"
export const ADDON_DATA_KEY = "AddonData"
export const ADDONS_KEY = "Addons"