import { CalcParamsEnum } from "../enums/calcParamsEnum";
import { DriftParamEnum } from "../enums/driftParamsEnum";
import { ProductLineEnum } from "../enums/productLineEnum";
import { ShuntParamsEnum } from "../enums/shuntParamsEnum";
import { calcDeltaPressure } from "./ShuntCalculations/baseFormulas/calcDeltaPressure";
import { calcAuthority } from "./ShuntCalculations/calculateAuthority";
import { flex_calcControlValveDimensions } from "./ShuntCalculations/flexCalculations/calculateControlValveDimension";
import { calcKvs } from "./ShuntCalculations/calculateKvs";
import { calcPrimPressureDrop, calcSecondaryPressureDrop } from "./ShuntCalculations/calculatePressureDrop";
import { calcPrimFlow } from "./ShuntCalculations/calculatePrimFlow";
import { ErrorMsg } from "../constants/errorMessages";
import { NoticeMsg } from "../constants/noticeMessages";

import { findCorrespondingDim, getDimRowFromName } from "./ShuntCalculations/helpFunctions/findCorrespondingDim";
import { getNextDimKvs, getNextLowerDimKvs, getNextSmartDimKvs } from "./ShuntCalculations/helpFunctions/getNextDimKvs";
import { setShuntType } from "./ShuntCalculations/setShuntType";
import { ErrorEnum } from "../enums/errorEnum";
import { calcTotalPressure } from "./ShuntCalculations/calculateTotalPressure";
import { roundNumber, roundToDecimals } from "./ShuntCalculations/helpFunctions/roundTo4Decimals";
import { NoticeEnum } from "../enums/noticeEnum";
import { checkForFlowErrors, checkForPressureErrors, checkForShuntErrorsTemperature } from "./ShuntCalculations/errorChecks/checkForShuntErrors";
import { checkNumOfAdjustmentValves, checkNumOfCheckValves } from "./ShuntCalculations/calculateNumOfValves";
import { checkDriftParamsInput } from "./ShuntCalculations/errorChecks/checkDriftParamsInput";
import { checkIfDesignationExists, checkIfPosExists } from "./ShuntCalculations/helpFunctions/checkIfPosAndDesignationExists";
import { SessionParamsEnum } from "../enums/sessionParamsEnum";
import { PRODUCTFAMILY_PR, PRODUCTFAMILY_SR, PRODUCTFAMILY_VA, PRODUCTLINE_FLEX, PRODUCTLINE_GREEN, PRODUCTLINE_SMART, PRODUCTPRINCIP_PR2, PRODUCTPRINCIP_PR3, PRODUCTPRINCIP_SR2, PRODUCTPRINCIP_SR2FLEX, PRODUCTPRINCIP_VA1 } from "../constants/productlineConstants";
import { COOL_SHUNT, CV_IMI, CV_MODEL_KVS, CV_MODEL_TACOMPACT_P, CV_MODEL_TAMODULATOR, CV_MODEL_TASMART, CV_MODEL_V231, CV_MODEL_V311, CV_SOE, HEAT_SHUNT } from "../constants/shuntOptions";
import { calculateBalancingKV } from "./ShuntCalculations/calculateBalancingKV";
import { calcInternalPrimDP } from "./ShuntCalculations/calcInternalPrimDP";
import { isGreenLevel2 } from "./ShuntCalculations/isGreenLevel2";
import { calcEffectFromFlow, calcFlowFromEffect } from "./ShuntCalculations/calcFlowAndEffect";
import { WizardParamEnum } from "../enums/wizardParamsEnum";
import { SYSTEM_FLUID } from "../constants/wizardConstants";

/**
 * 
 * @param {*} productLine 
 * @param {*} driftParams 
 * @param {*} calcParams 
 * @param {*} shuntParams 
 * @param {*} errorContext 
 * @param {*} noticeContext 
 * @param {*} dimensionData 
 * @param {*} database 
 * @param {*} sessionParams 
 * @param {*} wizardParams 
 * @param {import("../hooks/useGetSystemFluidOptions").SystemFluid[]} systemFluidOptions 
 * @param {*} flexDims 
 * @param {*} smartDims 
 * @param {*} customKvsList 
 * @param {*} specialFlexDim 
 * @param {*} heatEnergyMeasurementList 
 * @param {*} coolingEnergyMeasurementList 
 * @returns 
 */
const calculateShuntParams = function calculateShuntParams(productLine, driftParams, calcParams, shuntParams, errorContext, noticeContext, dimensionData, database, sessionParams, wizardParams, systemFluidOptions, flexDims = null, smartDims = null, customKvsList = null, specialFlexDim = null, heatEnergyMeasurementList = null, coolingEnergyMeasurementList = null) {
    const ErrorList = new ErrorEnum();
    const NoticeList = new NoticeEnum();

    const shuntID = SessionParamsEnum.CurrentShunt.getContextValue(sessionParams);
    const maxStep = SessionParamsEnum.MaxStep.getContextValue(sessionParams);

    //Productline Params
    const plPrincipVal = ProductLineEnum.ProductPrincip.getContextValue(productLine);
    const plProdFamilyVal = ProductLineEnum.ProductFamily.getContextValue(productLine);
    const plProdLine = ProductLineEnum.ProductLine.getContextValue(productLine);

    //Shunt Params
    const hasLatch = ShuntParamsEnum.HasLatch.getContextValue(shuntParams);
    const IsSabo = ShuntParamsEnum.IsSabo.getContextValue(shuntParams);
    const position = ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams);
    const designation = ShuntParamsEnum.ShuntDesignation.getContextValue(shuntParams);
    const cvModel = ShuntParamsEnum.CV_Model.getContextValue(shuntParams);
    const cvIsInterval = ShuntParamsEnum.CV_IsInterval.getContextValue(shuntParams);
    const adjustValve = ShuntParamsEnum.AdjustmentValve.getContextValue(shuntParams);

    //Drift Params
    let calcMode = Number(DriftParamEnum.CalcMode.getContextValue(driftParams));
    let flowCalcMode = DriftParamEnum.FlowCalcMode.getContextValue(driftParams);
    let primTempOut = DriftParamEnum.PrimTempOut.getContextValue(driftParams);
    let primTempIn = DriftParamEnum.PrimTempIn.getContextValue(driftParams);
    let secTempOut = DriftParamEnum.SecTempOut.getContextValue(driftParams);
    let secTempIn = DriftParamEnum.SecTempIn.getContextValue(driftParams);
    let secondaryFlow = DriftParamEnum.SecFlow.getContextValue(driftParams);
    let secondaryEffect = DriftParamEnum.SecFlowEffect.getContextValue(driftParams);
    let primDrivingPressure = DriftParamEnum.PrimDrivingPressure.getContextValue(driftParams);
    let primExternalPressureDrop = DriftParamEnum.PrimExternalPressureDrop.getContextValue(driftParams)
    let secExternalPressureDrop = DriftParamEnum.SecExternalPressureDrop.getContextValue(driftParams);
    const ivMod = DriftParamEnum.IVModification.getContextValue(driftParams);
    const hasMainPumpVal = DriftParamEnum.HasMainPump.getContextValue(driftParams);
    const hasBackVent = DriftParamEnum.HasBackVent.getContextValue(driftParams);

    const pEfk = DriftParamEnum.PEfk.getContextValue(driftParams);

    //Systemfluid values
    const systemFluid = WizardParamEnum.SystemFluidMain.getContextValue(wizardParams);
    const systemFluidMixRatio = WizardParamEnum.SystemFluidPrimaryMixRatio.getContextValue(wizardParams);
    const systemFluidMixRatioUnknown = WizardParamEnum.SystemFluidPrimaryMixRatioUnknown.getContextValue(wizardParams);

    const systemFluidObj = systemFluidOptions.find((obj) => obj.value === systemFluid);

    const useConnectedEnergy = DriftParamEnum.UseConnectedEnergy.getContextValue(driftParams);
    let connectedEnergyModel = ShuntParamsEnum.ConnectedEnergyModel.getContextValue(shuntParams);

    if (calcMode == null || isNaN(calcMode)) {
        calcMode = 1
    }

    let hasMainPump = true;
    if (hasMainPumpVal === "0") {
        hasMainPump = false;
    }

    checkIfPosExists(position, database, sessionParams).then((exists) => {
        if (exists === true) {
            //console.error(ErrorMsg.params_shuntPosAlreadyExists.msg);
            ErrorList.addError(errorContext, ErrorMsg.params_shuntPosAlreadyExists.key, ErrorMsg.params_shuntPosAlreadyExists.msg);
        }
    });

    if (designation != null && designation !== "") {
        checkIfDesignationExists(designation, database, sessionParams).then((exists) => {
            if (exists === true) {
                //console.log(NoticeMsg.param_designationExists.msg);
                NoticeList.addNotice(noticeContext, NoticeMsg.param_designationExists.key, NoticeMsg.param_designationExists.msg);
            }
        });
    }
    else {
        //console.error(ErrorMsg.params_noShuntDesignation.msg);
        ErrorList.addError(errorContext, ErrorMsg.params_noShuntDesignation.key, ErrorMsg.params_noShuntDesignation.msg);
    }


    let dimRow;
    let dimSekRow;
    let kvs = 0;
    let primInternalPressureDrop;
    let flexSettings = {};
    let minPressure;
    let maxPressure = 90;


    let smartDim;
    let primDimOrg;
    let smartDimOrg;
    let primPressureDropOrg;
    let kvsOrg;
    let hasIncreased = false;
    let smartMax = false;

    //Safechecking for empty fields
    let fieldsSet = checkDriftParamsInput(errorContext, plPrincipVal, hasMainPump, secTempOut, secTempIn, secondaryFlow, secondaryEffect, primDrivingPressure, primExternalPressureDrop, secExternalPressureDrop, flowCalcMode);

    //Returns if one or more fields has not been set
    if (fieldsSet === false) {
        return;
    }

    if (primTempOut == null || isNaN(primTempOut)) {
        primTempOut = secTempOut;
    }

    if (primTempIn == null || isNaN(primTempIn)) {
        primTempIn = secTempIn;
    }

    let tempErrors = checkForShuntErrorsTemperature(plPrincipVal, primTempIn, primTempOut, secTempIn, secTempOut, errorContext, noticeContext);

    if (tempErrors === true) {
        return;
    }

    //console.log("Systemfluid: ", systemFluid)
    if (flowCalcMode === 'kw') {
        if (systemFluid !== SYSTEM_FLUID.other.value && systemFluid !== SYSTEM_FLUID.unknown.value && systemFluid !== SYSTEM_FLUID.different.value) {
            if (systemFluid === SYSTEM_FLUID.water.value || (systemFluidMixRatioUnknown !== true && systemFluidMixRatio != null && systemFluidMixRatio !== "")) {
                if (systemFluid === SYSTEM_FLUID.water.value) {
                    secondaryFlow = Math.abs(calcFlowFromEffect(Number(secondaryEffect), systemFluidObj, 100, Number(secTempOut), Number(secTempIn)));
                }
                else {
                    secondaryFlow = Math.abs(calcFlowFromEffect(Number(secondaryEffect), systemFluidObj, systemFluidMixRatio, Number(secTempOut), Number(secTempIn)));
                }

                DriftParamEnum.SecFlow.setContextValue(driftParams, secondaryFlow, database, shuntID);
            }
            else {
                DriftParamEnum.SecFlow.setContextValue(driftParams, null, database, shuntID);
            }
        }
        else {
            DriftParamEnum.SecFlow.setContextValue(driftParams, null, database, shuntID);
        }
    }
    else {
        if (systemFluid !== SYSTEM_FLUID.other.value && systemFluid !== SYSTEM_FLUID.unknown.value && systemFluid !== SYSTEM_FLUID.different.value) {
            if (systemFluid === SYSTEM_FLUID.water.value || (systemFluidMixRatioUnknown !== true && systemFluidMixRatio != null && systemFluidMixRatio !== "")) {
                if (systemFluid === SYSTEM_FLUID.water.value) {
                    secondaryEffect = Math.abs(calcEffectFromFlow(secondaryFlow, systemFluidObj, 100, Number(secTempOut), Number(secTempIn)));
                }
                else {
                    secondaryEffect = Math.abs(calcEffectFromFlow(secondaryFlow, systemFluidObj, systemFluidMixRatio, Number(secTempOut), Number(secTempIn)));
                }

                DriftParamEnum.SecFlowEffect.setContextValue(driftParams, secondaryEffect, database, shuntID);
            }
            else {
                DriftParamEnum.SecFlowEffect.setContextValue(driftParams, null, database, shuntID);
            }
        }
        else {
            DriftParamEnum.SecFlowEffect.setContextValue(driftParams, null, database, shuntID);
        }
    }



    //Decides if the Check Valve is on the primary or/and secondary side
    let primarySideBV = false;
    let secondarySideBV = false;
    if (plProdFamilyVal === PRODUCTFAMILY_SR || Number(IsSabo) === 1) {
        secondarySideBV = true;
    }
    else if (plProdFamilyVal === PRODUCTFAMILY_PR) {
        primarySideBV = true;
    }

    //Check if the calculation should use an extra BV on the primary side.
    let extraBV = false;
    if (hasLatch === 1 && plProdFamilyVal === PRODUCTFAMILY_SR) {
        extraBV = true;
    }

    //Check the number of Check valves and Adjustmentvalves
    let backVents = checkNumOfCheckValves(plPrincipVal, hasBackVent);
    let numTotalIV = checkNumOfAdjustmentValves(plPrincipVal, ivMod);

    const primFlow = calcPrimFlow(primTempIn, primTempOut, secTempIn, secTempOut, secondaryFlow);

    if (primFlow <= 0) {
        DriftParamEnum.PrimFlow.setContextValue(driftParams, primFlow);

        //console.error(ErrorMsg.flow_nonexistingPrimFlow.msg + ", flow:", primFlow);
        ErrorList.addError(errorContext, ErrorMsg.flow_nonexistingPrimFlow.key, ErrorMsg.flow_nonexistingPrimFlow.msg);
        return;
    }
    else if (primFlow > 50) {
        DriftParamEnum.PrimFlow.setContextValue(driftParams, primFlow);

        //console.error(ErrorMsg.flow_toHighPrimFlow.msg + ", flow:", primFlow);
        ErrorList.addError(errorContext, ErrorMsg.flow_toHighPrimFlow.key, ErrorMsg.flow_toHighPrimFlow.msg);
        return;
    }

    if (plProdLine === PRODUCTLINE_FLEX) {
        let model = ShuntParamsEnum.FlexModel.getContextValue(shuntParams);
        let flexID = ShuntParamsEnum.FlexDimID.getContextValue(shuntParams);

        if (model != null && model !== "") {
            //console.log("Here? ", specialFlexDim)
            flexSettings = flex_calcControlValveDimensions(primFlow, primDrivingPressure, flexDims, model, flexID, errorContext, noticeContext, calcMode, specialFlexDim);
            //console.log("Here? ", specialFlexDim)
            if (flexSettings != null) {
                //console.log("SetValue 1", flexSettings)
                minPressure = flexSettings.flexObj?.data?.minPressure;
                maxPressure = flexSettings.flexObj?.data?.maxPressure;
                CalcParamsEnum.FlexSVDim.setContextValue(calcParams, flexSettings.flexObj?.data?.display);
                CalcParamsEnum.FlexSVDimObj.setContextValue(calcParams, flexSettings.flexObj.data);
                CalcParamsEnum.FlexSVMax.setContextValue(calcParams, roundToDecimals(flexSettings.svOfMax, 2));
                CalcParamsEnum.FlexSVSetting.setContextValue(calcParams, flexSettings.svSet);
                CalcParamsEnum.MinPressure.setContextValue(calcParams, minPressure);

                if (calcMode === 1) {
                    ShuntParamsEnum.FlexDimID.setContextValue(shuntParams, flexSettings.flexObj?.data?.id);

                    if (flexSettings.isSpecial === true) {
                        //console.log("SetValue")
                        ShuntParamsEnum.FlexModel.setContextValue(shuntParams, CV_MODEL_TACOMPACT_P);
                    }
                    else {
                        ShuntParamsEnum.FlexModel.setContextValue(shuntParams, CV_MODEL_TAMODULATOR);
                    }
                }
            }
            else {
                return;
            }
        }
    }
    else if (plProdLine === PRODUCTLINE_SMART) {
        if (primFlow < 0.044) {
            DriftParamEnum.PrimFlow.setContextValue(driftParams, primFlow);

            //console.error(ErrorMsg.smart_flowToLow.msg);
            ErrorList.addError(errorContext, ErrorMsg.smart_flowToLow.key, ErrorMsg.smart_flowToLow.msg);
            return;
        }
        else if (primFlow > 31.1111) {
            DriftParamEnum.PrimFlow.setContextValue(driftParams, primFlow);

            //console.error(ErrorMsg.smart_flowToHigh.msg);
            ErrorList.addError(errorContext, ErrorMsg.smart_flowToHigh.key, ErrorMsg.smart_flowToHigh.msg);
            return;
        }
    }

    let shuntData = setShuntType(secTempIn, secTempOut);

    //Calc Connected Energy
    if (useConnectedEnergy === true) {
        if (calcMode === 1 || connectedEnergyModel == null || connectedEnergyModel === "") {
            let measurementList = [];
            let found = false;
            let foundObj;

            if (shuntData === COOL_SHUNT) {
                if (coolingEnergyMeasurementList != null) {
                    measurementList = coolingEnergyMeasurementList.sort((a, b) => a?.minFlow - b?.minFlow);
                }
            }
            else {
                if (heatEnergyMeasurementList != null) {
                    measurementList = heatEnergyMeasurementList.sort((a, b) => a?.minFlow - b?.minFlow);
                }
            }

            for (let index = 0; index < measurementList.length; index++) {
                const element = measurementList[index];
                if (found === false) {
                    if (primFlow > element?.minFlow && primFlow <= element?.maxFlow) {
                        found = true;
                        foundObj = element;
                    }
                    else if (primFlow > element?.maxFlow && index === measurementList.length - 1) {
                        if (primFlow <= element?.absMaxFlow) {
                            found = true;
                            foundObj = element;
                        }
                        else {
                            const primFlowHighError = ErrorMsg.connected_PrimFlowToHigh(element?.absMaxFlow);
                            ErrorList.addError(errorContext, primFlowHighError.key, primFlowHighError.msg, element?.absMaxFlow);
                        }

                    }
                }
            }

            if (found === true) {
                connectedEnergyModel = foundObj;

                let extraDP = roundNumber(calcDeltaPressure(primFlow, Number(connectedEnergyModel?.kv)));
                let drivingPressure = true;
                if ((plPrincipVal === PRODUCTPRINCIP_PR2 && hasMainPump === false) || plPrincipVal === PRODUCTPRINCIP_VA1) {
                    drivingPressure = false;
                }

                //console.log("Found it!!", foundObj, extraDP, primDrivingPressure, hasMainPump, drivingPressure)
                /*
                if (extraDP > Number(primDrivingPressure) && drivingPressure === true) {
                    const primFlowHighError = ErrorMsg.connected_PressureDropToHigh(extraDP);
                    ErrorList.addError(errorContext, primFlowHighError.key, primFlowHighError.msg, extraDP);
                }*/
            }
            else {
                connectedEnergyModel = null;
            }
        }
        else if (calcMode === 0 && connectedEnergyModel != null && connectedEnergyModel !== ""){
            if (primFlow < Number(connectedEnergyModel?.absMinFlow)){
                ErrorList.addError(errorContext, ErrorMsg.connected_ManualToLowForAbsFlow.key, ErrorMsg.connected_ManualToLowForAbsFlow.msg);
                return;
            }
            else if (primFlow > Number(connectedEnergyModel?.absMaxFlow)){
                ErrorList.addError(errorContext, ErrorMsg.connected_ManualToHighForAbsFlow.key, ErrorMsg.connected_ManualToHighForAbsFlow.msg);
                return;
            }
        }
    }
    else {
        connectedEnergyModel = null;
    }



    //Automatic mode
    if (calcMode === 1) {
        //Find corresponding Dimensions based on the given parameters for Primary and Secondary
        dimRow = findCorrespondingDim(dimensionData, primFlow, primDrivingPressure, kvs, false, primarySideBV, extraBV, hasMainPump, minPressure, cvIsInterval, plPrincipVal, plProdFamilyVal, plProdLine, calcMode, adjustValve, shuntParams, connectedEnergyModel);
        dimSekRow = findCorrespondingDim(dimensionData, secondaryFlow, primDrivingPressure, kvs, true, primarySideBV, extraBV, hasMainPump, minPressure, cvIsInterval, plPrincipVal, plProdFamilyVal, plProdLine, calcMode, adjustValve, shuntParams, connectedEnergyModel);

        if (dimRow == null || dimSekRow == null) {
            //console.error(ErrorMsg.dimNotFound.msg);
            ErrorList.addError(errorContext, ErrorMsg.dimNotFound.key, ErrorMsg.dimNotFound.msg);
            return;
        }

        if (plProdLine === PRODUCTLINE_SMART) {
            smartDim = smartDims.find(obj => obj.id === dimRow.smartSV);
            if (smartDim != null) {
                if (smartDim.id === "DN125") {
                    smartMax = true;
                }
                kvs = smartDim.data.kvs;
            }
        }
        else {
            //Calculate the KVS value
            //console.log("Model: ", cvModel, cvIsInterval);
            if ((cvModel === CV_MODEL_V231 || cvModel === CV_MODEL_V311) && customKvsList != null) {
                //console.log("Custom!", customKvsList)
                kvs = calcKvs(primFlow, secondaryFlow, primDrivingPressure, secExternalPressureDrop, plPrincipVal, plProdLine, dimRow, dimSekRow, customKvsList, cvIsInterval, extraBV);
            }
            else if (cvModel === CV_MODEL_V231 || cvModel === CV_MODEL_V311) {
                kvs = calcKvs(primFlow, secondaryFlow, primDrivingPressure, secExternalPressureDrop, plPrincipVal, plProdLine, dimRow, dimSekRow, dimensionData, cvIsInterval, extraBV);
            }
            else {
                kvs = calcKvs(primFlow, secondaryFlow, primDrivingPressure, secExternalPressureDrop, plPrincipVal, plProdLine, dimRow, dimSekRow, dimensionData, cvIsInterval, extraBV);
            }

            //console.log("Kvs:", kvs)
        }


        //Reselect the primary dimension after the new KVS-value has been calculated
        //console.log("Dims", dimensionData)
        dimRow = findCorrespondingDim(dimensionData, primFlow, primDrivingPressure, kvs, false, primarySideBV, extraBV, hasMainPump, minPressure, cvIsInterval, plPrincipVal, plProdFamilyVal, plProdLine, calcMode, adjustValve, shuntParams, connectedEnergyModel);
        if (plProdLine === PRODUCTLINE_SMART) {
            smartDim = smartDims.find(obj => obj.id === dimRow.smartSV);
            let smartDimIndex = smartDims.findIndex(obj => obj.id === dimRow.smartSV);
            if (smartDim != null) {
                //console.log("SmartDim 1: ", smartDim, smartDimIndex, smartDims.length, smartDim.data.qMin, primFlow, smartDim.data.qMax)
                if (smartDimIndex >= smartDims.length) {
                    smartMax = true;
                }
                else if (primFlow > Number(smartDim.data.qMax)) {
                    smartDim = smartDims[smartDimIndex + 1];
                    if (smartDimIndex + 1 >= smartDims.length) {
                        smartMax = true;
                    }
                }
                kvs = smartDim.data.kvs;
            }
        }

        //Calculate the primary drop value
        primInternalPressureDrop = calcPrimPressureDrop(primFlow, primDrivingPressure, dimRow, kvs, minPressure, primarySideBV, extraBV, cvIsInterval, plProdFamilyVal, plProdLine, calcMode, adjustValve, shuntParams, null);
        //console.log("To High before: ", primInternalPressureDrop, '>', primDrivingPressure, plProdFamilyVal, primInternalPressureDrop > Number(primDrivingPressure))
        /**
         * (SR Only) If the primary drop value is higher than the primary driving pressure. 
         * Increase the kvs one step higher and recalculate the Dim and primary drop again. 
         */
        if ((plProdFamilyVal === PRODUCTFAMILY_SR && plPrincipVal !== PRODUCTPRINCIP_SR2FLEX) && primInternalPressureDrop > Number(primDrivingPressure) && extraBV === false) {
            hasIncreased = true;
            primDimOrg = dimRow;
            smartDimOrg = smartDim;
            primPressureDropOrg = primInternalPressureDrop;
            kvsOrg = kvs;
            let count = 0;

            while (primInternalPressureDrop > Number(primDrivingPressure) && count < 5 && smartMax === false) {
                count++;
                let newKvs;
                if (plProdLine === PRODUCTLINE_SMART) {
                    newKvs = getNextSmartDimKvs(dimensionData, smartDims, smartDim.id)
                }
                else {
                    newKvs = getNextDimKvs(dimensionData, kvs);
                }


                if (newKvs !== null) {
                    kvs = newKvs;
                }
                else {
                    dimRow = primDimOrg;
                    smartDim = smartDimOrg;
                    primInternalPressureDrop = primPressureDropOrg;
                    kvs = kvsOrg;
                    hasIncreased = false;
                    break;
                }

                if (plProdLine === PRODUCTLINE_SMART) {
                    // eslint-disable-next-line no-loop-func
                    smartDim = smartDims.find(obj => obj.data.kvs === kvs);
                    if (smartDim != null) {
                        //console.log("SmartDim 2: ", smartDim, primFlow)
                        if (smartDim.id === "DN125") { //Currently max
                            smartMax = true;
                        }
                        if (primFlow < smartDim.data.qMin && primFlow > smartDim.data.qMax) {
                            //console.error("PrimFlow not matching")
                            break;
                        }
                    }
                }
                else {
                    dimRow = findCorrespondingDim(dimensionData, primFlow, primDrivingPressure, kvs, false, primarySideBV, extraBV, hasMainPump, minPressure, cvIsInterval, plPrincipVal, plProdFamilyVal, plProdLine, calcMode, adjustValve, shuntParams, connectedEnergyModel);

                    if (dimRow == null) {
                        dimRow = primDimOrg;
                        primInternalPressureDrop = primPressureDropOrg;
                        kvs = kvsOrg;
                        hasIncreased = false;
                        break;
                    }
                }

                primInternalPressureDrop = calcPrimPressureDrop(primFlow, primDrivingPressure, dimRow, kvs, minPressure, primarySideBV, extraBV, cvIsInterval, plProdFamilyVal, plProdLine, calcMode, adjustValve, shuntParams, null);
                //console.log("To High After: ", primInternalPressureDrop, '>', primDrivingPressure, primInternalPressureDrop > Number(primDrivingPressure), smartDim?.id)
            }
        }

        // (SR Only) If the Primary dim is larger than the secondary. Increase the secondary to match the primary dim.
        if (plProdFamilyVal === PRODUCTFAMILY_SR) {
            if (dimRow.dim > dimSekRow.dim) {
                dimSekRow = dimRow;
            }
        }

        // SR-2-Smart Check
        if (plProdLine === PRODUCTLINE_SMART) {
            if (primDrivingPressure > 400) {
                ErrorList.addError(errorContext, ErrorMsg.smart_highPressure.key, ErrorMsg.smart_highPressure.msg);
            }
            else if (primFlow < smartDim.data.qMin) {
                if (hasIncreased === true) {
                    smartDim = smartDimOrg;
                    dimRow = primDimOrg;
                    primInternalPressureDrop = primPressureDropOrg;
                    kvs = kvsOrg;
                }

                const primPressureError = ErrorMsg.smart_PrimPressureToLow(primInternalPressureDrop);
                //console.error(primPressureError.msg);
                ErrorList.addError(errorContext, primPressureError.key, primPressureError.msg);
            }
            else if (primFlow > smartDim.data.qMax) {
                if (hasIncreased === true) {
                    smartDim = smartDimOrg;
                    dimRow = primDimOrg;
                    primInternalPressureDrop = primPressureDropOrg;
                    kvs = kvsOrg;
                }

                console.error(ErrorMsg.smart_flowToHigh.msg);
                ErrorList.addError(errorContext, ErrorMsg.smart_flowToHigh.key, ErrorMsg.smart_flowToHigh.msg);
            }
            else if (primInternalPressureDrop > Number(primDrivingPressure)) {
                if (hasIncreased === true) {
                    smartDim = smartDimOrg;
                    dimRow = primDimOrg;
                    primInternalPressureDrop = primPressureDropOrg;
                    kvs = kvsOrg;
                }

                const primPressureError = ErrorMsg.smart_PrimPressureToLow(primInternalPressureDrop);
                //console.error(primPressureError.msg);
                ErrorList.addError(errorContext, primPressureError.key, primPressureError.msg);
            }
            else {
                if (primFlow <= (Number(smartDim.data.qMin) * 1.10)) {
                    //console.log(NoticeMsg.smart_flowCloseLow.msg);
                    NoticeList.addNotice(noticeContext, NoticeMsg.smart_flowCloseLow.key, NoticeMsg.smart_flowCloseLow.msg);
                }
                else if (primFlow >= (Number(smartDim.data.qMax) * 0.9)) {
                    //console.log(NoticeMsg.smart_flowCloseHigh.msg);
                    NoticeList.addNotice(noticeContext, NoticeMsg.smart_flowCloseHigh.key, NoticeMsg.smart_flowCloseHigh.msg);
                }
            }
        }

        if (plProdLine !== PRODUCTLINE_FLEX) {
            ShuntParamsEnum.CV_Kvs.setContextValue(shuntParams, kvs);
            if (plProdLine === PRODUCTLINE_SMART) {
                ShuntParamsEnum.SmartDimID.setContextValue(shuntParams, smartDim?.id)
                ShuntParamsEnum.SmartDim.setContextValue(shuntParams, smartDim)
                ShuntParamsEnum.ControlValve.setContextValue(shuntParams, CV_IMI)
                ShuntParamsEnum.CV_Model.setContextValue(shuntParams, CV_MODEL_TASMART);
            }
            else {
                if (ShuntParamsEnum.CV_Model.getContextValue(shuntParams) == null) {
                    ShuntParamsEnum.CV_Model.setContextValue(shuntParams, CV_MODEL_KVS);
                }
                if (ShuntParamsEnum.ControlValve.getContextValue(shuntParams) == null) {
                    ShuntParamsEnum.ControlValve.setContextValue(shuntParams, CV_SOE)
                }

            }
        }
        else {
            if (primDrivingPressure < primInternalPressureDrop) {
                ErrorList.addError(errorContext, ErrorMsg.flex_lowPressure.key, ErrorMsg.flex_lowPressure.msg);
            }

            if (ShuntParamsEnum.ControlValve.getContextValue(shuntParams) == null || ShuntParamsEnum.ControlValve.getContextValue(shuntParams) === "") {
                ShuntParamsEnum.ControlValve.setContextValue(shuntParams, CV_IMI);
            }
            if (ShuntParamsEnum.FlexModel.getContextValue(shuntParams) == null || ShuntParamsEnum.FlexModel.getContextValue(shuntParams) === "") {
                ShuntParamsEnum.FlexModel.setContextValue(shuntParams, CV_MODEL_TAMODULATOR);
            }
        }
    }
    //Manual mode
    else if (calcMode === 0) {
        const primDimName = DriftParamEnum.PrimaryDim.getContextValue(driftParams);
        const sekDimName = DriftParamEnum.SecondaryDim.getContextValue(driftParams);

        if (primDimName == null || primDimName === "" || sekDimName == null || sekDimName === "") {
            //console.error(ErrorMsg.manual_noDimSelected.msg);
            ErrorList.addError(errorContext, ErrorMsg.manual_noDimSelected.key, ErrorMsg.manual_noDimSelected.msg);
            return;
        }

        dimRow = getDimRowFromName(primDimName, dimensionData);
        dimSekRow = getDimRowFromName(sekDimName, dimensionData);

        if (plProdLine !== PRODUCTLINE_FLEX) {
            if (ShuntParamsEnum.CV_Model.getContextValue(shuntParams) == null || ShuntParamsEnum.CV_Model.getContextValue(shuntParams) === "") {
                ErrorList.addError(errorContext, ErrorMsg.manual_cvNotSet.key, ErrorMsg.manual_cvNotSet.msg);
                return;
            }
        }

        if (cvIsInterval === true) {
            //Calculate the KVS value
            kvs = calcKvs(primFlow, secondaryFlow, primDrivingPressure, secExternalPressureDrop, plPrincipVal, plProdLine, dimRow, dimSekRow, dimensionData, cvIsInterval, extraBV);

            //Check if the kvs-value is within the selected control-valve's range.
            const kvVals = ShuntParamsEnum.CV_Kvs.getContextValue(shuntParams);
            let min = Number(kvVals.split(' - ')[0]);
            let max = Number(kvVals.split(' - ')[1]);
            if (kvs < min) {
                //console.error(ErrorMsg.manual_flowToLowForCV.msg);
                ErrorList.addError(errorContext, ErrorMsg.manual_flowToLowForCV.key, ErrorMsg.manual_flowToLowForCV.msg);
            }
            else if (kvs > max) {
                //console.error(ErrorMsg.manual_flowToLowForCV.msg);
                ErrorList.addError(errorContext, ErrorMsg.manual_flowToHighForCV.key, ErrorMsg.manual_flowToHighForCV.msg);
            }
        }
        else if (plProdLine === PRODUCTLINE_SMART) {
            let smartSVid = ShuntParamsEnum.SmartDimID.getContextValue(shuntParams);
            let smartDim = smartDims.find(obj => obj.id === smartSVid);


            if (smartDim != null) {
                ShuntParamsEnum.SmartDim.setContextValue(shuntParams, smartDim)
                kvs = smartDim.data.kvs;

                if (primFlow < smartDim.data.qMin) {
                    //console.error(ErrorMsg.smart_PrimFlowToLow_man.msg);
                    ErrorList.addError(errorContext, ErrorMsg.smart_PrimFlowToLow_man.key, ErrorMsg.smart_PrimFlowToLow_man.msg);
                }
                else if (primFlow > smartDim.data.qMax) {
                    //console.error(ErrorMsg.smart_PrimFlowToHigh_man.msg);
                    ErrorList.addError(errorContext, ErrorMsg.smart_PrimFlowToHigh_man.key, ErrorMsg.smart_PrimFlowToHigh_man.msg);
                }
                else {
                    if (primFlow <= (Number(smartDim.data.qMin) * 1.10)) {
                        //console.log(NoticeMsg.smart_flowCloseLow.msg);
                        NoticeList.addNotice(noticeContext, NoticeMsg.smart_flowCloseLow.key, NoticeMsg.smart_flowCloseLow.msg);
                    }
                    else if (primFlow >= (Number(smartDim.data.qMax) * 0.9)) {
                        //console.log(NoticeMsg.smart_flowCloseHigh.msg);
                        NoticeList.addNotice(noticeContext, NoticeMsg.smart_flowCloseHigh.key, NoticeMsg.smart_flowCloseHigh.msg);
                    }
                }
            }
            else {
                //console.error(ErrorMsg.manual_cvNotSet.msg);
            }
        }
        else if (plProdLine !== PRODUCTLINE_FLEX) {
            kvs = ShuntParamsEnum.CV_Kvs.getContextValue(shuntParams);

            if (kvs == null) {
                //console.error(ErrorMsg.manual_cvNotSet.msg);
                ErrorList.addError(errorContext, ErrorMsg.manual_cvNotSet.key, ErrorMsg.manual_cvNotSet.msg);
                return;
            }
        }

        primInternalPressureDrop = calcPrimPressureDrop(primFlow, primDrivingPressure, dimRow, kvs, minPressure, primarySideBV, extraBV, cvIsInterval, plProdFamilyVal, plProdLine, calcMode, adjustValve, shuntParams, null);
        //console.log("Prim Drop: ", primInternalPressureDrop);
    }
    else {
        //console.error(ErrorMsg.internalError.key + " - No ProductType could be found!");
        ErrorList.addError(errorContext, ErrorMsg.internalError.key, ErrorMsg.internalError.msg);
        return;
    }

    if (useConnectedEnergy === true && connectedEnergyModel != null && connectedEnergyModel?.kv != null) {
        let extraDP = roundNumber(calcDeltaPressure(primFlow, Number(connectedEnergyModel?.kv)));
        //console.log("Values ConnectedEnergy", extraDP, primInternalPressureDrop, primDrivingPressure, extraDP + primInternalPressureDrop > primDrivingPressure, roundNumber(extraDP + primInternalPressureDrop) > primDrivingPressure)
        if (plPrincipVal === PRODUCTPRINCIP_VA1 || (plPrincipVal === PRODUCTPRINCIP_PR2 && hasMainPump === false)) {
            primInternalPressureDrop = roundNumber(primInternalPressureDrop + extraDP);
        }
        else {
            if (roundNumber(extraDP + primInternalPressureDrop) > primDrivingPressure) {
                const primFlowHighError = ErrorMsg.connected_PressureDropToHigh(extraDP, extraDP + primInternalPressureDrop);
                ErrorList.addError(errorContext, primFlowHighError.key, primFlowHighError.msg, extraDP, roundNumber(extraDP + primInternalPressureDrop));
            }

            primInternalPressureDrop = roundNumber(primInternalPressureDrop + extraDP);
        }
    }


    let secInternalPressureDrop = calcSecondaryPressureDrop(secondaryFlow, primFlow, kvs, dimSekRow, secondarySideBV, shuntParams, cvIsInterval, plProdFamilyVal, plProdLine, calcMode, adjustValve, backVents);
    let SVpressureLoss;

    if (plProdLine === PRODUCTLINE_FLEX) {
        SVpressureLoss = minPressure;
    }
    else if (plProdFamilyVal === PRODUCTFAMILY_PR) {
        SVpressureLoss = calcDeltaPressure(secondaryFlow, kvs);
    }
    else {
        SVpressureLoss = calcDeltaPressure(primFlow, kvs);
    }

    if (plProdLine === PRODUCTLINE_SMART && calcMode === 0) {
        if (SVpressureLoss > primDrivingPressure) {
            //console.error(ErrorMsg.smart_PrimPressureToLow_man.msg);
            ErrorList.addError(errorContext, ErrorMsg.smart_PrimPressureToLow_man.key, ErrorMsg.smart_PrimPressureToLow_man.msg);
        }
    }

    //console.log("PEFK: ", pEfk, SVpressureLoss);
    let authority;
    if (plPrincipVal === PRODUCTPRINCIP_PR3) {
        authority = calcAuthority(kvs, primFlow, secondaryFlow, primInternalPressureDrop, dimSekRow.rorAndBend, plPrincipVal, hasMainPump, Number(pEfk), primDrivingPressure, primExternalPressureDrop, SVpressureLoss, cvIsInterval, calcMode);
    }
    else {
        authority = calcAuthority(kvs, primFlow, secondaryFlow, primInternalPressureDrop, dimRow.rorAndBend, plPrincipVal, hasMainPump, Number(pEfk), primDrivingPressure, primExternalPressureDrop, SVpressureLoss, cvIsInterval, calcMode);
    }


    if (plProdFamilyVal === PRODUCTFAMILY_VA && calcMode === 1) {
        let isBelow = false;
        let nextKvs = kvs;

        //Decrease kvs until authority is above 20%
        while (authority < 20) {
            isBelow = true;

            //console.log("Too Low: ", nextKvs, authority);
            let newKvs = getNextLowerDimKvs(dimensionData, nextKvs);
            if (newKvs != null) {
                let newAuth = calcAuthority(newKvs, primFlow, secondaryFlow, primInternalPressureDrop, dimSekRow.rorAndBend, plPrincipVal, hasMainPump, Number(pEfk), primDrivingPressure, primExternalPressureDrop, SVpressureLoss, cvIsInterval, calcMode);

                if (newAuth >= 20) {
                    SVpressureLoss = calcDeltaPressure(primFlow, newKvs);
                    secInternalPressureDrop = calcSecondaryPressureDrop(secondaryFlow, primFlow, newKvs, dimSekRow, secondarySideBV, shuntParams, cvIsInterval, plProdFamilyVal, plProdLine, calcMode, adjustValve, backVents);
                    kvs = newKvs;
                    authority = newAuth;
                    ShuntParamsEnum.CV_Kvs.setContextValue(shuntParams, kvs);
                    break;
                }
                else {
                    nextKvs = newKvs;
                }
            }
            else {
                console.error("Can't go lower!");
                break;
            }
        }

        if (isBelow === false) {
            let nextAuthVal = authority;
            let nextKvs = kvs;

            //Increase kvs until authority is above 20% (unless it has prev. been decreased to reach threshold)
            while (nextAuthVal >= 20) {
                let newKvs = getNextDimKvs(dimensionData, nextKvs);

                if (newKvs != null) {
                    let newAuth = calcAuthority(newKvs, primFlow, secondaryFlow, primInternalPressureDrop, dimSekRow.rorAndBend, plPrincipVal, hasMainPump, Number(pEfk), primDrivingPressure, primExternalPressureDrop, SVpressureLoss, cvIsInterval, calcMode);

                    if (newAuth < 20) {
                        //console.log("Can't go up another step! Auth:", newAuth, "kvs: ", newKvs);
                        break;
                    }
                    else {
                        //console.log("New Auth: ", newAuth);
                        nextAuthVal = newAuth;
                        nextKvs = newKvs;
                    }
                }
                else {
                    //console.error("Can't go higher!");
                    break;
                }
            }



            if (nextAuthVal !== authority) {
                SVpressureLoss = calcDeltaPressure(primFlow, nextKvs);
                secInternalPressureDrop = calcSecondaryPressureDrop(secondaryFlow, primFlow, nextKvs, dimSekRow, secondarySideBV, shuntParams, cvIsInterval, plProdFamilyVal, plProdLine, calcMode, adjustValve, backVents);
                kvs = nextKvs;
                authority = nextAuthVal;
                ShuntParamsEnum.CV_Kvs.setContextValue(shuntParams, kvs);
            }
        }
    }

    //console.log("PEFK: Auth:", authority, SVpressureLoss);
    if (authority !== 0 && authority < 20) {
        NoticeList.addNotice(noticeContext, NoticeMsg.calc_authToLow.key, NoticeMsg.calc_authToLow.msg);
    }

    if (plProdLine === PRODUCTLINE_GREEN && plPrincipVal !== PRODUCTPRINCIP_SR2 && kvs < 1.6) {
        NoticeList.addNotice(noticeContext, NoticeMsg.green_toLowKvs.key, NoticeMsg.green_toLowKvs.msg);
    }

    const totalPressure = calcTotalPressure(plPrincipVal, hasMainPump, secExternalPressureDrop, primDrivingPressure, Number(pEfk), secInternalPressureDrop, SVpressureLoss, primInternalPressureDrop, primExternalPressureDrop);
    //console.log("Total P: ", totalPressure);
    //console.log("Auth final: ", authority);
    //console.log("Prim Dim (final):", dimRow);
    //console.log("Sek Dim (final):", dimSekRow);

    const balanceKV = calculateBalancingKV(plProdFamilyVal, plProdLine, cvModel, primDrivingPressure, hasMainPump, extraBV, primFlow, dimRow, calcMode, adjustValve, primInternalPressureDrop);
    //console.log("Balance: ", balanceKV)
    let primInternalDrivPressure;
    if (plPrincipVal === PRODUCTPRINCIP_VA1 || (plPrincipVal === PRODUCTPRINCIP_PR2 && hasMainPump === false)) {
        primInternalDrivPressure = calcInternalPrimDP(plProdLine, plProdFamilyVal, primExternalPressureDrop, SVpressureLoss, primFlow, balanceKV);
    }
    else {
        primInternalDrivPressure = calcInternalPrimDP(plProdLine, plProdFamilyVal, primDrivingPressure, SVpressureLoss, primFlow, balanceKV);
    }
    //console.log(primInternalDrivPressure);
    //checkForFlowErrors(secondaryFlow, primDrivingPressure, errorContext);
    //console.log("MaxPressure", maxPressure)
    checkForFlowErrors(primDrivingPressure, primFlow, secondaryFlow, plProdLine, plPrincipVal, errorContext, noticeContext, true);

    checkForPressureErrors(primDrivingPressure, primInternalPressureDrop, secInternalPressureDrop, SVpressureLoss, plProdLine, plPrincipVal, maxPressure, hasMainPump, primFlow, dimRow?.maxFlow, calcMode, errorContext, noticeContext);


    if ((isNaN(authority) === true || authority === 0) && calcMode === 0) {
        if (Number(SVpressureLoss) < Number(primDrivingPressure) * 0.20 && kvs > 0.25) {
            NoticeList.addNotice(noticeContext, NoticeMsg.manual_cvPressureDropLowUnknownAuthority.key, NoticeMsg.manual_cvPressureDropLowUnknownAuthority.msg);
        }
    }

    if (Number(hasBackVent) === 0) {
        NoticeList.addNotice(noticeContext, NoticeMsg.manual_noBackVent.key, NoticeMsg.manual_noBackVent.msg);
    }

    if (plProdLine === PRODUCTLINE_GREEN) {
        let isGreenLvl2 = isGreenLevel2(plPrincipVal, shuntData, adjustValve, dimRow?.dim, dimSekRow?.dim, primTempIn, primTempOut, secTempIn, secTempOut, kvs, useConnectedEnergy);
        console.log("SetLevel - ", isGreenLvl2, shuntID, maxStep)
        //if (maxStep < 2) { //Behövs denna?
        if (isGreenLvl2 === true) {
            ProductLineEnum.ProductGreenLevel.setContextValue(productLine, 2, database, shuntID);
        }
        else {
            ProductLineEnum.ProductGreenLevel.setContextValue(productLine, 1, database, shuntID);
            ShuntParamsEnum.CV_Model.setContextValue(shuntParams, CV_MODEL_KVS);
            ShuntParamsEnum.ControlValve.setContextValue(shuntParams, CV_SOE)
        }
        //}
    }

    if (flowCalcMode == null || flowCalcMode === "") {
        DriftParamEnum.FlowCalcMode.setContextValue(driftParams, 'ls')
    }

    ShuntParamsEnum.NumBV.setContextValue(shuntParams, backVents);
    ShuntParamsEnum.NumIV.setContextValue(shuntParams, numTotalIV);

    ShuntParamsEnum.ConnectedEnergyModel.setContextValue(shuntParams, connectedEnergyModel);

    DriftParamEnum.PrimFlow.setContextValue(driftParams, primFlow);

    CalcParamsEnum.Kvs.setContextValue(calcParams, kvs);
    CalcParamsEnum.BalancingKv.setContextValue(calcParams, balanceKV);
    DriftParamEnum.PrimaryDim.setContextValue(driftParams, dimRow.display);
    CalcParamsEnum.PrimDimRow.setContextValue(calcParams, dimRow)
    DriftParamEnum.SecondaryDim.setContextValue(driftParams, dimSekRow.display);
    CalcParamsEnum.SekDimRow.setContextValue(calcParams, dimSekRow);
    CalcParamsEnum.Authority.setContextValue(calcParams, authority);
    CalcParamsEnum.SVPressureLoss.setContextValue(calcParams, SVpressureLoss);
    CalcParamsEnum.PrimInternalPressureDrop.setContextValue(calcParams, primInternalPressureDrop);
    CalcParamsEnum.SecInternalPressureDrop.setContextValue(calcParams, secInternalPressureDrop);
    CalcParamsEnum.ShuntType.setContextValue(calcParams, shuntData);
    CalcParamsEnum.TotalPressure.setContextValue(calcParams, roundToDecimals(totalPressure, 1));
    CalcParamsEnum.PrimInternalDP.setContextValue(calcParams, primInternalDrivPressure);

    let largestDim;
    if (dimRow?.dim > dimSekRow?.dim) {
        largestDim = dimRow?.dim;
    }
    else {
        largestDim = dimSekRow?.dim;
    }
    if (largestDim <= 20 && ShuntParamsEnum.IsMiniIncompatible.getContextValue(shuntParams) !== true) {
        ShuntParamsEnum.IsMini.setContextValue(shuntParams, true);
    }
    else {
        ShuntParamsEnum.IsMini.setContextValue(shuntParams, false);
    }

    SessionParamsEnum.UpdateValues.setContextValue(sessionParams, !SessionParamsEnum.UpdateValues.getContextValue(sessionParams));
    return;
}

export { calculateShuntParams }