import { PRODUCTLINE_FLEX, PRODUCTLINE_SMART } from "../../../constants/productlineConstants";
import { SessionParamsEnum } from "../../../enums";
import { AdjustmentsEnum } from "../../../enums/adjustmentsEnum";
import { ProductLineEnum } from "../../../enums/productLineEnum"
import { ShuntParamsEnum } from "../../../enums/shuntParamsEnum";


const createShuntNomenklaturString = function createShuntNomenklaturString(productLineParams, adjustmentParams, sessionParams, shuntParams, database, shuntID) {
    const prodFamily = ProductLineEnum.ProductFamily.getContextValue(productLineParams);
    const prodLine = ProductLineEnum.ProductLine.getContextValue(productLineParams);
    //const prodPricip = ProductLineEnum.ProductPrincip.getContextValue(productLineParams);   
    const prodNWaysValue = ProductLineEnum.ProductNWaysValue.getContextValue(productLineParams);

    const connectionDesign = AdjustmentsEnum.ConnectionDesign.getContextValue(adjustmentParams);
    const connectionValue = AdjustmentsEnum.ConnectionValue.getContextValue(adjustmentParams);

    const numBV = ShuntParamsEnum.NumBV.getContextValue(shuntParams);
    const numIV = ShuntParamsEnum.NumIV.getContextValue(shuntParams);
    const hasLatch = ShuntParamsEnum.HasLatch.getContextValue(shuntParams);
    const isSabo = ShuntParamsEnum.IsSabo.getContextValue(shuntParams);

    //console.log("NUM:", numBV, shuntParams)
    let nomenklaturString;

    if (connectionDesign != null && prodFamily != null && prodNWaysValue != null) {
        nomenklaturString = prodFamily + String(connectionDesign).toUpperCase() + "-" + prodNWaysValue;

        let plModel = nomenklaturString;

        if (prodLine === PRODUCTLINE_FLEX) {
            nomenklaturString += "Flex";
        }
        else if (prodLine === PRODUCTLINE_SMART) {
            nomenklaturString += "Smart";
        }

        nomenklaturString += "-";

        if (connectionValue != null) {
            nomenklaturString += String(connectionValue).toUpperCase();
        }
        else {
            nomenklaturString += '_'
        }

        nomenklaturString += "-";

        if (numBV != null && Number(numBV) > 0) {
            nomenklaturString += numBV + "bv";
        }
        else {
            nomenklaturString += "_";
        }

        //Kyl/Värmespärr - tag
        if (hasLatch != null && Number(hasLatch) === 1) {
            nomenklaturString += "Sp";
        }
        else if (isSabo != null && Number(isSabo) === 1) {
            nomenklaturString += "Sabo";
        }

        nomenklaturString += "-";

        if (numIV != null && Number(numIV) > 0) {
            nomenklaturString += numIV + "iv";
        }
        else {
            nomenklaturString += "_";
        }

        //console.log("Set Data: ", shuntID, plModel, hasLatch, SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams))
        if (SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true) {
            ShuntParamsEnum.PLModel.setContextValue(shuntParams, plModel, database, shuntID);
            ShuntParamsEnum.ShuntNomenklaturString.setContextValue(shuntParams, nomenklaturString, database, shuntID);
            if (hasLatch != null){
                ShuntParamsEnum.HasLatch.setContextValue(shuntParams, hasLatch, database, shuntID)
            }
            
        }
    }
}

const createShuntNomenklaturString_manual = function createShuntNomenklaturString_manual(connectionValue, productLineParams, adjustmentParams, shuntParams) {
    const prodFamily = ProductLineEnum.ProductFamily.getContextValue(productLineParams);
    const prodLine = ProductLineEnum.ProductLine.getContextValue(productLineParams);
    //const prodPricip = ProductLineEnum.ProductPrincip.getContextValue(productLineParams);   
    const prodNWaysValue = ProductLineEnum.ProductNWaysValue.getContextValue(productLineParams);

    let connectionDesign = AdjustmentsEnum.ConnectionDesign.getContextValue(adjustmentParams);

    const numBV = ShuntParamsEnum.NumBV.getContextValue(shuntParams);
    const numIV = ShuntParamsEnum.NumIV.getContextValue(shuntParams);
    const hasLatch = ShuntParamsEnum.HasLatch.getContextValue(shuntParams);
    const isSabo = ShuntParamsEnum.IsSabo.getContextValue(shuntParams);

    //console.log("NUM:", numBV, shuntParams)
    let nomenklaturString;
    //console.log("Fält: ", connectionDesign, prodFamily, prodNWaysValue)
    if (connectionDesign == null || connectionDesign === "") {
        connectionDesign = 'u';
    }

    if (prodFamily != null && prodNWaysValue != null) {
        nomenklaturString = prodFamily + String(connectionDesign).toUpperCase() + "-" + prodNWaysValue;

        if (prodLine === PRODUCTLINE_FLEX) {
            nomenklaturString += "Flex";
        }
        else if (prodLine === PRODUCTLINE_SMART) {
            nomenklaturString += "Smart";
        }

        nomenklaturString += "-";

        if (connectionValue != null) {
            nomenklaturString += String(connectionValue).toUpperCase();
        }
        else {
            nomenklaturString += '_'
        }

        nomenklaturString += "-";

        if (numBV != null && Number(numBV) > 0) {
            nomenklaturString += numBV + "bv";
        }
        else {
            nomenklaturString += "_";
        }

        //Kyl/Värmespärr - tag
        if (hasLatch != null && Number(hasLatch) === 1) {
            nomenklaturString += "Sp";
        }
        else if (isSabo != null && Number(isSabo) === 1) {
            nomenklaturString += "Sabo";
        }

        nomenklaturString += "-";

        if (numIV != null && Number(numIV) > 0) {
            nomenklaturString += numIV + "iv";
        }
        else {
            nomenklaturString += "_";
        }

        //console.log("ShuntNomenklatur:", nomenklaturString);
        return nomenklaturString;
    }

    return '';
}

const createShuntNomenklaturString_fromFBData = function createShuntNomenklaturString_fromFBData(connectionValue, prodFamily, prodLine, prodNWaysValue, connectionDesign, numBV, numIV, hasLatch, isSabo) {
    let nomenklaturString;

    if (connectionDesign != null && prodFamily != null && prodNWaysValue != null) {
        nomenklaturString = prodFamily + String(connectionDesign).toUpperCase() + "-" + prodNWaysValue;

        if (prodLine === PRODUCTLINE_FLEX) {
            nomenklaturString += "Flex";
        }
        else if (prodLine === PRODUCTLINE_SMART) {
            nomenklaturString += "Smart";
        }

        nomenklaturString += "-";

        if (connectionValue != null) {
            nomenklaturString += String(connectionValue).toUpperCase();
        }
        else {
            nomenklaturString += '_'
        }

        nomenklaturString += "-";

        if (numBV != null && Number(numBV) > 0) {
            nomenklaturString += numBV + "bv";
        }
        else {
            nomenklaturString += "_";
        }

        //Kyl/Värmespärr - tag
        if (hasLatch != null && Number(hasLatch) === 1) {
            nomenklaturString += "Sp";
        }
        else if (isSabo != null && Number(isSabo) === 1) {
            nomenklaturString += "Sabo";
        }

        nomenklaturString += "-";

        if (numIV != null && Number(numIV) > 0) {
            nomenklaturString += numIV + "iv";
        }
        else {
            nomenklaturString += "_";
        }

        //console.log("ShuntNomenklatur:", nomenklaturString);
        return nomenklaturString;
    }

    return '';
}

export { createShuntNomenklaturString, createShuntNomenklaturString_manual, createShuntNomenklaturString_fromFBData }