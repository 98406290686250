import { Box, Stack, FormControl, InputLabel, Select, MenuItem, FormLabel, TextField, FormControlLabel, Checkbox, Tooltip } from "@mui/material";
import { SYSTEM_FLUID, QUESTION_SYSTEM_FLUID_MIX_RATIO_KEY, QUESTION_SYSTEM_FLUID_KEY, QUESTION_SYSTEM_FLUID_MIX_RATIO_UNKNOWN_KEY } from "../../../constants/wizardConstants";
import { WizardParamEnum } from "../../../enums/wizardParamsEnum";
import { useWizardParams } from "../../../contexts/wizardParams";
import { useEffect, useState } from "react";
import { SessionParamsEnum } from "../../../enums";
import { useSessionParams } from "../../../contexts";
import { useDatabase } from "reactfire";
import { useContextAuth } from "../../../authContext/AuthHook";
import { SystemFluidMixRatioInputfield } from "./systemFluidMixRatioInputField";
import { useGetSystemFluidOptions } from "../../../hooks/useGetSystemFluidOptions";


export const SystemFluidPickerPumpTab = () => {
    const { user } = useContextAuth();
    const sessionParams = useSessionParams();
    const wizardParams = useWizardParams();
    const database = useDatabase();

    const systemFluidOptions = useGetSystemFluidOptions();

    const getSystemFluidSelected = (value) => {
        if (value != null) {
            return systemFluidOptions.find((obj) => obj.value === value);
        }
        return null;
    }

    const [systemFluid, setSystemFluid] = useState(SYSTEM_FLUID.unknown.value);
    const [systemFluidMixRatio, setSystemFluidMixRatio] = useState(100);
    const [systemFluidMixRatioUnknown, setSystemFluidMixRatioUnknown] = useState(false);


    useEffect(() => {
        if (WizardParamEnum.SystemFluidMain.getContextValue(wizardParams) != null) {
            setSystemFluid(WizardParamEnum.SystemFluidMain.getContextValue(wizardParams))
        }
    }, [WizardParamEnum.SystemFluidMain.getContextValue(wizardParams)])

    useEffect(() => {
        if (WizardParamEnum.SystemFluidPrimaryMixRatio.getContextValue(wizardParams) != null) {
            setSystemFluidMixRatio(WizardParamEnum.SystemFluidPrimaryMixRatio.getContextValue(wizardParams))
        }
    }, [WizardParamEnum.SystemFluidPrimaryMixRatio.getContextValue(wizardParams)])

    useEffect(() => {
        if (WizardParamEnum.SystemFluidPrimaryMixRatioUnknown.getContextValue(wizardParams) != null) {
            setSystemFluidMixRatioUnknown(WizardParamEnum.SystemFluidPrimaryMixRatioUnknown.getContextValue(wizardParams))
        }
    }, [WizardParamEnum.SystemFluidPrimaryMixRatioUnknown.getContextValue(wizardParams)])


    const answerQuestion = (key, value) => {
        switch (key) {
            case QUESTION_SYSTEM_FLUID_KEY:
                WizardParamEnum.SystemFluidMain.setContextValue(wizardParams, value, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams), SessionParamsEnum.SessionID.getContextValue(sessionParams));
                break;
            case QUESTION_SYSTEM_FLUID_MIX_RATIO_KEY:
                WizardParamEnum.SystemFluidPrimaryMixRatio.setContextValue(wizardParams, value, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams), SessionParamsEnum.SessionID.getContextValue(sessionParams));
                break;
            case QUESTION_SYSTEM_FLUID_MIX_RATIO_UNKNOWN_KEY:
                WizardParamEnum.SystemFluidPrimaryMixRatioUnknown.setContextValue(wizardParams, value, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams), SessionParamsEnum.SessionID.getContextValue(sessionParams));
                break;
            default:
                break;
        }
    }

    const handleChangeFluidMix = (value, key) => {
        const fluidOption = getSystemFluidSelected(systemFluid);
        if (fluidOption?.maxMixRatio != null) {
            if (Number(value) > fluidOption?.maxMixRatio) {
                answerQuestion(key, fluidOption?.maxMixRatio);
            }
            else if (Number(value) < 0) {
                answerQuestion(key, 0);
            }
            else {
                answerQuestion(key, value);
            }
        }
        else {
            if (Number(value) > 100) {
                answerQuestion(key, 100);
            }
            else if (Number(value) < 0) {
                answerQuestion(key, 0);
            }
            else {
                answerQuestion(key, value);
            }
        }
    }

    return (
        <Tooltip title={"Systemvätska ändras på Parameterfliken"} arrow placement="right">
            <Box padding={'2px 8px'}>
                <Stack>
                    <Stack sx={{ marginTop: '10px' }} direction={'row'} spacing={2} alignItems={'flex-start'}>
                        <Box sx={{ paddingTop: (systemFluid !== SYSTEM_FLUID.water.value && systemFluid !== SYSTEM_FLUID.unknown.value && systemFluid !== SYSTEM_FLUID.other.value) ? '10px' : '0px', }}>
                            <FormControl size="small">
                                <InputLabel>Systemvätska</InputLabel>
                                <Select
                                    sx={{ width: '150px', height: '30px', background: 'white' }}
                                    size="small"
                                    value={systemFluid}
                                    label={'Systemvätska'}
                                    disabled
                                    onChange={(e) => {
                                        if (getSystemFluidSelected(e.target.value)?.defaultMixRatio != null) {
                                            answerQuestion(QUESTION_SYSTEM_FLUID_MIX_RATIO_KEY, getSystemFluidSelected(e.target.value)?.defaultMixRatio);
                                        }
                                        answerQuestion(QUESTION_SYSTEM_FLUID_KEY, e.target.value)
                                    }}
                                >
                                    {systemFluidOptions.map((item) => {
                                        if (item?.wizardOptionOnly !== true) {
                                            return (
                                                <MenuItem key={item?.value} value={item?.value}>{item?.label}</MenuItem>
                                            );
                                        }
                                        else {
                                            return "";
                                        }

                                    })}
                                </Select>
                            </FormControl>
                        </Box>


                        {getSystemFluidSelected(systemFluid)?.defaultMixRatio != null ? (
                            <Box paddingBottom={'10px'}>
                                <Stack direction={'row'} alignItems={'flex-end'} marginTop={'-15px'}>
                                    <Stack>
                                        <FormLabel sx={{ fontSize: '11px' }}>Blandningsförhållande: (%)</FormLabel>
                                        <Stack direction={'row'} spacing={1} marginTop={'8px'}>
                                            <SystemFluidMixRatioInputfield
                                                questionKey={QUESTION_SYSTEM_FLUID_MIX_RATIO_KEY}
                                                value={systemFluidMixRatio}
                                                systemFluid={systemFluid}
                                                handleChangeFluidMix={handleChangeFluidMix}
                                                disabled
                                            />

                                            <TextField
                                                sx={{ width: '60px', background: 'white' }}
                                                size="small"
                                                type="number"
                                                label="Vatten"
                                                value={Math.round((100 - systemFluidMixRatio) * 100) / 100}
                                                disabled
                                                InputProps={{
                                                    inputProps: {
                                                        step: 'any',
                                                        max: 100,
                                                        min: 0
                                                    }
                                                }}
                                            />
                                        </Stack>
                                    </Stack>
                                    <FormControlLabel
                                        sx={{ marginLeft: '5px', height: '28px' }}
                                        control={
                                            <Checkbox disabled checked={systemFluidMixRatioUnknown} onChange={() => answerQuestion(QUESTION_SYSTEM_FLUID_MIX_RATIO_UNKNOWN_KEY, !systemFluidMixRatioUnknown)} />
                                        }
                                        label={SYSTEM_FLUID.unknown.label}
                                    />
                                </Stack>
                            </Box>
                        ) : ("")}
                    </Stack>
                </Stack>
            </Box>
        </Tooltip>
    )
}