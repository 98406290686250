import { Button, CircularProgress, Tooltip, Box } from '@mui/material';
import { saveAs } from "file-saver";
import { useSessionParams } from '../../../contexts/SessionParams';
import { SessionParamsEnum } from '../../../enums/sessionParamsEnum';
import { useState } from 'react';
import { useEffect } from 'react';
import { getShuntListName } from '../../../firebase/functions/getShuntList';
import { useDatabase, useDatabaseObjectData, useStorage } from 'reactfire';
import { getShuntData } from '../../../firebase/getShuntData';
import { getSessionCustomData } from '../../../firebase/functions/getSessionData';
import { CONN_VALUE_NOT_SPECIFIED, CONN_DESIGN_U, CONN_VALUE_L, CONN_VALUE_R } from '../../../constants/adjustmentsOptions';
import { getShuntImage } from '../../../firebase/functions/getShuntImage';
import { createShuntNomenklaturString_fromFBData } from '../../../functions/ShuntCalculations/helpFunctions/createShuntNomenklaturString';
import { SHUNT_FAMILY, SHUNT_FAMILY_FOLDER, SHUNT_NWAYS } from '../../../constants/customShunts';
import { get, ref } from 'firebase/database';
import { PROJECTS_PATH, SESSION_LINKED_PROJECT_PATH, SESSION_PATH } from '../../../constants/firebasePaths';
import { ADDON_CONNECTION_IMAGES } from '../../../constants/addonLabels';
import { useGetSystemFluidOptions } from '../../../hooks/useGetSystemFluidOptions';


export const WordExport = ({ apiButton = false }) => {
    const systemFluidOptions = useGetSystemFluidOptions();
    const sessionParams = useSessionParams();
    const database = useDatabase();
    const storage = useStorage();

    const linkedProjectRef = ref(database, SESSION_PATH + SessionParamsEnum.SessionID.getContextValue(sessionParams) + SESSION_LINKED_PROJECT_PATH);

    const { status, data } = useDatabaseObjectData(linkedProjectRef);

    let facility = SessionParamsEnum.Facility.getContextValue(sessionParams);
    let projNr = SessionParamsEnum.ProjNr.getContextValue(sessionParams);

    if (facility == null || facility === "") {
        facility = "-";
    }

    if (projNr == null || projNr === "") {
        projNr = "-";
    }


    const [shuntList, setShuntList] = useState([]);
    const [shuntDataList, setShuntDataList] = useState({});
    const [customData, setCustomData] = useState({});
    const [images, setImages] = useState({});
    const [loading, setLoading] = useState(true);
    const [saveDoc, setSaveDoc] = useState(false);
    const [projectID, setProjectID] = useState('');

    useEffect(() => {
        const readData = async () => {
            let shuntRes = await getShuntListName(database, sessionParams);
            let customData = await getSessionCustomData(database, sessionParams);

            let completedShuntList = [];
            shuntRes.forEach((shunt) => {
                // @ts-ignore
                if (shunt.maxStep === '4' || shunt.maxStep === 4 || shunt?.isAddon === true) {
                    completedShuntList.push(shunt);
                }
            })
            setShuntList(completedShuntList.sort((a, b) => a.pos - b.pos));
            setCustomData(customData);
        }

        readData();

    }, [])

    useEffect(() => {
        const getData = async () => {
            let localShuntData = {};
            for (let index = 0; index < shuntList.length; index++) {
                const shunt = shuntList[index];
                let data = await getShuntData(database, shunt.id);
                localShuntData[shunt.id] = data;
            }

            setShuntDataList(localShuntData);
        }

        getData();
    }, [shuntList])

    useEffect(() => {
        if (status === "success") {
            if (data != null) {
                setLoading(true);
                get(ref(database, PROJECTS_PATH + '/' + data + '/projectID')).then((snap) => {
                    if (snap.exists()) {
                        setProjectID(snap.val())
                    }

                }).finally(() => {
                    setLoading(false);
                })
            }
            else {
                setLoading(false);
            }
        }
    }, [status, data])


    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            let imageRefs;
            let blobs = {};

            for (let index = 0; index < Object.keys(shuntDataList).length; index++) {
                const key = Object.keys(shuntDataList)[index];
                let localBlobs = [];
                let customRef = shuntDataList[key]?.CustomData?.imageRef;
                //console.log("customData:", customRef);
                if (customRef != null) {
                    localBlobs.push(await getShuntImage(storage, customRef));
                }
                else {
                    imageRefs = getShuntImageUrl(shuntDataList[key]);

                    if (imageRefs.useBoth === true) {
                        localBlobs.push(await getShuntImage(storage, imageRefs.urlLeft));
                        localBlobs.push(await getShuntImage(storage, imageRefs.urlRight));
                    }
                    else {
                        localBlobs.push(await getShuntImage(storage, imageRefs.imageUrl));
                    }
                }


                blobs[key] = localBlobs;
            };

            //console.log("Blobs:", blobs);
            setImages(blobs);
            setLoading(false);
        }

        getData();
    }, [shuntDataList])

    useEffect(() => {
        if (loading === false) {
            if (saveDoc === true) {
                saveDocument();
                setSaveDoc(false);
            }
        }
    }, [saveDoc, loading])

    function saveDocument() {
        const time = new Date(Date.now());
        let fileName = "Spec_" + facility + "_" + projNr + "_" + time.toISOString().split("T")[0] + "_" + String(time.getHours()).padStart(2, '0') + "" + String(time.getMinutes()).padStart(2, '0') + "" + String(time.getSeconds()).padStart(2, '0');

        if (SessionParamsEnum.LinkedProject.getContextValue(sessionParams) != null && SessionParamsEnum.LinkedProject.getContextValue(sessionParams) !== "") {
            fileName += "-" + SessionParamsEnum.LinkedProject.getContextValue(sessionParams) + ".docx";
        }
        else {
            fileName += ".docx";
        }

        import('./wordDocument').then(({ createWordDocument }) => {
            const doc = createWordDocument(shuntList, shuntDataList, customData, sessionParams, images, systemFluidOptions);
            saveDocumentToFile(doc, fileName);
        }).catch(err => {
            console.log("Could not load component: ", err);
        })

    }

    function getShuntImageUrl(shuntData) {
        let folderName = "";
        let plNWays = 0;
        let isCustom = shuntData?.Baseinfo?.isCustom;
        let plPrincip = shuntData?.PLData?.princip;
        let plLine = shuntData?.PLData?.prodLine;
        const shuntNomenklaturString = shuntData?.ShuntData?.shuntNomenklaturString;
        let plFamily;
        //console.log("PL: ", plPrincip)
        if (SHUNT_FAMILY_FOLDER[plPrincip] != null) {
            folderName = String(SHUNT_FAMILY_FOLDER[plPrincip]).toLocaleLowerCase();
        }

        if (SHUNT_FAMILY[plPrincip] != null) {
            plFamily = SHUNT_FAMILY[plPrincip];
        }

        if (SHUNT_NWAYS[plPrincip] != null) {
            plNWays = SHUNT_NWAYS[plPrincip];
        }

        //console.log("FolderName:", folderName)
        let useBoth = false;
        let imageUrl = "";
        let urlLeft = "";
        let urlRight = "";

        if (shuntData?.ShuntData?.isAddon === true && shuntData?.ShuntData?.addonType != null) {
            if (ADDON_CONNECTION_IMAGES[shuntData?.ShuntData?.addonType] != null) {
                imageUrl = "images/addons/kopplingsbilder/" + ADDON_CONNECTION_IMAGES[shuntData?.ShuntData?.addonType] + ".jpg";
            }
        }
        else {
            if (shuntData?.AdjustData?.connectionValue === CONN_VALUE_NOT_SPECIFIED && shuntData?.AdjustData?.connectionDesign === CONN_DESIGN_U && isCustom !== true) {
                urlLeft = "images/" + folderName + "/kopplingsbilder/" + createShuntNomenklaturString_fromFBData(CONN_VALUE_L, plFamily, plLine, plNWays, shuntData?.AdjustData?.connectionDesign, shuntData?.ShuntData?.numBV, shuntData?.ShuntData?.numIV, shuntData?.ShuntData?.hasLatch, shuntData?.ShuntData?.isSabo) + ".jpg";
                urlRight = "images/" + folderName + "/kopplingsbilder/" + createShuntNomenklaturString_fromFBData(CONN_VALUE_R, plFamily, plLine, plNWays, shuntData?.AdjustData?.connectionDesign, shuntData?.ShuntData?.numBV, shuntData?.ShuntData?.numIV, shuntData?.ShuntData?.hasLatch, shuntData?.ShuntData?.isSabo) + ".jpg";
                useBoth = true;
            }
            else {
                imageUrl = "images/" + folderName + "/kopplingsbilder/" + shuntNomenklaturString + ".jpg"
            }
        }


        return { useBoth: useBoth, imageUrl: imageUrl, urlLeft: urlLeft, urlRight: urlRight }
    }


    function saveDocumentToFile(doc, fileName) {
        //const packer = new Packer.toBlob
        //console.log("Document:", doc)
        import('docx').then(({ Packer }) => {
            Packer.toBlob(doc).then(blob => {
                saveAs(blob, fileName);
                //console.log("Document created successfully");
            });
        })

    }

    async function generateWordDocument(event) {
        event.preventDefault();
        if (loading === true) {
            setSaveDoc(true);
        }
        else {
            saveDocument();
        }
    }


    return (
        <Box>
            {apiButton === false ? (
                <Tooltip title="Spara som Word-fil">
                    {/* @ts-ignore */}
                    <Button onClick={generateWordDocument} variant={'outlined'} disabled={saveDoc}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96" fill="#FFF" strokeMiterlimit="10" strokeWidth="2" style={{ width: '30px', marginRight: '0px' }}>
                            <path stroke="#979593" d="M67.1716,7H27c-1.1046,0-2,0.8954-2,2v78 c0,1.1046,0.8954,2,2,2h58c1.1046,0,2-0.8954,2-2V26.8284c0-0.5304-0.2107-1.0391-0.5858-1.4142L68.5858,7.5858 C68.2107,7.2107,67.702,7,67.1716,7z" />
                            <path fill="none" stroke="#979593" d="M67,7v18c0,1.1046,0.8954,2,2,2h18" />
                            <path fill="#C8C6C4" d="M79 61H48v-2h31c.5523 0 1 .4477 1 1l0 0C80 60.5523 79.5523 61 79 61zM79 55H48v-2h31c.5523 0 1 .4477 1 1l0 0C80 54.5523 79.5523 55 79 55zM79 49H48v-2h31c.5523 0 1 .4477 1 1l0 0C80 48.5523 79.5523 49 79 49zM79 43H48v-2h31c.5523 0 1 .4477 1 1l0 0C80 42.5523 79.5523 43 79 43zM79 67H48v-2h31c.5523 0 1 .4477 1 1l0 0C80 66.5523 79.5523 67 79 67z" />
                            <path fill="#185ABD" d="M12,74h32c2.2091,0,4-1.7909,4-4V38c0-2.2091-1.7909-4-4-4H12c-2.2091,0-4,1.7909-4,4v32 C8,72.2091,9.7909,74,12,74z" />
                            <path d="M21.6245,60.6455c0.0661,0.522,0.109,0.9769,0.1296,1.3657h0.0762 c0.0306-0.3685,0.0889-0.8129,0.1751-1.3349c0.0862-0.5211,0.1703-0.961,0.2517-1.319L25.7911,44h4.5702l3.6562,15.1272 c0.183,0.7468,0.3353,1.6973,0.457,2.8532h0.0608c0.0508-0.7979,0.1777-1.7184,0.3809-2.7615L37.8413,44H42l-5.1183,22h-4.86 l-3.4885-14.5744c-0.1016-0.4197-0.2158-0.9663-0.3428-1.6417c-0.127-0.6745-0.2057-1.1656-0.236-1.4724h-0.0608 c-0.0407,0.358-0.1195,0.8896-0.2364,1.595c-0.1169,0.7062-0.211,1.2273-0.2819,1.565L24.1,66h-4.9357L14,44h4.2349 l3.1843,15.3882C21.4901,59.7047,21.5584,60.1244,21.6245,60.6455z" />
                        </svg>{saveDoc && <CircularProgress size={'20px'} style={{ marginLeft: '10px' }} />}
                    </Button>
                </Tooltip>
            ) : (
                <Button onClick={generateWordDocument} size={'small'} variant={'contained'} fullWidth disabled={saveDoc}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96" fill="#FFF" strokeMiterlimit="10" strokeWidth="2" style={{ width: '27px', marginRight: '10px' }}>
                        <path stroke="#979593" d="M67.1716,7H27c-1.1046,0-2,0.8954-2,2v78 c0,1.1046,0.8954,2,2,2h58c1.1046,0,2-0.8954,2-2V26.8284c0-0.5304-0.2107-1.0391-0.5858-1.4142L68.5858,7.5858 C68.2107,7.2107,67.702,7,67.1716,7z" />
                        <path fill="none" stroke="#979593" d="M67,7v18c0,1.1046,0.8954,2,2,2h18" />
                        <path fill="#C8C6C4" d="M79 61H48v-2h31c.5523 0 1 .4477 1 1l0 0C80 60.5523 79.5523 61 79 61zM79 55H48v-2h31c.5523 0 1 .4477 1 1l0 0C80 54.5523 79.5523 55 79 55zM79 49H48v-2h31c.5523 0 1 .4477 1 1l0 0C80 48.5523 79.5523 49 79 49zM79 43H48v-2h31c.5523 0 1 .4477 1 1l0 0C80 42.5523 79.5523 43 79 43zM79 67H48v-2h31c.5523 0 1 .4477 1 1l0 0C80 66.5523 79.5523 67 79 67z" />
                        <path fill="#185ABD" d="M12,74h32c2.2091,0,4-1.7909,4-4V38c0-2.2091-1.7909-4-4-4H12c-2.2091,0-4,1.7909-4,4v32 C8,72.2091,9.7909,74,12,74z" />
                        <path d="M21.6245,60.6455c0.0661,0.522,0.109,0.9769,0.1296,1.3657h0.0762 c0.0306-0.3685,0.0889-0.8129,0.1751-1.3349c0.0862-0.5211,0.1703-0.961,0.2517-1.319L25.7911,44h4.5702l3.6562,15.1272 c0.183,0.7468,0.3353,1.6973,0.457,2.8532h0.0608c0.0508-0.7979,0.1777-1.7184,0.3809-2.7615L37.8413,44H42l-5.1183,22h-4.86 l-3.4885-14.5744c-0.1016-0.4197-0.2158-0.9663-0.3428-1.6417c-0.127-0.6745-0.2057-1.1656-0.236-1.4724h-0.0608 c-0.0407,0.358-0.1195,0.8896-0.2364,1.595c-0.1169,0.7062-0.211,1.2273-0.2819,1.565L24.1,66h-4.9357L14,44h4.2349 l3.1843,15.3882C21.4901,59.7047,21.5584,60.1244,21.6245,60.6455z" />
                    </svg>&nbsp;Spara Word-fil {saveDoc && <CircularProgress size={'20px'} style={{ marginLeft: '10px' }} />}
                </Button>
            )}

        </Box>
    )
}

export { WordExport as default }