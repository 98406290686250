import { Button, Stack, TextField } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { numberWithSpaceSeparator } from "../../../../functions/numberFunctions";
import { PriceFieldRow } from "./priceFieldRow"

export const PricePipeCoupling = ({ insulation, pipeCouplingPrice, insulationPrice, markupPipe, markupIns, standardMarkup, changePrice, changeMarkup, priceCouplingTotal, modMarkup = false }) => {
    const [localValPipe, setLocalValPipe] = useState(pipeCouplingPrice);
    const [localMarkupPipe, setLocalMarkupPipe] = useState(markupPipe || standardMarkup)
    const [localValIns, setLocalValIns] = useState(insulationPrice);
    const [localMarkupIns, setLocalMarkupIns] = useState(markupIns || standardMarkup)

    const displayTotalPricePipe = () => {
        if (localValPipe != null && localMarkupPipe != null && isNaN(localValPipe) !== true && isNaN(localMarkupPipe) !== true) {
            return Math.round((Number(localValPipe) / 100) * (Number(localMarkupPipe) + 100));
        }
        else {
            return 0;
        }
    }

    const displayTotalPriceInsulation = () => {
        if (localValIns != null && localMarkupIns != null && isNaN(localValIns) !== true && isNaN(localMarkupIns) !== true) {
            return Math.round((Number(localValIns) / 100) * (Number(localMarkupIns) + 100));
        }
        else {
            return 0;
        }
    }

    useEffect(() => {
        //console.log("Update Markup:", markupVal);
        if (markupPipe !== localMarkupPipe) {
            setLocalMarkupPipe(markupPipe);
        }

        if (markupIns !== localMarkupIns) {
            setLocalMarkupPipe(markupIns);
        }
    }, [markupPipe, markupIns])

    useEffect(() => {
        if (localValPipe != null && isNaN(localValPipe) === false && Number(localValPipe) !== Number(pipeCouplingPrice)) {
            changePrice(localValPipe, "pipeCoupling");
        }

        if (localValIns != null && isNaN(localValIns) === false && Number(localValIns) !== Number(insulationPrice)) {
            changePrice(localValIns, "insulation");
        }
    }, [localValPipe, localValIns]);


    useEffect(() => {
        if (localMarkupPipe != null && isNaN(localMarkupPipe) === false && Number(localMarkupPipe) !== Number(markupPipe)) {
            changeMarkup(localMarkupPipe, "pipeCoupling")
        }

        if (localMarkupIns != null && isNaN(localMarkupIns) === false && Number(localMarkupIns) !== Number(markupIns)) {
            changeMarkup(localMarkupIns, "insulation")
        }

    }, [localMarkupPipe, localMarkupIns]);


    const setPricePipe = (value) => {
        setLocalValPipe(value);
    }

    const setMarkupPipe = (value) => {
        setLocalMarkupPipe(value);
    }

    const setPriceIns = (value) => {
        setLocalValIns(value);
    }

    const setMarkupIns = (value) => {
        setLocalMarkupIns(value);
    }

    return (
        <Fragment>
            {insulation != null && insulation !== '' ? (
                <Stack direction={'row'}>
                    <Stack>
                        <div style={{ color: "GrayText" }}>
                            <b>Rörkoppel</b>
                        </div>
                        <TextField
                            style={{ width: "140px", marginBottom: "17px" }}
                            type={'number'}
                            onKeyDown={(evt) => (/^[a-öA-Ö+?']?$/).test(evt.key) && evt.preventDefault()}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                },
                                endAdornment: 'kr',
                            }}
                            value={localValPipe}
                            onChange={(e) => { setPricePipe(e.target.value) }}
                        />
                        <div style={{ color: "GrayText" }}>
                            <b>Isolering</b>
                        </div>
                        <TextField
                            style={{ width: "140px" }}
                            type={'number'}
                            onKeyDown={(evt) => (/^[a-öA-Ö+?']?$/).test(evt.key) && evt.preventDefault()}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                },
                                endAdornment: 'kr',
                            }}
                            value={localValIns}
                            onChange={(e) => { setPriceIns(e.target.value) }}
                        />
                    </Stack>
                    <div style={{ width: "393px", marginLeft: "25px", marginTop: "50px", color: "GrayText" }}>
                        <i>({numberWithSpaceSeparator(priceCouplingTotal)} kr)</i>
                    </div>
                    {modMarkup === true ? (
                        <Stack>
                            <TextField
                                style={{ width: "80px", marginTop: "14px", marginBottom: "33px", alignSelf: "flex-end" }}
                                label={'Påslag'}
                                type={'number'}
                                required={true}
                                onKeyDown={(evt) => (/^[a-öA-Ö+?']?$/).test(evt.key) && evt.preventDefault()}
                                InputProps={{
                                    endAdornment: '%',
                                    inputProps: {
                                        max: 999, min: 0
                                    },
                                }}
                                InputLabelProps={{ shrink: true }}
                                value={localMarkupPipe}
                                onChange={(e) => { setMarkupPipe(e.target.value) }}
                            />
                            <TextField
                                style={{ width: "80px", alignSelf: "flex-end" }}
                                label={'Påslag'}
                                type={'number'}
                                required={true}
                                onKeyDown={(evt) => (/^[a-öA-Ö+?']?$/).test(evt.key) && evt.preventDefault()}
                                InputProps={{
                                    endAdornment: '%',
                                    inputProps: {
                                        max: 999, min: 0
                                    },
                                }}
                                InputLabelProps={{ shrink: true }}
                                value={localMarkupIns}
                                onChange={(e) => { setMarkupIns(e.target.value) }}
                            />
                        </Stack>
                    ) : (
                        <Stack>
                            <div style={{ paddingLeft:"45px", width: "35px", marginTop: "14px", marginBottom: "33px", alignSelf: "flex-end" }}>
                                {localMarkupPipe} %
                            </div>
                            <div style={{ paddingLeft:"45px", width: "35px", alignSelf: "flex-end" }}>
                                {localMarkupIns} %
                            </div>
                        </Stack>
                    )}

                    <Stack>
                        <div style={{ marginLeft: '3px', marginTop: "20px", marginBottom: "46px", width: "100px", textAlign: 'right' }}>
                            {numberWithSpaceSeparator(displayTotalPricePipe())} kr
                        </div>
                        <div style={{ marginLeft: '3px', width: "100px", textAlign: 'right' }}>
                            {numberWithSpaceSeparator(displayTotalPriceInsulation())} kr
                        </div>
                    </Stack>
                </Stack>
            ) : (
                <Stack>
                    <div style={{ color: "GrayText" }}>
                        <b>Rörkoppel</b>
                    </div>
                    <Stack direction={'row'} alignItems="center">
                        <TextField
                            style={{ width: "140px" }}
                            type={'number'}
                            required={true}
                            onKeyDown={(evt) => (/^[a-öA-Ö+?']?$/).test(evt.key) && evt.preventDefault()}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                },
                                endAdornment: 'kr',
                            }}
                            value={localValPipe}
                            onChange={(e) => { setPricePipe(e.target.value) }}
                        />

                        <div style={{ width: "413px", marginLeft: "5px" }}></div>

                        {modMarkup === true ? (
                            <TextField
                                style={{ width: "80px", alignSelf: "flex-end" }}
                                label={'Påslag'}
                                type={'number'}
                                required={true}
                                onKeyDown={(evt) => (/^[a-öA-Ö+?']?$/).test(evt.key) && evt.preventDefault()}
                                InputProps={{
                                    endAdornment: '%',
                                    inputProps: {
                                        max: 999, min: 0
                                    },
                                }}
                                InputLabelProps={{ shrink: true }}
                                value={localMarkupPipe}
                                onChange={(e) => { setMarkupPipe(e.target.value) }}
                            />
                        ) : (
                            <div style={{marginLeft:"45px", marginBottom: "7px", width: "35px", alignSelf: "flex-end" }}>
                                {localMarkupPipe} %
                            </div>
                        )}

                        <div style={{ marginLeft: '3px', width: "100px", textAlign: 'right' }}>
                            {numberWithSpaceSeparator(displayTotalPricePipe())} kr
                        </div>
                    </Stack>
                </Stack>
            )}
        </Fragment>
    )

    /*
    return (
        <Stack>
            <div style={{ color: "GrayText" }}>
                {required === true ? (
                    <b>{String(title)}</b>
                ) : (
                    String(title)
                )}
            </div>
            <Stack direction={'row'} alignItems="center">
                <TextField
                    style={{ width: "140px" }}
                    type={'number'}
                    required={required}
                    onKeyDown={(evt) => (/^[a-öA-Ö+?']?$/).test(evt.key) && evt.preventDefault()}
                    InputProps={{
                        endAdornment: 'kr',
                    }}
                    value={localVal}
                    onChange={(e) => { setPrice(e.target.value) }}
                />
                {option != null ? (
                    <div style={{ width: "413px", marginLeft: "5px" }}>
                        ({option})
                    </div>
                ) : (
                    <Fragment>
                        {isPriceCoupling === true ? (
                            <div style={{ width: "413px", marginLeft: "5px", marginTop: "100px", position: 'relative' }}>
                                ({priceCoupling} kr)
                            </div>
                        ) : (
                            <div style={{ width: "413px", marginLeft: "5px" }}>
                                {isExtra === true &&
                                    <Button
                                        size="small"
                                        onClick={() => { isExtraFunc(false) }}
                                        style={{ height: "20px", width: "30px", justifyContent: 'left' }}>
                                        <HighlightOffIcon />
                                    </Button>}
                            </div>
                        )}
                    </Fragment>

                )}


                <TextField
                    style={{ width: "80px", alignSelf: "flex-end" }}
                    label={'Påslag'}
                    type={'number'}
                    required={required}
                    onKeyDown={(evt) => (/^[a-öA-Ö+?']?$/).test(evt.key) && evt.preventDefault()}
                    InputProps={{
                        endAdornment: '%',
                        inputProps: {
                            max: 999, min: 0
                        },
                    }}
                    InputLabelProps={{ shrink: true }}
                    value={localMarkup}
                    onChange={(e) => { setMarkup(e.target.value) }}
                />
                <div style={{ marginLeft: '3px', width: "100px", textAlign: 'right' }}>
                    {numberWithSpaceSeparator(displayTotalPrice())} kr
                </div>
            </Stack>
        </Stack>
    )*/
}