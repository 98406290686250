import { ref, set } from "firebase/database";
import { SESSION_PATH, SESSION_SHUNT_PATH, SHUNT_POSITION_PATH } from "../../constants/firebasePaths";

/**
 * 
 * @param {*} database 
 * @param {Number} position 
 * @param {Array} shuntList 
 */
export const updatePositionList = function updatePositionList(database, sessionID, position, shuntList, addonID = null) {
    //console.log("Values: ", position, shuntList);
    let currentPos = position;
    let modifiedList = {};
    while (shuntList.findIndex((obj) => obj.pos === currentPos) !== -1) {
        let currentObj = shuntList.find((obj) => obj.pos === currentPos);
        if (addonID != null && currentObj?.id === addonID) {
            break;
        }
        else {
            if (currentObj?.pos != null) {
                currentPos = currentPos + 1;
                modifiedList[currentObj.id] = currentObj.pos + 1
            }
        }

    }

    const promises = [];

    for (const key in modifiedList) {
        if (Object.prototype.hasOwnProperty.call(modifiedList, key)) {
            const newPosition = modifiedList[key];

            const sessionRef = ref(database, SESSION_PATH + sessionID + SESSION_SHUNT_PATH + key);
            const shuntRef = ref(database, SESSION_SHUNT_PATH + key + SHUNT_POSITION_PATH);

            const promiseSession = set(sessionRef, Number(newPosition));
            const promiseShunt = set(shuntRef, Number(newPosition));

            promises.push(promiseSession, promiseShunt);
        }
    }

    return Promise.all(promises);
}