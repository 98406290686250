import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useAnalytics } from 'reactfire';
import { logEvent } from 'firebase/analytics';
import { PrinciplePicker } from '../modules/principlePicker/principlePicker';

export const Step1 = () => {
    const analytics = useAnalytics();

    useEffect(() => {
        logEvent(analytics, 'page_view', { page_location: window.location.href, page_title: "Step 1" });
    }, [window.location.href]);

    return (
        <Box style={{ marginTop: '15px' }}>
            <PrinciplePicker />
        </Box>
    );
};