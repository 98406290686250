import { Database, get, ref } from "firebase/database";
import { SESSION_SHUNT_PATH } from "../../constants/firebasePaths";

/**
 * 
 * @param {Database} database 
 * @param {string} shuntID 
 */
const getShuntDesignation = async function getShuntDesignation(database, shuntID) {
    const infoRef = ref(database, SESSION_SHUNT_PATH + shuntID + "/Baseinfo")

    const result = await get(infoRef);

    return result.val();
}

export { getShuntDesignation }