import { Button, DialogActions, DialogContent, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDatabase } from "reactfire";
import { useSessionParams } from "../../../contexts/SessionParams";
import { SessionParamsEnum } from "../../../enums/sessionParamsEnum";
import { BootstrapDialog, BootstrapDialogTitle } from "../../utils/bootstrapDialog";
import { SESSION_OVERALL_COMMENT_PATH, SESSION_COMMENT_TIMESTAMP_PATH, SESSION_SHUNT_PATH, SHUNT_COMMENT_PATH, SHUNT_COMMENT_TIMESTAMP_PATH } from '../../../constants/firebasePaths';
import { SESSION_PATH } from '../../../constants/firebasePaths';
import { get, ref, remove, set } from 'firebase/database';


export const CommentDialog = ({ handleOnDecline, open, shuntID, type }) => {
    const sessionParams = useSessionParams();
    //const devParams = useDevbarParams();
    const database = useDatabase();
    // const user = useUser();

    // const userRef = "users/" + user.data.uid;
    const [comment, setComment] = useState('');
    const [orgComment, setOrgComment] = useState('');
    const [sent, setIsSent] = useState(false)

    let baseCommentUrl = SESSION_SHUNT_PATH + shuntID + SHUNT_COMMENT_PATH;
    let baseCommentTimestampUrl = SESSION_SHUNT_PATH + shuntID + SHUNT_COMMENT_TIMESTAMP_PATH;



    if (type === "overall") { baseCommentUrl = SESSION_PATH + SessionParamsEnum.SessionID.getContextValue(sessionParams) + SESSION_OVERALL_COMMENT_PATH }
    if (type === "overall") { baseCommentTimestampUrl = SESSION_PATH + SessionParamsEnum.SessionID.getContextValue(sessionParams) + SESSION_COMMENT_TIMESTAMP_PATH }


    const dbCommentRef = ref(database, baseCommentUrl);
    const dbCommentTimestampRef = ref(database, baseCommentTimestampUrl);

    //const [value, setValue] = useState(null);

    //const [comment, setComment] = useState("");

    useEffect(() => {
        get(dbCommentRef).then((res) => {
            if (res.val() != null) {
                //console.log("Set Comment: ", res.val())
                setComment(res.val());
                setOrgComment(res.val());
            }
        })
    }, [])

    useEffect(() => {
        if (open === true) {
            setIsSent(false);
        }
    }, [open])

    const closeDialog = () => {
        handleOnDecline();
    }

    const date = Date.now();

    const handleCancel = () => {
        setComment(orgComment);
        closeDialog();
    }

    const handleSend = () => {
        sendComment();
        closeDialog();
    }

    const sendComment = async () => {
        //setLoading(true);
        if (comment == null || comment === "") {
            await remove(dbCommentRef).then(() => {
                remove(dbCommentTimestampRef)
            });
        }
        else {
            await set(dbCommentRef, comment).then(() => {
                set(dbCommentTimestampRef, date)
            });
        }
        setOrgComment(comment);
    }

    return (
        <BootstrapDialog
            style={{
                minWidth: 640,
                minHeight: 440,
            }}
            onClose={closeDialog}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <BootstrapDialogTitle component="h3" sx={{ paddingLeft: '20px', paddingTop: '0px', paddingBottom: '0px', }} onClose={handleCancel}>
                <h3 className="header-title">Anmärkningar & förtydliganden</h3>
            </BootstrapDialogTitle>
            <DialogContent sx={{ marginTop: '10px', }}>


                <TextField
                    style={{ display: 'flex', margin: 'auto', width: '500px', marginTop: '6px', }}
                    id="comment-input"
                    value={comment}
                    type={'text'}
                    label="Kommentar"
                    multiline
                    rows={4}
                    onChange={(event) => { setComment(event.target.value) }}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>
                    Avbryt
                </Button>
                <Button onClick={handleSend}>
                    Spara & Stäng
                </Button>
            </DialogActions>
        </BootstrapDialog>
    )
};