import { Backdrop, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { signInAnonymously } from 'firebase/auth';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, useDatabase, useFirestore, useUser } from 'reactfire';
import { useAdjustParams, useCalcParams, useDriftParams, useErrorContext, useNoticeContext, useProductLineParams, usePumpParams, useSessionParams, useShuntParams, useWizardParams } from '../contexts';
import { useFSData } from '../contexts/fsDataContext';
import { CalcParamsEnum, SessionParamsEnum, ShuntParamsEnum } from '../enums';
import { saveSessionData } from '../firebase/functions';
import { loadSessionApi } from '../firebase/loadSession';
import { parseXmlFile } from '../functions/MagicadFunctions/parseXmlFile';
import { Start } from './Start'
import { useGetSystemFluidOptions } from '../hooks/useGetSystemFluidOptions';
import { useGetEnergyMeasurementList } from '../hooks/useGetEnergyMeasurementList';

const Magicad = () => {
    const sessionParams = useSessionParams();
    const productLineParams = useProductLineParams();
    const driftParams = useDriftParams();
    const adjustParams = useAdjustParams();
    const calcParams = useCalcParams();
    const errorContext = useErrorContext();
    const noticeContext = useNoticeContext();
    const pumpParams = usePumpParams();
    const shuntParams = useShuntParams();
    const fsDataParams = useFSData();
    const wizardParams = useWizardParams();

    const systemFluidOptions = useGetSystemFluidOptions();
    const { heatEnergyMeasurementList, coolingEnergyMeasurementList } = useGetEnergyMeasurementList();

    const auth = useAuth();
    const user = useUser();

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const database = useDatabase();
    const firestore = useFirestore();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [apiSession, setApiSession] = useState(String(window.location.hash).substring(1));
    const [apiMethod, setApiMethod] = useState(String(window.location.search).substring(1));
    const [xmlData, setXmlData] = useState({});

    useEffect(() => {
        user.firstValuePromise.then(async () => {
            if (auth.currentUser == null) {
                await signInAnonymously(auth);
                setIsLoggedIn(true);
            }
            else {
                setIsLoggedIn(true);
            }
        });
    }, [user.firstValuePromise]);

    useEffect(() => {
        const readData = async () => {
            setLoading(true);

            if (apiSession != null && apiSession !== "") {
                SessionParamsEnum.clearData(sessionParams);
                CalcParamsEnum.clearData(calcParams);
                ShuntParamsEnum.clearData(shuntParams);
                SessionParamsEnum.IsApiSession.setContextValue(sessionParams, true);
                SessionParamsEnum.SessionID.setContextValue(sessionParams, apiSession);
                SessionParamsEnum.ApiMethod.setContextValue(sessionParams, apiMethod);

                if (apiMethod === 'update' || apiMethod === 'UPD') {
                    let res = await loadSessionApi(database, apiSession, sessionParams);

                    if (res === false){
                        saveSessionData(apiSession, database, sessionParams, auth);
                    }
                }
                await parseXmlFile(database, firestore, apiSession, apiMethod, driftParams, shuntParams, calcParams, adjustParams, pumpParams, productLineParams, errorContext, noticeContext, sessionParams, fsDataParams, wizardParams, coolingEnergyMeasurementList, heatEnergyMeasurementList, systemFluidOptions);

                if (apiMethod != null && apiMethod !== '') {

                    if (apiMethod === 'update' || apiMethod === 'UPD') {
                        navigate('/stepper#step2', { replace: true });
                    }
                }
                setLoading(false);
            }
        }

        if (isLoggedIn === true){
            readData();
        }
        
    }, [apiSession, isLoggedIn]);

    return (
        <>
            <br />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div style={{
                margin: "0px 0px 0px 0px",
                width: "96.3%",
                borderRadius: "4px",
                padding: "20px",
                justifyContent: 'center',
                backgroundColor: '#E1E2E4',
            }}>
                {loading === false ? (
                    <Start isApi={true} />
                ) : (
                    <Box></Box>
                )}

            </div>
        </>
    )
}

export default Magicad