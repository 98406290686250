import { FormControl, FormLabel, Select, MenuItem, Checkbox, FormControlLabel, Stack, TextField, Box, InputLabel } from "@mui/material"
import { QUESTION_STAINLESS_KEY, QUESTION_SYSTEM_FLUID_KEY, QUESTION_SYSTEM_FLUID_MIX_RATIO_KEY, QUESTION_SYSTEM_FLUID_MIX_RATIO_UNKNOWN_KEY, QUESTION_SYSTEM_FLUID_PRIMARY_KEY, QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_KEY, QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_KEY, QUESTION_SYSTEM_FLUID_SECONDARY_KEY, QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY, QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_KEY, SYSTEM_FLUID } from "../../../../constants/wizardConstants"
import { useEffect, useState } from "react";
import { countDecimalPlaces, isNativeEventTyping, sanitizeIncrementedValue } from "../../../../functions/HelperFunctions/numberInputHelpers";
import { SystemFluidMixRatioInputfield } from "../../systemFluidPicker/systemFluidMixRatioInputField";
import { useGetSystemFluidOptions } from "../../../../hooks/useGetSystemFluidOptions";

export const SystemFluidQuestion = ({
    systemFluid,
    systemFluidMixRatio,
    systemFluidMixRatioUnknown,
    systemFluidPrimary,
    systemFluidPrimaryMixRatio,
    systemFluidPrimaryMixRatioUnknown,
    systemFluidSecondary,
    systemFluidSecondaryMixRatio,
    systemFluidSecondaryMixRatioUnknown,
    answerQuestion
}) => {
    const systemFluidOptions = useGetSystemFluidOptions();

    const getSystemFluidSelected = (value) => {
        if (value != null) {
            return systemFluidOptions.find((obj) => obj.value === value);
        }
        return null;
    }


    const handleChangeFluidMix = (value, key) => {
        let fluidOption;
        if (key === QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_KEY){
            fluidOption = getSystemFluidSelected(systemFluidPrimary);
        }
        else if (key === QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY){
            fluidOption = getSystemFluidSelected(systemFluidSecondary);
        }
        else{
            fluidOption = getSystemFluidSelected(systemFluid);
        }

        if (fluidOption?.maxMixRatio != null) {
            if (Number(value) > fluidOption?.maxMixRatio) {
                answerQuestion(key, fluidOption?.maxMixRatio);
            }
            else if (Number(value) < 0) {
                answerQuestion(key, 0);
            }
            else {
                answerQuestion(key, value);
            }
        }
        else {
            if (Number(value) > 100) {
                answerQuestion(key, 100);
            }
            else if (Number(value) < 0) {
                answerQuestion(key, 0);
            }
            else {
                answerQuestion(key, value);
            }
        }
    }

    return (
        <>
            <Stack>
                <Stack sx={{ marginTop: '10px' }} direction={'row'} spacing={2} alignItems={'flex-start'}>
                    <Box sx={{ paddingTop: (systemFluid !== SYSTEM_FLUID.water.value && systemFluid !== SYSTEM_FLUID.unknown.value && systemFluid !== SYSTEM_FLUID.different.value) ? '10px' : '0px', }}>
                        <FormControl size="small">
                            <InputLabel>Systemvätska</InputLabel>
                            <Select
                                sx={{ width: '150px', height: '30px', background: 'white' }}
                                size="small"
                                value={systemFluid}
                                label={'Systemvätska'}
                                onChange={(e) => {
                                    if (getSystemFluidSelected(e.target.value)?.defaultMixRatio != null) {
                                        answerQuestion(QUESTION_SYSTEM_FLUID_MIX_RATIO_KEY, getSystemFluidSelected(e.target.value)?.defaultMixRatio);
                                    }
                                    answerQuestion(QUESTION_SYSTEM_FLUID_KEY, e.target.value)
                                }}
                            >
                                {systemFluidOptions.map((item) => {
                                    if (item?.pumpOptionOnly !== true) {
                                        return (
                                            <MenuItem key={item?.value} value={item?.value}>{item?.label}</MenuItem>
                                        );
                                    }
                                    else {
                                        return "";
                                    }
                                })}
                            </Select>
                        </FormControl>
                    </Box>


                    {getSystemFluidSelected(systemFluid)?.defaultMixRatio != null ? (
                        <Box paddingBottom={'10px'}>
                            <Stack direction={'row'} alignItems={'flex-end'} marginTop={'-15px'}>
                                <Stack>
                                    <FormLabel>Blandningsförhållande: (%)</FormLabel>
                                    <Stack direction={'row'} spacing={1} marginTop={'8px'}>
                                        <SystemFluidMixRatioInputfield
                                            questionKey={QUESTION_SYSTEM_FLUID_MIX_RATIO_KEY}
                                            value={systemFluidMixRatio}
                                            systemFluid={systemFluid}
                                            handleChangeFluidMix={handleChangeFluidMix}
                                            disabled={systemFluidMixRatioUnknown}
                                        />

                                        <TextField
                                            sx={{ width: '60px', background: 'white' }}
                                            size="small"
                                            type="number"
                                            label="Vatten"
                                            value={Math.round((100 - systemFluidMixRatio) * 100) / 100}
                                            disabled
                                            InputProps={{
                                                inputProps: {
                                                    step: 'any',
                                                    max: 100,
                                                    min: 0
                                                }
                                            }}
                                        />
                                    </Stack>
                                </Stack>
                                <FormControlLabel
                                    sx={{ marginLeft: '-10px', height: '28px' }}
                                    control={
                                        <Checkbox checked={systemFluidMixRatioUnknown} onChange={() => answerQuestion(QUESTION_SYSTEM_FLUID_MIX_RATIO_UNKNOWN_KEY, !systemFluidMixRatioUnknown)} />
                                    }
                                    label={SYSTEM_FLUID.unknown.label}
                                />
                            </Stack>
                        </Box>
                    ) : ("")}
                </Stack>

                {systemFluid === SYSTEM_FLUID.different.value ? (
                    <>
                        <Stack direction={'row'} spacing={2}>
                            <FormControl size="small">
                                <FormLabel>Primär</FormLabel>
                                <Select
                                    sx={{ width: '150px', height: '30px', background: 'white' }}
                                    size="small"
                                    value={systemFluidPrimary}
                                    onChange={(e) => {
                                        if (getSystemFluidSelected(e.target.value)?.defaultMixRatio != null) {
                                            answerQuestion(QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_KEY, getSystemFluidSelected(e.target.value)?.defaultMixRatio);
                                        }
                                        answerQuestion(QUESTION_SYSTEM_FLUID_PRIMARY_KEY, e.target.value)
                                    }}
                                >
                                    {systemFluidOptions.map((item) => {
                                        if (item?.pumpOptionOnly !== true && item?.wizardOptionOnly !== true) {
                                            return (
                                                <MenuItem key={item?.value} value={item?.value}>{item?.label}</MenuItem>
                                            );
                                        }
                                        else {
                                            return "";
                                        }
                                    })}
                                </Select>
                            </FormControl>

                            {getSystemFluidSelected(systemFluidPrimary)?.defaultMixRatio != null  ? (
                                <Box paddingBottom={'10px'}>
                                    <Stack direction={'row'} alignItems={'flex-end'} >
                                        <Stack marginTop={'-8px'}>
                                            <FormLabel>Blandningsförhållande: (%)</FormLabel>
                                            <Stack direction={'row'} spacing={1} marginTop={'8px'}>
                                                <SystemFluidMixRatioInputfield
                                                    questionKey={QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_KEY}
                                                    value={systemFluidPrimaryMixRatio}
                                                    systemFluid={systemFluidPrimary}
                                                    handleChangeFluidMix={handleChangeFluidMix}
                                                    disabled={systemFluidPrimaryMixRatioUnknown}
                                                />

                                                <TextField
                                                    sx={{ width: '60px', background: 'white' }}
                                                    size="small"
                                                    type="number"
                                                    label="Vatten"
                                                    value={Math.round((100 - systemFluidPrimaryMixRatio) * 100) / 100}
                                                    disabled
                                                    InputProps={{
                                                        inputProps: {
                                                            step: 'any',
                                                            max: 100,
                                                            min: 0
                                                        }
                                                    }}
                                                />
                                            </Stack>
                                        </Stack>
                                        <FormControlLabel
                                            sx={{ marginLeft: '-10px', height: '28px' }}
                                            control={
                                                <Checkbox checked={systemFluidPrimaryMixRatioUnknown} onChange={() => answerQuestion(QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_KEY, !systemFluidPrimaryMixRatioUnknown)} />
                                            }
                                            label={SYSTEM_FLUID.unknown.label}
                                        />
                                    </Stack>
                                </Box>
                            ) : ("")}
                        </Stack>

                        <Stack direction={'row'} spacing={2}>
                            <FormControl size="small">
                                <FormLabel>Sekundär</FormLabel>
                                <Select
                                    sx={{ width: '150px', height: '30px', background: 'white' }}
                                    size="small"
                                    value={systemFluidSecondary}
                                    onChange={(e) => {
                                        if (getSystemFluidSelected(e.target.value)?.defaultMixRatio != null) {
                                            answerQuestion(QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY, getSystemFluidSelected(e.target.value)?.defaultMixRatio);
                                        }
                                        answerQuestion(QUESTION_SYSTEM_FLUID_SECONDARY_KEY, e.target.value)
                                    }}
                                >
                                    {systemFluidOptions.map((item) => {
                                        if (item?.pumpOptionOnly !== true && item?.wizardOptionOnly !== true) {
                                            return (
                                                <MenuItem key={item?.value} value={item?.value}>{item?.label}</MenuItem>
                                            );
                                        }
                                        else {
                                            return "";
                                        }
                                    })}
                                </Select>
                            </FormControl>

                            {getSystemFluidSelected(systemFluidSecondary)?.defaultMixRatio != null  ? (
                                <Box paddingBottom={'10px'}>
                                    <Stack direction={'row'} alignItems={'flex-end'} >
                                        <Stack marginTop={'-8px'}>
                                            <FormLabel>Blandningsförhållande: (%)</FormLabel>

                                            <Stack direction={'row'} spacing={1} marginTop={'8px'}>
                                                <SystemFluidMixRatioInputfield
                                                    questionKey={QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY}
                                                    value={systemFluidSecondaryMixRatio}
                                                    systemFluid={systemFluidSecondary}
                                                    handleChangeFluidMix={handleChangeFluidMix}
                                                    disabled={systemFluidSecondaryMixRatioUnknown}
                                                />

                                                <TextField
                                                    sx={{ width: '60px', background: 'white' }}
                                                    size="small"
                                                    type="number"
                                                    label="Vatten"
                                                    value={Math.round((100 - systemFluidSecondaryMixRatio) * 100) / 100}
                                                    disabled
                                                    InputProps={{
                                                        inputProps: {
                                                            step: 'any',
                                                            max: 100,
                                                            min: 0
                                                        }
                                                    }}
                                                />
                                            </Stack>
                                        </Stack>
                                        <FormControlLabel
                                            sx={{ marginLeft: '-10px', height: '28px' }}
                                            control={
                                                <Checkbox checked={systemFluidSecondaryMixRatioUnknown} onChange={() => answerQuestion(QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_KEY, !systemFluidSecondaryMixRatioUnknown)} />
                                            }
                                            label={SYSTEM_FLUID.unknown.label}
                                        />
                                    </Stack>
                                </Box>
                            ) : ("")}
                        </Stack>
                    </>
                ) : ("")}
            </Stack>
        </>
    );
}