import { Backdrop, Button, CircularProgress, Divider, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DataSnapshot, get, ref } from "firebase/database";
import { Fragment, useEffect, useState } from "react";
import { useDatabase, useFirestore } from "reactfire"
import { THERMO_MULTIPLE_LABELS } from "../../../constants/adjustmentsLables";
import { ADJUSTVALUE_STAD, ADJUSTVALUE_STAD_ZERO, CABINET_INSULATED, CONN_VALUE_R, NO_CABINET, NO_MANOMETER, STAND_WALL, THERMO_BIMETALLIC } from "../../../constants/adjustmentsOptions";
import { SESSION_SHUNT_PATH, SESSION_SHUNT_PRICES_PATH, SHUNT_ADJUSTDATA_CABINET_PATH, SHUNT_ADJUSTDATA_CUTOFF_PATH, SHUNT_ADJUSTDATA_DRIPPLATE_PATH, SHUNT_ADJUSTDATA_INSULATION_PATH, SHUNT_ADJUSTDATA_MANOMETER_PATH, SHUNT_ADJUSTDATA_MATERIAL_PATH, SHUNT_ADJUSTDATA_STAND_PATH, SHUNT_ADJUSTDATA_THERMO_PATH, SHUNT_CONTROLVALVE_PATH, SHUNT_CUSTOMDATA_PRICESAVED_PATH, SHUNT_CUSTOMDATA_PRICE_ISCOMPLETE_PATH, SHUNT_CUSTOMDATA_TOTALPRICE_PATH, SHUNT_CV_KVS_PATH, SHUNT_CV_MODEL_PATH, SHUNT_DESIGNATION_PATH, SHUNT_FLEXMODEL_PATH, SHUNT_IS_MINI_PATH, SHUNT_MAXSTEP_PATH, SHUNT_NUM_IV_PATH, SHUNT_PLMODEL_PATH, SHUNT_POSITION_PATH, SHUNT_PRIM_DIMROW_PATH, SHUNT_PRODFAMILY_PATH, SHUNT_PRODLINE_PATH, SHUNT_PUMPDATA_CARD_PATH, SHUNT_PUMPDATA_GENERALREQUESTTYPE_PATH, SHUNT_PUMPDATA_PUMPREQUESTTYPE_PATH, SHUNT_PUMPDATA_SPECIFICREQUESTTYPE_PATH, SHUNT_SEK_DIMROW_PATH } from "../../../constants/firebasePaths";
import { PRODUCTFAMILY_PR, PRODUCTFAMILY_SR, PRODUCTLINE_FLEX, PRODUCTLINE_GREEN, PRODUCTLINE_SMART, PRODUCTLINE_STANDARD } from "../../../constants/productlineConstants";
import { PUMP_SPECIFIC_REQ } from "../../../constants/pumpOptions";
import { ADJUST_VALVE_STADF, CV_SOE } from "../../../constants/shuntOptions";
import { getPriceCabinetValue } from "../../../firebase/functions/priceFunctions/getPriceCabinetValue";
import { getPriceCouplingValue } from "../../../firebase/functions/priceFunctions/getPriceCouplingValue";
import { getPriceDripPlate } from "../../../firebase/functions/priceFunctions/getPriceDripPlate";
import { getPriceGreenValue } from "../../../firebase/functions/priceFunctions/getPriceGreenValue";
import { getPriceInsulationValue } from "../../../firebase/functions/priceFunctions/getPriceInsulationValue";
import { getPriceWallMount } from "../../../firebase/functions/priceFunctions/getPriceWallMount";
import { saveShuntMarkups, saveShuntPrice } from "../../../firebase/functions/priceFunctions/saveShuntPrice";
import { countDecimals, numberWithSpaceSeparator, roundNearest100 } from "../../../functions/numberFunctions";
import { APriceTextField } from "./components/priceAPriceField";
import { PriceFieldRow } from "./components/priceFieldRow";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { roundTo1Decimals } from "../../../functions/ShuntCalculations/helpFunctions/roundTo4Decimals";
import { getComponentText } from "../../../functions/DisplayFunctions/getComponentText";
import { getSessionPriceData } from "../../../firebase/functions/priceFunctions/getSessionPriceData";
import { CalcParamsEnum, SessionParamsEnum, ShuntParamsEnum } from "../../../enums";
import { useSessionParams } from "../../../contexts";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { PricePipeCoupling } from "./components/pricePipeCoupling";
import { AdjustmentDefault } from "../../../constants/defaultValues/adjustmentDefault";
import { useGetSystemFluidOptions } from "../../../hooks/useGetSystemFluidOptions";


export const PriceCalcShunt = ({ shuntID, handleComplete }) => {
    const systemFluidOptions = useGetSystemFluidOptions();
    const database = useDatabase();
    const firestore = useFirestore();
    const sessionParams = useSessionParams();

    const shuntRef = ref(database, SESSION_SHUNT_PATH + shuntID);
    const shuntPriceRef = ref(database, SESSION_SHUNT_PRICES_PATH + shuntID);

    const resetPriceText = "Observera: alla manuellt inmatade priser kommer nollställas, alla påslags- och rabattsatser kommer återställas till standardvärden, och alla prislistebaserade uträkningar kommer göras om med de för tillfället gällande uppräkningsfaktorerna.";
    const shuntNotCompleteText = "Prissättning ej komplett. Ange pris för alla obligatoriska poster för markera shunt som komplett.";
    const rebateWarningText = "Obs, procentsats utanför standardintervall."

    const standardMarkup = 66;
    const loweredMarkup = 54;
    const shuntData = get(shuntRef);
    const shuntPriceData = get(shuntPriceRef);

    const [extraCV, setExtraCV] = useState(false);
    const [extraPump, setExtraPump] = useState(false);
    const [hasLoweredMarkup, setHasLowererdMarkup] = useState(false);
    const [isLocked, setIsLocked] = useState(false);

    const [adjustModel, setAdjustModel] = useState('');
    const [adjustValve, setAdjustValve] = useState('');
    const [cutOffValve, setCutOffValve] = useState('');

    const [reloadPrice, setReloadPrice] = useState(false);
    const [update, setUpdate] = useState(false);
    const [name, setName] = useState('');
    const [isCompleted, setIsCompleted] = useState(-1);
    const [savedIsCompleted, setSavedIsCompleted] = useState(false);
    const [prodFamily, setProdFamily] = useState('');
    const [prodLine, setProdLine] = useState('');
    //const [material, setMaterial] = useState('');
    const [insulation, setInsulation] = useState('');
    const [cabinet, setCabinet] = useState('');
    const [mount, setMount] = useState('');
    const [dripPlate, setDripPlate] = useState(false);
    const [manometer, setManometer] = useState('');
    const [thermo, setThermo] = useState('');
    //const [cutOffValve, setCutOffValve] = useState('');
    const [selectedDimName, setSelectedDimName] = useState('');
    const [cvName, setCVName] = useState('');
    const [primDim, setPrimDim] = useState(0);
    const [sekDim, setSekDim] = useState(0);
    const [pumpOption, setPumpOption] = useState('');
    //const [numiV, setNumIV] = useState(0);
    const [pumpCard, setPumpCard] = useState(false);
    const [adjustValveText, setAdjustValveText] = useState([]);
    const [cutOffValveText, setCutOffValveText] = useState([]);
    const [shippingCost, setShippingCost] = useState(null)

    const [priceList, setPriceList] = useState({});
    const [markupList, setMarkupList] = useState({});

    const [totalManuCost, setTotalManuCost] = useState(null);
    const [markupPrice, setMarkupPrice] = useState(null);
    const [totalMarkupPrice, setTotalMarkupPrice] = useState(null);
    const [totalWholesale, setTotalWholesale] = useState(null);
    const [totalProfitPrice, setTotalProfitPrice] = useState(null);
    const [wholesalePrice, setWholesalePrice] = useState(null);
    const [profitPrice, setProfitPrice] = useState(null);
    const [rebateSum, setRebateSum] = useState(null);
    const [totalPrice, setTotalPrice] = useState(null);
    const [savedPrice, setSavedPrice] = useState(null);
    const [savedPriceDate, setSavedPriceDate] = useState(null);

    const [markupAvg, setMarkupAvg] = useState(null);
    const [updateValues, setUpdateValues] = useState(false);
    const [updateMarkupValues, setUpdateMarkupValues] = useState(false);

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const displayMarkupPrice = (price, markup) => {
        return roundTo1Decimals((Number(price) / 100) * Number(markup));
    }

    const calcMarkupPrice = (price, markup) => {
        return roundTo1Decimals((Number(price) / 100) * (Number(markup) + 100));
    }

    function calcManuCost() {
        //console.log("Calc Start");
        let total = 0;
        Object.keys(priceList).forEach((priceType) => {
            if (priceList[priceType] != null) {
                if (priceType === 'extraCV') {
                    if (extraCV === true) {
                        total += priceList[priceType];
                    }
                }
                else if (priceType === 'extraPump') {
                    if (extraPump === true) {
                        total += priceList[priceType];
                    }
                }
                else {
                    total += priceList[priceType];
                }

            }
        });

        setTotalManuCost(Math.round(total))
    }

    function calcMarkupCost(recalculate = false) {
        //console.log("Markup Start");
        let markupCost;

        let total = 0;
        let procentTotal = 0;
        let totalNum = 0;

        let totalCVAndPumpPrice = 0;

        Object.keys(priceList).forEach((priceType) => {
            if (priceList[priceType] != null) {
                if (priceType === 'extraCV') {
                    if (extraCV === true) {
                        markupCost = displayMarkupPrice(priceList[priceType], markupList[priceType]);
                        total += markupCost;
                        procentTotal += markupList[priceType];
                        totalNum++;

                        totalCVAndPumpPrice += priceList[priceType] + markupCost;
                    }
                }
                else if (priceType === 'extraPump') {
                    if (extraPump === true) {
                        markupCost = displayMarkupPrice(priceList[priceType], markupList[priceType]);
                        total += markupCost;
                        procentTotal += markupList[priceType];
                        totalNum++;

                        totalCVAndPumpPrice += priceList[priceType] + markupCost;
                    }
                }
                else {
                    markupCost = displayMarkupPrice(priceList[priceType], markupList[priceType]);
                    total += markupCost;

                    procentTotal += markupList[priceType];
                    totalNum++;

                    if (priceType === 'controlValve' || priceType === 'pump') {
                        totalCVAndPumpPrice += priceList[priceType] + markupCost;
                    }
                }

            }
        });

        let totalMarkup = Math.round(Number(total) + Number(totalManuCost));
        if (totalCVAndPumpPrice > totalMarkup / 2 && recalculate === false && hasLoweredMarkup === false) {
            //console.log("Totalt Above half: ", totalMarkup, "CV & Pump:", totalCVAndPumpPrice);

            if (markupList['controlValve'] == null || markupList['controlValve'] === standardMarkup) {
                markupList['controlValve'] = loweredMarkup;
            }
            if (markupList['pump'] == null || markupList['pump'] === standardMarkup) {
                markupList['pump'] = loweredMarkup;
            }
            if (extraCV === true && (markupList['extraCV'] == null || markupList['extraCV'] === standardMarkup)) {
                markupList['extraCV'] = loweredMarkup;
            }
            if (extraPump === true && (markupList['extraPump'] == null || markupList['extraPump'] === standardMarkup)) {
                markupList['extraPump'] = loweredMarkup;
            }

            setHasLowererdMarkup(true);
            calcMarkupCost(true);
        } else {
            if (totalCVAndPumpPrice <= totalMarkup / 2 && recalculate === false && hasLoweredMarkup === true) {
                if (markupList['controlValve'] == null || markupList['controlValve'] === loweredMarkup) {
                    markupList['controlValve'] = standardMarkup;
                }
                if (markupList['pump'] == null || markupList['pump'] === loweredMarkup) {
                    markupList['pump'] = standardMarkup;
                }
                if (markupList['extraCV'] == null || markupList['extraCV'] === loweredMarkup) {
                    markupList['extraCV'] = standardMarkup;
                }
                if (markupList['extraPump'] == null || markupList['extraPump'] === loweredMarkup) {
                    markupList['extraPump'] = standardMarkup;
                }

                setHasLowererdMarkup(false);
                calcMarkupCost(true);
            }
            else {
                //console.log("Totalt: ", totalMarkup, "CV & Pump:", totalCVAndPumpPrice)
                let avgMarkup = procentTotal / totalNum;

                setMarkupPrice(Math.round(total))
                setTotalMarkupPrice(totalMarkup);
                setMarkupAvg(Math.round(avgMarkup));
            }

        }
        //console.log("Total:", Math.round(total), "Cost:", totalManuCost)


    }

    const calcWholesalePrice = () => {
        //console.log("Wholesale Start");
        if (markupList['wholesale'] == null) {
            markupList['wholesale'] = 11;
        }

        let priceAfterMarkup = calcMarkupPrice(totalMarkupPrice, markupList['wholesale']);
        let markupPrice = displayMarkupPrice(totalMarkupPrice, markupList['wholesale']);
        setTotalWholesale(Math.round(priceAfterMarkup));
        setWholesalePrice(Math.round(markupPrice));
    }

    const calcProfitPrice = () => {
        //console.log("Profit Start");
        if (markupList['profit'] == null) {
            markupList['profit'] = 11;
        }

        let priceAfterMarkup = calcMarkupPrice(totalWholesale, markupList['profit']);
        let markupPrice = displayMarkupPrice(totalWholesale, markupList['profit']);
        setTotalProfitPrice(Math.round(priceAfterMarkup));
        setProfitPrice(Math.round(markupPrice));
    }

    const calcRebatePrice = () => {
        //console.log("Rebate Start");
        if (markupList['rebate'] == null) {
            markupList['rebate'] = 0;
        }

        let localRebate = displayMarkupPrice(totalProfitPrice, markupList['rebate']);
        //console.log("local:", localRebate);
        setRebateSum(Math.round(localRebate));
        setTotalPrice(Math.round(Number(totalProfitPrice) - Number(localRebate)))
    }

    /**
     * 
     * @param {*} value
     * @param {String} category 
     */
    function changePrice(value, category) {
        //console.log("Val: ", e.target.value);
        let numValue = Number(value);
        priceList[category] = numValue;

        setUpdateValues(!updateValues)
    }

    /**
 * 
 * @param {*} value
 * @param {String} category 
 */
    function changeMarkup(value, category) {
        //console.log("Val: ", e.target.value);
        let numValue = Number(value);
        markupList[category] = numValue;

        setUpdateMarkupValues(!updateMarkupValues)
    }

    function reloadPrices() {
        setReloadPrice(true);
        setUpdate(!update)
    }

    /**
     * 
     * @param {boolean} next 
     * @param {boolean} completed 
     */
    function savePrice(next, completed) {
        saveShuntPrice(database, shuntID, totalPrice, priceList, completed);
        saveMarkup();

        if (next === true) {
            handleComplete();
        }
        else {
            setUpdate(!update)
        }
    }

    function saveMarkup() {
        saveShuntMarkups(database, shuntID, markupList);
    }

    useEffect(() => {
        const readData = async () => {
            setIsCompleted(-1);
            const snap = await shuntData;
            setMarkupList({})
            setSavedPrice(null);
            setSavedPriceDate(null);
            //console.log("Snap: ", shuntID, snap.val());
            if (snap.exists()) {

                if (snap.child(SHUNT_MAXSTEP_PATH).exists() && Number(snap.child(SHUNT_MAXSTEP_PATH).val()) === 4) {
                    const priceSnap = await shuntPriceData;
                    //console.log("PriceSnap: ", shuntID, priceSnap.val());
                    let localPriceList = {};

                    const sessionInfoData = await getSessionPriceData(database, SessionParamsEnum.SessionID.getContextValue(sessionParams));

                    if (sessionInfoData?.offerLocked != null) {
                        if (sessionInfoData?.offerLocked === true || sessionInfoData?.offerLocked === 'true') {
                            setIsLocked(true);
                        }
                        else if (sessionInfoData?.offerLocked === false || sessionInfoData?.offerLocked === 'false') {
                            setIsLocked(false);
                        }
                    }

                    const localPos = snap.child(SHUNT_POSITION_PATH).val();
                    const localDesig = snap.child(SHUNT_DESIGNATION_PATH).val();
                    const localModel = snap.child(SHUNT_PLMODEL_PATH).val();

                    const localProdFamily = snap.child(SHUNT_PRODFAMILY_PATH).val().split('-')[0];
                    const localProdLine = String(snap.child(SHUNT_PRODLINE_PATH).val()).toLocaleLowerCase();
                    const localPrimDim = Number(snap.child(SHUNT_PRIM_DIMROW_PATH + 'dim').val());
                    const localSekDim = Number(snap.child(SHUNT_SEK_DIMROW_PATH + 'dim').val());


                    let localPrimDimName = snap.child(SHUNT_PRIM_DIMROW_PATH + 'name').val();
                    let localSekDimName = snap.child(SHUNT_SEK_DIMROW_PATH + 'name').val();

                    let largestDimName = localPrimDimName;

                    if (localSekDim > localPrimDim) {
                        largestDimName = localSekDimName;
                    }

                    const isMini = snap.child(SHUNT_IS_MINI_PATH).val();

                    if (isMini === true) {
                        localPrimDimName += 'M';
                        localSekDimName += 'M';
                    }

                    const controlValve = snap.child(SHUNT_CONTROLVALVE_PATH).val();
                    const flexModel = snap.child(SHUNT_FLEXMODEL_PATH).val();
                    const cvModel = snap.child(SHUNT_CV_MODEL_PATH).val();
                    const cvKvs = snap.child(SHUNT_CV_KVS_PATH).val();

                    let localCVName = "";
                    if (localProdLine === PRODUCTLINE_FLEX) {
                        localCVName = controlValve + " " + flexModel + ", " + largestDimName;
                    }
                    else if (localProdLine === PRODUCTLINE_SMART) {
                        localCVName = controlValve + " " + cvModel + ", " + largestDimName;
                        localCVName += ", Kvs " + setNumOfDecimals(cvKvs)
                    }
                    else {
                        if (controlValve === CV_SOE) {
                            localCVName = "Samordnas med SÖE"
                        }
                        else {
                            localCVName = controlValve + " " + cvModel;
                        }
                        localCVName += ", Kvs " + setNumOfDecimals(cvKvs)
                    }


                    const localMaterial = snap.child(SHUNT_ADJUSTDATA_MATERIAL_PATH).val();
                    const localIsolation = snap.child(SHUNT_ADJUSTDATA_INSULATION_PATH).val();
                    const localCabinet = snap.child(SHUNT_ADJUSTDATA_CABINET_PATH).val();
                    const localMount = snap.child(SHUNT_ADJUSTDATA_STAND_PATH).val();
                    const localDripPlate = snap.child(SHUNT_ADJUSTDATA_DRIPPLATE_PATH).val();

                    const localManometer = snap.child(SHUNT_ADJUSTDATA_MANOMETER_PATH).val();
                    const localThermo = snap.child(SHUNT_ADJUSTDATA_THERMO_PATH).val();
                    const localCutOff = snap.child(SHUNT_ADJUSTDATA_CUTOFF_PATH).val();

                    const localPumpCard = snap.child(SHUNT_PUMPDATA_CARD_PATH).val();
                    //console.log("Pump: ", localPumpCard)

                    const localPumpRequest = snap.child(SHUNT_PUMPDATA_PUMPREQUESTTYPE_PATH).val();
                    let localPumpOption = "Pump ";
                    if (localPumpRequest === PUMP_SPECIFIC_REQ) {
                        localPumpOption += snap.child(SHUNT_PUMPDATA_SPECIFICREQUESTTYPE_PATH).val();
                    }
                    else {
                        localPumpOption += snap.child(SHUNT_PUMPDATA_GENERALREQUESTTYPE_PATH).val();
                        localPumpOption += " som dimensioneras av PREMA"
                    }

                    //console.log("IsMini: ", isMini)

                    let couplingPrice;
                    if (localPrimDim === localSekDim) {
                        couplingPrice = await getPriceCouplingValue(firestore, localProdFamily, localPrimDimName, localMaterial);
                        setSelectedDimName(localPrimDimName + '/' + localSekDimName);
                    }
                    else {
                        let pricePrim = await getPriceCouplingValue(firestore, localProdFamily, localPrimDimName, localMaterial);
                        let priceSek = await getPriceCouplingValue(firestore, localProdFamily, localSekDimName, localMaterial);

                        couplingPrice = Math.round((pricePrim + priceSek) / 2);
                        setSelectedDimName(localPrimDimName + '/' + localSekDimName);
                    }

                    //console.log("couplingPrice: ", priceSnap.child("pipeCoupling").val())
                    if (priceSnap.child("pipeCoupling").exists() && reloadPrice === false) {
                        localPriceList['pipeCoupling'] = Number(priceSnap.child("pipeCoupling").val());
                        //setPipeCouplingPrice(Number(priceSnap.child("pipeCoupling").val()));
                    }
                    else {
                        localPriceList['pipeCoupling'] = Number(couplingPrice);
                        //setPipeCouplingPrice(couplingPrice);
                    }


                    if (localIsolation != null) {
                        let insulationPrice;

                        if (localPrimDim === localSekDim) {
                            insulationPrice = await getPriceInsulationValue(firestore, localIsolation, localPrimDimName);
                        }
                        else {
                            let pricePrim = await getPriceInsulationValue(firestore, localIsolation, localPrimDimName);
                            let priceSek = await getPriceInsulationValue(firestore, localIsolation, localSekDimName);

                            insulationPrice = Math.round((pricePrim + priceSek) / 2);
                        }
                        //console.log("InsulationPrice: ", priceSnap.child("insulation").val())
                        if (priceSnap.child("insulation").exists() && reloadPrice === false) {
                            localPriceList['insulation'] = Number(priceSnap.child("insulation").val());
                        }
                        else {
                            localPriceList['insulation'] = insulationPrice;
                        }
                    }

                    if (localProdLine === PRODUCTLINE_GREEN) {
                        let greenPrice;

                        if (localPrimDim === localSekDim) {
                            greenPrice = await getPriceGreenValue(firestore, localProdFamily, localPrimDimName);
                        }
                        else {
                            let pricePrim = await getPriceGreenValue(firestore, localProdFamily, localPrimDimName);
                            let priceSek = await getPriceGreenValue(firestore, localProdFamily, localSekDimName);

                            greenPrice = Math.round((pricePrim + priceSek) / 2);
                        }

                        //console.log("GreenPrice: ", priceSnap.child("green").val())
                        if (priceSnap.child("green").exists() && reloadPrice === false) {
                            localPriceList['green'] = Number(priceSnap.child("green").val());
                        }
                        else {
                            localPriceList['green'] = greenPrice;
                        }
                    }

                    if (localCabinet !== NO_CABINET) {
                        let cabinetPrice;
                        let isInsulated = false;
                        if (localCabinet === CABINET_INSULATED) {
                            isInsulated = true;
                        }

                        if (localPrimDim > localSekDim) {
                            cabinetPrice = await getPriceCabinetValue(firestore, localPrimDimName, isInsulated);
                        }
                        else {
                            cabinetPrice = await getPriceCabinetValue(firestore, localSekDimName, isInsulated);
                        }

                        //console.log("cabinetPrice: ", priceSnap.child("cabinet").val())
                        if (priceSnap.child("cabinet").exists() && reloadPrice === false) {
                            localPriceList['cabinet'] = Number(priceSnap.child("cabinet").val());
                        }
                        else {
                            localPriceList['cabinet'] = cabinetPrice;
                        }
                    }

                    if (localMount === STAND_WALL) {
                        let wallMountPrice;

                        if (localPrimDim > localSekDim) {
                            wallMountPrice = await getPriceWallMount(firestore, localPrimDimName);
                        }
                        else {
                            wallMountPrice = await getPriceWallMount(firestore, localSekDimName);
                        }

                        //console.log("Value:", wallMountPrice)

                        if (priceSnap.child("wallMount").exists() && reloadPrice === false) {

                            localPriceList['wallMount'] = Number(priceSnap.child("wallMount").val());
                        }
                        else {
                            localPriceList['wallMount'] = wallMountPrice;
                        }
                    }

                    if (localDripPlate === true) {
                        let dripPlatePrice;

                        if (localPrimDim > localSekDim) {
                            dripPlatePrice = await getPriceDripPlate(firestore, localPrimDimName);
                        }
                        else {
                            dripPlatePrice = await getPriceDripPlate(firestore, localSekDimName);
                        }

                        if (priceSnap.child("dripPlate").exists() && reloadPrice === false) {
                            localPriceList['dripPlate'] = Number(priceSnap.child("dripPlate").val());
                            //setDripPlatePrice(Number(priceSnap.child("dripPlate").val()));
                        }
                        else {
                            localPriceList['dripPlate'] = Number(dripPlatePrice);
                            //setDripPlatePrice(dripPlatePrice);
                        }
                    }

                    if (localManometer !== NO_MANOMETER) {
                        if (priceSnap.child("manometer").exists() && reloadPrice === false) {
                            localPriceList['manometer'] = Number(priceSnap.child("manometer").val());
                            //setManoMeterPrice(Number(priceSnap.child("manometer").val()));
                        }
                    }

                    if (localThermo !== THERMO_BIMETALLIC) {
                        if (priceSnap.child("thermo").exists() && reloadPrice === false) {
                            localPriceList['thermo'] = Number(priceSnap.child("thermo").val());
                            //setThermoPrice(Number(priceSnap.child("thermo").val()));
                        }
                    }

                    if (priceSnap.child("pump").exists() && reloadPrice === false) {
                        localPriceList['pump'] = Number(priceSnap.child("pump").val());
                        //setPumpPrice(Number(priceSnap.child("pump").val()));
                    }

                    if (priceSnap.child("pumpCard").exists() && reloadPrice === false) {
                        localPriceList['pumpCard'] = Number(priceSnap.child("pumpCard").val());
                        //setPumpPrice(Number(priceSnap.child("pump").val()));
                    }

                    if (priceSnap.child("controlValve").exists() && reloadPrice === false) {
                        localPriceList['controlValve'] = Number(priceSnap.child("controlValve").val());
                        //setControlValvePrice(Number(priceSnap.child("controlValve").val()));
                    }

                    if (priceSnap.child("other").exists() && reloadPrice === false) {
                        localPriceList['other'] = Number(priceSnap.child("other").val());
                        //setOtherPrice(Number(priceSnap.child("other").val()));
                    }

                    let adjustModel = snap.child('ShuntData').child('adjValve').val(); //shuntData?.ShuntData?.adjValve;
                    let adjValueVal = snap.child('AdjustData').child('adjustValue').val(); //shuntData?.AdjustData?.adjustValue;
                    let cutOffValve = snap.child('AdjustData').child('cutOff').val(); //shuntData?.AdjustData?.cutOff;

                    setAdjustModel(adjustModel);
                    setAdjustValve(adjValueVal);
                    setCutOffValve(cutOffValve);

                    const adjustValue = getComponentText('adjustValve', false, snap.val(), systemFluidOptions);
                    setAdjustValveText(adjustValue);

                    const cutofftValue = getComponentText('cutOff', false, snap.val(), systemFluidOptions);
                    setCutOffValveText(cutofftValue);

                    if (reloadPrice === false) {
                        for (let index = 0; index < adjustValue.length; index++) {
                            if (priceSnap.child('adjustValve-' + index).exists()) {
                                localPriceList['adjustValve-' + index] = Number(priceSnap.child('adjustValve-' + index).val());
                            }
                        }

                        for (let index = 0; index < cutofftValue.length; index++) {
                            if (priceSnap.child('cutOffValve-' + index).exists()) {
                                localPriceList['cutOffValve-' + index] = Number(priceSnap.child('cutOffValve-' + index).val());
                            }
                        }
                    }

                    if (priceSnap.child("shipCost").exists()) {
                        setShippingCost(Number(priceSnap.child("shipCost").val()))
                    }

                    setMarkupsFromSnapshot(priceSnap, reloadPrice);


                    if (localProdLine === PRODUCTLINE_STANDARD) {
                        setName('Pos.' + localPos + ' - ' + localDesig + ' (' + localModel + ')');
                    }
                    else {
                        setName('Pos.' + localPos + ' - ' + localDesig + ' (' + localModel + ' - ' + capitalizeFirstLetter(localProdLine) + ')');
                    }

                    if (snap.child(SHUNT_CUSTOMDATA_TOTALPRICE_PATH).exists()) {
                        setSavedPrice(Number(snap.child(SHUNT_CUSTOMDATA_TOTALPRICE_PATH).val()));
                    }

                    if (snap.child(SHUNT_CUSTOMDATA_PRICESAVED_PATH).exists()) {
                        let saved = new Date(snap.child(SHUNT_CUSTOMDATA_PRICESAVED_PATH).val());
                        setSavedPriceDate(saved.toISOString().split('T')[0])
                    }

                    if (snap.child(SHUNT_CUSTOMDATA_PRICE_ISCOMPLETE_PATH).exists()) {
                        setSavedIsCompleted(snap.child(SHUNT_CUSTOMDATA_PRICE_ISCOMPLETE_PATH).val());
                    }

                    setProdLine(localProdLine);
                    setProdFamily(localProdFamily);
                    setCVName(localCVName);
                    setPumpOption(localPumpOption);
                    setPumpCard(localPumpCard);
                    setPrimDim(localPrimDim);
                    setSekDim(localSekDim);

                    setPriceList(localPriceList);
                    setInsulation(localIsolation);
                    setCabinet(localCabinet);
                    setMount(localMount);
                    setDripPlate(localDripPlate);
                    setManometer(localManometer);
                    setThermo(localThermo);

                    /*
                    setMaterial(localMaterial);
                    setCutOffValve(localCutOff);
                    
                    
                    setPrimDim(localPrimDim);
                    setSekDim(localSekDim);*/
                    setIsCompleted(1);
                }
                else {
                    setIsCompleted(0);
                }

                setReloadPrice(false);
                setUpdateValues(!updateValues);
                setUpdateMarkupValues(!updateMarkupValues);
            }
        };

        readData();
    }, [shuntID, update]);

    useEffect(() => {
        //console.log("Here", priceList)
        calcManuCost();
        //calcMarkupCost();
    }, [priceList, updateValues]);

    useEffect(() => {
        //calcManuCost();
        calcMarkupCost();
    }, [markupList, totalManuCost, updateMarkupValues]);

    useEffect(() => {
        calcWholesalePrice();
    }, [totalMarkupPrice, markupList['wholesale']]);

    useEffect(() => {
        calcProfitPrice();
    }, [totalWholesale, markupList['profit']]);

    useEffect(() => {
        calcRebatePrice();
    }, [totalProfitPrice, markupList['rebate']]);

    /**
     * 
     * @param {DataSnapshot} priceSnap 
     * @param {boolean} reloadPrice 
     */
    const setMarkupsFromSnapshot = (priceSnap, reloadPrice) => {
        if (priceSnap.child("Markups").exists() && reloadPrice === false) {
            let localMarkupList = {};
            priceSnap.child("Markups").forEach((child) => {
                localMarkupList[child.key] = Number(child.val());
            });

            setMarkupList(localMarkupList);
        }
        else {
            setMarkupList({
                wholesale: 11,
                profit: 3,
                rebate: 0,
            });
        }
    }

    const setNumOfDecimals = (value) => {
        if (value != null) {
            let num = countDecimals(value);

            if (num < 2) {
                return value.toFixed(1);
            }
            else {
                return value.toFixed(num);
            }
        }
    }


    const disableSaveButtons = () => {
        if (priceList['pipeCoupling'] == null || priceList['controlValve'] == null || priceList['pump'] == null) {
            return true;
        }

        if (insulation != null && insulation !== '') {
            if (priceList['insulation'] == null) {
                return true;
            }
        }

        if (prodLine === PRODUCTLINE_GREEN) {
            if (priceList['green'] == null) {
                return true;
            }
        }

        if (cabinet !== NO_CABINET) {
            if (priceList['cabinet'] == null) {
                return true;
            }
        }

        if (mount === STAND_WALL) {
            if (priceList['wallMount'] == null) {
                return true;
            }
        }

        if (dripPlate === true) {
            if (priceList['dripPlate'] == null) {
                return true;
            }
        }

        if (pumpCard === true) {
            if (priceList['pumpCard'] == null) {
                return true;
            }
        }

        if (isDefaultAdjustValve() === false) {
            for (let index = 0; index < adjustValveText.length; index++) {
                if (priceList['adjustValve-' + index] == null) {
                    return true;
                }
            }
        }

        if (isDefaultCutOffValve() === false) {
            for (let index = 0; index < cutOffValveText.length; index++) {
                if (priceList['cutOffValve-' + index] == null) {
                    return true;
                }
            }
        }


        if (manometer !== NO_MANOMETER) {
            if (priceList['manometer'] == null) {
                return true;
            }
        }

        if (thermo !== THERMO_BIMETALLIC) {
            if (priceList['thermo'] == null) {
                return true;
            }
        }

        return false;
    }


    const setRebateWarningWholesale = () => {
        if (markupList['wholesale'] !== 11) {
            return true;
        }
        else {
            return false;
        }
    }

    const setRebateWarningProfit = () => {
        if (markupList['profit'] < 2 || markupList['profit'] > 10) {
            return true;
        }
        else {
            return false;
        }
    }

    const setRebateWarningRebate = () => {
        if (markupList['rebate'] > 10) {
            return true;
        }
        else {
            return false;
        }
    }

    const setRebateWarningGeneral = () => {
        if (setRebateWarningWholesale() === true || setRebateWarningProfit() === true || setRebateWarningRebate() === true) {
            return true;
        }
        else {
            return false;
        }
    }

    const getDefaultValues = () => {
        let defaultValues = {};

        if (prodFamily === PRODUCTFAMILY_SR) {
            if (prodLine === PRODUCTLINE_FLEX) {
                defaultValues = AdjustmentDefault.SRFlexDefault;
            }
            else if (prodLine === PRODUCTLINE_GREEN) {
                defaultValues = AdjustmentDefault.SRGreenDefault;
            }
            else if (prodLine === PRODUCTLINE_SMART) {
                defaultValues = AdjustmentDefault.SRSmartDefault;
            }
            else {
                defaultValues = AdjustmentDefault.SRDefault;
            }
        }
        else if (prodFamily === PRODUCTFAMILY_PR) {
            if (prodLine === PRODUCTLINE_GREEN) {
                defaultValues = AdjustmentDefault.PRGreenDefault;
            }
            else {
                defaultValues = AdjustmentDefault.PRDefault;
            }
        }
        else {
            if (prodLine === PRODUCTLINE_GREEN) {
                defaultValues = AdjustmentDefault.VAGreenDefault;
            }
            else {
                defaultValues = AdjustmentDefault.VADefault;
            }
        }



        return defaultValues;
    }

    const isDefaultAdjustValve = () => {
        if (adjustModel === ADJUST_VALVE_STADF && adjustValve !== ADJUSTVALUE_STAD_ZERO) {
            return true;
        }
        else {
            return false;
        }
    }

    const isDefaultCutOffValve = () => {
        let defaultVals = getDefaultValues();

        if (defaultVals['cutOff'] === cutOffValve) {
            return true;
        }
        else {
            return false;
        }
    }

    return (
        <Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isCompleted === -1}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {isCompleted !== -1 &&
                <Fragment>
                    <Box style={{ marginLeft: '5px', fontSize: 16, marginTop: '-10px', marginBottom: '5px' }}>
                        <b>Shuntgrupp: </b> <i>{name}</i><br />
                    </Box>
                    <Divider sx={{ borderBottomColor: '#06559e', width: '800px' }} />
                </Fragment>
            }

            <Stack>
                <Box style={{ padding: '2px', marginTop: '1px', marginBottom: '1px' }}>
                    {isCompleted === 1 ? (
                        <Stack direction={'row'} justifyContent="space-between">
                            <Stack style={{ width: '800px' }} spacing={1}>
                                <Stack direction={'row'}>
                                    <Typography component={'h4'}>
                                        <strong><u>Dimensionsbaserade priser:</u></strong>
                                    </Typography>
                                    <Typography component={'div'} style={{marginLeft: '50px'}}>
                                        (<i>Dimensioner: {selectedDimName}</i> )
                                    </Typography>
                                </Stack>


                                <PricePipeCoupling
                                    insulation={insulation}
                                    pipeCouplingPrice={priceList['pipeCoupling']}
                                    markupPipe={markupList['pipeCoupling']}
                                    insulationPrice={priceList['insulation']}
                                    markupIns={markupList['insulation']}
                                    standardMarkup={standardMarkup}
                                    changePrice={changePrice}
                                    changeMarkup={changeMarkup}
                                    priceCouplingTotal={Number(priceList['insulation']) + Number(priceList['pipeCoupling'])}
                                />

                                {/*
                                <PriceFieldRow
                                    title={"Rörkoppel"}
                                    required={true}
                                    price={priceList['pipeCoupling']}
                                    markupVal={markupList['pipeCoupling']}
                                    changePriceFunc={changePrice}
                                    changeMarkup={changeMarkup}
                                    category={'pipeCoupling'}
                                    standardMarkup={standardMarkup}
                                    isPriceCoupling={true}
                                    priceCoupling={Number(priceList['insulation']) + Number(priceList['pipeCoupling'])}
                                />

                                {insulation != null && insulation !== '' &&
                                    <PriceFieldRow
                                        title={"Isolering"}
                                        required={true}
                                        price={priceList['insulation']}
                                        markupVal={markupList['insulation']}
                                        changePriceFunc={changePrice}
                                        changeMarkup={changeMarkup}
                                        category={'insulation'}
                                        standardMarkup={standardMarkup}
                                    />
                                } */}

                                {prodLine === PRODUCTLINE_GREEN &&
                                    <>
                                        <PriceFieldRow
                                            title={"Green-tillägg"}
                                            required={true}
                                            price={priceList['green']}
                                            markupVal={markupList['green']}
                                            changePriceFunc={changePrice}
                                            changeMarkup={changeMarkup}
                                            category={'green'}
                                            standardMarkup={standardMarkup}
                                        />
                                    </>
                                }

                                {cabinet !== NO_CABINET &&
                                    <PriceFieldRow
                                        title={"Skåp"}
                                        required={true}
                                        price={priceList['cabinet']}
                                        markupVal={markupList['cabinet']}
                                        changePriceFunc={changePrice}
                                        changeMarkup={changeMarkup}
                                        category={'cabinet'}
                                        standardMarkup={standardMarkup}
                                    />
                                }

                                {mount === STAND_WALL &&
                                    <PriceFieldRow
                                        title={"Väggstativ"}
                                        required={true}
                                        price={priceList['wallMount']}
                                        markupVal={markupList['wallMount']}
                                        changePriceFunc={changePrice}
                                        changeMarkup={changeMarkup}
                                        category={'wallMount'}
                                        standardMarkup={standardMarkup}
                                    />
                                }

                                {dripPlate === true &&
                                    <PriceFieldRow
                                        title={"Dropplåt"}
                                        required={true}
                                        price={priceList['dripPlate']}
                                        markupVal={markupList['dripPlate']}
                                        changePriceFunc={changePrice}
                                        changeMarkup={changeMarkup}
                                        category={'dripPlate'}
                                        standardMarkup={standardMarkup}
                                    />
                                }

                                {/* 
                                <Divider sx={{ borderBottomColor: '#06559e' }} />
                                
                                <Typography component={'h4'}>
                                    <strong><u>Val/komponenter med fast prislista:</u></strong>
                                </Typography>

                                
                                {manometer !== NO_MANOMETER &&
                                    <APriceTextField
                                        title={'Manometersats'}
                                        option={'Manometersats ' + manometer}
                                        amount={1}
                                        price={manometePrice}
                                        markupVal={markupManometer}
                                        changePriceFunc={changePrice}
                                        changeMarkup={changeMarkup}
                                        category={'manometer'}
                                    />
                                }
                                */}



                                <Divider sx={{ borderBottomColor: '#06559e' }} />

                                <Typography component={'h4'}>
                                    <strong><u>Val/komponenter med manuellt pris:</u></strong>
                                </Typography>

                                <PriceFieldRow
                                    title={"Styrventil"}
                                    required={true}
                                    option={cvName}
                                    price={priceList['controlValve']}
                                    markupVal={markupList['controlValve']}
                                    changePriceFunc={changePrice}
                                    changeMarkup={changeMarkup}
                                    category={'controlValve'}
                                    standardMarkup={standardMarkup}
                                />

                                {extraCV === true ? (
                                    <Fragment>
                                        <PriceFieldRow
                                            title={"Styrventil 2"}
                                            option={null}
                                            price={priceList['extraCV']}
                                            markupVal={markupList['extraCV']}
                                            changePriceFunc={changePrice}
                                            changeMarkup={changeMarkup}
                                            category={'extraCV'}
                                            standardMarkup={standardMarkup}
                                            isExtra={true}
                                            isExtraFunc={setExtraCV}
                                        />
                                    </Fragment>

                                ) : (
                                    <Button
                                        size="small"
                                        onClick={() => { setExtraCV(true) }}
                                        style={{ height: "20px", width: "150px", justifyContent: 'left' }}>
                                        <AddCircleOutlineIcon /><Typography style={{ marginLeft: "5px" }} fontSize={12}>Styrventil 2</Typography>
                                    </Button>
                                )}

                                <PriceFieldRow
                                    title={"Pump"}
                                    required={true}
                                    option={pumpOption}
                                    price={priceList['pump']}
                                    markupVal={markupList['pump']}
                                    changePriceFunc={changePrice}
                                    changeMarkup={changeMarkup}
                                    category={'pump'}
                                    standardMarkup={standardMarkup}
                                />

                                {extraPump === true ? (
                                    <Fragment>
                                        <PriceFieldRow
                                            title={"Pump 2"}
                                            option={null}
                                            price={priceList['extraPump']}
                                            markupVal={markupList['extraPump']}
                                            changePriceFunc={changePrice}
                                            changeMarkup={changeMarkup}
                                            category={'extraPump'}
                                            standardMarkup={standardMarkup}
                                            isExtra={true}
                                            isExtraFunc={setExtraPump}
                                        />
                                    </Fragment>

                                ) : (
                                    <Button
                                        size="small"
                                        onClick={() => { setExtraPump(true) }}
                                        style={{ height: "20px", width: "150px", justifyContent: 'left' }}>
                                        <AddCircleOutlineIcon /><Typography style={{ marginLeft: "5px" }} fontSize={12}>Pump 2</Typography>
                                    </Button>
                                )}

                                {pumpCard === true &&
                                    <PriceFieldRow
                                        title={"Kommunikationskort pump"}
                                        required={true}
                                        option={'Kommunikationskort pump (Modbus)'}
                                        price={priceList['pumpCard']}
                                        markupVal={markupList['pumpCard']}
                                        changePriceFunc={changePrice}
                                        changeMarkup={changeMarkup}
                                        category={'pumpCard'}
                                        standardMarkup={standardMarkup}
                                    />
                                }

                                {isDefaultAdjustValve() === false &&
                                    <>
                                        {adjustValveText.map((valve, index) => {
                                            return (
                                                <APriceTextField
                                                    title={'Injusteringsventiler'}
                                                    required={true}
                                                    option={valve?.text}
                                                    amount={Number(valve?.num)}
                                                    price={priceList['adjustValve-' + index]}
                                                    markupVal={markupList['adjustValve-' + index]}
                                                    changePriceFunc={changePrice}
                                                    changeMarkup={changeMarkup}
                                                    category={'adjustValve-' + index}
                                                    standardMarkup={standardMarkup}
                                                />
                                            )
                                        })}
                                    </>
                                }

                                {isDefaultCutOffValve() === false &&
                                    <>
                                        {cutOffValveText.map((valve, index) => {
                                            return (
                                                <APriceTextField
                                                    title={'Avstängningsventiler'}
                                                    required={true}
                                                    option={String(valve?.text)}
                                                    amount={Number(valve?.num)}
                                                    price={priceList['cutOffValve-' + index]}
                                                    markupVal={markupList['cutOffValve-' + index]}
                                                    changePriceFunc={changePrice}
                                                    changeMarkup={changeMarkup}
                                                    category={'cutOffValve-' + index}
                                                    standardMarkup={standardMarkup}
                                                />
                                            )
                                        })}
                                    </>}

                                {manometer !== NO_MANOMETER &&
                                    <APriceTextField
                                        title={'Manometersats'}
                                        required={true}
                                        option={'Manometersats ' + manometer}
                                        amount={1}
                                        price={priceList['manometer']}
                                        markupVal={markupList['manometer']}
                                        changePriceFunc={changePrice}
                                        changeMarkup={changeMarkup}
                                        category={'manometer'}
                                        standardMarkup={standardMarkup}
                                    />
                                }

                                {thermo !== THERMO_BIMETALLIC &&
                                    <APriceTextField
                                        title={'Termometrar'}
                                        required={true}
                                        option={THERMO_MULTIPLE_LABELS[thermo]}
                                        price={priceList['thermo']}
                                        amount={4}
                                        markupVal={markupList['thermo']}
                                        changePriceFunc={changePrice}
                                        changeMarkup={changeMarkup}
                                        category={'thermo'}
                                        standardMarkup={standardMarkup}
                                    />
                                }

                                {prodLine !== PRODUCTLINE_SMART &&
                                    <Fragment>
                                        <PriceFieldRow
                                            title={"Temperaturgivare"}
                                            price={priceList['tempSensor']}
                                            markupVal={markupList['tempSensor']}
                                            changePriceFunc={changePrice}
                                            changeMarkup={changeMarkup}
                                            category={'tempSensor'}
                                            standardMarkup={standardMarkup}
                                        />

                                        <PriceFieldRow
                                            title={"Energimätare"}
                                            price={priceList['energyMeter']}
                                            markupVal={markupList['energyMeter']}
                                            changePriceFunc={changePrice}
                                            changeMarkup={changeMarkup}
                                            category={'energyMeter'}
                                            standardMarkup={standardMarkup}
                                        />
                                    </Fragment>
                                }

                                <PriceFieldRow
                                    title={"Smutsfilter"}
                                    price={priceList['dirtFilter']}
                                    markupVal={markupList['dirtFilter']}
                                    changePriceFunc={changePrice}
                                    changeMarkup={changeMarkup}
                                    category={'dirtFilter'}
                                    standardMarkup={standardMarkup}
                                />

                                <PriceFieldRow
                                    title={"Övrigt"}
                                    price={priceList['other']}
                                    markupVal={markupList['other']}
                                    changePriceFunc={changePrice}
                                    changeMarkup={changeMarkup}
                                    category={'other'}
                                    standardMarkup={standardMarkup}
                                />

                                <Divider sx={{ borderBottomWidth: '5px', borderBottomColor: '#06559e' }} />

                                <Stack direction={'row'} alignItems="center">
                                    <div style={{ marginRight: "512px", width: "128px", textAlign: 'right' }}>
                                        Tillverkningskostnad:
                                    </div>
                                    <div style={{ width: "100px", textAlign: 'right' }}>
                                        {numberWithSpaceSeparator(totalManuCost || 0)} kr
                                    </div>
                                </Stack>

                                <Stack direction={'row'} alignItems="center">
                                    <div style={{ marginRight: "118px", width: "128px", textAlign: 'right' }}>
                                        Indir kost-påslag:
                                    </div>
                                    <div style={{ marginRight: "75px", width: "80px", color: "#878787" }}>
                                        (Snitt: {markupAvg || 0} %)
                                    </div>
                                    <div style={{ marginRight: "108px", width: "130px", color: "#34a402" }}>
                                        (+ {numberWithSpaceSeparator(markupPrice || 0)} kr)
                                    </div>
                                    <div style={{ width: "100px", textAlign: 'right' }}>
                                        {numberWithSpaceSeparator(totalMarkupPrice || 0)} kr
                                    </div>
                                </Stack>

                                <Stack direction={'row'} alignItems="center">
                                    <div style={{ marginRight: "118px", width: "128px", textAlign: 'right' }}>
                                        Grossist-påslag:
                                    </div>
                                    <Tooltip title={setRebateWarningWholesale() === false ? "" : rebateWarningText} arrow placement={'right'}>
                                        <TextField
                                            error={setRebateWarningWholesale()}
                                            style={{ width: "75px", marginRight: "80px" }}
                                            label={'Påslag'}
                                            type={'number'}
                                            onKeyDown={(evt) => (/^[a-öA-Ö+?']?$/).test(evt.key) && evt.preventDefault()}
                                            InputProps={{
                                                endAdornment: '%',
                                                inputProps: {
                                                    max: 999, min: 0
                                                },
                                            }}
                                            value={markupList['wholesale'] || 11}
                                            onChange={(e) => { changeMarkup(e.target.value, "wholesale") }}
                                        />
                                    </Tooltip>
                                    <div style={{ marginRight: "108px", width: "130px", color: "#34a402" }}>
                                        (+ {numberWithSpaceSeparator(wholesalePrice || 0)} kr)
                                    </div>
                                    <div style={{ width: "100px", textAlign: 'right' }}>
                                        {numberWithSpaceSeparator(totalWholesale || 0)} kr
                                    </div>
                                </Stack>

                                <Stack direction={'row'} alignItems="center">
                                    <div style={{ marginRight: "118px", width: "128px", textAlign: 'right' }}>
                                        Vinst-påslag:
                                    </div>
                                    <Tooltip title={setRebateWarningProfit() === false ? "" : rebateWarningText} arrow placement={'right'}>
                                        <TextField
                                            error={setRebateWarningProfit()}
                                            style={{ width: "75px", marginRight: "80px" }}
                                            label={'Påslag'}
                                            type={'number'}
                                            onKeyDown={(evt) => (/^[a-öA-Ö+?']?$/).test(evt.key) && evt.preventDefault()}
                                            InputProps={{
                                                endAdornment: '%',
                                                inputProps: {
                                                    max: 999, min: 0
                                                },
                                            }}
                                            value={markupList['profit'] || 3}
                                            onChange={(e) => { changeMarkup(e.target.value, "profit") }}
                                        />
                                    </Tooltip>
                                    <div style={{ marginRight: "108px", width: "130px", color: "#34a402" }}>
                                        (+ {numberWithSpaceSeparator(profitPrice || 0)} kr)
                                    </div>
                                    <div style={{ width: "100px", textAlign: 'right' }}>
                                        {numberWithSpaceSeparator(totalProfitPrice || 0)} kr
                                    </div>
                                </Stack>

                                <Stack direction={'row'} alignItems="center">
                                    <div style={{ marginRight: "118px", width: "128px", textAlign: 'right' }}>
                                        Rabatt:
                                    </div>
                                    <Tooltip title={setRebateWarningRebate() === false ? "" : rebateWarningText} arrow placement={'right'}>
                                        <TextField
                                            error={setRebateWarningRebate()}
                                            style={{ width: "75px", marginRight: "80px" }}
                                            label={'Rabatt'}
                                            type={'number'}
                                            onKeyDown={(evt) => (/^[a-öA-Ö+?']?$/).test(evt.key) && evt.preventDefault()}
                                            InputProps={{
                                                endAdornment: '%',
                                                inputProps: {
                                                    max: 100, min: 0
                                                },
                                            }}
                                            value={markupList['rebate'] || 0}
                                            onChange={(e) => { changeMarkup(e.target.value, "rebate") }}
                                        />
                                    </Tooltip>
                                    <div style={{ marginRight: "108px", width: "130px", color: "#f84d2f" }}>
                                        (- {numberWithSpaceSeparator(rebateSum || 0)} kr)
                                    </div>
                                    <div style={{ width: "100px", textAlign: 'right' }}>
                                        {numberWithSpaceSeparator(totalPrice || 0)} kr
                                    </div>
                                </Stack>
                                {/*shippingCost != null && isLocked === true &&
                                    <Stack direction={'row'} alignItems="center">
                                        <div style={{ marginRight: "512px", width: "128px", textAlign: 'right' }}>
                                            + frakt och emballage:
                                        </div>
                                        <div style={{ width: "100px", textAlign: 'right' }}>
                                            {numberWithSpaceSeparator(shippingCost)} kr
                                        </div>
                                    </Stack>
                                    */}

                                <Divider sx={{ borderBottomColor: '#06559e' }} />

                                <Stack>
                                    <Stack direction={'row'}>
                                        {shippingCost != null && isLocked === true ? (
                                            <div style={{ fontSize: 14, width: "160px", marginRight: "442px" }}>
                                                <b>UTPRIS:</b> {numberWithSpaceSeparator(roundNearest100(totalPrice + shippingCost) || 0)} kr
                                            </div>
                                        ) : (
                                            <div style={{ fontSize: 14, width: "160px", marginRight: "442px" }}>
                                                <b>UTPRIS:</b> {numberWithSpaceSeparator(roundNearest100(totalPrice) || 0)} kr
                                            </div>
                                        )}


                                        <div>
                                            Summa:
                                        </div>
                                        <div style={{ width: "90px", textAlign: 'right' }}>
                                            {numberWithSpaceSeparator(totalPrice || 0)} kr
                                        </div>
                                    </Stack>
                                    {shippingCost != null && isLocked === true &&
                                        <Stack direction={'row'}>
                                            <div style={{ marginLeft: "522px" }}>
                                                + frakt och emballage:
                                            </div>
                                            <div style={{ width: "90px", textAlign: 'right' }}>
                                                {numberWithSpaceSeparator(shippingCost || 0)} kr
                                            </div>
                                        </Stack>
                                    }
                                </Stack>


                                <Stack direction={'row'}>
                                    {disableSaveButtons() === false ? (
                                        <Fragment>
                                            <Button style={{ width: 70 }} variant='outlined' disabled={isLocked} onClick={() => { savePrice(false, true) }}>
                                                Spara
                                            </Button>
                                            <Button style={{ width: 200, marginLeft: 10 }} disabled={isLocked} variant='outlined' onClick={() => { savePrice(true, true) }}>
                                                Spara & Gå till nästa
                                            </Button>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <Tooltip title={shuntNotCompleteText} placement="bottom-start">
                                                <Button style={{ width: 70 }} variant='outlined' disabled={isLocked} onClick={() => { savePrice(false, false) }}>
                                                    Spara
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title={shuntNotCompleteText} placement="bottom-start">
                                                <Button style={{ width: 200, marginLeft: 10 }} disabled={isLocked} variant='outlined' onClick={() => { savePrice(true, false) }}>
                                                    Spara & Gå till nästa
                                                </Button>
                                            </Tooltip>
                                        </Fragment>
                                    )}

                                    {isLocked === true &&
                                        <Tooltip title={"Offert är låst."}>
                                            <LockOutlinedIcon style={{ marginTop: '3px', marginLeft: '10px', fontSize: "20px", color: "#e12427" }} fontSize="inherit" />
                                        </Tooltip>
                                    }

                                </Stack>

                                {savedPrice != null &&
                                    <Fragment>
                                        {shippingCost != null && isLocked === true ? (
                                            <Typography component={'h4'} color={'#878787'}>
                                                Sparat belopp: {numberWithSpaceSeparator(roundNearest100(savedPrice + shippingCost))} kr ({numberWithSpaceSeparator(savedPrice)} kr + {numberWithSpaceSeparator(shippingCost)} kr frakt), {savedPriceDate} {savedIsCompleted !== true && <> (Ej komplett)</>}
                                            </Typography>
                                        ) : (
                                            <Typography component={'h4'} color={'#878787'}>
                                                Sparat belopp: {numberWithSpaceSeparator(roundNearest100(savedPrice))} kr ({numberWithSpaceSeparator(savedPrice)} kr), {savedPriceDate} {savedIsCompleted !== true && <> (Ej komplett)</>}
                                            </Typography>
                                        )}
                                    </Fragment>
                                }

                                <Tooltip title={resetPriceText} placement="right">
                                    <div style={{ width: 'fit-content' }}>
                                        <Button
                                            sx={{ width: 'fit-content', marginTop: '10px' }}
                                            variant="outlined"
                                            onClick={reloadPrices}
                                            disabled={isLocked}>
                                            Återställ standard-priser
                                        </Button>
                                    </div>
                                </Tooltip>

                            </Stack>
                        </Stack>

                    ) : (
                        <Fragment>
                            {isCompleted === 0 &&
                                <Box>
                                    Shuntgruppen är inte färdigställd än!
                                </Box>}
                        </Fragment>
                    )}
                </Box>
            </Stack>
        </Box>
    )
}
