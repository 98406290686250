import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useAnalytics } from 'reactfire';
import { DriftParam } from '../modules/driftParam/driftParam';
import { ShuntgroupParam } from '../modules/driftParam/shuntgroupParam';
import { logEvent } from 'firebase/analytics';

export const Step2 = () => {
    const analytics = useAnalytics();

    useEffect(() => {
        logEvent(analytics, 'page_view', { page_location: window.location.href, page_title: "Step 2" });
    }, [window.location.href]);

    return (
        <Box style={{ marginTop: '15px' }}>
            {/* <CalculationMode /> */}
            <Stack direction="row" spacing={2}>
                <DriftParam />
                <ShuntgroupParam />
            </Stack>
        </Box>
    );
};