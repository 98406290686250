import { Stack, TextField, Button } from "@mui/material"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { numberWithSpaceSeparator } from "../../../../functions/numberFunctions";
import { Fragment, useEffect, useState } from "react";

export const PriceFieldRow = ({ title, option = null, required = false, price, markupVal, changePriceFunc, changeMarkup, category, standardMarkup, isExtra = false, isExtraFunc = null, modMarkup = false }) => {
    const [localVal, setLocalVal] = useState(price);
    const [localMarkup, setLocalMarkup] = useState(markupVal || standardMarkup)

    const displayTotalPrice = () => {
        if (localVal != null && localMarkup != null && isNaN(localVal) !== true && isNaN(localMarkup) !== true) {
            return Math.round((Number(localVal) / 100) * (Number(localMarkup) + 100));
        }
        else {
            return 0;
        }
    }

    useEffect(() => {
        //console.log("Update Markup:", markupVal);
        if (markupVal !== localMarkup) {
            setLocalMarkup(markupVal);
        }
    }, [markupVal])

    useEffect(() => {
        if (localVal != null && isNaN(localVal) === false && Number(localVal) !== Number(price)) {
            changePriceFunc(localVal, category);
        }
    }, [localVal]);

    useEffect(() => {
        if (localMarkup != null && isNaN(localMarkup) === false && Number(localMarkup) !== Number(markupVal)) {
            changeMarkup(localMarkup, category)
        }

    }, [localMarkup]);


    const setPrice = (value) => {
        setLocalVal(value);
    }

    const setMarkup = (value) => {
        setLocalMarkup(value);
    }

    return (
        <Stack>
            <div style={{ color: "GrayText" }}>
                {required === true ? (
                    <b>{String(title)}</b>
                ) : (
                    String(title)
                )}
            </div>
            <Stack direction={'row'} alignItems="center">
                <TextField
                    style={{ width: "140px" }}
                    type={'number'}
                    margin={'none'}
                    required={required}
                    onKeyDown={(evt) => (/^[a-öA-Ö+?']?$/).test(evt.key) && evt.preventDefault()}
                    InputProps={{
                        inputProps: {
                            min: 0
                        },
                        endAdornment: 'kr',
                    }}
                    value={localVal}
                    onChange={(e) => { setPrice(e.target.value) }}
                />
                {option != null ? (
                    <div style={{ width: "413px", marginLeft: "5px" }}>
                        ({option})
                    </div>
                ) : (
                    <div style={{ width: "413px", marginLeft: "5px" }}>
                        {isExtra === true &&
                            <Button
                                size="small"
                                onClick={() => { isExtraFunc(false) }}
                                style={{ height: "20px", width: "30px", justifyContent: 'left' }}>
                                <HighlightOffIcon />
                            </Button>}
                    </div>
                )}

                {modMarkup === true || category === "other" ? (
                    <TextField
                        style={{ width: "80px", alignSelf: "flex-end" }}
                        label={'Påslag'}
                        type={'number'}
                        margin={'none'}
                        required={required}
                        onKeyDown={(evt) => (/^[a-öA-Ö+?']?$/).test(evt.key) && evt.preventDefault()}
                        InputProps={{
                            endAdornment: '%',
                            inputProps: {
                                max: 999, min: 0
                            },
                        }}
                        InputLabelProps={{ shrink: true }}
                        value={localMarkup}
                        onChange={(e) => { setMarkup(e.target.value) }}
                    />
                ) : (
                    <div style={{ paddingLeft: "45px", width: "35px", }}>
                        {markupVal} %
                    </div>
                )}

                <div style={{ marginLeft: '3px', width: "100px", textAlign: 'right' }}>
                    {numberWithSpaceSeparator(displayTotalPrice())} kr
                </div>
            </Stack>
        </Stack>
    )
}