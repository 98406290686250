import { Database, get, ref } from "firebase/database";
import { SESSION_PATH, } from "../../constants/firebasePaths";
import { SessionParamsEnum } from "../../enums/sessionParamsEnum";

/**
 * 
 * @param {Database} database 
 * @param {*} sessionParams 
 */
const getCurrentHighPosition = async function getCurrentHighPosition(database, sessionParams) {
    const sessionID = SessionParamsEnum.SessionID.getContextValue(sessionParams);

    let max = 0;

    if (sessionID != null && sessionID !== "") {
        const infoRef = ref(database, SESSION_PATH + sessionID + "/SessionShunts");
        const result = await get(infoRef);

        result.forEach((child) => {
            if (Number(child.val()) > max) {
                max = Number(child.val());
            }
        });
    }

    return max;
}

/**
 * 
 * @param {Database} database 
 * @param {*} sessionParams 
 * @param {number} currentPos
 */
const getCurrentNextPosition = async function getCurrentNextPosition(database, sessionParams, currentPos) {
    const sessionID = SessionParamsEnum.SessionID.getContextValue(sessionParams);
    let max = 0;

    if (currentPos != null) {
        let next = Number(currentPos) + 1;

        if (sessionID != null && sessionID !== "") {
            const infoRef = ref(database, SESSION_PATH + sessionID + "/SessionShunts");
            const result = await get(infoRef);
            const numArray = []
            result.forEach((child) => {
                numArray.push(Number(child.val()));

                if (Number(child.val()) > max) {
                    max = Number(child.val());
                }
            });

            numArray.sort();
            let found = false;
            while (found === false) {
                if (numArray.findIndex((obj) => obj === next) !== -1) {
                    //console.log("Number", next)
                    next = Number(next) + 1;
                }
                else {
                    found = true;
                }
            }
        }

        return next;
    }
    else{
        return max + 1;
    }
}


/**
 * 
 * @param {Database} database 
 * @param {string} sessionID 
 */
const getCurrentHighPositionSession = async function getCurrentHighPositionSession(database, sessionID) {
    let max = 0;

    if (sessionID != null && sessionID !== "") {
        const infoRef = ref(database, SESSION_PATH + sessionID + "/SessionShunts");
        const result = await get(infoRef);

        result.forEach((child) => {
            if (Number(child.val()) > max) {
                max = Number(child.val());
            }
        });
    }

    return max;
}


export { getCurrentHighPosition, getCurrentHighPositionSession, getCurrentNextPosition }