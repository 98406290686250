import { useEffect, useState } from 'react'
import { Paper, Stack, FormControlLabel, Checkbox, RadioGroup, Radio } from "@mui/material"
import { useDevbarParams } from "../../../contexts/devBar";
import { useAdjustParams, useCalcParams, useProductLineParams, useShuntParams } from '../../../contexts';
import { ProductLineEnum } from '../../../enums/productLineEnum';
import { CalcParamsEnum } from '../../../enums/calcParamsEnum';
import { ShuntParamsEnum } from '../../../enums/shuntParamsEnum';
import { createShuntNomenklaturString } from '../../../functions/ShuntCalculations/helpFunctions/createShuntNomenklaturString';
import { SessionParamsEnum } from '../../../enums/sessionParamsEnum';
import { useDatabase } from 'reactfire';
import { useSessionParams } from '../../../contexts/SessionParams';
import { PRODUCTLINE_FLEX, PRODUCTLINE_GREEN, PRODUCTLINE_SMART, PRODUCTLINE_STANDARD } from '../../../constants/productlineConstants';
import { DevbarDimSelect } from './components/devbarDimSelector';
import { DevbarSelectfield } from './components/devbarSelectfield';


export const Devbar = () => {
    const devParams = useDevbarParams();
    const productLineParams = useProductLineParams();
    const calcParams = useCalcParams();
    const shuntParams = useShuntParams();
    const adjustParams = useAdjustParams();
    const database = useDatabase();
    const sessionParams = useSessionParams();

    const getProductLine = () => {
        //console.log("Preval:", ProductLineEnum.ProductLine.getContextValue(productLineParams))
        if (ProductLineEnum.ProductLine.getContextValue(productLineParams) != null) {
            return ProductLineEnum.ProductLine.getContextValue(productLineParams);
        }
        else {
            return 'not set';
        }
    }

    const getDevParams = (type) => {
        if (devParams.state.val != undefined) {
            switch (type) {
                case "checkedFlex":
                    if (ProductLineEnum.ProductLine.getContextValue(productLineParams) === PRODUCTLINE_FLEX) {
                        return true;
                    }
                    else {
                        return false;
                    }
                case "checkedSmart":
                    if (ProductLineEnum.ProductLine.getContextValue(productLineParams) === PRODUCTLINE_SMART) {
                        return true;
                    }
                    else {
                        return false;
                    }
                case "checkedGreen":
                    if (devParams.state.val.checkedGreen != null) {
                        return devParams.state.val.checkedGreen;
                    }
                    else {
                        return "";
                    }
                case "checkedExtreme":
                    if (devParams.state.val.checkedExtreme != null) {
                        return devParams.state.val.checkedExtreme;
                    }
                    else {
                        return "";
                    }
                case "adjustValue":
                    if (devParams.state.val.adjustValue != null) {
                        return devParams.state.val.adjustValue;
                    }
                    else {
                        return "";
                    }
                case "checkedLatch":
                    if (ShuntParamsEnum.HasLatch.getContextValue(shuntParams) != null) {
                        if (Number(ShuntParamsEnum.HasLatch.getContextValue(shuntParams)) === 1) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                    else {
                        return false;
                    }
                case "checkedIsSabo":
                    if (ShuntParamsEnum.IsSabo.getContextValue(shuntParams) != null) {
                        if (Number(ShuntParamsEnum.IsSabo.getContextValue(shuntParams)) === 1) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                    else {
                        return false;
                    }

                default:
                    return "";
            }
        }
        else {
            return '';
        }
    }


    //const storage = useStorage();
    //const firestore = useFirestore();

    //const conTypeDoc = doc(firestore, '/ConnectionTypes/' + productLine.state.productLine.princip);
    //const firestoreRes = useFirestoreDocDataOnce(conTypeDoc);

    const [selectedProductline, setSelectedProductline] = useState(getProductLine());


    const [checkedFlex, setCheckedFlex] = useState(getDevParams('checkedFlex'));
    const [checkedSmart, setCheckedSmart] = useState(getDevParams('checkedSmart'));
    const [checkedGreen, setCheckedGreen] = useState(getDevParams('checkedGreen'));
    const [checkedExtreme, setCheckedExtreme] = useState(getDevParams('checkedExtreme'));


    const [checkedLatch, setCheckedLatch] = useState(getDevParams('checkedLatch'));
    const [isSabo, setIsSabo] = useState(getDevParams('checkedIsSabo'));

    const [adjustValue, setAdjustValue] = useState(getDevParams('adjustValue'));

    const setProductLine = (value) => {
        ProductLineEnum.ProductLine.setContextValue(productLineParams, value);
        setSelectedProductline(value);
    }

    const setDevParams = (value, type) => {
        switch (type) {

            case "checkedFlex":
                if (value === true) {
                    ProductLineEnum.ProductLine.setContextValue(productLineParams, PRODUCTLINE_FLEX);
                }
                else {
                    ProductLineEnum.ProductLine.setContextValue(productLineParams, PRODUCTLINE_STANDARD);
                }
                break;
            case "checkedSmart":
                if (value === true) {
                    ProductLineEnum.ProductLine.setContextValue(productLineParams, PRODUCTLINE_SMART);
                }
                else {
                    ProductLineEnum.ProductLine.setContextValue(productLineParams, PRODUCTLINE_STANDARD);
                }
                break;
            case "checkedGreen":
                if (value === true) {
                    ProductLineEnum.ProductLine.setContextValue(productLineParams, PRODUCTLINE_GREEN);
                }
                else {
                    ProductLineEnum.ProductLine.setContextValue(productLineParams, PRODUCTLINE_STANDARD);
                }

                setCheckedGreen(value);
                break;
            case "checkedExtreme":
                devParams.dispatch({ type: 'setCheckedExtreme', payload: value })
                setCheckedExtreme(value);
                break;
            case "adjustValue":
                devParams.dispatch({ type: 'setAdjustValue', payload: value })
                setAdjustValue(value);
                break;

            case "checkedLatch":
                ShuntParamsEnum.HasLatch.setContextValue(shuntParams, Number(value))
                setCheckedLatch(value);
                break;
            case "isSabo":
                ShuntParamsEnum.IsSabo.setContextValue(shuntParams, Number(value))
                setIsSabo(value);
                break;
            default:
                console.log("No matching param")
                break;
        }
    }

    useEffect(() => {
        if (ShuntParamsEnum.HasLatch.getContextValue(shuntParams) === 1) {
            setCheckedLatch(true);
        }
        else {
            setCheckedLatch(false);
        }

        createShuntNomenklaturString(productLineParams, adjustParams, sessionParams, shuntParams, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
    }, [ShuntParamsEnum.HasLatch.getContextValue(shuntParams), ShuntParamsEnum.IsSabo.getContextValue(shuntParams), ProductLineEnum.ProductLine.getContextValue(productLineParams)])


    return (
        <div style={{ marginBottom: 3 }}>
            {devParams.state.val.devMode === true &&
                <Paper elevation={0} style={{ width: '80%', justifyContent: 'center', padding: '10px 20px', marginTop: '10px', height: 'fit-content' }}>
                    <Stack>
                        <Stack direction={'row'}>
                            <DevbarSelectfield enumVal={ProductLineEnum.ProductPrincip} context={productLineParams} className="select-label-top" labelText={"Kopplingsprincip"} options={["SR-2", "SR-3", "SR-2-flex", "SR-2-smart", "PR-2", "PR-3", "VA-1",]} isDisabled={false} />

                            <DevbarSelectfield enumVal={CalcParamsEnum.ShuntType} context={calcParams} className="select-label-top" labelText={"Värme / kyla"} options={["Värme", "Kyla",]} isDisabled={false} />

                            <DevbarSelectfield enumVal={ShuntParamsEnum.NumBV} context={shuntParams} className="select-label-top" labelText={"Antal Backventiler"} options={["Ingen", "1 Bv", "2 Bv"]} isDisabled={false} />

                            <DevbarSelectfield enumVal={ShuntParamsEnum.NumIV} context={shuntParams} className="select-label-top" labelText={"Antal Injust.ventiler"} options={["Ingen", "1 Iv", "2 Iv"]} isDisabled={false} />

                            <DevbarDimSelect />

                            <div style={{ marginTop: '15px', marginRight: '5px' }}>
                                <FormControlLabel control={<Checkbox checked={checkedLatch}
                                    onChange={(event) => { setDevParams(event.target.checked, "checkedLatch") }}
                                />} label="Kyl/Värmespärr" />
                            </div>

                            <div style={{ marginTop: '15px', marginRight: '10px' }}>
                                <FormControlLabel control={<Checkbox checked={isSabo}
                                    onChange={(event) => { setDevParams(event.target.checked, "isSabo") }}
                                />} label="Sabo" />
                            </div>

                        </Stack>
                        <Stack direction={'row'}>
                            <div style={{ marginTop: '10px', paddingLeft: '10px', marginRight: '10px' }}>
                                {/*<FormControlLabel control={<Checkbox checked={checkedExtreme}
                            onChange={(event) => { setDevParams(event.target.checked, "checkedExtreme") }}
                        />} label="Extra allt" />*/}

                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={selectedProductline}
                                    onChange={(event) => { setProductLine(event.target.value) }}
                                >

                                    <FormControlLabel value={PRODUCTLINE_STANDARD} control={<Radio />} label="Standard" />
                                    <FormControlLabel value={PRODUCTLINE_GREEN} control={<Radio />} label="Green" />
                                    <FormControlLabel value={PRODUCTLINE_FLEX} control={<Radio />} label="Flex" />
                                    <FormControlLabel value={PRODUCTLINE_SMART} control={<Radio />} label="Smart" />
                                </RadioGroup>
                            </div>
                        </Stack>
                    </Stack>
                </Paper>
            }
        </div>
    )
}
