import { ref, set } from "firebase/database";
import { SESSION_SHUNT_PATH } from "../../constants/firebasePaths";
import { SessionParamsEnum } from "../../enums/sessionParamsEnum";
import { ProductLineEnum } from "../../enums/productLineEnum"
import { PRODUCTLINE_GREEN } from "../../constants/productlineConstants";
import { PROD_GREEN_LEVEL } from "../../constants/keys/productLineKeys";

const saveProductLineData = function saveProductLineData(database, sessionParams, productLineParams, shuntID = null) {
    if (shuntID === null){
        shuntID = SessionParamsEnum.CurrentShunt.getContextValue(sessionParams);
    }
    
    const productLineRef = ref(database, SESSION_SHUNT_PATH + "/" + shuntID + "/PLData");

    const snapData = {
        prodLine: ProductLineEnum.ProductLine.getContextValue(productLineParams),
        princip: ProductLineEnum.ProductPrincip.getContextValue(productLineParams),
    }

    if (ProductLineEnum.ProductLine.getContextValue(productLineParams) === PRODUCTLINE_GREEN && ProductLineEnum.ProductGreenLevel.getContextValue(productLineParams)){
        snapData[PROD_GREEN_LEVEL] = ProductLineEnum.ProductGreenLevel.getContextValue(productLineParams);
    }

    const promises = [];
    if (ProductLineEnum.ProductLine.getContextValue(productLineParams) != null && ProductLineEnum.ProductPrincip.getContextValue(productLineParams) != null) {
        if (SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true){
            promises.push(set(productLineRef, snapData));
        }
        
    }

    return Promise.all(promises);
}

const saveProductLineDataInputs = function saveProductLineDataInputs(database, sessionParams, prodLine, princip, prodGreenLvl = null, shuntID = null) {
    if (shuntID === null){
        shuntID = SessionParamsEnum.CurrentShunt.getContextValue(sessionParams);
    }
    
    const productLineRef = ref(database, SESSION_SHUNT_PATH + "/" + shuntID + "/PLData");

    const snapData = {
        prodLine: prodLine,
        princip: princip,
    }

    if (prodLine === PRODUCTLINE_GREEN && prodGreenLvl != null){
        snapData[PROD_GREEN_LEVEL] = prodGreenLvl;
    }

    const promises = [];
    if (prodLine != null && princip != null) {
        if (SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true){
            promises.push(set(productLineRef, snapData));
        }
        
    }

    return Promise.all(promises);
}

export { saveProductLineData, saveProductLineDataInputs } 