import { Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import { InspectPageComponentData } from "../inspectPage/components/inspectPageComponentData";
import { getShuntData } from "../../../firebase/getShuntData";
import { useDatabase } from "reactfire";
import { useEffect, useState } from "react";
import { SidebarImageShuntData } from "./components/imageDisplayShuntData";
import { InspectPageHeader, InspectPageTechData, InspectPageMixingVesselData } from "../inspectPage/components";
import { InspectPageCustomShuntData } from "../inspectPage/components/inspectPageCustomShuntData";
import { InspectPageCustomTechData } from "../inspectPage/components/inspectPageCustomTechData";
import { useGetShuntData } from "../../../hooks/useGetShuntData";
import { InspectPageMixingVesselRow } from "../inspectPage/components/inspectPageMixingVesselRow";
import { InspectPageViewComments } from "../inspectPage/components/inspectPageViewComments";


export const SpecOverviewShunt = ({ shuntID }) => {
    const { shuntData, loading } = useGetShuntData(shuntID)

    return (
        <Paper style={{ marginBottom: "10px", }}>
            <Grid container>
                <Grid item xs={7.6}>
                    {shuntData?.Baseinfo?.isCustom === true ? (
                        <div>
                            <Paper elevation={0} style={{ width: '475px', justifyContent: 'center', padding: '10px 20px', marginTop: '10px', height: 'fit-content' }}>
                                <InspectPageHeader useCurrent={false} shuntData={shuntData} />
                                <Divider />
                                <InspectPageCustomShuntData useCurrent={false} shuntData={shuntData} />
                                <Divider />
                                <InspectPageCustomTechData shuntData={shuntData} />
                                <InspectPageViewComments shuntData={shuntData} />
                            </Paper>
                        </div>
                    ) : (
                        <div>
                            {shuntData?.ShuntData?.isAddon === true ? (
                                <Paper elevation={0} style={{ width: '475px', justifyContent: 'center', padding: '10px 20px', marginTop: '10px', height: 'fit-content' }}>
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Stack direction={'row'} spacing={1} justifyContent={"flex-start"} alignItems={"center"} style={{ paddingBottom: "5px" }}>
                                            <Typography component={'h3'}>
                                                <strong><u>Pos {shuntData?.Baseinfo?.position}, Glykolblandningskärl</u></strong>
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Divider />
                                    <InspectPageMixingVesselRow objData={shuntData} component="mixingVessel" />
                                    <InspectPageMixingVesselRow objData={shuntData} component="pump" />
                                    <InspectPageMixingVesselRow objData={shuntData} component="connHose" />
                                    <InspectPageMixingVesselRow objData={shuntData} component="hole" />
                                    <InspectPageViewComments shuntData={shuntData} />
                                </Paper>
                            ) : (
                                <Paper elevation={0} style={{ width: '475px', justifyContent: 'center', padding: '10px 20px', marginTop: '10px', height: 'fit-content' }}>
                                    <InspectPageHeader useCurrent={false} shuntData={shuntData} />
                                    <Divider />
                                    <InspectPageCustomShuntData useCurrent={false} shuntData={shuntData} />
                                    <Divider />
                                    <InspectPageCustomTechData shuntData={shuntData} />
                                    {/*<InspectPageMixingVesselData />*/}
                                    <InspectPageViewComments shuntData={shuntData} />
                                </Paper>
                            )}

                        </div>
                    )}

                </Grid>
                <Grid item xs={4}>
                    <SidebarImageShuntData shuntData={shuntData} />
                </Grid>

            </Grid>
        </Paper>
    )
}