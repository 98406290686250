import { Button, styled, TableCell, TableRow } from "@mui/material";
import { ref } from "firebase/database";
import { useEffect, useState } from "react";
import { useDatabase, useDatabaseObjectData } from "reactfire";
import { SESSION_SHUNT_PATH } from "../../../../constants/firebasePaths";
import CommentIcon from '@mui/icons-material/Comment';
import { CommentDialog } from '../commentDialog';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

export const FirebaseRemarksRow = ({ shuntID, editMode }) => {
    const database = useDatabase();

    const shuntRef = ref(database, SESSION_SHUNT_PATH + shuntID);

    const response = useDatabaseObjectData(shuntRef);
    // eslint-disable-next-line no-unused-vars
    const { status, data } = response;

    // eslint-disable-next-line no-unused-vars
    const [fieldValue, setFieldValue] = useState({})

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    useEffect(() => {
        if (data != null) {
            setFieldValue(data);
        }
    }, [data]);


    const getPos = () => {
        if (data?.Baseinfo?.position != null) {
            return "Pos. " + String(data.Baseinfo.position);
        }
        else {
            return "";
        }
    }

    const getBeteckning = () => {
        if (data?.Baseinfo?.designation != null) {
            return String(data.Baseinfo.designation);
        }
        else {
            return "";
        }
    }

    // const getIllustration = () => {
    //     if (data != undefined && data.ShuntData != undefined && data.ShuntData.shuntString != undefined) {
    //         return String(data.ShuntData.shuntString);
    //     }

    //     else {
    //         return "";
    //     }
    // }

    const [openComment, setOpenComment] = useState(false);

    const handleOpenComment = () => {
        setOpenComment(true);
    }

    const handleCloseComment = () => {
        setOpenComment(false);
    }

    const hasComments = () => {
        return data?.CustomData?.lastComment != null;
    }

    const getLastModified = () => {
        if (hasComments()) {
            const date = new Date(data.CustomData.lastComment)
            return date.toLocaleString('se-SV', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit', hour: '2-digit', minute: '2-digit'
            });
        }
        else {
            return "-";
        }
    }


    return (
        <>
            <StyledTableRow key={shuntID}>

                <TableCell>{getPos()} ({getBeteckning()})</TableCell>
                <TableCell>{getLastModified()}</TableCell>
                <TableCell>
                    <Button className="comment-btn" onClick={handleOpenComment}>
                        {hasComments() ? (
                            <EditIcon fontSize="small" />
                        ) : (
                            <AddIcon fontSize="small" />
                        )}
                    </Button>
                </TableCell>

            </StyledTableRow>
            <CommentDialog handleOnDecline={handleCloseComment} shuntID={shuntID} open={openComment} type={null} />
        </>


    );
}