import { createContext, useReducer, useContext } from "react";
import { ref, remove, set } from "firebase/database";

import { SESSION_SHUNT_PATH, SHUNT_WIZARDDATA_APPLICATION_INTENT_PATH, SHUNT_WIZARDDATA_APPLICATION_PATH, SHUNT_WIZARDDATA_APPLICATION_SIZE_PATH, SHUNT_WIZARDDATA_AVAIL_DRIVING_PRESSURE_PATH, SHUNT_WIZARDDATA_CERTICIFACTIONS_PATH, SHUNT_WIZARDDATA_ENERGY_MEASUREMENT_PATH, SHUNT_WIZARDDATA_HAS_MAIN_PUMP_PATH, SHUNT_WIZARDDATA_LIMITED_SPACE_PATH, SHUNT_WIZARDDATA_ONLY_DIMENSIONABLE_PATH, SHUNT_WIZARDDATA_PRIORITIES_PATH, SHUNT_WIZARDDATA_SECONDARY_FLOW_PATH, SHUNT_WIZARDDATA_SECONDARY_FLOW_SPEC_VAL_PATH, SHUNT_WIZARDDATA_SOURCE_FLOW_PATH, SHUNT_WIZARDDATA_SOURCE_PATH, SHUNT_WIZARDDATA_SPECIFIC_AVAIL_DRIVING_PRESSURE_PATH, SHUNT_WIZARDDATA_STAINLESS_PATH, SHUNT_WIZARDDATA_SYSTEM_FLUID_MAIN_PATH, SHUNT_WIZARDDATA_SYSTEM_FLUID_PRIMARY_MIX_RATIO_PATH, SHUNT_WIZARDDATA_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_PATH, SHUNT_WIZARDDATA_SYSTEM_FLUID_PRIMARY_PATH, SHUNT_WIZARDDATA_SYSTEM_FLUID_SECONDARY_MIX_RATIO_PATH, SHUNT_WIZARDDATA_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_PATH, SHUNT_WIZARDDATA_SYSTEM_FLUID_SECONDARY_PATH, SHUNT_WIZARDDATA_VARIABLE_AVAIL_DRIVING_PRESSURE_PATH } from "../constants/firebasePaths";
import { WIZARD_PARAMS } from "../constants/sessionStoragePaths";
import { WIZARD_APPLICATION_INTENT_KEY, WIZARD_APPLICATION_KEY, WIZARD_APPLICATION_SIZE_KEY, WIZARD_AVAIL_DRIVING_PRESSURE_KEY, WIZARD_CERTICIFACTIONS_KEY, WIZARD_CLEAR_SYSTEM_FLUID_MAIN, WIZARD_DYNAMIC_CONTROL_VALVE_KEY, WIZARD_ENERGY_MEASUREMENT_KEY, WIZARD_HAS_MAIN_PUMP_KEY, WIZARD_LIMITED_SPACE_KEY, WIZARD_ONLY_DIMENSIONABLE_KEY, WIZARD_PRIORITIES_KEY, WIZARD_SECONDARY_FLOW_KEY, WIZARD_SECONDARY_FLOW_SPEC_VAL_KEY, WIZARD_SET_APPLICATION, WIZARD_SET_APPLICATION_INTENT, WIZARD_SET_APPLICATION_SIZE, WIZARD_SET_AVAIL_DRIVING_PRESSURE, WIZARD_SET_CERTICIFACTIONS, WIZARD_SET_DYNAMIC_CONTROL_VALVE, WIZARD_SET_ENERGY_MEASUREMENT, WIZARD_SET_HAS_MAIN_PUMP, WIZARD_SET_LIMITED_SPACE, WIZARD_SET_ONLY_DIMENSIONABLE, WIZARD_SET_PRIORITIES, WIZARD_SET_SECONDARY_FLOW, WIZARD_SET_SECONDARY_FLOW_SPEC_VAL, WIZARD_SET_SOURCE, WIZARD_SET_SOURCE_FLOW, WIZARD_SET_SPECIFIC_AVAIL_DRIVING_PRESSURE, WIZARD_SET_STAINLESS, WIZARD_SET_SYSTEM_FLUID_MAIN, WIZARD_SET_SYSTEM_FLUID_PRIMARY, WIZARD_SET_SYSTEM_FLUID_PRIMARY_MIX_RATIO, WIZARD_SET_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN, WIZARD_SET_SYSTEM_FLUID_SECONDARY, WIZARD_SET_SYSTEM_FLUID_SECONDARY_MIX_RATIO, WIZARD_SET_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN, WIZARD_SET_VARIABLE_AVAIL_DRIVING_PRESSURE, WIZARD_SOURCE_FLOW_KEY, WIZARD_SOURCE_KEY, WIZARD_SPECIFIC_AVAIL_DRIVING_PRESSURE_KEY, WIZARD_STAINLESS_KEY, WIZARD_SYSTEM_FLUID_MAIN_KEY, WIZARD_SYSTEM_FLUID_PRIMARY_KEY, WIZARD_SYSTEM_FLUID_PRIMARY_MIX_RATIO_KEY, WIZARD_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_KEY, WIZARD_SYSTEM_FLUID_SECONDARY_KEY, WIZARD_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY, WIZARD_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_KEY, WIZARD_VARIABLE_AVAIL_DRIVING_PRESSURE_KEY } from "../constants/keys/wizardKeys";

const WizardParams = createContext(undefined);

function wizardParamsReducer(state, action) {
    switch (action.type) {
        case WIZARD_SET_SOURCE: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_SOURCE_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[WIZARD_SOURCE_KEY] = action.payload;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case WIZARD_SET_SOURCE_FLOW: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_SOURCE_FLOW_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[WIZARD_SOURCE_FLOW_KEY] = action.payload;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case WIZARD_SET_APPLICATION: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_APPLICATION_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[WIZARD_APPLICATION_KEY] = action.payload;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case WIZARD_SET_APPLICATION_INTENT: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_APPLICATION_INTENT_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[WIZARD_APPLICATION_INTENT_KEY] = action.payload;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case WIZARD_SET_APPLICATION_SIZE: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_APPLICATION_SIZE_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[WIZARD_APPLICATION_SIZE_KEY] = action.payload;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case WIZARD_SET_ONLY_DIMENSIONABLE: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_ONLY_DIMENSIONABLE_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[WIZARD_ONLY_DIMENSIONABLE_KEY] = action.payload;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case WIZARD_SET_AVAIL_DRIVING_PRESSURE: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_AVAIL_DRIVING_PRESSURE_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[WIZARD_AVAIL_DRIVING_PRESSURE_KEY] = action.payload;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case WIZARD_SET_SECONDARY_FLOW: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_SECONDARY_FLOW_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[WIZARD_SECONDARY_FLOW_KEY] = action.payload;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case WIZARD_SET_SECONDARY_FLOW_SPEC_VAL: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_SECONDARY_FLOW_SPEC_VAL_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[WIZARD_SECONDARY_FLOW_SPEC_VAL_KEY] = action.payload;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case WIZARD_SET_VARIABLE_AVAIL_DRIVING_PRESSURE: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_VARIABLE_AVAIL_DRIVING_PRESSURE_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[WIZARD_VARIABLE_AVAIL_DRIVING_PRESSURE_KEY] = action.payload;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case WIZARD_SET_SPECIFIC_AVAIL_DRIVING_PRESSURE: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_SPECIFIC_AVAIL_DRIVING_PRESSURE_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[WIZARD_SPECIFIC_AVAIL_DRIVING_PRESSURE_KEY] = action.payload;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case WIZARD_SET_HAS_MAIN_PUMP: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_HAS_MAIN_PUMP_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[WIZARD_HAS_MAIN_PUMP_KEY] = action.payload;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case WIZARD_SET_CERTICIFACTIONS: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_CERTICIFACTIONS_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[WIZARD_CERTICIFACTIONS_KEY] = action.payload;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case WIZARD_SET_SYSTEM_FLUID_MAIN: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_SYSTEM_FLUID_MAIN_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[WIZARD_SYSTEM_FLUID_MAIN_KEY] = action.payload;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case WIZARD_CLEAR_SYSTEM_FLUID_MAIN: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_SYSTEM_FLUID_MAIN_PATH);
                remove(fieldRef);
            }

            delete data.systemFluidMain;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case WIZARD_SET_SYSTEM_FLUID_PRIMARY: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_SYSTEM_FLUID_PRIMARY_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[WIZARD_SYSTEM_FLUID_PRIMARY_KEY] = action.payload;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case WIZARD_SET_SYSTEM_FLUID_PRIMARY_MIX_RATIO: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_SYSTEM_FLUID_PRIMARY_MIX_RATIO_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[WIZARD_SYSTEM_FLUID_PRIMARY_MIX_RATIO_KEY] = action.payload;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case WIZARD_SET_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[WIZARD_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_KEY] = action.payload;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case WIZARD_SET_SYSTEM_FLUID_SECONDARY: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_SYSTEM_FLUID_SECONDARY_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[WIZARD_SYSTEM_FLUID_SECONDARY_KEY] = action.payload;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case WIZARD_SET_SYSTEM_FLUID_SECONDARY_MIX_RATIO: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_SYSTEM_FLUID_SECONDARY_MIX_RATIO_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[WIZARD_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY] = action.payload;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case WIZARD_SET_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[WIZARD_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_KEY] = action.payload;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case WIZARD_SET_LIMITED_SPACE: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_LIMITED_SPACE_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[WIZARD_LIMITED_SPACE_KEY] = action.payload;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case WIZARD_SET_STAINLESS: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_STAINLESS_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[WIZARD_STAINLESS_KEY] = action.payload;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case WIZARD_SET_DYNAMIC_CONTROL_VALVE: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_STAINLESS_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[WIZARD_DYNAMIC_CONTROL_VALVE_KEY] = action.payload;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case WIZARD_SET_ENERGY_MEASUREMENT: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_ENERGY_MEASUREMENT_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[WIZARD_ENERGY_MEASUREMENT_KEY] = action.payload;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case WIZARD_SET_PRIORITIES: {
            let data = {};
            if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_WIZARDDATA_PRIORITIES_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[WIZARD_PRIORITIES_KEY] = action.payload;
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setDataFromSnapShot': {
            let data = {};

            data[WIZARD_SOURCE_KEY] = action.payload.child(WIZARD_SOURCE_KEY).val() || undefined;
            data[WIZARD_SOURCE_FLOW_KEY] = action.payload.child(WIZARD_SOURCE_FLOW_KEY).val() || undefined;
            data[WIZARD_APPLICATION_KEY] = action.payload.child(WIZARD_APPLICATION_KEY).val() || undefined;
            data[WIZARD_APPLICATION_INTENT_KEY] = action.payload.child(WIZARD_APPLICATION_INTENT_KEY).val() || undefined;
            data[WIZARD_APPLICATION_SIZE_KEY] = action.payload.child(WIZARD_APPLICATION_SIZE_KEY).val() || undefined;
            data[WIZARD_ONLY_DIMENSIONABLE_KEY] = action.payload.child(WIZARD_ONLY_DIMENSIONABLE_KEY).val() || undefined;
            data[WIZARD_SECONDARY_FLOW_KEY] = action.payload.child(WIZARD_SECONDARY_FLOW_KEY).val() || undefined;
            data[WIZARD_SECONDARY_FLOW_SPEC_VAL_KEY] = action.payload.child(WIZARD_SECONDARY_FLOW_SPEC_VAL_KEY).val() || undefined;
            data[WIZARD_AVAIL_DRIVING_PRESSURE_KEY] = action.payload.child(WIZARD_AVAIL_DRIVING_PRESSURE_KEY).val() || undefined;
            data[WIZARD_VARIABLE_AVAIL_DRIVING_PRESSURE_KEY] = action.payload.child(WIZARD_VARIABLE_AVAIL_DRIVING_PRESSURE_KEY).val() || undefined;
            data[WIZARD_SPECIFIC_AVAIL_DRIVING_PRESSURE_KEY] = action.payload.child(WIZARD_SPECIFIC_AVAIL_DRIVING_PRESSURE_KEY).val() || undefined;
            data[WIZARD_HAS_MAIN_PUMP_KEY] = action.payload.child(WIZARD_HAS_MAIN_PUMP_KEY).val() || undefined;
            data[WIZARD_CERTICIFACTIONS_KEY] = action.payload.child(WIZARD_CERTICIFACTIONS_KEY).val() || undefined;
            data[WIZARD_SYSTEM_FLUID_MAIN_KEY] = action.payload.child(WIZARD_SYSTEM_FLUID_MAIN_KEY).val() || undefined;
            data[WIZARD_SYSTEM_FLUID_PRIMARY_KEY] = action.payload.child(WIZARD_SYSTEM_FLUID_PRIMARY_KEY).val() || undefined;
            data[WIZARD_SYSTEM_FLUID_PRIMARY_MIX_RATIO_KEY] = action.payload.child(WIZARD_SYSTEM_FLUID_PRIMARY_MIX_RATIO_KEY).val() || undefined;
            data[WIZARD_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_KEY] = action.payload.child(WIZARD_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_KEY).val() || undefined;
            data[WIZARD_SYSTEM_FLUID_SECONDARY_KEY] = action.payload.child(WIZARD_SYSTEM_FLUID_SECONDARY_KEY).val() || undefined;
            data[WIZARD_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY] = action.payload.child(WIZARD_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY).val() || undefined;
            data[WIZARD_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_KEY] = action.payload.child(WIZARD_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_KEY).val() || undefined;
            data[WIZARD_LIMITED_SPACE_KEY] = action.payload.child(WIZARD_LIMITED_SPACE_KEY).val() || undefined;
            data[WIZARD_DYNAMIC_CONTROL_VALVE_KEY] = action.payload.child(WIZARD_DYNAMIC_CONTROL_VALVE_KEY).val() || undefined;
            data[WIZARD_SET_PRIORITIES] = action.payload.child(WIZARD_SET_PRIORITIES).val() || undefined;
            data[WIZARD_ENERGY_MEASUREMENT_KEY] = action.payload.child(WIZARD_ENERGY_MEASUREMENT_KEY).val() || undefined;
            //console.log("Loading Data:", data);
            sessionStorage.setItem(WIZARD_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'clear': {
            sessionStorage.removeItem(WIZARD_PARAMS);
            return { val: {} }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function WizardProvider({ children }) {
    let obj = {};
    if (sessionStorage.getItem(WIZARD_PARAMS) != null) {
        obj = JSON.parse(sessionStorage.getItem(WIZARD_PARAMS));
    }


    const [state, dispatch] = useReducer(wizardParamsReducer, { val: obj })
    const value = { state, dispatch }

    return (
        <WizardParams.Provider value={value}>
            {children}
        </WizardParams.Provider>
    );
}

function useWizardParams() {
    const context = useContext(WizardParams)
    if (context === undefined) {
        throw new Error('useWizardParams must be used within a WizardProvider')
    }
    return context
}


export { WizardProvider, useWizardParams };