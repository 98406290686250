import { useEffect, useState } from "react";
import { useAdjustParams, useProductLineParams, useCalcParams, useShuntParams, useDevbarParams, useSessionParams } from "../../../../contexts";
import { SessionParamsEnum, ShuntParamsEnum } from "../../../../enums";
import { useGetMagicloudIDs } from "../../../../hooks/useGetMagicloudIDs";
import { useGetShuntData } from "../../../../hooks/useGetShuntData";
import { getProdID } from "../../../../functions/MagicadFunctions/getProdCode";
import { Box } from "@mui/material";
import { MagicloudCadDownloadButtons } from "../../magicloud/cadDownloadButtons";

export const InspectPageMagicloudComponent = () => {
    const adjustParams = useAdjustParams();
    const productLineParams = useProductLineParams();
    const calcParams = useCalcParams();
    const shuntParams = useShuntParams();
    const devParams = useDevbarParams();
    const sessionParams = useSessionParams();
    const { magicloudIDs, loading } = useGetMagicloudIDs();
    const { shuntData } = useGetShuntData(SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));

    const [productID, setProductID] = useState("");
    const shuntNomenklaturString = ShuntParamsEnum.ShuntNomenklaturString;

    useEffect(() => {
        if (loading === false) {
            if (shuntData?.ShuntData?.isAddon === true) {
                let prodID = "";

                if (shuntData?.ShuntData?.addonType === 'mixingVessel' && shuntData?.ShuntData?.AddonData?.size != null) {
                    let size = String(shuntData?.ShuntData?.AddonData?.size).split(' ')[0];
                    prodID = 'PRE-BW-' + size;
                }
    
                let magicadUID = magicloudIDs[prodID];
                setProductID(magicadUID);
            }
            else {
                let prodID = getProdID(true, calcParams, shuntParams, adjustParams, productLineParams, shuntData);
                let magicadUID = magicloudIDs[prodID];
                //console.log("Inspect: ", prodID, " => ", magicadUID)
                setProductID(magicadUID);
            }

        }
    }, [shuntNomenklaturString.getContextValue(shuntParams), magicloudIDs, loading])

    return (
        <Box>
            <Box sx={{ paddingLeft: '20px' }}>
                <MagicloudCadDownloadButtons productId={productID} />
            </Box>
        </Box>
    )
}