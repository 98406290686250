import { Autocomplete, Stack, TextField } from "@mui/material"
import { useState } from "react";
import { PUMP_SPECIFIC_REQUEST, PUMP_SYSTEMFLUID } from "../../../../constants/keys/pumpKeys";
import { PRODUCTLINE_GREEN } from "../../../../constants/productlineConstants";
import { PUMP_GREEN_II_DEFAULT } from "../../../../constants/pumpOptions";
import { useProductLineParams } from "../../../../contexts";
import { usePumpParams } from "../../../../contexts/PumpParams";
import { useSessionParams } from "../../../../contexts/SessionParams";
import { ProductLineEnum } from "../../../../enums";
import { PumpParamEnum } from "../../../../enums/pumpParamsEnum";
import { SessionParamsEnum } from "../../../../enums/sessionParamsEnum";
import { WizardParamEnum } from "../../../../enums/wizardParamsEnum";
import { useWizardParams } from "../../../../contexts/wizardParams";
import { SYSTEM_FLUID } from "../../../../constants/wizardConstants";
import { resetMaxStepFunc } from "../../../../functions/HelperFunctions/resetMaxStep";
import { useDatabase } from "reactfire";

export const PumpTextfield = ({ id = '', labelText, isDisabled = false, className = "" }) => {
    const database = useDatabase();
    const pumpParams = usePumpParams();
    const sessionParams = useSessionParams();
    const productLine = useProductLineParams();
    const wizardParams = useWizardParams();

    /**
     * 
     * @returns {Array} data
     */
    const getSavedOptions = () => {
        let data = [];
        if (id === PUMP_SPECIFIC_REQUEST) {
            if (localStorage.getItem('pumpSearches') != null) {
                data = JSON.parse(localStorage.getItem('pumpSearches'));
            }
        }
        else if (id === PUMP_SYSTEMFLUID) {
            if (localStorage.getItem('fluidSearches') != null) {
                data = JSON.parse(localStorage.getItem('fluidSearches'));
            }
        }

        return data;
    }

    const [options, setOptions] = useState(getSavedOptions());

    const getPumpParams = () => {
        switch (id) {
            case PUMP_SPECIFIC_REQUEST:
                if (PumpParamEnum.SpecificPumpRequest.getContextValue(pumpParams) != null) {
                    const pumpVal = PumpParamEnum.SpecificPumpRequest.getContextValue(pumpParams);
                    if (options.findIndex(obj => obj === pumpVal) === -1) {
                        let local = options;
                        local.push(pumpVal);
                        localStorage.setItem("pumpSearches", JSON.stringify(local));
                    }
                    return pumpVal;
                }
                else {
                    if (ProductLineEnum.ProductLine.getContextValue(productLine) === PRODUCTLINE_GREEN && ProductLineEnum.ProductGreenLevel.getContextValue(productLine) === 2) {
                        if (options.findIndex(obj => obj === PUMP_GREEN_II_DEFAULT) === -1) {
                            let local = options;
                            local.push(PUMP_GREEN_II_DEFAULT);
                            localStorage.setItem("pumpSearches", JSON.stringify(local));
                        }
                        return PUMP_GREEN_II_DEFAULT;
                    }
                    else {
                        return '';
                    }
                }
            case PUMP_SYSTEMFLUID:
                if (PumpParamEnum.SystemFluid.getContextValue(pumpParams) != null) {
                    const fluidVal = PumpParamEnum.SystemFluid.getContextValue(pumpParams);

                    if (options.findIndex(obj => obj === fluidVal) === -1) {
                        let local = options;
                        local.push(fluidVal);
                        localStorage.setItem("fluidSearches", JSON.stringify(local));
                    }
                    return fluidVal;
                }
                else if (WizardParamEnum.SystemFluidMain.getContextValue(wizardParams) != null && WizardParamEnum.SystemFluidMain.getContextValue(wizardParams) !== "") {
                    const fluidValOption = SYSTEM_FLUID[WizardParamEnum.SystemFluidMain.getContextValue(wizardParams)];

                    if (fluidValOption != null && fluidValOption !== SYSTEM_FLUID.unknown) {
                        let fluidVal = "";
                        if (fluidValOption === SYSTEM_FLUID.different) {
                            console.log("Different");

                            if (WizardParamEnum.SystemFluidPrimary.getContextValue(wizardParams) != null && WizardParamEnum.SystemFluidPrimary.getContextValue(wizardParams) !== ""){
                                const fluidValOptionPrimary = SYSTEM_FLUID[WizardParamEnum.SystemFluidMain.getContextValue(wizardParams)];
                                if (fluidValOptionPrimary != null){
                                    if (fluidValOptionPrimary === SYSTEM_FLUID.unknown){
                                        fluidVal = "Primärt: Ej Fastställt / "
                                    }
                                    else{
                                        fluidVal = fluidValOptionPrimary.label + " / ";
                                    }
                                }
                            }

                            if (WizardParamEnum.SystemFluidSecondary.getContextValue(wizardParams) != null && WizardParamEnum.SystemFluidSecondary.getContextValue(wizardParams) !== ""){
                                const fluidValOptionSecondary = SYSTEM_FLUID[WizardParamEnum.SystemFluidSecondary.getContextValue(wizardParams)];
                                if (fluidValOptionSecondary != null){
                                    if (fluidValOptionSecondary === SYSTEM_FLUID.unknown){
                                        fluidVal = "Sekundärt: Ej Fastställt"
                                    }
                                    else{
                                        fluidVal = fluidValOptionSecondary.label;
                                    }
                                }
                            }
                        }
                        else {
                            fluidVal = fluidValOption.label;
                        }

                        if (options.findIndex(obj => obj === fluidVal) === -1 && fluidVal !== '') {
                            let local = options;
                            local.push(fluidVal);
                            localStorage.setItem("fluidSearches", JSON.stringify(local));
                        }
                        return fluidVal;
                    }
                    else{
                        return '';
                    }
                }
                else {
                    return '';
                }

            default:
                return '';
        }
    }

    const [pumpvalue, setValue] = useState(getPumpParams());


    const setPumpParams = (value, setContext) => {
        resetMaxStepFunc(sessionParams, database);
        /*
        if (SessionParamsEnum.MaxStep.getContextValue(sessionParams) !== SessionParamsEnum.CurrentStep.getContextValue(sessionParams)) {
            SessionParamsEnum.MaxStep.setContextValue(sessionParams, SessionParamsEnum.CurrentStep.getContextValue(sessionParams));
        }*/
        //console.log("Text:", value)

        switch (id) {
            case PUMP_SPECIFIC_REQUEST:
                if (setContext === true) {
                    if (value != null && value !== "" && options.findIndex(obj => obj === value) === -1) {
                        let local = options;
                        local.push(value);
                        localStorage.setItem("pumpSearches", JSON.stringify(local));
                        setOptions(local);
                    }

                    PumpParamEnum.SpecificPumpRequest.setContextValue(pumpParams, value);
                }

                setValue(value)
                break;

            case PUMP_SYSTEMFLUID:
                if (setContext === true) {
                    if (value != null && value !== "" && options.findIndex(obj => obj === value) === -1) {
                        let local = options;
                        local.push(value);
                        localStorage.setItem("fluidSearches", JSON.stringify(local));
                        setOptions(local);
                    }

                    PumpParamEnum.SystemFluid.setContextValue(pumpParams, value);
                }

                setValue(value)
                break;
            default:
                break;
        }
    }

    return (
        <Stack direction={"column"} justifyContent="space-evenly">
            {/* <div style={{ marginTop: "5px", }}>
                {labelText}:
            </div> */}
            <>
                <Autocomplete
                    freeSolo
                    options={options}
                    disabled={isDisabled}
                    defaultValue={options.find(obj => obj === pumpvalue)}
                    onChange={(event, value) => { setPumpParams(value, true) }}

                    renderInput={(params) => <TextField
                        {...params}
                        style={{ width: "95%", height: "10px", marginTop: '5px' }}
                        size={"small"}
                        value={pumpvalue}
                        margin="normal"
                        onBlur={(event) => { setPumpParams(event.target.value, true) }}
                        fullWidth
                        variant="outlined"
                        disabled={isDisabled}
                        label={labelText}
                    />}
                />
                {/* 
                <TextField
                    style={{ width: "50%", height: "10px", marginTop: '5px' }}
                    size={"small"}
                    value={value || ''}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    autoComplete="on"
                    onChange={(event) => { setPumpParams(event.target.value, false) }}
                    onBlur={(event) => { setPumpParams(event.target.value, true) }}
                    disabled={isDisabled}
                    label={labelText}
                />*/}
            </>
        </Stack>
    );
}