const { ref, get } = require("firebase/database");
const { SESSION_PATH } = require("../constants/firebasePaths");
const { FACILITY_KEY, PROJNR_KEY, SESSION_SHUNTS_KEY } = require("../constants/keys/sessionParamsKeys");
const { SessionParamsEnum } = require("../enums");

export const loadSessionApi = async (database, sessionID, sessionParams) => {
    const fieldRef = ref(database, SESSION_PATH + sessionID);

    const sessionData = await get(fieldRef);

    if (sessionData.exists()) {
        if (sessionData.child(FACILITY_KEY).exists() !== false) {
            SessionParamsEnum.Facility.setContextValue(sessionParams, sessionData.child(FACILITY_KEY).val(), database)
        }
        if (sessionData.child(PROJNR_KEY).exists() !== false) {
            SessionParamsEnum.ProjNr.setContextValue(sessionParams, sessionData.child(PROJNR_KEY).val(), database)
        }

        if (sessionData.child(SESSION_SHUNTS_KEY).exists() !== false) {
            const shunts = [];
            sessionData.child(SESSION_SHUNTS_KEY).forEach((shuntID) => {
                shunts.push(shuntID.key);
            });
            SessionParamsEnum.SessionShunts.setContextValue(sessionParams, shunts);
        }

        return true;
    }
    else{
        return false;
    }

}