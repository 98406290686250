export const MINMAX_TEMPERATURE_TEXT_18_90 = "Min/Max temperatur +18/90°C";
export const MINMAX_TEMPERATURE_TEXT_2_90 = "Min/Max temperatur +2/90°C";
export const MINMAX_TEMPERATURE_TEXT_MINUS_5_90 = "Min/Max temperatur -5/90°C";
export const MINMAX_TEMPERATURE_TEXT_MINUS_8_90 = "Min/Max temperatur -8/90°C";
export const MINMAX_TEMPERATURE_TEXT_MINUS_10_90 = "Min/Max temperatur -10/90°C";

export const MATERIAL_PAINTED_TEXT = "Rörkoppel i rostskyddsmålat, TIG svetsat tryckkärlsstål P235GH";
export const MATERIAL_STAINLESS_TEXT = "Rörkoppel i TIG svetsat rostfritt stål EN 1.4436";
export const MATERIAL_UNSPECIFIED_TEXT = "Rörkoppel i ~Info här~";

export const CABINET_INSULATED_TEXT = "Isolerskåp delbart med bygellås";
export const CABINET_UNINSULATED_TEXT = "Skåp delbart med bygellås"

export const CUTOFF_VENT_TEXT = (label, dim, side, multiple) => { if (multiple === true) { return "Avstängningsventiler " + label + " " + dim + " (" + side + ") +mätuttag" } else { return "Avstängningsventil " + label + " " + dim + " (" + side + ") +mätuttag" } }
export const CUTOFF_VENT_TEXT_WITHOUT_SOCKET = (label, dim, side, multiple) => { if (multiple === true) { return "Avstängningsventiler " + label + " " + dim + " (" + side + ")" } else { return "Avstängningsventil " + label + " " + dim + " (" + side + ")" } }
export const MIXING_VESSEL_TEXT = (amount, isGreen) => {
    if (isGreen === true) {
        return "PRE-BW-green-" + amount + " komplett med armaturer samt rörkoppel"
    }
    else {
        return "PRE-BW-" + amount + " komplett med armaturer samt rörkoppel"
    }
}