import { PRODUCTLINE_GREEN, PRODUCTLINE_SMART } from "../../constants/productlineConstants";
import { AdjustmentsEnum, ProductLineEnum, ShuntParamsEnum } from "../../enums";


export const getConnectedComponentText = function getConnectedComponentText(component, useCurrent, shuntData, productLineParams, shuntParams, adjustParams) {
    let text = [];
    let plLine;

    let connDiff;
    let connTemp;
    let connEnergyModel;
    let connEnergyCommModal;

    if (useCurrent === true) {
        plLine = ProductLineEnum.ProductLine.getContextValue(productLineParams);
        connDiff = AdjustmentsEnum.ConnectedDiff.getContextValue(adjustParams);
        connTemp = AdjustmentsEnum.ConnectedTemp.getContextValue(adjustParams);

        connEnergyModel = ShuntParamsEnum.ConnectedEnergyModel.getContextValue(shuntParams);
        connEnergyCommModal = ShuntParamsEnum.ConnectedEnergyCommModal.getContextValue(shuntParams);
    }
    else {
        plLine = shuntData?.PLData?.prodLine;
        connDiff = shuntData?.AdjustData?.connDiff;
        connTemp = shuntData?.AdjustData?.connTemp;

        connEnergyModel = shuntData?.ShuntData?.connectedEnergyModel;
        connEnergyCommModal = shuntData?.ShuntData?.connectedEnergyCommModal;
    }

    switch (component) {
        case "connectedTemp":
            if (connTemp != null) {
                let sideText;
                let totalNum;
                let baseText = "Temperaturgivare Belimo O1DT (PT1000)";
                if (connTemp?.primConnTemp === true && connTemp?.sekConnTemp === true) {
                    text.push({ num: 4, text: baseText });
                    sideText = "(Prim/Sek)"
                    totalNum = 4;
                }
                else if (connTemp?.primConnTemp === true) {
                    text.push({ num: 2, text: baseText });
                    sideText = "(Prim)"
                    totalNum = 2;
                }
                else if (connTemp?.sekConnTemp === true) {
                    text.push({ num: 2, text: baseText });
                    sideText = "(Sek)"
                    totalNum = 2;
                }

                if (sideText != null && sideText !== "") {
                    let extraText = "";

                    if (plLine === PRODUCTLINE_GREEN) {
                        extraText = "i rostfritt "
                    }
                    else if (plLine === PRODUCTLINE_SMART) {
                        extraText = "i mässing "
                    }

                    text.push({ num: totalNum, text: "Dykrör " + extraText + "monterade i tillopp och retur " + sideText });
                }
            }
            break;
        case "connectedDiff":
            if (connDiff != null) {
                let baseText = "Differenstryckgivare Belimo 22WDP";
                if (connDiff?.primConnDiff === true && connDiff?.sekConnDiff === true) {
                    text.push({ num: 2, text: baseText + " (Prim/Sek)" });
                }
                else if (connDiff?.primConnDiff === true) {
                    text.push({ num: 1, text: baseText + " (Prim)" });

                }
                else if (connDiff?.sekConnDiff === true) {
                    text.push({ num: 1, text: baseText + " (Sek)" });
                }
            }
            break;
        case "connectedEnergy":
            if (connEnergyModel != null) {
                text.push({ num: 1, text: "Energimätare " + connEnergyModel?.fullName });
                text.push({ num: Number(connEnergyModel?.sensorTubeReq), text: "Dykrör" });
                text.push({ num: 1, text: "Nätmodul AC230V" });
                if (connEnergyCommModal != null && connEnergyCommModal !== "") {
                    text.push({ num: 1, text: connEnergyCommModal });
                }
                else {
                    text.push({ num: 1, text: "Kommunikationsmodul" });
                }
            }
            break;
        default:
            break;
    }

    return text;
}