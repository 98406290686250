import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react"
import { useFirestore } from "reactfire";
import { SYSTEMFLUIDS_PATH } from "../constants/firebasePaths";

interface FluidDensityRow {
    [key: number]: number;
}

interface FluidCapacityRow {
    [key: number]: number;
}

interface FluidDensityTable {
    [key: number]: FluidDensityRow;
}

interface FluidCapacityTable {
    [key: number]: FluidCapacityRow;
}

export interface SystemFluid {
    value: string;
    order: number;
    label: string;
    paramLabel: string;
    defaultFallbackCalcValue?: number;
    defaultFor?: string[];
    defaultMixRatio?: number;
    maxMixRatio?: number;
    pumpOptionOnly?: boolean;
    wizardOptionOnly?: boolean;
    FluidDensityTable?: FluidDensityTable;
    FluidCapacityTable?: FluidCapacityTable;
}

export const useGetSystemFluidOptions = () => {
    const firestore = useFirestore();
    const [systemFluids, setSystemFluids] = useState<SystemFluid[]>([]);

    useEffect(() => {
        const systemFluidsColRef = query(collection(firestore, SYSTEMFLUIDS_PATH), orderBy('order'));

        getDocs(systemFluidsColRef).then((res) => {
            let local = [];

            res.forEach(child => {
                local.push(child.data());
            });

            setSystemFluids(local);
        })
    }, []);

    return systemFluids;
}

const test = {
    0: {10: 1, 20: 0.998, 30: 0.996, 40: 0.992},
    1: {10: 0.999, 20: 0.997, 30: 0.995, 40: 0.991},
    2: {10: 0.997, 20: 0.998, 30: 0.996, 40: 0.992},
    3: {10: 0.996, 20: 0.998, 30: 0.996, 40: 0.992},
}