import { PRODUCTFAMILY_VA, PRODUCTLINE_FLEX, PRODUCTLINE_SMART, PRODUCTPRINCIP_VA1 } from "../../constants/productlineConstants";
import { CalcParamsEnum, ShuntParamsEnum, AdjustmentsEnum, ProductLineEnum } from "../../enums";

export const getProdID = (getCurrent, calcParams, shuntParams, adjustParams, productLineParams, shuntData = null) => {
    let prodID = "";
    if (getCurrent === true) {
        if (ShuntParamsEnum.PLModel.getContextValue(shuntParams) != null) {
            prodID = ShuntParamsEnum.PLModel.getContextValue(shuntParams);
            let primDim = String(CalcParamsEnum.PrimDimRow.getContextValue(calcParams)?.dim);
            let sekDim = String(CalcParamsEnum.SekDimRow.getContextValue(calcParams)?.dim);

            let largestDim = primDim;
            let connValue = AdjustmentsEnum.ConnectionValue.getContextValue(adjustParams);
            let isMini = ShuntParamsEnum.IsMini.getContextValue(shuntParams);

            //console.log("Dims: ", primDim, sekDim)
            if (Number(sekDim) > Number(primDim)){
                largestDim = sekDim;
            }

            if (ProductLineEnum.ProductFamily.getContextValue(productLineParams) === PRODUCTFAMILY_VA) {
                prodID = prodID.slice(0, 3);
            }

            if (ProductLineEnum.ProductLine.getContextValue(productLineParams) === PRODUCTLINE_FLEX) {
                prodID += "-FLEX";
            }

            if (ProductLineEnum.ProductLine.getContextValue(productLineParams) === PRODUCTLINE_SMART) {
                prodID += "-SMART";
            }

            if (connValue === '_' || connValue == null || connValue === '') {
                prodID += "-R-" + largestDim;
            }
            else {
                prodID += "-" + connValue.toLocaleUpperCase() + "-" + largestDim;
            }

            if (ShuntParamsEnum.IsMini.getContextValue(shuntParams) === true) {
                prodID += "M";
            }


            if (AdjustmentsEnum.StandValue.getContextValue(adjustParams) === "wall") {
                prodID += "-WALL";
            }
        }

    }
    else {
        if (shuntData?.ShuntData?.plModel != null) {
            prodID = shuntData?.ShuntData?.plModel;
            let primDim = String(shuntData?.CalcParams?.primDimRow?.dim);
            let sekDim = String(shuntData?.CalcParams?.sekDimRow?.dim);
            let connValue = shuntData?.AdjustData?.connectionValue;

            let plLine = shuntData?.PLData?.prodLine;
            let plPrincip = shuntData?.PLData?.princip;

            let largestDim = primDim;

            if (Number(sekDim) > Number(primDim)){
                largestDim = sekDim;
            }

            if (plPrincip === PRODUCTPRINCIP_VA1) {
                prodID = prodID.slice(0, 3);
            }

            if (plLine === PRODUCTLINE_FLEX) {
                prodID += "-FLEX";
            }

            if (plLine === PRODUCTLINE_SMART) {
                prodID += "-SMART";
            }

            if (connValue === '_' || connValue == null || connValue === '') {
                prodID += "-R-" + largestDim;
            }
            else {
                prodID += "-" + connValue.toLocaleUpperCase() + "-" + largestDim;
            }

            if (shuntData?.ShuntData?.isMini === true || shuntData?.ShuntData?.isMini === 'true') {
                prodID += "M";
            }

            if (shuntData?.AdjustData?.stand === "wall") {
                prodID += "-WALL";
            }
        }
    }

    //console.log("ProdID: ", prodID)
    return prodID;
}