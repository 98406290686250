import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/Logout';
import { Button, Divider, Paper, Typography } from '@mui/material';
import { useContextAuth } from '../../../authContext/AuthHook';
import { SessionParamsEnum } from '../../../enums';
import { useSessionParams } from '../../../contexts';
import { OverviewPanels } from '../../../constants/overviewPanels';


export const MyPageSidePanel = ({ handleClose }) => {
    const { user, handleLogout } = useContextAuth();
    const sessionParams = useSessionParams();


    const handleLogoutFunc = (event) => {
        event.preventDefault();
        handleLogout();
        handleClose();
    }


    const handleCreateNewSession = () => {
        SessionParamsEnum.OverviewPanel.setContextValue(sessionParams, OverviewPanels.newSessionPanel)
    }

    const openSessionHistoryPanel = () => {
        SessionParamsEnum.OverviewPanel.setContextValue(sessionParams, OverviewPanels.sessionHistoryPanel)
    }

    const openMyProfilePanel = () => {
        SessionParamsEnum.OverviewPanel.setContextValue(sessionParams, OverviewPanels.myProfilePanel)
    }

    return (
        <Paper style={{ padding: "15px", minWidth: "202px", maxWidth: "210px", maxHeight: "535px" }}>
            <div style={{ paddingTop: "0px" }}>
                <Button
                    variant="contained"
                    onClick={handleCreateNewSession}
                    fullWidth
                    disabled={SessionParamsEnum.OverviewPanel.getContextValue(sessionParams) === OverviewPanels.newSessionPanel}
                    className={SessionParamsEnum.OverviewPanel.getContextValue(sessionParams) === OverviewPanels.newSessionPanel ? "handle-active-btn" : ""}
                >
                    Ny session
                </Button>
            </div>

            <Divider variant="middle" className="divider-sidepanel" />

            <div style={{ paddingTop: "10px" }}>
                <Button
                    variant="contained"
                    onClick={openSessionHistoryPanel}
                    fullWidth
                    disabled={SessionParamsEnum.OverviewPanel.getContextValue(sessionParams) === OverviewPanels.sessionHistoryPanel}
                    className={SessionParamsEnum.OverviewPanel.getContextValue(sessionParams) === OverviewPanels.sessionHistoryPanel ? "handle-active-btn" : ""}
                >
                    Min Sessionshistorik
                </Button>
            </div>

            <div style={{ marginTop: "10px" }}>

                <div style={{ paddingTop: "10px", marginBottom: '10px' }}>
                    <Button
                        variant="contained"
                        onClick={openMyProfilePanel}
                        fullWidth
                        disabled={SessionParamsEnum.OverviewPanel.getContextValue(sessionParams) === OverviewPanels.myProfilePanel}
                        className={SessionParamsEnum.OverviewPanel.getContextValue(sessionParams) === OverviewPanels.myProfilePanel ? "handle-active-btn" : ""}
                    >
                        Mina Sidor
                    </Button>
                </div>
            </div>

            <div style={{ marginTop: "40px", }}>
                {user != null && user?.isAnonymous === false ? (
                    <>
                        <Typography sx={{ textAlign: 'center', marginBottom: '-4px' }} fontStyle={'italic'} color={'GrayText'}>
                            Inloggad som {user?.name}
                        </Typography>
                        <Button fullWidth onClick={handleLogoutFunc}>
                            <LogoutIcon style={{ marginRight: '5px' }} /> Logga ut
                        </Button>
                        <span style={{
                            color: '#888888', display: 'table',
                            margin: ' 7px auto 0px'
                        }}>
                            {user?.data?.email}
                        </span>
                    </>
                ) : ("")}

            </div>
        </Paper>
    )
}