import { useState } from "react";
import { useContextAuth } from "../../../authContext/AuthHook";
import { Modal } from "@mui/material";
import { LoginModule } from "../login/loginModule";
import { MyPagesOverview } from "./myPagesOverview";
import { SessionParamsEnum } from "../../../enums";
import { useSessionParams } from "../../../contexts";
import { OverviewPanels } from "../../../constants/overviewPanels";
import { getAuth } from "firebase/auth";


export const MyPagesModalStart = () => {
    const { user } = useContextAuth();
    const auth = getAuth();
    const sessionParams = useSessionParams();
    
    const [openOverview, setOpenOverview] = useState(false);
    const handleOpenOverview = () => setOpenOverview(true);
    const handleCloseOverview = () => setOpenOverview(false);

    const [openLogin, setOpenLogin] = useState(false);
    const handleOpenLogin = () => setOpenLogin(true);
    const handleCloseLogin = () => {
        auth.onAuthStateChanged((res) => {
            if (res.isAnonymous === false){
                handleOpenOverview();
            }

            setOpenLogin(false);
        })
    }

    const handleOpenMyPages = () => {
        if (user != null && user.isAnonymous === false) {
            SessionParamsEnum.OverviewPanel.setContextValue(sessionParams, OverviewPanels.sessionHistoryPanel)
            handleOpenOverview();
        }
        else {
            handleOpenLogin();
        }
    }

    return (
        <>
            <span onClick={handleOpenMyPages} style={{ fontWeight: 'bold', cursor: 'pointer', textDecoration: 'underline' }}>Mina sidor</span>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openOverview}
                onClose={handleCloseOverview}
                closeAfterTransition
                slotProps={{ backdrop: { timeout: 500 } }}
            >
                <div>
                    <MyPagesOverview open={openOverview} handleClose={handleCloseOverview} />
                </div>
            </Modal>

            <Modal
                open={openLogin}
                onClose={handleCloseLogin}
            >
                <div>
                    <LoginModule handleClose={handleCloseLogin} />
                </div>
            </Modal>
        </>
    )
}