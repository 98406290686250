const { get, ref } = require("firebase/database");
const { SESSION_SHUNT_PATH, SESSION_PATH, SHUNT_CUSTOMDATA } = require("../../../constants/firebasePaths");

const getSessionPriceData = async function getSessionPriceData(database, sessionID) {
    const sessionPriceRef = ref(database, SESSION_PATH + sessionID + SHUNT_CUSTOMDATA);

    let data = await get(sessionPriceRef);

    return data.val();
}

export { getSessionPriceData }