import { Paper } from "@mui/material"
import { useEffect } from "react";
import PriceStepper from "../priceCalc/priceStepper";

export const PriceCalcOverview = () => {
    return (
        <Paper style={{ display: 'flex', flexDirection: 'column', width: "870px" }}>
            <div className="overview-content-size">
                <PriceStepper />
            </div >
        </Paper >
    )
}