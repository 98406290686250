import { Box, Button, IconButton, Modal, Paper, Stack, Tooltip, Typography } from "@mui/material"

import standardLine from '../../../assets/images/logos/Premablock_Standard_Logo.svg'
import greenLine1 from '../../../assets/images/logos/Premablock_Green_I_Logo.svg'
import greenLine2 from '../../../assets/images/logos/Premablock_Green_II_Logo.svg'
import flexLine from '../../../assets/images/logos/Premablock_Flex_Logo.svg'
import smartLine from '../../../assets/images/logos/Premablock_Smart_Logo.svg'
import { PRODUCTLINE_STANDARD, PRODUCTLINE_GREEN_I, PRODUCTLINE_GREEN_II, PRODUCTLINE_FLEX, PRODUCTLINE_SMART } from "../../../constants/productlineConstants"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InfoIcon from '@mui/icons-material/Info';
import { WIZARD_PRODLINE_HOVERTEXT_LONG_INFO_FLEX, WIZARD_PRODLINE_HOVERTEXT_LONG_INFO_GREEN, WIZARD_PRODLINE_HOVERTEXT_LONG_INFO_GREEN2, WIZARD_PRODLINE_HOVERTEXT_LONG_INFO_SMART, WIZARD_PRODLINE_HOVERTEXT_LONG_INFO_STANDARD, WIZARD_PRODLINE_HOVERTEXT_SHORT_INFO_FLEX, WIZARD_PRODLINE_HOVERTEXT_SHORT_INFO_GREEN, WIZARD_PRODLINE_HOVERTEXT_SHORT_INFO_GREEN2, WIZARD_PRODLINE_HOVERTEXT_SHORT_INFO_SMART, WIZARD_PRODLINE_HOVERTEXT_SHORT_INFO_STANDARD } from "../../../constants/generalText/shuntInfoTexts"
import { PRODLINE_FLEX_LINK, PRODLINE_GREEN1_LINK, PRODLINE_GREEN2_LINK, PRODLINE_SMART_LINK, PRODLINE_STANDARD_LINK } from "../../../constants/generalText/shuntLinks"
import CloseIcon from '@mui/icons-material/Close';
import { NotDimensionableInfo } from "./components/notDimensionable"

const style = {
    position: 'fixed',
    left: '50%',
    top: '12vh',
    translate: '-50%',
    minHeight: 100,
    bgcolor: 'rgba(255,255,255,0.85)',
    border: '2px solid #E1E2E4',
    borderRadius: 2,
    boxShadow: 24,
    padding: "20px",
    overflow: 'hidden',
};

/**
 * 
 * @param {Object} param0 
 * @param {boolean} param0.openModal
 * @param {() => void} param0.handleClose
 * @param {import('../../../constants/fullConnOptions/types').ShuntConnectionInterface} param0.selected
 * @param {(connectionObj, prodline) => boolean} param0.filterLines
 * @param {(connectionObj, prodline) => void} param0.clickAndContinue
 * @returns 
 */
export const SelectProdLinePopup = ({ openModal, handleClose, selected, filterLines, clickAndContinue }) => {

    const prodLineImage = (prodLine) => {
        switch (prodLine) {
            case PRODUCTLINE_STANDARD:
                return (<img src={standardLine} alt='Standard Line' style={{ height: '16px' }} />);
            case PRODUCTLINE_GREEN_I:
                return (<img src={greenLine1} alt='Green I Line' style={{ height: '20px' }} />);
            case PRODUCTLINE_GREEN_II:
                return (<img src={greenLine2} alt='Green II Line' style={{ height: '20px' }} />);
            case PRODUCTLINE_FLEX:
                return (<img src={flexLine} alt='Flex Line' style={{ height: '16px' }} />);
            case PRODUCTLINE_SMART:
                return (<img src={smartLine} alt='Smart Line' style={{ height: '16px' }} />);
            default:
                console.log("Prod: ", prodLine)
                return (<img src={standardLine} alt='Standard Line' style={{ height: '16px' }} />);
        }
    }

    return (
        <Modal
            aria-labelledby="auto-product-wizard"
            aria-describedby="wizard-to-select-product"
            open={openModal}
            onClose={handleClose}
            closeAfterTransition
            slotProps={{ backdrop: { timeout: 500 } }}
        >
            <Box sx={{...style, top: selected?.FilterData?.dimensionable === true ? "16vh" : "7vh"}}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    {selected?.FilterData?.dimensionable === true ? (
                        <Typography id="transition-modal-title" component="h2" variant='h2' fontWeight="bold" className="header-title" sx={{ mb: '10px', color: "#183851" }}>
                            {selected?.InfoData?.name} - Välj produktlinje
                        </Typography>
                    ) : (
                        <Typography id="transition-modal-title" component="h2" variant='h2' fontWeight="bold" className="header-title" sx={{ mb: '10px', color: "#183851" }}>
                            {selected?.InfoData?.name} - För närvarande ej dimensionerbar i ShuntLogik
                        </Typography>
                    )}

                    <IconButton onClick={handleClose} size="small" sx={{ height: '30px', marginTop: '-18px', marginRight: '-18px' }}>
                        <CloseIcon />
                    </IconButton>
                </Stack>

                <Paper sx={{ width: '100%', height: '100%', borderRadius: '3px', marginTop: '-8px' }}>
                    <Stack direction={'row'} justifyContent={'space-between'} height={'100%'}>
                        <Box sx={{ padding: '20px', width: '100%', height: '100%' }}>
                            {selected?.FilterData?.dimensionable === true ? (
                                <>
                                    <Box sx={{ width: 470, marginTop: '30px', marginBottom: '25px' }}>
                                        <Stack spacing={1}>
                                            {selected?.InfoData?.prodlines.map((line) => {
                                                return (
                                                    <Stack alignItems={'center'} spacing={1} direction={'row'} key={line} sx={{}}>
                                                        <Tooltip arrow title={displayTooltipText(line, true)}>
                                                            <span>
                                                                <Button disabled={filterLines(selected, line) === false} onClick={() => clickAndContinue(selected, line)} sx={{ backgroundColor: 'white', width: '340px', height: '36px', justifyContent: 'start', opacity: filterLines(selected, line) ? 1 : 0.8, filter: filterLines(selected, line) ? '' : 'grayscale(100%)' }} variant="outlined">
                                                                    {prodLineImage(line)}
                                                                </Button>
                                                            </span>
                                                        </Tooltip>

                                                        <Tooltip arrow placement="left-end" title={displayTooltipText(line, false)}>
                                                            <IconButton size="small">
                                                                <InfoIcon htmlColor="#0055a0" sx={{ fontSize: '18px' }} />
                                                            </IconButton>
                                                        </Tooltip>


                                                        <IconButton onClick={() => openPLLink(line)} size="small">
                                                            <OpenInNewIcon sx={{ fontSize: '18px' }} />
                                                        </IconButton>
                                                    </Stack>
                                                )
                                            })}

                                        </Stack>
                                    </Box>

                                </>
                            ) : (
                                <>
                                    <NotDimensionableInfo selected={selected} filterLines={filterLines} handleClose={handleClose} />
                                </>
                            )}

                        </Box>
                    </Stack>
                </Paper>
            </Box>
        </Modal>
    )
}

/**
 * 
 * @param {string} prodLine 
 * @param {boolean} shortInfo 
 */
export const displayTooltipText = (prodLine, shortInfo) => {
    switch (prodLine) {
        case PRODUCTLINE_STANDARD:
            if (shortInfo === true) {
                return WIZARD_PRODLINE_HOVERTEXT_SHORT_INFO_STANDARD;
            }
            else {
                return WIZARD_PRODLINE_HOVERTEXT_LONG_INFO_STANDARD;
            }
        case PRODUCTLINE_GREEN_I:
            if (shortInfo === true) {
                return WIZARD_PRODLINE_HOVERTEXT_SHORT_INFO_GREEN;
            }
            else {
                return WIZARD_PRODLINE_HOVERTEXT_LONG_INFO_GREEN;
            }
        case PRODUCTLINE_GREEN_II:
            if (shortInfo === true) {
                return WIZARD_PRODLINE_HOVERTEXT_SHORT_INFO_GREEN2;
            }
            else {
                return WIZARD_PRODLINE_HOVERTEXT_LONG_INFO_GREEN2;
            }
        case PRODUCTLINE_FLEX:
            if (shortInfo === true) {
                return WIZARD_PRODLINE_HOVERTEXT_SHORT_INFO_FLEX;
            }
            else {
                return WIZARD_PRODLINE_HOVERTEXT_LONG_INFO_FLEX;
            }
        case PRODUCTLINE_SMART:
            if (shortInfo === true) {
                return WIZARD_PRODLINE_HOVERTEXT_SHORT_INFO_SMART;
            }
            else {
                return WIZARD_PRODLINE_HOVERTEXT_LONG_INFO_SMART;
            }
        default:
            return "";
    }
}

/**
 * 
 * @param {string} prodLine 
 * @returns 
 */
export const openPLLink = (prodLine) => {
    switch (prodLine) {
        case PRODUCTLINE_STANDARD:
            window.open(PRODLINE_STANDARD_LINK, "_blank", "noreferrer");
            break;
        case PRODUCTLINE_GREEN_I:
            window.open(PRODLINE_GREEN1_LINK, "_blank", "noreferrer");
            break;
        case PRODUCTLINE_GREEN_II:
            window.open(PRODLINE_GREEN2_LINK, "_blank", "noreferrer");
            break;
        case PRODUCTLINE_FLEX:
            window.open(PRODLINE_FLEX_LINK, "_blank", "noreferrer");
            break;
        case PRODUCTLINE_SMART:
            window.open(PRODLINE_SMART_LINK, "_blank", "noreferrer");
            break;
        default:
            return "";
    }
}
