import { ref, set } from "firebase/database";
import React, { createContext, useReducer, useContext } from "react";
import { SESSION_SHUNT_PATH, SHUNT_ADJUSTDATA_ACTUATORS_PATH, SHUNT_ADJUSTDATA_ADJUSTVALUE_PATH, SHUNT_ADJUSTDATA_CABINET_PATH, SHUNT_ADJUSTDATA_CONNECTED_DIFF_PATH, SHUNT_ADJUSTDATA_CONNECTED_TEMP_PATH, SHUNT_ADJUSTDATA_CONNECTIONDESIGN_PATH, SHUNT_ADJUSTDATA_CONNECTIONVALUE_PATH, SHUNT_ADJUSTDATA_CUTOFF_PATH, SHUNT_ADJUSTDATA_DRIPPLATE_PATH, SHUNT_ADJUSTDATA_ENERGYMEASUREMENT_PATH, SHUNT_ADJUSTDATA_INSULATION_PATH, SHUNT_ADJUSTDATA_MANOMETER_PATH, SHUNT_ADJUSTDATA_MATERIAL_PATH, SHUNT_ADJUSTDATA_MIXINGVESSEL_PATH, SHUNT_ADJUSTDATA_SHUNTSIZE_PATH, SHUNT_ADJUSTDATA_STAND_PATH, SHUNT_ADJUSTDATA_THERMO_PATH } from "../constants/firebasePaths";
import { ACTUATORS_KEY, ADJUSTVALUE_KEY, CABINET_KEY, CONNECTED_DIFF_KEY, CONNECTED_TEMP_KEY, CONNECTION_DESIGN_KEY, CONNECTION_VALUE_KEY, CUTOFF_KEY, DRIPPLATE_KEY, ENERGYMEASUREMENT_KEY, INSULATION_KEY, MANOMETER_KEY, MATERIAL_KEY, MIXINGVESSELS_KEY, SHUNTSIZE_KEY, STAND_KEY, THERMO_KEY } from "../constants/keys/adjustParamsKeys";
import { ADJUSTMENT_PARAMS } from "../constants/sessionStoragePaths";


const AdjustParams = createContext(undefined);

function adjustParamsReducer(state, action) {
    switch (action.type) {

        case 'setConnectionDesign': {
            let data = {};
            if (sessionStorage.getItem(ADJUSTMENT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(ADJUSTMENT_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_ADJUSTDATA_CONNECTIONDESIGN_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[CONNECTION_DESIGN_KEY] = action.payload;
            sessionStorage.setItem(ADJUSTMENT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setConnectionValue': {
            let data = {};
            if (sessionStorage.getItem(ADJUSTMENT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(ADJUSTMENT_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_ADJUSTDATA_CONNECTIONVALUE_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[CONNECTION_VALUE_KEY] = action.payload;
            sessionStorage.setItem(ADJUSTMENT_PARAMS, JSON.stringify(data));
            return { val: data }
        }

        case 'setMaterialValue': {
            let data = {};
            if (sessionStorage.getItem(ADJUSTMENT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(ADJUSTMENT_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_ADJUSTDATA_MATERIAL_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[MATERIAL_KEY] = action.payload;
            sessionStorage.setItem(ADJUSTMENT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setThermoValue': {
            let data = {};
            if (sessionStorage.getItem(ADJUSTMENT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(ADJUSTMENT_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_ADJUSTDATA_THERMO_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[THERMO_KEY] = action.payload;
            sessionStorage.setItem(ADJUSTMENT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setIsolationValue': {
            let data = {};
            if (sessionStorage.getItem(ADJUSTMENT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(ADJUSTMENT_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_ADJUSTDATA_INSULATION_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[INSULATION_KEY] = action.payload;
            sessionStorage.setItem(ADJUSTMENT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setCutOffValue': {
            let data = {};
            if (sessionStorage.getItem(ADJUSTMENT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(ADJUSTMENT_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_ADJUSTDATA_CUTOFF_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[CUTOFF_KEY] = action.payload;
            sessionStorage.setItem(ADJUSTMENT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setManometerValue': {
            let data = {};
            if (sessionStorage.getItem(ADJUSTMENT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(ADJUSTMENT_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_ADJUSTDATA_MANOMETER_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[MANOMETER_KEY] = action.payload;
            sessionStorage.setItem(ADJUSTMENT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setStandValue': {
            let data = {};
            if (sessionStorage.getItem(ADJUSTMENT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(ADJUSTMENT_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_ADJUSTDATA_STAND_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[STAND_KEY] = action.payload;
            sessionStorage.setItem(ADJUSTMENT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setCabinetValue': {
            let data = {};
            if (sessionStorage.getItem(ADJUSTMENT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(ADJUSTMENT_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_ADJUSTDATA_CABINET_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[CABINET_KEY] = action.payload;
            sessionStorage.setItem(ADJUSTMENT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setActuatorsValue': {
            let data = {};
            if (sessionStorage.getItem(ADJUSTMENT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(ADJUSTMENT_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_ADJUSTDATA_ACTUATORS_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[ACTUATORS_KEY] = action.payload;
            sessionStorage.setItem(ADJUSTMENT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setDripPlateValue': {
            let data = {};
            if (sessionStorage.getItem(ADJUSTMENT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(ADJUSTMENT_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_ADJUSTDATA_DRIPPLATE_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[DRIPPLATE_KEY] = action.payload;
            sessionStorage.setItem(ADJUSTMENT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setMixingVessels': {
            let data = {};
            if (sessionStorage.getItem(ADJUSTMENT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(ADJUSTMENT_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_ADJUSTDATA_MIXINGVESSEL_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[MIXINGVESSELS_KEY] = action.payload;
            sessionStorage.setItem(ADJUSTMENT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setShuntSize': {
            let data = {};
            if (sessionStorage.getItem(ADJUSTMENT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(ADJUSTMENT_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_ADJUSTDATA_SHUNTSIZE_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[SHUNTSIZE_KEY] = action.payload;
            sessionStorage.setItem(ADJUSTMENT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setAdjustValue': {
            let data = {};
            if (sessionStorage.getItem(ADJUSTMENT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(ADJUSTMENT_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_ADJUSTDATA_ADJUSTVALUE_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[ADJUSTVALUE_KEY] = action.payload;
            sessionStorage.setItem(ADJUSTMENT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setEnergyMeasurement': {
            let data = {};
            if (sessionStorage.getItem(ADJUSTMENT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(ADJUSTMENT_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_ADJUSTDATA_ENERGYMEASUREMENT_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[ENERGYMEASUREMENT_KEY] = action.payload;
            sessionStorage.setItem(ADJUSTMENT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setConnTemp': {
            let data = {};
            if (sessionStorage.getItem(ADJUSTMENT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(ADJUSTMENT_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_ADJUSTDATA_CONNECTED_TEMP_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[CONNECTED_TEMP_KEY] = action.payload;
            sessionStorage.setItem(ADJUSTMENT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setConnDiff': {
            let data = {};
            if (sessionStorage.getItem(ADJUSTMENT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(ADJUSTMENT_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_ADJUSTDATA_CONNECTED_DIFF_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[CONNECTED_DIFF_KEY] = action.payload;
            sessionStorage.setItem(ADJUSTMENT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setDefaultValues' : {
            let data = {};
            if (action.payload != null){
                data = action.payload;
            }

            sessionStorage.setItem(ADJUSTMENT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'clearConnectedAddons' : {
            let data = {};

            delete data[CONNECTED_TEMP_KEY];
            delete data[CONNECTED_DIFF_KEY];

            sessionStorage.setItem(ADJUSTMENT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setDataFromSnapShot': {
            let data = {};

            data[CONNECTION_DESIGN_KEY] = action.payload.child(CONNECTION_DESIGN_KEY).val() || undefined;
            data[CONNECTION_VALUE_KEY] = action.payload.child(CONNECTION_VALUE_KEY).val() || undefined;
            data[MATERIAL_KEY] = action.payload.child(MATERIAL_KEY).val() || undefined;
            data[THERMO_KEY] = action.payload.child(THERMO_KEY).val() || undefined;
            data[INSULATION_KEY] = action.payload.child(INSULATION_KEY).val() || undefined;
            data[CUTOFF_KEY] = action.payload.child(CUTOFF_KEY).val() || undefined;
            data[MANOMETER_KEY] = action.payload.child(MANOMETER_KEY).val() || undefined;
            data[STAND_KEY] = action.payload.child(STAND_KEY).val() || undefined;
            data[CABINET_KEY] = action.payload.child(CABINET_KEY).val() || undefined;
            data[ACTUATORS_KEY] = action.payload.child(ACTUATORS_KEY).val() || undefined;
            data[DRIPPLATE_KEY] = action.payload.child(DRIPPLATE_KEY).val() || undefined;
            data[ADJUSTVALUE_KEY] = action.payload.child(ADJUSTVALUE_KEY).val() || undefined;
            data[MIXINGVESSELS_KEY] = action.payload.child(MIXINGVESSELS_KEY).val() || undefined;
            data[ENERGYMEASUREMENT_KEY] = action.payload.child(ENERGYMEASUREMENT_KEY).val() || undefined;
            data[CONNECTED_TEMP_KEY] = action.payload.child(CONNECTED_TEMP_KEY).val() || undefined;
            data[CONNECTED_DIFF_KEY] = action.payload.child(CONNECTED_DIFF_KEY).val() || undefined;

            //console.log("Loading Data:", data);
            sessionStorage.setItem(ADJUSTMENT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'clear': {
            sessionStorage.removeItem(ADJUSTMENT_PARAMS);

            return { val: {} }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function AdjustProvider({ children }) {
    let obj = {};
    if (sessionStorage.getItem(ADJUSTMENT_PARAMS) != null) {
        obj = JSON.parse(sessionStorage.getItem(ADJUSTMENT_PARAMS));
    }

    const [state, dispatch] = useReducer(adjustParamsReducer, { val: obj })
    const value = { state, dispatch }

    return (
        <AdjustParams.Provider value={value}>
            {children}
        </AdjustParams.Provider>
    );
}

function useAdjustParams() {
    const context = useContext(AdjustParams)
    if (context === undefined) {
        throw new Error('useAdjustParams must be used within a AdjustParams')
    }
    return context
}


export { AdjustProvider, useAdjustParams };