import { useEffect, useState } from "react";
import { Box, Checkbox, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, Tooltip } from "@mui/material";
import { useDatabase } from "reactfire";

import { useGetShuntListName } from "../../../../firebase/functions/getShuntList";
import { SessionParamsEnum } from "../../../../enums";
import { useSessionParams, useShuntParams } from "../../../../contexts";
import { getCurrentHighPosition } from "../../../../firebase/functions/getCurrentHighPosition";
import { getShuntDesignation } from "../../../../firebase/functions/getShuntDesignation";
import { addMixingVesselDataToShunt } from "../../../../firebase/functions/addMixingVesselDataToShunt";

import { PRODUCTLINE_GREEN, PRODUCTLINE_STANDARD } from "../../../../constants/productlineConstants";
import { MIXINGVESSEL_60L, MIXINGVESSEL_100L, MIXINGVESSEL_200L, MIXINGVESSEL_300L, MIXINGVESSEL_500L, MIXINGVESSEL_1000L } from "../../../../constants/adjustmentsOptions";

import greenLine1 from '../../../../assets/images/logos/Green_I_Logo.svg'

export const AddAddonMixingVesselContent = ({ setIsValid, addAddonTrigger, setAddonTrigger, handleClose }) => {
    const { shuntList, loading: loadingShunts } = useGetShuntListName();

    const sessionParams = useSessionParams();
    const shuntParams = useShuntParams();
    const database = useDatabase();

    const [selectedVolume, setSelectedVolume] = useState('');
    const [selectedShunt, setSelectedShunt] = useState('');
    const [greenVersion, setGreenversion] = useState(false);
    const [isStandalone, setIsStandalone] = useState(false);

    useEffect(() => {
        if (addAddonTrigger === true) {
            addAddonFunc();
        }
    }, [addAddonTrigger])

    useEffect(() => {
        if (selectedVolume == null || selectedVolume === "") {
            setIsValid(false);
        }
        else if ((selectedShunt == null || selectedShunt === "") && isStandalone === false) {
            setIsValid(false);
        }
        else {
            setIsValid(true);
        }
    }, [selectedVolume, selectedShunt, isStandalone])

    const mixingVesselsOptions = [
        MIXINGVESSEL_60L,
        MIXINGVESSEL_100L,
        MIXINGVESSEL_200L,
        MIXINGVESSEL_300L,
        MIXINGVESSEL_500L,
        MIXINGVESSEL_1000L
    ];

    const handleChangeShunt = (event) => {
        setSelectedShunt(event.target.value);
    }

    const handleChangeVolume = (event) => {
        setSelectedVolume(event.target.value);
    }

    const handleSetIsStandalone = () => {
        if (isStandalone === false) {
            setSelectedShunt('');
        }
        setIsStandalone(!isStandalone);
    }

    const addAddonFunc = () => {
        if (selectedVolume != null && selectedVolume !== "") {
            let addonData = {
                size: selectedVolume,
            }

            let prodLine = PRODUCTLINE_STANDARD;

            if (greenVersion === true) {
                prodLine = PRODUCTLINE_GREEN;
            }

            if (isStandalone === true) {
                getCurrentHighPosition(database, sessionParams).then((highPos) => {

                    console.log("Data Standalone: ", prodLine, addonData)
                    SessionParamsEnum.createShuntAddon(sessionParams, database, 'mixingVessel', shuntParams, highPos, prodLine, shuntList, null, addonData, true).then((addonID) => {
                        console.log("Addon", addonID)
                        setAddonTrigger(false);
                        handleClose();
                    })
                });
            }
            else {
                if (selectedShunt != null && selectedShunt !== "") {
                    getShuntDesignation(database, selectedShunt).then((res) => {
                        if (res?.position != null) {
                            console.log("Result: ", res.position)

                            console.log("Data Connected: ", selectedShunt, prodLine, addonData)
                            SessionParamsEnum.createShuntAddon(sessionParams, database, 'mixingVessel', shuntParams, Number(res.position), prodLine, shuntList, selectedShunt, addonData, true).then((addonID) => {
                                console.log("Addon", addonID)
                                addMixingVesselDataToShunt(database, selectedShunt, selectedVolume).then(() => {
                                    setAddonTrigger(false);
                                    handleClose();
                                });
                            })
                        }
                        else {
                            setAddonTrigger(false);
                        }
                    });
                }
                else {
                    setAddonTrigger(false);
                }
            }
        }
        else {
            setAddonTrigger(false);
        }


    }

    return (
        <>
            <Divider sx={{ marginBottom: '5px' }}>Utförande</Divider>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1}>
                <FormControl>
                    <InputLabel>
                        Volym
                    </InputLabel>

                    <Select
                        sx={{ width: '200px' }}
                        label={'Volym'}
                        value={selectedVolume}
                        onChange={handleChangeVolume}
                    >
                        <MenuItem sx={{ height: '22px' }} value={''}></MenuItem>
                        {mixingVesselsOptions.map((volume) => {
                            return (
                                <MenuItem key={volume} value={volume}>
                                    {volume}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>

                <Box display={'flex'} justifyContent={'flex-start'} sx={{ width: '200px', paddingLeft: '80px'  }}>
                    <FormControlLabel control={<Checkbox checked={greenVersion} onChange={() => setGreenversion(!greenVersion)} />} label={<span><img src={greenLine1} alt='Green I Line' style={{ height: '14px', paddingTop: '6px', userSelect: 'none' }} /></span>} />
                </Box>
            </Stack>

            <Stack sx={{ marginTop: '7px' }} direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1}>
                <FormControl>
                    <InputLabel>
                        Kopplad till shuntgrupp
                    </InputLabel>

                    <Select
                        sx={{ width: '200px' }}
                        label={'Kopplad till shuntgrupp'}
                        value={selectedShunt}
                        onChange={handleChangeShunt}
                        disabled={loadingShunts || isStandalone}
                    >
                        <MenuItem sx={{ height: '22px' }} value={''}></MenuItem>
                        {shuntList.filter((obj) => obj.isAddon !== true).map((shunt) => {
                            if (shunt?.hasMixingVessel === true) {
                                return (
                                    <Tooltip arrow placement="left" title="Blandningskärl finns redan kopplad till denna shuntgrupp">
                                        <span>
                                            <MenuItem key={shunt?.id} value={shunt?.id} disabled>
                                                <i>{shunt?.fullName} {shunt?.fullPlModel != null && shunt?.fullPlModel !== "" ? "(" + shunt?.fullPlModel + ")" : ""}</i>
                                            </MenuItem>
                                        </span>
                                    </Tooltip>
                                );
                            }
                            else {
                                return (
                                    <MenuItem key={shunt?.id} value={shunt?.id}>
                                        {shunt?.fullName} {shunt?.fullPlModel != null && shunt?.fullPlModel !== "" ? "(" + shunt?.fullPlModel + ")" : ""}
                                    </MenuItem>
                                );
                            }
                        })}
                    </Select>
                </FormControl>
                <Box display={'flex'} justifyContent={'flex-start'} sx={{ width: '200px', paddingLeft: '80px' }}>
                    <FormControlLabel control={<Checkbox checked={isStandalone} onChange={handleSetIsStandalone} />} label="Fristående" />
                </Box>

            </Stack>
        </>
    )
}