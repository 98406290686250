import { useAuth } from 'reactfire'
import { signInWithEmailAndPassword } from 'firebase/auth'
import Button from '@mui/material/Button'
import { useState } from 'react';
import { Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField } from '@mui/material'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { LoginForm } from './loginForm';
import { ResetPasswordForm } from './resetPasswordForm';

export const LoginModule = ({ handleClose }) => {
    const [resetPass, setResetPass] = useState(false);

    const handleResetPasswordSwitch = () => {
        setResetPass(!resetPass);
    }

    return (
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 250,
            minHeight: 215,
            bgcolor: 'background.paper',
            border: 'none',
            borderRadius: '5px',
            boxShadow: 24,
            p: 2,
        }}>
            {resetPass ? (
                <ResetPasswordForm handleClose={handleClose} handleResetPasswordSwitch={handleResetPasswordSwitch} />
            ) : (
                <LoginForm handleClose={handleClose} handleResetPasswordSwitch={handleResetPasswordSwitch} />
            )}
        </Box>
    )
}