import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Collapse, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { collection } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ADJUST_VALVE_ARMATEC1310, ADJUST_VALVE_OVENTROP, ADJUST_VALVE_PURMOEVO, ADJUST_VALVE_STADF } from "../../../constants/shuntOptions";

const Row = ({ valve, dimData }) => {
    const [open, setOpen] = useState(false);

    const showKVval = (dimRow) => {
        if (valve.NO_ID_FIELD === ADJUST_VALVE_ARMATEC1310) {
            return dimRow?.kvIV_At || "-";
        }
        else if (valve.NO_ID_FIELD === ADJUST_VALVE_PURMOEVO) {
            return dimRow?.kvIV_Stv || "-";
        }
        else if (valve.NO_ID_FIELD === ADJUST_VALVE_OVENTROP) {
            return dimRow?.kvIV_Oven || "-";
        }
        else if (valve.NO_ID_FIELD === ADJUST_VALVE_STADF) {
            return dimRow?.kvIV_Stad || "-";
        }
        else {
            return "-";
        }
    }

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.action.hover,
        // },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    return (
        <>
            <StyledTableRow style={{ height: "35px" }}>
                <TableCell>{valve.name}</TableCell>
                {valve.default === true ? (
                    <TableCell align="center"><CheckCircleIcon fontSize='small' color="success" /></TableCell>
                ) : (
                    <TableCell></TableCell>
                )}
                {valve.default_green === true ? (
                    <TableCell align="center"><CheckCircleIcon fontSize='small' color="success" /></TableCell>
                ) : (
                    <TableCell></TableCell>
                )}
                {valve.default_flex === true ? (
                    <TableCell align="center"><CheckCircleIcon fontSize='small' color="success" /></TableCell>
                ) : (
                    <TableCell></TableCell>
                )}
                {valve.default_smart === true ? (
                    <TableCell align="center"><CheckCircleIcon fontSize='small' color="success" /></TableCell>
                ) : (
                    <TableCell></TableCell>
                )}
                <TableCell align="center">
                    <Button
                        aria-label="expand row"
                        size="small"
                        className="models-btn"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        Kv-värden
                    </Button>
                </TableCell>
            </StyledTableRow>
            <StyledTableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <Typography variant="h6" gutterBottom component="div">
                                Kv-värden
                            </Typography>
                            <Table size="small">
                                <TableHead>
                                    <StyledTableRow>
                                        <TableCell sx={{ width: "130px" }}>Dimension</TableCell>
                                        <TableCell>Kv-Värde</TableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {dimData.map((dimRow) => {
                                        return (
                                            <StyledTableRow>
                                                <TableCell>{dimRow?.display}</TableCell>
                                                <TableCell>{showKVval(dimRow)}</TableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </StyledTableRow>
        </>
    )
}

export const SystemSpecAdjustValve = () => {
    const firestore = useFirestore();

    const conTypeDoc = collection(firestore, '/AdjustmentValves/');
    const dimsList = collection(firestore, '/ShuntDims/')
    const firestoreRes = useFirestoreCollectionData(conTypeDoc);
    const firestoreDimRes = useFirestoreCollectionData(dimsList);

    const [adjustValveData, setAdjustValveData] = useState([]);
    const [dimData, setDimData] = useState([]);

    useEffect(() => {
        if (firestoreRes.data != null) {
            //console.log("Data: ", firestoreRes.data)
            setAdjustValveData(firestoreRes.data);
        }

    }, [firestoreRes])

    useEffect(() => {
        if (firestoreDimRes.data != null) {
            //console.log("Data Dim: ", firestoreDimRes.data)
            setDimData(firestoreDimRes.data);
        }

    }, [firestoreDimRes])

    return (
        <Box sx={{ padding: "20px" }}>
            <TableContainer component={Paper} sx={{ width: '800px' }}>
                <Table sx={{ paddingRight: '20px' }} size="small" aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Märke</TableCell>
                            <TableCell>Förvalt standard</TableCell>
                            <TableCell>Förvalt green*</TableCell>
                            <TableCell>Förvalt flex</TableCell>
                            <TableCell>Förvalt smart</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {adjustValveData.map((valve) => {
                            return (
                                <Row valve={valve} dimData={dimData} />
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography style={{ paddingLeft: '10px', paddingTop: '3px' }}>
                * Fritt användarval mellan STAD ZERO/STAF respektive Purmo Evobalance/STV även i Automatiskt läge. STAD ZERO/STAF förvalt
            </Typography>
        </Box>
    )
}