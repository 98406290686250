import { getTechRowText } from "./getTechRowText";

const { getComponentText } = require("./getComponentText");

export const createComponentList = (shuntData, calcParams, productLineParams, shuntParams, adjustParams, pumpParams, driftParams, wizardParams, systemFluidOptions) => {
    let compList = [];

    compList.push({key: "blockInfo", data: getComponentText('blockInfo', false, shuntData, systemFluidOptions, calcParams, productLineParams, shuntParams, adjustParams, pumpParams, driftParams, wizardParams)});
    compList.push({key: "greenException", data: getComponentText('greenException', false, shuntData, systemFluidOptions, calcParams, productLineParams, shuntParams, adjustParams, pumpParams, driftParams, wizardParams)});
    compList.push({key: "returnVentsSabo", data: getComponentText('returnVentsSabo', false, shuntData, calcParams, productLineParams, shuntParams, adjustParams, pumpParams, driftParams, wizardParams)});
    compList.push({key: "material", data: getComponentText('material', false, shuntData, systemFluidOptions, calcParams, productLineParams, shuntParams, adjustParams, pumpParams, driftParams, wizardParams)});
    compList.push({key: "cabinet", data: getComponentText('cabinet', false, shuntData, systemFluidOptions, calcParams, productLineParams, shuntParams, adjustParams, pumpParams, driftParams, wizardParams)});
    compList.push({key: "insulation", data: getComponentText('insulation', false, shuntData, systemFluidOptions, calcParams, productLineParams, shuntParams, adjustParams, pumpParams, driftParams, wizardParams)});
    compList.push({key: "controlValve", data: getComponentText('controlValve', false, shuntData, systemFluidOptions, calcParams, productLineParams, shuntParams, adjustParams, pumpParams, driftParams, wizardParams)});
    compList.push({key: "actuators", data: getComponentText('actuators', false, shuntData, systemFluidOptions, calcParams, productLineParams, shuntParams, adjustParams, pumpParams, driftParams, wizardParams)});
    compList.push({key: "adjustValve", data: getComponentText('adjustValve', false, shuntData, systemFluidOptions, calcParams, productLineParams, shuntParams, adjustParams, pumpParams, driftParams, wizardParams)});
    compList.push({key: "cutOff", data: getComponentText('cutOff', false, shuntData, systemFluidOptions, calcParams, productLineParams, shuntParams, adjustParams, pumpParams, driftParams, wizardParams)});
    compList.push({key: "thermo", data: getComponentText('thermo', false, shuntData, systemFluidOptions, calcParams, productLineParams, shuntParams, adjustParams, pumpParams, driftParams, wizardParams)});
    compList.push({key: "returnVents", data: getComponentText('returnVents', false, shuntData, systemFluidOptions, calcParams, productLineParams, shuntParams, adjustParams, pumpParams, driftParams, wizardParams)});
    compList.push({key: "extraBV", data: getComponentText('extraBV', false, shuntData, systemFluidOptions, calcParams, productLineParams, shuntParams, adjustParams, pumpParams, driftParams, wizardParams)});
    compList.push({key: "drainValve", data: getComponentText('drainValve', false, shuntData, systemFluidOptions, calcParams, productLineParams, shuntParams, adjustParams, pumpParams, driftParams, wizardParams)});
    compList.push({key: "pump", data: getComponentText('pump', false, shuntData, systemFluidOptions, calcParams, productLineParams, shuntParams, adjustParams, pumpParams, driftParams, wizardParams)});
    compList.push({key: "dripPlate", data: getComponentText('dripPlate', false, shuntData, systemFluidOptions, calcParams, productLineParams, shuntParams, adjustParams, pumpParams, driftParams, wizardParams)});
    compList.push({key: "stand", data: getComponentText('stand', false, shuntData, systemFluidOptions, calcParams, productLineParams, shuntParams, adjustParams, pumpParams, driftParams, wizardParams)});
    compList.push({key: "manometer", data: getComponentText('manometer', false, shuntData, systemFluidOptions, calcParams, productLineParams, shuntParams, adjustParams, pumpParams, driftParams, wizardParams)});
    compList.push({key: "systemFluid", data: getComponentText('systemFluid', false, shuntData, systemFluidOptions, calcParams, productLineParams, shuntParams, adjustParams, pumpParams, driftParams, wizardParams)});
    compList.push({key: "infoSign", data: getComponentText('infoSign', false, shuntData, systemFluidOptions, calcParams, productLineParams, shuntParams, adjustParams, pumpParams, driftParams, wizardParams)});

    let completedList = [];

    compList.forEach((obj) => {
        //console.log("Obj", obj);
        if (obj != null && obj.data != null && obj.data.length > 0){
            //console.log("Obj", true);
            obj.data.forEach((row) => {
                if (obj.key === 'cutOff'){
                    if (String(row.text).includes('(prim/sek)') === true){
                        completedList.push({Item: 'CutOffValve', Count: String(row.num), Value: row.text})
                    }
                    else if (String(row.text).includes('(prim)') === true){
                        completedList.push({Item: 'CutOffValvePrim', Count: String(row.num), Value: row.text})
                    }
                    else if (String(row.text).includes('(sek)') === true){
                        completedList.push({Item: 'CutOffValveSec', Count: String(row.num), Value: row.text})
                    }
                }
                else if (obj.key === 'adjustValve'){
                    if (String(row.text).includes('(prim/sek)') === true){
                        completedList.push({Item: 'RegValve', Count: String(row.num), Value: row.text})
                    }
                    else if (String(row.text).includes('(prim)') === true){
                        completedList.push({Item: 'RegValvePrim', Count: String(row.num), Value: row.text})
                    }
                    else if (String(row.text).includes('(sek)') === true){
                        completedList.push({Item: 'RegValveSec', Count: String(row.num), Value: row.text})
                    }
                }
                else if (row.num != null){
                    completedList.push({Item: labelTable[obj.key], Count: String(row.num), Value: row.text})
                }
            })
        }
    })
    return completedList;
}

export const createTechInfoList = (shuntData, calcParams, productLineParams, shuntParams, adjustParams) => {
    let compList = [];

    compList.push({key: "flow", data: getTechRowText('flow', false, shuntData, calcParams, productLineParams, shuntParams, adjustParams)});
    compList.push({key: "pressureDropPrim", data: getTechRowText('pressureDropPrim', false, shuntData, calcParams, productLineParams, shuntParams, adjustParams)});
    compList.push({key: "pressureDropSec", data: getTechRowText('pressureDropSec', false, shuntData, calcParams, productLineParams, shuntParams, adjustParams)});
    compList.push({key: "mainPump", data: getTechRowText('mainPump', false, shuntData, calcParams, productLineParams, shuntParams, adjustParams)});
    compList.push({key: "externalPressureDropSec", data: getTechRowText('externalPressureDropSec', false, shuntData, calcParams, productLineParams, shuntParams, adjustParams)});
    compList.push({key: "balancingKV", data: getTechRowText('balancingKV', false, shuntData, calcParams, productLineParams, shuntParams, adjustParams)});

    let completedList = [];

    compList.forEach((obj) => {
        //console.log("Obj", obj);
        if (obj != null && obj.data != null && obj.data.length > 0){
            //console.log("Obj", true);
            obj.data.forEach((row) => {
                completedList.push({Item: obj.key, Value: row})
            })
        }
    })
    return completedList;
}

const labelTable = {
    blockInfo: 'ShuntGroup',
    material: 'Material',
    insulation: 'IsolationMaterial',
    controlValve: 'ControlValve',
    actuators: 'Actuator',
    thermo: 'Thermometers',
    returnVents: 'BackValve',
    extraBV: 'BackValveExtra',
    pump: 'Pump',
    stand: 'Stand',
    manometer: 'ManometerSet',
    cabinet: 'IsolationBox',
    dripPlate: 'DripPlate',
    drainValve: "DrainValve",

}