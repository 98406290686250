export const HOVER_TEXT_DEFAULT = "När alla fält är korrekt ifyllda kan du gå vidare. För muspekaren över textfälten för att visa mer information.";

export const HOVER_TEXT_PRIM_TEMP_IN = "Temperatur primärt till shuntgruppen";
export const HOVER_TEXT_PRIM_TEMP_OUT = "Temperatur primär retur";
export const HOVER_TEXT_PRIM_EXTERNAL_PRESSURE_DROP_PUMP = "Tryckfall i det primära systemet som pumpen på shuntgruppen ska klara";
export const HOVER_TEXT_PRIM_DRIVING_PRESSURE_PUMP_ROW1 = "För system med tillgängligt drivtryck (huvudpump) anges tillgängligt drivtryck."
export const HOVER_TEXT_PRIM_DRIVING_PRESSURE_PUMP_ROW2 = "För system utan tilgängligt drivtryck väljs \"Utan huvudpump\" till höger och tryckfallet i det primära system som pumpen på shuntgruppen ska klara anges."
export const HOVER_TEXT_PRIM_EXTERNAL_PRESSURE_DROP_BATTERY = "Tryckfall i tilluftsbatteri med tillhörande rörsystem";
export const HOVER_TEXT_PRIM_DRIVING_PRESSURE = "Tillgängligt drivtryck i primärkretsen";
export const HOVER_TEXT_PRIM_FLOW = "Flöde i anslutet primärsystem";

export const HOVER_TEXT_SEC_TEMP_IN = "Temperatur sekundär tillopp";
export const HOVER_TEXT_SEC_TEMP_OUT = "Temperatur sekundär retur";
export const HOVER_TEXT_PRIM_SEC_TEMP_IN = "Temperatur sekundärt tillopp / primärt tillopp";
export const HOVER_TEXT_PRIM_SEC_TEMP_OUT = "Temperatur sekundärt retur / primärt retur";
export const HOVER_TEXT_SEC_EXTERNAL_PRESSURE_DROP = "Tryckfall i tillkopplad sekundär krets";
export const HOVER_TEXT_SEC_EXTERNAL_PRESSURE_DROP_BATTERY = "Tryckfall i frånluftsbatteri med tillhörande rörsystem";
export const HOVER_TEXT_SEC_FLOW = "Flöde i anslutet sekundärsystem";
export const HOVER_TEXT_FLOW = "Flöde";

export const HOVER_TEXT_SEC_FLOW_EFFECT = (/** @type {Boolean} */ calculated) => calculated ? "Effekt i anslutet sekundärsystem (Kan beräknas om systemvätska är specificerad)" :  "Effekt i anslutet sekundärsystem";
export const HOVER_TEXT_SEC_FLOW_EFFECT_KNOWN = "Kan beräknas om systemvätska är specificerad";

export const HOVER_TEXT_OVERVIEW_ICON_CHANGED_IMAGE = "Ersatt bild";
export const HOVER_TEXT_OVERVIEW_ICON_COMMENT_EXISTS = "Anmärkning/förtydligande finns";
export const HOVER_TEXT_OVERVIEW_ICON_PRICE_SET = "Pris sparat";
export const HOVER_TEXT_OVERVIEW_ICON_SPEC_MODIFIED = "Specifikation modifierad"
export const HOVER_TEXT_OVERVIEW_ICON_SPEC_MODIFIED_CHANGED = "Specifikation modifierad (Shuntgrupp ändrad efter specifikationen sparades)";

export const HOVER_TEXT_OVERVIEW_SHARE_BLOCKED_MODIFIED_SPEC = "Sessionen innehåller shuntgrupper med modifierad specifikation som har konfigurerats om efter specifikationen modifierades. Kontrollera och återställ/ändra vid behov innan sessionen delas"

export const HOVER_TEXT_SPEC_HEADER_MANUAL_EDIT_WARNING = "Manuellt redigerat specifikationsstycke. Inga konfigurationsändringar sedan senaste editering"
export const HOVER_TEXT_SPEC_HEADER_MANUAL_EDIT_IS_CHANGED = "Shuntgruppen har konfigurerats om efter specifikationens stycke senast modifierades. Kontrollera om specifikationen fortfarande stämmer och återställ/ändra vid behov"
