import { Paper, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material"
import { MANOMETER_10_BAR, MANOMETER_2_5_BAR, MANOMETER_4_BAR, MANOMETER_6_BAR, NO_MANOMETER } from "../../../../../constants/adjustmentsOptions"
import { AdjustmentsEnum } from "../../../../../enums"

export const AdjustCardManometer = ({ manometerValue, setAdjustParams, isDisabled }) => {

    return (
        <div>
        <Paper elevation={0} className="adjust-card">
            <FormControl>
                <FormLabel id="manometer-set-radio-buttons-group">Manometersats</FormLabel>
                <RadioGroup
                    aria-labelledby="manometer-set-radio-buttons-group"
                    name="manometer-set"
                    value={manometerValue}
                    onChange={(event) => { setAdjustParams(event.target.value, AdjustmentsEnum.ManometerValue) }}
                >
                    <FormControlLabel value={NO_MANOMETER} control={<Radio />} label="Ingen manometersats" disabled={isDisabled()} />
                    <FormControlLabel value={MANOMETER_2_5_BAR} control={<Radio />} label="Manometersats 0-2,5 bar" disabled={isDisabled()} />
                    <FormControlLabel value={MANOMETER_4_BAR} control={<Radio />} label="Manometersats 0-4 bar" disabled={isDisabled()} />
                    <FormControlLabel value={MANOMETER_6_BAR} control={<Radio />} label="Manometersats 0-6 bar" disabled={isDisabled()} />
                    <FormControlLabel value={MANOMETER_10_BAR} control={<Radio />} label="Manometersats 0-10 bar" disabled={isDisabled()} />

                </RadioGroup>
            </FormControl>
        </Paper>
    </div>
    )
}