import { PRODUCTPRINCIP_PR2, PRODUCTPRINCIP_PR3 } from "../../../constants/productlineConstants";

/**
 * 
 * @param {Array} dimensionData 
 * @param {*} currentKvs 
 * @returns 
 */
const getNextDimKvs = function getNextDimKvs(dimensionData, currentKvs) {
    var result = dimensionData.findIndex(obj => {
        return obj.kvs === currentKvs;
    });

    if (result+1 < dimensionData.length){
        return dimensionData[result+1].kvs;
    }
    else{
        //console.error("Already on highest possible dim!")
        return null;
    }
}

/**
 * 
 * @param {Array} dimensionData 
 * @param {*} currentKvs 
 * @returns 
 */
 const getNextLowerDimKvs = function getNextLowerDimKvs(dimensionData, currentKvs) {
    var result = dimensionData.findIndex(obj => {
        return obj.kvs === currentKvs;
    });

    if (result-1 >= 0){
        return dimensionData[result-1].kvs;
    }
    else{
        //console.error("Already on lowest possible dim!")
        return null;
    }
    
}

const getNextSmartDimKvs = function getNextSmartDimKvs(dimensionData, smartDims, dimID) {
    var result = smartDims.findIndex(obj => {
        return obj.id === dimID;
    });
    
    if (result+1 < smartDims.length){
        return smartDims[result+1].data.kvs;
    }
    else{
        //console.error("Already on highest possible dim!")
        return null;
    }
    
}

const getDimKvs = function getDimKvs(dimensionData, currentKvs, prodPrincip) {
    let foundKvs = 0;
    for (const dim of dimensionData) {
        let kvs;
        if (prodPrincip === PRODUCTPRINCIP_PR2){
            kvs = dim.kvsPr2;
        }
        else if (prodPrincip === PRODUCTPRINCIP_PR3){
            kvs = dim.kvsPr3;
        }
        else{
            kvs = dim.kvs;
        }
        
        if (currentKvs <= kvs){
            foundKvs = kvs;
            break;
        }
    }

    return foundKvs;
}

const getDimKvs_Custom = function getDimKvs_Custom(dimensionData, currentKvs) {
    let foundKvs = 0;

    for (const dim of dimensionData) {
        let kvs = dim
        
        if (currentKvs <= kvs){
            foundKvs = kvs;
            break;
        }
    }

    return foundKvs;
}

export { getNextDimKvs, getDimKvs, getNextSmartDimKvs, getNextLowerDimKvs, getDimKvs_Custom }