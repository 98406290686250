import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Landing from './pages/Landing';
import Home from './pages/Home';

import { ContextProvider } from './provider/ContextProviders';
import { Footer } from './components/modules/footer/footer';
import HorizontalLinearStepper from './components/modules/stepper/stepper';
import { ErrorBoundary } from 'react-error-boundary'

import './App.css'
import { OpenLinkModule } from './components/modules/link/openLink';
import { ErrorPage } from './components/modules/errorHandling/errorBoundaries';
import { NotFound } from './pages/NotFound';
import Magicad from './pages/Magicad';
import { useEffect } from 'react';
import Intro from './pages/Intro';
import CacheBuster from 'react-cache-buster';

import { LinearProgress } from '@mui/material';
import CacheLoading from './pages/CacheLoading';
import Invite from './pages/Invite';
import HeaderBar from './components/modules/navbar/navbar';

export const App = () => {
    const isProduction = process.env.NODE_ENV === 'production';

    function ErrorFallback({ error }) {
        return (
            <div role="alert">
                <ErrorPage error={error} />
            </div >
        )
    }

    function renderLogo() {
        //Important :P
        //console.clear();
        console.log("\n" +
            " ██████╗██████╗  ██████╗    ███╗   ██╗ ██████╗ ██████╗ ██████╗ ██╗ ██████╗\n" +
            "██╔════╝██╔══██╗██╔════╝    ████╗  ██║██╔═══██╗██╔══██╗██╔══██╗██║██╔════╝\n" +
            "██║     ██████╔╝██║         ██╔██╗ ██║██║   ██║██████╔╝██║  ██║██║██║     \n" +
            "██║     ██╔══██╗██║         ██║╚██╗██║██║   ██║██╔══██╗██║  ██║██║██║     \n" +
            "╚██████╗██║  ██║╚██████╗    ██║ ╚████║╚██████╔╝██║  ██║██████╔╝██║╚██████╗\n" +
            " ╚═════╝╚═╝  ╚═╝ ╚═════╝    ╚═╝  ╚═══╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝ ╚═╝ ╚═════╝");
    }

    useEffect(() => {
        let fixedWidth = 1400;
        let fixedHeight = 980;

        window.resizeTo(fixedWidth, fixedHeight)
    }, []);

    return (
        <CacheBuster
            currentVersion={process.env.REACT_APP_VERSION}
            isEnabled={isProduction} //If false, the library is disabled.
            isVerboseMode={false} //If true, the library writes verbose logs to console.
            loadingComponent={<CacheLoading />} //If not pass, nothing appears at the time of new version check.
            metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
            reloadOnDowngrade={true}
        >
            <div className="flex flex-wrap justify-around p-4">
                {renderLogo()}
                <ContextProvider>
                    <BrowserRouter>
                        <HeaderBar />
                        {/* @ts-ignore */}
                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                            <div>
                                <Routes>
                                    <Route
                                        // @ts-ignore
                                        exact path="/" element={<Landing />} />
                                    <Route path="/home" element={<Home />} />
                                    <Route path="/stepper" element={<HorizontalLinearStepper />} />
                                    <Route path="/link" element={<OpenLinkModule />} />
                                    <Route path='/magicad' element={<Magicad />} />
                                    <Route path='/intro' element={<Intro />} />
                                    <Route path='/introduction' element={<Intro />} />
                                    <Route path='/invite' element={<Invite />} />
                                    <Route path="*" element={<NotFound />} />
                                </Routes>
                            </div>
                        </ErrorBoundary>
                    </BrowserRouter>
                    <Footer />
                </ContextProvider>
            </div>
        </CacheBuster>
    );
};
