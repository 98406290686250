import { httpsCallable } from "firebase/functions"

/**
 * 
 * @param {import("firebase/functions").Functions} functions 
 * @param {string} email 
 * @returns 
 */
const sendPasswordResetMail = function sendPasswordResetMail(functions, email) {
    functions.region = 'europe-west1';
    const sendPasswordResetMailFunc = httpsCallable(functions, "sendPasswordResetMail");

    return sendPasswordResetMailFunc({ 
        email: email,
    });
}

export { sendPasswordResetMail }