import { FormControl, Select, MenuItem, InputLabel, TextField, Stack, Typography } from "@mui/material"
import { QUESTION_SECONDARY_FLOW_KEY, QUESTION_SECONDARY_FLOW_SPEC_VALUE_KEY, SECONDARY_FLOW_OPTIONS } from "../../../../constants/wizardConstants"

export const SecondaryFlowQuestion = ({ secondaryFlow, secondarySpecFlow, answerQuestion }) => {

    return (
        <>
            <Stack spacing={0.5}>
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                    <FormControl size="small">
                        <InputLabel>Flöde (sekundärt)</InputLabel>
                        <Select
                            sx={{ width: '180px', height: '30px', background: 'white' }}
                            size="small"
                            value={secondaryFlow}
                            label={'Flöde (sekundärt)'}
                            onChange={(e) => answerQuestion(QUESTION_SECONDARY_FLOW_KEY, e.target.value)}
                        >
                            {SECONDARY_FLOW_OPTIONS.map((option) => (
                                <MenuItem key={option.label} value={option.level}>{option.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {secondaryFlow != null && Number(secondaryFlow) === -1 ? (
                        <TextField
                            size="medium"
                            sx={{ width: '150px' }}
                            inputProps={{
                                style: {
                                    height: '30px',
                                    padding: '0 14px',
                                    background: 'white'
                                },
                            }}
                            label="l/s"
                            type="number"
                            value={secondarySpecFlow}
                            onChange={(e) => answerQuestion(QUESTION_SECONDARY_FLOW_SPEC_VALUE_KEY, e.target.value)}
                        />
                    ) : ("")}


                </Stack>
                {secondarySpecFlow != null && Number(secondarySpecFlow) > 50 ? (
                    <Typography fontStyle={'italic'} sx={{paddingLeft: '5px', color: 'GrayText'}}>
                        Kontakta PREMA vid flöden över 50 l/s
                    </Typography>
                ) : ("")}
            </Stack>
        </>
    );
}