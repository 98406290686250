'use client'

import { createContext } from 'react';

export const AuthContext = createContext({
    user: null,
    loadingUser: true,
    loggingOutUser: false,
    firstLoadRef: null,
    handleLogout: () => { },
    handleChangePassword: (oldPassword, newPassword) => { },
    handleResetPassword: (email) => { },
    handleInactivateAccount: () => { },
    handleVerifyEmail: () => { },
});

