import { ref, set } from "firebase/database";
import { SESSION_SHUNT_PATH, SHUNT_WIZARDDATA } from "../constants/firebasePaths";
import { ContextEnum } from "./contextEnum";
import { WIZARD_APPLICATION_INTENT_KEY, WIZARD_APPLICATION_KEY, WIZARD_APPLICATION_SIZE_KEY, WIZARD_AVAIL_DRIVING_PRESSURE_KEY, WIZARD_CERTICIFACTIONS_KEY, WIZARD_DYNAMIC_CONTROL_VALVE_KEY, WIZARD_ENERGY_MEASUREMENT_KEY, WIZARD_HAS_MAIN_PUMP_KEY, WIZARD_LIMITED_SPACE_KEY, WIZARD_ONLY_DIMENSIONABLE_KEY, WIZARD_PRIORITIES_KEY, WIZARD_SECONDARY_FLOW_KEY, WIZARD_SECONDARY_FLOW_SPEC_VAL_KEY, WIZARD_SET_APPLICATION, WIZARD_SET_APPLICATION_INTENT, WIZARD_SET_APPLICATION_SIZE, WIZARD_SET_AVAIL_DRIVING_PRESSURE, WIZARD_SET_CERTICIFACTIONS, WIZARD_SET_DYNAMIC_CONTROL_VALVE, WIZARD_SET_ENERGY_MEASUREMENT, WIZARD_SET_HAS_MAIN_PUMP, WIZARD_SET_LIMITED_SPACE, WIZARD_SET_ONLY_DIMENSIONABLE, WIZARD_SET_PRIORITIES, WIZARD_SET_SECONDARY_FLOW, WIZARD_SET_SECONDARY_FLOW_SPEC_VAL, WIZARD_SET_SOURCE, WIZARD_SET_SOURCE_FLOW, WIZARD_SET_SPECIFIC_AVAIL_DRIVING_PRESSURE, WIZARD_SET_STAINLESS, WIZARD_SET_SYSTEM_FLUID_MAIN, WIZARD_SET_SYSTEM_FLUID_PRIMARY, WIZARD_SET_SYSTEM_FLUID_PRIMARY_MIX_RATIO, WIZARD_SET_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN, WIZARD_SET_SYSTEM_FLUID_SECONDARY, WIZARD_SET_SYSTEM_FLUID_SECONDARY_MIX_RATIO, WIZARD_SET_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN, WIZARD_SET_VARIABLE_AVAIL_DRIVING_PRESSURE, WIZARD_SOURCE_FLOW_KEY, WIZARD_SOURCE_KEY, WIZARD_SPECIFIC_AVAIL_DRIVING_PRESSURE_KEY, WIZARD_STAINLESS_KEY, WIZARD_SYSTEM_FLUID_MAIN_KEY, WIZARD_SYSTEM_FLUID_PRIMARY_KEY, WIZARD_SYSTEM_FLUID_PRIMARY_MIX_RATIO_KEY, WIZARD_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_KEY, WIZARD_SYSTEM_FLUID_SECONDARY_KEY, WIZARD_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY, WIZARD_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_KEY, WIZARD_VARIABLE_AVAIL_DRIVING_PRESSURE_KEY } from "../constants/keys/wizardKeys";

export class WizardParamEnum extends ContextEnum {
    static Source = new WizardParamEnum(WIZARD_SOURCE_KEY, WIZARD_SET_SOURCE);
    static SourceFlow = new WizardParamEnum(WIZARD_SOURCE_FLOW_KEY, WIZARD_SET_SOURCE_FLOW);
    static Application = new WizardParamEnum(WIZARD_APPLICATION_KEY, WIZARD_SET_APPLICATION);
    static ApplicationIntent = new WizardParamEnum(WIZARD_APPLICATION_INTENT_KEY, WIZARD_SET_APPLICATION_INTENT);
    static ApplicationSize = new WizardParamEnum(WIZARD_APPLICATION_SIZE_KEY, WIZARD_SET_APPLICATION_SIZE);
    static OnlyDimensionable = new WizardParamEnum(WIZARD_ONLY_DIMENSIONABLE_KEY, WIZARD_SET_ONLY_DIMENSIONABLE);
    static SecondaryFlow = new WizardParamEnum(WIZARD_SECONDARY_FLOW_KEY, WIZARD_SET_SECONDARY_FLOW);
    static SecondaryFlowSpecVal = new WizardParamEnum(WIZARD_SECONDARY_FLOW_SPEC_VAL_KEY, WIZARD_SET_SECONDARY_FLOW_SPEC_VAL);
    static AvailDrivingPressure = new WizardParamEnum(WIZARD_AVAIL_DRIVING_PRESSURE_KEY, WIZARD_SET_AVAIL_DRIVING_PRESSURE);
    static VariableAvailDrivingPressure = new WizardParamEnum(WIZARD_VARIABLE_AVAIL_DRIVING_PRESSURE_KEY, WIZARD_SET_VARIABLE_AVAIL_DRIVING_PRESSURE);
    static SpecificAvailDrivingPressure = new WizardParamEnum(WIZARD_SPECIFIC_AVAIL_DRIVING_PRESSURE_KEY, WIZARD_SET_SPECIFIC_AVAIL_DRIVING_PRESSURE);
    static HasMainPump = new WizardParamEnum(WIZARD_HAS_MAIN_PUMP_KEY, WIZARD_SET_HAS_MAIN_PUMP);
    static Certifications = new WizardParamEnum(WIZARD_CERTICIFACTIONS_KEY, WIZARD_SET_CERTICIFACTIONS);
    static SystemFluidMain = new WizardParamEnum(WIZARD_SYSTEM_FLUID_MAIN_KEY, WIZARD_SET_SYSTEM_FLUID_MAIN);
    static SystemFluidPrimary = new WizardParamEnum(WIZARD_SYSTEM_FLUID_PRIMARY_KEY, WIZARD_SET_SYSTEM_FLUID_PRIMARY);
    static SystemFluidSecondary = new WizardParamEnum(WIZARD_SYSTEM_FLUID_SECONDARY_KEY, WIZARD_SET_SYSTEM_FLUID_SECONDARY);
    static SystemFluidPrimaryMixRatio = new WizardParamEnum(WIZARD_SYSTEM_FLUID_PRIMARY_MIX_RATIO_KEY, WIZARD_SET_SYSTEM_FLUID_PRIMARY_MIX_RATIO);
    static SystemFluidPrimaryMixRatioUnknown = new WizardParamEnum(WIZARD_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_KEY, WIZARD_SET_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN);
    static SystemFluidSecondaryMixRatio = new WizardParamEnum(WIZARD_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY, WIZARD_SET_SYSTEM_FLUID_SECONDARY_MIX_RATIO);
    static SystemFluidSecondaryMixRatioUnknown = new WizardParamEnum(WIZARD_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_KEY, WIZARD_SET_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN);
    static LimitedSpace = new WizardParamEnum(WIZARD_LIMITED_SPACE_KEY, WIZARD_SET_LIMITED_SPACE);
    static Stainless = new WizardParamEnum(WIZARD_STAINLESS_KEY, WIZARD_SET_STAINLESS);
    static DynamicControlValve = new WizardParamEnum(WIZARD_DYNAMIC_CONTROL_VALVE_KEY, WIZARD_SET_DYNAMIC_CONTROL_VALVE);
    static EnergyMeasurement = new WizardParamEnum(WIZARD_ENERGY_MEASUREMENT_KEY, WIZARD_SET_ENERGY_MEASUREMENT);

    static Priorities = new WizardParamEnum(WIZARD_PRIORITIES_KEY, WIZARD_SET_PRIORITIES);

    setToFirebase(database, shuntID, value) {
        const dbRef = ref(database, SESSION_SHUNT_PATH + shuntID + SHUNT_WIZARDDATA + this.name);
        return set(dbRef, value);
    }
}