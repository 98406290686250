import { ref, set, update } from "firebase/database";
import { SESSION_SHUNT_PATH } from "../../constants/firebasePaths";
import { CALCMODE_KEY, HAS_BACKVENTS_KEY, HAS_MAINPUMP_KEY, IV_MOD_KEY, NUM_BACKVENTS_KEY, PEFK_KEY, PRIM_DRIVING_PRESSURE_KEY, PRIM_EXTERNAL_PRESSURE_DROP_KEY, PRIM_FLOW_KEY, PRIM_TEMP_IN_KEY, PRIM_TEMP_OUT_KEY, SEC_EXTERNAL_PRESSURE_DROP_KEY, SEC_FLOW_EFFECT_KEY, SEC_FLOW_KEY, SEC_TEMP_IN_KEY, SEC_TEMP_OUT_KEY, USE_CONNECTED_ENERGY_KEY } from "../../constants/keys/driftParamsKeys";
import { SessionParamsEnum, DriftParamEnum } from "../../enums";

const saveDriftParams = function saveDriftParams(database, sessionParams, driftParams) {
    const shuntID = SessionParamsEnum.CurrentShunt.getContextValue(sessionParams);

    const driftParamsRef = ref(database, SESSION_SHUNT_PATH + "/" + shuntID + "/DriftParams");

    const snapData = {
        primDim: DriftParamEnum.PrimaryDim.getContextValue(driftParams) || null,
        sekDim: DriftParamEnum.SecondaryDim.getContextValue(driftParams) || null,
    }

    if (DriftParamEnum.PrimTempIn.getContextValue(driftParams) != null && DriftParamEnum.PrimTempIn.getContextValue(driftParams) !== ""){
        snapData[PRIM_TEMP_IN_KEY] = DriftParamEnum.PrimTempIn.getContextValue(driftParams);
    }

    if (DriftParamEnum.SecTempIn.getContextValue(driftParams) != null && DriftParamEnum.SecTempIn.getContextValue(driftParams) !== ""){
        snapData[SEC_TEMP_IN_KEY] = DriftParamEnum.SecTempIn.getContextValue(driftParams);
    }

    if (DriftParamEnum.PrimTempOut.getContextValue(driftParams) != null && DriftParamEnum.PrimTempOut.getContextValue(driftParams) !== ""){
        snapData[PRIM_TEMP_OUT_KEY] = DriftParamEnum.PrimTempOut.getContextValue(driftParams);
    }

    if (DriftParamEnum.SecTempOut.getContextValue(driftParams) != null && DriftParamEnum.SecTempOut.getContextValue(driftParams) !== ""){
        snapData[SEC_TEMP_OUT_KEY] = DriftParamEnum.SecTempOut.getContextValue(driftParams);
    }

    if (DriftParamEnum.PrimFlow.getContextValue(driftParams) != null && DriftParamEnum.PrimFlow.getContextValue(driftParams) !== ""){
        snapData[PRIM_FLOW_KEY] = DriftParamEnum.PrimFlow.getContextValue(driftParams);
    }

    if (DriftParamEnum.SecFlow.getContextValue(driftParams) != null && DriftParamEnum.SecFlow.getContextValue(driftParams) !== ""){
        snapData[SEC_FLOW_KEY] = DriftParamEnum.SecFlow.getContextValue(driftParams);
    }

    if (DriftParamEnum.HasMainPump.getContextValue(driftParams) != null && DriftParamEnum.HasMainPump.getContextValue(driftParams) !== ""){
        snapData[HAS_MAINPUMP_KEY] = DriftParamEnum.HasMainPump.getContextValue(driftParams);
    }

    if (DriftParamEnum.PrimDrivingPressure.getContextValue(driftParams) != null && DriftParamEnum.PrimDrivingPressure.getContextValue(driftParams) !== ""){
        snapData[PRIM_DRIVING_PRESSURE_KEY] = DriftParamEnum.PrimDrivingPressure.getContextValue(driftParams);
    }

    if (DriftParamEnum.SecExternalPressureDrop.getContextValue(driftParams) != null && DriftParamEnum.SecExternalPressureDrop.getContextValue(driftParams) !== ""){
        snapData[SEC_EXTERNAL_PRESSURE_DROP_KEY] = DriftParamEnum.SecExternalPressureDrop.getContextValue(driftParams);
    }

    if (DriftParamEnum.PrimExternalPressureDrop.getContextValue(driftParams) != null && DriftParamEnum.PrimExternalPressureDrop.getContextValue(driftParams) !== ""){
        snapData[PRIM_EXTERNAL_PRESSURE_DROP_KEY] = DriftParamEnum.PrimExternalPressureDrop.getContextValue(driftParams);
    }

    if (DriftParamEnum.NumBackVents.getContextValue(driftParams) != null && DriftParamEnum.NumBackVents.getContextValue(driftParams) !== ""){
        snapData[NUM_BACKVENTS_KEY] = DriftParamEnum.NumBackVents.getContextValue(driftParams);
    }

    if (DriftParamEnum.HasBackVent.getContextValue(driftParams) != null && DriftParamEnum.HasBackVent.getContextValue(driftParams) !== ""){
        snapData[HAS_BACKVENTS_KEY] = DriftParamEnum.HasBackVent.getContextValue(driftParams);
    }

    if (DriftParamEnum.PEfk.getContextValue(driftParams) != null && DriftParamEnum.PEfk.getContextValue(driftParams) !== ""){
        snapData[PEFK_KEY] = DriftParamEnum.PEfk.getContextValue(driftParams);
    }

    if (DriftParamEnum.SecFlowEffect.getContextValue(driftParams) != null && DriftParamEnum.SecFlowEffect.getContextValue(driftParams) !== ""){
        snapData[SEC_FLOW_EFFECT_KEY] = DriftParamEnum.SecFlowEffect.getContextValue(driftParams);
    }

    if (DriftParamEnum.UseConnectedEnergy.getContextValue(driftParams) != null && DriftParamEnum.UseConnectedEnergy.getContextValue(driftParams) !== ""){
        snapData[USE_CONNECTED_ENERGY_KEY] = DriftParamEnum.UseConnectedEnergy.getContextValue(driftParams);
    }

    if (DriftParamEnum.IVModification.getContextValue(driftParams) != null && DriftParamEnum.IVModification.getContextValue(driftParams) !== ""){
        snapData[IV_MOD_KEY] = DriftParamEnum.IVModification.getContextValue(driftParams);
    }

    if (DriftParamEnum.CalcMode.getContextValue(driftParams) != null && DriftParamEnum.CalcMode.getContextValue(driftParams) !== ""){
        snapData[CALCMODE_KEY] = DriftParamEnum.CalcMode.getContextValue(driftParams);
    }

    const promises = [];

    let sendData = false;

    for (let index = 0; index < Object.keys(snapData).length; index++) {
        const element = Object.keys(snapData)[index];
        const value = snapData[element];
        if (value != null) {
            sendData = true;
        };
    }

    if (sendData === true && SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true) {
        promises.push(update(driftParamsRef, snapData));
    }


    return Promise.all(promises);
}

const clearDriftData = function clearDriftData(database, sessionParams) {
    const shuntID = SessionParamsEnum.CurrentShunt.getContextValue(sessionParams);

    const driftParamsRef = ref(database, SESSION_SHUNT_PATH + "/" + shuntID + "/DriftParams");

    const promises = [];

    if (SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true){
       promises.push(set(driftParamsRef, null)); 
    }

    return Promise.all(promises);
}

export { saveDriftParams, clearDriftData } 