import { CONN_VALUES_LABELS, CONN_LATCH_HEAT_LABEL, CONN_LATCH_COOL_LABEL, INSULATION_LABELS, THERMO_MULTIPLE_LABELS, CUTOFF_LABELS_UNDER_DN65, CUTOFF_LABELS_OVER_DN65, THERMO_MULTIPLE_LABELS_GREEN } from "../../constants/adjustmentsLables";
import { INSULATION_13ARMAFLEX, INSULATION_13ULTIMA, INSULATION_19ARMAFLEX, INSULATION_19ULTIMA, MATERIAL_PAINTED, MATERIAL_STAINLESS, CABINET_UNINSULATED, CABINET_INSULATED, ADJUSTVALUE_STAD_ZERO, STAND_FLOOR, NO_MANOMETER, CONN_VALUE_NOT_SPECIFIED, NO_MIXINGVESSEL, INSULATION_13NH_SMART, INSULATION_19NH_SMART, CONN_DESIGN_U } from "../../constants/adjustmentsOptions";
import { MINMAX_TEMPERATURE_TEXT_18_90, MINMAX_TEMPERATURE_TEXT_2_90, MINMAX_TEMPERATURE_TEXT_MINUS_5_90, MATERIAL_PAINTED_TEXT, MATERIAL_STAINLESS_TEXT, CABINET_UNINSULATED_TEXT, CABINET_INSULATED_TEXT, CUTOFF_VENT_TEXT, MIXING_VESSEL_TEXT, MINMAX_TEMPERATURE_TEXT_MINUS_10_90, MATERIAL_UNSPECIFIED_TEXT, CUTOFF_VENT_TEXT_WITHOUT_SOCKET } from "../../constants/componentsTexts";
import { connectionTypeList } from "../../constants/fullConnOptions/connOptionData";
//import { CUSTOM_SHUNT_ADJUST_VALVE_DIM_LABELS, CUSTOM_SHUNT_DIM_LABELS, CUSTOM_SHUNT_INSULATION_TEXT, CUSTOM_SHUNT_MUFF, CUSTOM_SHUNT_NUM_THERMO, CUSTOM_SHUNT_PRESSURE_CLASS, CUSTOM_SHUNT_SAFETY_VALVE, CUSTOM_SHUNT_SEC_CONTROLVALVE_OPTIONS } from "../../constants/customShunts";
import { PRODUCTPRINCIP_SR2, PRODUCTPRINCIP_SR2FLEX, PRODUCTPRINCIP_SR2SMART, PRODUCTPRINCIP_SR3, PRODUCTFAMILY_SR, PRODUCTPRINCIP_PR2, PRODUCTPRINCIP_PR3, PRODUCTFAMILY_PR, PRODUCTPRINCIP_VA1, PRODUCTFAMILY_VA, PRODUCTLINE_STANDARD, PRODUCTLINE_FLEX, PRODUCTLINE_SMART, PRODUCTLINE_GREEN, PRODUCTMODEL_PR2_APPSHUNT, PRODUCTMODEL_SR2_FLEX_APPSHUNT, PRODUCTPRINCIP_SR2_FLEX_APPSHUNT } from "../../constants/productlineConstants";
import { PUMP_GENERAL_REQ, PUMP_LABELS } from "../../constants/pumpOptions";
import { HEAT_SHUNT, CVMODELS_LABELS, CV_SOE, ADJUST_LABELS, ADJUST_VALVE_STADF, CV_MODEL_MXGF, ADJUST_VALVE_PURMOEVO, ADJUST_LABEL_STAD, ADJUST_LABEL_STAD_ZERO, ADJUST_LABEL_PURMO_EVO, ADJUST_LABEL_PURMO_STV, ADJUST_LABEL_STAF, COOL_SHUNT } from "../../constants/shuntOptions";
import { SYSTEM_FLUID } from "../../constants/wizardConstants";
import { CalcParamsEnum, ProductLineEnum, ShuntParamsEnum, AdjustmentsEnum, DriftParamEnum } from "../../enums";
import { PumpParamEnum } from "../../enums/pumpParamsEnum";
import { WizardParamEnum } from "../../enums/wizardParamsEnum";
import { countDecimals } from "../numberFunctions";

export const getComponentText = function getComponentText(component, useCurrent, shuntData, systemFluidOptions, calcParams, productLineParams, shuntParams, adjustParams, pumpParams, driftParams, wizardParams, ) {
    let text = [];
    let primDim;
    let prim2Dim;
    let sekDim;
    let sek2Dim;
    let primDimNum;
    let sekDimNum;

    let primStyrvent;
    let sekStyrvent;

    let primIV;
    let sekIV;
    let primIVdim;
    let sekIVdim;

    let dimString = primDim + "/" + sekDim + " (prim/sek)";

    let primTempIn;
    let primTempOut;
    let secTempIn;
    let secTempOut;

    let plLine;
    let plFamily;
    let plPrincip;
    let plGreenLvl;
    let plModel;
    let cvModel;
    let cvKvs;
    let shuntType;
    let numBV;
    let adjustValveModel;
    let connValue;
    let connDesign;
    let isCustom;

    let material;
    let cabinet;
    let insulation;
    let adjValueVal;
    let cutOffValve;
    let thermo;
    let actuators;
    let dripPlate;
    let standValue;
    let manoValue;
    let mixingVessel;

    let controlValve;
    let numIV;
    let ivMod;
    let hasLatch;
    let isSabo;
    let isMini;
    let isInterval;

    let exchanger;
    let controlValveNWays;
    let controlValve2NWays;

    let numConnections;

    let flexModel;
    let flexDim;
    let flexSVObj;
    let flexSVSetting;

    let smartDim;

    let systemFluid;
    let systemFluidMix;
    let systemFluidMixUnknown;
    let systemFluidObject;
    
    let pumpReqType;
    let pumpReq;
    let specPumpReq;
    let pumpAlarm;
    let pumpPressure;
    let pumpSteering;
    let pumpExternalStop;
    let pumpCard;
    let pumpFluid;

    let isAddon;
    let addonType;
    let addonData;



    if (useCurrent === true) {
        primDim = CalcParamsEnum.PrimDimRow.getContextValue(calcParams)?.name;
        sekDim = CalcParamsEnum.SekDimRow.getContextValue(calcParams)?.name;
        primDimNum = CalcParamsEnum.PrimDimRow.getContextValue(calcParams)?.dim;
        sekDimNum = CalcParamsEnum.SekDimRow.getContextValue(calcParams)?.dim;
        dimString = primDim + "/" + sekDim + " (prim/sek)";

        primIV = CalcParamsEnum.PrimDimRow.getContextValue(calcParams)?.ivName;
        sekIV = CalcParamsEnum.SekDimRow.getContextValue(calcParams)?.ivName;
        primIVdim = CalcParamsEnum.PrimDimRow.getContextValue(calcParams)?.ivDim;
        sekIVdim = CalcParamsEnum.SekDimRow.getContextValue(calcParams)?.ivDim;

        primTempIn = DriftParamEnum.PrimTempIn.getContextValue(driftParams);
        primTempOut = DriftParamEnum.PrimTempOut.getContextValue(driftParams);
        secTempIn = DriftParamEnum.SecTempIn.getContextValue(driftParams);
        secTempOut = DriftParamEnum.SecTempOut.getContextValue(driftParams);

        isInterval = ShuntParamsEnum.CV_IsInterval.getContextValue(shuntParams);

        //Kompatibel med tidigare skapade shuntar
        primStyrvent = CalcParamsEnum.PrimDimRow.getContextValue(calcParams)?.styrVent;
        sekStyrvent = CalcParamsEnum.SekDimRow.getContextValue(calcParams)?.styrVent;

        plLine = ProductLineEnum.ProductLine.getContextValue(productLineParams)
        plFamily = ProductLineEnum.ProductFamily.getContextValue(productLineParams);
        plPrincip = ProductLineEnum.ProductPrincip.getContextValue(productLineParams);
        plGreenLvl = ProductLineEnum.ProductGreenLevel.getContextValue(productLineParams);

        plModel = ShuntParamsEnum.PLModel.getContextValue(shuntParams);
        cvModel = ShuntParamsEnum.CV_Model.getContextValue(shuntParams);
        cvKvs = ShuntParamsEnum.CV_Kvs.getContextValue(shuntParams);
        shuntType = CalcParamsEnum.ShuntType.getContextValue(calcParams);
        adjustValveModel = ShuntParamsEnum.AdjustmentValve.getContextValue(shuntParams);
        numBV = Number(ShuntParamsEnum.NumBV.getContextValue(shuntParams));
        isCustom = ShuntParamsEnum.IsCustomShunt.getContextValue(shuntParams);
        ivMod = DriftParamEnum.IVModification.getContextValue(driftParams);

        connValue = AdjustmentsEnum.ConnectionValue.getContextValue(adjustParams);
        connDesign = AdjustmentsEnum.ConnectionDesign.getContextValue(adjustParams);
        cabinet = AdjustmentsEnum.CabinetValue.getContextValue(adjustParams);
        material = AdjustmentsEnum.MaterialValue.getContextValue(adjustParams);
        insulation = AdjustmentsEnum.IsolationValue.getContextValue(adjustParams);
        adjValueVal = AdjustmentsEnum.AdjustValue.getContextValue(adjustParams);
        cutOffValve = AdjustmentsEnum.CutOffValue.getContextValue(adjustParams);
        thermo = AdjustmentsEnum.ThermoValue.getContextValue(adjustParams);
        actuators = AdjustmentsEnum.ActuatorsValue.getContextValue(adjustParams);
        dripPlate = AdjustmentsEnum.DripPlateValue.getContextValue(adjustParams);
        standValue = AdjustmentsEnum.StandValue.getContextValue(adjustParams);
        manoValue = AdjustmentsEnum.ManometerValue.getContextValue(adjustParams);
        mixingVessel = AdjustmentsEnum.MixingVessels.getContextValue(adjustParams);

        hasLatch = Number(ShuntParamsEnum.HasLatch.getContextValue(shuntParams));
        numIV = Number(ShuntParamsEnum.NumIV.getContextValue(shuntParams));
        controlValve = ShuntParamsEnum.ControlValve.getContextValue(shuntParams);
        isSabo = ShuntParamsEnum.IsSabo.getContextValue(shuntParams);
        isMini = ShuntParamsEnum.IsMini.getContextValue(shuntParams);

        flexModel = ShuntParamsEnum.FlexModel.getContextValue(shuntParams);
        flexDim = CalcParamsEnum.FlexSVDim.getContextValue(calcParams)
        flexSVObj = CalcParamsEnum.FlexSVDimObj.getContextValue(calcParams);
        flexSVSetting = CalcParamsEnum.FlexSVSetting.getContextValue(calcParams);

        smartDim = ShuntParamsEnum.SmartDim.getContextValue(shuntParams);

        pumpReqType = PumpParamEnum.PumpRequestType.getContextValue(pumpParams);
        pumpReq = PumpParamEnum.GeneralPumpRequest.getContextValue(pumpParams);
        specPumpReq = PumpParamEnum.SpecificPumpRequest.getContextValue(pumpParams);
        pumpAlarm = PumpParamEnum.Alarm.getContextValue(pumpParams);
        pumpPressure = PumpParamEnum.Pressure.getContextValue(pumpParams);
        pumpSteering = PumpParamEnum.Steering.getContextValue(pumpParams);
        pumpExternalStop = PumpParamEnum.ExternalStop.getContextValue(pumpParams);
        pumpCard = PumpParamEnum.Card.getContextValue(pumpParams);
        pumpFluid = PumpParamEnum.SystemFluid.getContextValue(pumpParams);

        systemFluid = WizardParamEnum.SystemFluidMain.getContextValue(wizardParams);
        systemFluidMix = WizardParamEnum.SystemFluidPrimaryMixRatio.getContextValue(wizardParams);
        systemFluidMixUnknown = WizardParamEnum.SystemFluidPrimaryMixRatioUnknown.getContextValue(wizardParams);
        systemFluidObject = systemFluidOptions?.find((obj) => obj.value === systemFluid);

        isAddon = ShuntParamsEnum.IsAddon.getContextValue(shuntParams);
        addonType = ShuntParamsEnum.AddonType.getContextValue(shuntParams);
        addonData = ShuntParamsEnum.AddonData.getContextValue(shuntParams);
    }
    else if (shuntData != null) {
        //console.log("ShuntRow:", shuntData);
        primDim = shuntData?.CalcParams?.primDimRow?.name;
        prim2Dim = shuntData?.CalcParams?.prim2DimRow?.name;
        sekDim = shuntData?.CalcParams?.sekDimRow?.name;
        sek2Dim = shuntData?.CalcParams?.sek2DimRow?.name;
        primDimNum = shuntData?.CalcParams?.primDimRow?.dim;
        sekDimNum = shuntData?.CalcParams?.sekDimRow?.dim;
        dimString = primDim + "/" + sekDim + " (prim/sek)";

        primIV = shuntData?.CalcParams?.primDimRow?.ivName;
        sekIV = shuntData?.CalcParams?.sekDimRow?.ivName;
        primIVdim = shuntData?.CalcParams?.primDimRow?.ivDim;
        sekIVdim = shuntData?.CalcParams?.sekDimRow?.ivDim;

        //Kompatibel med tidigare skapade shuntar
        primStyrvent = shuntData?.CalcParams?.primDimRow?.styrVent;
        sekStyrvent = shuntData?.CalcParams?.sekDimRow?.styrVent;

        plLine = shuntData?.PLData?.prodLine;
        plPrincip = shuntData?.PLData?.princip;
        plGreenLvl = shuntData?.PLData?.greenLevel;
        if (plPrincip === PRODUCTPRINCIP_SR2 || plPrincip === PRODUCTPRINCIP_SR2FLEX || plPrincip === PRODUCTPRINCIP_SR2SMART || plPrincip === PRODUCTPRINCIP_SR3) {
            plFamily = PRODUCTFAMILY_SR;
        }
        else if (plPrincip === PRODUCTPRINCIP_PR2 || plPrincip === PRODUCTPRINCIP_PR3) {
            plFamily = PRODUCTFAMILY_PR;
        }
        else if (plPrincip === PRODUCTPRINCIP_VA1) {
            plFamily = PRODUCTFAMILY_VA;
        }

        plModel = shuntData?.ShuntData?.plModel;
        cvModel = shuntData?.ShuntData?.cvModel;
        cvKvs = shuntData?.ShuntData?.cvKvs;
        shuntType = shuntData?.CalcParams?.shuntType;
        adjustValveModel = shuntData?.ShuntData?.adjValve;
        numBV = Number(shuntData?.ShuntData?.numBV);
        isSabo = shuntData?.ShuntData?.isSabo;
        isMini = shuntData?.ShuntData?.isMini;
        isCustom = shuntData?.Baseinfo?.isCustom;

        connValue = shuntData?.AdjustData?.connectionValue;
        connDesign = shuntData?.AdjustData?.connectionDesign;
        cabinet = shuntData?.AdjustData?.cabinet
        material = shuntData?.AdjustData?.material;
        insulation = shuntData?.AdjustData?.isolation;
        adjValueVal = shuntData?.AdjustData?.adjustValue;
        cutOffValve = shuntData?.AdjustData?.cutOff;
        thermo = shuntData?.AdjustData?.thermo;
        actuators = shuntData?.AdjustData?.actuators;
        dripPlate = shuntData?.AdjustData?.dripPlate;
        standValue = shuntData?.AdjustData?.stand;
        manoValue = shuntData?.AdjustData?.manometer;
        mixingVessel = shuntData?.AdjustData?.mixingVessels;

        primTempIn = shuntData?.DriftParams?.primTempIn;
        primTempOut = shuntData?.DriftParams?.primTempOut;
        secTempIn = shuntData?.DriftParams?.secTempIn;
        secTempOut = shuntData?.DriftParams?.secTempOut;
        ivMod = shuntData?.DriftParams?.ivMod;
        isInterval = shuntData?.ShuntData?.cvIsInterval;

        hasLatch = Number(shuntData?.ShuntData?.hasLatch);
        numIV = Number(shuntData?.ShuntData?.numIV);
        controlValve = shuntData?.ShuntData?.controlValve;

        exchanger = shuntData?.ShuntData?.exchanger;
        controlValveNWays = shuntData?.ShuntData?.controlValveNWays;
        controlValve2NWays = shuntData?.ShuntData?.controlValve2NWays;

        numConnections = shuntData?.ShuntData?.numConn;

        flexModel = shuntData?.ShuntData?.flexModel;
        flexDim = shuntData?.CalcParams?.svDim;
        flexSVObj = shuntData?.CalcParams?.svDimObj;
        flexSVSetting = shuntData?.CalcParams?.svSetting;

        smartDim = shuntData?.ShuntData?.smartDim;

        pumpReqType = shuntData?.PumpData?.pumpRequestType;
        pumpReq = shuntData?.PumpData?.generalPumpRequest;
        specPumpReq = shuntData?.PumpData?.specificPumpRequest;
        pumpAlarm = shuntData?.PumpData?.alarm;
        pumpPressure = shuntData?.PumpData?.pressure;
        pumpSteering = shuntData?.PumpData?.steering;
        pumpExternalStop = shuntData?.PumpData?.externalStop;
        pumpCard = shuntData?.PumpData?.card;
        pumpFluid = shuntData?.PumpData?.systemfluid;

        systemFluid = shuntData?.WizardData?.systemFluidMain;
        systemFluidMix = shuntData?.WizardData?.systemFluidPrimaryMixRatio;
        systemFluidMixUnknown = shuntData?.WizardData?.systemFluidPrimaryMixRatioUnknown;
        systemFluidObject = systemFluidOptions?.find((obj) => obj.value === systemFluid);

        isAddon = shuntData?.ShuntData?.isAddon;
        addonType = shuntData?.ShuntData?.addonType;
        addonData = shuntData?.ShuntData?.AddonData;
    }

    let shuntConnObj;
    if (plPrincip === PRODUCTPRINCIP_SR2FLEX || PRODUCTPRINCIP_SR2SMART) {
        shuntConnObj = connectionTypeList[PRODUCTPRINCIP_SR2];
    }
    else {
        shuntConnObj = connectionTypeList[plPrincip];
    }


    switch (component) {
        case 'blockInfo':
            let pn = 'PN10';

            if (plPrincip === PRODUCTPRINCIP_VA1) {
                shuntType = "Återvinningsshunt";
            }

            let prodName = "";

            let customDimSting = "";
            let customlabelSting = "";
            let labels = shuntConnObj?.CustomOptions?.shuntDimLabels;

            if (labels != null && shuntData?.CalcParams != null) {
                //console.log("Labels:", labels, plPrincip)
                Object.keys(labels).forEach((key, index) => {
                    let label = labels[key];
                    let customDim = shuntData?.CalcParams[key + 'DimRow'];
                    if (customDim != null) {
                        customDimSting += customDim.name;
                        customlabelSting += label;
                    }
                    else {
                        customDimSting += "DNXXX";
                        customlabelSting += label;
                    }

                    if (index !== Object.keys(labels).length - 1) {
                        customDimSting += "/";
                        customlabelSting += "/";
                    }
                })
            }

            if (customlabelSting !== '') {
                customlabelSting = "(" + customlabelSting + ")";
            }
            else {
                customlabelSting = "(prim/sek)";
            }


            if (isCustom) {
                //console.log("Values: ", exchanger, numConnections, shuntType)
                if ((exchanger == null || exchanger === '') && (numConnections == null || Number(numConnections) <= 4) && shuntType != null) {
                    prodName = 'Premablock® ' + getFullPLModel_FB(plLine, plPrincip, plModel, plGreenLvl, isMini, isSabo) + ' - ' + shuntType + ' - ' + customDimSting + ' ' + customlabelSting + ' - ' + pn;
                }
                else {
                    prodName = 'Premablock® ' + getFullPLModel_FB(plLine, plPrincip, plModel, plGreenLvl, isMini, isSabo) + ' - ' + customDimSting + ' ' + customlabelSting + ' - ' + pn;
                }

                text.push({ num: 1, text: prodName });
            }
            else {
                prodName = 'Premablock® ' + getFullPLModel_FB(plLine, plPrincip, plModel, plGreenLvl, isMini, isSabo) + ' - ' + shuntType + ' - ' + customDimSting + ' ' + customlabelSting + ' - ' + pn;
                text.push({ num: 1, text: prodName });
            }

            if (plLine === PRODUCTLINE_GREEN && plGreenLvl != null && plGreenLvl === 2) {
                text.push({ num: null, text: "Miljövarudeklarerad enligt ISO 14025 och EN 15804:2012+A2:2019" });
            }

            //console.log("Insuulation: ", insulation, shuntType)
            if (insulation == null || insulation === "") {
                if (isCustom === true) {
                    if (shuntType === COOL_SHUNT) {
                        text.push({ num: null, text: MINMAX_TEMPERATURE_TEXT_MINUS_5_90 });
                    }
                    else {
                        text.push({ num: null, text: MINMAX_TEMPERATURE_TEXT_2_90 });
                    }

                }
                else {
                    text.push({ num: null, text: MINMAX_TEMPERATURE_TEXT_18_90 });
                }
            }
            else if (insulation === INSULATION_13ARMAFLEX || insulation === INSULATION_13ULTIMA || insulation === INSULATION_13NH_SMART) {
                text.push({ num: null, text: MINMAX_TEMPERATURE_TEXT_2_90 });
            }
            else if (insulation === INSULATION_19ARMAFLEX || insulation === INSULATION_19ULTIMA || insulation === INSULATION_19NH_SMART) {
                if (plFamily === PRODUCTFAMILY_VA) {
                    text.push({ num: null, text: MINMAX_TEMPERATURE_TEXT_MINUS_10_90 });
                }
                else {
                    text.push({ num: null, text: MINMAX_TEMPERATURE_TEXT_MINUS_5_90 });
                }
            }

            if (plModel !== PRODUCTMODEL_PR2_APPSHUNT && plModel !== PRODUCTMODEL_SR2_FLEX_APPSHUNT) {
                text.push({ num: null, text: 'Vändbar med termometeruttag på båda sidor' });
                //console.log("Label: ", connValue)
                const connLables = CONN_VALUES_LABELS;
                let connText = connLables[connValue];
                if (connValue == null || connValue === '') {
                    connText = "Ej specificerat utförande";
                }
                else if (connValue === CONN_VALUE_NOT_SPECIFIED) {
                    connText += " utförande"
                }

                if (Number(hasLatch) === 1) {
                    if (shuntType === HEAT_SHUNT) {
                        text.push({ num: null, text: connText + CONN_LATCH_HEAT_LABEL });
                    }
                    else {
                        text.push({ num: null, text: connText + CONN_LATCH_COOL_LABEL });
                    }
                }
                else {
                    text.push({ num: null, text: connText });
                }

                if (isSabo === '1' || isSabo === 1) {
                    text.push({ num: null, text: "SABO-koppel (sekundärmonterad backventil)" });
                }

            }

            break;
        case "material":
            if (material === MATERIAL_PAINTED) {
                text.push({ num: 1, text: MATERIAL_PAINTED_TEXT });
            }
            else if (material === MATERIAL_STAINLESS) {
                text.push({ num: 1, text: MATERIAL_STAINLESS_TEXT });
            }
            else if ((material == null || material === '') && isCustom === true) {
                text.push({ num: 1, text: MATERIAL_UNSPECIFIED_TEXT });
            }
            break;
        case "greenException":
            if (plLine === PRODUCTLINE_GREEN && plPrincip !== PRODUCTPRINCIP_SR2 && Number(cvKvs) < 1.6) {
                text.push({ num: null, text: "Med undantag för styrventilen är gruppen i utförande green." });
            }
            break;
        case "cabinet":
            if (cabinet === CABINET_UNINSULATED) {
                if (primDimNum < 150 && sekDimNum < 150) {
                    text.push({ num: 1, text: CABINET_UNINSULATED_TEXT });
                }
            }
            else if (cabinet === CABINET_INSULATED) {
                text.push({ num: 1, text: CABINET_INSULATED_TEXT });
            }
            break;
        case "insulation":
            let customText = shuntConnObj?.SpecData?.specialInsulationText;
            //console.log("Value:_", customText)
            if (customText != null) {
                if (shuntType === HEAT_SHUNT) {
                    text.push({ num: 1, text: "Värmeisolering " + customText })
                }
                else {
                    text.push({ num: 1, text: "Kondensisolering " + customText })
                }

            }
            else {
                if (insulation != null && insulation !== "") {
                    text.push({ num: 1, text: "Kondensisolering " + INSULATION_LABELS[insulation] })
                }
            }

            break;
        case "controlValve":
            if ((plLine === PRODUCTLINE_FLEX || plLine === PRODUCTLINE_SMART) && controlValveNWays === 3 && controlValve2NWays === 2) {
                createControlValveRow(text, plLine, plPrincip, controlValve, cvModel, cvKvs, flexModel, flexDim, flexSVObj, flexSVSetting, smartDim, actuators, controlValve2NWays, exchanger, isInterval, true);
                createControlValveRow(text, PRODUCTLINE_STANDARD, plPrincip, controlValve, cvModel, cvKvs, flexModel, flexDim, flexSVObj, flexSVSetting, smartDim, actuators, controlValveNWays, exchanger, isInterval, false);
            }
            else {
                createControlValveRow(text, plLine, plPrincip, controlValve, cvModel, cvKvs, flexModel, flexDim, flexSVObj, flexSVSetting, smartDim, actuators, controlValveNWays, exchanger, isInterval, false);
                if (controlValve2NWays != null) {
                    createControlValveRow(text, PRODUCTLINE_STANDARD, plPrincip, controlValve, cvModel, cvKvs, flexModel, flexDim, flexSVObj, flexSVSetting, smartDim, actuators, controlValve2NWays, exchanger, isInterval, true);
                }
            }


            break;
        case "adjustValve":
            if (numIV != null && numIV > 0) {
                let labels = shuntConnObj?.CustomOptions?.shuntAdjustValveLabels;
                /*
                if (exchanger != null && exchanger === 'KB') {
                    labels = CUSTOM_SHUNT_ADJUST_VALVE_DIM_LABELS[plPrincip + "-KB"];
                }
                else {
                    labels = CUSTOM_SHUNT_ADJUST_VALVE_DIM_LABELS[plPrincip];
                }*/
                //console.log("IV Labels: ", labels)
                if (labels != null && shuntData?.CalcParams != null) {
                    const ivRows = {};

                    Object.keys(labels).forEach((key, index) => {
                        if ((key !== 'prim' && (plFamily === PRODUCTFAMILY_SR || plFamily === PRODUCTFAMILY_VA) && numIV === 1) || (key !== 'sek' && plFamily === PRODUCTFAMILY_PR && numIV === 1) || numIV > 1) {
                            let label = labels[key];
                            let customDim = shuntData?.CalcParams[key + 'DimRow'];
                            //console.log("IV Dim: ", customDim)

                            if (customDim?.ivDim != null) {
                                if (ivRows[customDim.ivDim] == null) {
                                    let localLabels = [
                                        label,
                                    ];

                                    ivRows[customDim.ivDim] = {};
                                    ivRows[customDim.ivDim]['name'] = customDim.ivName;
                                    ivRows[customDim.ivDim]['labels'] = localLabels;
                                }
                                else {
                                    ivRows[customDim.ivDim]['labels'].push(label);
                                }
                            }
                            else if (customDim?.dim != null) {
                                if (ivRows[customDim.dim] == null) {
                                    let localLabels = [
                                        label,
                                    ];

                                    ivRows[customDim.dim] = {};
                                    ivRows[customDim.dim]['name'] = customDim.ivName;
                                    ivRows[customDim.dim]['labels'] = localLabels;
                                }
                                else {
                                    ivRows[customDim.dim]['labels'].push(label);
                                }
                            }
                            else {
                                if (ivRows[0] == null) {
                                    let localLabels = [
                                        label,
                                    ];

                                    ivRows[0] = {};
                                    ivRows[0]['name'] = "DNXXX";
                                    ivRows[0]['labels'] = localLabels;
                                }
                                else {
                                    ivRows[0]['labels'].push(label);
                                }
                            }
                        }

                    });

                    //console.log("Data: ", ivRows)

                    let totalValves;
                    if (numIV != null) {
                        totalValves = numIV;
                    }
                    else {
                        totalValves = 2;
                    }

                    //console.log("IV 1: ", numIV, primIV, primIVdim, sekIV, sekIVdim, ivRows, totalValves)

                    Object.keys(ivRows).forEach((ivVal) => {
                        let textStringAdjust = "";
                        let localLabels = ivRows[ivVal]?.labels;
                        //console.log("IV Labels: ", localLabels)
                        let dimName = ivRows[ivVal]?.name;
                        let numValves = localLabels.length;
                        let adjModelText = "";

                        if (numValves <= totalValves) {
                            totalValves -= Number(numValves)
                        }
                        else {
                            numValves = totalValves;
                        }

                        if (numValves > 1) {
                            textStringAdjust = "Injusteringsventiler ";
                        }
                        else {
                            textStringAdjust = "Injusteringsventil ";
                        }

                        if (adjustValveModel === ADJUST_VALVE_STADF || adjustValveModel === ADJUST_VALVE_PURMOEVO) {
                            if (adjValueVal === ADJUSTVALUE_STAD_ZERO || (plLine === PRODUCTLINE_GREEN && adjustValveModel === ADJUST_VALVE_STADF)) {
                                if (Number(ivVal) < 65) {
                                    adjModelText = ADJUST_LABEL_STAD_ZERO;
                                }
                                else {
                                    adjModelText = ADJUST_LABEL_STAF;
                                }
                            }
                            else {
                                if (adjustValveModel === ADJUST_VALVE_PURMOEVO) {
                                    if (Number(ivVal) < 65) {
                                        adjModelText = ADJUST_LABEL_PURMO_EVO;
                                    }
                                    else {
                                        adjModelText = ADJUST_LABEL_PURMO_STV;
                                    }
                                }
                                else {
                                    if (Number(ivVal) < 65) {
                                        adjModelText = ADJUST_LABEL_STAD;
                                    }
                                    else {
                                        adjModelText = ADJUST_LABEL_STAF;
                                    }
                                }
                            }
                        }
                        else {
                            if (adjValueVal === ADJUSTVALUE_STAD_ZERO) {
                                adjModelText = ADJUST_LABEL_STAD_ZERO;
                            }
                            else {
                                adjModelText = adjustValveModel
                            }
                        }
                        let dimLabelsText;
                        if (numIV === 1 && localLabels.length > 1) {
                            dimLabelsText = '(Sek)'
                        }
                        else {
                            dimLabelsText = createDimLabels(localLabels);
                        }

                        if (numValves > 0) {
                            text.push({ num: numValves, text: textStringAdjust + adjModelText + ", " + dimName + " " + dimLabelsText });
                        }

                    });
                }
                else {
                    let textStringAdjust = "Injusteringsventil ";
                    let adjustLabel = ADJUST_LABELS[adjustValveModel];

                    if (primIV == null) {
                        primIV = primStyrvent;
                    }
                    if (sekIV == null) {
                        sekIV = sekStyrvent;
                    }
                    if (primIVdim == null && primStyrvent != null) {
                        primIVdim = Number(String(primStyrvent).match(/\d/g)?.join(''));
                    }
                    if (sekIVdim == null && sekStyrvent != null) {
                        sekIVdim = Number(String(sekStyrvent).match(/\d/g)?.join(''));
                    }
                    //console.log("IV: ", primIV, primIVdim, sekIV, sekIVdim)
                    if (adjustValveModel === ADJUST_VALVE_STADF || adjustValveModel === ADJUST_VALVE_PURMOEVO) {
                        let adjustLabelBelow;
                        let adjustLabelAbove;

                        if (adjValueVal === ADJUSTVALUE_STAD_ZERO || (plLine === PRODUCTLINE_GREEN && adjustValveModel === ADJUST_VALVE_STADF)) {
                            adjustLabelBelow = ADJUST_LABEL_STAD_ZERO;
                            adjustLabelAbove = ADJUST_LABEL_STAF;
                        }
                        else {
                            if (adjustValveModel === ADJUST_VALVE_PURMOEVO) {
                                adjustLabelBelow = ADJUST_LABEL_PURMO_EVO;
                                adjustLabelAbove = ADJUST_LABEL_PURMO_STV;
                            }
                            else {
                                adjustLabelBelow = ADJUST_LABEL_STAD;
                                adjustLabelAbove = ADJUST_LABEL_STAF;
                            }
                        }

                        if (numIV > 1) {
                            if (primIVdim < 65 && sekIVdim < 65) {
                                if (primIVdim === sekIVdim) {
                                    textStringAdjust = "Injusteringsventiler ";
                                }

                                adjustLabel = adjustLabelBelow;

                                if (adjustLabel != null) {
                                    textStringAdjust += adjustLabel + ", ";
                                }
                                else {
                                    textStringAdjust += adjustValveModel + ", ";
                                }

                                if (numIV === 1) {
                                    textStringAdjust += sekIV + " (sek)";
                                    text.push({ num: numIV, text: textStringAdjust });
                                }
                                else if (primIVdim === sekIVdim) {
                                    textStringAdjust += primIV + " (prim/sek)";
                                    text.push({ num: numIV, text: textStringAdjust });
                                }
                                else {
                                    let primDimString = textStringAdjust + primIV + " (prim)";
                                    let sekDimString = textStringAdjust + sekIV + " (sek)";

                                    text.push({ num: 1, text: primDimString });
                                    text.push({ num: 1, text: sekDimString });
                                }
                            }
                            else if ((primIVdim >= 65 && sekIVdim < 65) || (primIVdim < 65 && sekIVdim >= 65)) {
                                let adjustLabelPrim;
                                let adjustLabelSek;

                                if (primIVdim >= 65) {
                                    adjustLabelPrim = adjustLabelAbove;
                                    adjustLabelSek = adjustLabelBelow;
                                }
                                else {
                                    adjustLabelPrim = adjustLabelBelow;
                                    adjustLabelSek = adjustLabelAbove;
                                }

                                let primTextString = textStringAdjust + adjustLabelPrim + ", " + primIV + " (prim)";
                                let sekTextString = textStringAdjust + adjustLabelSek + ", " + sekIV + " (sek)";

                                text.push({ num: 1, text: primTextString });
                                text.push({ num: 1, text: sekTextString });
                            }
                            else {
                                let adjustLabel = adjustLabelAbove;

                                if (primIVdim === sekIVdim) {
                                    textStringAdjust = "Injusteringsventiler ";
                                    let textString = textStringAdjust + adjustLabel + ", " + primIV + " (prim/sek)";
                                    text.push({ num: numIV, text: textString });
                                }
                                else {
                                    let primTextString = textStringAdjust + adjustLabel + ", " + primIV + " (prim)";
                                    let sekTextString = textStringAdjust + adjustLabel + ", " + sekIV + " (sek)";

                                    text.push({ num: 1, text: primTextString });
                                    text.push({ num: 1, text: sekTextString });
                                }
                            }
                        }
                        else if (numIV === 1) {
                            if (sekIVdim >= 65) {
                                adjustLabel = "STAF";
                            }
                            else {
                                if (adjValueVal === ADJUSTVALUE_STAD_ZERO || plLine === PRODUCTLINE_GREEN) {
                                    adjustLabel = "STAD ZERO";
                                }
                                else {
                                    adjustLabel = "STAD";
                                }
                            }


                            if (adjustLabel != null) {
                                textStringAdjust += adjustLabel + ", ";
                            }
                            else {
                                textStringAdjust += adjustValveModel + ", ";
                            }

                            if (plFamily === PRODUCTFAMILY_PR) {
                                textStringAdjust += primIV + " (prim)";
                            }
                            else {
                                textStringAdjust += sekIV + " (sek)";
                            }

                            if (numIV > 0) {
                                text.push({ num: numIV, text: textStringAdjust });
                            }
                        }

                    }
                    else {
                        if (numIV > 1 && primIVdim === sekIVdim) {
                            textStringAdjust = "Injusteringsventiler ";
                        }

                        if (adjValueVal === ADJUSTVALUE_STAD_ZERO) {
                            adjustLabel = "STAD ZERO";
                        }

                        if (adjustLabel != null) {
                            textStringAdjust += adjustLabel + ", ";
                        }
                        else {
                            textStringAdjust += adjustValveModel + ", ";
                        }

                        if (numIV === 1) {
                            //console.log("Prim", plFamily)
                            if (plFamily === PRODUCTFAMILY_PR) {
                                textStringAdjust += primIV + " (prim)";
                            }
                            else {
                                textStringAdjust += sekIV + " (sek)";
                            }
                            text.push({ num: numIV, text: textStringAdjust });
                        }
                        else if (primIVdim === sekIVdim) {
                            textStringAdjust += primIV + " (prim/sek)";
                            text.push({ num: numIV, text: textStringAdjust });
                        }
                        else {
                            let primDimString = textStringAdjust + primIV + " (prim)";
                            let sekDimString = textStringAdjust + sekIV + " (sek)";

                            text.push({ num: 1, text: primDimString });
                            text.push({ num: 1, text: sekDimString });
                        }
                    }
                }
            }

            break;
        case "cutOff":
            let cutOfflabels = shuntConnObj?.CustomOptions?.shuntDimLabels;

            /*
            if (exchanger != null && exchanger === 'KB') {
                cutOfflabels = CUSTOM_SHUNT_DIM_LABELS[plPrincip + "-KB"];
            }
            else {
                cutOfflabels = CUSTOM_SHUNT_DIM_LABELS[plPrincip];
            }*/

            if (cutOfflabels != null && shuntData?.CalcParams != null) {
                const cutOffRows = {};
                Object.keys(cutOfflabels).forEach((key, index) => {
                    let label = cutOfflabels[key];
                    let customDim = shuntData?.CalcParams[key + 'DimRow'];
                    //console.log("Dim: ", customDim);
                    if (customDim?.dim != null) {
                        if (cutOffRows[customDim.dim] == null) {
                            let localLabels = [
                                label,
                            ];

                            let localKeys = [
                                key,
                            ];

                            cutOffRows[customDim.dim] = {};
                            cutOffRows[customDim.dim]['name'] = customDim.name;
                            cutOffRows[customDim.dim]['labels'] = localLabels;
                            cutOffRows[customDim.dim]['keys'] = localKeys;
                        }
                        else {
                            cutOffRows[customDim.dim]['labels'].push(label);
                            cutOffRows[customDim.dim]['keys'].push(key);
                        }
                    }
                    else {
                        if (cutOffRows[0] == null) {
                            let localLabels = [
                                label,
                            ];

                            let localKeys = [
                                key,
                            ];

                            cutOffRows[0] = {};
                            cutOffRows[0]['name'] = "DNXXX";
                            cutOffRows[0]['labels'] = localLabels;
                            cutOffRows[0]['keys'] = localKeys;
                        }
                        else {
                            cutOffRows[0]['labels'].push(label);
                            cutOffRows[0]['keys'].push(key);
                        }
                    }
                });

                //console.log("Data: ", cutOffRows)
                let totalValves;
                if (numConnections != null && numIV != null) {
                    totalValves = numConnections - numIV;
                }
                else if (numIV != null) {
                    totalValves = 4 - numIV;
                }
                else if (numConnections != null) {
                    totalValves = numConnections - 2;
                }
                else {
                    totalValves = 4 - 2;
                }

                Object.keys(cutOffRows).forEach((dim, index) => {
                    let localLabels = cutOffRows[dim]?.labels;
                    //console.log("Labels: ", localLabels)
                    let dimName = cutOffRows[dim]?.name;
                    let numValves = localLabels.length;
                    let keys = cutOffRows[dim]?.keys;

                    //console.log("Labels: ", numValves, totalValves, index, ivMod, numIV)
                    if (keys.includes('prim') && numIV <= 1 && plFamily !== PRODUCTFAMILY_PR) {
                        numValves += 1;
                    }
                    if (keys.includes('sek') && (numIV < 1 || (numIV === 1 && plFamily === PRODUCTFAMILY_PR))) {
                        numValves += 1;
                    }

                    if (numValves <= totalValves) {
                        totalValves -= Number(numValves);
                    }
                    else {
                        numValves = totalValves;
                    }
                    let numConn = 4
                    if (numConnections != null) {
                        numConn = numConnections;
                    }

                    let label;
                    if (Number(dim) < 65) {
                        label = CUTOFF_LABELS_UNDER_DN65[cutOffValve];
                    }
                    else {
                        label = CUTOFF_LABELS_OVER_DN65[cutOffValve];
                    }

                    let dimLabelsText = createDimLabels(localLabels, false);
                    //console.log("Dims: ", numValves, numConn)

                    if (numValves > 0) {
                        if (plModel === PRODUCTMODEL_PR2_APPSHUNT || plModel === PRODUCTMODEL_SR2_FLEX_APPSHUNT) {
                            text.push({ num: numValves, text: CUTOFF_VENT_TEXT_WITHOUT_SOCKET(label, dimName, dimLabelsText, numValves > 1) });
                        }
                        else {
                            text.push({ num: numValves, text: CUTOFF_VENT_TEXT(label, dimName, dimLabelsText, numValves > 1) });
                        }
                    }
                });
            }
            else {
                if (primDimNum === sekDimNum) {
                    let label;

                    if (primDimNum < 65) {
                        label = CUTOFF_LABELS_UNDER_DN65[cutOffValve];
                    }
                    else {
                        label = CUTOFF_LABELS_OVER_DN65[cutOffValve];
                    }

                    if (numIV != null) {
                        if (plModel === PRODUCTMODEL_PR2_APPSHUNT || plModel === PRODUCTMODEL_SR2_FLEX_APPSHUNT) {
                            text.push({ num: getNumCutOffVents(numIV), text: CUTOFF_VENT_TEXT_WITHOUT_SOCKET(label, primDim, 'prim/sek', true) });
                        }
                        else {
                            text.push({ num: getNumCutOffVents(numIV), text: CUTOFF_VENT_TEXT(label, primDim, 'prim/sek', true) });
                        }

                    }
                }
                else {
                    if (numIV != null) {
                        let numCutOffVents = getNumCutOffVents(numIV);
                        let numPrim = 1;
                        let numSek = 1;

                        let labelPrim;
                        let labelSek;

                        if (numCutOffVents > 2) {
                            numPrim = 2;
                            if (numCutOffVents > 3) {
                                numSek = 2;
                            }
                        }

                        if (primDimNum < 65) {
                            labelPrim = CUTOFF_LABELS_UNDER_DN65[cutOffValve];
                        }
                        else {
                            labelPrim = CUTOFF_LABELS_OVER_DN65[cutOffValve];
                        }

                        if (sekDimNum < 65) {
                            labelSek = CUTOFF_LABELS_UNDER_DN65[cutOffValve];
                        }
                        else {
                            labelSek = CUTOFF_LABELS_OVER_DN65[cutOffValve];
                        }

                        text.push({ num: numPrim, text: CUTOFF_VENT_TEXT(labelPrim, primDim, 'prim', numPrim > 1) });
                        text.push({ num: numSek, text: CUTOFF_VENT_TEXT(labelSek, sekDim, 'sek', numSek > 1) });
                    }
                }
            }

            break;
        case "thermo":
            let thermoLabel = THERMO_MULTIPLE_LABELS[thermo];
            if (plLine === PRODUCTLINE_GREEN) {
                thermoLabel = THERMO_MULTIPLE_LABELS_GREEN[thermo];
            }
            let textStringThermo;

            if (thermoLabel != null) {
                textStringThermo = thermoLabel + " ";
            }
            else {
                textStringThermo = thermo + " ";
            }

            let maxTemp;
            let minTemp;

            if (primTempIn == null && primTempOut == null && secTempIn == null && secTempOut == null) {
                maxTemp = 21;
                minTemp = 21;
            }
            else {
                maxTemp = getMaxTempValue(Number(primTempIn), Number(primTempOut), Number(secTempIn), Number(secTempOut));
                minTemp = getMinTempValue(Number(primTempIn), Number(primTempOut), Number(secTempIn), Number(secTempOut))
            }

            //console.log("MaxTemp: ", maxTemp, primTempIn, primTempOut, secTempIn, secTempOut)

            let numThermo = 4;

            /*
            if (exchanger != null && exchanger === 'KB' && CUSTOM_SHUNT_NUM_THERMO[plPrincip + "-KB"] != null) {
                numThermo = CUSTOM_SHUNT_NUM_THERMO[plPrincip + "-KB"];
            }
            else if (CUSTOM_SHUNT_NUM_THERMO[plPrincip] != null) {
                numThermo = CUSTOM_SHUNT_NUM_THERMO[plPrincip];
            }*/

            if (shuntConnObj?.SpecData?.numOfThermo != null) {
                numThermo = shuntConnObj?.SpecData?.numOfThermo;
            }

            //console.log("Thermo", numThermo)
            if (numThermo != null && isNaN(numThermo)) {
                Object.keys(numThermo).forEach((key) => {
                    let customString;

                    if (key === 'cold') {
                        customString = textStringThermo + "-30/+50°C"
                    }
                    else {
                        customString = textStringThermo + "0-120°C"
                    }

                    text.push({ num: numThermo[key], text: customString });
                })
            }
            else {
                if (maxTemp > 46) {
                    textStringThermo += "0-120°C"
                }
                else if (minTemp < 3) {
                    textStringThermo += "-30/+50°C"
                }
                else {
                    let minLow = minTemp - (-30);
                    let maxLow = 50 - maxTemp;


                    let minHigh = minTemp - 0;
                    let maxHigh = 120 - maxTemp;

                    let lowMargin = Math.min(minLow, maxLow);
                    let highMargin = Math.min(minHigh, maxHigh);

                    //console.log("Temps: ", minTemp, maxTemp, "Values: ", lowMargin, highMargin)
                    if (highMargin > lowMargin) {
                        textStringThermo += "0-120°C"
                    }
                    else {
                        textStringThermo += "-30/+50°C"
                    }
                }

                text.push({ num: numThermo, text: textStringThermo });
            }

            break;
        case "exchanger":
            if (exchanger != null && exchanger !== '') {
                let textStringExch = "Växlare för ";
                let value;
                if (exchanger === 'KB') {
                    value = "kyla";
                    textStringExch += value + " S1/S2"
                }
                else if (exchanger === 'VP') {
                    value = "värme";
                    textStringExch += value + " S1/S2"
                }
                else {
                    if (shuntType === HEAT_SHUNT) {
                        value = "värme";
                    }
                    else {
                        value = "kyla";
                    }
                    textStringExch += value
                }

                text.push({ num: 1, text: textStringExch });
            }
            break;
        case "returnVents":
            if (isSabo !== '1' && isSabo !== 1) {
                if (numBV != null && numBV > 0) {
                    let greenText = "";
                    if (plLine === PRODUCTLINE_GREEN && getLargestDimLocal(primDimNum, sekDimNum) < 65) {
                        greenText = " GREEN"
                    }

                    let textStringBV = "Backventil" + greenText + " monterad mellan flänsar";

                    if (numBV > 1) {
                        textStringBV = "Backventiler" + greenText + " monterade mellan flänsar";
                    }

                    if (isSabo === '1' || isSabo === 1) {
                        textStringBV += " vid styrventilens sidoport"
                    }

                    text.push({ num: numBV, text: textStringBV });
                }
            }
            break;
        case "returnVentsSabo":
            if (isSabo === '1' || isSabo === 1) {
                if (numBV != null && numBV > 0) {

                    let greenText = "";
                    if (plLine === PRODUCTLINE_GREEN && getLargestDimLocal(primDimNum, sekDimNum) < 65) {
                        greenText = " GREEN"
                    }

                    let textStringBV = "Backventil" + greenText + " monterad mellan flänsar vid styrventilens sidoport";

                    if (numBV > 1) {
                        textStringBV = "Backventiler" + greenText + " monterade mellan flänsar vid styrventilens sidoport";
                    }

                    text.push({ num: numBV, text: textStringBV });
                }
            }
            break;
        case "extraBV":
            if (numBV != null && numBV > 0 && hasLatch === 1) {
                let extraBVString = "Extra backventil";

                if (shuntType === HEAT_SHUNT) {
                    extraBVString += ", värmespärr"
                }
                else {
                    extraBVString += ", kylspärr"
                }

                extraBVString += ", borrad"

                text.push({ num: 1, text: extraBVString });
            }

            break;
        case "drainValve":
            let drainValveText = "Avtappningsventiler";

            if (plLine === PRODUCTLINE_GREEN) {
                drainValveText += " AT3700";
            }

            if (connDesign === CONN_DESIGN_U) {
                text.push({ num: 2, text: drainValveText });
            }
            else {
                text.push({ num: 1, text: drainValveText });
            }


            break;
        case "actuators":
            if (actuators === true) {
                text.push({ num: 1, text: "Ställdon 24V/0-10V" });
            }
            break;
        case "dripPlate":
            if (dripPlate === true) {
                text.push({ num: 1, text: "Dropplåt i rostfritt stål" });
            }
            break;
        case "pump":
            let pumpString;
            if (pumpReqType === PUMP_GENERAL_REQ) {
                pumpString = "Pump " + PUMP_LABELS[pumpReq] + " som dimensioneras av PREMA";
            }
            else if (specPumpReq != null && specPumpReq !== '') {
                pumpString = "Pump " + specPumpReq;
            }
            else {
                pumpString = "Pump valfri som dimensioneras av PREMA";
            }

            text.push({ num: 1, text: pumpString });

            let hasOptions = false;
            let options = [];

            if (pumpAlarm === true) {
                hasOptions = true;
                options.push("Larmutgång ");
            }
            if (pumpPressure === true) {
                hasOptions = true;
                options.push("Tryckstyrd ");
            }
            if (pumpSteering === true) {
                hasOptions = true;
                options.push("0-10 V styrning ");
            }
            if (pumpExternalStop === true) {
                hasOptions = true;
                options.push("Extern start/stopp ");
            }
            if (pumpCard === true) {
                hasOptions = true;
                options.push("Kommunikationskort pump (Modbus) ");
            }



            if (hasOptions === true) {
                options[0] = "" + options[0];
                options[options.length - 1] = options[options.length - 1].replace(',', '');
            }

            //text.push(options)

            options.forEach(element => {
                text.push({ num: null, text: element });
            });

            break;
        case "stand":
            if (plModel !== PRODUCTMODEL_PR2_APPSHUNT && plModel !== PRODUCTMODEL_SR2_FLEX_APPSHUNT) {
                let textStringStand;

                if (standValue === STAND_FLOOR) {
                    textStringStand = "Golvstativ";
                }
                else {
                    textStringStand = "Väggstativ";
                }

                text.push({ num: 1, text: textStringStand });
            }

            break;
        case "safetyValve":
            if (shuntConnObj?.SpecData?.hasSafetyValve != null && shuntConnObj?.SpecData?.hasSafetyValve === true) {
                let pressure = 10;
                let textStringMano = "Säkerhetsventil " + pressure.toFixed(1) + "bar";

                text.push({ num: 1, text: textStringMano });
            }
            break;
        case "muff":
            if (shuntConnObj?.SpecData?.hasShuntMuff != null && shuntConnObj?.SpecData?.hasShuntMuff === true) {
                let textStringMuff = "Muff för expansionssystem";

                text.push({ num: 1, text: textStringMuff });
            }
            break;
        case "manometer":
            if (manoValue !== NO_MANOMETER) {
                let textStringMano = "Manometersats: " + manoValue;

                text.push({ num: 1, text: textStringMano });
            }

            break;
        case "systemFluid":
            if (systemFluid != null && systemFluidObject != null) {
                if (systemFluid !== SYSTEM_FLUID.unknown.value) {
                    let textStringFluid = "Systemvätska: "
                    if (systemFluid === SYSTEM_FLUID.other.value && pumpFluid != null && pumpFluid !== "") {
                        textStringFluid += pumpFluid
                    }
                    else {
                        if (systemFluidObject?.defaultMixRatio != null) {
                            if (systemFluidMixUnknown !== true) {
                                if (systemFluidMix != null && isNaN(systemFluidMix) === false) {
                                    textStringFluid += systemFluidMix + "% " + systemFluidObject?.paramLabel
                                }
                                else {
                                    textStringFluid += systemFluidObject?.paramLabel;
                                }
                            }
                            else {
                                textStringFluid += systemFluidObject?.paramLabel + ", blandningsförhållande ej specificerat"
                            }
                        }
                        else {
                            textStringFluid += systemFluidObject?.paramLabel;
                        }
                    }


                    text.push({ num: null, text: textStringFluid });
                }
            }
            else if (pumpFluid != null && pumpFluid !== "") {
                let textStringFluid = "Systemvätska: " + pumpFluid;

                text.push({ num: null, text: textStringFluid });
            }

            break;
        case "infoSign":
            let signString = "Informations- och dataskylt";
            text.push({ num: null, text: signString });
            break;
        case "mixingVesselData":
            if (mixingVessel != null && mixingVessel !== NO_MIXINGVESSEL) {
                let mixingValue = String(mixingVessel).split(' ')[0]

                text.push({ num: 1, text: MIXING_VESSEL_TEXT(mixingValue, plLine === PRODUCTLINE_GREEN) });
                text.push({ num: 1, text: "Pump Wilo WJ203, 1x240V med sladd och stickpropp" });
                text.push({ num: 1, text: "Flexibel anslutningsslang" });
                text.push({ num: 1, text: "Hål med propp för returledning" });
            }

            break;
        case "mixingVessel":
            if (isAddon === true && addonType === 'mixingVessel') {
                let mixingValue = String(addonData?.size).split(' ')[0]

                text.push({ num: 1, text: MIXING_VESSEL_TEXT(mixingValue, plLine === PRODUCTLINE_GREEN) });
                text.push({ num: 1, text: "Pump Wilo WJ203, 1x240V med sladd och stickpropp" });
                text.push({ num: 1, text: "Flexibel anslutningsslang" });
                text.push({ num: 1, text: "Hål med propp för returledning" });
            }

            break;
        default:
            break;
    }

    return text;
}

/**
 * 
 * @param {number} numIV 
 * @returns 
 */
const getNumCutOffVents = (numIV) => {
    return 4 - numIV;
}

/**
 * 
 * @param {number} numIV 
 * @param {number} numConn
 * @returns 
 */
const getNumCutOffVentsCustom = (numIV, numConn) => {
    return numConn - numIV;
}

const setNumOfDecimals = (value) => {
    if (value != null) {
        if (isNaN(Number(value)) === false) {
            let num = countDecimals(value);

            if (num < 2) {
                return value.toFixed(1);
            }
            else {
                return value.toFixed(num);
            }
        }
        else {
            return value;
        }
    }
}

/**
 * 
 * @param {string} plLine 
 * @param {string} plPrincip 
 * @param {string} plModel 
 * @param {number} plGreenLvl
 * @param {*} isMini 
 * @param {*} isSabo 
 * @returns 
 */
export const getFullPLModel_FB = (plLine, plPrincip, plModel, plGreenLvl, isMini, isSabo, caps = true) => {
    let plFamily;

    if (plPrincip === PRODUCTPRINCIP_SR2 || plPrincip === PRODUCTPRINCIP_SR2FLEX || plPrincip === PRODUCTPRINCIP_SR2SMART || plPrincip === PRODUCTPRINCIP_SR3) {
        plFamily = PRODUCTFAMILY_SR;
    }
    else if (plPrincip === PRODUCTPRINCIP_PR2 || plPrincip === PRODUCTPRINCIP_PR3) {
        plFamily = PRODUCTFAMILY_PR;
    }
    else if (plPrincip === PRODUCTPRINCIP_VA1) {
        plFamily = PRODUCTFAMILY_VA;
    }

    let name = plModel;
    if (plFamily === PRODUCTFAMILY_VA) {
        name = String(name).replace('A', 'Å');
    }

    if (isMini === true || isMini === "true") {
        name += 'M';
    }

    let saboText = "";
    if (isSabo === '1' || isSabo === 1) {
        saboText = " SABO"
    }

    if (plLine === PRODUCTLINE_STANDARD) {
        return name + saboText;
    }
    else {
        let greenText = "";

        if (plLine === PRODUCTLINE_GREEN && plGreenLvl != null) {
            if (Number(plGreenLvl) === 1) {
                greenText = " I";
            }
            else if (Number(plGreenLvl) === 2) {
                greenText = " II";
            }
        }
        if (plPrincip === PRODUCTPRINCIP_SR2_FLEX_APPSHUNT) {
            return name + saboText + " " + greenText;
        }
        else {
            if (caps === true) {
                return name + saboText + " " + String(plLine).toLocaleLowerCase() + greenText;
            }
            else {
                return name + saboText + " " + String(plLine).toLocaleLowerCase() + greenText;
            }

        }


    }
}

const createControlValveRow = (text, plLine, plPrincip, controlValve, cvModel, cvKvs, flexModel, flexDim, flexSVObj, flexSVSetting, smartDim, actuators, controlValveNWays, exchanger, isInterval, isSecond) => {
    let textString = "";
    let cvType = "";
    /*
    if (controlValveNWays != null) {
        let value;
        if (exchanger === 'KB') {
            value = CUSTOM_SHUNT_SEC_CONTROLVALVE_OPTIONS[plPrincip + '-KB'];
        }
        else {
            value = CUSTOM_SHUNT_SEC_CONTROLVALVE_OPTIONS[plPrincip];
        }

        //console.log("EXCHANGE", value)

        if (value != null && value !== "") {
            let cvName = "";
            if (isSecond === true) {
                cvName = "controlValve2";
            }
            else {
                cvName = "controlValve";
            }

            let row = value.find(obj => obj.cv === cvName);
            //console.log("EXCHANGE", row)

            if (row != null) {
                cvType = " " + row.value;
            }

        }
    }*/

    if (plLine === PRODUCTLINE_FLEX) {
        let modelName = CVMODELS_LABELS[flexModel];
        let controlValveText = "";
        if (modelName == null || modelName === '') {
            modelName = flexModel;
        }
        if (controlValve === CV_SOE) {
            controlValveText = " samordnas med SÖE"
        }
        else {
            controlValveText = ", " + controlValve + " " + modelName + ", " + flexDim
        }
        textString = "Styr/differenstrycksventil" + cvType + controlValveText + ", med mätnipplar,";
        text.push({ num: 1, text: textString });
        let minFlow = '___';
        let maxFlow = '___';
        if (flexSVObj?.qMax != null) {
            minFlow = Number(flexSVObj?.qMax[0]).toFixed(2);
            maxFlow = Number(flexSVObj?.qMax[flexSVObj?.qMax.length - 1]).toFixed(2);
        }

        let minPressure = flexSVObj?.minPressure || '___';
        let maxPressure = flexSVObj?.maxPressure || '___';

        let nextLine = "min/max " + minFlow + "/" + maxFlow + " l/s, vid " + minPressure + "-" + maxPressure + " kPa";
        if (flexSVSetting != null) {
            nextLine += ", inst.värde " + flexSVSetting
        }
        else {
            nextLine += ", inst.värde ___"
        }

        if (actuators !== true) {
            nextLine += ", exklusive ställdon"
        }

        text.push({ num: null, text: nextLine });
    }
    else if (plLine === PRODUCTLINE_SMART) {
        if (controlValve === CV_SOE) {
            textString = "Styrventil " + cvType + " samordnas med SÖE";
        }
        else {
            let modelName = CVMODELS_LABELS[cvModel];
            if (modelName == null || modelName === '') {
                modelName = cvModel;
            }
            textString = "Styrventil " + cvType + ", " + controlValve + " " + modelName + ", " + smartDim?.id;
        }

        if (smartDim != null) {
            textString += ", Kvs: " + smartDim?.data?.kvs + ", min/max: " + Number(smartDim?.data?.qMin).toFixed(2) + "/" + Number(smartDim?.data?.qMax).toFixed(2) + " l/s"
        }
        else {
            textString += ", Kvs: ___, min/max: ___/___ l/s"
        }

        textString += ", inklusive ställdon"
        /*
        if (actuators !== true) {
            textString += ", exklusive ställdon"
        }
        else{
            textString += ", inklusive ställdon"
        }*/

        text.push({ num: 1, text: textString });
    }
    else {
        if (plLine === PRODUCTLINE_GREEN && plPrincip !== PRODUCTPRINCIP_SR2 && Number(cvKvs) < 1.6) {
            textString = "Styrventil " + cvType.trimStart();
        }
        else if (plLine === PRODUCTLINE_GREEN) {
            textString = "Styrventil av gjutjärn " + cvType.trimStart();
        }
        else {
            textString = "Styrventil " + cvType.trimStart();
        }


        if (controlValve === CV_SOE) {
            textString += " samordnas med SÖE"
        }
        else {
            textString += controlValve + " " + cvModel;
        }


        if (controlValveNWays != null && controlValveNWays !== '') {
            textString += ", " + controlValveNWays + "-vägs";
        }
        else if (isInterval === true) {
            //console.log("Plmodel:", plPrincip)
            if (plPrincip === PRODUCTPRINCIP_SR2) {
                textString += ", 2-vägs"
            }
            else {
                textString += ", 3-vägs"
            }
        }

        if (cvKvs != null && cvKvs !== '') {
            textString += ", kvs " + setNumOfDecimals(cvKvs);
        }
        else {
            textString += ", kvs ___ ";
        }


        if (actuators !== true) {
            if (cvModel === CV_MODEL_MXGF) {
                textString += ", inklusive ställdon"
            }
            else {
                textString += ", exklusive ställdon"
            }
        }

        text.push({ num: 1, text: textString });
    }
}

/**
 * 
 * @param {Array} labelList 
 * @param {boolean} [withParentheses]
 * @returns {string}
 */
const createDimLabels = (labelList, withParentheses = true) => {
    let dimLabelsText;
    if (withParentheses) {
        dimLabelsText = "(";
    }
    else {
        dimLabelsText = "";
    }

    labelList.forEach((label, index) => {
        dimLabelsText += label;
        //console.log("List", index, labelList.length)
        if (index < labelList.length - 1) {
            dimLabelsText += "/";
        }
        else {
            if (withParentheses) {
                dimLabelsText += ")";
            }
        }
    });

    return dimLabelsText;
}

/**
 * 
 * @param {number} primDim 
 * @param {number} sekDim 
 * @returns {number}
 */
const getLargestDimLocal = (primDim, sekDim) => {
    return Math.max(primDim, sekDim);
}

/**
 * 
 * @param {number} primTempIn 
 * @param {number} primTempOut 
 * @param {number} secTempIn 
 * @param {number} secTempOut 
 * @returns {number}
 */
const getMaxTempValue = (primTempIn, primTempOut, secTempIn, secTempOut) => {
    //console.log("Vals:", primTempIn, primTempOut, secTempIn, secTempOut)
    if (isNaN(primTempIn) || primTempIn == null) {
        primTempIn = Number.MIN_SAFE_INTEGER;
    }
    if (isNaN(primTempOut) || primTempOut == null) {
        primTempOut = Number.MIN_SAFE_INTEGER;
    }
    if (isNaN(secTempIn) || secTempIn == null) {
        secTempIn = Number.MIN_SAFE_INTEGER;
    }
    if (isNaN(secTempOut) || secTempOut == null) {
        secTempOut = Number.MIN_SAFE_INTEGER;
    }

    return Math.max(primTempIn, primTempOut, secTempIn, secTempOut);
}

/**
 * 
 * @param {number} primTempIn 
 * @param {number} primTempOut 
 * @param {number} secTempIn 
 * @param {number} secTempOut 
 * @returns {number}
 */
const getMinTempValue = (primTempIn, primTempOut, secTempIn, secTempOut) => {
    //console.log("Vals:", primTempIn, primTempOut, secTempIn, secTempOut)
    if (isNaN(primTempIn) || primTempIn == null) {
        primTempIn = Number.MAX_SAFE_INTEGER;
    }
    if (isNaN(primTempOut) || primTempOut == null) {
        primTempOut = Number.MAX_SAFE_INTEGER;
    }
    if (isNaN(secTempIn) || secTempIn == null) {
        secTempIn = Number.MAX_SAFE_INTEGER;
    }
    if (isNaN(secTempOut) || secTempOut == null) {
        secTempOut = Number.MAX_SAFE_INTEGER;
    }

    return Math.min(primTempIn, primTempOut, secTempIn, secTempOut);
}