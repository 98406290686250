import { ErrorEnum } from "../../../enums/errorEnum";
import { ErrorMsg } from "../../../constants/errorMessages";
import { roundToDecimals } from "../helpFunctions/roundTo4Decimals";
import { flex_calcControlValveSetting } from "./calculateControlValveSetting";
import { NoticeEnum } from "../../../enums";
import { NoticeMsg } from "../../../constants/noticeMessages";

/**
 * 
 * @param {*} primFlow 
 * @param {*} drivingPressure 
 * @param {Array} flexDims 
 * @param {*} calcMode 
 */
const flex_calcControlValveDimensions = function calcControlValveDimensions(primFlow, drivingPressure, flexDims, model, flexID, errorContext, noticeContext, calcMode, specialFlexDim, useContext = true, localErrorList = null, localNoticeList = null) {
    const errorList = new ErrorEnum();
    const noticeList = new NoticeEnum();

    let svOfMax = 1;
    let svSettings = 1;
    let flexObj;

    if (calcMode === 0) {
        if (flexID != null) {
            flexObj = flexDims.find(obj => obj.id === flexID);

            if (flexObj != null) {
                let minFlow = flexObj.data?.qMax[0];
                let maxFlow = flexObj.data?.qMax[flexObj.data?.qMax.length - 1];
                //console.log("Min:", minFlow);
                //console.log("Max:", maxFlow);
                if (primFlow < minFlow) {
                    console.error(ErrorMsg.flex_valveToBig.msg);
                    if (useContext === true) {
                        errorList.addError(errorContext, ErrorMsg.flex_valveToBig.key, ErrorMsg.flex_valveToBig.msg);
                    }
                    else {
                        localErrorList[ErrorMsg.flex_valveToBig.key] = ErrorMsg.flex_valveToBig.msg;
                    }

                    //driftParams.dispatch({ type: 'setError', payload: ErrorMsg.flex_valveToBig });
                    return;
                }

                if (primFlow > maxFlow) {
                    //console.error(ErrorMsg.flex_valveToSmall.msg);
                    if (useContext === true) {
                        errorList.addError(errorContext, ErrorMsg.flex_valveToSmall.key, ErrorMsg.flex_valveToSmall.msg);
                    }
                    else {
                        localErrorList[ErrorMsg.flex_valveToSmall.key] = ErrorMsg.flex_valveToSmall.msg;
                    }

                    //driftParams.dispatch({ type: 'setError', payload: ErrorMsg.flex_valveToSmall });
                    return;
                }

                if (drivingPressure < flexObj.data.minPressure) {
                    //console.error(ErrorMsg.flex_lowPressure.msg);
                    if (useContext === true) {
                        errorList.addError(errorContext, ErrorMsg.flex_lowPressure.key, ErrorMsg.flex_lowPressure.msg);
                    }
                    else {
                        localErrorList[ErrorMsg.flex_lowPressure.key] = ErrorMsg.flex_lowPressure.msg;
                    }

                    //driftParams.dispatch({ type: 'setError', payload: ErrorMsg.flex_lowPressure });
                    return;
                }

                if (drivingPressure > flexObj.data.maxPressure) {
                    //console.error(ErrorMsg.flex_highPressure.msg);
                    if (useContext === true) {
                        errorList.addError(errorContext, ErrorMsg.flex_highPressure.key, ErrorMsg.flex_highPressure.msg);
                    }
                    else {
                        localErrorList[ErrorMsg.flex_highPressure.key] = ErrorMsg.flex_highPressure.msg;
                    }


                    //driftParams.dispatch({ type: 'setError', payload: ErrorMsg.flex_highPressure });
                    return;
                }

                svOfMax = calcSvOfMax(primFlow, maxFlow);
                svSettings = flex_calcControlValveSetting(primFlow, flexObj, model);

                //console.log("SV Max: ", svOfMax);
                //console.log("Settings: ", svSettings);

                return { flexObj: flexObj, svOfMax: svOfMax, svSet: svSettings, isSpecial: false }
            }
        }
    }

    let goodFlowDims = [];
    let finalDims = [];
    flexDims.forEach((dim) => {
        let qMin = dim.data.qMax[0];
        let qMax = dim.data.qMax[dim.data.qMax.length - 1];

        if (primFlow >= qMin && primFlow <= qMax) {
            goodFlowDims.push(dim);
            if (drivingPressure >= dim.data.minPressure && drivingPressure <= dim.data.maxPressure) {
                finalDims.push(dim);
            }
        }
    });

    let isSpecial = false;
    //If no flex-dimensions matched the flow
    if (goodFlowDims.length === 0) {

        let dim = flexDims[0];

        if (primFlow < dim.data.qMax[0]) {
            console.log("TP COMPACT TO THE RESQUE 1:", specialFlexDim[0].data, primFlow >= specialFlexDim[0].data?.qMax[0])
            if (specialFlexDim[0] != null && specialFlexDim[0].data?.qMax != null && primFlow >= specialFlexDim[0].data?.qMax[0] && calcMode === 1) {
                console.log("TP COMPACT TO THE RESQUE 1B:", specialFlexDim)
                let specialDim = specialFlexDim[0];

                let minFlow = specialDim.data?.qMax[0];
                let maxFlow = specialDim.data?.qMax[specialDim.data?.qMax.length - 1];

                flexObj = specialDim;
                svOfMax = calcSvOfMax(primFlow, maxFlow);
                svSettings = flex_calcControlValveSetting(primFlow, flexObj, model);
                isSpecial = true;

                if (useContext === true) {
                    noticeList.addNotice(noticeContext, NoticeMsg.flex_lowFlowChangeModel.key, NoticeMsg.flex_lowFlowChangeModel.msg);
                }
                else {
                    localNoticeList[NoticeMsg.flex_lowFlowChangeModel.key] = NoticeMsg.flex_lowFlowChangeModel.msg;
                }
            }
            else {
                flexObj = dim;
                svOfMax = calcSvOfMax(primFlow, dim.data.qMax[dim.data.qMax.length - 1]);
                svSettings = dim.data.qMaxPosStart;

                //console.error(ErrorMsg.flex_noAcceptableValve.msg);
                if (useContext === true) {
                    errorList.addError(errorContext, ErrorMsg.flex_noAcceptableValve.key, ErrorMsg.flex_noAcceptableValve.msg);
                }
                else {
                    localErrorList[ErrorMsg.flex_noAcceptableValve.key] = ErrorMsg.flex_noAcceptableValve.msg;
                }
            }



            return { flexObj: flexObj, svOfMax: svOfMax, svSet: svSettings, isSpecial: isSpecial }
        }

        dim = flexDims[flexDims.length - 1];

        if (primFlow > dim.data.qMax[dim.data.qMax.length - 1]) {
            flexObj = dim;
            svOfMax = calcSvOfMax(primFlow, dim.data.qMax[dim.data.qMax.length - 1]);
            svSettings = 10; //??

            //console.error(ErrorMsg.flex_noAcceptableValve.msg);
            if (useContext === true) {
                errorList.addError(errorContext, ErrorMsg.flex_noAcceptableValve.key, ErrorMsg.flex_noAcceptableValve.msg);
            }
            else {
                localErrorList[ErrorMsg.flex_noAcceptableValve.key] = ErrorMsg.flex_noAcceptableValve.msg;
            }


            return { flexObj: flexObj, svOfMax: svOfMax, svSet: svSettings, isSpecial: isSpecial }
        }
    }
    else if (finalDims.length === 0) {
        let dim = goodFlowDims[goodFlowDims.length - 1];
        flexObj = dim;
        svOfMax = calcSvOfMax(primFlow, dim.data.qMax[dim.data.qMax.length - 1]);
        svSettings = flex_calcControlValveSetting(primFlow, flexObj, model);


        if (drivingPressure < dim.data.minPressure) {
            //console.error(ErrorMsg.flex_lowPressure.msg);
            if (useContext === true) {
                errorList.addError(errorContext, ErrorMsg.flex_lowPressure.key, ErrorMsg.flex_lowPressure.msg);
            }
            else {
                localErrorList[ErrorMsg.flex_lowPressure.key] = ErrorMsg.flex_lowPressure.msg;
            }

        }
        else {
            //console.error(ErrorMsg.flex_highPressure.msg);
            if (useContext === true) {
                errorList.addError(errorContext, ErrorMsg.flex_highPressure.key, ErrorMsg.flex_highPressure.msg);
            }
            else {
                localErrorList[ErrorMsg.flex_highPressure.key] = ErrorMsg.flex_highPressure.msg;
            }

        }

        return { flexObj: flexObj, svOfMax: svOfMax, svSet: svSettings, isSpecial: isSpecial }
    }
    else {
        for (const dim of finalDims) {
            let maxQmax = dim.data.qMax[dim.data.qMax.length - 1];
            if (primFlow < maxQmax * 0.86) {
                //console.log("***Flow: ", primFlow);
                //console.log("***maxQ: ", maxQmax);

                flexObj = dim;
                svOfMax = calcSvOfMax(primFlow, maxQmax);
                svSettings = flex_calcControlValveSetting(primFlow, flexObj, model);
                return { flexObj: flexObj, svOfMax: svOfMax, svSet: svSettings, isSpecial: isSpecial }
            }
        }


        // If non of the available dims passed the 86% safety limit.
        //console.log(finalDims)
        let dim = finalDims[finalDims.length - 1];
        let maxQmax = dim.data.qMax[dim.data.qMax.length - 1];

        flexObj = dim;
        //console.log("***Else Flow: ", primFlow);
        //console.log("***Else maxQ: ", maxQmax);
        svOfMax = calcSvOfMax(primFlow, maxQmax);
        svSettings = flex_calcControlValveSetting(primFlow, flexObj, model);

        if (primFlow < maxQmax) {
            //console.error(ErrorMsg.flex_safetyMargin.msg);
            if (calcMode === 0) {
                if (useContext === true) {
                    noticeList.addNotice(noticeContext, NoticeMsg.flex_safetyMargin_man.key, NoticeMsg.flex_safetyMargin_man.msg);
                }
                else {
                    localNoticeList[NoticeMsg.flex_safetyMargin_man.key] = NoticeMsg.flex_safetyMargin_man.msg;
                }

            }
            else {
                if (useContext === true) {
                    errorList.addError(errorContext, ErrorMsg.flex_safetyMargin.key, ErrorMsg.flex_safetyMargin.msg);
                }
                else {
                    localErrorList[ErrorMsg.flex_safetyMargin.key] = ErrorMsg.flex_safetyMargin.msg;
                }

            }


        }
        else {
            //console.error(ErrorMsg.flex_noAcceptableValve.msg);
            if (useContext === true) {
                errorList.addError(errorContext, ErrorMsg.flex_noAcceptableValve.key, ErrorMsg.flex_noAcceptableValve.msg);
            }
            else {
                localErrorList[ErrorMsg.flex_noAcceptableValve.key] = ErrorMsg.flex_noAcceptableValve.msg;
            }

        }

        return { flexObj: flexObj, svOfMax: svOfMax, svSet: svSettings, isSpecial: isSpecial }
    }
}

function calcSvOfMax(flow, qMaxVal) {
    return roundToDecimals(flow / qMaxVal, 2) * 100;
}

export { flex_calcControlValveDimensions }