import { Box, Divider, Skeleton, Stack, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { NO_MIXINGVESSEL } from "../../../../constants/adjustmentsOptions";
import { useAdjustParams, useShuntParams } from "../../../../contexts";
import { AdjustmentsEnum, ShuntParamsEnum } from "../../../../enums";

import { InspectPageComponentRow } from "./inspectPageComponentRow";
import { get, ref } from "firebase/database";
import { useDatabase } from "reactfire";
import { SESSION_SHUNT_PATH } from "../../../../constants/firebasePaths";
import { InspectPageMixingVesselRow } from "./inspectPageMixingVesselRow";

export const InspectPageMixingVesselData = ({ useCurrent = true, shuntData = null }) => {
    const database = useDatabase();
    const adjustParams = useAdjustParams();
    const shuntParams = useShuntParams();

    const [loading, setLoading] = useState(true);
    const [mixingVesselObject, setMixingVesselObject] = useState(Object);

    useEffect(() => {
        if (hasMixingVessel() === true) {
            let vesselID = ShuntParamsEnum.Addons.getContextValue(shuntParams)['mixingVessel'];

            if (vesselID != null) {
                const vesselRef = ref(database, SESSION_SHUNT_PATH + vesselID);

                get(vesselRef).then((snap) => {
                    if (snap.exists()) {
                        setMixingVesselObject(snap.val());
                    }
                }).catch((err) => {
                    console.error("FB Error: ", err);
                }).finally(() => {
                    setLoading(false);
                })
            }
        }
        else {
            setLoading(false);
        }
    }, [AdjustmentsEnum.MixingVessels.getContextValue(adjustParams), ShuntParamsEnum.Addons.getContextValue(shuntParams)])

    const hasMixingVessel = () => {
        if (ShuntParamsEnum.Addons.getContextValue(shuntParams) != null && ShuntParamsEnum.Addons.getContextValue(shuntParams)['mixingVessel'] != null) {
            return true;
        }

        return false;
    }

    return (
        <Fragment>
            {hasMixingVessel() ? (
                <Fragment>
                    <Divider />
                    {loading ? (
                        <>
                            <Box paddingTop={'15px'}>
                                <Skeleton animation={'wave'} width={'400px'} height={'25px'} />
                                <Skeleton animation={'wave'} width={'370px'} height={'20px'} />
                                <Skeleton animation={'wave'} width={'370px'} height={'20px'} />
                                <Skeleton animation={'wave'} width={'270px'} height={'20px'} />
                                <Skeleton animation={'wave'} width={'290px'} height={'20px'} />
                            </Box>
                        </>
                    ) : (
                        <Stack spacing={0.1} style={{ paddingTop: "20px", paddingBottom: "5px" }}>
                            <Typography component={'h3'}>
                                <strong><u>Pos {mixingVesselObject?.Baseinfo?.position}, Glykolblandningskärl</u></strong>
                            </Typography>
                            <InspectPageMixingVesselRow objData={mixingVesselObject} component="mixingVessel" />
                            <InspectPageMixingVesselRow objData={mixingVesselObject} component="pump" />
                            <InspectPageMixingVesselRow objData={mixingVesselObject} component="connHose" />
                            <InspectPageMixingVesselRow objData={mixingVesselObject} component="hole" />
                        </Stack>
                    )}

                </Fragment>
            ) : (
                <Fragment></Fragment>
            )}
        </Fragment>
    )
}