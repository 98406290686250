import { createContext, useContext, useReducer } from 'react'
import { FSDATA_PARAMS } from '../constants/sessionStoragePaths';

const FSData = createContext(undefined);

function fsDataReducer(state, action) {
    switch (action.type) {
        case 'setDims': {
            let data = {};
            if (sessionStorage.getItem(FSDATA_PARAMS) != null){
                data = JSON.parse(sessionStorage.getItem(FSDATA_PARAMS));
            }

            data['dims'] = action.payload;
            sessionStorage.setItem(FSDATA_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setFlexDims': {
            let data = {};
            if (sessionStorage.getItem(FSDATA_PARAMS) != null){
                data = JSON.parse(sessionStorage.getItem(FSDATA_PARAMS));
            }
            
            data['flexDims'] = action.payload;
            sessionStorage.setItem(FSDATA_PARAMS, JSON.stringify(data));
            return { val: data}
        }
        case 'setSmartDims': {
            let data = {};
            if (sessionStorage.getItem(FSDATA_PARAMS) != null){
                data = JSON.parse(sessionStorage.getItem(FSDATA_PARAMS));
            }
            
            data['smartDims'] = action.payload;
            sessionStorage.setItem(FSDATA_PARAMS, JSON.stringify(data));
            return { val: data}
        }
        case 'clear': {
            sessionStorage.removeItem(FSDATA_PARAMS);
            return { val: null }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function FSDataProvider({ children }) {
    let obj = {};
    if (sessionStorage.getItem(FSDATA_PARAMS) != null) {
        obj = JSON.parse(sessionStorage.getItem(FSDATA_PARAMS));
    }

    //@ts-ignore
    const [state, dispatch] = useReducer(fsDataReducer, { val: obj })
    const value = { state, dispatch }
    return (
        <FSData.Provider value={value}>
            {children}
        </FSData.Provider>);
}

function useFSData() {
    const context = useContext(FSData)
    if (context === undefined) {
        throw new Error('useFSData must be used within a FSDataProvider')
    }
    return context
}


export { FSDataProvider, useFSData }