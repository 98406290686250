//Cabinet-values
export const CABINET_INSULATED = "cabinet_isolated";
export const CABINET_UNINSULATED = "cabinet_uninsulated";
export const NO_CABINET = "noCabinet"

//Material
export const MATERIAL_PAINTED = "paintedP235GH";
export const MATERIAL_STAINLESS = "stainlessEN1.4436";

//Insulation
export const INSULATION_13ARMAFLEX = "armaflex13";
export const INSULATION_19ARMAFLEX = "armaflex19";
export const INSULATION_13ULTIMA = "ultima13";
export const INSULATION_19ULTIMA = "ultima19";
export const INSULATION_13NH_SMART = "nh_smart13";
export const INSULATION_19NH_SMART = "nh_smart19";

//Avstängningsventil
export const CUTOFF_3601_2301 = "3601/2301";
export const CUTOFF_3701_2301 = "3701/2301";
export const CUTOFF_3601_2310 = "3601/2310";
export const CUTOFF_GENERIC = "genericCutOff";
export const CUTOFF_GENERIC_LOW_LEAD = "genericLowLeadCutOff"
export const CUTOFF_BA107S_BA115TE = "BA107S/BA115TE";
export const CUTOFF_VM4205_3001 = "VM4205/3001";
export const CUTOFF_190S_115TE = "190S/115TE";

//Thermo
export const THERMO_BIMETALLIC = "bimetallic";
export const THERMO_SPRIT110MM = "sprit110mm";
export const THERMO_QVINTUS = "qvintus";

//Manometer
export const NO_MANOMETER = "none";
export const MANOMETER_2_5_BAR = "0-2,5 bar";
export const MANOMETER_4_BAR = "0-4 bar";
export const MANOMETER_6_BAR = "0-6 bar";
export const MANOMETER_10_BAR = "0-10 bar";

//Stand
export const STAND_WALL = "wall";
export const STAND_FLOOR = "floor";

//Adjustmentvalve Value
export const ADJUSTVALUE_STAD = "STAD";
export const ADJUSTVALUE_STAD_ZERO = "STAD ZERO";

//Blandningskärl
export const NO_MIXINGVESSEL = "Inget blandningskärl";
export const MIXINGVESSEL_60L = "60 L";
export const MIXINGVESSEL_100L = "100 L";
export const MIXINGVESSEL_200L = "200 L";
export const MIXINGVESSEL_300L = "300 L";
export const MIXINGVESSEL_500L = "500 L";
//export const MIXINGVESSEL_320L = "320 L";
//export const MIXINGVESSEL_520L = "520 L";
export const MIXINGVESSEL_1000L = "1000 L";

//Connection Design
export const CONN_DESIGN_U = "u";
export const CONN_DESIGN_H = "h";
export const CONN_DESIGN_L = "l";

//Connection Value
export const CONN_VALUE_NOT_SPECIFIED = "_";
export const CONN_VALUE_R = "r";
export const CONN_VALUE_L = "l";
export const CONN_VALUE_VRD = "vrd";
export const CONN_VALUE_VLD = "vld";
export const CONN_VALUE_VRU = "vru";
export const CONN_VALUE_VLU = "vlu";
export const CONN_VALUE_HR = "hr";
export const CONN_VALUE_HL = "hl";
export const CONN_VALUE_UR = "ur";
export const CONN_VALUE_UL = "ul";
export const CONN_VALUE_DR = "dr";
export const CONN_VALUE_DL = "dl";
export const CONN_VALUE_RU = "ru";
export const CONN_VALUE_LU = "lu";
export const CONN_VALUE_RD = "rd";
export const CONN_VALUE_LD = "ld";


export const CONN_LATCH = "latch";
