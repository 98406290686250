import { SAVE_XML_PATH, SAVE_XML_PATH_LOCAL } from "../../constants/apiPaths";
import { SessionParamsEnum } from "../../enums";
import { getShuntData } from "../../firebase/getShuntData";
import { createComponentList, createTechInfoList } from "../DisplayFunctions/createComponentsTextList";

export const saveXmlFile = async (database, sessionParams, calcParams, productLineParams, shuntParams, adjustParams, pumpParams, driftParams, wizardParams, systemFluidOptions) => {
    const sessionID = SessionParamsEnum.SessionID.getContextValue(sessionParams);
    //const shuntID = SessionParamsEnum.CurrentShunt.getContextValue(sessionParams);
    const method = SessionParamsEnum.ApiMethod.getContextValue(sessionParams);
    const shuntList = SessionParamsEnum.SessionShunts.getContextValue(sessionParams);

    let prodInfoList = {}
    let techInfoList = {}
    let version;

    for (let index = 0; index < shuntList.length; index++) {
        const shunt = shuntList[index];
        if (shunt?.id != null) {
            const shuntData = await getShuntData(database, shunt?.id);

            prodInfoList[shunt?.id] = createComponentList(shuntData, calcParams, productLineParams, shuntParams, adjustParams, pumpParams, driftParams, wizardParams, systemFluidOptions);
            techInfoList[shunt?.id] = createTechInfoList(shuntData, calcParams, productLineParams, shuntParams, adjustParams);
        }
        //console.log("ShuntID: ", shuntID, techInfoList[shuntID])
    }

    if (method === 'create' || method === 'update') {
        version = 'autocad';
    }
    else {
        version = 'revit';
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            sessionID: sessionID,
            compList: prodInfoList,
            techList: techInfoList,
            version: version,
        }),
    };

    let path = SAVE_XML_PATH;

    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
        path = SAVE_XML_PATH_LOCAL;
    }

    const response = await fetch(path, requestOptions)
    const data = await response.json();
    //console.log("Status: ", response.status)
    if (response.status === 200) {
        console.log("RES: ", data.msg);
    }
    else {
        console.error("RES: ", data.msg);
    }
    //console.log("RES: ", res);
}