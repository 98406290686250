import { Paper, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material"
import { ShuntParamsEnum } from "../../../../../enums"

export const AdjustCardShuntSize = ({ shuntSize, setAdjustParams, isDisabled }) => {
    return (
        <div>
            <Paper elevation={0} className="adjust-card">
                <FormControl>
                    <FormLabel id="shuntSize-radio-buttons-group">Storleksalternativ</FormLabel>
                    <RadioGroup
                        aria-labelledby="shuntSize-radio-buttons-group"
                        name="shuntSize"
                        value={shuntSize}
                        onChange={(event) => { setAdjustParams(event.target.value, ShuntParamsEnum.IsMini) }}
                    >
                        <FormControlLabel value={false} control={<Radio />} label="Standardstorlek" disabled={isDisabled()} />
                        <FormControlLabel value={true} control={<Radio />} label="Miniutförande (M)" disabled={isDisabled()} />

                    </RadioGroup>
                </FormControl>
            </Paper>
        </div>
    )
}