
/**
 * 
 * @param {number} pressure 
 * @param {number} divideBy 
 * @returns {number}
 */
const calcAuthBase = function calcAuthBase(pressure, divideBy) {
    return (pressure / divideBy) * 100;
}

export { calcAuthBase }