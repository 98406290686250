import * as React from 'react'

import pr2 from '../assets/images/connectionTypes/small/pr2.jpeg'
import pr3 from '../assets/images/connectionTypes/small/pr3a.jpeg'
import sr2 from '../assets/images/connectionTypes/small/sr2.jpeg'
import sr2Flex from '../assets/images/connectionTypes/small/sr2_flex.png'
import sr2Smart from '../assets/images/connectionTypes/small/sr2_smart.png'
import sr3 from '../assets/images/connectionTypes/small/sr3.jpeg'
import vaa from '../assets/images/connectionTypes/small/va1.jpeg'
import pr2L from '../assets/images/connectionTypes/pr2_2_noBV.png'
import pr3L from '../assets/images/connectionTypes/pr3a_2.png'
import sr2L from '../assets/images/connectionTypes/sr2_2.png'
import sr2FlexL from '../assets/images/connectionTypes/sr2_flex_2.png'
import sr2SmartL from '../assets/images/connectionTypes/sr2_smart_2.png'

import sr3L from '../assets/images/connectionTypes/sr3_2.png'
import vaaL from '../assets/images/connectionTypes/vaa_2.png'


const connectionTypes = [

    {
        id: "SR-2",
        header: 'För system med tillgängligt drivtryck',
        imageRef: sr2,
        name: "SR-2",
        paramImageRef: sr2L,
        primText: 'För ventilation (värme och kyla), radiatorer, golvvärme och kylbafflar',
        firstListText: 'Primära flödet variabelt',
        secondListText: 'Sekundära flödet lika eller större än primära',
        thirdListText: '2-vägs styrventil',
        fourthListText: 'Styrventil placerad i primärsidans retur',
        fifthListText: 'Huvudpump erfodras',
        sixthListText: null,
        seventhListText: null,
        eightListText: null,
    },

    {
        id: "SR-2-flex",
        header: 'För system med tillgängligt drivtryck',
        imageRef: sr2Flex,
        name: "SR-2-flex",
        paramImageRef: sr2FlexL,
        primText: 'För ventilation (värme och kyla), radiatorer, golvvärme och kylbafflar, med inbyggd differenstrycksregulator i styrventil',
        firstListText: 'Primära flödet variabelt',
        secondListText: 'Sekundära flödet lika eller större än primära',
        thirdListText: '2-vägs styrventil',
        fourthListText: 'Styrventil placerad i primärsidans retur',
        fifthListText: 'Huvudpump erfodras',
        sixthListText: 'Automatisk anpassning till förändringar i tillgängligt drivtryck',
        seventhListText: 'Alltid god auktoritet',
        eightListText: 'Justerbart flöde (inom shuntgruppens flödesområde)',
        type: 'flex'
    },

    {
        id: "SR-2-smart",
        header: 'För system med tillgängligt drivtryck',
        imageRef: sr2Smart,
        name: "SR-2-smart",
        paramImageRef: sr2SmartL,
        primText: 'För ventilation (värme och kyla), radiatorer, golvvärme och kylbafflar, med automatisk mätning av flöde, temperatur och effekt',
        firstListText: 'Primära flödet variabelt',
        secondListText: 'Sekundära flödet lika eller större än primära',
        thirdListText: '2-vägs styrventil',
        fourthListText: 'Styrventil placerad i primärsidans retur',
        fifthListText: 'Huvudpump erfodras',
        sixthListText: 'Inbyggd mätning av flöde, temperatur och effekt',
        seventhListText: 'Ställbart min- och maxflöde, med koppling till ställdon',
        eightListText: 'Uppkopplingsbar/fjärrdiagnostik',
        type: 'smart'
    },

    {
        id: "SR-3",
        header: 'För system med tillgängligt drivtryck',
        imageRef: sr3,
        name: "SR-3",
        paramImageRef: sr3L,
        primText: 'För ventilation (värme och kyla), radiatorer, golvvärme och kylbafflar',
        firstListText: 'Primära flödet konstant',
        secondListText: 'Sekundära flödet lika eller större än primära',
        thirdListText: '3-vägs styrventil',
        fourthListText: 'Styrventil placerad i primärsidans retur',
        fifthListText: 'Huvudpump erfodras',
        sixthListText: null,
        seventhListText: null,
        eightListText: null,
    },

    {
        id: "PR-2",
        header: 'För system med lågt eller obefintligt drivtryck',
        imageRef: pr2,
        name: "PR-2",
        paramImageRef: pr2L,
        primText: 'För ventilation (värme och kyla), radiatorer och förshuntning',
        firstListText: 'Primära flödet variabelt',
        secondListText: 'Sekundära flödet lika eller lägre än primära',
        thirdListText: '3-vägs styrventil',
        fourthListText: 'Styrventil placerad i sekundärsidans framledning',
        fifthListText: 'Huvudpump erfodras ej',
        sixthListText: null,
        seventhListText: null,
        eightListText: null,
    },

    {
        id: "PR-3",
        header: 'För system med lågt drivtryck',
        imageRef: pr3,
        name: "PR-3",
        paramImageRef: pr3L,
        primText: 'För ventilation (kyla och värme) och radiatorer',
        firstListText: 'Primära flödet konstant',
        secondListText: 'Sekundära flödet lika eller lägre än primära',
        thirdListText: '3-vägs styrventil',
        fourthListText: 'Styrventil placerad i sekundärsidans framledning',
        fifthListText: 'Huvudpump erfodras',
        sixthListText: null,
        seventhListText: null,
        eightListText: null,
    },

    {
        id: "VA-1",
        header: 'Värmeåtervinning',
        imageRef: vaa,
        name: "VÅ-1",
        paramImageRef: vaaL,
        primText: 'För värmeåtervinning med vätskekopplade batterier',
        firstListText: 'Tilluft primärkrets',
        secondListText: 'Frånluft sekundärkrets',
        thirdListText: null,
        fourthListText: null,
        fifthListText: null,
        sixthListText: null,
        seventhListText: null,
        eightListText: null,
    }
]

const ConTypes = React.createContext(
    []
);

function ConTypesProvider({ children }) {

    return (
        <ConTypes.Provider value={connectionTypes}>
            {children}
        </ConTypes.Provider>);
}

function useConnTypes() {
    const context = React.useContext(ConTypes)
    if (context === undefined) {
        throw new Error('useCount must be used within a CountProvider')
    }
    return context
}


export { ConTypesProvider, useConnTypes }