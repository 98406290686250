import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportIcon from '@mui/icons-material/Report';

const help = function help({ loc }) {

    if (loc === "parameters") {
        return (
            <>
                <p>Fyll i vätsketemperaturer, sekundärt vätskeflöde, tillgängligt drivtryck primärt (externt tryckfall primärt för VÅ samt PR-2 utan huvudpump) och tryckfall i anslutet sekundärsystem i de vita rutorna. Fyll även i rutan Bet. (beteckning), t.ex. VS1-SHG. Programmet räknar fram flödet primärt samt avgör om det är en värme- eller kylshuntgrupp. OBS! Behov av kondensisolering avgörs av de absoluta temperaturvärdena i systemet, medan exempelvis temperaturspärrar i vissa utföranden avgörs av klassificeringen värme/kyla.</p>

                <p>Tryck sedan på <strong>Beräkna</strong>.
                    <br></br>
                    Om något värde saknas, är felaktigt eller ligger utanför programmets max/min spärrar så kommer en röd larmruta med förklaring. Vid sidan av dessa kan även icke-kritiska notismeddelanden (orange) dyka upp, med tips eller påpekningar om gränslägen och liknande att vara särskilt uppmärksam på.</p>

                <p>Är det tillgängliga drivtrycket osäkert kan det vara bra att prova med lite högre eller lägre tillgängligt drivtryck för att se hur shuntgruppens dimensioner påverkas.</p>

                <p>Kontrollera om beräkningsresultatet är enligt önskemål och tryck sedan på <strong>framåtpilen</strong> för att gå vidare. Det går inte att gå vidare vid förekomst av kritiskt felmeddelande (rött), medan icke-kritiska notiser (orange) tillåter vidare konfigurering.</p>

                <p>Det är möjligt att lämna en pågående dimensionering via Översikt-funktionen eller menyn för skifte mellan positioner i körningen. Värdena efter senast genomförda beräkning kommer finnas sparade, och vid senare skifte tillbaks till den aktuella positionen kommer man automatiskt ledas till Parameter-fliken och kan återuppta konfigureringen för positionen.</p>

                <p><strong>Manuellt läge</strong>
                    <br></br>
                    I manuellt läge går det att manuellt andra rördimension primärt och sekundärt, samt välja fabrikat och dimension/kvs-värde på styrventilen. Om en automatisk dimensionering görs innan övergång till manuellt läge kommer alla värden från den automatiska dimensioneringen vara förifyllda och enbart utvalda parametrar/dimensioner behöver ändras. Vid direkt övergång till manuellt läge, utan att först ha gjort en automatisk beräkning, behöver alla dimensioneringar och val göras för hand.</p>

                <p><strong>OBS!</strong> Manuellt läge har inte samma spärrar varför det kräver noggrannare kontroll av att resultatet är rimligt och enligt önskemål.</p>

                <p>Om man misstänker att man hamnat i ett gränsfall i en automatisk beräkning: Prova gärna att ändra dimension alternativt kvs i manuellt läge.</p>

                <p><strong>Styrventilsauktoritet</strong>
                    <br></br>
                    För att räkna ut styrventilsauktoriteten måste tryckfallet i den flödesvariabla delen av systemet vara känt. För SR-2 samt PR-2 med huvudpump är detta värde inte en del av de obligatoriska dimensioneringsparametrarna, och behöver därmed anges separat om auktoritetsberäkning önskas.</p>

                <p>För att göra detta:  Gå till manuellt läge &rarr; Fyll i tryckfallet i den flödesvariabla delen av systemet (efk-rutan) &rarr; Tryck <strong>Beräkna</strong> &rarr; Återgå till automatiskt läge. Auktoriteten kommer nu visas och uppdateras i vidare automatiska beräkningar.</p>

                <p><strong>Styrventilsfabrikat</strong>
                    <br></br>
                    I manuellt läge kan styrventilens fabrikat och modell samt dimension väljas. Alternativt kan olika kvs-värden för styrventil utan specificerat fabrikat testas (ej för de flödesområdesbaserade styrventilerna i SR-2 flex samt SR-2 smart).</p>

                <p><strong>Injusteringsventiler</strong>
                    <br></br>
                    Som standard räknar programmet med IMI's STAD/F. Om annat fabrikat önskas kan det väljas i manuellt läge. Gå till manuellt läge &rarr; Välj fabrikat &rarr; Tryck Beräkna &rarr; Återgå till automatiskt läge. Det manuella valet av injusteringsventil finns nu kvar i vidare automatiska beräkningar.</p>

                <p>För shuntgrupper i green-utförande kan val mellan IMI´s STAD ZERO/STAF respektive Purmo´s Evobalance/STV göras direkt i automatiskt läge.</p>


            </>
        )
    }

    if (loc === "overview") {
        return (
            <>
                <p>Här på fliken Hantera shuntgrupper i Översiktsfönstret ser du en tabell över alla shuntgrupper i den aktuella körningen. I den här tabellen kan du bland annat ta bort shuntgrupper som du inte önskar behålla. Det går inte att ta bort den shuntgrupp som för närvarande är vald som aktiv. Du väljer vilken shuntgrupp som ska vara aktiv genom att klicka på dess rad i tabellen. När du stänger ner Översiktsfönstret så kommer du då befinna dig på den valda shuntgruppens konfigurering. Du kan även dubbelklicka på en rad i tabellen för att automatiskt stänga Översiktsfönstret och komma direkt in i konfigureringen.</p>

                <p>Längst till vänster på varje rad i tabellen kan du se aktuell status för respektive position:</p>

                <p><CheckCircleIcon className="check-icon-green-help" /> indikerar att positionen är färdigkonfigurerad och inte har några felmeddelanden.</p>

                <span style={{ paddingRight: '4px', paddingTop: '3px' }}><svg style={{ color: '#1976d2', height: '1.2em', width: '1.2em' }} focusable="false" aria-hidden="true" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12" style={{ fill: '#06559e' }}></circle><text style={{ fill: '#fff', fontFamily: 'roboto', color: '#06559e' }} x="12" y="16" textAnchor="middle">2</text></svg></span>
                <span>indikerar att positionen ännu inte är färdigkonfigurerad/granskad. Siffran indikerar vilket steg som konfigureringen för närvarande befinner sig, och du kommer automatiskt att tas hit om du väljer shuntgruppen som aktiv.</span>

                <p><ReportIcon fontSize="small" color='error' /> indikerar att positionen har ett kritiskt felmeddelande som måste åtgärdas innan den kan konfigureras färdigt. Positionen kan ha denna felstatus antingen för att du lämnade dess konfigurering när den hade ett aktivt felmeddelande, eller som ett resultat av en omberäkning via Snabbredigerings-funktionen (se längre ner).</p>

                <p><strong>Lägg till shuntgrupp</strong>
                    <br></br>
                    Via knappen Lägg till shuntgrupp kan du välja att skapa en ny position helt från grunden, eller basera den nya shuntgruppen på en befintlig position. Du kan styra vilken position som den nya shuntgruppen baseras på genom att sätta den som aktiv i tabellen. En nyskapad position som baserats på en tidigare har systemparametrar och konfigureringsval förifyllda, men du måste fortfarande gå igenom konfigurationens alla steg för att granska och godkänna alla dimensioneringar och val. Det är helt fritt att ändra parametrar och val för den nya shuntgruppen, även om den baserats på en annan position.</p>

                <p><strong>Snabbredigering</strong>
                    <br></br>
                    Det är möjligt att ändra vissa systemparametrar för en eller flera positioner och genomföra omberäkning/dimensionering direkt från Hantera shuntgrupper-tabellen. När du klickar på knappen Snabbredigering så kan du bland annat redigera Anläggning och Projektnummer för körningen, samt ändra positionernas Beteckning. Det är även möjligt att ändra systemparametervärdena för tillgängligt drivtryck samt primär vätsketemperatur till shuntgruppen. När du sedan klickar på Uppdatera shuntgrupper så kommer alla ändringar sparas, och dimensioneringsberäkningarna kommer göras om för alla positioner med nya systemparametervärden. Om beräkningarna baserade på de nya värdena har gett upphov till ett felmeddelande så kommer detta framgå i tabellen, och du behöver gå till den aktuella positionens konfigurering för att granska felmeddelandet och vidta lämpliga åtgärder.</p>

                <p><strong>Specifikation</strong>
                    <br></br>
                    I menyn till höger hittar du knappen Specifikation. Via den kan du se en sammanställning över alla dina färdigkonfigurerade shuntgrupper i den aktuella körningen. Du kan välja att skriva ut sammanställningen direkt, eller spara ner specifikationen i Word-format.</p>

                <p><strong>Dela session</strong>
                    <br></br>
                    I menyn till höger finns även knappen Dela session. Du kan när som helst under en körning använda dig av denna funktion för att skicka ett automatiskt epostmeddelande till PREMA, med en länk till din aktuella körning. Du behöver ange dina kontaktuppgifter, och kan även skriva ett meddelande som bifogas länken, exempelvis med en fråga eller önskemål.</p>

                <p>Det finns även stöd för att manuellt ange en annan mottagare än PREMA, om du exempelvis vill dela en körning med en kollega eller liknande.</p>

                <p><strong>Begär offert</strong>
                    <br></br>
                    Förutsatt att alla positioner i körningen är färdigkonfigurerade så finns det möjlighet att begära en offert för shuntgrupperna direkt via Begär offert-knappen. Du behöver ange dina företags- och kontaktuppgifter, och du kommer få ett epostmeddelande med offert från en av PREMAs säljare efter att de granskat körningen som du begärt offert för. Det går såklart även bra att be om offert genom Dela session-funktionaliteten, eller genom att spara ner specifikationen i Word-format och bifoga i ett eget epostmeddelande, eller på annat vis skicka till någon av PREMAs säljare.</p>
            </>
        )
    }

}

export { help };