import introAccountImg1 from '../../assets/images/introImages/account/KoMs_Bild1.png'
import introAccountImg2 from '../../assets/images/introImages/account/KoMs_Bild2.png'
import introAccountImg3 from '../../assets/images/introImages/account/KoMs_Bild3.png'
import introAccountImg4 from '../../assets/images/introImages/account/KoMs_Bild4.png'
import introAccountImg5 from '../../assets/images/introImages/account/KoMs_Bild5.png'
import introAccountImg6 from '../../assets/images/introImages/account/KoMs_Bild6.png'
import introAccountImg7 from '../../assets/images/introImages/account/KoMs_Bild7.png'

export const IntroAccount = () => {
    return (
        <div>
            <h2 className="intro-title" style={{ marginBlockStart: '0px' }}>Konto och Mina sidor</h2>

            <p>
                Från och med ShuntLogik<sup>®</sup> 1.6 är det möjligt att ansöka om ett personligt konto. Alla körningar som görs när man är inloggad finns sedan tillgängliga att söka
                bland, och återuppta, i ens personliga Sessionshistorik. Utöver detta underlättas även kontakterna med PREMA vid rådgivning, support, förfrågningar och
                projektuppföljning.
            </p>

            <h3 className="intro-subheaders">Ansöka om konto</h3>
            <p>
                Formuläret för att ansöka om ett ShuntLogik<sup>®</sup>-konto hittar du dels på Start-sidan, dels uppe till höger inne i ShuntLogik<sup>®</sup>.
            </p>

            <p style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '20px', paddingBottom: '5px' }}>
                <img src={introAccountImg1} alt='Ansöka om konto - Start' width={'97%'} />
                <img src={introAccountImg2} alt='Ansöka om konto - I Session' width={'97%'} />
            </p>

            <p>
                När din ansökan behandlats kommer ett mail med inbjudan att färdigställa registreringen att skickas till e-postadressen du angivit vid ansökan, där du
                väljer ditt personliga lösenord som du sedan använder tillsammans med e-postadressen som inloggningsuppgifter.
            </p>

            <p style={{ fontSize: '0.8rem', fontStyle: 'italic', fontWeight: 700 }}>Tips: Kontrollera din Skräppost om du inte fått inbjudan att färdigställa kontoregistreringen inom 24 timmar</p>

            <h3 className="intro-subheaders">Logga in</h3>
            <p>
                För att dina körningar ska kopplas till ditt konto och komma med i din Sessionshistorik behöver du vara inloggad när de görs. Webbläsaren där du har loggat
                in kommer att komma ihåg dig när du besöker ShuntLogik<sup>®</sup> på nytt, förutsatt att du inte aktivt har loggat ut. Du kan verifiera att du är inloggad dels på Start-
                sidan, dels uppe till höger inne i ShuntLogik<sup>®</sup>.
            </p>

            <p style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '20px', paddingBottom: '5px' }}>
                <img src={introAccountImg3} alt='Inloggad - Start' width={'97%'} />
                <img src={introAccountImg4} alt='Inloggad - I Session' width={'97%'} />
            </p>

            <p>
                Det går bra att vara inloggad med sitt konto på flera datorer samtidigt, och Sessionshistoriken kommer att vara syncad mellan enheter.
            </p>
            <h3 className="intro-subheaders">Mina sidor och Sessionshistorik</h3>
            <p>
                När du är inne i en pågående körning i ShuntLogik<sup>®</sup> når du både Sessionshistoriken och Mina sidor via <strong>Översikt</strong>-fönstret. På Mina sidor kan du se de
                uppgifter du använt när du registrerade ditt konto, byta lösenord samt även inaktivera ditt konto om du skulle önska det. I Sessionshistoriken finns alla
                körningar kopplade till ditt konto. På första fliken finns alla sessioner som du själv valt att stjärnmärka, och du väljer själv om och hur du använder denna
                funktion. På andra fliken finns samtliga sessioner och här kan du sortera fallande eller stigande på respektive kolumn, använda filter samt söka i fritext. För
                varje session i historiken kan du välja att antingen öppna den i Granska-läge i en ny flik (utan att din pågående körning påverkas) eller växla aktiv session
                (observera att detta kommer att stänga den pågående sessionen du har öppen i ShuntLogik<sup>®</sup>).
            </p>

            <p style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '20px', paddingBottom: '5px' }}>
                <img src={introAccountImg5} alt='Stjärnmärkta sessioner' width={'97%'} />
                <img src={introAccountImg6} alt='Alla sessioner' width={'97%'} />
            </p>

            <p>
                Du når även Mina sidor och din Sessionshistorik direkt från Start-sidan, med samma funktionalitet som den via <strong>Översikt</strong>-fönstret inne i ShuntLogik<sup>®</sup>.
                Härifrån kan du välja att gå vidare in i ShuntLogik<sup>®</sup> med en av dina tidigare körningar, utan att först ha behövt skapa en ny och växla.
            </p>

            <p style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', paddingBottom: '5px' }}>
                <img src={introAccountImg7} alt='Mina Sidor - Start' width={'97%'} />
            </p>
        </div>
    )
}