import { useEffect, useState } from 'react'
import { Radio, RadioGroup, FormControlLabel, FormControl, Box, } from "@mui/material"
import HelpModal from '../introductionModal/helpModal';
import { useDriftParams, useShuntParams, useProductLineParams, useCalcParams, useErrorContext, useNoticeContext, useSessionParams } from '../../../contexts';
import { ProductLineEnum, DriftParamEnum, ShuntParamsEnum, CalcParamsEnum, ErrorEnum, NoticeEnum, FSDataEnum } from '../../../enums';
import { calculateShuntParams } from '../../../functions/calculateShuntParams';
import { useDatabase, useFirestore, useFirestoreCollection, useFirestoreCollectionData } from 'reactfire';
import { useFSData } from '../../../contexts/fsDataContext';
import { collection } from 'firebase/firestore';
import { getSpecialFlexDim } from '../../../firebase/functions/getSpecialFlexDim';
import { useWizardParams } from '../../../contexts/wizardParams';
import { useGetEnergyMeasurementList } from '../../../hooks/useGetEnergyMeasurementList';
import { useGetSystemFluidOptions } from '../../../hooks/useGetSystemFluidOptions';

import { PRODUCTLINE_FLEX, PRODUCTLINE_GREEN, PRODUCTLINE_SMART } from '../../../constants/productlineConstants';
import { FLEXMODEL_TAMODULATOR, CV_IMI, CV_MODEL_TASMART, CV_MODEL_KVS, CV_SOE } from '../../../constants/shuntOptions';
import { SHUNT_DIMS_PATH, CONTROL_VALVES_DIMS_FLEX_DEFAULT_PATH, CONTROL_VALVES_DIMS_SMART_DEFAULT_PATH } from '../../../constants/firebasePaths';


const CalculationMode = ({ isDisabled = false }) => {
    const driftParams = useDriftParams();
    const shuntParams = useShuntParams();
    const calcParams = useCalcParams();
    const wizardParams = useWizardParams();
    const firestore = useFirestore();
    const database = useDatabase();
    
    const systemFluidOptions = useGetSystemFluidOptions();

    const errorlist = new ErrorEnum();
    const noticelist = new NoticeEnum();

    const sessionParams = useSessionParams();
    const errorProvider = useErrorContext();
    const noticeProvider = useNoticeContext();
    const productLine = useProductLineParams();
    const fsDataParams = useFSData();

    const plType = ProductLineEnum.ProductLine.getContextValue(useProductLineParams());
    const [calculationMode, setCalculationMode] = useState('1');
    const [recalc, setRecalc] = useState(false);

    const calcMode = DriftParamEnum.CalcMode;

    const conTypeDoc = collection(firestore, SHUNT_DIMS_PATH);
    const firestoreRes = useFirestoreCollectionData(conTypeDoc);

    const [dimensionData, setDimensionData] = useState([]);
    const [flexDims, setFlexDims] = useState([]);
    const [smartDims, setSmartDims] = useState([]);

    const { heatEnergyMeasurementList, coolingEnergyMeasurementList } = useGetEnergyMeasurementList();


    useEffect(() => {
        if (firestoreRes.data != null) {
            //console.log("Data: ", firestoreRes.data)
            setDimensionData(firestoreRes.data);
            FSDataEnum.Dims.setContextValue(fsDataParams, firestoreRes.data);
        }

    }, [firestoreRes])

    let fsUrl = CONTROL_VALVES_DIMS_FLEX_DEFAULT_PATH;
    const dimsDoc = collection(firestore, fsUrl);
    const firestoreDimRes = useFirestoreCollection(dimsDoc);

    useEffect(() => {
        if (firestoreDimRes.data != null) {
            const localModels = [];

            firestoreDimRes.data.forEach(model => {
                localModels.push({ id: model.id, data: model.data() })
            });

            setFlexDims(localModels);
            FSDataEnum.FlexDims.setContextValue(fsDataParams, localModels);
        }

    }, [firestoreDimRes])

    const smartDimUrl = CONTROL_VALVES_DIMS_SMART_DEFAULT_PATH
    const smartDimsDoc = collection(firestore, smartDimUrl);
    const firestoreSmartDimRes = useFirestoreCollection(smartDimsDoc);

    useEffect(() => {
        if (firestoreSmartDimRes.data != null) {
            const localModels = [];

            firestoreSmartDimRes.data.forEach(model => {
                localModels.push({ id: model.id, data: model.data() })
            });

            setSmartDims(localModels);
            FSDataEnum.SmartDims.setContextValue(fsDataParams, localModels);
        }

    }, [firestoreSmartDimRes])

    useEffect(() => {
        const curValue = calcMode.getContextValue(driftParams);
        if (curValue != null && curValue !== "") {
            setCalculationMode(curValue);
        }
        else {
            setCalculationMode('1');
        }
    }, [calcMode.getContextValue(driftParams)]);


    const handleChangeCalcMode = (event) => {
        if (event.target.value === "1") {
            //Reset the ShuntParams to Automatic again
            if (plType === PRODUCTLINE_FLEX) {
                ShuntParamsEnum.FlexModel.setContextValue(shuntParams, FLEXMODEL_TAMODULATOR)
                ShuntParamsEnum.ControlValve.setContextValue(shuntParams, CV_IMI);
                //ShuntParamsEnum.AdjustmentValve.setContextValue(shuntParams, ADJUST_VALVE_STADF);
            }
            else if (plType === PRODUCTLINE_SMART) {
                ShuntParamsEnum.CV_Model.setContextValue(shuntParams, CV_MODEL_TASMART);
                ShuntParamsEnum.CV_Kvs.setContextValue(shuntParams, null);
                ShuntParamsEnum.ControlValve.setContextValue(shuntParams, CV_IMI);
                //ShuntParamsEnum.AdjustmentValve.setContextValue(shuntParams, ADJUST_VALVE_STADF);
            }
            else {
                ShuntParamsEnum.CV_Model.setContextValue(shuntParams, CV_MODEL_KVS);
                ShuntParamsEnum.CV_Kvs.setContextValue(shuntParams, null);
                ShuntParamsEnum.ControlValve.setContextValue(shuntParams, CV_SOE);
                ShuntParamsEnum.CV_IsInterval.setContextValue(shuntParams, false);
                DriftParamEnum.IVModification.setContextValue(driftParams, 0);
                //ShuntParamsEnum.AdjustmentValve.setContextValue(shuntParams, ADJUST_VALVE_STADF);
            }

            DriftParamEnum.HasBackVent.setContextValue(driftParams, '1');
            setRecalc(true)

        }
        calcMode.setContextValue(driftParams, event.target.value);
        setCalculationMode(event.target.value);
    };

    useEffect(() => {
        if (recalc === true) {
            const func = async () => {
                setRecalc(false);
                CalcParamsEnum.clearData(calcParams);
                errorlist.clearAllErrors(errorProvider);
                noticelist.clearAllNotices(noticeProvider);
                //console.log("SmartDimss:", smartDims)
                let specialDim = await getSpecialFlexDim(firestore);

                calculateShuntParams(productLine, driftParams, calcParams, shuntParams, errorProvider, noticeProvider, dimensionData, database, sessionParams, wizardParams, systemFluidOptions, flexDims, smartDims, null, specialDim, heatEnergyMeasurementList, coolingEnergyMeasurementList);
            }

            func();
        }
    }, [recalc])

    const disableManualOption = () => {
        if (plType === PRODUCTLINE_GREEN) {
            return true;
        }
        else {
            return false;
        }
    }

    const greenHover = () => {
        if (plType === PRODUCTLINE_GREEN) {
            return "Manuellt läge är ej tillgängligt vid Green-utförande"
        }
        else {
            return "";
        }
    }

    return (
        <div style={{ display: 'flex', justifyContent: "space-between" }}>
            <h3 style={{ marginTop: "1px", marginBottom: '0px' }}>Beräkningsläge</h3>

            <Box style={{ marginLeft: "30px", marginTop: '-3px' }}>
                <FormControl>

                    <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="ca"
                        value={calculationMode}
                        onChange={handleChangeCalcMode}
                    >

                        <FormControlLabel value="1" control={<Radio />} label="Automatiskt" disabled={isDisabled} />
                        <FormControlLabel value="0" control={<Radio />} disabled={isDisabled} label="Manuellt" />
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box style={{ justifyContent: "right", display: "flex" }}>
                <HelpModal loc={"parameters"} />
            </Box>
        </div>
    )
}

export default CalculationMode