import { PRODUCTPRINCIP_SR2, PRODUCTPRINCIP_SR2FLEX, PRODUCTPRINCIP_SR2SMART, PRODUCTPRINCIP_SR3, PRODUCTFAMILY_SR, PRODUCTPRINCIP_PR2, PRODUCTPRINCIP_PR3, PRODUCTFAMILY_PR, PRODUCTPRINCIP_VA1, PRODUCTFAMILY_VA, PRODUCTLINE_FLEX, PRODUCTLINE_SMART, PRODUCTLINE_GREEN, PRODUCTPRINCIP_SR2_FLEX_APPSHUNT, PRODUCTPRINCIP_PR2_APPSHUNT } from "../../constants/productlineConstants";
import { COOL_SHUNT, HEAT_SHUNT } from "../../constants/shuntOptions";
import { ProductLineEnum, DriftParamEnum, CalcParamsEnum, ShuntParamsEnum } from "../../enums";
import { roundToDecimals } from "../ShuntCalculations/helpFunctions/roundTo4Decimals";

export const getTechRowText = function getTechRowText(component, useCurrent, shuntData, calcParams, productLineParams, driftParams, shuntParams) {
    let text = [];

    let plLine;
    let plFamily;
    let plPrincip;
    let plGreenLvl;

    let primTempIn;
    let primTempOut;
    let secTempIn;
    let secTempOut;

    let secFlowEffect;
    let shuntType;

    let svPressureDrop;

    let primInternalPD;
    let mainPump;
    let isInterval;
    let ivMod;

    let primExternalPD;
    let secExternalPD;
    let secInternalPD;
    let primDrivingPressure;

    let balancingKV;
    let kvsVal;

    if (useCurrent === true) {
        plLine = ProductLineEnum.ProductLine.getContextValue(productLineParams)
        plFamily = ProductLineEnum.ProductFamily.getContextValue(productLineParams);
        plPrincip = ProductLineEnum.ProductPrincip.getContextValue(productLineParams);
        plGreenLvl = ProductLineEnum.ProductGreenLevel.getContextValue(productLineParams);

        primTempIn = Number(DriftParamEnum.PrimTempIn.getContextValue(driftParams));
        primTempOut = Number(DriftParamEnum.PrimTempOut.getContextValue(driftParams));
        secTempIn = Number(DriftParamEnum.SecTempIn.getContextValue(driftParams));
        secTempOut = Number(DriftParamEnum.SecTempOut.getContextValue(driftParams));

        svPressureDrop = Number(CalcParamsEnum.SVPressureLoss.getContextValue(calcParams));
        isInterval = ShuntParamsEnum.CV_IsInterval.getContextValue(shuntParams);

        primInternalPD = CalcParamsEnum.PrimInternalPressureDrop.getContextValue(calcParams);
        mainPump = DriftParamEnum.HasMainPump.getContextValue(driftParams);
        ivMod = Number(DriftParamEnum.IVModification.getContextValue(driftParams));
        primExternalPD = Number(DriftParamEnum.PrimExternalPressureDrop.getContextValue(driftParams));
        secExternalPD = Number(DriftParamEnum.SecExternalPressureDrop.getContextValue(driftParams));
        secInternalPD = CalcParamsEnum.SecInternalPressureDrop.getContextValue(calcParams);
        primDrivingPressure = DriftParamEnum.PrimDrivingPressure.getContextValue(driftParams);

        secFlowEffect = DriftParamEnum.SecFlowEffect.getContextValue(driftParams);
        shuntType = CalcParamsEnum.ShuntType.getContextValue(calcParams);

        balancingKV = Number(CalcParamsEnum.BalancingKv.getContextValue(calcParams));
        kvsVal = Number(CalcParamsEnum.Kvs.getContextValue(calcParams));
    }
    else if (shuntData != null) {
        plLine = shuntData?.PLData?.prodLine;
        plPrincip = shuntData?.PLData?.princip;
        plGreenLvl = shuntData?.PLData?.greenLevel;

        if (plPrincip === PRODUCTPRINCIP_SR2 || plPrincip === PRODUCTPRINCIP_SR2FLEX || plPrincip === PRODUCTPRINCIP_SR2SMART || plPrincip === PRODUCTPRINCIP_SR3 || plPrincip === PRODUCTPRINCIP_SR2_FLEX_APPSHUNT) {
            plFamily = PRODUCTFAMILY_SR;
        }
        else if (plPrincip === PRODUCTPRINCIP_PR2 || plPrincip === PRODUCTPRINCIP_PR3 || plPrincip === PRODUCTPRINCIP_PR2_APPSHUNT) {
            plFamily = PRODUCTFAMILY_PR;
        }
        else if (plPrincip === PRODUCTPRINCIP_VA1) {
            plFamily = PRODUCTFAMILY_VA;
        }

        primTempIn = Number(shuntData?.DriftParams?.primTempIn);
        primTempOut = Number(shuntData?.DriftParams?.primTempOut);
        secTempIn = Number(shuntData?.DriftParams?.secTempIn);
        secTempOut = Number(shuntData?.DriftParams?.secTempOut);

        ivMod = Number(shuntData?.DriftParams?.ivMod);

        svPressureDrop = Number(shuntData?.CalcParams?.svPressureDrop);
        isInterval = shuntData?.ShuntData?.cvIsInterval;

        primInternalPD = Number(shuntData?.CalcParams?.primInternalPressureDrop);
        mainPump = shuntData?.DriftParams?.hasMainPump;

        primExternalPD = Number(shuntData?.DriftParams?.primExternalPressureDrop);
        secExternalPD = Number(shuntData?.DriftParams?.secExternalPressureDrop);
        secInternalPD = Number(shuntData?.CalcParams?.secInternalPressureDrop);
        primDrivingPressure = shuntData?.DriftParams?.primDrivingPressure;

        secFlowEffect = shuntData?.DriftParams?.secFlowEffect;
        shuntType = shuntData?.CalcParams?.shuntType;

        balancingKV = Number(shuntData?.CalcParams?.balancingKv);
        kvsVal = Number(shuntData?.CalcParams?.kvs);
    }


    let textString;
    switch (component) {
        case "flow":
            if (secTempIn != null && isNaN(secTempIn) === false && secTempOut != null && isNaN(secTempOut) === false) {
                if (primTempOut == null || isNaN(primTempOut)) {
                    primTempOut = secTempOut
                }

                if (primTempIn == null || isNaN(primTempIn)) {
                    primTempIn = secTempIn;
                }


                if (plFamily === PRODUCTFAMILY_VA) {
                    textString = "Variabelt flöde tilluft, konstant flöde frånluft, temperatur " + secTempIn.toFixed(1) + "/" + secTempOut.toFixed(1) + "°C";
                    text.push(textString);
                }
                else {
                    textString = "Variabelt flöde primärt, ";

                    if (plPrincip === PRODUCTPRINCIP_SR3 || plPrincip === PRODUCTPRINCIP_PR3) {
                        textString = "Konstant flöde primärt, ";
                    }
                    textString += "temp. prim. " + primTempIn.toFixed(1) + "/" + primTempOut.toFixed(1) + "°C, temp. sek. " + secTempIn.toFixed(1) + "/" + secTempOut.toFixed(1) + "°C";
                    text.push(textString);
                }
            }
            else {
                if (plFamily === PRODUCTFAMILY_VA) {
                    textString = "Variabelt flöde tilluft, konstant flöde frånluft";
                    text.push(textString);
                }
                else {
                    textString = "Variabelt flöde primärt";

                    if (plPrincip === PRODUCTPRINCIP_SR3 || plPrincip === PRODUCTPRINCIP_PR3) {
                        textString = "Konstant flöde primärt";
                    }

                    textString += ", temp. prim. ___ °C, temp. sek. ___ °C";

                    text.push(textString);
                }
            }

            break;
        case "pressureDropPrim":
            let total = "";
            if ((primInternalPD == null || isNaN(primInternalPD)) && (secExternalPD == null || isNaN(secExternalPD))) {
                total = '__._'
                if (svPressureDrop != null && isNaN(svPressureDrop) === false) {
                    primInternalPD = Number(1 + svPressureDrop).toFixed(1);
                }
                else {
                    primInternalPD = '___'
                }

            }
            else {
                total = Number(primInternalPD + secInternalPD).toFixed(1);
            }

            if (svPressureDrop == null || isNaN(svPressureDrop)) {
                svPressureDrop = "___"
            }
            else {
                svPressureDrop = svPressureDrop.toFixed(1);
            }

            if (plFamily === PRODUCTFAMILY_VA) {
                textString = "Tryckfall i shuntgruppen = " + total + " kPa, varav i SV " + svPressureDrop + " kPa";
            }
            else {
                textString = "Tryckfall primärt i shuntgruppen = " + primInternalPD + " kPa";

                if (plFamily === PRODUCTFAMILY_SR) {
                    textString += ", varav i SV " + svPressureDrop + " kPa"

                    if (ivMod === -1 && kvsVal != null && plLine !== PRODUCTLINE_FLEX) {
                        textString += " vid kvs " + kvsVal;
                    }
                }
            }
            text.push(textString);

            break;
        case "mainPump":
            if (plPrincip === PRODUCTPRINCIP_PR2) {

                if (mainPump === '0') {
                    textString = "Utan huvudpump";
                }
                else {
                    textString = "Med huvudpump";
                }

                text.push(textString);
            }

            break;
        case "pressureDropSec":
            if (primExternalPD != null && secExternalPD != null) {
                if (plFamily === PRODUCTFAMILY_VA) {
                    let total = primExternalPD + secExternalPD;

                    textString = "Tryckfall i till- och frånluftsbatterierna = " + total.toFixed(1) + " kPa";
                }
                else {
                    if (secInternalPD == null || isNaN(secInternalPD)) {
                        secInternalPD = '___';
                    }

                    if (svPressureDrop == null || isNaN(svPressureDrop)) {
                        svPressureDrop = '___';
                    }
                    textString = "Tryckfall sekundärt i shuntgruppen = " + secInternalPD + " kPa";

                    if (plFamily === PRODUCTFAMILY_PR) {
                        textString += ", varav i SV " + svPressureDrop + " kPa"

                        if (isInterval === true && kvsVal != null) {
                            textString += " vid kvs " + kvsVal;
                        }
                    }
                }

                text.push(textString);
            }

            break;
        case "externalPressureDropSec":
            if (plFamily !== PRODUCTFAMILY_VA && secExternalPD != null) {
                if (secExternalPD == null || isNaN(secExternalPD)) {
                    secExternalPD = '___'
                }
                else {
                    secExternalPD = secExternalPD.toFixed(1);
                }
                textString = "Tryckfall i anslutet sekundärsystem = " + secExternalPD + " kPa";

                text.push(textString);
            }

            break;
        case "balancingKV":
            if (plLine !== PRODUCTLINE_FLEX && plLine !== PRODUCTLINE_SMART && primExternalPD != null && ivMod !== -1) {
                if (balancingKV === 0) {
                    textString = "IV primärt helt öppen, tryckfall i anslutet primärsystem " + primExternalPD.toFixed(2) + " kPa";
                }
                else if (balancingKV !== -1 && isNaN(balancingKV) === false) {
                    textString = "Kv-värde " + roundToDecimals(balancingKV, 1) + " för IV primärt vid tillgängligt drivtryck " + primDrivingPressure + " kPa";
                }
                text.push(textString);
            }

            break;
        case "secFlowEffect":
            if (secFlowEffect != null && secFlowEffect !== ""){
                if (shuntType === COOL_SHUNT){
                    textString = "Dimensionerad kyleffekt " + secFlowEffect + " kW";
                }
                else{
                    textString = "Dimensionerad värmeeffekt " + secFlowEffect + " kW";
                }
                text.push(textString);
            }
            if (plLine === PRODUCTLINE_GREEN && plGreenLvl != null && plGreenLvl === 2) {
                textString = "Miljövarudeklarerad enligt ISO 14025 och EN 15804:2012+A2:2019";

                //text.push(textString);
            }

            break;
        case "greenLevelText":
            //Not used
            if (plLine === PRODUCTLINE_GREEN && plGreenLvl != null && plGreenLvl === 2) {
                textString = "Miljövarudeklarerad enligt ISO 14025 och EN 15804:2012+A2:2019";

                //text.push(textString);
            }

            break;
        default:
            break;
    }

    return text;
}