import { Box } from '@mui/system';
import { useEffect } from 'react';
import { PumpSelection } from '../modules/pumpSelection';
import { useAnalytics } from 'reactfire';
import { logEvent } from 'firebase/analytics';

export const Step4 = () => {
    const analytics = useAnalytics();

    useEffect(() => {
        logEvent(analytics, 'page_view', { page_location: window.location.href, page_title: "Step 4" });
    }, [window.location.href]);

    return (
        <Box style={{ marginTop: '15px' }}>
            <PumpSelection />
        </Box>
    );
};