import { useEffect } from 'react';
import { useAnalytics, useDatabase } from 'reactfire';
import { Box, CircularProgress } from '@mui/material';
import { useSessionParams } from '../../contexts/SessionParams';
import { SessionParamsEnum } from '../../enums/sessionParamsEnum';
import { useAdjustParams, useCalcParams, useDriftParams, useErrorContext, useNoticeContext, useProductLineParams, usePumpParams, useShuntParams } from '../../contexts';
import { ErrorEnum } from '../../enums/errorEnum';
import { NoticeEnum } from '../../enums/noticeEnum';
import { loadShuntFromFirebase } from '../../firebase/functions/loadShuntFromFirebase';
import { logEvent } from 'firebase/analytics';
import { useNavigate } from 'react-router-dom';
import { useSwitchShunt } from '../../hooks/useSwitchShunt';

export const LoadNewShuntComponent = ({ shuntID, setOpen, handleClose = null, showLoad = true, newSession = false }) => {
    const errorlist = new ErrorEnum();
    const noticelist = new NoticeEnum();
    const errorProvider = useErrorContext();
    const noticeProvider = useNoticeContext();

    const database = useDatabase();
    const navigate = useNavigate();
    const sessionParams = useSessionParams();
    const shuntParams = useShuntParams();
    const adjustParams = useAdjustParams();
    const productLineParams = useProductLineParams();
    const calcParams = useCalcParams();
    const driftParams = useDriftParams();
    const pumpParams = usePumpParams();

    const analytics = useAnalytics();

    useSwitchShunt(shuntID, handleClose, newSession, setOpen);
    //console.log("Open Load Start:", shuntID, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams))

    useEffect(() => {
        logEvent(analytics, 'page_view', { page_location: window.location.href, page_title: "Load Shunt", shunt_id: shuntID });
    }, [window.location.href]);

    /*
    useEffect(() => {
        if (shuntID != null && shuntID !== "") {
            if (shuntID !== SessionParamsEnum.CurrentShunt.getContextValue(sessionParams) || newSession === true) {
                const loadShunt = async () => {
                    SessionParamsEnum.SwitchingShunt.setContextValue(sessionParams, true);
                    errorlist.clearAllErrors(errorProvider);
                    noticelist.clearAllNotices(noticeProvider);
                    const step = await loadShuntFromFirebase(shuntID, database, sessionParams, driftParams, shuntParams, calcParams, adjustParams, pumpParams, productLineParams, errorProvider, noticeProvider);
                    SessionParamsEnum.CurrentStep.setContextValue(sessionParams, step);

                    if (handleClose !== null) {
                        handleClose();
                    }

                    setOpen(false)
                    SessionParamsEnum.SwitchingShunt.setContextValue(sessionParams, false);
                    if (newSession === true){
                        navigate("/stepper");
                    }
                }

                loadShunt();
            }
            else {
                setOpen(false)
            }
        }
        else {
            setOpen(false)
        }
    }, [shuntID])*/


    return (
        <>
            {showLoad ? (
                <Box>
                    <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress size={'22px'} /></div>
                </Box>
            ) : (
                <Box>
                    
                </Box>
            )}
        </>
    );
};