export const PUMP_GENERAL_REQ = "generalRequest"
export const PUMP_SPECIFIC_REQ = "customerSpecified"

export const PUMP_PREMA_CHOICE = 'premasChoice';
export const PUMP_GRUNDFOS = 'Grundfos';
export const PUMP_WILO = 'Wilo';
export const PUMP_XYLEM = 'Xylem';
export const PUMP_KSB = 'KSB';

export const PUMP_ALARM = "alarm";
export const PUMP_STEERING = "steering";
export const PUMP_PRESSURE = "pressure";
export const PUMP_EXTERNAL_STOP = "externalStop";
export const PUMP_CARD = "card";

export const PUMP_GREEN_II_DEFAULT = "Grundfos Magna3";

export const PUMP_LABELS = {
    premasChoice: "valfri",
    Grundfos: "Grundfos",
    Wilo: "Wilo",
    Xylem: "Xylem",
    KSB: "KSB",
}