import { Database, get, ref, serverTimestamp, set } from "firebase/database";
import { PROJECT_PATH, PROJECT_PROJECT_ID_PATH, SESSION_PATH } from "../constants/firebasePaths";
import { ErrorEnum, NoticeEnum, SessionParamsEnum } from "../enums";
import { CURRENT_SHUNT, FACILITY_KEY, LINKED_PROJECT_KEY, PROJNR_KEY, SESSION_ADDONS_KEY, SESSION_SHUNTS_KEY } from "../constants/keys/sessionParamsKeys";
import { saveOwnerAndVersion } from "./functions/saveOwnerAndVersion";
import { loadShuntFromFirebase } from "./functions/loadShuntFromFirebase";

/**
 * 
 * @param {Database} database 
 * @param {string} sessionID 
 * @param {boolean} editSession 
 * @param {Object} sessionParams 
 * @param {Object} shuntParams 
 * @param {Object} modifySpecParams 
 * @param {Object} user 
 * @returns 
 */
export const loadNewSessionFromFB = async (database, sessionID, editSession, sessionParams, driftParams, shuntParams, calcParams, adjustParams, pumpParams, productLineParams, modifySpecParams, wizardParams, errorProvider, noticeProvider, user) => {
    console.log("Start Loading!", sessionID)
    const sessionRef = ref(database, SESSION_PATH + sessionID);

    const fieldValues = await get(sessionRef);

    if (fieldValues.exists()) {
        let selectedShuntID = "";

        if (editSession === true) {
            const newSession = await SessionParamsEnum.createSessionID(sessionParams, sessionID, database);
            SessionParamsEnum.SessionID.setContextValue(sessionParams, newSession);
            console.log("Set lastOpen (Edit)")
            const sessionRef = ref(database, SESSION_PATH + newSession + "/lastOpened")
            set(sessionRef, serverTimestamp());

            if (fieldValues.child(FACILITY_KEY).exists()) {
                SessionParamsEnum.Facility.setContextValue(sessionParams, fieldValues.child(FACILITY_KEY).val(), database);
            }
            else {
                SessionParamsEnum.Facility.setContextValue(sessionParams, '');
            }

            if (fieldValues.child(PROJNR_KEY).exists()) {
                SessionParamsEnum.ProjNr.setContextValue(sessionParams, fieldValues.child(PROJNR_KEY).val(), database);
            }
            else {
                SessionParamsEnum.ProjNr.setContextValue(sessionParams, '');
            }

            if (fieldValues.child(LINKED_PROJECT_KEY).exists()) {
                const projectSnap = await get(ref(database, PROJECT_PATH + fieldValues.child(LINKED_PROJECT_KEY).val() + PROJECT_PROJECT_ID_PATH));

                if (projectSnap.exists()) {
                    SessionParamsEnum.LinkedProject.setContextValue(sessionParams, projectSnap.val())
                }
            }

            if (fieldValues.child(SESSION_SHUNTS_KEY).exists()) {
                const shunts = [];
                let first = true;
                let currentIsSet = false;

                let shuntIDs = [];
                fieldValues.child(SESSION_SHUNTS_KEY).forEach((child) => {
                    shuntIDs.push(child.key);
                });

                for (let index = 0; index < shuntIDs.length; index++) {
                    const shuntID = shuntIDs[index];

                    if (fieldValues.child(CURRENT_SHUNT).exists()) {
                        if (fieldValues.child(CURRENT_SHUNT).val() === shuntID) {
                            const shuntIDString = await SessionParamsEnum.copySessionShunt(sessionParams, database, shuntID, true, shuntParams, newSession)
                            selectedShuntID = shuntIDString;

                            currentIsSet = true;
                            shunts.push(shuntIDString);
                        }
                        else {
                            shunts.push(await SessionParamsEnum.copySessionShunt(sessionParams, database, shuntID, false, shuntParams, newSession));
                        }
                    }
                    else {
                        if (first === true) {
                            const shuntIDString = await SessionParamsEnum.copySessionShunt(sessionParams, database, shuntID, true, shuntParams, newSession)
                            selectedShuntID = shuntIDString;
                            currentIsSet = true;
                            shunts.push(shuntIDString);
                            first = false;
                        }
                        else {
                            shunts.push(await SessionParamsEnum.copySessionShunt(sessionParams, database, shuntID, false, shuntParams, newSession));
                        }
                    }
                }

                if (currentIsSet === false) {
                    selectedShuntID = shunts[0];
                }

                SessionParamsEnum.SessionShunts.setContextValue(sessionParams, shunts);
            }
            else {
                const shuntIDString = await SessionParamsEnum.addSessionShunt(sessionParams, database, true, shuntParams);
                selectedShuntID = shuntIDString;
            }

            await loadShunt(selectedShuntID, database, sessionParams, driftParams, shuntParams, calcParams, adjustParams, pumpParams, productLineParams, wizardParams, errorProvider, noticeProvider)
            
            if (fieldValues.child('CustomData').exists()) {
                modifySpecParams.dispatch({ type: 'setSessionDataFromSnapshot', payload: fieldValues.child('CustomData').val() })
            }

            await saveOwnerAndVersion(newSession, database, user)
        }
        else {
            const sessionRef = ref(database, SESSION_PATH + sessionID + "/lastOpened")
            console.log("Set lastOpen (View)")
            set(sessionRef, serverTimestamp());

            SessionParamsEnum.SessionID.setContextValue(sessionParams, sessionID);
            SessionParamsEnum.Editable.setContextValue(sessionParams, false);

            if (fieldValues.child(FACILITY_KEY).exists()) {
                SessionParamsEnum.Facility.setContextValue(sessionParams, fieldValues.child(FACILITY_KEY).val());
            }
            else {
                SessionParamsEnum.Facility.setContextValue(sessionParams, '');
            }

            if (fieldValues.child(PROJNR_KEY).exists()) {
                SessionParamsEnum.ProjNr.setContextValue(sessionParams, fieldValues.child(PROJNR_KEY).val());
            }
            else {
                SessionParamsEnum.ProjNr.setContextValue(sessionParams, '');
            }

            if (fieldValues.child(LINKED_PROJECT_KEY).exists()) {
                const projectSnap = await get(ref(database, PROJECT_PATH + fieldValues.child(LINKED_PROJECT_KEY).val() + PROJECT_PROJECT_ID_PATH));

                if (projectSnap.exists()) {
                    SessionParamsEnum.LinkedProject.setContextValue(sessionParams, projectSnap.val())
                }
            }

            if (fieldValues.child(SESSION_SHUNTS_KEY).exists()) {
                let shuntIDs = [];
                fieldValues.child(SESSION_SHUNTS_KEY).forEach((child) => {
                    shuntIDs.push(child.key);
                });

                SessionParamsEnum.SessionShunts.setContextValue(sessionParams, shuntIDs);

                if (fieldValues.child(CURRENT_SHUNT).exists()) {
                    SessionParamsEnum.CurrentShunt.setContextValue(sessionParams, fieldValues.child(CURRENT_SHUNT).val());
                    selectedShuntID = fieldValues.child(CURRENT_SHUNT).val();
                }
                else {
                    if (shuntIDs.length > 0) {
                        SessionParamsEnum.CurrentShunt.setContextValue(sessionParams, shuntIDs[0]);
                        selectedShuntID = shuntIDs[0];
                    }
                }
            }
            else {
                const shuntIDString = await SessionParamsEnum.addSessionShunt(sessionParams, database, true, shuntParams);
                selectedShuntID = shuntIDString;
            }

            if (fieldValues.child(SESSION_ADDONS_KEY).exists()) {
                let addonIDs = [];
                fieldValues.child(SESSION_ADDONS_KEY).forEach((child) => {
                    addonIDs.push(child.key);
                });

                SessionParamsEnum.SessionAddons.setContextValue(sessionParams, addonIDs);
            }

            await loadShunt(selectedShuntID, database, sessionParams, driftParams, shuntParams, calcParams, adjustParams, pumpParams, productLineParams, wizardParams, errorProvider, noticeProvider)
            if (fieldValues.child('CustomData').exists()) {
                modifySpecParams.dispatch({ type: 'setSessionDataFromSnapshot', payload: fieldValues.child('CustomData').val() })
            }
        }


    }
    else {
        return Promise.reject("Session don't exists");
    }
}

const loadShunt = async (shuntID, database, sessionParams, driftParams, shuntParams, calcParams, adjustParams, pumpParams, productLineParams, wizardParams, errorProvider, noticeProvider) => {
    const errorlist = new ErrorEnum();
    const noticelist = new NoticeEnum();
    
    SessionParamsEnum.SwitchingShunt.setContextValue(sessionParams, true);
    errorlist.clearAllErrors(errorProvider);
    noticelist.clearAllNotices(noticeProvider);
    const step = await loadShuntFromFirebase(shuntID, database, sessionParams, driftParams, shuntParams, calcParams, adjustParams, pumpParams, productLineParams, wizardParams, errorProvider, noticeProvider);
    SessionParamsEnum.CurrentStep.setContextValue(sessionParams, step);


    SessionParamsEnum.SwitchingShunt.setContextValue(sessionParams, false);
}