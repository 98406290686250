import { signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { useAuth } from "reactfire";

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";


export const LoginForm = ({ handleClose, handleResetPasswordSwitch }) => {
    const auth = useAuth()
    const [validation, setValidation] = useState("");
    const [showPassword, setShowPassword] = useState(false)

    async function emailLogin(formValues) {
        try {
            await signInWithEmailAndPassword(
                auth,
                formValues?.email?.value,
                formValues?.pass?.value
            )

            handleClose()
        } catch (err) {
            console.log(err);
            if (err.code === "auth/invalid-login-credentials") {
                setValidation("Fel inloggningsuppgifter");
            }
            else if (err.code === "auth/wrong-password") {
                setValidation("Fel inloggningsuppgifter");
            } else if (err.code === "auth/user-not-found") {
                setValidation("Fel inloggningsuppgifter");
            }
            else if (err.code === "auth/user-disabled") {
                setValidation("Kontot är inaktiverat");
            }
            else if (err.code === "auth/too-many-requests") {
                setValidation("För många inloggningsförsök. Försök igen om en stund");
            }
            else {
                setValidation("Något gick fel. Kunde inte logga in");
            }
        }
    }

    const handleSubmit = (event) => {
        // Prevent page reload
        event.preventDefault();
        emailLogin(event.target);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    return (
        <form onSubmit={handleSubmit}>
            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                <AdminPanelSettingsIcon style={{ fontSize: '28px', color: '#1976d2' }} />
            </div>
            <div style={{ marginBottom: '10px' }}>
                <TextField
                    required
                    fullWidth
                    label="E-post"
                    name="email"
                    placeholder="Ange din e-post"
                    margin="dense"
                    size="medium"
                />
            </div>


            <div style={{ marginBottom: '20px' }}>
                <FormControl fullWidth>
                    <InputLabel required>
                        Lösenord
                    </InputLabel>
                    <OutlinedInput
                        type={showPassword ? "text" : "password"}
                        label="Lösenord"
                        name="pass"
                        placeholder="Skriv in lösenord"
                        size="small"
                        required
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </div>


            <Button variant="contained" type='submit' fullWidth>
                Logga in
            </Button>
            <Button className='forgot-pass' onClick={handleResetPasswordSwitch}>
                Glömt lösenord?
            </Button>

            <p className="validation">{validation}</p>
        </form>
    )
}