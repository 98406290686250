import { useState } from "react";
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Paper } from "@mui/material"
import { AdjustmentsEnum } from "../../../../../enums"


export const AdjustCardConnectedDiff = ({ connectedDiff, setAdjustParams, isDisabled }) => {
    const [options, setOptions] = useState({
        primConnDiff: connectedDiff['primConnDiff'] || false,
        sekConnDiff: connectedDiff['sekConnDiff'] || false,
    });

    const { primConnDiff, sekConnDiff } = options;

    const handleChange = (event) => {
        setOptions({
            ...options,
            [event.target.name]: event.target.checked,
        })
        setAdjustParams({
            ...connectedDiff,
            [event.target.name]: event.target.checked,
        }, AdjustmentsEnum.ConnectedDiff)
    };

    return (
        <div>
            <Paper elevation={0} className="adjust-card">
                <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group">Connected™ Differenstryck</FormLabel>
                    <FormGroup
                        aria-labelledby="checkbox-buttons-group-conn-temp"
                    >
                        <FormControlLabel sx={{ marginBottom: '-10px' }} control={<Checkbox name="primConnDiff" checked={primConnDiff} onChange={handleChange} />} label={<span><strong>Connected™ Diff Tryck Prim</strong> (Belimo 22WDP)</span>} disabled={isDisabled()} />
                        <FormControlLabel control={<Checkbox name="sekConnDiff" checked={sekConnDiff} onChange={handleChange} />} label={<span><strong>Connected™ Diff Tryck Sek</strong> (Belimo 22WDP)</span>} disabled={isDisabled()} />
                    </FormGroup>

                </FormControl>
            </Paper>
        </div>
    )
}