import { Paper, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Divider } from "@mui/material"
import { useCalcParams, useProductLineParams, useSessionParams } from "../../../../../contexts"
import { AdjustmentsEnum, SessionParamsEnum, CalcParamsEnum, ProductLineEnum } from "../../../../../enums"

import { CONN_VALUE_R, CONN_VALUE_L, CONN_VALUE_NOT_SPECIFIED, CONN_VALUE_VRD, CONN_VALUE_VLD, CONN_VALUE_VRU, CONN_VALUE_VLU, CONN_VALUE_HR, CONN_VALUE_HL, CONN_LATCH, CONN_VALUE_UR, CONN_VALUE_UL, CONN_VALUE_DR, CONN_VALUE_DL, CONN_VALUE_RU, CONN_VALUE_LU, CONN_VALUE_RD, CONN_VALUE_LD } from "../../../../../constants/adjustmentsOptions"
import { PRODUCTFAMILY_VA } from "../../../../../constants/productlineConstants"
import { HEAT_SHUNT, COOL_SHUNT } from "../../../../../constants/shuntOptions"

export const AdjustCardConnValue = ({ connectionValue, connectionDesign, setAdjustParams, isDisabled, disableExtraBV }) => {
    const sessionParams = useSessionParams();
    const productLineParams = useProductLineParams();
    const calcParams = useCalcParams();

    const productFamily = ProductLineEnum.ProductFamily;

    return (
        <div>
            <Paper elevation={0} className="adjust-card">
                <FormControl>
                    <FormLabel id="connection-radio-buttons-group">Inkopplingsalternativ</FormLabel>
                    <RadioGroup
                        aria-labelledby="connection-radio-buttons-group"
                        name="connection"
                        value={connectionValue}
                        onChange={(event) => { setAdjustParams(event.target.value, AdjustmentsEnum.ConnectionValue, event.target.name) }}
                    >
                        {connectionDesign === "u" &&
                            <>
                                <FormControlLabel value={CONN_VALUE_R} control={<Radio />} label="Högerutförande" disabled={isDisabled()} />
                                <FormControlLabel value={CONN_VALUE_L} control={<Radio />} label="Vänsterutförande" disabled={isDisabled()} />
                                {SessionParamsEnum.IsApiSession.getContextValue(sessionParams) !== true &&
                                    <>
                                        <Divider orientation="horizontal" flexItem />
                                        <FormControlLabel value={CONN_VALUE_NOT_SPECIFIED} control={<Radio />} label="Ej specificerat" disabled={isDisabled()} />
                                    </>
                                }

                            </>
                        }

                        {(connectionDesign === "h") && (productFamily.getContextValue(productLineParams) === "PR") &&
                            <>
                                <FormControlLabel value={CONN_VALUE_VRD} control={<Radio />} label="Lodrätt höger, prim nedåt" disabled={isDisabled()} />
                                <FormControlLabel value={CONN_VALUE_VLD} control={<Radio />} label="Lodrätt vänster, prim nedåt" disabled={isDisabled()} />
                                <Divider orientation="horizontal" flexItem />
                                <FormControlLabel value={CONN_VALUE_VRU} control={<Radio />} label="Lodrätt höger, prim uppåt" disabled={isDisabled()} />
                                <FormControlLabel value={CONN_VALUE_VLU} control={<Radio />} label="Lodrätt vänster, prim uppåt" disabled={isDisabled()} />
                                <Divider orientation="horizontal" flexItem />
                                <FormControlLabel value={CONN_VALUE_HR} control={<Radio />} label="Vågrätt höger, prim åt höger" disabled={isDisabled()} />
                                <FormControlLabel value={CONN_VALUE_HL} control={<Radio />} label="Vågrätt vänster, prim åt vänster" disabled={isDisabled()} />
                                {SessionParamsEnum.IsApiSession.getContextValue(sessionParams) !== true &&
                                    <>
                                        <Divider orientation="horizontal" flexItem />
                                        <FormControlLabel value={CONN_VALUE_NOT_SPECIFIED} control={<Radio />} label="Ej specificerat" disabled={isDisabled()} />
                                    </>

                                }
                            </>
                        }


                        {(connectionDesign === "h") && (productFamily.getContextValue(productLineParams) === "SR") && (CalcParamsEnum.ShuntType.getContextValue(calcParams) === HEAT_SHUNT) &&
                            <>
                                <FormControlLabel name={CONN_LATCH} value={CONN_VALUE_VRD} control={<Radio />} label="Lodrätt höger, prim nedåt, med värmespärr" disabled={disableExtraBV || isDisabled()} />
                                <FormControlLabel name={CONN_LATCH} value={CONN_VALUE_VLD} control={<Radio />} label="Lodrätt vänster, prim nedåt, med värmespärr" disabled={disableExtraBV || isDisabled()} />
                                <Divider orientation="horizontal" flexItem />
                                <FormControlLabel value={CONN_VALUE_VRU} control={<Radio />} label="Lodrätt höger, prim uppåt" disabled={isDisabled()} />
                                <FormControlLabel value={CONN_VALUE_VLU} control={<Radio />} label="Lodrätt vänster, prim uppåt" disabled={isDisabled()} />
                                <Divider orientation="horizontal" flexItem />
                                <FormControlLabel name={CONN_LATCH} value={CONN_VALUE_HR} control={<Radio />} label="Vågrätt höger, prim åt höger, med värmespärr" disabled={disableExtraBV || isDisabled()} />
                                <FormControlLabel name={CONN_LATCH} value={CONN_VALUE_HL} control={<Radio />} label="Vågrätt vänster, prim åt vänster, med värmespärr" disabled={disableExtraBV || isDisabled()} />
                                {SessionParamsEnum.IsApiSession.getContextValue(sessionParams) !== true &&
                                    <>
                                        <Divider orientation="horizontal" flexItem />
                                        <FormControlLabel value={CONN_VALUE_NOT_SPECIFIED} control={<Radio />} label="Ej specificerat" disabled={isDisabled()} />
                                    </>
                                }

                            </>
                        }


                        {(connectionDesign === "h") && (productFamily.getContextValue(productLineParams) === "SR") && (CalcParamsEnum.ShuntType.getContextValue(calcParams) === COOL_SHUNT) &&
                            <>
                                <FormControlLabel value={CONN_VALUE_VRD} control={<Radio />} label="Lodrätt höger, prim nedåt" disabled={isDisabled()} />
                                <FormControlLabel value={CONN_VALUE_VLD} control={<Radio />} label="Lodrätt vänster, prim nedåt" disabled={isDisabled()} />
                                <Divider orientation="horizontal" flexItem />
                                <FormControlLabel name={CONN_LATCH} value={CONN_VALUE_VRU} control={<Radio />} label="Lodrätt höger, prim uppåt, med kylspärr" disabled={disableExtraBV || isDisabled()} />
                                <FormControlLabel name={CONN_LATCH} value={CONN_VALUE_VLU} control={<Radio />} label="Lodrätt vänster, prim uppåt, med kylspärr" disabled={disableExtraBV || isDisabled()} />
                                <Divider orientation="horizontal" flexItem />
                                <FormControlLabel name={CONN_LATCH} value={CONN_VALUE_HR} control={<Radio />} label="Vågrätt höger, prim åt höger, med kylspärr" disabled={disableExtraBV || isDisabled()} />
                                <FormControlLabel name={CONN_LATCH} value={CONN_VALUE_HL} control={<Radio />} label="Vågrätt vänster, prim åt vänster, med kylspärr" disabled={disableExtraBV || isDisabled()} />
                                {SessionParamsEnum.IsApiSession.getContextValue(sessionParams) !== true &&
                                    <>
                                        <Divider orientation="horizontal" flexItem />
                                        <FormControlLabel value={CONN_VALUE_NOT_SPECIFIED} control={<Radio />} label="Ej specificerat" disabled={isDisabled()} />
                                    </>
                                }

                            </>
                        }

                        {(connectionDesign === "h") && ((productFamily.getContextValue(productLineParams) === "VA") &&
                            <>
                                <FormControlLabel value={CONN_VALUE_VRD} control={<Radio />} label="Lodrätt höger, TL nedåt" disabled={isDisabled()} />
                                <FormControlLabel value={CONN_VALUE_VLD} control={<Radio />} label="Lodrätt vänster, prim TL" disabled={isDisabled()} />
                                <Divider orientation="horizontal" flexItem />
                                <FormControlLabel value={CONN_VALUE_VRU} control={<Radio />} label="Lodrätt höger, TL uppåt" disabled={isDisabled()} />
                                <FormControlLabel value={CONN_VALUE_VLU} control={<Radio />} label="Lodrätt vänster, TL uppåt" disabled={isDisabled()} />
                                <Divider orientation="horizontal" flexItem />
                                <FormControlLabel value={CONN_VALUE_HR} control={<Radio />} label="Vågrätt höger, TL åt höger" disabled={isDisabled()} />
                                <FormControlLabel value={CONN_VALUE_HL} control={<Radio />} label="Vågrätt vänster, TL åt vänster" disabled={isDisabled()} />
                                {SessionParamsEnum.IsApiSession.getContextValue(sessionParams) !== true &&
                                    <>
                                        <Divider orientation="horizontal" flexItem />
                                        <FormControlLabel value={CONN_VALUE_NOT_SPECIFIED} control={<Radio />} label="Ej specificerat" disabled={isDisabled()} />
                                    </>
                                }
                            </>
                        )}


                        {(connectionDesign === "l") && (productFamily.getContextValue(productLineParams) === "SR") && (CalcParamsEnum.ShuntType.getContextValue(calcParams) === HEAT_SHUNT) &&
                            <>
                                <FormControlLabel value={CONN_VALUE_UR} control={<Radio />} label="Höger, prim uppåt, sek åt höger" disabled={isDisabled()} />
                                <FormControlLabel value={CONN_VALUE_UL} control={<Radio />} label="Vänster, prim uppåt, sek åt vänster" disabled={isDisabled()} />
                                <Divider orientation="horizontal" flexItem />
                                <FormControlLabel name={CONN_LATCH} value={CONN_VALUE_DR} control={<Radio />} label="Höger, prim nedåt, sek åt höger, med värmespärr" disabled={disableExtraBV || isDisabled()} />
                                <FormControlLabel name={CONN_LATCH} value={CONN_VALUE_DL} control={<Radio />} label="Vänster, prim nedåt, sek åt vänster, med värmespärr" disabled={disableExtraBV || isDisabled()} />
                                <Divider orientation="horizontal" flexItem />
                                <FormControlLabel value={CONN_VALUE_RU} control={<Radio />} label="Höger, prim åt höger, sek uppåt" disabled={isDisabled()} />
                                <FormControlLabel value={CONN_VALUE_LU} control={<Radio />} label="Vänster, prim åt vänster, sek uppåt" disabled={isDisabled()} />
                                <Divider orientation="horizontal" flexItem />
                                <FormControlLabel value={CONN_VALUE_RD} control={<Radio />} label="Höger, prim åt höger, sek nedåt" disabled={isDisabled()} />
                                <FormControlLabel value={CONN_VALUE_LD} control={<Radio />} label="Vänster, prim åt vänster, sek nedåt" disabled={isDisabled()} />
                                {SessionParamsEnum.IsApiSession.getContextValue(sessionParams) !== true &&
                                    <>
                                        <Divider orientation="horizontal" flexItem />
                                        <FormControlLabel value={CONN_VALUE_NOT_SPECIFIED} control={<Radio />} label="Ej specificerat" disabled={isDisabled()} />
                                    </>
                                }

                            </>
                        }

                        {(connectionDesign === "l") && (productFamily.getContextValue(productLineParams) === "SR") && (CalcParamsEnum.ShuntType.getContextValue(calcParams) === COOL_SHUNT) &&
                            <>
                                <FormControlLabel name={CONN_LATCH} value={CONN_VALUE_UR} control={<Radio />} label="Höger, prim uppåt, sek åt höger, med kylspärr" disabled={disableExtraBV || isDisabled()} />
                                <FormControlLabel name={CONN_LATCH} value={CONN_VALUE_UL} control={<Radio />} label="Vänster, prim uppåt, sek åt vänster, med kylspärr" disabled={disableExtraBV || isDisabled()} />
                                <Divider orientation="horizontal" flexItem />
                                <FormControlLabel value={CONN_VALUE_DR} control={<Radio />} label="Höger, prim nedåt, sek åt höger" disabled={isDisabled()} />
                                <FormControlLabel value={CONN_VALUE_DL} control={<Radio />} label="Vänster, prim nedåt, sek åt vänster" disabled={isDisabled()} />
                                <Divider orientation="horizontal" flexItem />
                                <FormControlLabel value={CONN_VALUE_RU} control={<Radio />} label="Höger, prim åt höger, sek uppåt" disabled={isDisabled()} />
                                <FormControlLabel value={CONN_VALUE_LU} control={<Radio />} label="Vänster, prim åt vänster, sek uppåt" disabled={isDisabled()} />
                                <Divider orientation="horizontal" flexItem />
                                <FormControlLabel value={CONN_VALUE_RD} control={<Radio />} label="Höger, prim åt höger, sek nedåt" disabled={isDisabled()} />
                                <FormControlLabel value={CONN_VALUE_LD} control={<Radio />} label="Vänster, prim åt vänster, sek nedåt" disabled={isDisabled()} />
                                {SessionParamsEnum.IsApiSession.getContextValue(sessionParams) !== true &&
                                    <>
                                        <Divider orientation="horizontal" flexItem />
                                        <FormControlLabel value={CONN_VALUE_NOT_SPECIFIED} control={<Radio />} label="Ej specificerat" disabled={isDisabled()} />
                                    </>
                                }

                            </>
                        }

                        {(connectionDesign === "l") && (productFamily.getContextValue(productLineParams) === "PR") &&
                            <>
                                <FormControlLabel value={CONN_VALUE_UR} control={<Radio />} label="Höger, prim uppåt, sek åt höger" disabled={isDisabled()} />
                                <FormControlLabel value={CONN_VALUE_UL} control={<Radio />} label="Vänster, prim uppåt, sek åt vänster" disabled={isDisabled()} />
                                <Divider orientation="horizontal" flexItem />
                                <FormControlLabel value={CONN_VALUE_DR} control={<Radio />} label="Höger, prim nedåt, sek åt höger" disabled={isDisabled()} />
                                <FormControlLabel value={CONN_VALUE_DL} control={<Radio />} label="Vänster, prim nedåt, sek åt vänster" disabled={isDisabled()} />
                                <Divider orientation="horizontal" flexItem />
                                <FormControlLabel value={CONN_VALUE_RU} control={<Radio />} label="Höger, prim åt höger, sek uppåt" disabled={isDisabled()} />
                                <FormControlLabel value={CONN_VALUE_LU} control={<Radio />} label="Vänster, prim åt vänster, sek uppåt" disabled={isDisabled()} />
                                <Divider orientation="horizontal" flexItem />
                                <FormControlLabel value={CONN_VALUE_RD} control={<Radio />} label="Höger, prim åt höger, sek nedåt" disabled={isDisabled()} />
                                <FormControlLabel value={CONN_VALUE_LD} control={<Radio />} label="Vänster, prim åt vänster, sek nedåt" disabled={isDisabled()} />
                                {SessionParamsEnum.IsApiSession.getContextValue(sessionParams) !== true &&
                                    <>
                                        <Divider orientation="horizontal" flexItem />
                                        <FormControlLabel value={CONN_VALUE_NOT_SPECIFIED} control={<Radio />} label="Ej specificerat" disabled={isDisabled()} />
                                    </>
                                }

                            </>
                        }

                        {(connectionDesign === "l") && ((productFamily.getContextValue(productLineParams) === PRODUCTFAMILY_VA) &&
                            <>
                                <FormControlLabel value={CONN_VALUE_UR} control={<Radio />} label="Höger, TL uppåt, FL åt höger" disabled={isDisabled()} />
                                <FormControlLabel value={CONN_VALUE_UL} control={<Radio />} label="Vänster, TL uppåt, FL åt vänster" disabled={isDisabled()} />
                                <Divider orientation="horizontal" flexItem />
                                <FormControlLabel value={CONN_VALUE_DR} control={<Radio />} label="Höger, TL nedåt, FL åt höger" disabled={isDisabled()} />
                                <FormControlLabel value={CONN_VALUE_DL} control={<Radio />} label="Vänster, TL nedåt, FL åt vänster" disabled={isDisabled()} />
                                <Divider orientation="horizontal" flexItem />
                                <FormControlLabel value={CONN_VALUE_RU} control={<Radio />} label="Höger, TL åt höger, FL uppåt" disabled={isDisabled()} />
                                <FormControlLabel value={CONN_VALUE_LU} control={<Radio />} label="Vänster, TL åt vänster, FL uppåt" disabled={isDisabled()} />
                                <Divider orientation="horizontal" flexItem />
                                <FormControlLabel value={CONN_VALUE_RD} control={<Radio />} label="Höger, TL åter höger, FL nedåt" disabled={isDisabled()} />
                                <FormControlLabel value={CONN_VALUE_LD} control={<Radio />} label="Vänster, TL åt vänster, FL nedåt" disabled={isDisabled()} />
                                {SessionParamsEnum.IsApiSession.getContextValue(sessionParams) !== true &&
                                    <>
                                        <Divider orientation="horizontal" flexItem />
                                        <FormControlLabel value={CONN_VALUE_NOT_SPECIFIED} control={<Radio />} label="Ej specificerat" disabled={isDisabled()} />
                                    </>
                                }

                            </>
                        )}

                    </RadioGroup>
                </FormControl>
            </Paper>
        </div>
    )
}