import { Stack, Typography, Tooltip, Button, styled, tooltipClasses } from "@mui/material";
import { useEffect, useState } from "react";
import { useDatabase } from "reactfire";
import { PRODUCTLINE_STANDARD, PRODUCTLINE_GREEN, PRODUCTLINE_FLEX, PRODUCTLINE_SMART } from "../../../constants/productlineConstants";
import { useProductLineParams, useDriftParams, useShuntParams, useAdjustParams, useCalcParams, useDevbarParams, useErrorContext, useNoticeContext, usePumpParams, useSessionParams } from "../../../contexts";
import { AdjustmentsEnum, CalcParamsEnum, DriftParamEnum, ShuntParamsEnum, SessionParamsEnum, ProductLineEnum, ErrorEnum, NoticeEnum } from "../../../enums";
import { PumpParamEnum } from "../../../enums/pumpParamsEnum";
import { clearCalcData } from "../../../firebase/functions";
import { clearShuntData } from "../../../firebase/functions/saveShuntData";
import { ConnectionList } from "./components/connectionList";
import standardLine from '../../../assets/images/logos/prodline_standard1.svg'
import greenLine2 from '../../../assets/images/logos/prodline_greenI+II.svg'
import flexLine from '../../../assets/images/logos/prodline_flex1.svg'
import smartLine from '../../../assets/images/logos/prodline_smart.svg'
import { PRINCIPLE_PICKER_HOVERTEXT_FLEX, PRINCIPLE_PICKER_HOVERTEXT_GREEN2, PRINCIPLE_PICKER_HOVERTEXT_SMART, PRINCIPLE_PICKER_HOVERTEXT_STANDARD } from "../../../constants/generalText/shuntInfoTexts";
import { AutoPickerDialog } from "../autoPicker/autoPickerDialog";
import { ConfirmChangedPrinciple } from "./components/confirmChangedPrinciple";
import { getCurrentHighPosition } from "../../../firebase/functions/getCurrentHighPosition";
import { useWizardParams } from "../../../contexts/wizardParams";
import { useNavigate } from "react-router-dom";
import { saveProductLineDataInputs } from "../../../firebase/functions/saveProductLineData";

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 370,
    },
});

export const PrinciplePicker = () => {
    const errorlist = new ErrorEnum();
    const noticelist = new NoticeEnum();
    
    const productLine = useProductLineParams();
    const driftParams = useDriftParams();
    const shuntParams = useShuntParams();
    const adjustParams = useAdjustParams();
    const calcParams = useCalcParams();
    const devParams = useDevbarParams();
    const errorContext = useErrorContext();
    const noticeContext = useNoticeContext();
    const pumpParams = usePumpParams();
    const database = useDatabase();
    const sessionParams = useSessionParams();
    const wizardParams = useWizardParams();

    const navigate = useNavigate();

    const getSelectedPLLine = () => {
        if (ProductLineEnum.ProductLine.getContextValue(productLine) != null) {
            return ProductLineEnum.ProductLine.getContextValue(productLine)
        }
        else {
            return '';
        }
    }

    const getSelectedPLPrinciple = () => {
        if (ProductLineEnum.ProductPrincip.getContextValue(productLine) != null) {
            return ProductLineEnum.ProductPrincip.getContextValue(productLine)
        }
        else {
            return '';
        }
    }

    const [selectedPLLine, setSelectedPLLine] = useState(getSelectedPLLine());
    const [selectedPLPrinciple, setSelectedPLPrinciple] = useState(getSelectedPLPrinciple());

    const [prevPLLine, setPrevPLLine] = useState(getSelectedPLLine());
    const [prevPLPrinciple, setPrevPLPrinciple] = useState(getSelectedPLPrinciple());

    const [openConfirmChange, setOpenConfirmChange] = useState(false);


    const handleOpenConfirmChangeModal = () => { setOpenConfirmChange(true) }
    const handleCloseConfirmChangeModal = () => {
        setOpenConfirmChange(false);
    }

    const handleConfirmChange = async () => {
        ProductLineEnum.ProductPrincip.clearData(productLine);
        ProductLineEnum.ProductFamily.clearData(productLine);
        ProductLineEnum.ProductGreenLevel.clearData(productLine);

        ProductLineEnum.ProductLine.setContextValue(productLine, selectedPLLine);
        ProductLineEnum.ProductPrincip.setContextValue(productLine, selectedPLPrinciple);

        await clearData(selectedPLPrinciple);

        if (SessionParamsEnum.CurrentStep.getContextValue(sessionParams) === 0 || SessionParamsEnum.CurrentStep.getContextValue(sessionParams) == null) {
            SessionParamsEnum.CurrentStep.setContextValue(sessionParams, 1, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
            SessionParamsEnum.MaxStep.setContextValue(sessionParams, 1, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
        }

        if (ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) == null || ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) === -1) {
            let pos = await getCurrentHighPosition(database, sessionParams);
            ShuntParamsEnum.ShuntPosition.setContextValue(shuntParams, pos + 1, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams), SessionParamsEnum.SessionID.getContextValue(sessionParams));
        }

        saveProductLineDataInputs(database, sessionParams, selectedPLLine, selectedPLPrinciple);

        handleCloseConfirmChangeModal();
        navigate("/stepper#step2");
    }

    const clearData = (PLPrinciple) => {
        let oldProdPrinciple = ProductLineEnum.ProductPrincip.getContextValue(productLine);

        CalcParamsEnum.clearData(calcParams);
        ShuntParamsEnum.clearData(shuntParams);

        const promises = [];

        promises.push(clearShuntData(database, sessionParams));
        promises.push(clearCalcData(database, sessionParams));

        errorlist.clearAllErrors(errorContext);
        noticelist.clearAllNotices(noticeContext);

        DriftParamEnum.clearDataForNewShunt(driftParams, database, PLPrinciple, oldProdPrinciple, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams), wizardParams);

        return Promise.all(promises);
    }

    const clearContexts = () => {
        //console.log("Clear PL")
        if (SessionParamsEnum.IsApiSession.getContextValue(sessionParams) !== true) {
            DriftParamEnum.clearData(driftParams);
        }
        AdjustmentsEnum.clearData(adjustParams);
        CalcParamsEnum.clearData(calcParams);
        PumpParamEnum.clearData(pumpParams);
        ShuntParamsEnum.clearData(shuntParams);
        ProductLineEnum.ProductGreenLevel.clearData(productLine)
        ProductLineEnum.SelectedProductLine.clearData(productLine)
        ProductLineEnum.SelectedProductPrincip.clearData(productLine)


        errorContext.dispatch({ type: 'clearErrorList' })
        noticeContext.dispatch({ type: 'clearNoticeList' })
    }

    useEffect(() => {
        if (SessionParamsEnum.CurrentStep.getContextValue(sessionParams) == null || SessionParamsEnum.CurrentStep.getContextValue(sessionParams) === "") {
            SessionParamsEnum.CurrentStep.setContextValue(sessionParams, 0, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
        }
    }, [])

    useEffect(() => {
        if (ProductLineEnum.ProductLine.getContextValue(productLine) != null) {
            setSelectedPLLine(ProductLineEnum.ProductLine.getContextValue(productLine));
            ProductLineEnum.SelectedProductLine.setContextValue(productLine, ProductLineEnum.ProductLine.getContextValue(productLine));
        }
        else {
            setSelectedPLLine('');
        }

        if (ProductLineEnum.ProductPrincip.getContextValue(productLine) != null) {
            setSelectedPLPrinciple(ProductLineEnum.ProductPrincip.getContextValue(productLine));
            ProductLineEnum.SelectedProductPrincip.setContextValue(productLine, ProductLineEnum.ProductPrincip.getContextValue(productLine));
        }
        else {
            setSelectedPLPrinciple('');
        }
    }, [SessionParamsEnum.CurrentShunt.getContextValue(sessionParams)]);

    const setCurrentProdLine = (choosen) => {
        ProductLineEnum.SelectedProductPrincip.setContextValue(productLine, '');
        ProductLineEnum.SelectedProductLine.setContextValue(productLine, choosen);
        setSelectedPLPrinciple('');
        setSelectedPLLine(choosen);
        /*
        if (selected === choosen) {
            //console.log("Clear - setChoosen")
            // ProductLineEnum.clearData(productLine);
            // setSelected('');
        }
        else {
            if (SessionParamsEnum.MaxStep.getContextValue(sessionParams) >= 1) {
                console.log("Things will be wiped...")
                setCurrentlyNewPick(choosen)
                handleOpenConfirmChangeModal();
            }
            else {
                ProductLineEnum.ProductPrincip.clearData(productLine);
                ProductLineEnum.ProductFamily.clearData(productLine);
                ProductLineEnum.ProductGreenLevel.clearData(productLine);
                ProductLineEnum.ProductLine.setContextValue(productLine, choosen);
                clearContexts();
                clearShuntData(database, sessionParams).then(() => {
                    //console.log("Done Shunt");
                });
                if (SessionParamsEnum.IsApiSession.getContextValue(sessionParams) !== true) {
                    clearDriftData(database, sessionParams).then(() => {
                        //console.log("Done Drift");
                    });
                }

                clearCalcData(database, sessionParams).then(() => {
                    //console.log("Done Calc");
                });
                //productLine.dispatch({ type: 'setType', payload: choosen })
                setSelected(choosen);
            }

        }*/

    }

    const setCurrentProdPrinciple = (selected) => {
        ProductLineEnum.SelectedProductPrincip.setContextValue(productLine, selected);
        setSelectedPLPrinciple(selected);
    }

    const setStyle = (current) => {
        if (selectedPLLine === current) {
            return {
                padding: '10px 15px',
                border: "2px solid #0055a0",
                backgroundColor: 'white',
            }
        }
        else if (prevPLLine === current) {
            return {
                padding: '10px 15px',
                border: "2px dotted #439539",
                backgroundColor: '#ffffff90',
            }
        }
        else {
            return {
                padding: '10px 15px',
                border: "2px solid transparent",
            }
        }
    }

    const isDisabled = () => {
        if (SessionParamsEnum.Editable.getContextValue(sessionParams) === false && devParams.state.val.devMode !== true) {
            return true;
        } else {
            return false;
        }
    }
    //console.log('selected:', selected)


    useEffect(() => {

        function handleClick(event) {
            var outsideBody = document.body;

            if ((SessionParamsEnum.CurrentStep.getContextValue(sessionParams) === 0) && (event.target.contains(outsideBody) && event.target !== outsideBody) && isDisabled() === false) {
                if (ProductLineEnum.ProductPrincip.getContextValue(productLine) == null || ProductLineEnum.ProductPrincip.getContextValue(productLine) === "") {
                    ProductLineEnum.clearData(productLine);
                    setSelectedPLLine('');
                }
            }
        }

        window.addEventListener('click', handleClick);

        return () => {
            // remove event listener when the component unmounts
            window.removeEventListener('click', handleClick);
        };
    }, [ProductLineEnum.ProductPrincip.getContextValue(productLine)]);


    const handleDoubleClick = async (line, principle) => {
        setSelectedPLLine(line);
        setCurrentProdPrinciple(principle);
        if (SessionParamsEnum.MaxStep.getContextValue(sessionParams) >= 1 && (prevPLLine !== line || prevPLPrinciple !== principle)) {
            handleOpenConfirmChangeModal();
        }
        else {
            ProductLineEnum.ProductPrincip.clearData(productLine);
            ProductLineEnum.ProductFamily.clearData(productLine);
            ProductLineEnum.ProductGreenLevel.clearData(productLine);

            ProductLineEnum.ProductLine.setContextValue(productLine, selectedPLLine);
            ProductLineEnum.ProductPrincip.setContextValue(productLine, selectedPLPrinciple);

            await clearData();

            if (SessionParamsEnum.CurrentStep.getContextValue(sessionParams) === 0 || SessionParamsEnum.CurrentStep.getContextValue(sessionParams) == null) {
                SessionParamsEnum.CurrentStep.setContextValue(sessionParams, 1, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
            }

            if (ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) == null || ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) === -1) {
                let pos = await getCurrentHighPosition(database, sessionParams);
                ShuntParamsEnum.ShuntPosition.setContextValue(shuntParams, pos + 1, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams), SessionParamsEnum.SessionID.getContextValue(sessionParams));
            }

            saveProductLineDataInputs(database, sessionParams, selectedPLLine, selectedPLPrinciple);
            //WizardParamEnum.clearData(wizardParams);
            navigate("/stepper#step2");
        }
    }

    return (
        <>
            {isDisabled() === false ? (
                <AutoPickerDialog />
            ) : ("")}

            <Stack sx={{ marginTop: '30px' }} spacing={2} justifyContent={"center"} alignItems={"stretch"}>

                {selectedPLLine !== "" && selectedPLLine != null ? (
                    <Typography variant='h4' fontWeight="bold" style={{ marginBottom: '-8px' }}>
                        1. Välj produktlinje nedan
                    </Typography>
                ) : (
                    <Typography variant='h3' fontWeight="bold" style={{ marginBottom: '-8px' }}>
                        1. Välj produktlinje nedan
                    </Typography>
                )}


                <Stack direction={"row"} spacing={1} justifyContent="space-around">
                    <Tooltip title={PRINCIPLE_PICKER_HOVERTEXT_STANDARD}>
                        <Button style={setStyle(PRODUCTLINE_STANDARD)} disabled={isDisabled()} onClick={() => { setCurrentProdLine("standard") }}>
                            <img src={standardLine} alt='standard Line' style={{ height: '20px', }} />
                        </Button>
                    </Tooltip>

                    <CustomWidthTooltip title={PRINCIPLE_PICKER_HOVERTEXT_GREEN2} style={{ width: '1500px' }}>
                        <Button style={setStyle(PRODUCTLINE_GREEN)} disabled={isDisabled()} onClick={() => { setCurrentProdLine("green") }}>
                            <img src={greenLine2} alt='green Line 1 & 2' style={{ height: '25px', marginTop: '5px' }} />
                        </Button>
                    </CustomWidthTooltip>

                    <Tooltip title={PRINCIPLE_PICKER_HOVERTEXT_FLEX}>
                        <Button style={setStyle(PRODUCTLINE_FLEX)} disabled={isDisabled()} onClick={() => { setCurrentProdLine("flex") }}>
                            <img src={flexLine} alt='flex Line' style={{ height: '20px' }} />
                        </Button>
                    </Tooltip>

                    <Tooltip title={PRINCIPLE_PICKER_HOVERTEXT_SMART}>
                        <Button style={setStyle(PRODUCTLINE_SMART)} disabled={isDisabled()} onClick={() => { setCurrentProdLine("smart") }}>
                            <img src={smartLine} alt='smart Line' style={{ height: '20px' }} />
                        </Button>
                    </Tooltip>
                </Stack>

            </Stack>
            <div style={{ width: '100%', margin: "auto", border: 'none', backgroundColor: '#E1E2E4', }}>
                {selectedPLLine !== "" && selectedPLLine !== undefined &&
                    <>
                        <Typography variant='h3' fontWeight="bold" sx={{ marginTop: '10px' }}>
                            2. Välj kopplingsprincip genom att klicka på motsvarande figur
                        </Typography>

                        <ConnectionList prodLine={selectedPLLine} prevPLLine={prevPLLine} prevPLPriciple={prevPLPrinciple} selectedPLPrinciple={selectedPLPrinciple} setSelectedPLPrinciple={setCurrentProdPrinciple} handleDoubleClick={handleDoubleClick} isDisabled={isDisabled()} /></>

                }
            </div>

            <ConfirmChangedPrinciple openModal={openConfirmChange} handleModalClose={handleCloseConfirmChangeModal} handleContinue={handleConfirmChange} />
        </>
    );
}