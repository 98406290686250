import { useState } from "react"
import { Stack, Container, Paper, FormControl, RadioGroup, FormControlLabel, Radio, Box, Zoom } from "@mui/material"
import { useDatabase } from "reactfire"
import { AdjustmentsEnum, CalcParamsEnum, DriftParamEnum, SessionParamsEnum, ShuntParamsEnum } from "../../../../../enums"
import { DriftParamsTextField } from "../../components"
import { LocalImage } from "../../localImage"
import { useAdjustParams, useCalcParams, useDriftParams, useSessionParams, useShuntParams } from "../../../../../contexts"
import { WizardParamEnum } from "../../../../../enums/wizardParamsEnum"
import { useWizardParams } from "../../../../../contexts/wizardParams"
import { resetMaxStepFunc } from "../../../../../functions/HelperFunctions/resetMaxStep"

import { HOVER_TEXT_PRIM_TEMP_IN, HOVER_TEXT_PRIM_FLOW, HOVER_TEXT_SEC_TEMP_OUT, HOVER_TEXT_SEC_EXTERNAL_PRESSURE_DROP, HOVER_TEXT_SEC_FLOW, HOVER_TEXT_SEC_TEMP_IN, HOVER_TEXT_SEC_FLOW_EFFECT, HOVER_TEXT_PRIM_EXTERNAL_PRESSURE_DROP_PUMP, HOVER_TEXT_PRIM_DRIVING_PRESSURE_PUMP_ROW1, HOVER_TEXT_PRIM_DRIVING_PRESSURE_PUMP_ROW2 } from "../../../../../constants/generalText/hoverTexts"

import RegulateValve from '../../../../../assets/images/rvent.gif'
import CheckValvePR2 from '../../../../../assets/images/check_valve_pr2.png'
import CheckValvePR2Sabo from '../../../../../assets/images/check_valve_pr2_sabo.png'

export const DriftParamsBody_PR2 = ({ handleHover, handleExit, isDisabled, imageRef }) => {
    const database = useDatabase();
    const driftParams = useDriftParams();
    const shuntParams = useShuntParams();
    const sessionParams = useSessionParams();
    const calcParams = useCalcParams();
    const adjustParams = useAdjustParams();
    const wizardParams = useWizardParams();

    const getIsSaboParam = () => {
        if (ShuntParamsEnum.IsSabo.getContextValue(shuntParams) != null) {
            return ShuntParamsEnum.IsSabo.getContextValue(shuntParams);
        }
        else {
            return '0';
        }
    }

    const getMainPump = () => {
        if (DriftParamEnum.HasMainPump.getContextValue(driftParams) != null && DriftParamEnum.HasMainPump.getContextValue(driftParams) !== '') {
            return DriftParamEnum.HasMainPump.getContextValue(driftParams);
        }
        else if (WizardParamEnum.HasMainPump.getContextValue(wizardParams) != null && WizardParamEnum.HasMainPump.getContextValue(wizardParams) !== ''){
            if (WizardParamEnum.HasMainPump.getContextValue(wizardParams) === false){
                DriftParamEnum.HasMainPump.setContextValue(driftParams, '0');
                return '0';
            }
            else{
                DriftParamEnum.HasMainPump.setContextValue(driftParams, '1');
                return '1';
            }
        }
        else {
            return '1';
        }
    }

    const [isSabo, setIsSabo] = useState(getIsSaboParam())
    const [mainPump, setMainPump] = useState(getMainPump());

    const handleCheckSaboVersion = (event) => {
        resetMaxStepFunc(sessionParams, database)

        CalcParamsEnum.clearData(calcParams);
        AdjustmentsEnum.clearData(adjustParams);

        ShuntParamsEnum.IsSabo.setContextValue(shuntParams, event.target.value);
        setIsSabo(event.target.value);
    };

    const handleCheckMainPump = (event) => {
        resetMaxStepFunc(sessionParams, database)

        CalcParamsEnum.clearData(calcParams);
        AdjustmentsEnum.clearData(adjustParams);

        DriftParamEnum.HasMainPump.setContextValue(driftParams, event.target.value);
        setMainPump(event.target.value);
    };


    /**
     * 
     * @param {Boolean} lockedField 
     * @param {Boolean} lockOnAPIUpdate 
     */
    const localIsDisabled = (lockedField, lockOnAPIUpdate) => {
        if ((lockOnAPIUpdate && SessionParamsEnum.ApiMethod.getContextValue(sessionParams) === "update") || lockedField || isDisabled()) {
            return true;
        }
        else {
            return false;
        }
    }

    return (
        <>
            <Stack direction={'row'} spacing={0}>
                <Container style={{ padding: '0px', marginLeft: '0px', paddingTop: '13px' }}>
                    {/* Left Side */}
                    {/* Row 1 */}
                    <DriftParamsTextField
                        driftEnum={DriftParamEnum.PrimTempIn}
                        hoverText={HOVER_TEXT_PRIM_TEMP_IN}
                        handleHover={handleHover}
                        handleExit={handleExit}
                        end={'°C'}
                        isDisabled={localIsDisabled(DriftParamEnum.LockedPrimTempIn.getContextValue(driftParams), true)}
                    />

                    {/* Row 2 */}
                    {mainPump === '0' ? (
                        <Zoom in={mainPump === '0'} style={{ transitionDelay: mainPump === '1' ? '600ms' : '0ms' }}>
                            <Stack direction={'row'} style={{ marginTop: "2px", marginBottom: '-7px' }}>
                                <DriftParamsTextField
                                    style={{ width: "60px", height: "10px", border: "2px solid #blue" }}
                                    driftEnum={DriftParamEnum.PrimExternalPressureDrop}
                                    hoverText={HOVER_TEXT_PRIM_EXTERNAL_PRESSURE_DROP_PUMP}
                                    handleHover={handleHover}
                                    handleExit={handleExit}
                                    isDisabled={localIsDisabled(DriftParamEnum.LockedPrimExternalPressureDrop.getContextValue(driftParams), true)}
                                />

                                <div style={{ marginLeft: '10px', marginTop: "14px", width: '30px', }}>
                                    <span style={{ position: 'relative', zIndex: '1000' }}>Tryckfall (kPa)</span>
                                </div>
                            </Stack>
                        </Zoom>
                    ) : ("")}

                    {mainPump === '1' ? (
                        <Zoom in={mainPump === '1'} style={{ transitionDelay: mainPump === '1' ? '0ms' : '600ms' }}>
                            <Box>
                                <DriftParamsTextField
                                    style={{ width: "60px", height: "10px", border: "2px solid #blue" }}
                                    driftEnum={DriftParamEnum.PrimDrivingPressure}
                                    hoverText={HOVER_TEXT_PRIM_DRIVING_PRESSURE_PUMP_ROW1}
                                    hoverTextRow2={HOVER_TEXT_PRIM_DRIVING_PRESSURE_PUMP_ROW2}
                                    handleHover={handleHover}
                                    handleExit={handleExit}
                                    end={"kPa"}
                                    isDisabled={localIsDisabled(DriftParamEnum.LockedPrimDrivingPressure.getContextValue(driftParams), true)}
                                />
                            </Box>
                        </Zoom>
                    ) : ("")}

                    {/* Row 3 */}
                    <DriftParamsTextField
                        driftEnum={DriftParamEnum.PrimFlow}
                        end={"l/s"}
                        isDisabled={true}
                        hoverText={HOVER_TEXT_PRIM_FLOW}
                        handleHover={handleHover}
                        handleExit={handleExit}
                    />
                </Container>

                {/* Middle */}
                <Stack spacing={2}>
                    <Paper elevation={0} id="principle_image" >
                        {imageRef != null ? (
                            <>
                                <LocalImage imageReference={imageRef} />

                                <div className="main_pump">
                                    <FormControl>
                                        <RadioGroup

                                            aria-labelledby="mainPump-radio-buttons-group"
                                            name="mainpump"
                                            value={mainPump}
                                            onChange={handleCheckMainPump}
                                        >

                                            <FormControlLabel value="1" control={<Radio />} label="Med huvudpump" disabled={isDisabled()} />
                                            <FormControlLabel value="0" control={<Radio />} label="Utan huvudpump" disabled={isDisabled()} />

                                        </RadioGroup>
                                    </FormControl>
                                </div>

                                <div className="back_valve">
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="isSabo-radio-buttons-group"
                                            name="isSabo"
                                            value={isSabo}
                                            onChange={handleCheckSaboVersion}

                                        >

                                            <FormControlLabel value="0" control={<Radio />} label="PR-2" disabled={isDisabled()} onMouseOver={(event) => handleHover(event, "Backventilen är placerad i primärsidan returledning och förhindrar att andra pumpar i systemet trycker systemvätska fel väg och kortsluter primärsystemet vid trasig/avstängd pump på shuntgruppen.")}
                                                onMouseLeave={handleExit} />
                                            <FormControlLabel value="1" control={<Radio />} label="PR-2 SABO" disabled={isDisabled()} onMouseOver={(event) => handleHover(event, "Backventilen är placerad i styrventilens by-pass ledning och säkerställer att eventuellt tillgängligt drivtrycket får all systemvätskan att gå via sekundärsystemet vid trasig/avstängd pump när styrventilen inte är helt öppen.")}
                                                onMouseLeave={handleExit} />

                                        </RadioGroup>
                                    </FormControl>
                                </div>

                                {isSabo === "0" &&
                                    <img src={CheckValvePR2} id="check_valve_PR2" alt="Backventil"
                                        title="Backventil" />
                                }

                                {isSabo === "1" &&
                                    <img src={CheckValvePR2Sabo} id="check_valve_PR2_Sabo" alt="Backventil PR-2 Sabo"
                                        title="Backventil PR-2 Sabo" />
                                }

                                {Number(ShuntParamsEnum.NumIV.getContextValue(shuntParams)) !== 1 && <img src={RegulateValve} id="regulate_valve_PR2" alt="Injusteringsventil" title="Injusteringsventil"></img>}

                            </>
                        ) : (
                            <div style={{ width: '437px', height: '100px', textAlign: 'center', marginTop: '88px' }}>
                                Could not load image!
                            </div>
                        )}
                    </Paper>
                </Stack>

                {/* Right Side */}
                <Container style={{ marginRight: '0px', paddingTop: '12px' }}>
                    {/* Row 1 */}
                    <DriftParamsTextField
                        driftEnum={DriftParamEnum.SecTempIn}
                        hoverText={HOVER_TEXT_SEC_TEMP_IN}
                        handleHover={handleHover}
                        handleExit={handleExit}
                        end={'°C'}
                        isDisabled={localIsDisabled(DriftParamEnum.LockedSecTempIn.getContextValue(driftParams), true)}
                    />

                    {/* Row 2 */}
                    <DriftParamsTextField
                        driftEnum={DriftParamEnum.SecExternalPressureDrop}
                        end={"kPa"}
                        hoverText={HOVER_TEXT_SEC_EXTERNAL_PRESSURE_DROP}
                        handleHover={handleHover}
                        handleExit={handleExit}
                        isDisabled={localIsDisabled(DriftParamEnum.LockedSecExternalPressureDrop.getContextValue(driftParams), true)}
                    />

                    {/* Row 3 */}
                    <DriftParamsTextField
                        driftEnum={DriftParamEnum.SecFlow}
                        end={"l/s"}
                        hoverText={HOVER_TEXT_SEC_FLOW}
                        handleHover={handleHover}
                        handleExit={handleExit}
                        isDisabled={localIsDisabled(DriftParamEnum.LockedSecTempOut.getContextValue(driftParams), true) || DriftParamEnum.FlowCalcMode.getContextValue(driftParams) === "kw"}
                    />

                    {/* Row 4 */}
                    <DriftParamsTextField
                        driftEnum={DriftParamEnum.SecTempOut}
                        hoverText={HOVER_TEXT_SEC_TEMP_OUT}
                        end={'°C'}
                        handleHover={handleHover}
                        handleExit={handleExit}
                        isDisabled={localIsDisabled(DriftParamEnum.LockedSecTempOut.getContextValue(driftParams), true)}
                    />

                    {/* Row 5 */}
                    <Box>
                        <DriftParamsTextField
                            driftEnum={DriftParamEnum.SecFlowEffect}
                            end={"kW"}
                            isDisabled={isDisabled() || DriftParamEnum.FlowCalcMode.getContextValue(driftParams) !== "kw"}
                            hoverText={HOVER_TEXT_SEC_FLOW_EFFECT}
                            handleHover={handleHover}
                            handleExit={handleExit}
                        />
                    </Box>

                </Container>
            </Stack >
        </>
    )
}