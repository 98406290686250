import { Database, get, ref } from "firebase/database";
import { SESSION_SHUNT_PATH } from "../constants/firebasePaths";

/**
 * 
 * @param {Database} database 
 * @param {string} shuntID 
 */
const getShuntData = async function getShuntData(database, shuntID) {
    const infoRef = ref(database, SESSION_SHUNT_PATH + shuntID);

    const result = await get(infoRef);
    //console.log("RES: ", result)
    return { NO_ID_FIELD: shuntID, ...result.val() };
}

export { getShuntData }
