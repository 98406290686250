import { useState } from "react";

import { RequestUserModal } from "./requestUserModal";

export const RequestSLUserTextButton = () => {
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        setOpenModal(false);
    }


    return (
        <>
            <span onClick={handleOpenModal} style={{ fontWeight: 'bold', cursor: 'pointer', textDecoration: 'underline' }}>ansöka om konto</span>

            <RequestUserModal openModal={openModal} handleCloseModal={handleCloseModal} />
        </>
    )
}