import { Stack, Paper, Typography, Button, Modal, Box, Tooltip } from "@mui/material"
import { logEvent } from "firebase/analytics"
import { Fragment, useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useAnalytics, useAuth, useDatabase } from "reactfire"
import { useSessionParams, useShuntParams } from "../../../contexts"
import { SessionParamsEnum } from "../../../enums"
import { saveSessionData } from "../../../firebase/functions"
import { FindSessionOnFB } from "../../utils/findSessionOnFB"
import { SessionInputField } from "./components/sessionInputField"
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import IntroductionModal from "../introductionModal/introductionModal"
import { getSessionCurrentShunt } from "../../../firebase/functions/getSessionData"
import { containsIlligalCharacters } from "../../../functions/HelperFunctions/containsIlligalChars"
import { useContextAuth } from "../../../authContext/AuthHook"
import { LoginModule } from "../login/loginModule"
import { MyPagesModalStart } from "../overviewStartTab/myPagesModalStartTab"
import { RequestSLUserTextButton } from "../requestSLUser/requestSLUserTextButton"

export const StartLanding = () => {
    const { user } = useContextAuth();

    const analytics = useAnalytics();
    const database = useDatabase();
    const sessionParams = useSessionParams();
    const shuntParams = useShuntParams();
    const navigate = useNavigate();
    const auth = useAuth()

    const isApiSession = SessionParamsEnum.IsApiSession.getContextValue(sessionParams);

    const [searchID, setSearchID] = useState('');
    const [isEdit, setIsEdit] = useState(false);

    const [openError, setOpenError] = useState(false);
    const handleOpenError = () => setOpenError(true);
    const handleCloseError = () => setOpenError(false);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const isLoggedIn = () => {
        if (user != null && user?.isAnonymous === false && user?.name != null && user?.name !== '') {
            return true;
        }
        else {
            return false;
        }
    }

    const editSession = () => {
        if (containsIlligalCharacters(SessionParamsEnum.SearchID.getContextValue(sessionParams)) === false) {
            logEvent(analytics, 'search_edit', { type: "editSession", search_term: SessionParamsEnum.SearchID.getContextValue(sessionParams) });

            setSearchID(String(SessionParamsEnum.SearchID.getContextValue(sessionParams)).trim());
            setIsEdit(true);
            setOpen(true);
        }
        else {
            handleOpenError();
        }
    }

    const viewSession = () => {
        if (containsIlligalCharacters(SessionParamsEnum.SearchID.getContextValue(sessionParams)) === false) {
            logEvent(analytics, 'search_view', { type: "viewSession", search_term: SessionParamsEnum.SearchID.getContextValue(sessionParams) });
            setSearchID(String(SessionParamsEnum.SearchID.getContextValue(sessionParams)).trim());
            setIsEdit(false);
            setOpen(true);
        }
        else {
            handleOpenError();
        }
    }

    const createSession = async () => {
        let sessionID;
        let shuntID;
        if (isApiSession !== true) {
            sessionID = await SessionParamsEnum.createSessionID(sessionParams);
            saveSessionData(sessionID, database, sessionParams, auth)
            shuntID = await SessionParamsEnum.addSessionShunt(sessionParams, database, true, shuntParams);
        }
        else if (SessionParamsEnum.SessionID.getContextValue(sessionParams) != null && SessionParamsEnum.SessionID.getContextValue(sessionParams) !== "") {
            sessionID = SessionParamsEnum.SessionID.getContextValue(sessionParams);
            saveSessionData(sessionID, database, sessionParams, auth)
            let current = await getSessionCurrentShunt(database, sessionParams);
            if (current == null) {
                shuntID = await SessionParamsEnum.addSessionShunt(sessionParams, database, true, shuntParams);
            }
            else {
                shuntID = current;
                SessionParamsEnum.CurrentShunt.setContextValue(sessionParams, current);
                SessionParamsEnum.MaxStep.setContextValue(sessionParams, 0, database, current);
                SessionParamsEnum.CurrentStep.setContextValue(sessionParams, 0, database, current);
            }
        }
        else {
            console.error("Is Api but no sessionID is set");
            return;
        }

        //console.log("Shunt: ", shuntID);
        navigate("/stepper");
    }

    return (
        <>
            <Stack direction="row" spacing={2} justifyContent={"center"} alignItems={"stretch"} marginBottom={'15px'}>
                <Paper elevation={0} sx={{ width: '40%', padding: '10px', backgroundColor: '#E1E2E4' }}>

                    <Typography variant='h3' fontWeight="bold" sx={{ mb: '10px', color: "#183851" }}>Ny utformning:
                    </Typography>
                    <Typography variant='h4' fontWeight="bold" sx={{ color: "#183851" }}>Ange:
                    </Typography>
                    <SessionInputField
                        type={SessionParamsEnum.Facility}
                        context={sessionParams}
                        label={"Anläggning"}
                        location={"anläggning"} />

                    <br />
                    <SessionInputField
                        type={SessionParamsEnum.ProjNr}
                        context={sessionParams}
                        label={"Projektnummer"}
                        location={"projNr"} />
                    <Button onClick={createSession} size='small' variant="contained" color="primary" sx={{ mt: '10px' }}>Skapa <ArrowCircleRightIcon style={{ marginLeft: '10px' }} /></Button>

                    {isApiSession !== true ? (
                        <Fragment>
                            <Typography variant='h3' fontWeight="bold" sx={{ mt: '30px', color: "#183851" }}>Tidigare session:
                            </Typography>

                            <SessionInputField
                                type={SessionParamsEnum.SearchID}
                                context={sessionParams}
                                label={"Sessions-iD"}
                                location={"sessionsID"} />

                            <Button onClick={viewSession} size='small' variant="contained" disabled={SessionParamsEnum.SearchID.getContextValue(sessionParams) == undefined || SessionParamsEnum.SearchID.getContextValue(sessionParams)?.length <= 14} color="primary" sx={{ mr: '20px', mt: '10px' }}>Granska <ArrowCircleRightIcon style={{ marginLeft: '10px' }} /></Button>
                            <Button onClick={editSession} disabled={SessionParamsEnum.SearchID.getContextValue(sessionParams) == null || SessionParamsEnum.SearchID.getContextValue(sessionParams)?.length <= 14} size='small' variant="contained" color="primary" sx={{ mt: '10px' }}>Redigera (ny session) <ArrowCircleRightIcon style={{ marginLeft: '10px' }} /></Button>
                        </Fragment>
                    ) : (
                        <Box style={{ marginBottom: '141px', marginTop: '30px' }}>

                        </Box>
                    )}

                </Paper>
                <Paper elevation={0} sx={{ width: '60%', padding: '10px', marginTop: '40px', backgroundColor: '#E1E2E4' }}>
                    {isLoggedIn() === true ? (
                        <>
                            <Typography variant="h1" fontWeight="bold" sx={{ mb: '5px', color: "#183851" }}>
                                Välkommen tillbaka till PREMA ShuntLogik<sup>®</sup>
                            </Typography>
                            <Typography variant="h2" className="welcome-title" sx={{ mb: '5px', color: "#183851" }}>
                                {user?.name} {user?.isInternal ? (" - PREMA") : ("")}
                            </Typography>
                        </>
                    ) : (
                        <Typography variant="h1" fontWeight="bold" className="welcome-title" sx={{ mb: '10px', color: "#183851" }}>
                            Välkommen till PREMA ShuntLogik<sup>®</sup>
                        </Typography>
                    )}

                    <Typography variant="body2" sx={{ color: "#183851", fontWeight: '500', marginBottom: '15px' }}>
                        ShuntLogik<sup>®</sup> är PREMAs web-baserade programvara för dimensionering och konfigurering av konventionella shuntgrupper
                        för kyla, värme och/eller värmeåtervinning. Verktyget kan även användas för att kontrollera befintliga shuntgruppers
                        tolerans för ändringar av driftsparametrar i anläggningen.
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#183851", fontWeight: '500', marginBottom: '15px' }}>
                        Kom igång genom att läsa vår <IntroductionModal /> eller gå vidare direkt med din utformning genom att klicka på knappen Skapa
                        (ej obligatoriskt att ange Anläggning och Projektnummer). I programmet finns förklaringstext i anslutning till samtliga inmatningsfält.
                        Det finns också en generell hjälpfunktion att tillgå. Om du har frågor utöver detta är du välkommen att kontakta vår kundsupport.
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#183851", fontWeight: '500', marginBottom: '15px' }}>
                        Det är även möjligt att granska eller redigera en tidigare körning, förutsatt att du har tillgång till körningens Sessions-ID.
                        Mer information om detta hittar du i introduktionsanvisningen.
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#183851", fontWeight: '500' }}>
                        Är du en återkommande användare av ShuntLogik<sup>®</sup> är du välkommen att <RequestSLUserTextButton />,
                        där du bland annat kan se och hantera alla dina skapade sessioner. Om du redan har ett konto så når du din sessionshistorik via Översikt-fönstret inne i en aktiv session,
                        eller här via <MyPagesModalStart />
                    </Typography>
                </Paper>
            </Stack>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div>
                    <FindSessionOnFB sessionID={searchID} edit={isEdit} />
                </div>
            </Modal>

            <Modal
                open={openError}
                onClose={handleCloseError}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '300px',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    borderRadius: '5px',
                    boxShadow: 24,
                    p: 4,
                }}>
                    Det angivna Sessions-ID:t innehåller ogiltiga tecken.
                </Box>

            </Modal>
        </>
    )
}

