import { Button, LinearProgress, TextField, Typography } from "@mui/material"
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useEffect, useState } from "react";
import { sendPasswordResetMail } from "../../../firebase/functions/cloudFunctions/sendPasswordResetMail";
import { useFunctions } from "reactfire";

export const ResetPasswordForm = ({ handleClose, handleResetPasswordSwitch }) => {
    const functions = useFunctions();
    const [validation, setValidation] = useState("");
    const [msgSent, setMsgSent] = useState(false);
    const [sending, setSending] = useState(false);

    useEffect(() => {
        setMsgSent(false);
    }, []);

    const handleLocalClose = () => {
        setMsgSent(false);
        handleClose();
    }

    const handleLocalSwitch = () => {
        setMsgSent(false);
        handleResetPasswordSwitch();
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const { email } = event.target;
        setSending(true);
        console.log("Email: ", email?.value)
        sendPasswordResetMail(functions, email?.value).then(() => {
            setMsgSent(true);
        }).catch((err) => {
            console.error("Error: ", err);
        }).finally(() => {
            setSending(false);
        })
    }

    return (
        <form onSubmit={handleSubmit}>
            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                <AdminPanelSettingsIcon style={{ fontSize: '28px', color: '#1976d2' }} />
            </div>
            <div style={{ marginBottom: '10px' }}>
                <TextField
                    required
                    fullWidth
                    disabled={msgSent}
                    label="E-post"
                    name="email"
                    placeholder="Ange din e-post"
                    margin="dense"
                    size="medium"
                />
            </div>

            {msgSent ? (
                <>
                    <Typography textAlign={'center'} color={'#616161'}>
                        Om det finns ett konto med denna mailen kommer det snart ett mail med vidare instruktioner för att återställa lösenordet.
                    </Typography>
                </>
            ) : (
                <>
                    <Button variant="contained" type='submit' fullWidth disabled={sending}>
                        Skicka återställnings-email
                    </Button>
                    {sending ? (
                        <LinearProgress />
                    ) : ("")}
                </>
            )}

            <Button className='forgot-pass' onClick={handleLocalSwitch}>
                Återgå till login
            </Button>

            <p className="validation">{validation}</p>
        </form>
    )
}