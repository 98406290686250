import { useEffect } from "react"
import { ErrorEnum, NoticeEnum, SessionParamsEnum } from "../enums";
import { useAdjustParams, useCalcParams, useDriftParams, useErrorContext, useNoticeContext, useProductLineParams, usePumpParams, useSessionParams, useShuntParams, useWizardParams } from "../contexts";
import { useDatabase } from "reactfire";
import { useNavigate } from "react-router-dom";
import { loadShuntFromFirebase } from "../firebase/functions/loadShuntFromFirebase";


export const useSwitchShunt = (shuntID, handleClose = null, newSession = false, setOpen = null) => {
    const errorlist = new ErrorEnum();
    const noticelist = new NoticeEnum();
    const errorProvider = useErrorContext();
    const noticeProvider = useNoticeContext();

    const database = useDatabase();
    const navigate = useNavigate();
    const sessionParams = useSessionParams();
    const shuntParams = useShuntParams();
    const adjustParams = useAdjustParams();
    const productLineParams = useProductLineParams();
    const calcParams = useCalcParams();
    const driftParams = useDriftParams();
    const pumpParams = usePumpParams();
    const wizardParams = useWizardParams();

    useEffect(() => {
        if (shuntID != null && shuntID !== "") {
            if (shuntID !== SessionParamsEnum.CurrentShunt.getContextValue(sessionParams) || newSession === true) {
                const loadShunt = async () => {
                    SessionParamsEnum.SwitchingShunt.setContextValue(sessionParams, true);
                    errorlist.clearAllErrors(errorProvider);
                    noticelist.clearAllNotices(noticeProvider);
                    const step = await loadShuntFromFirebase(shuntID, database, sessionParams, driftParams, shuntParams, calcParams, adjustParams, pumpParams, productLineParams, wizardParams, errorProvider, noticeProvider);
                    SessionParamsEnum.CurrentStep.setContextValue(sessionParams, step);

                    if (handleClose !== null) {
                        handleClose();
                    }

                    if (setOpen != null){
                        setOpen(false);
                    }
                    
                    SessionParamsEnum.SwitchingShunt.setContextValue(sessionParams, false);
                    if (newSession === true){
                        navigate("/stepper");
                    }
                }

                loadShunt();
            }
            else {
                if (setOpen != null){
                    setOpen(false);
                }
            }
        }
        else {
            if (setOpen != null){
                setOpen(false);
            }
        }
    }, [shuntID])
}