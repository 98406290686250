import { Box, Card, CardContent, IconButton, Stack, Tooltip, Typography } from "@mui/material"

import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

import { useContextAuth } from "../../../authContext/AuthHook";
import { useEffect, useState } from "react";
import { saveMeasurementRows } from "../../../firebase/functions/saveTechRows";
import { useDatabase } from "reactfire";
import { useSessionParams } from "../../../contexts";
import { SessionParamsEnum } from "../../../enums";

export const ShuntMeasurementCard = ({ shuntID, measurement, editable = false, shuntData = null }) => {
    const database = useDatabase();
    const sessionParams = useSessionParams();
    const [editMode, setEditMode] = useState(false);
    const [reset, setReset] = useState(false);
    const [measurementValue, setMeasurementValue] = useState('')
    const [measurementRows, setMeasurementRows] = useState([])
    const { user } = useContextAuth();

    useEffect(() => {
        if (editable === true) {
            if (shuntData != null && Object.keys(shuntData).length > 0) {
                if (shuntData?.SpecData?.measurementList != null && reset === false) {
                    let measureArray = []
                    for (let index = 0; index < shuntData.SpecData.measurementList.length; index++) {
                        const element = shuntData.SpecData.measurementList[index];
                        measureArray.push(element.text);
                    }
                    setMeasurementValue(measureArray.join('\n'));
                    setMeasurementRows(shuntData.SpecData.measurementList)
                }
                else if (measurement != null && Object.keys(measurement).length > 0) {
                    setTextRowData();
                }
            }
        }
    }, [measurement, editable])

    useEffect(() => {
        if (reset === true && editable === true) {
            setTextRowData();
            setReset(false);
        }
    }, [reset])

    const setTextRowData = () => {
        let textRows = [];
        let textRowData = [];

        if (measurement.weight != null) {
            textRows.push('Vikt c:a ' + measurement.weight + 'kg');
            textRowData.push({ text: 'Vikt c:a ' + measurement.weight + 'kg', tag: 'weight', value: measurement.weight, unit: 'kg' });
        }

        if (measurement.Y != null) {
            textRows.push('xx = ' + measurement.Y + 'mm');
            textRowData.push({ text: 'xx = ' + measurement.Y + 'mm', tag: 'xx', value: measurement.Y, unit: 'mm' });
        }

        if (measurement.h != null) {
            if (measurement.useHHTag === true) {
                textRows.push('H = ' + measurement.h + 'mm');
                textRowData.push({ text: 'H = ' + measurement.h + 'mm', tag: 'H', value: measurement.h, unit: 'mm' });
            }
            else {
                textRows.push('h = ' + measurement.h + 'mm');
                textRowData.push({ text: 'h = ' + measurement.h + 'mm', tag: 'h', value: measurement.h, unit: 'mm' });
            }
        }

        if (measurement.useIVal === true) {
            if (measurement.I != null) {
                textRows.push('I = ' + measurement.I + 'mm');
                textRowData.push({ text: 'I = ' + measurement.I + 'mm', tag: 'I', value: measurement.I, unit: 'mm' });
            }
        }
        else if (measurement.X != null) {
            if (measurement.useITag === true) {
                textRows.push('I = ' + measurement.X + 'mm');
                textRowData.push({ text: 'I = ' + measurement.X + 'mm', tag: 'I', value: measurement.X, unit: 'mm' });
            }
            else {
                textRows.push('x = ' + measurement.X + 'mm');
                textRowData.push({ text: 'x = ' + measurement.X + 'mm', tag: 'x', value: measurement.X, unit: 'mm' });
            }
        }

        if (measurement.depth != null) {
            textRows.push('Djup = ' + measurement.depth + 'mm');
            textRowData.push({ text: 'Djup = ' + measurement.depth + 'mm', tag: 'Djup', value: measurement.depth, unit: 'mm' });
        }

        if (measurement.diameter != null) {
            textRows.push('c/c = ' + measurement.diameter + 'mm');
            textRowData.push({ text: 'c/c = ' + measurement.diameter + 'mm', tag: 'cc', value: measurement.diameter, unit: 'mm' });
        }

        textRows.push('');
        textRows.push('Mått exkl. pump och ventiler');
        textRowData.push({ text: '\n' });
        textRowData.push({ text: 'Mått exkl. pump och ventiler' });

        setMeasurementValue(textRows.join('\n'));
        setMeasurementRows(textRowData);
    }

    const handleCancelEdit = () => {
        setTextRowData();
        setEditMode(false);
    }

    const saveMeasurementData = () => {
        const rows = measurementValue.split('\n');
        let textRows = [];
        for (let index = 0; index < rows.length; index++) {
            const element = rows[index];
            if (element === ""){
                textRows.push({ text: '\n' });
            }
            else{
               textRows.push({ text: element }); 
            }
            
        }

        setMeasurementRows(textRows);
        console.log("FB Data: ", shuntID, textRows)
        saveMeasurementRows(database, shuntID, SessionParamsEnum.SessionID.getContextValue(sessionParams), textRows);
        setEditMode(false);
    }

    function handleKeyDown(e) {
        if (e.key === 'Tab') {
            e.preventDefault();

            const cursorPosition = e.target.selectionStart;
            const cursorEndPosition = e.target.selectionEnd;
            const tab = '\t';

            e.target.value = measurementValue.substring(0, cursorPosition) + tab + measurementValue.substring(cursorEndPosition);

            e.target.selectionStart = cursorPosition + tab.length;
            e.target.selectionEnd = cursorPosition + tab.length;
            setMeasurementValue(e.target.value);
        }
    }

    function handleChangeMeasurementValue(e) {
        setMeasurementValue(e.target.value);
    }

    function renderText(value, index) {
        const tabSize = 4;
        const spaces = '\u00A0'.repeat(tabSize);
        let text;
        if (value != null) {
            text = value.replace(/\t/g, spaces);
        }
        else {
            text = "";
        }

        return (
            <Typography key={'tech-' + index} style={{ width: '550px', whiteSpace: 'pre-wrap' }}>
                {text}
            </Typography>
        )
    }

    return (
        <Card variant="outlined">
            <CardContent style={{ paddingBottom: '15px' }}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography style={{ marginBottom: '6px' }}><strong>Mått</strong></Typography>
                    {user?.isInternal === true && editable === true && SessionParamsEnum.Editable.getContextValue(sessionParams) === true ? (
                        <>
                            {editMode ? (
                                <Stack direction={'row'} style={{ position: 'relative' }}>
                                    <Tooltip title="Spara ändringar">
                                        <IconButton size="small" onClick={saveMeasurementData}>
                                            <SaveIcon sx={{ "&:hover": { color: "#9e9e9e" }, fontSize: '12px' }} htmlColor={"#d9d9d9"} />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Stäng utan att spara">
                                        <IconButton size="small" onClick={handleCancelEdit}>
                                            <CloseIcon sx={{ "&:hover": { color: "#9e9e9e" }, fontSize: '12px' }} htmlColor={"#d9d9d9"} />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            ) : (
                                <Stack direction={'row'} style={{ position: 'relative' }}>
                                    <Tooltip title="Redigera mått">
                                        <IconButton size="small" onClick={() => { setEditMode(true) }}>
                                            <EditIcon sx={{ "&:hover": { color: "#9e9e9e" }, fontSize: '12px' }} htmlColor={"#d9d9d9"} />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Återställ fördefinierad mått data.">
                                        <IconButton size="small" onClick={() => { setReset(true) }}>
                                            <SettingsBackupRestoreIcon sx={{ "&:hover": { color: "#9e9e9e" }, fontSize: '12px' }} htmlColor={"#d9d9d9"} />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            )}
                        </>

                    ) : ("")}

                </Stack>

                {editMode ? (
                    <Box paddingRight={'5px'}>
                        <textarea
                            className="textarea-box"
                            style={{
                                height: '170px',
                                minHeight: '90px',
                                maxHeight: '430px'
                            }}
                            value={measurementValue}
                            onChange={handleChangeMeasurementValue}
                            onKeyDown={handleKeyDown}
                            placeholder="Skriv mått här..."
                        />
                    </Box>
                ) : (
                    <>
                        {measurementRows != null && measurementRows.length > 0 && editable === true ? (
                            <>
                                {measurementRows != null ? (
                                    <>
                                        {measurementRows.map((row, index) => {
                                            return renderText(row?.text, index);
                                        })}
                                    </>
                                ) : ("")}
                            </>
                        ) : (
                            <>
                                {measurement != null && (
                                    <>
                                        {measurement.weight != null && (<Typography>Vikt c:a {measurement.weight}kg exkl. pump</Typography>)}
                                        {measurement.Y != null && (<Typography>xx = {measurement.Y}mm</Typography>)}
                                        {measurement.h != null && (
                                            <>
                                                {measurement.useHHTag === true ? (
                                                    <Typography>H = {measurement.h}mm</Typography>
                                                ) : (
                                                    <Typography>h = {measurement.h}mm</Typography>
                                                )}
                                            </>
                                        )}

                                        {measurement.useIVal === true ? (
                                            <>{measurement.I != null && (<Typography>I = {measurement.I}mm</Typography>)}</>
                                        ) : (
                                            <>
                                                {measurement.useITag === true ? (
                                                    <>{measurement.X != null && (<Typography>I = {measurement.X}mm</Typography>)}</>
                                                ) : (
                                                    <>{measurement.X != null && (<Typography>x = {measurement.X}mm</Typography>)}</>
                                                )}
                                            </>
                                        )}
                                        {measurement.depth != null && (<Typography>Djup = {measurement.depth}mm</Typography>)}
                                        {measurement.diameter != null && (<Typography>c/c = {measurement.diameter}mm</Typography>)}

                                        <br></br>
                                        <Typography>Mått exkl. pump och ventiler</Typography>
                                    </>
                                )}
                            </>
                        )}

                    </>
                )}
            </CardContent>
        </Card>
    )
}