import { INSULATION_LABELS } from "../adjustmentsLables";
import { INSULATION_19NH_SMART } from "../adjustmentsOptions";
import { ConnectionTypeSpecificationDataListInterface } from "./types";

export const connectionTypeSpecificationDataList: ConnectionTypeSpecificationDataListInterface = {
    "SR-2": {
        id: "SR-2",
        numOfConnections: 4,
    },
    "SR-2 flex Appshunt": {
        id: "SR-2 flex Appshunt",
        numOfConnections: 4,
        numOfThermo: 3,
        specialShuntData: {
            'adjValve': 'StadF',
            'controlValve': 'IMI',
            'flexModel': 'TAModulator',
            'numIV': 1,
            'numBV': 1,
            'shuntNomenklaturString': 'SRH-2flex-App'
        },
        specialPumpOptions: {
            'pumpRequestType': 'customerSpecified',
            'specificPumpRequest': 'Wilo PARA 25-130/7, 1x230V/0,44Amp'
        },
        specialInsulationText: INSULATION_LABELS[INSULATION_19NH_SMART] + " (hellimmad)",
    },
    "SR-3": {
        id: "SR-3",
        numOfConnections: 4,
    },
    "PR-2": {
        id: "PR-2",
        numOfConnections: 4,
    },
    "PR-2 Appshunt": {
        id: "PR-2 Appshunt",
        numOfConnections: 4,
        numOfThermo: 3,
        specialShuntData: {
            'adjValve': 'StadF',
            'controlValve': 'Belimo',
            'cvModel': 'R3015',
            'numIV': 2,
            'shuntNomenklaturString': 'PRH-2-App'
        },
        specialPumpOptions: {
            'pumpRequestType': 'customerSpecified',
            'specificPumpRequest': 'Wilo PARA 25-130/7, 1x230V/0,44Amp'
        },
        specialInsulationText: INSULATION_LABELS[INSULATION_19NH_SMART] + " (hellimmad)",
    },
    "PR-3": {
        id: "PR-3",
        numOfConnections: 4,
    },
    "VA-1": {
        id: "VA-1",
        numOfConnections: 4,
    },
    "FBU-5K": {
        id: "FBU-5K",
        numOfConnections: 5,
    },
    "FBU-5V": {
        id: "FBU-5V",
        numOfConnections: 5,
    },
    "FKU-5K": {
        id: "FKU-5K",
        numOfConnections: 5,
    },
    "SRX-42": {
        id: "SRX-42",
        numOfConnections: 4,
        hasSafetyValve: true,
        hasShuntMuff: true,
        specialExhangeValue: "Prim",
    },
    "SRX-43": {
        id: "SRX-43",
        numOfConnections: 4,
        hasSafetyValve: true,
        hasShuntMuff: true,
        specialExhangeValue: "Prim",
    },
    "PRX-43": {
        id: "PRX-43",
        numOfConnections: 4,
        hasSafetyValve: true,
        hasShuntMuff: true,
        specialExhangeValue: "Sek",
    },
    "PRUX-43FK": {
        id: "PRUX-43FK",
        numOfConnections: 4,
        hasSafetyValve: true,
        hasShuntMuff: true,
        specialExhangeValue: "Sek",
    },
    "SRBX-62VK": {
        id: "SRBX-62VK",
        numOfConnections: 6,
    },
    "SRBX-63VK": {
        id: "SRBX-63VK",
        numOfConnections: 6,
    },
    "PRBX-6VK": {
        id: "PRBX-6VK",
        numOfConnections: 6,
    },
    "SRBX-6ECO": {
        id: "SRBX-6ECO",
        numOfConnections: 6,
    },
    "SRBX-6VAV": {
        id: "SRBX-6VAV",
        numOfConnections: 6,
    },
    "SRBX-6SPETS": {
        id: "SRBX-6SPETS",
        numOfConnections: 6,
    },
    "SRBX-6NOD": {
        id: "SRBX-6NOD",
        numOfConnections: 6,
    },
    "SRBX-6VAK": {
        id: "SRBX-6VAK",
        numOfConnections: 6,
    },
    "SRBX-6VAK-Forbi": {
        id: "SRBX-6VAK-Forbi",
        numOfConnections: 6,
    },
    "SRBX-8VAKK": {
        id: "SRBX-8VAKK",
        numOfConnections: 8,
    },
    "SRBX-8VAKK-Forbi": {
        id: "SRBX-8VAKK-Forbi",
        numOfConnections: 8,
    },
    "PBCS": {
        id: "PBCS",
        numOfConnections: null,
    },
};