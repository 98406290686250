import { FormControl, Select, MenuItem, InputLabel, Stack } from "@mui/material"
import { APPLICATION, APPLICATION_INTENT, APPLICATION_SIZE, QUESTION_APPLICATION_INTENT_KEY, QUESTION_APPLICATION_KEY, QUESTION_APPLICATION_SIZE_KEY } from "../../../../constants/wizardConstants"

export const ApplicationQuestion = ({ application, applicationIntent, applicationSize, source, answerQuestion }) => {
    /**
     * 
     * @param {string[]} sourceNonCompatible 
     */
    const checkIfDisabled = (sourceNonCompatible) => {
        let disabled = false;
        if (sourceNonCompatible != null) {
            for (let index = 0; index < sourceNonCompatible.length; index++) {
                const sourceVal = sourceNonCompatible[index];

                if (source[sourceVal] === true) {
                    disabled = true;
                    break;
                }
            }
        }

        return disabled;
    }

    return (
        <Stack direction={'row'} spacing={2}>
            <FormControl size="small">
                <InputLabel>Applikation (syfte)</InputLabel>
                <Select
                    sx={{ width: '180px', height: '30px', background: 'white' }}
                    label={'Applikation (syfte)'}
                    size="small"
                    value={applicationIntent}
                    onChange={(e) => answerQuestion(QUESTION_APPLICATION_INTENT_KEY, e.target.value)}
                >
                    <MenuItem sx={{ height: '25px' }} value={''}></MenuItem>
                    {Object.keys(APPLICATION_INTENT).map((appKey) => (
                        <MenuItem key={appKey} disabled={checkIfDisabled(APPLICATION_INTENT[appKey]?.sourceNonCompatible)} value={APPLICATION_INTENT[appKey].value}>{APPLICATION_INTENT[appKey].label}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl size="small">
                <InputLabel>Applikation (system)</InputLabel>
                <Select
                    sx={{ width: '180px', height: '30px', background: 'white' }}
                    label={'Applikation (system)'}
                    size="small"
                    value={application}
                    onChange={(e) => answerQuestion(QUESTION_APPLICATION_KEY, e.target.value)}
                >
                    <MenuItem sx={{ height: '25px' }} value={''}></MenuItem>
                    {Object.keys(APPLICATION).map((appKey) => (
                        <MenuItem key={appKey} disabled={checkIfDisabled(APPLICATION[appKey]?.sourceNonCompatible)} value={APPLICATION[appKey].value}>{APPLICATION[appKey].label}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/*
            <FormControl size="small">
            <InputLabel>Systemstorlek</InputLabel>
                <Select
                    sx={{ width: '118px', height: '30px', background: 'white' }}
                    label={'Systemstorlek'}
                    size="small"
                    value={applicationSize}
                    onChange={(e) => answerQuestion(QUESTION_APPLICATION_SIZE_KEY, e.target.value)}
                >
                    <MenuItem sx={{height: '25px'}} value={''}></MenuItem>
                    {Object.keys(APPLICATION_SIZE).map((appKey) => (
                        <MenuItem key={appKey} value={APPLICATION_SIZE[appKey]?.value}>{APPLICATION_SIZE[appKey]?.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>*/}
        </Stack>
    );
}