import { Box, Button, CircularProgress, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react"
import { useDatabase } from "reactfire";
import { useInitMagicloud } from "../../../hooks/useInitMagicloud";
import { SessionParamsEnum, ShuntParamsEnum } from "../../../enums";
import { useSessionParams, useShuntParams } from "../../../contexts";
import { MagicloudCadDownloadButtons } from "./cadDownloadButtons";

const StyledButton = styled(Button)(({ theme }) => ({
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
    color: '#bababa',
    ":disabled": {
        color: '#06549d',
        backgroundColor: '#f9f9f9'
    },
}));

const StyledButtonDxf = styled(Button)(({ theme }) => ({
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '0px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '0px',
    borderColor: '#bababa',
    borderStyle: 'solid',
    borderWidth: '1px 1px 1px 1px',
    minWidth: '125px',
    height: '32px'
}));

const StyledButtonRfa = styled(Button)(({ theme }) => ({
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '4px',
    borderColor: '#bababa',
    borderStyle: 'solid',
    borderWidth: '1px 1px 1px 0',
    minWidth: '125px',
    height: '32px'
}));

export const ModelDisplay = ({ productId }) => {
    const database = useDatabase();
    const shuntParams = useShuntParams();
    const sessionParams = useSessionParams();
    const {
        magiCadUser,
        loadModel,
        signInToMagicad,
    } = useInitMagicloud();

    //const [magiCadUser, setMagiCadUser] = useState(null);
    const [value, setValue] = useState(0);
    const [dimImage, setDimImage] = useState('');
    const [modelExists, setModelExists] = useState(false);
    const [loading, setLoading] = useState(true);
    const [dxfLink, setDxfLink] = useState('');
    const [rfaLinks, setRfaLinks] = useState([]);

    const handleChange = (newValue) => {
        setValue(newValue);
    };


    // @ts-ignore
    const MagiCloud = window.MagiCloud;

    useEffect(() => {
        if (productId != null && productId !== '') {
            loadModel(productId).then((res) => {
                setDxfLink(res.dxfLink);
                setRfaLinks(res.rfaLinks);
                setDimImage(res.dimImage);
                setModelExists(res.modelExists);
                ShuntParamsEnum.MagicloudLink.setContextValue(shuntParams, res.permaLink, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));

                MagiCloud.renderX3D(res.x3dOptions);
            })
        }
        else {
            ShuntParamsEnum.MagicloudLink.setContextValue(shuntParams, "", database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
            setModelExists(false);
            setLoading(false);
        }
    }, [productId, magiCadUser]);

    if (loading === true) {
        return (
            <div style={{ width: '250px', height: '330px', display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
        )
    }

    return (
        <>
            {modelExists === true ? (
                <Box sx={{ marginTop: '20px' }}>
                    <StyledButton style={{ minWidth: '35px', minHeight: '35px' }} size="small" disabled={value === 0} onClick={() => { handleChange(0) }}>
                        3D
                    </StyledButton>
                    <StyledButton style={{ minWidth: '35px', minHeight: '35px' }} size="small" disabled={value === 1} onClick={() => { handleChange(1) }}>
                        2D
                    </StyledButton>

                    <div style={{ display: 'inline-block' }}>
                        <Box style={{ border: 'none' }} display={value === 0 ? '' : 'none'} id="x3dContainer"></Box>
                    </div>
                    {value !== 0 ? (
                        <>
                            {dimImage != null && dimImage !== '' ? (
                                <div style={{ display: 'inline-block' }}>
                                    <img src={dimImage} alt="2d Dim" width={'250px'} />
                                </div>
                            ) : (
                                <Box style={{ backgroundColor: '#f5f5f5', width: '250px', height: '330px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography fontSize={'18px'}>
                                        Bild ej tillgänglig
                                    </Typography>
                                </Box>
                            )}
                        </>
                    ) : ("")}

                    <Box>
                        <MagicloudCadDownloadButtons productId={productId} />
                    </Box>
                </Box>
            ) : (
                <Box sx={{ marginTop: '20px' }}>
                    <Button style={{ minWidth: '35px', minHeight: '35px' }} size="small" disabled>
                        3D
                    </Button>
                    <Button style={{ minWidth: '35px', minHeight: '35px' }} size="small" disabled>
                        2D
                    </Button>
                    <Box style={{ backgroundColor: '#f5f5f5', width: '250px', height: '330px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography fontSize={'18px'}>
                            Modell ej tillgänglig
                        </Typography>
                    </Box>
                </Box>
            )}
        </>
    )
}