import { SHUNT_SR2_LINK, SHUNT_SR2_FLEX_APP_LINK, SHUNT_SR3_LINK, SHUNT_PR2_LINK, SHUNT_PR2_APP_LINK, SHUNT_PR3_LINK, SHUNT_VA1_LINK, SHUNT_FBU_5K_LINK, SHUNT_FBU_5V_LINK, SHUNT_FKU_5K_LINK, SHUNT_SRX_42_LINK, SHUNT_SRX_43_LINK, SHUNT_PRX_43_LINK, SHUNT_PRX_43FK_LINK, SHUNT_SRBX_62VK_LINK, SHUNT_SRBX_63VK_LINK, SHUNT_PRBX_6VK_LINK, SHUNT_SRBX_6ECO_LINK, SHUNT_SRBX_6VAV_LINK, SHUNT_SRBX_6SPETS_LINK, SHUNT_SRBX_6NOD_LINK, SHUNT_SRBX_6VAK_LINK, SHUNT_SRBX_6VAK_FORBI_LINK, SHUNT_SRBX_8VAKK_LINK, SHUNT_SRBX_8VAKK_FORBI_LINK, SHUNT_PBCS_LINK } from "../generalText/shuntLinks";
import { PRODUCTFAMILY_SR, PRODUCTLINE_STANDARD, PRODUCTLINE_FLEX, PRODUCTLINE_SMART, PRODUCTLINE_GREEN_I, PRODUCTLINE_GREEN_II, PRODUCTFAMILY_PR, PRODUCTFAMILY_VA } from "../productlineConstants";
import { ConnectionTypeInfoDataListInterface } from "./types";

export const connectionTypeInfoDataList: ConnectionTypeInfoDataListInterface = {
    "SR-2": {
        id: "SR-2",
        name: "SR-2",
        plModName: "SR{x}-2",
        header: 'För system med tillgängligt drivtryck',
        shuntFamily: PRODUCTFAMILY_SR,
        primText: 'För ventilation (värme och kyla), radiatorer, golvvärme och kylbafflar',
        prodLink: SHUNT_SR2_LINK,
        applicationInfoText: 'Fjärrvärme/fjärrkyla Värmepump',
        applicationInfoSubText: '- exv. vid ackumulatortank',
        descriptionRows: [
            <span>2 - vägs primärmonterad styrventil </span>,
            <span>T<sub> prim, ret </sub> = T<sub>sek,ret</sub></span>,
            <span>ΔT<sub> prim </sub> {'>'}= ΔT<sub>sek</sub></span>,
        ],
        prodlines: [
            PRODUCTLINE_STANDARD,
            PRODUCTLINE_FLEX,
            PRODUCTLINE_SMART,
            PRODUCTLINE_GREEN_I,
            PRODUCTLINE_GREEN_II,
        ],
    },
    "SR-2 flex Appshunt": {
        id: "SR-2 flex Appshunt",
        header: 'Värmeåtervinning',
        shuntFamily: PRODUCTFAMILY_SR,
        name: "SRH-2 flex Appshunt",
        plModName: "SRH-2 flex Appshunt",
        primText: '',
        prodLink: SHUNT_SR2_FLEX_APP_LINK,
        applicationInfoText: 'Kyla, värme',
        applicationInfoSubText: '- För små ventilationsbatterier och enskilda rumsvärmare/kylare',
        descriptionRows: [
            <span>2 - vägs primärmonterad styrventil med inbyggd differensregulator</span>,
            <span>Flödesområde prim 0,055 - 0,49 l/s</span>,
        ],
        prodlines: [
            PRODUCTLINE_FLEX,
        ],
    },
    "SR-3": {
        id: "SR-3",
        header: 'För system med tillgängligt drivtryck',
        shuntFamily: PRODUCTFAMILY_SR,
        name: "SR-3",
        plModName: "SR{x}-3",
        primText: 'För ventilation (värme och kyla), radiatorer, golvvärme och kylbafflar',
        prodLink: SHUNT_SR3_LINK,
        applicationInfoText: 'Värmepanna/kylmaskin Värmepump',
        descriptionRows: [
            <span>3 - vägs primärmonterad styrventil</span>,
            <span>Auktoritet - {'>'} 100 % </span>,
            <span>ΔT<sub>prim</sub> {'>'}= ΔT<sub>sek</sub></span>,
        ],
        prodlines: [
            PRODUCTLINE_STANDARD,
            PRODUCTLINE_GREEN_I,
            PRODUCTLINE_GREEN_II,
        ],
    },
    "PR-2": {
        id: "PR-2",
        header: 'För system med lågt eller obefintligt drivtryck',
        shuntFamily: PRODUCTFAMILY_PR,
        name: "PR-2",
        plModName: "PR{x}-2",
        primText: 'För ventilation (värme och kyla), radiatorer och förshuntning',
        prodLink: SHUNT_PR2_LINK,
        applicationInfoText: 'Fjärrvärme/fjärrkyla',
        descriptionRows: [
            <span>3 - vägs primärmonterad styrventil</span>,
            <span>Auktoritet - {'>'}100 % </span>,
            <span>ΔT<sub>prim</sub> {'>'}= ΔT<sub>sek</sub></span>,
        ],
        prodlines: [
            PRODUCTLINE_STANDARD,
            PRODUCTLINE_GREEN_I,
            PRODUCTLINE_GREEN_II,
        ],
    },
    "PR-2 Appshunt": {
        id: "PR-2 Appshunt",
        header: 'Värmeåtervinning',
        shuntFamily: PRODUCTFAMILY_PR,
        name: "PRH-2 Appshunt",
        plModName: "PRH-2 Appshunt",
        primText: '',
        prodLink: SHUNT_PR2_APP_LINK,
        applicationInfoText: 'Kyla, värme',
        applicationInfoSubText: '- För små ventilationsbatterier och enskilda rumsvärmare/kylare',
        descriptionRows: [
            <span>3 - vägs sekundärmonterad styrventil</span>,
            <span>Flödesområde prim 0,015 - 0,40 l/s</span>,
        ],
        prodlines: [
            PRODUCTLINE_STANDARD,
        ],
    },
    "PR-3": {
        id: "PR-3",
        header: 'Värmeåtervinning',
        shuntFamily: PRODUCTFAMILY_PR,
        name: "PR-3",
        plModName: "PR{x}-3",
        primText: 'För ventilation (kyla och värme) och radiatorer',
        prodLink: SHUNT_PR3_LINK,
        applicationInfoText: 'Värmepanna/kylmaskin',
        descriptionRows: [
            <span>3 - vägs sekundärmonterad styrventil</span>,
            <span>Auktoritet - {'>'} 100%</span>,
            <span>ΔT<sub>prim</sub> {'<'}= ΔT<sub>sek</sub></span>,
        ],
        prodlines: [
            PRODUCTLINE_STANDARD,
            PRODUCTLINE_GREEN_I,
            PRODUCTLINE_GREEN_II,
        ],
    },
    "VA-1": {
        id: "VA-1",
        header: 'Värmeåtervinning',
        shuntFamily: PRODUCTFAMILY_VA,
        name: "VÅ-1",
        plModName: "VA{x}-1",
        primText: 'För värmeåtervinning med vätskekopplade batterier',
        prodLink: SHUNT_VA1_LINK,
        applicationInfoText: 'Värmeåtervinning vätskekopplade batterier',
        descriptionRows: [
            <span>3 - vägs sekundärmonterad styrventil</span>,
        ],
        prodlines: [
            PRODUCTLINE_STANDARD,
            PRODUCTLINE_GREEN_I,
        ],
    },
    "FBU-5K": {
        id: "FBU-5K",
        header: '',
        shuntFamily: 'others',
        name: "FBU-5K",
        plModName: "FBU-5K",
        primText: '',
        prodLink: SHUNT_FBU_5K_LINK,
        applicationInfoText: 'Fjärrkyla',
        applicationInfoSubText: '- för högre returtemperatur i kylsystemet',
        descriptionRows: [
            <span>3 - vägs sekundärmonterad styrventil för returvatten och 2 - vägs styrventil för spetskyla.</span>,
            <span>Använder i första hand returvatten för kylning av sekundärkretsen.</span>,
            <span>2 - vägsventilen spetsar om returvattnets kylning är otillräcklig.</span>,
        ],
        prodlines: [
            PRODUCTLINE_STANDARD,
            PRODUCTLINE_GREEN_I,
        ],
    },
    "FBU-5V": {
        id: "FBU-5V",
        header: '',
        shuntFamily: 'others',
        name: "FBU-5V",
        plModName: "FBU-5V",
        primText: '',
        prodLink: SHUNT_FBU_5V_LINK,
        applicationInfoText: 'Fjärrvärme',
        applicationInfoSubText: '- för lägre returtemperatur i värmesystemet',
        descriptionRows: [
            <span>3 - vägs sekundärmonterad styrventil för returvatten och 2 - vägs styrventil för spetsvärme.</span>,
            <span>Använder i första hand returvatten för värmning av sekundärkretsen.</span>,
            <span>2 - vägsventilen spetsar om returvattnets värmning är otillräcklig.</span>,
        ],
        prodlines: [
            PRODUCTLINE_STANDARD,
            PRODUCTLINE_FLEX,
            PRODUCTLINE_GREEN_I,
            PRODUCTLINE_GREEN_II,
        ],
    },
    "FKU-5K": {
        id: "FKU-5K",
        header: '',
        shuntFamily: 'others',
        name: "FKU-5K",
        plModName: "FKU-5K",
        primText: '',
        prodLink: SHUNT_FKU_5K_LINK,
        applicationInfoText: 'Fjärrkyla högtemp kondensorkyla',
        applicationInfoSubText: '- när högre returtemperatur önskas',
        descriptionRows: [
            <span></span>,
        ],
        prodlines: [
            PRODUCTLINE_STANDARD,
            PRODUCTLINE_FLEX,
            PRODUCTLINE_GREEN_I,
            PRODUCTLINE_GREEN_II,
        ],
    },
    "SRX-42": {
        id: "SRX-42",
        header: '',
        shuntFamily: PRODUCTFAMILY_SR,
        name: "SRX-42",
        plModName: "SR{x}X-42",
        primText: '',
        prodLink: SHUNT_SRX_42_LINK,
        applicationInfoText: 'Fjärrvärme/fjärrkyla',
        applicationInfoSubText: '- vid behov av justering av framledningstemp o/e temperaturdifferens',
        descriptionRows: [
            <span>2 - vägs primärmonterad styrventil och växlare som separerar primär - och sekundärkretsen.</span>,
            <span>Muff för expansionssystem sekundärt.</span>,
        ],
        prodlines: [
            PRODUCTLINE_STANDARD,
            PRODUCTLINE_FLEX,
            PRODUCTLINE_GREEN_I,
            PRODUCTLINE_GREEN_II,
        ],
    },
    "SRX-43": {
        id: "SRX-43",
        header: '',
        shuntFamily: PRODUCTFAMILY_SR,
        name: "SRX-43",
        plModName: "SR{x}X-43",
        primText: '',
        prodLink: SHUNT_SRX_43_LINK,
        applicationInfoText: 'Värmepanna/kylmaskin',
        applicationInfoSubText: '- vid behov av justering av framledningstemp o/e temperaturdifferens',
        descriptionRows: [
            <span>3 - vägs primärmonterad styrventil och växlare som separerar primär - och sekundärkretsen.</span>,
            <span>Muff för expansionssystem sekundärt.</span>,
        ],
        prodlines: [
            PRODUCTLINE_STANDARD,
            PRODUCTLINE_GREEN_I,
            PRODUCTLINE_GREEN_II,
        ],
    },
    "PRX-43": {
        id: "PRX-43",
        header: '',
        shuntFamily: PRODUCTFAMILY_PR,
        name: "PRX-43",
        plModName: "PR{x}X-43",
        primText: '',
        prodLink: SHUNT_PRX_43_LINK,
        applicationInfoText: 'Värmepanna/kylmaskin',
        applicationInfoSubText: '- vid behov av justering av framledningstemp o/e temperaturdifferens',
        descriptionRows: [
            <span>3 - vägs sekundärmonterad styrventil och växlare som separerar primär - och sekundärkretsen.</span>,
        ],
        prodlines: [
            PRODUCTLINE_STANDARD,
            PRODUCTLINE_GREEN_I,
            PRODUCTLINE_GREEN_II,
        ],
    },
    "PRUX-43FK": {
        id: "PRUX-43FK",
        header: '',
        shuntFamily: PRODUCTFAMILY_PR,
        name: "PRUX-43FK",
        plModName: "PRUX-43FK",
        primText: '',
        prodLink: SHUNT_PRX_43FK_LINK,
        applicationInfoText: 'Frikyla',
        applicationInfoSubText: '- för kylning med frikyla från exv. borrhål',
        descriptionRows: [
            <span>3 - vägs primärmonterad styrventil och växlare som separerar primär - och sekundärkretsen.</span>,
            <span>Muff för temperaturgivare / frysskydd mellan styrventil och pump.</span>,
        ],
        prodlines: [
            PRODUCTLINE_STANDARD,
            PRODUCTLINE_GREEN_I,
            PRODUCTLINE_GREEN_II,
        ],
    },
    "SRBX-62VK": {
        id: "SRBX-62VK",
        header: '',
        shuntFamily: PRODUCTFAMILY_SR,
        name: "SRBX-62VK",
        plModName: "SRBX-62VK",
        primText: '',
        prodLink: SHUNT_SRBX_62VK_LINK,
        applicationInfoText: 'Kyla+Värme',
        applicationInfoSubText: '- Ventilation, golvvärme och andra system/maskiner med både kyl- och värmebehov',
        descriptionRows: [
            <span>Använder kylbatteriet till både värmning och kylning, vilket ger lägre lufttryckfall och sparar fläktenergi.</span>,
            <span>Temperaturerna till batteriet kan hållas låga även vid maximalt värmebehov, vilket sänker energikostnaden.</span>,
            <span>Lägre energikostnad för huvudpump tack vare lägre flöde.</span>,
            <span>Flera alt.utföranden beroende på funktionskrav.</span>,
        ],
        prodlines: [
            PRODUCTLINE_STANDARD,
            PRODUCTLINE_FLEX,
            PRODUCTLINE_SMART,
            PRODUCTLINE_GREEN_I,
            PRODUCTLINE_GREEN_II,
        ],
    },
    "SRBX-63VK": {
        id: "SRBX-63VK",
        header: '',
        shuntFamily: PRODUCTFAMILY_SR,
        name: "SRBX-63VK",
        plModName: "SRBX-63VK",
        primText: '',
        prodLink: SHUNT_SRBX_63VK_LINK,
        applicationInfoText: 'Kyla+Värme',
        applicationInfoSubText: '- Ventilation, golvvärme och andra system/maskiner med både kyl- och värmebehov',
        descriptionRows: [
            <span>Använder kylbatteriet till både värmning och kylning, vilket ger lägre lufttryckfall och sparar fläktenergi.</span>,
            <span>Temperaturerna till batteriet kan hållas låga även vid maximalt värmebehov, vilket sänker energikostnaden.</span>,
            <span>Lägre energikostnad för huvudpump tack vare lägre flöde.</span>,
            <span>Flera alt.utföranden beroende på funktionskrav.</span>,
        ],
        prodlines: [
            PRODUCTLINE_STANDARD,
            PRODUCTLINE_FLEX,
            PRODUCTLINE_SMART,
            PRODUCTLINE_GREEN_I,
            PRODUCTLINE_GREEN_II,
        ],
    },
    "PRBX-6VK": {
        id: "PRBX-6VK",
        header: '',
        shuntFamily: PRODUCTFAMILY_PR,
        name: "PRBX-6VK",
        plModName: "PRBX-6VK",
        primText: '',
        prodLink: SHUNT_PRBX_6VK_LINK,
        applicationInfoText: 'Kyla+Värme',
        applicationInfoSubText: '- Ventilation, golvvärme och andra system/maskiner med både kyl- och värmebehov',
        descriptionRows: [
            <span>Använder kylbatteriet till både värmning och kylning, vilket ger lägre lufttryckfall och sparar fläktenergi.</span>,
            <span>Temperaturerna till batteriet kan hållas låga även vid maximalt värmebehov, vilket sänker energikostnaden.</span>,
            <span>Lägre energikostnad för huvudpump tack vare lägre flöde.</span>,
            <span>Flera alt.utföranden beroende på funktionskrav.</span>,
        ],
        prodlines: [
            PRODUCTLINE_STANDARD,
            PRODUCTLINE_FLEX,
            PRODUCTLINE_SMART,
            PRODUCTLINE_GREEN_I,
            PRODUCTLINE_GREEN_II,
        ],
    },
    "SRBX-6ECO": {
        id: "SRBX-6ECO",
        header: '',
        shuntFamily: PRODUCTFAMILY_SR,
        name: "SRBX-6ECO",
        plModName: "SRBX-6ECO",
        primText: '',
        prodLink: SHUNT_SRBX_6ECO_LINK,
        applicationInfoText: 'Econet-system',
        applicationInfoSubText: '- För utomhusplacerade aggregat, processmaskiner, osv. och som komplement till Fläkts Econet-system',
        descriptionRows: [
            <span></span>,
        ],
        prodlines: [
            PRODUCTLINE_STANDARD,
            PRODUCTLINE_FLEX,
            PRODUCTLINE_SMART,
            PRODUCTLINE_GREEN_I,
        ],
    },
    "SRBX-6VAV": {
        id: "SRBX-6VAV",
        header: '',
        shuntFamily: PRODUCTFAMILY_SR,
        name: "SRBX-6VÅV",
        plModName: "SRBX-6VÅV",
        primText: '',
        prodLink: SHUNT_SRBX_6VAV_LINK,
        applicationInfoText: 'Värme + återvinning',
        applicationInfoSubText: '- Ventilationsaggregat med värme och batteriåtervinning',
        descriptionRows: [
            <span></span>,
        ],
        prodlines: [
            PRODUCTLINE_STANDARD,
            PRODUCTLINE_FLEX,
            PRODUCTLINE_SMART,
            PRODUCTLINE_GREEN_I,
        ],
    },
    "SRBX-6SPETS": {
        id: "SRBX-6SPETS",
        header: '',
        shuntFamily: PRODUCTFAMILY_SR,
        name: "SRBX-6SPETS",
        plModName: "SRBX-6SPETS",
        primText: '',
        prodLink: SHUNT_SRBX_6SPETS_LINK,
        applicationInfoText: 'Spetsvärme',
        applicationInfoSubText: '- Ventilation, golvvärme och andra system, som primärt använder överskottsvärme från andra processer',
        descriptionRows: [
            <span>Inbyggd växlare möjliggör spetsvärmning om den återvunna överskottsvärmen inte räcker till.</span>,
        ],
        prodlines: [
            PRODUCTLINE_STANDARD,
            PRODUCTLINE_FLEX,
            PRODUCTLINE_SMART,
            PRODUCTLINE_GREEN_I,
        ],
    },
    "SRBX-6NOD": {
        id: "SRBX-6NOD",
        header: '',
        shuntFamily: PRODUCTFAMILY_SR,
        name: "SRBX-6NÖD",
        plModName: "SRBX-6NÖD",
        primText: '',
        prodLink: SHUNT_SRBX_6NOD_LINK,
        applicationInfoText: 'Nödkyla',
        applicationInfoSubText: '- Kylning av batterier, rumskylare, MR-kameror och andra objekt med höga krav på driftsäkerhet',
        descriptionRows: [
            <span>Inbyggd växlare för tappvatten kyler sekundärsystemet om den vanliga kylan uteblir.</span>,
        ],
        prodlines: [
            PRODUCTLINE_STANDARD,
            PRODUCTLINE_FLEX,
            PRODUCTLINE_SMART,
            PRODUCTLINE_GREEN_I,
        ],
    },
    "SRBX-6VAK": {
        id: "SRBX-6VAK",
        header: '',
        shuntFamily: PRODUCTFAMILY_SR,
        name: "SRBX-6VÅK",
        plModName: "SRBX-6VÅK",
        primText: '',
        prodLink: SHUNT_SRBX_6VAK_LINK,
        applicationInfoText: 'Kyla + Återvinning',
        applicationInfoSubText: '- Ventilationssystem med kyla och återvinning i gemensamt batteri',
        descriptionRows: [
            <span>Kylning med högre vätsketemperaturer jämfört med traditionellt kylbatteri vilket är fördelaktigt vid fjärrkyla.</span>,
            <span>Mindre kondens i batteriet sparar kyleffekt.</span>,
            <span>Färre batterier ger lägre lufttryckfall och sparar fläktenergi.</span>,
        ],
        prodlines: [
            PRODUCTLINE_STANDARD,
            PRODUCTLINE_FLEX,
            PRODUCTLINE_SMART,
            PRODUCTLINE_GREEN_I,
        ],
    },
    "SRBX-6VAK-Forbi": {
        id: "SRBX-6VAK-Forbi",
        header: '',
        shuntFamily: PRODUCTFAMILY_SR,
        name: "SRBX-6VÅK/Förbigång",
        plModName: "SRBX-6VÅK/Förbigång",
        primText: '',
        prodLink: SHUNT_SRBX_6VAK_FORBI_LINK,
        applicationInfoText: 'Kyla + Återvinning med förbigång',
        applicationInfoSubText: '- Ventilationssystem med kyla och återvinning i gemensamt batteri vid höga krav på driftsäkerhet eller låg verkningsgrad på återvinningen',
        descriptionRows: [
            <span>Kylning med högre vätsketemperaturer jämfört med traditionellt kylbatteri vilket är fördelaktigt vid fjärrkyla.</span>,
            <span>Mindre kondens i batteriet sparar kyleffekt.</span>,
            <span>Färre batterier ger lägre lufttryckfall och sparar fläktenergi.</span>,
            <span>Driftstörningar på grund av frysrisk i vvx förhindras tack vare förbigångsventilerna som gör att vätskan endast passerar växlaren vid kylbehov.</span>,
        ],
        prodlines: [
            PRODUCTLINE_STANDARD,
            PRODUCTLINE_FLEX,
            PRODUCTLINE_SMART,
            PRODUCTLINE_GREEN_I,
        ],
    },
    "SRBX-8VAKK": {
        id: "SRBX-8VAKK",
        header: '',
        shuntFamily: PRODUCTFAMILY_SR,
        name: "SRBX-8VÅKK",
        plModName: "SRBX-8VÅKK",
        primText: '',
        prodLink: SHUNT_SRBX_8VAKK_LINK,
        applicationInfoText: 'Värme + kyla + återvinning + avblåsning',
        applicationInfoSubText: '- Ventilationsaggregat med kyla, värme och batterivärmeåtervinning i gemensamt batteri samt avblåsning av kylkondensor',
        descriptionRows: [
            <span>Kylning med högre vätsketemperaturer jämfört med traditionellt kylbatteri vilket är fördelaktigt vid fjärrkyla.</span>,
            <span>Mindre kondens i batteriet sparar kyleffekt.</span>,
            <span>Färre batterier ger lägre lufttryckfall och sparar fläktenergi.</span>,
        ],
        prodlines: [
            PRODUCTLINE_STANDARD,
            PRODUCTLINE_FLEX,
            PRODUCTLINE_SMART,
            PRODUCTLINE_GREEN_I,
        ],
    },
    "SRBX-8VAKK-Forbi": {
        id: "SRBX-8VAKK-Forbi",
        header: '',
        shuntFamily: PRODUCTFAMILY_SR,
        name: "SRBX-8VÅKK/Förbigång",
        plModName: "SRBX-8VÅKK/Förbigång",
        primText: '',
        prodLink: SHUNT_SRBX_8VAKK_FORBI_LINK,
        applicationInfoText: 'Värme + kyla + återvinning med förbigång + avblåsning',
        applicationInfoSubText: '- Ventilationsaggregat med kyla, värme och batterivärmeåtervinning i gemensamt batteri samt avblåsning av kylkondensor',
        descriptionRows: [
            <span>Kylning med högre vätsketemperaturer jämfört med traditionellt kylbatteri vilket är fördelaktigt vid fjärrkyla.</span>,
            <span>Mindre kondens i batteriet sparar kyleffekt.</span>,
            <span>Färre batterier ger lägre lufttryckfall och sparar fläktenergi.</span>,
            <span>Driftstörningar på grund av frysrisk i vvx förhindras tack vare förbigångsventilerna som gör att vätskan endast passerar växlaren vid kylbehov.</span>,
        ],
        prodlines: [
            PRODUCTLINE_STANDARD,
            PRODUCTLINE_FLEX,
            PRODUCTLINE_SMART,
            PRODUCTLINE_GREEN_I,
        ],
    },
    "PBCS": {
        id: "PBCS",
        header: '',
        shuntFamily: 'others',
        name: "PBCS",
        plModName: "PBCS",
        primText: '',
        prodLink: SHUNT_PBCS_LINK,
        applicationInfoText: 'Centralshuntgrupp',
        applicationInfoSubText: '- För energieffektivitet i energicentraler, kylcentraler och system med många funktioner och/eller kretsar',
        descriptionRows: [
            <span>Verkar som ”nav” i systemet och fördelar flödet till anläggningens olika kretsar.</span>,
        ],
        prodlines: [
            PRODUCTLINE_STANDARD,
            PRODUCTLINE_GREEN_I,
        ],
    },
};
