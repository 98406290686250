export const OverviewPanels = {
    devPanel: 'devPanel',
    shuntPanel: 'shuntPanel',
    shuntSpecPanel: 'shuntSpecPanel',
    modifySpecPanel: 'modifySpecPanel',
    systemSpecOverview: 'systemSpecOverview',
    priceCalcOverview: 'priceCalcOverview',
    sessionHistoryPanel: 'sessionHistoryPanel',
    myProfilePanel: 'myProfilePanel',
    newSessionPanel: 'newSessionPanel',
}