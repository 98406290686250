const { ref, serverTimestamp, update } = require("firebase/database");
const { SESSION_PATH } = require("../../constants/firebasePaths");


const savePluginData = function savePluginData(database, sessionID, plugin, version, method) {
    const sessionRef = ref(database, SESSION_PATH + "/" + sessionID);

    const snapData = {
        plugin: plugin,
        pluginVersion: version,
        pluginMethod: method,
        created: serverTimestamp()
    }

    return Promise.all([update(sessionRef, snapData)]);
}

const setPluginSessionCompleted = function setPluginSessionCompleted(database, sessionID, isCompleted) {
    const sessionRef = ref(database, SESSION_PATH + "/" + sessionID);

    const snapData = {
        isCompleted: isCompleted,
        completedTime: serverTimestamp(),
    }

    return Promise.all([update(sessionRef, snapData)]);
}

export { savePluginData, setPluginSessionCompleted }