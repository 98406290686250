import { doc, getDoc } from "firebase/firestore";
import { PRODUCTFAMILY_PR, PRODUCTFAMILY_SR, PRODUCTFAMILY_VA  } from "../../../constants/productlineConstants";

const getPriceCouplingValue = async function getPriceCouplingValue(firestore, prodFamily, dim, material) {
    let couplingDoc;

    if (prodFamily === PRODUCTFAMILY_SR || prodFamily === PRODUCTFAMILY_PR) {
        couplingDoc = doc(firestore, "/PriceValues/PipeCoupling/options/" + material + "/prodFamily/SR_PR");
    }
    else if (prodFamily === PRODUCTFAMILY_VA) {
        couplingDoc = doc(firestore, "/PriceValues/PipeCoupling/options/" + material + "/prodFamily/VA");
    }
    else {
        console.error("Not accepted prodfamily: ", prodFamily);
        return;
    }

    const res = await getDoc(couplingDoc)
    
    //console.log("Res:", dim, res.data().basePrice[dim], res.data().enumFactor);
    return Math.round(Number(res.data().basePrice[dim] * Number(res.data().enumFactor)));
}

export { getPriceCouplingValue }