import { httpsCallable } from "firebase/functions"

const deleteImage = async function deleteImage(functions, sessionID, shuntID) {
    functions.region = 'europe-west1';
    const deleteImage = httpsCallable(functions, "deleteImage");

    return deleteImage({
        sessionID: sessionID,
        shuntID: shuntID,
    });
}

export { deleteImage }