import { ref, set } from "firebase/database";
import React, { createContext, useReducer, useContext } from "react";
import { SESSION_SHUNT_PATH, SHUNT_PUMPDATA_PUMPREQUESTTYPE_PATH } from "../constants/firebasePaths";
import { PUMP_ALARM, PUMP_CARD, PUMP_EXTERNAL_STOP, PUMP_GENERAL_REQUEST, PUMP_PRESSURE, PUMP_REQUEST_TYPE, PUMP_SPECIFIC_REQUEST, PUMP_STEERING, PUMP_SYSTEMFLUID } from "../constants/keys/pumpKeys";
import { PUMPDATA_PARAMS } from "../constants/sessionStoragePaths";


const PumpParams = createContext(undefined);

function pumpParamsReducer(state, action) {
    switch (action.type) {

        case 'setPumpRequestType': {
            let data = {};
            if (sessionStorage.getItem(PUMPDATA_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(PUMPDATA_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                const fieldRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_PUMPDATA_PUMPREQUESTTYPE_PATH);
                set(fieldRef, action.payload).then(() => {
                    //console.log("Added to fb!");
                }).catch((error) => {
                    console.error("Error writing to FB: ", error);
                });
            }

            data[PUMP_REQUEST_TYPE] = action.payload;
            sessionStorage.setItem(PUMPDATA_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setGeneralPumpRequest': {
            let data = {};
            if (sessionStorage.getItem(PUMPDATA_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(PUMPDATA_PARAMS));
            }

            data[PUMP_GENERAL_REQUEST] = action.payload;
            sessionStorage.setItem(PUMPDATA_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setSystemfluid': {
            let data = {};
            if (sessionStorage.getItem(PUMPDATA_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(PUMPDATA_PARAMS));
            }

            data[PUMP_SYSTEMFLUID] = action.payload;
            sessionStorage.setItem(PUMPDATA_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setSpecificPumpRequest': {
            let data = {};
            if (sessionStorage.getItem(PUMPDATA_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(PUMPDATA_PARAMS));
            }

            data[PUMP_SPECIFIC_REQUEST] = action.payload;
            sessionStorage.setItem(PUMPDATA_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setSteering': {
            let data = {};
            if (sessionStorage.getItem(PUMPDATA_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(PUMPDATA_PARAMS));
            }

            data[PUMP_STEERING] = action.payload;
            sessionStorage.setItem(PUMPDATA_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setAlarm': {
            let data = {};
            if (sessionStorage.getItem(PUMPDATA_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(PUMPDATA_PARAMS));
            }

            data[PUMP_ALARM] = action.payload;
            sessionStorage.setItem(PUMPDATA_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setExternalStop': {
            let data = {};
            if (sessionStorage.getItem(PUMPDATA_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(PUMPDATA_PARAMS));
            }

            data[PUMP_EXTERNAL_STOP] = action.payload;
            sessionStorage.setItem(PUMPDATA_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setCard': {
            let data = {};
            if (sessionStorage.getItem(PUMPDATA_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(PUMPDATA_PARAMS));
            }

            data[PUMP_CARD] = action.payload;
            sessionStorage.setItem(PUMPDATA_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setPressure': {
            let data = {};
            if (sessionStorage.getItem(PUMPDATA_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(PUMPDATA_PARAMS));
            }

            data[PUMP_PRESSURE] = action.payload;
            sessionStorage.setItem(PUMPDATA_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setDataFromSnapShot': {
            let data = {};

            data[PUMP_REQUEST_TYPE] = action.payload.child(PUMP_REQUEST_TYPE).val() || undefined;
            data[PUMP_GENERAL_REQUEST] = action.payload.child(PUMP_GENERAL_REQUEST).val() || undefined;
            data[PUMP_SYSTEMFLUID] = action.payload.child(PUMP_SYSTEMFLUID).val() || undefined;
            data[PUMP_SPECIFIC_REQUEST] = action.payload.child(PUMP_SPECIFIC_REQUEST).val() || undefined;
            data[PUMP_STEERING] = action.payload.child(PUMP_STEERING).val() || undefined;
            data[PUMP_ALARM] = action.payload.child(PUMP_ALARM).val() || undefined;
            data[PUMP_EXTERNAL_STOP] = action.payload.child(PUMP_EXTERNAL_STOP).val() || undefined;
            data[PUMP_PRESSURE] = action.payload.child(PUMP_PRESSURE).val() || undefined;
            data[PUMP_CARD] = action.payload.child(PUMP_CARD).val() || undefined;

            //console.log("Loading Data:", data);
            sessionStorage.setItem(PUMPDATA_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'clear': {
            sessionStorage.removeItem(PUMPDATA_PARAMS);
            let obj = { pumpRequestType: "generalRequest", generalPumpRequest: "premasChoice" }
            sessionStorage.setItem(PUMPDATA_PARAMS, JSON.stringify(obj));

            return { val: obj }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function PumpProvider({ children }) {
    let obj = {};
    if (sessionStorage.getItem(PUMPDATA_PARAMS) != null) {
        obj = JSON.parse(sessionStorage.getItem(PUMPDATA_PARAMS));
    }
    else {
        obj = { pumpRequestType: "generalRequest", generalPumpRequest: "premasChoice" }
        sessionStorage.setItem(PUMPDATA_PARAMS, JSON.stringify(obj));
    }

    const [state, dispatch] = useReducer(pumpParamsReducer, { val: obj })
    const value = { state, dispatch }

    return (
        <PumpParams.Provider value={value}>
            {children}
        </PumpParams.Provider>
    );
}

function usePumpParams() {
    const context = useContext(PumpParams)
    if (context === undefined) {
        throw new Error('usePumpParams must be used within a PumpParams')
    }
    return context
}


export { PumpProvider, usePumpParams };