import { Button, DialogContent, Divider, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../utils/bootstrapDialog";
import { useAdjustParams, useProductLineParams, useSessionParams, useShuntParams } from "../../../../contexts";
import { AdjustmentsEnum, ProductLineEnum, SessionParamsEnum, ShuntParamsEnum } from "../../../../enums";
import { MIXINGVESSEL_1000L, MIXINGVESSEL_100L, MIXINGVESSEL_200L, MIXINGVESSEL_300L, MIXINGVESSEL_500L, MIXINGVESSEL_60L, NO_MIXINGVESSEL } from "../../../../constants/adjustmentsOptions";
import { useState } from "react";
import { useDatabase } from "reactfire";

const options = [MIXINGVESSEL_60L, MIXINGVESSEL_100L, MIXINGVESSEL_200L, MIXINGVESSEL_300L, MIXINGVESSEL_500L, MIXINGVESSEL_1000L]

export const AddMixingVesselModal = ({ open, handleClose, shuntList }) => {
    const adjustParams = useAdjustParams();
    const sessionParams = useSessionParams();
    const database = useDatabase();
    const shuntParams = useShuntParams();
    const productLine = useProductLineParams();

    const [selected, setSelected] = useState('')
    const handleModalApprove = () => {
        AdjustmentsEnum.MixingVessels.setContextValue(adjustParams, selected);
        let addonData = {
            size: selected,
        }

        SessionParamsEnum.createShuntAddon(sessionParams, database, 'mixingVessel', shuntParams, ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams), ProductLineEnum.ProductLine.getContextValue(productLine), shuntList, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams), addonData);

        handleClose();
        setSelected('');
    }

    const handleModalDecline = () => {
        AdjustmentsEnum.MixingVessels.setContextValue(adjustParams, NO_MIXINGVESSEL);
        handleClose();
        setSelected('');
    }

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="dialog-for-mixingvessel"
            open={open}
        >
            <BootstrapDialogTitle component="h3" sx={{ paddingLeft: '20px', paddingTop: '0px', paddingBottom: '0px' }} onClose={handleClose}>
                <h3 className="header-title">Glykolblandningskärl</h3>
            </BootstrapDialogTitle>
            <DialogContent>
                <Typography gutterBottom style={{ display: 'flex', justifyContent: 'left', marginBottom: '10px' }}>
                    Önskas glykolblandningskärl till återvinningssystemet?
                </Typography>
                <Divider style={{ marginBottom: '10px' }} />

                <Stack sx={{ marginBottom: '10px', width: '550px', marginLeft: '7px' }} direction={'row'}>
                    <Button disabled={selected == null || selected === ""} variant="outlined" size="small" onClick={handleModalApprove} sx={{ width: '270px', textTransform: 'none' }}>
                        <strong>Ja</strong>, med volym:
                    </Button>

                    <Stack direction={"column"}>
                        <FormControl style={{ marginLeft: "10px", width: "270px", height: "33px" }}>
                            <InputLabel>
                                Välj Volym
                            </InputLabel>
                            <Select
                                label={"Välj Volym"}
                                sx={{height: "33px"}}
                                value={selected}
                                onChange={(event) => { setSelected(event.target.value) }}
                            >
                                {options.map((option, index) => {
                                    return (<MenuItem key={index} value={option}>{option}</MenuItem>)
                                })}
                            </Select>
                        </FormControl>
                    </Stack>
                </Stack>

                <Button variant="outlined" size="small" onClick={handleModalDecline} sx={{ width: '550px', marginLeft: '7px', textTransform: 'none' }}>
                    <strong>Nej</strong>, gå vidare utan blandningskärl
                </Button>
            </DialogContent>
        </BootstrapDialog>
    );
}