import { Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { InspectPageHeader, InspectPageMixingVesselData } from "../inspectPage/components";
import { InspectPageCustomShuntData } from "../inspectPage/components/inspectPageCustomShuntData";
import { InspectPageCustomTechData } from "../inspectPage/components/inspectPageCustomTechData";
import { SidebarImageShuntData } from "../overview/components/imageDisplayShuntData";
import { BootstrapDialog } from "../../utils/bootstrapDialog";
import { AddShuntRowModal } from "../customShunt/components/shuntRow";
import { LoadingShuntSpecSkeleton } from "./components/loadingSkeleton";
import { useProductLineParams, useShuntParams, useCalcParams, useAdjustParams, usePumpParams, useSessionParams, useDriftParams } from "../../../contexts";
import { useGetShuntData } from "../../../hooks/useGetShuntData";
import { InspectPageMagicloudComponent } from "../inspectPage/components/inspectPageMagicloudComponent";
import { InspectPageMixingVesselRow } from "../inspectPage/components/inspectPageMixingVesselRow";
import { InspectPageViewComments } from "../inspectPage/components/inspectPageViewComments";

export const ShuntSpecPage = ({ shuntID }) => {
    const productLineParams = useProductLineParams();
    const shuntParams = useShuntParams();
    const calcParams = useCalcParams();
    const adjustParams = useAdjustParams();
    const pumpParams = usePumpParams();
    const sessionParams = useSessionParams();
    const driftParams = useDriftParams();

    const [openModalCustom, setOpenModalCustom] = useState(false);
    const [update, setUpdate] = useState(false);
    const { shuntData, loading } = useGetShuntData(shuntID);

    const handleModalCustomOpen = () => { setOpenModalCustom(true) }
    const handleModalCustomClose = () => {
        setUpdate(!update);
        setOpenModalCustom(false)
    }

    const [editSpec, setEditSpec] = useState(false);

    const handleSwitchEditSpec = () => {
        setEditSpec(!editSpec);
    }


    return (
        <>
            <Paper elevation={0} style={{ minWidth: '805px', borderRadius: 0, boxShadow: "2px 2px 10px #0055a030", }}>
                <Grid container>
                    {loading ? (
                        <LoadingShuntSpecSkeleton />
                    ) : (
                        <>
                            <Grid item xs={7.6}>
                                {shuntData?.Baseinfo?.isCustom === true ? (
                                    <div>
                                        <Paper elevation={0} style={{ maxWidth: '475px', justifyContent: 'center', padding: '10px 20px', marginTop: '10px', height: 'fit-content' }}>
                                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                                <InspectPageHeader useCurrent={false} shuntData={shuntData} />
                                                {/* 
                                                {user?.data?.isAnonymous !== true ? (
                                                    <IconButton size="small" onClick={handleSwitchEditSpec}>
                                                        <EditIcon />
                                                    </IconButton>
                                                ) : ("")}
                                                */}
                                            </Stack>

                                            <Divider />
                                            <InspectPageCustomShuntData useCurrent={false} shuntData={shuntData} />
                                            <Divider />
                                            <InspectPageCustomTechData shuntData={shuntData} />

                                            <InspectPageViewComments shuntData={shuntData} />
                                        </Paper>
                                    </div>
                                ) : (
                                    <div>
                                        {shuntData?.ShuntData?.isAddon === true ? (
                                            <Paper elevation={0} style={{ width: '475px', justifyContent: 'center', padding: '10px 20px', marginTop: '10px', height: 'fit-content' }}>
                                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                                    <Stack direction={'row'} spacing={1} justifyContent={"flex-start"} alignItems={"center"} style={{ paddingBottom: "5px" }}>
                                                        <Typography component={'h3'}>
                                                            <strong><u>Pos {shuntData?.Baseinfo?.position}, Glykolblandningskärl</u></strong>
                                                        </Typography>
                                                    </Stack>
                                                </Stack>


                                                <Divider />
                                                <InspectPageMixingVesselRow objData={shuntData} component="mixingVessel" />
                                                <InspectPageMixingVesselRow objData={shuntData} component="pump" />
                                                <InspectPageMixingVesselRow objData={shuntData} component="connHose" />
                                                <InspectPageMixingVesselRow objData={shuntData} component="hole" />

                                                <InspectPageViewComments shuntData={shuntData} />
                                            </Paper>
                                        ) : (
                                            <Paper elevation={0} style={{ width: '475px', justifyContent: 'center', padding: '10px 20px', marginTop: '10px', height: 'fit-content' }}>
                                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                                    <InspectPageHeader useCurrent={false} shuntData={shuntData} />

                                                </Stack>
                                                <Divider />
                                                <InspectPageCustomShuntData useCurrent={false} shuntData={shuntData} />
                                                <Divider />
                                                <InspectPageCustomTechData shuntData={shuntData} />
                                                <InspectPageMixingVesselData />

                                                <InspectPageViewComments shuntData={shuntData} />
                                            </Paper>
                                        )}
                                    </div>
                                )}

                            </Grid>
                            <Grid item xs={4}>
                                <SidebarImageShuntData shuntData={shuntData} />
                                <InspectPageMagicloudComponent />

                            </Grid>
                            <Grid item xs={12}>

                            </Grid>
                        </>
                    )}
                </Grid>
            </Paper>

            <BootstrapDialog
                onClose={handleModalCustomClose}
                aria-labelledby="customized-dialog-title"
                open={openModalCustom}
            >
                <AddShuntRowModal handleModalClose={handleModalCustomClose} isEdit={true} shuntObj={shuntData} />
            </BootstrapDialog>
        </>

    )
}