import { IconButton } from "@mui/material"
import { useState } from "react";

import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { useFunctions } from "reactfire";
import { useContextAuth } from "../../../../authContext/AuthHook";
import { setIsStarred } from "../../../../firebase/functions/setIsStarred";


export const StarredField = ({ value, row }) => {
    const getInitialValue = () => {
        if (value != null) {
            return value;
        }
        else {
            return false;
        }
    }
    const [starred, setStarred] = useState(getInitialValue);
    const { user } = useContextAuth();
    const functions = useFunctions();

    const starSession = () => {
        console.log("Star it!", row.id, user.id)
        if (row.id != null && row.id !== "" && user.id != null && user.id !== "") {
            setIsStarred(functions, row.id, true).then(() => {
                console.log("Set!")
            })
            setStarred(true);
        }
    }

    const unStarSession = () => {
        console.log("Unstar it!", row.id, user.id)
        if (row.id != null && row.id !== "" && user.id != null && user.id !== "") {
            setIsStarred(functions, row.id, false).then(() => {
                console.log("Set!")
            })
            setStarred(false);
        }
    }

    if (starred != null) {
        if (starred === true) {
            return (
                <IconButton onClick={unStarSession} size="small">
                    <StarRoundedIcon htmlColor="#ebe300" />
                </IconButton>
            )
        }
        else {
            return (
                <IconButton onClick={starSession} size="small">
                    <StarBorderRoundedIcon />
                </IconButton>
            )
        }
    }
    return (
        <IconButton onClick={starSession} size="small">
            <StarBorderRoundedIcon />
        </IconButton>
    )
}