import { FormLabel, FormControl, FormControlLabel, FormGroup, Checkbox } from "@mui/material"
import { QUESTION_DYNAMIC_CONTROL_VALVE_KEY, QUESTION_LIMITED_SPACE_KEY, QUESTION_STAINLESS_KEY } from "../../../../constants/wizardConstants"


export const SpecialCircumstancesQuestion = ({ stainless, limitedSpace, dynamicControlValve, answerQuestion }) => {

    return (
        <FormControl margin={'dense'} variant="standard">
            <FormLabel>Särskilda omständigheter</FormLabel>
            <FormGroup>
                <FormControlLabel
                    sx={{ height: '28px' }}
                    control={
                        <Checkbox checked={stainless} onChange={() => answerQuestion(QUESTION_STAINLESS_KEY, !stainless)} />
                    }
                    label={'Korrosiv miljö eller systemvätska'}
                />
                <FormControlLabel
                    sx={{ height: '28px' }}
                    control={
                        <Checkbox checked={limitedSpace} onChange={() => answerQuestion(QUESTION_LIMITED_SPACE_KEY, !limitedSpace)} />
                    }
                    label={'Mycket begränsat utrymme'}
                />
                <FormControlLabel
                    sx={{ height: '28px' }}
                    control={
                        <Checkbox checked={dynamicControlValve} onChange={() => answerQuestion(QUESTION_DYNAMIC_CONTROL_VALVE_KEY, !dynamicControlValve)} />
                    }
                    label={'Dynamisk/tryckoberoende styrventil'}
                />
            </FormGroup>
        </FormControl>
    )
}