import { ref, set } from "firebase/database";
import { SESSION_SHUNT_PATH, SHUNT_BASEINFO, SHUNT_SHUNTDATA } from "../constants/firebasePaths";
import { CONTROLVALVE_KEY, ADJVALVE_KEY, DESIGNATION_KEY, POSITION_KEY, PL_MODEL_KEY, NUM_BV_KEY, NUM_IV_KEY, HAS_LATCH_KEY, IS_SABO_KEY, IS_MINI_KEY, CV_MODEL_KEY, CV_KVS_KEY, FLEX_BRAND_KEY, FLEX_MODEL_KEY, FLEX_DIM_ID_KEY, SMART_DIM_ID_KEY, SHUNT_STRING_KEY, SMART_MODEL_KEY, MINI_INCOMPATIBLE_KEY, SMART_DIM_KEY, CV_IS_INTERVAL_KEY, HAS_PREV_LATCH_KEY, MC_FUNCTION_KEY, IS_CUSTOM_KEY, EXCHANGER_KEY, NUM_CONN_KEY, MAGICLOUD_LINK_KEY, IS_ADDON_KEY, ADDON_TYPE_KEY, ADDONS_KEY, ADDON_DATA_KEY, CONNECTED_ENERGY_MODEL_KEY, CONNECTED_ENERGY_COMM_MODAL_KEY } from "../constants/keys/shuntKeys";
import { ContextEnum } from "./contextEnum";

export class ShuntParamsEnum extends ContextEnum {
    static ControlValve = new ShuntParamsEnum(CONTROLVALVE_KEY, "setControlValve");
    static CVName = new ShuntParamsEnum(CONTROLVALVE_KEY, "setCVName");
    static AdjustmentValve = new ShuntParamsEnum(ADJVALVE_KEY, "setAdjValve");
    static ShuntTypeData = new ShuntParamsEnum("shuntTypeData", "setShuntTypeData");

    static ShuntDesignation = new ShuntParamsEnum(DESIGNATION_KEY, "setDesignation");
    static ShuntPosition = new ShuntParamsEnum(POSITION_KEY, "setPosition");
    static PLModel = new ShuntParamsEnum(PL_MODEL_KEY, "setPLModel");
    static NumBV = new ShuntParamsEnum(NUM_BV_KEY, "setNumBV");
    static NumIV = new ShuntParamsEnum(NUM_IV_KEY, "setNumIV");
    static HasLatch = new ShuntParamsEnum(HAS_LATCH_KEY, "setHasLatch");
    static HasPrevLatch = new ShuntParamsEnum(HAS_PREV_LATCH_KEY, "setPrevHasLatch");
    static IsSabo = new ShuntParamsEnum(IS_SABO_KEY, "setIsSabo");
    static IsMini = new ShuntParamsEnum(IS_MINI_KEY, "setIsMini");
    static IsMiniIncompatible = new ShuntParamsEnum(MINI_INCOMPATIBLE_KEY, "setMiniIncompatible");
    static IsCustomShunt = new ShuntParamsEnum(IS_CUSTOM_KEY, "setIsCustom");
    static Exchanger = new ShuntParamsEnum(EXCHANGER_KEY, 'setExchanger');
    static NumConnections = new ShuntParamsEnum(NUM_CONN_KEY, 'setNumConn');

    static CV_Model = new ShuntParamsEnum(CV_MODEL_KEY, "setCVModel");
    static CV_Kvs = new ShuntParamsEnum(CV_KVS_KEY, "setCVKvs");
    static CV_IsInterval = new ShuntParamsEnum(CV_IS_INTERVAL_KEY, "setCVIsInterval");

    static IsAddon = new ShuntParamsEnum(IS_ADDON_KEY, "setIsAddon");
    static AddonData = new ShuntParamsEnum(ADDON_DATA_KEY, "setAddonData")
    static AddonType = new ShuntParamsEnum(ADDON_TYPE_KEY, "setAddonType");

    static Addons = new ShuntParamsEnum(ADDONS_KEY, "setAddons");
    
    static FlexBrand = new ShuntParamsEnum(FLEX_BRAND_KEY, "setFlexBrand");
    static FlexModel = new ShuntParamsEnum(FLEX_MODEL_KEY, "setFlexModel");
    static FlexDimID = new ShuntParamsEnum(FLEX_DIM_ID_KEY, "setFlexDimID");

    static SmartModel = new ShuntParamsEnum(SMART_MODEL_KEY, "setSmartModel");
    static SmartDimID = new ShuntParamsEnum(SMART_DIM_ID_KEY, "setSmartDimID");
    static SmartDim = new ShuntParamsEnum(SMART_DIM_KEY, "setSmartDim");

    static ConnectedEnergyModel = new ShuntParamsEnum(CONNECTED_ENERGY_MODEL_KEY, "setConnectedEnergyModel");
    static ConnectedEnergyCommModal = new ShuntParamsEnum(CONNECTED_ENERGY_COMM_MODAL_KEY, "setConnectedEnergyCommModal");

    static MagicloudLink = new ShuntParamsEnum(MAGICLOUD_LINK_KEY, "setMagicloudLink");

    static ShuntNomenklaturString = new ShuntParamsEnum(SHUNT_STRING_KEY, "setShuntNomenklaturString");
    static UpdateValues = new ShuntParamsEnum("updateValues", "setUpdateValues");

    static MCFunction = new ShuntParamsEnum(MC_FUNCTION_KEY, 'setMCFunction');

    setToFirebase(database, shuntID, value) {
        if (this.name === DESIGNATION_KEY || this.name === POSITION_KEY) {
            const dbRef = ref(database, SESSION_SHUNT_PATH + shuntID + SHUNT_BASEINFO + this.name);
            return set(dbRef, value);
        }
        else {
            const dbRef = ref(database, SESSION_SHUNT_PATH + shuntID + SHUNT_SHUNTDATA + this.name);
            return set(dbRef, value);
        }
    }

    static updateAddonValues = function updateAddonValues(context, database, sessionID, addonID, addonData, position = null) {
        context.dispatch({ type: "updateShuntAddon", payload: addonID, database: database, sessionID: sessionID, position: position, addonData: addonData });
    }

    static fullClearData = function fullClearData(context) {
        context.dispatch({ type: "fullClear" });
    }

    static apiClearData = function apiClearData(context) {
        context.dispatch({ type: "clearAPI" });
    }
}