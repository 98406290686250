import { Button, DialogActions, DialogContent, Stack, Typography } from "@mui/material"
import { BootstrapDialog, BootstrapDialogTitle } from "../../utils/bootstrapDialog"
import { useState } from "react";
import { NewSessionOptions } from "./newSessionOptions";


export const ConfirmOpenNewSession = ({ sessionsID, open, handleClose }) => {
    const [openOptions, setOpenOptions] = useState(false);
    const handleOpenOptions = () => setOpenOptions(true);
    const handleCloseOptions = () => setOpenOptions(false);

    const handleConfirm = () => {
        handleOpenOptions();

        handleClose();
    }

    return (
        <>
            <BootstrapDialog
                style={{
                    minWidth: 540,
                    minHeight: 540,
                }}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >

                <BootstrapDialogTitle component="h3" sx={{ paddingLeft: '20px', paddingTop: '0px', paddingBottom: '0px' }} onClose={handleClose}>
                    <h3 className="header-title">Växla session?</h3>
                </BootstrapDialogTitle>
                <DialogContent>
                    <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center', paddingBottom: '1px', width: 350 }}>
                        Genom att växla session så kommer du avsluta nuvarande session du har aktiv.
                    </Typography>
                    <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center', paddingBottom: '1px', width: 350 }}>
                        Fortsätt till {sessionsID}?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Stack direction={'row'} spacing={2}>
                        <Button variant="outlined" onClick={handleConfirm}>
                            Fortsätt
                        </Button>
                        <Button variant="outlined" onClick={handleClose}>
                            Avbryt
                        </Button>
                    </Stack>
                </DialogActions>
            </BootstrapDialog>


            <NewSessionOptions open={openOptions} handleClose={handleCloseOptions} sessionsID={sessionsID} />
        </>

    )
}