import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Collapse, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { collection } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const Row = ({ dim }) => {
    const [open, setOpen] = useState(false);
    //console.log("Dim: ", dim)
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.action.hover,
        // },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));



    const displayData = () => {
        let list = [];

        if (dim?.Y != null) {
            list.push({ title: "xx", val: dim?.Y + " mm" })
        }
        if (dim?.Y_H != null) {
            list.push({ title: "xx (H)", val: dim?.Y_H + " mm" })
        }
        if (dim?.h != null) {
            list.push({ title: "h", val: dim?.h + " mm" })
        }
        if (dim?.hh != null) {
            list.push({ title: "H", val: dim?.hh + " mm" })
        }
        if (dim?.I != null) {
            list.push({ title: "I", val: dim?.I + " mm" })
        }
        if (dim?.X != null) {
            list.push({ title: "x", val: dim?.X + " mm" })
        }
        if (dim?.djup != null) {
            list.push({ title: "Djup", val: dim?.djup + " mm" })
        }
        if (dim?.diameter != null) {
            list.push({ title: "c/c", val: dim?.diameter + " mm" })
        }
        
        if (dim?.vikt_IV010 != null) {
            list.push({ title: "Vikt (IV010)", val: dim?.vikt_IV010 + " kg" })
        }
        if (dim?.vikt_IV015 != null) {
            list.push({ title: "Vikt (IV015)", val: dim?.vikt_IV015 + " kg" })
        }
        if (dim?.vikt != null) {
            list.push({ title: "Vikt", val: dim?.vikt + " kg" })
        }

        if (dim?.vikt_IV010 != null) {
            list.push({ title: "Vikt green (IV010)", val: dim?.vikt_IV010_green + " kg" })
        }
        if (dim?.vikt_IV015 != null) {
            list.push({ title: "Vikt green (IV015)", val: dim?.vikt_IV015_green + " kg" })
        }
        if (dim?.vikt_green != null) {
            list.push({ title: "Vikt green", val: dim?.vikt_green + " kg" })
        }

        return list;
    }

    return (
        <>
            <StyledTableRow style={{ height: "35px" }}>
                <TableCell>{dim.NO_ID_FIELD}</TableCell>
                <TableCell align="center">
                    <Button
                        aria-label="expand row"
                        size="small"
                        className="models-btn"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        Mått-data
                    </Button>
                </TableCell>
            </StyledTableRow>
            <StyledTableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <Table size="small">
                                <TableHead>
                                    <StyledTableRow>
                                        <TableCell sx={{ width: "120px" }}>Fält</TableCell>
                                        <TableCell>Värde</TableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {displayData().map((obj) => {
                                        return (
                                            <StyledTableRow>
                                                <TableCell>{obj.title}</TableCell>
                                                <TableCell>{obj.val}</TableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </StyledTableRow>
        </>
    )
}

export const SystemSpecMeasurementData = () => {
    const firestore = useFirestore();

    const measurementDoc = collection(firestore, '/MeasurementData/');
    const firestoreRes = useFirestoreCollectionData(measurementDoc);

    const [measurementData, setMeasurementData] = useState([]);

    useEffect(() => {
        if (firestoreRes.data != null) {
            //console.log("Data: ", firestoreRes.data)
            setMeasurementData(firestoreRes.data);
        }

    }, [firestoreRes])

    return (
        <Box sx={{ padding: "20px" }}>
            <TableContainer component={Paper} sx={{ width: '800px' }}>
                <Table sx={{ paddingRight: '20px' }} size="small" aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Dimension</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {measurementData.map((dim) => {
                            return (
                                <Row dim={dim} />
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}