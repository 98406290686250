import { useState, useEffect } from 'react';
import { ref } from 'firebase/database';
import { useDatabase, useDatabaseObjectData } from 'reactfire';
import { Button, CircularProgress, DialogActions, DialogContent, Divider, Paper, Stack, Typography } from '@mui/material';
import { SESSION_SHUNT_PATH } from '../../../../constants/firebasePaths';
import { useSessionParams } from '../../../../contexts/SessionParams';
import { SessionParamsEnum } from '../../../../enums/sessionParamsEnum';
import { LoadNewShuntComponent } from '../../../utils/loadNewShuntComponent';
import { BootstrapDialog, BootstrapDialogTitle } from '../../../utils/bootstrapDialog';
import { deleteShunt } from '../../../../firebase/functions/deleteShunt';

export const FirebaseShuntDisplay = ({ shuntID, shortDesc, handleClose = null }) => {
    const database = useDatabase();
    const sessionParams = useSessionParams();

    const [openModal, setOpenModal] = useState(false);

    const shuntRef = ref(database, SESSION_SHUNT_PATH + shuntID);

    const response = useDatabaseObjectData(shuntRef);
    const { status, data } = response;

    const [fieldValue, setFieldValue] = useState({})

    const [openShunt, setOpenShunt] = useState(false);

    const selectShunt = () => {
        setOpenShunt(true);
    }

    const removeShunt = () => {
        setOpenModal(true);
    }

    const removeShuntApprove = async () => {
        await deleteShunt(database, shuntID, SessionParamsEnum.SessionID.getContextValue(sessionParams), sessionParams);
        setOpenModal(false);
    }

    const removeShuntDecline = () => {
        setOpenModal(false);
    }

    useEffect(() => {
        if (data != null) {
            setFieldValue(data);
        }
    }, [data]);

    if (status === 'loading') {
        return <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>;
    }

    const getStyle = () => {
        if (fieldValue["NO_ID_FIELD"] === SessionParamsEnum.CurrentShunt.getContextValue(sessionParams)) {
            return {
                padding: "14px",
                border: "2px solid #0055a0",
                width: "600px"
            }
        }
        else {
            return {
                padding: "14px",
                width: "600px",
            }
        }
    }

    if (shortDesc === false) {
        return (
            <Paper sx={{ padding: "14px", width: "600px", boxShadow: 3 }}>
                <Typography component={"h3"}>Shunt: {fieldValue["NO_ID_FIELD"]}</Typography>
                {Object.keys(fieldValue).map((key) => {
                    return (
                        <>
                            {key !== "NO_ID_FIELD" &&
                                <div key={key} style={{ padding: "10px" }}>
                                    {typeof fieldValue[key] === 'object' ? (
                                        <>
                                            <div style={{ marginBottom: "5px" }}><strong>{key}: </strong></div>
                                            {Object.keys(fieldValue[key]).map((subKey) => {
                                                return (
                                                    <><div><strong>{subKey}: </strong> {String(fieldValue[key][subKey])}</div></>
                                                )
                                            })}
                                        </>
                                    ) : (

                                        <><div><strong>{key}: </strong> {String(fieldValue[key])}</div><br /></>
                                    )}
                                    <br />
                                    <Divider orientation="horizontal" flexItem />
                                </div>
                            }
                        </>

                    )
                })}
            </Paper>
        );
    }
    else {
        return (
            <>
                <Paper style={getStyle()}>
                    <Typography component={"h3"}>Shunt: {fieldValue["NO_ID_FIELD"]}</Typography>

                    {fieldValue["Baseinfo"] != null ? (
                        <>
                            <br />
                            <Typography component={'div'}><strong>Designation: </strong>{fieldValue["Baseinfo"]["designation"]}</Typography>
                            <Typography component={'div'}><strong>Position: </strong>{fieldValue["Baseinfo"]["position"]}</Typography>
                        </>
                    ) : (
                        <>
                            <br />
                            <Typography component={'div'}><strong>Designation: </strong> Not set</Typography>
                            <Typography component={'div'}><strong>Position: </strong> Not set</Typography>
                        </>
                    )
                    }

                    {fieldValue["PLData"] != null &&
                        <>
                            <br />
                            <Typography component={'div'}><strong>ProduktPrincip: </strong>{fieldValue["PLData"]["princip"]}</Typography>
                            <Typography component={'div'}><strong>Produktlinje: </strong>{fieldValue["PLData"]["prodLine"]}</Typography>
                        </>
                    }

                    <Stack direction={'row'} justifyContent="flex-end" spacing={2}>
                        <Button variant='outlined'
                            disabled={fieldValue["NO_ID_FIELD"] === SessionParamsEnum.CurrentShunt.getContextValue(sessionParams)} onClick={selectShunt}>Välj</Button>

                        <Button variant='outlined'
                            disabled={fieldValue["NO_ID_FIELD"] === SessionParamsEnum.CurrentShunt.getContextValue(sessionParams)} onClick={removeShunt}>Ta bort</Button>
                    </Stack>

                    {openShunt && <LoadNewShuntComponent shuntID={fieldValue["NO_ID_FIELD"]} setOpen={setOpenShunt} handleClose={handleClose} />}
                </Paper>

                <BootstrapDialog
                    onClose={removeShuntDecline}
                    aria-labelledby="customized-dialog-title"
                    open={openModal}
                >
                    <BootstrapDialogTitle onClose={removeShuntDecline}>
                        Ta bort {fieldValue["Baseinfo"] != null ? (<>{fieldValue["Baseinfo"]["position"]} - {fieldValue["Baseinfo"]["designation"] != null ? (fieldValue["Baseinfo"]["designation"]) : ("Not Set")}</>) : ("Not Set - Not Set")}
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center' }}>
                            <strong>Är du säker på att du vill ta bort shuntgruppen?</strong>
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={removeShuntApprove}>
                            Ja
                        </Button>
                        <Button onClick={removeShuntDecline}>
                            Nej
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
            </>
        );
    }
};