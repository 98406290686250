import { Select, MenuItem, Stack } from '@mui/material';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import { collection } from 'firebase/firestore';
import { useRef, useState, useEffect } from 'react';
import { SHUNT_DIMS_PATH } from '../../../../constants/firebasePaths';
import { useCalcParams, useDriftParams } from '../../../../contexts';
import { DriftParamEnum, CalcParamsEnum } from '../../../../enums';


export const DevbarDimSelect = () => {
    const containerRef = useRef();
    const calcParams = useCalcParams();
    const driftParams = useDriftParams();
    const firestore = useFirestore();
    const [optData, setOptData] = useState([]);
    const [optDataObj, setOptDataObj] = useState([]);
    //CalcParamsEnum.PrimDimRow.setContextValue(calcParams, dimRow)

    const secondaryDim = DriftParamEnum.SecondaryDim;
    const secondaryDimObject = CalcParamsEnum.SekDimRow;

    const optionsDoc = collection(firestore, SHUNT_DIMS_PATH);
    const firestoreRes = useFirestoreCollection(optionsDoc);

    const [fieldValue, setFieldValue] = useState('not set')

    const setValue = event => {
        secondaryDim.setContextValue(driftParams, event.target.value);
        const selected = optDataObj.find((obj) => { return obj.display === event.target.value });
        if (selected != null) {
            secondaryDimObject.setContextValue(calcParams, selected);
        }
        setFieldValue(event.target.value);
    };

    useEffect(() => {
        if (firestoreRes.data != null) {
            const options = [
                '',
            ];

            const optionObj = [];

            firestoreRes.data.forEach(dim => {
                if (options.find(obj => { return obj === dim.data().display }) == null) {
                    options.push(dim.data().display);
                    optionObj.push(dim.data())
                }
            });

            setOptData(options);
            setOptDataObj(optionObj);
        }

    }, [firestoreRes])

    useEffect(() => {
        setFieldValue(secondaryDim.getContextValue(driftParams));

    }, [secondaryDim.getContextValue(driftParams)]);


    return (
        <Stack direction={"column"} style={{ marginRight: 25 }}>
            <div style={{ marginTop: "0px", paddingBottom: "4px" }}>
                Secondary dim:
            </div>

            <Select
                ref={containerRef}
                value={fieldValue}
                onChange={setValue}

                autoWidth
            >
                {optData.map((optionObject, index) => {
                    return (
                        <MenuItem key={index} value={optionObject}>{optionObject}</MenuItem>
                    )
                })}

            </Select>
        </Stack>

    );
};