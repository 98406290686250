export const CONNECTION_DESIGN_KEY = 'connectionDesign';
export const CONNECTION_VALUE_KEY = 'connectionValue';
export const MATERIAL_KEY = 'material';
export const THERMO_KEY = 'thermo';
export const INSULATION_KEY = 'isolation';
export const CUTOFF_KEY = 'cutOff';
export const MANOMETER_KEY = 'manometer';
export const STAND_KEY = 'stand';
export const CABINET_KEY = 'cabinet';
export const ACTUATORS_KEY = 'actuators';
export const DRIPPLATE_KEY = 'dripPlate';
export const ADJUSTVALUE_KEY = 'adjustValue';
export const MIXINGVESSELS_KEY = 'mixingVessels';
export const ENERGYMEASUREMENT_KEY = "energyMeasurement";
export const CONNECTED_TEMP_KEY = "connTemp";
export const CONNECTED_DIFF_KEY = "connDiff";
export const SHUNTSIZE_KEY = 'shuntSize';