import { httpsCallable } from "firebase/functions"

const sendShareSessionMail = function sendShareSessionMail(functions, sessionID, message, baseUrl, senderMail, senderPhone, reciverEmail, facility, projNr) {
    functions.region = 'europe-west1';
    const sendMail = httpsCallable(functions, "sendShareSessionMail");

    let sendMessage = null;
    if (message != null && message !== ""){
        sendMessage = message;
    }

    return sendMail({ 
        sessionID: sessionID, 
        email: reciverEmail, 
        message: sendMessage, 
        baseUrl: baseUrl, 
        senderMail: senderMail,
        senderPhone: senderPhone,
        facility: facility,
        projNr: projNr,
    });
}

export { sendShareSessionMail }