import { Alert, Box, Card, CardContent, CircularProgress, IconButton, Paper, Snackbar, Stack, Tooltip, Typography, styled } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { CONN_VALUE_NOT_SPECIFIED } from "../../../../constants/adjustmentsOptions";
import { PRODUCTLINE_FLEX, PRODUCTLINE_SMART, PRODUCTPRINCIP_PR2, PRODUCTPRINCIP_PR3, PRODUCTPRINCIP_SR2, PRODUCTPRINCIP_SR2FLEX, PRODUCTPRINCIP_SR2SMART, PRODUCTPRINCIP_SR3, PRODUCTPRINCIP_VA1, PRODUCTLINE_STANDARD, PRODUCTLINE_GREEN, PRODUCTLINE_GREEN_I, PRODUCTLINE_GREEN_II, PRODUCTPRINCIP_PR2_APPSHUNT, PRODUCTMODEL_SR2_FLEX_APPSHUNT, PRODUCTPRINCIP_SR2_FLEX_APPSHUNT, } from "../../../../constants/productlineConstants";
import { useDevbarParams } from "../../../../contexts/devBar";
import standardLine from '../../../../assets/images/logos/prodline_standard1.svg'
import greenLine1 from '../../../../assets/images/logos/Premablock_Green_I_Logo.svg'
import greenLine2 from '../../../../assets/images/logos/Premablock_Green_II_Logo.svg'
import flexLine from '../../../../assets/images/logos/prodline_flex1.svg'
import smartLine from '../../../../assets/images/logos/prodline_smart.svg'
import placeholder from '../../../../assets/images/placeholder.png'
import { useFunctions, useUser } from "reactfire";

import { uploadImage } from "../../../../firebase/functions/cloudFunctions/uploadImage";
import { SessionParamsEnum } from "../../../../enums";
import { useSessionParams } from "../../../../contexts";
import { StorageRefSingleImage } from "../../imageDisplay/StorageRefImage";
import { StorageRefDoubleImage } from "../../imageDisplay/StorageRefDoubleImage";
import { ShuntMeasurementCard } from "../../shuntSpecification/shuntMeasurementCard";
import { MAX_FILE_SIZE } from "../../../../constants/generalConstants";

import EditIcon from '@mui/icons-material/Edit';
import { CUSTOM_SHUNT_MEASUREMENT_ROWS, SHUNT_FAMILY_FOLDER } from "../../../../constants/customShunts";
import { ADDON_CONNECTION_IMAGES, ADDON_LABELS } from "../../../../constants/addonLabels";
import { AddonMeasurementCard } from "../../shuntSpecification/addonMeasurementCard";
import { useContextAuth } from "../../../../authContext/AuthHook";
const Input = styled('input')({
    display: 'none',
});

export const SidebarImageShuntData = ({ shuntData }) => {
    const [openAlert, setOpenAlert] = useState(false);
    const handleOpenAlert = () => { setOpenAlert(true) }
    const handleCloseAlert = () => { setOpenAlert(false) }

    const { user } = useContextAuth();
    const toolTipText = "Ladda upp manuell bild";
    const devParams = useDevbarParams();
    const sessionParams = useSessionParams();
    const functions = useFunctions();

    const setFolderNameInit = () => {
        let plPrincip = shuntData?.PLData?.princip;
        let plLine = shuntData?.PLData?.prodLine;
        let plFamily;

        if (SHUNT_FAMILY_FOLDER[plPrincip] != null) {
            plFamily = String(SHUNT_FAMILY_FOLDER[plPrincip]).toLocaleLowerCase();
        }

        if (plFamily != null) {
            return String(plFamily).toLowerCase();
        }
        else {
            return null;
        }

    }


    const [imageUrl, setImageUrl] = useState("");

    const [shuntNomenklaturString, setShuntNomenklaturString] = useState('');
    const [customUrl, setCustomUrl] = useState(shuntData?.CustomData?.imageRef);
    const [showDouble, setShowDouble] = useState(false);

    const [loading, setLoading] = useState(false);
    const [clearCache, setClearCache] = useState(false);
    const [updateImg, setUpdateImg] = useState(false);
    const [localMeasurement, setLocalMeasurement] = useState(shuntData?.CalcParams?.measurementData || {});

    useEffect(() => {
        if (SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true) {
            setShuntNomenklaturString(shuntData?.ShuntData?.shuntNomenklaturString);
        }
    }, [SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams), shuntData?.ShuntData])

    useEffect(() => {
        if (SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true) {
            setCustomUrl(shuntData?.CustomData?.imageRef);
            setClearCache(true);
            setUpdateImg(!updateImg);
        }
    }, [SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams), shuntData?.CustomData])

    useEffect(() => {
        if (SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true) {
            if (shuntData?.CalcParams?.measurementData != null && shuntData?.CalcParams?.measurementData !== "") {
                setLocalMeasurement(shuntData?.CalcParams?.measurementData);
            }
        }
    }, [SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams), shuntData?.CalcParams?.measurementData]);

    useEffect(() => {
        if (SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true) {
            if (shuntData?.AdjustData?.connectionValue === CONN_VALUE_NOT_SPECIFIED && shuntData?.Baseinfo?.isCustom !== true) {
                setShowDouble(true);
            }
            else {
                setShowDouble(false);
            }

            const localFolderName = setFolderNameInit();
            if (localFolderName != null && localFolderName !== "" && shuntNomenklaturString != null && shuntNomenklaturString !== "") {
                //console.log("Update: String: ", localFolderName, shuntNomenklaturString, shuntData?.AdjustData?.connectionValue)
                const imgUrl = "images/" + localFolderName + "/kopplingsbilder/" + shuntNomenklaturString + ".jpg";
                //console.log("Update: String: ", imgUrl);
                setImageUrl(imgUrl);
                setUpdateImg(!updateImg);
            }
            else {
                setImageUrl('');
                setUpdateImg(!updateImg);
            }
        }
    }, [SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams), shuntNomenklaturString])

    const displayLogo = () => {
        if (shuntData?.PLData?.prodLine === PRODUCTLINE_STANDARD) {
            return (
                <img src={standardLine} alt='Standard Line' style={{ height: '20px', }} />
            );
        }
        else if (shuntData?.PLData?.prodLine === PRODUCTLINE_GREEN) {
            if (shuntData?.PLData?.prodLine != null && shuntData?.PLData?.greenLevel === 2) {
                return (
                    <img src={greenLine2} alt='green Line II' style={{ height: '25px', marginTop: '5px' }} />
                );
            }
            else {
                return (
                    <img src={greenLine1} alt='green Line I' style={{ height: '25px', marginTop: '5px' }} />
                );
            }
        }
        else if (shuntData?.PLData?.prodLine === PRODUCTLINE_GREEN_I) {
            return (
                <img src={greenLine1} alt='green Line I' style={{ height: '25px', marginTop: '5px' }} />
            );
        }
        else if (shuntData?.PLData?.prodLine === PRODUCTLINE_GREEN_II) {
            return (
                <img src={greenLine2} alt='green Line II' style={{ height: '25px', marginTop: '5px' }} />
            );
        }
        else if (shuntData?.PLData?.prodLine === PRODUCTLINE_FLEX) {
            return (
                <img src={flexLine} alt='flex Line' style={{ height: '20px' }} />
            );
        }
        else if (shuntData?.PLData?.prodLine === PRODUCTLINE_SMART) {
            return (
                <img src={smartLine} alt='smart Line' style={{ height: '20px' }} />
            );
        }
        else {
            return "No Logo";
        }
    }

    const uploadPicture = (event) => {
        const file = event.target.files[0];
        //console.log("File: ", file)

        if (file != null) {
            console.log("Filedata: ", file.type)

            if (file.size > MAX_FILE_SIZE) {
                console.log("File to large!");
                handleOpenAlert();
            }
            else {
                setLoading(true);
                uploadImage(functions, SessionParamsEnum.SessionID.getContextValue(sessionParams), shuntData.NO_ID_FIELD, file, file.name, file.type).then(() => {
                    //console.log("File uploaded!");
                })
                    .catch((e) => {
                        console.error("Could not upload file:", e);
                    }).finally(() => {
                        setLoading(false);
                    });
            }
        }
    }

    const getAddonImageUrl = () => {
        let imgUrl = "";
        if (shuntData?.ShuntData?.isAddon === true && shuntData?.ShuntData?.addonType != null) {
            if (ADDON_CONNECTION_IMAGES[shuntData?.ShuntData?.addonType] != null) {
                imgUrl = "images/addons/kopplingsbilder/" + ADDON_CONNECTION_IMAGES[shuntData?.ShuntData?.addonType] + ".jpg";
            }
        }

        return imgUrl;
    }


    //console.log("Val: ", shuntData?.Baseinfo?.isCustom, shuntNomenklaturString, imageUrl, customUrl, folderName)
    return (
        <div>
            <Paper elevation={0} style={{ width: '250px', justifyContent: 'center', padding: '20px', height: 'fit-content' }}>
                <div className="logo-container">
                    {displayLogo()}
                </div>
                <div>
                    {customUrl != null && customUrl !== "" ? (
                        <Box style={{ minHeight: '120px' }}>
                            <Stack direction="row" justifyContent="center" alignItems="flex-start">
                                <StorageRefSingleImage
                                    imageRef={customUrl}
                                    altText={'Egen bild'}
                                    clearImgCache={clearCache}
                                    setClearImgCache={setClearCache}
                                    updateImg={updateImg}
                                    shuntID={shuntData.NO_ID_FIELD}
                                />
                            </Stack>
                            {user?.isAnonymous !== true && user?.isInternal === true && SessionParamsEnum.Editable.getContextValue(sessionParams) === true ? (
                                <label htmlFor={"contained-button-" + shuntData.NO_ID_FIELD}>
                                    <Input accept="image/jpeg, image/png, image/svg+xml" id={"contained-button-" + shuntData.NO_ID_FIELD} type="file" onChange={uploadPicture} />
                                    <Tooltip title={toolTipText}>
                                        <IconButton style={{ position: 'relative', marginLeft: '215px' }} component="span" size="small" disabled={loading}>
                                            {loading ? (
                                                <CircularProgress style={{ color: "#928b8b" }} size={14} />
                                            ) : (
                                                <EditIcon sx={{ "&:hover": { color: "#9e9e9e" }, fontSize: '12px' }} htmlColor={"#d9d9d9"} />
                                            )}
                                        </IconButton>
                                    </Tooltip>
                                </label>
                            ) : ("")}
                        </Box>
                    ) : (
                        <>
                            {shuntData?.ShuntData?.isAddon === true ? (
                                <>
                                    <StorageRefSingleImage
                                        imageRef={getAddonImageUrl()}
                                        altText={ADDON_LABELS[shuntData?.ShuntData?.addonType]}
                                        clearImgCache={clearCache}
                                        setClearImgCache={setClearCache}
                                        shuntID={shuntData.NO_ID_FIELD}
                                    />
                                </>
                            ) : (
                                <>
                                    {shuntNomenklaturString != null && shuntNomenklaturString !== "" ? (
                                        <>
                                            {devParams.state.val.devMode === true && <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '15px' }}>({shuntNomenklaturString})</div>}

                                            {imageUrl != null && imageUrl !== '' && showDouble === false ? (
                                                <>
                                                    <StorageRefSingleImage
                                                        imageRef={imageUrl}
                                                        altText={shuntNomenklaturString}
                                                        clearImgCache={clearCache}
                                                        setClearImgCache={setClearCache}
                                                        shuntID={shuntData.NO_ID_FIELD}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <StorageRefDoubleImage useCurrent={false} shuntData={shuntData} />
                                                </>
                                            )}

                                            {user?.isAnonymous !== true && user?.isInternal === true && SessionParamsEnum.Editable.getContextValue(sessionParams) === true  ? (
                                                <label htmlFor={"contained-button-" + shuntData.NO_ID_FIELD}>
                                                    <Input accept="image/jpeg, image/png, image/svg+xml" id={"contained-button-" + shuntData.NO_ID_FIELD} type="file" onChange={uploadPicture} />
                                                    <Tooltip title={toolTipText}>
                                                        <IconButton style={{ position: 'relative', marginLeft: '215px' }} component="span" size="small" disabled={loading}>
                                                            {loading ? (
                                                                <CircularProgress style={{ color: "#928b8b" }} size={14} />
                                                            ) : (
                                                                <EditIcon sx={{ "&:hover": { color: "#9e9e9e" }, fontSize: '12px' }} htmlColor={"#d9d9d9"} />
                                                            )}
                                                        </IconButton>
                                                    </Tooltip>
                                                </label>
                                            ) : ("")}
                                        </>

                                    ) : (
                                        <>
                                            <Box style={{ minHeight: '120px' }}>
                                                <Stack direction="row" justifyContent="center" alignItems="flex-start">
                                                    <img src={placeholder} style={{ border: 'solid 1px #06559e', borderRadius: '3px', marginTop: '10px' }} alt="Placeholder" width={'160px'} />
                                                </Stack>
                                                {user?.isAnonymous !== true && user?.isInternal === true && SessionParamsEnum.Editable.getContextValue(sessionParams) === true ? (
                                                    <label htmlFor={"contained-button-" + shuntData.NO_ID_FIELD}>
                                                        <Input accept="image/jpeg, image/png, image/svg+xml" id={"contained-button-" + shuntData.NO_ID_FIELD} type="file" onChange={uploadPicture} />
                                                        <Tooltip title={toolTipText}>
                                                            <IconButton style={{ position: 'relative', marginLeft: '215px' }} component="span" size="small" disabled={loading}>
                                                                {loading ? (
                                                                    <CircularProgress style={{ color: "#928b8b" }} size={14} />
                                                                ) : (
                                                                    <EditIcon sx={{ "&:hover": { color: "#9e9e9e" }, fontSize: '12px' }} htmlColor={"#d9d9d9"} />
                                                                )}
                                                            </IconButton>
                                                        </Tooltip>
                                                    </label>
                                                ) : ("")}
                                            </Box>
                                        </>
                                    )}
                                </>
                            )}

                        </>
                    )}
                </div>
                <br />
                {shuntData?.Baseinfo?.isCustom === true ? (
                    <>
                        {CUSTOM_SHUNT_MEASUREMENT_ROWS[shuntData?.PLData?.princip] != null && CUSTOM_SHUNT_MEASUREMENT_ROWS[shuntData?.PLData?.princip][shuntData?.CalcParams?.primDimRow?.name] != null ? (
                            <Card variant="outlined">
                                <CardContent style={{ paddingBottom: '15px' }}>
                                    <Typography style={{ marginBottom: '6px' }}><strong>Mått</strong></Typography>
                                    {CUSTOM_SHUNT_MEASUREMENT_ROWS[shuntData?.PLData?.princip][shuntData?.CalcParams?.primDimRow?.name].map((row) => {
                                        return (
                                            <Typography>{row}</Typography>
                                        )
                                    })}
                                </CardContent>
                            </Card>
                        ) : (
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography><strong>Mått</strong></Typography>
                                    <Typography style={{ marginTop: '20px', marginBottom: '20px' }}><i>Kontakta Prema för mått</i></Typography>
                                    <Typography>Mått exkl. pump och ventiler</Typography>
                                </CardContent>
                            </Card>
                        )}
                    </>

                ) : (
                    <>
                        {shuntData?.ShuntData?.isAddon === true ? (
                            <AddonMeasurementCard addonData={shuntData} />
                        ) : (
                            <ShuntMeasurementCard shuntID={shuntData.NO_ID_FIELD} measurement={localMeasurement} editable shuntData={shuntData} />
                        )}
                    </>
                )}
            </Paper>
            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
                    Kan ej ladda upp fil. <br />Filen är för stor.
                </Alert>
            </Snackbar>
        </div>
    )
}