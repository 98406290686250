import React, { createContext, useReducer, useContext } from "react";
import { NOTICELIST_PARAMS } from "../constants/sessionStoragePaths";

const NoticeContext = createContext(undefined);

function noticeContextReducer(state, action) {
    switch (action.type) {
        case 'addNotice': {
            let data = {};
            if (sessionStorage.getItem(NOTICELIST_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(NOTICELIST_PARAMS));
            }
            const key = action.payload.key;
            data[key] = action.payload.msg;
            sessionStorage.setItem(NOTICELIST_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'clearNotice': {
            let data = {};
            if (sessionStorage.getItem(NOTICELIST_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(NOTICELIST_PARAMS));
            }

            delete data[action.payload];
            sessionStorage.setItem(NOTICELIST_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'clearNoticeList': {
            sessionStorage.removeItem(NOTICELIST_PARAMS);
            return { val: null }
        }
        case 'clear': {
            sessionStorage.removeItem(NOTICELIST_PARAMS);
            return { val: null }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function NoticeContextProvider({ children }) {
    let obj = {};
    if (sessionStorage.getItem(NOTICELIST_PARAMS) != null) {
        obj = JSON.parse(sessionStorage.getItem(NOTICELIST_PARAMS));
    }

    const [state, dispatch] = useReducer(noticeContextReducer, { val: obj })
    const value = { state, dispatch }

    return (
        <NoticeContext.Provider value={value}>
            {children}
        </NoticeContext.Provider>
    );
}

function useNoticeContext() {
    const context = useContext(NoticeContext)
    if (context === undefined) {
        throw new Error('useNoticeContext must be used within a NoticeContext')
    }
    return context
}


export { NoticeContextProvider, useNoticeContext };