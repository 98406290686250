import { ConnectionTypeFilterDataListInterface } from "./types";
import { PRIM_FLOW_CONSTANT, PRIM_FLOW_VARIABLE } from "../productlineConstants";
import { AVAILABLE_DRIVE_PRESSURE } from "../wizardConstants";


export const connectionTypeFilterDataList: ConnectionTypeFilterDataListInterface = {
    "SR-2": {
        id: "SR-2",
        primFlow: PRIM_FLOW_VARIABLE,
        exchange: false,
        dimensionable: true,
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.normal,
        lowestDPValue: 10,
        needMainPump: true,
        numOfSources: 1,
        sourceOptions: [
            'districtHeating',
            'districtCooling',
            'heatPump',
            'recovery',//Yellow
            'freeCooling',
            'other',
            'unknown',
        ],
        sourceFlow: [
            'variable'
        ],
        appSource: [
            'heat',
            'cooling',
        ],
    },
    "SR-2 flex Appshunt": {
        id: "SR-2 flex Appshunt",
        primFlow: PRIM_FLOW_VARIABLE,
        exchange: false,
        dimensionable: false,
        needMainPump: true,
        limitedSpace: true,
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.normal,
        lowestDPValue: 15,
        minFlow: 0.055,
        maxFlow: 0.55,
        minFlowLevel: 2,
        maxFlowLevel: 2,
        numOfSources: 1,
        sourceOptions: [
            'districtHeating',
            'districtCooling',
            'heatPump',
            'recovery',//Yellow
            'freeCooling',
            'other',
            'unknown',
        ],
        sourceFlow: [
            'variable'
        ],
        appSource: [
            'heat',
            'cooling',
        ],
    },
    "SR-3": {
        id: "SR-3",
        primFlow: PRIM_FLOW_CONSTANT,
        exchange: false,
        dimensionable: true,
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.normal,
        lowestDPValue: 8,
        needMainPump: true,
        numOfSources: 1,
        sourceOptions: [
            'heatPump',
            'boiler',
            'coolingMachine',
            'other',
            'unknown',
        ],
        sourceFlow: [
            'constant'
        ],
        appSource: [
            'heat',
            'cooling',
        ],
    },
    "PR-2": {
        id: "PR-2",
        primFlow: PRIM_FLOW_VARIABLE,
        exchange: false,
        dimensionable: true,
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.nonExistent,
        lowestDPValue: 0,
        needMainPump: false,
        numOfSources: 1,
        sourceOptions: [
            'districtHeating',
            'districtCooling',
            'heatPump',         //Yellow
            'recovery',         //Yellow
            'freeCooling',
            'other',
            'unknown',
        ],
        sourceFlow: [
            'variable'
        ],
        appSource: [
            'heat',
            'cooling',
            'shunting',
        ],
    },
    "PR-2 Appshunt": {
        id: "PR-2 Appshunt",
        primFlow: PRIM_FLOW_VARIABLE,
        exchange: false,
        dimensionable: false,
        needMainPump: false,
        limitedSpace: true,
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.nonExistent,
        lowestDPValue: 0,
        minFlow: 0.015,
        maxFlow: 0.4,
        minFlowLevel: null,
        maxFlowLevel: 2,
        numOfSources: 1,
        sourceOptions: [
            'districtHeating',
            'districtCooling',
            'heatPump',
            'recovery',         //Yellow
            'freeCooling',
            'other',
            'unknown',
        ],
        sourceFlow: [
            'variable'
        ],
        appSource: [
            'heat',
            'cooling',
        ],
    },
    "PR-3": {
        id: "PR-3",
        primFlow: PRIM_FLOW_CONSTANT,
        exchange: false,
        dimensionable: true,
        needMainPump: true,
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.low,
        lowestDPValue: 0.1,
        sourceOptions: [
            'heatPump',
            'boiler',
            'coolingMachine',
            'other',
            'unknown',
        ],
        sourceFlow: [
            'constant'
        ],
        numOfSources: 1,
        appSource: [
            'heat',
            'cooling',
        ],
    },
    "VA-1": {
        id: "VA-1",
        primFlow: PRIM_FLOW_VARIABLE,
        exchange: false,
        dimensionable: true,
        needMainPump: false,
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.nonExistent,
        lowestDPValue: 0,
        numOfSources: 1,
        sourceOptions: [
            'recoveryBattery',
            'unknown',
        ],
        sourceFlow: [
            'variable'
        ],
        appSource: [
            'heat',
            'cooling',
        ],
    },
    "FBU-5K": {
        id: "FBU-5K",
        primFlow: PRIM_FLOW_VARIABLE,
        exchange: false,
        dimensionable: false,
        needMainPump: false,
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.nonExistent,
        lowestDPValue: 0,
        numOfSources: 1,
        sourceOptions: [
            'districtCooling',
            'unknown',
        ],
        sourceFlow: [
            'variable'
        ],
        appSource: [
            'cooling',
        ],
    },
    "FBU-5V": {
        id: "FBU-5V",
        primFlow: PRIM_FLOW_VARIABLE,
        exchange: false,
        dimensionable: false,
        needMainPump: false,
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.nonExistent,
        lowestDPValue: 0,
        numOfSources: 1,
        sourceOptions: [
            'districtHeating',
            'unknown',
        ],
        sourceFlow: [
            'variable'
        ],
        appSource: [
            'heat',
        ],
    },
    "FKU-5K": {
        id: "FKU-5K",
        primFlow: PRIM_FLOW_VARIABLE,
        exchange: false,
        dimensionable: false,
        needMainPump: false,
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.nonExistent,
        lowestDPValue: 0,
        numOfSources: 2,
        sourceOptions: [
            'districtCooling',
            'recovery',
            'unknown',
        ],
        sourceFlow: [
            'variable'
        ],
        appSource: [
            'cooling',
        ],
    },
    "SRX-42": {
        id: "SRX-42",
        primFlow: PRIM_FLOW_VARIABLE,
        exchange: true,
        dimensionable: false,
        needMainPump: true,
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.normal,
        lowestDPValue: 15,
        numOfSources: 1,
        sourceOptions: [
            'districtHeating',
            'districtCooling',
            'heatPump',
            'freeCooling',
            'recovery', //Yellow
            'other',
            'unknown',
        ],
        sourceFlow: [
            'variable'
        ],
        appSource: [
            'heat',
            'cooling',
        ],
    },
    "SRX-43": {
        id: "SRX-43",
        primFlow: PRIM_FLOW_CONSTANT,
        exchange: true,
        dimensionable: false,
        needMainPump: true,
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.normal,
        lowestDPValue: 15,
        numOfSources: 1,
        sourceOptions: [
            'heatPump',
            'boiler',
            'coolingMachine',
            'other',
            'unknown',
        ],
        sourceFlow: [
            'constant'
        ],
        appSource: [
            'heat',
            'cooling',
        ],
    },
    "PRX-43": {
        id: "PRX-43",
        primFlow: PRIM_FLOW_CONSTANT,
        exchange: true,
        dimensionable: false,
        needMainPump: true,
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.low,
        lowestDPValue: 0.1,
        numOfSources: 1,
        sourceOptions: [
            'heatPump',
            'boiler',
            'coolingMachine',
            'other',
            'unknown',
        ],
        sourceFlow: [
            'constant'
        ],
        appSource: [
            'heat',
            'cooling',
        ],
    },
    "PRUX-43FK": {
        id: "PRUX-43FK",
        primFlow: PRIM_FLOW_VARIABLE,
        exchange: true,
        dimensionable: false,
        needMainPump: false,
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.nonExistent,
        lowestDPValue: 0,
        numOfSources: 1,
        sourceOptions: [
            'freeCooling',
        ],
        sourceOptionRequired: [
            'freeCooling',
        ],
        sourceFlow: [
            'variable'
        ],
        appSource: [
            'heat',
            'cooling',
        ],
    },
    "SRBX-62VK": {
        id: "SRBX-62VK",
        primFlow: null,
        exchange: true,
        dimensionable: false,
        combination: true,
        needMainPump: true,
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.normal,
        lowestDPValue: 15, //Yellow
        numOfSources: 2,
        sourceOptions: [
            'districtHeating',
            'districtCooling',
            'heatPump',
            'boiler',
            'coolingMachine',
            'freeCooling',
            'recovery',          //Yellow
            'other',
            'unknown',
        ],
        sourceFlow: [
            'variable',
            'constant',
        ],
        appSource: [
            'heatAndCooling',
        ],
    },
    "SRBX-63VK": {
        id: "SRBX-63VK",
        primFlow: null,
        exchange: true,
        dimensionable: false,
        combination: true,
        needMainPump: true,
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.normal,
        lowestDPValue: 15, //Yellow
        numOfSources: 2,
        sourceOptions: [
            'districtHeating',
            'districtCooling',
            'heatPump',
            'boiler',
            'coolingMachine',
            'recovery',          //Yellow
            'freeCooling',
            'other',
            'unknown',
        ],
        sourceFlow: [
            'variable',
            'constant',
        ],
        appSource: [
            'heatAndCooling',
        ],
    },
    "PRBX-6VK": {
        id: "PRBX-6VK",
        primFlow: null,
        exchange: true,
        dimensionable: false,
        combination: true,
        needMainPump: false,
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.nonExistent,
        lowestDPValue: 0,
        numOfSources: 2,
        sourceOptions: [
            'districtHeating',
            'districtCooling',
            'heatPump',
            'boiler',
            'coolingMachine',
            'recovery',          //Yellow
            'freeCooling',
            'other',
            'unknown',
        ],
        sourceFlow: [
            'variable',
            'constant',
        ],
        appSource: [
            'heatAndCooling',
        ],
    },
    "SRBX-6ECO": {
        id: "SRBX-6ECO",
        primFlow: null,
        exchange: true,
        dimensionable: false,
        combination: true,
        needMainPump: true,                             //Yellow
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.normal, //Yellow
        lowestDPValue: 15,                              //Yellow
        numOfSources: 2,
        sourceOptions: [
            'districtHeating',
            'districtCooling',
            'heatPump',
            'boiler',
            'coolingMachine',
            'recovery',          //Yellow
            'freeCooling',
            'other',
            'unknown',
        ],
        sourceFlow: [
            'variable',
            'constant',
        ],
        appSource: [
            'heatAndCooling',
        ],
    },
    "SRBX-6VAV": {
        id: "SRBX-6VAV",
        primFlow: null,
        exchange: true,
        dimensionable: false,
        combination: true,
        needMainPump: true,                             //Yellow
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.normal, //Yellow
        lowestDPValue: 15,                              //Yellow
        numOfSources: 2,
        sourceOptions: [
            'districtHeating',
            'heatPump',
            'boiler',
            'recoveryBattery',
            'recovery',
            'other',
            'unknown',
        ],
        sourceOptionRequired: [
            'recoveryBattery',
        ],
        sourceFlow: [
            'variable',
            'constant',
        ],
        appSource: [
            'heat',
            'heatAndPeak',
        ],
    },
    "SRBX-6SPETS": {
        id: "SRBX-6SPETS",
        primFlow: null,
        exchange: true,
        dimensionable: false,
        needMainPump: true,                             //Yellow
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.normal, //Yellow
        lowestDPValue: 15,                              //Yellow
        combination: true,
        numOfSources: 2,
        sourceOptions: [
            'districtHeating',
            'heatPump',
            'boiler',
            'recovery',
            'other',
            'unknown',
        ],
        sourceOptionRequired: [
            'recovery',
        ],
        sourceFlow: [
            'variable',
            'constant',
        ],
        appSource: [
            'heatAndPeak',
        ],
    },
    "SRBX-6NOD": {
        id: "SRBX-6NOD",
        primFlow: null,
        exchange: true,
        dimensionable: false,
        needMainPump: true,                             //Yellow
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.normal, //Yellow
        lowestDPValue: 15,                              //Yellow
        combination: true,
        numOfSources: 2,
        sourceOptions: [
            'districtCooling',
            'coolingMachine',
            //'recovery', //Orange
            'freeCooling',
            'other',
            'unknown',
        ],
        sourceOptionRequired: [
            'other',
        ],
        sourceFlow: [
            'variable',
            'constant',
        ],
        appSource: [
            'coolingAndEmergencyCooling',
        ],
    },
    "SRBX-6VAK": {
        id: "SRBX-6VAK",
        primFlow: null,
        exchange: true,
        dimensionable: false,
        combination: true,
        needMainPump: true,                             //Yellow
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.normal, //Yellow
        lowestDPValue: 15,                              //Yellow
        numOfSources: 2,
        sourceOptions: [
            'districtCooling',
            'coolingMachine',
            'recoveryBattery',
            'freeCooling',
            'recovery',     //Yellow
            'other',
            'unknown',
        ],
        sourceOptionRequired: [
            'recoveryBattery',
        ],
        sourceFlow: [
            'variable',
            'constant',
        ],
        appSource: [
            'cooling',
            'heatAndCooling',
        ],
    },
    "SRBX-6VAK-Forbi": {
        id: "SRBX-6VAK-Forbi",
        primFlow: null,
        exchange: true,
        dimensionable: false,
        combination: true,
        needMainPump: true,                             //Yellow
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.normal, //Yellow
        lowestDPValue: 15,                              //Yellow
        numOfSources: 2,
        sourceOptions: [
            'districtCooling',
            'coolingMachine',
            'recoveryBattery',
            'recovery',
            'other',
            'unknown',
        ],
        sourceOptionRequired: [
            'recoveryBattery',
        ],
        sourceFlow: [
            'variable',
            'constant',
        ],
        appSource: [
            'cooling',
            'heatAndCooling',
        ],
    },
    "SRBX-8VAKK": {
        id: "SRBX-8VAKK",
        primFlow: null,
        exchange: true,
        dimensionable: false,
        combination: true,
        needMainPump: true,                             //Yellow
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.normal, //Yellow
        lowestDPValue: 15,                              //Yellow
        numOfSources: 3,
        sourceOptions: [
            'districtHeating',
            'districtCooling',
            'heatPump',
            'boiler',
            'coolingMachine',
            'recoveryBattery',
            'freeCooling',
            'recovery',          //Yellow
            'other',
            'unknown',
        ],
        sourceFlow: [
            'variable',
            'constant',
        ],
        appSource: [
            'heatAndCooling',
        ],
    },
    "SRBX-8VAKK-Forbi": {
        id: "SRBX-8VAKK-Forbi",
        primFlow: null,
        exchange: true,
        dimensionable: false,
        combination: true,
        needMainPump: true,                             //Yellow
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.normal, //Yellow
        lowestDPValue: 15,                              //Yellow
        numOfSources: 3,
        sourceOptions: [
            'districtHeating',
            'districtCooling',
            'heatPump',
            'boiler',
            'coolingMachine',
            'recoveryBattery',
            'freeCooling',
            'recovery',          //Yellow
            'other',
            'unknown',
        ],
        sourceFlow: [
            'variable',
            'constant',
        ],
        appSource: [
            'heatAndCooling',
        ],
    },
    "PBCS": {
        id: "PBCS",
        primFlow: null,
        exchange: true,
        dimensionable: false,
        combination: true,
        needMainPump: false,
        lowestDPClass: AVAILABLE_DRIVE_PRESSURE.nonExistent,
        lowestDPValue: 0,
        numOfSources: 6,
        sourceOptions: [
            'districtHeating',
            'districtCooling',
            'heatPump',
            'boiler',
            'coolingMachine',
            'recoveryBattery',
            'recovery',
            'other',
            'unknown',
        ],
        sourceFlow: [
            'variable',
            'constant',
        ],
        appSource: [
            'centralShuntGroup',
        ],
    },
};