import { Box, Stack, Tooltip } from "@mui/material"

const tagStyle = {
    background: '#06559e',
    padding: '1px 12px',
    color: 'white',
    fontWeight: 700,
    fontSize: '12px',
    borderRadius: '3px',
    border: '1px solid #054f94',
}

/**
 * 
 * @param {Object} param0 
 * @param {import('../../../../constants/fullConnOptions/types').ShuntConnectionInterface} param0.shunt
 * @returns 
 */
export const ShuntTagsComponent = ({ shunt }) => {


    return (
        <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
            {shunt?.FilterData?.dimensionable === true ? (
                <Tooltip arrow placement="top" title={"Dimensionerbar i ShuntLogik"}>
                    <Box sx={tagStyle}>
                        SL
                    </Box>
                </Tooltip>

            ) : ("")}


            {shunt?.FilterData?.combination === true ? (
                <Tooltip arrow placement="top" title={"Kombishuntgrupp"}>
                    <Box sx={tagStyle}>
                        KOMBI
                    </Box>
                </Tooltip>
            ) : ("")}

            {shunt?.FilterData?.exchange === true ? (
                <Tooltip arrow placement="top" title={"Innehåller växlare"}>
                    <Box sx={tagStyle}>
                        VÄXLARE
                    </Box>
                </Tooltip>

            ) : ("")}


        </Stack>
    )
}