import { ref, onValue } from "firebase/database";
import { useEffect, useState } from "react";
import { useDatabase } from "reactfire";
import { SESSION_SHUNT_PATH } from "../constants/firebasePaths";

/**
 * 
 * @param {string} shuntID 
 */
export const useGetShuntData = (shuntID) => {
    const database = useDatabase();
    const [shuntData, setShuntData] = useState(Object)
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (shuntID != null && shuntID !== '') {
            setLoading(true);
            const infoRef = ref(database, SESSION_SHUNT_PATH + shuntID);
            const valueRef = onValue(infoRef, (snapshot) => {
                if (snapshot.exists()) {
                    setShuntData({ NO_ID_FIELD: shuntID, id: shuntID, ...snapshot.val() })
                }
                setLoading(false);
            });

            return () => {
                valueRef();
            }
        }
    }, [shuntID])

    return { shuntData, loading };
}

