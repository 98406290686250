export const PRODUCTFAMILY_SR = 'SR';
export const PRODUCTFAMILY_PR = 'PR';
export const PRODUCTFAMILY_VA = 'VA';

export const PRODUCTPRINCIP_SR2 = 'SR-2'
export const PRODUCTPRINCIP_SR3 = 'SR-3'
export const PRODUCTPRINCIP_SR2FLEX = 'SR-2-flex'
export const PRODUCTPRINCIP_SR2SMART = 'SR-2-smart'
export const PRODUCTPRINCIP_PR2 = 'PR-2'
export const PRODUCTPRINCIP_PR3 = 'PR-3'
export const PRODUCTPRINCIP_VA1 = 'VA-1'

export const PRODUCTFOLDER_SR2 = 'sr2'
export const PRODUCTFOLDER_SR3 = 'sr3'
export const PRODUCTFOLDER_SR2_FLEX = 'sr2-flex'
export const PRODUCTFOLDER_SR2_SMART = 'sr2-smart'
export const PRODUCTFOLDER_PR2 = 'pr2'
export const PRODUCTFOLDER_PR3 = 'pr3'
export const PRODUCTFOLDER_VA1 = 'va1'
export const PRODUCTFOLDER_SRBX = 'srbx'
export const PRODUCTFOLDER_PRBX = 'prbx'
export const PRODUCTFOLDER_SRX = 'srx'
export const PRODUCTFOLDER_PRX = 'prx'

export const PRODUCTPRINCIP_PR2_APPSHUNT = 'PR-2 Appshunt'
export const PRODUCTPRINCIP_SR2_FLEX_APPSHUNT = 'SR-2 flex Appshunt'

export const PRODUCTMODEL_PR2_APPSHUNT = 'PRH-2App'
export const PRODUCTMODEL_SR2_FLEX_APPSHUNT = 'SRH-2 flex App'

export const PRODUCTLINE_STANDARD = 'standard';
export const PRODUCTLINE_GREEN = 'green';
export const PRODUCTLINE_GREEN_I = 'green I';
export const PRODUCTLINE_GREEN_II = 'green II';
export const PRODUCTLINE_FLEX = 'flex';
export const PRODUCTLINE_SMART = 'smart';

export const PRODUCTPRINCIP_SR2FLEX_OLD = 'SR-2-Flex'
export const PRODUCTPRINCIP_SR2SMART_OLD = 'SR-2-Smart'

export const PRIM_FLOW_CONSTANT = "constant";
export const PRIM_FLOW_VARIABLE = "variable";