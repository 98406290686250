import { Button, DialogActions, DialogContent, Stack, Typography } from "@mui/material"
import { BootstrapDialog, BootstrapDialogTitle } from "../../../utils/bootstrapDialog"

import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

export const EditedShuntSpecsModal = ({ open, handleClose, handleApprove, hasErrors }) => {


    return (
        <>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle onClose={handleClose}>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        {hasErrors === true ? (
                            <WarningAmberRoundedIcon color="error" />
                        ) : (
                            <WarningAmberRoundedIcon color="warning" />
                        )}
                        <span>
                            Det finns shuntgrupper med redigerad specifikation
                        </span>
                    </Stack>

                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center' }}>
                        {hasErrors === true ? (
                            "Sessionen innehåller shuntgrupper med modifierad specifikation som har konfigurerats om efter specifikationen modifierades. Kontrollera att all information är korrekt."
                        ) : (
                            "Sessionen innehåller shuntgrupper med modifierad specifikation. Kontrollera att all information är korrekt."
                        )}

                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={handleApprove}>
                        Fortsätt till specifikation
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    )
}