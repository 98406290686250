import { Button, CircularProgress, DialogActions, DialogContent, Stack, TextField, Typography } from "@mui/material";
import { doc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useFirestore, useFirestoreDoc, useFunctions, useUser } from "reactfire";
import { USER_PATH } from "../../../constants/firebasePaths";
import { useSessionParams, useDevbarParams } from "../../../contexts";
import { SessionParamsEnum } from "../../../enums";
import { sendShareSessionMail } from "../../../firebase/functions/cloudFunctions/sendShareSession";
import { BootstrapDialog, BootstrapDialogTitle } from "../../utils/bootstrapDialog";
import { useContextAuth } from "../../../authContext/AuthHook";

export const ShareSession = ({ handleOnDecline, open, hasEditedShunts = false, premaOnly = false }) => {
    const sessionParams = useSessionParams();
    const devParams = useDevbarParams();
    const functions = useFunctions();
    const firestore = useFirestore();
    const { user } = useContextAuth();

    const userRef = USER_PATH + user?.data?.uid;

    const optionsDoc = doc(firestore, userRef);
    const firestoreRes = useFirestoreDoc(optionsDoc);

    const [loading, setLoading] = useState(false);
    const [sent, setIsSent] = useState(false)
    const [shareDefault, setShareDefault] = useState(true)

    const [reciverMail, setReciverMail] = useState('');
    const [senderMail, setSenderMail] = useState('');
    const [senderPhone, setSenderPhone] = useState('');

    const [message, setMessage] = useState('');

    const [debugEmail, setDebugEmail] = useState('');

    useEffect(() => {
        if (open === true) {
            setIsSent(false);
        }
    }, [open])

    useEffect(() => {
        if (user?.isAnonymous === false) {
            if (firestoreRes.data != null && firestoreRes.data.data() != null) {
                if (firestoreRes?.data?.data()?.phoneNr != null) {
                    setSenderPhone(firestoreRes?.data?.data()?.phoneNr);
                }
            }
        }
    }, [firestoreRes, user])

    const closeDialog = () => {
        setMessage('');
        handleOnDecline();
    }

    const [isEmailInvalid, setEmailInvalid] = useState(false);
    const [isReciverEmailInvalid, setReciverEmailInvalid] = useState(false);


    function validateShareSessionForm() {
        validateEmailSubmit();
        validateReciverEmailSubmit();

    }

    const validateEmail = senderMail => {
        if (senderMail !== "") {
            setEmailInvalid(false);
        }
        else {
            setEmailInvalid(true);
        }
    }

    const validateReciverEmail = reciverMail => {
        if (reciverMail !== "") {
            setReciverEmailInvalid(false);
        }
        else {
            setReciverEmailInvalid(true);
        }
    }


    const validateEmailSubmit = () => {
        if (senderMail !== "") {
            setEmailInvalid(false);
        }
        else {
            setEmailInvalid(true);
        }
    }

    const validateReciverEmailSubmit = () => {
        if (reciverMail !== "") {
            setReciverEmailInvalid(false);
        }
        else {
            setReciverEmailInvalid(true);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if ((isEmailInvalid === false) && (isReciverEmailInvalid === false)) {
            handleSend();
        }
    }

    const handleSend = () => {
        sendInvitation();
        setIsSent(true);
    }

    const handleSwitchMode = () => {
        setShareDefault(!shareDefault);
    }

    useEffect(() => {
        if (user?.isAnonymous === false) {
            if (user?.isInternal === true) {
                setShareDefault(false);
            }

            if (user?.email != null) {
                setSenderMail(user?.email);
            }
        }
    }, [user])

    const sendInvitation = async () => {
        let projNr = SessionParamsEnum.ProjNr.getContextValue(sessionParams);
        let facility = SessionParamsEnum.Facility.getContextValue(sessionParams);

        const baseUrl = window.location.origin; //TODO: Ta bort när url'en är final.
        if (devParams.state.val.devMode === true && debugEmail != null && debugEmail !== "") {
            setLoading(true);
            await sendShareSessionMail(functions, SessionParamsEnum.SessionID.getContextValue(sessionParams), message, baseUrl, senderMail, senderPhone, debugEmail, facility, projNr).then(() => {
                //console.log("Sent!");
            }).finally(() => {
                setLoading(false);
            });
        }
        else {
            setLoading(true);
            await sendShareSessionMail(functions, SessionParamsEnum.SessionID.getContextValue(sessionParams), message, baseUrl, senderMail, senderPhone, reciverMail, facility, projNr).then(() => {
                //console.log("Sent!");
            }).finally(() => {
                setLoading(false);
            });
        }

    }

    return (
        <BootstrapDialog
            style={{
                minWidth: 640,
                minHeight: 440,
            }}
            onClose={closeDialog}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            {loading === false ? (
                <>
                    {sent === false ? (
                        <>
                            {shareDefault === true || premaOnly === true ? (
                                <>
                                    <form onSubmit={handleSubmit}>
                                        <BootstrapDialogTitle component="h3" sx={{ paddingLeft: '20px', paddingTop: '0px', paddingBottom: '0px' }} onClose={closeDialog}>
                                            <h3 className="header-title">Dela session?</h3>
                                        </BootstrapDialogTitle>
                                        <DialogContent>
                                            {devParams.state.val.devMode === true &&
                                                <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center', paddingBottom: '10px', width: 500 }}>
                                                    <div style={{ paddingTop: "4px" }}>
                                                        (Dev-Läge) Mottagare:
                                                    </div>

                                                    <TextField
                                                        style={{ display: 'flex', justifyContent: 'center', margin: 'auto', width: '65%' }}
                                                        value={debugEmail}
                                                        type={'email'}
                                                        label="MottagarEmail"
                                                        onChange={(event) => { setDebugEmail(event.target.value) }}
                                                    />
                                                </Typography>
                                            }

                                            <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center', paddingBottom: '4px', width: 500 }}>
                                                Vill du skicka en länk för den aktuella sessionen till PREMA för frågor eller rådgivning?
                                            </Typography>
                                            <TextField
                                                style={{ display: 'flex', margin: 'auto', width: '97%' }}
                                                id="comment-input"
                                                value={message}
                                                type={'text'}
                                                label="Meddelande (valfritt)"
                                                multiline
                                                rows={4}
                                                onChange={(event) => { setMessage(event.target.value) }}
                                            />
                                            <div style={{ padding: 8, marginTop: 10 }}>
                                                Kontaktuppgifter:
                                            </div>
                                            <Stack direction={'row'} spacing={1}>
                                                <TextField
                                                    style={{ display: 'flex', justifyContent: 'center', margin: 'auto', width: '47%' }}
                                                    value={senderMail}
                                                    type={'email'}
                                                    label="E-post"
                                                    onChange={(event) => { setSenderMail(event.target.value) }}
                                                    onInput={validateEmail}
                                                    error={isEmailInvalid}
                                                    required
                                                />
                                                <TextField
                                                    style={{ display: 'flex', justifyContent: 'center', margin: 'auto', width: '47%' }}
                                                    value={senderPhone}
                                                    type={'text'}
                                                    label="Telefon"
                                                    onChange={(event) => { setSenderPhone(event.target.value) }}
                                                // required
                                                />
                                            </Stack>
                                        </DialogContent>


                                        <DialogActions>
                                            {premaOnly !== true ? (
                                                <Button onClick={handleSwitchMode} style={{ marginRight: '240px' }}>
                                                    Annan Mottagare
                                                </Button>
                                            ) : ("")}

                                            <Button onClick={validateShareSessionForm} type="submit">
                                                Skicka Sessionen
                                            </Button>
                                        </DialogActions>
                                    </form>
                                </>
                            ) : (
                                <>
                                    <form onSubmit={handleSubmit}>
                                        <BootstrapDialogTitle sx={{ paddingLeft: '20px', paddingTop: '0px', paddingBottom: '0px' }} onClose={closeDialog}>
                                            <h3 className="header-title">Dela session?</h3>
                                        </BootstrapDialogTitle>
                                        {hasEditedShunts === true && user?.isInternal === true ? (
                                            <Typography sx={{ marginLeft: '28px', color: '#e12427', fontStyle: 'italic', width: 500 }}>
                                                Sessionen innehåller shuntgrupper med modifierad specifikation. Kontrollera att all information är korrekt innan sessionen delas.
                                            </Typography>
                                        ) : ("")}
                                        <DialogContent>

                                            <Typography gutterBottom style={{ paddingLeft: '9px', width: 500 }}>
                                                <div>
                                                    Skicka länk till:
                                                </div>

                                            </Typography>
                                            <TextField
                                                style={{ width: '47%', marginLeft: '8px', marginBottom: '10px' }}
                                                value={reciverMail}
                                                type={'email'}
                                                label="E-post"
                                                onChange={(event) => { setReciverMail(event.target.value) }}
                                                onInput={validateReciverEmail}
                                                error={isReciverEmailInvalid}
                                                required
                                            />
                                            <TextField
                                                style={{ display: 'flex', margin: 'auto', width: '97%' }}
                                                value={message}
                                                id="comment-input"
                                                type={'text'}
                                                label="Meddelande (valfritt)"
                                                multiline
                                                rows={4}
                                                onChange={(event) => { setMessage(event.target.value) }}
                                            />
                                            <div style={{ padding: 8, marginTop: 10 }}>
                                                Kontaktuppgifter:
                                            </div>
                                            <Stack direction={'row'} spacing={1}>
                                                <TextField
                                                    style={{ display: 'flex', justifyContent: 'center', margin: 'auto', width: '47%' }}
                                                    value={senderMail}
                                                    type={'email'}
                                                    label="E-post"
                                                    onChange={(event) => { setSenderMail(event.target.value) }}
                                                    onInput={validateEmail}
                                                    error={isEmailInvalid}
                                                    required
                                                />
                                                <TextField
                                                    style={{ display: 'flex', justifyContent: 'center', margin: 'auto', width: '47%' }}
                                                    value={senderPhone}
                                                    type={'text'}
                                                    label="Telefon"
                                                    onChange={(event) => { setSenderPhone(event.target.value) }}
                                                // required
                                                />
                                            </Stack>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleSwitchMode} style={{ marginRight: '240px' }}>
                                                Dela med PREMA
                                            </Button>
                                            <Button onClick={validateShareSessionForm} type="submit">
                                                Skicka Sessionen
                                            </Button>
                                        </DialogActions>
                                    </form>
                                </>
                            )}

                        </>
                    ) : (
                        <>
                            <BootstrapDialogTitle component="h3" sx={{ paddingLeft: '20px', paddingTop: '0px', paddingBottom: '0px' }} onClose={closeDialog}>
                                <h3 className="header-title">Dela session?</h3>
                            </BootstrapDialogTitle>
                            <DialogContent dividers>
                                <Typography component={'h2'} gutterBottom style={{ display: 'flex', justifyContent: 'center', width: 480, minHeight: 200, }}>
                                    Session delad!
                                </Typography>

                            </DialogContent>
                        </>
                    )}</>
            ) : (
                <>
                    <BootstrapDialogTitle component="h3" sx={{ paddingLeft: '10px', paddingTop: '0px', paddingBottom: '0px' }} onClose={closeDialog}>
                        <h3 className="header-title">Dela session?</h3>
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Typography component={'h2'} gutterBottom style={{ display: 'flex', justifyContent: 'center', width: 480, minHeight: 200, }}>
                            <Stack>
                                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress size={25} /></div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>Skickar...</div>
                            </Stack>
                        </Typography>

                    </DialogContent>
                </>
            )}

        </BootstrapDialog>
    )
};