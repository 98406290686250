import { ref, remove } from "firebase/database";
import { SESSION_PATH, SESSION_SHUNT_PATH } from "../../constants/firebasePaths";

const deleteShunt = function deleteShunt(database, shuntID, sessionID, sessionParams) {
    const shuntRef = ref(database, SESSION_SHUNT_PATH + shuntID);
    const sessionListRef = ref(database, SESSION_PATH + sessionID + "/SessionShunts/" + shuntID);
    const sessionDesgRef = ref(database, SESSION_PATH + sessionID + "/shuntDesignations/" + shuntID);

    const sessionCustomShuntRef = ref(database, SESSION_PATH + sessionID + "/CustomAddedShunts/" + shuntID);
    const sessionModifiedShuntRef = ref(database, SESSION_PATH + sessionID + "/ModifiedShunts/" + shuntID);

    const promiseDelete = remove(shuntRef);
    const promiseDeleteSes = remove(sessionListRef);
    const promiseDeleteDesg = remove(sessionDesgRef);
    const promiseDeleteCustom = remove(sessionCustomShuntRef);
    const promiseDeleteModified = remove(sessionModifiedShuntRef);

    sessionParams.dispatch({ type: 'removeSessionShunt', payload: shuntID })
    return Promise.all([promiseDelete, promiseDeleteSes, promiseDeleteDesg, promiseDeleteCustom, promiseDeleteModified]);
}

export { deleteShunt }