import { useEffect } from "react";
import { useAdjustParams, useDriftParams, useErrorContext, useModifySpecContext, useNoticeContext, useProductLineParams, usePumpParams, useShuntParams } from "../../contexts"
import { useCalcParams } from "../../contexts/calcParams";
import { useSessionParams } from "../../contexts/SessionParams";
import { AdjustmentsEnum, CalcParamsEnum, DriftParamEnum, ModifySpecEnum, ProductLineEnum, SessionParamsEnum, ShuntParamsEnum } from "../../enums";
import { PumpParamEnum } from "../../enums/pumpParamsEnum";
import { useWizardParams } from "../../contexts/wizardParams";
import { WizardParamEnum } from "../../enums/wizardParamsEnum";

export const ClearContext = ({ clearSession = false }) => {
    const adjustParams = useAdjustParams();
    const calcParams = useCalcParams();
    const driftParams = useDriftParams();
    const errorContext = useErrorContext();
    const noticeContext = useNoticeContext();
    const productLine = useProductLineParams();
    const pumpParams = usePumpParams();
    const modifySpecParams = useModifySpecContext();
    const shuntParams = useShuntParams();
    const sessionParams = useSessionParams();
    const wizardParams = useWizardParams();

    const clearContexts = () => {
       
        if (SessionParamsEnum.IsApiSession.getContextValue(sessionParams) !== true) {
            //console.log("Clear! - ClearContext");
            AdjustmentsEnum.clearData(adjustParams);
            CalcParamsEnum.clearData(calcParams);
            DriftParamEnum.clearData(driftParams);
            ProductLineEnum.clearData(productLine);
            PumpParamEnum.clearData(pumpParams);

            errorContext.dispatch({ type: 'clearErrorList' })
            noticeContext.dispatch({ type: 'clearNoticeList' })

            if (clearSession === true) {
                ShuntParamsEnum.fullClearData(shuntParams);
                SessionParamsEnum.clearData(sessionParams);
                ModifySpecEnum.clearData(modifySpecParams);
                WizardParamEnum.clearData(wizardParams);
            }
            else {
                ShuntParamsEnum.clearData(shuntParams);
            }
        }
        else{
            console.log("Clear! - ClearContext (Api)");
            AdjustmentsEnum.clearData(adjustParams);
            ProductLineEnum.clearData(productLine);
            PumpParamEnum.clearData(pumpParams);
            CalcParamsEnum.clearData(calcParams);
            ShuntParamsEnum.apiClearData(shuntParams);
            WizardParamEnum.clearData(wizardParams);
        }

    }

    useEffect(() => {
        clearContexts();
    }, []);

    return (
        <></>
    )
}