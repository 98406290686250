import { Box, IconButton, Stack, TextField, TextareaAutosize, Tooltip, Typography, styled } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDriftParams, useProductLineParams, useSessionParams, useShuntParams } from "../../../../contexts"
import { DriftParamEnum, ProductLineEnum, SessionParamsEnum, ShuntParamsEnum } from "../../../../enums";
import { countDecimals } from "../../../../functions/numberFunctions";
import FeedIcon from '@mui/icons-material/Feed';
import { CUSTOM_SHUNT_DIM_LABELS, CUSTOM_SHUNT_FLOW_LABELS } from "../../../../constants/customShunts";
import { useDatabase, useUser } from "reactfire";
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import EditIcon from '@mui/icons-material/Edit';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { saveHeaderText } from "../../../../firebase/functions/saveTechRows";
import { useContextAuth } from "../../../../authContext/AuthHook";
import { HOVER_TEXT_OVERVIEW_ICON_SPEC_MODIFIED, HOVER_TEXT_SPEC_HEADER_MANUAL_EDIT_IS_CHANGED, HOVER_TEXT_SPEC_HEADER_MANUAL_EDIT_WARNING } from "../../../../constants/generalText/hoverTexts";
import ListIcon from '@mui/icons-material/List';

const CustomTextarea = styled(TextareaAutosize)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    minWidth: 250,
    maxWidth: 420,
    minHeight: 15,
    maxHeight: 25,
    overflowY: 'auto',
    resize: 'horizontal',
    fontSize: "13px",
    fontFamily: theme.typography.fontFamily,
    '&:focus': {
        outline: 'none',
        borderColor: theme.palette.primary.main,
        boxShadow: `0 0 0 0.2rem ${theme.palette.primary.light}`,
    },
}));

export const InspectPageHeader = ({ useCurrent = true, shuntData = null }) => {
    const { user } = useContextAuth();
    const database = useDatabase();
    const shuntParams = useShuntParams();
    const driftParams = useDriftParams();
    const productLineParams = useProductLineParams();
    const sessionParams = useSessionParams();

    const [headerText, setHeaderText] = useState('');
    const [editText, setEditText] = useState('');
    const [isCustom, setIsCustom] = useState(false);

    const [isEdit, setIsEdit] = useState(false);
    const [reset, setReset] = useState(false);
    const [saveTrigger, setSaveTrigger] = useState(false);
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        if (SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true) {
            //console.log("Data: ", shuntData)
            let savedHeaderText;
            let position;
            let desig;
            let plPrincip;
            let exchanger;
            let primFlow;
            let secFlow;

            let isCustom = false;

            if (useCurrent === true) {
                //console.log("Current")
                position = ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams);
                desig = ShuntParamsEnum.ShuntDesignation.getContextValue(shuntParams);
                plPrincip = ProductLineEnum.ProductPrincip.getContextValue(productLineParams);
                exchanger = ShuntParamsEnum.Exchanger.getContextValue(shuntParams);
                primFlow = Number(DriftParamEnum.PrimFlow.getContextValue(driftParams));
                secFlow = Number(DriftParamEnum.SecFlow.getContextValue(driftParams));

                isCustom = ShuntParamsEnum.IsCustomShunt.getContextValue(shuntParams);
            }
            else if (shuntData != null) {
                savedHeaderText = shuntData?.SpecData?.headerText;
                position = shuntData?.Baseinfo?.position;
                desig = shuntData?.Baseinfo?.designation;
                plPrincip = shuntData?.PLData?.princip;
                exchanger = shuntData?.ShuntData?.exchanger;

                primFlow = Number(shuntData?.DriftParams?.primFlow);
                secFlow = Number(shuntData?.DriftParams?.secFlow);

                isCustom = shuntData?.Baseinfo?.isCustom;

                //console.log("ShuntData", desig, primFlow, secFlow, isCustom)
            }

            setIsCustom(isCustom);
            let headerString = "";

            if (isCustom === true) {
                if (savedHeaderText == null || savedHeaderText === '' || reset === true) {
                    //console.log("Here?")
                    let customFlowString = "";
                    let customlabelString = "";
                    let labels;
                    if (exchanger != null && exchanger === 'KB') {
                        labels = CUSTOM_SHUNT_FLOW_LABELS[plPrincip + "-KB"];
                    }
                    else {
                        labels = CUSTOM_SHUNT_FLOW_LABELS[plPrincip];
                    }

                    if (labels != null && shuntData?.DriftParams != null) {
                        //console.log("Labels:", labels, plPrincip)
                        Object.keys(labels).forEach((key, index) => {
                            let label = labels[key];
                            let customFlow = shuntData?.DriftParams[key + 'Flow'];
                            if (customFlow != null) {
                                let flowText;
                                if (countDecimals(Number(customFlow)) < 2) {
                                    flowText = String(Number(customFlow).toFixed(2));
                                }
                                else {
                                    flowText = String(customFlow);
                                }
                                customFlowString += flowText;
                                customlabelString += label;
                            }
                            else {
                                customFlowString += '_._';
                                customlabelString += label;
                            }

                            if (index !== Object.keys(labels).length - 1) {
                                customFlowString += "/";
                                customlabelString += "/";
                            }
                        })
                    }

                    if (customlabelString !== '') {
                        customlabelString = "(" + customlabelString + ")";
                    }
                    else {
                        customlabelString = "(prim/sek)";
                    }

                    headerString = 'Pos ' + position + ', ' + desig + ', ' + customFlowString + ' l/s ' + customlabelString;

                    setHeaderText(headerString);
                    setEditText(headerString);
                    setSaveTrigger(true);
                }
                else {
                    setHeaderText(savedHeaderText);
                    setEditText(savedHeaderText);
                }
            }
            else {
                //console.log("Here!")
                let primText;
                let secText;
                if (countDecimals(primFlow) < 2) {
                    primText = String(primFlow.toFixed(2));
                }
                else {
                    primText = String(primFlow);
                }

                if (countDecimals(secFlow) < 2) {
                    secText = String(secFlow.toFixed(2));
                }
                else {
                    secText = String(secFlow);
                }

                headerString = 'Pos ' + position + ', ' + desig + ', ' + primText + '/' + secText + ' l/s (prim/sek)';

                setHeaderText(headerString);
            }
        }
    }, [SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams), SessionParamsEnum.CurrentShunt.getContextValue(sessionParams), ShuntParamsEnum.ShuntDesignation.getContextValue(shuntParams), ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams), shuntData, reset, update])

    useEffect(() => {
        if (saveTrigger === true) {
            saveHeader();
            if (reset === true) {
                setReset(false);
            }
        }
    }, [saveTrigger]);

    const saveHeader = () => {
        setHeaderText(editText);

        if (shuntData.NO_ID_FIELD != null) {
            saveHeaderText(database, shuntData.NO_ID_FIELD, SessionParamsEnum.SessionID.getContextValue(sessionParams), editText);
        }

        setIsEdit(false);
        setUpdate(!update);
    }

    const isModifiedSpecList = () => {
        if (user?.isAnonymous !== true && user?.isInternal === true) {
            if (shuntData?.SpecData?.specList != null || shuntData?.SpecData?.connList || shuntData?.SpecData?.measurementList != null || shuntData?.SpecData?.techList) {
                return true;
            }
        }
        return false;
    }

    const isModifiedSpecListChanged = () => {
        if (user?.isAnonymous !== true && user?.isInternal === true) {
            if (shuntData?.SpecData?.specList != null || shuntData?.SpecData?.connList || shuntData?.SpecData?.measurementList != null || shuntData?.SpecData?.techList) {
                if (shuntData?.SpecData?.isModified != null && shuntData?.SpecData?.isModified === true) {
                    return true;
                }
            }
        }

        return false;
    }

    return (
        <div>
            <Stack direction={'row'} spacing={1} justifyContent={"flex-start"} alignItems={"center"} style={{ paddingBottom: "5px" }}>
                {isCustom ? (
                    <Tooltip title="Manuell shuntgrupp" arrow placement="top">
                        <span>
                            <FeedIcon style={{ color: '#06559e', paddingTop: '2px' }} />
                        </span>
                    </Tooltip>
                ) : ("")}

                {isEdit ? (
                    <Fragment>
                        <CustomTextarea
                            value={editText}
                            onChange={(e) => { setEditText(e.target.value) }}
                        />
                    </Fragment>
                ) : (
                    <Typography component={'h3'}>
                        <u><strong>{headerText}</strong></u>
                    </Typography>
                )}


                {isCustom && user?.isAnonymous !== true && user?.isInternal === true ? (
                    <Fragment>
                        {isEdit === false ? (
                            <Fragment>
                                <IconButton style={{ marginLeft: '10px' }} size="small" onClick={() => { setIsEdit(true) }}>
                                    <EditIcon sx={{ "&:hover": { color: "#9e9e9e" }, fontSize: '12px' }} htmlColor={"#d9d9d9"} />
                                </IconButton>
                                <IconButton style={{ marginLeft: '-3px' }} size="small" onClick={() => { setReset(true) }}>
                                    <SettingsBackupRestoreIcon sx={{ "&:hover": { color: "#9e9e9e" }, fontSize: '12px' }} htmlColor={"#d9d9d9"} />
                                </IconButton>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <IconButton style={{ marginLeft: '10px' }} size="small" onClick={saveHeader}>
                                    <SaveIcon sx={{ "&:hover": { color: "#9e9e9e" }, fontSize: '12px' }} htmlColor={"#d9d9d9"} />
                                </IconButton>
                                <IconButton style={{ marginLeft: '-3px' }} size="small" onClick={() => { setIsEdit(false) }}>
                                    <CloseIcon sx={{ "&:hover": { color: "#9e9e9e" }, fontSize: '12px' }} htmlColor={"#d9d9d9"} />
                                </IconButton>
                            </Fragment>
                        )}

                    </Fragment>
                ) : ("")}

                {isModifiedSpecList() ? (
                    <>
                        {isModifiedSpecListChanged() ? (
                            <Box paddingLeft={'10px'}>
                                <Tooltip arrow placement="right" title={HOVER_TEXT_SPEC_HEADER_MANUAL_EDIT_IS_CHANGED}>
                                    <WarningAmberRoundedIcon sx={{ color: "#da3e3e" }} />
                                </Tooltip>
                            </Box>
                        ) : (
                            <Box paddingLeft={'10px'}>
                                <Tooltip arrow placement="right" title={HOVER_TEXT_SPEC_HEADER_MANUAL_EDIT_WARNING}>
                                    <ListIcon sx={{ color: "#ff9900" }} />
                                </Tooltip>
                            </Box>
                        )}
                    </>

                ) : ("")}
            </Stack>

        </div>
    )
}