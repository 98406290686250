import { push, ref } from "firebase/database";
import { SessionParamsEnum } from "../../enums/sessionParamsEnum";

const reportError = function reportError(database, sessionID, errorMsg, errorStack) {
    const timestamp = new Date();

    const errorRef = ref(database, "FatalErrors/");
    let snapData = {
        sessionID: sessionID,
        timestamp: timestamp.toISOString(),
        errorMsg: errorMsg,
        errorStack: errorStack,
    };


    const promises = [];

    promises.push(push(errorRef, snapData));

    return Promise.all(promises);
}

export { reportError } 