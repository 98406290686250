import { ref, update } from "firebase/database";
import { SESSION_PATH } from "../../constants/firebasePaths";
import { SL_IS_DEMO, SL_VERSION } from "../../constants/generalText/generalInfo";


const saveOwnerAndVersion = function saveOwnerAndVersion(sessionID, database, user) {
    const sessionRef = ref(database, SESSION_PATH + sessionID);

    if (sessionID != null && sessionID !== ""){
        let datasnap = {
            versionSL: SL_VERSION,
            isBetaVersion: SL_IS_DEMO
        };

        console.log("User: ", user);
        if (user != null && user.isAnonymous === false){
            datasnap['owner'] = user.id;
        }
        else{
            datasnap['owner'] = "Anonymous";
        }

        return update(sessionRef, datasnap);
    }
    else{
        return Promise.reject('SessionID is null');
    }
};

export { saveOwnerAndVersion } 