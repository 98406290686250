export function countDecimals(value) {
    if (isNaN(value)) {
        return 0;
    }
    else {
        // eslint-disable-next-line eqeqeq
        if ((value % 1) != 0)
            return value.toString().split(".")[1].length;
        return 0;
    }
}

export function roundNearest100(num) {
    return Math.ceil(num / 100) * 100;
}

export function numberWithSpaceSeparator(num) {
    if (num != null){
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    else{
        return num;
    }
 
}