import { get, ref } from "firebase/database";
import { SESSION_PATH } from "../../constants/firebasePaths";
import { SessionParamsEnum } from "../../enums";

const getSessionCustomData = async function getSessionCustomData(database, sessionParams) {
    const sessionID = SessionParamsEnum.SessionID.getContextValue(sessionParams);

    const shuntsRef = ref(database, SESSION_PATH + sessionID + '/CustomData');

    const result = await get(shuntsRef);

    return result.val();
}

const getSessionCurrentShunt = async function getSessionCurrentShunt(database, sessionParams) {
    const sessionID = SessionParamsEnum.SessionID.getContextValue(sessionParams);

    const shuntsRef = ref(database, SESSION_PATH + sessionID + '/CurrentShunt');

    const result = await get(shuntsRef);

    return result.val();
}

export { getSessionCustomData, getSessionCurrentShunt };