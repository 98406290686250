import { roundNumber } from "./helpFunctions/roundTo4Decimals";

/**
 * Calculate the primary flow in l/s
 * @param {number} primTempIn Primary temperature in (*C)
 * @param {number} primTempOut Primary temperature out (*C)
 * @param {number} secTempIn Secondary temperature in (*C)
 * @param {number} secTempOut Secondary temperature out (*C)
 * @param {number} secFlow The Flow in the secondary system (l/s)
 * @returns {number} The calculated flow in l/s
 */
const calcPrimFlow = function calcPrimFlow(primTempIn, primTempOut, secTempIn, secTempOut, secFlow) {
    if (primTempIn - primTempOut === 0) {
        return secFlow;
    }
    else {
        return roundNumber(secFlow * (secTempIn - secTempOut) / (primTempIn - primTempOut));
    }
}

export { calcPrimFlow }