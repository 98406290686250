import { Box, Button, DialogActions, DialogContent, LinearProgress, Modal, Stack, TextField, Typography } from "@mui/material"
import { BootstrapDialog, BootstrapDialogTitle } from "../../utils/bootstrapDialog"
import { useContextAuth } from "../../../authContext/AuthHook";
import { useState } from "react";
import { inactivateUser } from "../../../firebase/functions/cloudFunctions/inactivateAccount";
import { useSessionParams } from "../../../contexts";
import { SessionParamsEnum } from "../../../enums";


export const ConfirmIncativateAccountModal = ({ open, handleClose }) => {
    const { user, handleInactivateAccount } = useContextAuth();
    const sessionParams = useSessionParams();
    
    const [loading, setLoading] = useState(false);
    const [inputEmail, setInputEmail] = useState('');

    const handleDecline = () => {
        handleClose();
        setInputEmail('');
    }

    const handleConfirm = () => {
        if (inputEmail === user?.email){
            setLoading(true);
            // @ts-ignore
            handleInactivateAccount().then(() => {
                handleDecline();
                SessionParamsEnum.OpenOverview.setContextValue(sessionParams, false);
            }).catch((err) => {
                console.error("Error: ", err);
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    return (
        <BootstrapDialog
            style={{
                minWidth: 540,
                minHeight: 540,
            }}
            onClose={handleDecline}
            aria-labelledby="customized-dialog-title"
            open={open}
        >

            <BootstrapDialogTitle component="h3" sx={{ paddingLeft: '20px', paddingTop: '0px', paddingBottom: '0px' }} onClose={handleDecline}>
                <h3 className="header-title">Inaktivera kontot?</h3>
            </BootstrapDialogTitle>
            <DialogContent>
                <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center', paddingBottom: '1px', width: 350 }}>
                    Är du säker att du vill inaktivera det här kontot?
                </Typography>
                <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center', paddingBottom: '1px', width: 350 }}>
                    Fyll i Email-adressen för att bekräfta.
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <TextField
                        sx={{ width: '250px' }}
                        value={inputEmail}
                        onChange={(e) => setInputEmail(e.target.value)}
                        label={user?.email || ''}
                    />
                </Box>


            </DialogContent>
            <Box height={'5px'}>
                {loading ? (
                    <LinearProgress />
                ) : ("")}
            </Box>
            <DialogActions>
                <Stack direction={'row'} spacing={2}>
                    <Button className="danger-btn-outlined" disabled={inputEmail !== user.email || loading} onClick={handleConfirm}>
                        Inaktivera
                    </Button>
                    <Button variant="outlined" onClick={handleDecline} disabled={loading}>
                        Avbryt
                    </Button>
                </Stack>
            </DialogActions>
        </BootstrapDialog>
    )
}