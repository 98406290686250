
export class NoticeMsg {
    //Params 
    static param_designationExists = new NoticeMsg("param_designationExists", "En shuntgrupp finns redan med denna beteckning i sessionen.")

    //Temperature Notices
    static temp_equal = new NoticeMsg("temp_equal", "Alla temperaturer är lika. Kontrollera att korrekta temperaturvärden angivits.");
    static temp_tempIncrease = new NoticeMsg("temp_tempIncrease", "Temperaturen ökar i sekundärkretsen (indikerar kylshunt), medan temperaturvärdena indikerar värmeshunt. Kontrollera att korrekta temperaturer angivits på korrekt position.")
    static temp_tempDecrease = new NoticeMsg("temp_tempDecrease", "Temperaturen sjunker i sekundärkretsen (indikerar värmeshunt), medan temperaturvärdena indikerar kylshunt. Kontrollera att korrekta temperaturer angivits på korrekt position.")

    //Pressure Errors
    static pressure_toLowPrimDrivingPressure_SR3 = new NoticeMsg("pressure_toLowPrimDrivingPressure_SR3", "Angivna tillgängligt drivtryck är lågt varför shunttyp PR-3 rekommenderas, alternativt högre tillgängligt drivtryck primärt anges.")
    static pressure_primInternalPressureDropToHighNoDp = new NoticeMsg("pressure_primInternalPressureDropToHighNoDp", "Notera högt tryckfall primärt. Kontrollera rördimension primärt i förhållande till aktuellt flöde.")
    static pressure_secInternalPressureDropToHigh_SR = new NoticeMsg("pressure_secInternalPressureDropToHigh_SR", "Notera högt tryckfall sekundärt. Kontrollera rördimension sekundärt i förhållande till aktuellt flöde.");
    static pressure_secMountedCvInternalPressureDropToHigh_dim = new NoticeMsg("pressure_secMountedCvInternalPressureDropToHigh_dim", "Notera högt tryckfall sekundärt. Kontrollera rördimension sekundärt i förhållande till aktuellt flöde.");
    static pressure_secMountedCvInternalPressureDropToHigh_cv = new NoticeMsg("pressure_secMountedCvInternalPressureDropToHigh_cv", "Notera högt tryckfall över styrventilen. Kontrollera kvs-värde för vald styrventil.");

    //Manual mode Notices
    static manual_noBackVent = new NoticeMsg("manual_noBackVent", "Utförandets funktionalitet är mer känsligt för pumphaveri på sekundärsidan")
    static manual_cvPressureDropLowUnknownAuthority = new NoticeMsg("manual_cvPressureDropLowUnknownAuthority", "Observera lågt tryckfall över styrventil. Ange efk (om känt) för att kontrollera auktoritet, alternativt välj lägre kvs-värde för styrventil.")

    //Green-specific Notices
    static green_toLowKvs = new NoticeMsg("green_toLowKvs", "Obs! 3-vägs styrventil i gjutjärn kan inte fås med kvs < 1,6. Shuntgruppen förses istället med styrventil i mässing, vilket noteras i specifikationen.")

    //Flow-Specific Notices
    static flow_primFlowLowerThanSecAbove7kPa_PR2 = new NoticeMsg("flow_primFlowLowerThanSecAbove7kPa_PR2", "Valda temperaturer ger lägre flöde primärt än sekundärt vilket försämrar styrventilens reglerområde. Shunttyp SR-2 kan vara att föredra.");
    static flow_primFlowLowerThanSecBelow7kPa_PR2 = new NoticeMsg("flow_primFlowLowerThanSecBelow7kPa_PR2", "Valda temperaturer ger lägre flöde primärt än sekundärt vilket försämrar styrventilens reglerområde.");
    static flow_primFlowLowerThanSec_PR3 = new NoticeMsg("flow_primFlowLowerThanSec_PR3", "Valda temperaturer ger lägre flöde primärt än sekundärt vilket försämrar styrventilens reglerområde.");
    static flow_primFlowHigherThanSec_SR3 = new NoticeMsg("flow_primFlowHigherThanSec_SR3", "Valda temperaturer ger högre flöde primärt än sekundärt vilket försämrar styrventilens reglerområde.");

    //Flex-specific Notices
    static flex_safetyMargin_man = new NoticeMsg('flex_safetyMargin', 'Styrventilen måste vara mer än 86% öppen och får för liten marginal');
    static flex_lowFlowChangeModel = new NoticeMsg("flow_primFlowLowChangeModel", "På grund av lågt flöde så har styrventilen ändrats till en IMI TA Compact-P.");

    //Smart-specific Notices
    static smart_flowCloseLow = new NoticeMsg("smart_flowCloseLow", "Flödet är nära ventilens minimiflöde. Gå ner en dimension via manuellt läge om ytterligare marginal önskas.");
    static smart_flowCloseHigh = new NoticeMsg("smart_flowCloseHigh", "Flödet är nära ventilens maxflöde. Gå upp en dimension via manuellt läge om ytterligare marginal önskas.");

    //Other unspecific Notices

    //Calc Notices
    static calc_authToLow = new NoticeMsg('calc_AuthToLow', 'Obs! Auktoriteten är mindre än 20%.');

    /**
     * 
     * @param {string} key 
     * @param {string} msg 
     */
    constructor(key, msg) {
        this.key = key;
        this.msg = msg;
    }
}
