import { Box, Button, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { useContextAuth } from "../../../authContext/AuthHook"
import { useEffect, useState } from "react";

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ConfirmIncativateAccountModal } from "./confirmIncativateAccountModal";
import { ConfirmSendResetPasswordEmail } from "./confirmSendResetPasswordEmail";
import { NOT_CHANGABLE_TEXT } from "../../../constants/generalText/accountInfo";

export const MyProfileMainComponent = () => {
    const { user, handleResetPassword, handleInactivateAccount } = useContextAuth();

    const [openInactive, setOpenInactive] = useState(false);
    const handleOpenInactiveConfirm = () => setOpenInactive(true);
    const handleCloseInactiveConfirm = () => setOpenInactive(false);

    const [openChangePass, setOpenChangePass] = useState(false);
    const handleOpenChangePass = () => setOpenChangePass(true);
    const handleCloseChangePass = () => setOpenChangePass(false);

    return (
        <>
            <Box padding={2}>
                <Stack spacing={2}>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <Typography width={'65px'} fontSize={'1rem'}>
                            Email:
                        </Typography>
                        <Box sx={{ cursor: 'text' }} fontSize={'0.9rem'} borderRadius={1} height={'18px'} width={'200px'} padding={'5px'} border={'1px solid #b0b0b0'}>
                            {user?.email}
                        </Box>
                        <Tooltip arrow placement="right" title={NOT_CHANGABLE_TEXT}>
                            <InfoOutlinedIcon />
                        </Tooltip>

                    </Stack>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <Typography width={'65px'} fontSize={'1rem'}>
                            Namn:
                        </Typography>
                        <Box sx={{ cursor: 'text' }} fontSize={'0.9rem'} borderRadius={1} height={'18px'} width={'200px'} padding={'5px'} border={'1px solid #b0b0b0'}>
                            {user?.name}
                        </Box>
                        <Tooltip arrow placement="right" title={NOT_CHANGABLE_TEXT}>
                            <InfoOutlinedIcon />
                        </Tooltip>
                    </Stack>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <Typography width={'65px'} fontSize={'1rem'}>
                            Företag:
                        </Typography>
                        {user?.isInternal === true ? (
                            <>
                                <Box sx={{ backgroundColor: '#f0f8ff' }} fontSize={'0.9rem'} borderRadius={1} height={'18px'} width={'200px'} padding={'5px'} border={'1px solid #b0b0b0'}>
                                    PREMA (intern användare)
                                </Box>
                                <Tooltip arrow placement="right" title={NOT_CHANGABLE_TEXT}>
                                    <InfoOutlinedIcon />
                                </Tooltip>
                            </>
                        ) : (
                            <>
                                <Box sx={{ cursor: 'text' }} fontSize={'0.9rem'} borderRadius={1} height={'18px'} width={'200px'} padding={'5px'} border={'1px solid #b0b0b0'}>
                                    {user?.company}
                                </Box>
                                <Tooltip arrow placement="right" title={NOT_CHANGABLE_TEXT}>
                                    <InfoOutlinedIcon />
                                </Tooltip>
                            </>
                        )}

                    </Stack>
                    <Divider />
                    <Box>
                        <Button onClick={handleOpenChangePass} variant="contained">
                            Byt lösenord
                        </Button>
                    </Box>


                    {user?.isInternal !== true ? (
                        <>

                            <Divider />
                            <Box>
                                <Button onClick={handleOpenInactiveConfirm} className="danger-btn" variant="contained">
                                    Inaktivera konto
                                </Button>
                            </Box>
                        </>

                    ) : ("")}
                </Stack >
            </Box >

            <ConfirmIncativateAccountModal open={openInactive} handleClose={handleCloseInactiveConfirm} />
            <ConfirmSendResetPasswordEmail open={openChangePass} handleClose={handleCloseChangePass} />
        </>
    )
}