import { doc, getDoc } from "firebase/firestore";

const getPriceWallMount = async function getPriceWallMount(firestore, dim) {
    let wallMountDoc = doc(firestore, "/PriceValues/wallMount");

    const res = await getDoc(wallMountDoc);
    
    //console.log("Res:", dim, res.data().basePrice[dim], res.data().enumFactor);
    if (res.data().basePrice[dim] != null){
        return Math.round(Number(res.data().basePrice[dim] * Number(res.data().enumFactor)));
    }
    else{
        return null;
    }
    
}

export { getPriceWallMount }