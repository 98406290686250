export class ContextEnum {
    /**
     * @param {string} name 
     * @param {string} dispatchMethod 
     */
    constructor(name, dispatchMethod) {
        this.name = name;
        this.dispatchMethod = dispatchMethod;
    }

    getContextValue(contextParam) {
        if (contextParam.state?.val != null) {
            return contextParam.state?.val[this.name];
        }
        else {
            return '';
        }
    }

    setContextValue(contextParam, value, database = null, shuntID = null, sessionID = null) {
        contextParam.dispatch({ type: this.dispatchMethod, payload: value, database: database, shuntID: shuntID, sessionID: sessionID })
    }

    static clearData = function clearData(context) {
        context.dispatch({ type: "clear" });
    }
}