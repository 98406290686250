import { Button, IconButton, Menu, MenuItem, Stack, Tooltip, Typography, styled } from "@mui/material"
import { SessionParamsEnum, ShuntParamsEnum } from "../../../enums";
import { useEffect, useState } from "react";
import { useDatabase, useUser } from "reactfire";
import { useSessionParams, useShuntParams } from "../../../contexts";
import { useInitMagicloud } from "../../../hooks/useInitMagicloud";

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';

import revitLogo from '../../../assets/images/revit-logo.png'
import autocadLogo from '../../../assets/images/autocad-logo.png'
import { ref, set } from "firebase/database";
import { MAGICLOUD_ID_PATH, SESSION_SHUNT_PATH, SHUNT_MAGICLOUD_LINK_PATH } from "../../../constants/firebasePaths";

const StyledButtonDxf = styled(Button)(({ theme }) => ({
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '0px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '0px',
    borderColor: '#bababa',
    borderStyle: 'solid',
    borderWidth: '1px 1px 1px 1px',
    minWidth: '125px',
    height: '32px'
}));

const StyledButtonRfa = styled(Button)(({ theme }) => ({
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '4px',
    borderColor: '#bababa',
    borderStyle: 'solid',
    borderWidth: '1px 1px 1px 0',
    minWidth: '125px',
    height: '32px'
}));

export const MagicloudCadDownloadButtons = ({ productId, shuntData = null, smallButtons = false }) => {
    const user = useUser();
    const database = useDatabase();
    const shuntParams = useShuntParams();
    const sessionParams = useSessionParams();
    const {
        magiCadUser,
        loadModel,
        signInToMagicad,
        signOutFromMagicad,
    } = useInitMagicloud();

    //const [magiCadUser, setMagiCadUser] = useState(null);
    const [loadedModel, setLoadedModel] = useState('');
    const [modelExists, setModelExists] = useState(true);
    const [loading, setLoading] = useState(true);
    const [dxfLink, setDxfLink] = useState('');
    const [rfaLinks, setRfaLinks] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);


    const [update, setUpdate] = useState(false);
    const openRFAMenu = Boolean(anchorEl);

    const handleOpenRFAMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseRFAMenu = () => {
        setAnchorEl(null);
    };

    const downloadRFAVersion = (rfaLink) => {
        if (rfaLink != null && rfaLink !== "") {
            window.open(rfaLink, "_blank");
        }
        setAnchorEl(null);
    }

    const handleDownloadDxf = () => {
        if (magiCadUser != null) {
            if (dxfLink != null && dxfLink !== "") {
                window.open(dxfLink, "_blank");
            }
        }
        else {
            signInToMagicad();
        }
    }

    const handleDownloadRfa = (event) => {
        if (magiCadUser != null) {
            setAnchorEl(event.currentTarget);
        }
        else {
            signInToMagicad();
        }
    }

    useEffect(() => {
        if (SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true) {
            if (productId != null && productId !== '' && productId !== loadedModel) {
                loadModel(productId).then((res) => {
                    setDxfLink(res.dxfLink);
                    setRfaLinks(res.rfaLinks);
                    setModelExists(res.modelExists);

                    if (shuntData != null && (shuntData?.ShuntData.magicloudLink == null || shuntData?.ShuntData.magicloudLink === "")) {
                        const magiCloudLinkRef = ref(database, SESSION_SHUNT_PATH + shuntData['NO_ID_FIELD'] + SHUNT_MAGICLOUD_LINK_PATH)
                        set(magiCloudLinkRef, res.permaLink);
                    }

                    setLoadedModel(productId);
                })
            }
        }
    }, [productId, loadedModel, magiCadUser, SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams)]);

    useEffect(() => {
        if (magiCadUser != null){
            setLoadedModel('');
        }
    }, [magiCadUser])

    return (
        <>
            {productId != null && productId !== "" ? (
                <>
                    {smallButtons ? (
                        <Stack direction={'row'} alignItems={'center'}>
                            <Tooltip arrow placement="top" title={modelExists === false ? "AutoCad Modell ej tillgänglig" : ""}>
                                <span>
                                    <IconButton disabled={modelExists === false} size="small" sx={{ height: '22px', width: "22px", opacity: modelExists === false ? 0.6 : 1 }} onClick={handleDownloadDxf}>
                                        <img height={'18px'} src={autocadLogo} alt="AutoCAD" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip arrow placement="top" title={modelExists === false ? "Revit Modell ej tillgänglig" : ""}>
                                <span>
                                    <IconButton disabled={modelExists === false} size="small" sx={{ height: '22px', width: magiCadUser != null ? '44px' : "22px" }} onClick={handleDownloadRfa}>
                                        <img style={{ marginLeft: magiCadUser != null ? '0px' : "0px" }} height={'18px'} src={revitLogo} alt="Revit" />
                                        {magiCadUser != null ? (
                                            <>
                                                {openRFAMenu ? (
                                                    <KeyboardArrowUpRoundedIcon htmlColor='#808080' sx={{ marginLeft: '0px' }} />
                                                ) : (
                                                    <KeyboardArrowDownRoundedIcon htmlColor='#808080' sx={{ marginLeft: '0px' }} />
                                                )}
                                            </>
                                        ) : ("")}
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Menu
                                anchorEl={anchorEl}
                                open={openRFAMenu}
                                onClose={handleCloseRFAMenu}
                            >
                                {rfaLinks.map((value) => {
                                    return (
                                        <MenuItem key={value?.Id} onClick={(e) => { downloadRFAVersion(value?.Uri) }}>{value?.Type}</MenuItem>
                                    )
                                })}
                            </Menu>
                        </Stack>
                    ) : (
                        <Stack direction={'row'} sx={{ paddingTop: '5px', paddingBottom: '10px' }}>
                            <StyledButtonDxf onClick={handleDownloadDxf}>
                                <img style={{ marginRight: '10px' }} height={'23px'} src={autocadLogo} alt="AutoCad" />
                                <Typography fontSize={16} color={'#808080'}>
                                    DXF
                                </Typography>
                            </StyledButtonDxf>
                            <StyledButtonRfa onClick={handleDownloadRfa}>
                                <img style={{ marginRight: '10px', marginLeft: magiCadUser != null ? '10px' : "0px" }} height={'23px'} src={revitLogo} alt="Revit" />
                                <Typography fontSize={16} color={'#808080'}>
                                    RFA
                                </Typography>
                                {magiCadUser != null ? (
                                    <>
                                        {openRFAMenu ? (
                                            <KeyboardArrowUpRoundedIcon htmlColor='#808080' sx={{ marginLeft: '10px' }} />
                                        ) : (
                                            <KeyboardArrowDownRoundedIcon htmlColor='#808080' sx={{ marginLeft: '10px' }} />
                                        )}
                                    </>
                                ) : ("")}
                            </StyledButtonRfa>
                            <Menu
                                anchorEl={anchorEl}
                                open={openRFAMenu}
                                onClose={handleCloseRFAMenu}
                            >
                                {rfaLinks.map((value) => {
                                    return (
                                        <MenuItem key={value?.Id} onClick={(e) => { downloadRFAVersion(value?.Uri) }}>{value?.Type}</MenuItem>
                                    )
                                })}
                            </Menu>
                        </Stack>
                    )}
                </>
            ) : (
                <></>
            )}
        </>

    )
}