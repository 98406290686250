
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import CalculateIcon from '@mui/icons-material/Calculate';

import { Paper, Stack, Typography, TableContainer, TableHead, TableBody, Button, CircularProgress, Table, TableRow, TableCell, Grid, Tooltip, Box, Skeleton } from '@mui/material';
import { collection } from 'firebase/firestore';
import { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDatabase, useFirestore, useUser, useFirestoreCollectionData } from 'reactfire';
import { SHUNT_DIMS_PATH } from '../../../constants/firebasePaths';
import { useSessionParams, useAdjustParams, useCalcParams, useDriftParams, useErrorContext, useNoticeContext, useProductLineParams, usePumpParams, useShuntParams } from '../../../contexts';
import { useFSData } from '../../../contexts/fsDataContext';
import { ErrorEnum, NoticeEnum, SessionParamsEnum, FSDataEnum } from '../../../enums';
import { calculateShuntParams } from '../../../functions/calculateShuntParams';
import { recalculateShunts } from '../../../functions/ShuntCalculations/helpFunctions/recalculateList';
import { EditShuntValue } from './editShuntValue';
import HelpModal from '../introductionModal/helpModal';
import { FirebaseShuntRow } from './components/firebaseShuntRow';
import { DISABLED_MSG_TEXT } from '../../../constants/generalText/sessionAPITexts';
import { CustomShuntModal } from '../customShunt/CustomShuntModal';
import { AddShuntModule } from '../addShunt/addShuntModule';
import { getSpecialFlexDim } from '../../../firebase/functions/getSpecialFlexDim';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useGetMagicloudIDs } from '../../../hooks/useGetMagicloudIDs';
import { useWizardParams } from '../../../contexts/wizardParams';
import { FirebaseAddonRow } from './components/firebaseAddonRow';
import { useGetEnergyMeasurementList } from '../../../hooks/useGetEnergyMeasurementList';
import { AddAddonModal } from '../addonModal/addAddonModal';
import { useGetSystemFluidOptions } from '../../../hooks/useGetSystemFluidOptions';

export const ShuntPanelOverview = ({ shuntList, loadingShunts, editMode, setEditMode, handleClose }) => {
    const errorlist = new ErrorEnum();
    const noticelist = new NoticeEnum();

    const sessionParams = useSessionParams();
    const database = useDatabase();
    const firestore = useFirestore();
    const navigate = useNavigate();

    const adjustParams = useAdjustParams();
    const calcParams = useCalcParams();
    const driftParams = useDriftParams();
    const errorContext = useErrorContext();
    const noticeContext = useNoticeContext();
    const productLine = useProductLineParams();
    const pumpParams = usePumpParams();
    const wizardParams = useWizardParams();
    const shuntParams = useShuntParams();
    const fsDataParams = useFSData();

    const systemFluidOptions = useGetSystemFluidOptions();

    const { magicloudIDs, loading } = useGetMagicloudIDs();
    const { heatEnergyMeasurementList, coolingEnergyMeasurementList } = useGetEnergyMeasurementList();

    const user = useUser();

    const [isLoading, setIsLoading] = useState(false);
    const [valueType, setValueType] = useState('');
    const [type, setType] = useState('');
    const [defaultValue, setDefaultValue] = useState('');
    const [shuntID, setShuntID] = useState('');

    const conTypeDoc = collection(firestore, SHUNT_DIMS_PATH);
    const firestoreRes = useFirestoreCollectionData(conTypeDoc);

    const [dimensionData, setDimensionData] = useState([]);

    const [openEditValue, setOpenEditValue] = useState(false);

    const handleOpenEditValue = (valueType, value, shuntID) => {
        setShuntID(shuntID);
        setDefaultValue(value);
        if (valueType === "Anläggning" || valueType === "Beteckning") {
            setType('text');
        }
        else {
            setType('number');
        }
        setValueType(valueType);
        setOpenEditValue(true);
    }

    const handleCloseEditValue = () => {
        setOpenEditValue(false);
    }

    useEffect(() => {
        if (firestoreRes.data != null) {
            //console.log("Data: ", firestoreRes.data)
            setDimensionData(firestoreRes.data);
        }

    }, [firestoreRes.data])

    const triggerEditMode = () => {
        setEditMode(!editMode)
    }

    const calculateShunts = async () => {
        setIsLoading(true);
        const dimData = await FSDataEnum.Dims.getContextValue(fsDataParams);
        const flexDimData = await FSDataEnum.FlexDims.getContextValue(fsDataParams);
        const smartDims = await FSDataEnum.SmartDims.getContextValue(fsDataParams);

        let specialDim = await getSpecialFlexDim(firestore);

        errorlist.clearAllErrors(errorContext);
        noticelist.clearAllNotices(noticeContext);
        calculateShuntParams(productLine, driftParams, calcParams, shuntParams, errorContext, noticeContext, dimensionData, database, sessionParams, wizardParams, systemFluidOptions, flexDimData, smartDims, null, specialDim, heatEnergyMeasurementList, coolingEnergyMeasurementList);

        recalculateShunts(shuntList, dimData, database, firestore, sessionParams, coolingEnergyMeasurementList, heatEnergyMeasurementList, systemFluidOptions).then(() => {
            setIsLoading(false);
            setEditMode(false);
            SessionParamsEnum.UpdateValues.setContextValue(sessionParams, !SessionParamsEnum.UpdateValues.getContextValue(sessionParams))
        });
    }

    const isDisabled = () => {
        if (SessionParamsEnum.Editable.getContextValue(sessionParams) === false) {
            return true;
        }
        else {
            return false;
        }
    }

    return (
        <>
            <Paper className='overview-main-panel'>
                <div className="overview-content-size">
                    <Stack spacing={1} style={{ width: "878px", paddingTop: "20px", paddingLeft: "12px" }}>
                        <Stack sx={{ paddingLeft: '13px' }} direction={'row'} justifyContent={'space-between'}>
                            <Box>
                                <div>
                                    <b>Anläggning:</b> {SessionParamsEnum.Facility.getContextValue(sessionParams)} {editMode && <span style={{ cursor: 'pointer', marginLeft: "10px" }} onClick={() => { handleOpenEditValue('Anläggning', SessionParamsEnum.Facility.getContextValue(sessionParams)) }}><EditIcon style={{ height: '15px', color: "#f7c03f" }} /></span>}
                                </div>
                                <div style={{ marginBottom: "10px" }}>
                                    <b>Projektnummer:</b> {SessionParamsEnum.ProjNr.getContextValue(sessionParams)} {editMode && <span style={{ cursor: 'pointer', marginLeft: "10px" }} onClick={() => { handleOpenEditValue('Projektnummer', SessionParamsEnum.ProjNr.getContextValue(sessionParams)) }}><EditIcon style={{ height: '15px', color: "#f7c03f" }} /></span>}
                                </div>
                            </Box>
                            <Box>
                                <HelpModal loc={"overview"} />
                            </Box>
                        </Stack>
                        {/* <Divider orientation="horizontal" flexItem /> */}
                        <TableContainer component={Paper} sx={{ width: 'fit-content' }}>
                            <Table sx={{ minWidth: 580, paddingRight: '5px' }} size="small" aria-label="customized table">

                                <TableHead>
                                    <TableRow className="shunt-table-header">
                                        <TableCell sx={{ width: 5 }}></TableCell>
                                        <TableCell sx={{ minWidth: 50, maxWidth: 55, padding: "7px 9px", textAlign: 'left' }}>Pos.</TableCell>
                                        <TableCell>Beteckning</TableCell>
                                        <TableCell>Modell</TableCell>
                                        <TableCell>Prod.linje</TableCell>
                                        <TableCell>Shunttyp</TableCell>
                                        <TableCell>Min/Max °C</TableCell>
                                        <TableCell>Drivtryck</TableCell>
                                        <TableCell>Prim Temp In</TableCell>
                                        {user?.data?.isAnonymous === false ? (
                                            <TableCell colSpan={2}>Tillägg</TableCell>
                                        ) : (
                                            <TableCell></TableCell>
                                        )}
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loadingShunts === true ? (
                                        <>
                                            {SessionParamsEnum.SessionShunts.getContextValue(sessionParams) != null ? (
                                                <>
                                                    {SessionParamsEnum.SessionShunts.getContextValue(sessionParams).map((row, index) => {
                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell colSpan={11} align='left'>
                                                                    <Skeleton animation="wave" variant="rectangular" width={780} height={'20px'} />
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </>
                                            ) : ("")}
                                        </>
                                    ) : (
                                        <>
                                            {shuntList.sort((a, b) => a.pos - b.pos).map((shunt) => {
                                                if (shunt?.isAddon === true) {
                                                    return (
                                                        <FirebaseAddonRow key={shunt.id} shuntID={shunt.id} editMode={editMode} handleClose={handleClose} magicloudIDs={magicloudIDs} loading={loading} />
                                                    );
                                                }
                                                else {
                                                    return (
                                                        <FirebaseShuntRow key={shunt.id} shuntID={shunt.id} editMode={editMode} openEditValue={handleOpenEditValue} handleClose={handleClose} magicloudIDs={magicloudIDs} loading={loading} />
                                                    );
                                                }
                                            })}
                                        </>
                                    )}

                                </TableBody>
                            </Table>
                        </TableContainer>

                        {SessionParamsEnum.IsApiSession.getContextValue(sessionParams) === true ? (
                            <Stack direction={'row'} alignItems={'center'} sx={{ paddingLeft: '5px', paddingTop: '5px' }}>
                                <Typography>
                                    Alla positioner måste vara granskade (
                                </Typography>
                                <CheckCircleIcon sx={{ paddingTop: '1px' }} fontSize="small" color="success" />
                                <Typography>
                                    ) för att uppdateringen ska kunna slutföras.
                                </Typography>
                            </Stack>

                        ) : ("")}

                        {isDisabled() !== true ? (
                            <>
                                <Stack direction={'row'} justifyContent={'center'}>
                                    {editMode ? (
                                        <div style={{ paddingTop: "10px", paddingLeft: "30px", paddingBottom: "20px" }}>
                                            {isLoading === true ? (
                                                <Button size="small" variant="contained" onClick={calculateShunts} disabled style={{ width: "230px", marginTop: '10px', alignSelf: 'flex-end' }}>
                                                    <CircularProgress size={15} style={{ marginRight: '5px' }} /> Uppdatera Shuntgrupper
                                                </Button>
                                            ) : (
                                                <Button size="small" variant="contained" onClick={calculateShunts} style={{ width: "230px", marginTop: '10px', alignSelf: 'flex-end' }}>
                                                    <CalculateIcon style={{ marginRight: '5px' }} />Uppdatera Shuntgrupper
                                                </Button>
                                            )}
                                        </div>
                                    ) : (
                                        <div style={{ paddingTop: "10px", paddingLeft: "30px", paddingBottom: "20px" }}>
                                            <Button size="small" variant="contained" onClick={triggerEditMode} style={{ width: "190px", marginTop: '10px', alignSelf: 'flex-end' }}>
                                                <EditIcon style={{ marginRight: '5px' }} />Snabbredigering
                                            </Button>
                                        </div>
                                    )}
                                </Stack>
                                <Stack spacing={17} direction={'row'} justifyContent="flex-start" alignItems="start" style={{ marginLeft: '100px', marginTop: '40px' }}>
                                    <Box width={'230px'} display={'flex'} justifyContent={'center'}>
                                        {SessionParamsEnum.IsApiSession.getContextValue(sessionParams) !== true ? (
                                            <AddShuntModule style={{ width: "220px", alignSelf: 'flex-end' }} handleClose={handleClose} />
                                        ) : (
                                            <Tooltip title={DISABLED_MSG_TEXT}>
                                                <span>
                                                    <Button size="small" variant="contained" disabled style={{ width: "220px", alignSelf: 'flex-end' }}>
                                                        <AddCircleOutlineIcon style={{ marginRight: '5px' }} />Lägg till shuntgrupp
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                        )}
                                    </Box>


                                    <Box>
                                        <Stack spacing={2}>
                                            <Box>
                                                <CustomShuntModal disabled={editMode} />
                                            </Box>
                                            <Box>
                                                <AddAddonModal disabled={editMode} />
                                            </Box>
                                        </Stack>

                                    </Box>



                                </Stack>

                            </>
                        ) : ("")}
                    </Stack>
                </div>
            </Paper>

            <EditShuntValue handleOnDecline={handleCloseEditValue} open={openEditValue} valueType={valueType} type={type} defaultValue={defaultValue} shuntID={shuntID} />
        </>
    )
}