import { useState } from "react"
import { Box, Button, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { BootstrapDialogTitle } from "../../utils/bootstrapDialog"
import { AddAddonMixingVesselContent } from "./AddonTypes/addAddonMixingVesselContent"

export const AddAddonModalContent = ({ handleModalClose }) => {
    const [selectedType, setSelectedType] = useState('mixingVessel');
    const [addAddonTrigger, setAddAddonTrigger] = useState(false);
    const [isValid, setIsValid] = useState(false)
    const handleChangeType = (event) => {
        setSelectedType(event.target.value);
    }

    const displayAddonContent = () => {
        switch (selectedType) {
            case "mixingVessel":
                return (<AddAddonMixingVesselContent handleClose={handleModalClose} setIsValid={setIsValid} addAddonTrigger={addAddonTrigger} setAddonTrigger={setAddAddonTrigger} />);
            default:
                return "";
        }
    }

    const addAddonFunc = () => {
        setAddAddonTrigger(true);
    }

    return (
        <>
            <BootstrapDialogTitle onClose={handleModalClose}>
                Lägg till manuellt tillbehör
            </BootstrapDialogTitle>

            <DialogContent sx={{ width: '400px' }} dividers>
                <FormControl>
                    <InputLabel>
                        Tillbehör
                    </InputLabel>

                    <Select
                        sx={{ width: '200px' }}
                        label={'Tillbehör'}
                        value={selectedType}
                        onChange={handleChangeType}
                    >
                        <MenuItem value={'mixingVessel'}>
                            Blandningskärl
                        </MenuItem>
                    </Select>
                </FormControl>

                <Box marginTop={'10px'}>
                    {displayAddonContent()}
                </Box>
            </DialogContent>

            <DialogActions>
                <Button onClick={addAddonFunc} variant="outlined" disabled={isValid === false || addAddonTrigger === true}>
                    Lägg till
                </Button>
                <Button variant="outlined" onClick={handleModalClose}>Avbryt</Button>
            </DialogActions>
        </>
    )
}