import { httpsCallable } from "firebase/functions"

/**
 * 
 * @param {import("firebase/functions").Functions} functions 
 * @param {string} userID 
 * @returns 
 */
const inactivateUser = function inactivateUser(functions, userID) {
    functions.region = 'europe-west1';
    const inactivateUserFunc = httpsCallable(functions, "inactivateUser");

    return inactivateUserFunc({ 
        sentUserID: userID,
    });
}

export { inactivateUser }