import { Box, Button, DialogActions, DialogContent, LinearProgress, Modal, Stack, TextField, Typography } from "@mui/material"
import { BootstrapDialog, BootstrapDialogTitle } from "../../utils/bootstrapDialog"
import { useContextAuth } from "../../../authContext/AuthHook";
import { useState } from "react";


export const ConfirmSendResetPasswordEmail = ({ open, handleClose }) => {
    const { user, handleResetPassword } = useContextAuth();

    const [sent, setSent] = useState(false);

    const handleConfirm = () => {
        if (user?.email != null) {
            handleResetPassword(user?.email);
            setSent(true);
        }
    }

    const handleLocalClose = () => {
        handleClose();
        setSent(false);
    }

    return (
        <BootstrapDialog
            style={{
                minWidth: 540,
                minHeight: 540,
            }}
            onClose={handleLocalClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >

            <BootstrapDialogTitle component="h3" sx={{ paddingLeft: '20px', paddingTop: '0px', paddingBottom: '0px' }} onClose={handleLocalClose}>
                <h3 className="header-title">Byta lösenord?</h3>
            </BootstrapDialogTitle>
            <DialogContent>
                {sent ? (
                    <>
                        <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center', paddingBottom: '1px', width: 350 }}>
                            Mail har skickats.
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center', paddingBottom: '1px', width: 350 }}>
                            Vi kommer skicka ett mail med instruktioner för att byta lösenordet.
                        </Typography>
                        <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center', paddingBottom: '1px', width: 350 }}>
                            Gå vidare?
                        </Typography>
                    </>
                )}

            </DialogContent>
            <DialogActions>
                <Stack direction={'row'} spacing={2}>
                    {sent === false ? (
                        <Button variant="outlined" onClick={handleConfirm}>
                            Skicka mail
                        </Button>
                    ) : ("")}

                    <Button variant="outlined" onClick={handleLocalClose}>
                        {sent === false ? (
                            "Avbryt"
                        ) : (
                            "Stäng"
                        )}
                    </Button>
                </Stack>
            </DialogActions>
        </BootstrapDialog>
    )
}