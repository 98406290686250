import { useState } from "react";
import { OverviewPanels } from "../../../constants/overviewPanels";
import { SessionParamsEnum } from "../../../enums";
import { useSessionParams } from "../../../contexts";
import { MyProfilePanel } from "../overview/myProfilePanel";
import { MySessionHistoryPanel } from "../overview/mySessionHistoryPanel";
import { OverviewSidePanel } from "../overview/sidePanel";
import { Box, Fade, IconButton, Stack, Typography } from "@mui/material";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { MyPageSidePanel } from "./myPagesSidePanel";
import { NewSessionPanel } from "./newSessionPanel";

const style = {
    position: 'fixed',
    top: '490px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1118,
    height: '610px',
    maxHeight: 'auto',
    bgcolor: 'rgba(255,255,255,0.85)',
    border: '2px solid #E1E2E4',
    borderRadius: 2,
    boxShadow: 24,
    padding: "20px",
    overflow: 'hidden',
};

export const MyPagesOverview = ({ open, handleClose }) => {
    const sessionParams = useSessionParams();
    
    const [headerText, setHeaderText] = useState("Min sessionshistorik")
    
    const setHeader = (value) => {
        if (value !== headerText) {
            setHeaderText(value)
        }
    }
    
    const displayContent = () => {
        switch (SessionParamsEnum.OverviewPanel.getContextValue(sessionParams)) {
            case OverviewPanels.newSessionPanel:
                setHeader("Ny session")
                return (<NewSessionPanel />);
            case OverviewPanels.sessionHistoryPanel:
                setHeader("Min sessionshistorik")
                return (<MySessionHistoryPanel notInSession />);
            case OverviewPanels.myProfilePanel:
                setHeader("Mina sidor")
                return (<MyProfilePanel />);
            default:
                setHeader("Min sessionshistorik")
                return (<MySessionHistoryPanel />);
        }
    }

    return (
        <Fade in={open}>
            <Box sx={style} className="modal-size">
                <IconButton style={{ float: 'right', cursor: 'pointer', marginTop: '-20px', marginRight: '-20px' }} onClick={handleClose}>
                    <CloseRoundedIcon />
                </IconButton>
                <Typography id="transition-modal-title" component="h3" variant='h3' fontWeight="bold" className="header-title" sx={{ mb: '10px', color: "#183851" }}>
                    {headerText}
                </Typography>

                <Stack direction={'row'} spacing={2}>
                    <div style={{ height: 'auto', }}>
                        {displayContent()}
                    </div>
                    <MyPageSidePanel handleClose={handleClose} />

                </Stack>

            </Box>
        </Fade>
    );
}