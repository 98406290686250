import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";

import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import FileOpenRoundedIcon from '@mui/icons-material/FileOpenRounded';
import { useState } from "react";
import { ConfirmOpenNewSession } from "../confirmOpenNewSession";
import { useSessionParams } from "../../../../contexts";
import { SessionParamsEnum } from "../../../../enums";
import { NewSessionOptions } from "../newSessionOptions";

export const OpenSessionField = ({ value, row, notInSession }) => {
    const sessionParams = useSessionParams();
    const [openConfirm, setOpenConfirm] = useState(false);

    const handleOpenConfirm = () => setOpenConfirm(true);
    const handleCloseConfirm = () => setOpenConfirm(false);

    const [openSwitch, setOpenSwitch] = useState(false);

    const handleOpenSwitch = () => setOpenSwitch(true);
    const handleCloseSwitch = () => setOpenSwitch(false);

    const openInSession = () => {
        if (notInSession === true){
            handleOpenSwitch();
        }
        else{
            handleOpenConfirm();
        }
    }
 
    const openInNewTab = () => {
        const basePath = window.location.origin;

        const urlRef = basePath + "/link?session=" + row.id;

        console.log("Path 3: ", urlRef)
        window.open(urlRef, "_blank");
    }

    if (row.id === SessionParamsEnum.SessionID.getContextValue(sessionParams)) {
        return (
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Typography sx={{marginTop: '8px'}} color={'#06559e'} fontSize={'0.9rem'} fontWeight={700} fontStyle={'italic'}>
                    Aktiv
                </Typography>
            </Box>
        )
    }

    return (
        <>
            <Box>
                <Stack direction={'row'}>
                    <Tooltip arrow title="Öppna i ny flik">
                        <IconButton onClick={openInNewTab} size="small">
                            <OpenInNewRoundedIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip arrow title={notInSession ? "Öppna session" : "Växla session"}>
                        <IconButton onClick={openInSession} size="small">
                            <FileOpenRoundedIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Box>

            <ConfirmOpenNewSession sessionsID={row.id} open={openConfirm} handleClose={handleCloseConfirm} />
            <NewSessionOptions sessionsID={row.id} open={openSwitch} handleClose={handleCloseSwitch}  />
        </>
    )
};