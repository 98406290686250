import { ref, set } from 'firebase/database';
import { createContext, useReducer, useContext } from 'react'
import { SESSION_SHUNT_PATH, SHUNT_PROD_GREENLEVEL_PATH } from '../constants/firebasePaths';
import { PROD_GREEN_LEVEL, PROD_N_WAYS_VALVE, PROD_PRINCIP, PROD_PRODFAMILY, PROD_PRODLINE, PROD_SELECTED_PRINCIP, PROD_SELECTED_PRODLINE } from '../constants/keys/productLineKeys';
import { PRODUCTFAMILY_PR, PRODUCTFAMILY_SR, PRODUCTFAMILY_VA, PRODUCTPRINCIP_PR2, PRODUCTPRINCIP_PR3, PRODUCTPRINCIP_SR2, PRODUCTPRINCIP_SR2FLEX, PRODUCTPRINCIP_SR2FLEX_OLD, PRODUCTPRINCIP_SR2SMART, PRODUCTPRINCIP_SR2SMART_OLD, PRODUCTPRINCIP_SR3, PRODUCTPRINCIP_VA1 } from '../constants/productlineConstants';
import { PRODUCTLINE_PARAMS } from '../constants/sessionStoragePaths';

const ProductLine = createContext(undefined);

function productLineReducer(state, action) {
    switch (action.type) {
        case 'setProductLine': {
            let data = {};
            if (sessionStorage.getItem(PRODUCTLINE_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(PRODUCTLINE_PARAMS));
            }

            data[PROD_PRODLINE] = action.payload;
            sessionStorage.setItem(PRODUCTLINE_PARAMS, JSON.stringify(data));
            return { productLine: data }
        }
        case 'setPrincip': {
            let data = {};
            if (sessionStorage.getItem(PRODUCTLINE_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(PRODUCTLINE_PARAMS));
            }
            //console.log("Prod: ", action.payload)
            data[PROD_PRINCIP] = action.payload;
            if (action.payload === PRODUCTPRINCIP_SR2 || action.payload === PRODUCTPRINCIP_SR3) {
                if (action.payload === PRODUCTPRINCIP_SR2) {
                    data[PROD_N_WAYS_VALVE] = 2;
                }
                else {
                    data[PROD_N_WAYS_VALVE] = 3;
                }
                data[PROD_PRODFAMILY] = PRODUCTFAMILY_SR;
            }
            else if (action.payload === PRODUCTPRINCIP_PR2 || action.payload === PRODUCTPRINCIP_PR3) {
                if (action.payload === PRODUCTPRINCIP_PR2) {
                    data[PROD_N_WAYS_VALVE] = 2;
                }
                else {
                    data[PROD_N_WAYS_VALVE] = 3;
                }
                data[PROD_PRODFAMILY] = PRODUCTFAMILY_PR;
            }
            else if (action.payload === PRODUCTPRINCIP_VA1) {
                data[PROD_N_WAYS_VALVE] = 1;
                data[PROD_PRODFAMILY] = PRODUCTFAMILY_VA;
            }
            else if (action.payload === PRODUCTPRINCIP_SR2FLEX) {
                //console.log("Here")
                data[PROD_N_WAYS_VALVE] = 2;
                data[PROD_PRODFAMILY] = PRODUCTFAMILY_SR
            }
            else if (action.payload === PRODUCTPRINCIP_SR2SMART) {
                data[PROD_N_WAYS_VALVE] = 2;
                data[PROD_PRODFAMILY] = PRODUCTFAMILY_SR
            }

            sessionStorage.setItem(PRODUCTLINE_PARAMS, JSON.stringify(data));
            return { productLine: data }
        }
        case 'setProdFamily': {
            let data = {};
            if (sessionStorage.getItem(PRODUCTLINE_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(PRODUCTLINE_PARAMS));
            }

            data[PROD_PRODFAMILY] = action.payload;
            sessionStorage.setItem(PRODUCTLINE_PARAMS, JSON.stringify(data));
            return { productLine: data }
        }
        case 'setGreenLevel': {
            let data = {};
            //console.log("SetLevel!", action.database, action.shuntID)
            if (sessionStorage.getItem(PRODUCTLINE_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(PRODUCTLINE_PARAMS));
            }

            if (action.database != null && action.shuntID != null) {
                //console.log("SetLevel - Send!")
                const sessionShuntRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_PROD_GREENLEVEL_PATH);

                set(sessionShuntRef, action.payload).then(() => {
                    //console.log('SetLevel - Saved!')
                }).catch((error) => {
                    console.error("Error writing to DB: ", error);
                });
            }
            
            data[PROD_GREEN_LEVEL] = action.payload;
            sessionStorage.setItem(PRODUCTLINE_PARAMS, JSON.stringify(data));
            return { productLine: data }
        }
        case 'setNWaysValve': {
            let data = {};
            if (sessionStorage.getItem(PRODUCTLINE_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(PRODUCTLINE_PARAMS));
            }

            data[PROD_N_WAYS_VALVE] = action.payload;
            sessionStorage.setItem(PRODUCTLINE_PARAMS, JSON.stringify(data));
            return { productLine: data }
        }
        case 'setSelectedProductLine': {
            let data = {};
            if (sessionStorage.getItem(PRODUCTLINE_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(PRODUCTLINE_PARAMS));
            }

            data[PROD_SELECTED_PRODLINE] = action.payload;
            sessionStorage.setItem(PRODUCTLINE_PARAMS, JSON.stringify(data));
            return { productLine: data }
        }
        case 'setSelectedPrincip': {
            let data = {};
            if (sessionStorage.getItem(PRODUCTLINE_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(PRODUCTLINE_PARAMS));
            }

            data[PROD_SELECTED_PRINCIP] = action.payload;
            sessionStorage.setItem(PRODUCTLINE_PARAMS, JSON.stringify(data));
            return { productLine: data }
        }
        case 'clearProductLine': {
            let data = {};
            if (sessionStorage.getItem(PRODUCTLINE_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(PRODUCTLINE_PARAMS));
            }

            delete data.prodLine;
            sessionStorage.setItem(PRODUCTLINE_PARAMS, JSON.stringify(data));
            return { productLine: data }
        }
        case 'clearPrincip': {
            let data = {};
            if (sessionStorage.getItem(PRODUCTLINE_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(PRODUCTLINE_PARAMS));
            }

            delete data.princip;
            sessionStorage.setItem(PRODUCTLINE_PARAMS, JSON.stringify(data));
            return { productLine: data }
        }
        case 'clearGreenLevel': {
            let data = {};
            if (sessionStorage.getItem(PRODUCTLINE_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(PRODUCTLINE_PARAMS));
            }

            delete data.greenLevel;
            sessionStorage.setItem(PRODUCTLINE_PARAMS, JSON.stringify(data));
            return { productLine: data }
        }
        case 'clearProdFamily': {
            let data = {};
            if (sessionStorage.getItem(PRODUCTLINE_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(PRODUCTLINE_PARAMS));
            }

            delete data.prodFamily;
            sessionStorage.setItem(PRODUCTLINE_PARAMS, JSON.stringify(data));
            return { productLine: data }
        }
        case 'clearNWaysValve': {
            let data = {};
            if (sessionStorage.getItem(PRODUCTLINE_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(PRODUCTLINE_PARAMS));
            }

            delete data.nWaysValve;
            sessionStorage.setItem(PRODUCTLINE_PARAMS, JSON.stringify(data));
            return { productLine: data }
        }
        case 'clearSelectedProductLine': {
            let data = {};
            if (sessionStorage.getItem(PRODUCTLINE_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(PRODUCTLINE_PARAMS));
            }

            delete data.selectedProdLine;
            sessionStorage.setItem(PRODUCTLINE_PARAMS, JSON.stringify(data));
            return { productLine: data }
        }
        case 'clearSelectedPrincip': {
            let data = {};
            if (sessionStorage.getItem(PRODUCTLINE_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(PRODUCTLINE_PARAMS));
            }

            delete data.selectedPrincip;
            sessionStorage.setItem(PRODUCTLINE_PARAMS, JSON.stringify(data));
            return { productLine: data }
        }
        case 'setDataFromSnapshot': {
            let data = {};


            let princip = action.payload.child(PROD_PRINCIP).val() || undefined;
            if (princip === PRODUCTPRINCIP_SR2FLEX_OLD){
                princip = PRODUCTPRINCIP_SR2FLEX;
            }
            else if (princip === PRODUCTPRINCIP_SR2SMART_OLD){
                princip = PRODUCTPRINCIP_SR2SMART;
            }
            data[PROD_PRINCIP] = princip;
            data[PROD_PRODLINE] = action.payload.child(PROD_PRODLINE).val() || undefined;
            data[PROD_GREEN_LEVEL] = action.payload.child(PROD_GREEN_LEVEL).val() || undefined;

            if (princip === PRODUCTPRINCIP_SR2 || princip === PRODUCTPRINCIP_SR3) {
                if (princip === PRODUCTPRINCIP_SR2) {
                    data[PROD_N_WAYS_VALVE] = 2;
                }
                else {
                    data[PROD_N_WAYS_VALVE] = 3;
                }
                data[PROD_PRODFAMILY] = PRODUCTFAMILY_SR;
            }
            else if (princip === PRODUCTPRINCIP_PR2 || princip ===  PRODUCTPRINCIP_PR3) {
                if (princip === PRODUCTPRINCIP_PR2) {
                    data[PROD_N_WAYS_VALVE] = 2;
                }
                else {
                    data[PROD_N_WAYS_VALVE] = 3;
                }
                data[PROD_PRODFAMILY] = PRODUCTFAMILY_PR;
            }
            else if (princip === PRODUCTPRINCIP_VA1) {
                data[PROD_N_WAYS_VALVE] = 1;
                data[PROD_PRODFAMILY] = PRODUCTFAMILY_VA;
            }
            if (princip === PRODUCTPRINCIP_SR2FLEX) {
                data[PROD_N_WAYS_VALVE] = 2;
                data[PROD_PRODFAMILY] = PRODUCTFAMILY_SR;
            }
            if (princip === PRODUCTPRINCIP_SR2SMART) {
                data[PROD_N_WAYS_VALVE] = 2;
                data[PROD_PRODFAMILY] = PRODUCTFAMILY_SR;
            }

            sessionStorage.setItem(PRODUCTLINE_PARAMS, JSON.stringify(data));
            return { productLine: data }
        }
        case 'clear': {
            //console.log("Clear")
            sessionStorage.removeItem(PRODUCTLINE_PARAMS);
            return { productLine: {} }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function ProductLineProvider({ children }) {
    let obj = {};
    if (sessionStorage.getItem(PRODUCTLINE_PARAMS) != null) {
        obj = JSON.parse(sessionStorage.getItem(PRODUCTLINE_PARAMS));
    }

    const [state, dispatch] = useReducer(productLineReducer, { productLine: obj })
    const value = { state, dispatch }
    return (
        <ProductLine.Provider value={value}>
            {children}
        </ProductLine.Provider>);
}

function useProductLineParams() {
    const context = useContext(ProductLine)
    if (context === undefined) {
        throw new Error('useProductLineParams must be used within a ProductLineProvider')
    }
    return context
}


export { ProductLineProvider, useProductLineParams }