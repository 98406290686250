import { ACTUATORS_KEY, ADJUSTVALUE_KEY, CABINET_KEY, CONNECTED_DIFF_KEY, CONNECTED_TEMP_KEY, CONNECTION_DESIGN_KEY, CONNECTION_VALUE_KEY, CUTOFF_KEY, DRIPPLATE_KEY, ENERGYMEASUREMENT_KEY, INSULATION_KEY, MANOMETER_KEY, MATERIAL_KEY, MIXINGVESSELS_KEY, SHUNTSIZE_KEY, STAND_KEY, THERMO_KEY } from "./keys/adjustParamsKeys";
import { CONTROL_VALVE_KEY, MEASUREMENTDATA_KEY, PRIM_DIM_ROW_KEY, PRIM_INTERNAL_PRESSURE_DROP_KEY, SEC_DIM_ROW_KEY } from "./keys/calcParamsKeys";
import { FLOW_CALC_MODE_KEY, PRIM_DIM_KEY, PRIM_DRIVING_PRESSURE_KEY, PRIM_TEMP_IN_KEY, PRIM_TEMP_OUT_KEY, SEC_DIM_KEY, SEC_FLOW_EFFECT_KEY, USE_CONNECTED_ENERGY_KEY } from "./keys/driftParamsKeys";
import { PROD_GREEN_LEVEL, PROD_PRINCIP, PROD_PRODLINE } from "./keys/productLineKeys";
import { PUMP_GENERAL_REQUEST, PUMP_REQUEST_TYPE, PUMP_SPECIFIC_REQUEST } from "./keys/pumpKeys";
import { FACILITY_KEY, PROJNR_KEY } from "./keys/sessionParamsKeys";
import { ADDON_TYPE_KEY, ADDONS_KEY, CONNECTED_ENERGY_COMM_MODAL_KEY, CONNECTED_ENERGY_MODEL_KEY, CV_KVS_KEY, CV_MODEL_KEY, DESIGNATION_KEY, FLEX_DIM_ID_KEY, FLEX_MODEL_KEY, HAS_LATCH_KEY, IS_ADDON_KEY, IS_MINI_KEY, MAGICLOUD_LINK_KEY, MINI_INCOMPATIBLE_KEY, NUM_IV_KEY, PL_MODEL_KEY, POSITION_KEY, SHUNT_STRING_KEY, SMART_DIM_ID_KEY } from "./keys/shuntKeys";
import { WIZARD_APPLICATION_INTENT_KEY, WIZARD_APPLICATION_KEY, WIZARD_APPLICATION_SIZE_KEY, WIZARD_AVAIL_DRIVING_PRESSURE_KEY, WIZARD_CERTICIFACTIONS_KEY, WIZARD_ENERGY_MEASUREMENT_KEY, WIZARD_HAS_MAIN_PUMP_KEY, WIZARD_LIMITED_SPACE_KEY, WIZARD_ONLY_DIMENSIONABLE_KEY, WIZARD_PRIORITIES_KEY, WIZARD_SECONDARY_FLOW_KEY, WIZARD_SECONDARY_FLOW_SPEC_VAL_KEY, WIZARD_SOURCE_FLOW_KEY, WIZARD_SOURCE_KEY, WIZARD_SPECIFIC_AVAIL_DRIVING_PRESSURE_KEY, WIZARD_STAINLESS_KEY, WIZARD_SYSTEM_FLUID_MAIN_KEY, WIZARD_SYSTEM_FLUID_PRIMARY_KEY, WIZARD_SYSTEM_FLUID_PRIMARY_MIX_RATIO_KEY, WIZARD_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_KEY, WIZARD_SYSTEM_FLUID_SECONDARY_KEY, WIZARD_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY, WIZARD_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_KEY, WIZARD_VARIABLE_AVAIL_DRIVING_PRESSURE_KEY } from "./keys/wizardKeys";

//Realtime Database
export const PROJECTS_PATH = '/Projects/'
export const SESSION_PATH = '/Sessions/'
export const SESSION_SHUNT_PATH = '/SessionShunts/'
export const SESSION_SYSTEM_PATH = '/SessionSystems/'
export const SESSION_SHUNT_PRICES_PATH = '/SessionShuntPrices/'
export const SESSION_PRICES_PATH = '/SessionPrices/'

export const MAGICLOUD_ID_PATH = "/MagicloudIDs/"

export const PROJECT_PATH = "/Projects/"
export const PROJECT_PROJECT_ID_PATH = "/projectID/"

export const INVITE_CODE_PATH = "/Invites/"

export const SL_USER_PATH = "/SLUsers/"
export const SL_USER_STARRED_PATH = "/SLUserStarred/"

//Session-Paths
export const SESSION_PARENT_ID_PATH = '/parent';
export const SESSION_CHILD_SESSIONS_PATH = '/ChildSessions/';
export const SESSION_CURRENT_SHUNT_PATH = '/CurrentShunt/';
export const SESSION_CUSTOMDATA = '/CustomData/';
export const SESSION_SHUNT_DESIGNATIONS_PATH = '/shuntDesignations/'
export const SESSION_SHUNT_ADDONS_PATH = '/ShuntAddons/'
export const SESSION_CUSTOM_ADDED_SHUNTS_PATH = '/CustomAddedShunts/'
export const SESSION_FACILITY_PATH = '/' + FACILITY_KEY;
export const SESSION_PROJ_NR_PATH = '/' + PROJNR_KEY;
export const SESSION_LINKED_PROJECT_PATH = '/linkedProject'
export const SESSION_STATUS_PATH = '/status'
export const SESSION_MODIFIED_SHUNTS_PATH = "/ModifiedShunts/"

export const SESSION_OVERALL_COMMENT_PATH = SESSION_CUSTOMDATA + 'overallComment';
export const SESSION_COMMENT_TIMESTAMP_PATH = SESSION_CUSTOMDATA + 'lastComment';

//Shunt-Paths
export const SHUNT_BASEINFO = '/Baseinfo/';
export const SHUNT_SHUNTDATA = '/ShuntData/';
export const SHUNT_PLDATA = '/PLData/';
export const SHUNT_CALCPARAMS = '/CalcParams/';
export const SHUNT_DRIFTPARAMS = '/DriftParams/';
export const SHUNT_ADJUSTDATA = '/AdjustData/';
export const SHUNT_PUMPDATA = '/PumpData/';
export const SHUNT_CUSTOMDATA = '/CustomData/';
export const SHUNT_WIZARDDATA = "/WizardData/"
export const SHUNT_SPECLISTDATA = "/SpecData/"
export const SHUNT_PARENTSESSION_PATH = '/parent';

export const SHUNT_MAXSTEP_PATH = SHUNT_BASEINFO + 'maxStep';
export const SHUNT_CURRENTSTEP_PATH = SHUNT_BASEINFO + 'curStep';
export const SHUNT_POSITION_PATH = SHUNT_BASEINFO + POSITION_KEY;
export const SHUNT_DESIGNATION_PATH = SHUNT_BASEINFO + DESIGNATION_KEY;

export const SHUNT_PRODFAMILY_PATH = SHUNT_PLDATA + PROD_PRINCIP;
export const SHUNT_PRODLINE_PATH = SHUNT_PLDATA + PROD_PRODLINE;
export const SHUNT_PROD_GREENLEVEL_PATH = SHUNT_PLDATA + PROD_GREEN_LEVEL;

export const SHUNT_PLMODEL_PATH = SHUNT_SHUNTDATA + PL_MODEL_KEY;
export const SHUNT_IS_MINI_PATH = SHUNT_SHUNTDATA + IS_MINI_KEY;
export const SHUNT_MINI_INCOMPATIBLE_PATH = SHUNT_SHUNTDATA + MINI_INCOMPATIBLE_KEY;
export const SHUNT_SHUNTSTRING_PATH = SHUNT_SHUNTDATA + SHUNT_STRING_KEY;
export const SHUNT_CV_KVS_PATH = SHUNT_SHUNTDATA + CV_KVS_KEY;
export const SHUNT_CV_MODEL_PATH = SHUNT_SHUNTDATA + CV_MODEL_KEY;
export const SHUNT_CONTROLVALVE_PATH = SHUNT_SHUNTDATA + CONTROL_VALVE_KEY;
export const SHUNT_FLEXMODEL_PATH = SHUNT_SHUNTDATA + FLEX_MODEL_KEY;
export const SHUNT_FLEXDIM_ID_PATH = SHUNT_SHUNTDATA + FLEX_DIM_ID_KEY;
export const SHUNT_SMART_SV_PATH = SHUNT_SHUNTDATA + SMART_DIM_ID_KEY;
export const SHUNT_NUM_IV_PATH = SHUNT_SHUNTDATA + NUM_IV_KEY;
export const SHUNT_ADJUSTVALVE_PATH = SHUNT_SHUNTDATA + ADJUSTVALUE_KEY;
export const SHUNT_MAGICLOUD_LINK_PATH = SHUNT_SHUNTDATA + MAGICLOUD_LINK_KEY;
export const SHUNT_HAS_LATCH_PATH = SHUNT_SHUNTDATA + HAS_LATCH_KEY;
export const SHUNT_CONNECTED_ENERGY_MODEL_PATH = SHUNT_SHUNTDATA + CONNECTED_ENERGY_MODEL_KEY;
export const SHUNT_CONNECTED_ENERGY_COMM_MODAL_PATH = SHUNT_SHUNTDATA + CONNECTED_ENERGY_COMM_MODAL_KEY;

export const SHUNT_IS_ADDON_PATH = SHUNT_SHUNTDATA + IS_ADDON_KEY;
export const SHUNT_ADDON_TYPE_PATH = SHUNT_SHUNTDATA + ADDON_TYPE_KEY;
export const SHUNT_ADDONS_PATH = SHUNT_SHUNTDATA + ADDONS_KEY + '/';

export const SHUNT_PRIM_DIMROW_PATH = SHUNT_CALCPARAMS + PRIM_DIM_ROW_KEY + '/';
export const SHUNT_SEK_DIMROW_PATH = SHUNT_CALCPARAMS + SEC_DIM_ROW_KEY + '/';

export const SHUNT_CALCPARAMS_PRIMINTERNALPRESSUREPROP_PATH = SHUNT_CALCPARAMS + PRIM_INTERNAL_PRESSURE_DROP_KEY;
export const SHUNT_CALCPARAMS_MEASUREMENTDATA_PATH = SHUNT_CALCPARAMS + MEASUREMENTDATA_KEY;

export const SHUNT_DRIFTPARAMS_PRIMDIM = SHUNT_DRIFTPARAMS + PRIM_DIM_KEY;
export const SHUNT_DRIFTPARAMS_SEKDIM = SHUNT_DRIFTPARAMS + SEC_DIM_KEY;

export const SHUNT_DRIFTPARAMS_FLOW_CALC_MODE = SHUNT_DRIFTPARAMS + FLOW_CALC_MODE_KEY;
export const SHUNT_DRIFTPARAMS_SECONDARY_EFFECT = SHUNT_DRIFTPARAMS + SEC_FLOW_EFFECT_KEY;

export const SHUNT_DRIFTPARAMS_PRIM_DRIVING_PRESSURE_PATH = SHUNT_DRIFTPARAMS + PRIM_DRIVING_PRESSURE_KEY;
export const SHUNT_DRIFTPARAMS_PRIM_TEMP_IN_PATH = SHUNT_DRIFTPARAMS + PRIM_TEMP_IN_KEY;
export const SHUNT_DRIFTPARAMS_PRIM_TEMP_OUT_PATH = SHUNT_DRIFTPARAMS + PRIM_TEMP_OUT_KEY;

export const SHUNT_DRIFTPARAMS_USE_CONNECTED_ENERGY_PATH = SHUNT_DRIFTPARAMS + USE_CONNECTED_ENERGY_KEY

export const SHUNT_ADJUSTDATA_CONNECTIONDESIGN_PATH = SHUNT_ADJUSTDATA + CONNECTION_DESIGN_KEY;
export const SHUNT_ADJUSTDATA_CONNECTIONVALUE_PATH = SHUNT_ADJUSTDATA + CONNECTION_VALUE_KEY;
export const SHUNT_ADJUSTDATA_MATERIAL_PATH = SHUNT_ADJUSTDATA + MATERIAL_KEY;
export const SHUNT_ADJUSTDATA_THERMO_PATH = SHUNT_ADJUSTDATA + THERMO_KEY;
export const SHUNT_ADJUSTDATA_INSULATION_PATH = SHUNT_ADJUSTDATA + INSULATION_KEY;
export const SHUNT_ADJUSTDATA_MANOMETER_PATH = SHUNT_ADJUSTDATA + MANOMETER_KEY;
export const SHUNT_ADJUSTDATA_STAND_PATH = SHUNT_ADJUSTDATA + STAND_KEY;
export const SHUNT_ADJUSTDATA_CUTOFF_PATH = SHUNT_ADJUSTDATA + CUTOFF_KEY;
export const SHUNT_ADJUSTDATA_CABINET_PATH = SHUNT_ADJUSTDATA + CABINET_KEY;
export const SHUNT_ADJUSTDATA_ADJUSTVALUE_PATH = SHUNT_ADJUSTDATA + ADJUSTVALUE_KEY;
export const SHUNT_ADJUSTDATA_MIXINGVESSEL_PATH = SHUNT_ADJUSTDATA + MIXINGVESSELS_KEY;
export const SHUNT_ADJUSTDATA_ENERGYMEASUREMENT_PATH = SHUNT_ADJUSTDATA + ENERGYMEASUREMENT_KEY;
export const SHUNT_ADJUSTDATA_CONNECTED_TEMP_PATH = SHUNT_ADJUSTDATA + CONNECTED_TEMP_KEY;
export const SHUNT_ADJUSTDATA_CONNECTED_DIFF_PATH = SHUNT_ADJUSTDATA + CONNECTED_DIFF_KEY;
export const SHUNT_ADJUSTDATA_DRIPPLATE_PATH = SHUNT_ADJUSTDATA + DRIPPLATE_KEY;
export const SHUNT_ADJUSTDATA_ACTUATORS_PATH = SHUNT_ADJUSTDATA + ACTUATORS_KEY;
export const SHUNT_ADJUSTDATA_SHUNTSIZE_PATH = SHUNT_ADJUSTDATA + SHUNTSIZE_KEY;

export const SHUNT_PUMPDATA_PUMPREQUESTTYPE_PATH = SHUNT_PUMPDATA + PUMP_REQUEST_TYPE;
export const SHUNT_PUMPDATA_SPECIFICREQUESTTYPE_PATH = SHUNT_PUMPDATA + PUMP_SPECIFIC_REQUEST;
export const SHUNT_PUMPDATA_GENERALREQUESTTYPE_PATH = SHUNT_PUMPDATA + PUMP_GENERAL_REQUEST;
export const SHUNT_PUMPDATA_CARD_PATH = SHUNT_PUMPDATA + 'card';

export const SHUNT_COMMENT_PATH = SHUNT_CUSTOMDATA + 'comment';
export const SHUNT_COMMENT_TIMESTAMP_PATH = SHUNT_CUSTOMDATA + 'lastComment';
export const SHUNT_CUSTOMDATA_TOTALPRICE_PATH = SHUNT_CUSTOMDATA + 'shuntPrice'
export const SHUNT_CUSTOMDATA_PRICESAVED_PATH = SHUNT_CUSTOMDATA + 'priceSaved';
export const SHUNT_CUSTOMDATA_PRICE_ISCOMPLETE_PATH = SHUNT_CUSTOMDATA + 'priceCompleted';
export const SHUNT_CUSTOMDATA_INCLUDE_MAGICLOUD_LINK_PATH = SHUNT_CUSTOMDATA + 'includeMagicloudLink';

export const SHUNT_WIZARDDATA_SOURCE_PATH = SHUNT_WIZARDDATA + WIZARD_SOURCE_KEY;
export const SHUNT_WIZARDDATA_SOURCE_FLOW_PATH = SHUNT_WIZARDDATA + WIZARD_SOURCE_FLOW_KEY;
export const SHUNT_WIZARDDATA_APPLICATION_PATH = SHUNT_WIZARDDATA + WIZARD_APPLICATION_KEY;
export const SHUNT_WIZARDDATA_APPLICATION_INTENT_PATH = SHUNT_WIZARDDATA + WIZARD_APPLICATION_INTENT_KEY;
export const SHUNT_WIZARDDATA_APPLICATION_SIZE_PATH = SHUNT_WIZARDDATA + WIZARD_APPLICATION_SIZE_KEY;
export const SHUNT_WIZARDDATA_ONLY_DIMENSIONABLE_PATH = SHUNT_WIZARDDATA + WIZARD_ONLY_DIMENSIONABLE_KEY;
export const SHUNT_WIZARDDATA_SECONDARY_FLOW_PATH = SHUNT_WIZARDDATA + WIZARD_SECONDARY_FLOW_KEY;
export const SHUNT_WIZARDDATA_SECONDARY_FLOW_SPEC_VAL_PATH = SHUNT_WIZARDDATA + WIZARD_SECONDARY_FLOW_SPEC_VAL_KEY;
export const SHUNT_WIZARDDATA_AVAIL_DRIVING_PRESSURE_PATH = SHUNT_WIZARDDATA + WIZARD_AVAIL_DRIVING_PRESSURE_KEY;
export const SHUNT_WIZARDDATA_VARIABLE_AVAIL_DRIVING_PRESSURE_PATH = SHUNT_WIZARDDATA + WIZARD_VARIABLE_AVAIL_DRIVING_PRESSURE_KEY;
export const SHUNT_WIZARDDATA_SPECIFIC_AVAIL_DRIVING_PRESSURE_PATH = SHUNT_WIZARDDATA + WIZARD_SPECIFIC_AVAIL_DRIVING_PRESSURE_KEY;
export const SHUNT_WIZARDDATA_HAS_MAIN_PUMP_PATH = SHUNT_WIZARDDATA + WIZARD_HAS_MAIN_PUMP_KEY;
export const SHUNT_WIZARDDATA_CERTICIFACTIONS_PATH = SHUNT_WIZARDDATA + WIZARD_CERTICIFACTIONS_KEY;
export const SHUNT_WIZARDDATA_SYSTEM_FLUID_MAIN_PATH = SHUNT_WIZARDDATA + WIZARD_SYSTEM_FLUID_MAIN_KEY;
export const SHUNT_WIZARDDATA_SYSTEM_FLUID_PRIMARY_PATH = SHUNT_WIZARDDATA + WIZARD_SYSTEM_FLUID_PRIMARY_KEY;
export const SHUNT_WIZARDDATA_SYSTEM_FLUID_SECONDARY_PATH = SHUNT_WIZARDDATA + WIZARD_SYSTEM_FLUID_SECONDARY_KEY;
export const SHUNT_WIZARDDATA_SYSTEM_FLUID_PRIMARY_MIX_RATIO_PATH = SHUNT_WIZARDDATA + WIZARD_SYSTEM_FLUID_PRIMARY_MIX_RATIO_KEY;
export const SHUNT_WIZARDDATA_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_PATH = SHUNT_WIZARDDATA + WIZARD_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_KEY;
export const SHUNT_WIZARDDATA_SYSTEM_FLUID_SECONDARY_MIX_RATIO_PATH = SHUNT_WIZARDDATA + WIZARD_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY;
export const SHUNT_WIZARDDATA_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_PATH = SHUNT_WIZARDDATA + WIZARD_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_KEY;
export const SHUNT_WIZARDDATA_ENERGY_MEASUREMENT_PATH = SHUNT_WIZARDDATA + WIZARD_ENERGY_MEASUREMENT_KEY;
export const SHUNT_WIZARDDATA_LIMITED_SPACE_PATH = SHUNT_WIZARDDATA + WIZARD_LIMITED_SPACE_KEY;
export const SHUNT_WIZARDDATA_STAINLESS_PATH = SHUNT_WIZARDDATA + WIZARD_STAINLESS_KEY;
export const SHUNT_WIZARDDATA_PRIORITIES_PATH = SHUNT_WIZARDDATA + WIZARD_PRIORITIES_KEY;


//Firestore
export const USER_PATH = 'users/'

export const SHUNT_DIMS_PATH = 'ShuntDims/';
export const CONTROL_VALVES_PATH = 'ControlValves/';
export const ADJUSTMENT_VALVES_PATH = 'AdjustmentValves/'
export const MEASUREMENTDATA_PATH = 'MeasurementData/'
export const ENERGYMEASUREMENT_PATH = "EnergyMeasurement/";
export const SYSTEMFLUIDS_PATH = "Systemfluids/"

export const CONTROL_VALVES_MODELS_DEFAULT_PATH = CONTROL_VALVES_PATH + 'SoE/Models';
export const CONTROL_VALVES_MODELS_IMI_PATH = CONTROL_VALVES_PATH + 'IMI/Models/';
export const CONTROL_VALVES_DIMS_FLEX_DEFAULT_PATH = CONTROL_VALVES_MODELS_IMI_PATH + 'TAModulator/Dims/';
export const CONTROL_VALVES_DIMS_SMART_DEFAULT_PATH = CONTROL_VALVES_MODELS_IMI_PATH + 'TASmart/Dims/';

export const ENERGYMEASUREMENT_SIEMENS_UH50_C_DIMS_PATH = ENERGYMEASUREMENT_PATH + "Siemens/Models/UH50-C/Dims/";
export const ENERGYMEASUREMENT_SIEMENS_UH50_A_DIMS_PATH = ENERGYMEASUREMENT_PATH + "Siemens/Models/UH50-A/Dims/";