//import { useState } from 'react';
import { CardMedia, Typography, Grid, Tooltip, Divider, Box, Stack, Checkbox, IconButton, Paper } from '@mui/material';
import { useProductLineParams, } from "../../../contexts"
import { PRODUCTLINE_FLEX, PRODUCTLINE_GREEN_I, PRODUCTLINE_GREEN_II, PRODUCTLINE_SMART, PRODUCTLINE_STANDARD } from '../../../constants/productlineConstants';
import { ProductLineEnum } from '../../../enums';

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

import standardLine from '../../../assets/images/logos/Premablock_Standard_Logo.svg'
import greenLine1 from '../../../assets/images/logos/Premablock_Green_I_Logo.svg'
import greenLine2 from '../../../assets/images/logos/Premablock_Green_II_Logo.svg'
import flexLine from '../../../assets/images/logos/Premablock_Flex_Logo.svg'
import smartLine from '../../../assets/images/logos/Premablock_Smart_Logo.svg'


import { useState } from 'react';
import { ShuntTagsComponent } from './components/shuntTags';

/**
 * 
 * @param {Object} param0 
 * @param {import('../../../constants/fullConnOptions/types').ShuntConnectionInterface} param0.connectionObj
 * @param {boolean} param0.isSelected
 * @param {boolean} param0.currentSelected
 * @param {(connectionObj, prodline) => boolean} param0.filterLines
 * @param {(event) => void} param0.onClick
 * @param {(event) => void} param0.onDoubleClick
 * @param {(connectionObj, prodline) => void} param0.clickAndContinue
 * @param {Array} param0.selectedList
 * @param {number} [param0.index]
 * @returns 
 */
export const CompactConnectionCard = ({ connectionObj, isSelected, currentSelected, filterLines, onClick, onDoubleClick, clickAndContinue, selectedList, index = null }) => {
    const cardHeight = "120px";
    const imageRef = connectionObj.ImageData.imageRef;
    const productLine = useProductLineParams();
    const prodLine = ProductLineEnum.ProductLine.getContextValue(productLine);
    const [selected, setSelected] = useState(false);

    const setStyle = () => {
        if (currentSelected) {
            if (index === 0) {
                return {
                    borderTop: "2px solid #e12427",
                    borderBottom: "2px solid #e12427",
                    boxShadow: "none",
                    borderRadius: 0
                    //padding: '10px',
                }
            }
            else {
                return {
                    borderTop: "2px solid #e12427",
                    borderBottom: "2px solid #e12427",
                    boxShadow: "none",
                    borderRadius: 0
                    //padding: '10px',
                }
            }

        }
        else {
            return {
                borderTop: "2px solid transparent",
                borderBottom: "2px solid transparent",
                boxShadow: "none",
            }
        }
    }

    const prodLineImage = (prodLine) => {
        switch (prodLine) {
            case PRODUCTLINE_STANDARD:
                return (<img src={standardLine} alt='Standard Line' style={{ height: '11px', padding: '2px', userSelect: 'none' }} />);
            case PRODUCTLINE_GREEN_I:
                return (<img src={greenLine1} alt='Green I Line' style={{ height: '14px', padding: '2px', userSelect: 'none' }} />);
            case PRODUCTLINE_GREEN_II:
                return (<img src={greenLine2} alt='Green II Line' style={{ height: '14px', padding: '2px', userSelect: 'none' }} />);
            case PRODUCTLINE_FLEX:
                return (<img src={flexLine} alt='Flex Line' style={{ height: '11px', padding: '2px', userSelect: 'none' }} />);
            case PRODUCTLINE_SMART:
                return (<img src={smartLine} alt='Smart Line' style={{ height: '11px', padding: '2px', userSelect: 'none' }} />);
            default:
                console.log("Prod: ", prodLine)
                return (<img src={standardLine} alt='Standard Line' style={{ height: '11px', padding: '2px', userSelect: 'none' }} />);
        }
    }

    const clickArrow = () => {
        let prodLines = connectionObj.InfoData.prodlines.filter((item) => filterLines(connectionObj, item));

        if (prodLines.length === 1) {
            //console.log("Hello!", connectionObj.InfoData.prodlines.filter((item) => filterLines(connectionObj, item)));
            clickAndContinue(connectionObj, prodLines[0]);
        }

    }

    return (
        <Paper sx={setStyle} >
            <Box sx={{ backgroundColor: '#FFF', padding: '10px 0px 10px 10px', cursor: 'pointer', }} onDoubleClick={onDoubleClick}>
                <Stack direction={'row'} spacing={1} alignItems={'flex-start'} justifyContent={'space-between'}>
                    <Stack onDoubleClick={onDoubleClick}>
                        {/* 
                        <Box marginTop={'-20px'} marginLeft={'-10px'} marginBottom={'-10px'}>
                            <Tooltip arrow placement='left' title={'Jämför'}>
                                <Checkbox checked={isSelected} onClick={onClick} />
                            </Tooltip>

                        </Box>*/}
                        <CardMedia
                            style={{ width: 170, marginLeft: '-8px' }}
                            component="img"
                            image={imageRef}
                            alt={connectionObj.InfoData.name}
                        />
                    </Stack>


                    <Box sx={{ padding: '1px 4px', width: '100%' }}>
                        <Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
                            <Stack direction={'row'} spacing={1}>
                                <Typography variant="h3" whiteSpace={'nowrap'}>
                                    <span><strong>{connectionObj.InfoData.name}</strong> |</span>
                                </Typography>
                                <Typography variant="h4" color={'#474747'}>
                                    {connectionObj.InfoData.applicationInfoText}
                                </Typography>
                            </Stack>

                            <ShuntTagsComponent shunt={connectionObj} />
                        </Stack>
                        {connectionObj.InfoData.applicationInfoSubText != null ? (
                            <Typography variant="h4">
                                <i>{connectionObj.InfoData.applicationInfoSubText}</i>
                            </Typography>
                        ) : ("")}

                        <Box padding={'5px 0'}>
                            {connectionObj.InfoData.prodlines != null ? (
                                <Stack direction={'row'} spacing={0.5} divider={<div style={{ height: '17px' }}><Divider orientation='vertical' /></div>}>
                                    {connectionObj.InfoData.prodlines.map((prodLine) => {
                                        return (
                                            <Grid key={connectionObj.id + "-" + prodLine} item xs={4} sx={{ opacity: filterLines(connectionObj, prodLine) ? 1 : 0.4, filter: filterLines(connectionObj, prodLine) ? '' : 'grayscale(100%)' }}>
                                                {prodLineImage(prodLine)}
                                            </Grid>
                                        )
                                    })}
                                </Stack>
                            ) : ("")}
                        </Box>

                    </Box>

                    <Box height={'100%'}>
                        {connectionObj.InfoData.prodlines.filter((item) => filterLines(connectionObj, item)).length === 1 && selectedList.length < 1 ? (
                            <IconButton onClick={clickArrow} size="small" sx={{ fontSize: '25px', color: '#0053A1', marginTop: '40%', marginLeft: '-16px', marginRight: '10px' }}>
                                <ArrowCircleRightIcon />
                            </IconButton>
                        ) : (
                            <Box width={'32px'} >

                            </Box>
                        )}
                    </Box>
                </Stack>
            </Box>
        </Paper >
    );
}