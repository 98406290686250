import { Card, CardContent, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { ADDON_MEASUREMENT_DATA } from "../../../constants/addonLabels";
import { PRODUCTLINE_GREEN } from "../../../constants/productlineConstants";


export const AddonMeasurementCard = ({ addonData }) => {
    const [measurement, setMeasurement] = useState({});

    useEffect(() => {
        if (addonData?.ShuntData?.isAddon === true && addonData?.ShuntData?.addonType != null) {
            if (ADDON_MEASUREMENT_DATA[addonData?.ShuntData?.addonType] != null) {
                if (addonData?.ShuntData?.AddonData?.size != null) {
                    let size = String(addonData?.ShuntData?.AddonData?.size).split(' ')[0];
                    let modelName = 'PRE-BW-' + size;

                    if (addonData?.PLData?.prodLine === PRODUCTLINE_GREEN){
                        modelName += ' green'
                    }
                    
                    if (ADDON_MEASUREMENT_DATA[addonData?.ShuntData?.addonType][modelName] != null) {
                        setMeasurement(ADDON_MEASUREMENT_DATA[addonData?.ShuntData?.addonType][modelName]);
                    }
                }
            }
        }
    }, [addonData])

    return (
        <Card variant="outlined">
            <CardContent style={{ paddingBottom: '15px' }}>
                {measurement != null && (
                    <>
                        <Typography style={{ marginBottom: '6px' }}><strong>Mått</strong></Typography>
                        {Object.keys(measurement).map((key) => (
                            <Typography key={key}>{key} = {measurement[key]}</Typography>
                        ))}
                        <br></br>
                        <Typography>*<i>Vagn eller golvstativ ökar H2 med ca. 150 mm</i></Typography>
                    </>
                )}
            </CardContent>
        </Card>
    )
}