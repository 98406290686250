import { doc, getDoc } from "firebase/firestore";

const getPriceCabinetValue = async function getPriceCabinetValue(firestore, dim, isInsulated) {
    let cabinetDoc;

    if (isInsulated === true){
        cabinetDoc = doc(firestore, "/PriceValues/cabinet/options/insulated");
    }
    else{
        cabinetDoc = doc(firestore, "/PriceValues/cabinet/options/uninsulated");
    }

    const res = await getDoc(cabinetDoc);
    
    //console.log("Res:", dim, res.data().basePrice[dim], res.data().enumFactor);
    return Math.round(Number(res.data().basePrice[dim] * Number(res.data().enumFactor)));
}

export { getPriceCabinetValue }