import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';

import {
    AnalyticsProvider,
    AuthProvider,
    DatabaseProvider,
    FirestoreProvider,
    FunctionsProvider,
    StorageProvider,
    useFirebaseApp
} from 'reactfire';

import { getStorage } from 'firebase/storage';
import { ProductLineProvider } from '../contexts/ProductLineParams';
import { DriftParamsProvider } from '../contexts/DriftParams';
import { getAnalytics } from 'firebase/analytics';
import { ConTypesProvider } from '../contexts/conTypes';
import { ShuntParamsProvider } from '../contexts/ShuntParams';
import { DevBarProvider } from '../contexts/devBar';
import { CalcParamsProvider } from '../contexts/calcParams';
import { AdjustProvider } from '../contexts/Adjustments'
import { PumpProvider } from '../contexts/PumpParams'
import { ErrorContextProvider } from '../contexts/ErrorContext';
import { NoticeContextProvider } from '../contexts/NoticeContext';
import { SessionParamsProvider } from '../contexts/SessionParams';
import { ModifyProvider } from '../contexts/ModifySpecContext';

import { FSDataProvider } from '../contexts/fsDataContext';
import { getFunctions } from 'firebase/functions';
import { ErrorPage } from '../components/modules/errorHandling/errorBoundaries';
import { ErrorBoundary } from 'react-error-boundary';
import { WizardProvider } from '../contexts/wizardParams';
import { AuthContextProvider } from '../authContext/AuthProvider';

export const ContextProvider = ({ children }) => {
    const firebaseApp = useFirebaseApp();
    const auth = getAuth(firebaseApp);
    const database = getDatabase(firebaseApp);
    const firestore = getFirestore(firebaseApp);
    const storage = getStorage(firebaseApp);
    const analytics = getAnalytics(firebaseApp);
    const functions = getFunctions(firebaseApp);

    function ErrorFallback({ error }) {
        return (
            <div role="alert">
                <ErrorPage error={error} />
            </div >
        )
    }

    return (
        <AuthProvider sdk={auth}>
            <DatabaseProvider sdk={database}>
                <FunctionsProvider sdk={functions}>
                    <FirestoreProvider sdk={firestore}>
                        <StorageProvider sdk={storage}>
                            <AnalyticsProvider sdk={analytics}>
                                <ErrorBoundary FallbackComponent={ErrorFallback}>
                                    <AuthContextProvider>
                                        <SessionParamsProvider>
                                            <ConTypesProvider>
                                                <ProductLineProvider>
                                                    <FSDataProvider>
                                                        <DriftParamsProvider>
                                                            <ShuntParamsProvider>
                                                                <CalcParamsProvider>
                                                                    <DevBarProvider>
                                                                        <AdjustProvider>
                                                                            <PumpProvider>
                                                                                <WizardProvider>
                                                                                    <ErrorContextProvider>
                                                                                        <NoticeContextProvider>
                                                                                            <ModifyProvider>
                                                                                                {children}
                                                                                            </ModifyProvider>
                                                                                        </NoticeContextProvider>
                                                                                    </ErrorContextProvider>
                                                                                </WizardProvider>
                                                                            </PumpProvider>
                                                                        </AdjustProvider>
                                                                    </DevBarProvider>
                                                                </CalcParamsProvider>
                                                            </ShuntParamsProvider>
                                                        </DriftParamsProvider>
                                                    </FSDataProvider>
                                                </ProductLineProvider>
                                            </ConTypesProvider>
                                        </SessionParamsProvider>
                                    </AuthContextProvider>
                                </ErrorBoundary>
                            </AnalyticsProvider>
                        </StorageProvider>
                    </FirestoreProvider>
                </FunctionsProvider>
            </DatabaseProvider>
        </AuthProvider>
    );
};
