import { get, ref } from "firebase/database";
import { SESSION_PATH, SESSION_SHUNT_PATH } from "../../../constants/firebasePaths";
import { SessionParamsEnum } from "../../../enums/sessionParamsEnum"

const checkIfPosExists = async function checkIfPosExists(position, database, sessionParams, newShunt = false) {
    const sessionID = SessionParamsEnum.SessionID.getContextValue(sessionParams);
    const shuntID = SessionParamsEnum.CurrentShunt.getContextValue(sessionParams);

    const infoRef = ref(database, SESSION_PATH + sessionID + "/SessionShunts");
    const result = await get(infoRef);

    let alreadyExists = false;
    result.forEach((child) => {
        if (shuntID !== child.key || newShunt === true) {
            if (Number(position) === Number(child.val())) {
                alreadyExists = true;
            }
        }
    });

    return alreadyExists;
}

const checkIfPosExistsCustom = async function checkIfPosExistsCustom(position, database, sessionParams, newShunt = false, shuntID = null) {
    const sessionID = SessionParamsEnum.SessionID.getContextValue(sessionParams);

    const infoRef = ref(database, SESSION_PATH + sessionID + "/SessionShunts");
    const result = await get(infoRef);

    let alreadyExists = false;
    let foundShunt = '';
    result.forEach((child) => {
        if (shuntID !== child.key || newShunt === true) {
            if (Number(position) === Number(child.val())) {
                alreadyExists = true;
                foundShunt = child.key;
            }
        }
    });

    return alreadyExists;

    /*
    // @ts-ignore
    if (alreadyExists === true){
        const shuntSnap = await get(ref(database, SESSION_SHUNT_PATH + foundShunt + "/PLData/princip"));
        console.log("Exists true? ", shuntSnap.exists())
        if (shuntSnap.exists()){
            return true;
        }
        else{
            return false;
        }
    }
    else{
        return false;
    }*/
}


const checkIfDesignationExists = async function checkIfDesignationExists(designation, database, sessionParams, newShunt = false, shuntID = null) {
    const sessionID = SessionParamsEnum.SessionID.getContextValue(sessionParams);
    if (shuntID == null){
        shuntID = SessionParamsEnum.CurrentShunt.getContextValue(sessionParams);
    }

    const infoRef = ref(database, SESSION_PATH + sessionID + "/shuntDesignations");
    let alreadyExists = false;
    if (designation !== "") {
        const result = await get(infoRef);

        result.forEach((child) => {
            if (shuntID !== child.key || newShunt === true) {
                if (designation === child.val()) {
                    alreadyExists = true;
                }
            }
        });
    }

    return alreadyExists;
}

export { checkIfPosExists, checkIfPosExistsCustom, checkIfDesignationExists }