import { httpsCallable } from "firebase/functions"
import { SessionParamsEnum } from "../../../enums";

const sendSupportMailFunc = function sendSupportMailFunc(functions, sessionParams, option, message, sender) {
    functions.region = 'europe-west1';
    const sendMail = httpsCallable(functions, "sendSupportMail");

    let sessionID = null;
    if (SessionParamsEnum.SessionID.getContextValue(sessionParams) != null && SessionParamsEnum.SessionID.getContextValue(sessionParams) !== ''){
        sessionID = SessionParamsEnum.SessionID.getContextValue(sessionParams);
    }

    return sendMail({ 
        sessionID: sessionID, 
        email: sender, 
        message: message, 
        option: option, 
    });
}

export { sendSupportMailFunc }