import { useState } from "react";
import { Button } from "@mui/material";

import { RequestUserModal } from "./requestUserModal";

export const RequestSLUserButton = () => {
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        setOpenModal(false);
    }


    return (
        <>
            <Button sx={{ opacity: 0.8, height: '18px', marginLeft: '25px', marginTop: '-2px', fontSize: '0.7rem', textTransform: 'none' }} onClick={handleOpenModal}>
                <i>Ansök om konto</i>
            </Button>

            <RequestUserModal openModal={openModal} handleCloseModal={handleCloseModal} />
        </>
    )
}