import { Database, get, ref } from "firebase/database";
import { INVITE_CODE_PATH } from "../../constants/firebasePaths";

/**
 * 
 * @param {Database} database 
 * @param {string} inviteCode
 */
export const getInviteData = async (database, inviteCode) => {
    if (inviteCode != null && inviteCode !== "") {
        const inviteRef = ref(database, INVITE_CODE_PATH + inviteCode);
        const result = await get(inviteRef);

        if (result.exists()) {
            return { key: result.key, ...result.val() }
        }
        else {
            return null;
        }
    }
    else {
        return null;
    }
}