import { ref, set, update } from "firebase/database";
import { SESSION_SHUNT_PATH, SESSION_SHUNT_PRICES_PATH } from "../../../constants/firebasePaths";

const saveShuntPrice = function saveShuntPrice(database, shuntID, totalPrice, priceList, isCompleted) {
    //console.log("ShuntID:", shuntID);
    const totalPriceRef = ref(database, SESSION_SHUNT_PATH + "/" + shuntID + "/CustomData");
    const allPriceRef = ref(database, SESSION_SHUNT_PRICES_PATH + "/" + shuntID);

    let snapData = {
        priceCompleted: isCompleted,
        shuntPrice: totalPrice,
        priceSaved: Date.now(),
    };


    const promises = [];

    //console.log("Saving Data");
    promises.push(update(totalPriceRef, snapData));
    promises.push(update(allPriceRef, priceList))

    return Promise.all(promises);
}

const saveShuntShippingCost = function saveShuntShippingCost(database, shuntID, shippingCost) {
    //console.log("ShuntID:", shuntID);
    const allPriceRef = ref(database, SESSION_SHUNT_PRICES_PATH + "/" + shuntID);
    const shipPriceRef = ref(database, SESSION_SHUNT_PATH + "/" + shuntID + "/CustomData/");

    const promises = [];

    //console.log("Saving Data");
    promises.push(update(allPriceRef, {
        shipCost: shippingCost,
    }));

    promises.push(update(shipPriceRef, {
        shipCost: shippingCost,
    }))

    return Promise.all(promises);
}

const saveShuntMarkups = function saveShuntMarkups(database, shuntID, markupList) {
    //console.log("ShuntID:", shuntID);
    const allPriceRef = ref(database, SESSION_SHUNT_PRICES_PATH + "/" + shuntID + "/Markups");

    const promises = [];

    //console.log("Saving Data");
    promises.push(update(allPriceRef, markupList))

    return Promise.all(promises);
}

export { saveShuntPrice, saveShuntMarkups, saveShuntShippingCost } 