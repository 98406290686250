import { Typography, FormControlLabel, Grid, Select, MenuItem, InputLabel, FormControl, Box, Collapse, Divider, Checkbox, Button, Tooltip } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
//import { CUSTOM_SHUNT_DIM_LABELS, CUSTOM_SHUNT_DIM_LIMITS, CUSTOM_SHUNT_SEC_CONTROLVALVE_LABELS, CUSTOM_SHUNT_EXCHANGE_LABELS, CUSTOM_SHUNT_TEMP_VERSION, CUSTOM_SHUNT_SEC_CONTROLVALVE_OPTIONS, CUSTOM_SHUNT_BYPASS_OPTION, CUSTOM_SHUNT_ADVANCED_MODE_ENABLED } from "../../../../constants/customShunts";
import { useFSData } from "../../../../contexts/fsDataContext";
import { FSDataEnum } from "../../../../enums";
import { useFirestore } from "reactfire";
import { PRODUCTLINE_FLEX, PRODUCTLINE_SMART, PRODUCTPRINCIP_PR2_APPSHUNT, PRODUCTPRINCIP_SR2_FLEX_APPSHUNT } from "../../../../constants/productlineConstants";

/**
 * 
 * @param {Object} param0 
 * @param {import('../../../../constants/fullConnOptions/types').ShuntConnectionInterface} param0.shuntConnOption
 * @param {*} param0.plModel
 * @param {*} param0.setPlModel
 * @param {*} param0.plPrincip
 * @param {*} param0.prodLine
 * @param {*} param0.dimVals
 * @param {*} param0.setDimVals
 * @param {*} param0.exchangeVal
 * @param {*} param0.setExchangeVal
 * @param {*} param0.exchangeVals
 * @param {*} param0.setExchangeVals
 * @param {*} param0.tempVersion
 * @param {*} param0.setTempVersion
 * @param {*} param0.cv2Val
 * @param {*} param0.setCV2Val
 * @param {*} param0.isSpecial
 * @param {*} param0.setIsSpecial
 * @param {*} param0.isBypass
 * @param {*} param0.setIsBypass
 * @param {*} param0.triggerUpdate
 * @param {*} [param0.prevChoice]
 * @param {*} [param0.prevChoiceExchange]
 * @returns 
 */
export const CustomShuntAddDescription = ({ shuntConnOption, plModel, setPlModel, plPrincip, prodLine, dimVals, setDimVals, exchangeVal, setExchangeVal, exchangeVals, setExchangeVals, tempVersion, setTempVersion, cv2Val, setCV2Val, isSpecial, setIsSpecial, isBypass, setIsBypass, triggerUpdate, prevChoice = null, prevChoiceExchange = null }) => {
    const fsParams = useFSData();
    const firestore = useFirestore();
    const [curPrincip, setCurPrincip] = useState('');
    const [curProdline, setCurProdline] = useState('');
    const [modPrincip, setModPrincip] = useState('');
    const [dims, setDims] = useState([]);
    const [flexdims, setFlexDims] = useState([]);

    const [expandedAdvanced, setExpandedAdvanced] = useState(false);
    const [tempOptions, setTempOptions] = useState([]);
    const [dimLabels, setDimLabels] = useState({});
    const [exchangeLabels, setExchangeLabels] = useState([])
    const [cv2Options, setCV2Options] = useState([]);
    const [cv2Labels, setCV2Labels] = useState([]);

    const [displayExchange, setDisplayExchange] = useState(false);
    const [displayCV2, setDisplayCV2] = useState(false);

    const [disableAdvanced, setDisableAdvanced] = useState(false);
    const [update, setUpdate] = useState(false);
    const [clear, setClear] = useState(false);

    function handleChangeAdvanced() {
        setExpandedAdvanced(!expandedAdvanced);
    }

    function handleChangeTempVersion(event) {
        setTempVersion(event.target.value);
    }

    function handleChangeExchangeVersion(event) {
        setModPrincip(plPrincip + "-" + event.target.value)
        setExchangeVal(event.target.value);
    }

    function handleChangeCV2Version(value, cv, label) {
        if (label === 'kyla') {
            insertNWayToModel(value);
        }
        let local = cv2Val;
        local[cv] = value;
        setCV2Val(local);
        setUpdate(!update);
        triggerUpdate()
    }

    function handleDimValChange(key, value) {
        if (plPrincip === PRODUCTPRINCIP_PR2_APPSHUNT || plPrincip === PRODUCTPRINCIP_SR2_FLEX_APPSHUNT) {
            let local = {};
            Object.keys(dimLabels).forEach((key) => {
                console.log("Key: ", key, "Dim: ", dims[0])

                local[key] = value;
            });

            setDimVals(local);
        }
        else {
            let local = dimVals;
            local[key] = value;
            setDimVals(local);
        }

        setUpdate(!update);
    }

    function handleSwitchedValChange(key, value) {
        console.log("Key: ", key, "Value: ", value)
        exchangeVals[key] = value;
        setUpdate(!update);
    }

    function insertNWayToModel(value) {
        let orgPLPrincip = plPrincip;
        let splitString = String(orgPLPrincip).split('-');
        let inserted = splitString[1].substring(0, 1) + String(value) + splitString[1].substring(1);
        let fullName = splitString[0] + "-" + inserted;

        if (isSpecial === true) {
            console.log("Set", isSpecial)
            fullName += "Spec"
        }
        setPlModel(fullName);
    }

    useEffect(() => {
        FSDataEnum.Dims.getContextValue(fsParams, firestore).then((res) => {
            let localList = [];
            if (shuntConnOption?.CustomOptions?.limitedDimSizes != null) {
                res.forEach(element => {
                    if (localList.find(obj => { return obj.name === element.name }) == null && shuntConnOption?.CustomOptions?.limitedDimSizes.find(obj => { return obj === element.NO_ID_FIELD }) != null) {
                        localList.push(element);
                    }
                });
            }
            else {
                res.forEach(element => {
                    //console.log("Element: ", element)
                    if (localList.find(obj => { return obj.name === element.name }) == null) {
                        if (element.name === 'DN020') {
                            if (element.ivName === 'DN020') {
                                localList.push(element);
                            }
                        }
                        else {
                            localList.push(element);
                        }
                    }
                });
            }

            setDims(localList);
            setUpdate(!update);
        });


    }, [plPrincip]);

    useEffect(() => {
        if (curPrincip !== plPrincip) {
            setCurPrincip(plPrincip);
            setDimVals({});
            setExchangeVal('');
            setIsSpecial(false);
            setIsBypass(false);
            setTempVersion('');

            if (shuntConnOption?.CustomOptions?.advancedModeEnabled != null && shuntConnOption?.CustomOptions?.advancedModeEnabled === true) {
                setDisableAdvanced(false);
            }
            else {
                setDisableAdvanced(true);
            }

            if (shuntConnOption?.CustomOptions?.shuntTempTypes != null) {
                setTempOptions(shuntConnOption?.CustomOptions?.shuntTempTypes);
                if (shuntConnOption?.CustomOptions?.shuntTempTypes.length === 1) {
                    setTempVersion(shuntConnOption?.CustomOptions?.shuntTempTypes[0])
                }
            }
            else {
                setTempOptions([]);
            }
            // CUSTOM_SHUNT_DIM_LABELS
            if (shuntConnOption?.CustomOptions?.shuntDimLabels != null) {
                setDimLabels(shuntConnOption?.CustomOptions?.shuntDimLabels);
            }
            else {
                setDimLabels({});
            }


            if (shuntConnOption?.CustomOptions?.shuntCustomExchangeLabels != null) {
                setExchangeLabels(shuntConnOption?.CustomOptions?.shuntCustomExchangeLabels);
                setExchangeVal(shuntConnOption?.CustomOptions?.shuntCustomExchangeLabels[0].val)
                //setModPrincip(plPrincip + "-" + shuntConnOption?.CustomOptions?.shuntCustomExchangeLabels[0].val)
                setDisplayExchange(true);
            }
            else {
                setExchangeLabels([]);
                setDisplayExchange(false);
            }

            setCV2Options([]);
            setDisplayCV2(false);

            /*
            if (CUSTOM_SHUNT_SEC_CONTROLVALVE_OPTIONS[plPrincip] != null) {
                setCV2Options(CUSTOM_SHUNT_SEC_CONTROLVALVE_OPTIONS[plPrincip]);
                let choices = {};
                CUSTOM_SHUNT_SEC_CONTROLVALVE_OPTIONS[plPrincip].forEach(element => {
                    console.log("Trigger! 2", element);
                    if (element.value === 'kyla') {
                        console.log("Trigger! 2, Inside")
                        insertNWayToModel(2);
                    }
                    choices[element.cv] = 2;
                });
                setCV2Val(choices);
                setDisplayCV2(true);
            }
            else {
                setCV2Options([]);
                setDisplayCV2(false);
            }*/

            if (shuntConnOption?.CustomOptions?.shuntSecCVLabels != null) {
                setCV2Labels(shuntConnOption?.CustomOptions?.shuntSecCVLabels);

                //setDisplayCV2(true);
            }
            else {
                setCV2Labels([]);
                //setDisplayCV2(false);
            }
            setUpdate(!update);
        }


    }, [plPrincip, modPrincip, shuntConnOption, clear])

    useEffect(() => {
        if (shuntConnOption?.CustomOptions?.shuntDimLabels != null) {
            setDimLabels(shuntConnOption?.CustomOptions?.shuntDimLabels);
        }
        else {
            setDimLabels({});
        }

        setCV2Options([]);
        setCV2Val({});
        setDisplayCV2(false);

        /*
        if (CUSTOM_SHUNT_SEC_CONTROLVALVE_OPTIONS[modPrincip] != null) {
            setCV2Options(CUSTOM_SHUNT_SEC_CONTROLVALVE_OPTIONS[modPrincip]);
            let choices = {};
            CUSTOM_SHUNT_SEC_CONTROLVALVE_OPTIONS[modPrincip].forEach(element => {
                console.log("Trigger! 1", element);
                if (cv2Val != null && cv2Val[element.cv] != null) {
                    if (element.value === 'kyla') {
                        console.log("Trigger! 2, Inside")
                        insertNWayToModel(Number(cv2Val[element.cv]));
                    }
                    choices[element.cv] = Number(cv2Val[element.cv]);
                }
                else {
                    if (element.value === 'kyla') {
                        console.log("Trigger! 2, Inside")
                        insertNWayToModel(2);
                    }
                    choices[element.cv] = 2;
                }
            });
            setCV2Val(choices);
            setDisplayCV2(true);
            setUpdate(!update);
        }
        else {
            if (CUSTOM_SHUNT_SEC_CONTROLVALVE_OPTIONS[plPrincip] != null) {
                setCV2Options(CUSTOM_SHUNT_SEC_CONTROLVALVE_OPTIONS[plPrincip]);
                console.log("Values", cv2Val)
                let choices = {};
                CUSTOM_SHUNT_SEC_CONTROLVALVE_OPTIONS[plPrincip].forEach(element => {
                    console.log("Trigger! 2", element);
                    if (cv2Val != null && cv2Val[element.cv] != null) {
                        if (element.value === 'kyla') {
                            console.log("Trigger! 2, Inside")
                            insertNWayToModel(Number(cv2Val[element.cv]));
                        }
                        choices[element.cv] = Number(cv2Val[element.cv]);
                    }
                    else {
                        if (element.value === 'kyla') {
                            console.log("Trigger! 2, Inside")
                            insertNWayToModel(2);
                        }
                        choices[element.cv] = 2;
                    }

                });
                setCV2Val(choices);
                setDisplayCV2(true);
                setUpdate(!update);
            }
            else {
                setCV2Options([]);
                setCV2Val({});
                setDisplayCV2(false);
            }
        }*/

    }, [modPrincip, plModel, shuntConnOption])

    useEffect(() => {
        if (prevChoice != null && dims.length > 0) {
            let selected = {};
            Object.keys(prevChoice).forEach((key) => {
                let selectedDim = dims.find(obj => { return obj.name === prevChoice[key]?.name });

                if (selectedDim != null) {
                    selected[key] = selectedDim;
                }
            });

            setDimVals(selected);
        }
    }, [prevChoice, dims]);

    useEffect(() => {
        if (prevChoiceExchange != null && dims.length > 0) {
            let selected = {};
            Object.keys(prevChoiceExchange).forEach((key) => {
                let selectedDim = dims.find(obj => { return obj.name === prevChoiceExchange[key]?.name });

                if (selectedDim != null) {
                    selected[key] = selectedDim;
                }
            });

            setExchangeVals(selected);
        }
    }, [prevChoiceExchange, dims])

    useEffect(() => {
        if (dims.length === 1) {
            let local = dimVals;

            Object.keys(dimLabels).forEach((key) => {
                console.log("Key: ", key, "Dim: ", dims[0])

                local[key] = dims[0];
            });
            setDimVals(local);
        }
    }, [dimLabels, dims])

    useEffect(() => {
        if (prodLine !== curProdline) {
            setCurProdline(prodLine);
            setCV2Options([]);
            setDisplayCV2(false);
            /*
            if (CUSTOM_SHUNT_SEC_CONTROLVALVE_OPTIONS[plPrincip] != null) {
                setCV2Options(CUSTOM_SHUNT_SEC_CONTROLVALVE_OPTIONS[plPrincip]);
                let choices = {};
                CUSTOM_SHUNT_SEC_CONTROLVALVE_OPTIONS[plPrincip].forEach(element => {
                    console.log("Trigger! 2", element);
                    if (element.value === 'kyla') {
                        console.log("Trigger! 2, Inside")
                        insertNWayToModel(2);
                    }
                    choices[element.cv] = 2;
                });
                setCV2Val(choices);
                setDisplayCV2(true);
            }
            else {
                setCV2Options([]);
                setDisplayCV2(false);
            }*/
        }
    }, [prodLine])

    const disableCV2Option = (variant, cv2Val, option) => {
        //console.log("Values: ", variant, cv2Val, option?.cv, prodLine)
        let isDisabled = false;

        if (variant?.nWays === 3 && (prodLine === PRODUCTLINE_FLEX || prodLine === PRODUCTLINE_SMART)) {
            Object.keys(cv2Val).forEach((val) => {
                if (cv2Val[val] === 3 && val !== option?.cv) {
                    isDisabled = true;
                }
            })
        }

        return isDisabled;
    }

    return (
        <Box>
            <Box style={{ display: 'flex' }}>
                <Typography sx={{ color: 'text.secondary', fontStyle: 'italic', marginRight: '8px', marginLeft: 'auto' }}>
                    <Tooltip arrow placement="top" title={disableAdvanced ? ("Avancerat läge är inte tillgängligt för den valda principen") : ("")}>
                        <div>
                            <Button variant="outlined" style={{ textTransform: "unset", marginTop: '2px', height: '25px' }} disabled={disableAdvanced} size="small" onClick={handleChangeAdvanced}>{expandedAdvanced ? ("Förenklat läge") : ("Avancerat läge")}</Button>
                        </div>
                    </Tooltip>
                </Typography>
            </Box>
            <Box>
                <Collapse in={!expandedAdvanced}>
                    <Grid container spacing={1} sx={{ marginTop: '0px', padding: '0px 10px 10px 10px', width: '518px' }}>
                        {tempOptions.length > 0 ? (
                            <Grid item xs={8}>
                                <FormControl fullWidth>
                                    <InputLabel>Värme/kyla</InputLabel>
                                    <Select
                                        disabled={tempOptions.length < 2}
                                        label={'Värme/kyla'}
                                        sx={{ width: '140px' }}
                                        value={tempVersion}
                                        onChange={handleChangeTempVersion}
                                    >
                                        <MenuItem key={'none'} value={''}>

                                        </MenuItem>
                                        {tempOptions.map((version) => {
                                            return (
                                                <MenuItem key={version} value={version}>
                                                    {version}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        ) : ("")}

                        {plPrincip !== PRODUCTPRINCIP_PR2_APPSHUNT && plPrincip !== PRODUCTPRINCIP_SR2_FLEX_APPSHUNT ? (
                            <Grid item xs={3} sx={{ marginTop: '-5px', }}>
                                <FormControlLabel control={<Checkbox sx={{ marginRight: '0px', marginLeft: 'auto' }} checked={isSpecial} onChange={() => { setIsSpecial(!isSpecial) }} />} label={"Specialutforming"} labelPlacement="start" />
                            </Grid>
                        ) : ("")}


                        {displayExchange ? (
                            <Fragment>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        Växlad krets:
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Variant</InputLabel>
                                        <Select
                                            label={'Variant'}
                                            disabled={exchangeLabels.length < 2}
                                            sx={{ width: '140px' }}
                                            value={exchangeVal}
                                            onChange={handleChangeExchangeVersion}
                                        >
                                            <MenuItem key={'none'} value={''}>

                                            </MenuItem>
                                            {exchangeLabels.map((option) => {
                                                return (
                                                    <MenuItem key={option.label} value={option.val}>
                                                        {option.label}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </Fragment>
                        ) : ("")}

                        {Object.keys(dimLabels).length > 0 ? (
                            <Fragment>
                                <Grid item xs={12}>
                                    <Typography>
                                        Dimensioner:
                                    </Typography>
                                </Grid>

                                {Object.keys(dimLabels).map((key) => {
                                    return (
                                        <Fragment>
                                            <Grid item key={key} xs={12 / Object.keys(dimLabels).length}  >
                                                <FormControl fullWidth>
                                                    <InputLabel>{"Dim " + dimLabels[key]}</InputLabel>
                                                    <Select
                                                        value={dimVals[key] || ''}
                                                        label={"Dim " + dimLabels[key]}
                                                        sx={{ maxWidth: '140px' }}
                                                        onChange={(e) => { handleDimValChange(key, e.target.value) }}
                                                    >
                                                        <MenuItem key={'none'} value={''}>

                                                        </MenuItem>

                                                        {dims.map((dim) => {
                                                            return (
                                                                <MenuItem key={dim.NO_ID_FIELD} value={dim}>
                                                                    {dim.name}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Fragment>
                                    )
                                })}
                            </Fragment>
                        ) : ("")}

                        {displayCV2 ? (
                            <Fragment>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                {Object.keys(cv2Options).map((option) => {
                                    return (
                                        <Fragment>
                                            <Grid item xs={12 / Object.keys(dimLabels).length}>
                                                <Typography sx={{ marginBottom: '10px' }}>
                                                    Styrventil {cv2Options[option].value}:
                                                </Typography>

                                                <FormControl fullWidth>
                                                    <InputLabel>Variant</InputLabel>
                                                    <Select
                                                        disabled={cv2Labels.length < 2}
                                                        label={'Variant'}
                                                        sx={{ width: '140px' }}
                                                        value={cv2Val[cv2Options[option].cv]}
                                                        onChange={(e) => { handleChangeCV2Version(e.target.value, cv2Options[option].cv, cv2Options[option].value) }}
                                                    >
                                                        <MenuItem key={'none'} value={''}>

                                                        </MenuItem>
                                                        {cv2Labels.map((variant) => {
                                                            return (
                                                                <MenuItem key={variant.label} value={variant.nWays} disabled={disableCV2Option(variant, cv2Val, cv2Options[option])}>
                                                                    {variant.label}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Fragment>
                                    )
                                })}
                            </Fragment>
                        ) : ("")}


                        {/*CUSTOM_SHUNT_BYPASS_OPTION[plPrincip] != null ? (
                            <Fragment>
                                <Grid item xs={5} sx={{ marginLeft: '5px' }}>
                                    <FormControlLabel control={<Checkbox checked={isBypass} onChange={() => { setIsBypass(!isBypass) }} />} label={"Förbigång"} labelPlacement="end" />
                                </Grid>
                            </Fragment>
                        ) : ("")*/}
                    </Grid>
                </Collapse>

                <Collapse in={expandedAdvanced}>
                    <Grid container alignItems={'center'} justifyContent={'center'} spacing={2} sx={{ marginTop: '0px', padding: '0px 10px 20px 10px' }}>
                        <Grid item alignItems={'center'} justifyContent={'center'} >
                            <Typography component={'h2'} style={{ height: '80px' }}>
                                <br /> {/* Because center is a mess...*/}
                                <i>Advanced mode here!</i>
                            </Typography>

                        </Grid>

                    </Grid>
                </Collapse>
            </Box>
        </Box>
    )
}