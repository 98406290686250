import { useEffect, useState } from "react";
import { DialogContent, Typography, DialogActions, Button, Select, MenuItem, Divider, TextField, Stack, CircularProgress } from "@mui/material"
import { useFunctions } from "reactfire";
import { useSessionParams } from "../../../contexts";
import { sendSupportMailFunc } from "../../../firebase/functions/cloudFunctions/sendSupportMail";
import { BootstrapDialog, BootstrapDialogTitle } from "../../utils/bootstrapDialog"


export const SupportModule = ({ openModal, handleModalClose }) => {
    const functions = useFunctions();
    const sessionParams = useSessionParams();

    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const [option, setOption] = useState('');
    const [message, setMessage] = useState('');
    const [mail, setMail] = useState('');
    const [isEmailInvalid, setEmailInvalid] = useState(false);
    const [isOptionInvalid, setOptionInvalid] = useState(false);
    const [isMessageInvalid, setMessageInvalid] = useState(false);

    const [isSent, setIsSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isSent === true){
            setIsSent(false);
            setOption('');
            setMessage('');
            setMail('');
        }
    }, [openModal])

    const categories = {
        suggestions: 'Synpunkter och förslag',
        errors: 'Felrapport',
        support: 'Användningssupport',
    }

    const handleTextChange = (event) => {
        if (isMessageInvalid === true && event.target.value !== '') {
            setMessageInvalid(false);
        }
        setMessage(event.target.value);
    }

    const handleMailChange = (event) => {
        if (isEmailInvalid === true && event.target.value !== '') {
            setEmailInvalid(false);
        }

        setMail(event.target.value);
    }

    const validateEmail = senderMail => {
        if (senderMail !== "" && emailRegex.test(senderMail)) {
            setEmailInvalid(false);
        }
        else {
            setEmailInvalid(true);
        }
    }

    const sentMessage = () => {
        if (option === 'errors'){
            return "Vi undersöker felet så snart som möjligt."
        }
        else if (option === 'support'){
            return "Vi återkommer så snart som möjligt."
        }
        else {
            return "Tack för din feedback!"
        }
    }

    const sendFeedback = () => {
        let valid = true;
        if (option == null || option === '') {
            setOptionInvalid(true);
            valid = false;
        }
        if (message == null || message === '') {
            setMessageInvalid(true);
            valid = false;
        }
        if (mail == null || emailRegex.test(mail) === false) {
            setEmailInvalid(true);
            valid = false;
        }

        if (valid === true) {
            setIsLoading(true);
            sendSupportMailFunc(functions, sessionParams, option, message, mail).then(() => {
                //console.log("Sent!")
                setIsSent(true);
            }).catch((error) => {
                console.error("Kunde inte skicka!", error)
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }

    return (
        <BootstrapDialog
            onClose={handleModalClose}
            aria-labelledby="customized-dialog-title"
            open={openModal}
        >
            <div style={{ minWidth: '500px' }}>
                <BootstrapDialogTitle component="h3" sx={{ paddingLeft: '20px', paddingTop: '0px', paddingBottom: '0px' }} onClose={handleModalClose}>
                    <h3 className="header-title">Kontaktformulär</h3>
                </BootstrapDialogTitle>
                {isLoading === false ? (
                    <>
                        {isSent === false ? (
                            <>
                                <DialogContent>
                                    <Typography style={{ display: 'flex', justifyContent: 'left', paddingLeft: '7px', fontSize: '12px', color: '#5f5f53' }}>
                                        Ärende: *
                                    </Typography>
                                    <Select
                                        sx={{ minWidth: '170px', marginBottom: '10px' }}
                                        value={option}
                                        required
                                        error={isOptionInvalid}
                                        onChange={(e) => { setOptionInvalid(false); setOption(e.target.value) }}>
                                        {Object.keys(categories).map((key) =>
                                            <MenuItem key={key} value={key}>{categories[key]}</MenuItem>
                                        )}
                                    </Select>
                                    <Divider />
                                    <TextField
                                        sx={{ marginTop: '10px', marginBottom: '10px' }}
                                        inputProps={{
                                            style: {
                                                padding: 0
                                            }
                                        }}
                                        fullWidth
                                        multiline
                                        minRows={5}
                                        maxRows={8}
                                        label='Meddelande'
                                        value={message}
                                        onChange={handleTextChange}
                                        error={isMessageInvalid}
                                        variant="outlined"
                                        required
                                    />
                                    <Divider />
                                    <TextField
                                        style={{ display: 'flex', justifyContent: 'left', marginTop: '10px', width: '57%' }}
                                        value={mail}
                                        type={'email'}
                                        label="E-post"
                                        onChange={handleMailChange}
                                        error={isEmailInvalid}
                                        required
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={sendFeedback}>
                                        Skicka
                                    </Button>
                                </DialogActions>
                            </>
                        ) : (
                            <>
                                <DialogContent>
                                    <Typography component={'h2'} gutterBottom style={{ display: 'flex', justifyContent: 'center', width: 500, marginTop: 50  }}>
                                        Skickat! <br/>
                                    </Typography>
                                    <Typography component={'h2'} gutterBottom style={{ display: 'flex', justifyContent: 'center', width: 500, minHeight: 150, }}>
                                        {sentMessage()}
                                    </Typography>

                                </DialogContent>
                            </>
                        )}

                    </>

                ) : (
                    <>
                        <DialogContent>
                            <Typography component={'h2'} gutterBottom style={{ display: 'flex', justifyContent: 'center', width: 480, marginTop: 50, minHeight: 150, }}>
                                <Stack>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress size={25} /></div>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>Skickar...</div>
                                </Stack>
                            </Typography>

                        </DialogContent>
                    </>
                )}

            </div>

        </BootstrapDialog>
    )
}