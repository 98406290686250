import { useState, useEffect } from 'react';

import { useDatabase } from 'reactfire';
import { Button, Modal, Paper, Typography, Divider, Tooltip } from '@mui/material';
import { useSessionParams } from '../../../contexts/SessionParams';
import { useDevbarParams } from '../../../contexts';
import { SessionParamsEnum } from '../../../enums/sessionParamsEnum';
import { LoginModule } from '../login/loginModule';
import { ShareSession } from './shareSession';
import { RequestOffer } from './requestOffer';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/Logout';
import { getShuntListName } from '../../../firebase/functions/getShuntList';
import { useContextAuth } from '../../../authContext/AuthHook';
import { OverviewPanels } from '../../../constants/overviewPanels';
import { IncompleteShuntSpecsModal } from './modals/incompleteShuntSpecsModal';
import { EditedShuntSpecsModal } from './modals/editedShuntSpecsModal';
import { HOVER_TEXT_OVERVIEW_SHARE_BLOCKED_MODIFIED_SPEC } from '../../../constants/generalText/hoverTexts';

export const OverviewSidePanel = ({ editMode }) => {
    const { handleLogout } = useContextAuth();
    const database = useDatabase();
    const sessionParams = useSessionParams();

    const { user } = useContextAuth();

    const devParams = useDevbarParams();
    // const adjustParams = useAdjustParams();
    // const calcParams = useCalcParams();
    // const driftParams = useDriftParams();
    // const errorContext = useErrorContext();
    // const noticeContext = useNoticeContext();
    // const productLine = useProduktLinje();
    // const pumpParams = usePumpParams();
    // const shuntParams = useShuntParams();

    useEffect(() => {
        getShuntListName(database, sessionParams).then((result) => {
            let localNotCompleted = false;
            let localHasErrors = false;
            let localEditedShunts = false;
            let localModifiedShunts = false;

            result.forEach((shunt) => {
                // @ts-ignore
                if (shunt.maxStep !== '4' && shunt.maxStep !== 4 && shunt.isStarted === true) {
                    localNotCompleted = true;
                }
                else if (shunt.hasErrors === true) {
                    localHasErrors = true;
                }

                if (shunt?.isSpecEdit === true) {
                    localEditedShunts = true;
                    
                    if (shunt?.isSpecEditModified === true) {
                        localModifiedShunts = true;
                    }
                }
            })

            setNotCompleted(localNotCompleted);
            setHasErrors(localHasErrors);
            setHasEditedShunts(localEditedShunts);
            setHasModifiedShunts(localModifiedShunts);
        })
    }, [SessionParamsEnum.UpdateValues.getContextValue(sessionParams)]);


    const [openShareSession, setOpenShareSession] = useState(false);

    const [notCompleted, setNotCompleted] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);
    const [hasEditedShunts, setHasEditedShunts] = useState(false);
    const [hasModifiedShunts, setHasModifiedShunts] = useState(false);

    //Modal for incomplete or shunts with errors
    const [openModal, setOpenModal] = useState(false);

    const handleModalClose = () => setOpenModal(false);
    const handleModalOpen = () => setOpenModal(true);

    const continueToSpec = () => {
        handleModalClose();
        if (hasEditedShunts === true && user != null && user?.isInternal === true) {
            handleModalEditedSpecOpen()
        }
        else {
            SessionParamsEnum.OverviewPanel.setContextValue(sessionParams, OverviewPanels.shuntSpecPanel)
        }
    };

    //Modal for edited specifications
    const [openModalEditedSpec, setOpenModalEditedSpec] = useState(false);

    const handleModalEditedSpecClose = () => setOpenModalEditedSpec(false);
    const handleModalEditedSpecOpen = () => setOpenModalEditedSpec(true);

    const continueToSpecEdited = () => {
        handleModalEditedSpecClose();
        SessionParamsEnum.OverviewPanel.setContextValue(sessionParams, OverviewPanels.shuntSpecPanel)
        //setCurrentPanel('shuntSpecPanel');
    };

    const handleOpenShareSession = () => {
        if (hasModifiedShunts !== true || user?.isInternal !== true) {
            setOpenShareSession(true);
        }
    }

    const handleCloseShareSession = () => {
        setOpenShareSession(false);
    }

    const [openReqOfferSession, setReqOfferSession] = useState(false);

    const handleOpenReqOfferSession = () => {
        setReqOfferSession(true);
    }

    const handleCloseReqOfferSession = () => {
        setReqOfferSession(false);
    }

    const [openLogin, setOpenLogin] = useState(false);
    const handleLoginClose = () => {
        setOpenLogin(false);
    }

    const handleLogoutFunc = (event) => {
        event.preventDefault();
        handleLogout();
        openHandleShunts();
    }

    const openHandleShunts = () => {
        SessionParamsEnum.OverviewPanel.setContextValue(sessionParams, OverviewPanels.shuntPanel)
        //setCurrentPanel('shuntPanel')
    }

    const openShuntSpecPanel = () => {
        if (notCompleted === true || hasErrors === true) {
            handleModalOpen();
        }
        else if (hasEditedShunts === true && user != null && user?.isInternal === true) {
            handleModalEditedSpecOpen()
        }
        else {
            SessionParamsEnum.OverviewPanel.setContextValue(sessionParams, OverviewPanels.shuntSpecPanel)
            //setCurrentPanel('shuntSpecPanel')
        }

    }

    const openModifySpecPanel = () => {
        SessionParamsEnum.OverviewPanel.setContextValue(sessionParams, OverviewPanels.modifySpecPanel)
        //setCurrentPanel('modifySpecPanel')
    }

    const openSystemSpecOverview = () => {
        SessionParamsEnum.OverviewPanel.setContextValue(sessionParams, OverviewPanels.systemSpecOverview)
        //setCurrentPanel('systemSpecOverview')
    }

    const openPriceCalcOverview = () => {
        SessionParamsEnum.OverviewPanel.setContextValue(sessionParams, OverviewPanels.priceCalcOverview)
        //setCurrentPanel('priceCalcOverview')
    }

    const openSessionHistoryPanel = () => {
        SessionParamsEnum.OverviewPanel.setContextValue(sessionParams, OverviewPanels.sessionHistoryPanel)
        //setCurrentPanel('sessionHistoryPanel');
    }

    const openMyProfilePanel = () => {
        SessionParamsEnum.OverviewPanel.setContextValue(sessionParams, OverviewPanels.myProfilePanel)
        //setCurrentPanel('myProfilePanel');
    }

    // const createNew = async () => {
    //     clearContexts();
    //     const shuntID = await SessionParamsEnum.addSessionShunt(sessionParams, database, true, shuntParams);
    //     console.log("Shunt: ", shuntID);
    //     handleClose();
    //     SessionParamsEnum.CurrentStep.setContextValue(sessionParams, 0, database, shuntID);
    //     navigate("/stepper");
    // }

    // const clearContexts = () => {
    //     console.log("Clear! - Sidepanel");
    //     sessionParams.dispatch({ type: 'clearStepData' })
    //     adjustParams.dispatch({ type: 'clear' })
    //     calcParams.dispatch({ type: 'clear' })
    //     driftParams.dispatch({ type: 'clear' })
    //     errorContext.dispatch({ type: 'clearErrorList' })
    //     noticeContext.dispatch({ type: 'clearNoticeList' })
    //     productLine.dispatch({ type: 'clear' })
    //     pumpParams.dispatch({ type: 'clear' })
    //     shuntParams.dispatch({ type: 'fullClear' })
    // }

    // eslint-disable-next-line no-unused-vars
    const isDisabled = () => {
        if (SessionParamsEnum.Editable.getContextValue(sessionParams) === false && devParams.state.val.devMode !== true) {
            return true;
        }
        else {
            return false;
        }
    }

    const displayInternalMenu = () => {
        return (
            <>
                <div style={{ paddingTop: "10px", marginTop: '10px' }}>
                    {user?.isDev === true ? (
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={openPriceCalcOverview}
                            disabled
                            //disabled={SessionParamsEnum.OverviewPanel.getContextValue(sessionParams) === OverviewPanels.priceCalcOverview || editMode}
                            className={SessionParamsEnum.OverviewPanel.getContextValue(sessionParams) === OverviewPanels.priceCalcOverview ? "handle-active-btn" : ""}
                        >
                            Prisuträkning
                        </Button>
                    ) : (
                        <Tooltip title={"Flyttas till Projektpanelen"} placement='top' arrow>
                            <span>
                                <Button variant="contained" onClick={openPriceCalcOverview} fullWidth disabled >
                                    Prisuträkning
                                </Button>
                            </span>
                        </Tooltip>

                    )}

                </div>

                <div style={{ paddingTop: "10px" }}>
                    <Button
                        variant="contained"
                        onClick={openModifySpecPanel}
                        fullWidth
                        disabled={SessionParamsEnum.OverviewPanel.getContextValue(sessionParams) === OverviewPanels.modifySpecPanel || editMode}
                        className={SessionParamsEnum.OverviewPanel.getContextValue(sessionParams) === OverviewPanels.modifySpecPanel ? "handle-active-btn" : ""}
                    >
                        Modifiera spec
                    </Button>
                </div>
                <Divider variant="middle" className="divider-sidepanel" />

                <div style={{ paddingTop: "10px", marginBottom: '10px' }}>
                    <Button
                        variant="contained"
                        onClick={openSystemSpecOverview}
                        fullWidth
                        disabled={SessionParamsEnum.OverviewPanel.getContextValue(sessionParams) === OverviewPanels.systemSpecOverview || editMode}
                        className={SessionParamsEnum.OverviewPanel.getContextValue(sessionParams) === OverviewPanels.systemSpecOverview ? "handle-active-btn" : ""}
                    >
                        Systemvärden
                    </Button>
                </div>

                <Divider variant="middle" className="divider-sidepanel" />

                <div style={{ paddingTop: "10px" }}>
                    <Button
                        variant="contained"
                        onClick={openSessionHistoryPanel}
                        fullWidth
                        disabled={SessionParamsEnum.OverviewPanel.getContextValue(sessionParams) === OverviewPanels.sessionHistoryPanel || editMode}
                        className={SessionParamsEnum.OverviewPanel.getContextValue(sessionParams) === OverviewPanels.sessionHistoryPanel ? "handle-active-btn" : ""}
                    >
                        Min Sessionshistorik
                    </Button>
                </div>
            </>
        )
    }

    const displayExternalMenu = () => {
        if (user != null && user?.isInternal !== true) {
            return (
                <>
                    <div style={{ paddingTop: "10px" }}>
                        <Button
                            variant="contained"
                            onClick={openSessionHistoryPanel}
                            fullWidth
                            disabled={SessionParamsEnum.OverviewPanel.getContextValue(sessionParams) === OverviewPanels.sessionHistoryPanel || editMode}
                            className={SessionParamsEnum.OverviewPanel.getContextValue(sessionParams) === OverviewPanels.sessionHistoryPanel ? "handle-active-btn" : ""}
                        >
                            Min Sessionshistorik
                        </Button>
                    </div>
                    <Divider variant="middle" className="divider-sidepanel" />

                    <div style={{ marginTop: "10px" }}>

                        <div style={{ paddingTop: "10px", marginBottom: '10px' }}>
                            <Button
                                variant="contained"
                                onClick={openMyProfilePanel}
                                fullWidth
                                disabled={SessionParamsEnum.OverviewPanel.getContextValue(sessionParams) === OverviewPanels.myProfilePanel || editMode}
                                className={SessionParamsEnum.OverviewPanel.getContextValue(sessionParams) === OverviewPanels.myProfilePanel ? "handle-active-btn" : ""}
                            >
                                Mina Sidor
                            </Button>
                        </div>

                    </div>
                </>

            )
        }
        else {
            return "";
        }
    }

    return (
        <>
            <Paper style={{ padding: "15px", minWidth: "202px", maxWidth: "210px", maxHeight: "535px" }}>
                {/* {  <Typography component={"h3"}>(Some creative header)</Typography> */}

                <div style={{ paddingTop: "0px" }}>
                    <Button
                        variant="contained"
                        onClick={openHandleShunts}
                        fullWidth
                        disabled={SessionParamsEnum.OverviewPanel.getContextValue(sessionParams) === OverviewPanels.shuntPanel || SessionParamsEnum.OverviewPanel.getContextValue(sessionParams) == null || editMode}
                        className={(SessionParamsEnum.OverviewPanel.getContextValue(sessionParams) === OverviewPanels.shuntPanel || SessionParamsEnum.OverviewPanel.getContextValue(sessionParams) == null) ? "handle-active-btn" : ""}
                    >
                        Hantera shuntgrupper
                    </Button>
                </div>



                <div style={{ paddingTop: "10px" }}>
                    <Button
                        variant="contained"
                        onClick={openShuntSpecPanel}
                        fullWidth
                        disabled={SessionParamsEnum.OverviewPanel.getContextValue(sessionParams) === OverviewPanels.shuntSpecPanel || editMode}
                        className={SessionParamsEnum.OverviewPanel.getContextValue(sessionParams) === OverviewPanels.shuntSpecPanel ? "handle-active-btn" : ""}
                    >
                        Specifikation
                    </Button>
                </div>
                <Divider variant="middle" className="divider-sidepanel" />

                <div style={{ paddingTop: "10px", marginTop: '10px' }}>
                    <Tooltip arrow placement='top' title={hasModifiedShunts === true && user?.isInternal === true ? HOVER_TEXT_OVERVIEW_SHARE_BLOCKED_MODIFIED_SPEC : ""}>
                        <span>
                            <Button variant="contained" onClick={handleOpenShareSession} disabled={editMode || (hasModifiedShunts === true && user?.isInternal === true)} fullWidth>
                                Dela session
                            </Button>
                        </span>
                    </Tooltip>


                </div>

                <div style={{ paddingTop: "10px" }}>
                    <Button variant="contained" onClick={handleOpenReqOfferSession} disabled={editMode} fullWidth>
                        Begär offert
                    </Button>
                </div>
                <Divider variant="middle" className="divider-sidepanel" />

                {user?.isAnonymous === false &&
                    <>
                        {user?.isInternal === true ? (
                            <>
                                {displayInternalMenu()}
                            </>

                        ) : (
                            <>
                                {displayExternalMenu()}
                            </>

                        )}
                    </>
                }

                <div style={{ marginTop: "40px", }}>
                    {user?.isAnonymous === true ? (
                        <>
                            <Button fullWidth disabled={editMode} onClick={() => { setOpenLogin(true) }}>
                                <AdminPanelSettingsIcon style={{ marginRight: '5px' }} /> Logga in
                            </Button>

                        </>) : (
                        <>
                            <Typography sx={{ textAlign: 'center', marginBottom: '-4px' }} fontStyle={'italic'} color={'GrayText'}>
                                Inloggad som {user?.name}
                            </Typography>
                            <Button fullWidth disabled={editMode} onClick={handleLogoutFunc}>
                                <LogoutIcon style={{ marginRight: '5px' }} /> Logga ut
                            </Button>
                            <span style={{
                                color: '#888888', display: 'table',
                                margin: ' 7px auto 0px'
                            }}>{user?.data?.email}</span>
                        </>
                    )}

                </div>
            </Paper>

            <Modal
                open={openLogin}
                onClose={handleLoginClose}
            >
                <div>
                    <LoginModule handleClose={handleLoginClose} />
                </div>
            </Modal>

            <ShareSession handleOnDecline={handleCloseShareSession} open={openShareSession} hasEditedShunts={hasEditedShunts} />

            <RequestOffer handleOnDecline={handleCloseReqOfferSession} open={openReqOfferSession} />

            <IncompleteShuntSpecsModal open={openModal} handleClose={handleModalClose} handleApprove={continueToSpec} hasErrors={hasErrors} />

            <EditedShuntSpecsModal open={openModalEditedSpec} handleClose={handleModalEditedSpecClose} handleApprove={continueToSpecEdited} hasErrors={hasModifiedShunts} />
        </>
    );
};