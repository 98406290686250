export const PRINCIPLE_PICKER_HOVERTEXT_STANDARD = "Högkvalitativt standardutförande med flexibla tillval";
export const PRINCIPLE_PICKER_HOVERTEXT_GREEN = "Miljögodkända shuntgrupper med högkvalitativa komponenter och materialval";
export const PRINCIPLE_PICKER_HOVERTEXT_GREEN2 = "Miljöbedömda (green I) och miljövarudeklarerade (green II) shuntgrupper med högkvalitativa komponenter och materialval";
export const PRINCIPLE_PICKER_HOVERTEXT_FLEX = "Shuntgrupper med automatisk reglering av flöde och differenstryck";
export const PRINCIPLE_PICKER_HOVERTEXT_SMART = "Uppkopplade shuntgrupper med inbyggd mätfunktion och hög styrnoggrannhet";

export const WIZARD_PRODLINE_HOVERTEXT_SHORT_INFO_STANDARD = "Vårt högkvalitativa standardutförande med marknadens högsta anpassningsbarhet";
export const WIZARD_PRODLINE_HOVERTEXT_SHORT_INFO_GREEN = "Miljöbedömda shuntgrupper med högre klassificering hos SundaHus och Byggvarubedömningen än standard-shuntgrupper";
export const WIZARD_PRODLINE_HOVERTEXT_SHORT_INFO_GREEN2 = "Miljövarudeklarerade shuntgrupper (ISO 14025 och EN 15804:2012+A2:2019)";
export const WIZARD_PRODLINE_HOVERTEXT_SHORT_INFO_FLEX = "Shuntgrupper med automatisk reglering av flöde och differenstryck";
export const WIZARD_PRODLINE_HOVERTEXT_SHORT_INFO_SMART = "Uppkopplade shuntgrupper med inbyggd mätfunktion och hög styrnoggrannhet";


export const WIZARD_PRODLINE_HOVERTEXT_LONG_INFO_STANDARD = "Premablock® är vårt högkvalitativa standardutförande, men det vi kallar standard är också lite speciellt. Premablock® har nämligen marknadens högsta flexibilitet och kan konfigureras för att klara i princip alla typer av tillkopplingar. På så sätt kan vi anpassa oss efter dina önskemål och leverera skräddarsydda shuntgrupper som blir till 100 procent anpassade till förutsättningarna på en viss plats i anläggningen. Därför kan vi också garantera att Premablock® alltid integreras i systemet med högsta möjliga prestanda.";
export const WIZARD_PRODLINE_HOVERTEXT_LONG_INFO_GREEN = "För att du ska kunna möta upp tuffa miljökrav erbjuder vi vår produktlinje Premablock® green I som har högre miljöklassificering hos SundaHus och Byggvarubedömningen än våra standard-shuntgrupper. En av de stora vinsterna med dessa shuntgrupper att vi genom val av miljövänliga komponenter reducerat mängden bly till så mycket som under 0,1 procent av shuntgruppens vikt. I övrigt gäller givetvis samma högkvalitativa utförande med lika robusta komponenter som kännetecknar alla våra shuntgrupper.";
export const WIZARD_PRODLINE_HOVERTEXT_LONG_INFO_GREEN2 = "På PREMA sätter vi stolthet i att ligga i framkant när det kommer till utvecklingen av så kallade gröna produkter och här är Premablock® green II vårt flaggskepp. Utföranden finns för såväl konventionella värme- och kylshuntgrupper, som växlar- och kombishuntgrupper. Produkterna är miljövarudeklarerade enligt ISO 14025 och EN 15804:2012+A2:2019, samt bedömda av SundaHus och Byggvarubedömningen.";
export const WIZARD_PRODLINE_HOVERTEXT_LONG_INFO_FLEX = "Shuntgrupper med automatisk reglering av flöde och differenstryck.";
export const WIZARD_PRODLINE_HOVERTEXT_LONG_INFO_SMART = "Uppkopplade shuntgrupper med inbyggd mätfunktion och hög styrnoggrannhet.";
