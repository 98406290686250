import { useState, useEffect } from 'react';

import { ref } from 'firebase/database';
import { useDatabase, useDatabaseObjectData } from 'reactfire';
import { Button, Paper } from '@mui/material';
import { useSessionParams } from '../../../contexts';
import { useShuntParams } from '../../../contexts';
import { SessionParamsEnum, ShuntParamsEnum } from '../../../enums';
import { SESSION_PATH, SESSION_SHUNT_PATH } from '../../../constants/firebasePaths';
import { getShuntDesignation } from '../../../firebase/functions/getShuntDesignation';
import { getShuntProdType } from '../../../firebase/functions/getShuntProdType';
import { PRODUCTLINE_STANDARD } from '../../../constants/productlineConstants';
import WordExport from '../wordExport/wordExport';

export const InspectSidePanelAPISession = () => {
    const database = useDatabase();
    const sessionParams = useSessionParams();

    const shuntParams = useShuntParams();

    const [shunts, setShunts] = useState([]);
    const shuntRef = ref(database, SESSION_PATH + SessionParamsEnum.SessionID.getContextValue(sessionParams) + SESSION_SHUNT_PATH);

    const response = useDatabaseObjectData(shuntRef);
    // eslint-disable-next-line no-unused-vars
    const { status, data } = response;

    const handleOpen = () => {
        SessionParamsEnum.OpenOverview.setContextValue(sessionParams, true);
    }

    useEffect(() => {
        if (data != null) {
            const getInfo = async () => {
                const keys = [];
                for (let index = 0; index < Object.keys(data).length; index++) {
                    const key = Object.keys(data)[index];
                    if (key !== "NO_ID_FIELD") {
                        const res = await getShuntDesignation(database, key);
                        const prodLine = await getShuntProdType(database, key);

                        let prodString = "";

                        if (prodLine != null && prodLine.model != null) {
                            prodString = prodLine.model;

                            if (prodLine.prodLine != null && prodLine.prodLine !== PRODUCTLINE_STANDARD) {
                                prodString += " " + prodLine.prodLine;
                            }
                        }

                        if (key === SessionParamsEnum.CurrentShunt.getContextValue(sessionParams)) {
                            keys.push({ id: key, desig: ShuntParamsEnum.ShuntDesignation.getContextValue(shuntParams) || "-", pos: ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) || res.position, posNr: Number(ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams)) || Number(res.position), prodString: prodString })
                        }
                        else {
                            if (res != null) {
                                keys.push({ id: key, desig: res.designation || "-", pos: res.position || "-", posNr: Number(res.position) || -1, prodString: prodString })
                            }
                            else {
                                keys.push({ id: key, desig: "-", pos: "-", posNr: -1, prodString: prodString })
                            }
                        }
                    }
                }

                setShunts(keys);
            }

            getInfo();
        }
    }, [data]);

    return (
        <Paper style={{ padding: "14px", width: "200px", paddingBottom: "20px", display: 'flex', flexDirection: 'column', height: "auto" }}>
            {(SessionParamsEnum.ApiMethod.getContextValue(sessionParams) === 'update' || SessionParamsEnum.ApiMethod.getContextValue(sessionParams) === 'UPD') &&
                <Button sx={{marginBottom: '15px'}} variant="contained" size="small" onClick={handleOpen} fullWidth>
                    Översikt
                </Button>
            }

            <WordExport apiButton={true} />
        </Paper>
    );
};