import { COOL_SHUNT, HEAT_SHUNT } from "../../constants/shuntOptions";

/**
 * @typedef {Object} ShuntTempType
 * @property {string} temp - The Temperature Type of the shunt
 * @property {string} tempMsg - The Type Message
 * @property {string} isolation - The type of isolation to be used.
 */


/**
 * @depricated ***No longer used*** 
 * 
 * Sets if the shunt is "Värmeshunt" or "Kylshunt" 
 * @returns {ShuntTempType}
 */
const setShuntType_old = function setShuntType(temp, prodType, driftParams, model = "") {
    let shuntData = {}

    if (prodType === 'VA-1') {
        if (temp < -10 || temp > 90) {
            driftParams.dispatch({ type: 'setError', payload: "Temperaturen måste vara mellan -10 och 90°C för en VA-1" })
            return;
        }
    }
    else {
        if (temp < -5 || temp > 90) {
            driftParams.dispatch({ type: 'setError', payload: "Temperaturen måste vara mellan -5 och 90°C" })
            return;
        }
    }
    if (model === "TAModulator" && temp < 0) {
        driftParams.dispatch({ type: 'setError', payload: "TA Modulator klarar som lägst 0°C" })
        return;
    }

    //console.log("Temp: ", temp)
    if (temp >= 20){
        shuntData["temp"] = "Värmeshunt";
        shuntData["tempMsg"] = "+18/90°C";
        shuntData["isolation"] = "Isolerskåp delbart med bygellås";
    }
    else if (temp >= 2){
        shuntData["temp"] = "Kylshunt";
        shuntData["tempMsg"] = "+2/90°C";
        shuntData["isolation"] = "Kondensisolering Armaflex AF13 mm";
    }
    else if (prodType === 'VA-1'){
        shuntData["temp"] = "Kylshunt";
        shuntData["tempMsg"] = "-8/90°C";
        shuntData["isolation"] = "Kondensisolering Armaflex AF19 mm";
    }
    else{
        shuntData["temp"] = "Kylshunt";
        shuntData["tempMsg"] = "-5/90°C";
        shuntData["isolation"] = "Kondensisolering Armaflex AF19 mm";
    }

    return shuntData;
}

const setShuntType = function setShuntType(secTempIn, secTempOut) {
    if (Number(secTempIn) >= Number(secTempOut)){
        return HEAT_SHUNT;
    }
    else{
        return COOL_SHUNT;
    }
}

export { setShuntType, setShuntType_old }