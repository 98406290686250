import { calcDeltaPressure } from "./baseFormulas/calcDeltaPressure";
import { getAdjustmentValveModel } from "./helpFunctions/getKvIVModel";
import { roundNumber } from "./helpFunctions/roundTo4Decimals";
import { PRODUCTFAMILY_PR, PRODUCTFAMILY_SR, PRODUCTFAMILY_VA, PRODUCTLINE_FLEX, PRODUCTLINE_GREEN, PRODUCTLINE_SMART } from "../../constants/productlineConstants";

/**
 * 
 * @param {number} primFlow The flow used in the primary system. 
 * @param {number} primDrivingPressure The Primary driving pressure for the system ***Only important for Model vxg11, vvg11, TA Fusion C and TBV-CM***
 * @param {import("./helpFunctions/findCorrespondingDim").DimRow} dimRow 
 * @param {number} kvs
 * @param {boolean} primBV True if the main check valve is on the primary side. (***Ordinarily on the SR-versions and PR-Sabo***)
 * @param {boolean} extraBV True if the user has choosen an connection-alternative that requires an extra check-valve on the primary side.
 * @param {*} shuntParams 
 * @param {number} minPressure

 * @returns 
 */
const calcPrimPressureDrop = function calcPrimPressureDrop(primFlow, primDrivingPressure, dimRow, kvs, minPressure, primBV, extraBV, cvIsInterval, prodType, prodLine, calcMode, adjustmentValve, shuntParams, connectedEnergyModel, useContext = true, shuntID = null, database = null) {
    if (dimRow != null) {
        let minDrivPressure = calcDeltaPressure(primFlow, dimRow.kvRor);
        if (prodType === PRODUCTFAMILY_VA) {
            if (connectedEnergyModel != null && connectedEnergyModel?.kv != null) {
                minDrivPressure += calcDeltaPressure(primFlow, Number(connectedEnergyModel?.kv));
            }

            return roundNumber(minDrivPressure);
        }

        if (prodType === PRODUCTFAMILY_SR && cvIsInterval === true) {
            return Number(primDrivingPressure);
        }

        if (extraBV === true) {
            if (prodLine === PRODUCTLINE_GREEN) {
                minDrivPressure += calcDeltaPressure(primFlow, dimRow.kvBV_Green);
            }
            else {
                minDrivPressure += calcDeltaPressure(primFlow, dimRow.kvBV);
            }
        }

        if (prodLine === PRODUCTLINE_FLEX) {
            minDrivPressure += minPressure;
        }
        else {
            if (prodLine !== PRODUCTLINE_SMART) {
                minDrivPressure += calcDeltaPressure(primFlow, dimRow[getAdjustmentValveModel(calcMode, prodLine, adjustmentValve, shuntParams, dimRow.dim, useContext, shuntID, database)]);
            }

            if (prodType === PRODUCTFAMILY_SR) {
                minDrivPressure += calcDeltaPressure(primFlow, kvs);
            }
            else if (primBV) {
                if (prodLine === PRODUCTLINE_GREEN) {
                    minDrivPressure += calcDeltaPressure(primFlow, dimRow.kvBV_Green);
                }
                else {
                    minDrivPressure += calcDeltaPressure(primFlow, dimRow.kvBV);
                }
            }
        }

        if (connectedEnergyModel != null && connectedEnergyModel?.kv != null) {
            //console.log("CONNECTION: ", connectedEnergyModel)
            minDrivPressure += calcDeltaPressure(primFlow, Number(connectedEnergyModel?.kv));
        }


        return roundNumber(minDrivPressure);
    }
    else {
        return 0;
    }
}

/**
 * 
 * @param {*} secFlow 
 * @param {*} primFlow 
 * @param {*} kvs 
 * @param {import("./helpFunctions/findCorrespondingDim").DimRow} dimRow 
 * @param {boolean} secondaryBV True if the main check valve is on the secondary side. (***Ordinarily on the PR-versions except PR-Sabo***)
 * @param {*} shuntParams 
 * @returns 
 */
const calcSecondaryPressureDrop = function calcSecondaryPressureDrop(secFlow, primFlow, kvs, dimRow, secondaryBV, shuntParams, cvIsInterval, prodType, prodLine, calcMode, adjustmentValve, numBV, useContext = true, shuntID = null, database = null) {
    if (dimRow != null) {
        if (prodType === PRODUCTFAMILY_VA) {
            let minDrivPressure = calcDeltaPressure(primFlow, dimRow.kvRor, false);
            minDrivPressure += calcDeltaPressure(secFlow, kvs, false);
            if (cvIsInterval !== true) {
                minDrivPressure += calcDeltaPressure(secFlow, dimRow[getAdjustmentValveModel(calcMode, prodLine, adjustmentValve, shuntParams, dimRow.dim, useContext, shuntID, database)], false);
            }
            return roundNumber(minDrivPressure);
        }
        else {
            let minDrivPressure = calcDeltaPressure(secFlow, dimRow.kvRor, false);

            if (prodType === PRODUCTFAMILY_PR) {
                if (cvIsInterval === true) {
                    minDrivPressure += 12; //Number should be stored with VXG11?
                    return roundNumber(minDrivPressure);
                }
                else {
                    minDrivPressure += calcDeltaPressure(secFlow, kvs, false);
                }
            }

            minDrivPressure += calcDeltaPressure(secFlow, dimRow[getAdjustmentValveModel(calcMode, prodLine, adjustmentValve, shuntParams, dimRow.dim, useContext, shuntID, database)], false);

            if (secondaryBV && numBV !== 0) {
                if (prodLine === PRODUCTLINE_GREEN) {
                    minDrivPressure += calcDeltaPressure(secFlow, dimRow.kvBV_Green, false);
                }
                else {
                    minDrivPressure += calcDeltaPressure(secFlow, dimRow.kvBV, false);
                }
            }

            return roundNumber(minDrivPressure);
        }
    }
    else {
        return 0;
    }
}

const calcExtraBVInterval = function calcExtraBVInterval(primFlow, dimRow, prodLine) {
    let bvPressure = 0;
    if (prodLine === PRODUCTLINE_GREEN) {
        bvPressure = calcDeltaPressure(primFlow, dimRow.kvBV_Green);
    }
    else {
        bvPressure = calcDeltaPressure(primFlow, dimRow.kvBV);
    }

    return roundNumber(bvPressure);
}

export { calcPrimPressureDrop, calcSecondaryPressureDrop, calcExtraBVInterval }