import { Box, Button, DialogContent, LinearProgress, Stack, Typography } from "@mui/material"
import { BootstrapDialog, BootstrapDialogTitle } from "../../utils/bootstrapDialog"
import { useContextAuth } from "../../../authContext/AuthHook";
import { useState } from "react";
import { useAdjustParams, useCalcParams, useDriftParams, useErrorContext, useModifySpecContext, useNoticeContext, useProductLineParams, usePumpParams, useSessionParams, useShuntParams, useWizardParams } from "../../../contexts";
import { SessionParamsEnum } from "../../../enums";
import EditIcon from '@mui/icons-material/Edit';
import { OverviewPanels } from "../../../constants/overviewPanels";
import { loadNewSessionFromFB } from "../../../firebase/loadNewSessionFromFB";
import { useDatabase } from "reactfire";
import { useNavigate } from "react-router-dom";


export const NewSessionOptions = ({ sessionsID, open, handleClose }) => {
    const { user } = useContextAuth();

    const sessionParams = useSessionParams();
    const shuntParams = useShuntParams();
    const errorProvider = useErrorContext();
    const noticeProvider = useNoticeContext();

    const database = useDatabase();
    const navigate = useNavigate();
    const adjustParams = useAdjustParams();
    const productLineParams = useProductLineParams();
    const calcParams = useCalcParams();
    const driftParams = useDriftParams();
    const pumpParams = usePumpParams();
    const modifySpecParams = useModifySpecContext();
    const wizardParams = useWizardParams();

    const [loading, setLoading] = useState(false);

    const handleConfirm = (/** @type {boolean} */ edit) => {
        console.log("Start!", edit, sessionsID, user)
        setLoading(true);
        loadNewSessionFromFB(database, sessionsID, edit, sessionParams, driftParams, shuntParams, calcParams, adjustParams, pumpParams, productLineParams, modifySpecParams, wizardParams, errorProvider, noticeProvider, user).then(() => {
            console.log("Swithed!");
            navigate('/stepper');
        }).catch((err) => {
            console.log("Error: ", err);
        }).finally(() => {
            setLoading(false);
            SessionParamsEnum.OverviewPanel.setContextValue(sessionParams, OverviewPanels.shuntPanel);
            handleClose();
        })
    }

    return (
        <>
            <BootstrapDialog
                style={{
                    minWidth: 540,
                    minHeight: 540,
                }}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >

                <BootstrapDialogTitle component="h3" sx={{ paddingLeft: '20px', paddingTop: '0px', paddingBottom: '0px' }} onClose={handleClose}>
                    <h3 className="header-title">Sessions-läge</h3>
                </BootstrapDialogTitle>
                <DialogContent>
                    <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center', paddingBottom: '1px', width: 380 }}>
                        Välj om du vill öppna sessionen i Gransknings-läge, eller om du vill skapa en ny session baserad på den tidigare
                        öppen för redigering.
                    </Typography>
                    <Typography gutterBottom style={{ fontStyle: 'italic', justifyContent: 'center', paddingBottom: '1px', width: 380 }}>
                        Tips: Du kan alltid skifta från Granska till Redigera genom att klicka på <EditIcon sx={{ height: '16px !important', marginLeft: '4px', marginRight: '-1px' }} /> bredvid sessions-IDt
                    </Typography>
                </DialogContent>

                <Box height={'4px'}>
                    {loading ? (
                        <LinearProgress />
                    ) : ("")}
                </Box>

                <Stack sx={{ marginBottom: '17px' }} direction={'row'} spacing={2} justifyContent={'space-around'}>
                    <Button variant="outlined" onClick={() => handleConfirm(false)}>
                        Granska
                    </Button>
                    <Button variant="outlined" onClick={() => handleConfirm(true)}>
                        Redigera (Ny session)
                    </Button>
                </Stack>
            </BootstrapDialog>
        </>

    )
}