import { createContext, useReducer, useContext } from 'react'
import { DRIFT_PARAMS } from '../constants/sessionStoragePaths';

import {
    CALCMODE_KEY,
    FLOW_CALC_MODE_KEY,
    HAS_BACKVENTS_KEY,
    HAS_MAINPUMP_KEY,
    IV_MOD_KEY,
    LOCKED_PRIM_DRIVING_PRESSURE_KEY,
    LOCKED_PRIM_EXTERNAL_PRESSURE_DROP_KEY,
    LOCKED_PRIM_FLOW_KEY,
    LOCKED_PRIM_TEMP_IN_KEY,
    LOCKED_PRIM_TEMP_OUT_KEY,
    LOCKED_SEC_EXTERNAL_PRESSURE_DROP_KEY,
    LOCKED_SEC_FLOW_KEY,
    LOCKED_SEC_TEMP_IN_KEY,
    LOCKED_SEC_TEMP_OUT_KEY,
    NUM_BACKVENTS_KEY, PEFK_KEY,
    PRIM_DIM_KEY,
    PRIM_DRIVING_PRESSURE_KEY,
    PRIM_EXTERNAL_PRESSURE_DROP_KEY,
    PRIM_FLOW_KEY,
    PRIM_TEMP_IN_KEY,
    PRIM_TEMP_OUT_KEY,
    SEC_DIM_KEY,
    SEC_EXTERNAL_PRESSURE_DROP_KEY,
    SEC_FLOW_EFFECT_KEY,
    SEC_FLOW_KEY,
    SEC_TEMP_IN_KEY,
    SEC_TEMP_OUT_KEY,
    USE_CONNECTED_ENERGY_KEY
} from '../constants/keys/driftParamsKeys';
import { ref, remove } from 'firebase/database';
import { SESSION_SHUNT_PATH, SHUNT_DRIFTPARAMS, SHUNT_DRIFTPARAMS_PRIM_DRIVING_PRESSURE_PATH, SHUNT_DRIFTPARAMS_PRIM_TEMP_IN_PATH, SHUNT_DRIFTPARAMS_PRIM_TEMP_OUT_PATH, SHUNT_DRIFTPARAMS_USE_CONNECTED_ENERGY_PATH } from '../constants/firebasePaths';

const DriftParams = createContext(undefined);

function driftParamsReducer(state, action) {
    switch (action.type) {
        case 'setPrimDim': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[PRIM_DIM_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setSekDim': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[SEC_DIM_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setPrimTempIn': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[PRIM_TEMP_IN_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'clearPrimTempIn': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            if (action?.database != null && action?.shuntID != null){
                const dbRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_DRIFTPARAMS_PRIM_TEMP_IN_PATH);
                remove(dbRef);
            }

            delete data.primTempIn;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setSecTempIn': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[SEC_TEMP_IN_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'clearSecTempIn': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            delete data.secTempIn;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setPrimTempOut': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[PRIM_TEMP_OUT_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'clearPrimTempOut': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            if (action?.database != null && action?.shuntID != null){
                const dbRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_DRIFTPARAMS_PRIM_TEMP_OUT_PATH);
                remove(dbRef);
            }

            delete data.primTempOut;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setSecTempOut': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[SEC_TEMP_OUT_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'clearSecTempOut': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            delete data.secTempOut;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setPrimFlow': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[PRIM_FLOW_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setSecFlow': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[SEC_FLOW_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setPrimDrivingPressure': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[PRIM_DRIVING_PRESSURE_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'clearPrimDrivingPressure': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            if (action?.database != null && action?.shuntID != null){
                const dbRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_DRIFTPARAMS_PRIM_DRIVING_PRESSURE_PATH);
                remove(dbRef);
            }

            delete data.primDrivingPressure;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setSecExternalPressureDrop': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[SEC_EXTERNAL_PRESSURE_DROP_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setPrimExternalPressureDrop': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[PRIM_EXTERNAL_PRESSURE_DROP_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setCalcMode': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[CALCMODE_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setNumBackVents': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[NUM_BACKVENTS_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setHasBackVent': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[HAS_BACKVENTS_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setHasMainPump': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[HAS_MAINPUMP_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setPEfk': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[PEFK_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setIVMod': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[IV_MOD_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setLockedPrimTempIn': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[LOCKED_PRIM_TEMP_IN_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setLockedPrimTempOut': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[LOCKED_PRIM_TEMP_OUT_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setLockedPrimFlow': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[LOCKED_PRIM_FLOW_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setLockedSecFlow': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[LOCKED_SEC_FLOW_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setLockedSecExternalPressureDrop': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[LOCKED_SEC_EXTERNAL_PRESSURE_DROP_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setLockedPrimDrivingPressure': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[LOCKED_PRIM_DRIVING_PRESSURE_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setLockedPrimExternalPressureDrop': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[LOCKED_PRIM_EXTERNAL_PRESSURE_DROP_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setLockedSecTempIn': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[LOCKED_SEC_TEMP_IN_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setLockedSecTempOut': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[LOCKED_SEC_TEMP_OUT_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setFlowCalcMode': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[FLOW_CALC_MODE_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setSecFlowEffect': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[SEC_FLOW_EFFECT_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'setUseConnectedEnergy': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            data[USE_CONNECTED_ENERGY_KEY] = action.payload;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'clearUseConnectedEnergy': {
            let data = {};
            if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
                data = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
            }

            if (action?.database != null && action?.shuntID != null){
                const dbRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_DRIFTPARAMS_USE_CONNECTED_ENERGY_PATH);
                remove(dbRef);
            }

            delete data.useConnectedEnergy;
            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        case 'clear': {
            sessionStorage.removeItem(DRIFT_PARAMS);
            return { val: {} }
        }
        case 'clearFB': {
            sessionStorage.removeItem(DRIFT_PARAMS);

            if (action?.database != null && action?.shuntID != null){
                const dbRef = ref(action.database, SESSION_SHUNT_PATH + action.shuntID + SHUNT_DRIFTPARAMS);
                remove(dbRef);
            }

            return { val: {} }
        }
        case 'setDataFromSnapShot': {
            let data = {};

            data[PRIM_DIM_KEY] = action.payload.child(PRIM_DIM_KEY).val() || undefined;
            data[SEC_DIM_KEY] = action.payload.child(SEC_DIM_KEY).val() || undefined;

            if (action.payload.child(PRIM_TEMP_IN_KEY).exists()) {
                data[PRIM_TEMP_IN_KEY] = action.payload.child(PRIM_TEMP_IN_KEY).val();
            }
            if (action.payload.child(SEC_TEMP_IN_KEY).exists()) {
                data[SEC_TEMP_IN_KEY] = action.payload.child(SEC_TEMP_IN_KEY).val();
            }
            if (action.payload.child(PRIM_TEMP_OUT_KEY).exists()) {
                data[PRIM_TEMP_OUT_KEY] = action.payload.child(PRIM_TEMP_OUT_KEY).val();
            }
            if (action.payload.child(SEC_TEMP_OUT_KEY).exists()) {
                data[SEC_TEMP_OUT_KEY] = action.payload.child(SEC_TEMP_OUT_KEY).val();
            }
            if (action.payload.child(PRIM_FLOW_KEY).exists()) {
                data[PRIM_FLOW_KEY] = action.payload.child(PRIM_FLOW_KEY).val();
            }
            if (action.payload.child(SEC_FLOW_KEY).exists()) {
                data[SEC_FLOW_KEY] = action.payload.child(SEC_FLOW_KEY).val();
            }
            if (action.payload.child(PRIM_DRIVING_PRESSURE_KEY).exists()) {
                data[PRIM_DRIVING_PRESSURE_KEY] = action.payload.child(PRIM_DRIVING_PRESSURE_KEY).val();
            }
            if (action.payload.child(SEC_EXTERNAL_PRESSURE_DROP_KEY).exists()) {
                data[SEC_EXTERNAL_PRESSURE_DROP_KEY] = action.payload.child(SEC_EXTERNAL_PRESSURE_DROP_KEY).val();
            }
            if (action.payload.child(PRIM_EXTERNAL_PRESSURE_DROP_KEY).exists()) {
                data[PRIM_EXTERNAL_PRESSURE_DROP_KEY] = action.payload.child(PRIM_EXTERNAL_PRESSURE_DROP_KEY).val();
            }
            if (action.payload.child(CALCMODE_KEY).exists()) {
                data[CALCMODE_KEY] = action.payload.child(CALCMODE_KEY).val();
            }
            if (action.payload.child(NUM_BACKVENTS_KEY).exists()) {
                data[NUM_BACKVENTS_KEY] = action.payload.child(NUM_BACKVENTS_KEY).val();
            }
            if (action.payload.child(HAS_BACKVENTS_KEY).exists()) {
                data[HAS_BACKVENTS_KEY] = action.payload.child(HAS_BACKVENTS_KEY).val();
            }
            if (action.payload.child(HAS_MAINPUMP_KEY).exists()) {
                data[HAS_MAINPUMP_KEY] = action.payload.child(HAS_MAINPUMP_KEY).val();
            }
            if (action.payload.child(PEFK_KEY).exists()) {
                data[PEFK_KEY] = action.payload.child(PEFK_KEY).val();
            }
            if (action.payload.child(IV_MOD_KEY).exists()) {
                data[IV_MOD_KEY] = action.payload.child(IV_MOD_KEY).val();
            }
            if (action.payload.child(FLOW_CALC_MODE_KEY).exists()) {
                data[FLOW_CALC_MODE_KEY] = action.payload.child(FLOW_CALC_MODE_KEY).val();
            }
            if (action.payload.child(SEC_FLOW_EFFECT_KEY).exists()) {
                data[SEC_FLOW_EFFECT_KEY] = action.payload.child(SEC_FLOW_EFFECT_KEY).val();
            }
            if (action.payload.child(USE_CONNECTED_ENERGY_KEY).exists()) {
                data[USE_CONNECTED_ENERGY_KEY] = action.payload.child(USE_CONNECTED_ENERGY_KEY).val();
            }


            sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(data));
            return { val: data }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function DriftParamsProvider({ children }) {
    let obj = {};
    if (sessionStorage.getItem(DRIFT_PARAMS) != null) {
        obj = JSON.parse(sessionStorage.getItem(DRIFT_PARAMS));
    }
    else {
        obj = { hasBackVent: "1" }
        sessionStorage.setItem(DRIFT_PARAMS, JSON.stringify(obj));
    }

    const [state, dispatch] = useReducer(driftParamsReducer, { val: obj })
    const value = { state, dispatch }
    return (
        <DriftParams.Provider value={value}>
            {children}
        </DriftParams.Provider>);
}

function useDriftParams() {
    const context = useContext(DriftParams)
    if (context === undefined) {
        throw new Error('useDriftParams must be used within a DriftParams')
    }
    return context
}


export { DriftParamsProvider, useDriftParams }