import { SYSTEM_FLUID } from "../../constants/wizardConstants";
import { toFixedIfNecessary } from "./helpFunctions/toFixedIfNecessary";

/**
 * Density (g/liter)
 */
const fluidHeatCapacity = {
    [SYSTEM_FLUID.water.value]: 4.18,
    [SYSTEM_FLUID.glycol.value]: 2.39,
    [SYSTEM_FLUID.ethanol.value]: 2.44
}

/**
 * Density (g/liter)
 */
const fluidDensity = {
    [SYSTEM_FLUID.water.value]: 1000,
    [SYSTEM_FLUID.glycol.value]: 1113,
    [SYSTEM_FLUID.ethanol.value]: 789
}

/**
 * 
 * @param {number} flow l/s
 * @param {import("../../hooks/useGetSystemFluidOptions").SystemFluid} systemFluidObj
 * @param {number} fluidMix
 * @param {number} fromTemp ℃
 * @param {number} toTemp ℃
 * @returns {number}
 */
export const calcEffectFromFlow = (flow, systemFluidObj, fluidMix, fromTemp, toTemp) => {
    let avgTemp = Math.round((fromTemp + toTemp) / 2);
    let capacityCalcVal = getCapacityValue(systemFluidObj, fluidMix, avgTemp);
    
    if (capacityCalcVal == null){
        return null;
    }
    const res = flow * capacityCalcVal * (toTemp - fromTemp);
    //console.log("Result (Flow => Effect): ", fluidMix, avgTemp, fromTemp, toTemp);
    return toFixedIfNecessary(res, 1);
}

/**
 * 
 * @param {number} effect kW
 * @param {import("../../hooks/useGetSystemFluidOptions").SystemFluid} systemFluidObj
 * @param {number} fluidMix
 * @param {number} fromTemp ℃
 * @param {number} toTemp ℃
 * @returns {number}
 */
export const calcFlowFromEffect = (effect, systemFluidObj, fluidMix, fromTemp, toTemp) => {
    let avgTemp = Math.round((fromTemp + toTemp) / 2);
    let capacityCalcVal = getCapacityValue(systemFluidObj, fluidMix, avgTemp);
    
    if (capacityCalcVal == null){
        return null;
    }

    const res = effect / (capacityCalcVal * (toTemp - fromTemp));
    //console.log("Result: (Effect => Flow)", fluidMix, avgTemp, fromTemp, toTemp)

    return toFixedIfNecessary(res, 2);
}

/**
 * 
 * @param {import("../../hooks/useGetSystemFluidOptions").SystemFluid} systemFluidObj 
 * @param {number} fluidMix 
 * @param {number} avgTemp 
 */
const getCapacityValue = (systemFluidObj, fluidMix, avgTemp) => {
    let capacityCalcVal = systemFluidObj?.defaultFallbackCalcValue;
    if (systemFluidObj?.FluidCapacityTable != null && systemFluidObj?.FluidDensityTable != null){
        let densityRow = systemFluidObj?.FluidDensityTable[fluidMix];
        let capacityRow = systemFluidObj?.FluidCapacityTable[fluidMix];

        let densityVal;
        let capacityVal;

        if (densityRow != null && capacityRow != null){
            densityVal = densityRow[findClosestKey(densityRow, avgTemp)];
            capacityVal = capacityRow[findClosestKey(capacityRow, avgTemp)];

            if (densityVal != null && capacityVal != null){
                capacityCalcVal = densityVal * capacityVal;
            }
            else{
                console.error("Capacity Calc Value Error: Density and/or Capacity could not be found!", densityVal, capacityVal, densityRow, capacityRow);
            }
        }
        else{
            console.error("Capacity Calc Value Error: FluidMix Row could not be found for Density and/or Capacity!", densityRow, capacityRow, systemFluidObj?.FluidDensityTable, systemFluidObj?.FluidCapacityTable);
        }
    }
    else{
        console.error("Capacity Calc Value Error: FluidCapacityTable and/or FluidCapacityTable is null!", systemFluidObj);
    }

    return toFixedIfNecessary(capacityCalcVal, 2);
}

function findClosestKey(row, targetVal) {
    const keys = Object.keys(row).map(Number);

    let closestKey = keys[0];
    let smallestDifference = Math.abs(targetVal - closestKey);

    for (let key of keys) {
        const difference = Math.abs(targetVal - key);
        if (difference < smallestDifference) {
            smallestDifference = difference;
            closestKey = key;
        }
    }

    return closestKey;
}
