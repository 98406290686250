import { httpsCallable } from "firebase/functions"

/**
 * 
 * @param {import("firebase/functions").Functions} functions 
 * @param {string} inviteID 
 * @param {string} email 
 * @param {string} password 
 * @param {string} name 
 * @param {string} companyName 
 * @returns 
 */
const createSLUser = function createSLUser(functions, inviteID, email, password, name, companyName) {
    functions.region = 'europe-west1';
    const createSLUserFunc = httpsCallable(functions, "createSLUser");

    return createSLUserFunc({ 
        inviteID: inviteID,
        email: email,
        password: password,
        name: name,
        companyName: companyName,
    });
}

export { createSLUser }