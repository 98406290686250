import { Paper, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material"
import { THERMO_LABELS } from "../../../../../constants/adjustmentsLables"
import { THERMO_BIMETALLIC, THERMO_QVINTUS, THERMO_SPRIT110MM } from "../../../../../constants/adjustmentsOptions"
import { AdjustmentsEnum } from "../../../../../enums"

export const AdjustCardThermo = ({ thermoValue, setAdjustParams, isDisabled }) => {
    return (
        <div>
            <Paper elevation={0} className="adjust-card">
                <FormControl>
                    <FormLabel id="thermo-radio-buttons-group">Termometrar</FormLabel>
                    <RadioGroup
                        aria-labelledby="thermo-radio-buttons-group"
                        name="thermo"
                        value={thermoValue}
                        onChange={(event) => { setAdjustParams(event.target.value, AdjustmentsEnum.ThermoValue) }}
                    >

                        <FormControlLabel value={THERMO_BIMETALLIC} control={<Radio />} label={THERMO_LABELS[THERMO_BIMETALLIC]} disabled={isDisabled()} />
                        <FormControlLabel value={THERMO_SPRIT110MM} control={<Radio />} label={THERMO_LABELS[THERMO_SPRIT110MM]} disabled={isDisabled()} />
                        <FormControlLabel value={THERMO_QVINTUS} control={<Radio />} label={THERMO_LABELS[THERMO_QVINTUS]} disabled={isDisabled()} />

                    </RadioGroup>
                </FormControl>
            </Paper>
        </div>
    )
}