import { useDatabase, useDatabaseObjectData } from "reactfire";
import { useSessionParams, useShuntParams } from "../../../../contexts";
import { ref } from "firebase/database";
import { SESSION_SHUNT_PATH } from "../../../../constants/firebasePaths";
import { Button, DialogActions, DialogContent, Divider, IconButton, Stack, styled, TableCell, TableRow, Typography } from "@mui/material";
import { SessionParamsEnum } from "../../../../enums";
import { useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { ADDON_LABELS } from "../../../../constants/addonLabels";
import { MagicloudCadDownloadButtons } from "../../magicloud/cadDownloadButtons";
import { LoadNewShuntComponent } from "../../../utils/loadNewShuntComponent";
import { EditAddonModal } from "./editAddonModal";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../utils/bootstrapDialog";
import { useContextAuth } from "../../../../authContext/AuthHook";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const FirebaseAddonRow = ({ shuntID, editMode, handleClose, magicloudIDs, loading }) => {
    const { user } = useContextAuth();
    const database = useDatabase();
    const sessionParams = useSessionParams();
    const shuntParams = useShuntParams();

    const [openModalEditAddon, setOpenModalEditAddon] = useState(false);
    const [openShunt, setOpenShunt] = useState(false);
    const [loadingShunt, setLoadingShunt] = useState(false);
    const handleModalEditAddonOpen = () => { setOpenModalEditAddon(true) }
    const handleModalEditAddonClose = () => { setOpenModalEditAddon(false) }

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const handleOpenDeleteModal = () => { setOpenDeleteModal(true) }
    const handleCloseDeleteModal = () => { setOpenDeleteModal(false) }

    const [productID, setProductID] = useState("");

    const shuntRef = ref(database, SESSION_SHUNT_PATH + shuntID);

    const response = useDatabaseObjectData(shuntRef);
    const { status, data } = response;

    const selectShunt = (event) => {
        if (event?.target?.cellIndex != null) {
            //console.log("Hello?", editMode, shuntID)
            if (editMode !== true && SessionParamsEnum.CurrentShunt.getContextValue(sessionParams) !== shuntID) {
                setLoadingShunt(true);
                setOpenShunt(true);
            }
        }
    }

    useEffect(() => {
        if (loading === false) {
            let prodID = "";

            if (data?.ShuntData?.addonType === 'mixingVessel' && data?.ShuntData?.AddonData?.size != null) {
                let size = String(data?.ShuntData?.AddonData?.size).split(' ')[0];
                prodID = 'PRE-BW-' + size;
            }

            let magicadUID = magicloudIDs[prodID];
            //console.log("Row: ", prodID, " => ", magicadUID)
            setProductID(magicadUID);
        }
    }, [magicloudIDs, loading, status, data])

    const isDisabled = () => {
        if (SessionParamsEnum.Editable.getContextValue(sessionParams) === false) {
            return true;
        }
        else {
            return false;
        }
    }

    const getPos = () => {
        if (data?.Baseinfo?.position != null) {
            return "Pos. " + String(data.Baseinfo.position);
        }
        else {
            return "";
        }
    }

    const getName = () => {
        let name = "";
        if (data?.ShuntData?.addonType != null) {
            if (ADDON_LABELS[data?.ShuntData?.addonType] != null) {
                name = ADDON_LABELS[data?.ShuntData?.addonType];
            }
            else {
                name = data?.ShuntData?.addonType;
            }
        }

        return name;
    }

    const getProdLine = () => {
        if (data?.PLData?.prodLine != null) {
            return String(data.PLData.prodLine);
        }
        else {
            return "";
        }
    }

    const getExtraData = () => {
        let text = "";

        if (data?.ShuntData?.addonType === 'mixingVessel' && data?.ShuntData?.AddonData?.size != null) {
            text = data?.ShuntData?.AddonData?.size;
        }

        return text;
    }

    const loadShunt = () => {
        if (openShunt === true) {
            //setOpen(false);
            return (
                <LoadNewShuntComponent shuntID={shuntID} setOpen={setOpenShunt} showLoad={false} />
            );
        }
        else {
            return (
                <></>
            )
        }
    }

    const handleDeleteAddon = () => {
        if (data?.ShuntData?.shuntID != null && data?.ShuntData?.shuntID !== "") {
            SessionParamsEnum.deleteShuntAddon(sessionParams, database, shuntParams, data?.NO_ID_FIELD, "mixingVessel", data?.ShuntData?.shuntID, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams) === data?.ShuntData?.shuntID);
        }
        else {
            SessionParamsEnum.deleteShuntAddon(sessionParams, database, shuntParams, data?.NO_ID_FIELD, "mixingVessel", null, false);
        }

        handleCloseDeleteModal();
    }

    return (
        <>
            <StyledTableRow key={shuntID} onClick={selectShunt} onDoubleClick={() => { selectShunt(); handleClose(); }} selected={shuntID === SessionParamsEnum.CurrentShunt.getContextValue(sessionParams)} hover={editMode ? false : true}>
                <TableCell className="table-cell"><FactCheckIcon fontSize="small" color="success" /></TableCell>
                <TableCell className={editMode ? "table-cell" : "table-cell pointer"}>{getPos()}</TableCell>
                <TableCell className={editMode ? "table-cell" : "table-cell pointer"}>{getName()}</TableCell>
                <TableCell className={editMode ? "table-cell" : "table-cell pointer"}>{getExtraData()}</TableCell>
                <TableCell className={editMode ? "table-cell" : "table-cell pointer"}>{getProdLine()}</TableCell>
                <TableCell className={editMode ? "table-cell" : "table-cell pointer"}></TableCell>
                <TableCell className={editMode ? "table-cell" : "table-cell pointer"}></TableCell>
                <TableCell className={editMode ? "table-cell" : "table-cell pointer"}></TableCell>
                <TableCell className={editMode ? "table-cell" : "table-cell pointer"}></TableCell>
                <TableCell className={editMode ? "table-cell" : "table-cell pointer"}></TableCell>

                {SessionParamsEnum.IsApiSession.getContextValue(sessionParams) !== true ? (
                    <TableCell sx={{ padding: '0px' }}>
                        <Stack direction={'row'} spacing={0.5}>
                            <span><Divider orientation="vertical" /></span>
                            <MagicloudCadDownloadButtons shuntData={data} productId={productID} smallButtons />
                            <span><Divider orientation="vertical" /></span>
                        </Stack>
                    </TableCell>
                ) : ("")}

                {SessionParamsEnum.IsApiSession.getContextValue(sessionParams) === true ? (
                    <TableCell sx={{ padding: '0px' }}>
                    </TableCell>
                ) : (
                    <>
                        {!isDisabled() &&
                            <TableCell sx={{ padding: '0px' }}>
                                <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>

                                    <Button onClick={handleOpenDeleteModal} className="delete-btn" disabled={shuntID === SessionParamsEnum.CurrentShunt.getContextValue(sessionParams) || editMode === true}>
                                        <DeleteIcon fontSize="small" />
                                    </Button>
                                    {user?.isAnonymous !== true && user?.isInternal === true ? (
                                        <>
                                            <span style={{ height: '24px' }}><Divider orientation="vertical" /></span>
                                            <IconButton onClick={handleModalEditAddonOpen} size="small">
                                                <EditIcon fontSize="small" style={{ height: '17px', width: '17px', color: "#f7c03f" }} />
                                            </IconButton>
                                        </>
                                    ) : ("")}
                                </Stack>
                            </TableCell>
                        }
                    </>
                )}
                {loadShunt()}
            </StyledTableRow>

            <BootstrapDialog
                onClose={handleCloseDeleteModal}
                aria-labelledby="customized-dialog-title"
                open={openDeleteModal}
            >
                <BootstrapDialogTitle onClose={handleCloseDeleteModal}>
                    Ta bort {getPos() != null ? (<>{getPos()} - {getName() != null ? (getName()) : ("")}</>) : ("")}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom style={{ width: '400px', display: 'flex', justifyContent: 'center' }}>
                        <strong>Är du säker på att du vill ta bort {getPos()} - {getName()}?</strong>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteAddon}>
                        Ja
                    </Button>
                    <Button onClick={handleCloseDeleteModal}>
                        Nej
                    </Button>
                </DialogActions>
            </BootstrapDialog>

            <EditAddonModal openModal={openModalEditAddon} handleModalClose={handleModalEditAddonClose} shuntData={data} />
        </>

    )

}