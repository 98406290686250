import HelpIcon from '@mui/icons-material/Help';
import greenDrop from '../../assets/images/green_drop.svg'

export const IntroDimension = () => {

    return (
        <div>
            <h2 className="intro-title" style={{ marginBlockStart: '0px' }}>Dimensionera shuntgrupper med ShuntLogik<sup>®</sup></h2>

            <p>
                Beräkningsprogrammet är avsett för dimensionering av konventionella shuntgrupper för kyla, värme och/eller värmeåtervinning. Programmet kan för närvarande inte användas för dimensionering av kombishuntgrupper, växlarshuntgrupper, grupper med fler än fyra anslutningar eller specialshuntgrupper. I dessa fall måste teknisk expertis på PREMA anlitas.
            </p>

            <h3 className="intro-subheaders">Start</h3>
            <p>
                Här anges Anläggning och Projektnummer, vilket ej är obligatoriskt men lämpligt att ange så dessa uppgifter kommer med på den färdiga specifikationen (dock möjligt att lägga till eller justera inne i pågående körning, se mer i avsnittet <i>Översikt och navigering mellan positioner</i>).
            </p>

            <p>
                Gå vidare genom att trycka på <strong><i>Skapa</i></strong>.
            </p>

            <p>
                (För information om inmatning av Sessions-ID för tidigare körning, se avsnitt <i>Hantering av körningar/sessioner</i>)
            </p>

            <h3 className="intro-subheaders">Steg 1. Val av kopplingsprincip</h3>
            <p>
                Här väljs önskad produktlinje:
                <br></br>
                Premablock<sup>®</sup> (shuntgrupper i standardutförande)
                <br></br>
                Premablock<sup>®</sup> green I (miljögodkända shuntgrupper)
                <br></br>
                Premablock<sup>®</sup> green II (miljövarudeklarerade shuntgrupper)
                <br></br>
                Premablock<sup>®</sup> flex (shuntgrupper med automatisk anpassning till variationer i systemets drivtryck)
                <br></br>
                Premablock<sup>®</sup> smart (uppkopplade shuntgrupper med inbyggd mätfunktion och hög styrnoggrannhet)
                <br></br>
                <br></br>
                samt den kopplingsprincip som passar primärkretsens tillgängliga värden.
            </p>

            <p>
                <strong><i>Tips: Sekundärkretsen har alltid konstant flöde om inte sekundärpumpen är varvtalsstyrd.</i></strong>
            </p>

            <p>
                Gå vidare genom att markera önskad kopplingsprincip och tryck på den högra pilen i det övre högra hörnet, alternativt dubbelklicka på önskad princip.
            </p>

            <br></br>

            <h3 className="intro-subheaders">Steg 2. Inskrivning av parametrar och beräkning</h3>
            <p>
                Här sker dimensionering och beräkning på grundval av de inskrivna driftsparametrarna. Om muspekaren placeras över värde-rutorna
                presenteras information om vilka uppgifter som ska anges. Alla värderutor måste vara korrekt ifyllda innan man kan gå vidare.
                Varje förändring som görs av driftsparametrarna i schemat måste uppdateras genom att trycka på ”beräkna” för att beräkningsmodulen
                i programmet skall ta hänsyn till detta. Glöm inte att även ange beteckning och position. Det går inte att gå vidare innan dessa är ifyllda..
            </p>

            <h3 className="intro-subheaders"><i>Beräkningsläge - Automatiskt</i></h3>
            <p>
                Flödet primärt beräknas av programmet baserat på de angivna temperaturdifferenserna sekundärt och primärt.
                Därför behöver värden för detta inte fyllas i.
            </p>

            <p>
                I system som har drivtryck primärt från huvudpumpen, exv. SR-2 och -3, ska det tillgängliga drivtrycket vid inkopplingspunkten anges.
                Om det tillgängliga drivtrycket är okänt kan ett antaget värde, exv. 20 kPa, anges för att möjliggöra dimensioneringen. Värdet bör sedan
                kontrolleras när systemet tryckfallsberäknas och huvudpumpen väljs.
            </p>

            <p>
                Om styrventilen efter beräkning får lågt tryckfall kan man prova att ange ett högre tillgängligt drivtryck primärt för att se hur det
                påverkar styrventilens tryckfall/kvs-värde.
            </p>

            <p>
                Programmet klassificerar shuntgruppen som värme- eller kylshunt baserat på om temperaturen sjunker eller ökar genom sekundärkretsen,
                medan behov av kondensisolering baseras på lägst förekommande temperatur i systemet, oberoende av klassificering värme- eller kylshunt.
            </p>

            <p>
                För kopplingsprincip PR-2 kan val göras för Med respektive Utan huvudpump, samt mellan PR-2 och PR-2 SABO (sekundärkretsmonterad bakventil).
            </p>

            <p>
                För shuntgrupper i green-utförande kan val göras för fabrikat på injusteringsventil (kan göras för övriga utföranden i manuellt läge, se längre ner).
            </p>

            <p>
                Om man skriver in värden som programmet inte godkänner, flaggar programmet genom en röd meddelanderuta, som anger varför värdet inte kan godtas.
                Man måste då ändra värdena och uppdatera. Värden, eller kombinationer av värden, som är tillåtna men som kan vara bra att se över närmare,
                indikeras genom en orange meddelanderuta.
            </p>

            <p>
                När man är nöjd med beräkningen, går man vidare genom att trycka på den högra pilen i det övre högra hörnet.
            </p>

            <h3 className="intro-subheaders"><i>Beräkningsläge - Manuellt</i></h3>
            <p>
                Ibland kan det finnas anledningar att justera kvs-värden och rördimensioner i samband med dimensionering. Man måste då gå över till manuellt
                beräkningsläge. I manuellt läge varnar programmet endast om interntryckfallen blir för höga. Om man är osäker kring detta rekommenderas
                att följa de förslag programmet ger, dvs. använda automatiskt beräkningsläge.
            </p>

            <p>
                Fabrikat på injusteringsventilen kan endast väljas i manuellt beräkningsläge (med undantag för green-utföranden). Glöm inte att uppdatera
                efter valet. Programmet tar hänsyn till det tryckfall som följer med den valda ventilen.
            </p>

            <p>
                Även för att välja fabrikat och kvs-värde (respektive flödesområde för flex- och smart-utföranden) på styrventilen måste man gå över
                till manuellt läge. Programmet listar de vanligast förekommande fabrikaten. De ventiler som inte klarar angiven temperatur utesluts
                av programmet för att minimera risk för fel. Om man väljer en ventil med inställbart kvs-värde, så tar programmet automatiskt bort
                den då överflödiga injusteringsventilen. Glöm inte att uppdatera efter varje ändring.
            </p>

            <p>
                I manuellt läge ges även möjlighet att välja bort backventilen för kopplingsprinciperna SR-2 samt SR-3. Motsvarande tryckfall räknas då bort.
                Glöm inte att uppdatera efter valet.
            </p>

            <p>
                För kopplingsprinciperna SR-2 respektive PR-2 Med huvudpump ingår inte det externa tryckfallet i den flödesvariabla kretsen som en av de
                obligatoriska systemparametrarna, och därmed kan inte styrventilens auktoritet beräknas automatiskt för dessa. I manuellt läge finns dock
                möjlighet att fylla i detta värde (efk) för dessa kopplingsprinciper, så att programmet kan beräkna vilken auktoritet styrventilen får.
            </p>

            <p>
                Programmet kan ta ut styrventilens kvs-värde utan att fabrikat angivits och i den färdiga specifikationen noteras i detta fall ”samordnas med SÖE”.
            </p>

            <p>
                Du kan få mer hjälp och tips genom att klicka på <HelpIcon className="help_icon_small" /> inne i ShuntLogiks<sup>®</sup> Steg 2.
            </p>

            <br></br>

            <h3 className="intro-subheaders">Steg 3. Val av utförande och anpassningar</h3>
            <p>
                Här väljs utförande och övriga anpassningar för shuntgruppen. Det finns tre utföranden på rörkopplet att välja mellan; U, H och L.
                När pekaren hålls över beteckningarna visas en bild på motsvarande rörkoppel. Om inget utförande anges så kommer det att framgå
                av texten i specifikationen. PREMA kommer i dessa fall att kontakta entreprenören för att säkerställa ett utförande som underlättar
                rördragningen.
            </p>

            <p>
                Vidare kan man även välja typ av rörmaterial, typ och fabrikat på avstängningsventiler, typ av termometrar, vägg eller golvstativ,
                samt även göra tillval som exv. manometersatser och ställdon. Standardutförande är alltid förmarkerat. Valbara alternativ som är
                mer miljövänliga än standard indikeras med <img src={greenDrop} alt="green drop" style={{ width: '7px', marginLeft: '1px', marginRight: '1px' }} /> (ej indikerat på shuntgrupper i green-utförande, då samtliga alternativ för dessa tillhör denna kategori).
            </p>

            <p>
                När samtliga val av anpassningar är klara går man vidare genom att trycka på den högra pilen i det övre högra hörnet.
            </p>

            <br></br>

            <h3 className="intro-subheaders">Steg 4. Val av pump</h3>
            <p>
                Här väljs pump alt. dimensioneringsalternativ för shuntgruppens pump. Att välja rätt pump är viktigt ur energisynpunkt och det finns
                stora ekonomiska vinster att göra om man väljer rätt.
            </p>

            <p>
                Programmet medger att man plockar ut pumpen själv och skriver den i klartext. Det enklaste är att använda pumptillverkarnas
                beräkningsprogram för att få tillgång till de senaste uppdateringarna. Programmet listar länkar till de vanligaste pumpfabrikanterna.
                Vid egen dimensionering är det viktigt att inte glömma att ta hänsyn till om brainen ska vara annan än vatten.
            </p>

            <p>
                Som alternativ till att plocka ut pumpen själv, kan användaren låta PREMA välja en passande pump. Detta görs genom att markera
                ”Leverantören får dimensionera”. Man har då möjlighet att styra vilket fabrikat som ska väljas. Alla data noteras i specifikationen.
            </p>

            <p>
                Glöm inte att ange om pumpen skall vara tryckstyrd, ha larmutgång, 0-10V styrning, samt ha extern start/stopp funktion.
                Kontrollera även att rätt systemvätska har använts i dimensioneringen, och om alternativet <i>Annan</i> har valts i tidigare steg ges
                här möjlighet att specificera närmare.
            </p>

            <br></br>

            <h3 className="intro-subheaders">Steg 5. Granska</h3>
            <p>
                Här visas för granskning den kompletta specifikationen för den senast dimensionerade shuntgruppen, inkl. samtliga tekniska data och en
                principskiss med mått och vikt. Vill man ändra något går det att stega tillbaka med vänsterpilen uppe i högra hörnet, alternativt
                klicka direkt på det steg man önskar gå till för att justera. Är man nöjd med specifikationen finns två alternativa val:
            </p>

            <p>
                1. <strong>Lägg till shuntgrupp</strong> – För att skapa ytterligare shuntgrupp i körningen. Ger valet att basera den nya shuntgruppen
                på den aktuella, alternativt skapa en helt ny från grunden.
            </p>

            <p>
                2. Gå till <strong>Översikt</strong> –  För att se samlad specifikation över alla positioner i körningen
                (med möjlighet till direktutskrift eller att spara ner i Word-filformat), eller använda övrig funktionalitet beskriven i avsnittet
                Översikt och navigering mellan positioner.
            </p>
        </div>
    )
}