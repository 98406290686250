import { useState } from "react";
import { Box, Fade, IconButton, Stack, Typography } from "@mui/material";
import { useSessionParams } from "../../../contexts/SessionParams";
import { OverviewSidePanel } from "./sidePanel";
import { DevPanelOverview } from "./devOverview";
import { ShuntPanelOverview } from "./shuntOverview";
import { SpecOverview } from "./specOverview";
import { ModifySpec } from "./modifySpec";
import { SystemSpecOverview } from "../specOverview/systemSpec";
import { PriceCalcOverview } from "./priceCalcPanel";
import { SessionParamsEnum } from "../../../enums";
import { useGetShuntList, useGetShuntListName } from "../../../firebase/functions/getShuntList";
import { MyProfilePanel } from "./myProfilePanel";
import { MySessionHistoryPanel } from "./mySessionHistoryPanel";
import { OverviewPanels } from "../../../constants/overviewPanels";

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export const ShuntOverview = ({ style, open, handleClose, setIsInEdit }) => {
    const sessionParams = useSessionParams();

    const [headerText, setHeaderText] = useState("Hantera Shuntgrupper")

    const [editMode, setEditMode] = useState(false);

    const shuntRes = useGetShuntList();
    const { shuntList, loading } = useGetShuntListName();

    const setEditModeFunc = (val) => {
        setEditMode(val);
        setIsInEdit(val);
    }

    const setHeader = (value) => {
        if (value !== headerText) {
            setHeaderText(value)
        }
    }

    const displayContent = () => {
        switch (SessionParamsEnum.OverviewPanel.getContextValue(sessionParams)) {
            case OverviewPanels.devPanel:
                setHeader("Översikt");
                return (<DevPanelOverview shuntList={shuntRes} handleClose={handleClose} />)
            case OverviewPanels.shuntPanel:
                setHeader("Hantera Shuntgrupper");
                return (<ShuntPanelOverview shuntList={shuntList} loadingShunts={loading} handleClose={handleClose} editMode={editMode} setEditMode={setEditModeFunc} />)
            case OverviewPanels.shuntSpecPanel:
                setHeader("Specifikation");
                return (<SpecOverview shuntList={shuntList} setHeaderText={setHeaderText} />);
            case OverviewPanels.modifySpecPanel:
                setHeader("Modifiera specifikation")
                return (<ModifySpec shuntList={shuntRes} />);
            case OverviewPanels.systemSpecOverview:
                setHeader("Systemvärden")
                return (<SystemSpecOverview />);
            case OverviewPanels.priceCalcOverview:
                setHeader("Prisuträkning")
                return (<PriceCalcOverview />);
            case OverviewPanels.sessionHistoryPanel:
                setHeader("Min sessionshistorik")
                return (<MySessionHistoryPanel />);
            case OverviewPanels.myProfilePanel:
                setHeader("Mina sidor")
                return (<MyProfilePanel />);
            default:
                setHeader("Hantera Shuntgrupper");
                return (<ShuntPanelOverview shuntList={shuntList} loadingShunts={loading} handleClose={handleClose} editMode={editMode} setEditMode={setEditModeFunc} />)
        }
    }

    return (
        <Fade in={open}>
            <Box sx={style} className="modal-size">
                <IconButton style={{ float: 'right', cursor: 'pointer', marginTop: '-20px', marginRight: '-20px' }} onClick={handleClose} disabled={editMode}>
                    <CloseRoundedIcon />
                </IconButton>
                <Typography id="transition-modal-title" component="h3" variant='h3' fontWeight="bold" className="header-title" sx={{ mb: '10px', color: "#183851" }}>
                    {headerText}
                </Typography>

                <Stack direction={'row'} spacing={2}>
                    <div style={{ height: 'auto', }}>
                        {displayContent()}
                    </div>
                    <OverviewSidePanel editMode={editMode} />

                </Stack>

            </Box>
        </Fade>
    );
}