import { useEffect, useState } from 'react'
import { useDatabase, useDatabaseObjectData, useFunctions } from 'reactfire';
import { Box, CircularProgress, FormControl, IconButton, MenuItem, Select, Stack, TextField, Tooltip } from '@mui/material'

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';

import { SESSION_LINKED_PROJECT_PATH, SESSION_PATH, SESSION_STATUS_PATH } from '../../../constants/firebasePaths';
import { SessionParamsEnum } from '../../../enums';
import { ref } from 'firebase/database';
import { useSessionParams } from '../../../contexts';
import { addprojectIDFunc } from '../../../firebase/functions/cloudFunctions/addprojectID';
import { setSessionStatusFunc } from '../../../firebase/functions/cloudFunctions/setSessionStatus';
import { useContextAuth } from '../../../authContext/AuthHook';

export default function DashboardLink() {
    const [prevProjectID, setPrevProjectID] = useState("");
    const [projectID, setProjectID] = useState("");
    const [prevSessionStatus, setPrevSessionStatus] = useState("");
    const [sessionStatus, setSessionStatus] = useState("");
    const [loading, setLoading] = useState(true);
    const [loadingSession, setLoadingSession] = useState(true);
    const [errorSession, setErrorSession] = useState(false);
    const sessionParams = useSessionParams();
    const database = useDatabase();
    const functions = useFunctions()
    const { user } = useContextAuth();

    const numberRegex = /^\d{0,5}$/;

    const linkedProjectRef = ref(database, SESSION_PATH + SessionParamsEnum.SessionID.getContextValue(sessionParams) + SESSION_LINKED_PROJECT_PATH);
    const sessionStatusRef = ref(database, SESSION_PATH + SessionParamsEnum.SessionID.getContextValue(sessionParams) + SESSION_STATUS_PATH);

    const { status, data } = useDatabaseObjectData(linkedProjectRef);
    const { status: sessionFBStatus, data: sessionData } = useDatabaseObjectData(sessionStatusRef);

    useEffect(() => {
        if (SessionParamsEnum.LinkedProject.getContextValue(sessionParams) != null && SessionParamsEnum.LinkedProject.getContextValue(sessionParams) !== "") {
            setProjectID(SessionParamsEnum.LinkedProject.getContextValue(sessionParams));
            setPrevProjectID(SessionParamsEnum.LinkedProject.getContextValue(sessionParams));
        }
    }, [SessionParamsEnum.LinkedProject.getContextValue(sessionParams)])


    useEffect(() => {
        if (status === "success") {
            setLoading(false);
        }

        if (sessionFBStatus === "success") {
            if (sessionData != null) {
                setPrevSessionStatus(sessionData);
                setSessionStatus(sessionData);
                setLoadingSession(false);
            }
            else {
                if ((user?.isDev === true || user?.isTestAccount === true) && SessionParamsEnum.Editable.getContextValue(sessionParams) === true) {
                    functions.region = 'europe-west1';
                    const curSessionState = "test"
                    setSessionStatusFunc(functions, SessionParamsEnum.SessionID.getContextValue(sessionParams), curSessionState).then(() => {
                        //console.log("FB Completed!");
                    }).then(() => {
                        setSessionStatus(curSessionState);
                        setPrevSessionStatus(curSessionState);
                    }).catch((error) => {
                        console.error("FB Error: ", error);
                    }).finally(() => {
                        setLoadingSession(false);
                    });
                }
                else {
                    setLoadingSession(false);
                }

            }
        }
    }, [status, sessionFBStatus]);

    const saveProjectLink = () => {
        functions.region = 'europe-west1';
        setLoading(true);
        addprojectIDFunc(functions, SessionParamsEnum.SessionID.getContextValue(sessionParams), projectID).then(() => {
            console.log("FB Completed!");
            setPrevProjectID(projectID);
            SessionParamsEnum.LinkedProject.setContextValue(sessionParams, projectID)
        }).catch((error) => {
            console.error("FB Error: ", error);
        }).finally(() => {
            setLoading(false);
        })
    }

    const saveSessionStatus = (status) => {
        setLoadingSession(true);
        setSessionStatusFunc(functions, SessionParamsEnum.SessionID.getContextValue(sessionParams), status)
            .then(() => {
                setPrevSessionStatus(sessionStatus);
                setErrorSession(false);
            })
            .catch((error) => {
                console.error("FB Error: ", error);
                setErrorSession(true);
            })
            .finally(() => {
                setLoadingSession(false);
            })
    }

    const handleChangeStatus = (event) => {
        setSessionStatus(event.target.value);
        saveSessionStatus(event.target.value)
    };

    const handleProjectNumber = (event) => {
        if (user?.isDev === true) {
            setProjectID(event.target.value);
        }
        else if (numberRegex.test(event.target.value) === true) {
            setProjectID(event.target.value);
        }
    }

    const displayButton = () => {
        if (loading === true) {
            return (
                <IconButton disabled size='small'>
                    <CircularProgress size={18} />
                </IconButton>
            )
        }
        else {
            if (prevProjectID != null && prevProjectID === "") {
                return (
                    <IconButton disabled={projectID == null || projectID === "" || projectID.length < 5} size='small' onClick={saveProjectLink}>
                        <AddCircleOutlineRoundedIcon htmlColor={projectID == null || projectID === "" || projectID.length < 5 ? '' : '#06559e'} />
                    </IconButton>

                )
            }
            else if (projectID !== prevProjectID && projectID.length > 4) {
                return (
                    <IconButton disabled={projectID === prevProjectID} size='small' onClick={saveProjectLink}>
                        <SaveRoundedIcon htmlColor={'#06559e'} />
                    </IconButton>
                )
            }
        }
    }

    const displayButtonStatus = () => {
        if (loadingSession === true) {
            return (
                <IconButton disabled >
                    <CircularProgress size={14} />
                </IconButton>
            )
        }
        else {
            if (errorSession === true) {
                return (
                    <Tooltip title="Kunde inte spara status." arrow>
                        <span>
                            <IconButton disabled size='small'>
                                <ReportProblemOutlinedIcon color="error" />
                            </IconButton>
                        </span>
                    </Tooltip>
                );


            }
            else {
                return (
                    <Box sx={{ minHeight: '32px' }}>

                    </Box>
                );
            }
        }
    }


    return (
        <>
            <Box style={{
                width: "260px",
                marginLeft: '815px',
                marginTop: '-60px',
                color: '#00000099',
                height: '40px',
                padding: "4px",
                textAlign: 'left',
                position: 'absolute',
            }}>
                <Stack direction={'row'} spacing={1} sx={{ marginTop: '5px', height: '25px' }} alignItems="center">
                    <div style={{ width: '98px' }}>
                        PREMA Projektnr
                    </div>

                    <TextField
                        sx={{ width: '100px' }}
                        value={projectID}
                        variant="outlined"
                        size="small"
                        onChange={handleProjectNumber}
                    />

                    {displayButton()}
                </Stack>
                {user?.isDev === true &&
                    <Stack direction={'row'} spacing={1} alignItems="center" sx={{ marginTop: '1px' }}>
                        <div style={{ width: '98px' }}>
                            Session Status
                        </div>

                        <FormControl sx={{ width: '100px' }} size="small">
                            <Select
                                value={sessionStatus}
                                onChange={handleChangeStatus}
                            >
                                <MenuItem value={""}>Generell</MenuItem>
                                <MenuItem value={'test'}>Test/debugg</MenuItem>
                                <MenuItem value={'archived'}>Arkiverad</MenuItem>
                                <MenuItem value={'altConfig'}>Alternativt utförande</MenuItem>
                            </Select>
                        </FormControl>

                        {displayButtonStatus()}
                    </Stack>}
            </Box >
        </>
    )
}