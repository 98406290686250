import { Typography, Grid, Box, Button } from "@mui/material"
import { useState } from "react";
import { SupportModule } from "../support/supportModal";

export const Footer = () => {
    const [openModal, setOpenModal] = useState(false);
    const [curDate, setCurDate] = useState(new Date())

    const handleOpenModal = () => { setOpenModal(true) }
    const handleCloseModal = () => { setOpenModal(false) }

    return (
        <>
            <div>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <ul style={{ listStyleType: "none" }}>
                                <li><h3 className="footer-h3">PREMA<sup>®</sup> Huvudkontor</h3></li>
                                <li>Franska vägen 17, 393 56 Kalmar</li>
                                <li>Tel: +46 480 560 90</li>
                                <li><a href="mailto: kundservice@prema.se" target="hidden-iframe" style={{ textDecoration: "none", color: '#06559e' }}><strong>Skicka e-post</strong></a></li>

                            </ul>
                        </Grid>
                        <Grid item xs={3}>
                            <ul style={{ listStyleType: "none" }}>
                                <li><h3 className="footer-h3">PREMA<sup>®</sup> Stockholm</h3></li>
                                <li>Industrigatan 4 B, 112 46 Stockholm</li>
                                <li>Tel: +46 8 704 28 82</li>
                                <li><a href="mailto: winge@prema.se" target="hidden-iframe" style={{ textDecoration: "none", color: '#06559e' }}><strong>Skicka e-post</strong></a></li>

                            </ul>
                        </Grid>
                        <Grid item xs={3}>
                            <ul style={{ listStyleType: "none", color: "#183851" }}>
                                <li><h3 className="footer-h3">PREMA<sup>®</sup> Göteborg</h3></li>
                                <li>Lilla Bommen 2, 411 04 Göteborg</li>
                                <li>Tel: +46 31 724 43 85</li>
                                <li><a href="mailto: goteborg@prema.se" target="hidden-iframe" style={{ textDecoration: "none", color: '#06559e' }}><strong>Skicka e-post</strong></a></li>

                            </ul>
                        </Grid>
                        <Grid item xs={3}>
                            <ul style={{ listStyleType: "none" }}>
                                <li><h3 className="footer-h3">PREMA<sup>®</sup> Malmö</h3></li>
                                <li>Stadiongatan 65, 217 62 Malmö</li>
                                <li>Tel: +46 40 666 76 05</li>
                                <li><a href="mailto: magnus.persson@prema.se" target="hidden-iframe" style={{ textDecoration: "none", color: '#06559e', }}><strong>Skicka e-post</strong></a></li>

                            </ul>
                        </Grid>
                    </Grid>
                </Box>
                <iframe title="mail-link" name="hidden-iframe" style={{ visibility: 'hidden', position: 'absolute' }}></iframe>
                <div style={{ margin: 'auto', justifyContent: 'center', padding: '5px 5px 0px 5px', }}>
                    <Typography variant="body2" align="center" fontWeight="bold" color="#b2b1a7" style={{ paddingBottom: "8px", marginTop: "-8px" }}>
                        © Copyright {curDate.getFullYear()} PREMA<sup>®</sup> AB
                    </Typography>
                </div>
                <div>
                    <Button onClick={handleOpenModal} variant="contained" size="small" style={{ position: 'absolute', height: '22px', marginTop: "-27px", marginLeft: '998px', fontSize: '10px', width: '118px' }}>
                        Kontakta support
                    </Button>
                    <SupportModule openModal={openModal} handleModalClose={handleCloseModal} />
                </div>
            </div>


        </>
    );
}