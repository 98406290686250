import { useState, useEffect } from 'react';
import { CircularProgress, FormControl, MenuItem, Select, Stack } from '@mui/material';
import { useSessionParams } from '../../../../contexts/SessionParams';
import { SessionParamsEnum } from '../../../../enums/sessionParamsEnum';
import { ShuntParamsEnum } from '../../../../enums/shuntParamsEnum';
import { useShuntParams } from '../../../../contexts';
import { LoadNewShuntComponent } from '../../../utils/loadNewShuntComponent';
import { useGetShuntListName } from '../../../../firebase/functions/getShuntList';

import { ADDON_LABELS } from '../../../../constants/addonLabels';

export const FirebaseShuntSelect = ({ disabled = false }) => {
    const sessionParams = useSessionParams();
    const shuntParams = useShuntParams();
    const { shuntList, loading: loadingShunts } = useGetShuntListName();


    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);
    const [shunts, setShunts] = useState([]);
    const [current, setCurrent] = useState(SessionParamsEnum.CurrentShunt.getContextValue(sessionParams))
    const [currentSession, setCurrentSession] = useState('');

    const [open, setOpen] = useState(false);

    useEffect(() => {
        setUpdate(!update);
    }, [shuntList, SessionParamsEnum.UpdateValues.getContextValue(sessionParams)])

    /*
    useEffect(() => {
        getShuntListName(database, sessionParams).then((result) => {
            console.log("New list: ", result)
            setShunts(result);
        })
    }, [SessionParamsEnum.SessionShunts.getContextValue(sessionParams), SessionParamsEnum.UpdateValues.getContextValue(sessionParams)]);*/

    /*
    useEffect(() => {
        const getInfo = async () => {

            if (shunts.length !== SessionParamsEnum.SessionShunts.getContextValue(sessionParams)?.length) {
                setLoading(true);
                setCurrentSession(SessionParamsEnum.SessionID.getContextValue(sessionParams));

                const shuntRef = ref(database, SESSION_PATH + SessionParamsEnum.SessionID.getContextValue(sessionParams) + '/SessionShunts');
                const response = get(shuntRef);

                const data = await response;
                const keys = [];

                const childIDs = []
                data.forEach((child) => {
                    childIDs.push(child.key);
                })

                for (let index = 0; index < childIDs.length; index++) {
                    const key = childIDs[index];
                    const res = await getShuntDesignation(database, key);
                    let disableChoice = false;
                    if (res.isCustom === true) {
                        disableChoice = true;
                    }

                    if (key === current) {
                        keys.push({ id: key, desig: ShuntParamsEnum.ShuntDesignation.getContextValue(shuntParams), pos: ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) || res.position, posNr: Number(ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams)) || Number(res.position), disabled: disableChoice  })
                    }
                    else {
                        if (res != null) {
                            keys.push({ id: key, desig: res.designation, pos: res.position, posNr: Number(res.position) || -1, disabled: disableChoice })
                        }
                        else {
                            keys.push({ id: key, desig: "-", pos: "-", posNr: -1, disabled: disableChoice  })
                        }
                    }

                }

                setShunts(keys);
                setLoading(false);
            }
        }

        getInfo();

    }, [SessionParamsEnum.SessionShunts.getContextValue(sessionParams), SessionParamsEnum.UpdateValues.getContextValue(sessionParams)]);
    */

    useEffect(() => {
        const id = SessionParamsEnum.CurrentShunt.getContextValue(sessionParams);
        const obj = shuntList.find((shunt) => shunt.id === id);
        //console.log("Start: ", obj)
        if (obj != null) {
            if (ShuntParamsEnum.ShuntDesignation.getContextValue(shuntParams) != null && ShuntParamsEnum.ShuntDesignation.getContextValue(shuntParams) !== "") {
                obj.desig = ShuntParamsEnum.ShuntDesignation.getContextValue(shuntParams);
            }

            if (ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) != null && ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) !== "") {
                obj.pos = ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams);
                obj.posNr = Number(ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams));
            }

            if (ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) != null && ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) !== -1 && ShuntParamsEnum.ShuntDesignation.getContextValue(shuntParams) != null) {
                obj.fullName = "(Pos." + ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) + ") - " + ShuntParamsEnum.ShuntDesignation.getContextValue(shuntParams);
            }
            else if (ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) != null && ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) !== -1) {
                obj.fullName = "(Pos." + ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) + ") - ";
            }
            else {
                obj.fullName = "Ny Shuntgrupp";
            }

            setUpdate(!update);
            //console.log("Find: ", obj)
        }

    }, [ShuntParamsEnum.ShuntDesignation.getContextValue(shuntParams), ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams), shuntList])

    useEffect(() => {
        if (SessionParamsEnum.CurrentShunt.getContextValue(sessionParams) != null) {
            setCurrent(SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
        }
    }, [SessionParamsEnum.CurrentShunt.getContextValue(sessionParams)])

    const onSelectedShunt = async (value) => {
        //setLoadShunt(true)
        setCurrent(value);
        setOpen(true);
        /*
        errorlist.clearAllErrors(errorProvider);
        noticelist.clearAllNotices(noticeProvider);
        sessionParams.dispatch({ type: 'clearStepData' })
        const step = await loadShuntFromFirebase(value, database, sessionParams, driftParams, shuntParams, calcParams, adjustParams, pumpParams, productLineParams);
        SessionParamsEnum.CurrentStep.setContextValue(sessionParams, step);

        setLoadShunt(false);
        navigate("/stepper");*/
    }

    return (
        <>
            {loading === true ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
            ) : (
                <Stack sx={{ marginTop: '3px' }} direction={'row'} alignItems={'center'} spacing={1}>
                    <FormControl sx={{ marginTop: 0.5, minWidth: 190 }}>
                        <Select
                            labelId="shunt-selector"
                            id="shunt-selector"
                            value={current}
                            onChange={(event) => { onSelectedShunt(event.target.value) }}
                            autoWidth
                            disabled={loading || disabled}
                        >

                            {shuntList.sort((a, b) => a.pos - b.pos).map((shuntID, index) => {
                                if (shuntID?.isAddon === true) {
                                    return (
                                        <MenuItem key={index} value={shuntID.id} style={{ minWidth: 190 }}><i>(Pos {shuntID.pos}) - {ADDON_LABELS[shuntID?.addonType]}</i></MenuItem>
                                    )
                                }
                                else {
                                    return (
                                        <MenuItem key={index} value={shuntID.id} style={{ minWidth: 190 }}>{shuntID.fullName} {shuntID.isCustom ? ("(Man. " + shuntID.plModel + ")") : ("")}</MenuItem>
                                    )
                                }

                            })}

                        </Select>
                    </FormControl>

                    {open ? (
                        <LoadNewShuntComponent shuntID={current} setOpen={setOpen} />
                    ) : ("")}

                </Stack>
            )}
        </>

    );
};