import { Stack, Typography } from "@mui/material"

export const QuickInfoRow = ({ label, value }) => {

    return (
        <Stack direction={'row'} spacing={1}>
            <Typography fontSize={'.95rem'} fontWeight={700}>
                {label}:
            </Typography>
            <Typography fontSize={'.95rem'}>
                {value != null ? (
                    <>
                        {value}
                    </>
                ) : (
                    <i></i>
                )}
            </Typography>
        </Stack>
    )
}