import { useState } from "react";
import { MenuItem, Select, Stack } from "@mui/material"
import { useDatabase } from "reactfire";
import { useAdjustParams, useSessionParams, useShuntParams } from "../../../../contexts";
import { AdjustmentsEnum, SessionParamsEnum, ShuntParamsEnum } from "../../../../enums";
import { resetMaxStepFunc } from "../../../../functions/HelperFunctions/resetMaxStep";
import { MIXINGVESSELS_KEY } from "../../../../constants/keys/adjustParamsKeys";
import { NO_MIXINGVESSEL } from "../../../../constants/adjustmentsOptions";


export const AdjustSelectfield = ({ id = "", labelText, options, isDisabled, className = "" }) => {
    const database = useDatabase();
    const adjustParams = useAdjustParams();
    const shuntParams = useShuntParams();
    const sessionParams = useSessionParams();

    const getAdjustParams = () => {
        switch (id) {
            case 'mixingVessels':
                if (adjustParams.state.val.mixingVessels != null) {
                    return adjustParams.state.val.mixingVessels;
                }
                else {
                    return '';
                }


            default:
                return '';
        }
    }
    const [mixingVesselsValue, setMixingVesselsValue] = useState(getAdjustParams());

    const setAdjustParams = (value) => {
        switch (id) {
            case MIXINGVESSELS_KEY:
                /*
                if (SessionParamsEnum.MaxStep.getContextValue(sessionParams) !== SessionParamsEnum.CurrentStep.getContextValue(sessionParams) && value !== AdjustmentsEnum.MixingVessels.getContextValue(adjustParams)) {
                    SessionParamsEnum.MaxStep.setContextValue(sessionParams, SessionParamsEnum.CurrentStep.getContextValue(sessionParams));
                }*/
                resetMaxStepFunc(sessionParams, database);
                AdjustmentsEnum.MixingVessels.setContextValue(adjustParams, value);

                if (value !== NO_MIXINGVESSEL) {
                    if (ShuntParamsEnum.Addons.getContextValue(shuntParams) != null && ShuntParamsEnum.Addons.getContextValue(shuntParams)['mixingVessel'] != null) {
                        const dataVal = {
                            size: value,
                        }
                        ShuntParamsEnum.updateAddonValues(shuntParams, database, SessionParamsEnum.SessionID.getContextValue(sessionParams), ShuntParamsEnum.Addons.getContextValue(shuntParams)['mixingVessel'], dataVal)
                    }
                }

                setMixingVesselsValue(value)
                break;
            default:
                break;
        }
    }

    return (
        <>
            {className === "" ? (
                <Stack direction={"row"}>
                    <div style={{ marginTop: "24px" }}>
                        {labelText}:
                    </div>

                    <Select
                        style={{ marginLeft: "auto", width: "50%", marginTop: "15px" }}
                        size={"small"}
                        value={mixingVesselsValue || 'Inget blandningkärl'}
                        margin="dense"
                        onChange={(event) => { setAdjustParams(event.target.value) }}
                        disabled={isDisabled}
                    >
                        {options.map((option, index) => {
                            return (<MenuItem key={index} value={option}>{option}</MenuItem>)
                        })}
                    </Select>
                </Stack>
            ) : (
                <Stack direction={"column"}>
                    <div style={{ marginTop: "15px" }}>
                        {labelText}:
                    </div>

                    <Select
                        style={{ marginLeft: "0px", width: "30%", marginTop: "5px" }}
                        size={"small"}
                        value={mixingVesselsValue || 'Inget blandningkärl'}
                        margin="dense"
                        onChange={(event) => { setAdjustParams(event.target.value) }}
                        disabled={isDisabled}
                    >
                        {options.map((option, index) => {
                            return (<MenuItem key={index} value={option}>{option}</MenuItem>)
                        })}
                    </Select>
                </Stack>
            )}
        </>
    );
}