import { DialogContent, Typography, TextField, Stack, DialogActions, Button, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { useFunctions } from "reactfire";
import { useSessionParams, useDevbarParams } from "../../../contexts";
import { SessionParamsEnum } from "../../../enums";
import { sendRequestOfferMail } from "../../../firebase/functions/cloudFunctions/sendReqOffer";
import { BootstrapDialog, BootstrapDialogTitle } from "../../utils/bootstrapDialog";
import { useContextAuth } from "../../../authContext/AuthHook";



export const RequestOffer = ({ handleOnDecline, open }) => {
    const { user } = useContextAuth();
    const sessionParams = useSessionParams();
    const devParams = useDevbarParams();
    const functions = useFunctions();

    const [loading, setLoading] = useState(false);
    const [sent, setIsSent] = useState(false)

    const [senderMail, setSenderMail] = useState('');
    const [senderPhone, setSenderPhone] = useState('');
    const [senderComp, setSenderComp] = useState('');
    const [senderRef, setSenderRef] = useState('');
    const [senderOrgNr, setSenderOrgNr] = useState('');
    const [senderAdress, setSenderAdress] = useState('');
    const [senderPostalNr, setSenderPostalNr] = useState('');
    const [senderPostalCity, setSenderPostalCity] = useState('');

    const [debugEmail, setDebugEmail] = useState('');

    const [message, setMessage] = useState('');

    const [isEmailInvalid, setEmailInvalid] = useState(false);
    const [isPhoneInvalid, setPhoneInvalid] = useState(false);
    const [isCompanyInvalid, setCompanyInvalid] = useState(false);

    useEffect(() => {
        if (user != null && user?.isAnonymous === false && user?.isInternal !== true){
            if (user.email != null && (senderMail == null || senderMail === "")){
                setSenderMail(user.email);
            }

            if (user.company != null && (senderComp == null || senderComp === "")){
                setSenderComp(user.company);
            }

            if (user.name != null && (senderRef == null || senderRef === "")){
                setSenderRef(user.name);
            }
        }
    }, [user]);

    function validateOfferForm() {
        validateEmailSubmit();
        validatePhoneSubmit();
        validateCompanySubmit();

    }

    const validateEmail = senderMail => {
        if (senderMail !== "") {
            setEmailInvalid(false);
        }
        else {
            setEmailInvalid(true);
        }
    }

    const validateEmailSubmit = () => {
        if (senderMail !== "") {
            setEmailInvalid(false);
        }
        else {
            setEmailInvalid(true);
        }
    }


    const validatePhone = senderPhone => {
        if (senderPhone !== "") {
            setPhoneInvalid(false);
        }
        else {
            setPhoneInvalid(true);
        }
    }

    const validatePhoneSubmit = () => {
        if (senderPhone !== "") {
            setPhoneInvalid(false);
        }
        else {
            setPhoneInvalid(true);
        }
    }


    const validateCompany = senderComp => {
        if (senderComp !== "") {
            setCompanyInvalid(false);
        }
        else {
            setCompanyInvalid(true);
        }
    }

    const validateCompanySubmit = () => {
        if (senderComp !== "") {
            setCompanyInvalid(false);
        }
        else {
            setCompanyInvalid(true);
        }
    }

    useEffect(() => {
        if (open === true) {
            setIsSent(false);
        }
    }, [open])

    const closeDialog = () => {
        setMessage('');
        handleOnDecline();
    }


    const sendOffer = async () => {
        const baseUrl = window.location.origin;

        let projNr = SessionParamsEnum.ProjNr.getContextValue(sessionParams);
        let facility = SessionParamsEnum.Facility.getContextValue(sessionParams);

        setLoading(true);
        if (devParams.state.val.devMode === true && debugEmail != null && debugEmail !== "") {
            await sendRequestOfferMail(functions, SessionParamsEnum.SessionID.getContextValue(sessionParams), message, baseUrl, senderMail, senderPhone, senderComp, senderRef, senderOrgNr, senderAdress, senderPostalNr, senderPostalCity, facility, projNr, debugEmail).then(() => {
                //console.log("Sent!");
                setIsSent(true);
            }).finally(() => {
                setLoading(false);
            });
        }
        else {
            await sendRequestOfferMail(functions, SessionParamsEnum.SessionID.getContextValue(sessionParams), message, baseUrl, senderMail, senderPhone, senderComp, senderRef, senderOrgNr, senderAdress, senderPostalNr, senderPostalCity, facility, projNr).then(() => {
                //console.log("Sent!");
                setIsSent(true);
            }).finally(() => {
                setLoading(false);
            });
        }

    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if ((isEmailInvalid === false) && (isPhoneInvalid === false) && (isCompanyInvalid === false)) {
            sendOffer();
        }
    }


    return (
        <BootstrapDialog
            style={{
                minWidth: 640,
                minHeight: 540,
            }}
            onClose={closeDialog}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            {loading === false ? (
                <>
                    {sent === false ? (
                        <>
                            <form onSubmit={handleSubmit}>
                                <BootstrapDialogTitle component="h3" sx={{ paddingLeft: '20px', paddingTop: '0px', paddingBottom: '0px' }} onClose={closeDialog}>
                                    <h3 className="header-title">Begär offert?</h3>
                                </BootstrapDialogTitle>
                                <DialogContent>
                                    {devParams.state.val.devMode === true &&
                                        <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center', paddingBottom: '5px', width: 500 }}>
                                            <div style={{ paddingTop: "4px" }}>
                                                (Dev-Läge) Mottagare:
                                            </div>

                                            <TextField
                                                style={{ display: 'flex', justifyContent: 'center', margin: 'auto', width: '65%' }}
                                                value={debugEmail}
                                                type={'email'}
                                                label="MottagarEmail"
                                                onChange={(event) => { setDebugEmail(event.target.value) }}
                                            />
                                        </Typography>
                                    }
                                    <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center', paddingBottom: '1px', width: 500 }}>
                                        Vill du skicka specifikationen från den aktuella sessionen till PREMA för offerering?
                                    </Typography>
                                    <div style={{ padding: 0, marginTop: 20, paddingBottom: "5px" }}>
                                        Kontaktuppgifter:
                                    </div>
                                    <Stack direction={'row'} spacing={1}>
                                        <TextField
                                            style={{ display: 'flex', justifyContent: 'center', width: '49%' }}
                                            value={senderMail}
                                            type={'email'}
                                            label="E-post"
                                            onChange={(event) => { setSenderMail(event.target.value) }}
                                            onInput={validateEmail}
                                            error={isEmailInvalid}

                                            required
                                        />
                                        <TextField
                                            style={{ display: 'flex', justifyContent: 'center', width: '49%' }}
                                            value={senderPhone}
                                            type={'text'}
                                            label="Telefon"
                                            onChange={(event) => { setSenderPhone(event.target.value) }}
                                            onInput={validatePhone}
                                            error={isPhoneInvalid}
                                            required

                                        />
                                    </Stack>
                                    <Stack spacing={1}>
                                        <div style={{ padding: 0, marginTop: 20 }}>
                                            Offertmottagare:
                                        </div>
                                        <Stack direction={'row'} spacing={1}>
                                            <TextField
                                                style={{ display: 'flex', justifyContent: 'center', width: '49%' }}
                                                value={senderComp}
                                                type={'text'}
                                                label="Företag"
                                                onChange={(event) => { setSenderComp(event.target.value) }}
                                                onInput={validateCompany}
                                                error={isCompanyInvalid}
                                                required

                                            />
                                            <TextField
                                                style={{ display: 'flex', justifyContent: 'center', width: '49%' }}
                                                value={senderRef}
                                                type={'text'}
                                                label="Referens"
                                                onChange={(event) => { setSenderRef(event.target.value) }}

                                            />
                                        </Stack>
                                        <Stack direction={'row'} spacing={1}>
                                            <TextField
                                                style={{ display: 'flex', justifyContent: 'center', width: '49%' }}
                                                value={senderOrgNr}
                                                type={'text'}
                                                label="Org. Nr"
                                                onChange={(event) => { setSenderOrgNr(event.target.value) }}

                                            />
                                        </Stack>
                                        <Stack direction={'row'} spacing={1}>
                                            <TextField
                                                style={{ display: 'flex', justifyContent: 'center', width: '49%' }}
                                                value={senderAdress}
                                                type={'text'}
                                                label="Adress"
                                                onChange={(event) => { setSenderAdress(event.target.value) }}

                                            />
                                            <TextField
                                                style={{ display: 'flex', justifyContent: 'center', width: '15%' }}
                                                value={senderPostalNr}
                                                type={'text'}
                                                label="Post Nr"
                                                onChange={(event) => { setSenderPostalNr(event.target.value) }}

                                            />
                                            <TextField
                                                style={{ display: 'flex', justifyContent: 'center', width: '32%' }}
                                                value={senderPostalCity}
                                                type={'text'}
                                                label="Stad"
                                                onChange={(event) => { setSenderPostalCity(event.target.value) }}

                                            />
                                        </Stack>

                                        <TextField
                                            style={{ display: 'flex', justifyContent: 'center', margin: 'auto', marginTop: '30px', width: '85%' }}
                                            id="comment-input"
                                            value={message}
                                            type={'text'}
                                            label="Meddelande (valfritt)"
                                            multiline
                                            rows={4}
                                            onChange={(event) => { setMessage(event.target.value) }}
                                        />
                                    </Stack>

                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={validateOfferForm} type="submit">
                                        Skicka förfrågan
                                    </Button>
                                </DialogActions>
                            </form>
                        </>
                    ) : (
                        <>
                            <BootstrapDialogTitle onClose={closeDialog}>
                                Begär offert?
                            </BootstrapDialogTitle>
                            <DialogContent dividers>
                                <Typography component={'h2'} gutterBottom style={{ display: 'flex', justifyContent: 'center', width: 480, minHeight: 200, }}>
                                    Offertförfrågan skickad!
                                </Typography>

                            </DialogContent>
                        </>
                    )}</>
            ) : (
                <>
                    <BootstrapDialogTitle onClose={closeDialog}>
                        Begär offert?
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Typography component={'h2'} gutterBottom style={{ display: 'flex', justifyContent: 'center', width: 480, minHeight: 200, }}>
                            <Stack>
                                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress size={25} /></div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>Skickar...</div>
                            </Stack>
                        </Typography>

                    </DialogContent>
                </>
            )
            }
        </BootstrapDialog >
    )
};