import { Button, CircularProgress, DialogActions, DialogContent, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDatabase } from "reactfire";
import { useSessionParams, useShuntParams, useDriftParams } from "../../../contexts/";
import { SessionParamsEnum } from "../../../enums/sessionParamsEnum";
import { BootstrapDialog, BootstrapDialogTitle } from "../../utils/bootstrapDialog";
import { ref, set } from "firebase/database";
import { SESSION_FACILITY_PATH, SESSION_PATH, SESSION_PROJ_NR_PATH } from "../../../constants/firebasePaths";
import { ShuntParamsEnum } from "../../../enums/shuntParamsEnum";
import { DriftParamEnum } from "../../../enums/driftParamsEnum";

export const EditShuntValue = ({ valueType, type, handleOnDecline, open, defaultValue, shuntID = null }) => {
    const database = useDatabase();
    const sessionParams = useSessionParams();
    const shuntParams = useShuntParams();
    const driftParams = useDriftParams();

    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue]);

    const closeDialog = () => {
        setValue('');
        handleOnDecline();
    }

    // eslint-disable-next-line no-unused-vars
    const setToFirebase = (type) => {
        if (type === "Anläggning") {
            const dbRef = ref(database, SESSION_PATH + SessionParamsEnum.SessionID.getContextValue(sessionParams) + SESSION_FACILITY_PATH);
            return set(dbRef, value);
        }
        else if (type === "Projektnummer") {
            const dbRef = ref(database, SESSION_PATH + SessionParamsEnum.SessionID.getContextValue(sessionParams) + SESSION_PROJ_NR_PATH);
            return set(dbRef, value);
        }

    }

    const saveValue = () => {
        //console.log("ShuntID:", shuntID)
        switch (valueType) {
            case "Anläggning":
                SessionParamsEnum.Facility.setContextValue(sessionParams, value, database);
                break;
            case "Projektnummer":
                SessionParamsEnum.ProjNr.setContextValue(sessionParams, value, database);
                break;
            case "Beteckning":
                if (shuntID != null && shuntID !== '') {
                    if (shuntID === SessionParamsEnum.CurrentShunt.getContextValue(sessionParams)) {
                        ShuntParamsEnum.ShuntDesignation.setContextValue(shuntParams, value);
                    }

                    ShuntParamsEnum.ShuntDesignation.setToFirebase(database, shuntID, value);
                }
                break;
            case "Drivtryck":
                if (shuntID != null && shuntID !== '') {
                    if (shuntID === SessionParamsEnum.CurrentShunt.getContextValue(sessionParams)) {
                        DriftParamEnum.PrimDrivingPressure.setContextValue(driftParams, value);
                    }

                    DriftParamEnum.PrimDrivingPressure.setToFirebase(database, shuntID, value);
                }
                break;
            case "Prim Temp In":
                if (shuntID != null && shuntID !== '') {
                    if (shuntID === SessionParamsEnum.CurrentShunt.getContextValue(sessionParams)) {
                        DriftParamEnum.PrimTempIn.setContextValue(driftParams, value);
                    }
                    DriftParamEnum.PrimTempIn.setToFirebase(database, shuntID, value);
                }
                break;
            default:
                break;
        }

        handleOnDecline();
    }


    return (
        <BootstrapDialog
            style={{
                minWidth: 640,
                minHeight: 440,
            }}
            onClose={closeDialog}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            {loading === false ? (
                <>
                    <>
                        <BootstrapDialogTitle onClose={closeDialog}>
                            <Typography gutterBottom style={{ display: 'flex', justifyContent: 'left', paddingBottom: '5px', width: 220, fontSize: '14px', marginBottom: '5px' }}>
                                Redigera {valueType}?
                            </Typography>
                        </BootstrapDialogTitle>
                        <DialogContent>
                            <TextField
                                style={{ display: 'flex', margin: 'auto', width: '87%', marginTop: '6px' }}
                                value={value}
                                type={type}
                                label={valueType}
                                onChange={(event) => { setValue(event.target.value) }}
                            />
                        </DialogContent>


                        <DialogActions>
                            <Button onClick={saveValue}>
                                Spara
                            </Button>
                        </DialogActions>
                    </>
                </>
            ) : (
                <>
                    <BootstrapDialogTitle onClose={closeDialog}>
                        Redigera {valueType}
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Typography component={'h2'} gutterBottom style={{ display: 'flex', justifyContent: 'center', width: 480, minHeight: 200, }}>
                            <Stack>
                                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress size={25} /></div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>Sparar...</div>
                            </Stack>
                        </Typography>

                    </DialogContent>
                </>
            )}

        </BootstrapDialog>
    )
};