import { Button, DialogActions, DialogContent, Typography } from "@mui/material"
import { BootstrapDialog, BootstrapDialogTitle } from "../../../utils/bootstrapDialog"

export const IncompleteShuntSpecsModal = ({ open, handleClose, handleApprove, hasErrors }) => {


    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle onClose={handleClose}>
                    {hasErrors === true ? (
                        "Sessionen innehåller positioner med dimensioneringsvarningar"
                    ) : (
                        "Sessionen har ofärdiga shuntgrupper"
                    )}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center' }}>
                        {hasErrors === true ? (
                            "Sessionen innehåller positioner med dimensioneringsvarningar och utskrift av specifikation rekommenderas ej"
                        ) : (
                            "Det finns ofärdiga shuntgrupper i denna sessionen"
                        )}

                    </Typography>
                    <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center', paddingTop: "10px" }}>
                        <b>Vad vill du göra?</b>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={handleClose}>
                        Återgå till översikt
                    </Button>
                    <Button variant='outlined' onClick={handleApprove}>
                        {hasErrors === true ? (
                            "Visa specifikation ändå"
                        ) : (
                            "Visa specifikation för färdiga shuntgrupper"
                        )}
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    )
}