import { useEffect, useState } from "react";


export const useInitMagicloud = () => {
    // @ts-ignore
    const MagiCloud = window.MagiCloud;
    const [loading, setLoading] = useState(false);
    const [magiCadUser, setMagiCadUser] = useState(null);
    const [isInit, setIsInit] = useState(false);

    useEffect(() => {
        if (isInit === false) {
            try {
                MagiCloud.init({
                    client_id: 'prema',
                    popup_redirect_uri: 'https://prema-shuntlogik.web.app/magicloud-callback.html',

                    // // Authenticated user status events
                    onLoadUser: function (user) {
                        // Event handler to get signed in user if any exists
                        // e.g. case when page refreshed
                        if (user) {
                            setMagiCadUser(user);
                        } else {
                            //console.log("Not logged in!")
                        }
                        setIsInit(true);
                    },
                    onSignin: function (user) {
                        // Event handler to perform some action on user signin
                        setMagiCadUser(user);
                    },
                    onSignout: function () {
                        console.log('onSignout');
                        setMagiCadUser(null);
                        // Event handler to perform some action on user signout
                    },
                    // Product data load events
                    // @ts-ignore
                    onLoad: function (data) {
                        // Event handler to perform some action on product data load
                    },
                    onLoadError: function (err) {
                        console.log('onLoadError', err);
                        // Event handler to perform some action on error when product data loaded
                    },

                    // X3D rendering
                    onRenderX3D: function () {
                        // Event handler to perform some action after succesful x3d rendering
                    }
                });
            } catch (error) {
                console.error("Error Init Magicloud: ", error)
            }
        }
    }, [isInit]);

    /**
     * 
     * @param {string} productId 
     * @returns 
     */
    const loadModel = async (productId) => {
        let modelExists = false;
        let dxfLink = null;
        let rfaLinks = null;
        let dimImage = null;
        let x3dOptions = null;
        let permaLink = null;

        //console.log("ProdID:", productId);
        try {
            setLoading(true);
            const data = await MagiCloud.load({
                productId: productId
            });

            //console.log("Data: ", data)
            if (data != null) {
                // @ts-ignore
                let productCode = data.ProductCode;
                let dxfDownloadUrl = data.DxfUrl;
                let attachments = data.Attachments; // contains e.g. RFA files
                // @ts-ignore
                let image2D = data.ImageUrl;
                let dimensionImage = data.DimensionsUrl;
                permaLink = data.Permalink;

                dxfLink = dxfDownloadUrl;
                rfaLinks = attachments;

                try {
                    fetch(data.VariantDetails.VariantDetailsUrl).then(function (response) {

                        sessionStorage.response = JSON.stringify(response);

                        if (sessionStorage.getItem("response") !== null) {

                            let responseArray = JSON.parse(sessionStorage.response);

                            for (let i in responseArray) {

                                // @ts-ignore
                                let MagiCloudCode = responseArray[i]["MagiCloudCode"];
                                // @ts-ignore
                                let Id = responseArray[i]["Id"];

                                //console.log("Response: ", MagiCloudCode, Id)
                                /*
                                $("<li />", {}).appendTo("ul.variations-list");
                                $("<a />", {
                                    href: window.location.pathname + "?product_id=" + Id,
                                    html: MagiCloudCode
                                }).appendTo("ul.variations-list li:last-child");*/

                            }

                        }

                    });
                } catch (err) {
                    if (sessionStorage.getItem("response") !== null) {

                        let responseArray = JSON.parse(sessionStorage.response);

                        for (let i in responseArray) {

                            // @ts-ignore
                            let MagiCloudCode = responseArray[i]["MagiCloudCode"];
                            // @ts-ignore
                            let Id = responseArray[i]["Id"];

                            //console.log("Response (catch): ", MagiCloudCode, Id)
                        }

                    }
                }

                dimImage = dimensionImage;

                x3dOptions = {
                    containerId: "x3dContainer",
                    x3dUri: data.X3DUrl,
                    imageUri: data.ImageUrl,
                };

                modelExists = true;
            }
        } catch (error) {
            console.error("Error Load Magicloud:", error)
        } finally {
            setLoading(false);
        }

        return {
            modelExists,
            dxfLink,
            rfaLinks,
            dimImage,
            x3dOptions,
            permaLink,
        }
    }

    const signInToMagicad = () => {
        MagiCloud.signin().then((res) => {
            console.log("Result: ", res)
        }).catch((error) => {
            console.log("Error signing in: ", error)
        })
    }

    const signOutFromMagicad = () => {
        MagiCloud.signout().then((res) => {
            console.log("Result: ", res)
            setMagiCadUser(null);
        }).catch((error) => {
            console.log("Error signing out: ", error)
        })
    }

    return { magiCadUser, loadModel, signInToMagicad, signOutFromMagicad }
}