import { useState, useEffect } from 'react';

import { ref } from 'firebase/database';
import { useDatabase, useDatabaseObjectData } from 'reactfire';
import { Box, Button, Paper, Stack } from '@mui/material';
import { useSessionParams } from '../../../contexts';
import { useShuntParams } from '../../../contexts';
import { SessionParamsEnum, ShuntParamsEnum } from '../../../enums';
import { SESSION_PATH, SESSION_SHUNT_PATH } from '../../../constants/firebasePaths';
import { getShuntDesignation } from '../../../firebase/functions/getShuntDesignation';
import { getShuntProdType } from '../../../firebase/functions/getShuntProdType';
import { PRODUCTLINE_GREEN, PRODUCTLINE_STANDARD } from '../../../constants/productlineConstants';
import { AddShuntModule } from '../addShunt/addShuntModule';
import { useGetShuntListName } from '../../../firebase/functions/getShuntList';
import { ADDON_LABELS } from '../../../constants/addonLabels';
import { updatePositionList } from '../../../firebase/functions/updatePositionLIst';

export const InspectSidePanel = () => {
    const database = useDatabase();
    const sessionParams = useSessionParams();
    const shuntParams = useShuntParams();

    const { shuntList, loading } = useGetShuntListName();

    const [shunts, setShunts] = useState([]);
    const shuntRef = ref(database, SESSION_PATH + SessionParamsEnum.SessionID.getContextValue(sessionParams) + SESSION_SHUNT_PATH);

    const response = useDatabaseObjectData(shuntRef);
    // eslint-disable-next-line no-unused-vars
    const { status, data } = response;

    const handleTest = () => {
        updatePositionList(database, SessionParamsEnum.SessionID.getContextValue(sessionParams), 2, shuntList)
    }

    const handleOpen = () => {
        SessionParamsEnum.OpenOverview.setContextValue(sessionParams, true);
    }

    useEffect(() => {
        if (data != null) {
            const getInfo = async () => {
                const keys = [];
                for (let index = 0; index < Object.keys(data).length; index++) {
                    const key = Object.keys(data)[index];
                    if (key !== "NO_ID_FIELD") {
                        const res = await getShuntDesignation(database, key);
                        const prodLine = await getShuntProdType(database, key);
                        //console.log("Green:", prodLine);
                        let prodString = "";

                        if (prodLine != null && prodLine.model != null) {
                            prodString = prodLine.model;

                            if (prodLine.prodLine != null && prodLine.prodLine !== PRODUCTLINE_STANDARD) {
                                prodString += " " + prodLine.prodLine;

                                if (prodLine.prodLine === PRODUCTLINE_GREEN && prodLine.greenLevel != null) {
                                    if (prodLine.greenLevel === 1) {
                                        prodString += " I"
                                    }
                                    else if (prodLine.greenLevel === 2) {
                                        prodString += " II"
                                    }
                                }
                            }
                        }

                        if (key === SessionParamsEnum.CurrentShunt.getContextValue(sessionParams)) {
                            keys.push({ id: key, desig: ShuntParamsEnum.ShuntDesignation.getContextValue(shuntParams) || "-", pos: ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams) || res.position, posNr: Number(ShuntParamsEnum.ShuntPosition.getContextValue(shuntParams)) || Number(res.position), prodString: prodString })
                        }
                        else {
                            if (res != null) {
                                keys.push({ id: key, desig: res.designation || "-", pos: res.position || "-", posNr: Number(res.position) || -1, prodString: prodString })
                            }
                            else {
                                keys.push({ id: key, desig: "-", pos: "-", posNr: -1, prodString: prodString })
                            }
                        }
                    }
                }

                setShunts(keys);
            }

            getInfo();
            //setFieldValue(data);
        }
    }, [data]);

    return (
        <>
            <Paper elevation={0} style={{ padding: "14px", paddingBottom: "20px", display: 'flex', flexDirection: 'column', height: "auto", borderRadius: 0, boxShadow: "2px 2px 10px #0055a030", }}>
                <Button variant="contained" size="small" onClick={handleOpen} fullWidth>
                    Översikt
                </Button>

                <div style={{ width: "200px", marginTop: '15px' }}>
                    <span style={{ fontWeight: '400', color: '#555555' }}>Gå till Översikt när du har skapat alla dina shuntgrupper, eller för att se en sammanställning över de du skapat så här långt. Via översikten kan du bland annat:</span>
                    <ul style={{ fontWeight: '400', color: '#555555', paddingLeft: '20px' }}>

                        <li style={{ paddingBottom: '8px' }}>
                            Granska, spara, eller skriva ut Specifikationen
                        </li>
                        <li style={{ paddingBottom: '8px' }}>
                            Redigera eller ta bort shuntgrupper
                        </li>
                        <li style={{ paddingBottom: '8px' }}>
                            Länka parametrar mellan shuntgrupper
                        </li>
                        <li style={{ paddingBottom: '8px' }}>
                            Dela den aktuella sessionen
                        </li>
                        <li>
                            Begära offert
                        </li>

                    </ul>
                </div>

                <Paper style={{ width: "200px", marginTop: '15px', }}>
                    <Stack>
                        {shuntList.sort((a, b) => a.pos - b.pos).map((shunt) => {
                            //console.log("Shunt: ", shunt)
                            if (shunt.id === SessionParamsEnum.CurrentShunt.getContextValue(sessionParams)) {
                                if (shunt.isAddon === true) {
                                    return (
                                        <Box key={shunt.id} sx={{ padding: '10px 15px', backgroundColor: '#eff5f6' }}>
                                            <strong>Pos {shunt?.pos}, {ADDON_LABELS[shunt?.addonType]}</strong>
                                        </Box>
                                    )
                                }
                                else {
                                    return (
                                        <Box key={shunt.id} sx={{ padding: '10px 15px', backgroundColor: '#eff5f6' }}>
                                            <strong>Pos {shunt?.pos}, {shunt?.desig} ({shunt?.fullPlModel != null ? shunt?.fullPlModel : shunt?.plModel})</strong>
                                        </Box>
                                    )

                                }

                            }
                            else {
                                if (shunt.isAddon === true) {
                                    return (
                                        <Box key={shunt.id} sx={{ padding: '10px 15px', color: 'gray' }}>
                                            <i>Pos {shunt?.pos}, {ADDON_LABELS[shunt?.addonType]}</i>
                                        </Box>
                                    )
                                }
                                else {
                                    return (
                                        <Box key={shunt.id} sx={{ padding: '10px 15px', color: 'gray' }}>
                                            <i>Pos {shunt?.pos}, {shunt?.desig} ({shunt?.fullPlModel != null ? shunt?.fullPlModel : shunt?.plModel})</i>
                                        </Box>
                                    )
                                }
                            }
                        })}
                    </Stack>

                </Paper>

                <AddShuntModule fullWidth style={{ marginTop: '20px', alignSelf: 'flex-end' }} />
            </Paper>
        </>
    );
};