import { httpsCallable } from "firebase/functions"

const addCustomShuntFunc = async function addCustomShunt(functions, sessionID, position, designation, princip, prodLine, plModel, dimVals, shuntType, connDesign, shuntData, pumpOptions, flexDim, exchangeVal = null, cv2Nways = null, adjustmentsOptions = null, isSpecial = null, ivMod = null) {
    functions.region = 'europe-west1';
    const addCustomShunt = httpsCallable(functions, "addCustomShunt");

    return addCustomShunt({
        sessionID: sessionID,
        position: position,
        designation: designation,
        princip: princip,
        prodLine: prodLine,
        plModel: plModel,
        connDesign: connDesign,
        shuntType: shuntType,
        dimValues: dimVals,
        exchangeValue: exchangeVal,
        cv2NWays: cv2Nways,
        shuntData: shuntData,
        pumpOptions: pumpOptions,
        adjustOptions: adjustmentsOptions,
        isSpecial: isSpecial,
        flexDim: flexDim,
        ivMod: ivMod
    });
}

export { addCustomShuntFunc }