import { useState, useEffect } from "react";
import { Paper, Stack, Typography, Button } from "@mui/material";
import { collection } from "firebase/firestore";
import { useDatabase, useFirestore, useFirestoreCollectionData, useFirestoreCollection } from "reactfire";
import { useSessionParams, useDriftParams, useShuntParams, useCalcParams, useErrorContext, useNoticeContext, useDevbarParams, useProductLineParams } from "../../../contexts";
import { useFSData } from "../../../contexts/fsDataContext";
import { ErrorEnum, NoticeEnum, ProductLineEnum, FSDataEnum, DriftParamEnum, ShuntParamsEnum, SessionParamsEnum, CalcParamsEnum } from "../../../enums";
import { saveCalcData, saveDriftParams, saveShuntData } from "../../../firebase/functions";
import { saveNoticesAndErrors } from "../../../firebase/functions/saveNoticesAndErrors";
import { calculateShuntParams } from "../../../functions/calculateShuntParams";
import { ValveSelectField, FlexSelectField, SmartSelectField, ModelSelectField, AdjustValveGreenSelect, ShuntParamTextfield, ContextTextfield } from "./components";
import { ShuntInfoGreenLevel } from "./components/shuntGreenLevel";
import { useGetSpecialFlexDim } from "../../../firebase/functions/getSpecialFlexDim";
import { useWizardParams } from "../../../contexts/wizardParams";
import { ConnectedEnergyMeasurement } from "./connectedExtras/connectedEnergyMeasurment";
import { useGetEnergyMeasurementList } from "../../../hooks/useGetEnergyMeasurementList";
import { resetMaxStepFunc } from "../../../functions/HelperFunctions/resetMaxStep";
import { useGetSystemFluidOptions } from "../../../hooks/useGetSystemFluidOptions";

import { SHUNT_DIMS_PATH, CONTROL_VALVES_PATH, CONTROL_VALVES_DIMS_FLEX_DEFAULT_PATH, CONTROL_VALVES_DIMS_SMART_DEFAULT_PATH } from "../../../constants/firebasePaths";
import { PRODUCTLINE_GREEN, PRODUCTLINE_FLEX, PRODUCTLINE_SMART, PRODUCTPRINCIP_SR3, PRODUCTPRINCIP_PR3, PRODUCTPRINCIP_VA1, PRODUCTPRINCIP_PR2 } from "../../../constants/productlineConstants";


export const ShuntgroupParam = () => {
    const errorlist = new ErrorEnum();
    const noticelist = new NoticeEnum();

    const database = useDatabase();
    const sessionParams = useSessionParams();
    const driftParams = useDriftParams();
    const shuntParams = useShuntParams();
    const calcParams = useCalcParams();
    const errorProvider = useErrorContext();
    const noticeProvider = useNoticeContext();
    const fsDataParams = useFSData();
    const wizardParams = useWizardParams();
    const devParams = useDevbarParams();

    const productLine = useProductLineParams();
    const firestore = useFirestore();

    const pLine = ProductLineEnum.ProductLine.getContextValue(productLine);
    const pPrincip = ProductLineEnum.ProductPrincip.getContextValue(productLine);
    const greenLevel = ProductLineEnum.ProductGreenLevel.getContextValue(productLine);
    const [hasMainPump, setHasMainPump] = useState(DriftParamEnum.HasMainPump.getContextValue(driftParams));

    const conTypeDoc = collection(firestore, SHUNT_DIMS_PATH);
    const firestoreRes = useFirestoreCollectionData(conTypeDoc);

    const [dimensionData, setDimensionData] = useState([]);
    const [flexDims, setFlexDims] = useState([]);
    const [smartDims, setSmartDims] = useState([]);

    const [addedEventListener, setAddedEventListener] = useState(false);
    const [triggerCalc, setTriggerCalc] = useState(false);

    const specialDim = useGetSpecialFlexDim();
    const systemFluidOptions = useGetSystemFluidOptions();
    const { heatEnergyMeasurementList, coolingEnergyMeasurementList } = useGetEnergyMeasurementList();

    //const [specialDim, setSpecialDim] = useState([]);

    /*
    useEffect(() => {
        getSpecialFlexDim(firestore).then((dimList) => {
            setSpecialDim(dimList);
        })
    }, []);*/

    useEffect(() => {
        if (firestoreRes.data != null) {
            //console.log("Data: ", firestoreRes.data)
            setDimensionData(firestoreRes.data);
            FSDataEnum.Dims.setContextValue(fsDataParams, firestoreRes.data);
        }

    }, [firestoreRes])

    useEffect(() => {
        setHasMainPump(DriftParamEnum.HasMainPump.getContextValue(driftParams));
    }, [DriftParamEnum.HasMainPump.getContextValue(driftParams)])

    const checkCalcMode = () => {
        if (DriftParamEnum.CalcMode.getContextValue(driftParams) === "0" || DriftParamEnum.CalcMode.getContextValue(driftParams) === 0) {
            return false;
        }
        else {
            return true;
        }
    }

    let fsUrl = "";

    if (ShuntParamsEnum.ControlValve.getContextValue(shuntParams) != null && ShuntParamsEnum.ControlValve.getContextValue(shuntParams) !== "" &&
        ShuntParamsEnum.FlexModel.getContextValue(shuntParams) != null && ShuntParamsEnum.FlexModel.getContextValue(shuntParams) !== "" && !checkCalcMode()) {
        fsUrl = CONTROL_VALVES_PATH + ShuntParamsEnum.ControlValve.getContextValue(shuntParams) + '/Models/' + ShuntParamsEnum.FlexModel.getContextValue(shuntParams) + "/Dims";
    }
    else {
        fsUrl = CONTROL_VALVES_DIMS_FLEX_DEFAULT_PATH;
    }

    const dimsDoc = collection(firestore, fsUrl);
    const firestoreDimRes = useFirestoreCollection(dimsDoc);

    useEffect(() => {
        if (firestoreDimRes.data != null) {
            const localModels = [];

            firestoreDimRes.data.forEach(model => {
                localModels.push({ id: model.id, data: model.data() })
            });

            setFlexDims(localModels);
            FSDataEnum.FlexDims.setContextValue(fsDataParams, localModels);
        }

    }, [firestoreDimRes])

    const smartDimUrl = CONTROL_VALVES_DIMS_SMART_DEFAULT_PATH
    const smartDimsDoc = collection(firestore, smartDimUrl);
    const firestoreSmartDimRes = useFirestoreCollection(smartDimsDoc);

    useEffect(() => {
        if (firestoreSmartDimRes.data != null) {
            const localModels = [];

            firestoreSmartDimRes.data.forEach(model => {
                localModels.push({ id: model.id, data: model.data() })
            });

            setSmartDims(localModels);
            FSDataEnum.SmartDims.setContextValue(fsDataParams, localModels);
        }

    }, [firestoreSmartDimRes])

    const isEmpty = (stateObj) => {
        if (stateObj == null || Object.keys(stateObj).length === 0) {
            return true;
        }
        else {
            let isEmpty = true;
            Object.keys(stateObj).forEach((val) => {
                if (stateObj[val] != null && stateObj[val] !== "") {
                    //console.log("This is not null: ", stateObj[val])
                    isEmpty = false;
                }
            });

            return isEmpty;
        }
    }

    const calculateResult = async () => {
        resetMaxStepFunc(sessionParams, database);
        /*
        if (SessionParamsEnum.MaxStep.getContextValue(sessionParams) !== SessionParamsEnum.CurrentStep.getContextValue(sessionParams)) {
            SessionParamsEnum.MaxStep.setContextValue(sessionParams, SessionParamsEnum.CurrentStep.getContextValue(sessionParams), database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
        }*/

        errorlist.clearAllErrors(errorProvider);
        noticelist.clearAllNotices(noticeProvider);
        //console.log("SmartDimss:", smartDims)
        calculateShuntParams(productLine, driftParams, calcParams, shuntParams, errorProvider, noticeProvider, dimensionData, database, sessionParams, wizardParams, systemFluidOptions, flexDims, smartDims, null, specialDim, heatEnergyMeasurementList, coolingEnergyMeasurementList);
    }

    useEffect(() => {
        //console.log("Has Errors: ", errorlist.hasErrors(errorProvider))
        saveNoticesAndErrors(database, sessionParams, noticeProvider, errorProvider);
    }, [errorlist.hasErrors(errorProvider)]);

    useEffect(() => {
        //console.log("Has Notices: ", noticelist.hasNotices(noticeProvider))
        saveNoticesAndErrors(database, sessionParams, noticeProvider, errorProvider);
    }, [noticelist.hasNotices(noticeProvider)]);

    useEffect(() => {
        if (isEmpty(calcParams.state.val) === false) {
            saveCalcData(database, sessionParams, calcParams);
        }
        if (isEmpty(driftParams.state.val) === false) {
            saveDriftParams(database, sessionParams, driftParams);
        }
        if (isEmpty(shuntParams.state.val) === false) {
            saveShuntData(database, sessionParams, shuntParams);
        }


    }, [sessionParams.state.val.update]);

    const isDisabled = () => {
        if (SessionParamsEnum.Editable.getContextValue(sessionParams) === false && devParams.state.val.devMode !== true) {
            return true;
        }
        else {
            return false;
        }
    }

    const displayEfkBox = () => {
        if (checkCalcMode() === false) {
            if (pLine !== PRODUCTLINE_GREEN) {
                if (pLine !== PRODUCTLINE_FLEX && pPrincip !== PRODUCTPRINCIP_SR3 && pPrincip !== PRODUCTPRINCIP_PR3 && pPrincip !== PRODUCTPRINCIP_VA1 && pPrincip !== PRODUCTPRINCIP_PR2) {
                    return (<>
                        <ShuntParamTextfield paramsEnum={DriftParamEnum.PEfk} context={driftParams} labelText={"Efk"} isDisabled={isDisabled()} end={"kPa"} />
                    </>);
                }
                else if (pPrincip === PRODUCTPRINCIP_PR2 && hasMainPump !== '0') {
                    return (<>
                        <ShuntParamTextfield paramsEnum={DriftParamEnum.PEfk} context={driftParams} labelText={"Efk"} isDisabled={isDisabled()} end={"kPa"} />
                    </>);
                }
            }
        }
    }

    useEffect(() => {
        if (addedEventListener === false) {
            setAddedEventListener(true);
            window.addEventListener('keydown', e => {
                if (e.key === "Enter") {
                    setTriggerCalc(true);
                }
            });
        }

    }, [addedEventListener]);

    useEffect(() => {
        if (triggerCalc === true) {
            calculateResult();
            setTriggerCalc(false);
        }
    }, [triggerCalc])

    /*
    useEffect(() => {
        if (driftParams.state.val != undefined && Object.keys(driftParams.state.val).length !== 0){
            saveDriftParams(database, sessionParams, driftParams);
        }
    }, [driftParams.state.val]);

    useEffect(() => {
        if (shuntParams.state.val != undefined && Object.keys(shuntParams.state.val).length !== 0){
            saveShuntData(database, sessionParams, shuntParams);
        }
    }, [shuntParams.state.val]);*/


    /*
    useEffect(() => {
        if (shuntParams.state.val != undefined && Object.keys(shuntParams.state.val).length !== 0){
            saveShuntData(database, sessionParams, shuntParams);
        }
    }, [shuntParams.state.val]);*/

    return (
        <Paper elevation={0} style={{ width: '300px', justifyContent: 'left', padding: '10px 10px 21px 10px', height: 'fit-content', borderRadius: 0, boxShadow: "2px 2px 10px #0055a030", }}>
            <Stack spacing={0}>
                <Typography variant='h3' fontWeight="bold" sx={{ mb: '-6px' }}>
                    Shuntgruppsparametrar
                </Typography>
                <ContextTextfield enumValue={ShuntParamsEnum.ShuntDesignation} context={shuntParams} labelText={"Beteckning"} isDisabled={isDisabled()} />
                <ContextTextfield enumValue={ShuntParamsEnum.ShuntPosition} context={shuntParams} labelText={"Position"} isDisabled={isDisabled()} isNumber={true} />
                {pLine !== PRODUCTLINE_GREEN &&
                    <ValveSelectField labelText={"Injusteringsventil"} isAdjustmentValve={true} isDisabled={isDisabled()} />}

                <ValveSelectField labelText={"Styrventil"} isAdjustmentValve={false} isDisabled={isDisabled()} />

                {pLine === PRODUCTLINE_FLEX ? (
                    <FlexSelectField />
                ) : (
                    <>
                        {pLine === PRODUCTLINE_SMART ? (
                            <SmartSelectField />
                        ) : (
                            <ModelSelectField />
                        )}
                    </>

                )}

                {displayEfkBox()}

                {pLine === PRODUCTLINE_GREEN &&
                    <AdjustValveGreenSelect />}

                <ConnectedEnergyMeasurement heatEnergyMeasurementList={heatEnergyMeasurementList} coolingEnergyMeasurementList={coolingEnergyMeasurementList} />

                <Button size="small" onClick={calculateResult} disabled={isDisabled()}
                    variant="contained" color="primary" sx={{ width: '70px', marginTop: '10px', fontSize: '10px' }}>Beräkna</Button>

                {pLine === PRODUCTLINE_GREEN && greenLevel != null &&
                    <>
                        <ShuntInfoGreenLevel />
                    </>
                }


                {pLine === PRODUCTLINE_FLEX ? (
                    <>
                        <ShuntParamTextfield paramsEnum={CalcParamsEnum.ShuntType} context={calcParams} labelText={"Shunttyp"} isDisabled={true} isNumber={false} />
                        <ShuntParamTextfield paramsEnum={CalcParamsEnum.FlexSVDim} context={calcParams} labelText={"SV dim"} isDisabled={true} isNumber={false} />
                        <ShuntParamTextfield paramsEnum={CalcParamsEnum.FlexSVMax} context={calcParams} labelText={"SV % av max"} isDisabled={true} end={"%"} className="shunt-percent" />
                        <ShuntParamTextfield paramsEnum={CalcParamsEnum.SVPressureLoss} context={calcParams} labelText={"Tryckfall SV"} isDisabled={true} end={"kPa"} />
                        <ShuntParamTextfield paramsEnum={CalcParamsEnum.FlexSVSetting} context={calcParams} labelText={"SV inställning"} isDisabled={true} className="shunt-percent" />
                        <ShuntParamTextfield paramsEnum={CalcParamsEnum.PrimInternalPressureDrop} context={calcParams} labelText={"Prim"} isDisabled={true} end={"kPa"} />
                        <ShuntParamTextfield paramsEnum={CalcParamsEnum.SecInternalPressureDrop} context={calcParams} labelText={"Sek"} isDisabled={true} end={"kPa"} />
                    </>
                ) : (
                    <>
                        <ShuntParamTextfield paramsEnum={CalcParamsEnum.Kvs} context={calcParams} labelText={"Kvs"} isDisabled={true} isNumber={false} />
                        <ShuntParamTextfield paramsEnum={CalcParamsEnum.ShuntType} context={calcParams} labelText={"Shunttyp"} isDisabled={true} isNumber={false} />
                        <ShuntParamTextfield paramsEnum={CalcParamsEnum.SVPressureLoss} context={calcParams} labelText={"Tryckfall SV"} isDisabled={true} end={"kPa"} />
                        <ShuntParamTextfield paramsEnum={CalcParamsEnum.Authority} context={calcParams} labelText={"Auktoritet SV"} isDisabled={true} end={"%"} className="shunt-percent" />
                        <ShuntParamTextfield paramsEnum={CalcParamsEnum.PrimInternalPressureDrop} context={calcParams} labelText={"Prim"} isDisabled={true} end={"kPa"} />
                        <ShuntParamTextfield paramsEnum={CalcParamsEnum.SecInternalPressureDrop} context={calcParams} labelText={"Sek"} isDisabled={true} end={"kPa"} />
                    </>
                )}

            </Stack>
        </Paper>
    );
}