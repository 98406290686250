import { MenuItem, Select, Stack } from "@mui/material"
import { useState, useEffect } from "react";
import { useDatabase } from "reactfire";
import { PRODUCTPRINCIP_SR2FLEX, PRODUCTLINE_FLEX, PRODUCTLINE_STANDARD } from "../../../../constants/productlineConstants";
import { COOL_SHUNT, HEAT_SHUNT } from "../../../../constants/shuntOptions";
import { useProductLineParams, useAdjustParams, useShuntParams, useSessionParams } from "../../../../contexts";
import { CalcParamsEnum, ShuntParamsEnum, ProductLineEnum, SessionParamsEnum } from "../../../../enums";
import { createShuntNomenklaturString } from "../../../../functions/ShuntCalculations/helpFunctions/createShuntNomenklaturString";


export const DevbarSelectfield = ({ enumVal, context, labelText, options, isDisabled, className = "" }) => {
    const getDevParams = () => {
        if (enumVal.getContextValue(context) != null) {
            if (enumVal === CalcParamsEnum.ShuntType) {
                if (enumVal.getContextValue(context) === HEAT_SHUNT) {
                    return "Värme";
                }
                else if (enumVal.getContextValue(context) === COOL_SHUNT) {
                    return "Kyla"
                }
                else {
                    return "";
                }
            }
            else if (enumVal === ShuntParamsEnum.NumBV) {
                if (Number(enumVal.getContextValue(context)) === 0) {
                    return "Ingen";
                }
                else if (Number(enumVal.getContextValue(context)) === 1) {
                    return "1 Bv"
                }
                else if (Number(enumVal.getContextValue(context)) === 2) {
                    return "2 Bv"
                }
                else {
                    return "";
                }
            }
            else if (enumVal === ShuntParamsEnum.NumIV) {
                if (Number(enumVal.getContextValue(context)) === 0) {
                    return "Ingen";
                }
                else if (Number(enumVal.getContextValue(context)) === 1) {
                    return "1 Iv"
                }
                else if (Number(enumVal.getContextValue(context)) === 2) {
                    return "2 Iv"
                }
                else {
                    return "";
                }
            }
            else {
                return enumVal.getContextValue(context);
            }
        }
        else {
            return '';
        }
    }

    const [value, setValue] = useState(getDevParams());
    const productLineParams = useProductLineParams();
    const adjustParams = useAdjustParams();
    const shuntParams = useShuntParams();
    const database = useDatabase();
    const sessionParams = useSessionParams();

    const setDevParams = (value) => {
        switch (enumVal) {
            case ProductLineEnum.ProductPrincip:
                if (value === PRODUCTPRINCIP_SR2FLEX) {
                    ProductLineEnum.ProductLine.setContextValue(productLineParams, PRODUCTLINE_FLEX);
                }
                else if (ProductLineEnum.ProductLine.getContextValue(productLineParams) === PRODUCTLINE_FLEX) {
                    ProductLineEnum.ProductLine.setContextValue(productLineParams, PRODUCTLINE_STANDARD);
                }
                enumVal.setContextValue(context, value);
                setValue(value)
                break;
            case CalcParamsEnum.ShuntType:
                if (value === "Värme") {
                    enumVal.setContextValue(context, HEAT_SHUNT);
                }
                else if (value === "Kyla") {
                    enumVal.setContextValue(context, COOL_SHUNT);
                }
                setValue(value)

                break;
            case ShuntParamsEnum.NumBV:
                if (value === "Ingen") {
                    enumVal.setContextValue(context, 0);
                }
                if (value === "1 Bv") {
                    enumVal.setContextValue(context, 1);
                }
                if (value === "2 Bv") {
                    enumVal.setContextValue(context, 2);
                }
                setValue(value)
                break;
            case ShuntParamsEnum.NumIV:
                if (value === "Ingen") {
                    enumVal.setContextValue(context, 0);
                }
                if (value === "1 Iv") {
                    enumVal.setContextValue(context, 1);
                }
                if (value === "2 Iv") {
                    enumVal.setContextValue(context, 2);
                }
                setValue(value)
                break;
            default:
                break;
        }


    }

    useEffect(() => {
        createShuntNomenklaturString(productLineParams, adjustParams, sessionParams, shuntParams, database, SessionParamsEnum.CurrentShunt.getContextValue(sessionParams));
    }, [enumVal.getContextValue(context)])


    return (
        <>
            <Stack direction={"column"} style={{ marginRight: 20 }}>
                <div style={{ marginTop: "0px", }}>
                    {labelText}:
                </div>

                <Select
                    style={{ marginLeft: "0px", width: "80px", marginTop: "5px" }}
                    size={"small"}
                    value={value || ''}
                    margin="dense"
                    onChange={(event) => { setDevParams(event.target.value) }}
                    disabled={isDisabled}
                >
                    {options.map((option, index) => {
                        return (<MenuItem key={index} value={option}>{option}</MenuItem>)
                    })}
                </Select>

            </Stack>
        </>
    );
}