import { ref, set, update } from "firebase/database";
import { SESSION_SHUNT_PATH } from "../../constants/firebasePaths";
import { NUM_BV_KEY, NUM_IV_KEY, HAS_LATCH_KEY, IS_SABO_KEY, MINI_INCOMPATIBLE_KEY, HAS_PREV_LATCH_KEY, MAGICLOUD_LINK_KEY, CONNECTED_ENERGY_MODEL_KEY, CONNECTED_ENERGY_COMM_MODAL_KEY } from "../../constants/keys/shuntKeys";
import { SessionParamsEnum } from "../../enums/sessionParamsEnum";
import { ShuntParamsEnum } from "../../enums/shuntParamsEnum";

const saveShuntData = function saveShuntData(database, sessionParams, shuntParams) {
    const shuntID = SessionParamsEnum.CurrentShunt.getContextValue(sessionParams);
    const driftParamsRef = ref(database, SESSION_SHUNT_PATH + "/" + shuntID + "/ShuntData");

    const snapData = {
        controlValve: ShuntParamsEnum.ControlValve.getContextValue(shuntParams) || null,
        adjValve: ShuntParamsEnum.AdjustmentValve.getContextValue(shuntParams) || null,
        cvName: ShuntParamsEnum.CVName.getContextValue(shuntParams) || null,
        cvModel: ShuntParamsEnum.CV_Model.getContextValue(shuntParams) || null,
        cvKvs: ShuntParamsEnum.CV_Kvs.getContextValue(shuntParams) || null,
        cvIsInterval: ShuntParamsEnum.CV_IsInterval.getContextValue(shuntParams) || null,
        flexBrand: ShuntParamsEnum.FlexBrand.getContextValue(shuntParams) || null,
        flexModel: ShuntParamsEnum.FlexModel.getContextValue(shuntParams) || null,
        flexDimID: ShuntParamsEnum.FlexDimID.getContextValue(shuntParams) || null,
        smartDim: ShuntParamsEnum.SmartDim.getContextValue(shuntParams) || null,
        smartDimID: ShuntParamsEnum.SmartDimID.getContextValue(shuntParams) || null,
        smartModel: ShuntParamsEnum.SmartModel.getContextValue(shuntParams) || null,
        shuntString: ShuntParamsEnum.ShuntNomenklaturString.getContextValue(shuntParams) || null,
        plModel: ShuntParamsEnum.PLModel.getContextValue(shuntParams) || null,
        smartSV: ShuntParamsEnum.SmartDimID.getContextValue(shuntParams) || null,
        isMini: ShuntParamsEnum.IsMini.getContextValue(shuntParams) || null,
        mcFunction: ShuntParamsEnum.MCFunction.getContextValue(shuntParams) || null,
    }

    if (ShuntParamsEnum.NumBV.getContextValue(shuntParams) != null && ShuntParamsEnum.NumBV.getContextValue(shuntParams) !== ""){
        snapData[NUM_BV_KEY] = ShuntParamsEnum.NumBV.getContextValue(shuntParams);
    }
    if (ShuntParamsEnum.NumIV.getContextValue(shuntParams) != null && ShuntParamsEnum.NumIV.getContextValue(shuntParams) !== ""){
        snapData[NUM_IV_KEY] = ShuntParamsEnum.NumIV.getContextValue(shuntParams);
    }
    if (ShuntParamsEnum.HasLatch.getContextValue(shuntParams) != null && ShuntParamsEnum.HasLatch.getContextValue(shuntParams) !== ""){
        snapData[HAS_LATCH_KEY] = ShuntParamsEnum.HasLatch.getContextValue(shuntParams);
    }
    if (ShuntParamsEnum.HasPrevLatch.getContextValue(shuntParams) != null && ShuntParamsEnum.HasPrevLatch.getContextValue(shuntParams) !== ""){
        snapData[HAS_PREV_LATCH_KEY] = ShuntParamsEnum.HasPrevLatch.getContextValue(shuntParams);
    }
    if (ShuntParamsEnum.IsSabo.getContextValue(shuntParams) != null && ShuntParamsEnum.IsSabo.getContextValue(shuntParams) !== ""){
        snapData[IS_SABO_KEY] = ShuntParamsEnum.IsSabo.getContextValue(shuntParams);
    }
    if (ShuntParamsEnum.IsMiniIncompatible.getContextValue(shuntParams) != null && ShuntParamsEnum.IsMiniIncompatible.getContextValue(shuntParams) !== ""){
        snapData[MINI_INCOMPATIBLE_KEY] = ShuntParamsEnum.IsMiniIncompatible.getContextValue(shuntParams);
    }
    if (ShuntParamsEnum.MagicloudLink.getContextValue(shuntParams) != null && ShuntParamsEnum.MagicloudLink.getContextValue(shuntParams) !== ""){
        snapData[MAGICLOUD_LINK_KEY] = ShuntParamsEnum.MagicloudLink.getContextValue(shuntParams);
    }
    
    if (ShuntParamsEnum.ConnectedEnergyModel.getContextValue(shuntParams) != null && ShuntParamsEnum.ConnectedEnergyModel.getContextValue(shuntParams) !== ""){
        snapData[CONNECTED_ENERGY_MODEL_KEY] = ShuntParamsEnum.ConnectedEnergyModel.getContextValue(shuntParams);
    }
    else {
        snapData[CONNECTED_ENERGY_MODEL_KEY] = null;
    }

    if (ShuntParamsEnum.ConnectedEnergyCommModal.getContextValue(shuntParams) != null && ShuntParamsEnum.ConnectedEnergyCommModal.getContextValue(shuntParams) !== ""){
        snapData[CONNECTED_ENERGY_COMM_MODAL_KEY] = ShuntParamsEnum.ConnectedEnergyCommModal.getContextValue(shuntParams);
    }
    else{
        snapData[CONNECTED_ENERGY_COMM_MODAL_KEY] = null;
    }

    const promises = [];

    if (Object.keys(snapData).length !== 0 && SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true) {
        promises.push(update(driftParamsRef, snapData));
    }

    return Promise.all(promises);
}

const clearShuntData = function clearShuntData(database, sessionParams) {
    const shuntID = SessionParamsEnum.CurrentShunt.getContextValue(sessionParams);
    
    const driftParamsRef = ref(database, SESSION_SHUNT_PATH + "/" + shuntID + "/ShuntData");

    const promises = [];

    if (SessionParamsEnum.SwitchingShunt.getContextValue(sessionParams) !== true){
        promises.push(set(driftParamsRef, null));
    }

    return Promise.all(promises);
}

export { saveShuntData, clearShuntData } 