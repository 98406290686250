import { roundNumber } from "../helpFunctions/roundTo4Decimals";
/**
 * Calculates the Delta Pressure based on the formula ***(flow * 3.6 / kvs)² * 100***
 * @param {number} flow ***(l/s)*** The flow used in the calculation 
 * @param {number} kvs ***(kvs)*** The KVS to be used in the calculation
 * @param {boolean} round If the result should be rounded or not.
 * @returns {number}
 */
const calcDeltaPressure = function calcDeltaPressure(flow, kvs, round = true) {
    if (kvs === 0){
        return 0;
    }
    let result = Math.pow((3.6 * flow) / kvs, 2) * 100;
    
    if (round){
        return roundNumber(result);
    }
    else{
        return result;
    }
    
}

export { calcDeltaPressure }