import { Box, Button, Divider, FormControl, Grid, IconButton, InputLabel, LinearProgress, MenuItem, Select, Stack, TextField, Tooltip, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { AVAILABLE_DRIVE_PRESSURE, PRIM_FLOW_LABELS, QUESTION_SYSTEM_FLUID_KEY, QUESTION_SYSTEM_FLUID_MIX_RATIO_KEY, QUESTION_SYSTEM_FLUID_MIX_RATIO_UNKNOWN_KEY, QUESTION_SYSTEM_FLUID_PRIMARY_KEY, QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_KEY, QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_KEY, QUESTION_SYSTEM_FLUID_SECONDARY_KEY, QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY, QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_KEY, SYSTEM_FLUID } from "../../../../constants/wizardConstants"
import { QuickInfoRow } from "./quickInfoRow"
import { PRODUCTLINE_STANDARD, PRODUCTLINE_GREEN_I, PRODUCTLINE_GREEN_II, PRODUCTLINE_FLEX, PRODUCTLINE_SMART } from "../../../../constants/productlineConstants"

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InfoIcon from '@mui/icons-material/Info';

import standardLine from '../../../../assets/images/logos/Premablock_Standard_Logo.svg'
import greenLine1 from '../../../../assets/images/logos/Premablock_Green_I_Logo.svg'
import greenLine2 from '../../../../assets/images/logos/Premablock_Green_II_Logo.svg'
import flexLine from '../../../../assets/images/logos/Premablock_Flex_Logo.svg'
import smartLine from '../../../../assets/images/logos/Premablock_Smart_Logo.svg'
import { displayTooltipText, openPLLink } from "../selectProdLinePopup"
import { useDevbarParams, useSessionParams } from "../../../../contexts"
import { SessionParamsEnum } from "../../../../enums"
import { WizardParamEnum } from "../../../../enums/wizardParamsEnum"
import { useWizardParams } from "../../../../contexts/wizardParams"
import { SystemFluidQuestion } from "../questions/systemFluidQuestion"
import { useDatabase } from "reactfire"
import { saveCustomDimensionRequest } from "../../../../firebase/functions/saveCustomDimensionRequest"

/**
 * 
 * @param {Object} param0 
 * @param {import('../../../../constants/fullConnOptions/types').ShuntConnectionInterface} param0.selected
 * @param {() => void} param0.handleClose
 * @param {(connectionObj, prodline) => boolean} param0.filterLines
 * @returns 
 */
export const NotDimensionableInfo = ({ selected, filterLines, handleClose }) => {
    // @ts-ignore
    const devParams = useDevbarParams();
    const database = useDatabase();
    const sessionParams = useSessionParams();
    const wizardParams = useWizardParams();
    const [selectedProdLine, setSelectedProdLine] = useState('');
    const [loading, setLoading] = useState(false);
    // @ts-ignore
    const [sent, setIsSent] = useState(false)

    const [projectName, setProjectName] = useState('');
    const [senderMail, setSenderMail] = useState('');
    const [senderPhone, setSenderPhone] = useState('');
    const [senderComp, setSenderComp] = useState('');
    const [senderName, setSenderName] = useState('');
    const [message, setMessage] = useState('');

    const [edited, setEdited] = useState(false);
    // @ts-ignore
    const [debugEmail, setDebugEmail] = useState('');

    // @ts-ignore
    const [isValidInput, setIsValidInput] = useState(true);

    const getContextValues = (type, useBaseValue = false) => {
        switch (type) {
            case QUESTION_SYSTEM_FLUID_KEY:
                if (WizardParamEnum.SystemFluidMain.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.SystemFluidMain.getContextValue(wizardParams);
                }
                else {
                    return '';
                }
            case QUESTION_SYSTEM_FLUID_MIX_RATIO_KEY:
                if (WizardParamEnum.SystemFluidPrimaryMixRatio.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.SystemFluidPrimaryMixRatio.getContextValue(wizardParams);
                }
                else {
                    return 0;
                }
            case QUESTION_SYSTEM_FLUID_MIX_RATIO_UNKNOWN_KEY:
                if (WizardParamEnum.SystemFluidPrimaryMixRatioUnknown.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.SystemFluidPrimaryMixRatioUnknown.getContextValue(wizardParams);
                }
                else {
                    return false;
                }
            case QUESTION_SYSTEM_FLUID_PRIMARY_KEY:
                if (WizardParamEnum.SystemFluidPrimary.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.SystemFluidPrimary.getContextValue(wizardParams);
                }
                else {
                    return '';
                }
            case QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_KEY:
                if (WizardParamEnum.SystemFluidPrimaryMixRatio.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.SystemFluidPrimaryMixRatio.getContextValue(wizardParams);
                }
                else {
                    return 0;
                }
            case QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_KEY:
                if (WizardParamEnum.SystemFluidPrimaryMixRatioUnknown.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.SystemFluidPrimaryMixRatioUnknown.getContextValue(wizardParams);
                }
                else {
                    return false;
                }
            case QUESTION_SYSTEM_FLUID_SECONDARY_KEY:
                if (WizardParamEnum.SystemFluidSecondary.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.SystemFluidSecondary.getContextValue(wizardParams);
                }
                else {
                    return '';
                }
            case QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY:
                if (WizardParamEnum.SystemFluidSecondaryMixRatio.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.SystemFluidSecondaryMixRatio.getContextValue(wizardParams);
                }
                else {
                    return 0;
                }
            case QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_KEY:
                if (WizardParamEnum.SystemFluidSecondaryMixRatioUnknown.getContextValue(wizardParams) != null && useBaseValue === false) {
                    return WizardParamEnum.SystemFluidSecondaryMixRatioUnknown.getContextValue(wizardParams);
                }
                else {
                    return false;
                }
            default:
                return "";
        }
    }

    const [systemFluid, setSystemFluid] = useState(getContextValues(QUESTION_SYSTEM_FLUID_KEY));
    const [systemFluidMixRatio, setSystemFluidMixRatio] = useState(getContextValues(QUESTION_SYSTEM_FLUID_MIX_RATIO_KEY));
    const [systemFluidMixRatioUnknown, setSystemFluidMixRatioUnknown] = useState(getContextValues(QUESTION_SYSTEM_FLUID_MIX_RATIO_UNKNOWN_KEY));
    const [systemFluidPrimary, setSystemFluidPrimary] = useState(getContextValues(QUESTION_SYSTEM_FLUID_PRIMARY_KEY));
    const [systemFluidPrimaryMixRatio, setSystemFluidPrimaryMixRatio] = useState(getContextValues(QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_KEY));
    const [systemFluidPrimaryMixRatioUnknown, setSystemFluidPrimaryMixRatioUnknown] = useState(getContextValues(QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_KEY));
    const [systemFluidSecondary, setSystemFluidSecondary] = useState(getContextValues(QUESTION_SYSTEM_FLUID_SECONDARY_KEY));
    const [systemFluidSecondaryMixRatio, setSystemFluidSecondaryMixRatio] = useState(getContextValues(QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY));
    const [systemFluidSecondaryMixRatioUnknown, setSystemFluidSecondaryMixRatioUnknown] = useState(getContextValues(QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_KEY));

    useEffect(() => {
        setEdited(false);
    }, [selected]);


    const prodLineImage = (prodLine) => {
        switch (prodLine) {
            case PRODUCTLINE_STANDARD:
                return (<img src={standardLine} alt='Standard Line' style={{ height: '15px' }} />);
            case PRODUCTLINE_GREEN_I:
                return (<img src={greenLine1} alt='Green I Line' style={{ height: '19px', paddingTop: '5px' }} />);
            case PRODUCTLINE_GREEN_II:
                return (<img src={greenLine2} alt='Green II Line' style={{ height: '19px', paddingTop: '5px' }} />);
            case PRODUCTLINE_FLEX:
                return (<img src={flexLine} alt='Flex Line' style={{ height: '15px' }} />);
            case PRODUCTLINE_SMART:
                return (<img src={smartLine} alt='Smart Line' style={{ height: '15px' }} />);
            default:
                console.log("Prod: ", prodLine)
                return (<img src={standardLine} alt='Standard Line' style={{ height: '15px' }} />);
        }
    }

    useEffect(() => {
        if (selected?.InfoData?.prodlines != null && selected?.InfoData?.prodlines.filter((item) => filterLines(selected, item)).length === 1) {
            setSelectedProdLine(selected?.InfoData.prodlines.filter((item) => filterLines(selected, item))[0]);
        }
    }, [selected])


    const createCVText = () => {
        let text = null;
        if (selected?.InputData?.cvValues != null) {
            text = "";

            for (let index = 0; index < selected?.InputData?.cvValues.length; index++) {
                const row = selected?.InputData?.cvValues[index];

                text += row.nWay + "-vägs";

                if (row?.use != null) {
                    text += " för " + row?.use;
                }

                if (row?.mounted != null) {
                    text += " (" + row?.mounted + ")";
                }

                if (index < selected?.InputData?.cvValues.length - 1) {
                    text += " / ";
                }
            }
        }

        return text;
    }

    const answerQuestion = (question, value) => {
        setEdited(true);
        switch (question) {
            case QUESTION_SYSTEM_FLUID_KEY:
                setSystemFluid(value);
                break;
            case QUESTION_SYSTEM_FLUID_MIX_RATIO_KEY:
                setSystemFluidMixRatio(value);
                break;
            case QUESTION_SYSTEM_FLUID_MIX_RATIO_UNKNOWN_KEY:
                setSystemFluidMixRatioUnknown(value);
                break;
            case QUESTION_SYSTEM_FLUID_PRIMARY_KEY:
                setSystemFluidPrimary(value);
                break;
            case QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_KEY:
                setSystemFluidPrimaryMixRatio(value);
                break;
            case QUESTION_SYSTEM_FLUID_PRIMARY_MIX_RATIO_UNKNOWN_KEY:
                setSystemFluidPrimaryMixRatioUnknown(value);
                break;
            case QUESTION_SYSTEM_FLUID_SECONDARY_KEY:
                WizardParamEnum.SystemFluidSecondary.setContextValue(wizardParams, value);
                setSystemFluidSecondary(value);
                break;
            case QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_KEY:
                setSystemFluidSecondaryMixRatio(value);
                break;
            case QUESTION_SYSTEM_FLUID_SECONDARY_MIX_RATIO_UNKNOWN_KEY:
                setSystemFluidSecondaryMixRatioUnknown(value);
                break;
            default:
                break;
        }
    }

    function handleSubmit(event) {
        event.preventDefault()
        setLoading(true);
        let dataInput = {};

        dataInput['sessionID'] = SessionParamsEnum.SessionID.getContextValue(sessionParams);
        dataInput['projectName'] = projectName;

        dataInput['ShuntInfo'] = {
            shuntModel: selected.InfoData.name,
            prodLine: selectedProdLine,
        };

        dataInput['ContactInfo'] = {
            mail: senderMail,
            phone: senderPhone,
            company: senderComp,
            name: senderName,
            message: message,
        };

        if (selected?.InputData?.dimensionInput != null) {
            dataInput['DimInputs'] = {};
            for (let index = 0; index < selected?.InputData?.dimensionInput.length; index++) {
                const row = selected?.InputData?.dimensionInput[index];

                for (let index = 0; index < row.length; index++) {
                    const element = row[index];
                    const target = event.target[element.key];
                    if (element.key === 'systemFluid') {
                        dataInput['DimInputs']['systemFluid'] = systemFluid;

                        if (systemFluid !== SYSTEM_FLUID.unknown.value && systemFluid !== SYSTEM_FLUID.water.value) {
                            if (systemFluid === SYSTEM_FLUID.different.value) {
                                if (systemFluidPrimaryMixRatioUnknown === true) {
                                    dataInput['DimInputs']['systemFluidPrimaryMixRatioUnknown'] = true;
                                }
                                else {
                                    dataInput['DimInputs']['systemFluidPrimaryMixRatio'] = systemFluidPrimaryMixRatio;
                                }

                                if (systemFluidSecondaryMixRatioUnknown === true) {
                                    dataInput['DimInputs']['systemFluidSecondaryMixRatioUnknown'] = true;
                                }
                                else {
                                    dataInput['DimInputs']['systemFluidSecondaryMixRatio'] = systemFluidSecondaryMixRatio;
                                }
                            }
                            else {
                                if (systemFluidMixRatioUnknown === true) {
                                    dataInput['DimInputs']['systemFluidMixRatioUnknown'] = true;
                                }
                                else {
                                    dataInput['DimInputs']['systemFluidMixRatio'] = systemFluidMixRatio;
                                }
                            }
                        }
                    }
                    else {
                        dataInput['DimInputs'][element.key] = target?.value;
                    }

                }
            }
        }

        console.log("Data to send: ", dataInput);

        saveCustomDimensionRequest(database, dataInput).then(() => {
            handleClose();
        }).catch((error) => {
            console.error("Could not save: ", error)
        }).finally(() => {
            setLoading(false);
        });
    }

    //console.log("Selected: ", selected)

    const isValidDimInput = () => {
        if (selected?.FilterData?.exchange === true) {
            let primTempIn = document.getElementById('tempPrimIn');
            let sekPrimIn = document.getElementById('secPrimIn');

            if (primTempIn != null && sekPrimIn != null) {
                // @ts-ignore
                if (primTempIn?.value != null && primTempIn?.value !== "" && sekPrimIn?.value != null && sekPrimIn?.value !== "") {
                    //console.log("Values: (Prim) - ", Number(primTempIn?.value), "(Sek) - ", Number(sekPrimIn?.value), Number(primTempIn?.value) - Number(sekPrimIn?.value));

                    // @ts-ignore
                    if (Number(primTempIn?.value) - Number(sekPrimIn?.value) < 1) {
                        setIsValidInput(false);
                    }
                    else {
                        setIsValidInput(true);
                    }
                }
            }
            else {
                setIsValidInput(true);
            }
        }
        else {
            setIsValidInput(true);
        }
    }

    return (
        <Box sx={{ width: 980, maxHeight: '74vh', overflowY: 'auto' }}>
            <Grid container spacing={1}>
                <Grid item md={6}>
                    <img src={selected?.ImageData?.autoImg} alt={selected?.InfoData?.name} width={350} />
                </Grid>

                <Grid item md={6}>
                    <Stack spacing={1}>
                        <Typography>
                            {selected?.InfoData?.applicationInfoText} <br />
                            {selected?.InfoData?.applicationInfoSubText}
                        </Typography>
                        <Divider />
                        <Box>
                            {selected?.InfoData?.descriptionRows != null ? (
                                <>
                                    {selected?.InfoData.descriptionRows.map((row, index) => (
                                        <div key={"desc-" + index} style={{ marginBottom: '3px' }}>
                                            {row}
                                        </div>
                                    ))}
                                </>
                            ) : ("")}
                        </Box>
                        <Typography>
                            Mer information hittar du <span style={{ cursor: 'pointer', color: '#06559e', fontWeight: 700 }} onClick={() => window.open(selected?.InfoData?.prodLink, "_blank", "noreferrer")}>här</span>
                        </Typography>
                    </Stack>
                </Grid>

                <Grid item md={6}>
                    <Stack marginLeft={'10px'}>
                        <QuickInfoRow label={'Flöde primärt'} value={PRIM_FLOW_LABELS[selected?.FilterData?.primFlow]} />
                        {selected?.FilterData?.needMainPump === true ? (
                            <>
                                {selected?.FilterData?.lowestDPValue != null ? (
                                    <QuickInfoRow label={'Tillgängligt drivtryck'} value={"≥ " + selected?.FilterData?.lowestDPValue + " kPa"} />
                                ) : (
                                    <QuickInfoRow label={'Tillgängligt drivtryck'} value={null} />
                                )}
                            </>
                        ) : ("")}

                        <QuickInfoRow label={'Styrventil'} value={createCVText()} />
                        {selected?.FilterData?.exchange ? (
                            <QuickInfoRow label={'Växlare'} value={selected?.FilterData?.exchange ? "Ja" : "Nej"} />
                        ) : ("")}

                        <QuickInfoRow label={'Huvudpump erfordras'} value={selected?.FilterData?.needMainPump === true ? "Ja" : selected?.FilterData?.needMainPump === false ? "Nej" : ""} />
                    </Stack>
                </Grid>

                <Grid item md={6}>
                    <Stack spacing={1}>
                        <Typography fontSize={'.95rem'} fontWeight={700}>
                            Tillgänglig i produktlinjerna
                        </Typography>

                        {selected?.InfoData?.prodlines != null ? (
                            <>
                                {selected?.InfoData?.prodlines.map((item) => {
                                    return (
                                        <Stack key={item} sx={{ height: '20px' }} direction={'row'} alignItems={'center'}>
                                            <div style={{ width: '220px' }}>
                                                <Tooltip arrow title={displayTooltipText(item, true)}>
                                                    <span>
                                                        {prodLineImage(item)}
                                                    </span>
                                                </Tooltip>
                                            </div>
                                            <Tooltip arrow placement="left-end" title={displayTooltipText(item, false)}>
                                                <IconButton sx={{ marginTop: '3px' }} size="small">
                                                    <InfoIcon htmlColor="#0055a0" sx={{ fontSize: '12px' }} />
                                                </IconButton>
                                            </Tooltip>


                                            <IconButton sx={{ marginTop: '3px' }} onClick={() => openPLLink(item)} size="small">
                                                <OpenInNewIcon sx={{ fontSize: '12px' }} />
                                            </IconButton>
                                        </Stack>
                                    )
                                })}
                            </>
                        ) : ("")}
                    </Stack>
                </Grid>
            </Grid>

            <Divider sx={{ marginTop: '20px', marginBottom: '10px' }} textAlign="left"><strong>Kontakta PREMA för dimensionering</strong></Divider>

            <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                    <Grid item md={6}>
                        <Box>
                            <Stack spacing={1}>
                                <FormControl size="medium">
                                    <InputLabel>Produktlinje</InputLabel>
                                    <Select
                                        label={'Produktlinje'}
                                        value={selectedProdLine}
                                        onChange={(e) => setSelectedProdLine(e.target.value)}
                                        sx={{ width: '230px', height: '35px' }}>
                                        <MenuItem></MenuItem>
                                        {selected?.InfoData?.prodlines?.map((line) => {
                                            return (
                                                <MenuItem sx={{ height: '40px', filter: filterLines(selected, line) ? '' : 'grayscale(100%)' }} key={line} value={line} disabled={filterLines(selected, line) === false}>{prodLineImage(line)}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>

                                {selected?.InputData?.dimensionInput != null ? (
                                    <>
                                        {selected?.InputData?.dimensionInput.map((row, index) => {
                                            return (
                                                <Stack key={'dim-' + index} direction={'row'} spacing={4}>
                                                    {row.map((item) => {
                                                        if (item?.key === "systemFluid") {
                                                            return (
                                                                <Box key={item?.key} paddingBottom={'5px'}>
                                                                    <SystemFluidQuestion
                                                                        systemFluid={systemFluid}
                                                                        systemFluidMixRatio={systemFluidMixRatio}
                                                                        systemFluidMixRatioUnknown={systemFluidMixRatioUnknown}
                                                                        systemFluidPrimary={systemFluidPrimary}
                                                                        systemFluidPrimaryMixRatio={systemFluidPrimaryMixRatio}
                                                                        systemFluidPrimaryMixRatioUnknown={systemFluidPrimaryMixRatioUnknown}
                                                                        systemFluidSecondary={systemFluidSecondary}
                                                                        systemFluidSecondaryMixRatio={systemFluidSecondaryMixRatio}
                                                                        systemFluidSecondaryMixRatioUnknown={systemFluidSecondaryMixRatioUnknown}
                                                                        answerQuestion={answerQuestion}
                                                                    />
                                                                </Box>

                                                            )
                                                        }
                                                        else if (item?.key === "cvNways") {
                                                            return (
                                                                <FormControl key={item?.key} size="small">
                                                                    <InputLabel>{item?.label}</InputLabel>
                                                                    <Select
                                                                        name={item.key}
                                                                        label={item?.label}
                                                                        sx={{ width: '150px' }}
                                                                        onChange={() => setEdited(true)}
                                                                    >
                                                                        <MenuItem></MenuItem>
                                                                        <MenuItem value={'2Way'}>2 vägs styrventil</MenuItem>
                                                                        <MenuItem value={'3Way'}>3 vägs styrventil</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            )
                                                        }
                                                        else {
                                                            let defaultVal = null;

                                                            if (item?.key === 'primDP') {
                                                                if (WizardParamEnum.AvailDrivingPressure.getContextValue(wizardParams) === AVAILABLE_DRIVE_PRESSURE.specify.value &&
                                                                    WizardParamEnum.SpecificAvailDrivingPressure.getContextValue(wizardParams) != null) {
                                                                    defaultVal = WizardParamEnum.SpecificAvailDrivingPressure.getContextValue(wizardParams);
                                                                }
                                                            }
                                                            else if (item?.key === 'secFlow') {
                                                                if (WizardParamEnum.SecondaryFlow.getContextValue(wizardParams) === 'Specifikt värde' &&
                                                                    WizardParamEnum.SecondaryFlowSpecVal.getContextValue(wizardParams) != null) {
                                                                    defaultVal = WizardParamEnum.SecondaryFlowSpecVal.getContextValue(wizardParams);
                                                                }
                                                            }
                                                            return (
                                                                <Tooltip key={item?.key} arrow placement="left" title={item?.toolTip}>
                                                                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                                        <TextField
                                                                            sx={{ width: '150px' }}
                                                                            type="number"
                                                                            size="small"
                                                                            id={item?.key}
                                                                            name={item?.key}
                                                                            label={item?.label}
                                                                            defaultValue={defaultVal}
                                                                            // @ts-ignore
                                                                            onChange={(e) => {
                                                                                isValidDimInput();
                                                                                setEdited(true);
                                                                            }}
                                                                        />
                                                                        <Typography>
                                                                            {item?.unit}
                                                                        </Typography>
                                                                    </Stack>
                                                                </Tooltip>
                                                            )
                                                        }
                                                    })}
                                                </Stack>
                                            )
                                        })}
                                    </>
                                ) : ("")}
                            </Stack>

                        </Box>
                    </Grid>
                    <Grid item md={6}>
                        <InputLabel sx={{ marginBottom: '8px' }}>Kontaktuppgifter</InputLabel>

                        <Stack spacing={1}>
                            <TextField
                                style={{ display: 'flex', justifyContent: 'center', width: '49%' }}
                                value={projectName}
                                type={'text'}
                                label="Projektnamn/anläggning"
                                onChange={(event) => { setProjectName(event.target.value) }}
                                required
                            />

                            <Stack direction={'row'} spacing={1}>
                                <TextField
                                    style={{ display: 'flex', justifyContent: 'center', width: '49%' }}
                                    value={senderName}
                                    type={'text'}
                                    label="Namn"
                                    onChange={(event) => { setSenderName(event.target.value) }}
                                    required
                                />
                                <TextField
                                    style={{ display: 'flex', justifyContent: 'center', width: '49%' }}
                                    value={senderComp}
                                    type={'text'}
                                    label="Företag"
                                    onChange={(event) => { setSenderComp(event.target.value) }}
                                />
                            </Stack>
                            <Stack direction={'row'} spacing={1}>
                                <TextField
                                    style={{ display: 'flex', justifyContent: 'center', width: '49%' }}
                                    value={senderMail}
                                    type={'email'}
                                    label="E-post"
                                    onChange={(event) => { setSenderMail(event.target.value) }}
                                    required
                                />
                                <TextField
                                    style={{ display: 'flex', justifyContent: 'center', width: '49%' }}
                                    value={senderPhone}
                                    type={'text'}
                                    label="Telefon"
                                    onChange={(event) => { setSenderPhone(event.target.value) }}
                                />
                            </Stack>

                            <Box>
                                <TextField
                                    style={{ display: 'flex', justifyContent: 'center', margin: 'auto', width: '100%' }}
                                    id="comment-input"
                                    value={message}
                                    type={'text'}
                                    label="Meddelande (valfritt)"
                                    multiline
                                    rows={4}
                                    onChange={(event) => { setMessage(event.target.value) }}
                                />
                            </Box>
                        </Stack>
                    </Grid>

                    <Grid item xs={12}>
                        <Box sx={{ marginTop: '5px' }} display={'flex'} justifyContent={'center'}>
                            <Stack alignItems={'center'}>

                                <Button disabled={senderName == null || senderName === "" || senderMail == null || senderMail === "" || projectName == null || projectName === "" || edited === false || isValidInput === false} type="submit" size="small" variant="contained">
                                    Begär dimensionering
                                </Button>
                                <Box width={'98%'} marginTop={'2px'}>
                                    <LinearProgress sx={{ borderRadius: '8px', opacity: loading ? 1 : 0 }} />
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}