import { collection, getDocs } from "firebase/firestore";
import { CONTROL_VALVES_PATH } from "../../constants/firebasePaths";
import { useEffect, useState } from "react";
import { useFirestore } from "reactfire";


export const getSpecialFlexDim = async (firestore) => {
    let fsSpecialFlex = CONTROL_VALVES_PATH + "IMI/Models/TACompact-P/Dims";

    const specialDimsDoc = collection(firestore, fsSpecialFlex);
    
    const firestoreSpecialDimRes = await getDocs(specialDimsDoc);

    const localSpecialModels = [];

    if (firestoreSpecialDimRes.empty === false) {
        firestoreSpecialDimRes.docs.forEach(model => {
            localSpecialModels.push({ id: model.id, data: model.data() })
        });
    }

    return localSpecialModels;
}

export const useGetSpecialFlexDim = () => {
    const firestore = useFirestore();
    const [specialDim, setSpecialDim] = useState([]);
    
    useEffect(() => {
        getSpecialFlexDim(firestore).then((dimList) => {
            setSpecialDim(dimList);
        })
    }, []);

    return specialDim;
}