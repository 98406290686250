//Flexmodels
export const FLEXMODEL_TAMODULATOR = 'TAModulator'

//Green II default controlvalves
export const DEFAULT_GREEN_II_CV_SR2 = "Siemens VVF53"
export const DEFAULT_GREEN_II_CV = "Siemens VXF53"

//ControlValves
export const CV_IMI = 'IMI';
export const CV_SOE = 'SoE';
export const CV_SCHNEIDER = "Schneider"
export const CV_SIEMENS = "Siemens"

//ControlValves Models
export const CV_MODEL_KVS = 'Kvs';
export const CV_MODEL_TASMART = 'TASmart';
export const CV_MODEL_TAMODULATOR = 'TAModulator';
export const CV_MODEL_TACOMPACT_P = 'TACompact-P';

export const CV_MODEL_VVG11 = 'VVG11';
export const CV_MODEL_VXG11 = 'VXG11';
export const CV_MODEL_TAFUSIONC = 'TA Fusion C';
export const CV_MODEL_TBV_CM = 'TBV-CM';
export const CV_MODEL_AB_QM = 'AB-QM'
export const CV_MODEL_MXGF = 'MXG/F'

export const CV_MODEL_VVF53 = 'VVF53';
export const CV_MODEL_VXF53 = 'VXF53';

export const CV_MODEL_V231 = 'V231';
export const CV_MODEL_V311 = 'V311';

export const CV_MODEL_H6S = 'H6S';

export const HEAT_SHUNT = "Värmeshunt";
export const COOL_SHUNT = "Kylshunt";

//AdjustmenValves
export const ADJUST_VALVE_STADF = "StadF";
export const ADJUST_VALVE_ARMATEC1310 = "Armatec1310";
export const ADJUST_VALVE_PURMOEVO = "PurmoEvo";
export const ADJUST_VALVE_OVENTROP = "Oventrop";

export const ADJUST_LABELS = {
    StadF: "STAD/F",
    Armatec1310: "Armatec 1310",
    PurmoEvo: "Purmo Evobalance/STV",
    Oventrop: "Oventrop Hydroctrl."
}

export const ADJUST_LABEL_STAD = "STAD";
export const ADJUST_LABEL_STAF = "STAF";
export const ADJUST_LABEL_STAD_ZERO = "STAD ZERO";
export const ADJUST_LABEL_PURMO_EVO = "Purmo Evobalance";
export const ADJUST_LABEL_PURMO_STV = "Purmo STV"

export const CVMODELS_LABELS = {
    'TAModulator': "TA Modulator",
    'TACompact-P': "TA Compact-P",
    'AB-QM': "AB-QM",
    'VPF43': "VPF43",
    'VPF44': "VPF44",
    'VPI46': "VPI46",
    'VPP46': "VPP46",
    'TASmart': "TA Smart",
}