import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme(({
  // Enable typography v2: https://material-ui.com/style/typography/#migration-to-typography-v2
  typography: {
    fontFamily: ['Roboto'].join(','),
    fontSize: "1rem",
  },
  flexColumnCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  palette: {
    primary: {
      main: "#06559e"
    },

  }

}))

theme = responsiveFontSizes(theme)
export default theme