import { PRODUCTPRINCIP_SR2, PRODUCTPRINCIP_VA1 } from "../../constants/productlineConstants"
import { ADJUST_VALVE_STADF } from "../../constants/shuntOptions"
import { getLargestDimValues } from "./helpFunctions/getLargestDim"

export const isGreenLevel2 = (plPrincipVal, shuntType, adjustValve, primDim, sekDim, primTempIn, primTempOut, secTempIn, secTempOut, kvs, useConnectedEnergy) => {
    if (plPrincipVal !== PRODUCTPRINCIP_VA1) {
        //console.log("Type:", shuntType, "Min:", getMinTempValue_Values(primTempIn, primTempOut, secTempIn, secTempOut));
        //if (shuntType === HEAT_SHUNT && getMinTempValue_Values(primTempIn, primTempOut, secTempIn, secTempOut) > 20) {
        if (getLargestDimValues(primDim, sekDim) <= 125 && adjustValve === ADJUST_VALVE_STADF) {
            if (plPrincipVal === PRODUCTPRINCIP_SR2 || kvs >= 1.6) {
                if (useConnectedEnergy !== true){
                    return true;
                }
            }
        }
        //}
    }

    return false;
}