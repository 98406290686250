import { useEffect, useState } from 'react'
import { Tooltip, Modal, Divider, Button, Grid, Paper, Fade } from '@mui/material'
import { useProductLineParams, useSessionParams, useDevbarParams } from "../../../contexts";
import { ProductLineEnum, SessionParamsEnum } from '../../../enums';
import { FirebaseShuntSelect } from './components/firebaseShuntSelect';
import { FindSessionOnFB } from '../../utils/findSessionOnFB';
import { OverviewModal } from '../overview/overviewModal';
import { useGetShuntListName } from '../../../firebase/functions/getShuntList';
import { useContextAuth } from '../../../authContext/AuthHook';

import { DISABLED_MSG_TEXT } from '../../../constants/generalText/sessionAPITexts';
import { PRODUCTLINE_GREEN } from '../../../constants/productlineConstants';

import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


export default function Infobox() {
    const productLine = ProductLineEnum.ProductLine;
    const productPrincip = ProductLineEnum.ProductPrincip;
    const productGreenLevel = ProductLineEnum.ProductGreenLevel;
    const productLineParams = useProductLineParams();
    const sessionParams = useSessionParams();
    const devParams = useDevbarParams();

    const { user } = useContextAuth();
    const { shuntList, loading } = useGetShuntListName();

    const isApiSession = SessionParamsEnum.IsApiSession.getContextValue(sessionParams);
    const isBulkUpdate = SessionParamsEnum.ApiIsBulk.getContextValue(sessionParams);

    const [openToolTip, setOpenToolTip] = useState(false);

    const [count, setCount] = useState(0);

    const handleTooltipClose = () => {
        setOpenToolTip(false);
    };

    const handleTooltipOpen = () => {
        setOpenToolTip(true);
        navigator.clipboard.writeText(SessionParamsEnum.SessionID.getContextValue(sessionParams)).then(() => {
            handleTooltipClose();
        });
    };

    // Modal
    const handleOpen = () => {
        SessionParamsEnum.OpenOverview.setContextValue(sessionParams, true);
    }

    // Edit modal
    const [isEdit, setIsEdit] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const handleCloseEdit = () => setOpenEdit(false);


    useEffect(() => {
        setOpenEdit(false);
    }, [SessionParamsEnum.SessionID.getContextValue(sessionParams)]);

    useEffect(() => {
        if (loading === false) {
            SessionParamsEnum.SessionShunts.setContextValue(sessionParams, shuntList);
        }
    }, [loading])

    useEffect(() => {
        let localCount = 0;
        shuntList.forEach(shunt => {
            if (shunt.isStarted === true) {
                localCount++;
            }
        });
        setCount(localCount);
    }, [shuntList])

    const getNumShunts = () => {
        if (count === 0) {
            return '-';
        }
        else {
            return count;
        }
    }

    const editSession = () => {
        setIsEdit(true);
        setOpenEdit(true);
    }

    const sessionID = SessionParamsEnum.SessionID.getContextValue(sessionParams)

    //console.log('session id: ' + SessionParamsEnum.ParentID.getContextValue(sessionParams))
    const displayProdLine = () => {
        if (productLineParams.state.productLine != null && productLine.getContextValue(productLineParams) != null) {
            let prodText = productLine.getContextValue(productLineParams);
            if (productLine.getContextValue(productLineParams) === PRODUCTLINE_GREEN && productGreenLevel.getContextValue(productLineParams) != null) {
                if (productGreenLevel.getContextValue(productLineParams) === 1) {
                    prodText += ' I'
                }
                else if (productGreenLevel.getContextValue(productLineParams) === 2) {
                    prodText += ' II'
                }
            }
            return prodText;
        }
        else {
            return "-"
        }
    }

    const setDivClass = () => {
        if (SessionParamsEnum.SessionID.getContextValue(sessionParams) != null && SessionParamsEnum.SessionID.getContextValue(sessionParams) !== "") {
            if (String(SessionParamsEnum.SessionID.getContextValue(sessionParams)).length > 20) {
                return "preview-icon-box-longID"
            }
            else {
                return "preview-icon-box"
            }
        }
        else {
            return "preview-icon-box"
        }
    }

    const displaySessionID = () => {
        if (SessionParamsEnum.SessionID.getContextValue(sessionParams) != null && SessionParamsEnum.SessionID.getContextValue(sessionParams) !== "") {
            if (String(SessionParamsEnum.SessionID.getContextValue(sessionParams)).length > 18) {
                return String(SessionParamsEnum.SessionID.getContextValue(sessionParams)).substring(0, 18) + "...";
            }
            else {
                return String(SessionParamsEnum.SessionID.getContextValue(sessionParams));
            }
        }
        else {
            return "-"
        }
    }

    return (
        <Paper elevation={1} style={{
            backgroundColor: '#fff',
            width: "475px",
            marginLeft: '320px',
            marginTop: '-46px',
            color: '#00000099',
            height: '83px',
            padding: "8px",
            textAlign: 'left',
            position: 'absolute',
        }}>
            <Grid container spacing={2}>
                <Grid item xs={5} className="info-box">
                    <strong>Session</strong>
                    <Tooltip
                        onClose={handleTooltipClose}
                        TransitionComponent={Fade}
                        TransitionProps={{
                            timeout: {
                                exit: 1400
                            }
                        }}
                        open={openToolTip}
                        disableHoverListener
                        disableTouchListener
                        title="Kopierad"
                    >
                        <span style={{ display: 'block', cursor: 'pointer' }} onClick={handleTooltipOpen}>
                            <Tooltip
                                placement='top'
                                title={"Klicka för att kopiera ID"}
                                arrow
                            >
                                <span>
                                    ID: <strong>{displaySessionID()}</strong>
                                </span>
                            </Tooltip>

                        </span>
                    </Tooltip>
                    <div className={setDivClass()}>
                        {SessionParamsEnum.Editable.getContextValue(sessionParams) === false && devParams.state.val.devMode !== true ? (
                            <>
                                <Tooltip title="Gransknings-läge">
                                    <span>
                                        <RemoveRedEyeRoundedIcon className="preview-icon" />
                                    </span>
                                </Tooltip>
                                <Tooltip title="Redigera (ny session)">
                                    <span>
                                        <EditRoundedIcon className="edit-icon" onClick={editSession} />
                                    </span>
                                </Tooltip>
                            </>
                        ) : ("")}
                    </div>
                    <div className={setDivClass()}>
                        {SessionParamsEnum.ParentID.getContextValue(sessionParams) == null && SessionParamsEnum.Editable.getContextValue(sessionParams) === true ? (
                            <Tooltip title="Nyskapad session">
                                <span>
                                    <NewReleasesIcon className="fresh-icon" />
                                </span>
                            </Tooltip>
                        ) : ("")}
                    </div>
                    <div className={setDivClass()}>
                        {SessionParamsEnum.ParentID.getContextValue(sessionParams) != null && SessionParamsEnum.Editable.getContextValue(sessionParams) === true ? (
                            <Tooltip title={"Baserad på " + SessionParamsEnum.ParentID.getContextValue(sessionParams)}>
                                <span>
                                    <ContentCopyIcon className="child-session-icon" />
                                </span>
                            </Tooltip>
                        ) : ("")}
                    </div>

                    <span className="facility-title">
                        Anläggning: <strong>{(sessionParams.state.val != null && SessionParamsEnum.Facility.getContextValue(sessionParams) != null ? (SessionParamsEnum.Facility.getContextValue(sessionParams)) : ("-"))}</strong>
                    </span>
                    {isApiSession !== true ? (
                        <Button
                            onClick={handleOpen}
                            size="small"
                            disabled={productPrincip.getContextValue(productLineParams) === undefined && shuntList.length <= 1 && user?.isAnonymous !== false}
                            variant="contained"
                            color="primary"
                            sx={{ minWidth: '82px', maxWidth: '95px', marginTop: '6px', fontSize: '10px', display: 'block', }}
                        >
                            Översikt ({getNumShunts()})
                        </Button>
                    ) : (
                        <>
                            {isBulkUpdate === true ? (
                                <span>
                                    <Button
                                        onClick={handleOpen}
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        sx={{ minWidth: '82px', maxWidth: '95px', marginTop: '6px', fontSize: '10px', display: 'block', }}
                                    >
                                        Översikt ({getNumShunts()})
                                    </Button>
                                </span>
                            ) : (
                                <Tooltip title={DISABLED_MSG_TEXT}>
                                    <span>
                                        <Button
                                            onClick={handleOpen}
                                            size="small"
                                            disabled
                                            variant="contained"
                                            color="primary"
                                            sx={{ minWidth: '82px', maxWidth: '95px', marginTop: '6px', fontSize: '10px', display: 'block', }}
                                        >
                                            Översikt ({getNumShunts()})
                                        </Button>
                                    </span>
                                </Tooltip>
                            )}
                        </>
                    )}

                </Grid >
                <Divider orientation="vertical" flexItem style={{ marginTop: '12px' }}>
                </Divider>
                <Grid item xs={6} className="info-box">
                    <strong>Shuntgrupp</strong> {devParams.state.val.devMode === true ? (
                        <strong><i>{(sessionParams.state.val != null && SessionParamsEnum.CurrentShunt.getContextValue(sessionParams) != null ? "(" + (SessionParamsEnum.CurrentShunt.getContextValue(sessionParams)) + ")" : ("-"))}</i></strong>
                    ) : ("")}
                    <span style={{ display: 'block' }}>Produktlinje: <strong>{displayProdLine()}</strong></span>
                    <span style={{ display: 'block' }}>Kopplingsprincip: <strong>{(productLineParams.state.productLine != null && productPrincip.getContextValue(productLineParams) != null) ? (productPrincip.getContextValue(productLineParams)) : ("-")}</strong></span>

                    {isApiSession !== true ? (
                        <FirebaseShuntSelect />
                    ) : (
                        <>
                            {isBulkUpdate === true ? (
                                <span>
                                    <FirebaseShuntSelect />
                                </span>
                            ) : (
                                <Tooltip title={DISABLED_MSG_TEXT}>
                                    <span>
                                        <FirebaseShuntSelect disabled={true} />
                                    </span>
                                </Tooltip>
                            )}
                        </>

                    )}

                </Grid>
            </Grid >

            <OverviewModal />

            <Modal
                open={openEdit}
                onClose={handleCloseEdit}
            >
                <div>
                    <FindSessionOnFB sessionID={sessionID} edit={isEdit} viaInfoBox />
                </div>
            </Modal>
        </Paper >
    )
}