import { Box, Button, IconButton, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDatabase, useUser } from "reactfire";

import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

import { styled } from '@mui/material/styles';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { saveTechRows } from "../../../../firebase/functions/saveTechRows";
import { getTechRowText } from "../../../../functions/DisplayFunctions/getTechRowText";
import { useContextAuth } from "../../../../authContext/AuthHook";
import { SessionParamsEnum } from "../../../../enums";
import { useSessionParams } from "../../../../contexts";

const CustomTextarea = styled(TextareaAutosize)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    width: '95%',
    minHeight: 80,
    maxHeight: 300,
    resize: 'vertical',
    fontSize: "12px",
    fontFamily: theme.typography.fontFamily,
    '&:focus': {
        outline: 'none',
        borderColor: theme.palette.primary.main,
        boxShadow: `0 0 0 0.2rem ${theme.palette.primary.light}`,
    },
}));

export const InspectPageCustomTechData = ({ shuntData }) => {
    const { user } = useContextAuth();
    const database = useDatabase();
    const sessionParams = useSessionParams();
    //console.log("Data: ", shuntData.SpecData)
    const [openEdit, setOpenEdit] = useState(false);
    const [currentShunt, setCurrentShunt] = useState('');
    const [techValue, setTechValue] = useState('')
    const [techRows, setTechRows] = useState([]);

    const [reset, setReset] = useState(false)
    const [saveTrigger, setSaveTrigger] = useState(false);

    useEffect(() => {
        if (shuntData != null && Object.keys(shuntData).length > 0) {
            if (shuntData?.SpecData?.techList != null && reset === false) {
                let techArray = []
                for (let index = 0; index < shuntData.SpecData.techList.length; index++) {
                    const element = shuntData.SpecData.techList[index];
                    techArray.push(element.text);
                }
                setCurrentShunt(shuntData?.id);
                setTechValue(techArray.join('\n'));
                setTechRows(shuntData.SpecData.techList)
            }
            else {
                const techArray = [];

                techArray.push(getTechRowText('secFlowEffect', false, shuntData));
                techArray.push(getTechRowText('flow', false, shuntData));
                techArray.push(getTechRowText('pressureDropPrim', false, shuntData));
                techArray.push(getTechRowText('pressureDropSec', false, shuntData));
                techArray.push(getTechRowText('mainPump', false, shuntData));
                techArray.push(getTechRowText('externalPressureDropSec', false, shuntData));
                techArray.push(getTechRowText('balancingKV', false, shuntData));
                techArray.push(getTechRowText('greenLevelText', false, shuntData));

                let textRows = []
                let textList = []
                techArray.forEach((row) => {
                    row.forEach((textRow) => {
                        textRows.push({ text: textRow })
                        textList.push(textRow);
                    })
                })

                setCurrentShunt(shuntData?.id);
                setTechValue(textList.join('\n'));
                setTechRows(textRows);

                if (shuntData?.Baseinfo?.isCustom === true || reset === true) {
                    setSaveTrigger(true);
                }
            }
        }
    }, [shuntData, reset]);

    useEffect(() => {
        if (saveTrigger === true) {
            setSaveTrigger(false);
            saveTechData();

            if (reset === true) {
                setReset(false)
            }
        }
    }, [saveTrigger])


    function handleChangeTech(e) {
        setTechValue(e.target.value);
    }

    function handleKeyDown(e) {
        if (e.key === 'Tab') {
            e.preventDefault();

            const cursorPosition = e.target.selectionStart;
            const cursorEndPosition = e.target.selectionEnd;
            const tab = '\t';

            e.target.value = techValue.substring(0, cursorPosition) + tab + techValue.substring(cursorEndPosition);

            e.target.selectionStart = cursorPosition + tab.length;
            e.target.selectionEnd = cursorPosition + tab.length;
            setTechValue(e.target.value);
        }
    }

    function saveTechData() {
        //console.log("Data", techValue);
        const rows = techValue.split('\n');
        let textRows = [];
        for (let index = 0; index < rows.length; index++) {
            const element = rows[index];
            textRows.push({ text: element });
            //console.log("Index: ", index, 'Row: ', element)
        }

        setTechRows(textRows);
        saveTechRows(database, shuntData.NO_ID_FIELD, SessionParamsEnum.SessionID.getContextValue(sessionParams), textRows);
        SessionParamsEnum.UpdateValues.setContextValue(sessionParams, !SessionParamsEnum.UpdateValues.getContextValue(sessionParams));
        setOpenEdit(false);
    }


    function renderText(value, index) {
        // Replace each tab character in the value with 4 spaces
        const tabSize = 4;
        const spaces = '\u00A0'.repeat(tabSize);
        let text;
        if (value != null) {
            text = value.replace(/\t/g, spaces);
        }
        else {
            text = "";
        }

        return (
            <Typography key={'tech-' + index} style={{ width: '550px', whiteSpace: 'pre-wrap' }}>
                {text}
            </Typography>
        )
    }

    return (
        <Fragment>
            <Stack spacing={0.1} style={{ paddingTop: "20px", paddingBottom: "15px" }}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <div style={{ paddingBottom: "3px" }}><strong>Tekniska data:</strong></div>
                </Stack>

                {openEdit ? (
                    <Fragment>
                        <textarea className="textarea-box" style={{ height: '120px', minHeight: '90px', maxHeight: '430px' }} value={techValue} onChange={handleChangeTech} onKeyDown={handleKeyDown} placeholder="Skriv teknisk data här..." />
                    </Fragment>
                ) : (
                    <Fragment>
                        {techRows != null ? (
                            <Fragment>
                                {techRows.map((row, index) => {
                                    return renderText(row?.text, index);
                                })}
                            </Fragment>
                        ) : ("")}
                    </Fragment>
                )}
            </Stack>

            {user?.isAnonymous !== true && user?.isInternal === true && SessionParamsEnum.Editable.getContextValue(sessionParams) === true ? (
                <Fragment>
                    {openEdit ? (
                        <Stack direction={'row'} style={{ position: 'relative' }}>
                            <Tooltip title="Spara ändringar">
                                <IconButton size="small" onClick={saveTechData}>
                                    <SaveIcon sx={{ "&:hover": { color: "#9e9e9e" }, fontSize: '12px' }} htmlColor={"#d9d9d9"} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Stäng utan att spara">
                                <IconButton size="small" onClick={() => { setOpenEdit(!openEdit) }}>
                                    <CloseIcon sx={{ "&:hover": { color: "#9e9e9e" }, fontSize: '12px' }} htmlColor={"#d9d9d9"} />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    ) : (
                        <Stack direction={'row'} style={{ position: 'relative' }}>
                            <Tooltip title="Redigera teknisk data">
                                <IconButton size="small" onClick={() => { setOpenEdit(!openEdit) }}>
                                    <EditIcon sx={{ "&:hover": { color: "#9e9e9e" }, fontSize: '12px' }} htmlColor={"#d9d9d9"} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Återställ fördefinierad teknisk data.">
                                <IconButton size="small" onClick={() => { setReset(true) }}>
                                    <SettingsBackupRestoreIcon sx={{ "&:hover": { color: "#9e9e9e" }, fontSize: '12px' }} htmlColor={"#d9d9d9"} />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    )}
                </Fragment>
            ) : ("")}
        </Fragment>

    )
}