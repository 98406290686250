import { useEffect, useState } from "react";
import { countDecimalPlaces, isNativeEventTyping, sanitizeIncrementedValue } from "../../../functions/HelperFunctions/numberInputHelpers";
import { TextField } from "@mui/material";
import { SYSTEM_FLUID } from "../../../constants/wizardConstants";
import { useGetSystemFluidOptions } from "../../../hooks/useGetSystemFluidOptions";


export const SystemFluidMixRatioInputfield = ({ questionKey, value, systemFluid, handleChangeFluidMix, disabled }) => {
    const systemFluidOptions = useGetSystemFluidOptions();
    
    const getSystemFluidSelected = (value) => {
        if (value != null){
            return systemFluidOptions.find((obj) => obj.value === value);
        }
        return null;
    }
    
    const [arrowChange, setArrowChange] = useState(0);

    const stepAmt = 1;
    const minDecimals = countDecimalPlaces(stepAmt);


    const applyStepChange = () => {
        if (arrowChange != null && arrowChange !== 0) {
            const wasUpwards = arrowChange > value;
            const wasDownwards = arrowChange < value;

            if (wasUpwards || wasDownwards) {
                const change = wasUpwards ? stepAmt : -stepAmt;
                const valueDecimals = countDecimalPlaces(value);
                const numDecimals =
                    valueDecimals > minDecimals ? valueDecimals : minDecimals;
                const newValue = sanitizeIncrementedValue(
                    value + change,
                    numDecimals
                );

                if (!isNaN(newValue)) {
                    //console.log("Apply new value: ", newValue, questionKey)
                    handleChangeFluidMix(newValue, questionKey)
                }
            }
            setArrowChange(undefined);
        }
    };

    const handleInputChange = (event) => {
        const isTypingChange = isNativeEventTyping(event.nativeEvent.inputType);
        if (!isTypingChange) {
            setArrowChange(parseFloat(event.currentTarget.value));
            event.preventDefault();
            return;
        }

        const newValue = parseFloat(event.target.value);

        if (event.target.value === "") {
            handleChangeFluidMix(0, questionKey)
            return;
        }

        if (!isNaN(newValue)) {
            //console.log("Input new value: ", newValue, questionKey)
            handleChangeFluidMix(newValue, questionKey)
        }
    };

    const onMouseDown = () => {
        applyStepChange();
    };

    useEffect(() => {
        applyStepChange();
    }, [arrowChange, applyStepChange]);

    return (
        <TextField
            sx={{ width: '60px', background: 'white' }}
            size="small"
            type="number"
            label={getSystemFluidSelected(systemFluid)?.label}
            value={value}
            disabled={disabled}
            InputProps={{
                inputProps: {
                    step: 'any',
                    max: 100,
                    min: 0,
                },

            }}
            onChange={handleInputChange}
            onMouseDown={onMouseDown}
        />
    )
}