import { Button, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material"
import { BootstrapDialog, BootstrapDialogTitle } from "../../utils/bootstrapDialog"

import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';

export const EditedShuntModal = ({ open, handleClose, hasErrors }) => {

    return (
        <>
            <BootstrapDialog
                style={{position: 'absolute', top: '-20vh'}}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        {hasErrors === true ? (
                            <>
                                <WarningAmberRoundedIcon color="error" />

                                <span>
                                    Varning!
                                </span>
                            </>
                        ) : (
                            <>
                                <PriorityHighRoundedIcon color="primary" />

                                <span>
                                    Observera!
                                </span>
                            </>
                        )}

                    </Stack>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom style={{ display: 'flex', justifyContent: 'center', width: '400px' }}>
                        {hasErrors === true ? (
                            "Sessionen innehåller shuntgrupper med redigerade specifikationer som har konfigurerats om efter specifikationen modifierades. Kontrollera att all information är korrekt."
                        ) : (
                            "Sessionen innehåller redigerade specifikationer"
                        )}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={handleClose}>
                        {hasErrors === true ? (
                            "Jag förstår, gå till Översikt"
                        ) : (
                            "Ok"
                        )}
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    )
}