import { ConnectionTypeCustomOptionsListInterface } from "./types";

export const connectionTypeCustomOptionsList: ConnectionTypeCustomOptionsListInterface = {
    "SR-2": {
        id: "SR-2",
        connOptions: [
            'U', 'H', 'L'
        ],
        shuntDimLabels: {
            prim: 'Prim',
            sek: 'Sek'
        },
        shuntFlowLabels: {
            prim: 'Prim',
            sec: 'Sek'
        },
        shuntAdjustValveLabels: {
            prim: 'Prim',
            sek: 'Sek'
        },
        shuntTempTypes: [
            'Värme',
            'Kyla',
        ],
        advancedModeEnabled: false,
    },
    "SR-2 flex Appshunt": {
        id: "SR-2 flex Appshunt",
        connOptions: [
            'H'
        ],
        shuntDimLabels: {
            prim: 'Prim',
            sek: 'Sek'
        },
        shuntFlowLabels: {
            prim: 'Prim',
            sec: 'Sek'
        },
        shuntAdjustValveLabels: {
            prim: 'Prim',
            sek: 'Sek'
        },
        limitedDimSizes: [
            'DN020-6',
            'DN025',
        ],
        shuntTempTypes: [
            'Värme',
            'Kyla',
        ],
        advancedModeEnabled: false,
    },
    "SR-3": {
        id: "SR-3",
        connOptions: [
            'U', 'H', 'L'
        ],
        shuntDimLabels: {
            prim: 'Prim',
            sek: 'Sek'
        },
        shuntFlowLabels: {
            prim: 'Prim',
            sec: 'Sek'
        },
        shuntAdjustValveLabels: {
            prim: 'Prim',
            sek: 'Sek'
        },
        shuntTempTypes: [
            'Värme',
            'Kyla',
        ],
        advancedModeEnabled: false,
    },
    "PR-2": {
        id: "PR-2",
        connOptions: [
            'U', 'H', 'L'
        ],
        shuntDimLabels: {
            prim: 'Prim',
            sek: 'Sek'
        },
        shuntAdjustValveLabels: {
            prim: 'Prim',
            sek: 'Sek'
        },
        shuntTempTypes: [
            'Värme',
            'Kyla',
        ],
        advancedModeEnabled: false,
    },
    "PR-2 Appshunt": {
        id: "PR-2 Appshunt",
        connOptions: [
            'H'
        ],
        shuntDimLabels: {
            prim: 'Prim',
            sek: 'Sek'
        },
        shuntFlowLabels: {
            prim: 'Prim',
            sec: 'Sek'
        },
        shuntAdjustValveLabels: {
            prim: 'Prim',
            sek: 'Sek'
        },
        limitedDimSizes: [
            'DN020-6'
        ],
        shuntTempTypes: [
            'Värme',
            'Kyla',
        ],
        advancedModeEnabled: false,
    },
    "PR-3": {
        id: "PR-3",
        connOptions: [
            'U', 'H', 'L'
        ],
        shuntDimLabels: {
            prim: 'Prim',
            sek: 'Sek'
        },
        shuntFlowLabels: {
            prim: 'Prim',
            sec: 'Sek'
        },
        shuntAdjustValveLabels: {
            prim: 'Prim',
            sek: 'Sek'
        },
        shuntTempTypes: [
            'Värme',
            'Kyla',
        ],
        advancedModeEnabled: false,
    },
    "VA-1": {
        id: "VA-1",
        connOptions: [
            'U', 'H', 'L'
        ],
        shuntDimLabels: {
            prim: 'TL',
            sek: 'FL'
        },
        shuntFlowLabels: {
            prim: 'TL',
            sec: 'FL'
        },
        shuntAdjustValveLabels: {
            prim: 'TL',
            sek: 'FL'
        },
        shuntTempTypes: [
            'Värme',
            'Kyla',
        ],
        advancedModeEnabled: false,
    },
    "FBU-5K": {
        id: "FBU-5K",
        connOptions: [
            'U'
        ],
        shuntDimLabels: {
            prim: 'KB',
            prim2: 'KS',
            sek: 'BF'
        },
        shuntFlowLabels: {
            prim: 'KB',
            prim2: 'KS',
            sec: 'BF'
        },
        shuntAdjustValveLabels: {
            prim: 'KB',
            prim2: 'KS',
            sek: 'BF'
        },
        shuntTempTypes: [
            'Värme',
        ],
        advancedModeEnabled: false,
    },
    "FBU-5V": {
        id: "FBU-5V",
        connOptions: [
            'U'
        ],
        shuntDimLabels: {
            prim: 'VP',
            prim2: 'VR',
            sek: 'Sek'
        },
        shuntFlowLabels: {
            prim: 'VP',
            prim2: 'VR',
            sec: 'Sek'
        },
        shuntAdjustValveLabels: {
            prim: 'VP',
            prim2: 'VR',
            sek: 'Sek'
        },
        shuntTempTypes: [
            'Kyla',
        ],
        advancedModeEnabled: false,
    },
    "FKU-5K": {
        id: "FKU-5K",
        connOptions: [
            'U'
        ],
        shuntDimLabels: {
            prim: 'KB',
            prim2: 'KS',
            sek: 'KS'
        },
        shuntFlowLabels: {
            prim: 'KB',
            prim2: 'KS',
            sec: 'KS'
        },
        shuntAdjustValveLabels: {
            prim: 'KB',
            prim2: 'KS',
            sek: 'KS'
        },
        shuntTempTypes: [
            'Kyla',
        ],
        advancedModeEnabled: false,
    },
    "SRX-42": {
        id: "SRX-42",
        connOptions: [
            'U', 'L'
        ],
        shuntDimLabels: {
            prim: 'Prim',
            sek: 'Sek'
        },
        shuntFlowLabels: {
            prim: 'Prim',
            sec: 'Sek'
        },
        shuntAdjustValveLabels: {
            prim: 'Prim',
            sek: 'Sek'
        },
        shuntTempTypes: [
            'Värme',
            'Kyla',
        ],
        advancedModeEnabled: false,
    },
    "SRX-43": {
        id: "SRX-43",
        connOptions: [
            'U', 'L'
        ],
        shuntDimLabels: {
            prim: 'Prim',
            sek: 'Sek'
        },
        shuntFlowLabels: {
            prim: 'Prim',
            sec: 'Sek'
        },
        shuntAdjustValveLabels: {
            prim: 'Prim',
            sek: 'Sek'
        },
        shuntTempTypes: [
            'Värme',
            'Kyla',
        ],
        advancedModeEnabled: false,
    },
    "PRX-43": {
        id: "PRX-43",
        connOptions: [
            'U', 'L'
        ],
        shuntDimLabels: {
            prim: 'Prim',
            sek: 'Sek'
        },
        shuntFlowLabels: {
            prim: 'Prim',
            sec: 'Sek'
        },
        shuntAdjustValveLabels: {
            prim: 'Prim',
            sek: 'Sek'
        },
        shuntTempTypes: [
            'Värme',
            'Kyla',
        ],
        advancedModeEnabled: false,
    },
    "PRUX-43FK": {
        id: "PRUX-43FK",
        connOptions: [
            'U'
        ],
        shuntDimLabels: {
            prim: 'KB',
            sek: 'FK'
        },
        shuntFlowLabels: {
            prim: 'KB',
            sec: 'FK'
        },
        shuntAdjustValveLabels: {
            prim: 'KB',
            sek: 'FK'
        },
        shuntTempTypes: [
            'Kyla',
        ],
        advancedModeEnabled: false,
    },
    "SRBX-62VK": {
        id: "SRBX-62VK",
        connOptions: [
            'U', 'H', 'L'
        ],
        shuntDimLabels: {
            prim: 'KB',
            prim2: 'VP',
            sek: 'Sek'
        },
        shuntFlowLabels: {
            prim: 'KB',
            prim2: 'VP',
            sec: 'Sek'
        },
        shuntAdjustValveLabels: {
            prim: 'KB',
            prim2: 'VP',
            sek: 'Sek'
        },
        shuntTempTypes: [
            'Värme + kyla',
        ],
        shuntSecCVLabels: [
            { 'label': '2-vägs', 'nWays': 2 },
            { 'label': '3-vägs', 'nWays': 3 }
        ],
        shuntCustomExchangeLabels: [
            { 'label': 'KB', 'val': 'prim' },
            { 'label': 'VP', 'val': 'prim2' }
        ],
        advancedModeEnabled: false,
    },
    "SRBX-63VK": {
        id: "SRBX-63VK",
        connOptions: [
            'U', 'H', 'L'
        ],
        shuntDimLabels: {
            prim: 'KB',
            prim2: 'VP',
            sek: 'Sek'
        },
        shuntFlowLabels: {
            prim: 'KB',
            prim2: 'VP',
            sec: 'Sek'
        },
        shuntAdjustValveLabels: {
            prim: 'KB',
            prim2: 'VP',
            sek: 'Sek'
        },
        shuntTempTypes: [
            'Värme + kyla',
        ],
        shuntSecCVLabels: [
            { 'label': '2-vägs', 'nWays': 2 },
            { 'label': '3-vägs', 'nWays': 3 }
        ],
        shuntCustomExchangeLabels: [
            { 'label': 'KB', 'val': 'prim' },
            { 'label': 'VP', 'val': 'prim2' }
        ],
        advancedModeEnabled: false,
    },
    "PRBX-6VK": {
        id: "PRBX-6VK",
        connOptions: [
            'U', 'H', 'L'
        ],
        shuntDimLabels: {
            prim: 'KB',
            prim2: 'VP',
            sek: 'Sek'
        },
        shuntFlowLabels: {
            prim: 'KB',
            prim2: 'VP',
            sec: 'Sek'
        },
        shuntAdjustValveLabels: {
            prim: 'KB',
            prim2: 'VP',
            sek: 'Sek'
        },
        shuntTempTypes: [
            'Värme + kyla',
        ],
        advancedModeEnabled: false,
    },
    "SRBX-6ECO": {
        id: "SRBX-6ECO",
        connOptions: [
            'U', 'H', 'L'
        ],
        shuntDimLabels: {
            prim: 'KB',
            sek: 'Sek',
            prim2: 'VP',
        },
        shuntFlowLabels: {
            prim: 'KB',
            sec: 'Sek',
            prim2: 'VP',
        },
        shuntAdjustValveLabels: {
            prim: 'KB',
            sek: 'Sek',
            prim2: 'VP',
        },
        shuntTempTypes: [
            'Värme + kyla',
        ],
        advancedModeEnabled: false,
    },
    "SRBX-6VAV": {
        id: "SRBX-6VAV",
        connOptions: [
            'U', 'H', 'L'
        ],
        shuntDimLabels: {
            prim: 'TL',
            prim2: 'VP',
            sek: 'Sek',
        },
        shuntFlowLabels: {
            prim: 'TL',
            prim2: 'VP',
            sec: 'Sek',
        },
        shuntAdjustValveLabels: {
            prim: 'TL',
            prim2: 'VP',
            sek: 'Sek',
        },
        shuntTempTypes: [
            'VÅ + kyla',
        ],
        advancedModeEnabled: false,
    },
    "SRBX-6SPETS": {
        id: "SRBX-6SPETS",
        connOptions: [
            'U', 'H', 'L'
        ],
        shuntDimLabels: {
            prim: 'ÅV',
            prim2: 'VP',
            sek: 'Sek',
        },
        shuntFlowLabels: {
            prim: 'ÅV',
            prim2: 'VP',
            sec: 'Sek',
        },
        shuntAdjustValveLabels: {
            prim: 'ÅV',
            prim2: 'VP',
            sek: 'Sek',
        },
        shuntTempTypes: [
            'Värme',
        ],
        advancedModeEnabled: false,
    },
    "SRBX-6NOD": {
        id: "SRBX-6NOD",
        connOptions: [
            'U', 'H', 'L'
        ],
        shuntDimLabels: {
            prim: 'KB',
            prim2: 'TAPP',
            sek: 'Sek',
        },
        shuntFlowLabels: {
            prim: 'KB',
            prim2: 'TAPP',
            sec: 'Sek',
        },
        shuntAdjustValveLabels: {
            prim: 'KB',
            prim2: 'TAPP',
            sek: 'Sek',
        },
        shuntTempTypes: [
            'Kyla',
        ],
        advancedModeEnabled: false,
    },
    "SRBX-6VAK": {
        id: "SRBX-6VAK",
        connOptions: [
            'U', 'H', 'L'
        ],
        shuntDimLabels: {
            prim: 'KB',
            prim2: 'TAPP',
            sek: 'Sek',
        },
        shuntFlowLabels: {
            prim: 'TL',
            prim2: 'KB',
            sec: 'FL',
        },
        shuntAdjustValveLabels: {
            prim: 'KB',
            prim2: 'TAPP',
            sek: 'Sek',
        },
        shuntTempTypes: [
            'VÅ + kyla',
        ],
        advancedModeEnabled: false,
    },
    "SRBX-6VAK-Forbi": {
        id: "SRBX-6VAK-Forbi",
        connOptions: [
            'U', 'H', 'L'
        ],
        shuntDimLabels: {
            prim: 'TL',
            prim2: 'KB',
            sek: 'FL',
        },
        shuntFlowLabels: {
            prim: 'TL',
            prim2: 'KB',
            sec: 'FL',
        },
        shuntAdjustValveLabels: {
            prim: 'TL',
            prim2: 'KB',
            sek: 'FL',
        },
        shuntTempTypes: [
            'VÅ + kyla',
        ],
        advancedModeEnabled: false,
    },
    "SRBX-8VAKK": {
        id: "SRBX-8VAKK",
        connOptions: [
            'U', 'H', 'L'
        ],
        shuntDimLabels: {
            prim: 'TL',
            prim2: 'VP',
            sek: 'KB',
            sek2: 'FL'
        },
        shuntFlowLabels: {
            prim: 'TL',
            prim2: 'VP',
            sec: 'KB',
            sec2: 'FL'
        },
        shuntAdjustValveLabels: {
            prim: 'TL',
            prim2: 'VP',
            sek: 'KB',
            sek2: 'FL'
        },
        shuntTempTypes: [
            'VÅ + värme + kyla',
        ],
        advancedModeEnabled: false,
    },
    "SRBX-8VAKK-Forbi": {
        id: "SRBX-8VAKK-Forbi",
        connOptions: [
            'U', 'H', 'L'
        ],
        shuntDimLabels: {
            prim: 'TL',
            prim2: 'VP',
            sek: 'KB',
            sek2: 'FL'
        },
        shuntFlowLabels: {
            prim: 'TL',
            prim2: 'VP',
            sec: 'KB',
            sec2: 'FL'
        },
        shuntAdjustValveLabels: {
            prim: 'TL',
            prim2: 'VP',
            sek: 'KB',
            sek2: 'FL'
        },
        shuntTempTypes: [
            'VÅ + värme + kyla',
        ],
        advancedModeEnabled: false,
    },
    "PBCS": {
        id: "PBCS",
        connOptions: [
            'Custom'
        ],
        advancedModeEnabled: false,
    },
};